import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import NoMeals from "../../icons/NoMeals";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  text: {
    fontWeight: 600,
    fontSize: 24,
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
}));

export interface ClientMealLoggingEmptyProps extends BoxProps {
  today?: boolean;
}

export function ClientMealLoggingEmpty(props: ClientMealLoggingEmptyProps) {
  const { className, today = true, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <NoMeals fill={theme.palette.primary.main} />
      <Typography className={s.text}>
        No meals logged {today ? "today" : "this day"}
      </Typography>
    </Box>
  );
}
