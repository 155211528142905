/**
 * @generated SignedSource<<ebe95451eccf627a08f5177798c82de3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientLast7DaysCompletion_completionByDate$data = ReadonlyArray<{
  readonly completion: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly date: string;
  readonly " $fragmentType": "ClientLast7DaysCompletion_completionByDate";
}>;
export type ClientLast7DaysCompletion_completionByDate$key = ReadonlyArray<{
  readonly " $data"?: ClientLast7DaysCompletion_completionByDate$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientLast7DaysCompletion_completionByDate">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientLast7DaysCompletion_completionByDate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CompletionByDate",
  "abstractKey": null
};

(node as any).hash = "e93d33c73f609dda20be29f055b6b2e3";

export default node;
