/**
 * @generated SignedSource<<b57851e53d98adad1994257649fd433d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteTagInput = {
  clientMutationId?: string | null | undefined;
  tagId: string;
};
export type ClientSettingsDialogDeleteTagMutation$variables = {
  input: deleteTagInput;
};
export type ClientSettingsDialogDeleteTagMutation$data = {
  readonly deleteTag: {
    readonly deleted: boolean | null | undefined;
  } | null | undefined;
};
export type ClientSettingsDialogDeleteTagMutation = {
  response: ClientSettingsDialogDeleteTagMutation$data;
  variables: ClientSettingsDialogDeleteTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "deleteTagPayload",
    "kind": "LinkedField",
    "name": "deleteTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsDialogDeleteTagMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSettingsDialogDeleteTagMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c785bee7bcb00797ac1e0904ce0f009f",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsDialogDeleteTagMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSettingsDialogDeleteTagMutation(\n  $input: deleteTagInput!\n) {\n  deleteTag(input: $input) {\n    deleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ff38e0ac32b79271d63e9f1fe5752b4";

export default node;
