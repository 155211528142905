/**
 * @generated SignedSource<<9421c2e18ae533a52cd55b3e081d0423>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientProgramsRouteQuery$variables = {
  username: string;
};
export type CoachClientProgramsRouteQuery$data = {
  readonly client: {
    readonly displayName: string | null | undefined;
    readonly email: string | null | undefined;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClientSummary_client">;
  } | null | undefined;
  readonly enrollments: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientPrograms_enrollments">;
  } | null | undefined;
};
export type CoachClientProgramsRouteQuery = {
  response: CoachClientProgramsRouteQuery$data;
  variables: CoachClientProgramsRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "client",
    "variableName": "username"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "format",
  "value": "fromNow"
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "completed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rate",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Completion",
  "kind": "LinkedField",
  "name": "completion",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "kind": "Literal",
  "name": "utc",
  "value": true
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachClientProgramsRouteQuery",
    "selections": [
      {
        "alias": "client",
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientSummary_client"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EnrollmentConnection",
        "kind": "LinkedField",
        "name": "enrollments",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientPrograms_enrollments"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachClientProgramsRouteQuery",
    "selections": [
      {
        "alias": "client",
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "age",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "units",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v7/*: any*/)
            ],
            "kind": "ScalarField",
            "name": "lastLoginAt",
            "storageKey": "lastLoginAt(format:\"fromNow\")"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "EnrollmentConnection",
        "kind": "LinkedField",
        "name": "enrollments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrollmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Enrollment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "upcoming",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "past",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "program",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "completion30Days",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "period",
                        "value": "LAST_30_DAYS"
                      }
                    ],
                    "concreteType": "Completion",
                    "kind": "LinkedField",
                    "name": "completion",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": "completion(period:\"LAST_30_DAYS\")"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "period",
                        "value": "LAST_7_DAYS"
                      }
                    ],
                    "concreteType": "CompletionByDate",
                    "kind": "LinkedField",
                    "name": "completionByDate",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": "completionByDate(period:\"LAST_7_DAYS\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentDay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalDays",
                    "storageKey": null
                  },
                  {
                    "alias": "startFromNow",
                    "args": [
                      (v7/*: any*/),
                      (v10/*: any*/)
                    ],
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": "startDate(format:\"fromNow\",utc:true)"
                  },
                  {
                    "alias": "endDateShort",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM DD, YYYY"
                      },
                      (v10/*: any*/)
                    ],
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": "endDate(format:\"MMM DD, YYYY\",utc:true)"
                  },
                  {
                    "alias": "endDateLong",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMMM D, YYYY"
                      },
                      (v10/*: any*/)
                    ],
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": "endDate(format:\"MMMM D, YYYY\",utc:true)"
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f28904bf2fe354a9d9501e1e95895a7",
    "id": null,
    "metadata": {},
    "name": "CoachClientProgramsRouteQuery",
    "operationKind": "query",
    "text": "query CoachClientProgramsRouteQuery(\n  $username: String!\n) {\n  client: user(username: $username) {\n    ...ClientSummary_client\n    username\n    email\n    displayName\n    id\n  }\n  enrollments(client: $username) {\n    ...ClientPrograms_enrollments\n  }\n}\n\nfragment ClientActiveProgramCard_enrollment on Enrollment {\n  ...ClientSmallProgramCard_enrollment\n  ...ClientCardCompletion_enrollment\n  currentDay\n  totalDays\n}\n\nfragment ClientCardCompletion_enrollment on Enrollment {\n  completion30Days: completion(period: LAST_30_DAYS) {\n    completed\n    total\n    rate\n  }\n  completionByDate(period: LAST_7_DAYS) {\n    ...ClientLast7DaysCompletion_completionByDate\n  }\n}\n\nfragment ClientLast7DaysCompletion_completionByDate on CompletionByDate {\n  date\n  completion {\n    completed\n    total\n    rate\n  }\n}\n\nfragment ClientPastProgramCard_enrollment on Enrollment {\n  ...ClientSmallProgramCard_enrollment\n  totalDays\n  endDateLong: endDate(utc: true, format: \"MMMM D, YYYY\")\n  completion {\n    completed\n    total\n    rate\n  }\n}\n\nfragment ClientPrograms_enrollments on EnrollmentConnection {\n  edges {\n    node {\n      id\n      active\n      upcoming\n      past\n      ...ClientActiveProgramCard_enrollment\n      ...ClientUpcomingProgramCard_enrollment\n      ...ClientPastProgramCard_enrollment\n    }\n  }\n}\n\nfragment ClientSmallProgramCard_enrollment on Enrollment {\n  program {\n    name\n    imageURL\n    slug\n    id\n  }\n  client {\n    username\n    id\n  }\n}\n\nfragment ClientSummary_client on User {\n  id\n  displayName\n  email\n  photoURL\n  age\n  gender\n  weight\n  height\n  units\n  location\n  clientStatus\n  lastLoginAt(format: \"fromNow\")\n}\n\nfragment ClientUpcomingProgramCard_enrollment on Enrollment {\n  ...ClientSmallProgramCard_enrollment\n  totalDays\n  startFromNow: startDate(utc: true, format: \"fromNow\")\n  endDateShort: endDate(utc: true, format: \"MMM DD, YYYY\")\n}\n"
  }
};
})();

(node as any).hash = "df3ab082354964ed33c5f969e13cd800";

export default node;
