import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { Icons } from "../../plate-ui/Icons/icons";
import { ELEMENT_H1, ELEMENT_H2 } from "@udecode/plate-heading";
import { ELEMENT_LINK } from "@udecode/plate-link";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { ELEMENT_PULLQUOTE } from "../elements/Pullquote";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { ELEMENT_CALLOUT } from "../elements/Callout";
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule";
import { IMAGE_ELEMENT } from "../elements/Image";
import { VIDEO } from "../elements/Video";
import { AUDIO } from "../elements/Audio";
import { FILE } from "../elements/FileUploader";
import { EMBED_WEBSITE } from "../elements/MediaEmbed";
import { WEB_BOOKMARK } from "../elements/web-bookmark/WebBookmark";
import { LucideIcon } from "lucide-react";
import { WORKOUT_SECTION } from "../components/workout/WorkoutSectionElement";
import { ELEMENT_ACCORDION, ELEMENT_TABS } from "../elements/tabs/Tabs";
import { Node } from "slate";

export interface IMenuItem {
  value: string;
  label: string;
  icon: LucideIcon;
  description: string;
}

/**
 * Text Menu Items
 */

export const MENU_ITEM_TEXT: IMenuItem = {
  value: ELEMENT_PARAGRAPH,
  label: "Text",
  icon: Icons.type,
  description: "Write with plain text",
};
export const MENU_ITEM_HEADING_1: IMenuItem = {
  value: ELEMENT_H1,
  label: "Heading 1",
  icon: Icons.h1,
  description: "Use for large section headings",
};
export const MENU_ITEM_HEADING_2: IMenuItem = {
  value: ELEMENT_H2,
  label: "Heading 2",
  icon: Icons.h2,
  description: "Use for medium section headings",
};
export const MENU_ITEM_LINK: IMenuItem = {
  value: ELEMENT_LINK,
  label: "Link",
  icon: Icons.link,
  description: "Add inline text link",
};

/**
 * List Menu Items
 */

export const MENU_ITEM_CHECKLIST: IMenuItem = {
  value: "todo",
  label: "Checklist",
  icon: Icons.listTodo,
  description: "Track tasks with check list",
};
export const MENU_ITEM_BULLETED_LIST: IMenuItem = {
  value: "ul",
  label: "Bulleted list",
  icon: Icons.ul,
  description: "Create a list using bullet points",
};
export const MENU_ITEM_NUMBERED_LIST: IMenuItem = {
  value: "ol",
  label: "Numbered list",
  icon: Icons.ol,
  description: "Create a list with numbers",
};

/**
 * Quotes Menu Items
 */

export const MENU_ITEM_QUOTE_BLOCK: IMenuItem = {
  value: ELEMENT_BLOCKQUOTE,
  label: "Block quote",
  icon: Icons.textQuote,
  description: "Format quote as a separate text block",
};
export const MENU_ITEM_QUOTE_PULL: IMenuItem = {
  value: ELEMENT_PULLQUOTE,
  label: "Pull quote",
  icon: Icons.blockquote,
  description: "Pull quote out of its context",
};

/**
 * Switch Menu Items
 */

export const MENU_ITEM_TABS: IMenuItem = {
  value: ELEMENT_TABS,
  label: "Tabs",
  icon: Icons.tabs,
  description: "Separate content into tabs",
};
export const MENU_ITEM_ACCORDION: IMenuItem = {
  value: ELEMENT_ACCORDION,
  label: "Accordion",
  icon: Icons.accordion,
  description: "Easily show and hide content",
};

/**
 * Layout Menu Items
 */

export const MENU_ITEM_TABLE: IMenuItem = {
  value: ELEMENT_TABLE,
  label: "Table",
  icon: Icons.table,
  description: "Add a simple table",
};
export const MENU_ITEM_CALLOUT: IMenuItem = {
  value: ELEMENT_CALLOUT,
  label: "Callout",
  icon: Icons.megaphone,
  description: "Make a message stand out",
};
export const MENU_ITEM_DIVIDER: IMenuItem = {
  value: ELEMENT_HR,
  label: "Divider",
  icon: Icons.minus,
  description: "Visually separate blocks",
};

/**
 * Media Menu Items
 */

export const MENU_ITEM_IMAGE: IMenuItem = {
  value: IMAGE_ELEMENT,
  label: "Image",
  icon: Icons.image,
  description: "Upload, embed or search images",
};
export const MENU_ITEM_VIDEO: IMenuItem = {
  value: VIDEO,
  label: "Video",
  icon: Icons.video,
  description: "Embed video (YouTube, Vimeo, etc...)",
};
export const MENU_ITEM_AUDIO: IMenuItem = {
  value: AUDIO,
  label: "Audio",
  icon: Icons.volume,
  description: "Embed audio (Soundcloud, Spotify, etc...)",
};
export const MENU_ITEM_FILE: IMenuItem = {
  value: FILE,
  label: "File attachment",
  icon: Icons.paperclip,
  description: "Attach a file for download",
};

/**
 * Url Menu Items
 */

export const MENU_ITEM_EMBED: IMenuItem = {
  value: EMBED_WEBSITE,
  label: "Embed",
  icon: Icons.compass,
  description: "Add an external website",
};
export const MENU_ITEM_WEB_BOOKMARK: IMenuItem = {
  value: WEB_BOOKMARK,
  label: "Web bookmark",
  icon: Icons.globe,
  description: "Add a link with a rich preview",
};

/**
 * Training Menu Items
 */

export const MENU_ITEM_EXERCISE: IMenuItem = {
  value: WORKOUT_SECTION,
  label: "Exercise section",
  icon: Icons.activity,
  description: "Add exercises and supersets",
};

/**
 * Check In Question Menu Items
 */

export enum CheckInTypes {
  YES_NO = "checkin_answer_binary",
  MEASUREMENT = "checkin_answer_measurement",
  PROGRESS_PHOTO = "checkin_answer_progress_photo",
  RATING_SCALE = "checkin_answer_rating_scale",
  LONG_TEXT = "checkin_answer_long_text",
  SHORT_TEXT = "checkin_answer_short_text",
  NUMBER = "checkin_answer_number",
  FILE = "checkin_answer_file",
  MULTIPLE_CHOICE = "checkin_answer_multiple_choice",
  MULTIPLE_CHECKBOX = "checkin_answer_multiple_choice_checkbox",
  DATE = "checkin_answer_date",
  EMOJI = "checkin_answer_emoji",
  STAR_RATING = "checkin_answer_star_rating",
  NUTRITION_TARGET = "checkin_answer_nutrition_target",
}

export const MENU_ITEM_CHECK_IN_SHORT_TEXT: IMenuItem = {
  value: CheckInTypes.SHORT_TEXT,
  label: "Short text answer",
  icon: Icons.bookMinus,
  description: "Ask a short open-ended question",
};
export const MENU_ITEM_CHECK_IN_LONG_TEXT: IMenuItem = {
  value: CheckInTypes.LONG_TEXT,
  label: "Long text answer",
  icon: Icons.bookText,
  description: "Ask a long open-ended question",
};
export const MENU_ITEM_CHECK_IN_DATE: IMenuItem = {
  value: CheckInTypes.DATE,
  label: "Date answer",
  icon: Icons.calendar,
  description: "Ask for a date answer",
};
export const MENU_ITEM_CHECK_IN_NUMBER: IMenuItem = {
  value: CheckInTypes.NUMBER,
  label: "Number answer",
  icon: Icons.hash,
  description: "Ask for a numeric answer",
};
export const MENU_ITEM_CHECK_IN_FILE: IMenuItem = {
  value: CheckInTypes.FILE,
  label: "File feedback",
  icon: Icons.cloudUpload,
  description: "Ask for any file answer (PDF, image, etc...)",
};

/**
 * Check In Vote Menu Items
 */

export const MENU_ITEM_CHECK_IN_YES_NO: IMenuItem = {
  value: CheckInTypes.YES_NO,
  label: "Yes/No",
  icon: Icons.power,
  description: "Offer two options to choose from",
};
export const MENU_ITEM_CHECK_MULTIPLE_CHOICE: IMenuItem = {
  value: CheckInTypes.MULTIPLE_CHOICE,
  label: "Multiple choice",
  icon: Icons.circleDot,
  description: "Provide a set of options to choose one",
};
export const MENU_ITEM_CHECK_IN_MULTIPLE_CHECKBOX: IMenuItem = {
  value: CheckInTypes.MULTIPLE_CHECKBOX,
  label: "Checkboxes",
  icon: Icons.checkBox,
  description: "Provide a set of options to choose multiple",
};

/**
 * Check In Rating Menu Items
 */

export const MENU_ITEM_CHECK_RATING_SCALE: IMenuItem = {
  value: CheckInTypes.RATING_SCALE,
  label: "Rating scale",
  icon: Icons.arrowDownWithNumbers,
  description: "Ask for a numerical rating",
};
export const MENU_ITEM_CHECK_STAR_RATING: IMenuItem = {
  value: CheckInTypes.STAR_RATING,
  label: "Star rating",
  icon: Icons.star,
  description: "Ask for a star rating",
};
export const MENU_ITEM_CHECK_EMOJI: IMenuItem = {
  value: CheckInTypes.EMOJI,
  label: "Emoji answer",
  icon: Icons.emojiPlus,
  description: "Ask for a mood rating",
};

/**
 * Check In Feedback Menu Items
 */

export const MENU_ITEM_CHECK_MEASUREMENT: IMenuItem = {
  value: CheckInTypes.MEASUREMENT,
  label: "Measurement",
  icon: Icons.ruler,
  description: "Collect body weight or other body stats",
};
export const MENU_ITEM_CHECK_PROGRESS_PHOTO: IMenuItem = {
  value: CheckInTypes.PROGRESS_PHOTO,
  label: "Progress photo",
  icon: Icons.camera,
  description: "Ask for progress pictures",
};
export const MENU_ITEM_CHECK_NUTRITION_TARGET: IMenuItem = {
  value: CheckInTypes.NUTRITION_TARGET,
  label: "Nutrition target",
  icon: Icons.food,
  description: "Add a nutrition target",
};

export const menuItemDisabled = (type: string, content?: Node[]) => {
  if (type === CheckInTypes.PROGRESS_PHOTO) {
    return content?.some(
      (node) => (node as unknown as { type: string }).type === type,
    );
  }

  return false;
};

export const MEMU_ITEMS_CHECKIN_ARRAY = [
  MENU_ITEM_CHECK_IN_SHORT_TEXT,
  MENU_ITEM_CHECK_IN_LONG_TEXT,
  MENU_ITEM_CHECK_IN_DATE,
  MENU_ITEM_CHECK_IN_NUMBER,
  MENU_ITEM_CHECK_IN_FILE,
  MENU_ITEM_CHECK_IN_YES_NO,
  MENU_ITEM_CHECK_MULTIPLE_CHOICE,
  MENU_ITEM_CHECK_IN_MULTIPLE_CHECKBOX,
  MENU_ITEM_CHECK_RATING_SCALE,
  MENU_ITEM_CHECK_STAR_RATING,
  MENU_ITEM_CHECK_EMOJI,
  MENU_ITEM_CHECK_MEASUREMENT,
  MENU_ITEM_CHECK_PROGRESS_PHOTO,
  MENU_ITEM_CHECK_NUTRITION_TARGET,
];
