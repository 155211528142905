/**
 * @generated SignedSource<<80003b90764619b10faf0596767234fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientMealLoggingEntriesList_workspace$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntryDialog_workspace">;
  readonly " $fragmentType": "ClientMealLoggingEntriesList_workspace";
};
export type ClientMealLoggingEntriesList_workspace$key = {
  readonly " $data"?: ClientMealLoggingEntriesList_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntriesList_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientMealLoggingEntriesList_workspace",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientMealLoggingEntryDialog_workspace"
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "6d303b8d2a6faf7e9cbee136388c8823";

export default node;
