import { Box, IconButton, Rating } from "@mui/material";
import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import { StarRating } from "../../../fields/StarRating";

export type IStarRatingQuestionAnswer = {
  starRating?: number;
};

const StarRatingQuestion = ({
  view,
  element,
  disableAnswer,
  handleSetNode,
}: ICheckInQuestionPropsBase<IStarRatingQuestionAnswer>) => {
  const answer = element.answer as IStarRatingQuestionAnswer;

  const handleChange = (newValue: number | null) => {
    handleSetNode({ answer: { starRating: newValue } });
  };

  const isCoachView = view === EditorElementView.Coach;
  const disabled = isCoachView || disableAnswer;

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      {/* BOX needed to make tooltip work properly */}
      <Box>
        <StarRating
          value={answer?.starRating}
          onSelect={handleChange}
          disabled={disabled}
        />
      </Box>
    </DisabledCheckInTooltip>
  );
};

export default StarRatingQuestion;
