import makeStyles from "@mui/styles/makeStyles";

export const tableStyles = makeStyles((theme) => ({
  tableTarget: {
    width: "12%",
  },

  tableDate: {
    width: "16%",
  },

  tableProtein: {
    width: "12%",
  },

  tableCarbs: {
    width: "12%",
  },

  tableFat: {
    width: "12%",
  },

  tableCalories: {
    width: "10%",
  },

  tableComments: {
    width: "20%",
  },

  tableAction: {
    width: "5%",
  },
}));
