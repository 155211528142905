import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardActionArea,
  CardHeader,
  Grid,
  useTheme,
  useMediaQuery,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  bindMenu,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { ArrowForwardRounded, MoreHoriz } from "@mui/icons-material";
import { graphql, useFragment } from "react-relay";

import { ProgramFolderCard_folder$key } from "./__generated__/ProgramFolderCard_folder.graphql";
import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},

  actionArea: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    "& .MuiCardHeader-action": {
      margin: "unset",
    },
  },

  header: {
    padding: 0,
    width: "100%",

    "& .MuiCardHeader-content": {
      overflow: "hidden",
    },
  },

  title: {
    fontSize: "1rem",
    textOverflow: "ellipsis",
  },
}));

const folderFragment = graphql`
  fragment ProgramFolderCard_folder on ProgramFolder {
    id
    name
  }
`;

export interface ProgramFolderCardProps extends CardProps {
  folder: ProgramFolderCard_folder$key;
  onRenameClick?: () => void;
  onDeleteClick?: () => void;
}

export function ProgramFolderCard(props: ProgramFolderCardProps) {
  const navigate = useNavigate();
  const {
    onRenameClick,
    onDeleteClick,
    className,
    folder: folderRef,
    ...other
  } = props;
  const folder = useFragment(folderFragment, folderRef);
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));

  const folderId = folder.id;
  const name = folder.name;
  const link = `${COACH_PROGRAMS_PUBLISHED_ROUTE}?folder=${folderId}`;

  const handleCardClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => navigate(link),
    [link],
  );

  const actionsMenuState = usePopupState({
    variant: "popover",
    popupId: "actions-menu",
  });
  const actionsMenuToggle = bindToggle(actionsMenuState);

  const handleActionsMenuToggle = React.useCallback(
    (event) => {
      event.stopPropagation();
      actionsMenuToggle.onClick(event);
    },
    [actionsMenuToggle],
  );

  const actionsMenuButton = (
    <IconButton
      {...actionsMenuToggle}
      onClick={handleActionsMenuToggle}
      size="large"
    >
      <MoreHoriz />
    </IconButton>
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <CardActionArea className={clsx(s.actionArea)} onClick={handleCardClick}>
        <CardHeader
          className={clsx(s.header)}
          title={name}
          titleTypographyProps={{
            className: s.title,
            fontSize: "1rem",
            noWrap: true,
            color: "secondary",
          }}
          action={
            <Grid container alignItems="center">
              {actionsMenuButton}
              {mdUp && <ArrowForwardRounded />}
            </Grid>
          }
        />
      </CardActionArea>
      <Menu {...bindMenu(actionsMenuState)} keepMounted>
        <MenuItem
          onClick={() => {
            actionsMenuState.close();
            onRenameClick?.();
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            actionsMenuState.close();
            onDeleteClick?.();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Card>
  );
}
