import React from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  getParentNode,
  useEditorRef,
} from "@udecode/plate-common";
import clsx from "clsx";
import { TabLabel } from "./TabLabel";
import { Box } from "@mui/material";
import { ELEMENT_ACCORDION } from "./Tabs";

export const ELEMENT_TABS_ITEM = "tabs_item";

export const TabsItem = withRef<typeof PlateElement>(({ ...props }, ref) => {
  const { children, element } = props;
  const editor = useEditorRef();
  const path = findNodePath(editor, element);
  const [parent, parentPath] = getParentNode(editor, path);

  const Wrapper = parent.type === ELEMENT_ACCORDION ? TabLabel : Box;
  const wrapperProps: any =
    parent.type === ELEMENT_ACCORDION ? { element, parentId: parent.id } : {};
  const editableProps: any = {};

  if (!element.active) {
    editableProps.contentEditable = false;
  }

  return (
    <PlateElement ref={ref} {...props}>
      <Wrapper {...wrapperProps} {...editableProps}>
        <div className={clsx("py-5 px-14", !element.active && "hidden")}>
          {children}
        </div>
      </Wrapper>
    </PlateElement>
  );
});
