import clsx from "clsx";
import React from "react";
import { List, ListProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ActivityResponseMealsList_activity$key } from "./__generated__/ActivityResponseMealsList_activity.graphql";
import { ActivityResponseMealsListItem } from "./ActivityResponseMealsListItem";
import { MealActivityContent } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const activityFragment = graphql`
  fragment ActivityResponseMealsList_activity on Activity {
    content
  }
`;

export interface ActivityResponseMealsListProps extends ListProps {
  activityRef: ActivityResponseMealsList_activity$key;
}

export function ActivityResponseMealsList(
  props: ActivityResponseMealsListProps,
) {
  const s = useStyles();
  const { className, activityRef, ...other } = props;
  const activity = useFragment(activityFragment, activityRef);
  const meals = JSON.parse(activity.content) as MealActivityContent[];
  const hasPhotos = meals.some(({ photoURL }) => photoURL);

  return (
    <List className={clsx(s.root, className)} {...other}>
      {meals.map((meal, index) => (
        <ActivityResponseMealsListItem
          key={index}
          meal={meal}
          divider={index < meals.length - 1}
          hasPhotos={hasPhotos}
        />
      ))}
    </List>
  );
}
