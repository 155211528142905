import { BasicToolbarMenu, MediaToolbarMenu, Schema } from "./Schema";

export const UserNoteEditorSchema: Schema = {
  generalDropDownMenuItems: [
    ...BasicToolbarMenu.sections,
    ...MediaToolbarMenu.sections,
  ],
  toolbarOptions: {
    showInsertDropdown: true,
    showTextTransformOptions: true,
    showTextFormattingOptions: true,
    showTextColorOptions: true,
    showTextIndentationOptions: false,
    showListOptions: true,
    showQuotesOptions: true,
    showLinkOption: false,
    showAdvancedLinkOptions: false,
    showElementInsertOptions: false,
    showEmojiOption: true,
  },
  stickyToolbar: true,
  showToolbarAlways: true,
  showInsertDropdownOnDraggable: false,
};
