import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ComponentStatus, ComponentType } from "../../constants";

import { ComponentPublishButton_component$key } from "./__generated__/ComponentPublishButton_component.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
  },
}));

const componentFragment = graphql`
  fragment ComponentPublishButton_component on Component {
    status
    draftExists
    type
  }
`;

export interface ComponentPublishButtonProps extends ButtonProps {
  componentRef: ComponentPublishButton_component$key;
  empty: boolean;
  dirty: boolean;
  savingStateMessage?: string;
}

export function ComponentPublishButton(props: ComponentPublishButtonProps) {
  const {
    className,
    componentRef,
    empty,
    dirty,
    savingStateMessage = "",
    ...other
  } = props;
  const s = useStyles();
  const theme = useTheme();
  const { status, draftExists, type } = useFragment(
    componentFragment,
    componentRef,
  );

  const draft = React.useMemo(() => status === ComponentStatus.DRAFT, [status]);
  const canPublishEmpty = [ComponentType.HABIT, ComponentType.MESSAGE].includes(
    type as ComponentType,
  );
  const disabled = draft ? empty && !canPublishEmpty : !draftExists || dirty;

  return (
    <Button
      className={clsx(s.root, className)}
      variant="contained"
      color="primary"
      sx={{ backgroundColor: theme.palette.primary.main }}
      children={draft ? "Publish" : "Save & Publish"}
      disabled={disabled || dirty || savingStateMessage === "Saving..."}
      {...other}
    />
  );
}
