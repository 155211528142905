import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.75),
  },
}));

export interface AuthButtonProps extends ButtonProps {}

export function AuthButton(props: AuthButtonProps) {
  const { className, children, ...other } = props;
  const s = useStyles();

  return (
    <Button
      className={clsx(s.root, className)}
      variant="contained"
      fullWidth
      children={children}
      {...other}
    />
  );
}
