import clsx from "clsx";
import React from "react";
import { Popover, PopoverProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ComponentSchedule } from "../../components/schedule/ComponentSchedule";
import { ComponentType, ComponentRepeat, ReminderType } from "../../constants";
import { useDirtyMutation } from "../dirty-transaction/hooks";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { Schedule } from "../schedule/types";

import { WeekComponentSchedule_component$key } from "./__generated__/WeekComponentSchedule_component.graphql";
import { WeekComponentScheduleMutation } from "./__generated__/WeekComponentScheduleMutation.graphql";
import { useProgramWeeks } from "../../hooks/useProgramWeeks";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.75),
  },

  arrow: {
    width: 50,
    height: 25,
    position: "absolute",

    left: "50%",
    overflow: "hidden",
    zIndex: 100,

    "&::after": {
      content: '""',
      position: "absolute",
      width: 18,
      height: 18,
      background: theme.palette.common.white,
      transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
      top: 0,
      left: "50%",
      boxShadow: theme.shadows[4],
    },
  },

  arrowUp: {
    transform: "translateX(-50%) translateY(-100%) rotate(180deg)",
    top: "0%",
  },

  arrowDown: {
    transform: "translateX(-50%)",
    top: "100%",
  },

  container: {
    padding: theme.spacing(3, 4),
    overflow: "visible",
  },

  topOriented: {
    marginTop: theme.spacing(-1.5),
  },

  bottomOriented: {
    marginTop: theme.spacing(1.5),
  },
}));

const componentFragment = graphql`
  fragment WeekComponentSchedule_component on Component {
    duration
    reminderType
    reminderTime
    messageTime
  }
`;

const saveComponentMutation = graphql`
  mutation WeekComponentScheduleMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        ...CoachComponent_component @arguments(draft: false)
        id
        slug
        weekId
      }
    }
  }
`;

export interface WeekComponentScheduleProps extends PopoverProps {
  componentRef: WeekComponentSchedule_component$key;
  weekId?: string;
  type?: string;
  title?: string;
  days?: any;
  repeat?: string;
  idItem?: string;
}

export function WeekComponentSchedule(props: WeekComponentScheduleProps) {
  const {
    className,
    componentRef,
    onClose,
    anchorEl,
    weekId,
    days,
    type,
    title,
    repeat,
    idItem,
    ...other
  } = props;
  const onAlert = useSnackAlert();
  const [bottomOriented, setBottomOriented] = React.useState(true);
  const s = useStyles();
  const component = useFragment(componentFragment, componentRef);
  const [saveComponent] = useDirtyMutation<WeekComponentScheduleMutation>(
    saveComponentMutation,
  );

  const { duration, reminderType, reminderTime, messageTime } = component;
  const [schedule, setSchedule] = React.useState<Schedule>({
    weekId,
    days: days as boolean[],
    duration,
    repeat: repeat as ComponentRepeat,
    reminderType: reminderType as ReminderType,
    reminderTime,
    messageTime,
  });
  const arrowRef = React.useRef<HTMLDivElement>(null);

  const programWeeks = useProgramWeeks();

  React.useEffect(() => {
    if (anchorEl) {
      const el = anchorEl as HTMLDivElement;
      const { y } = el.getBoundingClientRect();
      const screenHeight = window.screen.height;

      if (y < screenHeight / 2) {
        setBottomOriented(true);
      } else {
        setBottomOriented(false);
      }
    }
  }, [anchorEl]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (anchorEl && arrowRef.current) {
        const el = anchorEl as HTMLDivElement;
        const arrowEl = arrowRef.current;
        const elRect = el.getBoundingClientRect();
        const arrowRect = arrowEl.getBoundingClientRect();
        const offset = elRect.x - arrowRect.x;

        arrowEl.style.marginLeft = `${offset}px`;
      }
    });

    return () => clearTimeout(timer);
  });

  const handleScheduleChange = React.useCallback((schedule: Schedule) => {
    setSchedule(schedule);
  }, []);

  const handleScheduleSubmit = React.useCallback(
    (event) => {
      const input = {
        id: idItem,
        ...schedule,
      };

      saveComponent({
        variables: {
          input: input as any,
        },
        optimisticUpdater: (store) => {
          const node = store.get(idItem);

          for (const [field, value] of Object.entries(schedule)) {
            node.setValue(value as any, field);
          }
        },
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            onAlert({
              severity: "error",
              message: errors[0].message || "Error occurred.",
            });
          } else {
            const message = `${title} saved successfully.`;

            onAlert({
              severity: "success",
              message,
            });
          }
        },
        onError: (error) => {
          console.error(error);
          onAlert({
            severity: "error",
            message: "Internal server error.",
          });
        },
      });

      onClose(event, "escapeKeyDown");
    },
    [title, schedule, saveComponent, onClose, onAlert, idItem],
  );

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: bottomOriented ? "bottom" : "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: bottomOriented ? "top" : "bottom",
        horizontal: "center",
      }}
      className={clsx(
        s.root,
        className,
        bottomOriented ? s.bottomOriented : s.topOriented,
      )}
      PaperProps={{ className: s.container }}
      {...other}
    >
      <div
        className={clsx(s.arrow, bottomOriented ? s.arrowUp : s.arrowDown)}
        ref={arrowRef}
      />
      <ComponentSchedule
        layout="flat"
        componentType={type as ComponentType}
        schedule={schedule}
        onChange={handleScheduleChange}
        onSubmit={handleScheduleSubmit}
        programWeeks={programWeeks}
      />
    </Popover>
  );
}
