/**
 * @generated SignedSource<<c37561153b82e9b4404dbf0f31b8d753>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostCommentsListItemNoReplies_comment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsListItem_comment">;
  readonly " $fragmentType": "GroupPostCommentsListItemNoReplies_comment";
};
export type GroupPostCommentsListItemNoReplies_comment$key = {
  readonly " $data"?: GroupPostCommentsListItemNoReplies_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsListItemNoReplies_comment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostCommentsListItemNoReplies_comment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "fetchReplies",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "GroupPostCommentsListItem_comment"
    }
  ],
  "type": "GroupPostComment",
  "abstractKey": null
};

(node as any).hash = "e67602c47e43d1190c0d9706a381c72a";

export default node;
