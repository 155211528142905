import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { SubmitClientFormMutation } from "./__generated__/SubmitClientFormMutation.graphql";

const mutation = graphql`
  mutation SubmitClientFormMutation($input: SubmitClientFormInput!) {
    submitClientForm(input: $input) {
      clientForm {
        ...ClientForm_clientForm
      }
    }
  }
`;

export const useSubmitClientFormMutation = () =>
  useConfiguredMutation<SubmitClientFormMutation>(mutation, () => ({}));
