import clsx from "clsx";
import React from "react";
import {
  TableRow,
  TableRowProps,
  TableCell,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { ArrowForwardRounded as ArrowForwardIcon } from "@mui/icons-material";
import {
  usePopupState,
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/hooks";

import { ReactComponent as ClientFormIcon } from "../../icons/TaskListEdit.svg";
import { maybePluralize } from "../../utils/text";
import { defaultClientFormName } from "../client-forms/constants";
import { MoreMenuButton } from "../button/MoreMenuButton";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";
import { Menu } from "../menu/Menu";

import { CoachClientsFormsListItem_clientForm$key } from "./__generated__/CoachClientsFormsListItem_clientForm.graphql";
import { useRemoveClientFormMutation } from "./mutations/RemoveClientForm";
import { useDuplicateClientFormMutation } from "./mutations/DuplicateClientForm";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",

    "& td": {
      fontSize: 14,
      fontWeight: 500,
    },
  },

  nameWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(3),

    "& svg": {
      marginRight: theme.spacing(1),
    },
  },

  createdAtWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(2),

    "& svg": {
      color: theme.palette.text.secondary,
    },
  },

  buttons: {
    display: "flex",
    alignItems: "center",
  },

  open: {},

  more: {
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    opacity: 0,

    "$root:hover &, &$open": {
      opacity: 1,
    },

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));

const clientFormFragment = graphql`
  fragment CoachClientsFormsListItem_clientForm on ClientForm {
    id
    name
    slug
    totalQuestions
    createdAt(format: "MMM D, YYYY")
  }
`;

export interface CoachClientsFormsListItemProps extends TableRowProps {
  clientForm: CoachClientsFormsListItem_clientForm$key;
}

export function CoachClientsFormsListItem(
  props: CoachClientsFormsListItemProps,
) {
  const navigate = useNavigate();
  const s = useStyles();
  const { className, clientForm: clientFormRef, ...other } = props;
  const clientForm = useFragment(clientFormFragment, clientFormRef);
  const { id, slug, name, totalQuestions, createdAt } = clientForm;
  const url = `/coach/clients/forms/${slug}`;
  const [removeClientForm, removeClientFormInFlight] =
    useRemoveClientFormMutation();
  const [duplicateClientForm, duplicateClientFormInFlight] =
    useDuplicateClientFormMutation();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "moreMenu",
  });

  const handleClick = React.useCallback(() => {
    navigate(url);
  }, [url]);

  const handleMoreClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      menuState.open(event);
    },
    [menuState],
  );

  const handleDuplicateClientForm = React.useCallback(
    () =>
      duplicateClientForm({
        variables: { input: { id } },
        onSuccess: () => menuState.close(),
      }),
    [duplicateClientForm, id, menuState],
  );

  const handleRemoveClientForm = React.useCallback(
    () =>
      removeClientForm({
        variables: { input: { id } },
        onSuccess: () => menuState.close(),
      }),
    [id, menuState, removeClientForm],
  );

  const inFlight = removeClientFormInFlight || duplicateClientFormInFlight;

  return (
    <>
      <TableRow
        className={clsx(s.root, className)}
        onClick={handleClick}
        {...other}
      >
        <TableCell>
          <Box className={s.nameWrapper}>
            <ClientFormIcon />
            {name || defaultClientFormName}
          </Box>
        </TableCell>
        <TableCell>{maybePluralize(totalQuestions, "question")}</TableCell>
        <TableCell>
          <Box className={s.createdAtWrapper}>
            {createdAt}

            <Box className={s.buttons}>
              <MoreMenuButton
                styleVariant="inverse"
                className={clsx(s.more, menuState.isOpen && s.open)}
                {...bindTrigger(menuState)}
                onClick={handleMoreClick}
              />
              <ArrowForwardIcon />
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      {menuState.isOpen && (
        <Menu {...bindMenu(menuState)}>
          <MenuItem disabled={inFlight} onClick={handleDuplicateClientForm}>
            <ListItemIcon children={<DuplicateIcon />} />
            <ListItemText primary="Duplicate" />
          </MenuItem>

          <MenuItem disabled={inFlight} onClick={handleRemoveClientForm}>
            <ListItemIcon children={<ArchiveIcon />} />
            <ListItemText primary="Delete" />
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
