/**
 * @generated SignedSource<<eb57e9933d2b9f1b3f5fc9a8e46ef184>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostsCommentForm_post$data = {
  readonly id: string;
  readonly " $fragmentType": "GroupPostsCommentForm_post";
};
export type GroupPostsCommentForm_post$key = {
  readonly " $data"?: GroupPostsCommentForm_post$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsCommentForm_post">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostsCommentForm_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "GroupPost",
  "abstractKey": null
};

(node as any).hash = "daf78bf170fed571ec22183e58c0f627";

export default node;
