/**
 * @generated SignedSource<<8eb952e2ee1abfb9357084bba6a88d73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type MeasurementType = "BODYFAT" | "BODYWEIGHT" | "CALF" | "CHEST" | "HIP" | "SHOULDER" | "STEP_COUNT" | "THIGH" | "UPPER_ARM" | "WAIST" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientProfileScreen_client$data = {
  readonly autoNutrition: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_autoNutrition">;
  } | null | undefined;
  readonly autoNutritionLastSync: string | null | undefined;
  readonly autoNutritionMacroTarget: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_autoNutritionMacroTarget">;
  } | null | undefined> | null | undefined;
  readonly clientForms: {
    readonly totalCount: number;
  } | null | undefined;
  readonly goals: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ClientGoalsCard_goals">;
  };
  readonly id: string;
  readonly metrics: ReadonlyArray<{
    readonly value: {
      readonly type?: MeasurementType;
    };
  }>;
  readonly nutritionMacroTarget: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionMacroTarget">;
  } | null | undefined> | null | undefined;
  readonly nutritionPortionTarget: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionPortionTarget">;
  } | null | undefined> | null | undefined;
  readonly nutritionTarget: ReadonlyArray<{
    readonly calories: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionTarget">;
  } | null | undefined> | null | undefined;
  readonly terraSdkOptions: {
    readonly devId: string;
    readonly referenceId: string;
    readonly token: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ClientBodyWeightCard_client" | "ClientFormsCard_client" | "ClientMeasurementsCard_client" | "ClientProgressPhotosCard_client" | "ClientStepsCard_client" | "ClientSummary_client">;
  readonly " $fragmentType": "ClientProfileScreen_client";
};
export type ClientProfileScreen_client$key = {
  readonly " $data"?: ClientProfileScreen_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProfileScreen_client">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 999
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ClientProfileScreenRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ClientProfileScreen_client",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "metricType",
          "value": "CHECKIN_ANSWER_MEASUREMENT"
        }
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "type": "CheckinAnswerMeasurementValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "metrics(metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "calories",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutoNutrition",
      "kind": "LinkedField",
      "name": "autoNutrition",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_autoNutrition"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "h:mm A, MMM DD, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "autoNutritionLastSync",
      "storageKey": "autoNutritionLastSync(format:\"h:mm A, MMM DD, YYYY\")"
    },
    {
      "alias": "autoNutritionMacroTarget",
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionMacroTarget",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_autoNutritionMacroTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TerraSdkOptions",
      "kind": "LinkedField",
      "name": "terraSdkOptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "devId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referenceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "token",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionMacroTarget",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionMacroTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionPortionTarget",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionPortionTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 9999
        }
      ],
      "concreteType": "ClientGoalConnection",
      "kind": "LinkedField",
      "name": "goals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientGoalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientGoalsCard_goals"
        }
      ],
      "storageKey": "goals(first:9999)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSummary_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientStepsCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBodyWeightCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientMeasurementsCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientProgressPhotosCard_client"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "ClientFormsCard_client"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ClientFormConnection",
      "kind": "LinkedField",
      "name": "clientForms",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "clientForms(first:999)"
    },
    (v0/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "8c44ec1e2b0a14cfb6d9be48709b6556";

export default node;
