/**
 * @generated SignedSource<<1a88106c923a404d28e59f0de2fda21c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostsEditDialog_post$data = {
  readonly content: string;
  readonly id: string;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "GroupPostsEditDialog_post";
};
export type GroupPostsEditDialog_post$key = {
  readonly " $data"?: GroupPostsEditDialog_post$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsEditDialog_post">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostsEditDialog_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "type": "GroupPost",
  "abstractKey": null
};

(node as any).hash = "b676c26aee1fdb440d4c0070048b4c5d";

export default node;
