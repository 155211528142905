/**
 * @generated SignedSource<<019dfd6bf7b220cb23f4611b70621501>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertBillingAddressInput = {
  city: string;
  clientMutationId?: string | null | undefined;
  country: string;
  password?: string | null | undefined;
  postalCode: string;
  street: string;
};
export type UpsertBillingAddressMutation$variables = {
  input: UpsertBillingAddressInput;
};
export type UpsertBillingAddressMutation$data = {
  readonly upsertBillingAddress: {
    readonly billingAddress: {
      readonly " $fragmentSpreads": FragmentRefs<"ManagePlanCard_billingAddress" | "ProUpgradeCard_billingAddress">;
    };
  } | null | undefined;
};
export type UpsertBillingAddressMutation = {
  response: UpsertBillingAddressMutation$data;
  variables: UpsertBillingAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertBillingAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertBillingAddressPayload",
        "kind": "LinkedField",
        "name": "upsertBillingAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAddress",
            "kind": "LinkedField",
            "name": "billingAddress",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProUpgradeCard_billingAddress"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ManagePlanCard_billingAddress"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertBillingAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertBillingAddressPayload",
        "kind": "LinkedField",
        "name": "upsertBillingAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAddress",
            "kind": "LinkedField",
            "name": "billingAddress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af37982e6d8c7518a7d961e0864e4c51",
    "id": null,
    "metadata": {},
    "name": "UpsertBillingAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertBillingAddressMutation(\n  $input: UpsertBillingAddressInput!\n) {\n  upsertBillingAddress(input: $input) {\n    billingAddress {\n      ...ProUpgradeCard_billingAddress\n      ...ManagePlanCard_billingAddress\n      id\n    }\n  }\n}\n\nfragment ManagePlanCard_billingAddress on BillingAddress {\n  street\n  city\n  postalCode\n  country\n  ...UpdateBillingAddressDialog_billingAddress\n}\n\nfragment ProUpgradeCard_billingAddress on BillingAddress {\n  street\n  city\n  postalCode\n  country\n}\n\nfragment UpdateBillingAddressDialog_billingAddress on BillingAddress {\n  street\n  city\n  postalCode\n  country\n}\n"
  }
};
})();

(node as any).hash = "294e5b58224a8b73840cb14f0a1ff3bb";

export default node;
