import clsx from "clsx";
import React from "react";
import { ListItem, ListItemProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActivityCard, ActivityCardProps } from "./ActivityCard";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ActivityListItemProps
  extends Pick<
    ActivityCardProps,
    "activityRef" | "completionProgress" | "variant"
  > {
  userName?: string;
  clientsScreen?: boolean;
  idProgram?: string;
  className?: string;
}

export function ActivityListItem(props: ActivityListItemProps) {
  const { className, variant, userName, clientsScreen, idProgram, ...other } =
    props;
  const s = useStyles();

  return (
    <ListItem className={clsx(s.root, className)}>
      <ActivityCard
        {...other}
        variant={variant}
        userName={userName}
        clientsScreen={clientsScreen}
        idProgram={idProgram}
      />
    </ListItem>
  );
}
