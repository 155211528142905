/**
 * @generated SignedSource<<e3204cceeaae03b78ee9487e9a9e8d7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientMeasurementScreen_client$data = {
  readonly clientNutritionEntries?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionResults_nutritionEntries">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly metrics?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementChart_metrics" | "ClientMeasurementRows_metrics">;
  }>;
  readonly nutritionTarget: ReadonlyArray<{
    readonly trackingType: NutritionTrackingType;
  } | null | undefined> | null | undefined;
  readonly units: Units;
  readonly username: string;
  readonly " $fragmentType": "ClientMeasurementScreen_client";
};
export type ClientMeasurementScreen_client$key = {
  readonly " $data"?: ClientMeasurementScreen_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementScreen_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeMeasurement"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNutrition"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "measurementType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "period"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ClientMeasurementScreenRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ClientMeasurementScreen_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackingType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "includeNutrition",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "period",
              "variableName": "period"
            }
          ],
          "concreteType": "ClientNutritionEntryConnection",
          "kind": "LinkedField",
          "name": "clientNutritionEntries",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientNutritionEntryEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClientNutritionEntry",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ClientNutritionResults_nutritionEntries"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "includeMeasurement",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "measurementType",
              "variableName": "measurementType"
            },
            {
              "kind": "Literal",
              "name": "metricType",
              "value": "CHECKIN_ANSWER_MEASUREMENT"
            }
          ],
          "concreteType": "ClientMetric",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ClientMeasurementChart_metrics"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ClientMeasurementRows_metrics"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "420217c549b0eee78b326d4e57d9522b";

export default node;
