import clsx from "clsx";
import React from "react";
import { Pagination as MuiPagination } from "@mui/material";
import {
  PaginationProps as MuiPaginationProps,
  PaginationItem,
} from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";

import { useQueryParam } from "../../hooks/useQueryParam";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

export interface PaginationProps extends MuiPaginationProps {
  totalCount: number;
  perPage: number;
  queryKey?: string;
}

export function Pagination(props: PaginationProps) {
  const { className, totalCount, perPage, ...other } = props;
  const queryKey = props.queryKey || "page";
  const [currentPage, setCurrentPage] = useQueryParam(queryKey);
  const s = useStyles();
  const page = currentPage ? parseInt(currentPage) : 1;
  const count = Math.ceil(totalCount / perPage);
  const handlePageChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, page: unknown) => {
      setCurrentPage(String(page));
    },
    [setCurrentPage],
  );

  return count > 1 ? (
    <MuiPagination
      className={clsx(s.root, className)}
      page={page}
      count={count}
      size={"large"}
      onChange={handlePageChange}
      renderItem={(item) => <PaginationItem {...item} />}
      {...other}
    />
  ) : null;
}
