import clsx from "clsx";
import React from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PageSkeletonProps } from "./PageSkeleton";
import { BasicSkeletonCard } from "./BasicSkeletonCard";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
  },

  calendar: {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4),
    },
  },

  card: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export interface HomePageSkeletonProps extends PageSkeletonProps {}

export function HomePageSkeleton(props: HomePageSkeletonProps) {
  const {
    className,
    cardsCount = 3,
    ItemComponent = BasicSkeletonCard,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Container className={clsx(s.root, className)} {...other}>
      {Array.from({ length: cardsCount }).map((_, i) => (
        <ItemComponent key={i} className={s.card} />
      ))}
    </Container>
  );
}
