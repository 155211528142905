import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { PendingInviteItem } from "../item/PendingInviteItem";
import { colorSystem } from "../../theme";

import { PendingInvitesList_invites$key } from "./__generated__/PendingInvitesList_invites.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  item: {
    padding: theme.spacing(3, 0),
  },

  divider: {
    backgroundColor: colorSystem.secondaryGray,
  },

  text: {},
}));

const invitesFragment = graphql`
  fragment PendingInvitesList_invites on InviteConnection {
    edges {
      node {
        id
        ...PendingInviteItem_invite
      }
    }
  }
`;

export interface PendingInvitesListProps extends BoxProps {
  invites: PendingInvitesList_invites$key;
  setTotalCount: (any) => void;
  totalCount: any;
}

export function PendingInvitesList(props: PendingInvitesListProps) {
  const { className, invites: invitesRef, setTotalCount, totalCount } = props;
  const invites = useFragment(invitesFragment, invitesRef);
  const s = useStyles();

  return invites?.edges.length ? (
    <Box className={clsx(s.root, className)}>
      {invites.edges
        .filter(({ node }) => node)
        .map(({ node: invite }, index) => (
          <React.Fragment key={invite.id}>
            <PendingInviteItem
              className={s.item}
              invite={invite}
              totalCount={totalCount}
              setTotalCount={setTotalCount}
            />
            {index !== invites.edges.length - 1 && (
              <Divider className={s.divider} />
            )}
          </React.Fragment>
        ))}
    </Box>
  ) : (
    <Typography className={s.text}>
      There are currently no pending invites.
    </Typography>
  );
}
