/**
 * @generated SignedSource<<52a92d6ceebd861da006d3ba9aa13be6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientMealLoggingRouteQuery$variables = {
  date: string;
};
export type ClientMealLoggingRouteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingScreen_root">;
};
export type ClientMealLoggingRouteQuery = {
  response: ClientMealLoggingRouteQuery$data;
  variables: ClientMealLoggingRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = {
  "kind": "Variable",
  "name": "date",
  "variableName": "date"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientMealLoggingRouteQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ClientMealLoggingScreen_root"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientMealLoggingRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "MealLoggingEntriesConnection",
            "kind": "LinkedField",
            "name": "mealLoggingEntries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityFeedback",
                    "kind": "LinkedField",
                    "name": "feedbacks",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "read",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "format",
                            "value": "fromNow"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": "createdAt(format:\"fromNow\")"
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coachReadAnswers",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MealLoggingEntriesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MealLoggingEntry",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "raw",
                            "value": true
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": "date(raw:true)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answers",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "ClientMealLoggingEntriesList_mealLoggingEntries",
            "kind": "LinkedHandle",
            "name": "mealLoggingEntries"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mealLoggingQuestions",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9802592612d066a3e798125ba2a651e6",
    "id": null,
    "metadata": {},
    "name": "ClientMealLoggingRouteQuery",
    "operationKind": "query",
    "text": "query ClientMealLoggingRouteQuery(\n  $date: String!\n) {\n  ...ClientMealLoggingScreen_root_19b1FI\n}\n\nfragment ActivityFeedbackButton_activity on Activity {\n  ...ActivityFeedbackList_activity\n  feedbacks {\n    id\n  }\n}\n\nfragment ActivityFeedbackList_activity on Activity {\n  feedbacks {\n    id\n    ...ActivityFeedback_activityFeedback\n  }\n  ...FeedbackForm_activity\n  id\n  coachReadAnswers\n}\n\nfragment ActivityFeedback_activityFeedback on ActivityFeedback {\n  id\n  author {\n    id\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ClientMealLoggingEntriesListItem_mealLoggingEntry on MealLoggingEntry {\n  id\n  photoURL\n  description\n  date(raw: true)\n  time\n}\n\nfragment ClientMealLoggingEntriesList_user_19b1FI on User {\n  mealLoggingEntries(first: 10, date: $date) {\n    activity {\n      ...ActivityFeedbackButton_activity\n      id\n    }\n    edges {\n      node {\n        id\n        ...ClientMealLoggingEntriesListItem_mealLoggingEntry\n        ...ClientMealLoggingEntryDialog_mealLoggingEntry\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ClientMealLoggingEntriesList_workspace on Workspace {\n  ...ClientMealLoggingEntryDialog_workspace\n}\n\nfragment ClientMealLoggingEntryDialog_mealLoggingEntry on MealLoggingEntry {\n  id\n  date(raw: true)\n  time\n  description\n  photoURL\n  answers\n}\n\nfragment ClientMealLoggingEntryDialog_workspace on Workspace {\n  mealLoggingQuestions\n}\n\nfragment ClientMealLoggingScreen_root_19b1FI on Root {\n  me {\n    ...ClientMealLoggingEntriesList_user_19b1FI\n    id\n  }\n  workspace {\n    ...ClientMealLoggingEntriesList_workspace\n    id\n  }\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "da724ddafb81284d590e81a6ca917176";

export default node;
