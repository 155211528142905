import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ReactComponent as ClientFormIcon } from "../../icons/TaskListEdit.svg";

import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { ClientFormCardItem_clientForm$key } from "./__generated__/ClientFormCardItem_clientForm.graphql";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.25, 3.25),
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    marginTop: theme.spacing(2.5),
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  icon: {
    marginRight: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  name: {
    fontSize: 18,
    fontWeight: 600,
  },

  status: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

const clientFormFragment = graphql`
  fragment ClientFormCardItem_clientForm on ClientForm {
    slug
    name
    submittedAt
    createdAt(format: "MMM D, YYYY")
  }
`;

export interface ClientFormCardItemProps extends BoxProps {
  clientForm: ClientFormCardItem_clientForm$key;
}

export function ClientFormCardItem(props: ClientFormCardItemProps) {
  const s = useStyles();
  const navigate = useNavigate();
  const { className, clientForm: clientFormRef, ...other } = props;
  const clientForm = useFragment(clientFormFragment, clientFormRef);
  const { name, slug, createdAt, submittedAt } = clientForm;
  const location = useLocation();
  const role = useCurrentUserRole();
  const href = `${
    role === UserRole.COACH ? location.pathname.replace("/overview", "") : ""
  }/forms/${slug}`;

  const handleClick = React.useCallback(() => navigate(href), [href]);

  return (
    <Box className={clsx(s.root, className)} onClick={handleClick} {...other}>
      <ClientFormIcon className={s.icon} />
      <Box>
        <Typography className={s.name}>{name}</Typography>
        <Typography className={s.status}>
          {createdAt} • {submittedAt ? "Completed" : "Incomplete"}
        </Typography>
      </Box>
    </Box>
  );
}
