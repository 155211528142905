import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ClientPortalScreen } from "../../components/client-portal/ClientPortalScreen";
import { ClientPortalMode } from "../../constants";
import { isMobileApp } from "../../utils/mobile";

const ClientPortalRoute = () => {
  const params = useParams();

  const [searchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const email = searchParams.get("email");

  const slug = params.workspace;

  return (
    <ClientPortalScreen
      slug={slug}
      mode={
        mode === "resetPassword" && oobCode && email
          ? ClientPortalMode.CHANGE_PASSWORD
          : isMobileApp
            ? ClientPortalMode.WELCOME
            : ClientPortalMode.LOGIN
      }
    />
  );
};

export default ClientPortalRoute;
