/**
 * @generated SignedSource<<5087953e1f16529da4237f489346340a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Gender = "FEMALE" | "MALE" | "PRIVATE" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
export type UpdateUserInput = {
  admin?: boolean | null | undefined;
  birthday?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  displayName?: string | null | undefined;
  gender?: Gender | null | undefined;
  height?: string | null | undefined;
  id: string;
  location?: string | null | undefined;
  passwordUpdated?: boolean | null | undefined;
  phone?: string | null | undefined;
  phoneCode?: string | null | undefined;
  photoURL?: string | null | undefined;
  themeOptions?: string | null | undefined;
  timeZone?: string | null | undefined;
  topToolbar?: boolean | null | undefined;
  trialExpiryDate?: string | null | undefined;
  units?: Units | null | undefined;
  username?: string | null | undefined;
  weight?: string | null | undefined;
};
export type UserMenuUpdateUserMutation$variables = {
  input: UpdateUserInput;
};
export type UserMenuUpdateUserMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly admin: boolean;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type UserMenuUpdateUserMutation = {
  response: UserMenuUpdateUserMutation$data;
  variables: UserMenuUpdateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "admin",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMenuUpdateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserMenuUpdateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "60e425d3bf5241a0d8c06b531ef002ef",
    "id": null,
    "metadata": {},
    "name": "UserMenuUpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation UserMenuUpdateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      id\n      admin\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e7c8d610d1c5bea12928c706a723565";

export default node;
