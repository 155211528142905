import clsx from "clsx";
import React from "react";
import {
  CardMedia as MuiCardMedia,
  CardMediaProps as MuiCardMediaProps,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { isVideoThumbnail } from "../../utils/component";
import { useThumbnail } from "../../hooks/useThumbnail";
import {
  ThumbnailScale,
  generateThumbnailUrl,
  ThumbnailCover,
} from "../../utils/thumbnail";

const useStyles = makeStyles<Theme, { overlaySize: number }>((theme) => ({
  root: {},

  fit: {
    width: "100%",
    height: "100%",
  },

  video: {
    position: "relative",

    "&::before": {
      display: "block",
      content: "''",
      backgroundColor: "#ffffffd0",
      borderRadius: "50%",
      position: "absolute",
      left: ({ overlaySize }) => `calc(50% - ${overlaySize / 2}px)`,
      top: ({ overlaySize }) => `calc(50% - ${overlaySize / 2}px)`,
      width: ({ overlaySize }) => overlaySize,
      height: ({ overlaySize }) => overlaySize,
    },
    "&::after": {
      display: "block",
      content: "''",
      width: 0,
      height: 0,

      position: "absolute",
      borderTop: ({ overlaySize }) => `${overlaySize / 4}px solid transparent`,
      borderLeft: ({ overlaySize }) => `${overlaySize / 2.7}px solid #00000099`,
      borderBottom: ({ overlaySize }) =>
        `${overlaySize / 4}px solid transparent`,

      left: ({ overlaySize }) => `calc(50% - ${overlaySize / 8}px)`,
      top: ({ overlaySize }) => `calc(50% - ${overlaySize / 4}px)`,
    },
  },
}));

export interface CardMediaProps extends MuiCardMediaProps {
  size?: "normal" | "tiny";
  fit?: boolean;
  scale?: ThumbnailScale;
  cover?: ThumbnailCover;
}

export function CardMedia(props: CardMediaProps) {
  const {
    className,
    image,
    size = "normal",
    fit = true,
    scale,
    cover,
    ...other
  } = props;
  const s = useStyles({ overlaySize: size === "normal" ? 40 : 20 });
  const src = useThumbnail(image);
  const isVideoPreview = isVideoThumbnail(src);
  const thumbnail =
    scale || cover ? generateThumbnailUrl({ src, scale, cover }) : src;

  return (
    <MuiCardMedia
      className={clsx(s.root, className, {
        [s.fit]: fit,
        [s.video]: isVideoPreview,
      })}
      image={thumbnail}
      {...other}
    />
  );
}
