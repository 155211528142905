import { AssetType } from "../constants";
import {
  MediaElementType,
  ElementType,
} from "../components/editor/types/elements";
import { Accept } from "react-dropzone";

export const REGEX =
  /^(https:\/\/.+\.stridist\.com\/programs\/).+(\.[a-z0-9]{1,5})$/;

export function uploadFile(
  uploadUrl: string,
  file: any,
  onProgress?: (event: ProgressEvent<EventTarget>) => void,
) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("PUT", uploadUrl, true);
    req.onload = (event) => {
      resolve(file);
    };
    req.onerror = (err) => {
      reject(err);
    };

    if (onProgress) {
      req.upload.onprogress = onProgress;
    }

    req.send(file);
  });
}

export const getImageUrl = (file: File) => {
  const blob = new Blob([file], { type: file.type });
  const imageUrl = URL.createObjectURL(blob);
  return imageUrl;
};

export function humanReadableFileSize(size: number, precision = 0) {
  const units = ["B", "kB", "MB", "GB"];
  const base = 1024;
  const power = Math.min(
    Math.floor(Math.log(size) / Math.log(base)),
    units.length - 1,
  );
  const unit = units[power];
  const formatted = (size / Math.pow(base, power)).toFixed(precision);

  return `${formatted} ${unit}`;
}

export function nameFromUrl(url: string) {
  return url.replace(/\?.*/, "").replace(/^.*\//, "");
}

export function getAssetType(elementType: MediaElementType): AssetType {
  switch (elementType) {
    case ElementType.IMAGE:
      return AssetType.PROGRAM_IMAGE;
    case ElementType.AUDIO:
      return AssetType.PROGRAM_AUDIO;
    case ElementType.VIDEO:
      return AssetType.PROGRAM_VIDEO;
    case ElementType.FILE:
      return AssetType.PROGRAM_FILE;
  }
}

export function getMimeTypes(elementType: MediaElementType): Accept {
  switch (elementType) {
    case ElementType.IMAGE:
      return { "image/jpeg": [], "image/png": [], "image/gif": [] };
    case ElementType.AUDIO:
      return {
        "audio/mpeg": [],
        "audio/mpeg3": [],
        "audio/mp3": [],
        "audio/*": [],
      };
    case ElementType.VIDEO:
      return {
        "video/mpeg": [],
        "video/mpeg4": [],
        "video/mp4": [],
        "video/*": [],
      };
    case ElementType.FILE:
      return {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [],
        "application/vnd.ms-powerpoint": [],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          [],
        "text/plain": [],
        "application/pdf": [],
      };
    default:
      return null;
  }
}
export const getFileAssetType = ({ type }: File) => {
  if (/^image\//.test(type)) {
    return AssetType.PROGRAM_IMAGE;
  }

  if (/^application\//.test(type)) {
    return AssetType.PROGRAM_FILE;
  }

  return false;
};

export const isImageFile = (mimeType: string) => /^image\//.test(mimeType);
