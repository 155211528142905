import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";
import { graphql, useFragment } from "react-relay";

import { MessageEditor } from "../new-editor/MessageEditor";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { GroupPostsCommentForm_post$key } from "./__generated__/GroupPostsCommentForm_post.graphql";
import { GroupPostsCommentForm_comment$key } from "./__generated__/GroupPostsCommentForm_comment.graphql";
import { useUpsertGroupPostCommentMutation } from "./mutations/UpsertGroupPostComment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.selected.main,
    padding: theme.spacing(3),
  },

  form: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },

  editor: {
    flexGrow: 1,
  },

  helperText: {
    fontSize: 12,
    fontWeight: "normal",
    color: theme.palette.secondary.main,
    opacity: 0.6,
    margin: theme.spacing(0.5, 2),
  },

  formButtons: {
    right: theme.spacing(1),
    top: theme.spacing(1.5),
  },

  submit: {
    height: theme.spacing(6),
    width: "100%",
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginTop: 0,
      marginLeft: theme.spacing(2),
    },
  },
}));

const postFragment = graphql`
  fragment GroupPostsCommentForm_post on GroupPost {
    id
  }
`;

const commentFragment = graphql`
  fragment GroupPostsCommentForm_comment on GroupPostComment {
    id
    rootParentCommentId
  }
`;

export interface GroupPostsCommentFormProps extends BoxProps {
  postRef?: GroupPostsCommentForm_post$key;
  commentRef?: GroupPostsCommentForm_comment$key;
  helperText?: string;
  onSubmit?: () => void;
  focusOnInit?: boolean;
  placeholder?: string;
}

export function GroupPostsCommentForm(props: GroupPostsCommentFormProps) {
  const {
    className,
    postRef,
    commentRef,
    placeholder = "Post your thoughts...",
    helperText = "Remember to be kind when posting comments to the community.",
    onSubmit,
    focusOnInit = false,
    ...other
  } = props;
  const post = useFragment(postFragment, postRef);
  const comment = useFragment(commentFragment, commentRef);
  const onError = useGenericErrorHandler();
  const s = useStyles();
  const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const [createComment, createCommentInFlight] =
    useUpsertGroupPostCommentMutation(
      post?.id || comment?.rootParentCommentId || comment?.id,
    );

  React.useEffect(() => {
    if (focusOnInit && ref?.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!post && !comment) {
      throw new Error(
        "GroupPostsCommentForm: Either post or comment have to be referenced via a prop.",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessageSubmit = React.useCallback(
    (fragment?: Node[], callback?: () => void) =>
      createComment({
        variables: {
          input: {
            postId: post?.id,
            parentCommentId: comment?.id,
            content: JSON.stringify(fragment),
          },
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            callback();

            if (onSubmit) {
              onSubmit();
            }
          }
        },
        onError,
      }),
    [comment, createComment, onError, post, onSubmit],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.form}>
        <MessageEditor
          className={s.editor}
          placeholder={placeholder}
          fullWidth
          inputRef={ref}
          backupKeyType="group-post-comment"
          backupKeyId={post?.id || comment?.id}
          onMessageSubmit={handleMessageSubmit}
          disabled={createCommentInFlight}
        />
      </Box>

      {helperText && (
        <Typography className={s.helperText} variant="body2">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
