import React from "react";
import { useRelayEnvironment } from "react-relay/hooks";

import { CurrentUserProvider_user$data } from "../components/routes/__generated__/CurrentUserProvider_user.graphql";
import { UserRole } from "../constants";
import { getFirstName, getLastName } from "../utils/user";
import { nativeIntercom } from "../utils/intercom";
import { isMobileApp } from "../utils/mobile";
import { getSSRAnalytics } from "../utils/ssr-analytics";

import { useCurrentUser } from "./useCurrentUser";

export interface AnalyticsJS {
  identify(userId: string, traits: Object, callback?: () => void): void;
  track(event: string, properties?: Object, callback?: () => void): void;
  page(name?: string, properties?: Object, callback?: () => void): void;
  device?: (userId: string, deviceToken: string) => void;
}

const TRAITS_KEY = "traits";
let identified = false;

const identify = (
  analytics: AnalyticsJS,
  user: CurrentUserProvider_user$data,
) => {
  const {
    id,
    displayName,
    username,
    email,
    rawEmail,
    age,
    role,
    photoURL: avatar,
    phone,
    timeZone,
    gender,
    birthday,
    location,
    units,
    height,
    weight,
    createdAt,
    coach,
    clientsCount,
    programsCount,
    trialExpiryDateTimestamp,
    subscription,
    isImpersonating,
    isSample,
    accounts,
  } = user;

  const traits = {
    displayName,
    username,
    email: rawEmail || email,
    avatar,
    role,
    firstName: getFirstName(user),
    lastName: getLastName(user),
    phone,
    timeZone,
    createdAt,
    trialExpiryDate: parseInt(trialExpiryDateTimestamp),
    plan: subscription ? "premium" : "trial",

    ...(role === UserRole.CLIENT &&
      !isSample && {
        age,
        gender,
        birthday,
        location,
        units,
        height,
        weight,
        coachName: coach?.displayName,
        coachAvatar: coach?.photoURL,
        coachEmail: coach?.email,
      }),

    ...(role === UserRole.COACH && {
      clientsCount,
      programsCount,
    }),
  };

  if (isSample) {
    const coachAccount = accounts.find(({ isSample }) => !isSample);

    if (coachAccount) {
      Object.assign(traits, {
        displayName: coachAccount.displayName,
        username: coachAccount.username,
        email: coachAccount.email,
        avatar: coachAccount.photoURL,
        role: coachAccount.role,
        firstName: getFirstName(user),
        lastName: getLastName(user),
        phone: coachAccount.phone,
        timeZone: coachAccount.timeZone,
        createdAt: coachAccount.createdAt,
      });
    }
  }

  const stringifiedTraits = JSON.stringify(traits);

  if (
    !isImpersonating &&
    (!identified || localStorage.getItem(TRAITS_KEY) !== stringifiedTraits)
  ) {
    identified = true;
    localStorage.setItem(TRAITS_KEY, stringifiedTraits);
    analytics.identify(id, traits);
  }

  nativeIntercom.registerIdentifiedUser(user.id, user.rawEmail);
};

const deepEqual = (left, right) =>
  JSON.stringify(left) === JSON.stringify(right);

export interface PageTrackInfo {
  name?: string;
  properties?: Object;
}

// Intercom doesn't always respond to "hide_default_launcher" update call
window["intercomSettings"] = Object.assign(window["intercomSettings"] || {}, {
  hide_default_launcher: true,
});

export function useAnalytics() {
  const user = useCurrentUser();
  const relay = useRelayEnvironment();
  const [previousPage, setPreviousPage] = React.useState<PageTrackInfo>();
  const isImpersonating = user ? user.isImpersonating : false;

  const analytics = React.useMemo(() => {
    if (isMobileApp) {
      Object.assign(window, {
        analytics: getSSRAnalytics(relay, user?.id),
      });
    }

    return window["analytics"] ? (window["analytics"] as AnalyticsJS) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (analytics && user) {
    identify(analytics, user);
  }

  const trackPage: AnalyticsJS["page"] = React.useCallback(
    (name?: string, properties?: Object) => {
      const info = {
        name,
        properties,
      };

      if (analytics && !isImpersonating && !deepEqual(previousPage, info)) {
        analytics.page(name, properties);
        setPreviousPage(info);
      }
    },
    [analytics, isImpersonating, previousPage],
  );

  const trackEvent: AnalyticsJS["track"] = React.useCallback(
    (...args) => {
      if (analytics && !isImpersonating) {
        analytics.track(...args);
      }
    },
    [analytics, isImpersonating],
  );

  const trackDevice: AnalyticsJS["device"] = React.useCallback(
    (...args) => {
      if (analytics && !isImpersonating && analytics.device) {
        analytics.device(...args);
      }
    },
    [analytics, isImpersonating],
  );

  return [trackEvent, trackPage, trackDevice] as const;
}
