/**
 * @generated SignedSource<<dd7e542ed8ba664e511f9d99b2640b8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EndWorkout_activity$data = {
  readonly component: {
    readonly locked: boolean;
  } | null | undefined;
  readonly date: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "EndWorkout_activity";
};
export type EndWorkout_activity$key = {
  readonly " $data"?: EndWorkout_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"EndWorkout_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EndWorkout_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locked",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "291e7b5c0c1cafb51e8cb6015aa3d417";

export default node;
