import React from "react";
import { FixedSizeList as List, ListOnScrollProps } from "react-window";
import makeStyles from "@mui/styles/makeStyles";

import { useDebounce } from "../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  listItem: {
    textAlign: "center",
  },
}));

export interface IntrinsicInfiniteListProps {
  selectedIndex?: number;
  itemCount: number;
  itemsPerView?: number;
  renderItem: (index: number) => React.ReactNode;
  onScroll?: (index: number) => void;
  focusDelay?: number;
  disableScrollbar?: boolean;
}
export interface InfiniteListProps extends IntrinsicInfiniteListProps {
  width: number;
  height: number;
}

export function InfiniteList(props: InfiniteListProps) {
  const {
    width,
    height,
    itemCount,
    onScroll,
    selectedIndex = 0,
    itemsPerView = 1,
    focusDelay = 1000,
    renderItem,
    disableScrollbar = true,
  } = props;
  const s = useStyles();
  const listRef = React.createRef<List>();
  const offsetInner = Math.floor(itemsPerView / 2);
  const offsetOuter = Math.ceil(itemCount / 2);
  const currentScrollIndex = offsetOuter + selectedIndex;
  const initialScrollIndex = currentScrollIndex;
  const [scrolling, setScrolling] = React.useState(false);
  const itemSize = Math.floor(width / itemsPerView);
  const initialScrollOffset = (initialScrollIndex - offsetInner) * itemSize;
  const debouncedWidth = useDebounce(width, 50);

  const scrollToSelected = React.useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(currentScrollIndex, "center");
    }
  }, [listRef, currentScrollIndex]);

  React.useEffect(() => {
    if (!scrolling) {
      scrollToSelected();
    }
  }, [scrollToSelected, scrolling]);

  React.useEffect(() => {
    const timer = setTimeout(() => setScrolling(false), focusDelay);
    return () => clearTimeout(timer);
  }, [selectedIndex, focusDelay]);

  const handleScrollIndex = React.useCallback(
    (index: number) => {
      if (onScroll && index !== selectedIndex) {
        setScrolling(true);
        onScroll(index);
      }
    },
    [onScroll, selectedIndex],
  );

  const handleScroll = React.useCallback(
    (props: ListOnScrollProps) => {
      const { scrollOffset } = props;
      const newIndex =
        Math.round(scrollOffset / (itemSize * itemsPerView)) - offsetOuter;

      handleScrollIndex(newIndex);
    },
    [handleScrollIndex, itemSize, itemsPerView, offsetOuter],
  );

  React.useEffect(scrollToSelected, [scrollToSelected, debouncedWidth]);

  const scrollbarOffset = disableScrollbar ? 17 : 0;

  return (
    <List
      ref={listRef}
      width={width}
      height={height + scrollbarOffset}
      itemCount={itemCount}
      itemSize={itemSize}
      layout="horizontal"
      initialScrollOffset={initialScrollOffset}
      onScroll={handleScroll}
    >
      {({ index, style }) => (
        <div key={index} className={s.listItem} style={style}>
          {renderItem(index - offsetOuter)}
        </div>
      )}
    </List>
  );
}
