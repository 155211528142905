import clsx from "clsx";
import React from "react";
import { List, ListProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { uniq } from "lodash";

import { ComponentType } from "../../constants";

import { ActivityListItemProps, ActivityListItem } from "./ActivityListItem";
import { ActivityCompletionProgress } from "./ActivityCompletion";
import {
  ActivityList_activities$key,
  ActivityList_activities$data,
} from "./__generated__/ActivityList_activities.graphql";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  activity: {
    padding: 0,
    marginBottom: 24,
  },
}));

const activitiesFragment = graphql`
  fragment ActivityList_activities on Activity
  @relay(plural: true)
  @argumentDefinitions(compact: { type: "Boolean!", defaultValue: false }) {
    ...ActivityCard_activity @arguments(compact: $compact)
    id
    week
    date(raw: true)
    completed
    component {
      id
      type
    }
  }
`;

interface ActivitiesByDate {
  [key: string]: ActivityList_activities$data;
}

export interface ActivityListProps extends ListProps {
  activitiesRef: ActivityList_activities$key;
  variant: ActivityListItemProps["variant"];
  userName?: string;
  clientsScreen?: boolean;
  idProgram?: string;
  week?: any;
}

function getCompletionProgress(component, activities) {
  const siblings = activities.filter((it) => it.component.id === component.id);
  const completionProgress = [
    ComponentType.HABIT,
    ComponentType.CHECKIN,
  ].includes(component.type as any)
    ? siblings.map(
        ({ date, completed }): ActivityCompletionProgress => ({
          date,
          completed,
        }),
      )
    : undefined;

  return completionProgress;
}

export function ActivityList(props: ActivityListProps) {
  const {
    className,
    activitiesRef,
    variant,
    userName,
    clientsScreen,
    idProgram,
    week,
    ...other
  } = props;
  const activities = useFragment(activitiesFragment, activitiesRef);
  const s = useStyles();

  // TODO: Optimise calculation of dates and activitesByDate
  const dates = React.useMemo(
    () => uniq(activities.map(({ date }) => date)),
    [activities],
  );

  const activitiesByDate = React.useMemo(() => {
    const result: ActivitiesByDate = {};
    let seen = [];

    dates.forEach((date: any) => {
      const filtered = activities.filter(
        (activity) =>
          activity.date === date && !seen.includes(activity.component.id),
      );

      result[date] = filtered;
      seen = seen.concat(filtered.map((activity) => activity.component.id));
    });

    return result;
  }, [dates, activities]);

  const sortingActivities = React.useMemo(() => {
    return activities
      .map((el) => el)
      .sort(
        (a, b) =>
          week?.node?.positions.indexOf(a.component.id) -
          week?.node?.positions.indexOf(b.component.id),
      );
  }, [week, activities]);

  return activities.length ? (
    <List className={clsx(s.root, className)} {...other}>
      {week ? (
        <>
          {activities.length ? (
            <React.Fragment>
              {sortingActivities.map((activity) => {
                const { component } = activity;
                const completionProgress =
                  ["summary", "compact"].includes(variant) &&
                  getCompletionProgress(component, activities);

                return (
                  <ActivityListItem
                    className={s.activity}
                    key={activity.id}
                    activityRef={activity}
                    completionProgress={completionProgress}
                    variant={variant}
                    userName={userName}
                    clientsScreen={clientsScreen}
                    idProgram={idProgram}
                  />
                );
              })}
            </React.Fragment>
          ) : null}
        </>
      ) : (
        <>
          {dates.map((date: any) => {
            return activitiesByDate[date].length ? (
              <React.Fragment key={date}>
                {activitiesByDate[date].map((activity) => {
                  const { component } = activity;
                  const completionProgress =
                    ["summary", "compact"].includes(variant) &&
                    getCompletionProgress(component, activities);

                  return (
                    <ActivityListItem
                      className={s.activity}
                      key={activity.id}
                      activityRef={activity}
                      completionProgress={completionProgress}
                      variant={variant}
                      userName={userName}
                      clientsScreen={clientsScreen}
                      idProgram={idProgram}
                    />
                  );
                })}
              </React.Fragment>
            ) : null;
          })}
        </>
      )}
    </List>
  ) : (
    <Typography>No lessons this week</Typography>
  );
}
