/**
 * @generated SignedSource<<fb300b7db7c0155c10a35b5c25ce1746>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseAutocompleteRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  query?: string | null | undefined;
};
export type ExerciseAutocompleteRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseAutocomplete_rootRef">;
};
export type ExerciseAutocompleteRefetchQuery = {
  response: ExerciseAutocompleteRefetchQuery$data;
  variables: ExerciseAutocompleteRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 0,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "assetType",
    "value": [
      "WORKOUT_EXERCISE"
    ]
  },
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExerciseAutocompleteRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ExerciseAutocomplete_rootRef"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExerciseAutocompleteRefetchQuery",
    "selections": [
      {
        "alias": "suggestedExercises",
        "args": (v4/*: any*/),
        "concreteType": "CustomAssetsConnection",
        "kind": "LinkedField",
        "name": "custom_assets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomAssetsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomAsset",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "suggestedExercises",
        "args": (v4/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ExerciseAutocomplete_suggestedExercises",
        "kind": "LinkedHandle",
        "name": "custom_assets"
      }
    ]
  },
  "params": {
    "cacheID": "21289737c97658d027dfa56695e7b996",
    "id": null,
    "metadata": {},
    "name": "ExerciseAutocompleteRefetchQuery",
    "operationKind": "query",
    "text": "query ExerciseAutocompleteRefetchQuery(\n  $after: String = null\n  $first: Int = 0\n  $query: String\n) {\n  ...ExerciseAutocomplete_rootRef_1Tyy4D\n}\n\nfragment ExerciseAutocomplete_rootRef_1Tyy4D on Root {\n  suggestedExercises: custom_assets(first: $first, after: $after, query: $query, assetType: [WORKOUT_EXERCISE]) {\n    edges {\n      node {\n        id\n        name\n        content\n        userId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b0f233424736fe91a1fad1471c0d3e6";

export default node;
