import React from "react";
import { Box, BoxProps } from "@mui/material";
import { EditorFieldEditor } from "../new-editor/EditFieldEditor";

export function EditorValue(props: any) {
  const { className, defaultValue = null, onClick, value, ...other } = props;

  return (
    <Box className={className} onClick={onClick}>
      {value ? (
        <EditorFieldEditor value={value} readOnly {...other} />
      ) : (
        defaultValue
      )}
    </Box>
  );
}
