/**
 * @generated SignedSource<<4dcc3f6895b9474648f2e1dcfe9e56c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramEnrollmentStartDateViewDialog_enrollments$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly client: {
        readonly displayName: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"SelectableClient_client">;
      };
      readonly id: string;
      readonly startDate: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ProgramEnrollmentStartDateViewDialog_enrollments";
};
export type ProgramEnrollmentStartDateViewDialog_enrollments$key = {
  readonly " $data"?: ProgramEnrollmentStartDateViewDialog_enrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramEnrollmentStartDateViewDialog_enrollments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramEnrollmentStartDateViewDialog_enrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EnrollmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Enrollment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "raw",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": "startDate(raw:true)"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SelectableClient_client"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EnrollmentConnection",
  "abstractKey": null
};

(node as any).hash = "8c2903f13c2496457d2540fc112b2fc4";

export default node;
