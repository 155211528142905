/**
 * @generated SignedSource<<9c017a1d93cda0f2b021bae21a5d6442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserMenu_user$data = {
  readonly admin: boolean;
  readonly id: string;
  readonly role: UserRole;
  readonly " $fragmentType": "UserMenu_user";
};
export type UserMenu_user$key = {
  readonly " $data"?: UserMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenu_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "admin",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0e16a48936948f8cdc9323f931faf63e";

export default node;
