import clsx from "clsx";
import React from "react";
import {
  CircularProgress,
  CircularProgressProps,
  Box,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  spinner: {},
  label: {
    marginLeft: theme.spacing(1.5),
    fontSize: 16,
    fontWeight: 500,
  },
}));

export interface LabeledSpinnerProps extends CircularProgressProps {
  label?: React.ReactNode;
}

export function LabeledSpinner(props: LabeledSpinnerProps) {
  const { className, label, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)}>
      <CircularProgress className={s.spinner} {...other} />
      {label && <Typography className={s.label}>{label}</Typography>}
    </Box>
  );
}
