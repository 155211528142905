import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as GoogleLogo } from "../../icons/google-colour.svg";
import { AuthButtonProps } from "../../utils/auth";
import { useExternalScript } from "../../hooks/useExternalScript";
import { useGapiAuth2ClientId } from "../../hooks/useGapiAuth2ClientId";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { isMobileApp } from "../../utils/mobile";
import {
  SOMETHING_WENT_WRONG,
  GoogleAuthScriptSrc,
  AuthProvider,
} from "../../constants";
import { nativeGoogleAuthSignIn } from "../../utils/device";
import { ErrorWithCode } from "../../utils/errors";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: "gray",
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(3),
    backgroundColor: "white",
    padding: theme.spacing(2.25),

    "&:hover": {
      backgroundColor: "white",
    },
  },

  logo: {
    marginRight: theme.spacing(2),
    height: "20px",
  },
}));

export interface GoogleButtonProps extends ButtonProps, AuthButtonProps {
  disabled?: boolean;
}

export function GoogleButton(props: GoogleButtonProps) {
  const { className, children, onSuccess, onFailure, disabled, ...other } =
    props;
  const s = useStyles();
  const onError = useGenericErrorHandler();
  const gapiAuth2ClientId = useGapiAuth2ClientId();
  const googleAuth = React.useRef<gapi.auth2.GoogleAuth>();

  const handleAuthInit = React.useCallback(() => {
    const gapi = window["gapi"];

    if (gapi) {
      gapi.load("auth2", () => {
        googleAuth.current = gapi.auth2.init({
          client_id: gapiAuth2ClientId,
        });
      });
      return true;
    } else {
      return false;
    }
  }, [gapiAuth2ClientId]);

  const loaded = useExternalScript(
    GoogleAuthScriptSrc,
    handleAuthInit,
    !isMobileApp,
  );

  const handleSuccess = React.useCallback(
    (response) => {
      if (onSuccess) {
        const user = response as gapi.auth2.GoogleUser;
        const { id_token } = user.getAuthResponse();
        const profile = user.getBasicProfile();
        const email = profile.getEmail();
        const firstName = profile.getGivenName();
        const lastName = profile.getFamilyName();
        const photoURL = profile.getImageUrl();

        const displayName = [firstName, lastName].filter(Boolean).join(" ");

        onSuccess({
          provider: AuthProvider.GOOGLE,
          id_token,
          displayName,
          photoURL,
          email,
        });
      }
    },
    [onSuccess],
  );

  const handleFailure = React.useCallback(
    (error) => {
      if (error.error === "popup_closed_by_user") {
        onError(
          new ErrorWithCode(
            "auth/" + error.error,
            "Authorization popup closed by user",
          ),
        );
        return;
      }

      if (error.error === "popup_blocked_by_browser") {
        onError(
          new ErrorWithCode(
            "auth/" + error.error,
            "Authorization popup blocked by browser",
          ),
        );
        return;
      }

      if (onFailure) {
        onFailure(error);
      } else {
        console.error("google sign in failure", error);
        onError(SOMETHING_WENT_WRONG);
      }
    },
    [onError, onFailure],
  );

  const handleClick = React.useCallback(() => {
    if (loaded) {
      const signIn = isMobileApp
        ? nativeGoogleAuthSignIn
        : googleAuth.current?.signIn;

      if (signIn) {
        signIn().then(handleSuccess).catch(handleFailure);
      }
    }
  }, [handleFailure, handleSuccess, loaded]);

  return (
    <Button
      className={clsx(s.root, className)}
      variant="contained"
      fullWidth
      onClick={handleClick}
      disabled={!loaded || disabled}
      {...other}
    >
      <GoogleLogo className={s.logo} /> {children}
    </Button>
  );
}
