import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { UnsplashContext } from "../../../../hooks/useUnsplash";
import { CoachClientsFormScreen } from "../../../../components/coach-client-forms/CoachClientsFormScreen";
import { useParams } from "react-router-dom";
import { CoachClientsFormRouteQuery } from "./__generated__/CoachClientsFormRouteQuery.graphql";

export function CoachClientsFormRoute() {
  const { slug } = useParams();

  const props = useLazyLoadQuery<CoachClientsFormRouteQuery>(
    graphql`
      query CoachClientsFormRouteQuery($slug: String!) {
        ...UnsplashImages_rootRef
        ...CoachClientsFormScreen_root @arguments(slug: $slug)
        clientForm(slug: $slug) {
          id
        }
      }
    `,
    {
      slug: slug,
    },
  );
  return props.clientForm ? (
    <UnsplashContext.Provider value={props}>
      <CoachClientsFormScreen root={props} />
    </UnsplashContext.Provider>
  ) : null;
}
