import React from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as Add } from "../../icons/AddSquare.svg";
import { RowType } from "../../constants";
import {
  DEFAULT_NUMBER_OF_EXERCISE_SETS,
  DEFAULT_VALUE_OF_EXERCISE_REPS,
  createEmptyExercise,
} from "../workout/utils";
import { generateId } from "../new-editor/utils/nodeUtil";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    "& svg": {
      width: 16,
      height: 16,
      color: "black",
    },

    "& .MuiListItem-root:hover": {
      backgroundColor: theme.palette.quote,
    },

    "& .MuiListItemIcon-root": {
      margin: theme.spacing(0, -1, 0, 1),
    },
    "& .MuiListItemText-primary": {
      fontWeight: 500,
      fontSize: "1rem",
    },
  },
  paper: {
    minWidth: theme.spacing(35),
    borderRadius: theme.spacing(1.5),
  },
}));

interface IWeekWorkoutMenuProps extends MenuProps {
  type: RowType.EXERCISE | RowType.WORKOUT_SECTION;
  cellData: any;
  saveCurrentComponentContent: (content: any) => void;
  handleEditExerciseClick: () => void;
}

const WeekWorkoutMenu = (props: IWeekWorkoutMenuProps) => {
  const {
    type,
    cellData,
    saveCurrentComponentContent,
    handleEditExerciseClick,
    ...other
  } = props;

  const s = useStyles();

  const componentContent = cellData?.componentContent;
  const workout = cellData?.workoutSectionData;
  const workoutId = workout?.id;
  const exerciseId = cellData?.exerciseData?.id;
  const exercises = cellData?.exercises;

  const handleDuplicateClick = () => {
    switch (type) {
      case RowType.WORKOUT_SECTION: {
        const newDuplicatedWorkout = {
          ...workout,
          id: generateId(),
          workout: {
            ...workout.workout,
            exercises: workout.workout.exercises.map((exercise) => {
              const newId = generateId();
              return {
                ...exercise,
                id: newId,
                superset: newId,
              };
            }),
          },
        };

        const index = componentContent.findIndex(
          (element) => element.id === workoutId,
        );

        if (index !== -1) {
          componentContent.splice(index + 1, 0, newDuplicatedWorkout);
        }

        saveCurrentComponentContent(componentContent);
        return;
      }
      case RowType.EXERCISE: {
        const newExerciseId = generateId();
        const newDuplicatedExercise = {
          ...workout.workout.exercises.find(
            (exercise) => exercise.id === exerciseId,
          ),
          id: newExerciseId,
          superset: newExerciseId,
        };

        const index = workout.workout.exercises.findIndex(
          (exercise) => exercise.id === exerciseId,
        );

        if (index !== -1) {
          const updatedExercises = [
            ...workout.workout.exercises.slice(0, index + 1),
            newDuplicatedExercise,
            ...workout.workout.exercises.slice(index + 1),
          ];

          const updatedWorkout = {
            ...workout,
            workout: {
              ...workout.workout,
              exercises: updatedExercises,
            },
          };

          const workoutIndex = componentContent.findIndex(
            (element) => element.id === workoutId,
          );

          if (workoutIndex !== -1) {
            componentContent.splice(workoutIndex, 1, updatedWorkout);
            saveCurrentComponentContent(componentContent);
          }
        }
        return;
      }

      default:
        break;
    }
  };

  const handleDeleteClick = () => {
    switch (type) {
      case RowType.WORKOUT_SECTION: {
        const resultComponentContent = componentContent.filter(
          (element) => element.id !== workoutId,
        );
        saveCurrentComponentContent(resultComponentContent);
        return;
      }
      case RowType.EXERCISE: {
        const resultExercisesArr = exercises.filter((e) => e.id !== exerciseId);
        const resultComponentContent = componentContent.map((element) => {
          if (element.id === workoutId) {
            return {
              ...element,
              workout: {
                ...element.workout,
                exercises: resultExercisesArr,
              },
            };
          }
          return element;
        });
        saveCurrentComponentContent(resultComponentContent);
        return;
      }

      default:
        break;
    }
  };

  const handleAddExerciseClick = () => {
    const resultComponentContent = componentContent.map((element) => {
      if (element.id === workoutId) {
        return {
          ...element,
          workout: {
            ...element.workout,
            exercises: [
              ...element.workout.exercises,
              createEmptyExercise(
                DEFAULT_NUMBER_OF_EXERCISE_SETS,
                DEFAULT_VALUE_OF_EXERCISE_REPS,
              ),
            ],
          },
        };
      }
      return element;
    });
    saveCurrentComponentContent(resultComponentContent);
  };
  return (
    <Menu
      className={s.root}
      classes={{ paper: s.paper }}
      id="basic-menu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      variant="menu"
      {...other}
    >
      <MenuItem onClick={handleDuplicateClick}>
        <ListItemIcon children={<DuplicateIcon />} />
        <ListItemText primary="Duplicate" />
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon children={<BinIcon />} />
        <ListItemText primary="Delete" />
      </MenuItem>
      {type === RowType.WORKOUT_SECTION && (
        <MenuItem onClick={handleAddExerciseClick}>
          <ListItemIcon children={<Add />} />
          <ListItemText primary="Add exercise" />
        </MenuItem>
      )}
      {type === RowType.EXERCISE && (
        <MenuItem onClick={handleEditExerciseClick}>
          <ListItemIcon
            children={<MenuOpenRoundedIcon style={{ width: 18, height: 18 }} />}
          />
          <ListItemText primary="Edit details" />
        </MenuItem>
      )}
    </Menu>
  );
};

export default WeekWorkoutMenu;
