import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { ClientContext } from "../../../../hooks/useClient";
import { ClientContext as AlternativeClientContext } from "../../../../contexts/ClientContext";
import ClientDetails, {
  ClientDetailsDialogMode,
} from "../../../../components/coach-clients/ClientDetails";
import { CoachClientGenericQuery } from "./__generated__/CoachClientGenericQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

function CoachClientGeneric({
  dialogMode,
}: {
  dialogMode?: ClientDetailsDialogMode;
}) {
  const { username } = useParams();

  const props = useLazyLoadQuery<CoachClientGenericQuery>(
    graphql`
      query CoachClientGenericQuery($username: String!) {
        tags {
          edges {
            node {
              id
              title
            }
          }
        }
        client: user(username: $username) {
          id
          username
          email
          displayName

          ...ClientDetails_client
        }
      }
    `,
    { username: username },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  const { client, tags } = props;

  const context = useContext(AlternativeClientContext);
  context.setCoachClientQuery(props);

  return (
    client && (
      <>
        <ClientContext.Provider value={client}>
          <ClientDetails dialog={dialogMode} tags={tags.edges as any} />
        </ClientContext.Provider>
        <TrackInfoTool
          trackInfo={{
            name:
              dialogMode === "progress"
                ? "Coach - Client Progress Photos"
                : dialogMode === "settings"
                  ? "Coach - Client Settings"
                  : dialogMode === "stats"
                    ? "Coach - Client Stats"
                    : "Coach - Client Overview",
            properties: {
              clientUsername: client.username,
            },
          }}
        />
      </>
    )
  );
}

export const CoachClientOverviewRoute = () => {
  return <CoachClientGeneric />;
};

export const CoachClientSettingsRoute = () => {
  return <CoachClientGeneric dialogMode={ClientDetailsDialogMode.SETTINGS} />;
};

export const CoachClientProgressRoute = () => {
  return <CoachClientGeneric dialogMode={ClientDetailsDialogMode.PROGRESS} />;
};
