/**
 * @generated SignedSource<<4fa5e8f9728f3fa22bbbfe77e8d427da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateThreadInput = {
  archived?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  id: string;
  read?: boolean | null | undefined;
};
export type UpdateThreadMutation$variables = {
  input: UpdateThreadInput;
};
export type UpdateThreadMutation$data = {
  readonly updateThread: {
    readonly me: {
      readonly unreadMessagesCounter: number;
    } | null | undefined;
    readonly thread: {
      readonly " $fragmentSpreads": FragmentRefs<"ThreadCard_thread">;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateThreadMutation = {
  response: UpdateThreadMutation$data;
  variables: UpdateThreadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadMessagesCounter",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateThreadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateThreadPayload",
        "kind": "LinkedField",
        "name": "updateThread",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Thread",
            "kind": "LinkedField",
            "name": "thread",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ThreadCard_thread"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateThreadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateThreadPayload",
        "kind": "LinkedField",
        "name": "updateThread",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Thread",
            "kind": "LinkedField",
            "name": "thread",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": "formattedDate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "D MMM"
                  }
                ],
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": "updatedAt(format:\"D MMM\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "read",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "raw",
                        "value": true
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": "displayName(raw:true)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d044e5f8edfe1765c093a79b5eb550d",
    "id": null,
    "metadata": {},
    "name": "UpdateThreadMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateThreadMutation(\n  $input: UpdateThreadInput!\n) {\n  updateThread(input: $input) {\n    me {\n      unreadMessagesCounter\n      id\n    }\n    thread {\n      ...ThreadCard_thread\n      id\n    }\n  }\n}\n\nfragment ThreadCard_thread on Thread {\n  id\n  content\n  formattedDate: updatedAt(format: \"D MMM\")\n  read\n  slug\n  author {\n    id\n    displayName(raw: true)\n    photoURL\n  }\n  group {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "92d6db834b512401ca5cd7a1d5082cf9";

export default node;
