/**
 * @generated SignedSource<<c82bc7c9edfdb75a49c8b900d14006fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActivityResponseItem_feedback$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly id: string;
    readonly photoURL: string | null | undefined;
    readonly role: UserRole;
  };
  readonly content: string;
  readonly createdAt: string | null | undefined;
  readonly id: string;
  readonly read: boolean;
  readonly " $fragmentType": "ActivityResponseItem_feedback";
};
export type ActivityResponseItem_feedback$key = {
  readonly " $data"?: ActivityResponseItem_feedback$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityResponseItem_feedback">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityResponseItem_feedback",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"fromNow\")"
    }
  ],
  "type": "ActivityFeedback",
  "abstractKey": null
};
})();

(node as any).hash = "c7e1ef0a165060ca53246934ab66fcd1";

export default node;
