/**
 * @generated SignedSource<<caafbcba7af0bbd28fb4236c9b172828>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientAddableItem_client$data = {
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly photoURL: string | null | undefined;
  readonly " $fragmentType": "ClientAddableItem_client";
};
export type ClientAddableItem_client$key = {
  readonly " $data"?: ClientAddableItem_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientAddableItem_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientAddableItem_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d012c6223eebe62acb1341d8d1b6f07a";

export default node;
