import React from "react";

export enum PreviewType {
  WEB = "WEB",
  TABLET = "TABLET",
  MOBILE = "MOBILE",
}

export const PreviewContext = React.createContext<
  [PreviewType, React.Dispatch<React.SetStateAction<PreviewType>>]
>([undefined, undefined]);

export function usePreview() {
  return React.useContext(PreviewContext);
}
