import clsx from "clsx";
import React from "react";
import { DialogProps, Button, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { humanizeUserPlan } from "../../utils/user";

import { useCurrentUser } from "../../hooks/useCurrentUser";

import { BaseDialog } from "./BaseDialog";
import { COACH_PLAN_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { Celebration } from "../../icons/Celebration";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },

  svg: {
    width: "100%",
    maxWidth: 345,
    margin: theme.spacing(4, 0),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
  },
}));

export interface UpgradedDialogProps extends DialogProps {
  plan?: string;
}

export function UpgradedDialog(props: UpgradedDialogProps) {
  const theme = useTheme();

  const { className, onClose, plan, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();

  const handleButtonClick = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
      navigate(COACH_PLAN_ROUTE, { replace: true });
    },
    [onClose],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`Done! You're now on the ${humanizeUserPlan(
        plan || user.plan,
      )} plan`}
      showClose={false}
      {...other}
    >
      <Celebration className={s.svg} fill={theme.palette.primary.main} />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="OK, Let’s Go"
        onClick={handleButtonClick}
      />
    </BaseDialog>
  );
}
