import React from "react";
import "./loader.css";

const Loader = ({ height }: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height,
      }}
    >
      <div className="gradient"></div>
    </div>
  );
};

export default Loader;
