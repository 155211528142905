/**
 * @generated SignedSource<<65c3c59e7f5858d2e3bfe70099df6feb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramDefaultView = "CALENDAR" | "LIST" | "SPREADSHEET" | "%future added value";
export type ProgramStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type UpdateProgramInput = {
  clientMutationId?: string | null | undefined;
  defaultView?: ProgramDefaultView | null | undefined;
  description?: string | null | undefined;
  folderId?: string | null | undefined;
  id: string;
  image?: string | null | undefined;
  length?: number | null | undefined;
  name?: string | null | undefined;
  status?: ProgramStatus | null | undefined;
};
export type UpdateProgramMutation$variables = {
  input: UpdateProgramInput;
};
export type UpdateProgramMutation$data = {
  readonly updateProgram: {
    readonly program: {
      readonly " $fragmentSpreads": FragmentRefs<"ProgramSettings_program">;
    };
  } | null | undefined;
};
export type UpdateProgramMutation = {
  response: UpdateProgramMutation$data;
  variables: UpdateProgramMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProgramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProgramPayload",
        "kind": "LinkedField",
        "name": "updateProgram",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProgramSettings_program"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProgramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProgramPayload",
        "kind": "LinkedField",
        "name": "updateProgram",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "length",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultView",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InviteCode",
                "kind": "LinkedField",
                "name": "inviteCode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09afa1a59d0569488324b3ba97ec38be",
    "id": null,
    "metadata": {},
    "name": "UpdateProgramMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProgramMutation(\n  $input: UpdateProgramInput!\n) {\n  updateProgram(input: $input) {\n    program {\n      ...ProgramSettings_program\n      id\n    }\n  }\n}\n\nfragment InviteLinkSettings_inviteCode on InviteCode {\n  id\n  code\n}\n\nfragment ProgramSettings_program on Program {\n  id\n  slug\n  name\n  description\n  imageURL\n  status\n  length\n  defaultView\n  inviteCode {\n    ...InviteLinkSettings_inviteCode\n    id\n    startDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "f244520b1fa51770474cd9b0465b69ec";

export default node;
