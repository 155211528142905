import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { CreateClientFormMutation } from "./__generated__/CreateClientFormMutation.graphql";

const mutation = graphql`
  mutation CreateClientFormMutation($input: CreateClientFormInput!) {
    createClientForm(input: $input) {
      clientForm {
        slug
      }
    }
  }
`;

export const useCreateClientFormMutation = () =>
  useConfiguredMutation<CreateClientFormMutation>(mutation, () => ({}));
