import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import { Accordion, AccordionProps } from "../accordion/Accordion";
import { ComponentType } from "../../constants";

import type { ComponentScheduleLayoutProps } from "./ComponentSchedule";

const useStyles = (isSidebar: boolean) =>
  makeStyles((theme) => ({
    root: {
      "& .MuiExpansionPanelDetails-root:not(:last-child)": {
        paddingBottom: theme.spacing(1),
      },
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.up("md")]: {
        flexDirection: isSidebar ? "column" : "row",

        "& > div:not(:last-child)": {
          marginRight: isSidebar ? 0 : theme.spacing(2),
        },
      },
    },
  }));

export interface ComponentScheduleLayoutExpansionProps
  extends ComponentScheduleLayoutProps,
    Omit<AccordionProps, "children" | "label" | "header"> {
  componentType: ComponentType;
  layout?: "expansion" | "sidebar";
  disableClickAwayListener?: boolean;
}

export function ComponentScheduleLayoutExpansion(
  props: ComponentScheduleLayoutExpansionProps,
) {
  const {
    className,
    header,
    expandedHeader,
    daysSelection,
    customDaysSelection,
    durationSelection,
    repeatSelection,
    weekSelection,
    reminderSelection,
    messageTimeSelection,
    subHeader,
    componentType,
    layout = "expansion",
    ...other
  } = props;
  const isSidebar = layout === "sidebar";
  const s = useStyles(isSidebar)();

  return (
    <Accordion
      className={clsx(s.root, className)}
      label="Schedule"
      header={header}
      layout={layout}
      {...other}
    >
      <Box className={s.wrapper}>
        {weekSelection}

        {daysSelection}

        {durationSelection}

        {repeatSelection}
      </Box>

      {customDaysSelection}

      {reminderSelection}

      {messageTimeSelection}
    </Accordion>
  );
}
