import clsx from "clsx";
import React from "react";
import { Menu, MenuProps, MenuItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},

  listItem: {
    margin: theme.spacing(0, 0.5),
    borderRadius: 2,
    minWidth: 220,

    "&:hover": {
      background: theme.palette.selected.main,
    },
  },

  listItemText: {
    fontWeight: 500,
  },
}));

export interface PendingInviteMenuProps extends MenuProps {
  handleRevokeInvite: (event: any) => void;
  handleResendInvite: (event: any) => void;
  disabled?: boolean;
  isAdded?: boolean;
}

export function PendingInviteMenu(props: PendingInviteMenuProps) {
  const {
    className,
    onClose,
    handleRevokeInvite,
    handleResendInvite,
    disabled,
    isAdded,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Menu
      MenuListProps={{ dense: true }}
      onClose={onClose}
      className={clsx(s.root, className)}
      {...other}
    >
      <MenuItem
        className={s.listItem}
        onClick={handleRevokeInvite}
        disabled={disabled}
        dense
      >
        <ListItemText
          primary={isAdded ? "Delete" : "Revoke Invite"}
          primaryTypographyProps={{
            className: s.listItemText,
          }}
        />
      </MenuItem>
      <MenuItem
        className={s.listItem}
        onClick={handleResendInvite}
        disabled={disabled}
        dense
      >
        <ListItemText
          primary={isAdded ? "Send Invite" : "Resend Invite"}
          primaryTypographyProps={{
            className: s.listItemText,
          }}
        />
      </MenuItem>
    </Menu>
  );
}
