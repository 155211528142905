import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create(["height"]),
  },

  response: {
    marginTop: theme.spacing(2),

    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.quote}`,
    },
  },

  text: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

interface Response {
  text: string;
  date: string;
}

export interface TextResponsesSummaryProps extends BoxProps {
  responses: readonly Response[];
}

export function TextResponsesSummary(props: TextResponsesSummaryProps) {
  const { className, responses, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {responses.map(({ text, date }, index) => (
        <Box key={index} className={s.response}>
          <Typography className={s.text} children={text} />
          <Typography className={s.date} children={date} />
        </Box>
      ))}
    </Box>
  );
}
