import React from "react";

const Yoga = ({ fill }) => (
  <svg
    width="460"
    height="502"
    viewBox="0 0 460 502"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M460 451.557C460 466.777 357.025 501.459 230 501.459C102.975 501.459 0 466.777 0 451.557C0 436.337 102.975 446.343 230 446.343C357.025 446.343 460 436.337 460 451.557Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.1"
        d="M460 451.557C460 466.777 357.025 501.459 230 501.459C102.975 501.459 0 466.777 0 451.557C0 436.337 102.975 446.343 230 446.343C357.025 446.343 460 436.337 460 451.557Z"
        fill="black"
      />
      <path
        d="M65.5016 248.969C70.8457 248.969 75.1779 244.634 75.1779 239.287C75.1779 233.939 70.8457 229.604 65.5016 229.604C60.1575 229.604 55.8252 233.939 55.8252 239.287C55.8252 244.634 60.1575 248.969 65.5016 248.969Z"
        fill="#6C63FF"
      />
      <path
        d="M107.929 328.664C113.273 328.664 117.605 324.329 117.605 318.981C117.605 313.634 113.273 309.299 107.929 309.299C102.585 309.299 98.2524 313.634 98.2524 318.981C98.2524 324.329 102.585 328.664 107.929 328.664Z"
        fill="#6C63FF"
      />
      <path
        d="M75.178 308.554C80.5221 308.554 84.8543 304.219 84.8543 298.871C84.8543 293.524 80.5221 289.189 75.178 289.189C69.8338 289.189 65.5016 293.524 65.5016 298.871C65.5016 304.219 69.8338 308.554 75.178 308.554Z"
        fill="#6C63FF"
      />
      <path
        d="M78.2644 436.305C78.0936 436.025 74.0658 429.296 72.6695 415.322C71.3887 402.502 72.2123 380.892 83.4122 350.748C104.63 293.643 78.5224 247.568 78.2557 247.109L79.5437 246.361C79.6113 246.477 86.3481 258.225 90.3278 276.931C95.5863 301.731 93.6715 327.517 84.8078 351.268C63.6262 408.275 79.3736 435.262 79.535 435.529L78.2644 436.305Z"
        fill="#CFCCE0"
      />
      <path
        d="M69.9676 244.5C67.9066 244.5 65.8918 243.889 64.1782 242.743C62.4645 241.597 61.1288 239.969 60.3401 238.064C59.5514 236.158 59.345 234.062 59.7471 232.039C60.1492 230.016 61.1417 228.158 62.599 226.7C64.0564 225.242 65.9132 224.249 67.9346 223.846C69.956 223.444 72.0513 223.65 73.9554 224.44C75.8596 225.229 77.4871 226.565 78.6321 228.28C79.7771 229.995 80.3883 232.011 80.3883 234.073C80.3852 236.838 79.2863 239.488 77.3327 241.443C75.3791 243.398 72.7304 244.497 69.9676 244.5ZM69.9676 225.136C68.201 225.136 66.4741 225.66 65.0052 226.642C63.5364 227.624 62.3915 229.02 61.7155 230.653C61.0394 232.286 60.8625 234.083 61.2072 235.817C61.5518 237.551 62.4025 239.143 63.6517 240.393C64.9009 241.643 66.4924 242.494 68.225 242.839C69.9577 243.184 71.7536 243.007 73.3857 242.331C75.0179 241.654 76.4129 240.508 77.3943 239.039C78.3758 237.569 78.8996 235.841 78.8996 234.073C78.8969 231.704 77.955 229.432 76.2805 227.756C74.606 226.081 72.3357 225.138 69.9676 225.136Z"
        fill="#CFCCE0"
      />
      <path
        d="M100.485 279.507C105.83 279.507 110.162 275.172 110.162 269.824C110.162 264.477 105.83 260.141 100.485 260.141C95.1413 260.141 90.8091 264.477 90.8091 269.824C90.8091 275.172 95.1413 279.507 100.485 279.507Z"
        fill="#CFCCE0"
      />
      <path
        d="M79.6441 304.085C77.5831 304.085 75.5683 303.474 73.8546 302.328C72.141 301.182 70.8053 299.553 70.0166 297.648C69.2279 295.743 69.0215 293.646 69.4236 291.623C69.8257 289.601 70.8182 287.743 72.2756 286.285C73.7329 284.826 75.5897 283.833 77.6112 283.431C79.6326 283.028 81.7278 283.235 83.6319 284.024C85.5361 284.813 87.1636 286.15 88.3086 287.865C89.4537 289.579 90.0648 291.595 90.0648 293.658C90.0617 296.422 88.9628 299.073 87.0092 301.028C85.0556 302.982 82.4069 304.082 79.6441 304.085ZM79.6441 284.72C77.8775 284.72 76.1506 285.244 74.6817 286.226C73.2128 287.208 72.068 288.604 71.392 290.237C70.7159 291.871 70.539 293.668 70.8837 295.401C71.2283 297.135 72.079 298.728 73.3282 299.978C74.5773 301.228 76.1689 302.079 77.9015 302.424C79.6342 302.769 81.4301 302.592 83.0622 301.915C84.6943 301.239 86.0893 300.093 87.0708 298.623C88.0523 297.153 88.5761 295.425 88.5761 293.658C88.5735 291.288 87.6316 289.016 85.9571 287.341C84.2825 285.665 82.0122 284.723 79.6441 284.72V284.72Z"
        fill="#CFCCE0"
      />
      <path
        d="M104.951 324.195C102.89 324.195 100.876 323.583 99.162 322.438C97.4484 321.292 96.1127 319.663 95.324 317.758C94.5353 315.853 94.3289 313.756 94.731 311.733C95.1331 309.711 96.1256 307.853 97.5829 306.394C99.0403 304.936 100.897 303.943 102.919 303.541C104.94 303.138 107.035 303.345 108.939 304.134C110.843 304.923 112.471 306.26 113.616 307.975C114.761 309.689 115.372 311.705 115.372 313.768C115.369 316.532 114.27 319.183 112.317 321.137C110.363 323.092 107.714 324.192 104.951 324.195ZM104.951 304.83C103.185 304.83 101.458 305.354 99.9891 306.336C98.5202 307.318 97.3754 308.714 96.6994 310.347C96.0233 311.981 95.8464 313.778 96.1911 315.511C96.5357 317.245 97.3864 318.838 98.6356 320.088C99.8847 321.338 101.476 322.189 103.209 322.534C104.942 322.878 106.738 322.701 108.37 322.025C110.002 321.349 111.397 320.203 112.378 318.733C113.36 317.263 113.884 315.535 113.884 313.768C113.881 311.398 112.939 309.126 111.264 307.451C109.59 305.775 107.32 304.833 104.951 304.83Z"
        fill="#CFCCE0"
      />
      <path
        d="M72.2007 354.732C77.5448 354.732 81.877 350.397 81.877 345.05C81.877 339.702 77.5448 335.367 72.2007 335.367C66.8566 335.367 62.5243 339.702 62.5243 345.05C62.5243 350.397 66.8566 354.732 72.2007 354.732Z"
        fill="#CFCCE0"
      />
      <path
        d="M84.11 436.661C84.11 436.661 74.4336 412.827 103.463 394.952L84.11 436.661Z"
        fill="#CFCCE0"
      />
      <path
        d="M72.2096 436.229C72.2096 436.229 67.8058 410.883 33.7211 411.101L72.2096 436.229Z"
        fill="#CFCCE0"
      />
      <path
        d="M421.546 445.599H256.844C256.844 445.599 253.505 396.602 273.258 396.045C293.011 395.489 290.786 417.76 315.547 387.137C340.308 356.514 370.355 358.184 374.25 376.28C378.145 394.375 366.738 408.851 387.604 404.397C408.47 399.943 438.517 411.635 421.546 445.599Z"
        fill="#6C63FF"
      />
      <path
        d="M336.415 445.61L335.859 445.588C337.155 413.329 343.907 392.602 349.343 380.895C355.245 368.184 360.932 363.038 360.989 362.987L361.358 363.403C361.302 363.453 355.681 368.548 349.827 381.174C344.421 392.831 337.706 413.472 336.415 445.61Z"
        fill="#F2F2F2"
      />
      <path
        d="M396.024 445.676L395.49 445.521C401.922 423.43 417.069 409.482 417.221 409.344L417.595 409.756C417.444 409.893 402.411 423.742 396.024 445.676Z"
        fill="#F2F2F2"
      />
      <path
        d="M282.785 445.637L282.234 445.56C284.416 429.812 280.474 417.593 276.783 410.116C272.786 402.021 268.226 397.599 268.18 397.555L268.565 397.153C268.611 397.198 273.242 401.687 277.282 409.87C281.007 417.417 284.987 429.748 282.785 445.637Z"
        fill="#F2F2F2"
      />
      <path
        d="M443.899 446.344H239.079L239.032 445.649C238.914 443.922 236.301 403.184 249.493 388.648C252.442 385.398 255.994 383.693 260.051 383.579C268.65 383.334 274.053 386.557 278.412 389.143C286.627 394.02 292.054 397.242 311.785 372.838C333.161 346.402 356.225 340.714 369.483 343.877C378.05 345.921 383.954 351.697 385.682 359.725C387.318 367.326 386.797 374.469 386.378 380.208C385.93 386.349 385.576 391.199 388.097 393.397C390.201 395.231 394.4 395.398 401.312 393.922C415.371 390.921 434.988 393.489 444.835 406.219C450.132 413.068 454.306 425.517 444.105 445.932L443.899 446.344ZM240.477 444.854H442.977C450.579 429.422 450.816 416.385 443.658 407.131C434.625 395.453 415.675 392.379 401.623 395.379C394.113 396.982 389.638 396.717 387.119 394.52C384.037 391.833 384.415 386.655 384.893 380.1C385.305 374.455 385.818 367.431 384.227 360.038C382.624 352.594 377.125 347.231 369.138 345.326C356.303 342.262 333.883 347.878 312.943 373.775C292.402 399.179 286.215 395.507 277.653 390.425C273.24 387.806 268.231 384.836 260.093 385.067C256.456 385.17 253.261 386.712 250.595 389.649C238.739 402.713 240.186 439.346 240.477 444.854Z"
        fill="#CFCCE0"
      />
      <path
        d="M230 479.115C357.025 479.115 460 466.777 460 451.557C460 436.337 357.025 423.999 230 423.999C102.975 423.999 0 436.337 0 451.557C0 466.777 102.975 479.115 230 479.115Z"
        fill="#3F3D56"
      />
      <path
        d="M235.057 91.0517C235.057 91.0517 223.559 86.9425 216.167 103.379C208.775 119.816 197.277 137.897 197.277 137.897L203.847 139.54C203.847 139.54 205.49 128.034 209.597 126.391L207.954 141.184C207.954 141.184 257.233 158.442 279.409 139.54L278.587 133.787C278.587 133.787 281.873 134.609 281.873 139.54L284.337 137.075C284.337 137.075 281.873 132.144 274.481 125.569C269.629 121.254 267.962 113.044 267.389 107.911C266.96 103.625 265.246 99.5683 262.472 96.274C257.56 90.5773 248.609 84.5226 235.057 91.0517Z"
        fill="#2F2E41"
      />
      <path
        d="M222.929 155.496L211.019 140.6L202.087 110.063L236.327 73.5671C236.327 73.5671 243.77 45.2644 237.071 46.754C230.372 48.2436 228.883 69.0982 228.883 69.0982L185.712 102.615C185.712 102.615 190.178 149.537 202.832 165.923L212.508 196.46L222.929 155.496Z"
        fill="#FFB9B9"
      />
      <path
        d="M255.68 155.496L267.589 140.6L276.521 110.063L242.282 73.5671C242.282 73.5671 234.838 45.2644 241.537 46.754C248.236 48.2436 249.725 69.0982 249.725 69.0982L292.896 102.615C292.896 102.615 288.43 149.537 275.777 165.923L266.1 196.46L255.68 155.496Z"
        fill="#FFB9B9"
      />
      <path
        d="M239.304 142.834C249.992 142.834 258.657 134.164 258.657 123.469C258.657 112.774 249.992 104.104 239.304 104.104C228.616 104.104 219.951 112.774 219.951 123.469C219.951 134.164 228.616 142.834 239.304 142.834Z"
        fill="#FFB9B9"
      />
      <path
        d="M230.372 133.897C230.372 133.897 231.117 149.538 226.65 154.751C222.184 159.965 217.718 186.778 239.304 187.523C260.89 188.268 258.657 165.179 258.657 165.179L254.191 152.517C254.191 152.517 247.492 142.834 249.725 133.897H230.372Z"
        fill="#FFB9B9"
      />
      <path
        d="M210.275 307.437L226.278 312.278C226.278 312.278 239.304 304.458 239.304 311.906C239.304 319.354 234.838 329.036 236.327 335.739C237.815 342.443 240.793 358.829 236.327 358.829C231.861 358.829 222.929 329.781 222.929 329.781L208.042 321.588L210.275 307.437Z"
        fill="#FFB9B9"
      />
      <path
        d="M230.372 411.71L224.417 434.799C224.417 434.799 219.207 441.502 224.417 444.482C226.862 445.88 228.652 448.754 229.862 451.41C230.918 453.803 232.683 455.813 234.92 457.167C237.156 458.521 239.755 459.153 242.363 458.978C244.904 458.767 247.044 457.762 247.564 455.15C247.708 454.33 247.68 453.49 247.482 452.682C247.283 451.874 246.919 451.116 246.412 450.456C240.921 443.032 239.676 438.151 239.676 438.151L241.909 412.082L230.372 411.71Z"
        fill="#FFB9B9"
      />
      <path
        d="M215.959 126.908C215.959 126.908 222.53 117.868 228.279 116.225C234.028 114.581 234.849 106.362 234.849 106.362C234.849 106.362 249.633 122.799 258.668 123.621C267.702 124.443 259.489 103.897 259.489 103.897L243.063 99.7877L227.458 101.431L215.138 109.65L215.959 126.908Z"
        fill="#2F2E41"
      />
      <path
        d="M203.576 250.831L200.599 256.79C200.599 256.79 128.398 279.879 134.353 301.478C140.307 323.078 173.803 317.119 173.803 317.119L211.019 323.822L214.741 307.437L188.689 300.733L233.35 284.348L234.838 360.318L228.883 414.689L243.77 416.179C243.77 416.179 260.146 366.277 257.913 345.422C257.913 345.422 285.453 260.514 269.822 247.852C254.191 235.19 203.576 250.831 203.576 250.831Z"
        fill="#2F2E41"
      />
      <path
        d="M240.177 165.189L228.563 151.143L220.696 154.751L212.508 180.819C212.508 180.819 205.809 193.481 213.997 206.888C213.997 206.888 215.485 215.825 213.252 218.06C211.019 220.294 210.275 234.446 210.275 234.446L200.599 253.066C200.599 253.066 255.68 262.748 272.055 249.342C272.055 249.342 263.462 217.642 268.673 207.959C273.883 198.277 274.627 183.433 268.673 176.73C262.718 170.027 256.424 152.517 256.424 152.517L252.819 150.21L240.177 165.189Z"
        fill={fill}
      />
      <path
        d="M283.599 53.9871H276.46V46.8438H275.811V53.9871H268.673V54.6362H275.811V61.7795H276.46V54.6362H283.599V53.9871Z"
        fill="#CFCCE0"
      />
      <path
        d="M215.752 39.0515H208.613V31.9081H207.965V39.0515H200.826V39.7005H207.965V46.8438H208.613V39.7005H215.752V39.0515Z"
        fill="#CFCCE0"
      />
      <path
        d="M248.318 7.14332H241.18V0H240.531V7.14332H233.392V7.79238H240.531V14.9357H241.18V7.79238H248.318V7.14332Z"
        fill="#CFCCE0"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="460" height="501.459" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Yoga;
