/**
 * @generated SignedSource<<a710d644dd03acd6844316e31c10d660>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateNotificationsSettingInput = {
  activityCompletion?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  dailyUpdate?: boolean | null | undefined;
  groupReplyEmail?: boolean | null | undefined;
  groupReplyPush?: boolean | null | undefined;
  newGroupPostEmail?: boolean | null | undefined;
  newGroupPostPush?: boolean | null | undefined;
  newMessageEmail?: boolean | null | undefined;
  newMessagePush?: boolean | null | undefined;
  newResponseEmail?: boolean | null | undefined;
  newResponsePush?: boolean | null | undefined;
  remindersEmail?: boolean | null | undefined;
  remindersPush?: boolean | null | undefined;
  remindersSms?: boolean | null | undefined;
  userId?: string | null | undefined;
  weeklyUpdate?: boolean | null | undefined;
};
export type ClientSettingsNotificationsUpdateMutation$variables = {
  input: UpdateNotificationsSettingInput;
};
export type ClientSettingsNotificationsUpdateMutation$data = {
  readonly updateNotificationsSetting: {
    readonly notificationsSetting: {
      readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsNotifications_settings">;
    } | null | undefined;
  } | null | undefined;
};
export type ClientSettingsNotificationsUpdateMutation = {
  response: ClientSettingsNotificationsUpdateMutation$data;
  variables: ClientSettingsNotificationsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsNotificationsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotificationsSettingPayload",
        "kind": "LinkedField",
        "name": "updateNotificationsSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsSetting",
            "kind": "LinkedField",
            "name": "notificationsSetting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientSettingsNotifications_settings"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSettingsNotificationsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotificationsSettingPayload",
        "kind": "LinkedField",
        "name": "updateNotificationsSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsSetting",
            "kind": "LinkedField",
            "name": "notificationsSetting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newMessageEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newMessagePush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newResponseEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newResponsePush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weeklyUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dailyUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remindersEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remindersSms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remindersPush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newGroupPostEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newGroupPostPush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupReplyEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupReplyPush",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4114f13f5be8235769000cbcc41e9d0",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsNotificationsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSettingsNotificationsUpdateMutation(\n  $input: UpdateNotificationsSettingInput!\n) {\n  updateNotificationsSetting(input: $input) {\n    notificationsSetting {\n      ...ClientSettingsNotifications_settings\n      id\n    }\n  }\n}\n\nfragment ClientSettingsNotifications_settings on NotificationsSetting {\n  id\n  newMessageEmail\n  newMessagePush\n  newResponseEmail\n  newResponsePush\n  weeklyUpdate\n  dailyUpdate\n  remindersEmail\n  remindersSms\n  remindersPush\n  newGroupPostEmail\n  newGroupPostPush\n  groupReplyEmail\n  groupReplyPush\n}\n"
  }
};
})();

(node as any).hash = "11d8e18d98da9048a71ac904f70dc348";

export default node;
