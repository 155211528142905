/**
 * @generated SignedSource<<3927f5fb2d36482e2e489458954f131c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MetricType = "CHECKIN_ANSWER_BINARY" | "CHECKIN_ANSWER_DATE" | "CHECKIN_ANSWER_EMOJI" | "CHECKIN_ANSWER_FILE" | "CHECKIN_ANSWER_LONG_TEXT" | "CHECKIN_ANSWER_MEASUREMENT" | "CHECKIN_ANSWER_MULTIPLE_CHOICE" | "CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX" | "CHECKIN_ANSWER_NUMBER" | "CHECKIN_ANSWER_NUTRITION_TARGET" | "CHECKIN_ANSWER_PROGRESS_PHOTO" | "CHECKIN_ANSWER_RATING_SCALE" | "CHECKIN_ANSWER_SHORT_TEXT" | "CHECKIN_ANSWER_STAR_RATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientResponsesSummaryCard_summary$data = {
  readonly componentTitle: string;
  readonly count: number;
  readonly counts?: ReadonlyArray<number | null | undefined>;
  readonly dateResponses?: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly selectedDate: string | null | undefined;
  } | null | undefined>;
  readonly emojiResponses?: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly emoji: string;
  } | null | undefined>;
  readonly fromNow: string | null | undefined;
  readonly metricType: MetricType;
  readonly no?: number;
  readonly numberResponses?: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly number: string;
  } | null | undefined>;
  readonly options?: ReadonlyArray<string | null | undefined>;
  readonly question: string;
  readonly rates?: ReadonlyArray<number | null | undefined>;
  readonly starRatingResponses?: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly starRating: number;
  } | null | undefined>;
  readonly textResponses?: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly text: string;
  } | null | undefined>;
  readonly yes?: number;
  readonly " $fragmentType": "ClientResponsesSummaryCard_summary";
};
export type ClientResponsesSummaryCard_summary$key = {
  readonly " $data"?: ClientResponsesSummaryCard_summary$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientResponsesSummaryCard_summary">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "fromNow"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "counts",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rates",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(format:\"fromNow\")"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientResponsesSummaryCard_summary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metricType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "componentTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    {
      "alias": "fromNow",
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "lastDate",
      "storageKey": "lastDate(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "no",
          "storageKey": null
        }
      ],
      "type": "BinaryResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "type": "RatingResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "textResponses",
          "args": null,
          "concreteType": "TextResponse",
          "kind": "LinkedField",
          "name": "responses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "TextResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "dateResponses",
          "args": null,
          "concreteType": "DateResponse",
          "kind": "LinkedField",
          "name": "responses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "format",
                  "value": "MM-DD-YYYY"
                }
              ],
              "kind": "ScalarField",
              "name": "selectedDate",
              "storageKey": "selectedDate(format:\"MM-DD-YYYY\")"
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "DateResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "numberResponses",
          "args": null,
          "concreteType": "NumberResponse",
          "kind": "LinkedField",
          "name": "responses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "NumberResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "emojiResponses",
          "args": null,
          "concreteType": "EmojiResponse",
          "kind": "LinkedField",
          "name": "responses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "EmojiResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "starRatingResponses",
          "args": null,
          "concreteType": "StarRatingResponse",
          "kind": "LinkedField",
          "name": "responses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "starRating",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "StarRatingResponsesSummary",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "options",
          "storageKey": null
        }
      ],
      "type": "MultipleChoiceResponsesSummary",
      "abstractKey": null
    }
  ],
  "type": "ResponsesSummary",
  "abstractKey": "__isResponsesSummary"
};
})();

(node as any).hash = "e746c87ae2efd81674c52783ba2e6c1b";

export default node;
