import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    padding: 0,
    borderRadius: 4,
  },

  normal: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.feedback.text,

    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.feedback.text,
    },
  },

  cardAction: {
    backgroundColor: theme.palette.quote,
    color: theme.palette.secondary.main,
    width: 44,
    height: 44,

    "&:hover": {
      backgroundColor: theme.palette.quote,
    },
  },

  editorComponent: {
    width: "auto",
    height: "auto",
    padding: theme.spacing(0.5),
    opacity: 0.7,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.selected.light,

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));

export interface MoreMenuButtonProps extends IconButtonProps {
  styleVariant?: "normal" | "inverse" | "cardAction" | "editorComponent";
}

export function MoreMenuButton(props: MoreMenuButtonProps) {
  const { className, styleVariant = "normal", ...other } = props;
  const s = useStyles();

  return (
    <IconButton
      className={clsx(s.root, className, {
        [s.normal]: styleVariant === "normal",
        [s.cardAction]: styleVariant === "cardAction",
        [s.editorComponent]: styleVariant === "editorComponent",
      })}
      disableRipple
      {...other}
      size="large"
    >
      <MoreHoriz />
    </IconButton>
  );
}
