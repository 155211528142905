import { Node } from "slate";

export interface ClientFormData {
  name: string;
  description: string;
  image?: string;
  content: Node[];
}

export enum ClientFormSection {
  CONTENT = "CONTENT",
  QUESTIONS = "QUESTIONS",
}

export type ClientFormSectionInfo = {
  name: string;
  types: string[];
};
