import React, { ReactElement } from "react";
import MinimizedTooltip from "./MinimizedTooltip";
import { ExerciseTypeExtra, ExerciseTypeReps } from "../../constants";

interface IExerciseTooltipProps {
  children: ReactElement;
  type: ExerciseTypeReps | ExerciseTypeExtra;
}

const ExerciseTooltip = ({ children, type }: IExerciseTooltipProps) => {
  const titles = {
    [ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION]:
      "RPE (Rate of Perceived Exertion) means how hard you think you're pushing yourself during exercise",
    [ExerciseTypeExtra.REPS_IN_RESERVE]:
      "RIR (Reps in Reserve) means how many more reps could you do before failure",
  };
  const title = titles[type];

  return (
    <>
      {title ? (
        <MinimizedTooltip delayNextEnter={false} title={title}>
          <>{children}</>
        </MinimizedTooltip>
      ) : (
        children
      )}
    </>
  );
};

export default ExerciseTooltip;
