/**
 * @generated SignedSource<<c16f6bc035156c4cb9b79979ddfe4e05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClientStatus = "ACTIVE" | "ADDED" | "ARCHIVED" | "PENDING" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientSummary_client$data = {
  readonly age: number | null | undefined;
  readonly clientStatus: ClientStatus;
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly gender: string;
  readonly height: string | null | undefined;
  readonly id: string;
  readonly lastLoginAt: string | null | undefined;
  readonly location: string;
  readonly photoURL: string | null | undefined;
  readonly units: Units;
  readonly weight: string | null | undefined;
  readonly " $fragmentType": "ClientSummary_client";
};
export type ClientSummary_client$key = {
  readonly " $data"?: ClientSummary_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSummary_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSummary_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "lastLoginAt",
      "storageKey": "lastLoginAt(format:\"fromNow\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0f154ad9de8a78c66aef1838471d6429";

export default node;
