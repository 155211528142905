/**
 * @generated SignedSource<<1c6a2ba5377e5807a234b225476370cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgressPhotosCard_client$data = {
  readonly id: string;
  readonly photos: ReadonlyArray<{
    readonly createdAt: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly back?: string | null | undefined;
      readonly front?: string | null | undefined;
      readonly side?: string | null | undefined;
    };
  }>;
  readonly username: string;
  readonly " $fragmentType": "ClientProgressPhotosCard_client";
};
export type ClientProgressPhotosCard_client$key = {
  readonly " $data"?: ClientProgressPhotosCard_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProgressPhotosCard_client">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientProgressPhotosCard_client",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": "photos",
      "args": [
        {
          "kind": "Literal",
          "name": "metricType",
          "value": "CHECKIN_ANSWER_PROGRESS_PHOTO"
        }
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "format",
              "value": "MMM DD, YYYY"
            }
          ],
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": "createdAt(format:\"MMM DD, YYYY\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "front",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "back",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "side",
                  "storageKey": null
                }
              ],
              "type": "CheckinAnswerProgressPhotoValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "metrics(metricType:\"CHECKIN_ANSWER_PROGRESS_PHOTO\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "a1ede441db58dfd522b6f3a2db05796d";

export default node;
