import { SchemaElements } from "../editor/normalizers/withSchema";
import {
  NutritionTrackingType,
  NutrientType,
  getNutritionTargetLabel,
} from "../../utils/nutrition";

import {
  MealLoggingQuestion,
  MealLoggingQuestionType,
  MealLoggingOption,
  MealLoggingQuestionKind,
} from "./types";

export const normalizeQuestion = (
  question: MealLoggingQuestion,
  withEmpty = true,
): MealLoggingQuestion => {
  const result = { ...question };

  if (result.type === MealLoggingQuestionType.NUTRITION) {
    result.text = "Nutrition";
    result.trackingType = result.trackingType || NutritionTrackingType.PORTIONS;
    result.nutrientTypes = result.nutrientTypes || Object.values(NutrientType);
  }

  if (
    result.type === MealLoggingQuestionType.MULTIPLE_CHOICE ||
    result.type === MealLoggingQuestionType.CHECKBOXES
  ) {
    if (withEmpty) {
      const hasEmpty = result.options.some(({ text }) => !text);

      if (!hasEmpty) {
        result.options = [
          ...result.options,
          {
            id: SchemaElements.generateId(),
            text: "",
          },
        ];
      }
    } else if (!withEmpty) {
      result.options = result.options.filter(({ text }) => Boolean(text));
    }

    let hasPrompt = false;
    result.options = result.options.filter((it) => {
      if (it.prompt && hasPrompt) {
        return false;
      }

      if (it.prompt) {
        hasPrompt = true;
      }

      return true;
    });

    result.options = sortMealLoggingOptions(result.options);
  }

  return result;
};

export const sortMealLoggingOptions = (_options: MealLoggingOption[]) => {
  const moveTo = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== -1 && oldIndex < newIndex) {
      const option = options[oldIndex];

      options.splice(oldIndex, 1);
      options.splice(newIndex, 0, option);
    }
  };

  const options = _options.slice();
  const promptIndex = options.findIndex(({ prompt }) => prompt);
  const lastIndex = options.length - 1;

  moveTo(promptIndex, lastIndex);

  const emptyIndex = options.findIndex(({ text }) => !text);
  moveTo(emptyIndex, lastIndex);

  return options;
};

export function parseAnswer({
  type,
  answer,
}: MealLoggingQuestion<MealLoggingQuestionKind>): string {
  switch (type) {
    case MealLoggingQuestionType.CHECKBOXES: {
      const parsed: string[] =
        answer && answer !== "" ? JSON.parse(answer) : [];
      return parsed.join(", ");
    }
    case MealLoggingQuestionType.NUTRITION: {
      const parsed: Record<string, number> =
        answer && answer !== "" ? JSON.parse(answer) : {};
      return Object.entries(parsed)
        .map(
          ([key, value]) => `${getNutritionTargetLabel(key, true)}: ${value}`,
        )
        .join(", ");
    }
    default:
      return answer;
  }
}
