/**
 * @generated SignedSource<<06b8c38156a0c32be2a1b8ac01119f43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramFolderCard_folder$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "ProgramFolderCard_folder";
};
export type ProgramFolderCard_folder$key = {
  readonly " $data"?: ProgramFolderCard_folder$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramFolderCard_folder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramFolderCard_folder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "ProgramFolder",
  "abstractKey": null
};

(node as any).hash = "3adb2aee1f7f2b733eea8cdc6b4e79c9";

export default node;
