/**
 * @generated SignedSource<<899c5a18501d7c15d555d29cf1622cf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollDialog_groups$data = {
  readonly totalCount: number;
  readonly " $fragmentType": "EnrollDialog_groups";
};
export type EnrollDialog_groups$key = {
  readonly " $data"?: EnrollDialog_groups$data;
  readonly " $fragmentSpreads": FragmentRefs<"EnrollDialog_groups">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnrollDialog_groups",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "type": "GroupConnection",
  "abstractKey": null
};

(node as any).hash = "64057136e1f7f6c9c5172c0343b80677";

export default node;
