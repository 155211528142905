/**
 * @generated SignedSource<<7accda7d13debabb972eb0077eda1385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActivityByTypesList_activities$data = ReadonlyArray<{
  readonly component: {
    readonly type: ComponentType;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityList_activities">;
  readonly " $fragmentType": "ActivityByTypesList_activities";
}>;
export type ActivityByTypesList_activities$key = ReadonlyArray<{
  readonly " $data"?: ActivityByTypesList_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityByTypesList_activities">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "compact"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ActivityByTypesList_activities",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "compact",
          "variableName": "compact"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ActivityList_activities"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "280dc0c43208119cca67941710b90063";

export default node;
