import React from "react";
import { ColumnField } from "../components/program-workout-spreadsheet/utils";

export const SpreadsheetInfoDrawerContext = React.createContext<
  [ColumnField, React.Dispatch<React.SetStateAction<ColumnField>>]
>([undefined, undefined]);

export function useSpreadsheetInfoDrawer() {
  return React.useContext(SpreadsheetInfoDrawerContext);
}
