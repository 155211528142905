import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  StandardTextFieldProps,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientFormData } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {},

  withImage: {
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[9],
    padding: theme.spacing(3),
  },

  nameFieldInput: {
    color: theme.palette.text.primary,
    fontWeight: "700 !important" as any,
    fontSize: 24,
    lineHeight: "29px",
  },

  descriptionFieldInput: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 16,
  },

  fieldUnderlineName: {
    "&:before": {
      display: "none",
    },

    "&:after": {
      borderBottomWidth: 2,
    },
  },
  fieldUnderlineDescription: {
    "&:before": {
      display: "none",
    },

    "&:after": {
      borderBottomWidth: 1,
    },
  },
  buttons: {
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-1),

    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      left: theme.spacing(-15),
      marginTop: theme.spacing(-0.5),
      marginLeft: "initial",
    },
  },
}));

export interface ClientFormHeaderData
  extends Pick<ClientFormData, "name" | "description"> {}

export interface ClientFormHeaderProps extends BoxProps {
  header: ClientFormHeaderData;
  onHeaderChange?: (header: ClientFormHeaderData) => void;
  disabled?: boolean;
  withImage?: boolean;
  buttons?: React.ReactNode;
}

export function ClientFormHeader(props: ClientFormHeaderProps) {
  const s = useStyles();
  const {
    className,
    header,
    disabled,
    onHeaderChange,
    withImage,
    buttons,
    ...other
  } = props;
  const { name, description } = header;
  const theme = useTheme();
  const disabledStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: theme.palette.text.primary,
    },
  };
  const handleNameChange: StandardTextFieldProps["onChange"] =
    React.useCallback(
      (event) => {
        if (onHeaderChange) {
          onHeaderChange({
            name: event.target.value,
            description,
          });
        }
      },
      [description, onHeaderChange],
    );

  const handleDescriptionChange: StandardTextFieldProps["onChange"] =
    React.useCallback(
      (event) => {
        if (onHeaderChange) {
          onHeaderChange({
            name,
            description: event.target.value,
          });
        }
      },
      [name, onHeaderChange],
    );

  return (
    <Box
      className={clsx(s.root, className, withImage && s.withImage)}
      {...other}
    >
      {buttons && <Box className={s.buttons}>{buttons}</Box>}
      <TextField
        variant="standard"
        fullWidth
        multiline
        InputProps={{
          classes: {
            underline: s.fieldUnderlineName,
            input: s.nameFieldInput,
          },
        }}
        placeholder="Type client form name here"
        value={name}
        onChange={handleNameChange}
        sx={disabledStyles}
        disabled={disabled}
      />
      {(!disabled || description) && (
        <TextField
          variant="standard"
          fullWidth
          multiline
          InputProps={{
            classes: {
              underline: s.fieldUnderlineDescription,
              input: s.descriptionFieldInput,
            },
          }}
          placeholder="Type client form description here"
          value={description}
          onChange={handleDescriptionChange}
          disabled={disabled}
          sx={disabledStyles}
        />
      )}
    </Box>
  );
}
