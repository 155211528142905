import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

export const ExampleValueBadge = ({
  value,
  secondaryValue,
  unit,
}: {
  value: string;
  secondaryValue?: string;
  unit?: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        px: 1.5,
        py: 0.2,
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography fontWeight={500}>{value}</Typography>
      {secondaryValue && (
        <Typography fontWeight={500} sx={{ opacity: 0.35 }}>
          {secondaryValue}
        </Typography>
      )}
      {unit && (
        <Typography fontWeight={500} pl={0.5}>
          {unit}
        </Typography>
      )}
    </Box>
  );
};
