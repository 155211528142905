import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { ClientActivityScreen } from "../../../components/screen/ClientActivityScreen";
import { ClientActivityRouteQuery } from "./__generated__/ClientActivityRouteQuery.graphql";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";

export function ClientActivityRoute() {
  const props = useLazyLoadQuery<ClientActivityRouteQuery>(
    graphql`
      query ClientActivityRouteQuery {
        ...ClientActivityScreen_root @arguments(first: 10, after: null)
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  return (
    <>
      <ClientActivityScreen rootRef={props} />
      <TrackInfoTool
        trackInfo={{
          name: "Client - Activity",
        }}
      />
    </>
  );
}
