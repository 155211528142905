import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button, MenuItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDropzone } from "react-dropzone";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useUploadFile } from "../../hooks/useUploadFile";
import { AssetType } from "../../constants";
import { colorSystem } from "../../theme";
import { Menu } from "../menu/Menu";
import { UnsplashDialog } from "../unsplash/UnsplashDialog";
import { MoreMenuButton } from "../button/MoreMenuButton";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: theme.spacing(24),
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",

    "&:not($disabled)": {
      cursor: "pointer",
    },
  },

  backButton: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(3.75, 3),

    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },

    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  disabled: {},

  empty: {
    background: colorSystem.grayAlpha1A,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  upload: {
    backgroundColor: theme.palette.background.paper,
    fontSize: 13,
    fontWeight: "bold",
    padding: theme.spacing(1.5, 2),
    borderWidth: 2,
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    marginTop: theme.spacing(-4),

    "&:hover": {
      borderWidth: 2,
    },
  },

  more: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),

    width: theme.spacing(5),
    height: theme.spacing(5),

    "& svg": {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
}));

export interface ClientFormCoverImageProps extends BoxProps {
  clientFormId: string;
  image: string;
  onImageChange?: (image: string) => void;
  disabled?: boolean;
}

export function ClientFormCoverImage(props: ClientFormCoverImageProps) {
  const s = useStyles();
  const { className, clientFormId, image, onImageChange, disabled, ...other } =
    props;

  const onError = useGenericErrorHandler();

  const unsplashDialogState = usePopupState({
    variant: "popover",
    popupId: "select-unsplash-image",
  });

  const menuState = usePopupState({
    variant: "popover",
    popupId: "client-form-cover-menu",
  });

  const [upload, , uploadInFlight] = useUploadFile({
    id: clientFormId,
    getAssetType: () => AssetType.CLIENT_FORM_IMAGE,
  });

  const onUpload = React.useCallback(
    (files: File[]) =>
      upload(files[0])
        .then(({ url }) => onImageChange(url))
        .catch(onError),
    [onError, onImageChange, upload],
  );

  const handleMenuClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      menuState.open(event);
    },
    [menuState],
  );

  const handleRemove = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      menuState.close();
      onImageChange(null);
    },
    [menuState, onImageChange],
  );

  const handleSelectUnsplashImage = React.useCallback(
    (url: string) => {
      menuState.close();
      onImageChange(url);
    },
    [menuState, onImageChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUpload,
    accept: getMimeTypes(ElementType.IMAGE),
  });

  const empty = !image;
  const { onClick: onUploadClick, ...dropRootProps } = getRootProps();

  const handleUploadClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      menuState.close();
      onUploadClick(event);
    },
    [menuState, onUploadClick],
  );

  return (
    <>
      <Box
        className={clsx(s.root, className, {
          [s.empty]: empty,
          [s.disabled]: disabled,
        })}
        style={{
          backgroundImage: `url("${image}")`,
        }}
        {...other}
        {...(!disabled && dropRootProps)}
      >
        <input
          {...getInputProps()}
          accept="image/*"
          id="image-file"
          type="file"
        />

        {!image && !disabled && (
          <Button
            className={s.upload}
            onClick={handleMenuClick}
            variant="outlined"
            color="primary"
            disabled={uploadInFlight}
          >
            Add cover image
          </Button>
        )}

        {!disabled && image && (
          <MoreMenuButton
            className={s.more}
            styleVariant="cardAction"
            onClick={handleMenuClick}
          />
        )}
      </Box>

      {!disabled && menuState.isOpen && (
        <Menu {...bindMenu(menuState)}>
          <MenuItem onClick={handleUploadClick}>
            <ListItemText primary="From computer" />
          </MenuItem>

          <MenuItem {...bindTrigger(unsplashDialogState)}>
            <ListItemText primary="Unsplash" />
          </MenuItem>

          {image && (
            <MenuItem onClick={handleRemove}>
              <ListItemText primary="Delete image" />
            </MenuItem>
          )}
        </Menu>
      )}

      {!disabled && unsplashDialogState.isOpen && (
        <UnsplashDialog
          open
          onClose={unsplashDialogState.close}
          onSelect={handleSelectUnsplashImage}
        />
      )}
    </>
  );
}
