import clsx from "clsx";
import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Rnd } from "react-rnd";

import { CropScaled } from "../editor/types/legacy";

import { ZoomableImage } from "./ZoomableImage";

interface StyleProps {
  dimensions?: ImageDimensions;
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "100% !important",

    transform: "none !important" as "none",
    position: "inherit !important" as "inherit",
    display: "flex !important" as "flex",
    alignItems: "center",
    justifyContent: "center",

    width: (({ dimensions }: StyleProps) => dimensions?.width || "auto") as any,
    height: (({ dimensions }: StyleProps) =>
      dimensions?.height || "auto") as any,
  },
}));

export type ImageDimensions = {
  width: number;
  height: number;
};

export interface ResizableImageProps {
  dimensions?: ImageDimensions;
  crop?: CropScaled;
  onResize: (
    event: MouseEvent | TouchEvent,
    dimensions: ImageDimensions,
  ) => void;
  readOnly?: boolean;
  className?: string;
  src: string;
  alt?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
}

export const ResizableImage = (props: ResizableImageProps) => {
  const { src, alt, crop, onResize, onClick, readOnly, className } = props;
  const s = useStyles();
  const [dimensions, setDimensions] = React.useState<ImageDimensions>(
    props.dimensions,
  );
  const [realDimensions, setRealDimensions] = React.useState<ImageDimensions>();
  const [croppedSrc, setCroppedSrc] = React.useState("");

  const defaultValue = {
    width: dimensions?.width || "auto",
    height: dimensions?.height || "auto",
    x: 0,
    y: 0,
  };

  const resizeRef = React.useRef<Rnd>();

  React.useEffect(() => {
    setDimensions(props.dimensions);

    const resize = resizeRef.current;

    if (resize) {
      resize.updateSize(props.dimensions);
    }
  }, [props.dimensions]);

  React.useEffect(() => {
    if (!realDimensions) {
      const image = new Image();
      const f = () => {
        setRealDimensions({
          width: image.width,
          height: image.height,
        });
      };

      image.addEventListener("load", f);
      image.src = src;

      return () => {
        image.removeEventListener("load", f);
      };
    }
  }, [src, realDimensions]);

  React.useEffect(() => {
    if (crop && crop.width && crop.height) {
      const image = new Image();
      image.crossOrigin = "Anonymous";

      const f = () => {
        const canvas = document.createElement("canvas");
        canvas.width = crop.width * crop.scaleX;
        canvas.height = crop.height * crop.scaleY;
        const ctx = canvas.getContext("2d");
        const [sx, sy] = [crop.x * crop.scaleX, crop.y * crop.scaleY];
        const { width: w, height: h } = canvas;

        try {
          ctx.drawImage(image, sx, sy, w, h, 0, 0, w, h);

          canvas.toBlob((blob) => {
            if (!blob) {
              console.error("Canvas is empty");
              return;
            }

            setCroppedSrc(window.URL.createObjectURL(blob));
          }, "image/jpeg");
        } catch (e) {
          console.error(e);
        }
      };

      image.addEventListener("load", f);
      image.src = src;

      return () => {
        image.removeEventListener("load", f);
      };
    } else {
      setCroppedSrc("");
    }
  }, [crop, src]);

  const handleResize = React.useCallback(
    (event, direction, ref) => {
      const width = parseInt(ref.style.width);
      const height = parseInt(ref.style.height);

      setDimensions({ width, height });
    },
    [setDimensions],
  );

  const handleResizeStop = React.useCallback(
    (event) => {
      onResize(event, dimensions);
    },
    [onResize, dimensions],
  );

  if (!realDimensions) {
    return null;
  }

  const source = croppedSrc || src;
  const children = (
    <ZoomableImage
      src={source}
      previewSrc={source}
      alt={alt}
      onClick={onClick}
      style={
        dimensions && dimensions.width && dimensions.height
          ? {
              maxWidth: "100%",
              width: dimensions.width,
            }
          : {
              maxWidth: "100%",
            }
      }
    />
  );

  return readOnly ? (
    <Box className={clsx(s.root, className)} data-image-resized>
      {children}
    </Box>
  ) : (
    <Rnd
      ref={resizeRef}
      default={defaultValue}
      className={clsx(s.root, className)}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      maxWidth={realDimensions.width}
      maxHeight={realDimensions.height}
      resizeGrid={[5, 5]}
      lockAspectRatio
      disableDragging
      enableUserSelectHack
      data-image-resized
    >
      {children}
    </Rnd>
  );
};
