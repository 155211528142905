import React, { SyntheticEvent, ChangeEvent } from "react";

import { UserRole } from "../../constants";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { isMobileApp } from "../../utils/mobile";

import {
  ChangePasswordForm,
  ChangePasswordFormProps,
} from "./ChangePasswordForm";
import { useSignInMutation } from "./mutations/SignIn";
import firebase from "firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";

type State = {
  password: string;
  passwordConf: string;
};

type PasswordError = {
  password?: string;
  passwordConf?: string;
};

type Props = {
  className?: string;
  component?: React.FC<ChangePasswordFormProps>;
};

export function ChangePassword(props: Props) {
  const navigate = useNavigate();
  const { className, component: Form = ChangePasswordForm } = props;

  const [searchParams] = useSearchParams();
  const code = searchParams.get("oobCode");

  const [errors, setErrors] = React.useState<PasswordError>({});
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState<State>({
    password: "",
    passwordConf: "",
  });
  const [signIn] = useSignInMutation();
  const onError = useGenericErrorHandler();

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = event.target;
    setErrors((x: any) => (x[name] ? x : { ...x, [name]: null }));
    setState((x: any) => (x[name] === value ? x : { ...x, [name]: value }));
  }

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    const err: PasswordError = {};

    if (!state.password) {
      err.password = "New password is required.";
    }

    if (!state.passwordConf) {
      err.passwordConf = "New password confirmation is required.";
    }

    if (
      Object.keys(err).length === 0 &&
      state.password !== state.passwordConf
    ) {
      setState((x) => ({
        ...x,
        passwordConf: "Doesn't match to the password string above.",
      }));
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    changePassword(code, state.password);
  }

  const changePassword = async (code: string, password: string) => {
    setLoading(true);

    try {
      const auth = firebase.auth();
      const {
        data: { email },
        operation,
      } = await auth.checkActionCode(code);
      if (operation !== "PASSWORD_RESET") {
        throw new Error("Incorrect operation token");
      }
      await auth.confirmPasswordReset(code, password);
      if (!email) {
        return;
      }
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();
      signIn({
        variables: { idToken },
        onCompleted(result, errors) {
          if (errors && errors[0]) {
            onError(errors[0]);
          } else {
            window.location.href =
              isMobileApp || result.signIn?.me?.role === UserRole.CLIENT
                ? HOME_ROUTE
                : COACH_PROGRAMS_PUBLISHED_ROUTE;
          }
        },
        onError,
      });
    } catch (err) {
      setLoading(false);
      const errorMessage =
        err instanceof Error ? err.message : "Password Confirmation error";
      setErrors((x) => ({ ...x, passwordConf: errorMessage }));
      console.error(err);
    }
  };

  return (
    <Form
      className={className}
      state={state}
      errors={errors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      loading={loading}
    />
  );
}
