import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
  },

  label: {
    fontSize: 14,
    fontWeight: "bold",
    width: "30%",
    textAlign: "left",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  value: {
    fontSize: 14,
    fontWeight: 500,

    "&$withChildren": {
      marginBottom: theme.spacing(1),
    },
  },

  withChildren: {},
}));

export interface AdminLabeledValueProps extends BoxProps {
  label: string;
  value?: string;
}

export function AdminLabeledValue(props: AdminLabeledValueProps) {
  const { className, label, value, children, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.label} variant="body1" children={label} />

      <Box className={s.content}>
        {value && (
          <Typography
            className={clsx(s.value, children && s.withChildren)}
            variant="body1"
            children={value}
          />
        )}

        {children}
      </Box>
    </Box>
  );
}
