import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Close } from "@mui/icons-material";

import { getComponentImages } from "../../utils/component";
import { ScrollableContainer } from "../container/ScrollableContainer";
import { CustomImageButton } from "../button/CustomImageButton";
import { ImageButton, ImageButtonProps } from "../button/ImageButton";
import { ComponentPreview } from "../program-component/ComponentPreview";
import { UnsplashDialog } from "../unsplash/UnsplashDialog";

import { ChangeComponentCoverDialog_component$key } from "./__generated__/ChangeComponentCoverDialog_component.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
  },
  paper: {
    width: theme.spacing(106),
    paddingBottom: theme.spacing(2),
    "& > *": {
      padding: theme.spacing(1, 3),
    },
  },

  text: {
    fontWeight: 500,
  },

  preview: {
    margin: theme.spacing(0, 0, 3.75, 0),
  },

  gallery: {
    margin: theme.spacing(0, -3),
    padding: theme.spacing(0, 3),
    maxWidth: `calc(100%, ${theme.spacing(6)})`,
  },
  thumbnail: {
    margin: theme.spacing(1, 1, 2, 0),
    minWidth: 144,
    height: 96,
  },

  button: {
    fontWeight: 700,
    fontSize: 16,
    padding: theme.spacing(1.5, 0),
  },

  titleText: {
    fontSize: 24,
    fontWeight: 600,
  },

  close: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
  },
}));

const componentFragment = graphql`
  fragment ChangeComponentCoverDialog_component on Component
  @argumentDefinitions(draft: { type: "Boolean!", defaultValue: false }) {
    content(draft: $draft)
    teaser(draft: $draft)
    image(draft: $draft)
    ...ComponentPreview_component @arguments(draft: $draft)

    program {
      imageURL
    }
  }
`;

export interface ChangeComponentCoverDialogProps extends DialogProps {
  onCoverChange?: (value: string | null) => void;
  componentRef: ChangeComponentCoverDialog_component$key;
}

export function ChangeComponentCoverDialog(
  props: ChangeComponentCoverDialogProps,
) {
  const { className, onCoverChange, componentRef, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const component = useFragment(componentFragment, componentRef);
  const { program } = component;

  const unsplashDialogState = usePopupState({
    variant: "popover",
    popupId: "select-unsplash-image",
  });

  const images = React.useMemo(
    () => getComponentImages(component.content, program.imageURL),
    [component.content, program.imageURL],
  );

  const initialSelected = component.image || images[0];
  const [selected, setSelected] = React.useState(initialSelected);

  const handleSelect = React.useCallback(
    (value: string) => {
      setSelected(value || images[0]);
    },
    [images],
  );

  const handleImageClick: ImageButtonProps["onClick"] = React.useCallback(
    ({
      currentTarget: {
        dataset: { src },
      },
    }) => handleSelect(src),
    [handleSelect],
  );

  const handleSave = React.useCallback(() => {
    if (onCoverChange) {
      onCoverChange(selected);
    }
  }, [onCoverChange, selected]);

  const handleImageRequest = React.useCallback(() => {
    unsplashDialogState.open();
  }, [unsplashDialogState]);

  const handleUnsplashSelected = React.useCallback(
    (value: string) => {
      unsplashDialogState.close();
      handleSelect(value);
    },
    [handleSelect, unsplashDialogState],
  );

  const handleUnsplashClose = React.useCallback(() => {
    unsplashDialogState.close();
  }, [unsplashDialogState]);

  const customImage = images.every((image) => image !== selected) && selected;
  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  return unsplashDialogState.isOpen ? (
    <UnsplashDialog
      open
      onSelect={handleUnsplashSelected}
      onClose={handleUnsplashClose}
    />
  ) : (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      maxWidth={false}
      fullScreen={false}
      {...other}
    >
      <DialogTitle>
        <Box className={s.title}>
          <Typography variant="h1" className={s.titleText}>
            Set cover image
          </Typography>
          <IconButton
            className={s.close}
            color="primary"
            children={<Close />}
            onClick={handleClose}
            size="large"
          />
        </Box>

        <Typography variant="body1" color="textSecondary" className={s.text}>
          Here’s how your card will look in your clients’ calendar.
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ComponentPreview
          className={s.preview}
          componentRef={component}
          teaser={component.teaser}
          image={selected}
        />

        <Typography variant="body1" color="textSecondary" className={s.text}>
          Select another image as a cover image:
        </Typography>
        <ScrollableContainer className={s.gallery}>
          <CustomImageButton
            className={s.thumbnail}
            src={customImage}
            onSelect={handleSelect}
            onRequestImage={handleImageRequest}
          />
          {images.map((url, index) => (
            <ImageButton
              key={index}
              className={s.thumbnail}
              onClick={handleImageClick}
              selected={url === selected}
              src={url}
            />
          ))}
        </ScrollableContainer>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          className={s.button}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
