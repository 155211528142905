import clsx from "clsx";
import React from "react";
import { List, ListProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";

import { useDebounce } from "../../hooks/useDebounce";

import { RecipientList_root$key } from "./__generated__/RecipientList_root.graphql";
import { RecipientListItem } from "./RecipientListItem";

export const RecipientListQuery = graphql`
  query RecipientListRefetchQuery(
    $first: Int = 10
    $after: String
    $query: String
  ) {
    ...RecipientList_root
      @arguments(first: $first, after: $after, query: $query)
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {},

  empty: {
    marginTop: theme.spacing(3),
  },
}));

const rootFragment = graphql`
  fragment RecipientList_root on Root
  @refetchable(queryName: "RecipientListPaginatedRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    query: { type: "String" }
  ) {
    recipients: messageRecipients(first: $first, after: $after, query: $query)
      @connection(key: "RecipientList_recipients", filters: []) {
      edges {
        node {
          ...RecipientListItem_recipient
          id
          slug
        }
      }
    }
  }
`;

export interface RecipientListProps extends Omit<ListProps, "onSelect"> {
  rootRef: RecipientList_root$key;
  query?: string;
  pageSize?: number;
  onSelect?: (event: React.MouseEvent, username?: string) => void;
}

export function RecipientList(props: RecipientListProps) {
  const {
    className,
    rootRef,
    query = "",
    pageSize = 10,
    onSelect,
    ...other
  } = props;
  const {
    data: root,
    loadNext,
    hasNext,
    refetch: refetchConnection,
  } = usePaginationFragment(rootFragment, rootRef);
  const { recipients } = root;
  const s = useStyles();
  const [loading, setLoading] = React.useState(false);
  const delayedQuery = useDebounce(query, 250);
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const counter = React.useRef(0);

  const handleMoreClick = React.useCallback(() => {
    setLoading(true);
    loadNext(pageSize, {
      onComplete: setLoaded,
    });
  }, [pageSize, loadNext, setLoaded]);

  React.useEffect(() => {
    if (!loading && counter.current > 0) {
      refetchConnection(
        {
          query: delayedQuery,
          first: pageSize,
        },
        {
          onComplete: setLoaded,
        },
      );
    }

    ++counter.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedQuery, pageSize, refetchConnection, setLoaded]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const {
        dataset: { username },
      } = event.currentTarget;

      if (username && onSelect) {
        onSelect(event, username);
      }
    },
    [onSelect],
  );

  const edges = (recipients?.edges || []).filter(({ node }) => node);

  return edges.length > 0 ? (
    <>
      <List className={clsx(s.root, className)} {...other}>
        {edges.map(({ node }, index) => (
          <RecipientListItem
            key={node.id}
            recipientRef={node}
            divider={index < edges.length - 1}
            data-username={node.slug}
            onClick={handleClick}
          />
        ))}
      </List>
      {hasNext && (
        <Button onClick={handleMoreClick} fullWidth disabled={loading}>
          Load more
        </Button>
      )}
    </>
  ) : (
    <Typography component="div" className={s.empty}>
      Nothing found
    </Typography>
  );
}
