import React, { useTransition } from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import clsx from "clsx";
import {
  Drawer,
  DrawerProps,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { ClientMeasurementsCartDrawerCoach_client$key } from "./__generated__/ClientMeasurementsCartDrawerCoach_client.graphql";
import { measurementTypes, Units } from "../../constants";
import { toTrendMetricQueryVariables } from "../client-measurement/ClientMeasurementScreen";
import { ClientStepsDrawerHeader } from "../client-steps/ClientStepsDrawerHeader";
import { ClientMeasurementsCartDrawerEditView } from "./ClientMeasurementsCartDrawerEditView";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  header: {
    marginBottom: theme.spacing(4),
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  sortOption: {
    fontWeight: 500,
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
  },

  sort: {},

  overlay: {},
}));

const clientFragment = graphql`
  fragment ClientMeasurementsCartDrawerCoach_client on User
  @refetchable(queryName: "ClientMeasurementsCartDrawerCoachRefetchQuery")
  @argumentDefinitions(
    measurementType: { type: "MeasurementType" }
    includeMeasurement: { type: "Boolean!" }
    includeNutrition: { type: "Boolean!" }
    period: { type: "CompletionPeriodType" }
  ) {
    id
    username
    units
    nutritionTarget {
      trackingType
    }
    clientNutritionEntries(period: $period) @include(if: $includeNutrition) {
      edges {
        node {
          ...ClientNutritionResults_nutritionEntries
        }
      }
    }
    metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: $measurementType
    ) @include(if: $includeMeasurement) {
      ...ClientMeasurementsCartDrawerEditView_metrics
    }
  }
`;

export interface ClientMeasurementsCartDrawerCoachProps extends DrawerProps {
  today?: any;
  clientId?: string;
  setSteps?: (steps: any[]) => void;
  steps?: any[];
  clientRef?: ClientMeasurementsCartDrawerCoach_client$key;
}

export function ClientMeasurementsCartDrawerCoach(
  props: ClientMeasurementsCartDrawerCoachProps,
) {
  const { onClose, open, clientRef } = props;
  const [client, refetch] = useRefetchableFragment(clientFragment, clientRef);

  // TODO handle loading state with isPending
  const [isPending, startTransition] = useTransition();

  const { username } = client;
  const s = useStyles();
  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const metrics = client.metrics && client.metrics.filter(Boolean);

  const [selectType, setSelectType] = React.useState<any>("bodyfat");

  const units = client.units as Units;

  const items = React.useMemo(
    () =>
      measurementTypes
        .filter(({ label }) => label !== "Bodyweight")
        .map(({ label, name }) => ({ label, name })),
    [],
  );

  const handleChangeMetricType = React.useCallback(
    (value) => {
      setSelectType(value);
      const variables = toTrendMetricQueryVariables({
        username,
        metric: value,
      });
      startTransition(() => {
        refetch(variables, RELAY_LAZY_LOAD_COMMON_CONFIG);
      });
    },
    [username, setSelectType],
  );

  const handleMeasurementChanged = React.useCallback(() => {
    const variables = toTrendMetricQueryVariables({
      username,
      metric: selectType,
    });

    startTransition(() => {
      refetch(variables, RELAY_LAZY_LOAD_COMMON_CONFIG);
    });
  }, [username, selectType]);

  return (
    <Drawer
      className={clsx(s.root)}
      classes={{
        paper: clsx(s.paper),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={
        <>
          <ClientStepsDrawerHeader
            className={s.header}
            onClose={handleClose}
            title="Measurements"
          />
          <Select
            className={clsx(s.select, s.sort)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
            variant="outlined"
            fullWidth
            onChange={(e) => handleChangeMetricType(e.target.value)}
            value={selectType}
          >
            {items.map(({ label, name }) => (
              <MenuItem className={s.sortOption} key={label} value={name}>
                <ListItemText
                  classes={{ primary: s.primaryText }}
                  primary={label}
                />
              </MenuItem>
            ))}
          </Select>
          <ClientMeasurementsCartDrawerEditView
            onClose={handleClose}
            metrics={metrics}
            clientId={client.id}
            units={units}
            onMeasurementChanged={handleMeasurementChanged}
            selectType={selectType}
          />
        </>
      }
    />
  );
}
