import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpdateClientPortalMutation } from "./__generated__/UpdateClientPortalMutation.graphql";

const mutation = graphql`
  mutation UpdateClientPortalMutation($input: UpdateClientPortalInput!) {
    updateClientPortal(input: $input) {
      workspace {
        ...ThemeProvider_workspace
      }
    }
  }
`;

export const useUpdateClientPortalMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpdateClientPortalMutation>(
    mutation,
    ({ onSuccess }) => ({
      onSuccess: (result) => {
        snackAlert({
          severity: "success",
          message: "Client portal settings updated",
        });

        if (onSuccess) {
          onSuccess(result);
        }
      },
      onFailure: ([{ message }]) =>
        snackAlert({
          severity: "error",
          message,
        }),
    }),
  );
};
