import clsx from "clsx";
import React from "react";
import {
  Select,
  SelectProps,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MealLoggingQuestionTypes } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
  },

  select: {
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
  },

  text: {
    fontSize: 15,
    fontWeight: 500,
  },
}));

export type MealLoggingQuestionSelectTypeProps = SelectProps;

export function MealLoggingQuestionSelectType(
  props: MealLoggingQuestionSelectTypeProps,
) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Select
      className={clsx(s.root, className)}
      classes={{ select: s.select }}
      {...other}
    >
      {Object.entries(MealLoggingQuestionTypes).map(
        ([type, { name, Icon }]) => (
          <MenuItem key={type} value={type} dense>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText classes={{ primary: s.text }}>{name}</ListItemText>
          </MenuItem>
        ),
      )}
    </Select>
  );
}
