import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardContent,
  Typography,
  Box,
  Button,
  ButtonProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay/hooks";
import { ArrowForward } from "@mui/icons-material";

import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import {
  ComponentType,
  ComponentStatus,
  componentTypes,
} from "../../constants";
import { EditorValue } from "../program/EditorValue";
import { ReactComponent as EllipsisHorizontalIcon } from "../../icons/EllipsisHorizontal.svg";
import { useDirtyMutation } from "../dirty-transaction/hooks";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";
import { Filters } from "../program/ProgramDetailsFilters";
import { ProgramWeekDateRange } from "../program/ProgramWeekDateRange";

import { WeekAddComponent } from "./WeekAddComponent";
import { WeekComponentList } from "./WeekComponentList";
import { WeekCard_week$key } from "./__generated__/WeekCard_week.graphql";
import { WeekCardUpdateOrderMutation } from "./__generated__/WeekCardUpdateOrderMutation.graphql";
import { WeekDrawer } from "./WeekDrawer";
import useDependantKey from "../../hooks/useDependantKey";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3.25, 0),
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: 12,
    position: "relative",
  },
  content: {
    padding: 0,
  },
  header: {
    display: "flex",
    flexFlow: "wrap",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 0,
    cursor: "pointer",
  },
  description: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(-1),
    fontSize: 16,
    fontWeight: 500,
  },
  moreButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },
  detailsButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    fontSize: 16,
    marginLeft: "auto",
  },
  addComponent: {
    marginTop: theme.spacing(6),
  },
  weeks: {},
  collapsed: {
    "& $weeks": {
      display: "none",
    },
  },
  caret: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    width: 16,
    height: 16,

    "$collapsed &": {
      transform: "rotate(-90deg)",
    },
  },
  summary: {
    marginTop: theme.spacing(1),
    marginLeft: "auto",
  },

  summaryText: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: 16,
  },

  summaryDateRange: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));

const fragment = graphql`
  fragment WeekCard_week on Week {
    ...WeekDrawer_week
    description
    positions
    components(archived: true) {
      ...WeekComponentList_components
    }
  }
`;

const updateOrderMutation = graphql`
  mutation WeekCardUpdateOrderMutation(
    $input: UpdateWeekComponentsOrderInput!
  ) {
    updateWeekComponentsOrder(input: $input) {
      week {
        positions
      }
    }
  }
`;

export interface WeekCardProps extends CardProps {
  weekRef: WeekCard_week$key;
  schedule: ProgramWeekSchedule;
  filters?: Filters;
  startDate?: string;
  onAddComponent?: ProgramAddComponentCallback;
  onOpenMenu?: ButtonProps["onClick"];
  open?: boolean;
  id: string;
  week: number;
  programId: string;
  handleClickOpenDialog: (e?: any, slug?: string) => void;
}

export function WeekCard(props: WeekCardProps) {
  const {
    className,
    weekRef,
    onAddComponent,
    onOpenMenu,
    schedule,
    filters,
    startDate,
    id,
    programId,
    week: weekNumber,
    handleClickOpenDialog,
    ...other
  } = props;
  const s = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [updateOrder] =
    useDirtyMutation<WeekCardUpdateOrderMutation>(updateOrderMutation);
  const snackAlert = useSnackAlert();

  const handleDrawerOpen = React.useCallback((event) => {
    event.preventDefault();
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const week = useFragment(fragment, weekRef);
  const components = schedule.map(({ component }) => component);
  const [open, setOpen] = useLocalStorage(id, true);

  const handleAddComponent = React.useCallback(
    (componentType: ComponentType) => {
      if (onAddComponent) {
        onAddComponent(id, componentType);
      }
    },
    [onAddComponent, id],
  );

  const [rawPositions, setRawPositions] = React.useState(week.positions);

  const positions = React.useMemo(
    () => [
      ...rawPositions.filter((id) => components.some((it) => it.id === id)),
      ...components
        .map(({ id }) => id)
        .filter((id) => !rawPositions.includes(id)),
    ],
    [rawPositions, components],
  );

  const handleOnMove = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newValue = [...positions];
      newValue.splice(dragIndex, 0, newValue.splice(hoverIndex, 1)[0]);
      setRawPositions(newValue);
    },
    [setRawPositions, positions],
  );

  const handleOnMoveEnd = React.useCallback(() => {
    updateOrder({
      variables: {
        input: {
          programId: programId,
          week: weekNumber,
          positions,
        },
      },

      optimisticUpdater: (store) => {
        store.get(id).setValue(positions, "positions");
      },

      onCompleted() {
        snackAlert({
          severity: "success",
          message: "Components order updated",
        });
      },

      onError() {
        snackAlert({
          severity: "error",
          message: "Couldn't update components order",
        });
      },
    });
  }, [positions, snackAlert, updateOrder, id, programId, weekNumber]);

  const componentsByType = React.useMemo(
    () =>
      componentTypes
        .filter((componentType) => !filters || filters[componentType])
        .map((componentType) => ({
          components: components
            .filter(({ type }) => type === componentType)
            .filter(
              ({ status }) =>
                status !== ComponentStatus.ARCHIVED ||
                (filters && filters.archived),
            ),
          componentType,
        })),
    [filters, components],
  );

  const handleToggleExpanded = React.useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const descriptionKey = useDependantKey(week.description);

  return (
    <Card className={clsx(s.root, className, !open && s.collapsed)} {...other}>
      <WeekDrawer
        open={drawerOpen}
        weekRef={week}
        onClose={handleDrawerClose}
        weekId={id}
        weekNumber={weekNumber}
        programId={programId}
      />
      <CardContent className={s.content}>
        <Box className={s.header}>
          <CaretDownIcon className={s.caret} onClick={handleToggleExpanded} />

          <Typography
            variant="h5"
            className={s.title}
            onClick={handleToggleExpanded}
            gutterBottom
          >
            Week {weekNumber}
          </Typography>
          {onOpenMenu && (
            <Button data-id={id} onClick={onOpenMenu} className={s.moreButton}>
              <EllipsisHorizontalIcon />
            </Button>
          )}

          {open && !startDate ? (
            <Button
              className={s.detailsButton}
              endIcon={<ArrowForward />}
              onClick={handleDrawerOpen}
            >
              View details
            </Button>
          ) : (
            <Box className={s.summary}>
              <Typography className={s.summaryText}>
                {components.length} item{components.length !== 1 && "s"}
              </Typography>
              {startDate && (
                <ProgramWeekDateRange
                  className={s.summaryDateRange}
                  startDate={startDate}
                  week={weekNumber}
                />
              )}
            </Box>
          )}
        </Box>

        <Typography
          component="div"
          className={s.description}
          key={descriptionKey}
        >
          <EditorValue
            value={week.description}
            defaultValue="Add an optional description in the details."
            onClick={handleDrawerOpen}
          />
        </Typography>

        <Box className={s.weeks}>
          {componentsByType.map(
            ({ componentType, components }) =>
              components.length > 0 && (
                <WeekComponentList
                  key={componentType}
                  programId={programId}
                  componentsRef={components}
                  componentType={componentType}
                  positions={positions}
                  week={weekNumber}
                  onAddComponent={handleAddComponent}
                  onMove={handleOnMove}
                  onMoveEnd={handleOnMoveEnd}
                  weekId={id}
                  handleClickOpenDialog={handleClickOpenDialog}
                />
              ),
          )}

          <WeekAddComponent
            className={s.addComponent}
            onAddComponent={handleAddComponent}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
