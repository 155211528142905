import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { Line } from "react-chartjs-2";

import { ComponentType } from "../../constants";

import { ClientProgramActivity_enrollment$key } from "./__generated__/ClientProgramActivity_enrollment.graphql";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    lineHeight: "29px",
  },

  legend: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },

  type: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,

    "&:not(:last-child)": {
      marginRight: theme.spacing(2.25),
    },

    "&::before": {
      content: "''",
      display: "inline-block",
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: theme.spacing(0.75),
    },

    "&$habit::before": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&$checkin::before": {
      backgroundColor: theme.palette.primary.main,
    },
    "&$lesson::before": {
      backgroundColor: theme.palette.primary.light,
    },
  },

  habit: {},
  checkin: {},
  lesson: {},
}));

const enrollmentFragment = graphql`
  fragment ClientProgramActivity_enrollment on Enrollment {
    lessonsByWeeks: completionByWeeks(
      componentType: LESSON
      startWeek: -5
      endWeek: 0
    ) {
      startDate
      completion {
        completed
      }
    }
    habitsByWeeks: completionByWeeks(
      componentType: HABIT
      startWeek: -5
      endWeek: 0
    ) {
      startDate
      completion {
        completed
      }
    }
    checkinsByWeeks: completionByWeeks(
      componentType: CHECKIN
      startWeek: -5
      endWeek: 0
    ) {
      startDate
      completion {
        completed
      }
    }
  }
`;

const items = [
  { type: ComponentType.HABIT, label: "Habits" },
  { type: ComponentType.CHECKIN, label: "Check-ins" },
  { type: ComponentType.LESSON, label: "Lessons" },
];

export interface ClientProgramActivityProps extends BoxProps {
  enrollmentRef: ClientProgramActivity_enrollment$key;
}

export function ClientProgramActivity(props: ClientProgramActivityProps) {
  const { className, enrollmentRef, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  const { lessonsByWeeks, habitsByWeeks, checkinsByWeeks } = useFragment(
    enrollmentFragment,
    enrollmentRef,
  );

  const getDataset = React.useCallback((data, color, extra = {}) => {
    return {
      data: data.map(({ startDate: x, completion: { completed: y } }) => ({
        x,
        y,
      })),
      fill: true,
      lineTension: 0,
      backgroundColor: color,
      borderColor: color,
      borderWidth: 1,
      pointRadius: 2,
      pointBorderColor: theme.palette.primary.main,
      pointBackgroundColor: theme.palette.common.white,
      ...extra,
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const habitsDataset = React.useMemo(
    () =>
      getDataset(habitsByWeeks, theme.palette.primary.dark, {
        label: "habitsDataset",
      }),
    [habitsByWeeks], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const checkinsDataset = React.useMemo(
    () =>
      getDataset(checkinsByWeeks, theme.palette.primary.main, {
        label: "checkinsDataset",
      }),
    [checkinsByWeeks], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const lessonsDataset = React.useMemo(
    () =>
      getDataset(lessonsByWeeks, theme.palette.primary.light, {
        label: "lessonsDataset",
      }),
    [lessonsByWeeks], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h6">
        Program activity
      </Typography>
      <Box className={s.legend}>
        {items.map(({ type, label }) => (
          <Typography
            key={type}
            className={clsx(s.type, s[type.toLowerCase()])}
            variant="body1"
            component="div"
            children={label}
          />
        ))}
      </Box>
      <Line
        height={150}
        data={{
          datasets: [habitsDataset, checkinsDataset, lessonsDataset],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
              },
            },
            y: {
              type: "linear",
              ticks: {
                stepSize: 4,
                precision: 0,
              },
              stacked: true,
            },
          },
        }}
      />
    </Box>
  );
}
