import React from "react";
import clsx from "clsx";
import { Info } from "lucide-react";
import {
  Box,
  ClickAwayListener,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { IHeaderParams } from "ag-grid-community";
import { useSpreadsheetInfoDrawer } from "../../../hooks/useSpreadsheetInfoDrawer";
import { ColumnField } from "../utils";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    "&:hover $icon": {
      color: theme.palette.common.black,
    },
  },
  icon: {
    color: theme.palette.divider,
    transition: theme.transitions.create(["color"]),
  },
  iconActive: {
    color: theme.palette.common.black,
  },
}));

const InfoHeader = ({ column, displayName, context }: IHeaderParams) => {
  const s = useStyles();

  const [columnField, setColumnField] = useSpreadsheetInfoDrawer();

  const headerColumnField = column.getColId() as ColumnField;
  const isInfoActive = columnField === headerColumnField;

  const handleHeaderClick = () => {
    if (isInfoActive) {
      setColumnField(undefined);
      return;
    }

    setColumnField(headerColumnField);
  };

  return (
    <>
      <Box
        className={s.headerCell}
        sx={{
          cursor: "pointer",
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          px: "var(--ag-cell-horizontal-padding)",
        }}
        onClick={handleHeaderClick}
      >
        <span>{displayName}</span>

        <Info
          size={16}
          className={clsx(s.icon, isInfoActive && s.iconActive)}
        />
      </Box>
    </>
  );
};

export default InfoHeader;
