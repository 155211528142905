import clsx from "clsx";
import React, { ReactNode } from "react";
import { CircularProgress as MuiCircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    whiteSpace: "nowrap",
  },

  normal: {
    "& svg": {
      margin: 1,
      color: theme.palette.primary.main,
    },
    "& svg circle": {
      strokeLinecap: "square",
    },
  },

  circle: {
    display: "block",
    position: "absolute",
    borderRadius: "50%",
    border: "2px solid",
    borderColor: theme.palette.divider,

    "$normal &": {
      border: "4px solid",
      borderColor: theme.palette.primary.light,
      opacity: 0.5,
    },
  },
  progress: {
    "& svg": {
      strokeLinecap: "round",
    },
  },
  yellow: {
    color: theme.palette.progress.yellow,
  },
  green: {
    color: theme.palette.progress.green,
  },
  red: {
    color: theme.palette.progress.red,
  },
}));

export interface ClientCircularProgressProps {
  className?: string;
  value: number;
  children?: ReactNode;
  size: "small" | "medium" | "tinyBold" | "smaller" | "normal";
}

const sizes = {
  smaller: {
    size: 47,
    thickness: 3,
  },
  small: {
    size: 58,
    thickness: 3,
  },
  medium: {
    size: 90,
    thickness: 5,
  },
  tinyBold: {
    size: 32,
    thickness: 6,
  },
  normal: {
    size: 80,
    thickness: 2,
  },
};
export function ClientCircularProgress(props: ClientCircularProgressProps) {
  const { className, value, size = "medium", children } = props;
  const s = useStyles();

  return (
    <div
      className={clsx(s.root, className, s[size])}
      style={{
        width: sizes[size].size,
        height: sizes[size].size,
      }}
    >
      <span
        className={s.circle}
        style={{
          top: sizes[size].thickness / 2,
          left: sizes[size].thickness / 2,
          right: sizes[size].thickness / 2,
          bottom: sizes[size].thickness / 2,
        }}
      />
      <MuiCircularProgress
        size={sizes[size].size}
        thickness={sizes[size].thickness}
        className={clsx(
          s.progress,
          value < 26 && s.red,
          value > 25 && value < 76 && s.yellow,
          value > 75 && s.green,
        )}
        variant="determinate"
        value={value}
      />
      {children}
    </div>
  );
}
