import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Typography,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
  Portal,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useLazyLoadQuery } from "react-relay/hooks";

import { ReactComponent as MeasurementsChartIcon } from "../../icons/measurementsChart.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";

import { useLocation, useParams } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Units, UserRole } from "../../constants";
import { colorSystem } from "../../theme";

import {
  ClientMeasurementsCard_client$key,
  ClientMeasurementsCard_client$data,
} from "./__generated__/ClientMeasurementsCard_client.graphql";
import { ClientMeasurementItem } from "./ClientMeasurementItem";
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { ClientMeasurementsCartDrawer } from "./ClientMeasurementsCartDrawer";
import omit from "lodash.omit";
import { ConfirmActionMeasurements } from "../dialog/ConfirmActionMeasurements";

import { ClientMeasurementsCardQuery } from "./__generated__/ClientMeasurementsCardQuery.graphql";
import { ClientMeasurementsCardCoachQuery } from "./__generated__/ClientMeasurementsCardCoachQuery.graphql";
import { ClientMeasurementsCartDrawerCoach } from "./ClientMeasurementsCartDrawerCoach";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    [theme.breakpoints.down("lg")]: {
      fontSize: 22,
    },
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  button: {
    color: colorSystem.black,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(7),
    borderWidth: 2,
    borderColor: "black",
    borderStyle: "solid",
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(3.5),
    },
  },

  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  headerIcon: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  content: {
    marginTop: theme.spacing(3.299),
  },
}));

const clientFragment = graphql`
  fragment ClientMeasurementsCard_client on User {
    units
    allMetrics: metrics(metricType: CHECKIN_ANSWER_MEASUREMENT, limit: 1) {
      createdAt(format: "MMM DD, YYYY")
    }
    bodyFat: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: BODYFAT
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    chest: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: CHEST
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    waist: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: WAIST
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    hip: metrics(metricType: CHECKIN_ANSWER_MEASUREMENT, measurementType: HIP) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    thigh: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: THIGH
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    upperArm: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: UPPER_ARM
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    calf: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: CALF
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
    shoulder: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: SHOULDER
    ) {
      ...ClientMeasurementItem_metrics
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
          unit
        }
      }
    }
  }
`;

interface MetricItem {
  label: string;
  metrics: ClientMeasurementsCard_client$data["bodyFat"];
}

export interface ClientMeasurementsCardProps extends CardProps {
  clientRef: ClientMeasurementsCard_client$key;
}

export function ClientMeasurementsCard(props: ClientMeasurementsCardProps) {
  const { className, clientRef, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const location = useLocation();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const {
    units,
    allMetrics,
    bodyFat,
    chest,
    waist,
    hip,
    thigh,
    upperArm,
    calf,
    shoulder,
  } = useFragment(clientFragment, clientRef);

  const { username: usernameParam } = useParams();
  const username = usernameParam ? usernameParam : user.username;

  const dataUser = useLazyLoadQuery<ClientMeasurementsCardQuery>(
    graphql`
      query ClientMeasurementsCardQuery($measurementType: MeasurementType) {
        user: me {
          ...ClientMeasurementsCartDrawer_user
            @arguments(measurementType: $measurementType)
        }
      }
    `,
    {
      measurementType: "BODYFAT",
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const dataCoach = useLazyLoadQuery<ClientMeasurementsCardCoachQuery>(
    graphql`
      query ClientMeasurementsCardCoachQuery(
        $username: String!
        $measurementType: MeasurementType
        $period: CompletionPeriodType
        $includeMeasurement: Boolean!
        $includeNutrition: Boolean!
      ) {
        client: user(username: $username) {
          ...ClientSummary_client
          displayName
          username

          ...ClientMeasurementsCartDrawerCoach_client
            @arguments(
              measurementType: $measurementType
              period: $period
              includeMeasurement: $includeMeasurement
              includeNutrition: $includeNutrition
            )
        }
      }
    `,
    {
      includeMeasurement: true,
      includeNutrition: false,
      measurementType: "BODYFAT",
      username: username,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const [measurementsModal, setMeasurementsModal] = React.useState(false);
  const [selectItemIndex, setSelectItemIndex] = React.useState(null);
  const [isOpenContent, setIsOpenContent] = React.useState(true);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const handleOpenCharts = React.useCallback((index) => {
    setSelectItemIndex(index);
    setMeasurementsModal(true);
  }, []);

  const handleCloseCharts = React.useCallback((index) => {
    setMeasurementsModal(false);
    setSelectItemIndex(null);
  }, []);

  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-measurements",
  });

  const items: MetricItem[] = React.useMemo(() => {
    return [
      { label: "BF (%)", metrics: bodyFat },
      { label: "Shoulder", metrics: shoulder },
      { label: "Chest", metrics: chest },
      { label: "Waist", metrics: waist },
      { label: "Hips", metrics: hip },
      { label: "Thighs", metrics: thigh },
      { label: "Upper arm", metrics: upperArm },
      { label: "Calfs", metrics: calf },
    ];
  }, [bodyFat, chest, waist, hip, thigh, upperArm, calf, shoulder]);

  return (
    <>
      <Card className={clsx(s.root, className)} {...other}>
        <Box className={s.header}>
          <Box className={s.headerIcon}>
            <MeasurementsChartIcon className={s.icon} />
            <Box>
              <Typography
                variant="h6"
                className={s.title}
                children="Measurements"
              />
              {allMetrics.length ? (
                <Typography variant="body1" className={s.subtitle}>
                  Last recorded {allMetrics[0].createdAt}
                </Typography>
              ) : null}
            </Box>
          </Box>
          {smUp ? (
            <Button
              className={s.button}
              children="Add new check-in"
              {...bindToggle(drawerState)}
            />
          ) : (
            <ArrowIcon
              className={isOpenContent ? "" : s.arrowIconDown}
              onClick={() => setIsOpenContent(!isOpenContent)}
            />
          )}
        </Box>
        {allMetrics.length && isOpenContent ? (
          <Grid className={s.content}>
            {items.map(({ label, metrics }, index) => (
              <Grid item key={label}>
                <ClientMeasurementItem
                  metricsRef={metrics}
                  label={label}
                  units={units as Units}
                  onClick={() => handleOpenCharts(index)}
                />
                {index < items.length - 1 && <Divider className={s.divider} />}
              </Grid>
            ))}
          </Grid>
        ) : (
          isOpenContent && (
            <Typography className={s.noText}>
              No measurements to show.
            </Typography>
          )
        )}
        {!smUp && isOpenContent && (
          <Button
            className={s.button}
            children="Add new check-in"
            {...bindToggle(drawerState)}
          />
        )}
      </Card>
      <ConfirmActionMeasurements
        title="Measurements"
        onCancel={handleCloseCharts}
        onConfirm={() => {}}
        open={measurementsModal}
        disabled={false}
        items={items}
        units={units}
        selectItemIndex={selectItemIndex}
        setSelectItemIndex={setSelectItemIndex}
      />
      {drawerState.isOpen && (
        <Portal>
          {user.role === UserRole.COACH ? (
            <ClientMeasurementsCartDrawerCoach
              {...omit(bindPopper(drawerState), ["anchorMeasEl"])}
              clientRef={dataCoach.client}
              onClose={drawerState.close}
            />
          ) : (
            <ClientMeasurementsCartDrawer
              {...omit(bindPopper(drawerState), ["anchorMeasCoachEl"])}
              onClose={drawerState.close}
              userRef={dataUser.user}
            />
          )}
        </Portal>
      )}
    </>
  );
}
