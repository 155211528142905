import { createContext } from "react";

export interface IFinishWorkoutModalContextProps {
  isFinishWorkoutModalOpen: boolean;
  setIsFinishWorkoutModalOpen: (isActivityPending: boolean) => void;
}

export const FinishWorkoutModalContext = createContext<
  IFinishWorkoutModalContextProps | undefined
>(undefined);
