import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { graphql, useFragment } from "react-relay";

import { getTodayDaysDiff } from "../../utils/date";
import { CoachSettingsPlanTextSubtitle_user$key } from "./__generated__/CoachSettingsPlanTextSubtitle_user.graphql";
import { CoachSettingsPlanTextSubtitle_subscription$key } from "./__generated__/CoachSettingsPlanTextSubtitle_subscription.graphql";
import { humanizeUserPlan } from "../../utils/user";
import { planStatus } from "../../constants";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.black,
  },
}));

const userFragment = graphql`
  fragment CoachSettingsPlanTextSubtitle_user on User {
    plan
    trialExpiryDate(raw: true)
    trialExpired
  }
`;

const subscriptionFragment = graphql`
  fragment CoachSettingsPlanTextSubtitle_subscription on Subscription {
    status
    canceledAtDate(utc: true, format: "MMMM D, YYYY")
    periodEndDate(utc: true, format: "MMMM D, YYYY")
    cancelAtPeriodEnd
  }
`;

export interface CoachSettingsPlanTextSubtitleProps extends TypographyProps {
  user: CoachSettingsPlanTextSubtitle_user$key;
  subscription: CoachSettingsPlanTextSubtitle_subscription$key;
}

export function CoachSettingsPlanTextSubtitle(
  props: CoachSettingsPlanTextSubtitleProps,
) {
  const {
    className,
    user: userRef,
    subscription: subscriptionRef,
    ...other
  } = props;
  const user = useFragment(userFragment, userRef);
  const subscription = useFragment(subscriptionFragment, subscriptionRef);
  const s = useStyles();

  const trialDaysDiff = React.useMemo(() => {
    return getTodayDaysDiff(user?.trialExpiryDate);
  }, [user]);

  const subscriptionDaysDiff = React.useMemo(() => {
    const date = dayjs(subscription?.periodEndDate, "MMMM DD, YYYY").format(
      "YYYY-MM-DD",
    );
    return subscription?.status === "active" ? (
      <>
        When you change the plan to a new one, it is extended <br /> by{" "}
        {getTodayDaysDiff(date)} days, as the new plan will be partially paid
        from <br /> the balance of the current plan. After the end of{" "}
        {getTodayDaysDiff(date)} days,
        <br /> the amount of the plan will be withdrawn in full and the <br />{" "}
        plan will be extended for 1 month.
      </>
    ) : null;
  }, [subscription]);

  const node = React.useMemo(() => {
    if (!subscription && !user?.trialExpired) {
      return <>Your {humanizeUserPlan(user.plan)} plan has ended.</>;
    }
    if (subscription?.status === planStatus.CANCELED) {
      return (
        <>Your plan has been canceled on {subscription?.canceledAtDate}.</>
      );
    } else if (
      subscription?.cancelAtPeriodEnd &&
      subscription?.canceledAtDate &&
      subscription?.periodEndDate
    ) {
      return (
        <>
          Your {humanizeUserPlan(user.plan)} plan was canceled on{" "}
          {subscription.canceledAtDate} and <br />
          will expire on {subscription.periodEndDate}.
        </>
      );
    } else if (
      subscription?.status === planStatus.UNPAID ||
      subscription?.status === planStatus.PAST_DUE ||
      subscription?.status === planStatus.INCOMPLETE_EXPIRED ||
      subscription?.status === planStatus.INCOMPLETE
    ) {
      return <>Please check your card and try again update plan</>;
    } else if (
      !subscription?.cancelAtPeriodEnd &&
      subscription?.periodEndDate
    ) {
      return (
        <>
          <>
            Your {humanizeUserPlan(user.plan)} plan will be renewed on{" "}
            {subscription.periodEndDate}.<br />
          </>
          {subscriptionDaysDiff}
          {console.log(user)}
        </>
      );
    } else if (!user?.plan && user?.trialExpired) {
      return <>The trial period has ended</>;
    } else if (!user.plan) {
      return (
        <>
          <b>An unlimited 30 day plan is available to you.</b>
          <br />
          To start working with the platform, you need to issue a trial plan
        </>
      );
    } else if (user?.trialExpired === false) {
      return <>Your trial period will end in {trialDaysDiff} days</>;
    }
  }, [subscription, trialDaysDiff, user, subscriptionDaysDiff]);

  return (
    <Typography
      className={clsx(s.root, className)}
      variant="body1"
      children={node}
      {...other}
    />
  );
}
