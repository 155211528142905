import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";

import { ChangePassword } from "./ChangePassword";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  logo: {
    marginBottom: theme.spacing(10),
  },
}));

export interface ChangePasswordScreenProps extends BoxProps {
  returnUrl?: string;
}

export function ChangePasswordScreen(props: ChangePasswordScreenProps) {
  const { className, returnUrl, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo className={s.logo} main full width={160} height={37} />
      <ChangePassword />
    </Box>
  );
}
