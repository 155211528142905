import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { AppLayout } from "../../../../components/app/AppLayout";
import { SignupClientCompletePhotoScreen } from "../../../../components/screen/SignupClientCompletePhotoScreen";
import { SignUpCompletePhotoRouteQuery } from "./__generated__/SignUpCompletePhotoRouteQuery.graphql";

export function SignUpCompletePhotoRoute() {
  const props = useLazyLoadQuery<SignUpCompletePhotoRouteQuery>(
    graphql`
      query SignUpCompletePhotoRouteQuery {
        me {
          ...SignupClientCompletePhotoScreen_user
        }
      }
    `,
    {},
  );
  const { me } = props;

  return (
    me && (
      <AppLayout appBar={false}>
        <SignupClientCompletePhotoScreen user={me} />
      </AppLayout>
    )
  );
}
