/**
 * @generated SignedSource<<61e0883a25f0c77387486b270bfd840f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientPastProgramCard_enrollment$data = {
  readonly completion: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly endDateLong: string | null | undefined;
  readonly totalDays: number;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSmallProgramCard_enrollment">;
  readonly " $fragmentType": "ClientPastProgramCard_enrollment";
};
export type ClientPastProgramCard_enrollment$key = {
  readonly " $data"?: ClientPastProgramCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientPastProgramCard_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientPastProgramCard_enrollment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSmallProgramCard_enrollment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDays",
      "storageKey": null
    },
    {
      "alias": "endDateLong",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMMM D, YYYY"
        },
        {
          "kind": "Literal",
          "name": "utc",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": "endDate(format:\"MMMM D, YYYY\",utc:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "d58f7a6797903464781b634dbd64145a";

export default node;
