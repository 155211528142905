import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { NoteEditor } from "../new-editor/NoteEditor";
import { isEmptyContent } from "../editor/utils/common";
import { EditorDialog, EditorDialogProps } from "../dialog/EditorDialog";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useBackupState } from "../../hooks/useLocalStorage";
import { SOMETHING_WENT_WRONG } from "../../constants";

import { UserNoteDialog_note$key } from "./__generated__/UserNoteDialog_note.graphql";
import {
  UserNoteDialogUpsertUserNoteMutation,
  UpsertUserNoteInput,
} from "./__generated__/UserNoteDialogUpsertUserNoteMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  container: {
    padding: 0,
    "& > :nth-child(1)": {
      margin: theme.spacing(0, 4),
    },
  },

  content: {
    padding: theme.spacing(1, 0),
    "& > :nth-child(2)": {
      margin: theme.spacing(0, 4),
    },
  },

  editor: {
    margin: theme.spacing(0, 4),
  },
}));

const fragment = graphql`
  fragment UserNoteDialog_note on UserNote {
    id
    content
  }
`;

const upsertUserNoteMutation = graphql`
  mutation UserNoteDialogUpsertUserNoteMutation($input: UpsertUserNoteInput!) {
    upsertUserNote(input: $input) {
      userNote {
        ...UserNotesListItem_note
      }
    }
  }
`;

export interface UserNoteDialogProps extends EditorDialogProps {
  userId: string;
  noteRef?: UserNoteDialog_note$key;
}

export function UserNoteDialog(props: UserNoteDialogProps) {
  const { className, userId, noteRef, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const note = useFragment(fragment, noteRef);
  const originalContent = note ? JSON.parse(note.content) : [];

  const [content, setContent, removeBackup] = useBackupState(
    "user-notes",
    note?.id,
    originalContent,
  );

  const [upsertUserNote, pending] =
    useMutation<UserNoteDialogUpsertUserNoteMutation>(upsertUserNoteMutation);

  const handleSubmit = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const input: UpsertUserNoteInput = {
        userId,
        content: JSON.stringify(content),
      };

      if (note) {
        input.id = note.id;
      }

      upsertUserNote({
        variables: { input },
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            removeBackup();

            snackAlert({
              severity: "success",
              message: note ? "Note updated" : "Note created",
            });

            if (onClose) {
              onClose(event, "escapeKeyDown");
            }
          }
        },
        updater: (store) => {
          if (!note) {
            const client = store.get(userId);
            const notes = ConnectionHandler.getConnection(
              client,
              "CoachClientNotesScreen_notes",
            );
            const note = store
              .getRootField("upsertUserNote")
              .getLinkedRecord("userNote");

            const edge = ConnectionHandler.createEdge(
              store,
              notes,
              note,
              "UserNoteEdge",
            );
            ConnectionHandler.insertEdgeBefore(notes, edge);
          }
        },
      });
    },
    [content, note, onClose, removeBackup, snackAlert, upsertUserNote, userId],
  );

  const isEmpty = isEmptyContent(content);

  return (
    <EditorDialog
      className={clsx(s.root, className)}
      classes={{
        container: s.container,
        content: s.content,
      }}
      title="Note"
      submitLabel="Save"
      onSubmit={handleSubmit}
      submitDisabled={isEmpty || pending}
      {...other}
    >
      <NoteEditor className={s.editor} value={content} onChange={setContent} />
    </EditorDialog>
  );
}
