/**
 * @generated SignedSource<<581e20e839876df1c460ea5d3e840c71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientAcceptInviteInvalidSignOutMutation$variables = Record<PropertyKey, never>;
export type ClientAcceptInviteInvalidSignOutMutation$data = {
  readonly signOut: string | null | undefined;
};
export type ClientAcceptInviteInvalidSignOutMutation = {
  response: ClientAcceptInviteInvalidSignOutMutation$data;
  variables: ClientAcceptInviteInvalidSignOutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signOut",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAcceptInviteInvalidSignOutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientAcceptInviteInvalidSignOutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2b2feb698737e421c3748305c788ea8c",
    "id": null,
    "metadata": {},
    "name": "ClientAcceptInviteInvalidSignOutMutation",
    "operationKind": "mutation",
    "text": "mutation ClientAcceptInviteInvalidSignOutMutation {\n  signOut\n}\n"
  }
};
})();

(node as any).hash = "0a15b4e5f17bd3d7e26e04580344764b";

export default node;
