import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { ChangeClientGoalOrdinalMutation } from "./__generated__/ChangeClientGoalOrdinalMutation.graphql";

const mutation = graphql`
  mutation ChangeClientGoalOrdinalMutation(
    $input: ChangeClientGoalOrdinalInput!
  ) {
    changeClientGoalOrdinal(input: $input) {
      goal {
        ...ClientGoal_goal
      }
      client {
        goals(first: 9999)
          @connection(key: "ClientDetails_goals", filters: []) {
          edges {
            cursor
          }
          ...ClientGoalsCard_goals
        }
      }
    }
  }
`;

export const useChangeClientGoalOrdinalMutation = () => {
  return useConfiguredMutation<ChangeClientGoalOrdinalMutation>(
    mutation,
    () => ({}),
  );
};
