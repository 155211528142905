import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation, graphql, useFragment } from "react-relay/hooks";

import { DatePicker } from "../fields/DatePicker";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { ISO_DATE_FORMAT } from "../../utils/date";

import { UpdateTrialExpirationDateDialog_user$key } from "./__generated__/UpdateTrialExpirationDateDialog_user.graphql";
import { UpdateTrialExpirationDateDialogMutation } from "./__generated__/UpdateTrialExpirationDateDialogMutation.graphql";

import { BaseDialog } from "./BaseDialog";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

const updateUserMutation = graphql`
  mutation UpdateTrialExpirationDateDialogMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UpdateTrialExpirationDateDialog_user
        ...UserDetails_user
      }
    }
  }
`;

const userFragment = graphql`
  fragment UpdateTrialExpirationDateDialog_user on User {
    id
    trialExpiryDate(raw: true)
  }
`;

export interface UpdateTrialExpirationDateDialogProps extends DialogProps {
  user: UpdateTrialExpirationDateDialog_user$key;
}

export function UpdateTrialExpirationDateDialog(
  props: UpdateTrialExpirationDateDialogProps,
) {
  const { className, user: userRef, onClose, ...other } = props;
  const user = useFragment(userFragment, userRef);
  const s = useStyles();
  const [date, setDate] = React.useState(new Date(user.trialExpiryDate));
  const [updateUser, updateInFlight] =
    useMutation<UpdateTrialExpirationDateDialogMutation>(updateUserMutation);
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();

  const handleDateChange = React.useCallback((date) => {
    setDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setDate(new Date());
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleUpdateClick = React.useCallback(() => {
    updateUser({
      variables: {
        input: {
          id: user.id,
          trialExpiryDate: dayjs(date).format(ISO_DATE_FORMAT),
        },
      },
      onCompleted(_, errors) {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: "Trial expiration date updated.",
          });
          handleClose();
        }
      },
      onError,
    });
  }, [date, handleClose, onError, snackAlert, updateUser, user.id]);

  const disabled = !date || updateInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Update trial expiration date"
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={date}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="Update trial expiration"
        onClick={handleUpdateClick}
        disabled={disabled}
      />
    </BaseDialog>
  );
}
