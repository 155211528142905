import React from "react";
import { NutritionTargetScreen } from "../../fields/NutritionTargetScreen";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as NutritionTargetIcon } from "../../../icons/NutritionTargets.svg";
import { colorSystem } from "../../../theme";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "react-relay";
import { BorderedBox } from "../elements/common/BorderedBox";
import { ICheckInQuestionPropsBase } from "./CheckInQuestions";
import { EditorElementView } from "../utils/editorUtils";
import { useParams } from "react-router-dom";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: 4,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },

  headerLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colorSystem.gray11,
    padding: theme.spacing(1.8, 2.3, 1.8, 1.2),
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },

  headerLeft: {
    display: "flex",
    alignItems: "center",
  },

  createButton: {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    fontSize: 16,
    fontWeight: 600,
    boxShadow: "none",
    height: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  headerInfo: {
    marginLeft: theme.spacing(2),
  },

  createButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: "25px!important",
  },

  chevronIcon: {
    transform: "rotate(-90deg)",
  },

  titleLine: {
    color: colorSystem.gray,
    textTransform: "uppercase",
  },

  placeholderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  placeholderItem: {
    width: "10%",
    height: 20,
    backgroundColor: theme.palette.text.secondary,
    opacity: 0.5,
    borderRadius: 5,
  },
}));

interface ICheckInNutritionTargetProps extends ICheckInQuestionPropsBase<any> {
  handleSetNode: (dataToSet: object) => void;
}
export type CheckInNutritionTargetValue = {};

export const CheckInNutritionTarget = ({
  handleSetNode,
  view,
}: ICheckInNutritionTargetProps) => {
  const s = useStyles();

  const handleAddNutrition = () => {
    if (handleSetNode) {
      handleSetNode({
        nutrition: "Nutrition target updated",
      });
    }
  };

  return view === EditorElementView.Coach ? (
    <BorderedBox px={{ xs: 1.5, sm: 2 }} py={{ xs: 1.5, sm: 2.5 }}>
      <Box className={s.header}>
        <Box className={s.headerLeft}>
          <NutritionTargetIcon />
          <Box className={s.headerInfo}>
            <Typography
              variant="h6"
              className={s.title}
              children="Nutrition Targets"
            />
            <Typography variant="body1" className={s.subtitle}>
              Last recorded ...
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap={5} flexDirection="column">
        <Box>
          <Box className={s.headerLine} onClick={() => {}}>
            <Typography
              children="Macro targets"
              variant="subtitle1"
              className={s.titleLine}
            />
          </Box>
          <Box className={s.placeholderContainer}>
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
          </Box>
        </Box>
        <Box pb={3}>
          <Box className={s.headerLine} onClick={() => {}}>
            <Typography
              children="Portion targets"
              variant="subtitle1"
              className={s.titleLine}
            />
          </Box>
          <Box className={s.placeholderContainer}>
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
            <Box className={s.placeholderItem} />
          </Box>
        </Box>
      </Box>
    </BorderedBox>
  ) : (
    <BorderedBox px={{ xs: 0, sm: 2 }} py={{ xs: 1, sm: 2.5 }}>
      <NutritionTargetScreen
        handleAddNutrition={handleAddNutrition}
        readonly={view !== EditorElementView.Client}
      />
    </BorderedBox>
  );
};
