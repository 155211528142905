/**
 * @generated SignedSource<<ae221e8e997b9ee7617b1e7b800ee4f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NutritionTargetScreenUsernameQuery$variables = {
  username: string;
};
export type NutritionTargetScreenUsernameQuery$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"NutritionTargetScreen_client">;
  } | null | undefined;
};
export type NutritionTargetScreenUsernameQuery = {
  response: NutritionTargetScreenUsernameQuery$data;
  variables: NutritionTargetScreenUsernameQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calories",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carbsGrams",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proteinGrams",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fatGrams",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(raw:true)"
},
v11 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCalories",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentFat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCarbs",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentProtein",
    "storageKey": null
  },
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientNutritionEntry",
    "kind": "LinkedField",
    "name": "clientNutritionEntry",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NutritionTargetScreenUsernameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NutritionTargetScreen_client"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NutritionTargetScreenUsernameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionTarget",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMM DD, YYYY"
                  }
                ],
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionMacroTarget",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionPortionTarget",
            "plural": true,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "213699eea85b7bdff3fad3b840690481",
    "id": null,
    "metadata": {},
    "name": "NutritionTargetScreenUsernameQuery",
    "operationKind": "query",
    "text": "query NutritionTargetScreenUsernameQuery(\n  $username: String!\n) {\n  user(username: $username) {\n    ...NutritionTargetScreen_client\n    id\n  }\n}\n\nfragment ClientNutritionCard_nutritionMacroTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  currentCalories\n  currentFat\n  currentCarbs\n  currentProtein\n  date(raw: true)\n  clientNutritionEntry {\n    id\n    carbsGrams\n    proteinGrams\n    fatGrams\n    calories\n    date\n  }\n}\n\nfragment ClientNutritionCard_nutritionPortionTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  currentCalories\n  currentFat\n  currentCarbs\n  currentProtein\n  date(raw: true)\n  clientNutritionEntry {\n    id\n    carbsGrams\n    proteinGrams\n    fatGrams\n    calories\n    date\n  }\n}\n\nfragment ClientNutritionCard_nutritionTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  date(raw: true)\n  updatedAt(format: \"MMM DD, YYYY\")\n}\n\nfragment NutritionTargetScreen_client on User {\n  nutritionTarget {\n    calories\n    ...ClientNutritionCard_nutritionTarget\n    id\n  }\n  nutritionMacroTarget {\n    ...ClientNutritionCard_nutritionMacroTarget\n    id\n  }\n  nutritionPortionTarget {\n    ...ClientNutritionCard_nutritionPortionTarget\n    id\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "3b863872c598e5c1e2585a56bd365b67";

export default node;
