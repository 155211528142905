import React from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  useEditorRef,
} from "@udecode/plate-common";

import { Box } from "@mui/material";
import { TabLabel } from "./TabLabel";
import makeStyles from "@mui/styles/makeStyles";
import { parseTabsListElement } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },

  tabs: {
    display: "flex",
    padding: 0,
    margin: 0,
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.quote,
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },

  tab: {},
  active: {
    fontWeight: "bold",
  },

  content: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    marginTop: -1,
  },

  label: {},
  input: {},
  accordion: {},
  menuOpen: {},

  menuButton: {
    position: "absolute",
    top: theme.spacing(2.25),
    right: 0,
    display: "none",

    "$root:hover &, $menuOpen &": {
      display: "block",
    },

    "$accordion &": {
      right: 0,
      left: "100%",
      paddingLeft: theme.spacing(1),
    },
  },
}));

export const ELEMENT_TABS = "tabs_list";
export const ELEMENT_ACCORDION = "accordion_list";

export const TabsElement = withRef<typeof PlateElement>(({ ...props }, ref) => {
  const { children, element } = props;
  const editor = useEditorRef();
  const path = findNodePath(editor, element);
  if (!path) return;

  const { tabs, selected, variant } = parseTabsListElement(element as any);
  const s = useStyles();

  return (
    <PlateElement
      ref={ref}
      {...props}
      className="rounded-lg border"
      style={{ marginBlock: ".5rem" }}
    >
      <Box contentEditable={false}>
        <Box className={s.tabs}>
          {variant === "tabs" &&
            tabs.map((tab) => (
              <TabLabel
                key={tab.id}
                element={tab}
                parent={element}
                parentId={element.id}
              />
            ))}
        </Box>
      </Box>
      <div className="border-t">{children}</div>
    </PlateElement>
  );
});
