/**
 * @generated SignedSource<<d78ff4ba582cf5f14a35121aaebb5369>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClientStatus = "ACTIVE" | "ADDED" | "ARCHIVED" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InviteDialog_invitedUsers$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly client: {
        readonly clientStatus: ClientStatus;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly totalCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"PendingInvitesList_invites">;
  readonly " $fragmentType": "InviteDialog_invitedUsers";
};
export type InviteDialog_invitedUsers$key = {
  readonly " $data"?: InviteDialog_invitedUsers$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteDialog_invitedUsers">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteDialog_invitedUsers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InviteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Invite",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clientStatus",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PendingInvitesList_invites"
    }
  ],
  "type": "InviteConnection",
  "abstractKey": null
};

(node as any).hash = "480e219f32837a9f5dc0bee77d1960a8";

export default node;
