export enum ElementType {
  H1 = "h1",
  H2 = "h2",
  PARAGRAPH = "paragraph",
  MESSAGE = "message",

  LINK = "link",
  BOLD = "bold",
  ITALIC = "italic",
  STRIKETHROUGH = "strikethrough",
  HIGHLIGHT = "highlight",

  BLOCKQUOTE = "blockquote",
  PULLQUOTE = "pullquote",

  NUMBERED_LIST = "numbered_list",
  BULLETED_LIST = "bulleted_list",
  LIST_ITEM = "list_item",
  CHECKLIST = "checklist",
  CHECKLIST_ITEM = "checklist_item",
  FILE = "file",
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
  EMOJI = "emoji",
  GIF = "gif",
  ATTACHMENT = "attachment",

  CHECKIN_ANSWER_BINARY = "checkin_answer_binary",
  CHECKIN_ANSWER_MEASUREMENT = "checkin_answer_measurement",
  CHECKIN_ANSWER_PROGRESS_PHOTO = "checkin_answer_progress_photo",
  CHECKIN_ANSWER_RATING_SCALE = "checkin_answer_rating_scale",
  CHECKIN_ANSWER_LONG_TEXT = "checkin_answer_long_text",
  CHECKIN_ANSWER_SHORT_TEXT = "checkin_answer_short_text",
  CHECKIN_ANSWER_NUMBER = "checkin_answer_number",
  CHECKIN_ANSWER_FILE = "checkin_answer_file",
  CHECKIN_ANSWER_MULTIPLE_CHOICE = "checkin_answer_multiple_choice",
  CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX = "checkin_answer_multiple_choice_checkbox",
  CHECKIN_ANSWER_DATE = "checkin_answer_date",
  CHECKIN_ANSWER_EMOJI = "checkin_answer_emoji",
  CHECKIN_ANSWER_STAR_RATING = "checkin_answer_star_rating",
  CHECKIN_ANSWER_NUTRITION_TARGET = "checkin_answer_nutrition_target",

  CALLOUT = "callout",
  PREVIEW_LINK = "preview_link",
  TABS_LIST = "tabs_list",
  TABS_ITEM = "tabs_item",
  ACCORDION_LIST = "accordion_list",

  DIVIDER = "divider",
  MEDIA_CAPTION = "MEDIA_CAPTION",

  WORKOUT_SECTION = "workout_section",

  TABLE = "table",
  TABLE_HEAD_ROW = "table_head_row",
  TABLE_HEAD_CELL = "table_head_cell",
  TABLE_ROW = "table_row",
  TABLE_CELL = "table_cell",

  WEB_BOOKMARK = "web_bookmark",
  EMBED_WEBSITE = "embed_website",
}

export const TEXT_ELEMENT_TYPES = [
  ElementType.H1,
  ElementType.H2,
  ElementType.PARAGRAPH,
];

export const LIST_ELEMENT_TYPES = [
  ElementType.NUMBERED_LIST,
  ElementType.BULLETED_LIST,
  ElementType.CHECKLIST,
];

export type MediaElementType =
  | ElementType.FILE
  | ElementType.IMAGE
  | ElementType.AUDIO
  | ElementType.VIDEO;

export const MEDIA_ELEMENT_TYPES = [
  ElementType.FILE,
  ElementType.IMAGE,
  ElementType.AUDIO,
  ElementType.VIDEO,
];

export const CHECKIN_ELEMENT_TYPES = [
  ElementType.CHECKIN_ANSWER_BINARY,
  ElementType.CHECKIN_ANSWER_MEASUREMENT,
  ElementType.CHECKIN_ANSWER_PROGRESS_PHOTO,
  ElementType.CHECKIN_ANSWER_RATING_SCALE,
  ElementType.CHECKIN_ANSWER_LONG_TEXT,
  ElementType.CHECKIN_ANSWER_SHORT_TEXT,
  ElementType.CHECKIN_ANSWER_NUMBER,
  ElementType.CHECKIN_ANSWER_FILE,
  ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE,
  ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX,
  ElementType.CHECKIN_ANSWER_DATE,
  ElementType.CHECKIN_ANSWER_EMOJI,
  ElementType.CHECKIN_ANSWER_STAR_RATING,
  ElementType.CHECKIN_ANSWER_NUTRITION_TARGET,
];

export const VOID_ELEMENT_TYPE = [
  ...MEDIA_ELEMENT_TYPES,
  ...CHECKIN_ELEMENT_TYPES,
  ElementType.EMOJI,
  ElementType.WEB_BOOKMARK,
  ElementType.EMBED_WEBSITE,
];

export enum FormatType {
  FORMAT = "format",
  BLOCK = "block",
}
