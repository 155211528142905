import clsx from "clsx";
import React from "react";
import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { CalendarComponentCard } from "../card/CalendarComponentCard";
import { ProgramCalendarComponent_component$key } from "./__generated__/ProgramCalendarComponent_component.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: "100%",
    height: "100%",
    boxSizing: "content-box",
  },
}));

const componentFragment = graphql`
  fragment ProgramCalendarComponent_component on Component {
    type
    slug
    ...CalendarComponentCard_component
  }
`;

export interface ProgramCalendarComponentProps
  extends Omit<BoxProps, "component"> {
  component: ProgramCalendarComponent_component$key;
  onTitleClick: (slug?: string, typeName?: string) => void;
  activeComponentSlug?: string;
}

export function ProgramCalendarComponent(props: ProgramCalendarComponentProps) {
  const {
    className,
    component: componentRef,
    onTitleClick,
    activeComponentSlug,
  } = props;
  const component = useFragment(componentFragment, componentRef);
  const s = useStyles();

  return (
    <CalendarComponentCard
      className={clsx(s.root, className)}
      component={component}
      onTitleClick={() => onTitleClick(component.slug, component.type)}
      isHighlighted={component.slug === activeComponentSlug}
    />
  );
}
