/**
 * @generated SignedSource<<d0376d41d9a6f23f71f264b1bce5456d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NutritionTargetType = "ALL_DAYS" | "OFF_DAYS" | "REFEED_DAYS" | "TRAINING_DAYS" | "WEEKDAYS" | "WEEKENDS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientNutritionCard_autoNutritionMacroTarget$data = ReadonlyArray<{
  readonly calories: number | null | undefined;
  readonly carbsGrams: number | null | undefined;
  readonly fatGrams: number | null | undefined;
  readonly id: string;
  readonly proteinGrams: number | null | undefined;
  readonly targetType: NutritionTargetType;
  readonly " $fragmentType": "ClientNutritionCard_autoNutritionMacroTarget";
}>;
export type ClientNutritionCard_autoNutritionMacroTarget$key = ReadonlyArray<{
  readonly " $data"?: ClientNutritionCard_autoNutritionMacroTarget$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_autoNutritionMacroTarget">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientNutritionCard_autoNutritionMacroTarget",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carbsGrams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proteinGrams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fatGrams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calories",
      "storageKey": null
    }
  ],
  "type": "ClientNutritionTarget",
  "abstractKey": null
};

(node as any).hash = "22e49a2a92187a85da71fc054fbdfe94";

export default node;
