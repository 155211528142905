/**
 * @generated SignedSource<<b4b2c25c05717a7a09a79886661aac23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ClientStatus = "ACTIVE" | "ADDED" | "ARCHIVED" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachClientsListScreen_root$data = {
  readonly allClients?: {
    readonly edges: ReadonlyArray<{
      readonly client: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"CoachMessageClientsDialog_clients">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly clients: {
    readonly edges: ReadonlyArray<{
      readonly client: {
        readonly id: string;
        readonly invite: {
          readonly client: {
            readonly clientStatus: ClientStatus;
            readonly id: string;
          } | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ClientPendingCard_invite">;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ClientCard_client" | "ClientMenu_client" | "CoachMessageClientsDialog_clients">;
      } | null | undefined;
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly pageCursors: {
      readonly " $fragmentSpreads": FragmentRefs<"ConnectionPagination_pageCursors">;
    };
    readonly totalCount: number;
  } | null | undefined;
  readonly inviteCode: {
    readonly " $fragmentSpreads": FragmentRefs<"InviteDialog_inviteCode">;
  } | null | undefined;
  readonly tags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CoachClientsListScreen_root";
};
export type CoachClientsListScreen_root$key = {
  readonly " $data"?: CoachClientsListScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsListScreen_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CoachMessageClientsDialog_clients"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeAllClients"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "status"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./CoachClientsListScreenRefetchQuery.graphql')
    }
  },
  "name": "CoachClientsListScreen_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagConnection",
      "kind": "LinkedField",
      "name": "tags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TagEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        },
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "tagId",
          "variableName": "tagId"
        }
      ],
      "concreteType": "ClientConnection",
      "kind": "LinkedField",
      "name": "clients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageCursors",
          "kind": "LinkedField",
          "name": "pageCursors",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ConnectionPagination_pageCursors"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": "client",
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ClientCard_client"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ClientMenu_client"
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Invite",
                  "kind": "LinkedField",
                  "name": "invite",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "clientStatus",
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ClientPendingCard_invite"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "includeAllClients",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "allClients",
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 9999
            },
            (v1/*: any*/)
          ],
          "concreteType": "ClientConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": "client",
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InviteCode",
      "kind": "LinkedField",
      "name": "inviteCode",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InviteDialog_inviteCode"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};
})();

(node as any).hash = "2d10f74d1a6b312cf759d058a690d7c8";

export default node;
