/**
 * @generated SignedSource<<cce0608c9e90496f3ce4024994dd5934>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type PlanTierType = "BUILD" | "DEFAULT" | "LAUNCH" | "SCALE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachClientsListScreen_user$data = {
  readonly clientsCountNoSample: number;
  readonly plan: Plan | null | undefined;
  readonly planTier: PlanTierType | null | undefined;
  readonly " $fragmentType": "CoachClientsListScreen_user";
};
export type CoachClientsListScreen_user$key = {
  readonly " $data"?: CoachClientsListScreen_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsListScreen_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientsListScreen_user",
  "selections": [
    {
      "alias": "clientsCountNoSample",
      "args": [
        {
          "kind": "Literal",
          "name": "noSample",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "clientsCount",
      "storageKey": "clientsCount(noSample:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planTier",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "cf37ee264dc95bdfd402de7e2f6c0486";

export default node;
