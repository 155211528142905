/**
 * @generated SignedSource<<41de456fd39ada7f38d3783bc6fcaba7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageGroupDialog_group$data = {
  readonly clients: ReadonlyArray<{
    readonly displayName: string | null | undefined;
    readonly email: string | null | undefined;
  }>;
  readonly id: string;
  readonly name: string;
  readonly size: number;
  readonly " $fragmentType": "ManageGroupDialog_group";
};
export type ManageGroupDialog_group$key = {
  readonly " $data"?: ManageGroupDialog_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageGroupDialog_group">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageGroupDialog_group",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "clients",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "67d87672d02046aa82a33f7464ac96c1";

export default node;
