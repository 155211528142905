/**
 * @generated SignedSource<<dadd2cede1096704050234466a071181>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientMeasurementRows_metrics$data = ReadonlyArray<{
  readonly activityDate: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly value: {
    readonly measurement?: number;
    readonly unit?: MeasurementUnit;
  };
  readonly " $fragmentType": "ClientMeasurementRows_metrics";
}>;
export type ClientMeasurementRows_metrics$key = ReadonlyArray<{
  readonly " $data"?: ClientMeasurementRows_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementRows_metrics">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientMeasurementRows_metrics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "activityDate",
      "storageKey": "activityDate(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "measurement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "type": "CheckinAnswerMeasurementValue",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientMetric",
  "abstractKey": null
};

(node as any).hash = "48fdcc6d6bfd38e807c41d48ed5a7c7f";

export default node;
