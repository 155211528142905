import clsx from "clsx";
import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { tableStyles } from "./tableStyle";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: colorSystem.white2,
    padding: theme.spacing(2, 1),
  },

  tableHeaderText: {
    color: colorSystem.gray,
    fontSize: 14,
    fontWeight: 700,
  },

  flex: {
    display: "flex",
  },

  comments: {
    paddingLeft: theme.spacing(1),
  },
}));

export function ClientNutritionTargetListTableHeader() {
  const t = tableStyles();
  const s = useStyles();

  return (
    <Box className={clsx(s.flex, s.tableHeader)}>
      <Box className={t.tableTarget}>
        <Typography
          variant="body1"
          children="Target"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={t.tableDate}>
        <Typography
          variant="body1"
          children="Start date"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={t.tableProtein}>
        <Typography
          variant="body1"
          children="Protein"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={t.tableCarbs}>
        <Typography
          variant="body1"
          children="Carbs"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={t.tableFat}>
        <Typography
          variant="body1"
          children="Fat"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={t.tableCalories}>
        <Typography
          variant="body1"
          children="Kcal"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={clsx(t.tableComments, s.comments)}>
        <Typography
          variant="body1"
          children="Comments"
          className={s.tableHeaderText}
        />
      </Box>
      <Box className={t.tableAction}>
        <Typography variant="body1" children="" className={s.tableHeaderText} />
      </Box>
    </Box>
  );
}
