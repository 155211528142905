/**
 * @generated SignedSource<<78e2d1b98793e8872c65b64665a54990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GroupPostsListItem_post$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly id: string;
    readonly role: UserRole;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_user" | "GroupMemberBadges_user">;
  };
  readonly content: string;
  readonly createdAt: string | null | undefined;
  readonly groupId: string;
  readonly id: string;
  readonly notification: {
    readonly read: boolean;
    readonly userId: string;
  } | null | undefined;
  readonly title: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsList_post" | "GroupPostMenu_post" | "GroupPostsCommentForm_post" | "Likeable_likeable">;
  readonly " $fragmentType": "GroupPostsListItem_post";
};
export type GroupPostsListItem_post$key = {
  readonly " $data"?: GroupPostsListItem_post$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsListItem_post">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostsListItem_post",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupPostsCommentForm_post"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupPostCommentsList_post"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Likeable_likeable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupPostMenu_post"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Notification",
      "kind": "LinkedField",
      "name": "notification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "read",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GroupMemberBadges_user"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GroupPost",
  "abstractKey": null
};
})();

(node as any).hash = "fa04a4bb6a1de9f0ed2b9443536bfa2e";

export default node;
