import React, { FC, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import { ReactComponent as SaveIcon } from "../../icons/CheckMarkCircle2.svg";
import { floatNumberInputProps } from "../../constants";
import { useClient } from "../../hooks/useClient";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.1),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  actionIconButton: {
    color: theme.palette.text.secondary,
    "& svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
    },
  },

  date: {
    fontSize: 16,
    color: theme.palette.text.primary,
  },
}));

const deleteAutoNutritionMutation = graphql`
  mutation ClientAutoNutritionEntryHistoryItemDeleteMutation(
    $input: DeleteAutoNutritionInput!
  ) {
    deleteAutoNutrition(input: $input) {
      clientMutationId
    }
  }
`;

const upsertAutoNutritionMutation = graphql`
  mutation ClientAutoNutritionEntryHistoryItemUpdateMutation(
    $input: UpsertAutoNutritionInput!
  ) {
    upsertAutoNutrition(input: $input) {
      autoNutrition {
        id
      }
    }
  }
`;

export interface ClientAutoNutritionEntryHistoryItemProps extends BoxProps {
  entry: any;
  refresh: any;
}

export const ClientAutoNutritionEntryHistoryItem: FC<
  ClientAutoNutritionEntryHistoryItemProps
> = (props) => {
  const { entry: defaultValue, refresh, ...restProps } = props;
  const [entry, setEntry] = useState(defaultValue);
  const [dirty, setDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const s = useStyles(open);

  const client: any = useClient();

  const snackAlert = useSnackAlert();

  const [deleteAutoNutrition] = useMutation(deleteAutoNutritionMutation);
  const [updateAutoNutrition] = useMutation(upsertAutoNutritionMutation);

  const handleDelete = React.useCallback(() => {
    deleteAutoNutrition({
      variables: {
        input: { id: entry.id },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.error(errors);
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          refresh?.();
          snackAlert({
            severity: "success",
            message: "Delete successfully",
          });
        }
      },
    });
  }, [entry, refresh, snackAlert, deleteAutoNutrition]);

  const handleSave = React.useCallback(() => {
    const { date, ...inputData } = entry;
    updateAutoNutrition({
      variables: {
        input: inputData,
      },
      onCompleted: (data: any, errors) => {
        if (errors) {
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Success",
          });
          refresh?.();
        }
      },
    });
  }, [updateAutoNutrition, entry, refresh, snackAlert]);

  const handleChangeField = React.useCallback(
    (field) => {
      return (event) => {
        setDirty(true);
        const newValueString = event.target.value;
        let newValue;
        if (newValueString) {
          newValue = parseFloat(newValueString);
          if (!Number.isFinite(newValue)) {
            return;
          }
        } else {
          newValue = null;
        }
        setEntry({
          ...entry,
          [field]: newValue,
        });
      };
    },
    [setEntry, entry],
  );

  React.useEffect(() => {
    setEntry(defaultValue);
  }, [defaultValue]);

  return (
    <Box py={1} {...restProps}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Button
            fullWidth
            variant="text"
            onClick={() => setOpen(!open)}
            style={{ justifyContent: "flex-start" }}
          >
            <Typography className={s.date} variant="h6">
              {dayjs(entry.date, "YYYY-MM-DD").format("MMM, DD YYYY")}
            </Typography>
          </Button>
        </Grid>
        {!client && (
          <Grid item>
            <IconButton
              className={s.actionIconButton}
              onClick={handleSave}
              disabled={!dirty}
              size="large"
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              className={s.actionIconButton}
              onClick={handleDelete}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {open && (
        <Grid container>
          {[
            {
              field: "calories",
              label: "Calories",
            },
            {
              field: "carbs",
              label: "Carbs",
            },
            {
              field: "fat",
              label: "Fat",
            },
            {
              field: "protein",
              label: "Protein",
            },
            {
              field: "cholesterol",
              label: "Cholesterol",
            },
            {
              field: "fiber",
              label: "Fiber",
            },
            {
              field: "sugar",
              label: "Sugar",
            },
            {
              field: "sodium",
              label: "Sodium",
            },
            {
              field: "transFat",
              label: "Trans Fat",
            },
            {
              field: "alcohol",
              label: "Alcohol",
            },
          ].map(({ field, label }) => (
            <Grid item xs={6} key={field}>
              <Box p={1}>
                <Typography variant="body1" className={s.label}>
                  {label}
                </Typography>
                <TextField
                  variant="standard"
                  value={entry[field]}
                  onChange={handleChangeField(field)}
                  className={s.input}
                  error={+entry[field] < 0}
                  type="number"
                  InputProps={{
                    inputProps: floatNumberInputProps,
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};
