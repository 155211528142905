/**
 * @generated SignedSource<<4df16c4ce65b0663b19754ce7095a6c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChooseProgramEnrollDialog_client$data = {
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly " $fragmentType": "ChooseProgramEnrollDialog_client";
};
export type ChooseProgramEnrollDialog_client$key = {
  readonly " $data"?: ChooseProgramEnrollDialog_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChooseProgramEnrollDialog_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChooseProgramEnrollDialog_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6ec43a048de36043fc3571a8f6d6f9ba";

export default node;
