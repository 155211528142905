import { Box, useTheme, Menu, MenuItem, Divider, alpha } from "@mui/material";
import React, { useState } from "react";
import { ColumnField } from "../../utils";
import {
  DefaultExerciseTypeExtra,
  ExerciseTypeExtra,
  ExerciseTypeSet,
  getExerciseUnits,
  setUnitsTypeOptions,
  setUnitTimeTypeOptions,
  Units,
  UnitsTime,
} from "../../../../constants";
import { iconsHoverStyling } from "../DragCell/DragCellRenderer";
import { capitalize } from "lodash";
import {
  getExerciseLocalStorageConfig,
  updateExerciseLocalStorageConfig,
} from "../../../../utils/local-storage";
import { WorkoutSection } from "../../../workout/types";
import SetCellText from "./SetCellText";
import { Trash2 } from "lucide-react";

export const ExerciseTypeExtraOptions = [
  {
    label: "RPE",
    value: ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION,
  },
  {
    label: "RIR",
    value: ExerciseTypeExtra.REPS_IN_RESERVE,
  },
  {
    label: <Trash2 size={18} />,
    value: ExerciseTypeExtra.NONE,
  },
];

// this value used to avoid blink on extra type drop,
// when instead of NONE shown extra type from last action
export const BLANK_SELECT_VALUE = " ";

interface ISetCellSelectProps {
  inputKey: ColumnField;
  data: any;
  handleExerciseUpdate: (
    workout: WorkoutSection,
    content?: any,
    wsId?: any,
    id?: any,
    onCompleteCallback?: () => void,
  ) => void;
  preventUpdateRequest?: boolean;
}

const SetCellSelect = (props: ISetCellSelectProps) => {
  const { inputKey, data, handleExerciseUpdate, preventUpdateRequest } = props;
  const key = inputKey;
  const theme = useTheme();

  const [unitsMenuAnchorEl, setUnitsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const openUnitsDropdownMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUnitsMenuAnchorEl(event.currentTarget);
  };

  const handleUnitChange = (
    typeSet?: ExerciseTypeSet,
    value?: Units | UnitsTime,
  ) => {
    setUnitsMenuAnchorEl(null);

    if (!typeSet || !value) {
      return;
    }

    data.typeSet = typeSet;
    data.units = value;

    !preventUpdateRequest &&
      handleExerciseUpdate(
        {
          ...data.workoutSectionData,
          exercises: data.exercises.map((e) => ({
            ...e,
            ...(e.id === data.exerciseData.id && { typeSet, units: value }),
          })),
        },
        data.componentContent,
        data.workoutSectionData.id,
        data.componentId,
      );

    updateExerciseLocalStorageConfig({
      typeSet,
      units: value,
    });
  };

  const handleExtraTypeChange = (newValue: ExerciseTypeExtra) => {
    setUnitsMenuAnchorEl(null);

    if (!newValue && !data.typeExtraMeasurement) {
      return;
    }

    const isNewValueNone = newValue === ExerciseTypeExtra.NONE;
    data.typeExtraMeasurement = isNewValueNone ? BLANK_SELECT_VALUE : newValue;

    // preventUpdateRequest used to avoid race with text editor request,
    // but only in case of NONE (drop extra type), we performed it here
    (!preventUpdateRequest || isNewValueNone) &&
      handleExerciseUpdate(
        {
          ...data.workoutSectionData,
          exercises: data.exercises.map((e) => ({
            ...e,
            ...(e.id === data.exerciseData.id && {
              typeExtraMeasurement:
                newValue === ExerciseTypeExtra.NONE ? undefined : newValue,
              sets:
                newValue === ExerciseTypeExtra.NONE
                  ? e.sets.map(({ extraMeasurement, ...set }) => set)
                  : e.sets,
            }),
          })),
        },
        data.componentContent,
        data.workoutSectionData.id,
        data.componentId,
      );

    updateExerciseLocalStorageConfig({
      ...(newValue !== ExerciseTypeExtra.NONE && {
        typeExtraMeasurement: newValue,
      }),
    });
  };

  const getMenuItems = (key: ColumnField, currentValue: any) => {
    switch (key) {
      case ColumnField.WEIGHT:
        return (
          <>
            {Object.values(ExerciseTypeSet).map((typeSet, i) => (
              <>
                {i !== 0 && <Divider style={{ margin: 0 }} />}
                {typeSet === ExerciseTypeSet.TIME
                  ? setUnitTimeTypeOptions.map((item) => {
                      const isSelected =
                        currentValue === getExerciseUnits(typeSet, item.value);
                      return (
                        <MenuItem
                          value={typeSet}
                          onClick={() =>
                            !isSelected && handleUnitChange(typeSet, item.value)
                          }
                          sx={{
                            fontWeight: 500,
                            background: isSelected
                              ? alpha(theme.palette.common.black, 0.04)
                              : "unset",
                            ":hover": {
                              background: alpha(
                                theme.palette.common.black,
                                0.06,
                              ),
                            },
                          }}
                        >
                          <Box
                            sx={{
                              color: theme.palette.divider,
                              fontWeight: "bold",
                              mr: 3,
                            }}
                          >
                            {capitalize(typeSet)}
                          </Box>
                          <Box
                            sx={{
                              textTransform: "lowercase",
                              ml: "auto",
                            }}
                          >
                            {item.labelShortened}
                          </Box>
                        </MenuItem>
                      );
                    })
                  : setUnitsTypeOptions.map((item) => {
                      const isSelected =
                        currentValue === getExerciseUnits(typeSet, item.value);
                      return (
                        <MenuItem
                          value={typeSet}
                          onClick={() =>
                            !isSelected && handleUnitChange(typeSet, item.value)
                          }
                          sx={{
                            fontWeight: 500,
                            background: isSelected
                              ? alpha(theme.palette.common.black, 0.04)
                              : "unset",
                            ":hover": {
                              background: alpha(
                                theme.palette.common.black,
                                0.06,
                              ),
                            },
                          }}
                        >
                          <Box
                            sx={{
                              color: theme.palette.divider,
                              fontWeight: "bold",
                              mr: 3,
                            }}
                          >
                            {capitalize(typeSet)}
                          </Box>
                          <Box
                            sx={{
                              textTransform: "lowercase",
                              ml: "auto",
                            }}
                          >
                            {getExerciseUnits(typeSet, item.value)}
                          </Box>
                        </MenuItem>
                      );
                    })}
              </>
            ))}
          </>
        );
      case ColumnField.EXTRA_MEASUREMENT:
        return (
          <>
            {ExerciseTypeExtraOptions.map((option) => (
              <MenuItem
                value={option.value}
                onClick={() =>
                  currentValue === option.value
                    ? setUnitsMenuAnchorEl(null)
                    : currentValue === undefined
                      ? option.value === ExerciseTypeExtra.NONE
                        ? setUnitsMenuAnchorEl(null)
                        : handleExtraTypeChange(option.value)
                      : handleExtraTypeChange(option.value)
                }
                disabled={
                  option.value === ExerciseTypeExtra.NONE &&
                  ([ExerciseTypeExtra.NONE, undefined].includes(currentValue) ||
                    [ExerciseTypeExtra.NONE, undefined].includes(
                      data.exerciseData.typeExtraMeasurement,
                    ))
                }
                sx={{
                  fontWeight: 500,
                  background:
                    currentValue === option.value
                      ? alpha(theme.palette.common.black, 0.04)
                      : "unset",
                  ":hover": {
                    background: alpha(theme.palette.common.black, 0.06),
                  },
                  justifyContent: "center",
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </>
        );
      default:
        return <></>;
    }
  };

  const getMenuAnchorElementTitle = (key: ColumnField) => {
    switch (key) {
      case ColumnField.WEIGHT:
        return getExerciseUnits(data.typeSet, data.units);
      case ColumnField.EXTRA_MEASUREMENT:
        const value =
          data.typeExtraMeasurement === "" ||
          data.typeExtraMeasurement === undefined ||
          data.typeExtraMeasurement === ExerciseTypeExtra.NONE
            ? getExerciseLocalStorageConfig().typeExtraMeasurement ??
              DefaultExerciseTypeExtra
            : data.typeExtraMeasurement;
        return value;
      default:
        return "";
    }
  };

  const getMenuCurrentValue = (key: ColumnField) => {
    switch (key) {
      case ColumnField.WEIGHT:
        return getExerciseUnits(data.typeSet, data.units);
      case ColumnField.EXTRA_MEASUREMENT:
        const value =
          data.typeExtraMeasurement !== "" || Boolean(data.typeExtraMeasurement)
            ? data.typeExtraMeasurement
            : ExerciseTypeExtra.NONE;
        return value;
      default:
        return "";
    }
  };

  return (
    <>
      <Box onClick={openUnitsDropdownMenu} sx={{ mr: -0.5 }}>
        <SetCellText
          secondary
          sx={{
            ...iconsHoverStyling(theme, false),
            p: 1,
            borderRadius: 2,
          }}
        >
          {getMenuAnchorElementTitle(key)}
        </SetCellText>
      </Box>
      <Menu
        disablePortal
        anchorEl={unitsMenuAnchorEl}
        open={!!unitsMenuAnchorEl}
        onClose={() => handleUnitChange()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              ml: key === ColumnField.WEIGHT ? "27px" : "34px",
            },
          },
        }}
      >
        {getMenuItems(key, getMenuCurrentValue(key))}
      </Menu>
    </>
  );
};

export default SetCellSelect;
