import { createContext, useContext } from "react";
import { IMPERSONATION_BANNER_SPACING } from "../components/app/ImpersonationBanner";
import { UPGRADE_BANNER_SPACING } from "../components/app/UpgradeBanner";
import { PROMO_BANNER_SPACING } from "../components/app/PromoBanner";

export enum BannerType {
  PROMO = "promo",
  UPGRADE = "upgrade",
  IMPERSONATION = "impersonation",
}

export interface IBannerContextProps {
  isBannerShown: boolean;
  bannerType: BannerType;
}

const BannersContext = createContext<IBannerContextProps | undefined>(
  undefined,
);

export function BannerContextData(): IBannerContextProps {
  return useContext(BannersContext);
}

export const getBannerHeight = () => {
  switch (BannerContextData().bannerType) {
    case BannerType.IMPERSONATION:
      return IMPERSONATION_BANNER_SPACING;
    case BannerType.PROMO:
      return PROMO_BANNER_SPACING;
    case BannerType.UPGRADE:
      return UPGRADE_BANNER_SPACING;

    default:
      return 0;
  }
};

export default BannersContext;
