import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  IconButton,
  IconButtonProps,
  ButtonProps,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { graphql, useFragment } from "react-relay";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation } from "react-relay/lib/hooks";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { InviteLinkSettingsGenerateInviteCodeMutation } from "./__generated__/InviteLinkSettingsGenerateInviteCodeMutation.graphql";
import { InviteLinkSettingsDeleteInviteCodeMutation } from "./__generated__/InviteLinkSettingsDeleteInviteCodeMutation.graphql";
import { InviteLinkSettings_inviteCode$key } from "./__generated__/InviteLinkSettings_inviteCode.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },

  textLink: {},

  inviteLinkField: {
    flexGrow: 1,

    "$textLink &": {
      position: "absolute",
      left: -1000,
      top: -1000,
      opacity: 0,
    },
  },

  inviteLinkText: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
  },

  generateLinkButton: {
    width: "45%",
  },

  copyLinkButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  deleteLinkButton: {
    color: theme.palette.text.secondary,
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),

    "[disabled] &": {
      color: theme.palette.text.secondary,
    },
  },
}));

const generateInviteCodeMutation = graphql`
  mutation InviteLinkSettingsGenerateInviteCodeMutation(
    $input: GenerateInviteCodeInput!
  ) {
    generateInviteCode(input: $input) {
      inviteCode {
        code
        program {
          ...ProgramSettings_program
        }
      }
    }
  }
`;

const deleteInviteCodeMutation = graphql`
  mutation InviteLinkSettingsDeleteInviteCodeMutation(
    $input: DeleteInviteCodeInput!
  ) {
    deleteInviteCode(input: $input) {
      deleted
    }
  }
`;

const inviteCodeFragment = graphql`
  fragment InviteLinkSettings_inviteCode on InviteCode {
    id
    code
  }
`;

export interface InviteLinkSettingsProps extends BoxProps {
  inviteCode: InviteLinkSettings_inviteCode$key;
  groupId?: string;
  programId?: string;
  textLink?: boolean;
  LinkFieldProps?: TextFieldProps;
  CopyButtonProps?: ActionButtonProps;
  RemoveButtonProps?: IconButtonProps;
  GenerateButtonProps?: ActionButtonProps;
  hideRemoveButton?: boolean;
  disabled?: boolean;
}

export function InviteLinkSettings(props: InviteLinkSettingsProps) {
  const {
    className,
    groupId,
    programId,
    textLink,
    inviteCode: inviteCodeRef,
    LinkFieldProps,
    CopyButtonProps,
    RemoveButtonProps,
    GenerateButtonProps,
    hideRemoveButton = false,
    ...other
  } = props;
  const inviteCode = useFragment(inviteCodeFragment, inviteCodeRef);
  const s = useStyles();
  const [code, setCode] = React.useState(inviteCode?.code || "");
  const inputRef = React.useRef<HTMLInputElement>();
  const onError = useGenericErrorHandler();
  const codeId = inviteCode?.id;

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    React.useState(false);

  const inviteLink = React.useMemo(() => {
    if (code) {
      return `${window.location.origin}/invite/${code}`;
    }
  }, [code]);

  const [generateInviteCode, generatingInviteCode] =
    useMutation<InviteLinkSettingsGenerateInviteCodeMutation>(
      generateInviteCodeMutation,
    );
  const [deleteInviteCode, deletingInviteCode] =
    useMutation<InviteLinkSettingsDeleteInviteCodeMutation>(
      deleteInviteCodeMutation,
    );

  const handleCopyLink: ButtonProps["onClick"] = React.useCallback(() => {
    inputRef.current.select();
    document.execCommand("copy");
    inputRef.current.blur();
  }, []);

  const handleGenerateLink: ButtonProps["onClick"] = React.useCallback(() => {
    generateInviteCode({
      variables: {
        input: {
          groupId,
          programId,
        },
      },
      onCompleted(result, errors) {
        if (errors && errors[0]) {
          onError(errors[0]);
        } else {
          setCode(result.generateInviteCode.inviteCode.code);
        }
      },
      onError,
      updater: (store) => {
        const node = store.get(groupId || "client:root");
        const inviteCode = store
          .getRootField("generateInviteCode")
          .getLinkedRecord("inviteCode");

        if (node && inviteCode) {
          node.setLinkedRecord(inviteCode, "inviteCode");
        }
      },
    });
  }, [generateInviteCode, groupId, onError, programId]);

  const handleDeleteLink: ButtonProps["onClick"] = React.useCallback(() => {
    setConfirmDeleteDialogOpen(true);
  }, []);

  const handleCancelDelete = React.useCallback(() => {
    setConfirmDeleteDialogOpen(false);
  }, []);

  const handleConfirmDelete = React.useCallback(() => {
    deleteInviteCode({
      variables: {
        input: {
          groupId,
          programId,
        },
      },
      onCompleted(_result, errors) {
        if (errors && errors[0]) {
          onError(errors[0]);
        } else {
          setCode("");
          setConfirmDeleteDialogOpen(false);
        }
      },
      onError,
      updater: (store) => {
        store.delete(codeId);
      },
    });
  }, [deleteInviteCode, groupId, programId, onError, codeId]);

  const disabled = generatingInviteCode || deletingInviteCode || props.disabled;

  return inviteLink ? (
    <Box className={clsx(s.root, textLink && s.textLink, className)} {...other}>
      {textLink && (
        <Typography className={s.inviteLinkText}>{inviteLink}</Typography>
      )}
      <TextField
        className={s.inviteLinkField}
        variant="outlined"
        value={inviteLink}
        contentEditable={false}
        inputRef={inputRef}
        disabled={disabled}
        {...LinkFieldProps}
      />
      <ActionButton
        className={s.copyLinkButton}
        size="large"
        children="Copy link"
        onClick={handleCopyLink}
        {...CopyButtonProps}
      />

      {!hideRemoveButton && (
        <>
          <IconButton
            className={s.deleteLinkButton}
            children={<BinIcon />}
            onClick={handleDeleteLink}
            disabled={disabled}
            {...(RemoveButtonProps as any)}
            size="large"
          />
          <ConfirmActionDialog
            title="Are you sure you want to delete this link?"
            description="Clients will no longer be able to use it to sign up."
            open={confirmDeleteDialogOpen}
            onClose={handleCancelDelete}
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        </>
      )}
    </Box>
  ) : (
    <ActionButton
      className={s.generateLinkButton}
      size="large"
      children="Generate link"
      onClick={handleGenerateLink}
      disabled={disabled}
      {...GenerateButtonProps}
    />
  );
}
