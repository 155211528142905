import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { ClientSettingsNotifications } from "../../../../components/settings/ClientSettingsNotifications";
import { ClientSettingsNotificationRouteQuery } from "./__generated__/ClientSettingsNotificationRouteQuery.graphql";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function ClientSettingsNotificationRoute() {
  const props = useLazyLoadQuery<ClientSettingsNotificationRouteQuery>(
    graphql`
      query ClientSettingsNotificationRouteQuery {
        me {
          notificationsSetting {
            ...ClientSettingsNotifications_settings
          }
        }
      }
    `,
    {},
  );

  const { me } = props;

  return (
    <>
      <ClientSettingsNotifications settingsRef={me?.notificationsSetting} />
      <TrackInfoTool
        trackInfo={{
          name: "Client - Notifications Settings",
        }}
      />
    </>
  );
}
