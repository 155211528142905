import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { isValidWorkoutTitleLength } from "../../new-editor/utils/validationUtil";
import { createEmptyExercise } from "../../workout/utils";
import { ElementType } from "../../editor/types/elements";
import { isEqual, omit } from "lodash";
import MinimizedTooltip, { TooltipLine } from "../../tooltip/MinimizedTooltip";

interface IPublishButtonProps {
  componentId: string;
  componentContent: any;
  exercise: string;
  saveWorkoutContent: (
    componentId: string,
    componentContent: any,
    componentTitle?: string,
  ) => void;
  className?: string;
}

const PublishButton = (props: IPublishButtonProps) => {
  const {
    componentId,
    componentContent,
    exercise,
    className,
    saveWorkoutContent,
  } = props;

  const handlePublish = (
    componentId: string,
    componentContent: any,
    componentTitle: string,
  ) => {
    const emptyExerciseTemplate = omit(createEmptyExercise(), [
      "id",
      "superset",
    ]);

    const filteredComponentContent = componentContent?.map((element) => {
      if (element.type === ElementType.WORKOUT_SECTION) {
        const filteredExerciseArr = element?.workout?.exercises
          .map((e) => {
            const filteredExercise = omit(e, ["id", "superset"]);
            if (!isEqual(emptyExerciseTemplate, filteredExercise)) return e;
            return null;
          })
          .filter((e) => e !== null);

        const resultElement = {
          ...element,
          workout: {
            ...element.workout,
            exercises: filteredExerciseArr,
          },
        };
        return resultElement;
      }
      return element;
    });

    saveWorkoutContent(componentId, filteredComponentContent, componentTitle);
  };

  const isDisabled = !isValidWorkoutTitleLength(exercise);
  return (
    <>
      <MinimizedTooltip
        title={
          <Stack direction="column">
            <TooltipLine>
              <b>Workout name</b> must be between
            </TooltipLine>
            <TooltipLine>
              <b>5</b> and <b>255</b> characters.
            </TooltipLine>
          </Stack>
        }
        disableHoverListener={!isDisabled}
        disableFocusListener={!isDisabled}
        disableTouchListener={!isDisabled}
      >
        <Box
          component={"span"}
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          <Button
            className={className}
            variant="contained"
            size="small"
            fullWidth
            onClick={() => {
              handlePublish(componentId, componentContent, exercise);
            }}
            disabled={isDisabled}
            sx={{
              marginBlock: 1,
              borderRadius: 1,
              fontSize: "12px",
            }}
          >
            Publish
          </Button>
        </Box>
      </MinimizedTooltip>
    </>
  );
};

export default PublishButton;
