/**
 * @generated SignedSource<<6f18710a5cca3f55625992a18904de59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type ProgramCalendarSidebarQuery$variables = {
  componentSlug: string;
};
export type ProgramCalendarSidebarQuery$data = {
  readonly component: {
    readonly content: string | null | undefined;
    readonly days: ReadonlyArray<boolean>;
    readonly duration: number;
    readonly habitPrompt: string | null | undefined;
    readonly iconName: string;
    readonly id: string;
    readonly locked: boolean;
    readonly messageContent: string | null | undefined;
    readonly messageTime: string | null | undefined;
    readonly reminderTime: string | null | undefined;
    readonly reminderType: ReminderType | null | undefined;
    readonly repeat: ComponentRepeat;
    readonly slug: string;
    readonly status: ComponentStatus;
    readonly title: string;
    readonly type: ComponentType;
    readonly updatedAt: string | null | undefined;
    readonly weekId: string;
  } | null | undefined;
};
export type ProgramCalendarSidebarQuery = {
  response: ProgramCalendarSidebarQuery$data;
  variables: ProgramCalendarSidebarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentSlug"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": true
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "componentSlug"
      }
    ],
    "concreteType": "Component",
    "kind": "LinkedField",
    "name": "component",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iconName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "title",
        "storageKey": "title(draft:true)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "content",
        "storageKey": "content(draft:true)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "habitPrompt",
        "storageKey": "habitPrompt(draft:true)"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "days",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "repeat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locked",
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "reminderTime",
        "storageKey": "reminderTime(draft:true)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "messageTime",
        "storageKey": "messageTime(draft:true)"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "messageContent",
        "storageKey": "messageContent(draft:true)"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "utc",
            "value": true
          }
        ],
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": "updatedAt(utc:true)"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weekId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "reminderType",
        "storageKey": "reminderType(draft:true)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramCalendarSidebarQuery",
    "selections": (v2/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramCalendarSidebarQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "996575e2448ddf5693aa023bfa3b4cad",
    "id": null,
    "metadata": {},
    "name": "ProgramCalendarSidebarQuery",
    "operationKind": "query",
    "text": "query ProgramCalendarSidebarQuery(\n  $componentSlug: String!\n) {\n  component(slug: $componentSlug) {\n    id\n    slug\n    type\n    status\n    iconName\n    title(draft: true)\n    content(draft: true)\n    habitPrompt(draft: true)\n    days\n    duration\n    repeat\n    locked\n    reminderTime(draft: true)\n    messageTime(draft: true)\n    messageContent(draft: true)\n    updatedAt(utc: true)\n    weekId\n    reminderType(draft: true)\n  }\n}\n"
  }
};
})();

(node as any).hash = "664c16ef115a00491f47338441062262";

export default node;
