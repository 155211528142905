import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardHeader,
  Chip,
  IconButton,
  Typography,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { MoreHoriz } from "@mui/icons-material";
import { useFragment, useMutation } from "react-relay/hooks";
import { ClientStatus } from "../../constants";
import { colorSystem } from "../../theme";
import { ClientPendingCard_invite$key } from "./__generated__/ClientPendingCard_invite.graphql";
import { ClientPendingCardInviteAddedClientMutation } from "./__generated__/ClientPendingCardInviteAddedClientMutation.graphql";
import { ClientPendingMenu } from "../menu/ClientPendingMenu";
import { CardAvatar } from "./CardAvatar";
import { SendEmailDialog } from "../dialog/SendEmailDialog";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useSnackAlert } from "../../hooks/useSnackAlert";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    color: theme.palette.text.primary,
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },

  header: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },

  cardTitle: {
    fontSize: 18,
  },

  cardSubheader: {
    fontSize: 13,
  },

  avatar: {
    width: 48,
    height: 48,

    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },

  text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      float: "right",
      marginTop: theme.spacing(1),
    },
  },

  moreButton: {
    marginRight: theme.spacing(3),
    marginLeft: "auto",
    visibility: "hidden",

    "$actionArea:hover &": {
      visibility: "visible",
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      visibility: "visible",
      top: 5,
      right: -20,
    },
  },

  chip: {
    fontWeight: "bold",
    fontSize: 11,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(1, 4.5),
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  chipInvited: {
    backgroundColor: theme.palette.activity.habit,
  },
  chipAdded: {
    backgroundColor: colorSystem.orange2,
  },
}));

const inviteAddedClientMutation = graphql`
  mutation ClientPendingCardInviteAddedClientMutation(
    $input: InviteAddedClientInput!
  ) {
    inviteAddedClient(input: $input) {
      invites {
        ...ClientPendingCard_invite
      }
      clientMutationId
    }
  }
`;

const inviteFragment = graphql`
  fragment ClientPendingCard_invite on Invite {
    id
    email
    invitedAt: updatedAt(format: "fromNow")
    client {
      clientStatus
      id
    }
  }
`;

export interface ClientPendingProps extends CardProps {
  invite: ClientPendingCard_invite$key;
}

export function ClientPendingCard(props: ClientPendingProps) {
  const { invite: inviteRef, ...other } = props;
  const invite = useFragment(inviteFragment, inviteRef);
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailDialogIsOpen, setEmailDialogIsOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const isPending = invite?.client?.clientStatus === ClientStatus.PENDING;
  const label = isPending ? "Invited" : "Added";

  const onNotification = React.useCallback(
    (message: string) => {
      snackAlert({
        severity: "success",
        message,
      });
    },
    [snackAlert],
  );

  const [inviteAddedClient, invitingAddedClientMutation] =
    useMutation<ClientPendingCardInviteAddedClientMutation>(
      inviteAddedClientMutation,
    );

  const name = invite.email.replace(/@.*$/, "");
  const handleMoreClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const handleSendEmail = React.useCallback(
    (message) => {
      inviteAddedClient({
        variables: {
          input: {
            id: [invite?.client?.id],
            message: message,
          },
        },
        onCompleted: (_, errors) => {
          setEmailDialogIsOpen(false);
          if (errors?.length) {
            onError(errors[0]);
          } else {
            if (onNotification) {
              onNotification("Invitation has been send");
            }
          }
        },
      });
    },
    [invite, inviteAddedClient, onError, onNotification],
  );

  const handleMoreClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(false);
  }, [setEmailDialogIsOpen]);

  const handleOpenEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(true);
    setAnchorEl(null);
  }, [setEmailDialogIsOpen]);

  return (
    <Card {...other}>
      <Paper className={s.actionArea}>
        <CardHeader
          className={s.header}
          avatar={
            <CardAvatar className={s.avatar} alt={name}>
              {name[0]?.toUpperCase()}
            </CardAvatar>
          }
          title={name}
          titleTypographyProps={{ variant: "h5", className: s.cardTitle }}
          subheader={invite.email}
          subheaderTypographyProps={{ className: s.cardSubheader }}
        />

        <Chip
          className={clsx(s.chip, isPending ? s.chipInvited : s.chipAdded)}
          label={label}
        />
        <Typography variant="body2" className={s.text}>
          {label} {invite.invitedAt}
        </Typography>
        <IconButton
          className={s.moreButton}
          aria-controls="program-menu"
          aria-haspopup="true"
          onClick={handleMoreClick}
          size="large"
        >
          <MoreHoriz />
        </IconButton>
      </Paper>

      <ClientPendingMenu
        inviteId={invite.id}
        open={open}
        onClose={handleMoreClose}
        isPending={isPending}
        anchorEl={anchorEl}
        clientId={invite?.client?.id}
        onSendInvite={handleOpenEmailDialog}
      />

      <SendEmailDialog
        open={emailDialogIsOpen}
        onClose={handleCloseEmailDialog}
        sendingEmail={invitingAddedClientMutation}
        sendEmail={handleSendEmail}
      />
    </Card>
  );
}
