import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  ButtonProps,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import CheckInUploadClintPhoto from "../editor/components/checkin/CheckInUploudClintPhoto";
import { ProgressImageInfo } from "../editor/components/checkin/CheckInAnswerProgressPhotoView";
import { ProgressPhotoView } from "../editor/components/checkin/CheckInAnswerProgressPhoto";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { ConfirmActionProgressPhotosUploadMutation } from "./__generated__/ConfirmActionProgressPhotosUploadMutation.graphql";

import { ConfirmActionProgressPhotosCreateUrlMutation } from "./__generated__/ConfirmActionProgressPhotosCreateUrlMutation.graphql";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
  },

  description: {
    color: theme.palette.common.black,
  },
  viewLabel: {
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
  },
  container: {
    display: "grid",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  modal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 1200,
      flex: 1,
    },
  },

  button: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    minWidth: "100%",
    height: theme.spacing(7),
  },

  cancelButton: {
    color: theme.palette.text.secondary,
    borderWidth: 2,
    margin: theme.spacing(2, 0),
  },

  okayButton: {
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));

const createUploadPhotosMutation = graphql`
  mutation ConfirmActionProgressPhotosUploadMutation(
    $input: upsertProgressPhotosInput!
  ) {
    upsertProgressPhotos(input: $input) {
      metric {
        id
        createdAt(format: "MMM DD, YYYY")
        value {
          ... on CheckinAnswerProgressPhotoValue {
            front
            back
            side
          }
        }
      }
    }
  }
`;

const createUploadUrlMutation = graphql`
  mutation ConfirmActionProgressPhotosCreateUrlMutation(
    $id: ID!
    $file: String!
    $type: AssetType!
  ) {
    createSignedUrl(id: $id, file: $file, type: $type) {
      url
    }
  }
`;

export interface ConfirmActionDialogProps extends Omit<DialogProps, "title"> {
  title: string;
  description?: string;
  onCancel: ButtonProps["onClick"];
  onConfirm: ButtonProps["onClick"];
  disabled?: boolean;
  items?: any[];
  id: string;
  updatePhotosList: (value) => void;
}

export function ConfirmActionProgressPhotos(props: ConfirmActionDialogProps) {
  const {
    className,
    title,
    description,
    onCancel,
    onConfirm,
    disabled,
    items,
    id,
    updatePhotosList,
    ...other
  } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();

  const [photos, setPhotos] = React.useState({
    front: null,
    side: null,
    back: null,
  });

  const options: Record<ProgressPhotoView, string> = {
    front: "Front view",
    side: "Side view",
    back: "Back view",
  };

  const [createPhoto, creating] =
    useMutation<ConfirmActionProgressPhotosUploadMutation>(
      createUploadPhotosMutation,
    );
  const [createUrl, creatingUrl] =
    useMutation<ConfirmActionProgressPhotosCreateUrlMutation>(
      createUploadUrlMutation,
    );

  const handleChange = React.useCallback(
    (name, value: ProgressImageInfo) => {
      const values = {
        ...photos,
        [name]: value,
      };

      setPhotos(values);
    },
    [photos],
  );

  const handleSave = React.useCallback(
    (event) => {
      const content = { photos };
      const input = {
        content: JSON.stringify(content),
        date: dayjs().format("YYYY-MM-DD"),
      };
      createPhoto({
        variables: {
          input,
        },
        onCompleted: ({ upsertProgressPhotos: { metric } }, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Saved successfully",
            });
            onCancel(event);
            setPhotos({
              front: null,
              side: null,
              back: null,
            });
            updatePhotosList(metric);
          }
        },
      });
    },
    [photos, setPhotos, updatePhotosList, createPhoto, onCancel, snackAlert],
  );

  return (
    <BaseDialog
      className={clsx(s.root, s.modal)}
      title={title}
      description={description}
      onClose={onCancel}
      classes={{
        paper: s.paper,
        description: s.description,
      }}
      {...other}
    >
      <Box className={s.container}>
        {Object.entries(options).map(([name, label]) => (
          <Box key={name}>
            <Typography variant="body1" className={s.viewLabel}>
              {label}
            </Typography>
            <CheckInUploadClintPhoto
              name={name}
              value={photos[name]}
              onChange={handleChange}
              createUrl={createUrl}
              id={id}
              disabled={creatingUrl || creating}
            />
          </Box>
        ))}
      </Box>
      <Button
        className={clsx(s.button, s.cancelButton)}
        variant="outlined"
        children="Cancel"
        onClick={onCancel}
        disabled={disabled || creatingUrl || creating}
      />
      <Button
        className={clsx(s.button, s.okayButton)}
        variant="contained"
        children="OK"
        onClick={handleSave}
        disabled={disabled || creatingUrl || creating}
      />
    </BaseDialog>
  );
}
