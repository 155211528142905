import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { SOMETHING_WENT_WRONG } from "../../../constants";

import { UpdateProgramMutation } from "./__generated__/UpdateProgramMutation.graphql";

const mutation = graphql`
  mutation UpdateProgramMutation($input: UpdateProgramInput!) {
    updateProgram(input: $input) {
      program {
        ...ProgramSettings_program
      }
    }
  }
`;

export const useUpdateProgramMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpdateProgramMutation>(
    mutation,
    ({ onSuccess }) => ({
      onSuccess: (result) => {
        snackAlert({
          severity: "success",
          message: "Program settings updated",
        });

        if (onSuccess) {
          onSuccess(result);
        }
      },
      onFailure: () =>
        snackAlert({
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        }),
    }),
  );
};
