import React from "react";

export const useTimeInputSupported = () =>
  React.useMemo(() => {
    const input = document.createElement("input");
    const fakeValue = "some-fake-value";

    input.type = "time";
    input.value = fakeValue;

    return input.value !== fakeValue;
  }, []);
