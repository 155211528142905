/**
 * @generated SignedSource<<8ebd8bdc5a3884030de45073b15350c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientMeasurementsCard_client$data = {
  readonly allMetrics: ReadonlyArray<{
    readonly createdAt: string | null | undefined;
  }>;
  readonly bodyFat: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly calf: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly chest: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly hip: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly shoulder: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly thigh: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly units: Units;
  readonly upperArm: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly waist: ReadonlyArray<{
    readonly activityDate: string | null | undefined;
    readonly id: string;
    readonly value: {
      readonly measurement?: number;
      readonly unit?: MeasurementUnit;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
  }>;
  readonly " $fragmentType": "ClientMeasurementsCard_client";
};
export type ClientMeasurementsCard_client$key = {
  readonly " $data"?: ClientMeasurementsCard_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementsCard_client">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "metricType",
  "value": "CHECKIN_ANSWER_MEASUREMENT"
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "ClientMeasurementItem_metrics"
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "raw",
        "value": true
      }
    ],
    "kind": "ScalarField",
    "name": "activityDate",
    "storageKey": "activityDate(raw:true)"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "value",
    "plural": false,
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "measurement",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
          }
        ],
        "type": "CheckinAnswerMeasurementValue",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientMeasurementsCard_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": "allMetrics",
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "format",
              "value": "MMM DD, YYYY"
            }
          ],
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": "createdAt(format:\"MMM DD, YYYY\")"
        }
      ],
      "storageKey": "metrics(limit:1,metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "bodyFat",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "BODYFAT"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"BODYFAT\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "chest",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "CHEST"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"CHEST\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "waist",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "WAIST"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"WAIST\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "hip",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "HIP"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"HIP\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "thigh",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "THIGH"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"THIGH\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "upperArm",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "UPPER_ARM"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"UPPER_ARM\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "calf",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "CALF"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"CALF\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    },
    {
      "alias": "shoulder",
      "args": [
        {
          "kind": "Literal",
          "name": "measurementType",
          "value": "SHOULDER"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "metrics(measurementType:\"SHOULDER\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "be1ce3b9566f33fc2d0bf045d789f225";

export default node;
