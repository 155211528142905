import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

const VERT_BAR_WIDTH = 9;
const VERT_BAR_HEIGHT = 21;
const VERT_ITEM_HEIGHT = VERT_BAR_HEIGHT + 5;
const VERT_ROOT_BETWEEN_ROWS = 2;
const VERT_ROOT_HEIGHT = VERT_ITEM_HEIGHT * 2 + VERT_ROOT_BETWEEN_ROWS;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      flexFlow: "row wrap",
      justifyContent: "space-around",
      height: theme.spacing(VERT_ROOT_HEIGHT),
    },

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(VERT_ITEM_HEIGHT),
    },
  },

  item: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0.5, 0),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "space-between",
      width: theme.spacing(VERT_BAR_WIDTH),
      height: theme.spacing(VERT_ITEM_HEIGHT),
      margin: theme.spacing(0, 0.5, 2),
    },

    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },

  label: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.primary.main,
    width: 32,
    textAlign: "left",
    marginRight: theme.spacing(1),

    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(VERT_BAR_WIDTH),
      textAlign: "center",
      margin: 0,
    },
  },
}));

interface RatingLinearProgressProps extends LinearProgressProps {
  label: string;
}

const RatingLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: theme.spacing(4),
      width: "100%",
      borderRadius: theme.spacing(0.5),
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "24px",

      "&:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },

      [theme.breakpoints.up("sm")]: {
        height: theme.spacing(VERT_BAR_WIDTH),
        width: theme.spacing(VERT_BAR_HEIGHT),
        margin: theme.spacing(6, -6, 1),
        transform: "rotate(-90deg)",
      },
    },
    colorPrimary: {
      backgroundColor: `${theme.palette.primary.main}26`,

      "&::after": {
        display: "block",
        content: ({ value }: RatingLinearProgressProps) => `'${value}%'`,
        position: "absolute",
        right: theme.spacing(0.5),
        top: `calc(50% - ${theme.spacing(1.75)})`,
        color: theme.palette.primary.main,
        textAlign: "center",
        padding: theme.spacing(0.25, 1),
        backgroundColor: `${colorSystem.white}66`,
        borderRadius: theme.spacing(0.5),

        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(7.5),
          transform: "rotate(90deg)",
          padding: theme.spacing(0.5, 1),
          top: `calc(50% - ${theme.spacing(2)})`,
        },
      },
    },
    bar: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
)(LinearProgress);

export interface RatingResponsesSummaryProps extends BoxProps {
  counts: readonly number[];
  rates: readonly number[];
}

export function RatingResponsesSummary(props: RatingResponsesSummaryProps) {
  const { className, counts, rates, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {counts.map((count, i) => (
        <Box key={i} className={s.item}>
          <Typography className={s.label} children={`${i + 1}`} />
          <RatingLinearProgress
            variant="determinate"
            value={rates[i]}
            label={`${i + 1}`}
          />
        </Box>
      ))}
    </Box>
  );
}
