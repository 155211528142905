import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CardMedia } from "../card/CardMedia";
import { WorkoutMediaMenu } from "../editor/components/menus/WorkoutMediaMenu";

import { WorkoutExerciseImage } from "./types";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2, 2, 2),
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: theme.palette.selected.light,
    minWidth: "100%",
    maxWidth: "100%",
  },

  more: {},

  coverText: {
    backgroundColor: theme.palette.text.secondary,
    color: colorSystem.white,
    fontSize: 10,
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    borderRadius: 4,
    padding: theme.spacing(0.2, 0.8, 0.2, 0.8),
    margin: theme.spacing(0, 2.5, 0, 0),
  },

  imageName: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  imageContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    width: "50%",
  },

  image: {
    width: 32,
    height: 32,
    borderRadius: 8,
    minWidth: 32,
  },
}));

export interface WorkoutMediaProps extends BoxProps {
  image: WorkoutExerciseImage;
  onRemove?: (image: WorkoutExerciseImage) => void;
  onToggleCover?: (image: WorkoutExerciseImage) => void;
  handleOpenModal?: (data) => void;
  modal?: boolean;
}

export function WorkoutMedia(props: WorkoutMediaProps) {
  const {
    className,
    image,
    onRemove,
    onToggleCover,
    handleOpenModal,
    modal = false,
    ...other
  } = props;
  const s = useStyles();

  const handleRemove = React.useCallback(() => {
    if (onRemove) {
      handleOpenModal({ media: image, isOwnVideo: false });
    }
  }, [image, onRemove, handleOpenModal]);

  const handleToggleCover = React.useCallback(() => {
    if (onToggleCover) {
      onToggleCover(image);
    }
  }, [image, onToggleCover]);
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <div className={s.imageContainer}>
        <CardMedia image={image.url} fit={false} className={s.image} />
        {image.name && (
          <Typography component="div" className={s.imageName}>
            {image.name}
          </Typography>
        )}
      </div>
      {image.cover && (
        <Typography component="div" className={s.coverText}>
          Cover
        </Typography>
      )}
      {!modal && (
        <WorkoutMediaMenu
          className={s.more}
          isCover={image.cover}
          onToggleCover={handleToggleCover}
          onRemove={handleRemove}
        />
      )}
    </Box>
  );
}
