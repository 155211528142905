import clsx from "clsx";
import React from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ClientMealLoggingEntryMenuProps extends MenuProps {
  onItemClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function ClientMealLoggingEntryMenu(
  props: ClientMealLoggingEntryMenuProps,
) {
  const s = useStyles();
  const { className, onItemClick, ...other } = props;

  return (
    <Menu
      className={clsx(s.root, className)}
      MenuListProps={{ dense: true }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...other}
    >
      <MenuItem data-action="remove" onClick={onItemClick} dense>
        <ListItemIcon children={<RemoveIcon />} />
        <ListItemText primary="Delete meal" />
      </MenuItem>
    </Menu>
  );
}
