import React from "react";
import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpsertProgramFolderMutation } from "./__generated__/UpsertProgramFolderMutation.graphql";

export type UpsertedProgramFolder =
  UpsertProgramFolderMutation["response"]["upsertProgramFolder"]["programFolder"];

const mutation = graphql`
  mutation UpsertProgramFolderMutation($input: UpsertProgramFolderInput!) {
    upsertProgramFolder(input: $input) {
      programFolder {
        ...ProgramFolderCard_folder
      }
    }
  }
`;

export const useUpsertProgramFolderMutation = () => {
  return useConfiguredMutation<UpsertProgramFolderMutation>(mutation);
};
