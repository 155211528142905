import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";

import { maxEnrollments } from "../../../../constants";
import { ProgramEnrollmentsListContext } from "../../../../hooks/useProgramEnrollmentsList";
import { UnsplashContext } from "../../../../hooks/useUnsplash";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { HistoryBlock } from "../../../../components/history-block/HistoryBlock";
import { CurriculumEditScreen } from "../../../../components/screen/CurriculumEditScreen";
import { CoachProgramCurriculumRouteQuery } from "./__generated__/CoachProgramCurriculumRouteQuery.graphql";
import { ProgramContext } from "../../../../contexts/ProgramContext";
import LayoutLoadingContext from "../../../../contexts/LayoutLoadingContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { CoachProgramCurriculumRouteWeeksQuery } from "./__generated__/CoachProgramCurriculumRouteWeeksQuery.graphql";
import { containsKeyInLocalStorage } from "../../../../hooks/useLocalStorage";

export const CURRICULUM_VIEW_KEY = "curriculum-view";
export const getCurriculumViewStorageKey = (
  programSlug: string,
  programId?: string,
) => {
  const formatCurriculumViewStorageKey = (programIdentifier: string) =>
    `${CURRICULUM_VIEW_KEY}-${programIdentifier}`;

  const slugKey = formatCurriculumViewStorageKey(programSlug);
  const hasSlugKey = containsKeyInLocalStorage(slugKey);

  // Program curriculum data used to be stored in local storage by program id.
  // We are moving it to be based on program slug instead as it is available
  // directly at route initial load (for program id we need to make a call to BE).
  //
  // TODO drop this fallback to program id after this code is in production for some time.
  // TODO think about why do we need to store program view preferences in both URL
  // and local storage. There should be once source of truth, not sure that we need both.
  const idKey = programId ? formatCurriculumViewStorageKey(programId) : null;
  const hasIdKey = idKey ? containsKeyInLocalStorage(idKey) : false;
  if (hasIdKey) {
    if (!hasSlugKey) {
      const value = window.localStorage.getItem(idKey);
      window.localStorage.setItem(slugKey, value);
    }

    window.localStorage.removeItem(idKey);
  }

  return slugKey;
};

export const INITIAL_WEEK_DETAILS = {
  startNumber: 1,
  endNumber: 3,
};

export const MAX_WEEK_RANGE = 3;
export const getLimitedWeekMaxNumber = (
  startNumber: number,
  endNumber: number,
) => {
  return Math.min(startNumber + MAX_WEEK_RANGE - 1, endNumber);
};

export function CoachProgramCurriculumRoute() {
  const { slug } = useParams();

  const loadingContext = useContext(LayoutLoadingContext);
  loadingContext.setLoading(true);

  const props = useLazyLoadQuery<CoachProgramCurriculumRouteQuery>(
    graphql`
      query CoachProgramCurriculumRouteQuery(
        $programSlug: String!
        $maxEnrollments: Int!
        $startNumber: Int!
        $endNumber: Int!
      ) {
        ...UnsplashImages_rootRef

        program(slug: $programSlug) {
          id
          name
          slug
          ...CurriculumEditScreen_program
            @arguments(startNumber: $startNumber, endNumber: $endNumber)
          ...RefreshSlug
          ...EnrollDialog_program
          ...EnrollDateDialog_program
        }

        clients {
          ...EnrollDialog_clients
        }

        groups {
          ...EnrollDialog_groups
        }

        enrollmentsAvatars: enrollments(
          programSlug: $programSlug
          first: $maxEnrollments
        ) @connection(key: "Program_enrollmentsAvatars", filters: []) {
          totalCount
          edges {
            node {
              id
            }
          }
          ...EnrolledAvatars_connection
        }

        enrollments(programSlug: $programSlug, first: 9999)
          @connection(key: "Program_enrollments", filters: []) {
          edges {
            cursor
          }
          ...ProgramEnrollmentStartDateViewDialog_enrollments
          ...CurriculumEditScreen_enrollments
        }
      }
    `,
    {
      programSlug: slug,
      maxEnrollments,
      // there values are ignored and refetch
      // based on user preferences in child component
      startNumber: 0,
      endNumber: 0,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  // A separate query is required to get ALL week ids because of
  // graphQl's limitation of having unique prop names to query.
  // The main program query retrieves paginated weeks with details.
  // This query returns only identifier for all weeks of the program.
  const { program: programAllWeeks } =
    useLazyLoadQuery<CoachProgramCurriculumRouteWeeksQuery>(
      graphql`
        query CoachProgramCurriculumRouteWeeksQuery($programSlug: String!) {
          program(slug: $programSlug) {
            id
            ...CurriculumEditScreen_weeks
          }
        }
      `,
      { programSlug: slug },
      RELAY_LAZY_LOAD_COMMON_CONFIG,
    );

  const { program, enrollments } = props;

  const context = useContext(ProgramContext);
  context.setProgramQuery(props);
  program && loadingContext.setLoading(false);

  return (
    <>
      <RefreshSlug nodesRef={[program]} />
      <HistoryBlock>
        <ProgramEnrollmentsListContext.Provider value={enrollments}>
          <UnsplashContext.Provider value={props}>
            <CurriculumEditScreen
              programRef={program}
              programWeeksRef={programAllWeeks}
            />
          </UnsplashContext.Provider>
        </ProgramEnrollmentsListContext.Provider>
      </HistoryBlock>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Program Curriculum",
          properties: {
            programId: program?.id,
            programName: program?.name,
            programSlug: program?.slug,
          },
        }}
      />
    </>
  );
}
