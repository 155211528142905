/**
 * @generated SignedSource<<ae6330b947be8eec07654046798f45d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachProgramCurriculumRouteWeeksQuery$variables = {
  programSlug: string;
};
export type CoachProgramCurriculumRouteWeeksQuery$data = {
  readonly program: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CurriculumEditScreen_weeks">;
  } | null | undefined;
};
export type CoachProgramCurriculumRouteWeeksQuery = {
  response: CoachProgramCurriculumRouteWeeksQuery$data;
  variables: CoachProgramCurriculumRouteWeeksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "programSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramCurriculumRouteWeeksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CurriculumEditScreen_weeks"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachProgramCurriculumRouteWeeksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "after",
                "value": ""
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 9999
              }
            ],
            "concreteType": "WeeksConnection",
            "kind": "LinkedField",
            "name": "weeks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WeeksEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Week",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "week",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "weeks(after:\"\",first:9999)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90f24baff15440718e346d8c5006527a",
    "id": null,
    "metadata": {},
    "name": "CoachProgramCurriculumRouteWeeksQuery",
    "operationKind": "query",
    "text": "query CoachProgramCurriculumRouteWeeksQuery(\n  $programSlug: String!\n) {\n  program(slug: $programSlug) {\n    id\n    ...CurriculumEditScreen_weeks\n  }\n}\n\nfragment CurriculumEditScreen_weeks on Program {\n  weeks(first: 9999, after: \"\") {\n    edges {\n      node {\n        id\n        week\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "b20ebfc8817aa9aaf8412ee0e8a99eed";

export default node;
