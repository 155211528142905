/**
 * @generated SignedSource<<fc46d74f5c27185d888511fa043f17f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachSettingsAccount_user$data = {
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly notificationsSetting: {
    readonly activityCompletion: boolean;
    readonly id: string;
    readonly newMessageEmail: boolean;
    readonly newResponseEmail: boolean;
  } | null | undefined;
  readonly passwordSet: boolean;
  readonly passwordUpdatedAt: string | null | undefined;
  readonly phone: string | null | undefined;
  readonly phoneCode: string | null | undefined;
  readonly phoneVerified: boolean;
  readonly photoURL: string | null | undefined;
  readonly timeZone: string | null | undefined;
  readonly units: Units;
  readonly " $fragmentType": "CoachSettingsAccount_user";
};
export type CoachSettingsAccount_user$key = {
  readonly " $data"?: CoachSettingsAccount_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachSettingsAccount_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachSettingsAccount_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passwordSet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM DD, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "passwordUpdatedAt",
      "storageKey": "passwordUpdatedAt(format:\"MMM DD, YYYY\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationsSetting",
      "kind": "LinkedField",
      "name": "notificationsSetting",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newMessageEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newResponseEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activityCompletion",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "25df3703902685c150f8a1887b48a7c6";

export default node;
