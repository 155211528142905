import React from "react";
import { BoxProps } from "@mui/material";

import { StarRating, StarRatingProps } from "../fields/StarRating";

export interface ClientMealLoggingStarRatingProps
  extends Omit<BoxProps, "onSelect"> {
  rating?: number;
  onSelect?: StarRatingProps["onSelect"];
}

export function ClientMealLoggingStarRating(
  props: ClientMealLoggingStarRatingProps,
) {
  const { className, rating, onSelect, ...other } = props;

  return (
    <StarRating
      value={rating}
      onSelect={onSelect}
      disabled={!onSelect}
      small={!onSelect}
      {...other}
    />
  );
}
