export interface Country {
  name: string;
  flag: string;
  number: string;
  code: string;
}

export const countries: Country[] = [
  {
    name: "Afghanistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
    number: "+93",
    code: "AF",
  },
  {
    name: "Albania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
    number: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
    number: "+213",
    code: "DZ",
  },
  {
    name: "Andorra",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
    number: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
    number: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
    number: "+1264",
    code: "AI",
  },
  {
    name: "Antigua and Barbuda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
    number: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
    number: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
    number: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
    number: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
    number: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
    number: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
    number: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
    number: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
    number: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
    number: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
    number: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
    number: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
    number: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
    number: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
    number: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
    number: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
    number: "+975",
    code: "BT",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
    number: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
    number: "+267",
    code: "BW",
  },
  {
    name: "Bouvet Island",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    number: "+55",
    code: "BV",
  },
  {
    name: "Brazil",
    flag: "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
    number: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_British_Indian_Ocean_Territory.svg",
    number: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
    number: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
    number: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
    number: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
    number: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
    number: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
    number: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
    number: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
    number: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg",
    number: "+1345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
    number: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
    number: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
    number: "+56",
    code: "CL",
  },
  {
    name: "China",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
    number: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg",
    number: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg",
    number: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
    number: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
    number: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
    number: "+242",
    code: "CG",
  },
  {
    name: "Cook Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
    number: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
    number: "+506",
    code: "CR",
  },
  {
    name: "Croatia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
    number: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
    number: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
    number: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
    number: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
    number: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
    number: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
    number: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
    number: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
    number: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
    number: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
    number: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
    number: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
    number: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
    number: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
    number: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
    number: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
    number: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
    number: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
    number: "+358",
    code: "FI",
  },
  {
    name: "France",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    number: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg",
    number: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
    number: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
    number: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
    number: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
    number: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    flag: "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
    number: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
    number: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
    number: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
    number: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
    number: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
    number: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Unofficial_flag_of_Guadeloupe_%28local%29.svg",
    number: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg",
    number: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
    number: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
    number: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
    number: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
    number: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
    number: "+592",
    code: "GY",
  },
  {
    name: "Haiti",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
    number: "+509",
    code: "HT",
  },
  {
    name: "Heard Island and McDonald Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Proposed_flag_of_Antarctica_%28Graham_Bartram%29.svg",
    number: "+672",
    code: "HM",
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
    number: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Naval_Ensign_of_Honduras.svg",
    number: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
    number: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
    number: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
    number: "+354",
    code: "IS",
  },
  {
    name: "India",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
    number: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
    number: "+62",
    code: "ID",
  },
  {
    name: "Iran",
    flag: " https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
    number: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
    number: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
    number: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
    number: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    number: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    flag: "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
    number: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
    number: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    flag: "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
    number: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg",
    number: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
    number: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
    number: "+7",
    code: "KZ",
  },
  {
    name: "Kenya",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
    number: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
    number: "+686",
    code: "KI",
  },
  {
    name: "Kuwait",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    number: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
    number: "+996",
    code: "KG",
  },
  {
    name: "Lao People's Democratic Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
    number: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
    number: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
    number: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
    number: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
    number: "+231",
    code: "LR",
  },
  {
    name: "Liechtenstein",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
    number: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
    number: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
    number: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg",
    number: "+853",
    code: "MO",
  },
  {
    name: "Madagascar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
    number: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
    number: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
    number: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
    number: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
    number: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
    number: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
    number: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Unofficial_flag_of_Guadeloupe_%28local%29.svg",
    number: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
    number: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
    number: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    number: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
    number: "+52",
    code: "MX",
  },
  {
    name: "Monaco",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
    number: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
    number: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
    number: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg",
    number: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
    number: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
    number: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
    number: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
    number: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
    number: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
    number: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
    number: "+31",
    code: "NL",
  },
  {
    name: "New Caledonia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/26/Flags_of_New_Caledonia.svg",
    number: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
    number: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
    number: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
    number: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
    number: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
    number: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg",
    number: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg",
    number: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    number: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
    number: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
    number: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
    number: "+680",
    code: "PW",
  },
  {
    name: "Panama",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
    number: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
    number: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
    number: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
    number: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
    number: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
    number: "+870",
    code: "PN",
  },
  {
    name: "Poland",
    flag: "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
    number: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
    number: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
    number: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
    number: "+974",
    code: "QA",
  },
  {
    name: "Réunion",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    number: "+262",
    code: "RE",
  },
  {
    name: "Romania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
    number: "+40",
    code: "RO",
  },
  {
    name: "Rwanda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
    number: "+250",
    code: "RW",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
    number: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
    number: "+1758",
    code: "LC",
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg",
    number: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
    number: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
    number: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
    number: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg",
    number: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
    number: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
    number: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
    number: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
    number: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
    number: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
    number: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
    number: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
    number: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
    number: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
    number: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
    number: "+27",
    code: "ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
    number: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    flag: "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
    number: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
    number: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
    number: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
    number: "+597",
    code: "SR",
  },
  {
    name: "Eswatini",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Swaziland_1894.svg",
    number: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
    number: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
    number: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
    number: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
    number: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
    number: "+992",
    code: "TJ",
  },
  {
    name: "Thailand",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
    number: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
    number: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
    number: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg",
    number: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
    number: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
    number: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
    number: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
    number: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
    number: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg",
    number: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
    number: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
    number: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
    number: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
    number: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    flag: "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
    number: "+44",
    code: "GB",
  },
  {
    name: "United States",
    flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    number: "+1",
    code: "US",
  },
  {
    name: "United States Minor Outlying Islands",
    flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    number: "+1581",
    code: "UM",
  },
  {
    name: "Uruguay",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
    number: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
    number: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
    number: "+678",
    code: "VU",
  },
  {
    name: "Viet Nam",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
    number: "+84",
    code: "VN",
  },
  {
    name: "Wallis and Futuna",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg",
    number: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
    number: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
    number: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
    number: "+263",
    code: "ZW",
  },
];
