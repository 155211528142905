import clsx from "clsx";
import React, { useState } from "react";

import {
  Card,
  Container,
  ContainerProps,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { UserRole } from "../../constants";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import Condition from "./Condition";
import Dots from "./Dots";
import Button from "./Button";
import { StartWorkout_activity$key } from "./__generated__/StartWorkout_activity.graphql";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { ActivityBar, ActivityBarProps } from "../activity/ActivityBar";
import { useLocation, useNavigate } from "react-router-dom";
import TrackInfoTool from "../tools/TrackInfoTool";
import Mentally from "../../icons/Mentally";
import Physically from "../../icons/Physically";
import { getDaysDiffFromToday, getTimeDiff } from "../../utils/date";
import { ActivityLocked } from "../activity/ActivityLocked";

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      body: {
        fontFamily: "'Montserrat', sans-serif",
        [theme.breakpoints.down("md")]: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },

    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100%",
      padding: 0,
    },

    root: {
      paddingBottom: theme.spacing(3),
      paddingLeft: 0,
      flex: 1,
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        boxShadow: "none",
      },
    },

    gridContent: {
      padding: theme.spacing(4, 0),
    },

    imageBig: {
      width: "20vh",
      height: "20vh",
      [theme.breakpoints.down("md")]: {
        height: "15vh",
      },
    },
  };
});

const activityFragment = graphql`
  fragment StartWorkout_activity on Activity {
    id
    date(raw: true)
    component {
      locked
    }
  }
`;

const upsertActivityMutation = graphql`
  mutation StartWorkoutActivityCompleteActivityMutation(
    $input: UpsertActivityInput!
  ) {
    upsertActivity(input: $input) {
      activity {
        ...StartWorkout_activity
      }
    }
  }
`;

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activityRef: StartWorkout_activity$key;
  coach?: boolean;
}

export function StartWorkout(props: ActivityProps) {
  const { activityRef } = props;
  const navigate = useNavigate();
  const [activeDot, setActiveDot] = useState<number>(0);
  const [mindValue, setMindValue] = useState<null | string>(null);
  const [bodyValue, setBodyValue] = useState<null | string>(null);
  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));
  const activity = useFragment(activityFragment, activityRef);
  const theme = useTheme();
  const [upsertActivity, upsertActivityInFlight] = useMutation(
    upsertActivityMutation,
  );
  const s = useStyles();
  const location = useLocation();
  const locked = activity.component?.locked && getTimeDiff(activity.date) > 0;
  const user = useCurrentUser();
  const returnBack = location.key !== "default";

  const path = location.pathname.replace("start/", "") + "?workout=true";

  const handleSubmit = React.useCallback(() => {
    const input = {
      id: activity.id,
      startWorkout: JSON.stringify({ mindValue, bodyValue }),
    };

    upsertActivity({
      variables: {
        input,
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          navigate(path);
        }
      },
    });
  }, [activity.id, upsertActivity, path, mindValue, bodyValue]);

  const handleActivityClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      handleSubmit();
    },
    [handleSubmit],
  );

  const backButtonTooltip = activeDot
    ? "Back to Mind state"
    : user.role === UserRole.CLIENT
      ? returnBack
        ? "Back to Program"
        : "Back to Home"
      : null;

  const goBack = React.useCallback(() => {
    if (activeDot) {
      setActiveDot(0);
    } else {
      if (returnBack) {
        navigate(-1);
      } else {
        const returnUrl =
          user.role === UserRole.CLIENT
            ? "/home"
            : location.pathname.replace(/\/[^/]+\/[^/]+\/?\/?$/, "") +
              location.search;

        navigate(returnUrl);
      }
    }
  }, [returnBack, user.role, activeDot]);

  const BeforeWorkoutBarCommon = React.forwardRef<
    HTMLElement,
    ActivityBarProps
  >((props: Partial<ActivityBarProps>, ref) => {
    return (
      <ActivityBar
        ref={ref}
        coach={false}
        onCloseClick={goBack}
        backButtonTooltip={backButtonTooltip}
        {...props}
      />
    );
  });

  return (
    <>
      <Container maxWidth="md" className={clsx(s.container)}>
        {locked ? (
          <Grid mt={20}>
            <ActivityLocked daysDiff={getDaysDiffFromToday(activity.date)} />
          </Grid>
        ) : (
          <Grid container className={s.gridContent}>
            {mdSm && (
              <BeforeWorkoutBarCommon position="relative" elevation={0} />
            )}

            <Grid item sm={12}>
              <Card className={clsx(s.root)}>
                {!mdSm && (
                  <BeforeWorkoutBarCommon position="relative" elevation={0} />
                )}
                {activeDot ? (
                  <Condition
                    title="Body"
                    text="Physically how ready do you feel for this workout"
                    icon={
                      <Physically
                        fill={theme.palette.primary.main}
                        className={s.imageBig}
                      />
                    }
                    onChooseValue={setBodyValue}
                    activeValue={bodyValue}
                  />
                ) : (
                  <Condition
                    title="Mind"
                    text="Mentally how ready do you feel for this workout"
                    icon={
                      <Mentally
                        className={s.imageBig}
                        fill={theme.palette.primary.main}
                      />
                    }
                    onChooseValue={setMindValue}
                    activeValue={mindValue}
                  />
                )}
                <Dots activeDot={activeDot} />
                <Button
                  activeDot={activeDot}
                  nextStep={(e) =>
                    activeDot ? handleActivityClick(e) : setActiveDot(1)
                  }
                  isDisabled={
                    activeDot
                      ? !!bodyValue && !upsertActivityInFlight
                      : !!mindValue && !upsertActivityInFlight
                  }
                />
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Program",
        }}
      />
    </>
  );
}
