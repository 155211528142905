import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpdateClientFormMutation } from "./__generated__/UpdateClientFormMutation.graphql";

const mutation = graphql`
  mutation UpdateClientFormMutation($input: UpdateClientFormInput!) {
    updateClientForm(input: $input) {
      clientForm {
        ...ClientForm_clientForm
      }
    }
  }
`;

export const useUpdateClientFormMutation = () =>
  useConfiguredMutation<UpdateClientFormMutation>(mutation, () => ({}));
