/**
 * @generated SignedSource<<098c884f71eba28605c54a743530018d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AnalyticsRequestType = "DEVICE" | "IDENTIFY" | "PAGE" | "TRACK" | "%future added value";
export type SendAnalyticsRequestInput = {
  clientMutationId?: string | null | undefined;
  payload: string;
  type: AnalyticsRequestType;
};
export type ssrAnalyticsMutation$variables = {
  input: SendAnalyticsRequestInput;
};
export type ssrAnalyticsMutation$data = {
  readonly sendAnalyticsRequest: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type ssrAnalyticsMutation = {
  response: ssrAnalyticsMutation$data;
  variables: ssrAnalyticsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendAnalyticsRequestPayload",
    "kind": "LinkedField",
    "name": "sendAnalyticsRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ssrAnalyticsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ssrAnalyticsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5cbd1856659736440f7cc93b0fbf7d8f",
    "id": null,
    "metadata": {},
    "name": "ssrAnalyticsMutation",
    "operationKind": "mutation",
    "text": "mutation ssrAnalyticsMutation(\n  $input: SendAnalyticsRequestInput!\n) {\n  sendAnalyticsRequest(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "598d1de8e6ee43fb9f7a6c6229d62237";

export default node;
