import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useMutation } from "react-relay/hooks";

import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { ForgotPasswordScreenResetPasswordMutation } from "./__generated__/ForgotPasswordScreenResetPasswordMutation.graphql";
import { ForgotPasswordFormX } from "./ForgotPasswordForm";
import { PasswordResetDialog } from "./PasswordResetDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  logo: {
    marginBottom: theme.spacing(10),
  },
}));

const resetPasswordMutation = graphql`
  mutation ForgotPasswordScreenResetPasswordMutation($email: String!) {
    resetPassword(email: $email)
  }
`;

export interface ForgotPasswordScreenProps extends BoxProps {
  returnUrl?: string;
}

export function ForgotPasswordScreen(props: ForgotPasswordScreenProps) {
  const { className, returnUrl, ...other } = props;
  const s = useStyles();
  const [email, setEmail] = React.useState("");
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] =
    React.useState(false);
  const [resetPassword, pending] =
    useMutation<ForgotPasswordScreenResetPasswordMutation>(
      resetPasswordMutation,
    );
  const onError = useGenericErrorHandler();

  const handleEmailChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      event.preventDefault();

      resetPassword({
        variables: { email },
        onCompleted() {
          setPasswordResetDialogOpen(true);
        },
        onError,
      });
    },
    [email, onError, resetPassword],
  );

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <AppLogo className={s.logo} main full width={160} height={37} />
        <ForgotPasswordFormX
          email={email}
          onEmailChange={handleEmailChange}
          onSubmit={handleSubmit}
          disabled={pending}
        />
      </Box>
      <PasswordResetDialog open={passwordResetDialogOpen} email={email} />
    </>
  );
}
