import React from "react";
import { BoxProps } from "@mui/material";

import { MealLoggingQuestionStarRating } from "../meal-logging-settings/types";
import { ClientMealLoggingQuestionProps } from "./types";
import {
  ClientMealLoggingStarRating,
  ClientMealLoggingStarRatingProps,
} from "./ClientMealLoggingStarRating";

export interface ClientMealLoggingStarRatingQuestionProps
  extends Omit<BoxProps, "onSelect">,
    ClientMealLoggingQuestionProps<MealLoggingQuestionStarRating> {}

export function ClientMealLoggingStarRatingQuestion(
  props: ClientMealLoggingStarRatingQuestionProps,
) {
  const { className, option, onUpdate, ...other } = props;

  const handleSelect: ClientMealLoggingStarRatingProps["onSelect"] =
    React.useCallback(
      (value) => {
        if (onUpdate) {
          onUpdate({
            ...option,
            answer: String(value),
          });
        }
      },
      [onUpdate, option],
    );

  return (
    <ClientMealLoggingStarRating
      rating={parseInt(option.answer)}
      onSelect={handleSelect}
      {...other}
    />
  );
}
