import { graphql, commitMutation } from "react-relay";
import { RelayModernEnvironment } from "relay-runtime/lib/store/RelayModernEnvironment";

import type { AnalyticsJS } from "../hooks/useAnalytics";

import {
  ssrAnalyticsMutation,
  AnalyticsRequestType,
} from "./__generated__/ssrAnalyticsMutation.graphql";
import { uuidv4 } from "./uuid";
import { platform } from "./platform";

const mutation = graphql`
  mutation ssrAnalyticsMutation($input: SendAnalyticsRequestInput!) {
    sendAnalyticsRequest(input: $input) {
      clientMutationId
    }
  }
`;

type Callback = () => void;

const getAnonymousId = (key = "anonymousId") => {
  let id = localStorage.getItem(key);

  if (!id) {
    localStorage.setItem(key, (id = uuidv4()));
  }

  return id;
};

export const getSSRAnalytics = (
  relay: RelayModernEnvironment,
  userId,
): AnalyticsJS => {
  const anonymousId = getAnonymousId();

  const sendRequest = (
    type: AnalyticsRequestType,
    payload: any,
    callback?: Callback,
  ) =>
    commitMutation<ssrAnalyticsMutation>(relay, {
      mutation,
      variables: {
        input: {
          type,
          payload: JSON.stringify(payload),
        },
      },
      onCompleted: callback,
    });

  return {
    identify: (userId: string, traits: Object, callback?: Callback) =>
      sendRequest(
        "IDENTIFY",
        {
          anonymousId,
          userId,
          traits,
        },
        callback,
      ),

    track: (event: string, properties?: Object, callback?: Callback) =>
      sendRequest(
        "TRACK",
        {
          anonymousId,
          userId,
          event,
          properties,
        },
        callback,
      ),

    page: (name?: string, properties?: Object, callback?: Callback) =>
      sendRequest(
        "PAGE",
        {
          anonymousId,
          userId,
          name,
          properties,
        },
        callback,
      ),

    device: (userId: string, deviceToken: string, callback?: Callback) =>
      sendRequest(
        "DEVICE",
        {
          anonymousId,
          userId,
          platform,
          deviceToken,
        },
        callback,
      ),
  };
};
