import React, { ReactNode } from "react";
import { Box, Stack, Typography } from "@mui/material";

export const InfoBox = ({
  title,
  description,
  children,
}: {
  title: string;
  description: ReactNode | string;
  children?: ReactNode;
}) => {
  return (
    <Stack
      sx={{
        px: 3,
        py: 2,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
      spacing={0.8}
    >
      <Box display="flex" alignItems="center" gap={1} flexWrap={"wrap"}>
        <Typography fontWeight="bold">{title}</Typography>
        {children}
      </Box>
      <Typography
        component="span"
        variant="body2"
        display="inline-block"
        lineHeight={1.4}
      >
        {description}
      </Typography>
    </Stack>
  );
};
