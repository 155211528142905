import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { KeyboardBackspace as BackIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "&:last-child": {
      marginRight: theme.spacing(-1),
    },
  },

  tooltipPopper: {},

  tooltip: {
    padding: theme.spacing(1, 2),
  },
}));

export interface BackButtonProps extends ButtonProps {
  tooltip?: React.ReactNode;
}

export function BackButton(props: BackButtonProps) {
  const {
    className,
    tooltip,
    startIcon = <BackIcon />,
    children = "Back",
    ...other
  } = props;
  const s = useStyles();

  const button = (
    <Button
      className={clsx(s.root, className)}
      startIcon={startIcon}
      {...other}
    >
      {children}
    </Button>
  );

  return tooltip ? (
    <Tooltip
      classes={{ popper: s.tooltipPopper, tooltip: s.tooltip }}
      arrow
      placement="right"
      title={tooltip}
      children={button}
    />
  ) : (
    button
  );
}
