import { Box, FormHelperText } from "@mui/material";
import React from "react";
import { ErrorCode, FileRejection } from "react-dropzone";

interface IFileRejectionHelperText {
  uploaderErrors: FileRejection[] | undefined;
  getErrorMessage: (code: ErrorCode | string) => string;
}

const FileRejectionHelperText = ({
  uploaderErrors,
  getErrorMessage,
}: IFileRejectionHelperText) => {
  return (
    <>
      {uploaderErrors ? (
        uploaderErrors.map((file, index) => (
          <Box key={index}>
            {file.errors.map((error, index) => (
              <FormHelperText
                key={index}
                error
                sx={{ paddingInline: 3, paddingTop: 1 }}
              >
                {getErrorMessage(error.code)}
              </FormHelperText>
            ))}
          </Box>
        ))
      ) : (
        <FormHelperText error sx={{ paddingInline: 3, paddingTop: 1 }}>
          {` `}
        </FormHelperText>
      )}
    </>
  );
};

export default FileRejectionHelperText;
