import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Typography,
  Divider,
  Box,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { sortBy, reverse } from "lodash";

import {
  MeasurementTypeInfo,
  Units,
  getDefaultUnit,
  convertUnits,
} from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { ReactComponent as PlusIcon } from "../../icons/AddCircleOutline.svg";

import { ClientMeasurementRow, MetricType } from "./ClientMeasurementRow";
import { ClientMeasurementRows_metrics$key } from "./__generated__/ClientMeasurementRows_metrics.graphql";
import { ClientMeasurementRowsCreateClientMetricMutation } from "./__generated__/ClientMeasurementRowsCreateClientMetricMutation.graphql";
import { ClientMeasurementRowsUpdateClientMetricMutation } from "./__generated__/ClientMeasurementRowsUpdateClientMetricMutation.graphql";
import { ClientMeasurementRowsRemoveClientMetricMutation } from "./__generated__/ClientMeasurementRowsRemoveClientMetricMutation.graphql";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(3, 4, 2),
  },

  empty: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(3, 4),
  },

  divider: {
    margin: 0,
  },

  rows: {
    marginLeft: theme.spacing(4),
  },

  addResult: {
    margin: theme.spacing(2, 2, 0, 0),
    fontSize: 16,
    fontWeight: 500,
    float: "right",

    "& svg": {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  },
}));

const createMeasurementMutation = graphql`
  mutation ClientMeasurementRowsCreateClientMetricMutation(
    $input: CreateClientMetricInput!
  ) {
    createClientMetric(input: $input) {
      metric {
        x: activityDate(format: "YYYY-MM-DD")
        activityDate(raw: true)
        value {
          ... on CheckinAnswerMeasurementValue {
            measurement
            unit
          }
        }
      }
    }
  }
`;

const updateMeasurementMutation = graphql`
  mutation ClientMeasurementRowsUpdateClientMetricMutation(
    $input: UpdateClientMetricInput!
  ) {
    updateClientMetric(input: $input) {
      metric {
        x: activityDate(format: "YYYY-MM-DD")
        activityDate(raw: true)
        value {
          ... on CheckinAnswerMeasurementValue {
            measurement
            unit
          }
        }
      }
    }
  }
`;

const removeMeasurementMutation = graphql`
  mutation ClientMeasurementRowsRemoveClientMetricMutation(
    $input: RemoveClientMetricInput!
  ) {
    removeClientMetric(input: $input) {
      clientMutationId
    }
  }
`;

const metricsFragment = graphql`
  fragment ClientMeasurementRows_metrics on ClientMetric @relay(plural: true) {
    id
    activityDate(raw: true)
    name
    value {
      ... on CheckinAnswerMeasurementValue {
        measurement
        unit
      }
    }
  }
`;

export interface ClientMeasurementRowsProps extends CardProps {
  clientId?: string;
  metrics?: ClientMeasurementRows_metrics$key;
  measurementType?: MeasurementTypeInfo;
  units?: Units;
  refresh?: () => void;
}

export function ClientMeasurementRows(props: ClientMeasurementRowsProps) {
  const {
    className,
    clientId,
    metrics: metricsRef,
    measurementType,
    units,
    refresh,
    ...other
  } = props;
  const metrics = useFragment(metricsFragment, metricsRef);
  const s = useStyles();
  const snackAlert = useSnackAlert();

  const { unitType, name } = measurementType;
  const unit = getDefaultUnit(unitType, units);

  const [createMeasurement, creating] =
    useMutation<ClientMeasurementRowsCreateClientMetricMutation>(
      createMeasurementMutation,
    );

  const [updateMeasurement, updating] =
    useMutation<ClientMeasurementRowsUpdateClientMetricMutation>(
      updateMeasurementMutation,
    );

  const [removeMeasurement, removing] =
    useMutation<ClientMeasurementRowsRemoveClientMetricMutation>(
      removeMeasurementMutation,
    );

  const disabled = creating || updating || removing;
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [draftMetric, setDraftMetric] = React.useState<MetricType>(null);
  const formattedMetrics: MetricType[] = React.useMemo(
    () =>
      [
        draftMetric,
        ...reverse(
          sortBy(
            metrics.map(({ id, name, activityDate, value }) => ({
              id,
              name,
              date: activityDate,
              measurement: convertUnits(
                unitType,
                value.unit as Units,
                unit.toLocaleUpperCase() as Units,
                value.measurement,
              ),
              unit,
            })),
            ({ date }) => date,
          ),
        ),
      ].filter(Boolean),
    [draftMetric, metrics, unit, unitType],
  );

  const handleRemove = React.useCallback(
    ({ id }: MetricType) => {
      removeMeasurement({
        variables: {
          input: { id },
        },
        updater: (store) => {
          store.delete(id);
        },
        onCompleted: (_, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Delete successfully",
            });
          }
        },
      });
    },
    [removeMeasurement, snackAlert],
  );

  const handleEdit = React.useCallback(
    (metric: MetricType) => {
      setEditingIndex(formattedMetrics.findIndex((it) => it.id === metric.id));
    },
    [formattedMetrics],
  );

  const handleCancel = React.useCallback(() => {
    setEditingIndex(-1);

    if (draftMetric) {
      setDraftMetric(null);
    }
  }, [draftMetric]);

  const handleSave = React.useCallback(
    ({ id, unit, date, name, measurement, setConfirmDelete }: MetricType) => {
      if (id) {
        updateMeasurement({
          variables: {
            input: {
              id,
              measurement,
              date,
              unit,
            },
          },
          onCompleted: (_, errors) => {
            if (errors) {
              console.error(errors);
              snackAlert({
                severity: "error",
                message: "Error occurred.",
              });
            } else {
              snackAlert({
                severity: "success",
                message: "Saved successfully",
              });
              handleCancel();
            }
          },
        });
      } else {
        createMeasurement({
          variables: {
            input: {
              unit,
              date,
              name,
              measurement,
              clientId,
              type: measurementType.name.toLocaleUpperCase(),
            },
          },
          onCompleted: (_, errors) => {
            if (errors) {
              console.error(errors);
              snackAlert({
                severity: "error",
                message: "Error occurred.",
              });
            } else {
              snackAlert({
                severity: "success",
                message: "Created successfully",
              });
              handleCancel();

              if (refresh) {
                refresh();
              }
            }
          },
        });
      }
    },
    [
      clientId,
      createMeasurement,
      handleCancel,
      measurementType,
      refresh,
      snackAlert,
      updateMeasurement,
    ],
  );

  const handleCreateClick = React.useCallback(() => {
    setDraftMetric({
      name,
      unit,
      date: dayjs().format("YYYY-MM-DD"),
      measurement: 0,
    });
    setEditingIndex(0);
  }, [name, unit]);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      {!draftMetric && (
        <Button
          variant="text"
          color="primary"
          className={s.addResult}
          onClick={handleCreateClick}
          disabled={disabled}
        >
          Add result
          <PlusIcon />
        </Button>
      )}
      <Typography variant="subtitle1" className={s.title}>
        History
      </Typography>

      <Divider />

      <Box className={s.rows}>
        {formattedMetrics.map((metric, index) => (
          <React.Fragment key={[index, metric.date, metric.id].join(",")}>
            <ClientMeasurementRow
              metric={metric}
              disabled={disabled}
              editing={index === editingIndex}
              onCancel={handleCancel}
              onEdit={handleEdit}
              onSave={handleSave}
              onRemove={handleRemove}
            />
            {index < metrics.length && <Divider />}
          </React.Fragment>
        ))}
      </Box>
      {!formattedMetrics.length && (
        <Typography variant="body2" className={s.empty}>
          No measurement of this type found
        </Typography>
      )}
    </Card>
  );
}
