import clsx from "clsx";
import React from "react";
import {
  Menu,
  MenuProps,
  Divider,
  ListItemText,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  ISelectComponentIconComponent,
  SelectComponentIcons,
  SelectComponentIconsProps,
} from "../program-component/SelectComponentIcons";
import { SelectComponentIcons_component$key } from "../program-component/__generated__/SelectComponentIcons_component.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface SelectComponentIconsMenuPropsBase
  extends Omit<MenuProps, "onSubmit">,
    Pick<SelectComponentIconsProps, "onSubmit" | "disableApplyToAll"> {}

export interface SelectComponentIconsMenuPropsWithRef
  extends SelectComponentIconsMenuPropsBase {
  componentRef: SelectComponentIcons_component$key;
  componentData?: ISelectComponentIconComponent;
}

export interface SelectComponentIconsMenuPropsWithData
  extends SelectComponentIconsMenuPropsBase {
  componentData: ISelectComponentIconComponent;
  componentRef?: SelectComponentIcons_component$key;
}

export type SelectComponentIconsMenuProps =
  | SelectComponentIconsMenuPropsWithRef
  | SelectComponentIconsMenuPropsWithData;

export function SelectComponentIconsMenu(props: SelectComponentIconsMenuProps) {
  const {
    className,
    componentRef,
    onSubmit,
    disableApplyToAll,
    componentData,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Menu className={clsx(s.root, className)} {...other}>
      <MenuItem disabled>
        <ListItemText primary="Select a custom icon" />
      </MenuItem>

      <Divider />
      <SelectComponentIcons
        componentRef={componentRef}
        onSubmit={onSubmit}
        disableApplyToAll={disableApplyToAll}
        componentData={componentData}
      />
    </Menu>
  );
}
