import React from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import {
  QueryRenderer,
  createPaginationContainer,
  GraphQLTaggedNode,
} from "react-relay";
import { Variables } from "relay-runtime";

import {
  DynamicSearchListProps,
  DynamicSearchList,
  DynamicSearchFragment,
} from "./DynamicSearchList";

export interface DynamicSearchProps<F extends DynamicSearchFragment, V>
  extends Omit<DynamicSearchListProps<F>, "fragment" | "pageSize"> {
  fragment: GraphQLTaggedNode;
  query: GraphQLTaggedNode;
  variables?: V;
}

type DynamicSearchQuery<F> = {
  response: F;
  variables: Variables;
};

export const DynamicSearch = <
  F extends DynamicSearchFragment,
  V extends Variables = {},
>({
  query,
  fragment,
  variables = {
    first: 25,
  } as any as V,
  ...other
}: DynamicSearchProps<F, V>) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialVariables = React.useMemo(() => variables, []);
  const pageSize = initialVariables.first;

  const List = React.useMemo(
    () =>
      createPaginationContainer(
        DynamicSearchList,
        {
          fragment,
        },
        {
          direction: "forward",
          getConnectionFromProps: ({ fragment }) => fragment.connection,
          getVariables: (
            _props,
            { count: first, cursor: after },
            { query },
          ) => ({
            ...initialVariables,
            first,
            after,
            query,
          }),
          query,
        },
      ),
    [fragment, initialVariables, query],
  );

  return (
    <QueryRenderer<DynamicSearchQuery<F>>
      environment={useRelayEnvironment()}
      render={React.useCallback(
        ({ props }) =>
          props && <List fragment={props} pageSize={pageSize} {...other} />,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [List, pageSize, other],
      )}
      query={query}
      variables={initialVariables}
    />
  );
};
