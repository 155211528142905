import clsx from "clsx";
import React from "react";
import { Drawer, List, ListItem, useMediaQuery } from "@mui/material";
import { AccountCircle, Launch } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useLogout } from "../../hooks/useLogout";
import { Link } from "../link/Link";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    [theme.breakpoints.up("md")]: {
      width: theme.adminDrawer.width,
      zIndex: 1,
      paddingTop: theme.spacing(8),
    },
    borderRight: "1px solid",
    borderRightColor: theme.palette.border.primary,
  },
  nav: {
    marginTop: theme.spacing(3),
  },
  navItem: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0),
  },
  navItemSelected: {
    backgroundColor: theme.palette.selected.main,
  },
}));

function AppDrawer(props) {
  const { className, open, location, ...other } = props;
  const { breakpoints } = useTheme();
  const mediaQuery = `(min-width:${breakpoints.values.md}px)`;
  const mdUp = useMediaQuery(mediaQuery);
  const s = useStyles();
  const logout = useLogout();

  const isSelected = React.useCallback(
    (navPath) =>
      location.pathname === navPath ||
      location.pathname.startsWith(`${navPath}/`),
    [location.pathname],
  );

  const handleLogOut = React.useCallback(
    (event) => {
      event.preventDefault();
      logout();
    },
    [logout],
  );

  const menuItems = React.useMemo(
    () => [
      {
        text: "Users",
        link: "/admin/users",
        icon: <AccountCircle />,
      },
      // {
      //   text: "Programs",
      //   link: "/admin/programs",
      //   icon: <LibraryBooks />,
      // },
      {
        link: "/logout",
        text: "Log out",
        onClick: handleLogOut,
        icon: <Launch />,
      },
    ],
    [handleLogOut],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      {...other}
      classes={{ paper: s.paper }}
      open={mdUp ? true : open}
      variant={mdUp ? "permanent" : "temporary"}
      anchor="left"
    >
      <List className={s.nav}>
        {menuItems.map((x) => (
          <ListItem
            key={x.link}
            className={clsx(s.navItem, {
              [s.navItemSelected]: isSelected(x.link),
            })}
            button
            component={Link}
            href={x.link}
            onClick={x.onClick || props.onClose}
            underline="none"
          >
            {x.icon}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

const AdminDrawer = AppDrawer;
export { AdminDrawer };
