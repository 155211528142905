import React from "react";
import { useMutation } from "react-relay/hooks";
import {
  MutationParameters,
  GraphQLTaggedNode,
  PayloadError,
  SelectorStoreUpdater,
  ConnectionHandler,
  DeclarativeMutationConfig,
} from "relay-runtime";
import { UseMutationConfig } from "react-relay/hooks";

import { useGenericErrorHandler } from "./useGenericErrorHandler";

export interface ConfiguredMutationCallbacks<T extends MutationParameters>
  extends UseMutationConfig<T> {
  onFailure?: (errors: PayloadError[]) => void;
  onSuccess?: (response: T["response"]) => void;
  variables: T["variables"];
}

export const extendedDeclarativeStoreUpdater = (
  config: DeclarativeMutationConfig,
): SelectorStoreUpdater => {
  switch (config.type) {
    case "RANGE_DELETE":
      return (store) => {
        const { parentID, connectionKeys = [] } = config;

        for (const { key, filters } of connectionKeys) {
          const connection = ConnectionHandler.getConnection(
            store.get(parentID),
            key,
            filters,
          );
          const totalCount = connection && connection.getValue("totalCount");

          if (typeof totalCount === "number") {
            connection.setValue(totalCount - 1, "totalCount");
          }
        }
      };

    case "RANGE_ADD":
      return (store) => {
        const { parentID, connectionInfo = [] } = config;

        for (const { key, filters } of connectionInfo) {
          const connection = ConnectionHandler.getConnection(
            store.get(parentID),
            key,
            filters,
          );
          const totalCount = connection && connection.getValue("totalCount");

          if (typeof totalCount === "number") {
            connection.setValue(totalCount + 1, "totalCount");
          }
        }
      };
  }
};

export const extendDeclarativeMutationConfigs = <T extends MutationParameters>(
  mutationConfig: UseMutationConfig<T> & ConfiguredMutationCallbacks<T>,
) => {
  const { configs } = mutationConfig;

  if (configs) {
    const updaters = configs
      .map(extendedDeclarativeStoreUpdater)
      .filter(Boolean);

    if (mutationConfig.updater) {
      updaters.push(mutationConfig.updater);
    }

    if (updaters.length) {
      const updater: SelectorStoreUpdater = (store, data) => {
        for (const updater of updaters) {
          updater(store, data);
        }
      };

      return Object.assign({}, mutationConfig, { updater });
    }
  }

  return mutationConfig;
};

export const useConfiguredMutation = <T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  prepareConfig?: (
    config: Partial<ConfiguredMutationCallbacks<T>>,
  ) => Partial<ConfiguredMutationCallbacks<T>>,
) => {
  const onError = useGenericErrorHandler();
  const [commit, pending] = useMutation<T>(mutation);

  return [
    React.useCallback(
      (_config: ConfiguredMutationCallbacks<T>) => {
        const config = extendDeclarativeMutationConfigs({
          ..._config,
          ...(prepareConfig && prepareConfig(_config)),
        });

        return commit({
          onCompleted(response, errors) {
            if (errors?.length) {
              if (config.onFailure) {
                config.onFailure(errors);
              } else {
                onError(errors[0]);
              }
            } else if (config.onSuccess) {
              config.onSuccess(response);
            }
          },
          onError,
          ...config,
        });
      },
      [commit, onError, prepareConfig],
    ),
    pending,
  ] as const;
};
