/**
 * @generated SignedSource<<7ef0f6326098d744444a9c0a2254e07e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertClientGoalInput = {
  clientId: string;
  clientMutationId?: string | null | undefined;
  currentValue?: string | null | undefined;
  date?: string | null | undefined;
  id?: string | null | undefined;
  name: string;
  targetValue?: string | null | undefined;
};
export type UpsertClientGoalMutation$variables = {
  input: UpsertClientGoalInput;
};
export type UpsertClientGoalMutation$data = {
  readonly upsertClientGoal: {
    readonly clientGoalEdge: {
      readonly node: {
        readonly currentValue: string | null | undefined;
        readonly date: string | null | undefined;
        readonly formattedDate: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly targetValue: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ClientGoal_goal">;
      } | null | undefined;
    };
  } | null | undefined;
};
export type UpsertClientGoalMutation = {
  response: UpsertClientGoalMutation$data;
  variables: UpsertClientGoalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetValue",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentValue",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(raw:true)"
},
v7 = {
  "alias": "formattedDate",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "MMM D, YYYY"
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(format:\"MMM D, YYYY\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertClientGoalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertClientGoalPayload",
        "kind": "LinkedField",
        "name": "upsertClientGoal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientGoalEdge",
            "kind": "LinkedField",
            "name": "clientGoalEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientGoal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClientGoal_goal"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertClientGoalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertClientGoalPayload",
        "kind": "LinkedField",
        "name": "upsertClientGoal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientGoalEdge",
            "kind": "LinkedField",
            "name": "clientGoalEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientGoal",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1965b99264d42204c52f310156a21b4",
    "id": null,
    "metadata": {},
    "name": "UpsertClientGoalMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertClientGoalMutation(\n  $input: UpsertClientGoalInput!\n) {\n  upsertClientGoal(input: $input) {\n    clientGoalEdge {\n      node {\n        id\n        targetValue\n        currentValue\n        name\n        date(raw: true)\n        formattedDate: date(format: \"MMM D, YYYY\")\n        ...ClientGoal_goal\n      }\n    }\n  }\n}\n\nfragment ClientGoal_goal on ClientGoal {\n  id\n  name\n  targetValue\n  currentValue\n  formattedDate: date(format: \"MMM D, YYYY\")\n  completed\n}\n"
  }
};
})();

(node as any).hash = "f7e93963e446d2ebe6a2ce166f576b36";

export default node;
