import React from "react";

import { CurrentUserProvider_user$data } from "../components/routes/__generated__/CurrentUserProvider_user.graphql";
import { UserRole } from "../constants";

export type User = CurrentUserProvider_user$data;
export const CurrentUserContext = React.createContext<User>(null);

export function useCurrentUser() {
  return React.useContext(CurrentUserContext);
}

export function useUserIsClient() {
  const user = useCurrentUser();

  return user && user.role === UserRole.CLIENT;
}

export function useCurrentUserId() {
  const user = useCurrentUser();

  return user && user.id;
}

export function useCurrentUserRole() {
  const user = useCurrentUser();

  return user && user.role;
}
