import React, { createContext } from "react";
import { ClientSort } from "../constants";

interface CoachClientFiltersContextProps {
  after: string | null;
  setAfter: React.Dispatch<React.SetStateAction<string | null>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  filterTag: string;
  setFilterTag: (tag: string) => void;
  sortKey: ClientSort;
  setSortKey: (sortKey: ClientSort) => void;
}

export const CoachClientFiltersContext =
  createContext<CoachClientFiltersContextProps | null>(null);
