/**
 * @generated SignedSource<<f999d3ac8645629a6edbd26b082e9414>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendEmailVerificationCodeInput = {
  clientMutationId?: string | null | undefined;
  email: string;
};
export type ChangeEmailDialogCodeMutation$variables = {
  input: SendEmailVerificationCodeInput;
};
export type ChangeEmailDialogCodeMutation$data = {
  readonly sendEmailVerificationCode: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type ChangeEmailDialogCodeMutation = {
  response: ChangeEmailDialogCodeMutation$data;
  variables: ChangeEmailDialogCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendEmailVerificationCodePayload",
    "kind": "LinkedField",
    "name": "sendEmailVerificationCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEmailDialogCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeEmailDialogCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3fd9256f48e34b5a13ea6aa386ec2455",
    "id": null,
    "metadata": {},
    "name": "ChangeEmailDialogCodeMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeEmailDialogCodeMutation(\n  $input: SendEmailVerificationCodeInput!\n) {\n  sendEmailVerificationCode(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "37b3255023d5bf7c060a591f0be58ed9";

export default node;
