/**
 * @generated SignedSource<<469403a2f8ddd1d543552e2b7b7bf0ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DuplicateComponentInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  newId?: string | null | undefined;
  newWeekId?: string | null | undefined;
};
export type WeekComponentMenuDuplicateComponentMutation$variables = {
  input: DuplicateComponentInput;
};
export type WeekComponentMenuDuplicateComponentMutation$data = {
  readonly duplicateComponent: {
    readonly week: {
      readonly " $fragmentSpreads": FragmentRefs<"WeekCard_week">;
    };
  } | null | undefined;
};
export type WeekComponentMenuDuplicateComponentMutation = {
  response: WeekComponentMenuDuplicateComponentMutation$data;
  variables: WeekComponentMenuDuplicateComponentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": true
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WeekComponentMenuDuplicateComponentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateComponentPayload",
        "kind": "LinkedField",
        "name": "duplicateComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WeekCard_week"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WeekComponentMenuDuplicateComponentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateComponentPayload",
        "kind": "LinkedField",
        "name": "duplicateComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "positions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "archived",
                    "value": true
                  }
                ],
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "components",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": "title(draft:true)"
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": "image(draft:true)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "days",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "repeat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locked",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "previousStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weekId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "program",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": "content(draft:true)"
                  },
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "kind": "ScalarField",
                    "name": "teaser",
                    "storageKey": "teaser(draft:true)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reminderType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reminderTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "messageTime",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isComponentIcon"
                  }
                ],
                "storageKey": "components(archived:true)"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7e268ee16855db35d8c4ea1cbe00ad8",
    "id": null,
    "metadata": {},
    "name": "WeekComponentMenuDuplicateComponentMutation",
    "operationKind": "mutation",
    "text": "mutation WeekComponentMenuDuplicateComponentMutation(\n  $input: DuplicateComponentInput!\n) {\n  duplicateComponent(input: $input) {\n    week {\n      ...WeekCard_week\n      id\n    }\n  }\n}\n\nfragment ChangeComponentCoverDialog_component_3CJfPz on Component {\n  content(draft: true)\n  teaser(draft: true)\n  image(draft: true)\n  ...ComponentPreview_component_3CJfPz\n  program {\n    imageURL\n    id\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment ComponentPreview_component_3CJfPz on Component {\n  title(draft: true)\n  teaser(draft: true)\n  image(draft: true)\n  ...ComponentIcon_component\n}\n\nfragment CopyComponentDialog_component on Component {\n  id\n  program {\n    id\n  }\n}\n\nfragment SelectComponentIcons_component on Component {\n  id\n  iconName\n  type\n}\n\nfragment WeekCard_week on Week {\n  ...WeekDrawer_week\n  description\n  positions\n  components(archived: true) {\n    ...WeekComponentList_components\n    id\n  }\n}\n\nfragment WeekComponentListItem_component on Component {\n  type\n  status\n  title(draft: true)\n  image(draft: true)\n  days\n  repeat\n  slug\n  locked\n  ...ComponentIcon_component\n  ...WeekComponentMenu_component\n  ...WeekComponentSchedule_component\n}\n\nfragment WeekComponentList_components on Component {\n  ...WeekComponentListItem_component\n  id\n}\n\nfragment WeekComponentMenu_component on Component {\n  id\n  type\n  status\n  previousStatus\n  weekId\n  locked\n  ...SelectComponentIcons_component\n  ...CopyComponentDialog_component\n  ...ChangeComponentCoverDialog_component_3CJfPz\n}\n\nfragment WeekComponentSchedule_component on Component {\n  duration\n  reminderType\n  reminderTime\n  messageTime\n}\n\nfragment WeekDrawer_week on Week {\n  description\n}\n"
  }
};
})();

(node as any).hash = "78ab8c8eb216ef0c605963f9ac085154";

export default node;
