/**
 * @generated SignedSource<<05365ca596df2205e5d4d735c51d135c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type UpsertComponentInput = {
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  days?: ReadonlyArray<boolean> | null | undefined;
  draft?: string | null | undefined;
  duration?: number | null | undefined;
  habitPrompt?: string | null | undefined;
  iconName?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  locked?: boolean | null | undefined;
  messageContent?: string | null | undefined;
  messageTime?: string | null | undefined;
  reminderTime?: string | null | undefined;
  reminderType?: ReminderType | null | undefined;
  repeat?: ComponentRepeat | null | undefined;
  status?: ComponentStatus | null | undefined;
  title?: string | null | undefined;
  type?: ComponentType | null | undefined;
  weekId?: string | null | undefined;
};
export type ProgramCalendarWeeksListMutation$variables = {
  input: UpsertComponentInput;
};
export type ProgramCalendarWeeksListMutation$data = {
  readonly upsertComponent: {
    readonly component: {
      readonly days: ReadonlyArray<boolean>;
      readonly id: string;
      readonly updatedAt: string | null | undefined;
      readonly weekId: string;
    };
  } | null | undefined;
};
export type ProgramCalendarWeeksListMutation = {
  response: ProgramCalendarWeeksListMutation$data;
  variables: ProgramCalendarWeeksListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertComponentPayload",
    "kind": "LinkedField",
    "name": "upsertComponent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Component",
        "kind": "LinkedField",
        "name": "component",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "days",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weekId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "utc",
                "value": true
              }
            ],
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": "updatedAt(utc:true)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramCalendarWeeksListMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramCalendarWeeksListMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "91df5b7963ddcabd2e12c40de1658346",
    "id": null,
    "metadata": {},
    "name": "ProgramCalendarWeeksListMutation",
    "operationKind": "mutation",
    "text": "mutation ProgramCalendarWeeksListMutation(\n  $input: UpsertComponentInput!\n) {\n  upsertComponent(input: $input) {\n    component {\n      id\n      days\n      weekId\n      updatedAt(utc: true)\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c45c53506fa068fd1a5b3cdd8b2e6c1";

export default node;
