/**
 * @generated SignedSource<<56f815840a2ebdce4377490519e2a2eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WeekComponentListItem_component$data = {
  readonly days: ReadonlyArray<boolean>;
  readonly image: string;
  readonly locked: boolean;
  readonly repeat: ComponentRepeat;
  readonly slug: string;
  readonly status: ComponentStatus;
  readonly title: string;
  readonly type: ComponentType;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentIcon_component" | "WeekComponentMenu_component" | "WeekComponentSchedule_component">;
  readonly " $fragmentType": "WeekComponentListItem_component";
};
export type WeekComponentListItem_component$key = {
  readonly " $data"?: WeekComponentListItem_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekComponentListItem_component">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekComponentListItem_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(draft:true)"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "image",
      "storageKey": "image(draft:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "days",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locked",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentIcon_component"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WeekComponentMenu_component"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WeekComponentSchedule_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "584067cfd03cf0e6f3f71be17f2aaf4a";

export default node;
