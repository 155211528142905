import React from "react";

const Sitting = ({ fill }) => (
  <svg
    width="162"
    height="229"
    viewBox="0 0 162 229"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.1657 40.2866C86.6193 42.3764 90.0127 43.3861 91.6056 42.9739C95.5999 41.9403 95.9671 27.2712 92.8766 21.4881C89.786 15.7051 73.8979 13.1845 73.0971 24.6425C72.8191 28.6192 74.4824 32.1682 76.94 35.0641L72.5334 55.6827H85.3335L83.1657 40.2866Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.9565 37.6434C74.35 30.4683 69.7732 25.5773 70.4722 22.3704C71.1712 19.1634 74.1982 18.3818 74.1982 18.3818C74.1982 18.3818 75.7637 12.0238 84.2965 12.9732C92.8293 13.9226 98.0372 17.48 95.7881 25.3077C93.7207 25.3077 91.2249 24.5571 87.7738 25.8431C86.1312 26.4552 85.4241 29.6393 85.4241 29.6393H83.8646C83.8646 29.6393 81.5941 25.8607 79.3872 26.8622C77.1803 27.8637 78.3839 31.7277 78.3839 31.7277L77.6157 37.6434H75.9565Z"
      fill="#191847"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.3986 122.609H90.601L95.9998 189H34.6665L49.3986 122.609Z"
      fill="#C5CFD6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M49.3986 122.609H62.0707L69.6552 189H34.6665L49.3986 122.609Z"
      fill="black"
      fill-opacity="0.1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M113.239 135.762C108.609 145.62 101.374 169.55 101.374 169.55L107.516 172.367C107.516 172.367 123.101 147.212 132.625 130.017C132.182 133.888 131.73 138.246 131.311 142.828C130.318 153.681 131.31 177.128 131.844 183.901C132.18 188.158 137.941 187.266 138.479 183.89C138.57 183.319 138.941 181.311 139.495 178.316C142.212 163.628 149.321 125.189 149.333 115.776C149.338 111.989 141.968 108.587 137.575 111.235C134.558 108.003 128.847 106.14 125.566 111.479C123.48 114.873 118.524 124.51 113.239 135.762Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101.813 160.69L122.894 110.556C127.945 102.4 142.105 112.215 140.382 116.945C136.472 127.684 116.586 162.451 115.421 165.651L101.813 160.69Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M102.841 167.42C102.102 166.508 100.762 166.272 99.8934 167.057C99.1688 167.712 98.4103 168.472 98.0796 169.047C97.3583 170.301 96.3907 172.949 96.3907 172.949C97.8401 173.789 122.442 188.048 122.442 188.048C122.442 188.048 125.395 185.266 123.362 183.73C121.329 182.193 120.006 181.138 120.006 181.138L111.297 169.091C110.966 168.633 110.324 168.54 109.878 168.884L108.389 170.035C108.389 170.035 105.818 169.81 104.552 169.076C104.009 168.761 103.381 168.087 102.841 167.42Z"
      fill="#E4E4E4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M132.917 180.976C131.822 180.556 130.54 181.024 130.179 182.141C129.878 183.071 129.6 184.11 129.6 184.773C129.6 186.222 130.081 189 130.081 189C131.754 189 160.162 189 160.162 189C160.162 189 161.334 185.108 158.808 184.798C156.282 184.488 154.611 184.238 154.611 184.238L141.07 178.178C140.555 177.947 139.951 178.189 139.736 178.711L139.02 180.454C139.02 180.454 136.682 181.55 135.22 181.55C134.594 181.55 133.716 181.282 132.917 180.976Z"
      fill="#E4E4E4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M150.811 119.628C150.811 133.853 144.082 167.032 144.078 170.164L129.6 170.175C129.6 170.175 132.779 125.767 131.854 125.288C130.93 124.81 93.9605 128.994 82.8728 128.994C66.8842 128.994 60.2701 118.872 59.7334 100.122H91.4061C97.855 100.815 131.493 107.626 143.985 109.967C149.333 110.97 150.811 115.71 150.811 119.628Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.332 106.417L115.224 91.6208L110.206 100.337L125.916 109.636C129.856 115.866 132.169 118.557 132.857 117.711C133.437 116.997 133.171 116.215 132.927 115.495C132.736 114.934 132.558 114.41 132.802 113.986C133.361 113.016 136.515 113.571 139.486 114.227C142.456 114.882 141.769 113.417 141.138 112.653C138.33 110.378 134.728 108.299 130.332 106.417ZM41.279 127.914C42.842 124.731 48.1644 93.7076 48.1644 93.7076L59.3096 93.7516C59.3096 93.7516 48.9871 127.204 48.1644 129.251C47.0956 131.91 48.911 135.773 50.1421 138.393C50.3325 138.798 50.509 139.174 50.6586 139.51C48.9505 140.278 48.13 139.35 47.2649 138.373C46.2879 137.268 45.2541 136.1 42.8204 137.236C41.8801 137.675 40.9904 138.195 40.1211 138.702C37.1184 140.454 34.36 142.064 30.6076 139.739C30.0127 139.371 29.3608 137.983 30.9359 136.899C34.8601 134.198 40.515 129.47 41.279 127.914Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79.8375 46.7585L84.4492 45.9644C92.3893 65.8765 106.252 83.9691 126.037 100.242L111.086 118.476C91.3036 95.3056 79.1096 71.3996 79.8375 46.7585Z"
      fill="#191847"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.7334 104.941H100.8C100.8 104.941 86.5288 70.1055 86.5288 45.7566L74.141 43.9037C64.4053 59.6225 61.656 78.9755 59.7334 104.941Z"
      fill="#DDE3E9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.0667 122.609C45.5578 71.7429 73.8688 43.9037 73.8688 43.9037L73.8786 43.9125C73.8802 43.9095 73.8819 43.9066 73.8835 43.9037H74.6921C76.1435 43.9539 79.1134 44.0845 79.1134 44.0845L82.1067 52.672C82.5817 68.3774 87.6539 115.973 87.6539 115.973H60.7733C60.819 118.155 60.9202 120.369 61.083 122.609H41.0667Z"
      fill="#2F3676"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.7779 116.184C60.5634 106.681 61.4032 97.7575 62.8042 89.6823C63.5369 99.2644 65.0683 110.794 68.2667 116.184H60.7779Z"
      fill="black"
      fill-opacity="0.1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M66.7258 99.5864L80 93.3913V99.5864H66.7258Z"
      fill="white"
      fill-opacity="0.2"
    />
  </svg>
);

export default Sitting;
