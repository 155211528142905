import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import CheckInTextfield from "./CheckInTextfield";

export type INumberQuestionAnswer = {
  number?: number;
};

const NumberQuestion = ({
  view,
  disableAnswer,
  element,
  handleSetNode,
}: ICheckInQuestionPropsBase<INumberQuestionAnswer>) => {
  const answer = element.answer as INumberQuestionAnswer;

  const handleChange = (newValue: number | null) => {
    handleSetNode({ answer: { number: newValue } }, true);
  };

  const isCoachView = view === EditorElementView.Coach;
  const disabled = isCoachView || disableAnswer;

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      <CheckInTextfield
        type="number"
        placeholder="Fill in your answer"
        fullWidth
        value={answer?.number}
        onChange={(e) =>
          handleChange(e.target.value === "" ? null : parseInt(e.target.value))
        }
        disabled={disabled}
      />
    </DisabledCheckInTooltip>
  );
};

export default NumberQuestion;
