/**
 * @generated SignedSource<<3b4a4dc2f5b07aad65d6a9ef9790138b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseLibraryExercise_asset$data = {
  readonly content: string;
  readonly id: string;
  readonly name: string;
  readonly userId: string;
  readonly " $fragmentType": "ExerciseLibraryExercise_asset";
};
export type ExerciseLibraryExercise_asset$key = {
  readonly " $data"?: ExerciseLibraryExercise_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseLibraryExercise_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseLibraryExercise_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    }
  ],
  "type": "CustomAsset",
  "abstractKey": null
};

(node as any).hash = "4b7c130b2dafdd12c4f23a2367e2484a";

export default node;
