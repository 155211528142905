import { Accept, ErrorCode } from "react-dropzone";

export const getCustomErrorMessage = (
  code: ErrorCode | string,
  supportedTypes: Accept,
  maxFileSizeMb: number,
  minFileSize: number,
  maxFilesAmount: number,
) => {
  const supportedFileTypes: string[] = [];

  // Get list of supported file types from supportedTypes Accept object
  for (const type in supportedTypes) {
    if (Object.prototype.hasOwnProperty.call(supportedTypes, type)) {
      const result = supportedTypes[type];
      supportedFileTypes.push(...result);
    }
  }

  switch (code) {
    case ErrorCode.FileInvalidType:
      return `Sorry, supported file types is/are ${supportedFileTypes.map((s) => ` ${s}`)}`;
    case ErrorCode.FileTooLarge:
      return `Sorry, file is too large, max file size is ${maxFileSizeMb} mb`;
    case ErrorCode.FileTooSmall:
      return `Sorry, file is too small, min file size is ${minFileSize} mb`;
    case ErrorCode.TooManyFiles:
      return `Sorry, too many files, lease choose only ${maxFilesAmount} file/files`;
    default:
      return `Sorry, something went wrong`;
  }
};
