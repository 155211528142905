import {
  Editor,
  Path,
  Transforms,
  Node,
  NodeEntry,
  Element,
  Range,
} from "slate";
import { useSlate, useEditor, ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";

import { ElementType } from "../types/elements";
import {
  getEmptyNode,
  isEmpty,
  isEmptyNode,
  deepCompare,
} from "../utils/common";
import { ElementTypes, ElementTypeConfig } from "../constants/elements";
import { AnalyticsJS } from "../../../hooks/useAnalytics";

export type SchemaElementType = ElementType;
export type SchemaTypeName = SchemaElementType | "document";
export type SchemaMarks = SchemaElementType[];
export type SchemaNodeRule = {
  type: SchemaTypeName;
  maxOccurrence?: number;
  minOccurrence?: number;
  defaults?: any;
  match?: (currentNode: any) => boolean;
};
export type SchemaType = {
  nodes?: SchemaNodeRule[];
  marks?: SchemaMarks;
  defaultType?: SchemaElementType;
};

export type Schema = {
  marks: SchemaMarks;
  types: Partial<Record<SchemaTypeName, SchemaType>>;
};

export enum SchemaNodeTypes {
  DOCUMENT = "DOCUMENT",
  BLOCK = "BLOCK",
  INLINE = "INLINE",
}

const unsafeMethods = ["redo", "undo"] as const;

export const getNodeType = (editor: any, node: any): SchemaNodeTypes =>
  node.type && !Editor.isInline(editor, node)
    ? SchemaNodeTypes.BLOCK
    : Editor.isEditor(node)
      ? SchemaNodeTypes.DOCUMENT
      : SchemaNodeTypes.INLINE;

export const getSchemaTypeName = (node: any): SchemaTypeName =>
  Editor.isEditor(node) ? "document" : (node.type as SchemaTypeName);

export const getSchemaType = (
  schema: Schema,
  node: Node,
): SchemaType | null => {
  const nodeType = getSchemaTypeName(node);
  const typeDef = schema.types[nodeType];

  return typeDef;
};

export const getParentNode = (editor: Editor, path: Path) =>
  path.length ? Node.get(editor, Path.parent(path)) : null;

const skipMarkProps = ["text", "id"];

export const getNodeMarks = (node: Node): string[] =>
  Object.keys(node).filter((mark) => !skipMarkProps.includes(mark));

export interface SchemaEditor extends ReactEditor {
  allowedMarks(path?: Path): SchemaMarks;
  allowedBlocks(node?: Node, insert?: boolean): SchemaTypeName[];
  isSupported(type: ElementType): boolean;
  trackEvent?: AnalyticsJS["track"];
}

export interface WithSchemaOptions {
  schema: Schema;
  trackEvent?: AnalyticsJS["track"];
}

export function useSlateWithSchema(): ReactEditor & SchemaEditor {
  return useSlate() as ReactEditor & SchemaEditor;
}

export function useEditorWithSchema(): ReactEditor & SchemaEditor {
  return useEditor() as ReactEditor & SchemaEditor;
}

export type ElementId = any;
let localIdsCounter = 0;

export const SchemaElements = {
  generateId() {
    return Date.now() * 1000 + localIdsCounter++;
  },

  registerElement(editor: ReactEditor, element: any) {
    if (element instanceof Array) {
      return element.map((it) => SchemaElements.registerElement(editor, it));
    } else {
      (editor as any as SchemaEditor).trackEvent(
        "Coach - Add Component Block",
        {
          type: element.type,
        },
      );
    }
  },

  isVoid: (node: any) => {
    if (node.type) {
      const element = node as any;
      const isVoid = ElementTypes[element.type as ElementType]?.isVoid;

      return typeof isVoid === "function" ? isVoid(element) : isVoid || false;
    }

    return false;
  },

  isInline: (node: any) => {
    if (node.type) {
      const element = node as any;

      return ElementTypes[element.type as ElementType]?.inline || false;
    }

    return false;
  },

  isSelectedVoid: (editor: ReactEditor) => {
    const { selection } = editor;

    if (selection) {
      let { path } = editor.selection.anchor;

      for (; path.length > 0; path = Path.parent(path)) {
        const node: any = Node.get(editor, path);
        const isVoid = node.type && SchemaElements.isVoid(node);

        if (isVoid) {
          return true;
        }
      }
    }

    return false;
  },

  createElement(
    elementType: string,
    attrs: Record<string, any> = {},
    children: Node[] = [{ text: "" }],
  ): any {
    if (!attrs.id) {
      attrs.id = SchemaElements.generateId();
    }

    const typeDef = ElementTypes[elementType];
    const element = getEmptyNode(elementType, attrs, children) as any;
    return typeDef?.defaultProps ? typeDef.defaultProps(element) : element;
  },

  insertElement(
    editor: ReactEditor,
    elementType: ElementType,
    attrs: Record<string, any> = {},
    options: any = {},
  ): void {
    const element = getEmptyNode(elementType, attrs);
    Transforms.insertNodes(editor, element, options);
    SchemaElements.registerElement(editor, element);
  },

  insertElementAtEnd(
    editor: ReactEditor,
    elementType: ElementType,
    attrs: Record<string, any> = {},
  ): void {
    const element = getEmptyNode(elementType, attrs);
    SchemaElements.registerElement(editor, element);

    const last: any = editor.children[editor.children.length - 1];
    const lastId =
      last && last.type === ElementType.PARAGRAPH && isEmptyNode(last)
        ? last.id
        : null;

    if (lastId) {
      SchemaElements.replaceElement(editor, lastId, element);
    } else {
      Transforms.insertNodes(editor, element);
    }
  },

  updateElement(
    editor: ReactEditor,
    id: ElementId,
    attrs: any,
    onlyElements = false,
  ): void {
    const entry = SchemaElements.nodeEntryById(editor, id, onlyElements);

    if (entry) {
      const element = entry[0];
      const hasChanges = Object.entries(attrs).some(
        ([key, value]) => !deepCompare(element[key], value),
      );

      if (hasChanges) {
        Transforms.setNodes(editor, attrs, { at: entry[1] });
      }
    }
  },

  insertElementBefore(
    editor: ReactEditor,
    id: ElementId,
    element: Element | Element[],
  ) {
    const entry = SchemaElements.nodeEntryById(editor, id, false);

    if (entry) {
      const at = entry[1];

      Transforms.insertNodes(editor, element, { at });
      SchemaElements.registerElement(editor, element);

      return at;
    }
  },

  insertElementAfter(
    editor: ReactEditor,
    id: ElementId,
    element: Element | Element[],
  ) {
    const entry = SchemaElements.nodeEntryById(editor, id, false);

    if (entry) {
      const at = Path.next(entry[1]);

      Transforms.insertNodes(editor, element, { at });
      SchemaElements.registerElement(editor, element);

      return at;
    }
  },

  replaceElement(
    editor: ReactEditor,
    id: ElementId,
    element: Element,
    onlyElements = false,
  ) {
    const entry = SchemaElements.nodeEntryById(editor, id, onlyElements);

    if (entry) {
      const at = Path.next(entry[1]);

      Transforms.insertNodes(editor, element, { at });
      Transforms.removeNodes(editor, { at: entry[1] });
      Transforms.move(editor);
      SchemaElements.registerElement(editor, element);

      return entry[1];
    }
  },

  removeElement(
    editor: ReactEditor,
    id: ElementId,
    onlyElements = false,
  ): void {
    const entry = SchemaElements.nodeEntryById(editor, id, onlyElements);

    if (entry) {
      Transforms.removeNodes(editor, { at: entry[1] });
    }
  },

  parentElementById(editor: ReactEditor, id: ElementId): NodeEntry<any> | null {
    const entry = SchemaElements.nodeEntryById(editor, id);

    if (entry) {
      const parentPath = Path.parent(entry[1]);
      const parentElement = Node.get(editor, parentPath) as any;

      return [parentElement, parentPath];
    } else {
      return null;
    }
  },

  focusedContainer(editor: ReactEditor) {
    return SchemaElements.focusedByPred(
      editor,
      (_node, config) => config?.container,
      true,
    );
  },

  focusedBlock(editor: ReactEditor) {
    return SchemaElements.focusedByPred(
      editor,
      (_node, config) => config?.block,
      true,
    );
  },

  blockIsEmpty(editor: ReactEditor) {
    const focusedBlock = SchemaElements.focusedBlock(editor);

    return focusedBlock && !Node.string(focusedBlock[0]);
  },

  focusedByPred(
    editor: ReactEditor,
    pred: (node: any, typeConfig?: ElementTypeConfig) => boolean,
    editorAsRoot = false,
  ): NodeEntry<any | ReactEditor> | null {
    if (editor.selection) {
      for (
        let path = editor.selection.focus.path;
        path.length > 0;
        path = Path.parent(path)
      ) {
        const node = Node.get(editor, path) as any;
        const type = node.type as ElementType;
        const elementTypeConfig = type && ElementTypes[type];

        if (pred(node, elementTypeConfig)) {
          return [node, path];
        }
      }
    }

    return editorAsRoot ? [editor, []] : null;
  },

  nodeEntryById(
    editor: ReactEditor,
    id: ElementId,
    onlyElements = true,
  ): NodeEntry<Node> | null {
    const iter = onlyElements
      ? Node.elements(editor)
      : Node.descendants(editor);

    // for (const entry of iter) {
    //   if (entry[0].id === parseInt(id)) {
    //     return entry;
    //   }
    // }

    return null;
  },

  parentIsContainer(editor: ReactEditor, id: ElementId) {
    const [element] = SchemaElements.parentElementById(editor, id);

    return Editor.isEditor(element)
      ? true
      : Boolean(ElementTypes[element.type as ElementType].container);
  },

  closestEntryWithType(editor: any, types: ElementType[]) {
    if (editor.selection) {
      const [node, path] = Editor.node(editor, editor.selection);

      // while (path.length > 0) {
      //   if (types.includes(node.type as any)) {
      //     return [node, path] as const;
      //   } else {
      //     path = Path.parent(path);
      //     node = Node.get(editor, path);
      //   }
      // }
    }
  },

  closestEntryByPred(
    editor: SchemaEditor,
    entry: any,
    pred: (node: any, typeConfig?: ElementTypeConfig) => boolean,
  ): NodeEntry<Node> | null {
    let [node, path] = entry;

    while (path.length > 0) {
      const typeConfig = ElementTypes[node.type as ElementType];

      if (pred(node as any, typeConfig)) {
        return [node, path];
      } else {
        path = Path.parent(path);
        node = Node.get(editor, path);
      }
    }

    return null;
  },

  closestContainer(editor: SchemaEditor, id: ElementId) {
    const container = SchemaElements.closestEntryByPred(
      editor,
      SchemaElements.nodeEntryById(editor, id),
      (_, { container }) => container,
    );

    return container || [editor, []];
  },

  hasElementWithId(editor: SchemaEditor, elementId: ElementId) {
    return Boolean(SchemaElements.nodeEntryById(editor, elementId));
  },

  canInsertTo(
    editor: SchemaEditor,
    content: any | any[],
    targetId: ElementId,
  ): boolean {
    const [target, path] = SchemaElements.closestContainer(editor, targetId);
    const sourceElements = content instanceof Array ? content : [content];

    if (sourceElements.length) {
      const sourceTypes = sourceElements.map(
        (element) => element.type as ElementType,
      );
      const anySourceId = sourceElements[0].id;
      const containerEntry =
        anySourceId &&
        SchemaElements.hasElementWithId(editor, anySourceId) &&
        SchemaElements.closestContainer(editor, anySourceId);
      const inserting =
        !containerEntry || !Path.equals(path, containerEntry[1]);
      const allowed = editor.allowedBlocks(target, inserting);

      return sourceTypes.every((type) => allowed.includes(type));
    } else {
      return false;
    }
  },

  focusAtNode(editor: ReactEditor, elementId: ElementId) {
    const [, path] = SchemaElements.nodeEntryById(editor, elementId, true);
    ReactEditor.focus(editor);
    Transforms.select(editor, path);
  },

  isElementSelected(editor: ReactEditor, elementId: ElementId) {
    if (ReactEditor.isFocused(editor)) {
      const [, path] = SchemaElements.nodeEntryById(editor, elementId, true);

      return editor.selection && Range.includes(editor.selection, path);
    } else {
      return false;
    }
  },
} as const;

export const clipTrailingNodes = (value: any[]) => {
  const newValue = [...value];

  for (let i = newValue.length - 1; i > 0; i--) {
    const node = newValue[i];

    if (node.type === ElementType.PARAGRAPH && !Node.string(node)) {
      newValue.pop();
    } else {
      break;
    }
  }

  return newValue;
};
