/**
 * @generated SignedSource<<c7f43e62c4dc204a7817014dd1420886>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StartWorkout_activity$data = {
  readonly component: {
    readonly locked: boolean;
  } | null | undefined;
  readonly date: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "StartWorkout_activity";
};
export type StartWorkout_activity$key = {
  readonly " $data"?: StartWorkout_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"StartWorkout_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StartWorkout_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locked",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "f37f7cfc4e8106e9b6ac85b5ea762e72";

export default node;
