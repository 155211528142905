/**
 * @generated SignedSource<<e32e0cb663f71d99da90d5fe690e4500>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type PlanTierType = "BUILD" | "DEFAULT" | "LAUNCH" | "SCALE" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurrentUserProvider_user$data = {
  readonly accounts: ReadonlyArray<{
    readonly createdAt: string | null | undefined;
    readonly displayName: string | null | undefined;
    readonly email: string | null | undefined;
    readonly id: string;
    readonly isSample: boolean;
    readonly phone: string | null | undefined;
    readonly photoURL: string | null | undefined;
    readonly role: UserRole;
    readonly timeZone: string | null | undefined;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAccountMenuItem_user">;
  }>;
  readonly admin: boolean;
  readonly age: number | null | undefined;
  readonly birthday: string;
  readonly clientsCount: number;
  readonly clientsCountNoSample: number;
  readonly coach: {
    readonly displayName: string | null | undefined;
    readonly email: string | null | undefined;
    readonly photoURL: string | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly createdAt: string | null | undefined;
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly gender: string;
  readonly groups: {
    readonly totalCount: number;
  } | null | undefined;
  readonly height: string | null | undefined;
  readonly id: string;
  readonly isImpersonating: boolean;
  readonly isSample: boolean;
  readonly location: string;
  readonly notificationUnreadCount: number;
  readonly notificationsActive: boolean;
  readonly nutritionTarget: ReadonlyArray<{
    readonly id: string;
  } | null | undefined> | null | undefined;
  readonly phone: string | null | undefined;
  readonly phoneVerified: boolean;
  readonly photoURL: string | null | undefined;
  readonly plan: Plan | null | undefined;
  readonly planTier: PlanTierType | null | undefined;
  readonly programsCount: number;
  readonly rawEmail: string | null | undefined;
  readonly role: UserRole;
  readonly subscription: {
    readonly status: string | null | undefined;
  } | null | undefined;
  readonly timeZone: string | null | undefined;
  readonly topToolbar: boolean;
  readonly trialExpired: boolean;
  readonly trialExpiryDate: string | null | undefined;
  readonly trialExpiryDateTimestamp: string | null | undefined;
  readonly units: Units;
  readonly unreadActivitiesCount: number;
  readonly unreadMessagesCounter: number;
  readonly username: string;
  readonly weight: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GroupNavItemList_me" | "Notifications_user">;
  readonly " $fragmentType": "CurrentUserProvider_user";
};
export type CurrentUserProvider_user$key = {
  readonly " $data"?: CurrentUserProvider_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentUserProvider_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "raw",
    "value": true
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSample",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "shouldFetchNotifications"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentUserProvider_user",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": "rawEmail",
      "args": (v3/*: any*/),
      "kind": "ScalarField",
      "name": "email",
      "storageKey": "email(raw:true)"
    },
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneVerified",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadMessagesCounter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationsActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationUnreadCount",
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImpersonating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "admin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topToolbar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientsCount",
      "storageKey": null
    },
    {
      "alias": "clientsCountNoSample",
      "args": [
        {
          "kind": "Literal",
          "name": "noSample",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "clientsCount",
      "storageKey": "clientsCount(noSample:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "programsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadActivitiesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v3/*: any*/),
      "kind": "ScalarField",
      "name": "trialExpiryDate",
      "storageKey": "trialExpiryDate(raw:true)"
    },
    {
      "alias": "trialExpiryDateTimestamp",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "X"
        }
      ],
      "kind": "ScalarField",
      "name": "trialExpiryDate",
      "storageKey": "trialExpiryDate(format:\"X\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialExpired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planTier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/),
        (v10/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": (v3/*: any*/),
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": "displayName(raw:true)"
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v9/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAccountMenuItem_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "GroupConnection",
      "kind": "LinkedField",
      "name": "groups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "groups(first:3)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "condition": "shouldFetchNotifications",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Notifications_user"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupNavItemList_me"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "c7ef1e7a5fde9796b150fb88b8c92c31";

export default node;
