import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Element } from "slate";
import { Crop, Heart, Pen, Plus, Trash } from "lucide-react";
import { MenuProps } from "../../menu/Menu";
import { ReactComponent as AlignLeftIcon } from "../../../icons/BoxAlignLeft.svg";
import { ReactComponent as AlignCenterIcon } from "../../../icons/BoxAlignCenter.svg";
import { ReactComponent as AlignRightIcon } from "../../../icons/BoxAlignRight.svg";
import { ReactComponent as FullWidthIcon } from "../../../icons/BoxFullWidth.svg";
import { ElementType } from "../../editor/types/elements";
import { Action } from "../elements/MoreMenu";

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    zIndex: 1,
    display: "block",
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    opacity: 0,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    width: 32,
    height: 24,
    padding: 0,
    borderRadius: 4,
    "&:hover": {
      color: "white",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  },
}));

export interface MediaElementMenuProps extends Omit<MenuProps, "open"> {
  element?: Element;
  onChangeClick?: (event: React.MouseEvent) => void;
  onRemove?: () => void;
  onCropClick?: (event: React.MouseEvent) => void;
  onCoverChange?: (event: React.MouseEvent, value: string) => void;
  onSetAlign?: (align: string) => void;
  setActiveItemIndex?: (index: number) => void;
}

export enum MediaAlign {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
  FULL_WIDTH = "full_width",
}

export function getMediaElementMenuActions(props: any): Action[] {
  const {
    className,
    element,
    onRemove,
    onChangeClick,
    onCropClick,
    onCoverChange,
    onSetAlign,
    setActiveItemIndex,
    createAsset,
    component,
    ...other
  } = props;

  const handleChangeClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onChangeClick) {
      onChangeClick(event);
    }
  };

  const handleDeleteClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onRemove) {
      onRemove();
    }
  };

  const elementTypeName = ((element.type as string) || "").toLowerCase();
  const handleCoverChange = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onCoverChange) {
      onCoverChange(event, element.url as string);
    }
  };

  const handleCropClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCropClick(event);
  };

  const handleAddAssetClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    createAsset(element);
  };

  const align = (element as any).align;

  const handleAlignClick = (imageAlign: MediaAlign) => {
    onSetAlign(imageAlign === MediaAlign.CENTER ? "" : imageAlign);
  };

  const getCurrentAlignmentIcon = () => {
    switch (align) {
      case MediaAlign.LEFT:
        return <AlignLeftIcon fontSize={"small"} />;
      case MediaAlign.CENTER:
        return <AlignCenterIcon fontSize={"small"} />;
      case MediaAlign.RIGHT:
        return <AlignRightIcon fontSize={"small"} />;
      case MediaAlign.FULL_WIDTH:
        return <FullWidthIcon fontSize={"small"} />;
      default:
        return <AlignCenterIcon fontSize={"small"} />;
    }
  };

  const hasUrl = Boolean(element.url);
  const canChangeCover =
    component &&
    onCoverChange &&
    hasUrl &&
    [ElementType.IMAGE, ElementType.VIDEO].includes(
      element.type as ElementType,
    );
  const isAlignable =
    onSetAlign && element.type === ElementType.IMAGE && hasUrl;
  const canCrop = onCropClick && element.type === ElementType.IMAGE && hasUrl;
  const canChangeImage = onChangeClick && hasUrl;
  const canAddAsset = hasUrl && Boolean(element.name && element.size);

  return [
    isAlignable && {
      label: "Align",
      icon: getCurrentAlignmentIcon(),
      dropdownMenu: [
        {
          index: 0,
          action: (e) => {
            setActiveItemIndex(0), handleAlignClick(MediaAlign.LEFT);
          },
          icon: <AlignLeftIcon fontSize={"small"} />,
        },
        {
          index: 1,
          action: (e) => {
            setActiveItemIndex(1), handleAlignClick(MediaAlign.CENTER);
          },
          icon: <AlignCenterIcon fontSize={"small"} />,
        },
        {
          index: 2,
          action: (e) => {
            setActiveItemIndex(2), handleAlignClick(MediaAlign.RIGHT);
          },
          icon: <AlignRightIcon fontSize={"small"} />,
        },
        {
          index: 3,
          action: (e) => {
            setActiveItemIndex(3), handleAlignClick(MediaAlign.FULL_WIDTH);
          },
          icon: <FullWidthIcon fontSize={"small"} />,
        },
      ],
    },
    canChangeCover && {
      icon: <Heart fontSize="small" />,
      action: handleCoverChange,
      label: `Set as cover ${elementTypeName}`,
    },
    canChangeImage && {
      icon: <Pen fontSize="small" />,
      action: handleChangeClick,
      label: `Change ${elementTypeName}`,
    },
    canCrop && {
      icon: <Crop fontSize="small" />,
      action: handleCropClick,
      label: `Crop ${elementTypeName}`,
    },
    canAddAsset && {
      icon: <Plus fontSize="small" />,
      action: handleAddAssetClick,
      label: "Add to asset library",
    },
    onRemove && {
      icon: <Trash fontSize="small" />,
      action: handleDeleteClick,
      label: hasUrl ? `Delete ${elementTypeName}` : "Delete",
    },
  ].filter(Boolean);
}
