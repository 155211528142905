import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as Close } from "../../icons/Close.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    height: 34,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    backgroundColor: `${theme.palette.primary.main}99`,
    padding: theme.spacing(0.5, 2),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
  },

  remove: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
}));

export interface AddedLozengeProps extends BoxProps {
  text: string;
  onRemoveClick?: () => void;
}

export function AddedLozenge(props: AddedLozengeProps) {
  const { className, text, onRemoveClick, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography component="span" children={text} />
      {onRemoveClick && <Close className={s.remove} onClick={onRemoveClick} />}
    </Box>
  );
}
