/**
 * @generated SignedSource<<44de5f17dc988d2697b4d95a0765a8a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentUserProvider_root$data = {
  readonly me?: {
    readonly " $fragmentSpreads": FragmentRefs<"CurrentUserProvider_user">;
  } | null | undefined;
  readonly workspace: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
  };
  readonly " $fragmentType": "CurrentUserProvider_root";
};
export type CurrentUserProvider_root$key = {
  readonly " $data"?: CurrentUserProvider_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentUserProvider_root">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CurrentUserProvider_user"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "currentUserId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "overrideCurrentUser"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "program"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspace"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentUserProvider_root",
  "selections": [
    {
      "condition": "overrideCurrentUser",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "me",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "condition": "overrideCurrentUser",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "me",
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "currentUserId"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "programSlug",
          "variableName": "program"
        },
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "workspace"
        }
      ],
      "concreteType": "Workspace",
      "kind": "LinkedField",
      "name": "workspace",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ThemeProvider_workspace"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};
})();

(node as any).hash = "7ca77cb3b75b35a19cbfe670ef60d83a";

export default node;
