export enum LegacyElementType {
  NUMBERED_LIST = "numbered_list",
  BULLETED_LIST = "bulleted_list",
  LIST_ITEM = "list_item",
  CHECKLIST = "checklist",
  CHECKLIST_ITEM = "checklist_item",
  PARAGRAPH = "paragraph",
  LINK = "link",
  DIVIDER = "divider",
}
