/**
 * @generated SignedSource<<8b81a04ec96c627529a5ae953d4fd48b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityType = "CLIENT_FORM" | "MEAL_LOGGING" | "NUTRITION_TARGET" | "PROGRAM_COMPONENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationBox_notification$data = {
  readonly activityFeedback: {
    readonly activity: {
      readonly clientForm: {
        readonly formattedDate: string | null | undefined;
        readonly name: string;
        readonly slug: string;
      } | null | undefined;
      readonly component: {
        readonly program: {
          readonly name: string;
          readonly slug: string;
        };
        readonly slug: string;
        readonly title: string;
      } | null | undefined;
      readonly date: string | null | undefined;
      readonly type: ActivityType;
    };
    readonly content: string;
    readonly formattedDate: string | null | undefined;
  } | null | undefined;
  readonly activityFeedbackId: string;
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly photoURL: string | null | undefined;
    readonly username: string;
  };
  readonly clientForm: {
    readonly formattedDate: string | null | undefined;
    readonly id: string;
    readonly name: string;
    readonly slug: string;
  } | null | undefined;
  readonly deleted: boolean | null | undefined;
  readonly groupComment: {
    readonly author: {
      readonly displayName: string | null | undefined;
      readonly photoURL: string | null | undefined;
      readonly username: string;
    };
    readonly content: string;
    readonly formattedDate: string | null | undefined;
    readonly groupPost: {
      readonly group: {
        readonly name: string;
        readonly slug: string;
      };
      readonly title: string | null | undefined;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly groupPost: {
    readonly author: {
      readonly displayName: string | null | undefined;
      readonly photoURL: string | null | undefined;
      readonly username: string;
    };
    readonly content: string;
    readonly formattedDate: string | null | undefined;
    readonly group: {
      readonly name: string;
      readonly slug: string;
    };
    readonly id: string;
    readonly title: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly message: {
    readonly author: {
      readonly displayName: string | null | undefined;
      readonly photoURL: string | null | undefined;
      readonly username: string;
    };
    readonly content: string;
    readonly formattedDate: string | null | undefined;
    readonly id: string;
    readonly notified: boolean;
  } | null | undefined;
  readonly read: boolean;
  readonly " $fragmentType": "NotificationBox_notification";
};
export type NotificationBox_notification$key = {
  readonly " $data"?: NotificationBox_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationBox_notification">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMM DD, YYYY"
  }
],
v7 = {
  "alias": "formattedDate",
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Group",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationBox_notification",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityFeedbackId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityFeedback",
      "kind": "LinkedField",
      "name": "activityFeedback",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Activity",
          "kind": "LinkedField",
          "name": "activity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Component",
              "kind": "LinkedField",
              "name": "component",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Program",
                  "kind": "LinkedField",
                  "name": "program",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientForm",
              "kind": "LinkedField",
              "name": "clientForm",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v2/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "raw",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "date",
              "storageKey": "date(raw:true)"
            }
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": "formattedDate",
          "args": (v6/*: any*/),
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": "createdAt(format:\"MMM DD, YYYY\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupPost",
      "kind": "LinkedField",
      "name": "groupPost",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/),
        (v3/*: any*/),
        (v7/*: any*/),
        (v1/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Message",
      "kind": "LinkedField",
      "name": "message",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/),
        (v7/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notified",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupPostComment",
      "kind": "LinkedField",
      "name": "groupComment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/),
        (v7/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupPost",
          "kind": "LinkedField",
          "name": "groupPost",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientForm",
      "kind": "LinkedField",
      "name": "clientForm",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        (v2/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": null
};
})();

(node as any).hash = "a5874be4a8cc947819e44ee4895c262b";

export default node;
