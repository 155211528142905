/**
 * @generated SignedSource<<f05b14646332166e0efdbeac82038182>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientStatsQuery$variables = {
  clientId: string;
};
export type ClientStatsQuery$data = {
  readonly client: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientCardCompletion_client" | "ClientCardWeight_client">;
  } | null | undefined;
};
export type ClientStatsQuery = {
  response: ClientStatsQuery$data;
  variables: ClientStatsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "clientId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "YYYY-MM-DD"
    }
  ],
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": "createdAt(format:\"YYYY-MM-DD\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "completed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientStatsQuery",
    "selections": [
      {
        "alias": "client",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientCardWeight_client"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientCardCompletion_client"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientStatsQuery",
    "selections": [
      {
        "alias": "client",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 2
                  },
                  {
                    "kind": "Literal",
                    "name": "measurementType",
                    "value": "BODYWEIGHT"
                  },
                  {
                    "kind": "Literal",
                    "name": "metricType",
                    "value": "CHECKIN_ANSWER_MEASUREMENT"
                  }
                ],
                "concreteType": "ClientMetric",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "value",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "measurement",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unit",
                            "storageKey": null
                          }
                        ],
                        "type": "CheckinAnswerMeasurementValue",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": "metrics(limit:2,measurementType:\"BODYWEIGHT\",metricType:\"CHECKIN_ANSWER_MEASUREMENT\")"
              },
              {
                "alias": "completion30Days",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "period",
                    "value": "LAST_30_DAYS"
                  }
                ],
                "concreteType": "Completion",
                "kind": "LinkedField",
                "name": "completion",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "completion(period:\"LAST_30_DAYS\")"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "period",
                    "value": "LAST_7_DAYS"
                  }
                ],
                "concreteType": "CompletionByDate",
                "kind": "LinkedField",
                "name": "completionByDate",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Completion",
                    "kind": "LinkedField",
                    "name": "completion",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": "completionByDate(period:\"LAST_7_DAYS\")"
              }
            ],
            "type": "User",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21bd4c261b939ca3b7f6c07673c898c3",
    "id": null,
    "metadata": {},
    "name": "ClientStatsQuery",
    "operationKind": "query",
    "text": "query ClientStatsQuery(\n  $clientId: ID!\n) {\n  client: node(id: $clientId) {\n    __typename\n    ... on User {\n      ...ClientCardWeight_client\n      ...ClientCardCompletion_client\n    }\n    id\n  }\n}\n\nfragment ClientCardCompletion_client on User {\n  completion30Days: completion(period: LAST_30_DAYS) {\n    completed\n    total\n    rate\n  }\n  completionByDate(period: LAST_7_DAYS) {\n    ...ClientLast7DaysCompletion_completionByDate\n  }\n}\n\nfragment ClientCardWeight_client on User {\n  units\n  weight\n  createdAt(format: \"YYYY-MM-DD\")\n  metrics(metricType: CHECKIN_ANSWER_MEASUREMENT, measurementType: BODYWEIGHT, limit: 2) {\n    value {\n      __typename\n      ... on CheckinAnswerMeasurementValue {\n        measurement\n        unit\n      }\n    }\n    createdAt(format: \"YYYY-MM-DD\")\n    id\n  }\n}\n\nfragment ClientLast7DaysCompletion_completionByDate on CompletionByDate {\n  date\n  completion {\n    completed\n    total\n    rate\n  }\n}\n"
  }
};
})();

(node as any).hash = "0fe2a95930ee5b234effc73e6a970eba";

export default node;
