import React from "react";
import { useTheme } from "@mui/material";
import { Breakpoint } from "@mui/material/styles";

import { isMobileApp } from "../utils/mobile";

import { useDebounce } from "./useDebounce";

export function useMediaMobile(
  breakpoint: Breakpoint = "md",
  checkMobileApp = true,
) {
  const theme = useTheme();

  const getIsMobile = React.useCallback(() => {
    const query = `(min-width:${theme.breakpoints.values[breakpoint]}px)`;
    const { matches } = window.matchMedia(query);

    return !matches || (checkMobileApp && isMobileApp);
  }, [breakpoint, checkMobileApp, theme.breakpoints.values]);

  const [isMobile, setIsMobile] = React.useState(getIsMobile());

  const update = React.useCallback(
    () => setIsMobile(getIsMobile()),
    [getIsMobile],
  );

  React.useEffect(() => {
    window.addEventListener("resize", update);

    return () => window.removeEventListener("resize", update);
  }, [update]);

  return useDebounce(isMobile, 25);
}
