const getNativeIntercom = () =>
  typeof window["intercom"] === "undefined" ? null : window["intercom"];

const logout = () => {
  const _intercom = getNativeIntercom();

  if (_intercom) {
    _intercom.logout();
  }
};

const registerIdentifiedUser = (userId: string, email: string) => {
  const _intercom = getNativeIntercom();

  if (_intercom) {
    _intercom.registerIdentifiedUser({ userId, email });
  }
};
export const nativeIntercom = {
  getNativeIntercom,
  logout,
  registerIdentifiedUser,
};
