import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ChevronArrowForward } from "../../icons/ChevronForwardOutline.svg";
import {
  getISODate,
  getCalendarMonths,
  parseISODate,
  monthsLabels,
} from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  text: {
    fontSize: 24,
    fontWeight: 600,
  },

  button: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 1),
  },

  reverse: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
}));

export interface MonthSelectProps extends Omit<BoxProps, "onSelect"> {
  onSelect?: (date: string) => void;
  date?: string;
}

export function MonthSelect(props: MonthSelectProps) {
  const { className, date = getISODate(), onSelect, ...other } = props;
  const s = useStyles();
  const months = getCalendarMonths(date, -2, 3);
  const [year, month] = parseISODate(date);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {
        dataset: { date },
      } = event.currentTarget;

      if (date && onSelect) {
        onSelect(date);
      }
    },
    [onSelect],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <IconButton
        className={clsx(s.button, s.reverse)}
        onClick={handleClick}
        data-date={months[0]}
        size="large"
      >
        <ChevronArrowForward />
      </IconButton>

      <Typography className={s.text}>
        {monthsLabels[month]} {year}
      </Typography>

      <IconButton
        className={s.button}
        onClick={handleClick}
        data-date={months[2]}
        size="large"
      >
        <ChevronArrowForward />
      </IconButton>
    </Box>
  );
}
