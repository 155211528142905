import clsx from "clsx";
import React from "react";
import { Card, CardProps, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { TextButton } from "../button/TextButton";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useAnalytics } from "../../hooks/useAnalytics";
import { SOMETHING_WENT_WRONG } from "../../constants";

import { CardMedia } from "./CardMedia";
import { CardProgramTemplate_template$key } from "./__generated__/CardProgramTemplate_template.graphql";
import { CardProgramTemplateMutation } from "./__generated__/CardProgramTemplateMutation.graphql";
import { useNavigate } from "react-router-dom";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  media: {
    width: "100%",
    height: 141,
  },
  content: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  category: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.secondary.main}74`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    fontSize: 14,
    fontWeight: 700,
    position: "absolute",
    left: 0,
    top: theme.spacing(3.5),
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.secondary.main,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  description: {
    height: "4.5rem",
    overflow: "hidden",
    "& p": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

const fragment = graphql`
  fragment CardProgramTemplate_template on ProgramTemplate {
    id
    name
    imageURL
    description
    programLength: length
    used
    category
  }
`;

const useTemplateMutation = graphql`
  mutation CardProgramTemplateMutation($input: UseProgramTemplateInput!) {
    useProgramTemplate(input: $input) {
      program {
        id
        slug
      }
    }
  }
`;

export interface CardProgramTemplateProps extends CardProps {
  templateRef: CardProgramTemplate_template$key;
}

export function CardProgramTemplate(props: CardProgramTemplateProps) {
  const { className, templateRef, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const [trackEvent] = useAnalytics();
  const template = useFragment(fragment, templateRef);
  const [createProgram] =
    useMutation<CardProgramTemplateMutation>(useTemplateMutation);

  const {
    id: templateId,
    imageURL,
    name,
    description,
    programLength,
    category,
    used,
  } = template;

  const handleClick = React.useCallback(
    (event) => {
      event.preventDefault();
      createProgram({
        variables: { input: { templateId } },
        onCompleted({ useProgramTemplate }, errors) {
          if (errors) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            trackEvent("Coach - Use Program Template", {
              name,
              templateId,
            });

            snackAlert({
              severity: "success",
              message: "Successfully created new program using template.",
            });

            const { program } = useProgramTemplate;

            navigate(
              COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", program.slug),
            );
          }
        },
      });
    },
    [createProgram, templateId, snackAlert, trackEvent, name],
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <CardMedia className={s.media} image={imageURL} />
      {category && <Typography className={s.category}>{category}</Typography>}
      <Box className={s.content}>
        <Typography className={s.title} variant="subtitle1">
          {name}
        </Typography>
        <Typography className={s.subtitle}>
          {programLength} week{programLength > 1 && "s"}
          {" • "}
          Used {used} time{used > 1 && "s"}
        </Typography>
        <Box className={s.description}>
          <Typography>{description}</Typography>
        </Box>
        <TextButton onClick={handleClick}>Use template</TextButton>
      </Box>
    </Card>
  );
}
