export const partiallyCompare = (
  left: Record<string, any>,
  right: Record<string, any>,
) => Object.entries(left).every(([name, value]) => right[name] === value);

export const slugify = (s: string) =>
  s
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");

export const toEnum = <T, E>(value: any, enumType: E, defaultValue: T): T => {
  const enumValue = Object.values(enumType).find(
    (it) => it.toUpperCase() === (value || "").toUpperCase(),
  );

  return enumValue || defaultValue;
};
