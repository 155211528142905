import clsx from "clsx";
import React from "react";
import { Box, BoxProps, ListItem, List, ListSubheader } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ElementTypes } from "../editor/constants/elements";
import { ElementType } from "../editor/types/elements";
import { queryMatchesContent } from "../../utils/search";

import {
  LibrarySections,
  LibrarySection,
  LibrarySubSections,
  ElementTypesDescriptions,
} from "./constants";
import { ComponentLibrarySectionComponentsList } from "./ComponentLibrarySectionComponentsList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(-4),
  },

  subSection: {
    "& ul": {
      width: "100%",
    },

    "& li": {
      padding: 0,
    },
  },
  subHeader: {
    textTransform: "uppercase",
    fontWeight: 700,
  },
  divider: {
    backgroundColor: theme.palette.quote,
    marginLeft: theme.spacing(5),
  },
}));

export interface ComponentLibrarySectionProps extends BoxProps {
  section: LibrarySection;
  query?: string;
}

export function ComponentLibrarySection(props: ComponentLibrarySectionProps) {
  const { className, section, query, ...other } = props;
  const s = useStyles();

  const subSections = React.useMemo(
    () =>
      LibrarySections[section].subSections
        .map((subSection) => {
          const { types, ...other } = LibrarySubSections[subSection];

          return {
            subSection,
            types: types.filter(getTypeQueryFilter(query)),
            ...other,
          };
        })
        .filter(({ types }) => types.length),
    [query, section],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <List className={s.subSection}>
        {subSections.map(({ subSection, name, types }) => (
          <ListItem key={subSection} disableGutters>
            <ComponentLibrarySectionComponentsList
              types={types}
              subheader={
                <ListSubheader
                  className={s.subHeader}
                  disableGutters
                  disableSticky
                >
                  {name}
                </ListSubheader>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

const getTypeQueryFilter = (query?: string) => (type: string) => {
  const info = ElementTypesDescriptions[type];
  const text = [info?.text, info?.description].filter(Boolean).join(" ");

  return queryMatchesContent(text, query);
};
