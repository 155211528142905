import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add as AddIcon } from "@mui/icons-material";
import { PlateEditor, useEditorRef } from "@udecode/plate-common";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    borderColor: "transparent",
    borderStyle: "solid",
    cursor: "pointer",
    opacity: 0,

    "&::before": {
      content: '""',
      display: "block",
      backgroundColor: theme.palette.primary.main,
    },

    "&:hover": {
      opacity: 1,
    },

    transition: theme.transitions.create(["opacity"]),
  },

  button: {
    position: "absolute",
    width: theme.spacing(3),
    height: theme.spacing(3),

    "& > span": {
      position: "absolute",
    },

    "& svg": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "50%",
      color: theme.palette.primary.main,
    },

    "$horizontal &": {
      top: `calc(50% - ${theme.spacing(1.5)})`,
      left: theme.spacing(-1.5),
    },

    "$vertical &": {
      top: theme.spacing(-1.5),
      left: `calc(50% - ${theme.spacing(1.5)})`,
    },
  },

  horizontal: {
    width: 2,
    height: "100%",
    borderWidth: 5,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    margin: theme.spacing(0, -0.75),

    "&::before": {
      width: 2,
      height: "100%",
    },
  },

  vertical: {
    height: 2,
    width: "100%",
    borderWidth: 5,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    margin: theme.spacing(-0.75, 0),

    "&::before": {
      width: "100%",
      height: 2,
    },
  },
}));

export interface TabInsertDividerProps {
  orientation: "horizontal" | "vertical";
  className: string;
  onAdd: () => void;
}

export function TabInsertDivider(props: TabInsertDividerProps) {
  const { className, orientation, onAdd, ...other } = props;
  const s = useStyles();

  return (
    <Box
      className={clsx(s.root, className, s[orientation])}
      contentEditable={false}
      {...other}
    >
      <IconButton
        className={s.button}
        children={<AddIcon />}
        onClick={onAdd}
        disableRipple
        {...other}
        size="large"
      />
    </Box>
  );
}
