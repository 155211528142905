/**
 * @generated SignedSource<<c6928fed7882923f3176323b997ffaf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CopyComponentDialog_component$data = {
  readonly id: string;
  readonly program: {
    readonly id: string;
  };
  readonly " $fragmentType": "CopyComponentDialog_component";
};
export type CopyComponentDialog_component$key = {
  readonly " $data"?: CopyComponentDialog_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"CopyComponentDialog_component">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CopyComponentDialog_component",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "a1010825664dc007c377d48bba67cc80";

export default node;
