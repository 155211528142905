import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import logo from "./logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",

    [theme.breakpoints.up("md")]: {
      paddingTop: "initial",
      marginBottom: theme.spacing(5),
      fontSize: 16,
    },

    "& img": {
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export interface PoweredByStridistProps extends TypographyProps {}

export function PoweredByStridist(props: PoweredByStridistProps) {
  const { className } = props;
  const s = useStyles();

  return (
    <Typography className={clsx(s.root, className)} component="div">
      <img src={logo} width={40} height={40} alt="Stridist logo" />
      Powered by Stridist
    </Typography>
  );
}
