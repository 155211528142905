import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ProgramWeekDateRangeProps extends TypographyProps {
  startDate: string;
  week: number;
}

export function ProgramWeekDateRange(props: ProgramWeekDateRangeProps) {
  const { className, startDate, week, ...other } = props;
  const s = useStyles();

  return (
    <Typography className={clsx(s.root, className)} {...other}>
      {dayjs(startDate)
        .add((week - 1) * 7, "days")
        .format("MMM D")}{" "}
      -{" "}
      {dayjs(startDate)
        .add((week - 1) * 7 + 6, "days")
        .format("MMM D")}
    </Typography>
  );
}
