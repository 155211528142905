/**
 * @generated SignedSource<<c0e0b82fe59027823cc1a284baba54a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTagInput = {
  clientMutationId?: string | null | undefined;
  title: string;
};
export type ClientSettingsDialogCreateTagMutation$variables = {
  input: CreateTagInput;
};
export type ClientSettingsDialogCreateTagMutation$data = {
  readonly createTag: {
    readonly tag: {
      readonly id: string;
      readonly title: string;
    } | null | undefined;
  } | null | undefined;
};
export type ClientSettingsDialogCreateTagMutation = {
  response: ClientSettingsDialogCreateTagMutation$data;
  variables: ClientSettingsDialogCreateTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTagPayload",
    "kind": "LinkedField",
    "name": "createTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsDialogCreateTagMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSettingsDialogCreateTagMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26749539470331059b0c1f13b8b58ef8",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsDialogCreateTagMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSettingsDialogCreateTagMutation(\n  $input: CreateTagInput!\n) {\n  createTag(input: $input) {\n    tag {\n      title\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbfbd9a9d39eb51ba80a0a201f995cf6";

export default node;
