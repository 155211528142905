/**
 * @generated SignedSource<<a2c55935824766efd0507c99b870cc2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationDeleteInput = {
  activityFeedbackId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  groupPostComment?: string | null | undefined;
  groupPostId?: string | null | undefined;
  id?: string | null | undefined;
};
export type NotificationBoxDeleteMutation$variables = {
  input: NotificationDeleteInput;
};
export type NotificationBoxDeleteMutation$data = {
  readonly notificationDelete: {
    readonly notification: {
      readonly activityFeedbackId: string;
      readonly id: string;
    };
  } | null | undefined;
};
export type NotificationBoxDeleteMutation = {
  response: NotificationBoxDeleteMutation$data;
  variables: NotificationBoxDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NotificationDeletePayload",
    "kind": "LinkedField",
    "name": "notificationDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "notification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activityFeedbackId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationBoxDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationBoxDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4aefb1c12f5d989410f84480749d5326",
    "id": null,
    "metadata": {},
    "name": "NotificationBoxDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationBoxDeleteMutation(\n  $input: NotificationDeleteInput!\n) {\n  notificationDelete(input: $input) {\n    notification {\n      id\n      activityFeedbackId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "623367e1ef38dffa0fbf66a6fca0f0c4";

export default node;
