import clsx from "clsx";
import React from "react";
import { Menu as MuiMenu, MenuProps as MuiMenuProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: theme.spacing(35),
    overFlowX: "hidden",
    borderRadius: theme.spacing(1.5),

    "& [role=menuitem]": {
      "& svg": {
        width: 16,
        height: 16,
        margin: theme.spacing(0, -1.5, 0, 1),
      },
    },

    "& span": {
      fontWeight: 500,
    },

    "& [role=menuitem]:hover": {
      backgroundColor: theme.palette.quote,
    },
  },

  inverse: {
    "& $paper": {
      backgroundColor: theme.palette.common.black,
      borderRadius: theme.spacing(0.5),
    },

    "& $paper [role=menuitem]": {
      color: theme.palette.text.secondary,
      fontSize: 14,
    },

    "& $paper [role=menuitem] svg": {
      color: theme.palette.text.secondary,
    },

    "& $paper [role=menuitem]:hover": {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.quote}40`,
    },

    "& $paper [role=menuitem]:hover svg": {
      color: theme.palette.common.white,
    },
  },
}));

export interface MenuProps extends MuiMenuProps {
  styleVariant?: "normal" | "inverse";
}

export function Menu(props: MenuProps) {
  const { className, styleVariant, classes, ...other } = props;
  const s = useStyles();

  return (
    <MuiMenu
      className={clsx(s.root, className, {
        [s.inverse]: styleVariant === "inverse",
      })}
      classes={{
        paper: clsx(s.paper, classes?.paper),
      }}
      {...other}
    />
  );
}
