/**
 * @generated SignedSource<<dd7203aa3e3924798590fa0e0b4d386b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GroupPostCommentsListItem_comment$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly role: UserRole;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_user" | "GroupMemberBadges_user">;
  };
  readonly content: string;
  readonly createdAt: string | null | undefined;
  readonly id: string;
  readonly notification: {
    readonly read: boolean;
    readonly userId: string;
  } | null | undefined;
  readonly replies?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsListItemNoReplies_comment" | "GroupPostsCommentForm_comment">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsCommentForm_comment" | "Likeable_likeable">;
  readonly " $fragmentType": "GroupPostCommentsListItem_comment";
};
export type GroupPostCommentsListItem_comment$key = {
  readonly " $data"?: GroupPostCommentsListItem_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsListItem_comment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "replies"
],
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "GroupPostsCommentForm_comment"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "fetchReplies"
    },
    {
      "defaultValue": 3,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "last",
        "cursor": "before",
        "direction": "backward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": null,
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./GroupPostCommentsListItemRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "GroupPostCommentsListItem_comment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Likeable_likeable"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "condition": "fetchReplies",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "replies",
          "args": null,
          "concreteType": "GroupPostCommentConnection",
          "kind": "LinkedField",
          "name": "__GroupPostCommentsListItem_replies_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GroupPostCommentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GroupPostComment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "GroupPostCommentsListItemNoReplies_comment"
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Notification",
      "kind": "LinkedField",
      "name": "notification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "read",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GroupMemberBadges_user"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GroupPostComment",
  "abstractKey": null
};
})();

(node as any).hash = "58e8c04cc5b25d5c09acc0bee2bf1be6";

export default node;
