import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { GroupPostsScreen } from "../../../../components/group-posts/GroupPostsScreen";
import { CoachGroupPostsRouteQuery } from "./__generated__/CoachGroupPostsRouteQuery.graphql";
import GroupContext from "../../../../contexts/GroupContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import { UserRole } from "../../../../constants";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachGroupPostsRoute() {
  const { slug } = useParams();

  const props = useLazyLoadQuery<CoachGroupPostsRouteQuery>(
    graphql`
      query CoachGroupPostsRouteQuery($slug: String!) {
        ...GroupPostsScreen_rootRef @arguments(slug: $slug)

        group(slug: $slug) {
          id
          name
          slug
          membersCount
          membersCanNotPost
          coachId
        }

        me {
          id
          role
        }
      }
    `,
    {
      slug: slug,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { group, me } = props;
  const canPost =
    group && (!group.membersCanNotPost || group.coachId === me?.id);

  const context = useContext(GroupContext);
  context.setGroupRouteQuery(props);

  return (
    <>
      {group && (
        <>
          <GroupPostsScreen rootRef={props} />
          <TrackInfoTool
            trackInfo={{
              name: `${
                me?.role === UserRole.COACH ? "Coach" : "Client"
              } - Group Posts`,
              properties: {
                groupSlug: group.slug,
                groupName: group.name,
              },
            }}
          />
        </>
      )}
    </>
  );
}
