import React from "react";
import clsx from "clsx";
import { IconButton } from "@mui/material";

type WorkoutButtonProps = {
  icon: React.ReactNode;
  text: string;
  style: string;
  styleText: string;
  onClick: () => void;
  closeIcon?: React.ReactNode;
  isOpen?: boolean;
  disabled?: boolean;
};

const WorkoutButton = (props: WorkoutButtonProps) => {
  const { icon, text, style, styleText, onClick, closeIcon, isOpen, disabled } =
    props;
  return (
    <IconButton
      color="primary"
      className={clsx(style)}
      onClick={onClick}
      size="large"
      disabled={disabled}
    >
      {isOpen ? closeIcon : icon}{" "}
      <span className={clsx(styleText)}>{text}</span>
    </IconButton>
  );
};

export default WorkoutButton;
