import { alpha } from "@mui/material";
import React from "react";

const Mentally = ({ fill, className }) => (
  <svg
    className={className}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.05" cx="40" cy="40" r="40" fill={fill} />
    <path
      d="M55.7103 44.9191H58.5971C59.5043 43.4553 60 41.7532 60 40C60 38.2411 59.5009 36.5336 58.588 35.0666H52.585C50.8293 35.0666 49.4009 36.4949 49.4009 38.2506V39.1524H47.0572V38.2506C47.0572 36.3739 47.998 34.7137 49.432 33.7141C48.2624 32.6998 47.5206 31.2047 47.5206 29.5387V28.637H49.8644V29.5387C49.8644 31.2945 51.2927 32.7228 53.0484 32.7228H57.4183C57.4183 29.2168 55.1049 25.9534 51.8068 24.9832C51.0846 22.0909 48.4704 20 45.4166 20C43.8008 20 42.319 20.5849 41.1716 21.5538V28.7192L39.6389 36.4154H43.9673L41.923 46.7969H39.5342L41.117 38.7591H36.7824L38.8279 28.4881V21.5534C37.6805 20.5847 36.1989 20 34.5834 20C31.5296 20 28.9154 22.0909 28.1932 24.9831C24.8951 25.9534 22.582 29.231 22.582 32.7371H28.9777C30.7334 32.7371 32.1617 31.3088 32.1617 29.553V28.6513H34.5055V29.553C34.5055 32.4033 32.337 34.7562 29.5632 35.0496C30.214 35.9562 30.5988 37.0662 30.5988 38.2649V39.1667H28.2551V38.2648C28.2551 36.5091 26.8267 35.0808 25.071 35.0808H21.4029C20.4957 36.5447 20 38.2468 20 40C20 41.7589 20.4991 43.4664 21.412 44.9334H29.759C31.5147 44.9334 32.943 43.5051 32.943 41.7494V40.8477H35.2868V41.7494C35.2868 43.6261 34.3459 45.2862 32.912 46.2859C34.0815 47.3002 34.8234 48.7953 34.8234 50.4613V51.363H32.4796V50.4613C32.4796 48.7055 31.0513 47.2772 29.2955 47.2772H22.5817C22.5817 50.7833 24.8951 54.0466 28.1932 55.0168C28.9154 57.9091 31.5296 60 34.5834 60C36.8253 60 38.8095 58.8743 40 57.1585C41.1906 58.8743 43.1747 60 45.4166 60C48.4704 60 51.0846 57.9091 51.8068 55.0169C55.1049 54.0466 57.418 50.769 57.418 47.263H51.8037C50.048 47.263 48.6196 48.6913 48.6196 50.447V51.3488H46.2759V50.447C46.2759 47.5968 48.4444 45.2439 51.2181 44.9505C50.5673 44.0438 50.1825 42.9339 50.1825 41.7352V40.8334H52.5262V41.7352C52.5262 43.4908 53.9546 44.9191 55.7103 44.9191Z"
      fill={alpha(fill, 0.5)}
    />
  </svg>
);

export default Mentally;
