/**
 * @generated SignedSource<<cb87b0255ce42ffb89d06da5bfd21a34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollDialogSearchGroupsQuery$variables = {
  after?: string | null | undefined;
  first: number;
  query?: string | null | undefined;
};
export type EnrollDialogSearchGroupsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EnrollDialogSearchGroups_fragment">;
};
export type EnrollDialogSearchGroupsQuery = {
  response: EnrollDialogSearchGroupsQuery$data;
  variables: EnrollDialogSearchGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "status",
    "value": "ACTIVE"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EnrollDialogSearchGroupsQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "EnrollDialogSearchGroups_fragment"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EnrollDialogSearchGroupsQuery",
    "selections": [
      {
        "alias": "connection",
        "args": (v6/*: any*/),
        "concreteType": "GroupConnection",
        "kind": "LinkedField",
        "name": "groups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "programs",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "connection",
        "args": (v6/*: any*/),
        "filters": [
          "query",
          "status"
        ],
        "handle": "connection",
        "key": "Groups_connection",
        "kind": "LinkedHandle",
        "name": "groups"
      }
    ]
  },
  "params": {
    "cacheID": "9bfcb38390f2aec340ccae37d66f2494",
    "id": null,
    "metadata": {},
    "name": "EnrollDialogSearchGroupsQuery",
    "operationKind": "query",
    "text": "query EnrollDialogSearchGroupsQuery(\n  $first: Int!\n  $after: String\n  $query: String\n) {\n  ...EnrollDialogSearchGroups_fragment_1Tyy4D\n}\n\nfragment EnrollDialogSearchGroups_fragment_1Tyy4D on Root {\n  connection: groups(first: $first, after: $after, query: $query, status: ACTIVE) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        name\n        size\n        programs {\n          id\n        }\n        ...GroupAddableItem_group\n        __typename\n      }\n    }\n  }\n}\n\nfragment GroupAddableItem_group on Group {\n  id\n  name\n  size\n}\n"
  }
};
})();

(node as any).hash = "2e69aa8a801c0902c650a81b653a425c";

export default node;
