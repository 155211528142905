import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { ReactComponent as StarIcon } from "../../icons/Star.svg";
import { UserRole } from "../../constants";

import { GroupMemberBadges_user$key } from "./__generated__/GroupMemberBadges_user.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: 700,
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const fragment = graphql`
  fragment GroupMemberBadges_user on User {
    role
  }
`;

export interface GroupMemberBadgesProps extends BoxProps {
  memberRef: GroupMemberBadges_user$key;
}

export function GroupMemberBadges(props: GroupMemberBadgesProps) {
  const { className, memberRef, ...other } = props;
  const s = useStyles();
  const { role } = useFragment(fragment, memberRef);

  return (
    role === UserRole.COACH && (
      <Box
        display={"flex"}
        sx={{ alignItems: "center" }}
        className={clsx(s.root, className)}
        {...other}
      >
        <StarIcon className={s.icon} />
        Coach
      </Box>
    )
  );
}
