import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

const mutation = graphql`
  mutation UpsertClientNutritionTargetMutation(
    $input: UpsertNutritionTargetInput!
  ) {
    upsertNutritionTarget(input: $input) {
      nutritionTarget {
        id
        calories
        carbsGrams
        fatGrams
        proteinGrams
      }
    }
  }
`;

export const useUpsertClientNutritionTargetMutation = () => {
  return useConfiguredMutation(mutation, (config: any) => ({
    configs: config.variables.input.id
      ? []
      : [
          {
            type: "RANGE_ADD",
            parentID: config.variables.input.clientId,
            connectionInfo: [
              {
                key: "ClientDetails_targets",
                rangeBehavior: "prepend",
              },
            ],
            edgeName: "clientNutritionTargetEdge",
          },
        ],
  }));
};
