import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { Box, IconButton, alpha } from "@mui/material";
import clsx from "clsx";

const MAX_RATING = 10;
const mobileBreakpoint = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down(mobileBreakpoint)]: {
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
      columnGap: 0,
    },
  },

  button: {
    width: theme.spacing(4.25),
    height: 55,
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.secondaryGray,
    borderRadius: 0,
    flexGrow: 1,

    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.3),
    },

    "&$disabled": {
      filter: theme.filters.disabled,
    },

    "&$active": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },

    "&:first-child": {
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },

    "&:last-child": {
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },

    "&:not(:last-child)": {
      borderRight: 0,
    },

    [theme.breakpoints.down(mobileBreakpoint)]: {
      width: "100%",

      "&:first-child": {
        borderTopLeftRadius: theme.spacing(0.5),
        borderBottomLeftRadius: 0,
      },
      "&:nth-child(6)": {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: colorSystem.secondaryGray,
        borderRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: theme.spacing(0.5),
        borderRightWidth: 0,
      },
      "&:nth-child(5)": {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: colorSystem.secondaryGray,
        borderRadius: 0,
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: 0,
      },
      "&:last-child": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: theme.spacing(0.5),
      },
      "&:nth-child(-n+5)": {
        borderBottom: "none",
      },
    },
  },

  active: {},
  disabled: {},
}));

interface ScaleRatingProps {
  value?: number;
  onSelect?: (value: number | null) => void;
  disabled?: boolean;
}

const ScaleRating = ({
  value = null,
  onSelect,
  disabled = false,
}: ScaleRatingProps) => {
  const s = useStyles();

  const handleChange = (newValue: number | null) => {
    if (onSelect) {
      onSelect(newValue);
    }
  };

  return (
    <Box className={s.root}>
      {Array.from({ length: MAX_RATING }).map((_, i) => {
        const rate: number = i + 1;
        const isSelected = rate === value;
        return (
          <IconButton
            disabled={disabled}
            className={clsx(
              s.button,
              disabled && s.disabled,
              isSelected && s.active,
            )}
            key={rate}
            onClick={() => handleChange(rate)}
            aria-label={rate.toString()}
            value={rate}
            children={rate}
            size="large"
          />
        );
      })}
    </Box>
  );
};
export default ScaleRating;
