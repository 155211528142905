import { Node, Element, Editor, Range, last } from "slate";
import { ReactEditor } from "slate-react";

import {
  ElementType,
  MEDIA_ELEMENT_TYPES,
  CHECKIN_ELEMENT_TYPES,
} from "../types/elements";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { LegacyElementType } from "./legacyElementTypes";
import { generateId } from "../../new-editor/utils/nodeUtil";
import { ELEMENT_LIST } from "../../new-editor/utils/listElementsUtils";
import { ELEMENT_LINK } from "@udecode/plate-link";
import { WORKOUT_SECTION } from "../../new-editor/components/workout/WorkoutSectionElement";
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule";
// import { SchemaElements } from "../normalizers/withSchema";

const legacyTypeMap: { [key: string]: string } = {
  [LegacyElementType.PARAGRAPH]: ELEMENT_PARAGRAPH,
  [LegacyElementType.LINK]: ELEMENT_LINK,
  [LegacyElementType.DIVIDER]: ELEMENT_HR,
};

const legacyLists = [
  LegacyElementType.NUMBERED_LIST,
  LegacyElementType.BULLETED_LIST,
  LegacyElementType.CHECKLIST,
];

const legacyListItems = [
  LegacyElementType.LIST_ITEM,
  LegacyElementType.CHECKLIST_ITEM,
];

const legacyListStyleTypeMap: { [key: string]: string } = {
  [LegacyElementType.NUMBERED_LIST]: "decimal",
  [LegacyElementType.BULLETED_LIST]: "disc",
  [LegacyElementType.CHECKLIST]: "todo",
};

const legacyListItemsTypeMap: { [key: string]: string } = {
  [LegacyElementType.NUMBERED_LIST]: LegacyElementType.LIST_ITEM,
  [LegacyElementType.BULLETED_LIST]: LegacyElementType.LIST_ITEM,
  [LegacyElementType.CHECKLIST]: LegacyElementType.CHECKLIST_ITEM,
};

// Fixes for legacy content
type Fixer = (nodes: any[], index?: number) => void;

const fixSoftBreak: Fixer = (nodes: any, index: number) => {
  const node = nodes[index];

  if (node.type === "soft_break") {
    nodes[index] = {
      text: "\n",
    };
  }
};

const fixTypeOfTextElementsFromLegacy: Fixer = (nodes: any, index: number) => {
  const node = nodes[index];

  const newType = legacyTypeMap[node.type];
  if (newType) {
    node.id = node.id || generateId();
    node.type = newType;
  }
};

export const fixCompletedRepsTypoInWorkoutSection = (workout: any) => {
  if (workout && Array.isArray(workout.exercises)) {
    workout.exercises.forEach((exercise) => {
      if (Array.isArray(exercise.sets)) {
        exercise.sets.forEach((set) => {
          if ("competedReps" in set) {
            set.completedReps = set.competedReps;
            // we do NOT delete previous key for old app versions compatibility
            // TODO delete old key when users are on the latest app versions (after Aug 5 app release)
            // delete set.competedReps;
          }
        });
      }
      if (Array.isArray(exercise.result)) {
        exercise.result.forEach((result) => {
          if ("competedReps" in result) {
            result.completedReps = result.competedReps;
            // we do NOT delete previous key for old app versions compatibility
            // TODO delete old key when users are on the latest app versions (after Aug 5 app release)
            // delete set.competedReps;
          }
        });
      }
    });
  }
};

export const fixMinimumRequiredWorkoutSectionSchema = (node: any) => {
  if (!node.workout) {
    node.workout = {};
    return fixMinimumRequiredWorkoutSectionSchema(node);
  }
  if (!Array.isArray(node.workout.exercises)) {
    node.workout.exercises = [];
  }
};

const fixWorkoutSectionsSchema: Fixer = (nodes: any, index: number) => {
  const node = nodes[index];

  if (node.type === WORKOUT_SECTION) {
    if (node.workout?.exercises) {
      node.workout.exercises = node.workout?.exercises?.filter((e) => e);
    }
    fixExercisesDuplicateIds(node.workout?.exercises);
    fixCompletedRepsTypoInWorkoutSection(node.workout);
    fixMinimumRequiredWorkoutSectionSchema(node);
  }
};

const fixTypeOfTextElementsToLegacy: Fixer = (nodes: any, index: number) => {
  const node = nodes[index];

  const legacyType = Object.keys(legacyTypeMap).find(
    (key) => legacyTypeMap[key] === node.type,
  );

  if (legacyType) {
    node.type = legacyType;
  }
};

const fixListElementsFromLegacy: Fixer = (nodes: any, index: number) => {
  const node = nodes[index];

  if (legacyLists.includes(node.type)) {
    const newNodes = [];
    const traverse = (node, listStyleType, currentIndent) => {
      if (legacyListItems.includes(node.type)) {
        const newItem = {
          id: node.id,
          children: node.children,
          indent: currentIndent,
          listStyleType: listStyleType,
          // TODO: Replace `ELEMENT_PARAGRAPH` with `ELEMENT_LIST` after components updated to always use have type
          type: ELEMENT_PARAGRAPH,
          ...(node.type === LegacyElementType.CHECKLIST_ITEM && {
            checked: node.checked,
          }),
        };
        newNodes.push(newItem);
      } else if (
        legacyLists.includes(node.type) &&
        node.children &&
        node.children.length
      ) {
        node.children.forEach((child) =>
          traverse(child, legacyListStyleTypeMap[node.type], currentIndent + 1),
        );
      }
    };

    node.children.forEach((child) =>
      traverse(child, legacyListStyleTypeMap[node.type], 1),
    );

    const updatedNodes = nodes
      .slice(0, index)
      .concat(newNodes)
      .concat(nodes.slice(index + 1));
    nodes.length = 0;
    Array.prototype.push.apply(nodes, updatedNodes);
  }
};

export const fixListElementsToLegacy = (nodes: any[]) => {
  const getLegacyTypes = (node) => {
    const legacyListType = Object.keys(legacyListStyleTypeMap).find(
      (key) => legacyListStyleTypeMap[key] === node.listStyleType,
    );
    const legacyListItemType =
      legacyListType && legacyListItemsTypeMap[legacyListType];

    return [legacyListType, legacyListItemType];
  };

  const isNewListNode = (node) =>
    // TODO: Replace `ELEMENT_PARAGRAPH` with `ELEMENT_LIST` after components updated to always use have type
    node.type === ELEMENT_PARAGRAPH &&
    node.listStyleType &&
    node.children?.length > 0;

  const groupedLists = nodes.reduce((acc, node) => {
    const lastGroup = acc[acc.length - 1];
    if (
      lastGroup &&
      node.listStyleType &&
      lastGroup[lastGroup.length - 1]?.listStyleType === node.listStyleType
    ) {
      lastGroup.push(node);
    } else {
      acc.push([node]);
    }
    return acc;
  }, []);
  // TODO: May be not needed to slice old nodes correctly
  // .filter((group) => group.some((node) => node.listStyleType));

  const updatedNodes = [];
  groupedLists.forEach((group) => {
    const listNodes = group?.map((node) => {
      const isNewElement = isNewListNode(node);
      const [legacyListType, legacyListItemType] = getLegacyTypes(node);

      if (!isNewElement || !legacyListType || !legacyListItemType) {
        return node;
      }
      return recursiveBuild(
        node,
        node.indent,
        legacyListType,
        legacyListItemType,
      );
    });

    updatedNodes.push(...listNodes);
  });

  nodes.length = 0;
  Array.prototype.push.apply(nodes, updatedNodes);
};

const recursiveBuild = (node, indent, legacyListType, legacyListItemType) => {
  if (indent === 0) {
    return {
      id: generateId(),
      type: legacyListItemType,
      children: node.children,
      ...(legacyListType === LegacyElementType.CHECKLIST && {
        checked: node.checked,
      }),
    };
  } else {
    return {
      id: generateId(),
      type: legacyListType,
      children: [
        recursiveBuild(node, indent - 1, legacyListType, legacyListItemType),
      ],
    };
  }
};

const fixDuplicateIds = (): Fixer => {
  const ids = [];

  return (nodes, index) => {
    try {
      const node = nodes[index];

      if (node.type) {
        if (!node.id || ids.includes(node.id)) {
          node.id = generateId();
        }

        ids.push(node.id);
      }
    } catch (e) {
      console.warn(e);
    }
  };
};

const fixExercisesDuplicateIds = (exercises) => {
  const ids = [];

  exercises?.forEach((exercise) => {
    if (exercise.id && ids.includes(exercise.id)) {
      exercise.id = generateId();
    }

    ids.push(exercise.id);
  });
};

export const fix = (fns: Fixer[]) => {
  const walk = (nodes: any[]) => {
    nodes &&
      nodes.forEach((node, index) => {
        const children = (node.children || []) as Node[];

        fns.forEach((fn) => fn(nodes, index));

        if (children) {
          walk(children);
        }
      });
  };

  return walk;
};

export const unFix = (fns: Fixer[]) => {
  // global functions applied to the all nodes
  const globalFunctions = fns.filter((fn) => fn.length === 1);

  // index functions applied to the specific nodes
  const indexFunctions = fns.filter((fn) => globalFunctions.indexOf(fn) === -1);

  const walk = (nodes: any[]) => {
    nodes &&
      nodes.forEach((node, index) => {
        const children = (node.children || []) as Node[];

        indexFunctions.forEach((fn) => fn(nodes, index));

        if (children) {
          walk(children);
        }
      });
  };

  const walkGlobal = (nodes: any[]) => {
    nodes && globalFunctions.forEach((fn) => fn(nodes));
  };

  return (nodes: any[]) => {
    walkGlobal(nodes);
    walk(nodes);
  };
};

export function fixLegacyContent(
  nodes: Node[],
  fixers = [
    fixSoftBreak,
    fixTypeOfTextElementsFromLegacy,
    fixListElementsFromLegacy,
    fixWorkoutSectionsSchema,
    fixDuplicateIds(),
  ],
) {
  fix(fixers)(nodes);

  return nodes;
}

export function unfixLegacyContent(
  nodes: Node[],
  fixers = [fixListElementsToLegacy, fixTypeOfTextElementsToLegacy],
) {
  unFix(fixers)(nodes);

  return nodes;
}

// Deprecated
export function getEmptyNode(
  type: ElementType | string = ElementType.PARAGRAPH,
  attrs: any = {},
  children: Array<Node | Element> = [{ text: "" }],
): Element {
  if (!attrs.id) {
    attrs.id = generateId();
  }

  return { type, children, ...attrs };
}

export const isEmpty = (root: ReactEditor | Element): boolean =>
  root.children.length <= 1 && !Node.string(root);

export const deepCompare = (left: any, right: any) =>
  left === right || JSON.stringify(left) === JSON.stringify(right);

export type NodeFilter = (node: Node) => boolean;

export const isMediaNode = (node: any) =>
  MEDIA_ELEMENT_TYPES.includes(node.type as any);

export const isIncompleteMediaNode = (node: any) =>
  isMediaNode(node) && !node.url;

export const isCheckingNode = (value: any) =>
  CHECKIN_ELEMENT_TYPES.includes(value.type as any);

export const isNotEmptyWorkoutNode = (value: any) =>
  (value.type === WORKOUT_SECTION && value.workout?.exercises?.length) ||
  value.workout?.title;

export const applyNodeFilters = (value: Node[], filters: NodeFilter[]) =>
  filters.length
    ? value.filter((it) => !filters.some((filter) => filter(it)))
    : value;

export const isEmptyContent = (value: Node[]) =>
  !value || !value.length || value.every(isEmptyNode);

export const isEmptyNode = (node: Node) =>
  !isMediaNode(node) &&
  !isCheckingNode(node) &&
  !isNotEmptyWorkoutNode(node) &&
  !Node.string(node);
// !SchemaElements.isVoid(node);

export const selectedLocalRange = (editor: Editor): boolean => {
  if (editor.selection) {
    const [start, end] = Range.edges(editor.selection);

    return (
      start.path.length === end.path.length &&
      start.path.every((value, index) => end.path[index] === value)
    );
  } else {
    return false;
  }
};

export const matchIfEmpty = (node: any) =>
  node.children.some(({ type }) => !type);
