import clsx from "clsx";
import React from "react";
import { List, ListProps, Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay/hooks";
import { AddCircleOutline } from "@mui/icons-material";

import {
  WeekComponentListItem,
  WeekComponentListItemProps,
} from "../program-week/WeekComponentListItem";
import { ComponentType } from "../../constants";
import { getComponentSpecifics } from "../../utils/component";
import { useMediaMobile } from "../../hooks/useMediaMobile";

import { WeekComponentList_components$key } from "./__generated__/WeekComponentList_components.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0, 0, 0),
  },

  header: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
  },

  title: {
    textTransform: "uppercase",
    fontSize: 16,
  },

  list: {
    padding: 0,
  },

  listItem: {},

  button: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),

    fontSize: 16,
  },
}));

const fragment = graphql`
  fragment WeekComponentList_components on Component @relay(plural: true) {
    ...WeekComponentListItem_component
    id
  }
`;

export interface WeekComponentListProps extends ListProps {
  componentsRef: WeekComponentList_components$key;
  componentType: ComponentType;
  positions?: string[];
  programId: string;
  week: number;
  onAddComponent?: (componentType: ComponentType) => void;
  onMove?: WeekComponentListItemProps["onMove"];
  onMoveEnd?: WeekComponentListItemProps["onMoveEnd"];
  weekId?: string;
  handleClickOpenDialog: (e?: any, slug?: string) => void;
}

export function WeekComponentList(props: WeekComponentListProps) {
  const {
    className,
    componentsRef,
    programId,
    week,
    componentType,
    onAddComponent,
    positions = [],
    onMove,
    onMoveEnd,
    weekId,
    handleClickOpenDialog,
    ...other
  } = props;
  const s = useStyles();
  const mobile = useMediaMobile();
  const components = useFragment(fragment, componentsRef);

  const handleAddComponent = React.useCallback(() => {
    if (onAddComponent) {
      onAddComponent(componentType);
    }
  }, [onAddComponent, componentType]);

  const sortedComponents = React.useMemo(
    () =>
      positions
        .map((id, index) => ({
          component: components.find((it) => it.id === id),
          index,
        }))
        .filter(({ component }) => Boolean(component)),
    [components, positions],
  );

  const { typeName } = React.useMemo(() => {
    return getComponentSpecifics(componentType as ComponentType);
  }, [componentType]);

  const total = sortedComponents.length;

  return (
    <Box className={clsx(s.root, className)}>
      <Box className={s.header}>
        <Typography variant="h5" className={s.title}>
          {total} {typeName}
          {total === 1 ? "" : "s"}
        </Typography>

        {!mobile && (
          <Button
            className={s.button}
            endIcon={<AddCircleOutline />}
            onClick={handleAddComponent}
          >
            Add new {typeName.toLocaleLowerCase()}
          </Button>
        )}
      </Box>

      <List className={s.list} {...other}>
        {sortedComponents.map(({ component, index }) => (
          <WeekComponentListItem
            key={component.id}
            className={s.listItem}
            componentRef={component}
            onMove={onMove}
            onMoveEnd={onMoveEnd}
            index={index}
            week={week}
            id={component.id}
            weekId={weekId}
            handleClickOpenDialog={handleClickOpenDialog}
          />
        ))}
      </List>
    </Box>
  );
}
