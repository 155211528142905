/**
 * @generated SignedSource<<59fc18724b6e5f61be7421e3b1726811>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProgressDialog_enrollments$data = ReadonlyArray<{
  readonly progressPhotos: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly url: string;
    readonly urls: ReadonlyArray<string>;
    readonly weight: {
      readonly measurement: number;
      readonly unit: MeasurementUnit;
    } | null | undefined;
  } | null | undefined>;
  readonly " $fragmentType": "ProgressDialog_enrollments";
}>;
export type ProgressDialog_enrollments$key = ReadonlyArray<{
  readonly " $data"?: ProgressDialog_enrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgressDialog_enrollments">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProgressDialog_enrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgressPhotoWeight",
      "kind": "LinkedField",
      "name": "progressPhotos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urls",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "format",
              "value": "MMMM D, YYYY"
            },
            {
              "kind": "Literal",
              "name": "utc",
              "value": true
            }
          ],
          "kind": "ScalarField",
          "name": "date",
          "storageKey": "date(format:\"MMMM D, YYYY\",utc:true)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CheckinAnswerMeasurementValue",
          "kind": "LinkedField",
          "name": "weight",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "measurement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "ea1d4e5c2ed5649b9228f59d440326d8";

export default node;
