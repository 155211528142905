import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  Typography,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close as CloseIcon } from "@mui/icons-material";

import { ActionButton } from "../button/ActionButton";
import { useHistory } from "../../hooks/useHistory";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: theme.spacing(66),
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 0,
  },

  closeButton: {
    marginRight: theme.spacing(-1),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: theme.spacing(3),

    "& > button": {
      padding: theme.spacing(1.25, 10),
    },
  },
}));

export interface DiscardChangesDialogProps extends Omit<DialogProps, "open"> {
  dirty: boolean;
}

// Copy form "history" package
export declare enum Action {
  Pop = "POP",
  Push = "PUSH",
  Replace = "REPLACE",
}

export type DelayedState = {
  state: any;
  action: Action;
};

export function DiscardChangesDialog(props: DiscardChangesDialogProps) {
  const { className, dirty, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [discarded, setDiscarded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [locationState, setLocationState] = React.useState<DelayedState>();

  const handleCancel = React.useCallback(() => {
    setLocationState(null);
    setOpen(false);
  }, []);

  const handleDiscard = React.useCallback(() => {
    setDiscarded(true);
  }, []);

  const handleRestartHistory = React.useCallback(() => {
    const { action, state } = locationState;

    switch (action) {
      case "POP":
      case "PUSH":
        navigate(state);
        break;
      case "REPLACE":
        navigate(state, { replace: true });
        break;
    }
  }, [locationState]);

  React.useEffect(() => {
    if (discarded) {
      handleRestartHistory();
    } else if (dirty) {
      const unregister = history.block(() => {
        // setLocationState({ state, action });
        setOpen(true);
        return false;
      });

      return () => unregister();
    } else {
      setOpen(false);
      setLocationState(null);
    }
  }, [dirty, discarded, handleRestartHistory, history]);

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      open={open}
      onClose={handleCancel}
      {...other}
    >
      <DialogTitle className={s.title}>
        <Typography variant="h6">Discard changes?</Typography>
        <IconButton
          className={clsx(s.closeButton)}
          onClick={handleCancel}
          children={<CloseIcon />}
          size="large"
        />
      </DialogTitle>

      <DialogContent>
        <Typography className={s.text}>
          Your changes have not been saved. Are you sure you want to leave
          without saving?
        </Typography>
      </DialogContent>

      <DialogActions className={s.actions}>
        <ActionButton variant="outlined" onClick={handleCancel}>
          Cancel
        </ActionButton>
        <ActionButton color="primary" onClick={handleDiscard}>
          Discard
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
