import clsx from "clsx";
import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import { Dialog, DialogProps, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { usePreview, PreviewType } from "../../hooks/usePreview";
import { useEditorProgram } from "../new-editor/hooks";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(4),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: theme.spacing(1),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },
}));

const enrollMutation = graphql`
  mutation PreviewProgramStartDateDialogMutation($input: EnrollPreviewInput!) {
    enrollPreview(input: $input) {
      clientMutationId
      enrollment {
        id
      }
    }
  }
`;

export interface PreviewProgramStartDateDialogProps extends DialogProps {}

export function PreviewProgramStartDateDialog(
  props: PreviewProgramStartDateDialogProps,
) {
  const [_, setSearchParams] = useSearchParams();
  const { className, onClose, ...other } = props;
  const s = useStyles();
  const { id: programId } = useEditorProgram();
  const [, setPreview] = usePreview();
  const snackAlert = useSnackAlert();
  const [startDate, setStartDate] = React.useState(new Date());
  const [enroll, loading] = useMutation(enrollMutation);

  const handleChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const handleConfirm = React.useCallback(() => {
    setSearchParams((searchParams) => {
      searchParams.delete("component");
      searchParams.set("preview", "true");
      return searchParams;
    });
    enroll({
      variables: {
        input: { programId, startDate },
      },
      onCompleted(data, errors) {
        const err = errors && errors[0];

        if (err) {
          snackAlert({
            severity: "error",
            message: err.message,
          });
        } else {
          onClose({}, "backdropClick");
          setPreview(PreviewType.MOBILE);
        }
      },
      onError(err) {
        snackAlert({
          severity: "error",
          message: err.message,
        });
      },
    });
  }, [enroll, onClose, programId, setPreview, snackAlert, startDate]);

  return (
    <Dialog
      className={clsx(s.root, className)}
      PaperProps={{ className: s.paper }}
      onClose={onClose}
      {...other}
    >
      <Typography
        className={s.title}
        children="When would the program begin?"
      />
      <Typography
        className={s.subtitle}
        children="Add a program start time to preview the program."
      />
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        fullWidth
        children="Preview program"
        disabled={!startDate || loading}
        onClick={handleConfirm}
      />
    </Dialog>
  );
}
