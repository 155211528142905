/**
 * @generated SignedSource<<9ed7e381355b6416d42dd509446c0fa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendClientFormInput = {
  clientMutationId?: string | null | undefined;
  clients: ReadonlyArray<string>;
  id: string;
};
export type SendClientFormMutation$variables = {
  input: SendClientFormInput;
};
export type SendClientFormMutation$data = {
  readonly sendClientForm: {
    readonly clientForm: {
      readonly clients: {
        readonly " $fragmentSpreads": FragmentRefs<"SelectClientsDialog_clients">;
      } | null | undefined;
    };
  } | null | undefined;
};
export type SendClientFormMutation = {
  response: SendClientFormMutation$data;
  variables: SendClientFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendClientFormPayload",
        "kind": "LinkedField",
        "name": "sendClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientForm",
            "kind": "LinkedField",
            "name": "clientForm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SelectClientsDialog_clients"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendClientFormPayload",
        "kind": "LinkedField",
        "name": "sendClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientForm",
            "kind": "LinkedField",
            "name": "clientForm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c845be5175653b401bf8d22ebe77e6a",
    "id": null,
    "metadata": {},
    "name": "SendClientFormMutation",
    "operationKind": "mutation",
    "text": "mutation SendClientFormMutation(\n  $input: SendClientFormInput!\n) {\n  sendClientForm(input: $input) {\n    clientForm {\n      clients {\n        ...SelectClientsDialog_clients\n      }\n      id\n    }\n  }\n}\n\nfragment SelectClientsDialog_clients on ClientConnection {\n  edges {\n    node {\n      email\n      displayName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee7808a1d362ae4f46f8c5ea1ab39fef";

export default node;
