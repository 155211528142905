import { Typography } from "@mui/material";
import React from "react";
import { UrlMetadata } from "../../../types/links";

type InlinePreviewLinkProps = UrlMetadata & {
  onClick: () => void;
};

export const InlinePreviewLink = ({ url, onClick }: InlinePreviewLinkProps) => {
  return (
    <Typography
      width={"100%"}
      sx={{ pr: 1 }}
      color={(theme) => theme.palette.primary.main}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      {url}
    </Typography>
  );
};
