import React, { useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEnableColors } from "../../../hooks/useEnableColors";
import { Maximize2 } from "lucide-react";
import { X } from "lucide-react";
import { useSearchParams } from "react-router-dom";

interface ICalendarSidebarTitleProps {
  title: string;
  color: string;
  componentSlug: string;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: boolean;
  handleClickOpenDialog: () => void;
  handleCloseDialog: () => void;
}

const CalendarSidebarTitle = (props: ICalendarSidebarTitleProps) => {
  const {
    color,
    title,
    componentSlug,
    setActivityPreview,
    openDialog,
    handleClickOpenDialog,
    handleCloseDialog,
  } = props;
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const enableColors = useEnableColors();

  const handleCloseSidebarClick = () => {
    return setActivityPreview(false);
  };

  useEffect(() => {
    searchParams.size === 0 && handleCloseDialog();
  }, [searchParams]);

  return (
    <>
      <Box
        display={"flex"}
        sx={{
          borderBottom: "1px solid",
          color: theme.palette.common.white,
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.common.black,
          borderTopLeftRadius: 7,
          borderTopRightRadius: 7,

          ...(enableColors && {
            color: theme.palette.common.white,
            borderColor: color,
            backgroundColor: color,
          }),
        }}
      >
        <IconButton
          onClick={handleClickOpenDialog}
          color="inherit"
          size="small"
          sx={{ visibility: "hidden" }}
        >
          <Maximize2 />
        </IconButton>
        <Typography
          fontWeight={"bold"}
          textAlign={"center"}
          width={"100%"}
          alignContent={"center"}
        >
          {title}
        </Typography>
        <IconButton
          onClick={handleCloseSidebarClick}
          color="inherit"
          size="small"
        >
          <X />
        </IconButton>
      </Box>
    </>
  );
};

export default CalendarSidebarTitle;
