/**
 * @generated SignedSource<<db248cdfa1cac4cd3f5a6fa380a12b92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignInWithAuthTokenInput = {
  clientMutationId?: string | null | undefined;
  token: string;
};
export type useAuthTokenSignInMutation$variables = {
  input: SignInWithAuthTokenInput;
};
export type useAuthTokenSignInMutation$data = {
  readonly signInWithAuthToken: {
    readonly idToken: string;
  } | null | undefined;
};
export type useAuthTokenSignInMutation = {
  response: useAuthTokenSignInMutation$data;
  variables: useAuthTokenSignInMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SignInWithAuthTokenPayload",
    "kind": "LinkedField",
    "name": "signInWithAuthToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAuthTokenSignInMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAuthTokenSignInMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d09537635007f620cfcd922f1340b2a",
    "id": null,
    "metadata": {},
    "name": "useAuthTokenSignInMutation",
    "operationKind": "mutation",
    "text": "mutation useAuthTokenSignInMutation(\n  $input: SignInWithAuthTokenInput!\n) {\n  signInWithAuthToken(input: $input) {\n    idToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b36263a168eb5507fdd0491525f1739";

export default node;
