import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close as CloseIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "relative",
    justifyContent: "center",

    "& *": {
      boxSizing: "border-box",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    "& > div": {
      position: "absolute",
      bottom: 0,
      borderRadius: theme.spacing(0, 0, 1, 1),
      backgroundColor: theme.palette.background.paper,
      height: theme.spacing(4),
      border: "1px solid",
      borderColor: theme.palette.primary.main,
    },
  },

  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    objectPosition: "top center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
  },

  caption: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    display: "block",
    width: `calc(100% - ${theme.spacing(4.5)})`,
    margin: theme.spacing(0.5, 1),
  },

  removeButton: {
    position: "absolute",
    right: theme.spacing(-0.5),
    bottom: theme.spacing(-0.5),

    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}));

export type ProgressImageInfo = {
  url: string;
  fileName: string;
  fileSize: number;
};

export interface CheckInAnswerProgressPhotoViewProps extends BoxProps {
  image: ProgressImageInfo;
  onRemove: () => void;
}

export function CheckInAnswerProgressPhotoView(
  props: CheckInAnswerProgressPhotoViewProps,
) {
  const { className, image, onRemove, ...other } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLImageElement>();

  React.useEffect(() => {
    const img = ref.current;

    if (img) {
      const handler = () => {
        const { width } = img.getBoundingClientRect();
        const captionNode = img.nextElementSibling as HTMLDivElement;

        if (captionNode) {
          captionNode.style.width = `${width}px`;
        }
      };

      img.addEventListener("load", handler);
      window.addEventListener("resize", handler);

      return () => {
        img.removeEventListener("load", handler);
        window.removeEventListener("resize", handler);
      };
    }
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <img ref={ref} className={s.img} src={image.url} alt={image.fileName} />
      <Box>
        <Typography className={s.caption} variant="caption">
          {image.fileName}
        </Typography>

        <IconButton
          className={s.removeButton}
          onClick={onRemove}
          disableRipple
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
