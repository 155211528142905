/**
 * @generated SignedSource<<bf7d47faece223c9be8f6acfc69aaae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostsSidebar_group$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GroupDescription_group" | "GroupSidebarMembers_group">;
  readonly " $fragmentType": "GroupPostsSidebar_group";
};
export type GroupPostsSidebar_group$key = {
  readonly " $data"?: GroupPostsSidebar_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsSidebar_group">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostsSidebar_group",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupDescription_group"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupSidebarMembers_group"
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "401c65788725b26aba6df5980a5a21f8";

export default node;
