import React from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

type Values = {
  [key: string]: string;
};

export function useQueryParams(
  defaultValue: Values = {},
): [Values, (x: Values) => void] {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const query = Object.fromEntries(searchParams.entries());
  const initialValue =
    Object.keys(query).length > 0 ? (query as Values) : defaultValue;

  const setValue = React.useCallback(
    (values: Values) => {
      const newParams = new URLSearchParams(searchParams);

      for (const [key, value] of Object.entries(values)) {
        if (value !== null && value !== undefined) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      }

      const newUrl = `${location.pathname}?${newParams.toString()}`;

      navigate(newUrl, { replace: true, state: location.state });
    },
    [searchParams, location.pathname, location.state, navigate],
  );

  return [initialValue, setValue];
}
