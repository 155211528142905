import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { UnsplashContext } from "../../../../hooks/useUnsplash";
import { GroupSettingsScreen } from "../../../../components/group-settings/GroupSettingsScreen";
import { CoachGroupSettingsRouteQuery } from "./__generated__/CoachGroupSettingsRouteQuery.graphql";
import GroupContext from "../../../../contexts/GroupContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachGroupSettingsRoute() {
  const { slug } = useParams();

  const props = useLazyLoadQuery<CoachGroupSettingsRouteQuery>(
    graphql`
      query CoachGroupSettingsRouteQuery($slug: String!) {
        ...UnsplashImages_rootRef
        ...GroupSettingsScreen_rootRef @arguments(slug: $slug)

        group(slug: $slug) {
          id
          name
          slug
          membersCount
          membersCanNotPost
          coachId
        }

        me {
          id
          role
        }
      }
    `,
    {
      slug: slug,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const context = useContext(GroupContext);
  context.setGroupRouteQuery(props);
  const { group } = props;

  return (
    <>
      <UnsplashContext.Provider value={props}>
        <GroupSettingsScreen rootRef={props} />
      </UnsplashContext.Provider>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Group Settings",
          properties: {
            groupSlug: group.slug,
            groupName: group.name,
          },
        }}
      />
    </>
  );
}
