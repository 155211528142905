import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import queryString from "query-string";

import { colorSystem } from "../../theme";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole, ActivityType, ResponsesView } from "../../constants";
import { getContentTeaser } from "../../utils/slate";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";

import { NotificationBox_notification$key } from "./__generated__/NotificationBox_notification.graphql";
import { useFragment, useMutation } from "react-relay/hooks";
import { NotificationBoxTitle } from "./NotificationBoxTitle";
import { notificationReadStoreUpdater } from "../activity-feedback/ActivityFeedback";
import { useNavigate } from "react-router-dom";

const DOT_SIZE = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    // height: 140,
    width: "100%",
    position: "relative",

    "&:hover": {
      backgroundColor: `${colorSystem.secondaryGray}66`,
      cursor: "pointer",

      "& $close": {
        display: "block",
      },
    },

    "&::after": {
      display: "block",
      content: "''",
      width: DOT_SIZE,
      height: DOT_SIZE,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      position: "absolute",
      top: `calc(50% - ${DOT_SIZE / 2}px)`,
      right: theme.spacing(3),
    },

    "&$read": {
      "&::after": {
        display: "none",
      },
    },
  },

  close: {
    display: "none",
    position: "absolute",
    top: 5,
    right: 5,
    padding: theme.spacing(0.95, 0),
  },

  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },

  content: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    paddingRight: theme.spacing(2),
    overflow: "hidden",
  },

  title: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  subtitle: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },

  text: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "17px",
    color: theme.palette.common.black,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  postTitle: {
    fontWeight: 600,
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },

  bold: {
    fontWeight: 700,
  },

  closeIcon: {
    height: 20,
  },

  read: {},

  inFlight: {
    opacity: 0.7,
  },

  deleted: {
    display: "none!important",
  },
}));

export interface NotificationBoxProps extends BoxProps {
  notificationRef: NotificationBox_notification$key;
  index: number;
  onClickDelete: (index: number) => void;
  onClickBox: () => void;
}

const notificationFragment = graphql`
  fragment NotificationBox_notification on Notification {
    id

    author {
      username
      displayName
      photoURL
    }
    activityFeedbackId
    activityFeedback {
      activity {
        type
        component {
          slug
          title
          program {
            slug
            name
          }
        }
        clientForm {
          name
          slug
          formattedDate: updatedAt(format: "MMM DD, YYYY")
        }
        date(raw: true)
      }

      content
      formattedDate: createdAt(format: "MMM DD, YYYY")
    }
    groupPost {
      id
      content
      title
      formattedDate: updatedAt(format: "MMM DD, YYYY")
      author {
        username
        displayName
        photoURL
      }
      group {
        slug
        name
      }
    }

    message {
      id
      content
      formattedDate: updatedAt(format: "MMM DD, YYYY")
      author {
        username
        displayName
        photoURL
      }
      notified
    }

    groupComment {
      id
      content
      formattedDate: updatedAt(format: "MMM DD, YYYY")
      author {
        username
        displayName
        photoURL
      }
      groupPost {
        title
        group {
          slug
          name
        }
      }
    }

    clientForm {
      id
      name
      slug
      formattedDate: updatedAt(format: "MMM DD, YYYY")
    }
    deleted
    read
  }
`;

const notificationDeleteMutation = graphql`
  mutation NotificationBoxDeleteMutation($input: NotificationDeleteInput!) {
    notificationDelete(input: $input) {
      notification {
        id
        activityFeedbackId
      }
    }
  }
`;

const notificationReadMutation = graphql`
  mutation NotificationBoxReadMutation($input: NotificationReadInput!) {
    notificationRead(input: $input) {
      notification {
        id
        activityFeedbackId
      }
    }
  }
`;

export function NotificationBox(props: NotificationBoxProps) {
  const {
    className,
    notificationRef,
    index,
    onClickDelete,
    onClickBox,
    ...other
  } = props;
  const notification = useFragment(notificationFragment, notificationRef);
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const {
    id,
    author,
    activityFeedback,
    activityFeedbackId,
    read,
    deleted,
    clientForm,
    groupPost,
    groupComment,
    message,
  } = notification;
  const activity = activityFeedback?.activity;
  const component = activityFeedback?.activity?.component;
  const program = component?.program;
  const programSlug = program?.slug;
  const componentSlug = component?.slug;
  const formattedDate =
    activityFeedback?.formattedDate ||
    clientForm?.formattedDate ||
    groupPost?.formattedDate ||
    groupComment?.formattedDate ||
    message?.formattedDate;

  const [notificationDelete, inFlight] = useMutation(
    notificationDeleteMutation,
  );

  const [notificationRead] = useMutation(notificationReadMutation);

  const handleClick = React.useCallback(() => {
    if (clientForm) {
      notificationRead({
        variables: {
          input: { clientFormId: clientForm.id },
        },
        onCompleted: (data, errors) => {
          if (errors && errors.length) {
            console.error(errors[0]);
          }
        },
        onError: (error) => {
          console.error(error);
        },
        updater: notificationReadStoreUpdater,
      });
    }
    let path = "";
    if (!!groupComment?.groupPost?.group?.slug || !!groupPost?.group?.slug) {
      path =
        user.role === UserRole.CLIENT
          ? `/groups/${
              groupComment?.groupPost?.group?.slug || groupPost?.group?.slug
            }`
          : `/coach/groups/${
              groupComment?.groupPost?.group?.slug || groupPost?.group?.slug
            }`;
    } else if (message?.content) {
      path =
        user.role === UserRole.CLIENT
          ? `/messages/${author.username}`
          : `/coach/messages/${author.username}`;
    } else {
      path =
        clientForm || activity.clientForm
          ? user.role === UserRole.CLIENT
            ? `/forms/${(clientForm || activity.clientForm).slug}`
            : `/coach/clients/${author.username}/forms/${
                (clientForm || activity.clientForm).slug
              }`
          : user.role === UserRole.CLIENT
            ? activity.type === ActivityType.MEAL_LOGGING
              ? `/meal-logging/${activity.date}`
              : `/programs/${programSlug}/${activity.date}/${componentSlug}`
            : activity.type === ActivityType.MEAL_LOGGING
              ? `/coach/clients/${author.username}/responses`
              : `/coach/clients/${author.username}/programs/${programSlug}/${activity.date}/${componentSlug}`;
    }

    const queryParams: Record<string, string> = {};

    if (activity) {
      queryParams.feedback = "true";
      queryParams.notification = "true";
    }

    if (
      user.role === UserRole.COACH &&
      activity?.type === ActivityType.MEAL_LOGGING
    ) {
      queryParams.view = ResponsesView.INDIVIDUAL;
      queryParams.mealDate = activity.date;
    }

    navigate(`${path}?${queryString.stringify(queryParams)}`);
    onClickBox();
  }, [
    activity,
    author.username,
    clientForm,
    componentSlug,
    programSlug,
    groupComment,
    groupPost,
    user.role,
    message,
    notificationRead,
  ]);

  const teaser = React.useMemo(() => {
    return activityFeedback
      ? getContentTeaser(activityFeedback?.content)
      : getContentTeaser(groupPost?.content) ||
          getContentTeaser(groupComment?.content) ||
          getContentTeaser(message?.content);
  }, [activityFeedback, groupPost, groupComment, message]);

  const handleClickDelete = (e) => {
    e.stopPropagation();
    const isFromGroup = !groupComment?.groupPost?.title || !groupPost?.title;
    const input = isFromGroup ? { id } : { activityFeedbackId };
    notificationDelete({
      variables: {
        input: input,
      },
      onCompleted: (data, errors) => {
        if (errors && errors.length) {
          console.error(errors[0]);
        } else {
          onClickDelete(index);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <Box
      className={clsx(
        s.root,
        read && s.read,
        deleted && s.deleted,
        inFlight && s.inFlight,
        className,
      )}
      onClick={handleClick}
      {...other}
    >
      <IconButton
        onClick={handleClickDelete}
        className={s.close}
        disabled={inFlight}
        size="large"
      >
        <CloseIcon className={s.closeIcon} />
      </IconButton>
      <Avatar
        className={s.avatar}
        alt={author.displayName}
        src={author.photoURL}
        variant="square"
      />
      <Box className={s.content}>
        <NotificationBoxTitle
          author={author || groupPost?.author || groupComment?.author}
          component={
            component ||
            (groupPost?.content && JSON.parse(groupPost?.content)) ||
            (groupComment?.content && JSON.parse(groupComment?.content))
          }
          activity={activity}
          message={message?.content && JSON.parse(message?.content)}
          title={groupComment?.groupPost?.group?.name || groupPost?.group?.name}
          clientForm={clientForm}
        />
        <Typography className={s.subtitle}>
          {formattedDate} {program && <>&bull; {program?.name}</>}
        </Typography>
        <Typography className={s.postTitle}>
          {groupComment?.groupPost?.title || groupPost?.title}
        </Typography>
        {teaser && <Typography className={s.text}>{teaser}</Typography>}
      </Box>
    </Box>
  );
}
