/**
 * @generated SignedSource<<a7375079bdaaff14d97927ae42407938>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollDialogSearchClientsQuery$variables = {
  after?: string | null | undefined;
  first: number;
  query?: string | null | undefined;
};
export type EnrollDialogSearchClientsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EnrollDialogSearchClients_fragment">;
};
export type EnrollDialogSearchClientsQuery = {
  response: EnrollDialogSearchClientsQuery$data;
  variables: EnrollDialogSearchClientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EnrollDialogSearchClientsQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "EnrollDialogSearchClients_fragment"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EnrollDialogSearchClientsQuery",
    "selections": [
      {
        "alias": "connection",
        "args": (v3/*: any*/),
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "programs",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "connection",
        "args": (v3/*: any*/),
        "filters": [
          "query"
        ],
        "handle": "connection",
        "key": "Clients_connection",
        "kind": "LinkedHandle",
        "name": "clients"
      }
    ]
  },
  "params": {
    "cacheID": "80ac0683361256472176b7f7360e2ee3",
    "id": null,
    "metadata": {},
    "name": "EnrollDialogSearchClientsQuery",
    "operationKind": "query",
    "text": "query EnrollDialogSearchClientsQuery(\n  $first: Int!\n  $after: String\n  $query: String\n) {\n  ...EnrollDialogSearchClients_fragment_1Tyy4D\n}\n\nfragment ClientAddableItem_client on User {\n  id\n  email\n  displayName\n  photoURL\n}\n\nfragment EnrollDialogSearchClients_fragment_1Tyy4D on Root {\n  connection: clients(first: $first, after: $after, query: $query) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        email\n        displayName\n        programs {\n          id\n        }\n        ...ClientAddableItem_client\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "52cce70fd568ba97f5fe4dfee70f5d18";

export default node;
