/**
 * @generated SignedSource<<1b745c1b4fd5b73eec6ec66ddfce0f82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GenerateInviteCodeInput = {
  clientMutationId?: string | null | undefined;
  groupId?: string | null | undefined;
  programId?: string | null | undefined;
};
export type InviteLinkSettingsGenerateInviteCodeMutation$variables = {
  input: GenerateInviteCodeInput;
};
export type InviteLinkSettingsGenerateInviteCodeMutation$data = {
  readonly generateInviteCode: {
    readonly inviteCode: {
      readonly code: string;
      readonly program: {
        readonly " $fragmentSpreads": FragmentRefs<"ProgramSettings_program">;
      } | null | undefined;
    };
  } | null | undefined;
};
export type InviteLinkSettingsGenerateInviteCodeMutation = {
  response: InviteLinkSettingsGenerateInviteCodeMutation$data;
  variables: InviteLinkSettingsGenerateInviteCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteLinkSettingsGenerateInviteCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateInviteCodePayload",
        "kind": "LinkedField",
        "name": "generateInviteCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InviteCode",
            "kind": "LinkedField",
            "name": "inviteCode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProgramSettings_program"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteLinkSettingsGenerateInviteCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GenerateInviteCodePayload",
        "kind": "LinkedField",
        "name": "generateInviteCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InviteCode",
            "kind": "LinkedField",
            "name": "inviteCode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "length",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "defaultView",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InviteCode",
                    "kind": "LinkedField",
                    "name": "inviteCode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "530d1918fe3975ea6cb7f9eadd4a397e",
    "id": null,
    "metadata": {},
    "name": "InviteLinkSettingsGenerateInviteCodeMutation",
    "operationKind": "mutation",
    "text": "mutation InviteLinkSettingsGenerateInviteCodeMutation(\n  $input: GenerateInviteCodeInput!\n) {\n  generateInviteCode(input: $input) {\n    inviteCode {\n      code\n      program {\n        ...ProgramSettings_program\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment InviteLinkSettings_inviteCode on InviteCode {\n  id\n  code\n}\n\nfragment ProgramSettings_program on Program {\n  id\n  slug\n  name\n  description\n  imageURL\n  status\n  length\n  defaultView\n  inviteCode {\n    ...InviteLinkSettings_inviteCode\n    id\n    startDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c55e2eee1e2aa50dc2e0e50c3d06243";

export default node;
