/**
 * @generated SignedSource<<5559fa7c1c38505bb6e5223d6e903dae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Threads_threads$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly author: {
        readonly displayName: string | null | undefined;
      } | null | undefined;
      readonly group: {
        readonly name: string;
      } | null | undefined;
      readonly id: string;
      readonly read: boolean;
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"ThreadCard_thread">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "Threads_threads";
};
export type Threads_threads$key = {
  readonly " $data"?: Threads_threads$data;
  readonly " $fragmentSpreads": FragmentRefs<"Threads_threads">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Threads_threads",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ThreadsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Thread",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "read",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "raw",
                      "value": true
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": "displayName(raw:true)"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Group",
              "kind": "LinkedField",
              "name": "group",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ThreadCard_thread"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ThreadsConnection",
  "abstractKey": null
};

(node as any).hash = "ed903c03dffc5e6d25cda9f7a2c2594b";

export default node;
