import {
  COACH_PROGRAMS_ARCHIVED_ROUTE,
  COACH_PROGRAMS_DRAFT_ROUTE,
  COACH_PROGRAMS_FOLDERS_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
} from "../../routes/routes";

const createLink = (route, folderId) =>
  folderId ? `${route}/?folder=${folderId}` : route;

export const programsNavigation = (folderId) => [
  {
    name: "All programs",
    link: COACH_PROGRAMS_PUBLISHED_ROUTE,
  },
  {
    name: "Drafts",
    link: COACH_PROGRAMS_DRAFT_ROUTE,
  },
  {
    name: "Archived",
    link: COACH_PROGRAMS_ARCHIVED_ROUTE,
  },
  {
    name: "Folders",
    link: COACH_PROGRAMS_FOLDERS_ROUTE,
  },
];
