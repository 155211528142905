import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Portal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindPopper,
  bindToggle,
} from "material-ui-popup-state/hooks";
import { graphql, useFragment } from "react-relay";

import { ReactComponent as MessageIcon } from "../../icons/Message.svg";
import { useQueryParam } from "../../hooks/useQueryParam";

import { ActivityFeedbackDrawer } from "./ActivityFeedbackDrawer";
import { ActivityFeedbackButton_activity$key } from "./__generated__/ActivityFeedbackButton_activity.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),

    "& svg": {
      color: theme.palette.primary.main,
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
  },
}));

const activityFragment = graphql`
  fragment ActivityFeedbackButton_activity on Activity {
    ...ActivityFeedbackList_activity
    feedbacks {
      id
    }
  }
`;

export interface ActivityFeedbackButtonProps extends IconButtonProps {
  activity: ActivityFeedbackButton_activity$key;
  hideWhenEmpty?: boolean;
}

export function ActivityFeedbackButton(props: ActivityFeedbackButtonProps) {
  const {
    className,
    activity: activityRef,
    hideWhenEmpty = false,
    ...other
  } = props;
  const activity = useFragment(activityFragment, activityRef);
  const s = useStyles();
  const toggleState = usePopupState({
    variant: "popover",
    popupId: "feedbacks",
  });
  const [feedbackQueryParam, setFeedbackQueryParam] = useQueryParam(
    "feedback",
    "",
    { silent: true },
  );

  React.useEffect(() => {
    if (feedbackQueryParam === "true") {
      toggleState.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFeedbackQueryParam(toggleState.isOpen ? "true" : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState.isOpen]);

  return hideWhenEmpty && !activity.feedbacks.length ? null : (
    <>
      <IconButton
        className={clsx(s.root, className)}
        {...bindToggle(toggleState)}
        {...other}
        size="large"
      >
        <MessageIcon /> {activity.feedbacks.length}
      </IconButton>

      {toggleState.isOpen && (
        <Portal>
          <ActivityFeedbackDrawer
            {...bindPopper(toggleState)}
            activity={activity}
            onClose={toggleState.close}
          />
        </Portal>
      )}
    </>
  );
}
