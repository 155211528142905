import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface INavigateWithParamParams {
  defaultPath: string;
}
export const NavigateWithParam = ({
  defaultPath,
}: INavigateWithParamParams) => {
  const location = useLocation();
  const pathname = location.pathname;
  const genericPathname = pathname.endsWith("/")
    ? pathname.slice(0, -1)
    : pathname;
  return <Navigate to={genericPathname + defaultPath} />;
};
