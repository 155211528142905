import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { getISODate } from "../../utils/date";

import { BaseDialog } from "./BaseDialog";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface ProgramSpecificStartDateViewDialogProps
  extends Omit<DialogProps, "onSelect"> {
  onSelect: (date: string) => void;
}

export function ProgramSpecificStartDateViewDialog(
  props: ProgramSpecificStartDateViewDialogProps,
) {
  const { className, onClose, onSelect, ...other } = props;
  const s = useStyles();
  const [startDate, setStartDate] = React.useState(new Date());

  const handleDateChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setStartDate(new Date());
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleConfirm = React.useCallback(() => {
    onSelect(getISODate(startDate));
    handleClose();
  }, [handleClose, onSelect, startDate]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="When would the program begin?"
      subtitle="You will still need to set a start date when enrolling clients."
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="View using this start date"
        onClick={handleConfirm}
      />
    </BaseDialog>
  );
}
