"use client";

import React from "react";
import { withRef } from "@udecode/cn";
import {
  focusEditor,
  getNodeEntries,
  isBlock,
  toggleNodeType,
  useEditorRef,
  useEditorSelector,
  useMarkToolbarButton,
  useMarkToolbarButtonState,
} from "@udecode/plate-common";

import { ToolbarButton } from "./toolbar";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";

export const TurnIntoToolbarButton = withRef<
  typeof ToolbarButton,
  {
    nodeType: string;
    clear?: string | string[];
  }
>(({ clear, nodeType, ...rest }, ref) => {
  const value: string | null = useEditorSelector((editor) => {
    let initialNodeType: string = ELEMENT_PARAGRAPH;
    let allNodesMatchInitialNodeType = false;
    const codeBlockEntries = getNodeEntries(editor, {
      match: (n) => isBlock(editor, n),
      mode: "highest",
    });
    const nodes = Array.from(codeBlockEntries);

    if (nodes.length > 0) {
      initialNodeType = nodes[0][0].type as string;
      allNodesMatchInitialNodeType = nodes.every(([node]) => {
        const type: string = (node?.type as string) || ELEMENT_PARAGRAPH;

        return type === initialNodeType;
      });
    }

    return allNodesMatchInitialNodeType ? initialNodeType : null;
  }, []);

  const editor = useEditorRef();

  const onClick = () => {
    toggleNodeType(editor, { activeType: nodeType });
    focusEditor(editor);
  };

  return (
    <ToolbarButton
      ref={ref}
      pressed={value === nodeType}
      onClick={onClick}
      {...rest}
    />
  );
});
