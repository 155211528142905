import clsx from "clsx";
import React from "react";
import { DialogProps, Button, TextField, TextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import {
  VerificationStatus,
  SOMETHING_WENT_WRONG,
  PHONE_NUMBER_VERIFICATION_SUCCESS,
  PHONE_NUMBER_VERIFICATION_FAILURE,
  PHONE_NUMBER_VERIFICATION_CODE_SENT,
} from "../../constants";

import { VerifyPhoneDialogVerifyPhoneMutation } from "./__generated__/VerifyPhoneDialogVerifyPhoneMutation.graphql";
import { VerifyPhoneDialogVerifyPhoneCheckMutation } from "./__generated__/VerifyPhoneDialogVerifyPhoneCheckMutation.graphql";
import { BaseDialog } from "./BaseDialog";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    padding: theme.spacing(5, 3, 4),
  },

  paper: {
    margin: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4),
    },
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  closeButton: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(-1.5),

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  resendButton: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.primary.main,
    margin: theme.spacing(0.5, 0, 5, 2),
  },

  continueButton: {
    height: 56,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
  },
}));

const verifyPhoneMutation = graphql`
  mutation VerifyPhoneDialogVerifyPhoneMutation($input: VerifyPhoneInput!) {
    verifyPhone(input: $input) {
      sid
    }
  }
`;

const verifyPhoneCheckMutation = graphql`
  mutation VerifyPhoneDialogVerifyPhoneCheckMutation(
    $input: VerifyPhoneCheckInput!
  ) {
    verifyPhoneCheck(input: $input) {
      status
      user {
        ...CurrentUserProvider_user
      }
    }
  }
`;

export interface VerifyPhoneDialogProps extends DialogProps {
  phone: string;
}

export function VerifyPhoneDialog(props: VerifyPhoneDialogProps) {
  const { className, onClose, phone, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const snackAlert = useSnackAlert();
  const [code, setCode] = React.useState("");
  const [verifyPhone, verifyPhoneInFlight] =
    useMutation<VerifyPhoneDialogVerifyPhoneMutation>(verifyPhoneMutation);
  const [verifyPhoneCheck, verifyPhoneCheckInFlight] =
    useMutation<VerifyPhoneDialogVerifyPhoneCheckMutation>(
      verifyPhoneCheckMutation,
    );

  const handleCodeChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setCode(event.target.value);
    },
    [],
  );

  const handleResend = React.useCallback(() => {
    verifyPhone({
      variables: { input: { phone } },
      onCompleted(data, errors) {
        if (errors) {
          snackAlert({
            severity: "error",
            message: errors[0].message || SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "info",
            message: PHONE_NUMBER_VERIFICATION_CODE_SENT,
          });
        }
      },
      onError(err) {
        snackAlert({
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        });
        console.error(err.message || SOMETHING_WENT_WRONG);
      },
    });
  }, [phone, snackAlert, verifyPhone]);

  const handleContinue = React.useCallback(
    (event) => {
      verifyPhoneCheck({
        variables: { input: { id: user.id, phone, code } },
        onCompleted(data, errors) {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: PHONE_NUMBER_VERIFICATION_FAILURE,
            });
          } else {
            if (data.verifyPhoneCheck.status === VerificationStatus.APPROVED) {
              snackAlert({
                severity: "success",
                message: PHONE_NUMBER_VERIFICATION_SUCCESS,
              });
            } else {
              snackAlert({
                severity: "error",
                message: PHONE_NUMBER_VERIFICATION_FAILURE,
              });
            }

            onClose(event, "backdropClick");
          }
        },
        onError(err) {
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });

          console.error(err);
        },
      });
    },
    [code, onClose, phone, snackAlert, user.id, verifyPhoneCheck],
  );

  const inFlight = verifyPhoneInFlight || verifyPhoneCheckInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Verify phone number"
      description={`Enter the verification code that was sent to ${phone}.`}
      onClose={onClose}
      closeDisabled={inFlight}
      classes={{ paper: s.paper }}
      {...other}
    >
      <TextField
        variant="outlined"
        fullWidth
        value={code}
        onChange={handleCodeChange}
        placeholder="Verification code"
      />
      <Button
        className={s.resendButton}
        onClick={handleResend}
        children="Resend verification code"
        disabled={inFlight}
      />
      <Button
        className={s.continueButton}
        fullWidth
        variant="contained"
        children="Continue"
        onClick={handleContinue}
        disabled={!code || inFlight}
      />
    </BaseDialog>
  );
}
