import React from "react";
import { useLocation } from "react-router-dom";
import { Breakpoint, Container } from "@mui/material";
import { COACH_PROGRAMS_ROUTE } from "../../../routes/routes";
import CommonSuspenseWrapper from "../client/CommonSuspenseWrapper";

const CoachProgramsWrapper = () => {
  const location = useLocation();

  const maxWidth: Breakpoint = React.useMemo(() => {
    if (!location.pathname.includes(COACH_PROGRAMS_ROUTE)) return undefined;
    else if (location.pathname.includes("curriculum")) {
      return "slg";
    } else if (location.pathname.includes("enrollments")) {
      return "md";
    }
    return undefined;
  }, [location.pathname]);

  return CommonSuspenseWrapper({ maxWidth });
};

export default CoachProgramsWrapper;
