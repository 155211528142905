import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { SignInMutation } from "./__generated__/SignInMutation.graphql";
import { IsMobileAppModalShown } from "../../../constants";

const mutation = graphql`
  mutation SignInMutation($idToken: String!) {
    signIn(idToken: $idToken) {
      me {
        id
        role
        ...CurrentUserProvider_user @arguments(shouldFetchNotifications: false)
      }
    }
  }
`;

export const useSignInMutation = () => {
  localStorage.removeItem(IsMobileAppModalShown);
  return useConfiguredMutation<SignInMutation>(mutation, () => ({
    updater: (store) => {
      (store as any).invalidateStore();
    },
  }));
};
