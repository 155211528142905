import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { SortableList } from "../sortable-list/SortableList";

import { ClientNutritionTargetDrawerListItem } from "./ClientNutritionTargetDrawerListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ClientGoalsListProps {
  nutritionTargets: any;
  onItemActionClick: any;
  onUpdate: any;
  disabled?: boolean;
}

export function ClientNutritionTargetDrawerList(props: ClientGoalsListProps) {
  const {
    nutritionTargets,
    onItemActionClick,
    disabled = false,
    ...other
  } = props;
  const s = useStyles();

  return (
    <SortableList
      className={s.root}
      itemType="client_nutrition_target"
      ListItem={ClientNutritionTargetDrawerListItem as any}
      ListItemProps={{ onAction: onItemActionClick, disabled: disabled }}
      options={nutritionTargets as any}
      {...other}
    ></SortableList>
  );
}
