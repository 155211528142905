/**
 * @generated SignedSource<<ea7746249f3f5a6b889e36256b372a40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SelectComponentIcons_component$data = {
  readonly iconName: string;
  readonly id: string;
  readonly type: ComponentType;
  readonly " $fragmentType": "SelectComponentIcons_component";
};
export type SelectComponentIcons_component$key = {
  readonly " $data"?: SelectComponentIcons_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectComponentIcons_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectComponentIcons_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "0150650571e9c4181ede5e32ece91beb";

export default node;
