import React, { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppLayout from "../../components/app/AppLayout";
import {
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_HOME_ROUTE,
  CLIENT_PROFILE_ROUTE,
  CLIENT_SETTING_PROFILE_ROUTE,
  CLIENT_SETTING_ROUTE,
  GROUPS_ROUTE,
  HOME_ROUTE,
  MEAL_LOGGING_ROUTE,
  MESSAGES_ROUTE,
  PROGRAMS_ROUTE,
} from "../../routes/routes";
import { groupNavigation } from "../../routes/coach/group/common";
import {
  ClientActivityStates,
  IsMobileAppModalShown,
  UserRole,
  defaultHomeScreenMessage,
} from "../../constants";
import { useCurrentUser, useCurrentUserRole } from "../../hooks/useCurrentUser";
import GroupContext from "../../contexts/GroupContext";
import { Skeleton } from "@mui/material";
import { GroupPostsAddButton } from "../../components/group-posts/GroupPostsAddButton";
import { programsNavigation } from "../../routes/client/programs/utils/common";
import ProgramsContext from "../../contexts/ClientPorgrams";
import { ClientProgramListRouteQuery$data } from "../../routes/client/programs/list/__generated__/ClientProgramListRouteQuery.graphql";
import { useCurrentWorkspace } from "../../hooks/useCurrentWorkspace";
import { clientSettingsNavigation } from "../../routes/client/settings/utils/common";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CompactDateDialogContext from "../../contexts/CompactDateDialogContext";
import { getISODate } from "../../utils/date";
import { NewMessageAction } from "../../components/messages/NewMessageAction";
import { ClientProfileActions } from "../../components/screen/ClientProfileScreen";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { PendingActivityContext } from "../../contexts/PendingActivityContext";
import WorkoutFinishAction from "../../components/workout/WorkoutFinishAction";
import { FinishWorkoutModalContext } from "../../contexts/FinishWorkoutModalContext";
import dayjs from "dayjs";
import { MobileAppModalContext } from "../../contexts/MobileAppModalContext";

const DEFAULT_CONFIG = {
  title: undefined,
  subtitle: <>&nbsp;</>,
  actions: [],
  tabsNavigation: undefined,
  appBar: true,
  appDrawer: true,
  filters: undefined,
  breadcrumbs: [{ name: "" }],
  disableSpeedDial: true,
  forceBackButton: false,
  appBarHideOnMobile: false,
};

const AppLayoutClient = () => {
  const location = useLocation();
  const { slug, program, date, component, status, recipient } = useParams();
  const role = useCurrentUserRole();
  const workspace = useCurrentWorkspace();
  const navigate = useNavigate();
  const { unreadMessagesCounter } = useCurrentUser();
  const isMobile = useMediaMobile("md", false);
  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));
  const [searchParams] = useSearchParams();
  const isWorkout = searchParams.get("workout");

  date === ":date" &&
    navigate(CLIENT_HOME_ROUTE.replace(":date", getISODate()));

  const PATHNAME_FIRST_SECTION = "/" + location.pathname.split("/")[1];
  const PATHNAME_LAST_SECTION = "/" + location.pathname.split("/").at(-1);

  const FORMATTED_DATE = date && dayjs(date).format("MMMM DD, YYYY");

  // LOADING CONTEXT
  const [loading, setLoading] = useState(true);

  const loadingContextValue = useMemo(
    () => ({ loading, setLoading }),
    [loading],
  );

  // GROUP CONTEXT
  const [groupRouteQuery, setGroupRouteQuery] = useState<any>({
    group: { name: "...", membersCount: ".." },
  });

  const groupContextValue = useMemo(
    () => ({ groupRouteQuery, setGroupRouteQuery }),
    [groupRouteQuery],
  );

  // PROGRAMS CONTEXT
  const [programsData, setProgramsData] =
    useState<ClientProgramListRouteQuery$data>();

  const programsContextValue = useMemo(
    () => ({ programsData, setProgramsData }),
    [programsData],
  );

  // COMPACT DATA MODAL CONTEXT
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);

  const compactDateDialogContextValue = useMemo(
    () => ({ dateDialogOpen, setDateDialogOpen }),
    [dateDialogOpen],
  );

  // PENDING ACTIVITY CONTEXT
  const [isActivityPending, setIsActivityPending] = React.useState(false);

  const pendingActivityContextValue = useMemo(
    () => ({ isActivityPending, setIsActivityPending }),
    [isActivityPending],
  );

  // FINISH WORKOUT CONTEXT
  const [isFinishWorkoutModalOpen, setIsFinishWorkoutModalOpen] =
    React.useState(false);

  const finishWorkoutModalContextValue = useMemo(
    () => ({ isFinishWorkoutModalOpen, setIsFinishWorkoutModalOpen }),
    [isFinishWorkoutModalOpen],
  );
  const [isMobileAppModalShown, setIsMobileAppModalShown] =
    React.useState<boolean>(
      window.localStorage.getItem(IsMobileAppModalShown) !== "false",
    );

  // CONFIGS
  const getConfig = () => {
    switch (PATHNAME_FIRST_SECTION) {
      case GROUPS_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: groupRouteQuery.group.name,
          subtitle: `${groupRouteQuery.group.membersCount} member${
            groupRouteQuery.group.membersCount === 1 ? "" : "s"
          }`,
          tabsNavigation: groupNavigation(slug, role as UserRole),
          actions: PATHNAME_LAST_SECTION === "/posts" && [
            <GroupPostsAddButton
              groupId={groupRouteQuery.group.id}
              children="New post"
            />,
          ],
        };

      case PROGRAMS_ROUTE:
        switch (status) {
          case ClientActivityStates.START:
            return {
              ...DEFAULT_CONFIG,
              title: "Program",
              subtitle: "Start workout",
              appBar: !mdSm,
            };
          case ClientActivityStates.SUMMARY:
            return {
              ...DEFAULT_CONFIG,
              title: "Program",
              subtitle: "End workout",
            };
          case ClientActivityStates.END:
            return {
              ...DEFAULT_CONFIG,
              title: "Program",
              subtitle: "End workout",
              appBar: !mdSm,
            };
          default:
            if (isWorkout)
              return {
                ...DEFAULT_CONFIG,
                title: "Programs",
                subtitle: "During workout",
                actions: [
                  <WorkoutFinishAction
                    handleSubmit={() => {
                      setIsFinishWorkoutModalOpen(true);
                    }}
                  />,
                ],
              };
            if (program && date && component)
              return {
                ...DEFAULT_CONFIG,
                appBar: false,
                appDrawer: false,
              };
            return {
              ...DEFAULT_CONFIG,
              title: "Programs",
              tabsNavigation: programsNavigation(
                programsData?.me.enrollmentsStatus.active,
                programsData?.me.enrollmentsStatus.upcoming,
                programsData?.me.enrollmentsStatus.past,
              ),
            };
        }
      case HOME_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Home",
          subtitle: workspace.message || defaultHomeScreenMessage,
          disableSpeedDial: false,
        };

      case CLIENT_SETTING_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "My Account",
          tabsNavigation: clientSettingsNavigation,
        };

      case CLIENT_ACTIVITY_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Activity",
          subtitle: "View and edit past responses.",
        };

      case MEAL_LOGGING_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Meals",
          subtitle: (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => setDateDialogOpen(true)}
            >
              {FORMATTED_DATE}
            </Box>
          ),
          forceBackButton: true,
        };

      case MESSAGES_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: !isMobile ? "Messages" : null,
          subtitle: !isMobile
            ? `${unreadMessagesCounter} new message${
                unreadMessagesCounter === 1 ? "" : "s"
              }`
            : null,
          actions: !isMobile && [<NewMessageAction />],
          appBarHideOnMobile: recipient ? true : false,
        };

      case CLIENT_PROFILE_ROUTE:
        return {
          ...DEFAULT_CONFIG,
          title: "Profile",
          subtitle: " ",
          actions: [
            ClientProfileActions(
              () => {
                setIsMobileAppModalShown(true);
                localStorage.setItem(IsMobileAppModalShown, "true");
              },
              () => navigate(CLIENT_SETTING_PROFILE_ROUTE),
              isMobile,
            ),
          ],
        };

      default:
        return { ...DEFAULT_CONFIG };
    }
  };

  const config = getConfig();

  useEffect(() => {
    setLoading(true);
    setGroupRouteQuery({ group: { name: "...", membersCount: ".." } });
  }, [slug]);

  useEffect(() => {
    setLoading(false);
    setIsActivityPending(false);
  }, [location.pathname]);

  useEffect(() => {
    setLoading(false);
  }, [groupRouteQuery]);

  return (
    <GroupContext.Provider value={groupContextValue}>
      <ProgramsContext.Provider value={programsContextValue}>
        <CompactDateDialogContext.Provider
          value={compactDateDialogContextValue}
        >
          <PendingActivityContext.Provider value={pendingActivityContextValue}>
            <FinishWorkoutModalContext.Provider
              value={finishWorkoutModalContextValue}
            >
              <MobileAppModalContext.Provider
                value={[isMobileAppModalShown, setIsMobileAppModalShown]}
              >
                <AppLayout
                  title={
                    config.title === "..." || loading ? (
                      <Skeleton variant="text" width={500} />
                    ) : (
                      config.title
                    )
                  }
                  subtitle={
                    config.title === "..." || (config.subtitle && loading) ? (
                      <Skeleton variant="text" width={200} />
                    ) : (
                      config.subtitle
                    )
                  }
                  tabsNavigation={config.tabsNavigation}
                  actions={config.actions}
                  breadcrumbs={config.breadcrumbs}
                  disableSpeedDial={config.disableSpeedDial}
                  forceBackButton={config.forceBackButton}
                  appBar={config.appBar}
                  appDrawer={config.appDrawer}
                  appBarHideOnMobile={config.appBarHideOnMobile}
                >
                  <Outlet />
                </AppLayout>
              </MobileAppModalContext.Provider>
            </FinishWorkoutModalContext.Provider>
          </PendingActivityContext.Provider>
        </CompactDateDialogContext.Provider>
      </ProgramsContext.Provider>
    </GroupContext.Provider>
  );
};

export default AppLayoutClient;
