import clsx from "clsx";
import React from "react";
import { graphql, usePaginationFragment } from "react-relay";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActivityResponseCard } from "../../components/activity/ActivityResponseCard";
import { LoadMoreButton } from "../button/LoadMoreButton";

import {
  NavigationItem,
  ActivityStatus,
  ActivityType,
  ActivitySort,
  defaultCoachActivityFilters,
  ActivitySortLocalStorageParameter,
} from "../../constants";
import { PageSkeleton } from "../loading/PageSkeleton";
import {
  ActivityTypesFilter,
  ActivityTypesFilterProps,
} from "../filters/ActivityTypesFilter";
import { polyfillCSS } from "../../utils/css";
import {
  ActivitySortFilter,
  ActivitySortFilterProps,
} from "../filters/ActivitySortFilter";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";

import { CoachActivity_root$key } from "./__generated__/CoachActivity_root.graphql";
import {
  COACH_ACTIVITY_ARCHIVED_ROUTE,
  COACH_ACTIVITY_INBOX_ROUTE,
} from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";
import EmptyActivitiesArt from "../../icons/EmptyActivitiesArt";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },

  empty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(10, 0),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(15, 0),
    },
  },
}));

export const coachActivityNavigation: NavigationItem[] = [
  {
    name: "Inbox",
    link: COACH_ACTIVITY_INBOX_ROUTE,
  },
  {
    name: "Archived",
    link: COACH_ACTIVITY_ARCHIVED_ROUTE,
  },
];

export const getCoachActivityTypes = (
  filters: typeof defaultCoachActivityFilters,
) =>
  Object.entries(filters)
    .filter(([, enabled]) => enabled)
    .map(([type]) => type) as ActivityType[];

const rootFragment = graphql`
  fragment CoachActivity_root on Root
  @refetchable(queryName: "CoachActivityRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    withAnswersOnly: { type: "Boolean", defaultValue: true }
    withMealLogging: { type: "Boolean", defaultValue: true }
    withClientForms: { type: "Boolean", defaultValue: true }
    coachArchived: { type: "Boolean" }
    orderBy: { type: "ActivitySortBy", defaultValue: COMPLETED_AT_DESC }
    activityTypes: { type: "[ActivityType!]" }
  ) {
    allActivities: activitiesConn(
      first: 0
      withAnswersOnly: $withAnswersOnly
      withMealLogging: $withMealLogging
      withClientForms: $withClientForms
      coachArchived: $coachArchived
    ) {
      totalCount
    }

    activities: activitiesConn(
      first: $first
      after: $after
      withAnswersOnly: $withAnswersOnly
      withMealLogging: $withMealLogging
      withClientForms: $withClientForms
      coachArchived: $coachArchived
      orderBy: $orderBy
      activityTypes: $activityTypes
    ) @connection(key: "CoachActivity_activities", filters: []) {
      edges {
        node {
          ...ActivityResponseCard_activity
          id
          coachArchived
        }
      }
    }
  }
`;

export interface CoachActivityProps extends Omit<ContainerProps, "children"> {
  root: CoachActivity_root$key;
  status: ActivityStatus;
  pageSize?: number;
}

export function CoachActivity(props: CoachActivityProps) {
  const { className, root: rootRef, status, pageSize = 10, ...other } = props;

  const {
    data: root,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment(rootFragment, rootRef);

  const s = useStyles();
  const theme = useTheme();

  const [pending, setPending] = React.useState(false);
  const [visibleModalVideo, setVisibleModalVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);

  const [sortedBy, setSortedBy] = useLocalStorage(
    ActivitySortLocalStorageParameter.SORT,
    ActivitySort.COMPLETED_AT_DESC,
  );
  const [filters, setFilters] = useLocalStorage<
    ActivityTypesFilterProps["filters"]
  >(ActivitySortLocalStorageParameter.FILTER, defaultCoachActivityFilters);

  const handleFiltersChange: ActivityTypesFilterProps["onChange"] =
    React.useCallback(
      (filters) => {
        setFilters(filters);
        setPending(true);
      },
      [setFilters],
    );

  const handleSortChange: ActivitySortFilterProps["onChange"] =
    React.useCallback(
      (sortedBy) => {
        setSortedBy(sortedBy);
        setPending(true);
      },
      [setSortedBy],
    );

  const handleMoreClick = React.useCallback(() => {
    loadNext(pageSize);
  }, [pageSize, loadNext]);

  React.useEffect(() => {
    if (pending && !isLoadingNext) {
      setPending(false);

      // TODO_P is refetch the right function? pageSize is missing
      refetch(
        {
          activityTypes: getCoachActivityTypes(filters),
          first: pageSize,
          orderBy: sortedBy,
        },
        RELAY_LAZY_LOAD_COMMON_CONFIG,
      );
    }
  }, [pageSize, refetch, isLoadingNext, filters, pending, sortedBy]);

  const handleOpenVideo = React.useCallback((url) => {
    setVisibleModalVideo(true);
    setVideoUrl(url);
  }, []);

  const handleCloseVideo = React.useCallback(() => {
    setVisibleModalVideo(false);
    setVideoUrl(null);
  }, []);

  return (
    <>
      {!root || pending ? (
        <PageSkeleton />
      ) : (
        <Container className={clsx(s.root, className)} {...other}>
          {root.allActivities.totalCount ? (
            <>
              <Box className={s.actions}>
                <ActivitySortFilter
                  sortedBy={sortedBy}
                  onChange={handleSortChange}
                />
                <ActivityTypesFilter
                  filters={filters}
                  onChange={handleFiltersChange}
                />
              </Box>

              {root.activities.edges.length > 0 ? (
                root.activities.edges.map(({ node: activity }) => (
                  <ActivityResponseCard
                    key={activity.id}
                    activityRef={activity}
                    handleOpenVideo={handleOpenVideo}
                  />
                ))
              ) : (
                <Typography>There are no new activities</Typography>
              )}

              {hasNext && (
                <Box className={s.actions}>
                  <LoadMoreButton
                    onClick={handleMoreClick}
                    disabled={isLoadingNext}
                  />
                </Box>
              )}
            </>
          ) : status === ActivityStatus.ACTIVE ? (
            <Box className={s.empty}>
              <EmptyActivitiesArt fill={theme.palette.primary.main} />
            </Box>
          ) : (
            <Typography>There are no new activities</Typography>
          )}
        </Container>
      )}
      <ConfirmActionDialog
        title=""
        onCancel={handleCloseVideo}
        onConfirm={() => {}}
        open={visibleModalVideo}
        activity
        videoUrl={videoUrl}
      />
      <TrackInfoTool
        trackInfo={{
          name:
            status === ActivityStatus.ACTIVE
              ? "Coach - Activity"
              : "Coach - Archived Activity",
        }}
      />
    </>
  );
}
