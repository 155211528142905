import React from "react";
import { graphql, useFragment } from "react-relay/hooks";

import { useExerciseLibrary_rootRef$key } from "./__generated__/useExerciseLibrary_rootRef.graphql";

const fragment = graphql`
  fragment useExerciseLibrary_rootRef on Root {
    ...ExerciseLibraryExercisesList_rootRef
    ...ExerciseAutocomplete_rootRef
  }
`;

export const ExerciseLibraryContext =
  React.createContext<useExerciseLibrary_rootRef$key>(null);

export function useExerciseLibrary() {
  const rootRef: any = React.useContext(ExerciseLibraryContext);

  return useFragment(fragment, rootRef);
}
