import clsx from "clsx";
import React from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { Node } from "slate";
import { VisibilityOutlined as PreviewIcon } from "@mui/icons-material";

import { ClientFormEditor } from "../new-editor/ClientFormEditor";
import { useBackupState } from "../../hooks/useLocalStorage";
import { ReactComponent as SidebarIcon } from "../../icons/AddSquare.svg";
import { useHistoryBlock } from "../history-block/hooks";

import { usePreview, PreviewType } from "../../hooks/usePreview";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { ActionButton } from "../button/ActionButton";
import { ComponentToolbar } from "../coach-client-forms/ComponentToolbar";
import { OnAnswerUpdateContext } from "../../hooks/useOnAnswerUpdate";
import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { checkinQuestionsAreValid } from "../../utils/component";
import { UserRole } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { ActivityFeedbackButton } from "../activity-feedback/ActivityFeedbackButton";
import { ReactComponent as BackIcon } from "../../icons/ArrowBack.svg";
import { polyfillCSS } from "../../utils/css";

import { ClientFormData } from "./types";
import { defaultClientFormName } from "./constants";
import { ClientFormCoverImage } from "./ClientFormCoverImage";
import { ClientFormHeader, ClientFormHeaderData } from "./ClientFormHeader";
import { ClientFormSidebar } from "./ClientFormSidebar";
import { ClientForm_clientForm$key } from "./__generated__/ClientForm_clientForm.graphql";
import { useUpdateClientFormMutation } from "./mutations/UpdateClientForm";
import { useSubmitClientFormMutation } from "./mutations/SubmitClientForm";
import {
  deepCompare,
  isEmptyContent,
  unfixLegacyContent,
} from "../editor/utils/common";
import { useNavigate, useParams } from "react-router-dom";
import { COACH_CLIENTS_FORMS_ROUTE } from "../../routes/routes";
import dayjs from "dayjs";
import {
  CheckInComponentContext,
  EditorComponentItemDisabledContext,
} from "../new-editor/hooks";
import {
  getCheckInComponentStatus,
  getCheckInComponentView,
} from "../new-editor/utils/editorUtils";
import { useQueryParam } from "../../hooks/useQueryParam";
import { TElement } from "@udecode/plate-common";
import { CheckInTypes } from "../new-editor/utils/menuItemUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
    width: "100%",
    minHeight: "100vh",

    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(106.25),
    },
  },

  withoutImage: {},
  disabled: {
    pointerEvents: "none",
  },
  readOnly: {
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",

    "& $coverImage": {
      borderRadius: 0,
    },
  },

  coverImage: {
    position: "absolute",
    left: 0,
    right: 0,
  },

  header: {
    position: "relative",
    margin: theme.spacing(16, 5, 3, 5),

    "$withoutImage &": {
      marginTop: theme.spacing(5),
    },
  },

  editor: {
    margin: theme.spacing(0, 5),
    backgroundColor: theme.palette.background.paper,

    "$readOnly &": {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  },

  preview: {
    width: "100%",
    height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(PREVIEW_BAR_SPACING),
  },

  content: {
    marginBottom: "auto",
  },

  submit: {
    margin: theme.spacing(4, 0),
  },

  buttons: {
    position: "absolute",
    top: polyfillCSS(`calc(${theme.spacing(1)} + var(--safe-area-inset-top))`),
    left: polyfillCSS(
      `calc(${theme.spacing(1)} + var(--safe-area-inset-left))`,
    ),
  },

  backButton: {
    width: theme.spacing(6),
    minWidth: theme.spacing(6),
    height: theme.spacing(6),

    "$buttons &": {
      backgroundColor: theme.palette.quote,
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(7),
      height: theme.spacing(5.5),
    },
  },

  feedbackButton: {
    "$buttons &": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(2),
      height: theme.spacing(5.5),
    },
  },
}));

const clientFormFragment = graphql`
  fragment ClientForm_clientForm on ClientForm {
    id
    requestId
    name
    description
    image
    content
    updatedAt
    submittedAt

    activity {
      ...ActivityFeedbackButton_activity
    }
  }
`;

export interface ClientFormProps {
  clientForm: ClientForm_clientForm$key;
  readOnly?: boolean;
  autoSaveDelay?: number;
  returnUrl?: string;
  onSendForm?: () => void;
  disabled?: boolean;
  className?: string;
}

export function ClientForm(props: ClientFormProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const {
    className,
    clientForm: clientFormRef,
    onSendForm,
    autoSaveDelay = 2000,
    returnUrl = COACH_CLIENTS_FORMS_ROUTE,
    readOnly = false,
    disabled = false,
  } = props;
  const clientForm = useFragment(clientFormFragment, clientFormRef);
  const {
    id: clientFormId,
    name,
    description,
    content,
    image,
    updatedAt,
    activity,
  } = clientForm;
  const role = useCurrentUserRole();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [unsafe, setDirty, dirty] = useHistoryBlock();
  const backupEnabled = !(role === UserRole.COACH && readOnly);
  const [data, setData, clearCacheData] = useBackupState<ClientFormData>(
    "client-form",
    [clientFormId, clientForm.requestId].filter(Boolean).join(":"),
    {
      name,
      description,
      image,
      content: JSON.parse(content),
    },
    backupEnabled,
  );
  const snackAlert = useSnackAlert();
  const [updateClientForm, updateClientFormInFlight] =
    useUpdateClientFormMutation();
  const [submitClientForm, submitClientFormInFlight] =
    useSubmitClientFormMutation();

  const [preview, setPreview] = usePreview();
  const updateData = React.useCallback(
    (update: Partial<ClientFormData>) => {
      setData({ ...data, ...update });
      setDirty(true);
    },
    [data, setData, setDirty],
  );

  const handleContentChange = React.useCallback(
    (content: Node[]) => {
      if (!deepCompare(data.content, content)) {
        updateData({ content });
      }
    },
    [data.content, updateData],
  );

  const handleHeaderChange = React.useCallback(
    (header: ClientFormHeaderData) => updateData({ ...header }),
    [updateData],
  );

  const handleImageChange = React.useCallback(
    (image: string) => updateData({ image }),
    [updateData],
  );

  const handleToggleSidebar = React.useCallback(
    () => setSidebarOpen((value) => !value),
    [],
  );

  const handleTogglePreview = React.useCallback(
    () => setPreview(PreviewType.MOBILE),
    [setPreview],
  );

  const handleBack = React.useCallback(
    () => unsafe(() => navigate(returnUrl)),
    [returnUrl, unsafe],
  );

  const submitted = Boolean(clientForm.submittedAt);
  const [editingEl, setEditingEl] = React.useState<TElement | null>(null);

  const save = React.useCallback(() => {
    setDirty(false);
    updateClientForm({
      variables: {
        input: {
          id: clientFormId,
          name: data.name,
          description: data.description,
          image: data.image,
          content: JSON.stringify(unfixLegacyContent(data.content)),
        },
      },
      onSuccess: () => {
        setSaved(true);
      },
    });
  }, [
    clientFormId,
    data.content,
    data.description,
    data.image,
    data.name,
    setDirty,
    updateClientForm,
  ]);

  const handleFormSubmit = React.useCallback(
    () =>
      submitClientForm({
        variables: {
          input: {
            id: clientFormId,
            content: JSON.stringify(data.content),
          },
        },
        onSuccess() {
          snackAlert({
            severity: "success",
            message: submitted ? "Response updated" : "Form results submitted",
          });
          clearCacheData();
          setDirty(false);

          if (!submitted) {
            navigate(returnUrl);
          }
        },
      }),
    [
      clearCacheData,
      clientFormId,
      data.content,
      returnUrl,
      setDirty,
      snackAlert,
      submitClientForm,
      submitted,
    ],
  );

  const status = React.useMemo(() => {
    const statusText = updateClientFormInFlight
      ? "Saving..."
      : saved && !dirty
        ? "Changes saved"
        : "Last update";
    const statusUpdatedAt = dayjs(updatedAt).fromNow();

    return `${statusText} ${statusUpdatedAt}`;
  }, [dirty, saved, updateClientFormInFlight, updatedAt]);

  const incomplete = !data.name || isEmptyContent(data.content);
  const withoutImage = readOnly && !data.image;

  React.useEffect(() => {
    if (dirty && !readOnly && !updateClientFormInFlight) {
      const timer = setTimeout(save, autoSaveDelay);
      return () => clearTimeout(timer);
    }
  }, [dirty, data, updateClientFormInFlight, save, autoSaveDelay, readOnly]);

  const handleBackClick = React.useCallback(
    () => navigate(returnUrl),
    [returnUrl],
  );

  const [previewQueryParam] = useQueryParam("preview", false);
  const { username: clientName } = useParams();

  const buttons = (
    <>
      {!previewQueryParam && (
        <Button onClick={handleBackClick} className={s.backButton}>
          <BackIcon />
        </Button>
      )}

      {activity && (
        <ActivityFeedbackButton
          className={s.feedbackButton}
          activity={activity}
        />
      )}
    </>
  );

  const isPreviewOrClientView = previewQueryParam || role === UserRole.CLIENT;
  const isCoachReview = role === UserRole.COACH && Boolean(clientName);

  const isDisabledMenuItem = (type: string) => {
    // Progress photo disabled in forms
    return type === CheckInTypes.PROGRESS_PHOTO;
  };

  return (
    <EditorComponentItemDisabledContext.Provider value={isDisabledMenuItem}>
      <CheckInComponentContext.Provider
        value={{
          view: getCheckInComponentView(
            role,
            Boolean(previewQueryParam),
            submitted || isCoachReview,
          ),
          status: getCheckInComponentStatus(role, submitted),
          editingEl: editingEl,
          setEditingEl: setEditingEl,
        }}
      >
        {preview ? (
          <Box className={s.preview}>
            <PreviewBar />
            <PreviewBox src={window.location + "?preview=true"} />
          </Box>
        ) : (
          <Box
            className={clsx(s.root, className, {
              [s.readOnly]: readOnly,
              [s.withoutImage]: withoutImage,
            })}
          >
            {!withoutImage && (
              <ClientFormCoverImage
                clientFormId={clientFormId}
                className={s.coverImage}
                image={data.image}
                onImageChange={handleImageChange}
                disabled={readOnly}
              />
            )}

            {!withoutImage && buttons && (
              <Box className={s.buttons}>{buttons}</Box>
            )}

            <ClientFormHeader
              className={s.header}
              header={{
                name: data.name,
                description: data.description,
              }}
              onHeaderChange={handleHeaderChange}
              disabled={readOnly}
              withImage={!withoutImage}
              buttons={withoutImage && buttons}
            />

            <Box className={clsx(s.editor, isCoachReview && s.disabled)}>
              <OnAnswerUpdateContext.Provider
                value={
                  role === UserRole.CLIENT && clientForm.submittedAt
                    ? handleFormSubmit
                    : undefined
                }
              >
                <ClientFormEditor
                  className={s.content}
                  value={data.content}
                  onChange={handleContentChange}
                  readOnly={readOnly || isPreviewOrClientView}
                  disabled={disabled || isPreviewOrClientView}
                />
              </OnAnswerUpdateContext.Provider>
              {readOnly && !submitted && !isCoachReview && (
                <ActionButton
                  className={s.submit}
                  fullWidth
                  disabled={
                    disabled ||
                    !checkinQuestionsAreValid(data.content) ||
                    submitClientFormInFlight
                  }
                  onClick={handleFormSubmit}
                >
                  Submit form
                </ActionButton>
              )}
            </Box>

            {!readOnly && (
              <ComponentToolbar
                header={data.name || defaultClientFormName}
                headerMuted={!data.name}
                onBack={handleBack}
                status={status}
                BackButtonProps={{
                  disabled: updateClientFormInFlight,
                }}
                SubmitButtonProps={{
                  disabled: incomplete || dirty,
                  onClick: onSendForm,
                }}
                buttons={[
                  {
                    title: "Add content",
                    active: sidebarOpen,
                    icon: <SidebarIcon />,
                    onClick: handleToggleSidebar,
                  },

                  {
                    title: "Preview",
                    icon: <PreviewIcon />,
                    onClick: handleTogglePreview,
                  },
                ]}
              />
            )}

            {!readOnly && (
              <ClientFormSidebar
                open={sidebarOpen}
                onClose={handleToggleSidebar}
              />
            )}
          </Box>
        )}
      </CheckInComponentContext.Provider>
    </EditorComponentItemDisabledContext.Provider>
  );
}
