import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";

import { BadgeCounter, BadgeCounterProps } from "./BadgeCounter";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface UnreadActivitiesCounterProps
  extends Omit<BadgeCounterProps, "counter"> {}

export function UnreadActivitiesCounter(props: UnreadActivitiesCounterProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const counter = user?.unreadActivitiesCount;

  return (
    <BadgeCounter
      className={clsx(s.root, className)}
      counter={counter}
      {...other}
    />
  );
}
