import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Add } from "@mui/icons-material";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";

import { useCreateClientFormMutation } from "./mutations/CreateClientForm";
import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_FORM_ROUTE } from "../../routes/routes";

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface CreateClientFormActionProps extends ActionButtonProps {}

export function CreateClientFormAction(props: CreateClientFormActionProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const [createClientForm, createClientFormInFlight] =
    useCreateClientFormMutation();

  const navigate = useNavigate();

  const handleClick = React.useCallback(
    () =>
      createClientForm({
        variables: { input: {} },
        onSuccess: ({
          createClientForm: {
            clientForm: { slug },
          },
        }) => navigate(COACH_CLIENTS_FORM_ROUTE.replace(":slug", slug)),
      }),

    [createClientForm],
  );

  return (
    <ActionButton
      className={clsx(s.root, className)}
      startIcon={<Add />}
      size="small"
      children="Add a form"
      disabled={createClientFormInFlight}
      onClick={handleClick}
      {...other}
    />
  );
}
