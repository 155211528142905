import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { SortableListItem } from "../sortable-list/SortableListItem";
import {
  nutritionTargetsLabels,
  nutritionTrackingTypes,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {},

  name: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
  },

  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

export interface ClientGoalListItemProps {
  option: any;
  position: any;
  itemType: any;
}

export function ClientNutritionTargetDrawerListItem(
  props: ClientGoalListItemProps,
) {
  const { ...other } = props;

  const s = useStyles();

  const { option } = other;
  const { targetType, updatedAt, trackingType } = option;

  return (
    <SortableListItem actions={[{ name: "edit", Icon: EditIcon }]} {...other}>
      <Box>
        <Typography
          className={s.name}
          variant="body1"
          children={`${nutritionTrackingTypes[trackingType]} | ${nutritionTargetsLabels[targetType]}`}
        />
        <Typography
          className={s.date}
          variant="body1"
          children={`Last recorded ${updatedAt}`}
        />
      </Box>
    </SortableListItem>
  );
}
