import clsx from "clsx";
import React from "react";
import { BoxProps, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { PendingInviteMenu } from "../menu/PendingInviteMenu";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import {
  ClientStatus as EnumClientStatus,
  InviteSource,
} from "../../constants";

import { PendingInviteItemRevokeMutation } from "./__generated__/PendingInviteItemRevokeMutation.graphql";
import { PendingInviteItemResendMutation } from "./__generated__/PendingInviteItemResendMutation.graphql";
import { PendingInviteItem_invite$key } from "./__generated__/PendingInviteItem_invite.graphql";
import { SelectableItem } from "./SelectableItem";
import { PendingInviteItemDeleteMutation } from "./__generated__/PendingInviteItemDeleteMutation.graphql";
import { SendEmailDialog } from "../dialog/SendEmailDialog";

const useStyles = makeStyles(() => ({
  root: {},
}));

const resendInviteMutation = graphql`
  mutation PendingInviteItemResendMutation($input: ResendClientInviteInput!) {
    resendClientInvite(input: $input) {
      clientMutationId
    }
  }
`;

const revokeInviteMutation = graphql`
  mutation PendingInviteItemRevokeMutation($input: RevokeClientInviteInput!) {
    revokeClientInvite(input: $input) {
      clientMutationId
    }
  }
`;

const deleteAddedUserMutation = graphql`
  mutation PendingInviteItemDeleteMutation($input: RevokeAddedClientInput!) {
    revokeAddedClient(input: $input) {
      clientMutationId
    }
  }
`;

const inviteAddedClientMutation = graphql`
  mutation PendingInviteItemSendEmailMutation($input: InviteAddedClientInput!) {
    inviteAddedClient(input: $input) {
      invites {
        ...PendingInviteItem_invite
      }
      clientMutationId
    }
  }
`;

const inviteFragment = graphql`
  fragment PendingInviteItem_invite on Invite {
    id
    email
    source
    createdAt(format: "MMMM Do")
    client {
      id
      clientStatus
    }
  }
`;

export interface PendingInviteItemProps extends BoxProps {
  invite: PendingInviteItem_invite$key;
  setTotalCount: (totalCount: any) => void;
  totalCount: any;
}

export function PendingInviteItem(props: PendingInviteItemProps) {
  const {
    className,
    invite: inviteRef,
    setTotalCount,
    totalCount,
    ...other
  } = props;
  const invite = useFragment(inviteFragment, inviteRef);
  const s = useStyles();
  const [emailDialogIsOpen, setEmailDialogIsOpen] = React.useState(false);

  const isAdded = invite.client.clientStatus === EnumClientStatus.ADDED;

  const [resendInvite, resendInviteInFlight] =
    useMutation<PendingInviteItemResendMutation>(resendInviteMutation);
  const [revokeInvite, revokeInviteInFlight] =
    useMutation<PendingInviteItemRevokeMutation>(revokeInviteMutation);
  const [deleteAddedUser, deleteAddedUserInFlight] =
    useMutation<PendingInviteItemDeleteMutation>(deleteAddedUserMutation);
  const [inviteAddedClient, invitingAddedClientMutation] = useMutation(
    inviteAddedClientMutation,
  );

  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "pendingInviteMenu",
  });

  const menuTriggerProps = React.useMemo(
    () => bindTrigger(menuState),
    [menuState],
  );

  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);

  const getOnCompleted = React.useCallback(
    (message: string) => (_, errors) => {
      if (errors && errors[0]) {
        onError(errors[0]);
      } else {
        snackAlert({
          severity: "success",
          message,
        });
        menuState.close();
      }
    },
    [menuState, onError, snackAlert],
  );

  const revokeInviteStoreUpdater = React.useCallback(
    (store) => {
      const invites = ConnectionHandler.getConnection(
        store.getRoot(),
        "ClientInvites_invitedUsers",
      );

      if (invites) {
        ConnectionHandler.deleteNode(invites, invite.id);

        setTotalCount({
          ...totalCount,
          invited: totalCount.invited - 1,
        });
      }
    },
    [invite.id, totalCount, setTotalCount],
  );

  const deleteAddedUserStoreUpdater = React.useCallback(
    (store) => {
      const invites = ConnectionHandler.getConnection(
        store.getRoot(),
        "ClientInvites_addedUsers",
      );

      if (invites) {
        ConnectionHandler.deleteNode(invites, invite.id);

        setTotalCount({
          ...totalCount,
          added: totalCount.added - 1,
        });
      }
    },
    [invite.id, totalCount, setTotalCount],
  );

  const handleResendInvite = React.useCallback(() => {
    resendInvite({
      variables: {
        input: {
          id: invite.id,
        },
      },
      onCompleted: getOnCompleted("Invitation has been resent"),
      onError,
    });
  }, [resendInvite, invite.id, getOnCompleted, onError]);

  const handleRevokeInvite = React.useCallback(() => {
    revokeInvite({
      variables: {
        input: {
          id: invite.id,
        },
      },
      onCompleted: getOnCompleted("Invitation has been revoked"),
      onError,
      updater: revokeInviteStoreUpdater,
      optimisticUpdater: revokeInviteStoreUpdater,
    });
  }, [
    revokeInvite,
    invite.id,
    getOnCompleted,
    onError,
    revokeInviteStoreUpdater,
  ]);

  const handleDeleteAddedUser = React.useCallback(() => {
    deleteAddedUser({
      variables: {
        input: {
          id: invite.client.id,
        },
      },
      onCompleted: getOnCompleted("User removed from added list"),
      onError,
      updater: deleteAddedUserStoreUpdater,
      optimisticUpdater: deleteAddedUserStoreUpdater,
    });
  }, [
    deleteAddedUserStoreUpdater,
    getOnCompleted,
    onError,
    invite,
    deleteAddedUser,
  ]);

  const handleSendEmail = React.useCallback(
    (message) => {
      inviteAddedClient({
        variables: {
          input: {
            id: [invite.client.id],
            message: message,
          },
        },
        onCompleted: getOnCompleted("Invite send"),
        onError,
        updater: (store) => {
          const added = ConnectionHandler.getConnection(
            store.getRoot(),
            "ClientInvites_addedUsers",
          );

          const invited = ConnectionHandler.getConnection(
            store.getRoot(),
            "ClientInvites_invitedUsers",
          );

          if (invited) {
            const newInvite = store
              .getRootField("inviteAddedClient")
              .getLinkedRecords("invites");

            const edge = ConnectionHandler.createEdge(
              store,
              invited,
              newInvite[0],
              "InviteEdge",
            );

            ConnectionHandler.insertEdgeBefore(invited, edge);
          }

          if (added) {
            ConnectionHandler.deleteNode(added, invite.id);

            setTotalCount({
              ...totalCount,
              added: totalCount.added - 1,
              invited: totalCount.invited + 1,
            });
          }
        },
      });
    },
    [
      invite,
      getOnCompleted,
      onError,
      inviteAddedClient,
      setTotalCount,
      totalCount,
    ],
  );

  const handleOpenEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(true);
    menuState.close();
  }, [setEmailDialogIsOpen, menuState]);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(false);
  }, [setEmailDialogIsOpen]);

  return (
    <>
      <SelectableItem
        className={clsx(s.root, className)}
        header={invite.email}
        subheader={`${isAdded ? "Added by" : "Invited by"} ${
          invite.source === InviteSource.LINK ? "link" : "email"
        } on ${invite.createdAt}`}
        action={
          <IconButton
            children={<MoreHoriz />}
            {...menuTriggerProps}
            size="large"
          />
        }
        {...other}
      />

      <PendingInviteMenu
        disabled={
          resendInviteInFlight ||
          revokeInviteInFlight ||
          deleteAddedUserInFlight
        }
        handleResendInvite={
          isAdded ? handleOpenEmailDialog : handleResendInvite
        }
        handleRevokeInvite={
          isAdded ? handleDeleteAddedUser : handleRevokeInvite
        }
        isAdded={isAdded}
        {...menuProps}
      />

      <SendEmailDialog
        open={emailDialogIsOpen}
        onClose={handleCloseEmailDialog}
        sendingEmail={invitingAddedClientMutation}
        sendEmail={handleSendEmail}
      />
    </>
  );
}
