import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { RefreshSlug } from "../routes/RefreshSlug";

import { GroupSettingsCard } from "./GroupSettingsCard";
import { GroupSettingsScreen_rootRef$key } from "./__generated__/GroupSettingsScreen_rootRef.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const fragment = graphql`
  fragment GroupSettingsScreen_rootRef on Root
  @argumentDefinitions(slug: { type: "String!" }) {
    group(slug: $slug) {
      ...RefreshSlug
      ...GroupSettingsCard_group
    }
  }
`;

export interface GroupSettingsScreenProps
  extends Omit<ContainerProps, "children"> {
  rootRef: GroupSettingsScreen_rootRef$key;
}

export function GroupSettingsScreen(props: GroupSettingsScreenProps) {
  const { className, rootRef, ...other } = props;
  const s = useStyles();
  const { group } = useFragment(fragment, rootRef);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <RefreshSlug nodesRef={[group]} />
      <GroupSettingsCard groupRef={group} />
    </Container>
  );
}
