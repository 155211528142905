import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { EditorDialog, EditorDialogProps } from "../dialog/EditorDialog";
import { useUpdateGroupDescription } from "../groups/mutations/UpsertGroup";

import { GroupDescriptionDialog_group$key } from "./__generated__/GroupDescriptionDialog_group.graphql";
import { GroupDescriptionEditor } from "./GroupDescriptionEditor";
import { isEmptyContent } from "../editor/utils/common";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const fragment = graphql`
  fragment GroupDescriptionDialog_group on Group {
    id
    description
  }
`;

export interface GroupDescriptionDialogProps extends EditorDialogProps {
  groupRef: GroupDescriptionDialog_group$key;
}

export function GroupDescriptionDialog(props: GroupDescriptionDialogProps) {
  const { className, groupRef, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const { id, description } = useFragment(fragment, groupRef);
  const [upsertGroup, pending] = useUpdateGroupDescription();
  const initialValue = React.useMemo(
    () => (description ? JSON.parse(description) : []),
    [description],
  );
  const [content, setContent] = React.useState(initialValue);
  const isEmpty = isEmptyContent(content);
  const isInitiallyEmpty = isEmptyContent(initialValue);

  const handleSubmit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }

      upsertGroup({
        variables: {
          input: {
            id,
            description: JSON.stringify(content),
          },
        },
      });
    },
    [content, id, onClose, upsertGroup],
  );

  return (
    <EditorDialog
      className={clsx(s.root, className)}
      title="Group description"
      submitDisabled={pending || (isEmpty && isInitiallyEmpty)}
      onSubmit={handleSubmit}
      {...other}
    >
      <GroupDescriptionEditor value={content} onChange={setContent} />
    </EditorDialog>
  );
}
