import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useMediaMobile } from "../../hooks/useMediaMobile";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(4, 3),
    justifyContent: "flex-start",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      minWidth: 524,
      borderRadius: theme.spacing(1.5),
      justifyContent: "initial",
      alignItems: "center",
    },
  },

  title: {
    padding: 0,
    marginBottom: theme.spacing(8),

    "& h2": {
      fontSize: 24,
      fontWeight: 600,
      color: theme.palette.common.black,
    },

    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
  },

  content: {
    padding: 0,
    textAlign: "center",
    margin: theme.spacing(2, 0),
    flex: "none",
  },

  actions: {
    padding: 0,
    flexDirection: "column",
    width: "100%",
  },

  skipButton: {
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
}));

// export interface UploadDialogProps extends DialogProps {
//   title: string;
//   subtitle?: string;
//   content: React.ReactNode;
//   action: React.ReactNode;
//   noSkip?: boolean;
//   onSkipClick?: () => void;
// }

export function UploadDialog(props: any) {
  const {
    className,
    title,
    subtitle,
    content,
    action,
    noSkip = false,
    onSkipClick,
    ...other
  } = props;
  const { onClose } = props;
  const s = useStyles();
  const isMobile = useMediaMobile("sm");

  const handleSkipClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      fullScreen={isMobile}
      {...other}
    >
      <DialogTitle className={s.title}>
        {title}
        {subtitle && <Typography className={s.subtitle}>{subtitle}</Typography>}
      </DialogTitle>

      <DialogContent className={s.content}>{content}</DialogContent>

      <DialogActions className={s.actions}>
        {action}
        {!noSkip && (
          <Button
            className={s.skipButton}
            variant="text"
            color="primary"
            onClick={onSkipClick || handleSkipClick}
            children="Skip for now"
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
