/**
 * @generated SignedSource<<84deed0d3172a3c7a769e2cc96a0af47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ComponentIcon_component$data = {
  readonly iconName: string;
  readonly type: ComponentType;
  readonly " $fragmentType": "ComponentIcon_component";
};
export type ComponentIcon_component$key = {
  readonly " $data"?: ComponentIcon_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentIcon_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ComponentIcon_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "ComponentIcon",
  "abstractKey": "__isComponentIcon"
};

(node as any).hash = "79ee6bde4df06d8cf50acaefc73f81c4";

export default node;
