import React from "react";
import { DialogProps, Box, Button, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useMutation } from "react-relay/hooks";

import art from "../../../icons/camera-art.svg";
import { UploadDialog } from "../../dialog/UploadDialog";
import {
  DropzoneUploadButton,
  DropzoneUploadButtonProps,
} from "../../button/DropzoneUploadButton";
import { AssetType } from "../../../constants";
import { colorSystem } from "../../../theme";
import { useGenericErrorHandler } from "../../../hooks/useGenericErrorHandler";
import { useCurrentUserId } from "../../../hooks/useCurrentUser";
import { useMediaMobile } from "../../../hooks/useMediaMobile";

import { UploadProfilePhotoDialogUpdateUserMutation } from "./__generated__/UploadProfilePhotoDialogUpdateUserMutation.graphql";
import { getMimeTypes } from "../../../utils/file";
import { ElementType } from "../../editor/types/elements";
import Camera from "../../../icons/Camera";

const useStyles = makeStyles((theme) => ({
  frame: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(4),
    backgroundColor: colorSystem.white2,
  },

  photo: {
    maxHeight: 218,
    maxWidth: "100%",
    width: "auto",
    borderRadius: theme.spacing(1),
  },

  art: {
    maxWidth: "100%",
  },

  uploadButton: {
    [theme.breakpoints.up("sm")]: {
      border: "none",
      backgroundColor: "transparent",
    },
  },

  text: {
    color: theme.palette.primary.main,
  },

  saveButton: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    padding: theme.spacing(2.25),
    marginTop: theme.spacing(5),
  },
}));

const updateUserMutation = graphql`
  mutation UploadProfilePhotoDialogUpdateUserMutation(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      user {
        ...CurrentUserProvider_user
        ...CoachSettingsAccount_user
      }
    }
  }
`;

// export interface UploadProfilePhotoDialogProps
//   extends Omit<DialogProps, "onChange">,
//     Pick<DropzoneUploadButtonProps, "onChange">,
//     Pick<UploadDialogProps, "onSkipClick" | "noSkip"> {
//   onSaved: () => void;
// }

export function UploadProfilePhotoDialog(props: any) {
  const { className, onSaved, onChange, ...other } = props;
  const s = useStyles();
  const [src, setSrc] = React.useState("");
  const userId = useCurrentUserId();
  const onError = useGenericErrorHandler();
  const isMobile = useMediaMobile("sm");
  const theme = useTheme();
  const [updateUser, updatingUser] =
    useMutation<UploadProfilePhotoDialogUpdateUserMutation>(updateUserMutation);

  const handleChange = React.useCallback((photoURL: string) => {
    setSrc(photoURL);
  }, []);

  const handleSaveClick = React.useCallback(() => {
    if (!updatingUser && userId) {
      updateUser({
        variables: {
          input: {
            id: userId,
            photoURL: src,
          },
        },
        onCompleted(_, errors) {
          if (errors && errors[0]) {
            onError(errors[0]);
          } else if (onSaved) {
            onSaved();
          }
        },
        onError,
      });
    }
  }, [onError, onSaved, src, updateUser, updatingUser, userId]);

  const content = React.useMemo(
    () =>
      src ? (
        <Box className={s.frame}>
          <img className={s.photo} src={src} alt="" />
        </Box>
      ) : (
        <Camera className={s.art} fill={theme.palette.primary.main}></Camera>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [src],
  );

  const action = React.useMemo(
    () => (
      <>
        <DropzoneUploadButton
          className={src && s.uploadButton}
          variant={src ? "text" : "outlined"}
          onChange={handleChange}
          assetType={AssetType.USER_PHOTO}
          mimeType={getMimeTypes(ElementType.IMAGE)}
          inputName="photoURL"
          assetDescription="a profile photo"
          text={
            isMobile
              ? "Upload a profile photo"
              : src
                ? "Upload another image"
                : undefined
          }
          textClassName={s.text}
        />

        {src && (
          <Button
            className={s.saveButton}
            fullWidth
            variant="contained"
            children="Save profile photo"
            onClick={handleSaveClick}
          />
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange, handleSaveClick, src],
  );

  return (
    <UploadDialog
      title="Add a profile photo"
      content={content}
      action={action}
      noSkip={Boolean(src)}
      {...other}
    />
  );
}
