/**
 * @generated SignedSource<<ab9b27dadf90adeda8f9cf9805b5971c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Gender = "FEMALE" | "MALE" | "PRIVATE" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
export type UpdateUserInput = {
  admin?: boolean | null | undefined;
  birthday?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  displayName?: string | null | undefined;
  gender?: Gender | null | undefined;
  height?: string | null | undefined;
  id: string;
  location?: string | null | undefined;
  passwordUpdated?: boolean | null | undefined;
  phone?: string | null | undefined;
  phoneCode?: string | null | undefined;
  photoURL?: string | null | undefined;
  themeOptions?: string | null | undefined;
  timeZone?: string | null | undefined;
  topToolbar?: boolean | null | undefined;
  trialExpiryDate?: string | null | undefined;
  units?: Units | null | undefined;
  username?: string | null | undefined;
  weight?: string | null | undefined;
};
export type UpdateTrialExpirationDateDialogMutation$variables = {
  input: UpdateUserInput;
};
export type UpdateTrialExpirationDateDialogMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateTrialExpirationDateDialog_user" | "UserDetails_user">;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateTrialExpirationDateDialogMutation = {
  response: UpdateTrialExpirationDateDialogMutation$data;
  variables: UpdateTrialExpirationDateDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTrialExpirationDateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateTrialExpirationDateDialog_user"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserDetails_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTrialExpirationDateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "raw",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "trialExpiryDate",
                "storageKey": "trialExpiryDate(raw:true)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "admin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55c93df8f3ff505f7eda9d90dced65d0",
    "id": null,
    "metadata": {},
    "name": "UpdateTrialExpirationDateDialogMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTrialExpirationDateDialogMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      ...UpdateTrialExpirationDateDialog_user\n      ...UserDetails_user\n      id\n    }\n  }\n}\n\nfragment UpdateTrialExpirationDateDialog_user on User {\n  id\n  trialExpiryDate(raw: true)\n}\n\nfragment UserDetails_user on User {\n  id\n  email\n  admin\n  role\n  trialExpiryDate(raw: true)\n  ...UpdateTrialExpirationDateDialog_user\n}\n"
  }
};
})();

(node as any).hash = "5e59937341100859def19fc6f07d3c19";

export default node;
