import React from "react";
import {
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
} from "../routes/routes";
import { GroupStatus } from "../constants";

export const getAfter = (
  perPage: number,
  currentPage: number,
  key = "arrayconnection",
) =>
  currentPage > 1
    ? btoa(
        unescape(
          encodeURIComponent(`${key}:${perPage * (currentPage - 1) - 1}`),
        ),
      )
    : null;

export const perPage = 5;

export const getStatusFromPath = (path: string) => {
  switch (path) {
    case COACH_GROUPS_ACTIVE_ROUTE:
      return GroupStatus.ACTIVE;
    case COACH_GROUPS_ARCHIVED_ROUTE:
      return GroupStatus.ARCHIVED;

    default:
      return GroupStatus.ACTIVE;
  }
};

export const PaginationContext = React.createContext<any>(null);
