import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    borderStyle: "solid",
    fontWeight: 700,
    fontSize: 13,
    padding: theme.spacing(0.5, 3),
    marginTop: theme.spacing(2),
    backgroundColor: `${theme.palette.secondary.main}0f`,

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}0f`,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export interface TextButtonProps extends ButtonProps {}

export function TextButton(props: TextButtonProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return <Button className={clsx(s.root, className)} {...other} />;
}
