import clsx from "clsx";
import React from "react";
import {
  Drawer,
  IconButton,
  Box,
  Portal,
  Typography,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../../icons/Close.svg";
import { useSpreadsheetInfoDrawer } from "../../../hooks/useSpreadsheetInfoDrawer";
import { ColumnInstructions } from "./ColumnInstructions";
import { ColumnField } from "../utils";
import { TabPanel } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: "99999 !important" as any,
  },

  paper: {
    padding: theme.spacing(3, 4),
    width: "100%",

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  header: {
    margin: theme.spacing(0, 0, 3, 0),
  },

  buttons: {
    display: "flex",
    alignItems: "center",

    "& > :last-child": {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
    },
  },

  headerButton: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,

    "& svg": {
      width: 24,
      height: 24,
    },
  },

  closeButton: {
    marginRight: theme.spacing(-2),
  },

  title: {},

  tabs: {},

  tab: {
    fontWeight: "bold",
  },

  divider: {
    height: 1,
    backgroundColor: theme.palette.border.primary,
    marginTop: -1,
    marginBottom: theme.spacing(3),
  },
}));

export function SpreadsheetInfoDrawer() {
  const [columnField, setColumnField] = useSpreadsheetInfoDrawer();

  const s = useStyles();

  const handleClose = React.useCallback(() => {
    setColumnField(undefined);
  }, [setColumnField]);

  return (
    <Portal>
      <Drawer
        className={clsx(s.root)}
        classes={{ paper: s.paper }}
        anchor="right"
        variant="persistent"
        open={Boolean(columnField)}
        onClose={handleClose}
      >
        <Box className={s.buttons}>
          <IconButton
            className={clsx(s.headerButton, s.closeButton)}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography variant="h2" className={s.title}>
          Instructions
        </Typography>

        <Tabs
          value={columnField ?? ""}
          onChange={(e, newValue) => {
            setColumnField(newValue);
          }}
          className={s.tabs}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab className={s.tab} label="Reps" value={ColumnField.REPS} />
          <Tab className={s.tab} label="Weight +" value={ColumnField.WEIGHT} />
          <Tab
            className={s.tab}
            label="RPE / RIR"
            value={ColumnField.EXTRA_MEASUREMENT}
          />
        </Tabs>
        <Divider className={s.divider} orientation="horizontal" />

        {Boolean(columnField) && (
          <ColumnInstructions columnField={columnField} />
        )}
      </Drawer>
    </Portal>
  );
}
