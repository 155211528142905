import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { useQueryParam } from "../../hooks/useQueryParam";

export const IMPERSONATION_BANNER_SPACING = 6.75;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#root": {
      marginTop: (preview: boolean) =>
        preview ? "initial" : theme.spacing(IMPERSONATION_BANNER_SPACING),
    },
    ".MuiDrawer-docked > div": {
      paddingTop: (preview: boolean) =>
        preview ? "initial" : theme.spacing(IMPERSONATION_BANNER_SPACING),
    },
  },

  root: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(IMPERSONATION_BANNER_SPACING),
    zIndex: theme.zIndex.banner,
    position: "fixed",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(5.75),
    paddingRight: theme.spacing(6.5),
    top: 0,
    left: 0,
    right: 0,
  },

  header: {
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 18,
  },

  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

export interface ImpersonationBannerProps extends BoxProps {}

export function ImpersonationBanner(props: ImpersonationBannerProps) {
  const { className, ...other } = props;
  const user = useCurrentUser();
  const switchUser = useSwitchUser();
  const [previewQueryParam] = useQueryParam<string>("preview");
  const preview = previewQueryParam === "true";
  const s = useStyles(preview);

  const handleExit = React.useCallback(() => {
    switchUser();
  }, [switchUser]);

  return preview ? null : (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.header}>
        Logged in as {user.displayName}
      </Typography>
      <Button className={s.button} onClick={handleExit} variant="outlined">
        Exit
      </Button>
    </Box>
  );
}
