/**
 * @generated SignedSource<<6e1ea4b4848f2ffd048698d3338103f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientNotesScreen_root$data = {
  readonly client: {
    readonly id: string;
    readonly notes: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"UserNotesList_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UnsplashImages_rootRef">;
  readonly " $fragmentType": "CoachClientNotesScreen_root";
};
export type CoachClientNotesScreen_root$key = {
  readonly " $data"?: CoachClientNotesScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientNotesScreen_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "username"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "client",
          "notes"
        ]
      }
    ]
  },
  "name": "CoachClientNotesScreen_root",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnsplashImages_rootRef"
    },
    {
      "alias": "client",
      "args": [
        {
          "kind": "Variable",
          "name": "username",
          "variableName": "username"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": "notes",
          "args": null,
          "concreteType": "UserNoteConnection",
          "kind": "LinkedField",
          "name": "__CoachClientNotesScreen_notes_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserNoteEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserNote",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserNotesList_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "da3f78e08a50caa79605450da11426a2";

export default node;
