import clsx from "clsx";
import React from "react";
import AutoResize from "react-virtualized-auto-sizer";
import { Box, IconButton } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ArrowForwardOutline } from "../../icons/ArrowForwardOutline.svg";
import { InfiniteList, IntrinsicInfiniteListProps } from "../list/InfiniteList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },

  scrollButton: {
    borderStyle: "solid",
    borderWidth: 1.5,
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(0.75),

    "& svg": {
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },

    "& svg [stroke]": {
      stroke: theme.palette.text.secondary,
    },

    "&[disabled]": {
      opacity: 0.5,
    },

    [theme.breakpoints.up("md")]: {
      borderWidth: 2,
      borderRadius: theme.spacing(1),
      "& svg": {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
  },
  scrollBack: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  scrollForward: {},

  content: {
    height: "100%",
    flexGrow: 1,
  },

  smoothScroll: {
    "& > div > div": {
      scrollBehavior: "smooth",
    },
  },

  disableScrollbar: {
    "& > div > div": {
      scrollbarWidth: "none",
    },
    "& > div > div::-webkit-scrollbar": {
      "-webkit-appearance": "none",
    },
  },
}));

export interface AutoResizeInfiniteListProps
  extends IntrinsicInfiniteListProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  scrollButtons?: boolean;
  smoothScroll?: boolean;
}

export function AutoResizeInfiniteList({
  className,
  onClick,
  disableScrollbar = true,
  smoothScroll = false,
  ...props
}: AutoResizeInfiniteListProps) {
  const s = useStyles();
  const handleRender = React.useCallback(
    (size) => (
      <InfiniteList {...props} {...size} disableScrollbar={disableScrollbar} />
    ),
    [disableScrollbar, props],
  );

  const { scrollButtons, selectedIndex, itemsPerView = 1 } = props;
  const previousIndex = selectedIndex - itemsPerView;
  const nextIndex = selectedIndex + itemsPerView;

  return (
    <Box className={clsx(s.root, className)}>
      {scrollButtons && (
        <IconButton
          data-week-index={previousIndex}
          onClick={onClick}
          className={clsx(s.scrollButton, s.scrollBack)}
          children={<ArrowForwardOutline />}
          size="large"
        />
      )}
      <Box
        className={clsx(s.content, {
          [s.disableScrollbar]: disableScrollbar,
          [s.smoothScroll]: smoothScroll,
        })}
      >
        <AutoResize children={handleRender} />
      </Box>
      {scrollButtons && (
        <IconButton
          data-week-index={nextIndex}
          onClick={onClick}
          className={clsx(s.scrollButton, s.scrollForward)}
          children={<ArrowForwardOutline />}
          size="large"
        />
      )}
    </Box>
  );
}
