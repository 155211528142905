import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpsertBillingAddressMutation } from "./__generated__/UpsertBillingAddressMutation.graphql";

const mutation = graphql`
  mutation UpsertBillingAddressMutation($input: UpsertBillingAddressInput!) {
    upsertBillingAddress(input: $input) {
      billingAddress {
        ...ProUpgradeCard_billingAddress
        ...ManagePlanCard_billingAddress
      }
    }
  }
`;

export const useUpsertBillingAddressMutation = () => {
  return useConfiguredMutation<UpsertBillingAddressMutation>(
    mutation,
    (config) => ({}),
  );
};
