import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  Typography,
  lighten,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { ArrowDropDown } from "@mui/icons-material";

import { ReactComponent as CalendarDots } from "../../icons/CalendarDots.svg";
import { MAX_WEEK_RANGE } from "../../routes/coach/program/curriculum/CoachProgramCurriculumRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: lighten(theme.palette.text.secondary, 0.8),
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.75, 2),
    borderRadius: theme.spacing(0.5),
  },

  text: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export enum ProgramStartDateView {
  ANY = "any",
  CLIENT = "client",
  SPECIFIC = "specific",
}

export interface ProgramWeekRangeDetails {
  startNumber: number;
  endNumber: number;
}

export interface ProgramWeekRangeSelectorProps
  extends Omit<ButtonProps, "value" | "onChange"> {
  value?: ProgramWeekRangeDetails;
  onChange?: (value: ProgramWeekRangeDetails) => void;
  programLength: number;
}

export function ProgramWeekRangeSelector(props: ProgramWeekRangeSelectorProps) {
  const {
    className,
    value = { startNumber: 1, endNumber: 3 },
    onChange = () => {},
    programLength = 3,
    ...other
  } = props;
  const s = useStyles();

  const startMenuState = usePopupState({
    variant: "popover",
    popupId: "program-start-number",
  });
  const endMenuState = usePopupState({
    variant: "popover",
    popupId: "program-end-number",
  });

  const handleStartNumberChange = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      startMenuState.close();

      const {
        dataset: { start },
      } = event.currentTarget;
      const limitedEndValue = Number(start) + MAX_WEEK_RANGE - 1;

      onChange({
        startNumber: Number(start),
        endNumber:
          Number(start) > value.endNumber || limitedEndValue < value.endNumber
            ? limitedEndValue
            : value.endNumber,
      });
    },
    [startMenuState, onChange],
  );

  const handleEndNumberChange = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      endMenuState.close();

      const {
        dataset: { end },
      } = event.currentTarget;
      const limitedStartValue = Number(end) - MAX_WEEK_RANGE + 1;

      onChange({
        startNumber:
          Number(end) < value.startNumber ||
          limitedStartValue > value.startNumber
            ? limitedStartValue
            : value.startNumber,
        endNumber: Number(end),
      });
    },
    [endMenuState, onChange],
  );

  return (
    <>
      <Box>
        <Typography className={s.text} component="span" pr={1}>
          Week
        </Typography>
        <Button
          className={clsx(s.root, className)}
          startIcon={<CalendarDots />}
          endIcon={<ArrowDropDown />}
          children={Math.min(value.startNumber, programLength)}
          {...bindTrigger(startMenuState)}
          {...other}
        />
        <Typography className={s.text} component="span" px={0.5}>
          -
        </Typography>
        <Button
          className={clsx(s.root, className)}
          startIcon={<CalendarDots />}
          endIcon={<ArrowDropDown />}
          children={Math.min(value.endNumber, programLength)}
          {...bindTrigger(endMenuState)}
          {...other}
        />
      </Box>

      <Menu {...bindMenu(startMenuState)}>
        {[...Array(programLength)].map((_, index) => (
          <MenuItem
            key={index}
            data-start={index + 1}
            selected={Number(index + 1) === value.startNumber}
            onClick={handleStartNumberChange}
            children={<Typography className={s.text} children={index + 1} />}
          />
        ))}
      </Menu>

      <Menu {...bindMenu(endMenuState)}>
        {[...Array(programLength)].map((_, index) => (
          <MenuItem
            key={index}
            data-end={index + 1}
            selected={Number(index + 1) === value.endNumber}
            onClick={handleEndNumberChange}
            children={<Typography className={s.text} children={index + 1} />}
          />
        ))}
      </Menu>
    </>
  );
}
