/**
 * @generated SignedSource<<fc0e3eff0a487bd15c152be4456d16a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentPreview_component$data = {
  readonly image: string;
  readonly teaser: string | null | undefined;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentIcon_component">;
  readonly " $fragmentType": "ComponentPreview_component";
};
export type ComponentPreview_component$key = {
  readonly " $data"?: ComponentPreview_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentPreview_component">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "draft",
    "variableName": "draft"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "draft"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ComponentPreview_component",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "teaser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentIcon_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "6f45a64e43d1636e8c9a5ff068513b1f";

export default node;
