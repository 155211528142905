import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { AdminUserRouteQuery } from "./__generated__/AdminUserRouteQuery.graphql";
import { UserPage } from "../../../components/admin/UserPage";
import { useParams } from "react-router-dom";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";

export function AdminUserRoute() {
  const { username } = useParams();
  const props = useLazyLoadQuery<AdminUserRouteQuery>(
    graphql`
      query AdminUserRouteQuery($username: String!) {
        user(username: $username) {
          email
          role
          ...UserPage_user
        }
      }
    `,
    {
      username: username,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { user } = props;

  return (
    <>
      <UserPage user={user} />
      <TrackInfoTool
        trackInfo={{
          name: `Admin - User - ${user.email} (${user.role})`,
        }}
      />
    </>
  );
}
