/**
 * @generated SignedSource<<f7c0f9294f920336302fc32f7f9fd78f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type CoachSettingsIntegrationRouteQuery$variables = Record<PropertyKey, never>;
export type CoachSettingsIntegrationRouteQuery$data = {
  readonly me: {
    readonly subscription: {
      readonly plan: Plan | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type CoachSettingsIntegrationRouteQuery = {
  response: CoachSettingsIntegrationRouteQuery$data;
  variables: CoachSettingsIntegrationRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachSettingsIntegrationRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoachSettingsIntegrationRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf7c91b3015b026fcc9e594b4a5528cd",
    "id": null,
    "metadata": {},
    "name": "CoachSettingsIntegrationRouteQuery",
    "operationKind": "query",
    "text": "query CoachSettingsIntegrationRouteQuery {\n  me {\n    subscription {\n      plan\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5292b9ed21202b5ea6bf4d6d06d8d5b6";

export default node;
