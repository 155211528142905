/**
 * @generated SignedSource<<e47e2a3a5a31e40512d0b8286d51d287>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientWorkoutsExercisesWorkoutsQuery$variables = {
  after?: string | null | undefined;
  clientId?: string | null | undefined;
  first: number;
};
export type ClientWorkoutsExercisesWorkoutsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClientWorkouts_data">;
};
export type ClientWorkoutsExercisesWorkoutsQuery = {
  response: ClientWorkoutsExercisesWorkoutsQuery$data;
  variables: ClientWorkoutsExercisesWorkoutsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "clientId",
    "variableName": "clientId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientWorkoutsExercisesWorkoutsQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "ClientWorkouts_data"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientWorkoutsExercisesWorkoutsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "WorkoutExerciseResultConnection",
        "kind": "LinkedField",
        "name": "workoutExerciseResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkoutExerciseResultEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkoutExerciseResult",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Activity",
                    "kind": "LinkedField",
                    "name": "activity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Component",
                        "kind": "LinkedField",
                        "name": "component",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "content",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endWorkout",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "format",
                            "value": "MMM D.YY"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": "completedAt(format:\"MMM D.YY\")"
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ClientWorkouts_workoutExerciseResults",
        "kind": "LinkedHandle",
        "name": "workoutExerciseResults"
      }
    ]
  },
  "params": {
    "cacheID": "faecdf576b8e439013021e7ea49b14a1",
    "id": null,
    "metadata": {},
    "name": "ClientWorkoutsExercisesWorkoutsQuery",
    "operationKind": "query",
    "text": "query ClientWorkoutsExercisesWorkoutsQuery(\n  $first: Int!\n  $clientId: ID\n  $after: String\n) {\n  ...ClientWorkouts_data_2tUDQX\n}\n\nfragment ClientWorkouts_data_2tUDQX on Root {\n  workoutExerciseResults(first: $first, after: $after, clientId: $clientId) {\n    edges {\n      node {\n        id\n        activity {\n          component {\n            title\n            content\n            id\n          }\n          endWorkout\n          completedAt(format: \"MMM D.YY\")\n          id\n        }\n        value\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5003321281d58b4abd78aa5413154633";

export default node;
