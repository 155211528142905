import React, { useState } from "react";

import { withRef } from "@udecode/cn";
import { PlateElement, findNodePath } from "@udecode/plate-common";

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxInput,
  InlineComboboxItem,
  defaultFilter,
} from "./inline-combobox";
import { IMenuSection } from "../new-editor/utils/menuSectionsUtil";
import { Box } from "@mui/material";
import { InsertElementMenuItem } from "./insert-element-menu-item";
import { InsertElementMenuGroupHeader } from "./insert-element-menu-group-header";
import { insertElementAt } from "../new-editor/utils/editorUtils";
import { Path } from "slate";
import { useItemDisabled } from "../new-editor/hooks";

interface DraggableProps {
  generalDropDownMenuItems: IMenuSection[];
}

export const SlashInputElement = withRef<typeof PlateElement, DraggableProps>(
  ({ className, ...props }, ref) => {
    const { children, editor, element, generalDropDownMenuItems } = props;

    const path = findNodePath(editor, element);
    const [value, setValue] = useState("");

    const isItemDisabled = useItemDisabled();

    const handleMenuItemClick = async (type: string) => {
      insertElementAt(type, editor, Path.next(Path.parent(path)));
    };

    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        ref={ref}
        {...props}
      >
        <InlineCombobox
          element={element}
          trigger="/"
          setValue={setValue}
          value={value}
        >
          <InlineComboboxInput />

          <InlineComboboxContent className="max-h-[400px] w-auto">
            <InlineComboboxEmpty>
              No matching commands found
            </InlineComboboxEmpty>

            {generalDropDownMenuItems
              .filter((item) =>
                item.items.some((item) =>
                  defaultFilter(
                    { value: item.label, keywords: [item.value] },
                    value,
                  ),
                ),
              )
              .map(({ items: nestedItems, label }) => (
                <Box key={label}>
                  <InsertElementMenuGroupHeader groupLabel={label} />

                  <Box sx={{ py: 1 }}>
                    {nestedItems.map(
                      (
                        {
                          value: type,
                          label: itemLabel,
                          icon: Icon,
                          description: itemDescription,
                        },
                        index,
                      ) => (
                        <InlineComboboxItem
                          key={index}
                          keywords={[type]}
                          onClick={() => handleMenuItemClick(type)}
                          value={itemLabel}
                          className="h-auto py-0"
                          disabled={isItemDisabled(type)}
                        >
                          <InsertElementMenuItem
                            itemLabel={itemLabel}
                            itemDescription={itemDescription}
                            Icon={Icon}
                          />
                        </InlineComboboxItem>
                      ),
                    )}
                  </Box>
                </Box>
              ))}
          </InlineComboboxContent>
        </InlineCombobox>

        {children}
      </PlateElement>
    );
  },
);
