import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import {
  ForgotPasswordFormProps,
  ForgotPasswordForm,
} from "./ForgotPasswordForm";

const resetPasswordMutation = graphql`
  mutation ForgotPasswordMutation($email: String!, $workspaceSlug: String) {
    resetPassword(email: $email, workspaceSlug: $workspaceSlug)
  }
`;

type State = {
  email: string;
  error?: string;
  sent: boolean;
};

type Props = {
  className?: string;
  email: string;
  workspaceSlug?: string;
  component?: React.FC<ForgotPasswordFormProps>;
  onCancel?: ForgotPasswordFormProps["onCancel"];
};

export function ForgotPassword(props: Props) {
  const {
    className,
    email,
    workspaceSlug,
    onCancel,
    component: Form = ForgotPasswordForm,
  } = props;
  const [state, setState] = React.useState<State>({
    email: email || "",
    error: null,
    sent: false,
  });
  const [resetPassword, loading] = useMutation(resetPasswordMutation);

  function handleChange(event) {
    const { name, value } = event.target;
    setState((x) => (x.error === null ? x : { ...x, error: null }));
    setState((x) => (x[name] === value ? x : { ...x, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetPassword({
      variables: { email: state.email, workspaceSlug },
      onCompleted(_, errors) {
        if (errors) {
          setState((x) => ({ ...x, error: errors[0].message }));
        } else {
          setState((x) => ({ ...x, sent: true }));
        }
      },
    });
  }

  function reset() {
    setState((x) => ({ ...x, sent: false }));
  }

  return (
    <Form
      className={className}
      state={state}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      onResend={reset}
      loading={loading}
    />
  );
}
