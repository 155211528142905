/**
 * @generated SignedSource<<de13ea069c87ea150d855c5ad473e50b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWeekComponentsOrderInput = {
  clientMutationId?: string | null | undefined;
  positions: ReadonlyArray<string>;
  programId: string;
  week: number;
};
export type ProgramDetailsUpdateOrderMutation$variables = {
  input: UpdateWeekComponentsOrderInput;
};
export type ProgramDetailsUpdateOrderMutation$data = {
  readonly updateWeekComponentsOrder: {
    readonly week: {
      readonly positions: ReadonlyArray<string>;
    };
  } | null | undefined;
};
export type ProgramDetailsUpdateOrderMutation = {
  response: ProgramDetailsUpdateOrderMutation$data;
  variables: ProgramDetailsUpdateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "positions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramDetailsUpdateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeekComponentsOrderPayload",
        "kind": "LinkedField",
        "name": "updateWeekComponentsOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramDetailsUpdateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeekComponentsOrderPayload",
        "kind": "LinkedField",
        "name": "updateWeekComponentsOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b45c08456de4001279dd1681986c7c1e",
    "id": null,
    "metadata": {},
    "name": "ProgramDetailsUpdateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation ProgramDetailsUpdateOrderMutation(\n  $input: UpdateWeekComponentsOrderInput!\n) {\n  updateWeekComponentsOrder(input: $input) {\n    week {\n      positions\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "696da4e48e9fe5efda7578f3de249e78";

export default node;
