import React, { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

import { ColumnField } from "../utils";
import { RepsValuesInfoSection } from "./info-sections/reps/RepsValuesInfoSection";
import { RepsSetCustomizationInfoSection } from "./info-sections/reps/RepsSetCustomizationInfoSection";
import { WeightTypesInfoSection } from "./info-sections/weight/WeightTypesInfoSection";
import { WeightSetCustomizationInfoSection } from "./info-sections/weight/WeightSetCustomizationInfoSection";
import { WeightValuesInfoSection } from "./info-sections/weight/WeightValuesInfoSection";
import { ExtraMeasurementSetCustomizationInfoSection } from "./info-sections/extra-measurement/ExtraMeasurementSetCustomizationInfoSection";
import { ExtraMeasurementTypesInfoSection } from "./info-sections/extra-measurement/ExtraMeasurementTypesInfoSection";
import { ExtraMeasurementValuesInfoSection } from "./info-sections/extra-measurement/ExtraMeasurementValuesInfoSection";

export interface ColumnInstructionsProps {
  columnField: ColumnField;
}

export interface ColumnInstructionsData {
  title: string;
  sections: Array<ReactNode>;
}

const instructions: { [key in ColumnField]?: ColumnInstructionsData } = {
  [ColumnField.REPS]: {
    title: "Reps Guide",
    sections: [<RepsValuesInfoSection />, <RepsSetCustomizationInfoSection />],
  },
  [ColumnField.WEIGHT]: {
    title: "Measurement Guide",
    sections: [
      <WeightTypesInfoSection />,
      <WeightValuesInfoSection />,
      <WeightSetCustomizationInfoSection />,
    ],
  },
  [ColumnField.EXTRA_MEASUREMENT]: {
    title: "RPE / RIR Guide",
    sections: [
      <ExtraMeasurementTypesInfoSection />,
      <ExtraMeasurementValuesInfoSection />,
      <ExtraMeasurementSetCustomizationInfoSection />,
    ],
  },
};

export function ColumnInstructions({ columnField }: ColumnInstructionsProps) {
  const { title, sections } = instructions[columnField];

  return (
    <Stack spacing={3} mb={2}>
      <Typography variant="h5">{title}</Typography>

      <Stack spacing={4}>
        {sections.map((section, index) => (
          <React.Fragment key={index}>{section}</React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
