/**
 * @generated SignedSource<<5e95ba6014e158406cee31d38efe6eb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnsplashImage_image$data = {
  readonly authorName: string;
  readonly authorUrl: string;
  readonly description: string;
  readonly height: number;
  readonly id: string;
  readonly unsplashImageId: string;
  readonly urls: {
    readonly regular: string;
    readonly thumb: string;
  };
  readonly width: number;
  readonly " $fragmentType": "UnsplashImage_image";
};
export type UnsplashImage_image$key = {
  readonly " $data"?: UnsplashImage_image$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnsplashImage_image">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnsplashImage_image",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unsplashImageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnsplashImageUrls",
      "kind": "LinkedField",
      "name": "urls",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "regular",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumb",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UnsplashImage",
  "abstractKey": null
};

(node as any).hash = "9460c2ac872cc14f4f8d0db448c4e8ec";

export default node;
