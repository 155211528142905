import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { useSnackAlert } from "../../../hooks/useSnackAlert";

import { RestoreProgramMutation } from "./__generated__/RestoreProgramMutation.graphql";

const mutation = graphql`
  mutation RestoreProgramMutation($input: RestoreProgramInput!) {
    restoreProgram(input: $input) {
      programId
    }
  }
`;

export const useRestoreProgramMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<RestoreProgramMutation>(mutation, () => ({
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: "client:root",
        connectionKeys: [
          {
            key: "ProgramsList_programs",
          },
        ],
        pathToConnection: ["client:root", "programs"],
        deletedIDFieldName: "programId",
      },
    ],

    onSuccess: () =>
      snackAlert({
        severity: "success",
        message: "Program restored.",
      }),
  }));
};
