import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TrainingSummaryExercise } from "./TrainingSummaryExercise";
import { TrainingSummaryWellBegin } from "./TrainingSummaryWellBegin";
import { graphql } from "react-relay";
import { TrainingSummary_activity$key } from "./__generated__/TrainingSummary_activity.graphql";
import { useFragment } from "react-relay/hooks";
import { Node } from "slate";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => {
  return {
    root: {
      paddingTop: spacing(7),
      paddingBottom: spacing(2),
      maxWidth: breakpoints.values.slg,
    },
  };
});

const activityFragment = graphql`
  fragment TrainingSummary_activity on Activity {
    id
    clientId
    content
    startWorkout
    endWorkout
  }
`;

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activityRef: TrainingSummary_activity$key;
  coach?: boolean;
}

export function TrainingSummary(props: ActivityProps) {
  const { activityRef } = props;
  const s = useStyles();

  const activity: any = useFragment(activityFragment, activityRef);

  const originalContent: Node[] = React.useMemo(
    () => JSON.parse(activity.content) || [],
    [activity.content],
  );

  const startWorkout: Node[] = React.useMemo(
    () => JSON.parse(activity.startWorkout) || [],
    [activity.startWorkout],
  );
  const endWorkout: Node[] = React.useMemo(
    () => JSON.parse(activity.endWorkout) || [],
    [activity.endWorkout],
  );

  const workoutSection = React.useMemo(() => {
    return originalContent.filter((i: any) => i.type === "workout_section");
  }, [originalContent]);

  return (
    <Container className={clsx(s.root)}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {workoutSection.map(({ workout }: any) =>
            workout.exercises.map((exercise, index) => (
              <TrainingSummaryExercise
                key={index}
                exercise={exercise}
                clientId={activity?.clientId}
              />
            )),
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TrainingSummaryWellBegin
            startWorkout={startWorkout}
            endWorkout={endWorkout}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
