/**
 * @generated SignedSource<<3b1a0bd67930a4f93c6e25308b333b2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInviteCodeStartDateInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  startDate?: string | null | undefined;
};
export type UpdateInviteCodeStartDateMutation$variables = {
  input: UpdateInviteCodeStartDateInput;
};
export type UpdateInviteCodeStartDateMutation$data = {
  readonly updateInviteCodeStartDate: {
    readonly inviteCode: {
      readonly id: string;
      readonly startDate: string | null | undefined;
    };
  } | null | undefined;
};
export type UpdateInviteCodeStartDateMutation = {
  response: UpdateInviteCodeStartDateMutation$data;
  variables: UpdateInviteCodeStartDateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateInviteCodeStartDatePayload",
    "kind": "LinkedField",
    "name": "updateInviteCodeStartDate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InviteCode",
        "kind": "LinkedField",
        "name": "inviteCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateInviteCodeStartDateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateInviteCodeStartDateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e11b3deb33d8d4d7ef3646a708635c28",
    "id": null,
    "metadata": {},
    "name": "UpdateInviteCodeStartDateMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateInviteCodeStartDateMutation(\n  $input: UpdateInviteCodeStartDateInput!\n) {\n  updateInviteCodeStartDate(input: $input) {\n    inviteCode {\n      id\n      startDate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dceeb3c72edadd6514825a9a8a83f742";

export default node;
