import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachClientsListScreen } from "../../../../components/coach-clients/CoachClientsListScreen";
import {
  ClientStatusFilter,
  CoachClientsListRouteQuery,
} from "./__generated__/CoachClientsListRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import { CoachClientFiltersContext } from "../../../../contexts/CoachClientFiltersContext";

interface CoachClientsListRouteProps {
  status: string[];
}

export function CoachClientsListRoute({ status }: CoachClientsListRouteProps) {
  const filterContext = useContext(CoachClientFiltersContext);

  const props = useLazyLoadQuery<CoachClientsListRouteQuery>(
    graphql`
      query CoachClientsListRouteQuery(
        $status: [ClientStatusFilter!]
        $sort: ClientSortBy!
        $tag: ID
      ) {
        ...CoachClientsListScreen_root
          @arguments(status: $status, sort: $sort, tagId: $tag)

        ...CoachClientsListScreen_invitedUsers
        ...CoachClientsListScreen_addedUsers

        user: me {
          ...CoachClientsListScreen_user
        }
      }
    `,
    {
      sort: filterContext.sortKey ?? "NAME_ASC",
      status: status,
      tag: !status.includes("ACTIVE") ? "" : filterContext.filterTag ?? "",
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  return (
    <>
      <CoachClientsListScreen
        root={props}
        invitedUsers={props}
        addedUsers={props}
        user={props?.user || null}
        status={status as [ClientStatusFilter]}
      />
    </>
  );
}

export const CoachClientsActiveRoute = () => {
  return (
    <>
      <CoachClientsListRoute status={["ACTIVE"]} />
    </>
  );
};

export const CoachClientsPendingRoute = () => {
  return (
    <>
      <CoachClientsListRoute status={["PENDING", "ADDED"]} />
    </>
  );
};

export const CoachClientsArchivedRoute = () => {
  return (
    <>
      <CoachClientsListRoute status={["ARCHIVED"]} />
    </>
  );
};
