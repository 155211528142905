import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Typography } from "@mui/material";
import { graphql, useFragment } from "react-relay";
import makeStyles from "@mui/styles/makeStyles";

import { SettingsCard } from "../card/SettingsCard";
import { ColorPickerGroup } from "../fields/ColorPickerGroup";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";

import { CoachThemeSettingsScreen_workspace$key } from "./__generated__/CoachThemeSettingsScreen_workspace.graphql";
import { useUpdateThemeMutation } from "./mutations/UpdateThemeMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  label: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}));

const workspaceFragment = graphql`
  fragment CoachThemeSettingsScreen_workspace on Workspace {
    primaryColor
  }
`;

export interface CoachThemeSettingsScreenProps
  extends Omit<ContainerProps, "children"> {
  workspace: CoachThemeSettingsScreen_workspace$key;
}

export function CoachThemeSettingsScreen(props: CoachThemeSettingsScreenProps) {
  const { className, workspace: workspaceRef, ...other } = props;
  const workspace = useFragment(workspaceFragment, workspaceRef);
  const s = useStyles();
  const [dirty, setDirty] = React.useState(false);
  const currentThemeOptions = {
    primaryColor: workspace.primaryColor,
  };
  const [themeOptions, setThemeOptions] = React.useState(currentThemeOptions);
  const [updateTheme, pending] = useUpdateThemeMutation();

  const handlePrimaryChange = React.useCallback(
    (primaryColor) => {
      setThemeOptions({
        ...themeOptions,
        primaryColor,
      });
      setDirty(true);
    },
    [setThemeOptions, themeOptions],
  );

  const handleSave = React.useCallback(() => {
    updateTheme({
      variables: {
        input: themeOptions,
      },
      onSuccess: () => {
        setDirty(false);
      },
    });
  }, [themeOptions, updateTheme]);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Theme settings"
        subheader="Change the appearance of what your clients see when they log in"
        ButtonProps={{
          disabled: !dirty || pending,
          onClick: handleSave,
        }}
      >
        <Typography variant="h4" className={s.label}>
          Primary colour
        </Typography>

        <ColorPickerGroup
          value={themeOptions.primaryColor}
          onChange={handlePrimaryChange}
          disabled={pending}
        />
      </SettingsCard>
    </Container>
  );
}
