import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { SelectorStoreUpdater, ConnectionHandler } from "relay-runtime";
import { useFragment, useMutation } from "react-relay/hooks";
import { capitalize } from "lodash";

import { DatePicker } from "../fields/DatePicker";
import { EnrollmentSort, SOMETHING_WENT_WRONG } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { ClientAddableItem_client$data } from "../item/__generated__/ClientAddableItem_client.graphql";
import { GroupAddableItem_group$data } from "../item/__generated__/GroupAddableItem_group.graphql";
import { getISODate } from "../../utils/date";
import { EnrollmentSelected } from "../groups/EnrollDialog";

import { EnrollDateDialog_program$key } from "./__generated__/EnrollDateDialog_program.graphql";
import { BaseDialog } from "./BaseDialog";
import { useParams, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

const programFragment = graphql`
  fragment EnrollDateDialog_program on Program {
    id
  }
`;

const enrollClientsMutation = graphql`
  mutation EnrollDateDialogEnrollClientsMutation($input: EnrollClientsInput!) {
    enrollClients(input: $input) {
      enrollments {
        id
        ...EnrolledAvatar_enrollment
        ...EnrollmentCard_enrollment
      }
    }
  }
`;

const enrollGroupsMutation = graphql`
  mutation EnrollDateDialogEnrollGroupsMutation($input: EnrollGroupsInput!) {
    enrollGroups(input: $input) {
      enrollments {
        id
        ...EnrolledAvatar_enrollment
        ...EnrollmentCard_enrollment
      }
    }
  }
`;

export interface EnrollDateDialogProps extends DialogProps {
  programRef: EnrollDateDialog_program$key;
  selected: EnrollmentSelected;
}

export function EnrollDateDialog(props: EnrollDateDialogProps) {
  const { className, onClose, programRef, selected, ...other } = props;
  const s = useStyles();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("sort");
  const program = useFragment(programFragment, programRef);
  const [startDate, setStartDate] = React.useState(new Date());
  const [enrollClients, enrollingClients] = useMutation(enrollClientsMutation);
  const [enrollGroups, enrollingGroups] = useMutation(enrollGroupsMutation);
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();

  const items = React.useMemo(
    () => selected.clients || selected.groups,
    [selected],
  );
  const label = React.useMemo(
    () =>
      `${selected.clients ? "client" : "group"}${
        items.length === 1 ? "" : "s"
      }`,
    [items.length, selected.clients],
  );

  const handleDateChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setStartDate(new Date());
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const variables = React.useMemo(
    () => ({
      input: {
        programId: program.id,
        startDate: getISODate(startDate),
        ...(selected.clients
          ? {
              emails: (items as ClientAddableItem_client$data[]).map(
                ({ email }) => email,
              ),
            }
          : {
              groupsIds: (items as GroupAddableItem_group$data[]).map(
                ({ id }) => id,
              ),
            }),
      },
    }),
    [items, program.id, selected.clients, startDate],
  );

  const onCompleted = React.useCallback(
    (_data, errors) => {
      if (errors?.length) {
        snackAlert({
          severity: "error",
          message: errors[0].message || SOMETHING_WENT_WRONG,
        });
        console.error(errors[0]);
      } else {
        handleClose();
        snackAlert({
          severity: "success",
          message: `${capitalize(label)} enrolled.`,
        });
      }
    },
    [handleClose, label, snackAlert],
  );

  const updater: SelectorStoreUpdater = React.useCallback(
    (store) => {
      const enrollmentsAvatars = ConnectionHandler.getConnection(
        store.getRoot(),
        "Program_enrollmentsAvatars",
        [],
      );
      const enrollments = ConnectionHandler.getConnection(
        store.getRoot(),
        "Program_enrollments",
        {
          first: null,
          after: null,
          orderBy: filter ?? EnrollmentSort.CREATED_AT_ASC,
          programSlug: slug,
        },
      );
      const field = selected.clients ? "enrollClients" : "enrollGroups";
      const newEnrollments = store
        .getRootField(field)
        ?.getLinkedRecords("enrollments");

      // TODO: Refactor so that a single connection is used
      if (enrollmentsAvatars && enrollments && newEnrollments) {
        for (const enrollment of newEnrollments) {
          const enrollmentsAvatarsEdge = ConnectionHandler.createEdge(
            store,
            enrollmentsAvatars,
            enrollment,
            "EnrollmentEdge",
          );
          ConnectionHandler.insertEdgeBefore(
            enrollmentsAvatars,
            enrollmentsAvatarsEdge,
          );

          const enrollmentsEdge = ConnectionHandler.createEdge(
            store,
            enrollments,
            enrollment,
            "EnrollmentEdge",
          );
          ConnectionHandler.insertEdgeBefore(enrollments, enrollmentsEdge);
        }

        const totalCount = enrollmentsAvatars.getValue("totalCount") as number;
        enrollmentsAvatars.setValue(
          totalCount + newEnrollments.length,
          "totalCount",
        );
        enrollments.setValue(totalCount + newEnrollments.length, "totalCount");
      }
    },
    [selected.clients],
  );

  const mutationConfig = React.useMemo(
    () => ({
      variables,
      onCompleted,
      onError,
      updater,
    }),
    [variables, onCompleted, onError, updater],
  );

  const handleEnrollClick = React.useCallback(() => {
    if (selected.clients) {
      enrollClients(mutationConfig);
    } else {
      enrollGroups(mutationConfig);
    }
  }, [enrollClients, enrollGroups, mutationConfig, selected.clients]);

  const disabled = !items.length || enrollingClients || enrollingGroups;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Choose start time"
      subtitle={`Select when ${label} should start on the program.`}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={`Enroll ${items.length} ${label}`}
        onClick={handleEnrollClick}
        disabled={disabled}
      />
    </BaseDialog>
  );
}
