/**
 * @generated SignedSource<<abf67669323a403f6e9037b95db2dc22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientActivityRouteQuery$variables = Record<PropertyKey, never>;
export type ClientActivityRouteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClientActivityScreen_root">;
};
export type ClientActivityRouteQuery = {
  response: ClientActivityRouteQuery$data;
  variables: ClientActivityRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v1 = [
  {
    "kind": "Literal",
    "name": "client",
    "value": "$me"
  },
  (v0/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "COMPLETED_AT_DESC"
  },
  {
    "kind": "Literal",
    "name": "withAnswersOnly",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "withClientForms",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "withMealLogging",
    "value": true
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "fromNow"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientActivityRouteQuery",
    "selections": [
      {
        "args": [
          (v0/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ClientActivityScreen_root"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientActivityRouteQuery",
    "selections": [
      {
        "alias": "activities",
        "args": (v1/*: any*/),
        "concreteType": "ActivityConnection",
        "kind": "LinkedField",
        "name": "activitiesConn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "raw",
                        "value": true
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": "date(raw:true)"
                  },
                  {
                    "alias": "dateFormatted",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "ddd, MMM DD"
                      },
                      {
                        "kind": "Literal",
                        "name": "utc",
                        "value": true
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": "date(format:\"ddd, MMM DD\",utc:true)"
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": "completedAt(format:\"fromNow\")"
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "kind": "ScalarField",
                    "name": "submittedAt",
                    "storageKey": "submittedAt(format:\"fromNow\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edited",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startWorkout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endWorkout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Enrollment",
                    "kind": "LinkedField",
                    "name": "enrollment",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Program",
                        "kind": "LinkedField",
                        "name": "program",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Component",
                    "kind": "LinkedField",
                    "name": "component",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientMetric",
                    "kind": "LinkedField",
                    "name": "metrics",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "formattedValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "type": "CheckinAnswerFileValue",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityFeedback",
                    "kind": "LinkedField",
                    "name": "feedbacks",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "read",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v4/*: any*/),
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": "createdAt(format:\"fromNow\")"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkoutExerciseResult",
                    "kind": "LinkedField",
                    "name": "workoutExerciseResults",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientForm",
                    "kind": "LinkedField",
                    "name": "clientForm",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coachArchived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coachReadAnswers",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "activitiesConn(client:\"$me\",first:10,orderBy:\"COMPLETED_AT_DESC\",withAnswersOnly:true,withClientForms:true,withMealLogging:true)"
      },
      {
        "alias": "activities",
        "args": (v1/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CoachActivity_activities",
        "kind": "LinkedHandle",
        "name": "activitiesConn"
      }
    ]
  },
  "params": {
    "cacheID": "487e03c0981f43afbfedbd31339da479",
    "id": null,
    "metadata": {},
    "name": "ClientActivityRouteQuery",
    "operationKind": "query",
    "text": "query ClientActivityRouteQuery {\n  ...ClientActivityScreen_root_3dCm89\n}\n\nfragment ActivityResponseCard_activity on Activity {\n  id\n  type\n  date(raw: true)\n  dateFormatted: date(utc: true, format: \"ddd, MMM DD\")\n  completedAt(format: \"fromNow\")\n  submittedAt(format: \"fromNow\")\n  answers\n  edited\n  startWorkout\n  endWorkout\n  enrollment {\n    program {\n      name\n      slug\n      id\n    }\n    id\n  }\n  client {\n    displayName\n    photoURL\n    username\n    id\n  }\n  component {\n    type\n    title\n    slug\n    id\n  }\n  metrics {\n    id\n    type\n    name\n    formattedValue\n    value {\n      __typename\n      ... on CheckinAnswerFileValue {\n        url\n        name\n        type\n      }\n    }\n  }\n  feedbacks {\n    id\n    ...ActivityResponseItem_feedback\n  }\n  workoutExerciseResults {\n    ...ActivityWorkoutResponse_workoutExerciseResult\n    id\n  }\n  clientForm {\n    slug\n    id\n  }\n  coachArchived\n  coachReadAnswers\n  ...FeedbackForm_activity\n  ...ActivityResponseMealsList_activity\n}\n\nfragment ActivityResponseItem_feedback on ActivityFeedback {\n  id\n  author {\n    id\n    role\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ActivityResponseMealsList_activity on Activity {\n  content\n}\n\nfragment ActivityWorkoutResponse_workoutExerciseResult on WorkoutExerciseResult {\n  id\n  value\n}\n\nfragment ClientActivityScreen_root_3dCm89 on Root {\n  activities: activitiesConn(client: \"$me\", first: 10, withAnswersOnly: true, withMealLogging: true, withClientForms: true, orderBy: COMPLETED_AT_DESC) {\n    totalCount\n    edges {\n      node {\n        ...ActivityResponseCard_activity\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fa0fea5fbe1498f6e05af60cab37d59";

export default node;
