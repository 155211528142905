/**
 * @generated SignedSource<<d8c13e93de36939d2c2612d59226531b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertActivityFeedbackInput = {
  activityId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  id?: string | null | undefined;
};
export type FeedbackFormSubmitMutation$variables = {
  input: UpsertActivityFeedbackInput;
};
export type FeedbackFormSubmitMutation$data = {
  readonly upsertActivityFeedback: {
    readonly activityFeedback: {
      readonly activityId: string;
      readonly " $fragmentSpreads": FragmentRefs<"ActivityFeedback_activityFeedback" | "ActivityResponseItem_feedback">;
    } | null | undefined;
  } | null | undefined;
};
export type FeedbackFormSubmitMutation = {
  response: FeedbackFormSubmitMutation$data;
  variables: FeedbackFormSubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackFormSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityFeedbackPayload",
        "kind": "LinkedField",
        "name": "upsertActivityFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityFeedback",
            "kind": "LinkedField",
            "name": "activityFeedback",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityResponseItem_feedback"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityFeedback_activityFeedback"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedbackFormSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityFeedbackPayload",
        "kind": "LinkedField",
        "name": "upsertActivityFeedback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityFeedback",
            "kind": "LinkedField",
            "name": "activityFeedback",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "read",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "fromNow"
                  }
                ],
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": "createdAt(format:\"fromNow\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c6235f2813b6a341641a41e9fcecf1b8",
    "id": null,
    "metadata": {},
    "name": "FeedbackFormSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackFormSubmitMutation(\n  $input: UpsertActivityFeedbackInput!\n) {\n  upsertActivityFeedback(input: $input) {\n    activityFeedback {\n      activityId\n      ...ActivityResponseItem_feedback\n      ...ActivityFeedback_activityFeedback\n      id\n    }\n  }\n}\n\nfragment ActivityFeedback_activityFeedback on ActivityFeedback {\n  id\n  author {\n    id\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ActivityResponseItem_feedback on ActivityFeedback {\n  id\n  author {\n    id\n    role\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n"
  }
};
})();

(node as any).hash = "4355eddbfe45e6392de05e21d09ef004";

export default node;
