import React from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { SkeletonCard } from "../../components/card/SkeletonCard";
import { Pagination } from "../../components/pagination/Pagination";
import { GroupEnrollmentCard } from "../../components/groups/GroupEnrollmentCard";
import { PaginationContext } from "../../utils/paging";

import { GroupEnrollmentList_groupEnrollments$key } from "./__generated__/GroupEnrollmentList_groupEnrollments.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

const groupEnrollmentsFragment = graphql`
  fragment GroupEnrollmentList_groupEnrollments on GroupEnrollmentConnection {
    totalCount
    edges {
      node {
        id
        ...GroupEnrollmentCard_groupEnrollment
      }
    }
  }
`;

export interface GroupEnrollmentListProps {
  groupEnrollmentsRef: GroupEnrollmentList_groupEnrollments$key;
}

export function GroupEnrollmentList(props: GroupEnrollmentListProps) {
  const { groupEnrollmentsRef } = props;
  const s = useStyles();
  const { first } = React.useContext(PaginationContext);

  const groupEnrollments = useFragment(
    groupEnrollmentsFragment,
    groupEnrollmentsRef,
  );

  return groupEnrollments ? (
    <Container className={s.root} maxWidth="md">
      {groupEnrollments.edges.length > 0 &&
        groupEnrollments.edges.map(({ node }) => (
          <GroupEnrollmentCard
            key={node.id}
            className={s.card}
            groupEnrollmentRef={node}
          />
        ))}

      <Pagination totalCount={groupEnrollments.totalCount} perPage={first} />
    </Container>
  ) : (
    <Container className={s.root} maxWidth="md">
      {Array.from({ length: 3 }).map((_, i) => (
        <SkeletonCard key={i} className={s.card} />
      ))}
    </Container>
  );
}
