import React from "react";
import { graphql } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { PaginationContext } from "../../../utils/paging";

import { UpsertGroupMutation } from "./__generated__/UpsertGroupMutation.graphql";

export type UpsertedGroup =
  UpsertGroupMutation["response"]["upsertGroup"]["group"];

const mutation = graphql`
  mutation UpsertGroupMutation($input: UpsertGroupInput!) {
    upsertGroup(input: $input) {
      group {
        ...GroupCard_group
        ...ManageGroupDialog_group
        ...GroupDescription_group
        ...GroupSettingsCard_group
      }
    }
  }
`;

export const useUpsertGroupMutation = () => {
  const filters = React.useContext(PaginationContext);

  return useConfiguredMutation<UpsertGroupMutation>(mutation, (config) => ({
    updater(store) {
      if (!config.variables.input.id) {
        const connection = ConnectionHandler.getConnection(
          store.getRoot(),
          "CoachGroups_groups",
          filters,
        );

        if (connection && filters?.status !== "ARCHIVED") {
          const node = store
            .getRootField("upsertGroup")
            .getLinkedRecord("group");

          if (node) {
            const edge = ConnectionHandler.createEdge(
              store,
              connection,
              node,
              "GroupEdge",
            );
            ConnectionHandler.insertEdgeBefore(connection, edge);
          }

          const totalCount = connection.getValue("totalCount") as number;
          connection.setValue(totalCount + 1, "totalCount");
        }
      }
    },
  }));
};

export const useUpdateGroupDescription = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpsertGroupMutation>(mutation, () => ({
    onSuccess: () =>
      snackAlert({
        severity: "success",
        message: "Group description updated",
      }),
  }));
};
