import React from "react";
import {
  Copy,
  MoveDown,
  MoveUp,
  MoveLeft,
  MoveRight,
  LucideProps,
} from "lucide-react";
import { Box } from "@mui/material";

interface ICopyWithArrowProps extends LucideProps {
  direction?: "up" | "down" | "left" | "right";
}

const CopyWithArrow = (props: ICopyWithArrowProps) => {
  const { direction = "left" } = props;

  const ICON_SIZE = 14;

  const getArrow = () => {
    switch (direction) {
      case "up":
        return <MoveUp size={ICON_SIZE} {...props} />;
      case "down":
        return <MoveDown size={ICON_SIZE} {...props} />;
      case "left":
        return <MoveLeft size={ICON_SIZE} {...props} />;
      case "right":
        return <MoveRight size={ICON_SIZE} {...props} />;
    }
  };

  const isVertical = ["up", "down"].includes(direction);
  return (
    <>
      <Box
        display={"flex"}
        position={"relative"}
        flexDirection={isVertical ? "row" : "column"}
        marginRight={isVertical ? 1 : 0}
        marginBottom={isVertical ? 0 : 1}
      >
        <Copy size={ICON_SIZE} {...props} />
        <Box
          position={"absolute"}
          right={isVertical ? -12 : "unset"}
          bottom={isVertical ? "unset" : -12}
        >
          {getArrow()}
        </Box>
      </Box>
    </>
  );
};

export default CopyWithArrow;
