import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { Menu } from "../menu/Menu";
import { CalendarDays, LayoutList, TableProperties } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
  icon: {
    "&.MuiListItemIcon-root svg": {
      height: 20,
      width: 20,
      color: theme.palette.secondary.main,
    },
  },
  primary: {
    fontWeight: 500,
  },
}));

export enum ProgramDetailsViewMode {
  CALENDAR = "calendar",
  LIST = "list",
  SPREADSHEET = "spreadsheet",
}

export const DEFAULT_PROGRAM_DETAILS_VIEW_MODE = ProgramDetailsViewMode.LIST;

export type CalendarViewOptions = Record<
  ProgramDetailsViewMode,
  CalendarViewOption
>;

export const defaultCalendarViewOptions: CalendarViewOptions = {
  [ProgramDetailsViewMode.LIST]: {
    label: "List view",
    icon: <LayoutList />,
    disabled: false,
  },
  [ProgramDetailsViewMode.CALENDAR]: {
    label: "Calendar view",
    icon: <CalendarDays />,
    disabled: false,
  },
  [ProgramDetailsViewMode.SPREADSHEET]: {
    label: "Sheets view",
    icon: <TableProperties />,
    disabled: false,
  },
};

export interface CalendarViewOption {
  label: React.ReactNode;
  icon: React.ReactNode;
  disabled: boolean;
}

export interface ProgramDetailsViewButtonProps
  extends Omit<ButtonProps, "onChange"> {
  onChange?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ProgramDetailsViewMode,
  ) => void;
  value?: ProgramDetailsViewMode;
  options?: CalendarViewOptions;
}

export function ProgramDetailsViewButton(props: ProgramDetailsViewButtonProps) {
  const {
    className,
    value = ProgramDetailsViewMode.CALENDAR,
    options = defaultCalendarViewOptions,
    onChange,
    ...other
  } = props;
  const s = useStyles();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "program-details-view",
  });

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      menuState.close();

      const {
        dataset: { viewOption: value },
      } = event.currentTarget;

      if (value && onChange) {
        onChange(event, value as ProgramDetailsViewMode);
      }
    },
    [menuState, onChange],
  );

  const selected = options[value] || Object.values(options)[0];

  return (
    <>
      <Button
        className={clsx(s.root, className)}
        {...bindTrigger(menuState)}
        startIcon={selected.icon}
        endIcon={<ArrowDropDownIcon />}
        {...other}
      >
        {selected.label}
      </Button>

      <Menu {...bindMenu(menuState)}>
        {Object.entries(options).map(([name, { label, icon, disabled }]) => (
          <MenuItem
            key={name}
            data-view-option={name}
            selected={name === value}
            onClick={handleClick}
            disabled={disabled}
          >
            <ListItemIcon className={s.icon} children={icon} />
            <ListItemText classes={{ primary: s.primary }} primary={label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
