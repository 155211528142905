/**
 * @generated SignedSource<<8fbf5634beb517b0fc4b9c105d3c7918>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ToggleManyClientsArchivedInput = {
  archived: boolean;
  clientMutationId?: string | null | undefined;
  ids: ReadonlyArray<string>;
};
export type ToggleManyClientsArchivedMutation$variables = {
  input: ToggleManyClientsArchivedInput;
};
export type ToggleManyClientsArchivedMutation$data = {
  readonly toggleManyClientsArchived: {
    readonly clients: ReadonlyArray<{
      readonly id: string;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type ToggleManyClientsArchivedMutation = {
  response: ToggleManyClientsArchivedMutation$data;
  variables: ToggleManyClientsArchivedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleManyClientsArchivedPayload",
    "kind": "LinkedField",
    "name": "toggleManyClientsArchived",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "clients",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToggleManyClientsArchivedMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToggleManyClientsArchivedMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b41b7ee2f4d6bb731165c456aaaf5c94",
    "id": null,
    "metadata": {},
    "name": "ToggleManyClientsArchivedMutation",
    "operationKind": "mutation",
    "text": "mutation ToggleManyClientsArchivedMutation(\n  $input: ToggleManyClientsArchivedInput!\n) {\n  toggleManyClientsArchived(input: $input) {\n    clients {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b523fa95c4d5f70a6ea0fef29c2aac41";

export default node;
