import clsx from "clsx";
import React from "react";
import {
  Drawer,
  DrawerProps,
  Typography,
  TextFieldProps,
  InputLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "relay-runtime";
import { useFragment, useMutation } from "react-relay/hooks";

import { useDebounce } from "../../hooks/useDebounce";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { EditorField } from "../program/EditorField";

import { WeekDrawer_week$key } from "./__generated__/WeekDrawer_week.graphql";
import { WeekDrawerMutation } from "./__generated__/WeekDrawerMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(3, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  title: {
    fontSize: 32,
    margin: theme.spacing(5, 0),
  },

  description: {
    borderRadius: 0,
  },

  input: {
    "& .MuiInputBase-multiline": {
      padding: theme.spacing(0, 1.75),
    },
  },
}));

const fragment = graphql`
  fragment WeekDrawer_week on Week {
    description
  }
`;

const updateWeekMutation = graphql`
  mutation WeekDrawerMutation($input: UpdateWeekInput!) {
    updateWeek(input: $input) {
      week {
        ...WeekDrawer_week
      }
    }
  }
`;

export interface WeekDrawerProps extends DrawerProps {
  weekRef: WeekDrawer_week$key;
  weekId?: string;
  weekNumber?: number;
  programId?: string;
}

export function WeekDrawer(props: WeekDrawerProps) {
  const { className, weekRef, weekId, weekNumber, programId, ...other } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const week = useFragment(fragment, weekRef);
  const [updateWeek] = useMutation<WeekDrawerMutation>(updateWeekMutation);

  const [description, setDescription] = React.useState(week.description);
  const debouncedDescription = useDebounce(description, 500);

  React.useEffect(() => {
    if (week.description !== debouncedDescription) {
      updateWeek({
        variables: {
          input: {
            programId: programId,
            week: weekNumber,
            description: debouncedDescription || null,
          },
        },
        onCompleted: (_, errors) => {
          if (errors && errors[0]) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Week description updated",
            });
          }
        },
        onError,
      });
    }
  }, [
    debouncedDescription,
    onError,
    snackAlert,
    updateWeek,
    week.description,
    programId,
    weekNumber,
  ]);

  const handleDescriptionChange: TextFieldProps["onChange"] = React.useCallback(
    ({ target: { value } }) => {
      setDescription(value);
    },
    [],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      anchor="right"
      variant="temporary"
      disableEnforceFocus
      {...other}
    >
      <Typography variant="h2" className={s.title}>
        Week {weekNumber} Settings
      </Typography>

      <InputLabel>Description</InputLabel>
      <EditorField
        className={clsx(s.input)}
        value={description}
        onChange={handleDescriptionChange}
        helperText="Optional description to describe what your clients will focus on."
        variant="outlined"
        color="primary"
        fullWidth
        multiline
      />
    </Drawer>
  );
}
