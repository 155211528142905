import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  Button,
  ButtonProps,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay/hooks";

import { ReactComponent as EllipsisHorizontalIcon } from "../../icons/EllipsisHorizontal.svg";
import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";
import { Filters } from "../program/ProgramDetailsFilters";
import { ComponentType, sevenDays } from "../../constants";
import { ProgramWeekDateRange } from "../program/ProgramWeekDateRange";

import { calcScheduleGrid } from "./utils";
import { ProgramCalendarWeek_week$key } from "./__generated__/ProgramCalendarWeek_week.graphql";
import {
  ProgramCalendarDay,
  ProgramCalendarDayProps,
} from "./ProgramCalendarDay";
import { ProgramCalendarComponentContainer } from "./ProgramCalendarComponentContainer";
import { ProgramCalendarComponentButtons } from "./ProgramCalendarComponentButtons";
import { ProgramCalendarComponent } from "./ProgramCalendarComponent";
import { getBannerHeight } from "../../contexts/BannersContext";
import { headerHeight } from "../app/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    position: "sticky",
    background: "white",
    zIndex: theme.zIndex.speedDial + 1,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 0,
  },

  dateRange: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2.25),
  },

  moreButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "none",
    },
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },

  calendar: {
    backgroundColor: theme.palette.background.paper,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    flex: 1,
    borderTopWidth: 0,
  },

  days: {
    display: "flex",
    height: "100%",
  },

  day: {
    position: "relative",
    paddingBottom: theme.spacing(12),
  },
}));

const fragment = graphql`
  fragment ProgramCalendarWeek_week on Week {
    id
    week
    description
  }
`;

export interface ProgramCalendarWeekProps extends BoxProps {
  weekRef: ProgramCalendarWeek_week$key;
  filters?: Filters;
  startDate?: string;
  onAddComponent?: ProgramAddComponentCallback;
  onOpenMenu?: ButtonProps["onClick"];
  onMove?: ProgramCalendarDayProps["onMove"];
  onMoveEnd?: () => void;
  schedule: ProgramWeekSchedule;
  activityPreview: boolean;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
  activityPreviewItem?: any;
  setActivityPreviewItem: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpenDialog: (e?: any, slug?: string) => void;
}

export function ProgramCalendarWeek(props: ProgramCalendarWeekProps) {
  const {
    className,
    weekRef,
    schedule,
    filters,
    startDate,
    onOpenMenu,
    onAddComponent,
    onMove,
    onMoveEnd,
    activityPreview,
    setActivityPreview,
    activityPreviewItem,
    setActivityPreviewItem,
    handleClickOpenDialog,
    ...other
  } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const s = useStyles();
  const week = useFragment(fragment, weekRef);
  const grid = React.useMemo(
    () =>
      calcScheduleGrid(
        schedule.filter(
          ({ weeks, component }) =>
            weeks.includes(week.week) &&
            // If active component is moved to the week that is not yet loaded
            // using sidebar schedule tab, it appeared on the first week
            // of the schedule until the missing week was loaded by a user
            // with "load more" weeks button.
            // TODO drop this after migration from relay.
            (!component.type ||
              !component?.weekId ||
              component.weekId === week.id),
        ),
      ),
    [schedule, week.week],
  );

  const handleCalendarComponentClick = (slug?: string, typeName?: string) => {
    setActivityPreviewItem(slug);
    setActivityPreview(false);
    if (!isMd && typeName === ComponentType.WORKOUT) {
      activityPreview &&
        activityPreviewItem === slug &&
        handleClickOpenDialog(undefined, slug);
      setActivityPreview(true);
      return;
    }
    handleClickOpenDialog(undefined, slug);
  };

  const bannerSpacing = theme.spacing(getBannerHeight());
  const computedHeaderHeight = isMd ? headerHeight(theme) : "0px";

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box
        className={s.header}
        sx={{
          top: `calc(${bannerSpacing} + ${computedHeaderHeight})`,
        }}
      >
        <Typography variant="h5" className={s.title}>
          Week {week.week}
        </Typography>

        {onOpenMenu && (
          <Button
            data-id={week.id}
            onClick={onOpenMenu}
            className={s.moreButton}
          >
            <EllipsisHorizontalIcon />
          </Button>
        )}
      </Box>

      {startDate && (
        <ProgramWeekDateRange
          variant="h6"
          className={s.dateRange}
          startDate={startDate}
          week={week.week}
        />
      )}
      <Box className={s.calendar}>
        <Box className={s.days}>
          {sevenDays.map((_, dayIndex) => (
            <ProgramCalendarDay
              className={s.day}
              key={dayIndex}
              weekId={week.id}
              week={week.week}
              day={dayIndex}
              startDate={startDate}
              onMove={onMove}
              onMoveEnd={onMoveEnd}
              onAddComponent={onAddComponent}
            >
              {grid[dayIndex].map(({ span, id, component }, index) => (
                <ProgramCalendarComponentContainer
                  componentId={id}
                  week={week.week}
                  day={dayIndex}
                  key={[id || index, dayIndex, span].join(".")}
                  span={span}
                  disableResize={component?.type === ComponentType.LESSON}
                >
                  {component && (
                    <React.Fragment key={id}>
                      <ProgramCalendarComponentButtons
                        componentRef={component}
                        span={span}
                        spanOffset={dayIndex}
                        activeComponentSlug={activityPreviewItem as string}
                      />
                      <ProgramCalendarComponent
                        component={component}
                        onTitleClick={handleCalendarComponentClick}
                        activeComponentSlug={activityPreviewItem as string}
                      />
                    </React.Fragment>
                  )}
                </ProgramCalendarComponentContainer>
              ))}
            </ProgramCalendarDay>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
