import { LinkOff as RemoveLink } from "@mui/icons-material";

import { ReactComponent as TextIcon } from "../../../icons/Text.svg";
import { ReactComponent as Heading1Icon } from "../../../icons/Header1.svg";
import { ReactComponent as Heading2Icon } from "../../../icons/Header2.svg";
import { ReactComponent as CalloutIcon } from "../../../icons/Callout.svg";
import { ReactComponent as TabsIcon } from "../../../icons/Tabs.svg";
import { ReactComponent as YesNoIcon } from "../../../icons/YesNoQuestion.svg";
import { ReactComponent as MeasurementIcon } from "../../../icons/MeasurementQuestion.svg";
import { ReactComponent as AccordionIcon } from "../../../icons/Accordion.svg";
import { ReactComponent as AudioIcon } from "../../../icons/Audio.svg";
import { ReactComponent as AttachFileIcon } from "../../../icons/File.svg";
import { ReactComponent as VideoIcon } from "../../../icons/Video.svg";
import { ReactComponent as ImageIcon } from "../../../icons/Image.svg";
import { ReactComponent as FormatListBulletedIcon } from "../../../icons/BulletedList.svg";
import { ReactComponent as FormatListNumberedIcon } from "../../../icons/NumberedList.svg";
import { ReactComponent as CheckBoxIcon } from "../../../icons/Checklist.svg";
import { ReactComponent as NutritionTargetIcon } from "../../../icons/NutritionTargetsEmpty.svg";
import { ReactComponent as LongTextQuestionIcon } from "../../../icons/LongTextQuestion.svg";
import { ReactComponent as ShortTextQuestion } from "../../../icons/ShortTextQuestion.svg";
import { ReactComponent as HashIcon } from "../../../icons/hash.svg";
import { ReactComponent as LinearScaleIcon } from "../../../icons/RatingScaleQuestion.svg";
import { ReactComponent as CloudUploadUserIcon } from "../../../icons/ProgressPhotoQuestion.svg";
import { ReactComponent as CloudUploadIcon } from "../../../icons/CloudUpload.svg";
import { ReactComponent as InsertLink } from "../../../icons/IconLink.svg";
import { ReactComponent as FormatBold } from "../../../icons/FormatBold.svg";
import { ReactComponent as FormatItalic } from "../../../icons/FormatItalic.svg";
import { ReactComponent as StrikethroughS } from "../../../icons/FormatStrikethrough.svg";
import { ReactComponent as FormatQuote } from "../../../icons/FormatQuote.svg";
import { ReactComponent as FormatPullQuote } from "../../../icons/FormatPullQuote.svg";
import { ReactComponent as Divider } from "../../../icons/Divider.svg";
import { ReactComponent as Highlight } from "../../../icons/Highlight.svg";
import { ReactComponent as WorkoutIcon } from "../../../icons/WorkoutSection.svg";
import { ReactComponent as MultipleChoice } from "../../../icons/MultipleChoice.svg";
import { ReactComponent as Table } from "../../../icons/Table.svg";
import { ReactComponent as WebBookmark } from "../../../icons/WebBookmark.svg";
import { ReactComponent as EmbedWebsite } from "../../../icons/EmbedWebsite.svg";
import { ReactComponent as DateIcon } from "../../../icons/CalendarOutlined.svg";
import { ReactComponent as EmojiIcon } from "../../../icons/Emoji3.svg";
import { ReactComponent as StarRatingIcon } from "../../../icons/StarOutlined.svg";
import { ReactComponent as MultipleChoiceCheckboxIcon } from "../../../icons/Checkbox.svg";
import { ElementType } from "../types/elements";
// TODO_Editor
// import { SchemaElements } from "../normalizers/withSchema";
// import { createTableRows } from "../elements/table/utils";
import { createEmptyWorkout } from "../../workout/utils";

export interface ElementTypeConfigToolbar {
  icon?: React.FC;
  activeIcon?: React.FC;
}

export interface ElementTypeConfigSidebar {
  text: string;
  description: string;
  icon: React.FC;
}

export interface ElementTypeConfig {
  toggle?: boolean;
  block?: boolean;
  inline?: boolean;
  text?: boolean;
  multiline?: boolean;
  container?: boolean;
  selectable?: boolean;
  mark?: boolean;
  component?: React.FC;
  sidebar?: ElementTypeConfigSidebar;
  toolbar?: ElementTypeConfigToolbar;
  defaultProps?: any;
  isVoid?: boolean | ((element: any) => boolean);
  deserializer?: any;
  merge?: boolean;
}

export const ElementTypes: Record<ElementType, ElementTypeConfig> = {
  [ElementType.PARAGRAPH]: {
    selectable: true,
    text: true,
    block: true,
    toggle: true,
    sidebar: {
      text: "Text",
      description: "Add some normal text.",
      icon: TextIcon,
    },
  },
  [ElementType.BLOCKQUOTE]: {
    selectable: true,
    text: true,
    toggle: true,
    toolbar: {
      icon: FormatQuote,
      activeIcon: FormatPullQuote,
    },
  },
  [ElementType.PULLQUOTE]: {
    selectable: true,
    text: true,
    toggle: true,
    toolbar: {
      icon: FormatPullQuote,
      activeIcon: FormatQuote,
    },
  },
  [ElementType.H1]: {
    toggle: true,
    selectable: true,
    text: true,
    toolbar: {
      icon: Heading1Icon,
    },
    sidebar: {
      text: "Heading 1",
      description: "Use for large section headings.",
      icon: Heading1Icon,
    },
  },
  [ElementType.H2]: {
    toggle: true,
    selectable: true,
    text: true,
    toolbar: {
      icon: Heading2Icon,
    },
    sidebar: {
      text: "Heading 2",
      description: "Use for medium section headings.",
      icon: Heading2Icon,
    },
  },
  [ElementType.CHECKLIST]: {
    selectable: true,
    merge: true,
    text: true,
    toolbar: {
      icon: CheckBoxIcon,
    },
    sidebar: {
      text: "Checklist",
      description: "Add a checklist to your lesson.",
      icon: CheckBoxIcon,
    },
  },
  [ElementType.CHECKLIST_ITEM]: {
    block: true,
    text: true,
  },
  [ElementType.NUMBERED_LIST]: {
    selectable: true,
    merge: true,
    text: true,
    toolbar: {
      icon: FormatListNumberedIcon,
    },
    sidebar: {
      text: "Numbered List",
      description: "Create a list using numbers.",
      icon: FormatListNumberedIcon,
    },
  },
  [ElementType.BULLETED_LIST]: {
    selectable: true,
    merge: true,
    text: true,
    toolbar: {
      icon: FormatListBulletedIcon,
    },
    sidebar: {
      text: "Bulleted List",
      description: "Create a list using bullet points.",
      icon: FormatListBulletedIcon,
    },
  },
  [ElementType.LIST_ITEM]: {
    block: true,
  },

  [ElementType.FILE]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "File Attachment",
      description: "Attach a file for download.",
      icon: AttachFileIcon,
    },
  },
  [ElementType.VIDEO]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Video",
      description: "Embed video (YouTube, Vimeo, etc...)",
      icon: VideoIcon,
    },
  },
  [ElementType.AUDIO]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Audio",
      description: "Embed audio (Soundcloud, Spotify, etc...)",
      icon: AudioIcon,
    },
  },
  [ElementType.IMAGE]: {
    isVoid: (element) => !element.url,
    selectable: true,
    block: true,
    sidebar: {
      text: "Image",
      description: "Upload or embed images.",
      icon: ImageIcon,
    },
    // deserializer: {
    //   node: (el) => ({
    //     type: ElementType.IMAGE,
    //     url: el.getAttribute("src"),
    //   }),
    //   rules: [{ nodeNames: "IMG" }],
    // } as ElementTypeDeserializer,
  },
  [ElementType.MEDIA_CAPTION]: {
    text: true,
  },
  [ElementType.CHECKIN_ANSWER_BINARY]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Yes/No",
      description: "Offer two options to choose from.",
      icon: YesNoIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_MEASUREMENT]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Measurement",
      description: "Collect body weight or other body stats.",
      icon: MeasurementIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_PROGRESS_PHOTO]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Progress Photos",
      description: "Ask for progress pictures.",
      icon: CloudUploadUserIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_RATING_SCALE]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Rating Scale",
      description: "Ask for a numerical rating.",
      icon: LinearScaleIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_LONG_TEXT]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Long Text Answer",
      description: "Ask an open-ended question.",
      icon: LongTextQuestionIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_SHORT_TEXT]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Short Text Answer",
      description: "Ask an open-ended question.",
      icon: ShortTextQuestion,
    },
  },
  [ElementType.CHECKIN_ANSWER_NUMBER]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Number",
      description: "Ask for a numeric answer.",
      icon: HashIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_FILE]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "File Upload",
      description: "Ask for any file type, like PDF or image upload.",
      icon: CloudUploadIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Multiple Choice",
      description: "Provide a set of options to choose from.",
      icon: MultipleChoice,
    },
    defaultProps: (element: any): any =>
      Object.assign(element, {
        answer: {
          options: [""],
          selected: null,
        },
      }),
  },
  [ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Checkboxes",
      description:
        "Provide a set of options to choose more than one option from.",
      icon: MultipleChoiceCheckboxIcon,
    },
    defaultProps: (element: any): any =>
      Object.assign(element, {
        answer: {
          options: [""],
          selectedMany: [],
        },
      }),
  },
  [ElementType.CHECKIN_ANSWER_DATE]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Date",
      description: "Ask for a date answer.",
      icon: DateIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_EMOJI]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Emoji",
      description: "Ask for a mood rating.",
      icon: EmojiIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_STAR_RATING]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Star Rating",
      description: "Ask for a star rating.",
      icon: StarRatingIcon,
    },
  },
  [ElementType.CHECKIN_ANSWER_NUTRITION_TARGET]: {
    isVoid: true,
    selectable: true,
    sidebar: {
      text: "Nutrition target",
      description: "Add a nutrition target.",
      icon: NutritionTargetIcon,
    },
  },
  [ElementType.GIF]: {
    isVoid: true,
  },
  [ElementType.ATTACHMENT]: {
    isVoid: true,
  },
  [ElementType.EMOJI]: {},
  [ElementType.BOLD]: {
    mark: true,
    toggle: true,
    toolbar: {
      icon: FormatBold,
    },
  },
  [ElementType.ITALIC]: {
    mark: true,
    toggle: true,
    toolbar: {
      icon: FormatItalic,
    },
  },
  [ElementType.STRIKETHROUGH]: {
    mark: true,
    toggle: true,
    toolbar: {
      icon: StrikethroughS,
    },
  },
  [ElementType.HIGHLIGHT]: {
    mark: true,
    toggle: true,
    toolbar: {
      icon: Highlight,
    },
  },
  [ElementType.LINK]: {
    mark: true,
    inline: true,
    toggle: true,
    toolbar: {
      icon: InsertLink,
      activeIcon: RemoveLink,
    },
  },
  [ElementType.MESSAGE]: {
    text: true,
  },
  [ElementType.CALLOUT]: {
    block: true,
    selectable: true,
    text: true,
    multiline: true,
    sidebar: {
      text: "Callout",
      description: "Make a message stand out.",
      icon: CalloutIcon,
    },
  },
  [ElementType.PREVIEW_LINK]: {
    isVoid: true,
    block: true,
    selectable: true,
  },
  [ElementType.TABS_LIST]: {
    block: true,
    container: true,
    selectable: true,
    sidebar: {
      text: "Tabs",
      description: "Separate content into tabs.",
      icon: TabsIcon,
    },
    // defaultProps: (element: any): any =>
    //   Object.assign(element, {
    //     children: [
    //       SchemaElements.createElement(ElementType.TABS_ITEM, {
    //         label: "First Tab",
    //         active: true,
    //       }),
    //       SchemaElements.createElement(ElementType.TABS_ITEM, {
    //         label: "Second Tab",
    //         active: false,
    //       }),
    //     ],
    //   }),
  },
  [ElementType.ACCORDION_LIST]: {
    block: true,
    container: true,
    selectable: true,
    sidebar: {
      text: "Accordion",
      description: "Easily show and hide content.",
      icon: AccordionIcon,
    },
    // defaultProps: (element: any): any =>
    //   Object.assign(element, {
    //     children: [
    //       SchemaElements.createElement(ElementType.TABS_ITEM, {
    //         label: "First Item",
    //         active: false,
    //       }),
    //       SchemaElements.createElement(ElementType.TABS_ITEM, {
    //         label: "Second Item",
    //         active: false,
    //       }),
    //     ],
    //   }),
  },
  [ElementType.TABS_ITEM]: {
    block: true,
    container: true,
    // defaultProps: (element: any): any =>
    //   Object.assign(element, {
    //     children: [
    //       SchemaElements.createElement(ElementType.PARAGRAPH, {}, [
    //         { text: "Add content here." },
    //       ]),
    //     ],
    //   }),
  },

  [ElementType.DIVIDER]: {
    isVoid: true,
    block: true,
    selectable: true,
    sidebar: {
      text: "Divider",
      description: "Visually separate blocks.",
      icon: Divider,
    },
    // deserializer: {
    //   node: () => ({
    //     type: ElementType.DIVIDER,
    //   }),
    //   rules: [{ nodeNames: "HR" }],
    // } as ElementTypeDeserializer,
  },

  [ElementType.WORKOUT_SECTION]: {
    isVoid: true,
    block: false,
    selectable: true,
    sidebar: {
      text: "Exercise Section",
      description: "Add exercises and supersets.",
      icon: WorkoutIcon,
    },
    defaultProps: (element: any): any =>
      Object.assign(element, {
        workout: createEmptyWorkout(),
        open: true,
      }),
  },

  [ElementType.TABLE]: {
    block: true,
    selectable: true,
    container: true,
    sidebar: {
      text: "Table",
      description: "Add a simple table.",
      icon: Table,
    },
    // deserializer: {
    //   node: () => ({
    //     type: ElementType.TABLE,
    //   }),
    //   rules: [{ nodeNames: "TABLE" }],
    // } as ElementTypeDeserializer,
    // defaultProps: (element: any): any =>
    //   Object.assign(element, {
    //     children: createTableRows(2, 2),
    //   }),
  },

  [ElementType.TABLE_HEAD_ROW]: {
    container: true,
  },

  [ElementType.TABLE_HEAD_CELL]: {
    container: true,
    block: true,
    // deserializer: {
    //   node: () => ({
    //     type: ElementType.TABLE_HEAD_CELL,
    //   }),
    //   rules: [{ nodeNames: "TH" }],
    // } as ElementTypeDeserializer,
  },

  [ElementType.TABLE_ROW]: {
    container: true,
    // deserializer: {
    //   node: () => ({
    //     type: ElementType.TABLE_ROW,
    //   }),
    //   rules: [{ nodeNames: "TR" }],
    // } as ElementTypeDeserializer,
  },

  [ElementType.TABLE_CELL]: {
    container: true,
    block: true,
    // deserializer: {
    //   node: () => ({
    //     type: ElementType.TABLE_CELL,
    //   }),
    //   rules: [{ nodeNames: "TD" }],
    // } as ElementTypeDeserializer,
  },

  [ElementType.WEB_BOOKMARK]: {
    isVoid: true,
    block: true,
    selectable: true,
    sidebar: {
      text: "Web Bookmark",
      description: "Add a link with a rich preview.",
      icon: WebBookmark,
    },
    defaultProps: (element: any): any =>
      Object.assign(element, {
        open: true,
      }),
  },

  [ElementType.EMBED_WEBSITE]: {
    isVoid: true,
    block: true,
    sidebar: {
      text: "Embed",
      description: "Add an external website.",
      icon: EmbedWebsite,
    },
    defaultProps: (element: any): any =>
      Object.assign(element, {
        open: true,
      }),
  },
} as const;

export interface ElementGroupConfig {
  text: string;
  types: ReadonlyArray<ElementType>;
}
export enum ElementGroup {
  BASIC = "basic",
  CHECK_IN = "check_in",
}

export const ElementGroups: Record<ElementGroup, ElementGroupConfig> = {
  [ElementGroup.BASIC]: {
    text: "Basic content",
    types: [
      ElementType.PARAGRAPH,
      ElementType.H1,
      ElementType.H2,
      ElementType.CHECKLIST,
      ElementType.NUMBERED_LIST,
      ElementType.BULLETED_LIST,
      ElementType.FILE,
      ElementType.VIDEO,
      ElementType.AUDIO,
      ElementType.IMAGE,
      ElementType.CALLOUT,
      ElementType.TABS_LIST,
      ElementType.ACCORDION_LIST,
      ElementType.DIVIDER,
      ElementType.WORKOUT_SECTION,
      ElementType.TABLE,
      ElementType.WEB_BOOKMARK,
      ElementType.EMBED_WEBSITE,
    ],
  },
  [ElementGroup.CHECK_IN]: {
    text: "Check-in questions",
    types: [
      ElementType.CHECKIN_ANSWER_BINARY,
      ElementType.CHECKIN_ANSWER_LONG_TEXT,
      ElementType.CHECKIN_ANSWER_SHORT_TEXT,
      ElementType.CHECKIN_ANSWER_MEASUREMENT,
      ElementType.CHECKIN_ANSWER_PROGRESS_PHOTO,
      ElementType.CHECKIN_ANSWER_RATING_SCALE,
      ElementType.CHECKIN_ANSWER_FILE,
      ElementType.CHECKIN_ANSWER_NUMBER,
      ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE,
      ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX,
      ElementType.CHECKIN_ANSWER_DATE,
      ElementType.CHECKIN_ANSWER_EMOJI,
      ElementType.CHECKIN_ANSWER_STAR_RATING,
      ElementType.CHECKIN_ANSWER_NUTRITION_TARGET,
    ],
  },
} as const;

export const InlineToolbarGroupsMultiline: ElementType[][] = [
  [ElementType.BOLD, ElementType.ITALIC, ElementType.STRIKETHROUGH],
  [ElementType.CHECKLIST, ElementType.BULLETED_LIST, ElementType.NUMBERED_LIST],
  [ElementType.H1, ElementType.H2],
  [
    ElementType.HIGHLIGHT,
    ElementType.LINK,
    ElementType.BLOCKQUOTE,
    ElementType.PULLQUOTE,
  ],
];

export const InlineToolbarGroups: ElementType[][] = [
  [
    ElementType.BOLD,
    ElementType.ITALIC,
    ElementType.STRIKETHROUGH,
    ElementType.LINK,
  ],
];
