"use client";

import React from "react";
import {
  TooltipProvider as RadixTooltipProvider,
  Root as RadixRoot,
  TooltipTrigger as RadixTooltipTrigger,
  Portal as RadixPortal,
  Content as RadixContent,
} from "@radix-ui/react-tooltip";
import { withCn, withProps } from "@udecode/cn";

export const TooltipProvider = RadixTooltipProvider;
export const Tooltip = RadixRoot;
export const TooltipTrigger = RadixTooltipTrigger;
export const TooltipPortal = RadixPortal;

export const TooltipContent = withCn(
  withProps(RadixContent, {
    sideOffset: 4,
  }),
  "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md",
);

export function withTooltip<
  T extends React.ComponentType<any> | keyof HTMLElementTagNameMap,
>(Component: T) {
  return React.forwardRef<
    React.ElementRef<T>,
    React.ComponentPropsWithoutRef<T> & {
      tooltip?: React.ReactNode;
      tooltipContentProps?: Omit<
        React.ComponentPropsWithoutRef<typeof RadixContent>,
        "children"
      >;
      tooltipProps?: Omit<
        React.ComponentPropsWithoutRef<typeof RadixRoot>,
        "children"
      >;
    }
  >(function ExtendComponent(
    { tooltip, tooltipContentProps, tooltipProps, ...props },
    ref,
  ) {
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => {
      setMounted(true);
    }, []);

    const component = <Component ref={ref} {...(props as any)} />;

    if (tooltip && mounted) {
      return (
        <Tooltip {...tooltipProps}>
          <TooltipTrigger asChild>{component}</TooltipTrigger>

          <TooltipPortal>
            <TooltipContent {...tooltipContentProps}>{tooltip}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    }

    return component;
  });
}
