export const queryMatchesContent = (
  text: string | null,
  query?: string,
): boolean => {
  if (!text) return false;
  if (!query) return true;

  const keywords = query ? query.split(/\s+/).filter(Boolean) : [];
  const normText = text.toLocaleLowerCase();

  return (
    !keywords.length ||
    keywords.every((keyword) => normText.includes(keyword.toLocaleLowerCase()))
  );
};
