/**
 * @generated SignedSource<<60caab7c0a49ee105e072f45b1dba9c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachSettingsPlan_user$data = {
  readonly billingAddress: {
    readonly " $fragmentSpreads": FragmentRefs<"ManagePlanCard_billingAddress" | "ProUpgradeCard_billingAddress">;
  } | null | undefined;
  readonly clientsCountNoSample: number;
  readonly isImpersonating: boolean;
  readonly plan: Plan | null | undefined;
  readonly subscription: {
    readonly cancelAtPeriodEnd: boolean;
    readonly canceledAtDate: string | null | undefined;
    readonly periodEndDate: string | null | undefined;
    readonly status: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CoachSettingsPlanStatusText_subscription" | "CoachSettingsPlanTextSubtitle_subscription">;
  } | null | undefined;
  readonly trialExpired: boolean;
  readonly trialExpiryDate: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CoachSettingsPlanStatusText_user" | "CoachSettingsPlanTextSubtitle_user" | "ProUpgradeCard_user">;
  readonly " $fragmentType": "CoachSettingsPlan_user";
};
export type CoachSettingsPlan_user$key = {
  readonly " $data"?: CoachSettingsPlan_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachSettingsPlan_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMMM D, YYYY"
  },
  {
    "kind": "Literal",
    "name": "utc",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachSettingsPlan_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImpersonating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "trialExpiryDate",
      "storageKey": "trialExpiryDate(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialExpired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": "clientsCountNoSample",
      "args": [
        {
          "kind": "Literal",
          "name": "noSample",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "clientsCount",
      "storageKey": "clientsCount(noSample:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "periodEndDate",
          "storageKey": "periodEndDate(format:\"MMMM D, YYYY\",utc:true)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cancelAtPeriodEnd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "canceledAtDate",
          "storageKey": "canceledAtDate(format:\"MMMM D, YYYY\",utc:true)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CoachSettingsPlanTextSubtitle_subscription"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CoachSettingsPlanStatusText_subscription"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingAddress",
      "kind": "LinkedField",
      "name": "billingAddress",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ManagePlanCard_billingAddress"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProUpgradeCard_billingAddress"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProUpgradeCard_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CoachSettingsPlanTextSubtitle_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CoachSettingsPlanStatusText_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "ffdead5b8af324168a2f1fba1ba2a2ea";

export default node;
