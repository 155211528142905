import React from "react";
import { ContainerProps, RelayPaginationProp } from "react-relay";

import { StaticContainer } from "../routes/StaticContainer";
import { useEffectLater } from "../../hooks/useEffectLater";
import { FragmentOrRegularProp } from "react-relay/lib/ReactRelayTypes";

export type DynamicSearchFragment = FragmentOrRegularProp<{
  readonly connection: {
    readonly totalCount: number;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
  } | null;
}>;

export interface DynamicSearchListProps<F extends DynamicSearchFragment> {
  children: (props: {
    connection: F["connection"];
    onMoreClick: () => void;
    hasMore: boolean;
    loading: boolean;
  }) => React.ReactNode;
  fragment?: F;
  pageSize?: number;
  filter?: string;
}

export const DynamicSearchList = <F extends DynamicSearchFragment>(
  props: ContainerProps<DynamicSearchListProps<F>> & {
    relay: RelayPaginationProp;
  },
) => {
  const {
    fragment: { connection },
    children,
    relay,
    filter,
    pageSize,
  } = props;

  const hasMore = relay.hasMore();
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const [loading, setLoading] = React.useState(false);

  const handleMoreClick = React.useCallback(() => {
    setLoading(true);
    relay.loadMore(pageSize, setLoaded);
  }, [pageSize, relay, setLoaded]);

  useEffectLater(() => {
    if (!loading) {
      setLoading(true);
      relay.refetchConnection(pageSize, setLoaded, {
        query: filter,
        first: pageSize,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, pageSize]);

  return (
    <StaticContainer shouldUpdate={!loading}>
      {children({
        connection,
        hasMore,
        onMoreClick: handleMoreClick,
        loading,
      })}
    </StaticContainer>
  );
};
