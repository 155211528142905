import React from "react";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { colorSystem } from "../../theme";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { AppError, SOMETHING_WENT_WRONG } from "../../constants";
import { useUpsertBillingAddressMutation } from "../settings/mutations/UpsertBillingAddress";
import { useRegisterCoachWithFreeLaunchMutation } from "./mutations/RegisterCoachWithFreeLaunch";
import { useSignInMutation } from "./mutations/SignIn";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  card: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginTop: theme.spacing(3.5),
  },
  input: {
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.border2,
    padding: theme.spacing(2.17, 1.9),
    fontSize: 16,
    fontWeight: 500,
    backgroundColor: "white",
    fontFamily: "Montserrat, sans-serif",
    marginTop: theme.spacing(3),
  },
  flexInput: {
    display: "flex",
  },
  separator: {
    margin: theme.spacing(0, 0.5),
  },
}));

const cardStyle = {
  placeholder: "Card Number",
  style: {
    base: {
      color: colorSystem.black,
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "500",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: colorSystem.gray,
        fontSize: "16px",
        fontWeight: "500",
      },
    },
    invalid: {
      fontFamily: "Montserrat, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
      fontSize: "16px",
      fontWeight: "500",
    },
  },
};

const SignUpCardDetails = ({
  setSavingCard,
  onSaveCard,
  clientSecret,
  plan,
  email,
  fullName,
  password,
  addressLine1,
  country,
  city,
  postalCode,
  setLoading,
  closePreloader,
}: any) => {
  const navigate = useNavigate();
  const s = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const snackAlert = useSnackAlert();
  const [registerCoachWithFreeLaunch] =
    useRegisterCoachWithFreeLaunchMutation();
  const [signIn] = useSignInMutation();
  const onError = useGenericErrorHandler();
  const [trackEvent] = useAnalytics();

  const [upsertBillingAddress] = useUpsertBillingAddressMutation();

  const handleUpdate = React.useCallback(() => {
    upsertBillingAddress({
      variables: {
        input: {
          street: addressLine1,
          city,
          postalCode,
          country,
          password,
        },
      },
      onSuccess() {
        setLoading(false);
        window.location.href = "/coach/programs/published?photoPrompt=true";
      },
    });
  }, [
    upsertBillingAddress,
    addressLine1,
    city,
    postalCode,
    country,
    setLoading,
    password,
  ]);

  const handleSaveCard = React.useCallback(async () => {
    try {
      setLoading(true);
      setSavingCard(false);
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      });
      if (result.error) {
        snackAlert({
          severity: "error",
          message: result.error.message,
        });
        setLoading(false);
        closePreloader();
      } else {
        snackAlert({
          severity: "success",
          message: "Card details saved",
        });
        registerCoachWithFreeLaunch({
          variables: {
            input: {
              email,
              displayName: fullName,
              password,
              timeZone: dayjs.tz.guess(),
              plan,
            },
          },
          onCompleted(data, errors) {
            if (errors && errors[0]) {
              const err = errors[0] as AppError;
              console.error(err);
              setLoading(false);
              closePreloader();
              snackAlert({
                severity: "error",
                message: err.message.includes("Your card was declined")
                  ? err.message
                  : SOMETHING_WENT_WRONG,
              });
            } else {
              firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((x) => x.user.getIdToken())
                .then((idToken) => {
                  // Using user's ID token, authenticate in the API
                  signIn({
                    variables: { idToken },
                    onCompleted(result, errors) {
                      const err = errors && errors[0];
                      if (err) {
                        if (err["code"]) {
                          onError(err);
                        }
                        closePreloader();
                        snackAlert({
                          severity: "error",
                          message: SOMETHING_WENT_WRONG,
                        });
                      } else {
                        trackEvent("SignUp");
                        handleUpdate();
                      }
                    },
                  });
                })
                .catch();
            }
          },
        });
        setSavingCard(false);
      }
    } catch (e) {
      setSavingCard(false);
      setLoading(false);
      closePreloader();
      console.error(e);
      snackAlert({
        severity: "error",
        message: SOMETHING_WENT_WRONG,
      });
    }
  }, [
    email,
    onError,
    password,
    plan,
    fullName,
    handleUpdate,
    clientSecret,
    elements,
    snackAlert,
    stripe,
    setSavingCard,
    trackEvent,
    signIn,
    registerCoachWithFreeLaunch,
    setLoading,
    closePreloader,
  ]);

  React.useEffect(() => {
    if (onSaveCard) {
      handleSaveCard();
    }
  }, [onSaveCard, handleSaveCard]);

  return (
    <>
      <Typography className={s.card} variant="h1">
        Card details
      </Typography>
      <Box className={s.input}>
        <CardNumberElement options={cardStyle} />
      </Box>
      <Box className={s.flexInput}>
        <Box className={s.input}>
          <CardExpiryElement
            options={{ ...cardStyle, placeholder: "MM / YY" }}
          />
        </Box>
        <div className={s.separator} />
        <Box className={s.input}>
          <CardCvcElement options={{ ...cardStyle, placeholder: "CVV" }} />
        </Box>
      </Box>
    </>
  );
};

export default SignUpCardDetails;
