import clsx from "clsx";
import React from "react";
import { Box, IconButton, IconButtonProps, Popover } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Picker from "@emoji-mart/react";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { ReactComponent as EmojiIcon } from "../../icons/Emoji.svg";

const useStyles = makeStyles((theme) => ({
  root: {},

  open: {
    color: theme.palette.primary.main,
  },
}));

export interface MessageEditorEmojiButtonProps extends IconButtonProps {
  onSelectEmoji?: (emoji: string) => void;
}

export function MessageEditorEmojiButton(props: MessageEditorEmojiButtonProps) {
  const { className, onSelectEmoji, ...other } = props;
  const s = useStyles();

  const popoverState = usePopupState({
    variant: "popover",
    popupId: "emoji-picker",
  });

  const handleEmojiSelect = React.useCallback(
    (emoji: { native: string }) => {
      popoverState.close();
      if (emoji.native && onSelectEmoji) {
        setTimeout(() => onSelectEmoji(emoji.native), 25);
      }
    },
    [onSelectEmoji, popoverState],
  );

  return (
    <>
      <IconButton
        className={clsx(s.root, className, popoverState.isOpen && s.open)}
        {...bindTrigger(popoverState)}
        {...other}
        size="large"
      >
        <EmojiIcon />
      </IconButton>

      {popoverState.isOpen && (
        <Popover
          open
          {...bindMenu(popoverState)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Box width={350}>
            <Picker
              native
              title="Pick your emoji..."
              emoji="point_up"
              onEmojiSelect={handleEmojiSelect}
            />
          </Box>
        </Popover>
      )}
    </>
  );
}
