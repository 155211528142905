/**
 * @generated SignedSource<<38292ba32730354f84ea141b29ddfc10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WeekCard_week$data = {
  readonly components: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"WeekComponentList_components">;
  } | null | undefined> | null | undefined;
  readonly description: string | null | undefined;
  readonly positions: ReadonlyArray<string>;
  readonly " $fragmentSpreads": FragmentRefs<"WeekDrawer_week">;
  readonly " $fragmentType": "WeekCard_week";
};
export type WeekCard_week$key = {
  readonly " $data"?: WeekCard_week$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekCard_week">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekCard_week",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WeekDrawer_week"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "positions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "archived",
          "value": true
        }
      ],
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "components",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WeekComponentList_components"
        }
      ],
      "storageKey": "components(archived:true)"
    }
  ],
  "type": "Week",
  "abstractKey": null
};

(node as any).hash = "fb5e71653eb4e2126d18b40c503d2173";

export default node;
