/**
 * @generated SignedSource<<0484ea307377c04cf86e83f9dcd5c9ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumEditScreenWeeksRefetchQuery$variables = {
  id: string;
};
export type CurriculumEditScreenWeeksRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CurriculumEditScreen_weeks">;
  } | null | undefined;
};
export type CurriculumEditScreenWeeksRefetchQuery = {
  response: CurriculumEditScreenWeeksRefetchQuery$data;
  variables: CurriculumEditScreenWeeksRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurriculumEditScreenWeeksRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CurriculumEditScreen_weeks"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurriculumEditScreenWeeksRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "after",
                    "value": ""
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 9999
                  }
                ],
                "concreteType": "WeeksConnection",
                "kind": "LinkedField",
                "name": "weeks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WeeksEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Week",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "week",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "weeks(after:\"\",first:9999)"
              }
            ],
            "type": "Program",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dbeadf78e4390b8692cb30a81adacd6",
    "id": null,
    "metadata": {},
    "name": "CurriculumEditScreenWeeksRefetchQuery",
    "operationKind": "query",
    "text": "query CurriculumEditScreenWeeksRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CurriculumEditScreen_weeks\n    id\n  }\n}\n\nfragment CurriculumEditScreen_weeks on Program {\n  weeks(first: 9999, after: \"\") {\n    edges {\n      node {\n        id\n        week\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "2f45785ec35a9ce4fbcd38ff0f11acef";

export default node;
