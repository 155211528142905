/**
 * @generated SignedSource<<c7b499a7a32adf0b851f560e93526dea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWeekComponentsOrderInput = {
  clientMutationId?: string | null | undefined;
  positions: ReadonlyArray<string>;
  programId: string;
  week: number;
};
export type ProgramSpreadsheetGridUpdateOrderMutation$variables = {
  input: UpdateWeekComponentsOrderInput;
};
export type ProgramSpreadsheetGridUpdateOrderMutation$data = {
  readonly updateWeekComponentsOrder: {
    readonly week: {
      readonly positions: ReadonlyArray<string>;
    };
  } | null | undefined;
};
export type ProgramSpreadsheetGridUpdateOrderMutation = {
  response: ProgramSpreadsheetGridUpdateOrderMutation$data;
  variables: ProgramSpreadsheetGridUpdateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "positions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProgramSpreadsheetGridUpdateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeekComponentsOrderPayload",
        "kind": "LinkedField",
        "name": "updateWeekComponentsOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProgramSpreadsheetGridUpdateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeekComponentsOrderPayload",
        "kind": "LinkedField",
        "name": "updateWeekComponentsOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8297781893b9a108902cd620a9416abb",
    "id": null,
    "metadata": {},
    "name": "ProgramSpreadsheetGridUpdateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation ProgramSpreadsheetGridUpdateOrderMutation(\n  $input: UpdateWeekComponentsOrderInput!\n) {\n  updateWeekComponentsOrder(input: $input) {\n    week {\n      positions\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a5a6852af84fcc6a4f2d21b98f29886";

export default node;
