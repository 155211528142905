import clsx from "clsx";
import React from "react";
import { Menu, MenuProps, MenuItem, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { UserRole } from "../../constants";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";

import { UserMenu_user$key } from "./__generated__/UserMenu_user.graphql";

const useStyles = makeStyles(() => ({
  root: {},
}));

const fragment = graphql`
  fragment UserMenu_user on User {
    id
    role
    admin
  }
`;

const updateUserMutation = graphql`
  mutation UserMenuUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        admin
      }
    }
  }
`;

export interface UserMenuProps extends Omit<MenuProps, "open" | "onClose"> {
  userRef: UserMenu_user$key;
}

export function UserMenu(props: UserMenuProps) {
  const { className, userRef, ...other } = props;
  const user = useFragment(fragment, userRef);
  const [updateUser] = useMutation(updateUserMutation);
  const { id: userId, admin } = user;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const switchUser = useSwitchUser();
  const onError = useGenericErrorHandler();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [addAsAdminDialogOpen, setAddAsAdminDialogOpen] = React.useState(false);

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLoginAs = React.useCallback(() => {
    switchUser(userId);
  }, [switchUser, userId]);

  const updateUserAdmin = React.useCallback(
    (admin: boolean) => {
      updateUser({
        variables: { input: { id: user.id, admin } },
        onCompleted(_, errors) {
          if (errors && errors[0]) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: admin
                ? "User added as admin."
                : "User removed from admins.",
            });
          }
        },
        onError,
      });
    },
    [onError, snackAlert, updateUser, user.id],
  );

  const handleUpdateAdmin = React.useCallback(() => {
    setAnchorEl(null);

    if (admin) {
      updateUserAdmin(false);
    } else {
      setAddAsAdminDialogOpen(true);
    }
  }, [admin, updateUserAdmin]);

  const handleConfirmAddAsAdmin = React.useCallback(() => {
    updateUserAdmin(true);
    setAddAsAdminDialogOpen(false);
  }, [updateUserAdmin]);

  const handleCloseAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(false);
  }, []);

  return (
    <>
      <IconButton aria-describedby={userId} onClick={handleOpen} size="large">
        <MoreHoriz />
      </IconButton>
      <Menu
        id={userId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        className={clsx(s.root, className)}
        {...other}
      >
        <MenuItem onClick={handleLoginAs}>Login as User</MenuItem>
        {user.role === UserRole.COACH && (
          <MenuItem onClick={handleUpdateAdmin}>
            {user.admin ? "Remove as Admin" : "Add as Admin"}
          </MenuItem>
        )}

        {/* <MenuItem onClick={() => null)}>
        Reset and Set Password
      </MenuItem> */}
      </Menu>

      <ConfirmActionDialog
        title="Are you sure you want to make this user an admin?"
        open={addAsAdminDialogOpen}
        onClose={handleCloseAddAsAdmin}
        onCancel={handleCloseAddAsAdmin}
        onConfirm={handleConfirmAddAsAdmin}
      />
    </>
  );
}
