import clsx from "clsx";
import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ManageGroupDialog } from "../groups/ManageGroupDialog";

import { GroupManageMembersButton_root$key } from "./__generated__/GroupManageMembersButton_root.graphql";
import { ActionButton, ActionButtonProps } from "../button/ActionButton";
import ManageGroupDialogContext from "../../contexts/ManageGroupDialogContext";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const rootFragment = graphql`
  fragment GroupManageMembersButton_root on Root
  @argumentDefinitions(slug: { type: "String!" }) {
    group(slug: $slug) {
      ...ManageGroupDialog_group
    }

    clients(status: ACTIVE, first: 9999) {
      ...ManageGroupDialog_clients
    }
  }
`;

export interface GroupManageMembersButtonProps extends ActionButtonProps {
  root: GroupManageMembersButton_root$key;
}

export function GroupManageMembersButton(props: GroupManageMembersButtonProps) {
  const { className, children, root: rootRef, ...other } = props;
  const root = useFragment(rootFragment, rootRef);
  const s = useStyles();

  const context = useContext(ManageGroupDialogContext);
  const { manageGroupDialogOpen, setManageGroupDialogOpen } = context;

  const handleCloseDialog = React.useCallback(() => {
    setManageGroupDialogOpen(false);
  }, []);

  return (
    <>
      {root.group && manageGroupDialogOpen && (
        <ManageGroupDialog
          open={true}
          onClose={handleCloseDialog}
          groupRef={root.group}
          clientsRef={root.clients}
        />
      )}
    </>
  );
}
