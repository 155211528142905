import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";

const preferredCountries = ["us", "ca", "gb", "au"];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .react-tel-input .special-label": {
      display: "none",
    },

    "& .react-tel-input ul.country-list": {
      padding: theme.spacing(1),
    },

    "& .react-tel-input ul.country-list .flag": {
      display: "none",
    },

    "& .react-tel-input ul.country-list li": {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 15,
      fontWeight: 500,

      padding: theme.spacing(1),
      borderRadius: 2,

      "&:hover": {
        backgroundColor: theme.palette.quote,
      },
    },
  },

  input: {
    width: "100%",
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 500,
    padding: theme.spacing(2.25, 1.75, 2.25, 7),
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: 1,

    "&:focus": {
      outlineColor: theme.palette.primary.main,
    },
  },
}));

export interface PhoneFieldProps
  extends Omit<FieldsGroupProps, "onChange" | "label"> {
  disabled?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
    countryCode: string | null,
  ) => void;
  value: string;
  countryCode?: string;
}

export function PhoneField(props: PhoneFieldProps) {
  const { className, onChange, value, countryCode, disabled, ...other } = props;
  const s = useStyles();
  const handleChange: PhoneInputProps["onChange"] = React.useCallback(
    (value, data, event) => {
      const countryCode = data ? (data as any).countryCode : null;
      const phone =
        value.length > 4 ? (value.startsWith("+") ? value : "+" + value) : null;

      onChange(event, phone, countryCode);
    },
    [onChange],
  );

  return (
    <FieldsGroup
      className={clsx(s.root, className)}
      label="Phone number"
      {...other}
    >
      <PhoneInput
        inputProps={{ className: s.input }}
        value={value}
        country={countryCode || "us"}
        preferredCountries={preferredCountries}
        onChange={handleChange}
        countryCodeEditable
        disabled={disabled}
      />
    </FieldsGroup>
  );
}
