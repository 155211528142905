import clsx from "clsx";
import React from "react";
import { DialogProps, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { uniq } from "lodash";

import {
  SelectableClient,
  SelectableClientProps,
} from "../item/SelectableClient";
import { colorSystem } from "../../theme";

import { BaseDialog } from "./BaseDialog";
import { ProgramEnrollmentStartDateViewDialog_enrollments$key } from "./__generated__/ProgramEnrollmentStartDateViewDialog_enrollments.graphql";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 490,
    height: 524,
  },

  date: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 0.75,
    color: theme.palette.text.secondary,
  },

  client: {
    cursor: "pointer",
    padding: theme.spacing(2.75, 0),

    "&:hover": {
      backgroundColor: `${colorSystem.black}0A`,
    },

    "&:not(:first-child)": {
      borderTopWidth: 1,
      borderTopColor: theme.palette.border.primary,
      borderTopStyle: "solid",
    },
  },
}));

const enrollmentsFragment = graphql`
  fragment ProgramEnrollmentStartDateViewDialog_enrollments on EnrollmentConnection {
    edges {
      node {
        id
        startDate(raw: true)
        client {
          displayName
          ...SelectableClient_client
        }
      }
    }
  }
`;

export interface ProgramEnrollmentStartDateViewDialogProps
  extends Omit<DialogProps, "onSelect"> {
  enrollments: ProgramEnrollmentStartDateViewDialog_enrollments$key;
  onSelect: (date: string, clientName: string) => void;
}

export function ProgramEnrollmentStartDateViewDialog(
  props: ProgramEnrollmentStartDateViewDialogProps,
) {
  const {
    className,
    enrollments: enrollmentsRef,
    onSelect,
    onClose,
    ...other
  } = props;
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const s = useStyles();

  const dates = React.useMemo(
    () =>
      uniq(enrollments.edges.map(({ node: { startDate } }) => startDate)).sort(
        (a, b) => (new Date(a).getTime() < new Date(b).getTime() ? -1 : 1),
      ),
    [enrollments],
  );

  const handleClientClick: SelectableClientProps["onClick"] = React.useCallback(
    (event) => {
      const {
        currentTarget: {
          dataset: { startDate, clientName },
        },
      } = event;

      if (startDate && clientName) {
        onSelect(startDate, clientName);
        onClose(event, "backdropClick");
      }
    },
    [onClose, onSelect],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="View using client start date"
      onClose={onClose}
      PaperProps={{
        className: s.paper,
      }}
      {...other}
    >
      {dates.map((date, index) => (
        <React.Fragment key={index}>
          <Typography className={s.date} variant="h6">
            {new Date(date).getTime() < new Date().getTime()
              ? "Started"
              : "Starts"}{" "}
            {dayjs(date).format("MMM D, YYYY")}
          </Typography>
          <Box>
            {enrollments.edges
              .filter(({ node: { startDate } }) => startDate === date)
              .map(({ node: { id, startDate, client } }) => (
                <SelectableClient
                  key={id}
                  className={s.client}
                  client={client}
                  data-start-date={startDate}
                  data-client-name={client.displayName}
                  onClick={handleClientClick}
                />
              ))}
          </Box>
        </React.Fragment>
      ))}
    </BaseDialog>
  );
}
