import clsx from "clsx";
import React, { ChangeEvent, useTransition } from "react";
import {
  Box,
  BoxProps,
  TextField,
  Typography,
  Button,
  TextFieldProps,
} from "@mui/material";
import { StyledComponentProps } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation, useRefetchableFragment } from "react-relay/hooks";

import { UnsplashImage, UnsplashImageValue } from "./UnsplashImage";
import {
  UnsplashImages_rootRef$data,
  UnsplashImages_rootRef$key,
} from "./__generated__/UnsplashImages_rootRef.graphql";
import { UnsplashImagesTrackMutation } from "./__generated__/UnsplashImagesTrackMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
  query: {
    marginBottom: theme.spacing(2),
  },
  queryInput: {
    fontWeight: 500,
  },
  resultsWrapper: {
    height: 350,
    overflowY: "auto",
  },
  empty: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
    margin: theme.spacing(2, 0),
  },
  grid: {
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "wrap",
    overflowY: "auto",
  },
  image: {
    height: theme.spacing(10),
    width: theme.spacing(18.5),
    marginBottom: theme.spacing(3.5),
    padding: 0,

    "&:last-of-type": {
      marginRight: "auto",
    },
  },
  more: {
    margin: theme.spacing(2, 0),
  },
}));

const trackMutation = graphql`
  mutation UnsplashImagesTrackMutation($input: TrackUnsplashPhotoInput!) {
    trackUnsplashPhoto(input: $input) {
      clientMutationId
    }
  }
`;

export interface UnsplashImagesProps
  extends Omit<BoxProps, "onSelect" | "classes">,
    StyledComponentProps<"root" | "image" | "imageSelected"> {
  rootRefRef: UnsplashImages_rootRef$key;
  onSelect?: (value: string) => void;
  limit?: number;
  searchInputProps?: TextFieldProps;
  defaultQuery?: string;
  searchInputVariant?: "standard" | "outlined" | "filled";
}

type ImageEdges = UnsplashImages_rootRef$data["unsplashImages"]["edges"];

const rootRefFragment = graphql`
  fragment UnsplashImages_rootRef on Root
  @refetchable(queryName: "UnsplashImagesRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int" }
    after: { type: "String" }
    query: { type: "String" }
    shouldFetchUnsplash: { type: "Boolean", defaultValue: false }
  ) {
    unsplashImages(first: $first, after: $after, query: $query)
      @include(if: $shouldFetchUnsplash) {
      edges {
        node {
          id
          ...UnsplashImage_image
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const mergeEdges = (left: ImageEdges, right: ImageEdges) => [
  ...left,
  ...right.filter(
    ({ node: { id: rid } }) =>
      !left.some(({ node: { id: lid } }) => lid === rid),
  ),
];

export function UnsplashImages(props: UnsplashImagesProps) {
  const {
    className,
    rootRefRef,
    defaultQuery = "",
    limit: first = 16,
    onSelect,
    searchInputProps = {},
    searchInputVariant,
    classes = {},
    ...other
  } = props;
  const [rootRef, rootRefRefetch] = useRefetchableFragment(
    rootRefFragment,
    rootRefRef,
  );
  const s = useStyles();
  const [query, setQuery] = React.useState(defaultQuery);
  const [after, setAfter] = React.useState<string | null>();
  const [selected, setSelected] = React.useState<string | null>(null);
  const [previousEdges, setPreviousEdges] = React.useState<ImageEdges>([]);
  const [isPending, setTransition] = useTransition();

  const { unsplashImages } = rootRef;
  const loading = !unsplashImages;
  const hasMore = !loading && unsplashImages.pageInfo.hasNextPage;

  const [trackUnsplashImage] =
    useMutation<UnsplashImagesTrackMutation>(trackMutation);

  const trackImage = React.useCallback(
    (unsplashImageId: string) => {
      trackUnsplashImage({
        variables: {
          input: {
            unsplashImageId,
          },
        },
      });
    },
    [trackUnsplashImage],
  );

  const handleChangeQuery = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTransition(() => {
        setAfter(null);
        setPreviousEdges([]);
        setQuery(event.target.value);
      });
    },
    [],
  );

  const handleMore = React.useCallback(() => {
    if (unsplashImages) {
      setTransition(() => {
        setAfter(unsplashImages.pageInfo.endCursor);
        setPreviousEdges(mergeEdges(previousEdges, unsplashImages.edges));
      });
    }
  }, [unsplashImages, previousEdges]);

  const handleSelect = React.useCallback(
    (image: UnsplashImageValue) => {
      setSelected(image.id);

      if (onSelect) {
        onSelect(image.urls.regular);
        trackImage(image.unsplashImageId);
      }
    },
    [onSelect, trackImage],
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTransition(() => {
        rootRefRefetch({
          first,
          after,
          query,
          shouldFetchUnsplash: true,
        });
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [first, query, rootRefRefetch, after]);

  const edges =
    !loading && unsplashImages
      ? mergeEdges(previousEdges, unsplashImages.edges)
      : previousEdges;

  return (
    <Box className={clsx(s.root, classes.root, className)} {...other}>
      <TextField
        className={s.query}
        InputProps={{ className: s.queryInput }}
        placeholder="Search for an image"
        variant={searchInputVariant}
        value={query}
        fullWidth
        disabled={loading}
        {...searchInputProps}
        onChange={handleChangeQuery}
      />

      <Box className={s.resultsWrapper}>
        {!loading && edges.length ? (
          <Box className={s.grid}>
            {edges.map(({ node }) => (
              <Box key={node.id} className={clsx(s.image, classes.image)}>
                <UnsplashImage
                  classes={{ selected: classes.imageSelected }}
                  imageRef={node}
                  onSelect={handleSelect}
                  selected={selected === node.id}
                />
              </Box>
            ))}

            {hasMore && (
              <Button className={s.more} onClick={handleMore} fullWidth>
                Load more
              </Button>
            )}
          </Box>
        ) : (
          <Typography variant="body1" className={s.empty}>
            {unsplashImages ? "No images found." : "Loading..."}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
