/**
 * @generated SignedSource<<523a4b0237830583f60f0354cef15f6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgram_enrollment$data = {
  readonly activities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientProgramWeek_activities">;
  } | null | undefined>;
  readonly completion: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientProgramWeek_completion">;
  };
  readonly currentWeek: number | null | undefined;
  readonly program: {
    readonly id: string;
    readonly length: number;
    readonly name: string;
    readonly slug: string;
    readonly weeks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly description: string | null | undefined;
          readonly id: string;
          readonly positions: ReadonlyArray<string>;
          readonly week: number;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  };
  readonly startDate: string | null | undefined;
  readonly " $fragmentType": "ClientProgram_enrollment";
};
export type ClientProgram_enrollment$key = {
  readonly " $data"?: ClientProgram_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProgram_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "week",
  "variableName": "week"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "week"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientProgram_enrollment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "completion",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "period",
          "value": "ALL_TIME"
        },
        (v0/*: any*/)
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientProgramWeek_completion"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activities",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientProgramWeek_activities"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "length",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "after",
              "value": ""
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 9999
            }
          ],
          "concreteType": "WeeksConnection",
          "kind": "LinkedField",
          "name": "weeks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WeeksEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Week",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "week",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "positions",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "weeks(after:\"\",first:9999)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "2c141b92b21d5ededde7613815dba462";

export default node;
