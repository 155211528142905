import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Divider, Button } from "@mui/material";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { ReactComponent as ManIcon } from "../../icons/man3.svg";
import { ReactComponent as WindowIcon } from "../../icons/window1.svg";
import { ReactComponent as CheckMarkCircleIcon } from "../../icons/CheckMarkCircle2.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin2.svg";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { UserRole } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { UpdateTrialExpirationDateDialog } from "../dialog/UpdateTrialExpirationDateDialog";

import { AdminPaper } from "./AdminPaper";
import { AdminLabeledValue } from "./AdminLabeledValue";
import { UserDetails_user$key } from "./__generated__/UserDetails_user.graphql";
import { UserDetailsUpdateUserMutation } from "./__generated__/UserDetailsUpdateUserMutation.graphql";
import { UserDetailsDeleteUserMutation } from "./__generated__/UserDetailsDeleteUserMutation.graphql";
import { AdminTag } from "./AdminTag";
import { useNavigate } from "react-router-dom";
import { ADMIN_USERS_ROUTE } from "../../routes/routes";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  paper: {
    flexGrow: 1,
  },

  info: {
    marginRight: theme.spacing(5),
  },

  actions: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  button: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },

  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));

const updateUserMutation = graphql`
  mutation UserDetailsUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        admin
        ...UserDetails_user
      }
    }
  }
`;

const deleteUserMutation = graphql`
  mutation UserDetailsDeleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      deletedUserId
    }
  }
`;

const userFragment = graphql`
  fragment UserDetails_user on User {
    id
    email
    admin
    role
    trialExpiryDate(raw: true)

    ...UpdateTrialExpirationDateDialog_user
  }
`;

export interface UserDetailsProps extends BoxProps {
  user: UserDetails_user$key;
}

export function UserDetails(props: UserDetailsProps) {
  const { className, user: userRef, ...other } = props;
  const navigate = useNavigate();
  const user = useFragment(userFragment, userRef);
  const s = useStyles();
  const [updateTrialDialogOpen, setUpdateTrialDialogOpen] =
    React.useState(false);
  const [addAsAdminDialogOpen, setAddAsAdminDialogOpen] = React.useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] =
    React.useState(false);
  const [updateUser, updatingUser] =
    useMutation<UserDetailsUpdateUserMutation>(updateUserMutation);
  const [deleteUser, deletingUser] =
    useMutation<UserDetailsDeleteUserMutation>(deleteUserMutation);
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const switchUser = useSwitchUser();

  const handleLogInAsUser = React.useCallback(() => {
    switchUser(user.id);
  }, [switchUser, user.id]);

  const handleUpdateTrial = React.useCallback(() => {
    setUpdateTrialDialogOpen(true);
  }, []);

  const handleCloseUpdateTrial = React.useCallback(() => {
    setUpdateTrialDialogOpen(false);
  }, []);

  const handleAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(true);
  }, []);

  const handleCloseAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(false);
  }, []);

  const updateUserAdmin = React.useCallback(
    (admin: boolean) => {
      updateUser({
        variables: { input: { id: user.id, admin } },
        onCompleted(_, errors) {
          if (errors && errors[0]) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: admin
                ? "User added as admin."
                : "User removed from admins.",
            });
          }
        },
        onError,
      });
    },
    [onError, snackAlert, updateUser, user.id],
  );

  const handleConfirmAddAsAdmin = React.useCallback(() => {
    updateUserAdmin(true);
    setAddAsAdminDialogOpen(false);
  }, [updateUserAdmin]);

  const handleRemoveAsAdmin = React.useCallback(() => {
    updateUserAdmin(false);
  }, [updateUserAdmin]);

  const handleDeleteAccount = React.useCallback(() => {
    setDeleteAccountDialogOpen(true);
  }, []);

  const handleCloseDeleteAccount = React.useCallback(() => {
    setDeleteAccountDialogOpen(false);
  }, []);

  const handleConfirmDeleteAccount = React.useCallback(() => {
    deleteUser({
      variables: { input: { id: user.id } },
      onCompleted(_, errors) {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: "User deleted.",
          });
          setDeleteAccountDialogOpen(false);
          navigate(ADMIN_USERS_ROUTE);
        }
      },
      onError,
    });
  }, [deleteUser, onError, snackAlert, user.id]);

  const disabled = updatingUser || deletingUser;

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <AdminPaper className={clsx(s.paper, s.info)} title="User Info">
          <AdminLabeledValue label="Email" value={user.email}>
            {user.admin && <AdminTag />}
          </AdminLabeledValue>

          <Divider />

          <AdminLabeledValue label="Role" value={user.role} />

          <Divider />

          <AdminLabeledValue
            label="Trial Expiration"
            value={dayjs.utc(user.trialExpiryDate).format("MMM D, YYYY")}
          />

          <Divider />

          <Box className={s.actions}>
            <Button
              className={s.button}
              variant="text"
              startIcon={<ManIcon />}
              onClick={handleLogInAsUser}
              children="Log in as user"
              disabled={disabled}
            />

            <Button
              className={s.button}
              variant="text"
              startIcon={<WindowIcon />}
              onClick={handleUpdateTrial}
              children="Update trial expiration"
              disabled={disabled}
            />
          </Box>
        </AdminPaper>

        <AdminPaper className={s.paper} title="Other actions">
          <Box className={s.actions}>
            {user.admin ? (
              <Button
                className={s.button}
                variant="text"
                startIcon={<CheckMarkCircleIcon />}
                onClick={handleRemoveAsAdmin}
                children="Remove as Admin"
                disabled={disabled}
              />
            ) : user.role === UserRole.COACH ? (
              <Button
                className={s.button}
                variant="text"
                startIcon={<CheckMarkCircleIcon />}
                onClick={handleAddAsAdmin}
                children="Add as Admin"
                disabled={disabled}
              />
            ) : null}
            <Button
              className={clsx(s.button, s.deleteButton)}
              startIcon={<BinIcon />}
              onClick={handleDeleteAccount}
              children="Delete account"
              disabled={disabled}
            />
          </Box>
        </AdminPaper>
      </Box>

      <UpdateTrialExpirationDateDialog
        user={user}
        open={updateTrialDialogOpen}
        onClose={handleCloseUpdateTrial}
      />

      <ConfirmActionDialog
        title="Are you sure you want to make this user an admin?"
        open={addAsAdminDialogOpen}
        onClose={handleCloseAddAsAdmin}
        onCancel={handleCloseAddAsAdmin}
        onConfirm={handleConfirmAddAsAdmin}
      />

      <ConfirmActionDialog
        title={`Are you sure you want to delete this ${user.role.toLowerCase()}?`}
        description={
          user.role === UserRole.COACH &&
          "All their data and clients will be removed and cannot be recovered."
        }
        open={deleteAccountDialogOpen}
        onClose={handleCloseDeleteAccount}
        onCancel={handleCloseDeleteAccount}
        onConfirm={handleConfirmDeleteAccount}
      />
    </>
  );
}
