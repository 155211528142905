import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { AppLogo } from "../app/AppLogo";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { AppError, AcceptClientInviteInvalidReason } from "../../constants";
import { colorSystem } from "../../theme";

import { ClientAcceptInviteInvalid } from "./ClientAcceptInviteInvalid";
import { useSignInMutation } from "./mutations/SignIn";
import { useSignUpMutation } from "./mutations/SignUp";
import { ClientAcceptInviteForm } from "./ClientAcceptInviteForm";
import { ClientAcceptInviteScreen_invite$key } from "./__generated__/ClientAcceptInviteScreen_invite.graphql";
import firebase from "firebase";
import { SIGN_UP_CLIENT_COMPLETE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ConsentBanner } from "../app/ConsentBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  logo: {
    marginBottom: theme.spacing(4),
  },
}));

const inviteFragment = graphql`
  fragment ClientAcceptInviteScreen_invite on Invite {
    id
    coach {
      displayName
    }
    accepted
  }
`;

export interface ClientAcceptInviteScreenProps extends BoxProps {
  inviteRef: ClientAcceptInviteScreen_invite$key;
  email: string;
  code: string;
  id: string;
}

export function ClientAcceptInviteScreen(props: ClientAcceptInviteScreenProps) {
  const { className, inviteRef, email, code, id, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const onError = useGenericErrorHandler();
  const user = useCurrentUser();
  const invite = useFragment(inviteFragment, inviteRef);

  const [signUp, signingUp] = useSignUpMutation();
  const [signIn, signingIn] = useSignInMutation();

  const handlePasswordChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      event.preventDefault();

      signUp({
        variables: {
          input: {
            id,
            email,
            password: password,
            timeZone: dayjs.tz.guess(),
          },
        },
        onCompleted({ signUp }, errors) {
          if (errors && errors[0]) {
            const err = errors[0] as AppError;
            if (err.code && err.code.includes("password")) {
              setPasswordError(err.message);
            } else {
              onError(errors[0]);
            }
          } else {
            setLoading(true);
            const idToken = signUp?.idToken;
            if (!idToken) {
              return;
            }
            firebase
              .auth()
              .applyActionCode(code)
              .then(() => firebase.auth().signInWithCustomToken(idToken))
              .then(() => {
                return firebase.auth().currentUser?.getIdToken();
              })
              .then((idToken) => {
                if (!idToken) {
                  return;
                }
                signIn({
                  variables: { idToken },
                  onCompleted(_, errors) {
                    if (errors && errors[0]) {
                      onError(errors[0]);
                    } else {
                      window.location.href = SIGN_UP_CLIENT_COMPLETE;
                    }
                  },
                  onError,
                });
              })
              .catch(onError)
              .finally(() => {
                setLoading(false);
              });
          }
        },
        onError,
      });
    },
    [code, email, id, onError, password, signIn, signUp],
  );

  if (user) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED}
      />
    );
  }

  if (!invite) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.INVITATION_REVOKED}
      />
    );
  }

  if (invite?.accepted) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.INVITATION_ACCEPTED}
      />
    );
  }

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo className={s.logo} />

      <ClientAcceptInviteForm
        coachName={invite.coach.displayName}
        email={email}
        password={password}
        passwordError={passwordError}
        onPasswordChange={handlePasswordChange}
        onSubmit={handleSubmit}
        submitDisabled={Boolean(
          password?.length < 8 || loading || signingUp || signingIn,
        )}
      />
      <ConsentBanner />
    </Box>
  );
}
