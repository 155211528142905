import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  Typography,
  Box,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { ArrowBack } from "@mui/icons-material";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { CardMedia } from "../card/CardMedia";
import { DynamicSearch } from "../list/DynamicSearch";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useDebounce } from "../../hooks/useDebounce";
import { SOMETHING_WENT_WRONG } from "../../constants";
import { LoadMoreButton } from "../button/LoadMoreButton";

import { CopyComponentDialog_component$key } from "./__generated__/CopyComponentDialog_component.graphql";
import { CopyComponentDialogMutation } from "./__generated__/CopyComponentDialogMutation.graphql";
import { CopyComponentDialogSearchPrograms_fragment$data } from "./__generated__/CopyComponentDialogSearchPrograms_fragment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  programs: {
    minWidth: 524,
    padding: theme.spacing(3),
  },

  weeks: {
    minWidth: 418,
    padding: theme.spacing(0),
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  searchInput: {
    color: theme.palette.text.secondary,
    "& > *": {
      borderRadius: 0,
      fontWeight: 500,
      fontSize: 16,
    },
  },

  program: {
    padding: theme.spacing(2, 0),
  },

  image: {
    width: 40,
    height: 40,
    borderRadius: 4,
    marginRight: theme.spacing(2),
  },

  primaryText: {
    fontSize: 18,
    fontWeight: 600,
  },

  list: {
    height: 350,
    overflowY: "auto",
  },

  secondaryText: {
    fontSize: 16,
    fontWeight: 500,
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },

  backIcon: {
    minWidth: theme.spacing(3),
    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },

  backText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },

  week: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
  },
}));

const copyComponentMutation = graphql`
  mutation CopyComponentDialogMutation($input: DuplicateComponentInput!) {
    duplicateComponent(input: $input) {
      component {
        id
      }
    }
  }
`;

const searchQuery = graphql`
  query CopyComponentDialogSearchProgramsQuery(
    $first: Int!
    $after: String
    $query: String
  ) {
    ...CopyComponentDialogSearchPrograms_fragment
      @arguments(first: $first, query: $query, after: $after)
  }
`;

const searchFragment = graphql`
  fragment CopyComponentDialogSearchPrograms_fragment on Root
  @argumentDefinitions(
    first: { type: "Int!" }
    after: { type: "String" }
    query: { type: "String" }
  ) {
    connection: programs(first: $first, after: $after, query: $query)
      @connection(key: "CopyPrograms_connection", filters: [$query]) {
      totalCount

      pageInfo {
        hasNextPage
      }

      edges {
        cursor
        node {
          id
          name
          length
          enrolledTotal
          imageURL
          weeks(first: 9999, after: "") {
            edges {
              node {
                id
                week
              }
            }
          }
        }
      }
    }
  }
`;

const componentFragment = graphql`
  fragment CopyComponentDialog_component on Component {
    id
    program {
      id
    }
  }
`;

export interface CopyComponentDialogProps extends DialogProps {
  componentRef: CopyComponentDialog_component$key;
}

export function CopyComponentDialog(props: CopyComponentDialogProps) {
  const s = useStyles();
  const { className, componentRef, ...other } = props;
  const component = useFragment(componentFragment, componentRef);
  const { program } = component;
  const [keywords, setKeywords] = React.useState("");
  const delayedFilter = useDebounce(keywords, 250);
  const [programId, setProgramId] = React.useState<string>();
  const snackAlert = useSnackAlert();
  const [copyComponent, disabled] = useMutation<CopyComponentDialogMutation>(
    copyComponentMutation,
  );

  const handleKeywordsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setKeywords(event.currentTarget.value);
    },
    [],
  );

  const handleClose = React.useCallback(
    (event) => {
      if (props.onClose) {
        props.onClose(event, "backdropClick");
      }
    },
    [props],
  );

  const handleProgramClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { programId },
      } = event.currentTarget;

      setProgramId(programId);
    },
    [],
  );

  const handleBackClick = React.useCallback(() => {
    setProgramId(null);
  }, []);

  const handleWeekClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { weekId } = event.currentTarget.dataset;
      const input = {
        id: component.id,
        newWeekId: weekId,
      };

      copyComponent({
        variables: { input },
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Component was copied",
            });
            handleClose(event);
          }
        },
      });
    },
    [component.id, copyComponent, handleClose, snackAlert],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: programId ? s.weeks : s.programs }}
      {...other}
    >
      <DynamicSearch<CopyComponentDialogSearchPrograms_fragment$data>
        query={searchQuery}
        filter={delayedFilter}
        fragment={searchFragment}
      >
        {({ connection: { edges }, hasMore, onMoreClick, loading }) => {
          const programs = edges
            .map(({ node }) => node)
            .filter((it) => it.id !== program.id);
          const selectedProgram = programs.find((it) => it.id === programId);

          return selectedProgram ? (
            <List>
              <ListItem button onClick={handleBackClick} disabled={disabled}>
                <ListItemIcon className={s.backIcon} children={<ArrowBack />} />
                <ListItemText
                  primary="Select week"
                  classes={{ primary: s.backText }}
                />
              </ListItem>
              <Divider />
              {selectedProgram.weeks.edges.map(({ node: { id, week } }) => (
                <ListItem
                  key={id}
                  data-week-id={id}
                  onClick={handleWeekClick}
                  button
                  disabled={disabled}
                >
                  <ListItemText
                    primary={`Week ${week}`}
                    classes={{ primary: s.week }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box>
              <Box className={s.header}>
                <Typography variant="h4" className={s.title} gutterBottom>
                  Choose a program
                </Typography>

                <IconButton onClick={handleClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box>
                <TextField
                  className={s.searchInput}
                  variant="outlined"
                  value={keywords}
                  onChange={handleKeywordsChange}
                  placeholder="Search programs"
                  fullWidth
                />
                <List className={s.list}>
                  {programs.length ? (
                    programs.map((node, index) => (
                      <React.Fragment key={node.id}>
                        {index > 0 && <Divider className={s.divider} />}

                        <ListItem
                          className={s.program}
                          button
                          data-program-id={node.id}
                          onClick={handleProgramClick}
                        >
                          <ListItemIcon>
                            <CardMedia
                              className={s.image}
                              image={node.imageURL}
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{
                              primary: s.primaryText,
                              secondary: s.secondaryText,
                            }}
                            primary={node.name}
                            secondary={`${node.length} week${
                              node.length === 1 ? "" : "s"
                            } • ${node.enrolledTotal} enrolled`}
                          />
                        </ListItem>
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography variant="subtitle1">
                      No programs found
                    </Typography>
                  )}
                  {hasMore && (
                    <LoadMoreButton onClick={onMoreClick} disabled={loading} />
                  )}
                </List>
              </Box>
            </Box>
          );
        }}
      </DynamicSearch>
    </Dialog>
  );
}
