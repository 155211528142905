/**
 * @generated SignedSource<<f5727563c098d6494c5ef4b8d9456cfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "CLIENT" | "COACH" | "%future added value";
export type AdminUsersRouteQuery$variables = {
  after?: string | null | undefined;
  page?: number | null | undefined;
  perPage: number;
  role: UserRole;
  search?: string | null | undefined;
};
export type AdminUsersRouteQuery$data = {
  readonly users: {
    readonly " $fragmentSpreads": FragmentRefs<"UsersTable_users">;
  } | null | undefined;
};
export type AdminUsersRouteQuery = {
  response: AdminUsersRouteQuery$data;
  variables: AdminUsersRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "role",
    "variableName": "role"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUsersRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UsersTable_users"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminUsersRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "admin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": "lastLoginAtRaw",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastLoginAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "fromNow"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "lastLoginAt",
                    "storageKey": "lastLoginAt(format:\"fromNow\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "programsCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbbb07358559a331024e4e161f4d630b",
    "id": null,
    "metadata": {},
    "name": "AdminUsersRouteQuery",
    "operationKind": "query",
    "text": "query AdminUsersRouteQuery(\n  $perPage: Int!\n  $role: UserRole!\n  $after: String\n  $search: String\n  $page: Int\n) {\n  users(first: $perPage, page: $page, after: $after, role: $role, search: $search) {\n    ...UsersTable_users\n  }\n}\n\nfragment UserMenu_user on User {\n  id\n  role\n  admin\n}\n\nfragment UsersTableRow_user on User {\n  ...UserMenu_user\n  username\n  displayName\n  email\n  lastLoginAtRaw: lastLoginAt\n  lastLoginAt(format: \"fromNow\")\n  role\n  photoURL\n  clientsCount\n  programsCount\n}\n\nfragment UsersTable_users on UserConnection {\n  totalCount\n  edges {\n    node {\n      id\n      ...UsersTableRow_user\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ba6bb53e4ec8916795b2609f83991bb";

export default node;
