import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useLazyLoadQuery } from "react-relay/hooks";

import { useClient } from "../../hooks/useClient";

import { getISODate } from "../../utils/date";
import { ClientProgramProgressCard } from "../card/ClientProgramProgressCard";
import { ClientMeasurementsCard } from "../card/ClientMeasurementsCard";
import { ClientNutritionCard } from "../client-nutrition-targets/ClientNutritionCard";
import { ClientGoalsCard } from "../card/ClientGoalsCard";
import { ClientProgressPhotosCard } from "../card/ClientProgressPhotosCard";
import { ProgressDialog } from "../client-progress/ProgressDialog";
import { ClientFormsCard } from "../client-forms/ClientFormsCard";

import { ClientSummary } from "./ClientSummary";
import { ClientSettingsDialog } from "./ClientSettingsDialog";
import { ClientStepsCard } from "../client-steps/ClientStepsCard";
import { ClientBodyWeightCard } from "../client-body-weight/ClientBodyWeightCard";
import ClientWorkoutsExercises from "../client-workouts/ClientWorkoutsExercises";
import { ClientDetailsQuery } from "./__generated__/ClientDetailsQuery.graphql";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    maxWidth: breakpoints.values.slg,
  },
  button: {
    color: palette.common.white,
    borderColor: palette.common.white,
  },
  status: {
    marginTop: "auto",
    marginLeft: spacing(5),
    marginBottom: spacing(3),
  },
  bodyweightCard: {
    [breakpoints.up("lg")]: {
      height: 224,
    },
  },
}));

const fragment = graphql`
  fragment ClientDetails_client on User {
    id
    username
    displayName
    timeZone

    enrollments {
      id
      active
      endDateRaw: endDate(raw: true)
      ...ProgressDialog_enrollments
      ...ClientProgramProgressCard_enrollment
    }

    nutritionTarget {
      ...ClientNutritionCard_nutritionTarget
    }

    nutritionMacroTarget {
      id
      trackingType
      targetType
      commentType
      carbsGrams
      proteinGrams
      fatGrams
      calories
      currentCalories
      currentFat
      currentCarbs
      currentProtein
      date(raw: true)
    }

    nutritionPortionTarget {
      id
      trackingType
      targetType
      commentType
      carbsGrams
      proteinGrams
      fatGrams
      calories
      currentCalories
      currentFat
      currentCarbs
      currentProtein
      date(raw: true)
    }

    goals(first: 9999) @connection(key: "ClientDetails_goals", filters: []) {
      edges {
        cursor
      }
      ...ClientGoalsCard_goals
    }

    ...ClientSummary_client
    ...ClientSettingsDialog_client
    ...ClientBodyWeightCard_client
    ...ClientStepsCard_client
    ...ClientProgressPhotosCard_client
    ...ClientMeasurementsCard_client
    ...ClientFormsCard_client
  }
`;

const today = getISODate();

type TagsProps = {
  node: {
    title: string;
    id: string;
  };
};

export enum ClientDetailsDialogMode {
  SETTINGS = "settings",
  PROGRESS = "progress",
  STATS = "stats",
}

export interface ClientDetailsProps extends Omit<ContainerProps, "children"> {
  dialog?: ClientDetailsDialogMode;
  tags?: TagsProps[];
}

const ClientDetails = (props: ClientDetailsProps) => {
  const { className, tags, dialog } = props;
  const navigate = useNavigate();
  const clientRef: any = useClient();

  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const client = useFragment(fragment, clientRef);
  const { enrollments, goals } = client;

  const { targets }: any = useLazyLoadQuery(
    graphql`
      query ClientDetailsQuery($username: String!) {
        targets: user(username: $username) {
          nutritionTarget {
            ...ClientNutritionCard_nutritionTarget
          }
          nutritionMacroTarget {
            ...ClientNutritionCard_nutritionMacroTarget
          }
          nutritionPortionTarget {
            ...ClientNutritionCard_nutritionPortionTarget
          }
          autoNutrition {
            id
            ...ClientNutritionCard_autoNutrition
          }
          autoNutritionLastSync(format: "h:mm A, MMM DD, YYYY")
          autoNutritionMacroTarget: nutritionMacroTarget {
            ...ClientNutritionCard_autoNutritionMacroTarget
          }
        }
      }
    `,
    {
      username: clientRef.username,
    },
    {
      fetchPolicy: "store-or-network",
    },
  );

  return (
    <Container className={clsx(s.root, className)}>
      <Grid sx={{ mb: 3 }} container spacing={3}>
        <Grid item xs={12} lg={12}>
          <ClientSummary clientRef={client} />
        </Grid>

        <Grid item xs={12}>
          <ClientStepsCard clientRef={client} />
        </Grid>

        {enrollments.map((enrollment) => {
          let show = enrollment.active;

          if (!show) {
            const now = client.timeZone
              ? dayjs(today).tz(client.timeZone)
              : dayjs(today).utc();
            const end = client.timeZone
              ? dayjs(enrollment.endDateRaw).tz(client.timeZone)
              : dayjs(enrollment.endDateRaw).utc();

            show = now.diff(end, "days") <= 42;
          }

          return (
            show && (
              <Grid key={enrollment.id} item xs={12}>
                <ClientProgramProgressCard enrollmentRef={enrollment} />
              </Grid>
            )
          );
        })}

        <Grid item xs={12}>
          <ClientFormsCard client={client} />
        </Grid>

        <Grid item xs={12}>
          <ClientNutritionCard
            nutritionTargetRef={targets.nutritionTarget}
            nutritionPortionTargetRef={targets.nutritionPortionTarget}
            nutritionMacroTargetRef={targets.nutritionMacroTarget}
            autoNutritionRef={targets.autoNutrition}
            autoNutritionLastSync={targets.autoNutritionLastSync}
            autoNutritionMacroTargetRef={targets.autoNutritionMacroTarget}
            username={clientRef.username}
          />
        </Grid>

        <Grid item xs={12}>
          <ClientGoalsCard goalsRef={goals} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ClientBodyWeightCard clientRef={client} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ClientMeasurementsCard clientRef={client} />
        </Grid>

        <Grid item xs={12}>
          <ClientProgressPhotosCard clientRef={client} />
        </Grid>

        <Grid item xs={12}>
          <ClientWorkoutsExercises typeBox="workout" clientRef={client} />
        </Grid>

        {!smUp && (
          <Grid item xs={12}>
            <ClientWorkoutsExercises typeBox="exercises" clientRef={client} />
          </Grid>
        )}
      </Grid>

      <ProgressDialog
        open={dialog === ClientDetailsDialogMode.PROGRESS}
        onClose={() => navigate(-1)}
        clientName={client.displayName}
        enrollments={enrollments}
      />

      <ClientSettingsDialog
        align="top"
        open={dialog === ClientDetailsDialogMode.SETTINGS}
        onClose={() => navigate(-1)}
        client={client}
        tags={tags}
      />
    </Container>
  );
};

export default ClientDetails;
