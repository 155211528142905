import React from "react";
import { useAnalytics } from "../../hooks/useAnalytics";

interface ITrackInfoToolProps {
  trackInfo: {
    name?: string;
    properties?: Object;
  };
}

const TrackInfoTool = ({ trackInfo }: ITrackInfoToolProps) => {
  const [, trackPage] = useAnalytics();

  if (trackInfo) {
    trackPage(trackInfo.name, trackInfo.properties);
  }

  return <></>;
};

export default TrackInfoTool;
