import React, { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    color: colorSystem.gray,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
}));

export type EmptyTextProps = TypographyProps;

const EmptyText: FC<EmptyTextProps> = (props) => {
  const { children, ...restProps } = props;

  const s = useStyles(props);

  return (
    <Typography variant="body1" className={s.root} {...restProps}>
      You don’t have synced nutrition entries for current day
      {children}
    </Typography>
  );
};

export default EmptyText;
