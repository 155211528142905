import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  },

  common: {
    backgroundColor: `${theme.palette.text.secondary}1A`,
    borderRadius: theme.spacing(0.5),
  },

  bar1: {
    height: 10,
    width: theme.spacing(6),
    marginBottom: theme.spacing(0.5),
  },

  bar2: {
    height: 11,
    width: theme.spacing(9),
    marginBottom: theme.spacing(1),
  },

  bar3: {
    height: theme.spacing(2.5),
    width: "100%",
  },
}));

export interface EmptyValueBarsProps extends BoxProps {}

export function EmptyValueBars(props: EmptyValueBarsProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={clsx(s.common, s.bar1)} />
      <Box className={clsx(s.common, s.bar2)} />
      <Box className={clsx(s.common, s.bar3)} />
    </Box>
  );
}
