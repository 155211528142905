/**
 * @generated SignedSource<<892aae0d7dfad8f1727161e87e849706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssetType = "CLIENT_FORM_IMAGE" | "COMPONENT_TEMPLATE" | "FEEDBACK_FILE" | "FEEDBACK_IMAGE" | "GROUP_IMAGE" | "LIBRARY_AUDIO" | "LIBRARY_FILE" | "LIBRARY_IMAGE" | "LIBRARY_VIDEO" | "MEAL_PHOTO" | "MESSAGE_FILE" | "MESSAGE_IMAGE" | "PORTAL_LOGO" | "PROGRAM_AUDIO" | "PROGRAM_CHECKIN_FILE" | "PROGRAM_CHECKIN_PHOTO" | "PROGRAM_COVER" | "PROGRAM_FILE" | "PROGRAM_IMAGE" | "PROGRAM_VIDEO" | "USER_PHOTO" | "WORKOUT_MEDIA" | "%future added value";
export type UploadAvatarCreateUrlMutation$variables = {
  file: string;
  id: string;
  type: AssetType;
};
export type UploadAvatarCreateUrlMutation$data = {
  readonly createSignedUrl: {
    readonly url: string | null | undefined;
  } | null | undefined;
};
export type UploadAvatarCreateUrlMutation = {
  response: UploadAvatarCreateUrlMutation$data;
  variables: UploadAvatarCreateUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "file"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "file",
        "variableName": "file"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "CreateSignedUrlPayload",
    "kind": "LinkedField",
    "name": "createSignedUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadAvatarCreateUrlMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadAvatarCreateUrlMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "1bc5c4499d37242985f71f4e5fba303b",
    "id": null,
    "metadata": {},
    "name": "UploadAvatarCreateUrlMutation",
    "operationKind": "mutation",
    "text": "mutation UploadAvatarCreateUrlMutation(\n  $id: ID!\n  $file: String!\n  $type: AssetType!\n) {\n  createSignedUrl(id: $id, file: $file, type: $type) {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "47feb9dc72c4fc6eed427a899bee6c10";

export default node;
