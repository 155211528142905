import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";
import {
  ExerciseTypeSet,
  UnitType,
  Units,
  getClientUnits,
} from "../../../../../constants";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { unitSetLabelCoach } from "../../../../../utils/units";

export const WeightTypesInfoSection = () => {
  const me = useCurrentUser();
  const defaultCoachUnits = me.units ?? Units.US;

  return (
    <InfoSection
      title="Types"
      description={
        <>
          Name of <b>Distance</b> / <b>Time</b> / <b>Weight</b> column is
          automatic. <br /> Just pick measurement type and column name will keep
          up. <br /> <i>(based on the type used the most in the column)</i>
        </>
      }
    >
      <InfoBox
        title="Distance"
        description="Use profile measurement unit or customize for exercise."
      >
        <ExampleValueBadge
          value="200"
          unit={unitSetLabelCoach(defaultCoachUnits, ExerciseTypeSet.DISTANCE)}
        />
      </InfoBox>

      <InfoBox
        title="Time"
        description="Pick from min / sec units for time measurement."
      >
        <ExampleValueBadge value="15" unit="min" />
        <ExampleValueBadge value="30" unit="sec" />
      </InfoBox>

      <InfoBox
        title="Weight"
        description="Use profile measurement unit or customize for exercise."
      >
        <ExampleValueBadge
          value="10"
          unit={unitSetLabelCoach(defaultCoachUnits, ExerciseTypeSet.WEIGHT)}
        />
      </InfoBox>
    </InfoSection>
  );
};
