import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, ContainerProps, Typography } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { colorSystem } from "../../theme";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/lib/hooks";
import { SOMETHING_WENT_WRONG } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { CoachCardInfoFormSaveCoachCreditCardForFutureUsageMutation } from "./__generated__/CoachCardInfoFormSaveCoachCreditCardForFutureUsageMutation.graphql";

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 16,
    marginTop: theme.spacing(2),
  },

  cardTitle: {
    margin: theme.spacing(2, 0),
  },

  separator: {
    margin: theme.spacing(0, 0.5),
  },

  flexInput: {
    display: "flex",
  },

  input: {
    margin: theme.spacing(0.5, 0),
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.border2,
    padding: theme.spacing(2.3, 1.9),
    fontSize: 16,
  },

  subtext: {
    marginTop: theme.spacing(1.5),
    textAlign: "center",
    color: colorSystem.gray2,
    fontSize: 12,
  },
}));

const cardStyle = {
  placeholder: "Card Number",
  style: {
    base: {
      color: colorSystem.black,
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: colorSystem.gray,
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export interface CoachCardInfoFormProps
  extends Omit<ContainerProps, "children"> {
  isNewUser?: boolean;
}

const setUpCoachStripeCardMutation = graphql`
  mutation CoachCardInfoFormSaveCoachCreditCardForFutureUsageMutation(
    $input: ChangeCoachSavedCreditCardInput!
  ) {
    changeCoachSavedCreditCard(input: $input) {
      client_secret
    }
  }
`;

export const CoachCardInfoForm = (props: CoachCardInfoFormProps) => {
  const s = useStyles();
  const [processing, setProcessing] = React.useState(false);
  const snackAlert = useSnackAlert();
  const stripe = useStripe();
  const elements = useElements();

  const [createClientSecret, creatingClientSecret] =
    useMutation<CoachCardInfoFormSaveCoachCreditCardForFutureUsageMutation>(
      setUpCoachStripeCardMutation,
    );

  const handleSubmit = React.useCallback(() => {
    createClientSecret({
      variables: { input: {} },
      async onCompleted(data, errors) {
        if (errors?.length) {
          console.error(errors[0]);
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
        } else {
          const { client_secret: clientSecret } =
            data.changeCoachSavedCreditCard;

          try {
            setProcessing(true);
            const result = await stripe.confirmCardSetup(clientSecret, {
              payment_method: {
                card: elements.getElement(CardNumberElement),
              },
            });

            if (result.error) {
              setProcessing(false);
              snackAlert({
                severity: "error",
                message: result.error.message,
              });
            } else {
              setTimeout(() => {
                setProcessing(false);
                snackAlert({
                  severity: "success",
                  message: "Card details saved",
                });
                const path = "/coach/plan";
                window.location.href = `${window.location.protocol}//${window.location.hostname}${path}`;
              }, 1500);
            }
          } catch (e) {
            console.error(e);
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          }
        }
      },
      onError(err) {
        console.error(err);
      },
    });
  }, [stripe, elements, createClientSecret, snackAlert]);

  return (
    <form id="payment-form">
      <Typography className={s.cardTitle} variant="h3">
        Enter card details
      </Typography>
      <CardNumberElement className={s.input} options={cardStyle} />
      <div className={s.flexInput}>
        <CardExpiryElement
          className={s.input}
          options={{ ...cardStyle, placeholder: "MM / YY" }}
        />
        <div className={s.separator} />
        <CardCvcElement
          className={s.input}
          options={{ ...cardStyle, placeholder: "CVV" }}
        />
      </div>
      <Button
        className={s.submit}
        variant="contained"
        children="Confirm"
        fullWidth
        onClick={handleSubmit}
        disabled={creatingClientSecret || processing}
      />
      <Typography className={s.subtext} variant="subtitle2">
        By confirming, you allow Growth Machine to charge your card for this
        payment and future payments in accordance with their terms.
      </Typography>
    </form>
  );
};
