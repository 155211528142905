import React from "react";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { CoachBrandedAppInfo } from "../../../../components/settings/CoachBrandedAppInfo";

export function CoachBrandedAppRoute() {
  return (
    <>
      <CoachBrandedAppInfo />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - My Branded App",
        }}
      />
    </>
  );
}
