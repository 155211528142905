/**
 * @generated SignedSource<<193d83a287ee193b1412f9248116c61c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteInviteCodeInput = {
  clientMutationId?: string | null | undefined;
  groupId?: string | null | undefined;
  programId?: string | null | undefined;
};
export type InviteLinkSettingsDeleteInviteCodeMutation$variables = {
  input: DeleteInviteCodeInput;
};
export type InviteLinkSettingsDeleteInviteCodeMutation$data = {
  readonly deleteInviteCode: {
    readonly deleted: boolean;
  } | null | undefined;
};
export type InviteLinkSettingsDeleteInviteCodeMutation = {
  response: InviteLinkSettingsDeleteInviteCodeMutation$data;
  variables: InviteLinkSettingsDeleteInviteCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteInviteCodePayload",
    "kind": "LinkedField",
    "name": "deleteInviteCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteLinkSettingsDeleteInviteCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteLinkSettingsDeleteInviteCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "130dd4f7f0305ef5f2f6825ecfc23997",
    "id": null,
    "metadata": {},
    "name": "InviteLinkSettingsDeleteInviteCodeMutation",
    "operationKind": "mutation",
    "text": "mutation InviteLinkSettingsDeleteInviteCodeMutation(\n  $input: DeleteInviteCodeInput!\n) {\n  deleteInviteCode(input: $input) {\n    deleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "6aded2b22bb8d21dfda34637d9207a74";

export default node;
