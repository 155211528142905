import React from "react";
import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import type { GroupPostCommentsListItemProps } from "./GroupPostCommentsListItem";
import { GroupPostCommentsListItem } from "./GroupPostCommentsListItem";
import { GroupPostCommentsListItemNoReplies_comment$key } from "./__generated__/GroupPostCommentsListItemNoReplies_comment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::before": {
      left: "21px !important",
    },
  },

  header: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },

  avatar: {
    marginLeft: "0 !important",
  },

  body: {
    paddingLeft: "56px !important",
  },
}));

const commentFragment = graphql`
  fragment GroupPostCommentsListItemNoReplies_comment on GroupPostComment {
    ...GroupPostCommentsListItem_comment @arguments(fetchReplies: false)
  }
`;

export interface GroupPostCommentsListItemNoRepliesProps
  extends BoxProps,
    Pick<GroupPostCommentsListItemProps, "first" | "onReplyClick"> {
  comment: GroupPostCommentsListItemNoReplies_comment$key;
}

export function GroupPostCommentsListItemNoReplies(
  props: GroupPostCommentsListItemNoRepliesProps,
) {
  const { className, comment: commentRef, ...other } = props;
  const comment = useFragment(commentFragment, commentRef);
  const s = useStyles();

  return (
    <GroupPostCommentsListItem
      commentRef={comment}
      className={s.root}
      headerClassName={s.header}
      avatarClassName={s.avatar}
      bodyClassName={s.body}
      {...other}
    />
  );
}
