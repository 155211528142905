import React from "react";
import { matchPath } from "react-router-dom";

import {
  COACH_GROUP_MEMBERS_ROUTE,
  COACH_GROUP_POST_ROUTE,
} from "../../../routes/routes";
import { GroupPostsAddButton } from "../../../components/group-posts/GroupPostsAddButton";
import { ActionButton } from "../../../components/button/ActionButton";

export const getActionForGroup = (
  pathname: string,
  groupRouteQuery,
  setManageGroupDialogOpen,
) => {
  const group = groupRouteQuery.group;
  const me = groupRouteQuery.me;
  const canPost =
    group && (!group.membersCanNotPost || group.coachId === me?.id);

  const canManageMembers = group.coachId === me?.id;

  if (matchPath(COACH_GROUP_POST_ROUTE, pathname)) {
    return (
      canPost && [
        <GroupPostsAddButton groupId={group.id} children="New post" />,
      ]
    );
  }

  if (matchPath(COACH_GROUP_MEMBERS_ROUTE, pathname)) {
    return (
      canManageMembers && [
        <ActionButton onClick={() => setManageGroupDialogOpen(true)}>
          Manage members
        </ActionButton>,
      ]
    );
  }
  return [<></>];
};
