import React from "react";
import { Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as NutritionTargetsEmptyIcon } from "../../icons/NutritionTargetsEmpty.svg";
import { colorSystem } from "../../theme";
import { useClient } from "../../hooks/useClient";

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    width: "100%",
    textAlign: "center",
    backgroundColor: colorSystem.gray10,
    borderRadius: 4,
    borderColor: colorSystem.gray7,
    borderWidth: 1,
    borderStyle: "solid",
    padding: theme.spacing(3, 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  emptyText: {
    color: colorSystem.gray,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },

  button: {
    fontWeight: 700,
    borderWidth: 2,
    borderColor: colorSystem.black,
    width: 170,
    height: 56,
  },
}));

export function ClientNutritionTargetEmpty(props) {
  const { onCreate, ...other } = props;
  const s = useStyles();

  const client = useClient();

  return (
    <Box className={s.emptyContainer}>
      <NutritionTargetsEmptyIcon />
      <Typography variant="body1" className={s.emptyText}>
        You don’t have nutrition plan yet
      </Typography>
      {Boolean(client) && (
        <Button
          className={s.button}
          children="Create"
          variant="outlined"
          {...other}
        />
      )}
    </Box>
  );
}
