import React from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

export interface QueryParamOptions<T> {
  normalize?: (x: any) => T;
  silent?: boolean;
}

const defaultOptions = {
  normalize: <T>(x: any): T => x as unknown as T,
  silent: false,
};

export function useQueryParam<T = string>(
  name: string,
  defaultValue: T = null,
  options: QueryParamOptions<T> = {},
): [T, (x: T) => void] {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { normalize, silent } = {
    ...defaultOptions,
    ...options,
  } as QueryParamOptions<T>;
  const initialValue = normalize(searchParams.get(name)) || defaultValue;

  const [silentValue, setSilentValue] = React.useState<T>(initialValue);
  const value = silent ? silentValue : initialValue;
  const setValue = React.useCallback(
    (value: T) => {
      const newParams = new URLSearchParams(searchParams);

      if (value !== null && value !== undefined) {
        newParams.set(name, value as unknown as string);
      } else {
        newParams.delete(name);
      }

      const newUrl = `${location.pathname}?${newParams.toString()}`;
      const { scrollX, scrollY } = window;

      if (silent) {
        setSilentValue(value);
        navigate(newUrl, { replace: true });
      } else {
        navigate(newUrl, { replace: true, state: location.state });
        requestAnimationFrame(() => window.scrollTo(scrollX, scrollY));
      }
    },
    [searchParams, location.pathname, location.state, silent, name, navigate],
  );

  return [value, setValue];
}
