import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachClientsFormsListScreen } from "../../../../components/coach-client-forms/CoachClientsFormsListScreen";
import { CoachClientsFormsRouteQuery } from "./__generated__/CoachClientsFormsRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

export function CoachClientsFormsRoute() {
  const props = useLazyLoadQuery<CoachClientsFormsRouteQuery>(
    graphql`
      query CoachClientsFormsRouteQuery {
        ...CoachClientsFormsListScreen_root
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  return (
    <>
      <CoachClientsFormsListScreen root={props} />
    </>
  );
}
