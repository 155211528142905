import clsx from "clsx";
import React, { useTransition } from "react";
import { Container, ContainerProps, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";

import { ActivityResponseCard } from "../activity/ActivityResponseCard";
import { defaultCoachActivityFilters } from "../../constants";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getCoachActivityTypes } from "../activity/CoachActivity";
import { LoadMoreButton } from "../button/LoadMoreButton";

import { ClientActivityScreen_root$key } from "./__generated__/ClientActivityScreen_root.graphql";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { PageSkeleton } from "../loading/PageSkeleton";
import {
  ActivityTypesFilter,
  ActivityTypesFilterProps,
} from "../filters/ActivityTypesFilter";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
  },

  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },

  more: {
    marginBottom: theme.spacing(4),
  },
}));

const rootFragment = graphql`
  fragment ClientActivityScreen_root on Root
  @refetchable(queryName: "ClientActivityScreenRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 5 }
    after: { type: "String" }
    withAnswersOnly: { type: "Boolean", defaultValue: true }
    withMealLogging: { type: "Boolean", defaultValue: true }
    withClientForms: { type: "Boolean", defaultValue: true }
    orderBy: { type: "ActivitySortBy", defaultValue: COMPLETED_AT_DESC }
    activityTypes: { type: "[ActivityType!]" }
  ) {
    activities: activitiesConn(
      client: "$me"
      first: $first
      after: $after
      withAnswersOnly: $withAnswersOnly
      withMealLogging: $withMealLogging
      withClientForms: $withClientForms
      orderBy: $orderBy
      activityTypes: $activityTypes
    ) @connection(key: "CoachActivity_activities", filters: []) {
      totalCount
      edges {
        node {
          ...ActivityResponseCard_activity
          id
        }
      }
    }
  }
`;

export interface ClientActivityScreenProps
  extends Omit<ContainerProps, "children"> {
  rootRef: ClientActivityScreen_root$key;
  pageSize?: number;
}

export function ClientActivityScreen(props: ClientActivityScreenProps) {
  const { className, rootRef, pageSize = 5, ...other } = props;
  const {
    data: root,
    loadNext,
    hasNext,
    refetch: refetchConnection,
  } = usePaginationFragment(rootFragment, rootRef);
  const [loading, setLoading] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [isPending, setTransition] = useTransition();
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const [visibleModalVideo, setVisibleModalVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);

  const s = useStyles();
  const [filters, setFilters] = React.useState(defaultCoachActivityFilters);

  const handleFiltersChange: ActivityTypesFilterProps["onChange"] =
    React.useCallback(
      (filters) => {
        setFilters(filters);
        setPending(true);
      },
      [setFilters],
    );

  const handleMoreClick = React.useCallback(() => {
    loadNext(pageSize);
  }, [pageSize, loadNext]);

  React.useEffect(() => {
    if (pending && !loading) {
      setPending(false);
      setTransition(() => {
        refetchConnection(
          {
            activityTypes: getCoachActivityTypes(filters),
            first: pageSize,
          },
          {
            onComplete: setLoaded,
            ...RELAY_LAZY_LOAD_COMMON_CONFIG,
          },
        );
      });
    }
  }, [pageSize, refetchConnection, setLoaded, loading, filters, pending]);

  const handleOpenVideo = React.useCallback((url) => {
    setVisibleModalVideo(true);
    setVideoUrl(url);
  }, []);

  const handleCloseVideo = React.useCallback(() => {
    setVisibleModalVideo(false);
    setVideoUrl(null);
  }, []);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.actions}>
        <ActivityTypesFilter filters={filters} onChange={handleFiltersChange} />
      </Box>
      {!root || loading || pending || isPending ? (
        <PageSkeleton />
      ) : root.activities.edges.length > 0 ? (
        <>
          {root.activities.edges.map(({ node: activity }) => (
            <ActivityResponseCard
              key={activity.id}
              activityRef={activity}
              enableArchive={false}
              handleOpenVideo={handleOpenVideo}
            />
          ))}

          {hasNext && (
            <LoadMoreButton
              className={s.more}
              onClick={handleMoreClick}
              disabled={loading}
            />
          )}
        </>
      ) : (
        <Typography>There are no new activities</Typography>
      )}
      <ConfirmActionDialog
        title=""
        onCancel={handleCloseVideo}
        onConfirm={() => {}}
        open={visibleModalVideo}
        activity
        videoUrl={videoUrl}
      />
    </Container>
  );
}
