import { useEffect, useState } from "react";
import { useAppMetadata } from "./useAppMetadata";

interface MainenanceMode {
  enabled: boolean;
}

export interface AppMetadata {
  forceAppVersion: string;
  softAppVersion: string;
  isMaintenance: boolean;
}

export function useMainenanceMode(): MainenanceMode {
  const [enabled, setEnabled] = useState<boolean>(false);

  const appMetadata = useAppMetadata();

  useEffect(() => {
    if (!appMetadata) {
      setEnabled(false);
    } else {
      setEnabled(appMetadata.isMaintenance);
    }
  }, [appMetadata]);

  return {
    enabled,
  };
}
