import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { ClientSettingsProfile } from "../../../../components/settings/ClientSettingsProfile";
import { clientSettingsNavigation } from "../utils/common";
import { ClientSettingsProfileRouteQuery } from "./__generated__/ClientSettingsProfileRouteQuery.graphql";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function ClientSettingsProfileRoute() {
  const props = useLazyLoadQuery<ClientSettingsProfileRouteQuery>(
    graphql`
      query ClientSettingsProfileRouteQuery {
        me {
          ...ClientSettingsProfile_user
        }
      }
    `,
    {},
  );

  const { me } = props;

  return (
    <>
      <ClientSettingsProfile userRef={me} />
      <TrackInfoTool
        trackInfo={{
          name: "Client - Settings",
        }}
      />
    </>
  );
}
