import {
  MENU_ITEM_HEADING_1,
  MENU_ITEM_HEADING_2,
  MENU_ITEM_TEXT,
} from "../../utils/menuItemUtil";
import { TEXT_SECTION } from "../../utils/menuSectionsUtil";
import { Schema } from "./Schema";

export const GroupPostEditorSchema: Schema = {
  generalDropDownMenuItems: [TEXT_SECTION],
  showToolbarBorder: false,
  toolbarOptions: {
    showTextTransformOptions: true,
    showTextFormattingOptions: true,
    showTextColorOptions: false,
    showTextIndentationOptions: false,
    showListOptions: false,
    showQuotesOptions: false,
    showLinkOption: true,
    showAdvancedLinkOptions: false,
    showElementInsertOptions: false,
  },
  turnIntoMenuItemGroups: [
    {
      items: [MENU_ITEM_TEXT, MENU_ITEM_HEADING_1, MENU_ITEM_HEADING_2],
    },
  ],
  stickyToolbar: true,
  showToolbarAlways: true,
};
