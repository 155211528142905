/**
 * @generated SignedSource<<f0f7b111aa4ba61e38ce0e75939d3019>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachComponentClientSummaryRouteQuery$variables = {
  client: string;
  component: string;
  date: string;
};
export type CoachComponentClientSummaryRouteQuery$data = {
  readonly activity: {
    readonly " $fragmentSpreads": FragmentRefs<"TrainingSummary_activity">;
  } | null | undefined;
};
export type CoachComponentClientSummaryRouteQuery = {
  response: CoachComponentClientSummaryRouteQuery$data;
  variables: CoachComponentClientSummaryRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "client"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "component"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v3 = [
  {
    "kind": "Variable",
    "name": "client",
    "variableName": "client"
  },
  {
    "kind": "Variable",
    "name": "component",
    "variableName": "component"
  },
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachComponentClientSummaryRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TrainingSummary_activity"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CoachComponentClientSummaryRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startWorkout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endWorkout",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d19f26e765a3d4eab7fc226faa89dc39",
    "id": null,
    "metadata": {},
    "name": "CoachComponentClientSummaryRouteQuery",
    "operationKind": "query",
    "text": "query CoachComponentClientSummaryRouteQuery(\n  $client: String!\n  $date: String!\n  $component: String!\n) {\n  activity(client: $client, date: $date, component: $component) {\n    ...TrainingSummary_activity\n    id\n  }\n}\n\nfragment TrainingSummary_activity on Activity {\n  id\n  clientId\n  content\n  startWorkout\n  endWorkout\n}\n"
  }
};
})();

(node as any).hash = "709eddf1befaf099b45d6388df8cb86a";

export default node;
