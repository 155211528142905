import clsx from "clsx";
import React from "react";
import { ListItem, List, Divider, ListProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ElementType } from "../editor/types/elements";

import { ComponentLibrarySnippet } from "./ComponentLibrarySnippet";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(-4),
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },
}));

export interface ComponentLibrarySectionComponentsListProps extends ListProps {
  types: string[];
}

export function ComponentLibrarySectionComponentsList(
  props: ComponentLibrarySectionComponentsListProps,
) {
  const { className, types, ...other } = props;
  const s = useStyles();

  return (
    <List className={clsx(s.root, className)} dense {...other}>
      {types.map((elementType, index) => (
        <React.Fragment key={elementType}>
          {index !== 0 && <Divider className={s.divider} />}
          <ListItem disableGutters>
            <ComponentLibrarySnippet elementType={elementType} />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
}
