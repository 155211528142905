import clsx from "clsx";
import React from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Switch,
  Tooltip,
} from "@mui/material";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import makeStyles from "@mui/styles/makeStyles";
import { usePopupState } from "material-ui-popup-state/hooks";
import { ConnectionHandler } from "relay-runtime";
import { Editor } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";

import { ReactComponent as HeartIcon } from "../../icons/HeartOutline.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/ArchiveOutline.svg";
import { ReactComponent as TemplateIcon } from "../../icons/BookmarkOutline.svg";
import { ReactComponent as CalendarLockIcon } from "../../icons/calendar-lock.svg";
import { ReactComponent as TextInputAreaIcon } from "../../icons/text-input-area.svg";
import { ReactComponent as UndoIcon } from "../../icons/Undo.svg";
import {
  ComponentStatus,
  ComponentType,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { getContentType } from "../../utils/component";
import { ChangeComponentCoverDialog } from "../dialog/ChangeComponentCoverDialog";
import { useEditorCoverImage } from "../../hooks/useEditorCoverImage";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { EditorMenu_component$key } from "./__generated__/EditorMenu_component.graphql";
import { EditorMenuUpdateComponentMutation } from "./__generated__/EditorMenuUpdateComponentMutation.graphql";
import { EditorMenuUpdateUserMutation } from "./__generated__/EditorMenuUpdateUserMutation.graphql";
import { EditorMenuCreateTemplateMutation } from "./__generated__/EditorMenuCreateTemplateMutation.graphql";
import { Menu, MenuProps } from "./Menu";
import { useNavigate } from "react-router-dom";
import { useEditorRef } from "@udecode/plate-common";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
    "& svg": {
      color: theme.palette.text.primary,
    },
  },

  primaryText: {
    fontWeight: 500,
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },

  switchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(1),
  },

  switch: {
    margin: 0,
  },
}));

const fragment = graphql`
  fragment EditorMenu_component on Component
  @argumentDefinitions(draft: { type: "Boolean!", defaultValue: false }) {
    id
    type
    title(draft: $draft)
    content(draft: $draft)
    habitPrompt(draft: $draft)
    image(draft: $draft)
    locked
    ...ChangeComponentCoverDialog_component @arguments(draft: $draft)
  }
`;

const updateComponentMutation = graphql`
  mutation EditorMenuUpdateComponentMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        id
        ...EditorMenu_component
      }
    }
  }
`;

const updateUserMutation = graphql`
  mutation EditorMenuUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        topToolbar
      }
    }
  }
`;

const createTemplateMutation = graphql`
  mutation EditorMenuCreateTemplateMutation(
    $input: UpsertComponentTemplateInput!
  ) {
    upsertComponentTemplate(input: $input) {
      template {
        ...ComponentTemplatePreview_template
      }
    }
  }
`;

export interface EditorMenuProps extends MenuProps {
  componentRef: EditorMenu_component$key;
  isEmpty?: boolean;
  editor: Editor & ReactEditor & HistoryEditor;
}

export function EditorMenu(props: EditorMenuProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const { className, componentRef, onClose, isEmpty = false, ...other } = props;
  const component = useFragment(fragment, componentRef);
  const user = useCurrentUser();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const [updateComponent] = useMutation<EditorMenuUpdateComponentMutation>(
    updateComponentMutation,
  );
  const [updateUser] =
    useMutation<EditorMenuUpdateUserMutation>(updateUserMutation);
  const [createTemplate] = useMutation<EditorMenuCreateTemplateMutation>(
    createTemplateMutation,
  );
  const editor = useEditorRef();

  const handleArchiveClick = React.useCallback(() => {
    updateComponent({
      variables: {
        input: {
          id: component.id,
          status: ComponentStatus.ARCHIVED,
        },
      },
      onCompleted(_, errors) {
        if (errors && errors[0]) {
          onError(errors[0]);
        } else {
          // TODO: Improve by replacing current path with previous one
          // so the user can't go forward again to the component editor
          if (onClose) {
            navigate(-1);
          }
        }
      },
      onError,
    });
  }, [component.id, onClose, onError, updateComponent]);

  const changeCoverDialogState = usePopupState({
    variant: "popover",
    popupId: "change-cover-dialog",
  });

  const elementTypeName = getContentType(component.type as ComponentType);
  const onUpdateCover = useEditorCoverImage();

  const handleCoverChangeClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
      changeCoverDialogState.open(event);
    },
    [changeCoverDialogState, onClose],
  );

  const handleCoverChange = React.useCallback(
    (value: string) => {
      changeCoverDialogState.close();
      onUpdateCover(value);
    },
    [changeCoverDialogState, onUpdateCover],
  );

  const handleLockedToggle = React.useCallback(() => {
    updateComponent({
      variables: {
        input: {
          id: component.id,
          locked: !component.locked,
        },
      },
      onCompleted(_, errors) {
        if (errors && errors[0]) {
          onError(errors[0]);
        }
      },
      onError,
    });
  }, [component.id, component.locked, onError, updateComponent]);

  const handleTopToolbarToggle = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateUser({
        variables: {
          input: {
            id: user.id,
            topToolbar: checked,
          },
        },
        onCompleted(_, errors) {
          if (errors) {
            snackAlert({
              severity: "error",
              message: errors[0].message || SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Settings updated",
            });
          }
        },
      });
    },
    [snackAlert, updateUser, user.id],
  );

  const handleSaveAsTemplate = React.useCallback(
    (event) => {
      const input = {
        title: component.title,
        content: component.content,
        habitPrompt: component.habitPrompt || "",
        image: component.image,
        componentType: component.type,
      };

      createTemplate({
        variables: { input },
        onCompleted(_, errors) {
          if (errors) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Template created.",
            });
            onClose(event, "backdropClick");
          }
        },
        updater: (store) => {
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "ComponentTemplateDialog_componentTemplates",
            [],
          );

          if (conn) {
            const node = store
              .getRootField("upsertComponentTemplate")
              .getLinkedRecord("template");

            if (node) {
              const edge = ConnectionHandler.createEdge(
                store,
                conn,
                node,
                "ComponentTemplateEdge",
              );
              ConnectionHandler.insertEdgeBefore(conn, edge);
            }
          }
        },
      });
    },
    [
      component.content,
      component.habitPrompt,
      component.image,
      component.title,
      component.type,
      createTemplate,
      onClose,
      snackAlert,
    ],
  );

  const handleUndoClick = React.useCallback(() => editor.undo(), [editor]);

  return (
    <>
      <Menu
        className={clsx(s.root, className)}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...other}
      >
        <MenuItem
          onClick={handleUndoClick}
          disabled={editor.history.undos.length === 0}
        >
          <ListItemIcon children={<UndoIcon />} />
          <ListItemText classes={{ primary: s.primaryText }} primary="Undo" />
        </MenuItem>

        {component.type !== ComponentType.MESSAGE && (
          <MenuItem onClick={handleCoverChangeClick}>
            <ListItemIcon children={<HeartIcon />} />
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Set cover image"
            />
          </MenuItem>
        )}

        <MenuItem onClick={handleArchiveClick}>
          <ListItemIcon children={<ArchiveIcon />} />
          <ListItemText
            classes={{ primary: s.primaryText }}
            primary={`Archive ${elementTypeName}`}
          />
        </MenuItem>

        {!isEmpty && (
          <MenuItem onClick={handleSaveAsTemplate}>
            <ListItemIcon children={<TemplateIcon />} />
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Save as template"
            />
          </MenuItem>
        )}

        <Divider className={s.divider} />

        {component.type !== ComponentType.MESSAGE && (
          <Tooltip
            arrow
            placement="left"
            title="Prevent clients from viewing this until the scheduled date."
          >
            <MenuItem>
              <ListItemIcon children={<CalendarLockIcon />} />
              <ListItemText
                classes={{ root: s.switchText, primary: s.primaryText }}
                primary="Content locking"
                secondary={
                  <Switch
                    className={s.switch}
                    value="fixed"
                    checked={component.locked}
                    onChange={handleLockedToggle}
                  />
                }
              />
            </MenuItem>
          </Tooltip>
        )}

        <Tooltip
          arrow
          placement="left"
          title="Include a handy toolbar for quick access to components."
        >
          <MenuItem>
            <ListItemIcon children={<TextInputAreaIcon />} />
            <ListItemText
              classes={{ root: s.switchText, primary: s.primaryText }}
              primary="Top toolbar"
              secondary={
                <Switch
                  className={s.switch}
                  value="fixed"
                  checked={user.topToolbar}
                  onChange={handleTopToolbarToggle}
                />
              }
            />
          </MenuItem>
        </Tooltip>
      </Menu>

      {changeCoverDialogState.isOpen && (
        <ChangeComponentCoverDialog
          open
          onClose={changeCoverDialogState.close}
          componentRef={component}
          onCoverChange={handleCoverChange}
        />
      )}
    </>
  );
}
