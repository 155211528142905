/**
 * @generated SignedSource<<b1c6558d6348dbb5fe3f1bc11367d783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramSortBy = "DATE_ADDED_ASC" | "DATE_ADDED_DESC" | "NAME_ASC" | "NAME_DESC" | "%future added value";
export type ProgramStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type CoachProgramsListScreenRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  folder?: string | null | undefined;
  orderBy?: ProgramSortBy | null | undefined;
  query?: string | null | undefined;
  status?: ProgramStatus | null | undefined;
};
export type CoachProgramsListScreenRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CoachProgramsListScreen_root">;
};
export type CoachProgramsListScreenRefetchQuery = {
  response: CoachProgramsListScreenRefetchQuery$data;
  variables: CoachProgramsListScreenRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 12,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "folder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "folder",
    "variableName": "folder"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramsListScreenRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CoachProgramsListScreen_root"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachProgramsListScreenRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProgramFolder",
                    "kind": "LinkedField",
                    "name": "folder",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "length",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": "createdAtFromNow",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "fromNow"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"fromNow\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enrolledTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "clients",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ProgramsList_programs",
        "kind": "LinkedHandle",
        "name": "programs"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramFolder",
        "kind": "LinkedField",
        "name": "programFolders",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d2479f29a3a96d85417380bc99e3fa3",
    "id": null,
    "metadata": {},
    "name": "CoachProgramsListScreenRefetchQuery",
    "operationKind": "query",
    "text": "query CoachProgramsListScreenRefetchQuery(\n  $after: String\n  $first: Int = 12\n  $folder: ID\n  $orderBy: ProgramSortBy\n  $query: String\n  $status: ProgramStatus\n) {\n  ...CoachProgramsListScreen_root_PMeSL\n}\n\nfragment CoachProgramCard_program on Program {\n  ...CoachProgramMenu_program\n  id\n  slug\n  name\n  description\n  length\n  status\n  createdAt\n  createdAtFromNow: createdAt(format: \"fromNow\")\n  imageURL\n  enrolledTotal\n  clients {\n    ...StackedClientsAvatars_clients\n    id\n  }\n}\n\nfragment CoachProgramMenu_program on Program {\n  id\n  status\n  folder {\n    id\n  }\n}\n\nfragment CoachProgramsListScreen_root_PMeSL on Root {\n  programs(first: $first, after: $after, status: $status, orderBy: $orderBy, query: $query, folder: $folder) {\n    edges {\n      node {\n        id\n        ...CoachProgramCard_program\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  programFolders {\n    id\n    name\n  }\n}\n\nfragment StackedClientsAvatars_clients on User {\n  id\n  displayName\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "05fbc833c84c73f09ba476860fce47b9";

export default node;
