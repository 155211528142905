import clsx from "clsx";
import React from "react";
import { graphql, useFragment } from "react-relay";
import {
  BoxProps,
  Box,
  Typography,
  Divider,
  Button,
  darken,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WorkoutSection } from "../workout/types";
import {
  countExercises,
  getExtraColumnLabel,
  getRepsColumnLabel,
  hasExtraColumn,
  parseWorkoutResultValueJsonToWorkoutSection,
} from "../workout/utils";
import { Link } from "../link/Link";
import WorkoutSet from "../workout/workout-set/WorkoutSet";
import { colorSystem } from "../../theme";
import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
  UserRole,
} from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { unitSetLabel } from "../../utils/units";

import { ActivityWorkoutResponse_workoutExerciseResult$key } from "./__generated__/ActivityWorkoutResponse_workoutExerciseResult.graphql";
import { ActivityResponseCard_activity$data } from "./__generated__/ActivityResponseCard_activity.graphql";
import { TrainingSummaryWellBegin } from "../training-summary/TrainingSummaryWellBegin";
import { useWorkoutTableStyles } from "../workout/tableStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },

  setsContainer: {
    maxWidth: 560,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },

  header: {
    display: "flex",
    color: colorSystem.gray,
    fontWeight: 500,
    marginBottom: 12,
  },

  exercise: {
    position: "relative",

    "&::after": {
      content: "''",
      display: "block",

      width: theme.spacing(2),
      height: theme.spacing(2),

      position: "absolute",
      left: 0,
      top: theme.spacing(0.25),

      borderRadius: "50%",
      borderColor: theme.palette.secondary.main,
      borderWidth: theme.spacing(0.25),
      borderStyle: "solid",
      backgroundColor: theme.palette.background.paper,

      zIndex: 2,
    },
  },

  divider: {
    backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(2, 0, 2, 3),
  },

  before: {
    content: "''",
    display: "block",
    position: "absolute",
    width: 2,
    transform: `translateY(calc(10px))`,
    left: 7,
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
  },

  exerciseTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    paddingLeft: theme.spacing(3),
  },

  exerciseResult: {
    fontWeight: 500,
    fontSize: 16,
  },

  flex: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },

  button: {
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.light, 0.2),
    },
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },

  emptyResult: {
    backgroundColor: theme.palette.grey[100],
    height: "42px",
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    fontSize: 14,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 3),
  },
}));

const workoutExerciseResultFragment = graphql`
  fragment ActivityWorkoutResponse_workoutExerciseResult on WorkoutExerciseResult {
    id
    value
  }
`;

export interface ActivityStartWorkout {
  mindValue: string;
  bodyValue: string;
}

export interface ActivityEndWorkout {
  feel: string;
  notes: string;
  time: number;
}

export interface ActivityWorkoutResponseProps extends BoxProps {
  workoutExerciseResult: ActivityWorkoutResponse_workoutExerciseResult$key;
  handleOpenVideo?: (url) => void;
  activity?: ActivityResponseCard_activity$data | any;
}

export function ActivityWorkoutResponse({
  workoutExerciseResult: workoutExerciseResultRef,
  className,
  handleOpenVideo,
  activity,
  ...other
}: ActivityWorkoutResponseProps) {
  const workoutExerciseResult = useFragment(
    workoutExerciseResultFragment,
    workoutExerciseResultRef,
  );

  const { title, exercises } = parseWorkoutResultValueJsonToWorkoutSection(
    workoutExerciseResult.value,
  );

  const s = useStyles(exercises);
  const tableStyles = useWorkoutTableStyles();
  const counters = countExercises(exercises);
  const user = useCurrentUser();

  const isCoach = user.role === UserRole.COACH;
  const linkHref = `${isCoach ? "/coach" : ""}/programs/summary/${
    activity?.enrollment?.program.slug
  }/${activity?.date}/${activity?.component?.slug}/${
    isCoach ? activity?.client?.username : ""
  }`;

  const startWorkout: ActivityStartWorkout = React.useMemo(
    () => JSON.parse(activity.startWorkout) || [],
    [activity.startWorkout],
  );
  const endWorkout: ActivityEndWorkout = React.useMemo(
    () => JSON.parse(activity.endWorkout) || [],
    [activity.endWorkout],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="baseline" gap={2}>
          {title && <Typography className={s.title}>{title}</Typography>}
          <Button
            LinkComponent={Link}
            href={linkHref}
            color="primary"
            variant="outlined"
            size="small"
          >
            View Summary
          </Button>
        </Box>
        <Box pt={2} pb={4}>
          <TrainingSummaryWellBegin
            shortened
            startWorkout={startWorkout}
            endWorkout={endWorkout}
          />
        </Box>
      </Box>
      {exercises.map((exercise, index) => (
        <React.Fragment key={exercise.id}>
          {index !== 0 && <Divider className={s.divider} />}
          <Box className={clsx(s.exercise)}>
            {counters[index] >= 1 &&
              counters[index] < counters[index + 1] &&
              Array.isArray(exercises[index + 1]?.sets) && (
                <div
                  style={{
                    height: `calc(100% + 40px)`,
                  }}
                  className={s.before}
                />
              )}
            <Typography className={s.exerciseTitle}>
              {exercise.title}
            </Typography>
            <div className={s.setsContainer}>
              {Array.isArray(exercise.result) ? (
                exercise.result.length === 0 ? (
                  <Box className={s.emptyResult}>{"no completed sets..."}</Box>
                ) : (
                  <>
                    <div
                      className={clsx(
                        s.header,
                        tableStyles.header,
                        hasExtraColumn(exercise?.typeExtraMeasurement) &&
                          tableStyles.headerWithExtraColumn,
                      )}
                    >
                      <div className={clsx(tableStyles.cell, tableStyles.set)}>
                        Set
                      </div>
                      <div className={clsx(tableStyles.cell, tableStyles.reps)}>
                        {getRepsColumnLabel(exercise?.typeReps, true)}
                      </div>
                      <div
                        className={clsx(tableStyles.cell, tableStyles.weight)}
                      >
                        {unitSetLabel(
                          exercise?.units || user.units,
                          exercise?.typeSet || ExerciseTypeSet.WEIGHT,
                        )}
                      </div>
                      {hasExtraColumn(exercise?.typeExtraMeasurement) && (
                        <div
                          className={clsx(
                            tableStyles.cell,
                            tableStyles.extraColumn,
                          )}
                        >
                          {getExtraColumnLabel(exercise?.typeExtraMeasurement)}
                        </div>
                      )}
                      <div
                        className={clsx(
                          tableStyles.cell,
                          tableStyles.result,
                          history && tableStyles.resultHistory,
                        )}
                      ></div>
                    </div>
                    {exercise.result?.map((set, index) => (
                      <WorkoutSet
                        key={index}
                        set={set as any} // TODO alight ResultSet and Sets types
                        setNumber={index}
                        history
                        typeExtraMeasurement={
                          exercise?.typeExtraMeasurement ||
                          ExerciseTypeExtra.NONE
                        }
                        typeReps={exercise?.typeReps || ExerciseTypeReps.WHOLE}
                        typeSet={exercise?.typeSet || ExerciseTypeSet.WEIGHT}
                      />
                    ))}
                  </>
                )
              ) : (
                <>
                  <Box>{exercise?.result}</Box>
                </>
              )}
            </div>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}
