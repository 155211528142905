import React, { ReactNode } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  CheckboxProps,
} from "@mui/material";

export interface CheckboxFieldProps
  extends Omit<FormControlLabelProps, "onChange" | "control" | "label">,
    Pick<CheckboxProps, "icon"> {
  name?: string;
  label?: ReactNode;
  checked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

export function CheckboxField(props: CheckboxFieldProps) {
  const { name, label, checked, onChange, icon, ...other } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          icon={icon}
        />
      }
      label={label}
      {...other}
    />
  );
}
