import { Transforms, NodeEntry, Node, Editor, Path, createEditor } from "slate";
import { ELEMENT_LINK } from "@udecode/plate-link";
import { ELEMENT_VIDEO } from "@udecode/plate-media";
import { SchemaElements } from "../../utils/withSchema";
import { TLD_REGEX_CHUNK } from "../../../../utils/top-level-domains";
import { createPluginFactory } from "@udecode/plate-common";

export interface ReplacerRule {
  regexp: RegExp;
  validate?: (str: string) => boolean;
  replace: (str: string, entry: NodeEntry<Node>) => any | null;
}

export interface ReplacerOptions {
  rules: ReplacerRule[];
  ignoreFocus?: boolean;
}

/* eslint-disable no-irregular-whitespace */
export const replaceYoutubeLink: ReplacerRule = {
  regexp:
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/,
  replace: (url) => SchemaElements.createElement(ELEMENT_VIDEO, { url }),
};
/* eslint-enable no-irregular-whitespace */

/* eslint-disable no-irregular-whitespace */
export const replaceVimeoLink: ReplacerRule = {
  regexp:
    /http(?:s?):\/\/(?:www\.)?vimeo\.com\/([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/,
  replace: (url) => SchemaElements.createElement(ELEMENT_VIDEO, { url }),
};
/* eslint-enable no-irregular-whitespace */

export const replaceUrl: ReplacerRule = {
  regexp: new RegExp(
    `((https?)?(:\\/\\/)?([A-z0-9-]{2,})(\\.[a-z]{2,})?\\.(${TLD_REGEX_CHUNK})\\/?(\\S*))`,
  ),
  validate: (str) => new RegExp(`\\.(${TLD_REGEX_CHUNK})($|\\/)`).test(str),
  replace: (url) => {
    return SchemaElements.createElement(ELEMENT_LINK, { url }, []);
  },
};

export const replaceEmail: ReplacerRule = {
  regexp:
    /([a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/,
  replace: (url) => {
    return SchemaElements.createElement(
      ELEMENT_LINK,
      { url: "mailto:" + url },
      [],
    );
  },
};

const defaultOptions = {
  rules: [replaceYoutubeLink, replaceVimeoLink, replaceEmail, replaceUrl],
  ignoreFocus: false,
};

export const withAutoReplacer =
  (options: ReplacerOptions = defaultOptions) =>
  (editor: Editor) => {
    const { normalizeNode } = editor;
    const { rules, ignoreFocus } = options;

    editor.normalizeNode = (entry) => {
      const [node, path] = entry;
      const text = (node as any).text as string;

      if (text) {
        for (const rule of rules) {
          const match = rule.regexp.exec(text);

          if (match) {
            const substr = match[0];
            const valid = !rule.validate || rule.validate(substr);

            if (!valid) {
              continue;
            }

            const range = {
              anchor: { path, offset: match.index },
              focus: { path, offset: match.index + substr.length },
            };
            const element = rule.replace(substr, entry);

            if (element) {
              if (Editor.isInline(editor, element)) {
                const focus = editor.selection?.focus;
                const focusedAtMatch =
                  focus &&
                  Path.equals(entry[1], focus.path) &&
                  focus.offset <= match.index + substr.length &&
                  focus.offset >= match.index;
                const parentNode = Node.get(editor, Path.parent(path));

                if (
                  (ignoreFocus || !focusedAtMatch) &&
                  ![(node as any).type, (parentNode as any).type].includes(
                    element.type,
                  )
                ) {
                  Transforms.wrapNodes(editor, element, {
                    at: range,
                    split: true,
                  });
                  return;
                }
              } else {
                Transforms.wrapNodes(editor, element, {
                  at: range,
                  split: true,
                });
                return;
              }
            }
          }
        }
      }

      return normalizeNode(entry);
    };

    return editor;
  };

export const autoReplaceInNodes = (
  nodes: any[],
  options: ReplacerOptions = defaultOptions,
) => {
  const editor = withAutoReplacer({
    ...options,
    ignoreFocus: true,
  })(createEditor());
  const isInline = editor.isInline;

  editor.isInline = (element: any) => {
    return element.type === ELEMENT_LINK || isInline(element);
  };

  Transforms.insertNodes(editor, nodes);

  return editor.children;
};

const AutoReplacerPluginKey = "AutoReplacerPlugin";

export const createAutoReplacerPlugin = createPluginFactory({
  key: AutoReplacerPluginKey,
  withOverrides: (editor) => {
    withAutoReplacer()(editor as Editor);

    return editor;
  },
});
