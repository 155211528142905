import { Node } from "slate";
// import { SchemaElements } from "../components/editor/normalizers/withSchema";
import { ElementType } from "../components/editor/types/elements";
import { SchemaElements } from "../components/editor/normalizers/withSchema";

export function getContentTeaser(
  content: string | Node[],
  maxLength = 150,
  ellipsis = true,
): string | null {
  if (!content) {
    return "";
  }

  const nodes: any[] =
    typeof content === "string" ? JSON.parse(content) : content;

  if (nodes.some(({ type }) => type === ElementType.IMAGE)) {
    return "[Image]";
  }

  if (nodes.some(({ type }) => type === ElementType.FILE)) {
    return "[File]";
  }

  const full = nodes
    .map((node) => Node.texts(node))
    .map((it) => [...it])
    .flat(Infinity)
    .filter((v) => typeof v !== "number")
    .map((v: any) => v.text)
    .join(" ");

  const truncated = full.substring(0, maxLength);

  return !full
    ? null
    : full.length > maxLength && ellipsis
      ? `${truncated}...`
      : truncated;
}

export const createTextMessage = (text: string): any =>
  SchemaElements.createElement(ElementType.MESSAGE, {}, [{ text }]);
