import { createContext } from "react";
import { ClientProgramListRouteQuery$data } from "../routes/client/programs/list/__generated__/ClientProgramListRouteQuery.graphql";

export interface IProgramsContextProps {
  programsData: ClientProgramListRouteQuery$data;
  setProgramsData: (programsData: ClientProgramListRouteQuery$data) => void;
}

const ProgramsContext = createContext<IProgramsContextProps | undefined>(
  undefined,
);

export default ProgramsContext;
