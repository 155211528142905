import clsx from "clsx";
import React from "react";
import {
  AppBar,
  AppBarProps,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { VisibilityOffOutlined } from "@mui/icons-material";
import { capitalize } from "lodash";

import { colorSystem } from "../../theme";
import { ReactComponent as DesktopIcon } from "../../icons/desktop-outline.svg";
import { ReactComponent as TabletIcon } from "../../icons/tablet-outline.svg";
import { ReactComponent as PhoneIcon } from "../../icons/phone-outline.svg";
import { PreviewType, usePreview } from "../../hooks/usePreview";
import { useSearchParams } from "react-router-dom";

export const PREVIEW_BAR_SPACING = 12;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".MuiDrawer-docked > div": {
      paddingTop: theme.spacing(PREVIEW_BAR_SPACING),
    },
  },

  root: {
    zIndex: 1103,
  },

  bar: {
    height: theme.spacing(PREVIEW_BAR_SPACING),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: colorSystem.white2,
    backgroundColor: theme.palette.common.black,
  },

  edge: {
    display: "flex",
    alignItems: "center",
    width: "20%",
    flexGrow: 1,
  },

  left: {
    justifyContent: "flex-start",
  },

  right: {
    justifyContent: "flex-end",
  },

  textBox: {
    display: "flex",
    flexDirection: "column",
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: "29px",
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px",
  },

  tabs: {
    height: "100%",

    "& .MuiTabs-flexContainer": {
      height: "100%",
    },
  },

  tab: {
    minWidth: 80,
    width: 80,
    height: "100%",
    textAlign: "center",

    "&$selected path": {
      stroke: colorSystem.white2,

      "&[fill]": {
        fill: colorSystem.white2,
      },
    },
  },

  indicator: {
    height: 5,
  },

  exitPreviewButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),

    "& svg": {
      color: theme.palette.common.white,
    },

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}99`,
    },
  },

  selected: {},
}));

const TYPE_ICONS = {
  [PreviewType.WEB]: DesktopIcon,
  [PreviewType.TABLET]: TabletIcon,
  [PreviewType.MOBILE]: PhoneIcon,
};

export interface PreviewBarProps extends AppBarProps {
  actions?: React.ReactNode;
}

export function PreviewBar(props: PreviewBarProps) {
  const { className, actions, ...other } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [preview, setPreview] = usePreview();
  const s = useStyles();

  const handleExitPreview = React.useCallback(() => {
    setPreview(null);
    if (searchParams.has("preview")) {
      searchParams.delete("preview");
      setSearchParams(searchParams);
    }
  }, [setPreview]);

  const getTypeClickHandler = React.useCallback(
    (type: PreviewType) => () => {
      setPreview(type);
    },
    [setPreview],
  );

  return (
    <AppBar className={clsx(s.root, className)} {...other}>
      <Toolbar className={s.bar}>
        <Box className={clsx(s.edge, s.left)}>
          <Box className={s.textBox}>
            <Typography className={s.title} children="Preview" />
            <Typography className={s.subtitle} children={capitalize(preview)} />
          </Box>
        </Box>
        <Tabs
          className={s.tabs}
          value={preview}
          indicatorColor="primary"
          TabIndicatorProps={{ className: s.indicator }}
        >
          {Object.values(PreviewType).map((type) => {
            const Icon = TYPE_ICONS[type];
            return (
              <Tab
                key={type}
                label={<Icon />}
                value={type}
                className={clsx(s.tab, preview === type && s.selected)}
                onClick={getTypeClickHandler(type)}
              />
            );
          })}
        </Tabs>
        <Box className={clsx(s.edge, s.right)}>
          {actions}
          <Tooltip arrow placement="bottom" title="Exit preview">
            <IconButton
              className={s.exitPreviewButton}
              onClick={handleExitPreview}
              children={<VisibilityOffOutlined />}
              size="large"
            />
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
