import clsx from "clsx";
import React from "react";
import { BoxProps, TextField, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { ChangePasswordFormProps } from "../auth/ChangePasswordForm";
import { ActionButton } from "../button/ActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& p": {
      textAlign: "center",
    },
  },

  field: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },

  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

export interface ClientPortalChangePasswordFormProps
  extends ChangePasswordFormProps {}

export function ClientPortalChangePasswordForm(
  props: ClientPortalChangePasswordFormProps,
) {
  const { className, state, errors, onChange, loading, ...other } = props;
  const s = useStyles();
  const complete = state.password === state.passwordConf && !errors.password;
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const VisibilityIcon = passwordVisible ? VisibilityOff : Visibility;

  const handlePasswordVisibilityToggle = React.useCallback(() => {
    setPasswordVisible((value) => !value);
  }, []);

  return (
    <form className={clsx(s.root, className)} {...other}>
      <Typography>Set your new password</Typography>

      <TextField
        className={s.field}
        variant="outlined"
        name="password"
        type={passwordVisible ? "text" : "password"}
        label="New Password"
        placeholder="* * * * * *"
        value={state.password}
        onChange={onChange}
        disabled={loading}
        error={Boolean(errors.password)}
        helperText={errors.password}
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={handlePasswordVisibilityToggle} size="large">
              <VisibilityIcon />
            </IconButton>
          ),
        }}
      />
      <TextField
        className={s.field}
        variant="outlined"
        name="passwordConf"
        type="password"
        label="Confirm Password"
        placeholder="* * * * * *"
        value={state.passwordConf}
        onChange={onChange}
        disabled={loading}
        error={Boolean(errors.passwordConf)}
        helperText={errors.passwordConf}
        fullWidth
      />

      <ActionButton
        className={s.submit}
        type="submit"
        disabled={loading || !complete}
        children="Change my password"
        fullWidth
      />
    </form>
  );
}
