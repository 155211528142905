import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
  },
}));

export function SendUsMessageLink() {
  const s = useStyles();

  const handleClick = React.useCallback((event) => {
    const Intercom = window["Intercom"] || null;

    if (Intercom) {
      event.preventDefault();
      Intercom("showNewMessage");
    }
  }, []);

  return (
    <a
      className={s.root}
      href="mailto:accounts@stridist.com"
      onClick={handleClick}
    >
      Send us a message.
    </a>
  );
}
