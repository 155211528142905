import clsx from "clsx";
import React from "react";
import { DialogProps, ButtonProps, Grid, Divider } from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "./BaseDialog";
import { ClientMeasurementItem } from "../card/ClientMeasurementItem";
import { Units } from "../../constants";
import MeasurementsCharts from "../card/MeasurementsCharts";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
    margin: theme.spacing(1),
  },

  description: {
    color: theme.palette.common.black,
  },

  button: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    width: `calc(50% - ${theme.spacing(1)})`,
    height: theme.spacing(7),
  },

  cancelButton: {
    color: theme.palette.text.secondary,
    borderWidth: 2,
    marginRight: theme.spacing(2),
  },

  okayButton: {
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
}));

export interface ConfirmActionDialogProps extends Omit<DialogProps, "title"> {
  title: string;
  description?: string;
  onCancel: ButtonProps["onClick"];
  onConfirm: ButtonProps["onClick"];
  disabled?: boolean;
  items?: any[];
  units: any;
  selectItemIndex?: number;
  setSelectItemIndex: (index) => void;
}

export function ConfirmActionMeasurements(props: ConfirmActionDialogProps) {
  const {
    className,
    title,
    description,
    onCancel,
    onConfirm,
    disabled,
    items,
    units,
    selectItemIndex,
    setSelectItemIndex,
    ...other
  } = props;
  const s = useStyles();

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={title}
      description={description}
      onClose={onCancel}
      classes={{
        paper: s.paper,
        description: s.description,
      }}
      {...other}
    >
      <Grid>
        {items.map(({ label, metrics }, index) => (
          <Grid item key={label}>
            <ClientMeasurementItem
              metricsRef={metrics}
              label={label}
              units={units as Units}
              onClick={() => setSelectItemIndex(index)}
            />
            {selectItemIndex === index && (
              <MeasurementsCharts
                units={units as Units}
                dataMetrics={metrics}
              />
            )}
            {index < items.length - 1 && <Divider className={s.divider} />}
          </Grid>
        ))}
      </Grid>
    </BaseDialog>
  );
}
