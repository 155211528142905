import React from "react";
import clsx from "clsx";
import { ReactComponent as FinishIcon } from "../../icons/finishWorkout.svg";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: colorSystem.blackOpacity,
      zIndex: 9999999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      backgroundColor: colorSystem.white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 4,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05);",
      padding: "50px 99px",
    },
    titleModal: {
      fontSize: 24,
      fontWeight: "bold",
      lineHeight: "29px",
      marginTop: 24,
      marginBottom: 8,
    },
    textModal: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "17px",
      marginBottom: 24,
      color: colorSystem.gray,
    },
    buttonFinish: {
      backgroundColor: colorSystem.black,
      color: colorSystem.white,
      width: 310,
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "20px",
      padding: 18,
      "&:hover": {
        backgroundColor: colorSystem.gray2,
      },
      "&.Mui-disabled": {
        backgroundColor: colorSystem.gray2,
      },
    },
    buttonCancel: {
      marginTop: 24,
      backgroundColor: "transparent",
      color: colorSystem.gray,
      "&:hover": {
        backgroundColor: colorSystem.gray8,
      },
    },
  };
});

const WorkoutFinishModal = (props: any) => {
  const { handleSubmit, onClose, disabled } = props;
  const s = useStyles();
  return (
    <div className={clsx(s.modal)}>
      <div className={clsx(s.modalContent)}>
        <FinishIcon />
        <div className={clsx(s.titleModal)}>Finish workout</div>
        <div className={clsx(s.textModal)}>All exercises will be completed</div>
        <Button
          className={clsx(s.buttonFinish)}
          children={"Finish"}
          onClick={handleSubmit}
          disabled={disabled}
        />
        <Button
          className={clsx(s.buttonFinish, s.buttonCancel)}
          children={"Cancel"}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default WorkoutFinishModal;
