import clsx from "clsx";
import React from "react";
import { DialogProps, TextField, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import {
  ProgramSelectableItem,
  ProgramSelectableItemProps,
} from "../item/ProgramSelectableItem";
import { useClient } from "../../hooks/useClient";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useDebounce } from "../../hooks/useDebounce";
import { getFirstName } from "../../utils/user";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { DynamicSearch } from "../list/DynamicSearch";
import { BaseDialog } from "./BaseDialog";

import { ChooseProgramEnrollDialog_client$key } from "./__generated__/ChooseProgramEnrollDialog_client.graphql";
import { ChooseProgramEnrollDialog_enrollments$key } from "./__generated__/ChooseProgramEnrollDialog_enrollments.graphql";
import { ChooseProgramEnrollDialogEnrollMutation } from "./__generated__/ChooseProgramEnrollDialogEnrollMutation.graphql";
import { ChooseProgramEnrollDialogUnenrollEnrollMutation } from "./__generated__/ChooseProgramEnrollDialogUnenrollEnrollMutation.graphql";
import { ChooseProgramEnrollDialogSearch_fragment$data } from "./__generated__/ChooseProgramEnrollDialogSearch_fragment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
    height: 456,
  },

  search: {
    marginBottom: theme.spacing(1),
  },

  item: {
    "&:not(:first-child)": {
      borderTop: `1px solid ${theme.palette.border.primary}`,
    },
  },
}));

const clientFragment = graphql`
  fragment ChooseProgramEnrollDialog_client on User {
    email
    displayName
  }
`;

const enrollmentsFragment = graphql`
  fragment ChooseProgramEnrollDialog_enrollments on EnrollmentConnection {
    edges {
      node {
        program {
          id
        }
      }
    }
  }
`;

const enrollMutation = graphql`
  mutation ChooseProgramEnrollDialogEnrollMutation(
    $input: EnrollClientsInput!
  ) {
    enrollClients(input: $input) {
      enrollments {
        ...CoachClientCalendar_enrollment
      }
    }
  }
`;

const unenrollEnrollMutation = graphql`
  mutation ChooseProgramEnrollDialogUnenrollEnrollMutation(
    $input: UnenrollEnrollClientInput!
  ) {
    unenrollEnrollClient(input: $input) {
      deletedEnrollmentId
      createdEnrollment {
        ...CoachClientCalendar_enrollment
      }
    }
  }
`;

function createInsertEnrollmentEdge(store, connection, enrollment) {
  const edge = ConnectionHandler.createEdge(
    store,
    connection,
    enrollment,
    "EnrollmentEdge",
  );
  ConnectionHandler.insertEdgeBefore(connection, edge);
}

function enrollUpdater(store) {
  const mutationRoot = store.getRootField("enrollClients");

  if (mutationRoot) {
    const enrollments = ConnectionHandler.getConnection(
      store.getRoot(),
      "CoachClientCalendar_enrollments",
      [],
    );

    if (enrollments) {
      const createdEnrollments = mutationRoot.getLinkedRecords("enrollments");

      if (createdEnrollments?.length) {
        createInsertEnrollmentEdge(store, enrollments, createdEnrollments[0]);
      }
    }
  }
}

function unenrollEnrollUpdater(store) {
  const mutationRoot = store.getRootField("unenrollEnrollClient");

  if (mutationRoot) {
    const enrollments = ConnectionHandler.getConnection(
      store.getRoot(),
      "CoachClientCalendar_enrollments",
      [],
    );

    if (enrollments) {
      const deletedEnrollmentId = mutationRoot.getValue("deletedEnrollmentId");
      const createdEnrollment =
        mutationRoot.getLinkedRecord("createdEnrollment");

      if (deletedEnrollmentId) {
        ConnectionHandler.deleteNode(enrollments, deletedEnrollmentId);
      }

      if (createdEnrollment) {
        createInsertEnrollmentEdge(store, enrollments, createdEnrollment);
      }
    }
  }
}

export interface ChooseProgramEnrollDialogProps extends DialogProps {
  enrollmentsRef: ChooseProgramEnrollDialog_enrollments$key;
  startDate: string;
  deleteEnrollmentId?: string;
  onSuccess?: () => void;
}

export function ChooseProgramEnrollDialog(
  props: ChooseProgramEnrollDialogProps,
) {
  const {
    className,
    enrollmentsRef,
    startDate,
    deleteEnrollmentId,
    onSuccess,
    onClose,
    ...other
  } = props;
  const s = useStyles();
  const [search, setSearch] = React.useState("");
  const delayedFilter = useDebounce(search, 250);
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const clientRef = useClient<ChooseProgramEnrollDialog_client$key>();
  const client = useFragment(clientFragment, clientRef);
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const [enroll, enrollInFlight] =
    useMutation<ChooseProgramEnrollDialogEnrollMutation>(enrollMutation);
  const [unenrollEnroll, unenrollEnrollInFlight] =
    useMutation<ChooseProgramEnrollDialogUnenrollEnrollMutation>(
      unenrollEnrollMutation,
    );

  const resetState = React.useCallback(() => {
    setSearch("");
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleSearchChange = React.useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const onCompleted = React.useCallback(
    (_, errors) => {
      if (errors?.length) {
        onError(errors[0]);
      } else {
        snackAlert({
          severity: "success",
          message: `Program has been ${
            deleteEnrollmentId ? "changed in" : "added to"
          } ${getFirstName(client.displayName)}’s calendar.`,
        });
        handleClose();

        if (onSuccess) {
          onSuccess();
        }
      }
    },
    [
      client.displayName,
      deleteEnrollmentId,
      handleClose,
      onError,
      onSuccess,
      snackAlert,
    ],
  );

  const handleProgramClick: ProgramSelectableItemProps["onClick"] =
    React.useCallback(
      (program) => {
        const variables = {
          programId: program.id,
          startDate,
        };

        if (deleteEnrollmentId) {
          unenrollEnroll({
            variables: {
              input: {
                ...variables,
                enrollmentId: deleteEnrollmentId,
              },
            },
            onCompleted,
            onError,
            updater: unenrollEnrollUpdater,
          });
        } else {
          enroll({
            variables: {
              input: {
                ...variables,
                emails: [client.email],
              },
            },
            onCompleted,
            onError,
            updater: enrollUpdater,
          });
        }
      },
      [
        client.email,
        deleteEnrollmentId,
        enroll,
        onCompleted,
        onError,
        startDate,
        unenrollEnroll,
      ],
    );

  const disabled = enrollInFlight || unenrollEnrollInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Choose a program"
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <TextField
        className={s.search}
        variant="outlined"
        fullWidth
        value={search}
        onChange={handleSearchChange}
        label="Search programs"
      />

      <DynamicSearch<ChooseProgramEnrollDialogSearch_fragment$data>
        query={graphql`
          query ChooseProgramEnrollDialogSearchQuery(
            $first: Int!
            $after: String
            $query: String
          ) {
            ...ChooseProgramEnrollDialogSearch_fragment
              @arguments(first: $first, query: $query, after: $after)
          }
        `}
        filter={delayedFilter}
        fragment={graphql`
          fragment ChooseProgramEnrollDialogSearch_fragment on Root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            query: { type: "String" }
          ) {
            connection: programs(
              first: $first
              after: $after
              query: $query
              status: PUBLISHED
            )
              @connection(
                key: "ChooseProgramEnrollDialogSearch_connection"
                filters: [$query]
              ) {
              totalCount

              pageInfo {
                hasNextPage
              }

              edges {
                node {
                  id
                  ...ProgramSelectableItem_program
                }
              }
            }
          }
        `}
      >
        {({ connection: { edges }, hasMore, onMoreClick, loading }) => {
          const programs = edges
            .map(({ node }) => node)
            .filter(
              ({ id }) =>
                !enrollments.edges.some(
                  ({ node: enrollment }) => enrollment.program.id === id,
                ),
            );

          return (
            <Box>
              {programs.map((node) => (
                <React.Fragment key={node.id}>
                  <ProgramSelectableItem
                    key={node.id}
                    className={s.item}
                    programRef={node}
                    onClick={handleProgramClick}
                    disabled={disabled}
                  />
                </React.Fragment>
              ))}

              {hasMore && (
                <LoadMoreButton onClick={onMoreClick} disabled={loading} />
              )}

              {!programs.length && (
                <Typography variant="subtitle1">No programs found</Typography>
              )}
            </Box>
          );
        }}
      </DynamicSearch>
    </BaseDialog>
  );
}
