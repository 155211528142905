import clsx from "clsx";
import React, { MouseEventHandler } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton } from "../button/ActionButton";

import type { ComponentScheduleLayoutProps } from "./ComponentSchedule";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(97),
  },

  title: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "24px",
  },

  wrapper: {
    display: "flex",

    "& > div:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  button: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    textTransform: "uppercase",
    height: 56,
    marginTop: theme.spacing(3),
  },
}));

export interface ComponentScheduleLayoutFlatProps
  extends ComponentScheduleLayoutProps,
    BoxProps {}

export function ComponentScheduleLayoutFlat(
  props: ComponentScheduleLayoutFlatProps,
) {
  const {
    className,
    daysSelection,
    customDaysSelection,
    durationSelection,
    repeatSelection,
    onSubmit,
    weekSelection,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h5" children="Schedule" />

      <Box className={s.wrapper}>
        {weekSelection}

        {daysSelection}

        {durationSelection}

        {repeatSelection}
      </Box>

      {customDaysSelection}

      <ActionButton
        className={s.button}
        fullWidth
        children="Update"
        onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
      />
    </Box>
  );
}
