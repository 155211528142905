/**
 * @generated SignedSource<<5d071ba66c1037f41be93441abf3c0ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserAccountMenuItem_user$data = {
  readonly displayName: string | null | undefined;
  readonly id: string;
  readonly isSample: boolean;
  readonly photoURL: string | null | undefined;
  readonly role: UserRole;
  readonly " $fragmentType": "UserAccountMenuItem_user";
};
export type UserAccountMenuItem_user$key = {
  readonly " $data"?: UserAccountMenuItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserAccountMenuItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserAccountMenuItem_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": "displayName(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d87fd688365f66895353f441ca245ffe";

export default node;
