import React, { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { CoachClientsFilter } from "../../../components/coach-clients/CoachClientsFilter";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { ClientSort, ClientStatus } from "../../../constants";
import { toEnum } from "../../../utils/misc";
import { CoachClientFiltersContext } from "../../../contexts/CoachClientFiltersContext";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { polyfillCSS } from "../../../utils/css";
import { COACH_CLIENTS_ACTIVE_ROUTE } from "../../../routes/routes";
import CoachClientListContext from "../../../contexts/CoachClientListContext";
import { CoachClientsListScreen_root$data } from "../../../components/coach-clients/__generated__/CoachClientsListScreen_root.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
    },
  },
  filters: {
    marginBottom: theme.spacing(3),
  },
}));

const CoachClientsWrapper = () => {
  const s = useStyles();
  const location = useLocation();

  const [coachClientsList, setCoachClientsList] =
    React.useState<CoachClientsListScreen_root$data>();
  const coachClientListContextValue = { coachClientsList, setCoachClientsList };

  const tagList = coachClientsList?.tags?.edges || [];
  const [after, setAfter] = React.useState<string>(null);
  const [query, setQuery] = React.useState("");
  const [filterTag, setFilterTag] = useQueryParam("tag", "", {
    silent: true,
  });
  const [sortKey, setSortKey] = useQueryParam<ClientSort>(
    "sort",
    ClientSort.NAME_ASC,
    {
      silent: true,
      normalize: (param) => toEnum(param, ClientSort, ClientSort.NAME_ASC),
    },
  );

  useEffect(() => {
    setQuery("");
  }, [location.pathname]);

  const handleQueryChange = React.useCallback(
    (query: string) => {
      setQuery(query);
      setAfter(null);
    },
    [setQuery],
  );

  const handleSortKeyChange = React.useCallback(
    (sortKey: ClientSort) => {
      setSortKey(sortKey);
    },
    [setSortKey],
  );

  const handleFilterTagIdChange = React.useCallback(
    (tagId: string) => {
      setFilterTag(tagId);
    },
    [setFilterTag],
  );

  const contextValue = {
    after,
    setAfter,
    query,
    setQuery,
    filterTag,
    setFilterTag,
    sortKey,
    setSortKey,
  };

  return (
    <>
      <CoachClientFiltersContext.Provider value={contextValue}>
        <CoachClientListContext.Provider value={coachClientListContextValue}>
          <Container className={s.root} maxWidth="xl">
            <CoachClientsFilter
              className={s.filters}
              query={query}
              onChangeQuery={handleQueryChange}
              sortKey={sortKey}
              onChangeSortKey={handleSortKeyChange}
              tagList={tagList as any}
              onChangeFilterTagId={handleFilterTagIdChange}
              filterTag={filterTag}
              disabled={!location.pathname.includes(COACH_CLIENTS_ACTIVE_ROUTE)}
            />
            <Suspense fallback={<PageSkeleton fullWidth />}>
              <Outlet />
            </Suspense>
          </Container>
        </CoachClientListContext.Provider>
      </CoachClientFiltersContext.Provider>
    </>
  );
};

export default CoachClientsWrapper;
