import React from "react";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import {
  findNode,
  isBlock,
  TElement,
  useEditorRef,
  useEditorSelector,
} from "@udecode/plate-common";

import { ITurnIntoMenuItemGroup } from "../../new-editor/utils/menuItemTurnIntoGroups";
import { IMenuItem } from "../../new-editor/utils/menuItemUtil";
import { TurnIntoDropdownMenu } from "./turn-into-dropdown-menu";
import { collapseSelection, focusEditor } from "@udecode/plate-common";

import { turnIntoElement } from "../../new-editor/utils/editorUtils";

export interface SelectionTurnIntoDropdownMenuProps extends DropdownMenuProps {
  menuItemGroups: ITurnIntoMenuItemGroup[];
  defaultMenuItem: IMenuItem;
}

export function SelectionTurnIntoDropdownMenu({
  menuItemGroups,
  defaultMenuItem,
  ...props
}: SelectionTurnIntoDropdownMenuProps) {
  const items = menuItemGroups.map((g) => g.items).flat();

  const value: string = useEditorSelector((editor) => {
    const entry = findNode<TElement>(editor, {
      match: (n) => isBlock(editor, n),
    });

    if (entry) {
      return (
        items.find((item) => item.value === entry[0].type)?.value ??
        defaultMenuItem.value
      );
    }

    return defaultMenuItem.value;
  }, []);

  const selectedMenuItem =
    items.find((item) => item.value === value) ?? defaultMenuItem;

  const editor = useEditorRef();

  const onTypeChange = (type) => {
    turnIntoElement(editor, type);

    collapseSelection(editor);
    focusEditor(editor);
  };

  return (
    <TurnIntoDropdownMenu
      menuItemGroups={menuItemGroups}
      selectedMenuItem={selectedMenuItem}
      onTypeChange={onTypeChange}
      {...props}
    />
  );
}
