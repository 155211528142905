/**
 * @generated SignedSource<<00ce2b81a01f3225b483d3ec64c5219a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteProgramFolderInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type DeleteProgramFolderMutation$variables = {
  input: DeleteProgramFolderInput;
};
export type DeleteProgramFolderMutation$data = {
  readonly deleteProgramFolder: {
    readonly programFolderId: string;
  } | null | undefined;
};
export type DeleteProgramFolderMutation = {
  response: DeleteProgramFolderMutation$data;
  variables: DeleteProgramFolderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteProgramFolderPayload",
    "kind": "LinkedField",
    "name": "deleteProgramFolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "programFolderId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteProgramFolderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteProgramFolderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "204083a614b4be0ec09d028108e4ddc2",
    "id": null,
    "metadata": {},
    "name": "DeleteProgramFolderMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteProgramFolderMutation(\n  $input: DeleteProgramFolderInput!\n) {\n  deleteProgramFolder(input: $input) {\n    programFolderId\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb3427c6668a698fe0f37b6e5b5845e4";

export default node;
