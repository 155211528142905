import React from "react";

export interface AppMetadata {
  forceAppVersion: string;
  softAppVersion: string;
  isMaintenance: boolean;
}

export const AppMetadataContext = React.createContext<AppMetadata>(null);

export function useAppMetadata() {
  return React.useContext(AppMetadataContext);
}
