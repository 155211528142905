import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { ClientFormScreen } from "../../../../components/client-forms/ClientFormScreen";
import { CoachClientFormRouteQuery } from "./__generated__/CoachClientFormRouteQuery.graphql";

export function CoachClientFormRoute() {
  const { username, slug } = useParams();
  const props = useLazyLoadQuery<CoachClientFormRouteQuery>(
    graphql`
      query CoachClientFormRouteQuery($slug: String!, $username: String!) {
        ...ClientFormScreen_root @arguments(slug: $slug, username: $username)
        clientForm(slug: $slug, username: $username) {
          id
        }
      }
    `,
    {
      username: username,
      slug: slug,
    },
  );
  return props.clientForm ? <ClientFormScreen root={props} /> : null;
}
