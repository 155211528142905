/**
 * @generated SignedSource<<ef6d64fe25cb8c530e0b0acf799631df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteClientNutritionEntryInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation$variables = {
  input: DeleteClientNutritionEntryInput;
};
export type ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation$data = {
  readonly deleteClientNutritionEntry: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation = {
  response: ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation$data;
  variables: ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteClientNutritionEntryPayload",
    "kind": "LinkedField",
    "name": "deleteClientNutritionEntry",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "259d221f787a5942999f182f9d2b5ce3",
    "id": null,
    "metadata": {},
    "name": "ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ClientNutritionTargetDrawerEntryHistoryItemDeleteMutation(\n  $input: DeleteClientNutritionEntryInput!\n) {\n  deleteClientNutritionEntry(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c8a831b492a174c7bb751592adbdd1b";

export default node;
