import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachThemeSettingsScreen } from "../../../../components/theme-settings/CoachThemeSettingsScreen";
import { CoachSettingsThemeRouteQuery } from "./__generated__/CoachSettingsThemeRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachSettingsThemeRoute() {
  const props = useLazyLoadQuery<CoachSettingsThemeRouteQuery>(
    graphql`
      query CoachSettingsThemeRouteQuery {
        me {
          subscription {
            plan
          }
        }

        workspace {
          ...CoachThemeSettingsScreen_workspace
        }
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { me, workspace } = props;

  return (
    <>
      <CoachThemeSettingsScreen workspace={workspace} />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Theme",
        }}
      />
    </>
  );
}
