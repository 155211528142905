import React from "react";
import { Box, BoxProps, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClientNutritionTargetDrawerEntryHistoryItem } from "./ClientNutritionTargetDrawerEntryHistoryItem";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(3, 4, 2, 0),
  },
}));

export interface ClientNutritionTargetDrawerEntryHistoryProps extends BoxProps {
  entries: any[];
  commentTypeValue: string;
  trackingTypeValue: string;
  targetId: string;
  refetchEntries: () => void;
}

export function ClientNutritionTargetDrawerEntryHistory(
  props: ClientNutritionTargetDrawerEntryHistoryProps,
) {
  const {
    entries,
    commentTypeValue,
    trackingTypeValue,
    refetchEntries,
    targetId,
  } = props;
  const s = useStyles();

  return (
    <Box>
      <Typography variant="subtitle1" className={s.title}>
        History
      </Typography>
      <Divider />
      {entries.map(({ node }, index) => (
        <ClientNutritionTargetDrawerEntryHistoryItem
          entry={node}
          commentTypeValue={commentTypeValue}
          trackingTypeValue={trackingTypeValue}
          refetchEntries={refetchEntries}
          targetId={targetId}
          key={index}
        />
      ))}
    </Box>
  );
}
