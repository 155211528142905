import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardActionArea,
  CardHeader,
  Chip,
  useTheme,
  useMediaQuery,
  Checkbox,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  bindMenu,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { graphql, useFragment } from "react-relay";
import { ArrowForwardRounded, MoreHoriz } from "@mui/icons-material";

import { ClientStatus } from "../../constants";
import { useSwitchUser } from "../../hooks/useSwitchUser";

import { ClientCard_client$key } from "./__generated__/ClientCard_client.graphql";
import { CardAvatar } from "./CardAvatar";
import { ClientStats } from "./ClientStats";
import { getTodayDaysDiff } from "../../utils/date";
import { useNavigate } from "react-router-dom";
import { COACH_CLIENT_OVERVIEW_ROUTE } from "../../routes/routes";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  selected: {
    "& $avatar": {
      display: "none",
    },
    "& $checkbox": {
      display: "block",
    },
  },

  selectable: {
    "&:hover $avatar": {
      display: "none",
    },
    "&:hover $checkbox": {
      display: "block",
    },
  },

  actionArea: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    "& > div": {
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",

      "& > div": {
        marginBottom: 0,
      },
    },
  },

  actionAreaArchived: {
    padding: theme.spacing(3),
    justifyContent: "space-between",
  },

  header: {
    padding: 0,
    width: "100%",

    "& .MuiCardHeader-avatar": {
      marginRight: theme.spacing(1),
    },
    "& .MuiCardHeader-content": {
      overflow: "hidden",
    },

    [theme.breakpoints.up("md")]: {
      width: "28%",
    },
  },

  headerArchived: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 300,
      width: "30%",
    },
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.black,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(2.5),
  },

  text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  avatar: {
    margin: theme.spacing(0.5),
    width: 40,
    height: 40,
    borderRadius: 20,

    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
      borderRadius: 20,
    },
  },

  checkbox: {
    display: "none",
    borderRadius: theme.spacing(1),

    "& span:nth-child(1)": {
      overflow: "hidden",
      borderRadius: theme.spacing(0.5),
    },

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: theme.spacing(-0.5),
    },

    "&:hover": {
      backgroundColor: `${theme.palette.quote} !important`,
    },

    "&:hover svg": {
      backgroundColor: theme.palette.background.paper,
    },
  },

  email: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  weight: {
    flexShrink: 0,
  },

  chip: {
    fontWeight: "bold",
    fontSize: 11,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(1, 4.5),
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  chipEndPrograms: {
    fontWeight: 600,
    fontSize: 12,
    padding: theme.spacing(0),
    width: 110,
    height: 24,
    backgroundColor: theme.palette.progress.red,
    lineHeight: "13px",
  },
  chipInvited: {
    backgroundColor: theme.palette.activity.habit,
  },
  chipArchived: {
    backgroundColor: theme.palette.secondary.main,
  },
  tag: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "16px",
    width: "17%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: theme.spacing(0, 1.5),
    [theme.breakpoints.down("md")]: {
      width: "auto",
      padding: theme.spacing(0, 0, 1.5, 0),
    },
  },
  noVisibility: {
    visibility: "hidden",
  },
  statsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  endProgramsGreen: {
    backgroundColor: theme.palette.progress.green,
  },
  endProgramsYellow: {
    backgroundColor: theme.palette.progress.yellow,
  },
}));

const clientFragment = graphql`
  fragment ClientCard_client on User {
    id
    email
    username
    tag {
      title
    }
    displayName
    clientStatus
    photoURL
    lastLoginAt(format: "fromNow")
    endPrograms
  }
`;

export interface ClientCardProps extends Omit<CardProps, "onSelect"> {
  client: ClientCard_client$key;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: (clientId: string, selected: boolean) => void;
}

export function ClientCard(props: ClientCardProps) {
  const navigate = useNavigate();
  const {
    className,
    client: clientRef,
    selectable,
    onSelect,
    selected,
    ...other
  } = props;
  const client = useFragment(clientFragment, clientRef);
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const userId = client.id;
  const isActive = client.clientStatus === ClientStatus.ACTIVE;
  const name = client.displayName || client.email;
  const tag = client?.tag?.title || "";
  const link = COACH_CLIENT_OVERVIEW_ROUTE.replace(
    ":username",
    client.username,
  );
  const dateProgram =
    client.endPrograms &&
    dayjs(client.endPrograms, "YYYY-MM-DD").format("MMM DD, YYYY");

  const switchUser = useSwitchUser();

  const daysDiff = React.useMemo(() => {
    return (
      client.endPrograms &&
      getTodayDaysDiff(dayjs(client.endPrograms, "YYYY-MM-DD").toISOString())
    );
  }, [client.endPrograms]);

  const handleSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (onSelect) {
        const clientId = event.currentTarget.value;

        onSelect(clientId, checked);
      }
    },
    [onSelect],
  );

  const handleStopPropagation = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
      event.stopPropagation(),
    [],
  );

  const handleCardClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => navigate(link),
    [link],
  );

  const handleLoginAs = React.useCallback(
    (event) => {
      switchUser(userId);
    },
    [switchUser, userId],
  );

  const actionsMenuState = usePopupState({
    variant: "popover",
    popupId: "actions-menu",
  });
  const actionsMenuToggle = bindToggle(actionsMenuState);

  const handleActionsMenuToggle = React.useCallback(
    (event) => {
      event.stopPropagation();
      actionsMenuToggle.onClick(event);
    },
    [actionsMenuToggle],
  );

  const actionsMenuButton = (
    <IconButton
      {...actionsMenuToggle}
      onClick={handleActionsMenuToggle}
      size="large"
    >
      <MoreHoriz />
    </IconButton>
  );

  return (
    <Card
      className={clsx(
        s.root,
        className,
        selectable && s.selectable,
        selected && s.selected,
      )}
      {...other}
    >
      <CardActionArea
        className={clsx(
          s.actionArea,
          client.clientStatus === ClientStatus.ARCHIVED && s.actionAreaArchived,
        )}
        onClick={handleCardClick}
      >
        <CardHeader
          className={clsx(
            s.header,
            client.clientStatus === ClientStatus.ARCHIVED && s.headerArchived,
          )}
          avatar={
            <>
              <CardAvatar className={s.avatar} alt={name} src={client.photoURL}>
                {name[0]?.toUpperCase()}
              </CardAvatar>
              {selectable && (
                <Checkbox
                  className={s.checkbox}
                  checked={selected}
                  value={client.id}
                  onChange={handleSelect}
                  onClick={handleStopPropagation}
                  style={{ marginRight: 6 }}
                />
              )}
            </>
          }
          title={name}
          titleTypographyProps={{
            variant: "h5",
            className: s.title,
          }}
          action={!mdUp && actionsMenuButton}
        />

        {isActive && (
          <>
            <Typography className={s.tag} children={tag} />
            <Chip
              className={clsx(
                s.chip,
                s.chipEndPrograms,
                !client.endPrograms && s.noVisibility,
                daysDiff === 1 && s.endProgramsYellow,
                daysDiff > 1 && s.endProgramsGreen,
              )}
              label={dateProgram}
            />

            <Box className={s.statsContainer}>
              <ClientStats clientId={client.id} />
            </Box>
          </>
        )}
        {client.clientStatus === ClientStatus.ARCHIVED && (
          <Chip className={clsx(s.chip, s.chipArchived)} label="Archived" />
        )}
        {mdUp && actionsMenuButton}
        {mdUp && <ArrowForwardRounded />}
      </CardActionArea>
      <Menu {...bindMenu(actionsMenuState)} keepMounted>
        <MenuItem onClick={handleLoginAs} disabled={!isActive}>
          Login as Client
        </MenuItem>
      </Menu>
    </Card>
  );
}
