import React from "react";

export type ProgramWeek = {
  id: string;
  week: number;
};

export const ProgramWeeksContext =
  React.createContext<Array<ProgramWeek>>(null);

export function useProgramWeeks(): any {
  return React.useContext(ProgramWeeksContext);
}
