/**
 * @generated SignedSource<<fc9b1cc588408275b409d91f4eee5e3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRole = "CLIENT" | "COACH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UsersTableRow_user$data = {
  readonly clientsCount: number;
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly lastLoginAt: string | null | undefined;
  readonly lastLoginAtRaw: string | null | undefined;
  readonly photoURL: string | null | undefined;
  readonly programsCount: number;
  readonly role: UserRole;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenu_user">;
  readonly " $fragmentType": "UsersTableRow_user";
};
export type UsersTableRow_user$key = {
  readonly " $data"?: UsersTableRow_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsersTableRow_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsersTableRow_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenu_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": "lastLoginAtRaw",
      "args": null,
      "kind": "ScalarField",
      "name": "lastLoginAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "lastLoginAt",
      "storageKey": "lastLoginAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "programsCount",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "62bfcbaf594fd0c34c45778e3fb2a531";

export default node;
