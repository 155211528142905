import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useMediaMobile } from "../../hooks/useMediaMobile";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { colorSystem } from "../../theme";
import { SOMETHING_WENT_WRONG, AppError } from "../../constants";

import { SignUpSetUpSide } from "./SignUpSetUpSide";
import { SignUpSetUpForm, SignUpValues } from "./SignUpSetUpForm";
import { useSignInMutation } from "./mutations/SignIn";
import { useSignUpMutation } from "./mutations/SignUp";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
  },

  side: {
    width: "40%",
    backgroundColor: theme.palette.common.white,
  },

  form: {
    margin: "auto",
    backgroundColor: colorSystem.white2,

    "&$mobile": {
      padding: theme.spacing(4),
      textAlign: "center",
    },
  },

  mobile: {},
}));

export interface SignUpSetUpScreenProps extends BoxProps {
  code?: string;
  id?: string;
  email?: string;
}

export function SignUpSetUpScreen(props: SignUpSetUpScreenProps) {
  const { className, code, id, email, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [values, setValues] = React.useState<SignUpValues>({
    name: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<SignUpValues>({
    name: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaMobile();
  const snackAlert = useSnackAlert();
  const [trackEvent] = useAnalytics();
  const onError = useGenericErrorHandler();
  const [signUp, signingUp] = useSignUpMutation();
  const [signIn, signingIn] = useSignInMutation();

  const handleChange = React.useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (Object.keys(values).includes(name)) {
        setValues((values) => ({
          ...values,
          [name]: value,
        }));
        setErrors((errors) => ({
          ...errors,
          [name]: value ? undefined : errors[name],
        }));
      }
    },
    [values],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      event.preventDefault();

      signUp({
        variables: {
          input: {
            id,
            email,
            displayName: values.name,
            password: values.password,
            timeZone: dayjs.tz.guess(),
          },
        },
        onCompleted({ signUp }, errors) {
          if (errors && errors[0]) {
            const err = errors[0] as AppError;

            console.error(err);

            if (err.code && err.code.includes("email")) {
              setErrors((errors) => ({ ...errors, email: err.message }));
            } else if (err.code && err.code.includes("password")) {
              setErrors((errors) => ({ ...errors, password: err.message }));
            } else {
              snackAlert({ severity: "error", message: SOMETHING_WENT_WRONG });
            }
          } else {
            setLoading(true);

            firebase
              .auth()
              .applyActionCode(code)
              .then(() => firebase.auth().signInWithCustomToken(signUp.idToken))
              .then((x) => x.user.getIdToken())
              .then((idToken) => {
                trackEvent("Coach - Email Verified");
                signIn({
                  variables: { idToken },
                  onCompleted(_, errors) {
                    if (errors && errors[0]) {
                      onError(errors[0]);
                    } else {
                      window.location.href =
                        "/coach/programs/published?photoPrompt=true";
                    }
                  },
                  onError,
                });
              })
              .catch((error) => {
                console.error(error);
                snackAlert({ severity: "error", message: error.message });
              })
              .finally(() => {
                setLoading(false);
              });
          }
        },
        onError,
      });
    },
    [
      code,
      email,
      id,
      onError,
      signIn,
      signUp,
      snackAlert,
      trackEvent,
      values.name,
      values.password,
    ],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {!isMobile && <SignUpSetUpSide className={s.side} />}

      <SignUpSetUpForm
        className={clsx(s.form, isMobile && s.mobile)}
        values={values}
        errors={errors}
        onChange={handleChange}
        onSubmit={handleSubmit}
        submitDisabled={
          !values.name ||
          values.password?.length < 8 ||
          loading ||
          signingUp ||
          signingIn
        }
      />
    </Box>
  );
}
