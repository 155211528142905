import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { ClientContext } from "../../../../hooks/useClient";
import { ClientContext as AlternativeClientContext } from "../../../../contexts/ClientContext";
import { CoachClientCalendarScreen } from "../../../../components/screen/CoachClientCalendarScreen";
import { CoachClientCalendarRouteQuery } from "./__generated__/CoachClientCalendarRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachClientCalendarRoute() {
  const { username } = useParams();

  const props = useLazyLoadQuery<CoachClientCalendarRouteQuery>(
    graphql`
      query CoachClientCalendarRouteQuery($username: String!) {
        client: user(username: $username) {
          ... on User {
            username
            email
            displayName
            ...ChooseProgramEnrollDialog_client
          }
        }

        ...CoachClientCalendarScreen_root @arguments(username: $username)
      }
    `,
    {
      username: username,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const context = useContext(AlternativeClientContext);
  context.setCoachClientQuery(props);

  return (
    props.client && (
      <>
        <ClientContext.Provider value={props.client}>
          <CoachClientCalendarScreen rootRef={props} />
        </ClientContext.Provider>
        <TrackInfoTool
          trackInfo={{
            name: "Coach - Client Calendar",
            properties: {
              clientUsername: props.client.username,
            },
          }}
        />
      </>
    )
  );
}
