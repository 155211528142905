import Url from "url";

import React, { useEffect } from "react";
import {
  useFragment,
  useMutation,
  graphql,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Match } from "found";

import { AuthTypeEnum, UserRole } from "../../constants";
import { AppThemeProvider } from "../app/ThemeProvider";
import { CurrentUserContext } from "../../hooks/useCurrentUser";
import { useLiveUpdates } from "../../hooks/useLiveUpdates";

import { useSwitchUser } from "../../hooks/useSwitchUser";
import { isMobileApp } from "../../utils/mobile";

import {
  CurrentUserProvider_user$data,
  CurrentUserProvider_user$key,
} from "./__generated__/CurrentUserProvider_user.graphql";
import { CurrentUserProvider_root$key } from "./__generated__/CurrentUserProvider_root.graphql";
import dayjs from "dayjs";
import firebase from "firebase";

const fragmentRoot = graphql`
  fragment CurrentUserProvider_root on Root
  @argumentDefinitions(
    workspace: { type: String }
    program: { type: String }
    currentUserId: { type: "ID!", defaultValue: "" }
    overrideCurrentUser: { type: Boolean, defaultValue: false }
  ) {
    me @skip(if: $overrideCurrentUser) {
      ...CurrentUserProvider_user
    }

    me: node(id: $currentUserId) @include(if: $overrideCurrentUser) {
      ...CurrentUserProvider_user
    }

    workspace(slug: $workspace, programSlug: $program) {
      slug
      ...ThemeProvider_workspace
    }
  }
`;

const fragmentUser = graphql`
  fragment CurrentUserProvider_user on User
  @argumentDefinitions(
    shouldFetchNotifications: { type: "Boolean!", defaultValue: true }
  ) {
    id
    username
    email
    rawEmail: email(raw: true)
    displayName
    photoURL
    phone
    phoneVerified
    timeZone
    unreadMessagesCounter
    notificationsActive
    notificationUnreadCount
    role
    location
    birthday
    age
    gender
    units
    height
    weight
    createdAt
    isSample
    isImpersonating
    admin
    topToolbar
    clientsCount
    clientsCountNoSample: clientsCount(noSample: true)
    programsCount
    unreadActivitiesCount
    trialExpiryDate(raw: true)
    trialExpiryDateTimestamp: trialExpiryDate(format: "X")
    trialExpired
    timeZone
    plan
    planTier
    subscription {
      status
    }

    coach {
      username
      email
      displayName
      photoURL
    }

    accounts {
      id
      role
      isSample
      username
      email
      displayName(raw: true)
      photoURL
      phone
      timeZone
      createdAt
      role
      ...UserAccountMenuItem_user
    }

    groups(first: 3, after: null) {
      totalCount
    }

    nutritionTarget {
      id
    }

    ...Notifications_user @include(if: $shouldFetchNotifications)
    ...GroupNavItemList_me
  }
`;

const updateUserMutation = graphql`
  mutation CurrentUserProviderUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const isIncomplete = (user: CurrentUserProvider_user$data): boolean => {
  return !(user.displayName && user.birthday && user.location);
};

const getReturnUrl = (url: string) =>
  Url.parse(url, true).query.returnUrl as string;

const getRedirect = (
  user: CurrentUserProvider_user$data | null,
  authType: AuthTypeEnum,
  currentUrl: string,
) => {
  const returnUrl = getReturnUrl(currentUrl);

  if (/^\/(\?|$)/.test(currentUrl)) {
    return !user
      ? "/login"
      : user.role === UserRole.COACH && !isMobileApp
        ? "/coach/programs"
        : "/home";
  }

  if (user) {
    if (AuthTypeEnum.NOBODY === authType && returnUrl) {
      return returnUrl;
    }

    if (authType === AuthTypeEnum.ADMIN && user.admin) {
      return null;
    } else if (user.role === AuthTypeEnum.CLIENT) {
      if (
        isIncomplete(user) &&
        currentUrl.indexOf("/signup/client/complete") === -1
      ) {
        return (
          "/signup/client/complete?returnUrl=" + encodeURIComponent(currentUrl)
        );
      }

      if ([AuthTypeEnum.COACH, AuthTypeEnum.NOBODY].includes(authType)) {
        return "/home";
      }
    }

    if (user.role === AuthTypeEnum.COACH) {
      if ([AuthTypeEnum.CLIENT, AuthTypeEnum.NOBODY].includes(authType)) {
        return "/coach";
      }
    }
  } else if (authType && authType !== AuthTypeEnum.NOBODY) {
    return "/login?returnUrl=" + encodeURIComponent(currentUrl);
  }
};

const getSwitchableUser = (
  user: CurrentUserProvider_user$data,
  authType: AuthTypeEnum,
): string | null => {
  if (!user) {
    return null;
  }

  if (![AuthTypeEnum.CLIENT, AuthTypeEnum.COACH].includes(authType)) {
    return null;
  }

  const mirrorUser = user.accounts.find(
    (it) => it.role !== user.role && (it.role === "COACH" || it.isSample),
  );

  return mirrorUser ? mirrorUser.id : null;
};

export interface CurrentUserProviderProps {
  children: React.ReactNode;
  rootRef: CurrentUserProvider_root$key;
  me: any;
  match?: Match;
  liveUpdates?: boolean;
}

export const CurrentUserProvider = (props: CurrentUserProviderProps) => {
  const [updateUser, updatingUser] = useMutation(updateUserMutation);
  const { match, rootRef, liveUpdates = true } = props;
  const { me, workspace } = useFragment(fragmentRoot, rootRef);
  const user = useFragment(fragmentUser, me as CurrentUserProvider_user$key);
  const switchUser = useSwitchUser();

  const relay = useRelayEnvironment();
  useLiveUpdates({
    userId: user?.id ?? null,
    relay: relay,
    enabled: user?.id && liveUpdates,
  });

  useEffect(() => {
    const Sentry: any = window["Sentry"];
    if (!Sentry) {
      return;
    }
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        Sentry.setUser({ id: user.uid, email: user.email });
      } else {
        Sentry.setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const isActive = match && match.router.isActive(match, location as any);
  const currentUrl = window.location.pathname;
  const authType =
    match &&
    match.routes
      .map(({ authType }) =>
        typeof authType === "function" ? authType(match) : authType,
      )
      .find(Boolean);

  const switchUserId = getSwitchableUser(user, authType);
  let redirect = isActive && getRedirect(user, authType, currentUrl);

  if (
    /^\/login/.test(redirect) &&
    authType === AuthTypeEnum.CLIENT &&
    match.params.program
  ) {
    redirect = redirect.replace(/^(\/login)/, `/${(workspace as any).slug}`);
  }

  React.useEffect(() => {
    if (redirect) {
      if (switchUserId) {
        switchUser(switchUserId, false);
      } else {
        // TODO change to navigate()
        window.location.href = redirect;
      }
    }
  }, [history, redirect, switchUser, switchUserId]);

  React.useEffect(() => {
    if (user && !user.isImpersonating && !user.timeZone && !updatingUser) {
      updateUser({
        variables: {
          input: { id: user.id, timeZone: dayjs.tz.guess() },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppThemeProvider workspace={workspace}>
      <CurrentUserContext.Provider value={user}>
        {redirect ? null : props.children}
      </CurrentUserContext.Provider>
    </AppThemeProvider>
  );
};
