import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { RefreshSlug } from "../routes/RefreshSlug";
import { HistoryBlock } from "../history-block/HistoryBlock";
import { AppLayout } from "../app/AppLayout";
import { useQueryParam } from "../../hooks/useQueryParam";
import {
  SelectAddableClientsDialog,
  SelectAddableClientsDialogProps,
} from "../coach-clients/SelectAddableClientsDialog";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { maybePluralize } from "../../utils/text";
import { ClientForm } from "../client-forms/ClientForm";

import { CoachClientsFormScreen_root$key } from "./__generated__/CoachClientsFormScreen_root.graphql";
import { useSendClientFormMutation } from "./mutations/SendClientForm";
import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_FORMS_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    position: "relative",
  },
}));

const rootFragment = graphql`
  fragment CoachClientsFormScreen_root on Root
  @argumentDefinitions(slug: { type: "String!" }) {
    clientForm(slug: $slug) {
      ...RefreshSlug
      ...ClientForm_clientForm
      id
      clients {
        ...SelectAddableClientsDialog_clients @relay(mask: false)
      }
    }
    clients(first: 999, status: ACTIVE) {
      ...SelectAddableClientsDialog_clients
    }
  }
`;

export interface CoachClientsFormScreenProps {
  root: CoachClientsFormScreen_root$key;
}

export function CoachClientsFormScreen({
  root: rootRef,
}: CoachClientsFormScreenProps) {
  const root = useFragment(rootFragment, rootRef);
  const navigate = useNavigate();
  const s = useStyles();
  const { clientForm, clients } = root;
  const [previewQueryParam] = useQueryParam("preview", false);
  const previewing = Boolean(previewQueryParam);
  const snackAlert = useSnackAlert();
  const [openSelectClients, setOpenSelectClients] = React.useState(false);
  const [sendClientForm, sendClientFormInFlight] = useSendClientFormMutation();

  const handleSendForm = React.useCallback(
    () => setOpenSelectClients(true),
    [],
  );

  const handleCloseClientsDialog = React.useCallback(() => {
    setOpenSelectClients(false);
  }, []);

  const handleSelectClients: SelectAddableClientsDialogProps["onSelectClients"] =
    React.useCallback(
      (clients) =>
        sendClientForm({
          variables: {
            input: {
              id: clientForm.id,
              clients: clients.map(({ id }) => id),
            },
          },
          onSuccess() {
            snackAlert({
              severity: "success",
              message: `Form submitted to ${maybePluralize(
                clients.length,
                "client",
              )}`,
            });
            navigate(COACH_CLIENTS_FORMS_ROUTE);
          },
        }),
      [clientForm.id, sendClientForm, snackAlert],
    );

  return (
    <AppLayout appDrawer={false} appBar={false} hideUpgradeBanner>
      <Box className={s.root}>
        <RefreshSlug nodesRef={[clientForm]} />
        <HistoryBlock>
          <ClientForm
            clientForm={clientForm}
            onSendForm={handleSendForm}
            readOnly={previewing}
          />
        </HistoryBlock>
      </Box>

      {openSelectClients && (
        <SelectAddableClientsDialog
          open
          clients={clients}
          title="Send Client Intake Form"
          subtitle="Select one or more clients to send this form to."
          onSelectClients={handleSelectClients}
          onClose={handleCloseClientsDialog}
          selectedClientEmails={clientForm.clients.edges.map(
            ({ node }) => node.email,
          )}
          SubmitButtonProps={{
            children: "Send form",
          }}
          disabled={sendClientFormInFlight}
        />
      )}
    </AppLayout>
  );
}
