import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { getISODate, getDayAbbreviation } from "../../utils/date";

import { ClientLast7DaysCompletion_completionByDate$key } from "./__generated__/ClientLast7DaysCompletion_completionByDate.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  day: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: theme.spacing(3),

    "&:not(:last-child)": {
      marginRight: theme.spacing(1.5),
    },

    "&::before": {},

    "&:not($completed)::before": {},

    "&$empty": {
      color: theme.palette.quote,
    },

    "&$none": {
      color: theme.palette.progress.red,
    },

    "&$some": {
      color: theme.palette.progress.yellow,
    },
  },

  check: {
    width: 20,
    height: 20,
    color: theme.palette.progress.green,
  },

  dayText: {
    fontSize: 14,
    fontWeight: "bold",
  },

  completed: {
    color: theme.palette.progress.green,
  },
  none: {},
  some: {},
  empty: {},
}));

const fragment = graphql`
  fragment ClientLast7DaysCompletion_completionByDate on CompletionByDate
  @relay(plural: true) {
    date
    completion {
      completed
      total
      rate
    }
  }
`;

export interface ClientLast7DaysCompletionProps extends BoxProps {
  fragmentRef: ClientLast7DaysCompletion_completionByDate$key;
}

export function ClientLast7DaysCompletion(
  props: ClientLast7DaysCompletionProps,
) {
  const { className, fragmentRef, ...other } = props;
  const s = useStyles();
  const completionByDate = useFragment(fragment, fragmentRef);

  const dates = React.useMemo(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    return Array.from({ length: 7 })
      .map((_, i) => {
        const date = new Date(start);
        date.setDate(date.getDate() - i);
        return getISODate(date);
      })
      .reverse();
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {dates.map((date) => {
        const day = getDayAbbreviation(date);
        const completion = completionByDate.find(
          (it) => it.date === date,
        )?.completion;
        const tooltipText = `${completion?.completed || 0}/${
          completion?.total || 0
        } completed`;

        return (
          <Tooltip key={date} arrow placement="top" title={tooltipText}>
            <Box
              className={clsx({
                [s.day]: true,
                [s.completed]: completion?.rate === 100,
                [s.some]: completion?.rate > 0 && completion?.rate < 100,
                [s.none]: completion?.rate === 0,
                [s.empty]: !completion,
              })}
            >
              <Typography
                className={s.dayText}
                component="span"
                children={day}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
}
