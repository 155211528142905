/**
 * @generated SignedSource<<2a7199c3c58785a35e9ab140526cbb8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachSettingsPlanStatusText_subscription$data = {
  readonly cancelAtPeriodEnd: boolean;
  readonly canceledAtDate: string | null | undefined;
  readonly periodEndDate: string | null | undefined;
  readonly status: string | null | undefined;
  readonly " $fragmentType": "CoachSettingsPlanStatusText_subscription";
};
export type CoachSettingsPlanStatusText_subscription$key = {
  readonly " $data"?: CoachSettingsPlanStatusText_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachSettingsPlanStatusText_subscription">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMMM D, YYYY"
  },
  {
    "kind": "Literal",
    "name": "utc",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachSettingsPlanStatusText_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "canceledAtDate",
      "storageKey": "canceledAtDate(format:\"MMMM D, YYYY\",utc:true)"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "periodEndDate",
      "storageKey": "periodEndDate(format:\"MMMM D, YYYY\",utc:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelAtPeriodEnd",
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};
})();

(node as any).hash = "d193da6626fe8b72c7a5c0d2f832331e";

export default node;
