import React from "react";

const Settings = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4022 11.5801C18.7599 11.7701 19.0358 12.0701 19.23 12.3701C19.6081 12.9901 19.5775 13.7501 19.2096 14.4201L18.4942 15.6201C18.1161 16.2601 17.4109 16.6601 16.6853 16.6601C16.3277 16.6601 15.9291 16.5601 15.6021 16.3601C15.3364 16.1901 15.0298 16.1301 14.7027 16.1301C13.691 16.1301 12.8428 16.9601 12.8121 17.9501C12.8121 19.1001 11.8719 20.0001 10.6967 20.0001H9.3068C8.12133 20.0001 7.18113 19.1001 7.18113 17.9501C7.16069 16.9601 6.31247 16.1301 5.30073 16.1301C4.96348 16.1301 4.6569 16.1901 4.40141 16.3601C4.07438 16.5601 3.6656 16.6601 3.31813 16.6601C2.58232 16.6601 1.87717 16.2601 1.49905 15.6201L0.793898 14.4201C0.415773 13.7701 0.395334 12.9901 0.773459 12.3701C0.936972 12.0701 1.24356 11.7701 1.59102 11.5801C1.87717 11.4401 2.06112 11.2101 2.23486 10.9401C2.74584 10.0801 2.43925 8.95012 1.57059 8.44012C0.558848 7.87012 0.231821 6.60012 0.814337 5.61012L1.49905 4.43012C2.09178 3.44012 3.35901 3.09012 4.38097 3.67012C5.27007 4.15012 6.42488 3.83012 6.94608 2.98012C7.10959 2.70012 7.20157 2.40012 7.18113 2.10012C7.16069 1.71012 7.27311 1.34012 7.46728 1.04012C7.8454 0.420122 8.53012 0.0201221 9.27614 0.00012207H10.7171C11.4734 0.00012207 12.1581 0.420122 12.5362 1.04012C12.7201 1.34012 12.8428 1.71012 12.8121 2.10012C12.7917 2.40012 12.8837 2.70012 13.0472 2.98012C13.5684 3.83012 14.7232 4.15012 15.6225 3.67012C16.6342 3.09012 17.9117 3.44012 18.4942 4.43012L19.1789 5.61012C19.7716 6.60012 19.4446 7.87012 18.4227 8.44012C17.554 8.95012 17.2474 10.0801 17.7686 10.9401C17.9321 11.2101 18.1161 11.4401 18.4022 11.5801ZM7.10959 10.0101C7.10959 11.5801 8.40748 12.8301 10.012 12.8301C11.6164 12.8301 12.8837 11.5801 12.8837 10.0101C12.8837 8.44012 11.6164 7.18012 10.012 7.18012C8.40748 7.18012 7.10959 8.44012 7.10959 10.0101Z"
      fill={fill}
    />
  </svg>
);

export default Settings;
