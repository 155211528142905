import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  normal: {
    marginBottom: theme.spacing(1),
  },
  big: {
    marginBottom: theme.spacing(3),
  },

  header: {
    color: theme.palette.common.black,

    "$big &": {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: "39px",
    },

    "$normal &": {
      fontSize: 24,
      fontWeight: 600,
    },
  },

  caret: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: theme.palette.primary.main,

    "$big &": {
      fontSize: 64,
    },

    "$normal &": {
      width: 15,
    },
  },

  paper: {
    minWidth: 262,
  },

  itemText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.common.black,
  },

  subHeader: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2),
  },

  item: {
    padding: theme.spacing(1, 2),
  },

  selected: {
    backgroundColor: theme.palette.quote,
  },
}));

type Variant = "normal" | "big" | "header";
type DataAttrs = Record<string, string | number>;

export interface DropdownMenuItem {
  children: React.ReactNode | string;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  variant?: Variant;
  data?: DataAttrs;
  selected?: boolean;
}

export interface DropdownMenuProps extends BoxProps {
  header?: React.ReactNode;
  items: DropdownMenuItem[];
  variant?: Variant;
  subHeader?: React.ReactNode;
  menuId?: string;
  triggerGenerator?: (bindState, menuState) => React.JSX.Element;
}

const dataAttrs = (attrs: DataAttrs) =>
  attrs
    ? Object.entries(attrs).reduce(
        (acc, [name, value]) =>
          Object.assign(acc, {
            ["data-" + name]: value,
          }),
        {},
      )
    : {};

export function DropdownMenu(props: DropdownMenuProps) {
  const {
    className,
    header,
    subHeader,
    menuId = "dropdown-menu",
    variant = "normal",
    triggerGenerator,
    items,
    ...other
  } = props;
  const s = useStyles();
  const menuState = usePopupState({
    variant: "popover",
    popupId: menuId,
  });

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      menuState.close();

      const { index } = event.currentTarget.dataset;
      const onClick = items[index]?.onClick;

      if (onClick) {
        onClick(event);
      }
    },
    [items, menuState],
  );

  const trigger = React.useMemo(
    () =>
      triggerGenerator ? (
        triggerGenerator(bindTrigger, menuState)
      ) : (
        <CaretDownIcon className={s.caret} {...bindTrigger(menuState)} />
      ),
    [menuState, s.caret, triggerGenerator],
  );

  return (
    <Box
      className={clsx(s.root, className, {
        [s.big]: variant === "big",
        [s.normal]: variant === "normal",
      })}
      {...other}
    >
      {header && <Typography className={s.header} children={header} />}

      {trigger}

      <Menu
        PaperProps={{
          className: s.paper,
        }}
        {...bindMenu(menuState)}
      >
        {subHeader && (
          <Typography
            className={clsx(s.itemText, s.subHeader)}
            children={subHeader}
          />
        )}

        {items.map(({ variant, children, data, selected }, index) =>
          variant === "header" ? (
            <Typography
              key={index}
              className={clsx(s.itemText, s.subHeader)}
              children={children}
            />
          ) : (
            <MenuItem
              key={index}
              className={clsx(s.item, { [s.selected]: selected })}
              onClick={handleClick}
              data-index={index}
              {...dataAttrs(data)}
            >
              <Typography className={s.itemText} children={children} />
            </MenuItem>
          ),
        )}
      </Menu>
    </Box>
  );
}
