/**
 * @generated SignedSource<<d1218f64657d795e36c23bfa8e48c139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type UpsertComponentInput = {
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  days?: ReadonlyArray<boolean> | null | undefined;
  draft?: string | null | undefined;
  duration?: number | null | undefined;
  habitPrompt?: string | null | undefined;
  iconName?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  locked?: boolean | null | undefined;
  messageContent?: string | null | undefined;
  messageTime?: string | null | undefined;
  reminderTime?: string | null | undefined;
  reminderType?: ReminderType | null | undefined;
  repeat?: ComponentRepeat | null | undefined;
  status?: ComponentStatus | null | undefined;
  title?: string | null | undefined;
  type?: ComponentType | null | undefined;
  weekId?: string | null | undefined;
};
export type ExerciseTextEditorComponentTitleMutation$variables = {
  input: UpsertComponentInput;
};
export type ExerciseTextEditorComponentTitleMutation$data = {
  readonly upsertComponent: {
    readonly component: {
      readonly updatedAt: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"DragCellRenderer_component">;
    };
  } | null | undefined;
};
export type ExerciseTextEditorComponentTitleMutation = {
  response: ExerciseTextEditorComponentTitleMutation$data;
  variables: ExerciseTextEditorComponentTitleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "utc",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": "updatedAt(utc:true)"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExerciseTextEditorComponentTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DragCellRenderer_component"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExerciseTextEditorComponentTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previousStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iconName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "content",
                "storageKey": "content(draft:true)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "teaser",
                "storageKey": "teaser(draft:true)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "image",
                "storageKey": "image(draft:true)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "title",
                "storageKey": "title(draft:true)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "days",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "draftExists",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isComponentIcon"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8643cbdf8e79e37b6e877f24b153518",
    "id": null,
    "metadata": {},
    "name": "ExerciseTextEditorComponentTitleMutation",
    "operationKind": "mutation",
    "text": "mutation ExerciseTextEditorComponentTitleMutation(\n  $input: UpsertComponentInput!\n) {\n  upsertComponent(input: $input) {\n    component {\n      ...DragCellRenderer_component\n      updatedAt(utc: true)\n      id\n    }\n  }\n}\n\nfragment ChangeComponentCoverDialog_component_3CJfPz on Component {\n  content(draft: true)\n  teaser(draft: true)\n  image(draft: true)\n  ...ComponentPreview_component_3CJfPz\n  program {\n    imageURL\n    id\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment ComponentPreview_component_3CJfPz on Component {\n  title(draft: true)\n  teaser(draft: true)\n  image(draft: true)\n  ...ComponentIcon_component\n}\n\nfragment CopyComponentDialog_component on Component {\n  id\n  program {\n    id\n  }\n}\n\nfragment DragCellRenderer_component on Component {\n  ...WeekComponentMenu_component\n  id\n  slug\n  type\n  days\n  status\n  locked\n  draftExists\n}\n\nfragment SelectComponentIcons_component on Component {\n  id\n  iconName\n  type\n}\n\nfragment WeekComponentMenu_component on Component {\n  id\n  type\n  status\n  previousStatus\n  weekId\n  locked\n  ...SelectComponentIcons_component\n  ...CopyComponentDialog_component\n  ...ChangeComponentCoverDialog_component_3CJfPz\n}\n"
  }
};
})();

(node as any).hash = "d2a7f3d04d8066060bb530f84f42e5ff";

export default node;
