/**
 * @generated SignedSource<<0cc9066e0c8d6ad4d38968068ad5795f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateNotificationsSettingInput = {
  activityCompletion?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  dailyUpdate?: boolean | null | undefined;
  groupReplyEmail?: boolean | null | undefined;
  groupReplyPush?: boolean | null | undefined;
  newGroupPostEmail?: boolean | null | undefined;
  newGroupPostPush?: boolean | null | undefined;
  newMessageEmail?: boolean | null | undefined;
  newMessagePush?: boolean | null | undefined;
  newResponseEmail?: boolean | null | undefined;
  newResponsePush?: boolean | null | undefined;
  remindersEmail?: boolean | null | undefined;
  remindersPush?: boolean | null | undefined;
  remindersSms?: boolean | null | undefined;
  userId?: string | null | undefined;
  weeklyUpdate?: boolean | null | undefined;
};
export type ClientSettingsDialogUpdateNotificationsSettingMutation$variables = {
  input: UpdateNotificationsSettingInput;
};
export type ClientSettingsDialogUpdateNotificationsSettingMutation$data = {
  readonly updateNotificationsSetting: {
    readonly notificationsSetting: {
      readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsDialog_notificationsSetting">;
    } | null | undefined;
  } | null | undefined;
};
export type ClientSettingsDialogUpdateNotificationsSettingMutation = {
  response: ClientSettingsDialogUpdateNotificationsSettingMutation$data;
  variables: ClientSettingsDialogUpdateNotificationsSettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsDialogUpdateNotificationsSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotificationsSettingPayload",
        "kind": "LinkedField",
        "name": "updateNotificationsSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsSetting",
            "kind": "LinkedField",
            "name": "notificationsSetting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientSettingsDialog_notificationsSetting"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSettingsDialogUpdateNotificationsSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotificationsSettingPayload",
        "kind": "LinkedField",
        "name": "updateNotificationsSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsSetting",
            "kind": "LinkedField",
            "name": "notificationsSetting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newMessageEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newResponseEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weeklyUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dailyUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a74d54e5ed147d107e807b6e3d4f2188",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsDialogUpdateNotificationsSettingMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSettingsDialogUpdateNotificationsSettingMutation(\n  $input: UpdateNotificationsSettingInput!\n) {\n  updateNotificationsSetting(input: $input) {\n    notificationsSetting {\n      ...ClientSettingsDialog_notificationsSetting\n      id\n    }\n  }\n}\n\nfragment ClientSettingsDialog_notificationsSetting on NotificationsSetting {\n  newMessageEmail\n  newResponseEmail\n  weeklyUpdate\n  dailyUpdate\n}\n"
  }
};
})();

(node as any).hash = "acb885f4cb54d458971af6b8f67b9c56";

export default node;
