/**
 * @generated SignedSource<<80fd8fa71c3c0d0b7d50f9e41408fa47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientMealLoggingScreen_root$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntriesList_user">;
  } | null | undefined;
  readonly workspace: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntriesList_workspace">;
  };
  readonly " $fragmentType": "ClientMealLoggingScreen_root";
};
export type ClientMealLoggingScreen_root$key = {
  readonly " $data"?: ClientMealLoggingScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingScreen_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "date"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientMealLoggingScreen_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "date",
              "variableName": "date"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ClientMealLoggingEntriesList_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Workspace",
      "kind": "LinkedField",
      "name": "workspace",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientMealLoggingEntriesList_workspace"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "79e05c23bb93dcfd805b309dff8e9d6a";

export default node;
