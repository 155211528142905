import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UnlikeMutation } from "./__generated__/UnlikeMutation.graphql";

const mutation = graphql`
  mutation UnlikeMutation($input: UnlikeInput!) {
    unlike(input: $input) {
      removedLikeId
    }
  }
`;

export const useUnlikeMutation = () =>
  useConfiguredMutation<UnlikeMutation>(mutation, (config) => ({
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: config.variables.input.id,
        connectionKeys: [
          {
            key: "LikesList_likes",
          },
        ],
        pathToConnection: ["node", "likes"],
        deletedIDFieldName: "removedLikeId",
      },
    ],

    updater(store) {
      const node = store.get(config.variables.input.id);
      node.setValue(false, "likedByMe");
    },
  }));
