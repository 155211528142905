import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { Message } from "./Message";
import {
  MessagesList_messages$data,
  MessagesList_messages$key,
} from "./__generated__/MessagesList_messages.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 0,
    padding: theme.spacing(2, 0),

    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(2.5),
    },
  },

  wrapper: {
    marginTop: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    alignContent: "flex-end",
  },
}));

const renderMessage = (
  { node }: MessagesList_messages$data["edges"][0],
  index: number,
  edges: MessagesList_messages$data["edges"],
) => {
  const nextNode = index > 0 && edges[index - 1].node;
  const prevNode = edges.length > index + 1 && edges[index + 1].node;
  const displayDate = !prevNode || prevNode.group !== node.group;
  const displayAvatar =
    !nextNode || nextNode.author.username !== node.author.username;
  const displayUserName =
    !prevNode || prevNode.author.username !== node.author.username;
  return (
    <Message
      key={node.id}
      message={node}
      displayDate={displayDate}
      displayUserName={displayUserName}
      displayAvatar={displayAvatar}
    />
  );
};

const messagesFragment = graphql`
  fragment MessagesList_messages on MessageConnection {
    edges {
      cursor
      node {
        id
        group: createdAt(format: "dddd DD MMM yyyy")
        author {
          id
          username
        }
        ...Message_message
      }
    }
  }
`;

export interface MessagesListProps extends BoxProps {
  messagesRef?: MessagesList_messages$key;
  threadId?: string;
}

export function MessagesList(props: MessagesListProps) {
  const { className, messagesRef } = props;
  const messages = useFragment(messagesFragment, messagesRef);
  const s = useStyles();
  const endScrollRef = React.useRef<HTMLInputElement>();
  const renderedMessages =
    messages && messages.edges.slice(0).map(renderMessage);
  const hasMessages = renderedMessages && renderedMessages.length > 0;
  const scrollState = React.useRef({
    scrollHeight: 0,
    height: 0,
  });

  React.useLayoutEffect(() => {
    const interval = setInterval(() => {
      const endScrollEl = endScrollRef.current;
      const scrollEl = endScrollEl?.parentElement;
      const containerEl = scrollEl?.parentElement?.parentElement;
      const scroll = scrollState.current;

      if (containerEl && scroll) {
        const newScrollHeight = scrollEl.scrollHeight;
        const newContainerHeight = containerEl.getBoundingClientRect().height;

        if (newScrollHeight > scroll.scrollHeight) {
          endScrollEl.scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        } else if (newContainerHeight !== scroll.height) {
          endScrollEl.scrollIntoView();
        }

        Object.assign(scroll, {
          scrollHeight: newScrollHeight,
          height: newContainerHeight,
        });
      }
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={clsx(s.root, className)}>
      {hasMessages ? (
        <Box className={s.wrapper}>
          <div ref={endScrollRef} />
          {renderedMessages}
        </Box>
      ) : null}
    </Box>
  );
}
