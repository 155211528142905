import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-database";
import { isLocalEnvironment, isStagingEnvironment } from "./utils";

/**
 * Client-side application settings.
 */

const prodConfig = {
  firebase: {
    projectId: "stridist-prod",
    appId: "1:168713142478:web:88ff71a0141c0f8e4a909f",
    apiKey: "AIzaSyDOlJBmGdhwMmpVrVwXCfJrXpdnx_KSwvs",
    authDomain: "stridist-prod.firebaseapp.com", // TODO: Replace with "stridist.com"
    databaseURL: "https://stridist-prod.firebaseio.com/",
  },
};

const testConfig = {
  firebase: {
    projectId: "stridist-test",
    appId: "1:722029373286:web:6cce2aa285847271764aa7",
    apiKey: "AIzaSyBnrJxWXC9cOnRFfJdqPqAjHq0HaNJxF5Y",
    authDomain: "stridist-test.firebaseapp.com", // TODO: Replace with "test.stridist.com"
    databaseURL: "https://stridist-test.firebaseio.com/",
  },
};

/*
  Currently, for development configuration, the testConfig is used because of usage of staging backend.

  Use the following configuration for development with local backend:
  firebase: {
    projectId: "stridist-dev",
    appId: "1:956707039105:web:d063b2514b33accef6ea80",
    apiKey: "AIzaSyDpK1sN2jv2nhZNaoWWtiLXEECEyFVT3p4",
    authDomain: "stridist-dev.firebaseapp.com", // TODO: Replace with "dev.stridist.com"
    databaseURL: "https://stridist-dev.firebaseio.com",
    storageBucket: "stridist-dev.appspot.com",
    messagingSenderId: "956707039105",
  },
};
  */
const devConfig = testConfig;

const CAPACITOR_ENV = process.env.REACT_APP_CAPACITOR_ENV;

// Select the config based on the URL domain name.
const config = CAPACITOR_ENV
  ? CAPACITOR_ENV === "prod"
    ? prodConfig
    : CAPACITOR_ENV === "test"
      ? testConfig
      : devConfig
  : isLocalEnvironment()
    ? devConfig
    : isStagingEnvironment()
      ? testConfig
      : prodConfig;

// Initialize Firebase Client SDK
firebase.initializeApp(config.firebase);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default config;
