import { Units, UserRole } from "../../../constants";
import { CurrentUserProvider_user$data } from "../../routes/__generated__/CurrentUserProvider_user.graphql";

export enum UnitType {
  WEIGHT = "WEIGHT",
  LENGTH = "LENGTH",
  PERCENTAGE = "PERCENTAGE",
  STEPS = "STEPS",
}

export enum MeasurementType {
  BODYWEIGHT = "bodyweight",
  BODYFAT = "bodyfat",
  SHOULDER = "shoulder",
  CHEST = "chest",
  UPPER_ARM = "upper_arm",
  WAIST = "waist",
  HIP = "hip",
  THIGH = "thigh",
  CALF = "calf",
}

export enum MeasurementUnits {
  LBS = "lbs",
  KG = "kg",
  IN = "in",
  CM = "cm",
  PERCENTAGE = "PERCENTAGE",
  STEPS = "STEPS",
}

export const measurementTypeArr = [
  MeasurementType.BODYWEIGHT,
  MeasurementType.BODYFAT,
  MeasurementType.SHOULDER,
  MeasurementType.CHEST,
  MeasurementType.UPPER_ARM,
  MeasurementType.WAIST,
  MeasurementType.HIP,
  MeasurementType.THIGH,
  MeasurementType.CALF,
];

export const measurementUnitsArr = [
  MeasurementUnits.LBS,
  MeasurementUnits.KG,
  MeasurementUnits.IN,
  MeasurementUnits.CM,
  MeasurementUnits.PERCENTAGE,
];

export const UnitTypeAssets = {
  [UnitType.LENGTH]: {
    defaultValue: MeasurementUnits.IN,
  },
  [UnitType.WEIGHT]: {
    defaultValue: MeasurementUnits.LBS,
  },
  [UnitType.PERCENTAGE]: {
    defaultValue: MeasurementUnits.PERCENTAGE,
  },
  [UnitType.STEPS]: {
    defaultValue: MeasurementUnits.STEPS,
  },
};

export const measurementTypesAssets = {
  [MeasurementType.BODYWEIGHT]: {
    value: MeasurementType.BODYWEIGHT,
    label: "Bodyweight",
    unitType: UnitType.WEIGHT,
  },
  [MeasurementType.BODYFAT]: {
    value: MeasurementType.BODYFAT,
    label: "Body fat",
    unitType: UnitType.PERCENTAGE,
  },
  [MeasurementType.SHOULDER]: {
    value: MeasurementType.SHOULDER,
    label: "Shoulder",
    unitType: UnitType.LENGTH,
  },
  [MeasurementType.CHEST]: {
    value: MeasurementType.CHEST,
    label: "Chest",
    unitType: UnitType.LENGTH,
  },
  [MeasurementType.UPPER_ARM]: {
    value: MeasurementType.UPPER_ARM,
    label: "Upper Arm",
    unitType: UnitType.LENGTH,
  },
  [MeasurementType.WAIST]: {
    value: MeasurementType.WAIST,
    label: "Waist",
    unitType: UnitType.LENGTH,
  },
  [MeasurementType.HIP]: {
    value: MeasurementType.HIP,
    label: "Hip",
    unitType: UnitType.LENGTH,
  },
  [MeasurementType.THIGH]: {
    value: MeasurementType.THIGH,
    label: "Thigh",
    unitType: UnitType.LENGTH,
  },
  [MeasurementType.CALF]: {
    value: MeasurementType.CALF,
    label: "Calf",
    unitType: UnitType.LENGTH,
  },
};

export const measurementUnitsAssets = {
  [MeasurementUnits.KG]: {
    value: MeasurementUnits.KG,
    label: "kg",
    unitType: UnitType.WEIGHT,
  },
  [MeasurementUnits.LBS]: {
    value: MeasurementUnits.LBS,
    label: "lbs",
    unitType: UnitType.WEIGHT,
  },
  [MeasurementUnits.CM]: {
    value: MeasurementUnits.CM,
    label: "cm",
    unitType: UnitType.LENGTH,
  },
  [MeasurementUnits.IN]: {
    value: MeasurementUnits.IN,
    label: "in",
    unitType: UnitType.LENGTH,
  },
  [MeasurementUnits.PERCENTAGE]: {
    value: MeasurementUnits.PERCENTAGE,
    label: "%",
    unitType: UnitType.PERCENTAGE,
  },
  [MeasurementUnits.STEPS]: {
    value: MeasurementUnits.STEPS,
    label: "steps",
    unitType: UnitType.STEPS,
  },
};

/**
 * TODO_Editor: Unify with the original function.
 * @deprecated This function was copied from ./src/constants.ts since measurement enums were duplicated here.
 * The original function always return a US unit for coaches.
 */
export const getClientUnits = (
  user: Pick<CurrentUserProvider_user$data, "units" | "role">,
): Units => {
  if (!user) {
    return Units.US;
  }

  return user.units as Units;
};

/**
 * TODO_Editor: Unify with the original function.
 * @deprecated This function was copied from ./src/constants.ts since measurement enums were duplicated here.
 * The original function has a different parameter type.
 */
export const getDefaultUnit = (
  type: MeasurementType,
  units: Units = Units.US,
): MeasurementUnits => {
  const unitType = measurementTypesAssets[type]?.unitType;
  switch (unitType) {
    case UnitType.LENGTH:
      return units === Units.US ? MeasurementUnits.IN : MeasurementUnits.CM;
    case UnitType.WEIGHT:
      return units === Units.US ? MeasurementUnits.LBS : MeasurementUnits.KG;
    case UnitType.PERCENTAGE:
      return MeasurementUnits.PERCENTAGE;
    case UnitType.STEPS:
      return MeasurementUnits.STEPS;
    default:
      throw new Error("Unsupported measurement type");
  }
};
