import React from "react";

const Physically = ({ fill, className }) => (
  <svg
    className={className}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.05" cx="40" cy="40" r="40" fill={fill} />
    <g clip-path="url(#clip0_239_5615)">
      <path
        d="M54.5112 21.8178C53.9162 20.6963 52.7386 20.0005 51.4374 20.0005H41.0003C39.0617 20.0005 37.4847 21.5775 37.4847 23.5161V28.2035C37.4847 30.142 39.0617 31.719 41.0003 31.719H44.5159V27.0316H43.344C42.6963 27.0316 42.1721 26.5075 42.1721 25.8598C42.1721 25.212 42.6963 24.6879 43.344 24.6879H45.7658C46.4136 24.6879 46.9377 25.212 46.9377 25.8598V31.719H48.1095C48.7561 31.719 49.2814 32.2443 49.2814 32.8909V42.2094C50.6636 42.5866 52.0225 43.0681 53.3211 43.7174C53.9001 44.0069 54.1347 44.7108 53.8452 45.2898C53.5601 45.8615 52.8599 46.1055 52.2728 45.8139C46.7317 43.0422 40.0345 43.0422 34.4933 45.8139C33.9143 46.1012 33.2093 45.8689 32.9209 45.2898C32.6314 44.7108 32.866 44.0069 33.445 43.7174C35.1994 42.8402 37.0544 42.2397 38.947 41.8591C36.2844 39.3101 32.5858 38.3308 28.927 39.2463C25.2135 40.1744 22.1679 43.1831 21.2879 46.916C20.5383 50.1489 21.2685 53.4619 23.2906 56.007C25.3071 58.5442 28.3949 59.9998 31.6255 59.9998C42.4297 59.9998 51.3599 57.3959 60.3545 52.8439C60.7504 52.6448 60.9999 52.2397 60.9999 51.7968V46.7111C60.9999 38.0161 58.7511 29.2964 54.5112 21.8178V21.8178Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_239_5615">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(21 20)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Physically;
