/**
 * @generated SignedSource<<96cf084a6f4b06614855e93dc87b62ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StackedClientsAvatars_clients$data = ReadonlyArray<{
  readonly displayName: string | null | undefined;
  readonly id: string;
  readonly photoURL: string | null | undefined;
  readonly " $fragmentType": "StackedClientsAvatars_clients";
}>;
export type StackedClientsAvatars_clients$key = ReadonlyArray<{
  readonly " $data"?: StackedClientsAvatars_clients$data;
  readonly " $fragmentSpreads": FragmentRefs<"StackedClientsAvatars_clients">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StackedClientsAvatars_clients",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4bcfe3cb32f599c9a10e3348f52cbf48";

export default node;
