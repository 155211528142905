/**
 * @generated SignedSource<<d64de1195eb25b5d200a44acec1762fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollmentCard_enrollment$data = {
  readonly client: {
    readonly displayName: string | null | undefined;
    readonly email: string | null | undefined;
    readonly id: string;
    readonly photoURL: string | null | undefined;
    readonly username: string;
  };
  readonly completion: {
    readonly unlockedScore: number;
  };
  readonly endDate: string | null | undefined;
  readonly id: string;
  readonly startDate: string | null | undefined;
  readonly viaGroup: boolean;
  readonly " $fragmentType": "EnrollmentCard_enrollment";
};
export type EnrollmentCard_enrollment$key = {
  readonly " $data"?: EnrollmentCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EnrollmentCard_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMM Do"
  },
  {
    "kind": "Literal",
    "name": "utc",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnrollmentCard_enrollment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(format:\"MMM Do\",utc:true)"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": "endDate(format:\"MMM Do\",utc:true)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "period",
          "value": "BEFORE_NOW"
        }
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": "unlockedScore",
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": "completion(period:\"BEFORE_NOW\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viaGroup",
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "45cacdc0691b2cf53a9c3020912cbbe0";

export default node;
