/**
 * @generated SignedSource<<c15d370f71da6b295d85f35339ca595c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsProfile_user$data = {
  readonly birthday: string;
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly gender: string;
  readonly height: string | null | undefined;
  readonly id: string;
  readonly isSample: boolean;
  readonly location: string;
  readonly passwordUpdatedAt: string | null | undefined;
  readonly phone: string | null | undefined;
  readonly phoneCode: string | null | undefined;
  readonly phoneVerified: boolean;
  readonly photoURL: string | null | undefined;
  readonly terraConnected: boolean;
  readonly terraProviders: ReadonlyArray<string>;
  readonly terraSdkOptions: {
    readonly devId: string;
    readonly referenceId: string;
    readonly token: string;
  };
  readonly timeZone: string | null | undefined;
  readonly units: Units;
  readonly weight: string | null | undefined;
  readonly " $fragmentType": "ClientSettingsProfile_user";
};
export type ClientSettingsProfile_user$key = {
  readonly " $data"?: ClientSettingsProfile_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsProfile_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSettingsProfile_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSample",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM DD, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "passwordUpdatedAt",
      "storageKey": "passwordUpdatedAt(format:\"MMM DD, YYYY\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terraConnected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terraProviders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TerraSdkOptions",
      "kind": "LinkedField",
      "name": "terraSdkOptions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "devId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referenceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "token",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6c6a8f05e3899b69977872382791f0c4";

export default node;
