import React from "react";

const Chart = ({ fill, onClick }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M6.14286 8.50195V14.2187"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0318 5.7666V14.2189"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.8572 11.5225V14.2183"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9048 1.66699H6.09526C3.37304 1.66699 1.66669 3.59373 1.66669 6.32129V13.6794C1.66669 16.4069 3.3651 18.3337 6.09526 18.3337H13.9048C16.6349 18.3337 18.3334 16.4069 18.3334 13.6794V6.32129C18.3334 3.59373 16.6349 1.66699 13.9048 1.66699Z"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Chart;
