import React, { useContext, useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";

import { WeekMenu } from "../program-week/WeekMenu";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import {
  CALENDAR_SIDEBAR_WIDTH,
  ProgramCalendarWeeksList,
} from "../program-calendar/ProgramCalendarWeeksList";
import { ProgramWeeksList } from "../program/ProgramWeeksList";

import { useAnalytics } from "../../hooks/useAnalytics";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { CopyCalendarComponentContext } from "../../hooks/useCopyCalendarComponent";
import {
  getComponentDefaultTitle,
  defaultComponentDays,
} from "../../utils/component";
import {
  ProgramStatus,
  ComponentType,
  ComponentRepeat,
  LocalStorageKeys,
} from "../../constants";
import { ReactComponent as AddWeekIcon } from "../../icons/AddCircleOutline.svg";
import {
  useDirtyMutation,
  useInterruptiveMutation,
} from "../dirty-transaction/hooks";

import { ProgramDetailsAddComponentMutation } from "./__generated__/ProgramDetailsAddComponentMutation.graphql";

import { Filters } from "./ProgramDetailsFilters";
import { useNavigate } from "react-router-dom";
import MinimizedDrawerContext, {
  getLocalStorageMinimizedDrawerValue,
} from "../../contexts/MinimizedDrawerContext";
import { useProgramRefetch } from "../../hooks/useProgramRefetch";
import { ProgramSpreadsheet } from "../program-workout-spreadsheet/ProgramSpreadsheet";
import { ProgramDetailsUpdateOrderMutation } from "./__generated__/ProgramDetailsUpdateOrderMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  calendar: {},
  list: {
    maxWidth: 1116,
  },
  addWeekButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: "none",
    fontWeight: 600,
    fontSize: 18,
    margin: theme.spacing(3, 0),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(8.75),
      fontSize: 20,
      "& svg": {
        width: 32,
        height: 32,
      },
    },

    "& svg": {
      width: 24,
      height: 24,
    },

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}0F`,
    },

    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },
}));

const addComponentMutation = graphql`
  mutation ProgramDetailsAddComponentMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        slug
        id
      }
    }
  }
`;

const updateOrderMutation = graphql`
  mutation ProgramDetailsUpdateOrderMutation(
    $input: UpdateWeekComponentsOrderInput!
  ) {
    updateWeekComponentsOrder(input: $input) {
      week {
        positions
      }
    }
  }
`;

interface ProgramDetailsProps {
  programRef: any;
  viewMode: ProgramDetailsViewMode;
  filters: Filters;
  weeksFilter: { start: number; end: number };
  startDate?: string;
  length?: number;
  weeks?: any;
  id?: string;
  slug?: string;
  handleAddWeek?: () => void;
  addWeekInFlight?: boolean;
  handleDeleteWeek?: (event, setMoreMenuEl) => void;
  handleDuplicateWeek?: (event, closeMoreMenu) => void;
  dublicateWeekInFlight?: boolean;
  handleMove?: (event, closeMoreMenu) => void;
  handlePrevClick?: () => void;
  handleNextClick?: () => void;
  loading?: boolean;
}

export type ProgramAddComponentCallback = (
  weekId: string,
  type: ComponentType,
  days?: boolean[],
  positions?: string[],
  content?: string,
  callback?: (e?: any, slug?: string, type?: ComponentType) => void,
) => void;

export function ProgramDetails(props: ProgramDetailsProps) {
  const {
    programRef,
    filters,
    weeksFilter,
    viewMode,
    startDate,
    id,
    weeks: allWeeks,
    slug,
    handleAddWeek,
    addWeekInFlight,
    handleDeleteWeek,
    handleDuplicateWeek,
    dublicateWeekInFlight,
    handleMove,
    handlePrevClick,
    handleNextClick,
    loading,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [moreMenuEl, setMoreMenuEl] = React.useState(null);
  const [weekId, setWeekId] = React.useState(null);
  const s = useStyles();
  const [copiedCalendarComponentId, setCopiedCalendarComponentId] =
    React.useState<string>();

  const programRefetch = useProgramRefetch();

  const hasPrev = weeksFilter.start > 1;
  const hasNext = weeksFilter.end < programRef.length;

  const weeks = allWeeks || [];

  const [addComponent] =
    useInterruptiveMutation<ProgramDetailsAddComponentMutation>(
      addComponentMutation,
    );

  const [updateOrder] =
    useDirtyMutation<ProgramDetailsUpdateOrderMutation>(updateOrderMutation);

  const [trackEvent] = useAnalytics();
  const snackAlert = useSnackAlert();
  const weekIndex = weeks.findIndex((week) => week.id === weekId);
  const weekData = weeks.find((week) => week.id === weekId);

  React.useEffect(() => {
    if (
      copiedCalendarComponentId === undefined &&
      viewMode !== ProgramDetailsViewMode.CALENDAR
    ) {
      setCopiedCalendarComponentId(undefined);
    }
  }, [copiedCalendarComponentId, viewMode]);

  const setErrorMessage = React.useCallback(
    (error: string) => {
      snackAlert({
        severity: "error",
        message: error,
      });
    },
    [snackAlert],
  );

  const openMoreMenu = React.useCallback((event) => {
    setWeekId(event.currentTarget.dataset.id);
    setMoreMenuEl(event.currentTarget);
  }, []);

  const closeMoreMenu = React.useCallback(() => {
    setMoreMenuEl(null);
  }, []);

  const handleAddComponent: ProgramAddComponentCallback = React.useCallback(
    (
      weekId,
      type,
      days = defaultComponentDays,
      positions,
      content,
      callback,
    ) => {
      const input = {
        weekId,
        type,
        title: getComponentDefaultTitle(type),
        status: ProgramStatus.DRAFT,
        days,
        repeat: ComponentRepeat.NONE,
        duration: 1,
        content,
      };

      setWeekId(weekId);

      addComponent({
        variables: { input },

        onCompleted: (upsertComponent, errors) => {
          if (errors && errors.length) {
            setErrorMessage(errors[0].message || "Error occurred.");
          } else {
            const { component } = upsertComponent.upsertComponent;

            trackEvent("Coach - Add Component", {
              type,
            });

            callback && callback(undefined, component.slug, type);

            // performe updateOrder if positions passed
            if (positions) {
              const weekIndex = weeks.findIndex((week) => week.id === weekId);

              const index = positions.indexOf("");
              if (index !== -1) {
                positions[index] = component.id;
              }
              updateOrder({
                variables: {
                  input: {
                    programId: id,
                    week: weekIndex + 1,
                    positions,
                  },
                },
              });
            }

            // refetch program data on component add to update relay store with new ids
            // TODO drop with migration to REST API
            programRefetch();
          }
        },
        onError: () => {
          setErrorMessage("Internal server error.");
        },
      });
    },
    [addComponent, slug, setErrorMessage, trackEvent],
  );

  const canMoveUp = weekIndex > 0;
  const canMoveDown = weekIndex < weeks.length - 1;

  const WeeksList = React.useMemo(() => {
    switch (viewMode) {
      case ProgramDetailsViewMode.LIST:
        return ProgramWeeksList;
      case ProgramDetailsViewMode.CALENDAR:
        return ProgramCalendarWeeksList;
      case ProgramDetailsViewMode.SPREADSHEET:
        return ProgramSpreadsheet;
      default:
        throw new Error(`Not implemented ${viewMode} program view.`);
    }
  }, [viewMode]);

  const { setMinimizedDrawer } = useContext(MinimizedDrawerContext);

  const [isCalendarSidebarOpen, setIsCalendarSidebarOpen] = useState(false);

  useEffect(() => {
    setMinimizedDrawer(
      isCalendarSidebarOpen ? true : getLocalStorageMinimizedDrawerValue(),
    );
  }, [isCalendarSidebarOpen]);

  return (
    <>
      <CopyCalendarComponentContext.Provider
        value={[copiedCalendarComponentId, setCopiedCalendarComponentId]}
      >
        <WeeksList
          filters={filters}
          onOpenMenu={openMoreMenu}
          onAddComponent={handleAddComponent}
          programRef={programRef}
          startDate={startDate}
          weeks={weeks}
          programId={viewMode !== ProgramDetailsViewMode.CALENDAR ? id : null}
          activityPreview={isCalendarSidebarOpen}
          setActivityPreview={setIsCalendarSidebarOpen}
          duplicateWeekInFlight={dublicateWeekInFlight}
          weeksActions={{
            onClickDelete: (e) => handleDeleteWeek(e, setMoreMenuEl),
            onClickMove: (e) => handleMove(e, closeMoreMenu),
            onClickDuplicate: (e) => handleDuplicateWeek(e, closeMoreMenu),
          }}
        />
      </CopyCalendarComponentContext.Provider>

      <Box
        sx={{
          width: isCalendarSidebarOpen
            ? `calc(100% - ${CALENDAR_SIDEBAR_WIDTH}px - ${theme.spacing(2)})`
            : "100%",

          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        {/* TODO move weeks logic to each view or refactor to a sidebar */}
        {!hasNext && (
          <Button
            className={s.addWeekButton}
            fullWidth
            variant="outlined"
            startIcon={<AddWeekIcon />}
            children="Add new week"
            onClick={handleAddWeek}
            disabled={addWeekInFlight}
          />
        )}

        <Box display="flex" justifyContent="center" gap={2} pt={1} pb={2}>
          <Button onClick={handlePrevClick} disabled={loading || !hasPrev}>
            Load previous
          </Button>
          <Button onClick={handleNextClick} disabled={loading || !hasNext}>
            Load next
          </Button>
        </Box>
      </Box>

      {Boolean(moreMenuEl) && (
        <WeekMenu
          id="week-menu"
          weekId={weekId}
          open={true}
          anchorEl={moreMenuEl}
          onClose={closeMoreMenu}
          onClickDelete={(e) => handleDeleteWeek(e, setMoreMenuEl)}
          onClickMove={(e) => handleMove(e, closeMoreMenu)}
          canMoveUp={canMoveUp}
          canMoveDown={canMoveDown}
          onClickDuplicate={(e) => handleDuplicateWeek(e, closeMoreMenu)}
          canDuplicate={
            dublicateWeekInFlight || weekData?.node?.components?.length === 0
          }
          canDelete={weeks.length > 1}
        />
      )}
    </>
  );
}
