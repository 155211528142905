/**
 * @generated SignedSource<<37cfb8ace3c8d1990232f39dbcce36ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramActivityStartRouteQuery$variables = {
  component: string;
  date: string;
};
export type ClientProgramActivityStartRouteQuery$data = {
  readonly activity: {
    readonly " $fragmentSpreads": FragmentRefs<"StartWorkout_activity">;
  } | null | undefined;
};
export type ClientProgramActivityStartRouteQuery = {
  response: ClientProgramActivityStartRouteQuery$data;
  variables: ClientProgramActivityStartRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "component"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v2 = [
  {
    "kind": "Literal",
    "name": "client",
    "value": "$me"
  },
  {
    "kind": "Variable",
    "name": "component",
    "variableName": "component"
  },
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProgramActivityStartRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StartWorkout_activity"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientProgramActivityStartRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "raw",
                "value": true
              }
            ],
            "kind": "ScalarField",
            "name": "date",
            "storageKey": "date(raw:true)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eeaff0325295eb2735be6d344b6e996d",
    "id": null,
    "metadata": {},
    "name": "ClientProgramActivityStartRouteQuery",
    "operationKind": "query",
    "text": "query ClientProgramActivityStartRouteQuery(\n  $date: String!\n  $component: String!\n) {\n  activity(client: \"$me\", date: $date, component: $component) {\n    ...StartWorkout_activity\n    id\n  }\n}\n\nfragment StartWorkout_activity on Activity {\n  id\n  date(raw: true)\n  component {\n    locked\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e38e3e25d0807acea798d48f9a353faf";

export default node;
