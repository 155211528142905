import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Grid, GridSize } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { useQueryParam } from "../../hooks/useQueryParam";
import { EnrollmentStatus } from "../../constants";
import { ClientUpcomingProgramCard } from "../card/ClientUpcomingProgramCard";
import { ClientPastProgramCard } from "../card/ClientPastProgramCard";
import { ClientProgramProgressCard } from "../card/ClientProgramProgressCard";
import { useLocation } from "react-router-dom";
import {
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  CLIENT_PROGRAMS_PAST_ROUTE,
  CLIENT_PROGRAMS_UPCOMING_ROUTE,
} from "../../routes/routes";

import { Programs_enrollments$key } from "./__generated__/Programs_enrollments.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.slg,
  },
  panel: {
    background: "transparent !important",
    boxShadow: "none !important",
    border: "none !important",
  },
  panelSummary: {
    "& .MuiExpansionPanelSummary-content": {
      alignItems: "center",
      padding: theme.spacing(2, 0),
    },
  },
  panelTitle: {
    color: theme.palette.common.black,
    fontSize: 20,
    fontWeight: "bold",
  },
  expand: {
    fontSize: 32,
    color: theme.palette.secondary.main,
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
    "&$open": {
      transform: "rotate(0)",
    },
  },
  open: {},
}));

const enrollmentsFragment = graphql`
  fragment Programs_enrollments on EnrollmentConnection {
    edges {
      node {
        id
        ...ClientProgramProgressCard_enrollment
        ...ClientUpcomingProgramCard_enrollment
        ...ClientPastProgramCard_enrollment
      }
    }
  }
`;

export interface ProgramsProps extends Omit<ContainerProps, "children"> {
  enrollmentsRef: Programs_enrollments$key;
}

const getStatusByLocation = (path: string) => {
  switch (path) {
    case CLIENT_PROGRAMS_ACTIVE_ROUTE:
      return EnrollmentStatus.ACTIVE;
    case CLIENT_PROGRAMS_UPCOMING_ROUTE:
      return EnrollmentStatus.UPCOMING;
    case CLIENT_PROGRAMS_PAST_ROUTE:
      return EnrollmentStatus.PAST;

    default:
      break;
  }
};

export function Programs(props: ProgramsProps) {
  const { className, enrollmentsRef, ...other } = props;
  const s = useStyles();
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const location = useLocation();
  const status = getStatusByLocation(location.pathname);

  const [xs, sm, md, EnrollmentCard]: [
    GridSize,
    GridSize,
    GridSize,
    React.FC<any>,
  ] = React.useMemo(() => {
    switch (status) {
      case EnrollmentStatus.UPCOMING:
        return [12, 6, 4, ClientUpcomingProgramCard];
      case EnrollmentStatus.PAST:
        return [12, 6, 4, ClientPastProgramCard];
      default:
        return [12, 12, 12, ClientProgramProgressCard];
    }
  }, [status]);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {enrollments.edges.map(({ node: enrollment }) => (
          <Grid key={enrollment.id} item xs={xs} sm={sm} md={md}>
            <EnrollmentCard enrollmentRef={enrollment} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
