/**
 * @generated SignedSource<<bde73a6b43a460f25f061652721ce139>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type bodySide = "BACK" | "FRONT" | "SIDE" | "%future added value";
export type ClientProgressPhotosCardDeletePhotoMutation$variables = {
  bodySide: bodySide;
  id: string;
  url: string;
};
export type ClientProgressPhotosCardDeletePhotoMutation$data = {
  readonly deletePhoto: {
    readonly deleted: string;
  } | null | undefined;
};
export type ClientProgressPhotosCardDeletePhotoMutation = {
  response: ClientProgressPhotosCardDeletePhotoMutation$data;
  variables: ClientProgressPhotosCardDeletePhotoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bodySide"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bodySide",
        "variableName": "bodySide"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      }
    ],
    "concreteType": "DeletePhoto",
    "kind": "LinkedField",
    "name": "deletePhoto",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProgressPhotosCardDeletePhotoMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientProgressPhotosCardDeletePhotoMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ac0fcc43c5f191c122ada6bcd5803d33",
    "id": null,
    "metadata": {},
    "name": "ClientProgressPhotosCardDeletePhotoMutation",
    "operationKind": "mutation",
    "text": "mutation ClientProgressPhotosCardDeletePhotoMutation(\n  $id: ID!\n  $url: String!\n  $bodySide: bodySide!\n) {\n  deletePhoto(id: $id, url: $url, bodySide: $bodySide) {\n    deleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c787619e14507e7672819278e985386";

export default node;
