import {
  PlateLeaf,
  RenderAfterEditable,
  createPluginFactory,
  createPlugins,
} from "@udecode/plate-common";
import {
  ELEMENT_PARAGRAPH,
  createParagraphPlugin,
} from "@udecode/plate-paragraph";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  createHeadingPlugin,
} from "@udecode/plate-heading";
import {
  ELEMENT_TODO_LI,
  createListPlugin,
  createTodoListPlugin,
} from "@udecode/plate-list";
import {
  createBoldPlugin,
  createItalicPlugin,
  createUnderlinePlugin,
  createStrikethroughPlugin,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import { createFontSizePlugin } from "@udecode/plate-font";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";
import { createAlignPlugin } from "@udecode/plate-alignment";
import { createIndentPlugin } from "@udecode/plate-indent";
import { createLineHeightPlugin } from "@udecode/plate-line-height";
import { createTabbablePlugin } from "@udecode/plate-tabbable";
import { withProps } from "@udecode/cn";
import { HeadingElement } from "../../../plate-ui/heading-element";
import { ParagraphElement } from "../../../plate-ui/paragraph-element";
import { HighlightLeaf } from "../../../plate-ui/highlight-leaf";
import { MARK_KBD } from "@udecode/plate-kbd";
import { KbdLeaf } from "../../../plate-ui/kbd-leaf";
import { ELEMENT_LINK, createLinkPlugin } from "@udecode/plate-link";
import { LinkFloatingToolbar } from "../../../plate-ui/link-floating-toolbar";
import { LinkElement } from "../../../plate-ui/link-element";
import { createIndentListPlugin } from "@udecode/plate-indent-list";
import {
  createIndentListPluginWithOptions,
  createIndentPluginWithOptions,
} from "./utils";
import { TodoListElement } from "../../../plate-ui/todo-list-element";
import {
  ELEMENT_GIF,
  MessageGifElement,
} from "../../elements/media/MessageGifElement";
import {
  ELEMENT_ATTACHMENT,
  MessageAttachmentElement,
} from "../../elements/media/MessageAttachmentElement";
import { VIDEO, VideoElement } from "../../elements/Video";
import { createVideoPlugin } from "@udecode/plate-media";
import { createAutoReplacerPlugin } from "./withAutoReplacer";

const createMessageGifPlugin = createPluginFactory({
  key: ELEMENT_GIF,
  isElement: true,
  isVoid: true,
});

const createMessageAttachmentPlugin = createPluginFactory({
  key: ELEMENT_ATTACHMENT,
  isElement: true,
  isVoid: true,
});

const createHighlightLeafPlugin = createPluginFactory({
  key: MARK_HIGHLIGHT,
  isLeaf: true,
});

export const editorPlugins = createPlugins(
  [
    createAutoReplacerPlugin(),
    createMessageGifPlugin(),
    createMessageAttachmentPlugin(),
    createHeadingPlugin(),
    createParagraphPlugin(),
    createListPlugin(),
    createBoldPlugin(),
    createItalicPlugin(),
    createTodoListPlugin(),
    createUnderlinePlugin(),
    createStrikethroughPlugin(),
    createVideoPlugin(),
    createFontSizePlugin(),
    createHighlightLeafPlugin(),
    createLinkPlugin({
      renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
    }),
    createIndentPluginWithOptions(),
    createIndentListPluginWithOptions(),
    createAlignPlugin({
      inject: {
        props: {
          validTypes: [ELEMENT_PARAGRAPH],
        },
      },
    }),
    createLineHeightPlugin({
      inject: {
        props: {
          defaultNodeValue: 1.5,
          validNodeValues: [1, 1.2, 1.5, 2, 3],
          validTypes: [ELEMENT_PARAGRAPH],
        },
      },
    }),
    createTabbablePlugin(),
  ],
  {
    components: {
      [ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
      [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
      [ELEMENT_H3]: withProps(HeadingElement, { variant: "h3" }),
      [ELEMENT_H4]: withProps(HeadingElement, { variant: "h4" }),
      [ELEMENT_H5]: withProps(HeadingElement, { variant: "h5" }),
      [ELEMENT_H6]: withProps(HeadingElement, { variant: "h6" }),
      [ELEMENT_LINK]: LinkElement,
      [ELEMENT_TODO_LI]: TodoListElement,
      [ELEMENT_PARAGRAPH]: ParagraphElement,
      [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
      [MARK_HIGHLIGHT]: HighlightLeaf,
      [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
      [MARK_KBD]: KbdLeaf,
      [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
      [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: "sub" }),
      [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: "sup" }),
      [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
      [ELEMENT_GIF]: MessageGifElement,
      [ELEMENT_ATTACHMENT]: MessageAttachmentElement,
      [VIDEO]: VideoElement,
    },
  },
);
