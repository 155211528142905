import clsx from "clsx";
import React from "react";
import {
  Box,
  TextFieldProps,
  Divider,
  Typography,
  Button,
  ButtonProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { BaseDialog, BaseDialogProps } from "../dialog/BaseDialog";
import {
  ClientAddableItem,
  ClientAddableItemProps,
} from "../item/ClientAddableItem";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { SearchField } from "../fields/SearchField";
import { ClientAddableItem_client$data } from "../item/__generated__/ClientAddableItem_client.graphql";

import { SelectAddableClientsDialog_clients$key } from "./__generated__/SelectAddableClientsDialog_clients.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: theme.spacing(65.5),
    borderRadius: theme.spacing(1.5),
  },

  lozenges: {
    padding: theme.spacing(2, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  lozenge: {
    display: "inline-flex",
    height: 34,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    backgroundColor: `${theme.palette.primary.main}99`,
    padding: theme.spacing(0.5, 2),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  remove: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },

  clients: {
    height: 438,
    overflowY: "auto",
  },

  item: {
    padding: theme.spacing(2.5, 0),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },

  inviteDivider: {
    margin: theme.spacing(2, -3),
  },
}));

const clientsFragment = graphql`
  fragment SelectAddableClientsDialog_clients on ClientConnection {
    edges {
      node {
        id
        email
        displayName
        ...ClientAddableItem_client
      }
    }
  }
`;

export interface SelectAddableClientsDialogProps extends BaseDialogProps {
  clients: SelectAddableClientsDialog_clients$key;
  selectedClientEmails?: string[];
  SubmitButtonProps?: ButtonProps;
  onSelectClients?: (clients: ClientAddableItem_client$data[]) => void;
  disabled?: boolean;
}

export function SelectAddableClientsDialog(
  props: SelectAddableClientsDialogProps,
) {
  const {
    className,
    onClose,
    clients: clientsRef,
    SubmitButtonProps,
    onSelectClients,
    disabled,
    selectedClientEmails = [],
    ...other
  } = props;
  const clients = useFragment(clientsFragment, clientsRef);
  const s = useStyles();
  const [filter, setFilter] = React.useState("");
  const [addedClients, setAddedClients] = React.useState<
    ClientAddableItem_client$data[]
  >([]);

  const handleFilterChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [],
  );

  const handleFilterReset = React.useCallback(() => {
    setFilter("");
  }, []);

  const resetState = React.useCallback(() => {
    setFilter("");
    setAddedClients([]);
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const removeClient = React.useCallback(
    (email: string) => {
      const i = addedClients.findIndex(
        (addedClient) => addedClient.email === email,
      );

      if (i !== -1) {
        const updated = [...addedClients];
        updated.splice(i, 1);
        setAddedClients(updated);
      }
    },
    [addedClients],
  );

  const handleToggle: ClientAddableItemProps["onToggle"] = React.useCallback(
    (added, client) => {
      if (added) {
        setAddedClients((v) => [...v, client]);
      } else {
        removeClient(client.email);
      }
    },
    [removeClient],
  );

  const getRemoveHandler = React.useCallback(
    (email: string) => () => removeClient(email),
    [removeClient],
  );

  const filteredClients = React.useMemo(() => {
    if (!clients) {
      return [];
    }

    const edges = filter
      ? clients.edges.filter(({ node: { displayName, email } }) =>
          [displayName, email].some((value) =>
            value.toLowerCase().includes(filter.toLowerCase()),
          ),
        )
      : clients.edges;

    return edges.map(({ node }) => node);
  }, [clients, filter]);

  const handleSubmit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }

      if (onSelectClients) {
        onSelectClients(addedClients);
      }
    },
    [addedClients, onClose, onSelectClients],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <SearchField
        variant="outlined"
        fullWidth
        value={filter}
        onChange={handleFilterChange}
        onReset={handleFilterReset}
        placeholder="Search clients"
        disabled={disabled}
      />
      {Boolean(addedClients.length) && false && (
        <Box className={s.lozenges}>
          {addedClients.map(({ email, displayName }) => (
            <Box key={email} className={s.lozenge}>
              <Typography component="span" children={displayName} />
              <CloseIcon
                className={s.remove}
                onClick={getRemoveHandler(email)}
              />
            </Box>
          ))}
        </Box>
      )}
      <Box className={s.clients}>
        {filteredClients.map((client, i, arr) => {
          const added = Boolean(
            [
              selectedClientEmails,
              addedClients.map((client) => client.email),
            ].some((emails) => emails.find((email) => email === client.email)),
          );

          return (
            <React.Fragment key={client.id}>
              <ClientAddableItem
                className={s.item}
                clientRef={client}
                added={added}
                onToggle={handleToggle}
                disabled={disabled}
              />
              {i < arr.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </Box>
      <Divider />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="Select clients"
        onClick={handleSubmit}
        disabled={disabled || addedClients.length === 0}
        {...SubmitButtonProps}
      />
    </BaseDialog>
  );
}
