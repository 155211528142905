import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { KeepClientsMutation } from "./__generated__/KeepClientsMutation.graphql";

const mutation = graphql`
  mutation KeepClientsMutation($input: KeepClientsInput!) {
    keepClients(input: $input) {
      user {
        ...CoachSettingsPlan_user
        ...CurrentUserProvider_user
      }
    }
  }
`;

export const useKeepClientsMutation = () => {
  return useConfiguredMutation<KeepClientsMutation>(mutation, (config) => ({}));
};
