import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { LikesList_likeable$key } from "./__generated__/LikesList_likeable.graphql";
import { LikeListItem } from "./LikeListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingRight: theme.spacing(1),
  },

  bubble: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    borderRadius: "50%",
    borderWidth: 1,
    borderStyle: "solid",

    marginLeft: 0,
    marginRight: theme.spacing(-1),
  },

  avatar: {
    borderColor: theme.palette.background.paper,
  },

  rest: {
    borderColor: theme.palette.quote,
    color: theme.palette.avatar,
    lineHeight: "30px",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
}));

const fragment = graphql`
  fragment LikesList_likeable on Likeable
  @argumentDefinitions(last: { type: "Int!", defaultValue: 1 }) {
    likes(last: $last) @connection(key: "LikesList_likes") {
      totalCount
      edges {
        node {
          ...LikeListItem_like
          id
        }
      }
    }
  }
`;

export interface LikesListProps extends BoxProps {
  likeableRef: LikesList_likeable$key;
}

export function LikesList(props: LikesListProps) {
  const { className, likeableRef, ...other } = props;
  const s = useStyles();
  const { likes } = useFragment(fragment, likeableRef);
  const restCount = likes.totalCount - likes.edges.length;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {likes.edges.map(({ node }) => (
        <LikeListItem
          key={node.id}
          className={clsx(s.bubble, s.avatar)}
          likeRef={node}
        />
      ))}

      {restCount > 0 && (
        <Typography className={clsx(s.bubble, s.rest)}>+{restCount}</Typography>
      )}
    </Box>
  );
}
