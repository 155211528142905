import clsx from "clsx";
import React, { FormEventHandler } from "react";
import { Box, BoxProps, Typography, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AuthButton } from "./AuthButton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },

  passwordConfirm: {
    marginTop: theme.spacing(2),
  },

  submitButton: {
    marginTop: theme.spacing(3),
  },
}));

type State = {
  password: string;
  passwordConf: string;
};

type PasswordError = {
  password?: string;
  passwordConf?: string;
};

export interface ChangePasswordFormProps {
  className: string;
  state: State;
  errors: PasswordError;
  onChange?: (event: any) => void;
  onSubmit?: FormEventHandler<HTMLDivElement | HTMLFormElement>;
  loading?: boolean;
}

export function ChangePasswordForm(props: ChangePasswordFormProps) {
  const { className, state, errors, onChange, onSubmit, loading, ...other } =
    props;
  const s = useStyles();

  const submitDisabled = Boolean(
    loading ||
      !state.password ||
      !state.passwordConf ||
      state.password !== state.passwordConf ||
      errors.password ||
      errors.passwordConf,
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h1">
        Set your new password
      </Typography>

      <Box component={"form"} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          name="password"
          type="password"
          label="New Password"
          placeholder="* * * * * *"
          value={state.password}
          onChange={onChange}
          disabled={loading}
          error={Boolean(errors.password)}
          helperText={errors.password}
          fullWidth
        />

        <TextField
          className={s.passwordConfirm}
          variant="outlined"
          name="passwordConf"
          type="password"
          label="Confirm Password"
          placeholder="* * * * * *"
          value={state.passwordConf}
          onChange={onChange}
          disabled={loading}
          error={Boolean(errors.passwordConf)}
          helperText={errors.passwordConf}
          fullWidth
        />

        <AuthButton
          className={s.submitButton}
          type="submit"
          children="Change my password"
          disabled={submitDisabled}
        />
      </Box>
    </Box>
  );
}
