import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: "nowrap",

    "&.Mui-disabled, &[disabled]": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },
  },

  small: {
    padding: theme.spacing(0.75, 5.5),
  },

  medium: {
    padding: theme.spacing(0.75, 8.25),
  },

  large: {
    padding: theme.spacing(1.5, 4.25),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.5, 6.25),
    },
  },

  contained: {},

  outlined: {
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
  },

  text: {},
}));

export interface ActionButtonProps extends ButtonProps {}

export function ActionButton(props: ActionButtonProps) {
  const { className, variant = "contained", size = "medium", ...other } = props;
  const s = useStyles();

  return (
    <Button
      className={clsx(s.root, className, s[size], s[variant])}
      variant={variant}
      {...other}
    />
  );
}
