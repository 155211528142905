import clsx from "clsx";
import React from "react";
import {
  Select,
  SelectProps,
  MenuItem,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTimeZones } from "../../hooks/useTimeZones";

import { FieldsGroup } from "./FieldsGroup";

const useStyles = makeStyles((theme) => ({
  root: {},

  select: {
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,
  },

  separator: {
    width: "100%",
  },
}));

export interface TimezoneFieldProps
  extends Omit<SelectProps, "error" | "onChange"> {
  error?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
}

export function TimezoneField(props: TimezoneFieldProps) {
  const {
    className,
    error,
    onChange,
    errorMessage = "Please select a time zone",
    ...other
  } = props;
  const s = useStyles();
  const timeZones = useTimeZones();
  const handleChange = React.useCallback(
    (event: SelectChangeEvent<unknown>) => {
      onChange(event.target.value as unknown as string);
    },
    [onChange],
  );

  return (
    <FieldsGroup
      className={clsx(s.root, className)}
      label="Time Zone"
      error={error && errorMessage}
    >
      <Select
        className={s.select}
        onChange={handleChange}
        variant="outlined"
        displayEmpty
        fullWidth
        {...other}
      >
        <MenuItem disabled>Timezone</MenuItem>
        {timeZones.map(({ value, label, separate }) => [
          <MenuItem value={value} children={label} />,
          separate && (
            <MenuItem disabled>
              <Divider className={s.separator} />
            </MenuItem>
          ),
        ])}
      </Select>
    </FieldsGroup>
  );
}
