import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import { ClientMealLoggingQuestionProps } from "./types";
import { MealLoggingQuestionRatingScale } from "../meal-logging-settings/types";
import ScaleRating from "../fields/ScaleRating";

export interface ClientMealLoggingRatingScaleQuestionProps
  extends BoxProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionRatingScale> {}

export function ClientMealLoggingRatingScaleQuestion(
  props: ClientMealLoggingRatingScaleQuestionProps,
) {
  const { className, option, onUpdate, ...other } = props;
  const [answer, setAnswer] = React.useState<string>(option.answer);

  const handleClick = React.useCallback(
    (value: number) => {
      const newValue = value.toString();
      setAnswer(newValue);

      if (onUpdate) {
        onUpdate({
          ...option,
          answer: newValue,
        });
      }
    },
    [answer, onUpdate, option],
  );

  return (
    <Box className={clsx(className)} {...other}>
      <ScaleRating value={Number(answer ?? 0)} onSelect={handleClick} />
    </Box>
  );
}
