import React, { ReactNode } from "react";
import { Root, Trigger, Portal, Content } from "@radix-ui/react-popover";

type EmojiToolbarDropdownProps = {
  control: ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children: ReactNode;
};

export function EmojiToolbarDropdown({
  control,
  isOpen,
  setIsOpen,
  children,
}: EmojiToolbarDropdownProps) {
  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      <Trigger asChild>{control}</Trigger>

      <Portal>
        <Content className="z-[1300]">{children}</Content>
      </Portal>
    </Root>
  );
}
