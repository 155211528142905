import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { RenderElementProps } from "slate-react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& td": {
      borderTopWidth: 0,
      borderTopStyle: "solid",
      borderTopColor: "transparent",

      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.border.tertiary,
    },
  },
}));

export interface TableRowElementProps extends RenderElementProps {}

export const ELEMENT_TABLE_ROW = "table_row";

export function TableRowElement(props: any) {
  const s = useStyles();
  const { element, children, attributes } = props;

  return (
    <tr
      className={clsx(s.root)}
      data-table-row-index={element.rowIndex}
      {...attributes}
    >
      {children}
    </tr>
  );
}
