"use client";

import React from "react";
import {
  Root as RadixRoot,
  Trigger as RadixTrigger,
  Anchor as RadixAnchor,
  Portal as RadixPortal,
  Content as RadixContent,
} from "@radix-ui/react-popover";
import { cn, withRef } from "@udecode/cn";
import { cva } from "class-variance-authority";
import { useTheme } from "@mui/material";

export const Popover = RadixRoot;
export const PopoverTrigger = RadixTrigger;
export const PopoverAnchor = RadixAnchor;

export const popoverVariants = cva(
  "w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 print:hidden",
);

export const PopoverContent = withRef<typeof RadixContent>(
  ({ className, style, align = "center", sideOffset = 4, ...props }, ref) => {
    const theme = useTheme();
    return (
      <RadixPortal>
        <RadixContent
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={cn(popoverVariants(), className)}
          style={{
            zIndex: theme.zIndex.mobileStepper,
            ...style,
          }}
          {...props}
        />
      </RadixPortal>
    );
  },
);
