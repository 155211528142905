import clsx from "clsx";
import React from "react";
import {
  ListItem,
  ListItemProps,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { formatTime } from "../../utils/date";
import { ClientMealLoggingStarRating } from "../client-meal-logging/ClientMealLoggingStarRating";
import { ReactComponent as EmptyPhoto } from "../../icons/EmptyPhoto.svg";
import { ZoomableImage } from "../image/ZoomableImage";

import { MealLoggingQuestionType } from "../meal-logging-settings/types";
import { parseAnswer } from "../meal-logging-settings/utils";
import { MealActivityContent } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: "column",
    marginTop: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  image: {
    width: 224,
    height: 140,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    flexShrink: 0,
    objectFit: "cover",
  },

  info: {
    width: "100%",
  },

  time: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },

  description: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  question: {
    marginTop: theme.spacing(3),
  },

  questionText: {
    fontSize: 14,
    fontWeight: 600,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },

  answerText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },

  divider: {
    marginTop: theme.spacing(3),
  },
}));

export interface ActivityResponseMealsListItemProps extends ListItemProps {
  button?: false;
  meal: MealActivityContent;
  hasPhotos?: boolean;
}

export function ActivityResponseMealsListItem(
  props: ActivityResponseMealsListItemProps,
) {
  const s = useStyles();
  const { className, meal, divider, hasPhotos, ...other } = props;
  const { description, createdAt } = meal;

  return (
    <ListItem button={false} className={clsx(s.root, className)} {...other}>
      {hasPhotos ? (
        meal.photoURL ? (
          <ZoomableImage
            className={s.image}
            src={meal.photoURL}
            alt={description}
          />
        ) : (
          <EmptyPhoto className={s.image} />
        )
      ) : null}

      <Box className={s.info}>
        <Typography className={s.time} component="div">
          {formatTime(createdAt)}
        </Typography>
        <Typography className={s.description} component="div">
          {description}
        </Typography>

        {meal.answers.map((item, index) => {
          const answer = parseAnswer(item);
          return (
            <Box key={index} className={s.question}>
              <Typography className={s.questionText}>{item.text}</Typography>

              {item.type === MealLoggingQuestionType.STAR_RATING ? (
                <ClientMealLoggingStarRating rating={parseInt(answer)} />
              ) : (
                <Typography className={s.answerText}>{answer}</Typography>
              )}
            </Box>
          );
        })}

        {divider && <Divider className={s.divider} />}
      </Box>
    </ListItem>
  );
}
