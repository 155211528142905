/**
 * @generated SignedSource<<1213105dd4e5285187bcf3555e57e708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupAddableItem_group$data = {
  readonly id: string;
  readonly name: string;
  readonly size: number;
  readonly " $fragmentType": "GroupAddableItem_group";
};
export type GroupAddableItem_group$key = {
  readonly " $data"?: GroupAddableItem_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupAddableItem_group">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupAddableItem_group",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "3a651b7a14041b3d0bd4de2dd2842bf4";

export default node;
