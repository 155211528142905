/**
 * @generated SignedSource<<1c1cbdea3973e86d8f8ecca0c0a96122>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertActivityInput = {
  clientMutationId?: string | null | undefined;
  completed?: boolean | null | undefined;
  content?: string | null | undefined;
  edited?: boolean | null | undefined;
  endWorkout?: string | null | undefined;
  id: string;
  startWorkout?: string | null | undefined;
  submitted?: boolean | null | undefined;
};
export type ActivityCardCompleteActivityMutation$variables = {
  input: UpsertActivityInput;
};
export type ActivityCardCompleteActivityMutation$data = {
  readonly upsertActivity: {
    readonly activity: {
      readonly " $fragmentSpreads": FragmentRefs<"ActivityCard_activity">;
    } | null | undefined;
  } | null | undefined;
};
export type ActivityCardCompleteActivityMutation = {
  response: ActivityCardCompleteActivityMutation$data;
  variables: ActivityCardCompleteActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityCardCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityCard_activity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityCardCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "raw",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(raw:true)"
              },
              {
                "alias": "formattedDate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMM DD, YYYY"
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(format:\"MMM DD, YYYY\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submitted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "component",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "days",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "teaser",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "program",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iconName",
                        "storageKey": null
                      }
                    ],
                    "type": "ComponentIcon",
                    "abstractKey": "__isComponentIcon"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8135aceff50eea66552ed7e6386404fd",
    "id": null,
    "metadata": {},
    "name": "ActivityCardCompleteActivityMutation",
    "operationKind": "mutation",
    "text": "mutation ActivityCardCompleteActivityMutation(\n  $input: UpsertActivityInput!\n) {\n  upsertActivity(input: $input) {\n    activity {\n      ...ActivityCard_activity\n      id\n    }\n  }\n}\n\nfragment ActivityCard_activity on Activity {\n  id\n  date(raw: true)\n  formattedDate: date(format: \"MMM DD, YYYY\")\n  completed\n  submitted\n  component {\n    ...ComponentIcon_component\n    type\n    title\n    slug\n    days\n    locked\n    image\n    teaser\n    program {\n      slug\n      id\n    }\n    id\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n"
  }
};
})();

(node as any).hash = "dc8db4e727a04b57ccf40317abffb23b";

export default node;
