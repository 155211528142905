import makeStyles from "@mui/styles/makeStyles";

export const useWorkoutTableStyles = makeStyles((theme) => {
  const rowWithExtraColumn = {
    "& $reps": {
      width: "22%",
      paddingLeft: theme.spacing(2),
    },
    "& $weight": {
      paddingRight: 0,
    },
  };

  return {
    cell: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      "& input": {
        textAlign: "center",
      },
      width: "100%",
      "& .MuiTextField-root": {
        width: "100%",
      },
      maxWidth: "160px",
      "& > *:first-of-type": {
        maxWidth: "100px",
      },
      lineHeight: 1.1,
    },
    set: {
      width: 65,
      [theme.breakpoints.down("md")]: {
        width: 45,
      },
      flexShrink: 0,
    },
    extraColumn: {
      width: "20%",
    },
    reps: {
      width: "40%",
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
      },
    },
    weight: {
      width: "45%",
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingRight: theme.spacing(1),
      },
    },
    result: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      minWidth: 65,
      flexShrink: 0,
      maxWidth: "none",
      textAlign: "right",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down("sm")]: {
        minWidth: 45,
        padding: theme.spacing(0, 1.5),
      },
    },
    resultHistory: {
      [theme.breakpoints.down("sm")]: {
        minWidth: 50,
      },
    },
    header: {
      display: "flex",
      alignItems: "flex-end",

      "& $resultHistory": {
        padding: 0,
      },
    },
    headerWithExtraColumn: rowWithExtraColumn,
    rowWithExtraColumn: rowWithExtraColumn,
  };
});
