import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { StackedClientsAvatars_clients$key } from "./__generated__/StackedClientsAvatars_clients.graphql";

const OVERLAP_SPACING = 2;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row-reverse",
    marginRight: theme.spacing(OVERLAP_SPACING),
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(-OVERLAP_SPACING),
  },
}));

export type StackedClientsAvatarsClassKey = "root" | "avatar";

const clientsFragment = graphql`
  fragment StackedClientsAvatars_clients on User @relay(plural: true) {
    id
    displayName
    photoURL
  }
`;

export interface StackedClientsAvatarsProps extends BoxProps {
  clients: StackedClientsAvatars_clients$key;
  limit?: number;
  classes?: Partial<ClassNameMap<StackedClientsAvatarsClassKey>>;
}

export function StackedClientsAvatars(props: StackedClientsAvatarsProps) {
  const {
    className,
    clients: clientsRef,
    limit = 4,
    classes = {},
    ...other
  } = props;
  const clients = useFragment(clientsFragment, clientsRef);
  const s = useStyles();

  return (
    <Box className={clsx(s.root, classes.root, className)} {...other}>
      {clients.length > limit && (
        <Avatar
          className={clsx(s.avatar, classes.avatar)}
          variant={"square"}
          children={clients.length - limit}
        />
      )}
      {clients.slice(0, limit).map(({ id, displayName, photoURL }) => (
        <Avatar
          className={clsx(s.avatar, classes.avatar)}
          key={id}
          src={photoURL}
          alt={displayName}
          variant="square"
        />
      ))}
    </Box>
  );
}
