import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as EmptyIcon } from "../../icons/EmptyClients.svg";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: theme.spacing(2),
  },

  header: {
    fontSize: 24,
    fontWeight: 600,
    margin: theme.spacing(2, 0, 1),
  },

  subHeader: {
    fontSize: 16,
    fontWeight: 500,
  },

  button: {
    marginTop: theme.spacing(5),
    borderRadius: 4,
    padding: theme.spacing(1.5, 8),
    fontSize: 16,
    fontWeight: 700,
  },
}));

export interface EnrollDialogEmptyProps extends BoxProps {
  header: React.ReactNode;
  filter?: string;
}

export function EnrollDialogEmpty(props: EnrollDialogEmptyProps) {
  const { className, header, filter, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();

  const handleClick = React.useCallback(() => {
    window.localStorage.setItem("enrollDialogEmail", filter);
    navigate("/coach/clients?invite=true");
  }, [filter]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <EmptyIcon />
      <Typography variant="h5" className={s.header}>
        {header}
      </Typography>
      <Typography variant="h6" className={s.subHeader}>
        To start coaching new clients, you’ll need to invite them first.
      </Typography>

      <Button className={s.button} variant="contained" onClick={handleClick}>
        Invite Clients
      </Button>
    </Box>
  );
}
