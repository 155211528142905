import React from "react";

import {
  MealLoggingQuestionOptionsListItem,
  MealLoggingQuestionOptionsListItemProps,
} from "./MealLoggingQuestionOptionsListItem";

export interface MealLoggingQuestionCheckboxesListItemProps
  extends Omit<MealLoggingQuestionOptionsListItemProps, "type"> {}

export function MealLoggingQuestionCheckboxesListItem(
  props: MealLoggingQuestionCheckboxesListItemProps,
) {
  return <MealLoggingQuestionOptionsListItem type="checkbox" {...props} />;
}
