/**
 * @generated SignedSource<<4500b5dbb075c48f754a5af35e6d2f4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DragCellRenderer_component$data = {
  readonly days: ReadonlyArray<boolean>;
  readonly draftExists: boolean;
  readonly id: string;
  readonly locked: boolean;
  readonly slug: string;
  readonly status: ComponentStatus;
  readonly type: ComponentType;
  readonly " $fragmentSpreads": FragmentRefs<"WeekComponentMenu_component">;
  readonly " $fragmentType": "DragCellRenderer_component";
};
export type DragCellRenderer_component$key = {
  readonly " $data"?: DragCellRenderer_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"DragCellRenderer_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DragCellRenderer_component",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WeekComponentMenu_component"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "days",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftExists",
      "storageKey": null
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "9f4e7a564897a60d7e63fab9b2a3c7f5";

export default node;
