import React, { FC } from "react";
import { useTheme } from "@mui/material";
import { zip } from "lodash";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  DoughnutController,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";
import { useMediaMobile } from "../../hooks/useMediaMobile";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

export interface DistributionChartProps {
  title?: string;
  values: {
    value: number;
    label: string;
    color: string;
  }[];
  children?: any;
}

const DistributionChart: FC<DistributionChartProps> = (props) => {
  const { title, values, children, ...restProps } = props;

  const theme = useTheme();
  const isMobile = useMediaMobile();

  const sum = values.reduce((a, b) => a + b.value, 0);
  const isEmpty = sum === 0;

  const colors = values.map(({ color }) => color);
  const labels = values.map(({ label }) => label);
  const dataValues = values.map(({ value }) => value);

  return (
    <>
      <Doughnut
        redraw
        width={"100%"}
        height={"280px"}
        data={{
          datasets: [
            {
              data: !isEmpty ? dataValues : [100],
              backgroundColor: !isEmpty ? colors : ["#E8EBED"],
            },
          ],
          labels: !isEmpty ? labels : ["No data"],
        }}
        options={{
          rotation: Math.PI,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              position: "top",
              text: title,
              font: {
                size: theme.typography.fontSize * 1.5,
                family: theme.typography.fontFamily.slice(0, -1),
              },
              color: theme.palette.text.primary,
            },
            legend: {
              position: isMobile ? "bottom" : "right",
              labels: {
                textAlign: "left",
                usePointStyle: true,
                boxWidth: 40,
                boxHeight: 10,
                boxPadding: 100,
                padding: Number(theme.spacing(2).replace("px", "")),
                pointStyleWidth: 15,
                color: theme.palette.text.primary,
                font: {
                  size: theme.typography.fontSize,
                  family: theme.typography.fontFamily.slice(0, -1),
                  weight: 500,
                },
                generateLabels: (chart) => {
                  const legendColors = !isEmpty
                    ? (chart.config.data.datasets[0]
                        .backgroundColor as string[])
                    : colors;
                  const legentLabels = !isEmpty
                    ? (chart.config.data.labels as string[])
                    : labels;
                  const legendValues = !isEmpty
                    ? (chart.config.data.datasets[0].data as number[])
                    : dataValues;

                  return zip(legentLabels, legendColors, legendValues).map(
                    ([label, color, value]) => {
                      let percentageValue = Math.floor((100 * value) / sum);
                      percentageValue = isNaN(percentageValue)
                        ? 0
                        : percentageValue;
                      return {
                        text: `${label} (${percentageValue}% / ${value}g)`,
                        fillStyle: color,
                        lineWidth: 0,
                        pointStyle: "circle",
                      };
                    },
                  );
                },
              },
            },
          },
        }}
        {...restProps}
      />
      {children}
    </>
  );
};

export default DistributionChart;
