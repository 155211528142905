/**
 * @generated SignedSource<<7601676273d28a8286271df77b027d7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientLinkInviteScreen_inviteCode$data = {
  readonly coach: {
    readonly clientsCountNoSample: number;
    readonly displayName: string | null | undefined;
    readonly plan: Plan | null | undefined;
  };
  readonly code: string;
  readonly group: {
    readonly id: string;
  } | null | undefined;
  readonly program: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ClientDetailedLinkInviteScreen_inviteCode">;
  readonly " $fragmentType": "ClientLinkInviteScreen_inviteCode";
};
export type ClientLinkInviteScreen_inviteCode$key = {
  readonly " $data"?: ClientLinkInviteScreen_inviteCode$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientLinkInviteScreen_inviteCode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientLinkInviteScreen_inviteCode",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientDetailedLinkInviteScreen_inviteCode"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": "clientsCountNoSample",
          "args": [
            {
              "kind": "Literal",
              "name": "noSample",
              "value": true
            }
          ],
          "kind": "ScalarField",
          "name": "clientsCount",
          "storageKey": "clientsCount(noSample:true)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plan",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "InviteCode",
  "abstractKey": null
};
})();

(node as any).hash = "815167171c69aaf8ef5dd572fb0da359";

export default node;
