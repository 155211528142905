import { Button } from "@mui/material";
import React from "react";

function FileUpload({ handleFileChange, accept, buttonTitle, disabled }: any) {
  return (
    <Button variant="contained" component="label" disabled={disabled} fullWidth>
      {buttonTitle ? buttonTitle : "Upload File"}
      <input type="file" hidden onChange={handleFileChange} accept={accept} />
    </Button>
  );
}

export default FileUpload;
