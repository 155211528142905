import clsx from "clsx";
import React from "react";
import { TextField, OutlinedTextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AccessTime as TimeIcon } from "@mui/icons-material";

import { useTimeInputSupported } from "../../hooks/useTimeInputSupported";

import { TimePicker } from "./TimePicker";

const useStyles = makeStyles((theme) => ({
  root: {},

  icon: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.5),
  },
}));

export interface TimeInputProps
  extends Omit<OutlinedTextFieldProps, "variant"> {}

export function TimeInput(props: TimeInputProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLInputElement>();
  const native = useTimeInputSupported();

  const handleIconClick = React.useCallback(() => {
    const el = ref.current;
    const inputEl = el && el.querySelector("input");

    if (inputEl) {
      inputEl.click();
    }
  }, []);

  return native ? (
    <TextField
      variant="outlined"
      type="time"
      className={clsx(s.root, className)}
      {...other}
    />
  ) : (
    <TextField
      variant="outlined"
      ref={ref}
      className={clsx(s.root, className)}
      InputProps={{
        inputComponent: TimePicker as any,
        endAdornment: <TimeIcon className={s.icon} onClick={handleIconClick} />,
      }}
      {...other}
    />
  );
}
