import clsx from "clsx";
import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { graphql, useFragment } from "react-relay";

import { CardAvatar, CardAvatarProps } from "../card/CardAvatar";
import { maybePluralize } from "../../utils/text";

import { ThreadHeader_thread$key } from "./__generated__/ThreadHeader_thread.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
  },

  avatar: {
    width: 38,
    height: 38,
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 0,
    backgroundColor: theme.palette.avatar,

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
    },
  },

  userInfo: {
    margin: theme.spacing(0, 2),

    fontSize: 16,
    fontWeight: 700,
    marginLeft: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
  },

  name: {
    fontWeight: 600,
    fontSize: 14,

    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      fontWeight: 600,
    },
  },

  subheader: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 16,
  },
}));

export type ThreadHeaderClassKey =
  | "root"
  | "avatar"
  | "userInfo"
  | "name"
  | "subheader";

const threadFragment = graphql`
  fragment ThreadHeader_thread on Thread {
    lastMessage {
      createdAt(format: "fromNow")
    }

    author {
      displayName
      photoURL
    }

    group {
      name
      membersCount
    }
  }
`;

export interface ThreadHeaderProps extends Omit<CardAvatarProps, "classes"> {
  threadRef: ThreadHeader_thread$key;
  subheader?: ReactNode;
  classes?: Partial<ClassNameMap<ThreadHeaderClassKey>>;
}

export function ThreadHeader(props: ThreadHeaderProps) {
  const s = useStyles(props);
  const { className, subheader, classes = {}, threadRef, ...others } = props;
  const thread = useFragment(threadFragment, threadRef);
  const { group, author, lastMessage } = thread;
  const threadName = author?.displayName || group?.name || "";
  const initials = threadName.substring(0, 1);
  const photoURL = author?.photoURL;
  const recentActivityAt = lastMessage?.createdAt;

  return (
    <Box className={clsx(s.root, className, classes.root)}>
      <CardAvatar
        className={clsx(s.avatar, classes.avatar)}
        src={photoURL}
        {...others}
      >
        {initials}
      </CardAvatar>
      <Box className={clsx(s.userInfo, classes.userInfo)}>
        <Typography className={clsx(s.name, classes.name)}>
          {threadName}
        </Typography>

        <Typography
          className={clsx(s.subheader, classes.subheader)}
          variant="body2"
        >
          {group
            ? maybePluralize(group.membersCount, "member")
            : recentActivityAt
              ? `Active ${recentActivityAt}`
              : "New message"}
        </Typography>
      </Box>
    </Box>
  );
}
