/**
 * @generated SignedSource<<e30254baeb26f419306d83afca796089>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectionPagination_pageCursors$data = {
  readonly around: ReadonlyArray<{
    readonly current: boolean;
    readonly cursor: string;
    readonly page: number;
  }>;
  readonly next: {
    readonly current: boolean;
    readonly cursor: string;
  } | null | undefined;
  readonly previous: {
    readonly current: boolean;
    readonly cursor: string;
  } | null | undefined;
  readonly " $fragmentType": "ConnectionPagination_pageCursors";
};
export type ConnectionPagination_pageCursors$key = {
  readonly " $data"?: ConnectionPagination_pageCursors$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectionPagination_pageCursors">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectionPagination_pageCursors",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageCursor",
      "kind": "LinkedField",
      "name": "previous",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageCursor",
      "kind": "LinkedField",
      "name": "around",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "page",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageCursor",
      "kind": "LinkedField",
      "name": "next",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "PageCursors",
  "abstractKey": null
};
})();

(node as any).hash = "99abc559c82ab336d06f042a380141a2";

export default node;
