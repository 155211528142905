import React from "react";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import { useEditorReadOnly } from "@udecode/plate-common";

import { Icons, iconVariants } from "./Icons/icons";

import { MarkToolbarButton } from "./mark-toolbar-button";
import { MoreDropdownMenu } from "./more-dropdown-menu";
import { SelectionTurnIntoDropdownMenu } from "./turn-into/selection-turn-into-dropdown-menu";
import { LinkToolbarButton } from "./link-toolbar-button";
import { MARK_COLOR, MARK_BG_COLOR } from "@udecode/plate-font";
import { ColorDropdownMenu } from "./color-dropdown-menu";
import { ToolbarGroup } from "./toolbar";
import { Divider } from "@mui/material";
import {
  ITurnIntoMenuItemGroup,
  TURN_INTO_TEXT_MENU_ITEM_DEFAULT,
  TURN_INTO_TEXT_MENU_ITEM_GROUPS,
} from "../new-editor/utils/menuItemTurnIntoGroups";
import { ToolbarOptions } from "../new-editor/editor-configuration/schemas/Schema";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";

interface FloatingToolbarButtonsProps {
  turnIntoMenuItemGroups?: ITurnIntoMenuItemGroup[];
  toolbarOptions?: ToolbarOptions;
}

export function FloatingToolbarButtons({
  turnIntoMenuItemGroups,
  toolbarOptions,
}: FloatingToolbarButtonsProps) {
  const readOnly = useEditorReadOnly();
  const divider = <Divider orientation="vertical" sx={{ height: "24px" }} />;
  return (
    <>
      {!readOnly && (
        <>
          {!turnIntoMenuItemGroups ||
            (turnIntoMenuItemGroups.length > 0 && (
              <>
                <SelectionTurnIntoDropdownMenu
                  menuItemGroups={
                    turnIntoMenuItemGroups ?? TURN_INTO_TEXT_MENU_ITEM_GROUPS
                  }
                  defaultMenuItem={TURN_INTO_TEXT_MENU_ITEM_DEFAULT}
                />
                {divider}
              </>
            ))}

          {(!toolbarOptions || toolbarOptions.showTextFormattingOptions) && (
            <>
              <MarkToolbarButton nodeType={MARK_BOLD} tooltip="Bold (⌘+B)">
                <Icons.bold />
              </MarkToolbarButton>
              <MarkToolbarButton nodeType={MARK_ITALIC} tooltip="Italic (⌘+I)">
                <Icons.italic />
              </MarkToolbarButton>
              <MarkToolbarButton
                nodeType={MARK_UNDERLINE}
                tooltip="Underline (⌘+U)"
              >
                <Icons.underline />
              </MarkToolbarButton>
              <MarkToolbarButton
                nodeType={MARK_STRIKETHROUGH}
                tooltip="Strikethrough (⌘+⇧+M)"
              >
                <Icons.strikethrough />
              </MarkToolbarButton>
            </>
          )}

          {(!toolbarOptions || toolbarOptions.showLinkOption) && (
            <>
              {divider}
              <LinkToolbarButton />
            </>
          )}

          {(!toolbarOptions || toolbarOptions.showTextColorOptions) && (
            <>
              {divider}
              <ToolbarGroup>
                <ColorDropdownMenu nodeType={MARK_COLOR} tooltip="Text Color">
                  <Icons.color
                    className={iconVariants({ variant: "toolbar" })}
                  />
                </ColorDropdownMenu>

                <ColorDropdownMenu
                  nodeType={MARK_HIGHLIGHT}
                  tooltip="Highlight Color"
                >
                  <Icons.bg className={iconVariants({ variant: "toolbar" })} />
                </ColorDropdownMenu>
              </ToolbarGroup>
            </>
          )}
        </>
      )}
    </>
  );
}
