import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import mime from "mime";

import { ElementType } from "../editor/types/elements";
import { ReactComponent as PdfIcon } from "../../icons/PdfFile.svg";
import { ReactComponent as FileIcon } from "../../icons/paper-clip.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Close.svg";
import { colorSystem } from "../../theme";
import { giphyUrlById } from "../../utils/giphy";
import { isImageFile } from "../../utils/file";

type AttachmentWithId = {
  id: string | number;
};

export type AttachmentFile = {
  type: ElementType.ATTACHMENT;
  name: string;
  url: string;
  size: number;
  mimeType: string;
};

export type AttachmentGif = {
  type: ElementType.GIF;
  giphyId: string;
};

export type AttachmentElement = (AttachmentFile | AttachmentGif) &
  AttachmentWithId;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  image: {
    flex: `0 0 ${theme.spacing(14)}`,
    height: theme.spacing(9),

    "& $cover": {
      borderRadius: theme.spacing(0.3),
    },
  },

  file: {
    flex: `0 0 ${theme.spacing(29)}`,
    height: theme.spacing(9),
    borderRadius: theme.spacing(0.5),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    padding: theme.spacing(2, 4, 2, 2),
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },

  fileIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1.75),
    flex: `0 0 ${theme.spacing(5)}`,
  },

  fileHeader: {
    overflow: "hidden",
  },

  fileName: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  fileType: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.quote,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginTop: -2,
  },

  gif: {
    width: "100%",
    height: theme.spacing(20),

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(35),
    },

    "& $cover": {
      borderRadius: theme.spacing(1),
    },
  },

  cover: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  remove: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: theme.spacing(0.3),
    padding: theme.spacing(0.25),
    color: theme.palette.common.white,
    backgroundColor: colorSystem.grayAlpha55,

    "&:hover": {
      backgroundColor: colorSystem.gray,
    },

    "& svg": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },

    "$gif &": {
      padding: theme.spacing(0.5),

      "& svg": {
        width: theme.spacing(3.25),
        height: theme.spacing(3.25),
      },
    },
  },
}));

export interface MessageAttachmentsPreviewListItemProps extends BoxProps {
  attachment: AttachmentElement;
  onRemove?: (attachment: AttachmentElement) => void;
}

export function MessageAttachmentsPreviewListItem(
  props: MessageAttachmentsPreviewListItemProps,
) {
  const { className, attachment, onRemove, ...other } = props;
  const s = useStyles();

  const handleRemoveClick = React.useCallback(() => {
    if (onRemove) {
      onRemove(attachment);
    }
  }, [attachment, onRemove]);

  const isGif = attachment.type === ElementType.GIF;
  const isImage =
    attachment.type === ElementType.ATTACHMENT &&
    isImageFile(attachment.mimeType);
  const isFile = attachment.type === ElementType.ATTACHMENT && !isImage;

  return (
    <Box
      className={clsx(s.root, className, {
        [s.image]: isImage,
        [s.file]: isFile,
        [s.gif]: isGif,
      })}
      {...other}
    >
      <IconButton className={s.remove} onClick={handleRemoveClick} size="large">
        <RemoveIcon />
      </IconButton>

      {attachment.type === ElementType.GIF && (
        <Box
          className={s.cover}
          style={{
            backgroundImage: `url("${giphyUrlById(attachment.giphyId)}")`,
          }}
        />
      )}

      {attachment.type === ElementType.ATTACHMENT && isImage && (
        <Box
          className={s.cover}
          style={{ backgroundImage: `url("${attachment.url}")` }}
        />
      )}

      {attachment.type === ElementType.ATTACHMENT && isFile && (
        <>
          {attachment.mimeType.includes("pdf") ? (
            <PdfIcon className={s.fileIcon} />
          ) : (
            <FileIcon className={s.fileIcon} />
          )}
          <Box className={s.fileHeader}>
            <Typography className={s.fileName}>{attachment.name}</Typography>
            {attachment.mimeType && (
              <Typography className={s.fileType}>
                {mime.getExtension(attachment.mimeType)}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
