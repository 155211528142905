/**
 * @generated SignedSource<<bcd5450d4fb9367e163be4d582202e2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClientAutoNutritionDrawerRefetchQuery$variables = Record<PropertyKey, never>;
export type ClientAutoNutritionDrawerRefetchQuery$data = {
  readonly me: {
    readonly autoNutritionHistory: ReadonlyArray<{
      readonly alcohol: number | null | undefined;
      readonly calories: number | null | undefined;
      readonly carbs: number | null | undefined;
      readonly cholesterol: number | null | undefined;
      readonly date: string | null | undefined;
      readonly fat: number | null | undefined;
      readonly fiber: number | null | undefined;
      readonly id: string;
      readonly protein: number | null | undefined;
      readonly sodium: number | null | undefined;
      readonly sugar: number | null | undefined;
      readonly transFat: number | null | undefined;
    }>;
  } | null | undefined;
};
export type ClientAutoNutritionDrawerRefetchQuery = {
  response: ClientAutoNutritionDrawerRefetchQuery$data;
  variables: ClientAutoNutritionDrawerRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AutoNutrition",
  "kind": "LinkedField",
  "name": "autoNutritionHistory",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carbs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protein",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cholesterol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fiber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sugar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sodium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transFat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alcohol",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAutoNutritionDrawerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientAutoNutritionDrawerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7031c0afec329186e3b13901a6a068d4",
    "id": null,
    "metadata": {},
    "name": "ClientAutoNutritionDrawerRefetchQuery",
    "operationKind": "query",
    "text": "query ClientAutoNutritionDrawerRefetchQuery {\n  me {\n    autoNutritionHistory {\n      id\n      date(raw: true)\n      calories\n      carbs\n      fat\n      protein\n      cholesterol\n      fiber\n      sugar\n      sodium\n      transFat\n      alcohol\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ca724dfeacf12f0915b68c4bda4bdde";

export default node;
