import clsx from "clsx";
import React, { useContext } from "react";
import { Container, ContainerProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { CompactDateDialog } from "../dialog/CompactDateDialog";

import { ClientMealLoggingScreen_root$key } from "./__generated__/ClientMealLoggingScreen_root.graphql";
import { ClientMealLoggingEntriesList } from "./ClientMealLoggingEntriesList";
import { useNavigate } from "react-router-dom";
import { CLIENT_MEAL_LOGGING_ROUTE } from "../../routes/routes";
import CompactDateDialogContext from "../../contexts/CompactDateDialogContext";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
  },

  date: {
    cursor: "pointer",
  },
}));

const rootFragment = graphql`
  fragment ClientMealLoggingScreen_root on Root
  @argumentDefinitions(date: { type: "String!" }) {
    me {
      ...ClientMealLoggingEntriesList_user @arguments(date: $date)
    }

    workspace {
      ...ClientMealLoggingEntriesList_workspace
    }
  }
`;

export interface ClientMealLoggingScreenProps
  extends Omit<ContainerProps, "children"> {
  root: ClientMealLoggingScreen_root$key;
  date: string;
}

export function ClientMealLoggingScreen(props: ClientMealLoggingScreenProps) {
  const s = useStyles();
  const navigate = useNavigate();
  const { className, root: rootRef, date, ...other } = props;
  const root = useFragment(rootFragment, rootRef);
  const { me, workspace } = root;

  const context = useContext(CompactDateDialogContext);

  const handleDateDialogClose = React.useCallback(() => {
    context.setDateDialogOpen(false);
  }, []);

  const handleDateChange = React.useCallback((date: string) => {
    context.setDateDialogOpen(false);
    navigate(CLIENT_MEAL_LOGGING_ROUTE.replace(":date", date));
  }, []);

  return (
    <>
      <Container className={clsx(s.root, className)} maxWidth="xs" {...other}>
        <ClientMealLoggingEntriesList
          userRef={me}
          workspaceRef={workspace}
          date={date}
        />
      </Container>
      {context.dateDialogOpen && (
        <CompactDateDialog
          open
          value={date}
          onClose={handleDateDialogClose}
          onDateChange={handleDateChange}
        />
      )}
    </>
  );
}
