/**
 * @generated SignedSource<<a354f15b453365a052aefb3b95185b0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertMealLoggingEntryInput = {
  answers: string;
  clientMutationId?: string | null | undefined;
  date: string;
  description: string;
  id?: string | null | undefined;
  photoURL?: string | null | undefined;
  time: string;
};
export type UpsertMealLoggingEntryMutation$variables = {
  input: UpsertMealLoggingEntryInput;
};
export type UpsertMealLoggingEntryMutation$data = {
  readonly upsertMealLoggingEntry: {
    readonly mealLoggingEntryEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntriesListItem_mealLoggingEntry" | "ClientMealLoggingEntryDialog_mealLoggingEntry">;
      } | null | undefined;
    };
  } | null | undefined;
};
export type UpsertMealLoggingEntryMutation = {
  response: UpsertMealLoggingEntryMutation$data;
  variables: UpsertMealLoggingEntryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertMealLoggingEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertMealLoggingEntryPayload",
        "kind": "LinkedField",
        "name": "upsertMealLoggingEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MealLoggingEntriesEdge",
            "kind": "LinkedField",
            "name": "mealLoggingEntryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MealLoggingEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClientMealLoggingEntryDialog_mealLoggingEntry"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClientMealLoggingEntriesListItem_mealLoggingEntry"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertMealLoggingEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertMealLoggingEntryPayload",
        "kind": "LinkedField",
        "name": "upsertMealLoggingEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MealLoggingEntriesEdge",
            "kind": "LinkedField",
            "name": "mealLoggingEntryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MealLoggingEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "raw",
                        "value": true
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": "date(raw:true)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answers",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8683aeef5eeb6c03338e8bb500e4a262",
    "id": null,
    "metadata": {},
    "name": "UpsertMealLoggingEntryMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertMealLoggingEntryMutation(\n  $input: UpsertMealLoggingEntryInput!\n) {\n  upsertMealLoggingEntry(input: $input) {\n    mealLoggingEntryEdge {\n      node {\n        ...ClientMealLoggingEntryDialog_mealLoggingEntry\n        ...ClientMealLoggingEntriesListItem_mealLoggingEntry\n        id\n      }\n    }\n  }\n}\n\nfragment ClientMealLoggingEntriesListItem_mealLoggingEntry on MealLoggingEntry {\n  id\n  photoURL\n  description\n  date(raw: true)\n  time\n}\n\nfragment ClientMealLoggingEntryDialog_mealLoggingEntry on MealLoggingEntry {\n  id\n  date(raw: true)\n  time\n  description\n  photoURL\n  answers\n}\n"
  }
};
})();

(node as any).hash = "cc9686d6a75c4f1a619cc0d80ac5c3ba";

export default node;
