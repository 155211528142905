import clsx from "clsx";
import React, { MouseEvent } from "react";
import {
  CardActionArea,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CheckboxField } from "../../components/checkbox/CheckboxField";

import { ProgressDialog_enrollments$data } from "./__generated__/ProgressDialog_enrollments.graphql";
import type { SelectedPhoto } from "./ProgressDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  title: {
    fontSize: 18,
    whiteSpace: "nowrap",
  },
  checkbox: {
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(-1),
  },
  cards: {
    padding: theme.spacing(1.5),
    display: "flex",
    maxWidth: 300,
    overflowX: "scroll",
    [theme.breakpoints.up("md")]: {
      maxWidth: 690,
    },
  },
  cardAction: {
    border: `2px solid transparent`,
    borderRadius: theme.shape.borderRadius,
    padding: 2,
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    "&$selected": {
      borderColor: theme.palette.primary.main,
    },
  },
  cardMedia: {
    width: 117,
    height: 112,
    [theme.breakpoints.up("md")]: {
      width: 98,
      height: 94,
    },
  },
  button: {
    whiteSpace: "nowrap",
    // Component not needed for MVP
    display: "none",
    [theme.breakpoints.up("md")]: {
      // Kept in DOM, but hidden, for layout purposes
      display: "initial",
      visibility: "hidden",
    },
  },
  wrapper: {
    width: 250,
  },
  selected: {},
}));

export interface ProgressDialogActionsProps {
  clientName: string;
  photos: ProgressDialog_enrollments$data[0]["progressPhotos"];
  selected?: SelectedPhoto;
  onSelect: (event: MouseEvent<HTMLElement>, url: string) => void;
  onSwitchMode: any;
  comparisonMode: boolean;
}

export function ProgressDialogActions(props: ProgressDialogActionsProps) {
  const {
    clientName,
    photos,
    selected,
    onSelect,
    comparisonMode,
    onSwitchMode,
  } = props;
  const s = useStyles();

  const handleClick = React.useCallback(
    (event) => {
      onSelect(event, event.currentTarget.dataset.url);
    },
    [onSelect],
  );

  return (
    <Box className={clsx(s.root)}>
      <div className={s.wrapper}>
        <Typography className={s.title} variant="h6">
          {clientName}’s progress
        </Typography>
        {photos.length > 1 && (
          <CheckboxField
            className={s.checkbox}
            name="comparisonMode"
            label="Comparison mode"
            checked={comparisonMode}
            onChange={onSwitchMode}
          />
        )}
      </div>

      <div className={s.cards}>
        {photos.map((photo) =>
          photo.urls.filter(Boolean).map((url) => (
            <CardActionArea
              key={url}
              className={clsx(s.cardAction, {
                [s.selected]: url === selected?.url,
              })}
              onClick={handleClick}
              data-url={url}
            >
              <CardMedia className={s.cardMedia} image={url} />
            </CardActionArea>
          )),
        )}
      </div>

      <div className={s.wrapper}>
        <Button
          className={s.button}
          variant="contained"
          size="large"
          component="a"
          disabled={!selected}
          href={selected?.url}
          target="_blank"
        >
          Save Image
        </Button>
      </div>
    </Box>
  );
}
