import React from "react";

export const useEffectLater: typeof React.useEffect = (callback, deps) => {
  const skip = React.useRef(true);

  return React.useLayoutEffect(
    (...args) => {
      const timer = setTimeout(() => {
        if (skip.current) {
          skip.current = false;
        } else {
          callback(...args);
        }
      }, 1);

      return () => clearTimeout(timer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
};
