import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: "relative",
  },
  track: {
    background: palette.divider,
    borderRadius: 4,
    height: 2,
  },
  indicator: {
    position: "absolute",
    borderRadius: 4,
    height: 4,
    top: -1,
    left: 0,
  },
  yellow: {
    background: palette.progress.yellow,
  },
  green: {
    background: palette.progress.green,
  },
  red: {
    background: palette.progress.red,
  },
  grey: {
    background: palette.text.secondary,
  },
}));

export interface ProgressLineProps {
  value: number;
  className?: string;
  classNameTrack?: string;
  disabled?: boolean;
}

export function ProgressLine(props: ProgressLineProps) {
  const { className, classNameTrack, value, disabled = false } = props;
  const s = useStyles();

  return (
    <div className={clsx(s.root, className)}>
      <div className={clsx(s.track, classNameTrack)} />
      <div
        className={clsx(
          s.indicator,
          value < 26 && s.red,
          value > 25 && value < 76 && s.yellow,
          value > 75 && s.green,
          disabled && s.grey,
        )}
        style={{
          width: `${value}%`,
        }}
      />
    </div>
  );
}
