import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as Plus } from "../../icons/plus.svg";
import { ReactComponent as Check } from "../../icons/check.svg";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    opacity: ({ disabled }: SelectableItemProps) => (disabled ? 0.5 : 1),
  },

  left: {
    display: "flex",
    alignItems: "center",
    maxWidth: 420,
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",

    "& > p": {
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },

  header: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
  },

  subheader: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  action: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: "50%",
    backgroundColor: colorSystem.gray7,
    cursor: ({ disabled }: SelectableItemProps) =>
      disabled ? "default" : "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&$added": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  added: {},
}));

export interface SelectableItemProps extends BoxProps {
  avatarSrc?: string;
  header: string;
  subheader?: string;
  added?: boolean;
  disabled?: boolean;
  disabledText?: string;
  onToggle?: (added: boolean) => void;
  action?: React.ReactNode;
}

export function SelectableItem(props: SelectableItemProps) {
  const {
    className,
    avatarSrc,
    header,
    subheader,
    added,
    disabled = false,
    disabledText,
    onToggle,
    onClick,
    action: _action,
    ...other
  } = props;
  const s = useStyles(props);

  const isAdd = [added, onToggle].every((it) => it !== undefined);

  const handleToggle = React.useCallback(() => {
    if (isAdd) {
      onToggle(!added);
    }
  }, [added, isAdd, onToggle]);

  const content = React.useMemo(
    () => (
      <Box className={s.left}>
        <Avatar
          className={s.avatar}
          variant="square"
          src={avatarSrc}
          alt={header}
        />
        <Box className={s.text}>
          <Typography className={s.header} children={header} />
          {subheader && (
            <Typography className={s.subheader} children={subheader} />
          )}
        </Box>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [avatarSrc, header, subheader],
  );

  const action = React.useMemo(
    () =>
      _action ? (
        _action
      ) : isAdd ? (
        <Box
          className={clsx(s.action, added && s.added)}
          onClick={disabled ? undefined : handleToggle}
          children={added ? <Check /> : <Plus />}
        />
      ) : null,
    [_action, added, disabled, handleToggle, isAdd, s.action, s.added],
  );

  return (
    <Box
      className={clsx(s.root, className)}
      onClick={disabled || !onClick ? undefined : onClick}
      {...other}
    >
      {disabled ? (
        disabledText ? (
          <Tooltip
            arrow
            placement="right"
            title={disabledText}
            children={content}
          />
        ) : (
          content
        )
      ) : (
        <>
          {content}
          {action}
        </>
      )}
    </Box>
  );
}
