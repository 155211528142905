import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { SortableList, SortableListProps } from "../sortable-list/SortableList";

import {
  ClientGoalsList_goals$data,
  ClientGoalsList_goals$key,
} from "./__generated__/ClientGoalsList_goals.graphql";
import {
  ClientGoalListItem,
  ClientGoalListItemProps,
} from "./ClientGoalListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const goalsFragment = graphql`
  fragment ClientGoalsList_goals on ClientGoalConnection {
    edges {
      node {
        id
        name
        targetValue
        currentValue
        date(raw: true)
        formattedDate: date(format: "MMM D, YYYY")
      }
    }
  }
`;

export interface ClientGoalsListProps<
  T = ClientGoalsList_goals$data["edges"][0]["node"],
> extends Omit<SortableListProps<T>, "options" | "ListItem" | "itemType"> {
  goals: ClientGoalsList_goals$key;
  onItemActionClick: ClientGoalListItemProps["onAction"];
  onUpdate: SortableListProps<T>["onUpdate"];
  disabled?: boolean;
}

export function ClientGoalsList(props: ClientGoalsListProps) {
  const {
    className,
    goals: goalsRef,
    onItemActionClick,
    disabled = false,
    ...other
  } = props;
  const goals = useFragment(goalsFragment, goalsRef);
  const s = useStyles();

  return (
    <SortableList
      className={clsx(s.root, className)}
      itemType="client_goal"
      ListItem={ClientGoalListItem as any}
      ListItemProps={{ onAction: onItemActionClick, disabled: disabled }}
      options={goals.edges.map(({ node }) => node) as any}
      {...other}
    ></SortableList>
  );
}
