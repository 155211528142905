import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { RemoveGroupPostMutation } from "./__generated__/RemoveGroupPostMutation.graphql";

const mutation = graphql`
  mutation RemoveGroupPostMutation($input: RemoveGroupPostInput!) {
    removeGroupPost(input: $input) {
      clientMutationId
      removedGroupPostId
    }
  }
`;

export const useRemoveGroupPostMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<RemoveGroupPostMutation>(mutation, (config) => ({
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: config.variables.input.groupId,
        connectionKeys: [
          {
            key: "GroupPostsList_posts",
          },
        ],
        pathToConnection: [config.variables.input.groupId, "posts"],
        deletedIDFieldName: "removedGroupPostId",
      },
    ],

    onSuccess: () =>
      snackAlert({
        severity: "success",
        message: "Group post deleted.",
      }),
  }));
};
