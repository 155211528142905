import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { TimeLockIcon } from "../icons/TimeLockIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  lockIconDisk: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: 200,
    height: 200,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    marginBottom: theme.spacing(4),
  },
  lockIconBig: {
    width: 110,
    height: 110,
    marginLeft: 22,
    marginTop: 22,
  },
  lockedTitle: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },
  lockedSubtitle: {
    fontSize: 18,
    color: theme.palette.text.secondary,
  },
}));

export interface ActivityLockedProps extends BoxProps {
  daysDiff: number;
}

export function ActivityLocked(props: ActivityLockedProps) {
  const { className, daysDiff, ...other } = props;
  const s = useStyles();
  const { palette } = useTheme();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <div className={s.lockIconDisk}>
        <TimeLockIcon
          fill={palette.common.white}
          backgroundFill={palette.primary.main}
          className={s.lockIconBig}
        />
      </div>
      <Typography
        className={s.lockedTitle}
        children={`Unlocks in ${daysDiff} day${daysDiff > 1 ? "s" : ""}.`}
      />
      <Typography
        className={s.lockedSubtitle}
        children="Come back later to get started."
      />
    </Box>
  );
}
