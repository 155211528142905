import React from "react";

import { useNetworkStatus } from "../../hooks/useNetworkStatus";

import { OfflinePage } from "./OfflinePage";

export interface NetworkBoundaryProps {
  children: React.ReactNode;
}

export function NetworkBoundary(props: NetworkBoundaryProps): any {
  const { children } = props;
  const [connected] = useNetworkStatus();

  return connected ? children : <OfflinePage />;
}
