/**
 * @generated SignedSource<<bfd896acaae7acf856ad38dae22e3a0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HabitCheckInProgressTable_activities$data = ReadonlyArray<{
  readonly activeStreak: number | null | undefined;
  readonly completion: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly component: {
    readonly id: string;
    readonly slug: string;
    readonly title: string;
    readonly type: ComponentType;
  } | null | undefined;
  readonly date: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "HabitCheckInProgressTable_activities";
}>;
export type HabitCheckInProgressTable_activities$key = ReadonlyArray<{
  readonly " $data"?: HabitCheckInProgressTable_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"HabitCheckInProgressTable_activities">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "period"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "HabitCheckInProgressTable_activities",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeStreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "period",
          "variableName": "period"
        }
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};
})();

(node as any).hash = "e7a947002095e916abd379aca307c536";

export default node;
