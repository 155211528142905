import clsx from "clsx";
import React from "react";
import { Card, CardProps, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ClientFormsCard_client$key } from "./__generated__/ClientFormsCard_client.graphql";
import { ClientFormCardItem } from "./ClientFormCardItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
    marginBottom: theme.spacing(4),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4),
    textAlign: "center",
  },
}));

const clientFragment = graphql`
  fragment ClientFormsCard_client on User
  @argumentDefinitions(first: { type: "Int", defaultValue: 999 }) {
    clientForms(first: $first) {
      edges {
        node {
          ...ClientFormCardItem_clientForm
          id
        }
      }
    }
  }
`;

export interface ClientFormsCardProps extends CardProps {
  client: ClientFormsCard_client$key;
}

export function ClientFormsCard(props: ClientFormsCardProps) {
  const { className, client: clientRef, ...other } = props;
  const client = useFragment(clientFragment, clientRef);
  const s = useStyles();
  const clientForms = client.clientForms.edges.map(({ node }) => node);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Box>
          <Typography variant="h6" className={s.title} children="Forms" />
        </Box>
      </Box>

      {clientForms.length ? (
        clientForms.map((clientForm) => (
          <ClientFormCardItem key={clientForm.id} clientForm={clientForm} />
        ))
      ) : (
        <Typography className={s.noText}>No forms to show.</Typography>
      )}
    </Card>
  );
}
