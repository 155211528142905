import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MultipleChoiceLinearProgress } from "./MultipleChoiceLinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },

  option: {
    display: "flex",
    alignItems: "center",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },

  label: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.primary.main,
    position: "absolute",
  },

  progress: {
    margin: theme.spacing(0, 5, 0, 6.5),
  },
}));

export interface MultipleChoiceResponsesSummaryProps extends BoxProps {
  counts: readonly number[];
  rates: readonly number[];
  options: readonly string[];
}

export function MultipleChoiceResponsesSummary(
  props: MultipleChoiceResponsesSummaryProps,
) {
  const { className, counts, rates, options, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {options.map((label, index) => (
        <Box key={index} className={s.option}>
          <Typography className={s.label}>{rates[index]}%</Typography>
          <MultipleChoiceLinearProgress
            className={s.progress}
            variant="determinate"
            value={rates[index]}
            count={counts[index]}
            label={label}
          />
        </Box>
      ))}
    </Box>
  );
}
