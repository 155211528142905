import clsx from "clsx";
import React, { useMemo } from "react";
import { Box, BoxProps, Button, Typography, CardMedia } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { isEmptyContent } from "../editor/utils/common";

import { GroupDescription_group$key } from "./__generated__/GroupDescription_group.graphql";
import { GroupDescriptionEditor } from "./GroupDescriptionEditor";
import { GroupDescriptionDialog } from "./GroupDescriptionDialog";
import useDependantKey from "../../hooks/useDependantKey";

const useStyles = makeStyles((theme) => ({
  root: {},

  label: {
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  button: {
    float: "right",
    marginTop: theme.spacing(-1.5),
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
  },

  cover: {
    width: "100%",
    height: theme.spacing(20),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(3),
  },
}));

const fragment = graphql`
  fragment GroupDescription_group on Group {
    ...GroupDescriptionDialog_group
    description
    coachId
    cover: imageURL
  }
`;

export interface GroupDescriptionProps extends BoxProps {
  groupRef: GroupDescription_group$key;
}

export function GroupDescription(props: GroupDescriptionProps) {
  const { className, groupRef, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const group = useFragment(fragment, groupRef);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const content = React.useMemo(
    () => JSON.parse(group.description),
    [group.description],
  );
  const canEdit = user.id === group.coachId;

  const key = useDependantKey(content);

  const handleEditDialogOpen = React.useCallback(() => {
    setOpenEditDialog(true);
  }, []);

  const handleEditDialogClose = React.useCallback(() => {
    setOpenEditDialog(false);
  }, []);

  if (!canEdit && isEmptyContent(content)) {
    return null;
  }

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {group.cover && <CardMedia className={s.cover} image={group.cover} />}
      {canEdit && (
        <Button
          className={s.button}
          variant="text"
          onClick={handleEditDialogOpen}
        >
          Edit
        </Button>
      )}
      <Typography variant="h4" className={s.label}>
        About
      </Typography>

      <Typography component="div" key={key}>
        <GroupDescriptionEditor value={content} readOnly />
      </Typography>

      <GroupDescriptionDialog
        open={openEditDialog}
        groupRef={group}
        onClose={handleEditDialogClose}
      />
    </Box>
  );
}
