import React from "react";
import { ExerciseSetNumberType } from "../../../constants";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  mark: { minWidth: "17px", textAlign: "center", fontWeight: 700 },
  regular: {
    color: theme.palette.primary.main,
  },
  warmUp: {
    color: theme.palette.warning.main,
  },
  dropSet: {
    color: theme.palette.info.main,
  },
  failure: {
    color: theme.palette.error.main,
  },
}));

type SetNumberMarkingProps = {
  setNumber: string | number;
  type: ExerciseSetNumberType;
};

const SetNumberTypeMarking = ({ setNumber, type }: SetNumberMarkingProps) => {
  const s = useStyles();

  switch (type) {
    case ExerciseSetNumberType.WARM_UP:
      return <Typography className={clsx(s.mark, s.warmUp)}>W</Typography>;
    case ExerciseSetNumberType.DROP_SET:
      return <Typography className={clsx(s.mark, s.dropSet)}>D</Typography>;
    case ExerciseSetNumberType.FAILURE:
      return <Typography className={clsx(s.mark, s.failure)}>F</Typography>;
    case ExerciseSetNumberType.REGULAR_SET:
    default:
      return (
        <Typography className={clsx(s.mark, s.regular)}>{setNumber}</Typography>
      );
  }
};

export default SetNumberTypeMarking;
