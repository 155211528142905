/**
 * @generated SignedSource<<44c81628f4921bc7868bcc16c498ef8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertActivityInput = {
  clientMutationId?: string | null | undefined;
  completed?: boolean | null | undefined;
  content?: string | null | undefined;
  edited?: boolean | null | undefined;
  endWorkout?: string | null | undefined;
  id: string;
  startWorkout?: string | null | undefined;
  submitted?: boolean | null | undefined;
};
export type EndWorkoutActivityCompleteActivityMutation$variables = {
  input: UpsertActivityInput;
};
export type EndWorkoutActivityCompleteActivityMutation$data = {
  readonly upsertActivity: {
    readonly activity: {
      readonly " $fragmentSpreads": FragmentRefs<"EndWorkout_activity">;
    } | null | undefined;
  } | null | undefined;
};
export type EndWorkoutActivityCompleteActivityMutation = {
  response: EndWorkoutActivityCompleteActivityMutation$data;
  variables: EndWorkoutActivityCompleteActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EndWorkoutActivityCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EndWorkout_activity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EndWorkoutActivityCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "raw",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(raw:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "component",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locked",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f1e93aa3a57b00a1724023351c833e12",
    "id": null,
    "metadata": {},
    "name": "EndWorkoutActivityCompleteActivityMutation",
    "operationKind": "mutation",
    "text": "mutation EndWorkoutActivityCompleteActivityMutation(\n  $input: UpsertActivityInput!\n) {\n  upsertActivity(input: $input) {\n    activity {\n      ...EndWorkout_activity\n      id\n    }\n  }\n}\n\nfragment EndWorkout_activity on Activity {\n  id\n  date(raw: true)\n  component {\n    locked\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "657e1c8bde65cc3774aca072f14ead55";

export default node;
