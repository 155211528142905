import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  TextField,
  Button,
  Typography,
  Box,
  Popover,
  ClickAwayListener,
  TextFieldProps,
  ButtonProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { isValidUrl, formatVideoUrl } from "../../utils/component";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
  },

  paper: {
    minWidth: 480,
    padding: theme.spacing(3, 1.5, 4),
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down("md")]: {
      minWidth: 360,
    },
  },

  header: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(2.5),
  },

  button: {
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1.25),
    borderRadius: 4,
    marginTop: theme.spacing(3),
  },
}));

export interface EmbedUrlDialogProps {
  TextFieldProps?: TextFieldProps;
  ButtonProps?: ButtonProps;
  onUpdate?: (url: string) => void;
  header?: React.ReactNode;
  actionLabel?: React.ReactNode;
  variant?: "dialog" | "popover";
  open: boolean;
  onClose: (e: any, v: string) => void;
  className?: string;
  contentEditable?: boolean;
}

export function EmbedUrlDialog(props: EmbedUrlDialogProps) {
  const {
    className,
    onUpdate,
    actionLabel = "Embed link",
    header = "Embed",
    variant = "dialog",
    TextFieldProps,
    ButtonProps,
    ...other
  } = props;
  const s = useStyles();
  const [url, setUrl] = React.useState("");

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [],
  );

  const handleUpdate = React.useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (onUpdate) {
        onUpdate(formatVideoUrl(url));
      }

      if (props.onClose) {
        props.onClose(event, "escapeKeyDown");
      }
    },
    [onUpdate, props, url],
  );

  const handleTextClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleClickAway = React.useCallback(
    (event) => {
      if (props.onClose) {
        props.onClose(event, "escapeKeyDown");
      }
    },
    [props],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        handleUpdate(event);
      }
    },
    [handleUpdate],
  );

  const children = (
    <React.Fragment>
      {header && (
        <Typography className={s.header} variant="h6">
          {header}
        </Typography>
      )}
      <TextField
        variant="outlined"
        label="URL"
        value={url}
        onChange={handleChange}
        onClick={handleTextClick}
        autoFocus
        fullWidth
        onKeyDown={handleKeyDown}
        {...TextFieldProps}
      />
      <Button
        className={s.button}
        variant="contained"
        disabled={!isValidUrl(url)}
        onClick={handleUpdate}
        fullWidth
        children={actionLabel}
        {...ButtonProps}
      />
    </React.Fragment>
  );

  return variant === "dialog" ? (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      {...other}
    >
      {children}
    </Dialog>
  ) : (
    other.open && (
      <Popover
        className={clsx(s.root, className)}
        classes={{
          paper: s.paper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...other}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>{children}</Box>
        </ClickAwayListener>
      </Popover>
    )
  );
}
