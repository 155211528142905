import { graphql } from "react-relay/lib/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { DuplicateProgramMutation } from "./__generated__/DuplicateProgramMutation.graphql";

const mutation = graphql`
  mutation DuplicateProgramMutation($input: DuplicateProgramInput!) {
    duplicateProgram(input: $input) {
      programEdge {
        node {
          ...CoachProgramCard_program
        }
      }
    }
  }
`;

export const useDuplicateProgramMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<DuplicateProgramMutation>(
    mutation,
    (config) => ({
      configs: [
        {
          type: "RANGE_ADD",
          parentID: "client:root",
          connectionInfo: [
            {
              key: "ProgramsList_programs",
              rangeBehavior: "prepend",
            },
          ],
          edgeName: "programEdge",
        },
      ],

      onSuccess: () =>
        snackAlert({
          severity: "success",
          message: "Program duplicated.",
        }),
    }),
  );
};
