import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpsertGroupPostMutation } from "./__generated__/UpsertGroupPostMutation.graphql";

const mutation = graphql`
  mutation UpsertGroupPostMutation($input: UpsertGroupPostInput!) {
    upsertGroupPost(input: $input) {
      groupPostEdge {
        node {
          ...GroupPostsEditDialog_post
          ...GroupPostsListItem_post
        }
      }
    }
  }
`;

export const useUpsertGroupPostMutation = (onSuccess?: () => void) => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpsertGroupPostMutation>(mutation, (config) => ({
    onSuccess: () => {
      snackAlert({
        severity: "success",
        message: "Post submitted",
      });

      if (onSuccess) {
        onSuccess();
      }
    },

    configs: !config.variables.input.id && [
      {
        type: "RANGE_ADD",
        parentID: config.variables.input.groupId,
        connectionInfo: [
          {
            key: "GroupPostsList_posts",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "groupPostEdge",
      },
    ],
  }));
};
