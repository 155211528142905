import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useUnsplash } from "../../hooks/useUnsplash";

import { UnsplashImages, UnsplashImagesProps } from "./UnsplashImages";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
  },
}));

export interface UnsplashFormProps extends Omit<BoxProps, "onSelect"> {
  onSelect: UnsplashImagesProps["onSelect"];
}

export function UnsplashForm(props: UnsplashFormProps) {
  const { className, onSelect, ...other } = props;
  const s = useStyles();
  const rootRef = useUnsplash();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <UnsplashImages
        rootRefRef={rootRef}
        onSelect={onSelect}
        defaultQuery="fitness"
      />
    </Box>
  );
}
