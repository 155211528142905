import { ReactEditor } from "slate-react";
import { SchemaElements as NewSchemaElements } from "../../../new-editor/utils/withSchema";
import { ELEMENT_TABLE_ROW } from "./TableRowElement";
import { ELEMENT_TABLE_HEAD_ROW } from "./TableHeadRowElement";
import { ELEMENT_TABLE_HEAD_CELL } from "./TableHeadCellElement";
import { ELEMENT_TABLE_CELL } from "./TableCellElement";

export type TableSelectorOrientation = "vertical" | "horizontal";
export type TableSelectorDirection = "before" | "after";
export type TableSelector = {
  row: number;
  column: number;
  orientation: TableSelectorOrientation;
  direction: TableSelectorDirection;
  highlight?: boolean;
};

const getWrapperEl = (cellEl: HTMLElement) =>
  cellEl.parentElement.parentElement.parentElement.parentElement;

export const createTableRow = (width: number, rowType = ELEMENT_TABLE_ROW) =>
  NewSchemaElements.createElement(
    rowType,
    {},
    [...Array(width)].map(() =>
      NewSchemaElements.createElement(
        rowType === ELEMENT_TABLE_HEAD_ROW
          ? ELEMENT_TABLE_HEAD_CELL
          : ELEMENT_TABLE_CELL,
      ),
    ),
  );

export const createTableRows = (width: number, height: number) => [
  createTableRow(width, ELEMENT_TABLE_HEAD_ROW),
  ...[...Array(height)].map(() => createTableRow(width)),
];

export const setAddButtonPosition = (
  buttonEl: HTMLElement,
  cellEl: HTMLElement,
  { orientation, direction }: TableSelector,
) => {
  const rect = cellEl.getBoundingClientRect();

  Object.assign(
    buttonEl.style,
    orientation === "horizontal"
      ? {
          left: `calc(50% - 12px)`,
          top: `calc(${cellEl.offsetTop}px + ${rect.height}px * ${
            direction === "before" ? 0 : 1
          } - 12px)`,
          marginLeft: "0px",
        }
      : {
          left: `calc(${cellEl.offsetLeft}px - 12px + ${rect.width}px * ${
            direction === "before" ? 0 : 1
          })`,
          top: `calc(${rect.height}px / 2 - 12px)`,
          marginLeft: `-${getWrapperEl(cellEl).scrollLeft}px`,
        },
  );

  const hrEl = buttonEl.querySelector("hr");
  const wrapperEl = getWrapperEl(cellEl);
  const wrapperRect = wrapperEl.getBoundingClientRect();

  if (orientation === "horizontal") {
    Object.assign(hrEl.style, {
      width: `${wrapperRect.width}px`,
      height: "2px",
      top: "11px",
      left: `calc(${wrapperRect.width}px / -2 + 12px)`,
    });
  }

  if (orientation === "vertical") {
    Object.assign(hrEl.style, {
      top: "-16px",
      left: "12px",
      width: "2px",
      height: `${wrapperRect.height}px`,
    });
  }
};

export const setMoreButtonPosition = (
  buttonEl: HTMLElement,
  cellEl: HTMLElement,
  { row, orientation }: TableSelector,
) => {
  if (orientation === "vertical" && row === 0) {
    Object.assign(buttonEl.style, {
      height: "12px",
      top: "-12px",
      width: `${cellEl.offsetWidth}px`,
      left: `${cellEl.offsetLeft}px`,
      right: "unset",
      transform: "none",
      marginLeft: `-${getWrapperEl(cellEl).scrollLeft}px`,
    });
  }

  if (orientation === "horizontal" && row > 0) {
    Object.assign(buttonEl.style, {
      height: `${cellEl.offsetHeight}px`,
      width: "12px",
      top: `${cellEl.offsetTop}px`,
      right: "-12px",
      left: "unset",
      marginLeft: "unset",
    });
  }
};

export const getCellEl = (el: HTMLElement): HTMLElement => {
  let ptr = el;

  do {
    if (ptr.dataset.slateNode === "element") {
      return ptr;
    }

    if (ptr.tagName === "BODY") {
      return null;
    }
    // eslint-disable-next-line no-cond-assign
  } while ((ptr = ptr.parentElement));
};

export const getCellPosition = (table: any, cell: any) => {
  for (let y = 0; y < table.children.length; y++) {
    const row = table.children[y] as any;

    for (let x = 0; x < row.children.length; x++) {
      if (row.children[x].id === cell.id) {
        return {
          row: y,
          column: x,
        };
      }
    }
  }
};

export function updateTable(editor: ReactEditor, table: any) {
  for (let rowIndex = 0; rowIndex < table.children.length; rowIndex++) {
    const row = table.children[rowIndex] as any;
    const isHeader = rowIndex === 0;
    row.type = isHeader ? ELEMENT_TABLE_HEAD_ROW : ELEMENT_TABLE_ROW;
    if (row.rowIndex !== rowIndex) {
      NewSchemaElements.updateElement(editor, row.id, {
        rowIndex,
      });
    }

    for (
      let columnIndex = 0;
      columnIndex < row.children?.length;
      columnIndex++
    ) {
      const cell = row.children[columnIndex] as any;
      cell.type = isHeader ? ELEMENT_TABLE_HEAD_CELL : ELEMENT_TABLE_CELL;
      if (cell.columnIndex !== columnIndex) {
        NewSchemaElements.updateElement(editor, cell.id, {
          columnIndex,
        });
      }
    }
  }
}

export const highlightTableCells = (
  tableEl: HTMLTableElement,
  selector?: TableSelector,
) => {
  tableEl
    .querySelectorAll(".selected")
    .forEach(({ classList }) => classList.remove("selected"));

  if (selector) {
    const { row, column, orientation, highlight } = selector;

    if (highlight) {
      if (orientation === "horizontal") {
        tableEl
          .querySelectorAll(`tr[data-table-row-index="${row}"] > *`)
          .forEach(({ classList }) => classList.add("selected"));
      }

      if (orientation === "vertical") {
        tableEl
          .querySelectorAll(`[data-table-column-index="${column}"]`)
          .forEach(({ classList }) => classList.add("selected"));
      }
    }
  }
};

export const insertTableCells = (
  editor: ReactEditor,
  table: any,
  selector: TableSelector,
) => {
  const { row, column, orientation, direction } = selector;
  const insert =
    direction === "before"
      ? NewSchemaElements.insertElementBefore
      : NewSchemaElements.insertElementAfter;

  for (let y = 0; y < table.children.length; y++) {
    const rowEl = table.children[y] as any;

    if (orientation === "horizontal") {
      if (y === row) {
        insert(editor, rowEl.id, createTableRow(rowEl.children.length));
      }
    } else {
      for (let x = 0; x < rowEl.children.length; x++) {
        const colEl = rowEl.children[x] as any;

        if (x === column) {
          const type = y === 0 ? ELEMENT_TABLE_HEAD_CELL : ELEMENT_TABLE_CELL;

          insert(editor, colEl.id, NewSchemaElements.createElement(type));
        }
      }
    }
  }
};

export const removeTableCells = (
  editor: ReactEditor,
  table: any,
  selector: TableSelector,
) => {
  const { row, column, orientation } = selector;
  const rowsTotal = table.children.length;

  for (let y = 0; y < rowsTotal; y++) {
    const rowEl = table.children[y] as any;

    if (orientation === "horizontal") {
      if (y === row && rowsTotal > 2) {
        NewSchemaElements.removeElement(editor, rowEl.id);
      }
    } else {
      const colsTotal = rowEl.children.length;

      for (let x = 0; x < colsTotal; x++) {
        const colEl = rowEl.children[x] as any;
        if (x === column && colsTotal > 1) {
          NewSchemaElements.removeElement(editor, colEl.id);
        }
      }
    }
  }
};
