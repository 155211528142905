import { useMutation } from "react-relay/hooks";
import { graphql } from "react-relay";
import { RecordSourceSelectorProxy } from "relay-runtime";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { SOMETHING_WENT_WRONG } from "../../../constants";

import { useRemoveCustomAssetTypeMutation as RemoveAssetMutation } from "./__generated__/useRemoveCustomAssetTypeMutation.graphql";

const removeAssetMutation = graphql`
  mutation useRemoveCustomAssetTypeMutation($id: ID!) {
    removeCustomAsset(input: { id: $id }) {
      clientMutationId
    }
  }
`;

export function useRemoveCustomAsset(successMessage = "Asset removed") {
  const [removeAsset] = useMutation<RemoveAssetMutation>(removeAssetMutation);
  const snackAlert = useSnackAlert();

  return (assetId: string) => {
    return removeAsset({
      variables: {
        id: assetId,
      },
      onCompleted: (_, errors) => {
        if (errors && errors.length) {
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "success",
            message: successMessage,
          });
        }
      },
      updater: (store: RecordSourceSelectorProxy) => {
        store.delete(assetId);
      },
    });
  };
}
