import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import AppLayout from "../../../../../components/app/AppLayout";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { ClientProgram } from "../../../../../components/program/ClientProgram";
import { useParams, useSearchParams } from "react-router-dom";
import { CoachProgramPreviewRouteQuery } from "./__generated__/CoachProgramPreviewRouteQuery.graphql";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../../../routes";

export function CoachProgramPreviewRoute() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const week = searchParams.get("week");

  const slug = params.slug;

  const props = useLazyLoadQuery<CoachProgramPreviewRouteQuery>(
    graphql`
      query CoachProgramPreviewRouteQuery($slug: String!, $week: Int!) {
        enrollment(client: "$me", program: $slug) {
          ...ClientProgram_enrollment @arguments(week: $week)
          program {
            ...RefreshSlug
            name
            description
            imageURL
          }
        }
      }
    `,
    {
      slug: slug,
      week: week ? Number(week) : 1,
    },
  );

  const { enrollment } = props;

  return (
    enrollment && (
      <AppLayout
        appDrawer={false}
        title={enrollment?.program?.name || "Program"}
        subtitle={enrollment?.program?.description}
        trackInfo={{
          name: "Coach - Preview Program",
        }}
        breadcrumbs={[
          {
            name: enrollment?.program?.name || "Program",
            link: COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug),
          },
        ]}
        cover={enrollment?.program?.imageURL}
      >
        <RefreshSlug nodesRef={[enrollment?.program]} />
        <ClientProgram enrollmentRef={enrollment} />
      </AppLayout>
    )
  );
}
