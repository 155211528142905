import clsx from "clsx";
import React from "react";
import { Badge, BadgeProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},
  badge: {
    top: theme.spacing(1.75),
    right: theme.spacing(-2),
    fontWeight: 500,
  },
}));

export interface BadgeCounterProps extends BadgeProps {
  counter: number;
}

export function BadgeCounter(props: BadgeCounterProps) {
  const { className, counter, children, ...other } = props;
  const s = useStyles();

  return (
    <Badge
      className={clsx(s.root, className)}
      classes={{ badge: s.badge }}
      showZero={false}
      badgeContent={counter}
      color="primary"
      children={children}
      {...other}
    />
  );
}
