/**
 * @generated SignedSource<<f50be3daae7baffa6004fa3d1587dc7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NutritionTargetScreenMeQuery$variables = Record<PropertyKey, never>;
export type NutritionTargetScreenMeQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"NutritionTargetScreen_client">;
  } | null | undefined;
};
export type NutritionTargetScreenMeQuery = {
  response: NutritionTargetScreenMeQuery$data;
  variables: NutritionTargetScreenMeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calories",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carbsGrams",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proteinGrams",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fatGrams",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(raw:true)"
},
v9 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCalories",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentFat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCarbs",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentProtein",
    "storageKey": null
  },
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientNutritionEntry",
    "kind": "LinkedField",
    "name": "clientNutritionEntry",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NutritionTargetScreenMeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NutritionTargetScreen_client"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NutritionTargetScreenMeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionTarget",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMM DD, YYYY"
                  }
                ],
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionMacroTarget",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionPortionTarget",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3929da4d7a80bdd118e08a302a0de0f1",
    "id": null,
    "metadata": {},
    "name": "NutritionTargetScreenMeQuery",
    "operationKind": "query",
    "text": "query NutritionTargetScreenMeQuery {\n  me {\n    ...NutritionTargetScreen_client\n    id\n  }\n}\n\nfragment ClientNutritionCard_nutritionMacroTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  currentCalories\n  currentFat\n  currentCarbs\n  currentProtein\n  date(raw: true)\n  clientNutritionEntry {\n    id\n    carbsGrams\n    proteinGrams\n    fatGrams\n    calories\n    date\n  }\n}\n\nfragment ClientNutritionCard_nutritionPortionTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  currentCalories\n  currentFat\n  currentCarbs\n  currentProtein\n  date(raw: true)\n  clientNutritionEntry {\n    id\n    carbsGrams\n    proteinGrams\n    fatGrams\n    calories\n    date\n  }\n}\n\nfragment ClientNutritionCard_nutritionTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  date(raw: true)\n  updatedAt(format: \"MMM DD, YYYY\")\n}\n\nfragment NutritionTargetScreen_client on User {\n  nutritionTarget {\n    calories\n    ...ClientNutritionCard_nutritionTarget\n    id\n  }\n  nutritionMacroTarget {\n    ...ClientNutritionCard_nutritionMacroTarget\n    id\n  }\n  nutritionPortionTarget {\n    ...ClientNutritionCard_nutritionPortionTarget\n    id\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "1b6b8843816b7e8f668defb359cb49bb";

export default node;
