import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { RegisterCoachWithFreeLaunchMutation } from "./__generated__/RegisterCoachWithFreeLaunchMutation.graphql";

const mutation = graphql`
  mutation RegisterCoachWithFreeLaunchMutation(
    $input: RegisterCoachWithFreeLaunchInput!
  ) {
    registerCoachWithFreeLaunch(input: $input) {
      idToken
    }
  }
`;

export const useRegisterCoachWithFreeLaunchMutation = () => {
  return useConfiguredMutation<RegisterCoachWithFreeLaunchMutation>(
    mutation,
    () => ({
      updater: (store) => {
        (store as any).invalidateStore();
      },
    }),
  );
};

// mutation registerCoachWithFreeLaunch($input: RegisterCoachWithFreeLaunchInput!) {
//   registerCoachWithFreeLaunch(input: $input) {
//     idToken
//   }
// }
