/**
 * @generated SignedSource<<24eb669f74c441c0ab1bcd8f560f9278>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientCardCompletion_enrollment$data = {
  readonly completion30Days: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly completionByDate: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientLast7DaysCompletion_completionByDate">;
  } | null | undefined>;
  readonly " $fragmentType": "ClientCardCompletion_enrollment";
};
export type ClientCardCompletion_enrollment$key = {
  readonly " $data"?: ClientCardCompletion_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientCardCompletion_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientCardCompletion_enrollment",
  "selections": [
    {
      "alias": "completion30Days",
      "args": [
        {
          "kind": "Literal",
          "name": "period",
          "value": "LAST_30_DAYS"
        }
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": "completion(period:\"LAST_30_DAYS\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "period",
          "value": "LAST_7_DAYS"
        }
      ],
      "concreteType": "CompletionByDate",
      "kind": "LinkedField",
      "name": "completionByDate",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientLast7DaysCompletion_completionByDate"
        }
      ],
      "storageKey": "completionByDate(period:\"LAST_7_DAYS\")"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "56be18c2e26afeaf8ebe099a1be58bf7";

export default node;
