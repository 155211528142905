import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelected } from "slate-react";
import { Element } from "slate";
import { MediaAlign } from "../../menus/MediaElementMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    "&$right div, &$left div": {
      height: "auto !important",
    },

    "&$left, &$right": {
      width: "33%",
    },

    "&$left [data-image-resized], &$right [data-image-resized]": {
      width: "100% !important",
    },

    "&$left img, &$right img": {
      width: "100% !important",
    },
  },

  right: {
    marginLeft: "auto",
    "& [data-image-resized] > span": {
      display: "none",
    },
  },

  left: {
    marginRight: "auto",
    "& [data-image-resized] > span": {
      display: "none",
    },
  },

  fullWidth: {
    minWidth: "100vw",

    '[role="dialog"] &': {
      minWidth: "100%",
    },

    "& > div:nth-child(1)": {
      margin: `${theme.spacing(0, -1)} !important`,
    },

    "& img": {
      minWidth: "100%",
      maxWidth: "auto",
    },

    "& [data-image-resized]": {
      display: "block !important",
      position: "static !important",
      width: "100% !important",
      overflow: "hidden",
    },

    "& [data-image-resized] > span": {
      display: "none",
    },
  },
}));

export interface MediaAlignBoxProps extends BoxProps {
  element: Element;
  editor: any;
  node: any;
  path: any;
  onRemove: () => void;
  onSetAlign: (align: string) => void;
}

export function MediaAlignBox(props: MediaAlignBoxProps) {
  const {
    className,
    children,
    element,
    editor,
    node,
    onSetAlign,
    onRemove,
    path,
    ...other
  } = props;
  const s = useStyles();
  const align = (element as any).align as MediaAlign;

  return (
    <Box
      className={clsx(s.root, className, {
        [s.fullWidth]: align === MediaAlign.FULL_WIDTH,
        [s.left]: align === MediaAlign.LEFT,
        [s.right]: align === MediaAlign.RIGHT,
      })}
      {...other}
    >
      {children}
    </Box>
  );
}
