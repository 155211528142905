/**
 * @generated SignedSource<<9385596d1a5eeddfd22b675f50e36b16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientCalendarProgramCard_enrollment$data = {
  readonly endDateShort: string | null | undefined;
  readonly id: string;
  readonly startDateRaw: string | null | undefined;
  readonly startDateShort: string | null | undefined;
  readonly startFromNow: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeEnrollmentStartDateDialog_enrollment" | "ClientSmallProgramCard_enrollment" | "ConfirmRemoveFromProgramDialog_enrollment">;
  readonly " $fragmentType": "ClientCalendarProgramCard_enrollment";
};
export type ClientCalendarProgramCard_enrollment$key = {
  readonly " $data"?: ClientCalendarProgramCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientCalendarProgramCard_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "utc",
  "value": true
},
v1 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMM DD, YYYY"
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientCalendarProgramCard_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "startDateRaw",
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(raw:true)"
    },
    {
      "alias": "startFromNow",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        },
        (v0/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(format:\"fromNow\",utc:true)"
    },
    {
      "alias": "startDateShort",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(format:\"MMM DD, YYYY\",utc:true)"
    },
    {
      "alias": "endDateShort",
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": "endDate(format:\"MMM DD, YYYY\",utc:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSmallProgramCard_enrollment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmRemoveFromProgramDialog_enrollment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeEnrollmentStartDateDialog_enrollment"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "9156f3d04edae2ac77c98333328e1e26";

export default node;
