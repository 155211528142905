import clsx from "clsx";
import React from "react";
import { Box, CardProps, Card, Typography, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { ReactComponent as CheckIcon } from "../../icons/CheckGreenCircle.svg";
import { ReactComponent as Mentally } from "../../icons/mentallyOpacity.svg";
import { ReactComponent as Physically } from "../../icons/physicallyOpacity.svg";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  PROGRAMS_ROUTE,
} from "../../routes/routes";
import { Icons } from "../plate-ui/Icons/icons";
import SeeMoreNotes from "./SeeMoreNotes";
import { TbMoodConfuzedFilled as LightlyBadFeelIcon } from "react-icons/tb";
import { TbMoodSadFilled as BadFeelIcon } from "react-icons/tb";
import { TbMoodSmileFilled as GoodFeelIcon } from "react-icons/tb";
import { IoTime as ClockIcon } from "react-icons/io5";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";

const useStyles = (shortened: boolean) =>
  makeStyles(({ spacing, palette }) => {
    return {
      root: {
        ...(!shortened
          ? {
              padding: spacing(3),
              marginBottom: spacing(2),
            }
          : {
              display: "flex",
              alignItems: "center",
              gap: spacing(2),
            }),
      },

      title: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 18,
        color: palette.text.secondary,
        fontWeight: 500,
        marginBottom: spacing(2),
        display: "block",
      },

      type: {
        fontFamily: "Montserrat, sans-serif",
        marginLeft: spacing(4),
      },

      notes: {
        ...(shortened
          ? {
              fontSize: 14,
              fontWeight: "normal",
              wordBreak: "break-word",
            }
          : {
              backgroundColor: colorSystem.background,
              padding: spacing(1.5, 2),
              borderRadius: 8,
              fontSize: 16,
              fontWeight: "normal",
              wordBreak: "break-word",
            }),
      },

      value: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: shortened ? 600 : 500,
        fontSize: shortened ? 14 : 18,
      },

      hyped: {
        color: colorSystem.green2,
      },
      bad: {
        color: colorSystem.primary,
      },
      tired: {
        color: colorSystem.orange,
      },
      ok: {
        color: colorSystem.yellow,
      },
      good: {
        color: colorSystem.blue1,
      },

      item: {
        borderColor: colorSystem.border3,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 40,
        backgroundColor: shortened ? colorSystem.gray8 : colorSystem.background,
        padding: shortened ? spacing(1, 2) : spacing(0, 3, 0, 0),
        display: "flex",
        alignItems: "center",
        justifyContent: shortened ? "flex-start" : "space-between",
        columnGap: shortened ? spacing(2) : 0,
        marginBottom: spacing(1.3),
      },

      bottomContainer: {
        marginTop: spacing(4),
      },

      flex: {
        display: "flex",
        alignItems: "center",
        ...(shortened && { gap: spacing(2) }),
      },

      button: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "44px",
        marginTop: spacing(5),
      },

      iconContainer: {
        backgroundColor: colorSystem.white,
        width: shortened ? 40 : 70,
        height: shortened ? 40 : 70,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
      },
    };
  });

export interface TrainingSummaryWellBeginProps extends CardProps {
  startWorkout: any;
  endWorkout: any;
  shortened?: boolean;
}

export function TrainingSummaryWellBegin(props: TrainingSummaryWellBeginProps) {
  const {
    className,
    startWorkout,
    endWorkout,
    shortened = false,
    ...other
  } = props;
  const navigate = useNavigate();
  const s = useStyles(shortened)();
  const user = useCurrentUser();

  const isCoach = user.role === UserRole.COACH;

  const styleBefore = (value: string) => {
    switch (value) {
      case "5 Hyped":
      case "5 Strong":
        return s.hyped;
      case "1 Bad":
        return s.bad;
      case "2 Tired":
        return s.tired;
      case "3 OK":
        return s.ok;
      case "4 Good":
        return s.good;
      default:
        return "";
    }
  };

  const styleAfter = (value: string) => {
    switch (value) {
      case "5 V.Hard":
        return s.bad;
      case "1 V.Ease":
        return s.hyped;
      case "2 Ease":
        return s.good;
      case "3 Medium":
        return s.ok;
      case "4 Hard":
        return s.tired;
      default:
        return "";
    }
  };

  const FeelIcon = ({ value, ...props }) => {
    switch (value) {
      case "5 V.Hard":
        return <BadFeelIcon {...props} />;
      case "4 Hard":
        return <LightlyBadFeelIcon {...props} />;

      default:
        return <GoodFeelIcon {...props} />;
    }
  };
  if (shortened) {
    const items = [
      {
        description: "Mental readiness for training",
        element: startWorkout.mindValue ? (
          <>
            <Mentally
              fill="#000"
              width={24}
              height={24}
              viewBox="15 15 50 50"
            />
            <Typography
              className={`${s.value} ${styleBefore(startWorkout.mindValue)}`}
              noWrap
            >
              {startWorkout.mindValue}
            </Typography>
          </>
        ) : null,
      },

      {
        description: "Physical readiness for training",
        element: startWorkout.bodyValue ? (
          <>
            <Physically width={24} height={24} viewBox="15 15 50 50" />
            <Typography
              className={`${s.value} ${styleBefore(startWorkout.bodyValue)}`}
              noWrap
            >
              {startWorkout.bodyValue}
            </Typography>
          </>
        ) : null,
      },
      {
        description: "Feelings after training",
        element: endWorkout.feel ? (
          <>
            <FeelIcon
              value={endWorkout.feel}
              color={colorSystem.yellow}
              fontSize={24}
            />
            <Typography
              className={`${s.value} ${styleAfter(endWorkout.feel)}`}
              noWrap
            >
              {endWorkout.feel}
            </Typography>
          </>
        ) : null,
      },

      {
        description: "How long the training took",
        element: endWorkout.time ? (
          <>
            <ClockIcon
              width={20}
              height={20}
              fontSize={24}
              color={colorSystem.gray2}
            />
            <Typography className={s.value} noWrap>
              {endWorkout.time} min
            </Typography>
          </>
        ) : null,
      },
      {
        description: "",
        element: endWorkout.notes ? (
          <>
            <Icons.notepadText
              width={20}
              height={20}
              style={{
                flexShrink: 0,
              }}
            />
            <SeeMoreNotes>{endWorkout.notes}</SeeMoreNotes>
          </>
        ) : null,
      },
    ];
    return (
      <Grid container columnSpacing={2} maxWidth={700}>
        {items
          // Filter out any null items if values are empty
          .filter((i) => i.element)
          .map((item, index) => (
            <Grid
              key={index}
              item
              xs={index === items.length - 1 ? 12 : 6}
              sm={index === items.length - 1 ? 12 : "auto"}
            >
              <MinimizedTooltip
                title={item.description}
                placement="bottom"
                opacity={0.9}
                delayNextEnter={false}
              >
                <Box
                  className={s.item}
                  sx={{
                    ...(index === items.length - 1 && {
                      borderRadius: "12px !important",
                    }),
                  }}
                >
                  {item.element}
                </Box>
              </MinimizedTooltip>
            </Grid>
          ))}
      </Grid>
    );
  }

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box>
        <span className={s.title}>Well-being before training</span>
        <Box className={s.item}>
          <div className={s.flex}>
            <div className={s.iconContainer}>
              <Mentally fill="#000" />
            </div>
            <Typography variant="h6" className={s.type} children="Mind" />
          </div>
          <span className={`${s.value} ${styleBefore(startWorkout.mindValue)}`}>
            {startWorkout.mindValue}
          </span>
        </Box>
        <Box className={s.item}>
          <div className={s.flex}>
            <div className={s.iconContainer}>
              <Physically />
            </div>
            <Typography variant="h6" className={s.type} children="Body" />
          </div>
          <span className={`${s.value} ${styleBefore(startWorkout.bodyValue)}`}>
            {startWorkout.bodyValue}
          </span>
        </Box>
      </Box>
      <Box className={s.bottomContainer}>
        <span className={s.title}>Well-being after training</span>
        <Box className={s.item}>
          <div className={s.flex}>
            <div className={s.iconContainer}>
              <FeelIcon
                value={endWorkout.feel}
                color={colorSystem.yellow}
                fontSize={35}
              />
            </div>
            <Typography variant="h6" className={s.type} children="Feels" />
          </div>
          <span className={`${s.value} ${styleAfter(endWorkout.feel)}`}>
            {endWorkout.feel}
          </span>
        </Box>
        <Box className={s.item}>
          <div className={s.flex}>
            <div className={s.iconContainer}>
              <ClockIcon fontSize={35} color={colorSystem.gray2} />
            </div>
            <Typography variant="h6" className={s.type} children="Time" />
          </div>
          <span className={s.value}>{endWorkout.time} minutes</span>
        </Box>
      </Box>
      {endWorkout.notes && (
        <Box className={s.bottomContainer}>
          <span className={s.title}>Notes</span>
          <Typography className={`${s.notes}`}>{endWorkout.notes}</Typography>
        </Box>
      )}
      <Button
        fullWidth
        variant="contained"
        className={s.button}
        onClick={() =>
          isCoach ? navigate(-1) : navigate(CLIENT_PROGRAMS_ACTIVE_ROUTE)
        }
      >
        Done
      </Button>
    </Card>
  );
}
