import React from "react";
import WorkoutHistoryClient from "./WorkoutHistoryClient";

const WorkoutHistoryClientContainer = (props: any) => {
  const { exercise } = props;
  return (
    <div>
      <React.Suspense fallback={<div>Loading results…</div>}>
        <WorkoutHistoryClient exercise={exercise} />
      </React.Suspense>
    </div>
  );
};

export default WorkoutHistoryClientContainer;
