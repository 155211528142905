import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { Box, Typography } from "@mui/material";
import { nutritionTargetsLabels } from "../../constants";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colorSystem.gray,
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 500,
  },

  titleContainer: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0.8, 1.1),
    borderRadius: 4,
  },

  active: {
    backgroundColor: colorSystem.black,
  },

  activeText: {
    color: colorSystem.white,
    fontWeight: 700,
  },
}));

export interface ClientNutritionTargetListItemHeaderProps {
  title: string;
  index: number;
  activeIndex: number;
  onClick: () => void;
}

export function ClientNutritionTargetListItemHeader(
  props: ClientNutritionTargetListItemHeaderProps,
) {
  const { title, index, activeIndex, onClick } = props;
  const s = useStyles();

  return (
    <Box
      className={clsx(s.titleContainer, index === activeIndex && s.active)}
      onClick={onClick}
    >
      <Typography
        variant="body1"
        className={clsx(s.title, index === activeIndex && s.activeText)}
        children={nutritionTargetsLabels[title]}
      />
    </Box>
  );
}
