import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { UserNotesList_user$key } from "./__generated__/UserNotesList_user.graphql";

import { UserNotesListItem } from "./UserNotesListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginBottom: theme.spacing(4),
  },
}));

const userFragment = graphql`
  fragment UserNotesList_user on User
  @refetchable(queryName: "UserNotesListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
  ) {
    id
    notes(first: $first, after: $after)
      @connection(key: "CoachClientNotesScreen_notes") {
      edges {
        node {
          id
          ...UserNotesListItem_note
        }
      }
    }
  }
`;

export interface UserNotesListProps extends BoxProps {
  user: UserNotesList_user$key;
}

export function UserNotesList(props: UserNotesListProps) {
  const { className, user, ...other } = props;
  const {
    data: userData,
    loadNext,
    hasNext,
  } = usePaginationFragment(userFragment, user);
  const s = useStyles();
  const { notes } = userData;
  const pageSize = 10;

  const [loading, setLoading] = React.useState(false);
  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    setLoading(true);
    loadNext(pageSize, {
      onComplete: setLoaded,
    });
  }, [pageSize, loadNext, setLoaded]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {notes.edges.map(({ node }) => (
        <UserNotesListItem key={node.id} className={s.card} noteRef={node} />
      ))}

      {hasNext && (
        <Button onClick={handleMoreClick} disabled={loading} fullWidth>
          Show older notes
        </Button>
      )}
    </Box>
  );
}
