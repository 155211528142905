import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { useParams } from "react-router-dom";
import { TrainingSummary } from "../../../../components/training-summary/TrainingSummary";
import { CoachComponentClientSummaryRouteQuery } from "./__generated__/CoachComponentClientSummaryRouteQuery.graphql";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachComponentClientSummaryRoute() {
  const params = useParams();

  const program = params.slug;
  const client = params.client;
  const date = params.date;
  const component = params.component;

  const props = useLazyLoadQuery<CoachComponentClientSummaryRouteQuery>(
    graphql`
      query CoachComponentClientSummaryRouteQuery(
        $client: String!
        $date: String!
        $component: String!
      ) {
        activity(client: $client, date: $date, component: $component) {
          ...TrainingSummary_activity
        }
      }
    `,
    {
      client: client,
      program: program,
      date: date,
      component: component,
    },
  );
  const { activity } = props;
  return (
    activity && (
      <>
        <TrainingSummary activityRef={activity} />;
        <TrackInfoTool
          trackInfo={{
            name: "Client - Program",
          }}
        />
      </>
    )
  );
}
