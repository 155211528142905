import React from "react";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Typography,
  darken,
  useTheme,
} from "@mui/material";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { FieldsGroup } from "./FieldsGroup";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    margin: 0,
  },

  popper: {
    zIndex: "99999 !important" as any,
    borderRadius: 12,
    boxShadow: theme.shadows[5],
  },

  option: {
    padding: theme.spacing(1, 2),
    fontSize: 18,
    fontWeight: 500,
  },
  renderLabel: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: 12,
    paddingBlock: 8,
    "&:hover": {
      backgroundColor: darken(theme.palette.common.white, 0.1),
      boxShadow: "none",
    },
  },
  icon: {
    padding: 4,
    marginLeft: 4,
  },
}));

type TagsProps = {
  node: {
    title: string;
    id: string;
  };
};

export interface TagFieldProps
  extends Omit<
    AutocompleteProps<any, true, false, any>,
    "options" | "onChange"
  > {
  onChange?: (data: string) => void;
  allTags?: TagsProps[];
  onDeleteTag: (id: string, title: string) => void;
  disabled: boolean;
}

const TagField = (props: TagFieldProps) => {
  const theme = useTheme();
  const { onChange, allTags, onDeleteTag, disabled, ...other } = props;
  const s = useStyles();

  const renderLabel = (others, value: TagsProps, ownerState) => {
    return (
      <Box
        {...others}
        className={s.renderLabel}
        sx={{
          backgroundColor:
            ownerState.selected === true &&
            darken(theme.palette.common.white, 0.05),
        }}
      >
        <Typography
          sx={{
            fontWeight: ownerState.selected === true && "bold",
          }}
        >
          {value.node.title}
        </Typography>
        <IconButton
          children={<DeleteIcon width={18} height={20} />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDeleteTag(value.node.id, value.node.title);
          }}
          disabled={disabled}
          className={s.icon}
          size="large"
        />
      </Box>
    );
  };

  const handleChange = React.useCallback(
    (_, value: TagsProps) => {
      if (onChange) {
        onChange(value.node.title);
      }
    },
    [onChange],
  );

  return (
    <FieldsGroup className={clsx(s.root)} label={"Tag #"}>
      <Autocomplete
        classes={{
          root: clsx(s.root),
          paper: s.paper,
          popper: s.popper,
          option: s.option,
        }}
        getOptionLabel={(option) => option?.node?.title}
        options={allTags}
        freeSolo
        disableClearable={false}
        renderOption={renderLabel}
        onChange={handleChange as any}
        {...other}
      />
    </FieldsGroup>
  );
};

export default TagField;
