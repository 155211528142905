import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { clientNavigation, clientsBreadcrumb } from "../utils/common";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ClientMeasurementScreen,
  defaultClientMeasurementType,
} from "../../../../components/client-measurement/ClientMeasurementScreen";
import { CoachClientTrendsRouteQuery } from "./__generated__/CoachClientTrendsRouteQuery.graphql";
import { toEnum } from "../../../../utils/misc";
import { TypeMeasurement } from "../../../../constants";
import { ClientContext as AlternativeClientContext } from "../../../../contexts/ClientContext";

export function CoachClientTrendsRoute() {
  const { username } = useParams();
  const [searchParams] = useSearchParams();
  const metric = searchParams.get("metric");

  const props = useLazyLoadQuery<CoachClientTrendsRouteQuery>(
    graphql`
      query CoachClientTrendsRouteQuery(
        $username: String!
        $measurementType: MeasurementType
        $period: CompletionPeriodType
        $includeMeasurement: Boolean!
        $includeNutrition: Boolean!
      ) {
        client: user(username: $username) {
          ...ClientSummary_client
          displayName
          username

          ...ClientMeasurementScreen_client
            @arguments(
              measurementType: $measurementType
              period: $period
              includeMeasurement: $includeMeasurement
              includeNutrition: $includeNutrition
            )
        }
      }
    `,
    {
      includeMeasurement: true,
      includeNutrition: false,
      measurementType: "BODYFAT",
      period: null,
      username: username,
    },
  );

  const { client } = props;

  const context = useContext(AlternativeClientContext);
  context.setCoachClientQuery(props);

  return client && <ClientMeasurementScreen client={client} />;
}
