/**
 * @generated SignedSource<<d34509eae494e51c31548b7e66a3c822>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachComponent_component$data = {
  readonly content: string | null | undefined;
  readonly days: ReadonlyArray<boolean>;
  readonly duration: number;
  readonly habitPrompt: string | null | undefined;
  readonly id: string;
  readonly messageContent: string | null | undefined;
  readonly messageTime: string | null | undefined;
  readonly reminderTime: string | null | undefined;
  readonly reminderType: ReminderType | null | undefined;
  readonly repeat: ComponentRepeat;
  readonly slug: string;
  readonly status: ComponentStatus;
  readonly title: string;
  readonly type: ComponentType;
  readonly weekId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CoachComponentBar_component" | "CoachComponentTitle_component" | "ComponentIcon_component" | "ComponentPublishButton_component">;
  readonly " $fragmentType": "CoachComponent_component";
};
export type CoachComponent_component$key = {
  readonly " $data"?: CoachComponent_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachComponent_component">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "draft",
    "variableName": "draft"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "draft"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachComponent_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "habitPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "messageContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "reminderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "reminderTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "messageTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "days",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "CoachComponentBar_component"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentPublishButton_component"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "CoachComponentTitle_component"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentIcon_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "8f61df2cc322bde907338a14c1a72464";

export default node;
