import React from "react";
import { Node } from "slate";

import { EditorConfig } from "../editor/utils/configure";
import { fixLegacyContent } from "../editor/utils/common";
import { defaultAllowedTypes } from "../editor/defaults";
import {
  SchemaElements,
  clipTrailingNodes,
} from "../editor/normalizers/withSchema";
import { ElementType } from "../editor/types/elements";
import {
  EditorConfigContext,
  EditorDisabledContext,
  EditorReadOnlyContext,
} from "./hooks";
import { PlateEditor } from "./PlateEditor";
import { Schema } from "./editor-configuration/schemas/Schema";
import { PlatePlugin } from "@udecode/plate-common";

export interface BaseEditorProps {
  value?: Node[];
  onChange?: (value: Node[]) => void;
  placeholder?: string;
  spellCheck?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  preNormalize?: boolean;
  inlineToolbar?: boolean;
  enableLinkPreviews?: EditorConfig["enableLinkPreviews"];
  allowTypes?: EditorConfig["allowTypes"];
  trackEvent?: EditorConfig["trackEvent"];
  editor?: EditorConfig["editor"];
  schema?: EditorConfig["schema"];
  debugging?: boolean;
  topToolbar?: boolean;
  compact?: boolean;
  clipTrailing?: boolean;
  children?: React.ReactNode;

  // TODO_Editor fix typing
  onFocus: any;
  onBlur: any;
  onKeyDownCapture?: any;
  onDrop?: any;
  onPaste?: any;
  className?: string;

  sidebar?: boolean;
  softbreak?: "shift+enter" | "enter";
  multiline?: boolean;
  deserialize?: boolean;

  editorSchema: Schema;
  platePlugins: PlatePlugin[];
  stickyTop?: number;
}

export function BaseEditor(props: BaseEditorProps) {
  const {
    className,
    onChange = () => null,
    value,
    preNormalize,
    allowTypes = defaultAllowedTypes,
    softbreak,
    multiline,
    deserialize,
    autoFocus,
    inlineToolbar,
    sidebar,
    editor: overrideEditor,
    schema,
    enableLinkPreviews,
    trackEvent,
    topToolbar,
    debugging = true,
    compact = false,
    readOnly,
    clipTrailing = readOnly,
    children,
    platePlugins,
    stickyTop,
    ...other
  } = props;
  const editorValue: Node[] =
    value && typeof value === "string" ? JSON.parse(value) : value;
  const content = fixLegacyContent(
    editorValue.length
      ? editorValue
      : [SchemaElements.createElement(ElementType.PARAGRAPH)],
  );

  const config: EditorConfig = React.useMemo(
    () => ({
      softbreak,
      multiline,
      allowTypes,
      deserialize,
      sidebar,
      editor: overrideEditor,
      schema,
      enableLinkPreviews,
      trackEvent,
      compact,
      readOnly,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // TODO_Editor
  //const Editable = multiline ? EditablePluginsMultiline : EditablePlugins;

  const handleDebug = React.useCallback(
    () => JSON.stringify(content, null, "\t"),
    [content],
  );

  if (debugging) {
    window["__slatejs_debug"] = handleDebug;
  }

  return (
    <EditorConfigContext.Provider value={config}>
      <EditorDisabledContext.Provider value={Boolean(props.disabled)}>
        <EditorReadOnlyContext.Provider value={Boolean(props.readOnly)}>
          {/* TODO_editor editor & autoFocus props */}
          <PlateEditor
            editorData={clipTrailing ? clipTrailingNodes(content) : content}
            setEditorData={onChange}
            readOnly={props.readOnly}
            editorSchema={props.editorSchema}
            platePlugins={props.platePlugins}
            stickyTop={stickyTop}
            //editor={editor}
            //autoFocus={autoFocus}
          >
            {/* TODO_editor {inlineToolbar && !readOnly && (
                <InlineToolbar topToolbar={topToolbar} />
              )}}
              {/* TODO_editor <Editable
                className={className}
                plugins={plugins}
                readOnly={readOnly}
                {...other}
              /> */}
            {children}
            {/* TODO_editor {multiline && !compact && <Clicker />} */}
          </PlateEditor>
        </EditorReadOnlyContext.Provider>
      </EditorDisabledContext.Provider>
    </EditorConfigContext.Provider>
  );
}
