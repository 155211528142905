import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { isOverflown } from "../../utils/dom";
import { ReactComponent as ForwardIcon } from "../../icons/ChevronForwardOutline.svg";

interface ThemeProps {
  maxHeight: number;
}

const useStyles = makeStyles((theme) => ({
  root: {},

  container: {
    overflowY: "hidden",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    maxHeight: ({ maxHeight }: ThemeProps) => maxHeight,
  },

  button: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 18,
    margin: theme.spacing(1, 0, -2, -1),

    "& svg$arrow": {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
      transform: "rotate(90deg)",
    },

    "$expanded & svg$arrow": {
      transform: "rotate(-90deg)",
    },
  },

  overlay: {
    "&$collapsed:not($expanded) $container::before": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "50%",
      background: `linear-gradient(transparent ${theme.spacing(-2)}, ${
        theme.palette.background.paper
      })`,
    },
  },

  collapsed: {
    '&:not($expanded) [data-collapsed="hidden"]': {
      display: "none",
    },
  },

  expanded: {
    "& $container": {
      maxHeight: "inherit",
      overflowY: "inherit",
    },
  },

  arrow: {},
}));

export interface HeightOverflowProps extends BoxProps {
  renderLabel?: (expanded: boolean) => React.ReactNode;
  label?: string;
  disableOverlay?: boolean;
  alwaysExpandable?: boolean;
  onExpandChange?: (expanded: boolean) => void;
}

export function HeightOverflow(props: HeightOverflowProps) {
  const {
    className,
    children,
    maxHeight,
    label,
    renderLabel,
    disableOverlay = false,
    alwaysExpandable = false,
    onExpandChange,
    ...other
  } = props;
  const maxHeightValue = maxHeight as number;
  const themeProps: ThemeProps = { maxHeight: maxHeightValue };
  const s = useStyles(themeProps);
  const ref = React.useRef<HTMLDivElement>();
  const [collapsed, setCollapsed] = React.useState(alwaysExpandable);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandToggle = React.useCallback(
    (event) => {
      event.stopPropagation();
      setExpanded(!expanded);
      if (onExpandChange) {
        onExpandChange(!expanded);
      }
    },
    [expanded, onExpandChange],
  );

  React.useEffect(() => {
    const detectCollapse = () => {
      const el = ref.current;

      if (el && isOverflown(el)) {
        setCollapsed(true);
      }
    };

    detectCollapse();

    const timer = setInterval(detectCollapse, 250);
    return () => clearInterval(timer);
  });

  return (
    <Box
      className={clsx(s.root, className, {
        [s.collapsed]: collapsed,
        [s.expanded]: expanded,
        [s.overlay]: !disableOverlay,
      })}
      {...other}
    >
      <div ref={ref} className={clsx(s.container)}>
        {children}
      </div>
      {collapsed && (
        <Button className={s.button} onClick={handleExpandToggle}>
          {renderLabel ? (
            renderLabel(expanded)
          ) : (
            <>
              <ForwardIcon className={s.arrow} />
              {`${expanded ? "Collapse" : "Expand"} ${label || ""}`}
            </>
          )}
        </Button>
      )}
    </Box>
  );
}
