import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { useRelayEnvironment } from "react-relay/hooks";

import ClientStatsSkeleton from "../loading/ClientStatsSkeleton";

import { ClientCardCompletion } from "./ClientCardCompletion";
import { ClientStatsQuery } from "./__generated__/ClientStatsQuery.graphql";

export interface ClientStatsProps {
  clientId: string;
}

const QUERY = graphql`
  query ClientStatsQuery($clientId: ID!) {
    client: node(id: $clientId) {
      ... on User {
        ...ClientCardWeight_client
        ...ClientCardCompletion_client
      }
    }
  }
`;

const ClientStatsCard = ({ client }: ClientStatsQuery["response"]) => (
  <ClientCardCompletion clientRef={client} />
);

export const ClientStats = ({ clientId }: ClientStatsProps) => (
  <QueryRenderer<ClientStatsQuery>
    environment={useRelayEnvironment()}
    query={QUERY}
    variables={{ clientId }}
    fetchPolicy="network-only"
    render={({ props }) =>
      props ? (
        <ClientStatsCard client={props.client} />
      ) : (
        <ClientStatsSkeleton />
      )
    }
  />
);
