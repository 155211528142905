/**
 * @generated SignedSource<<296ff69de2b81e427572feacbbccdf46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientsFormsRouteQuery$variables = Record<PropertyKey, never>;
export type CoachClientsFormsRouteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsFormsListScreen_root">;
};
export type CoachClientsFormsRouteQuery = {
  response: CoachClientsFormsRouteQuery$data;
  variables: CoachClientsFormsRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachClientsFormsRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CoachClientsFormsListScreen_root"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoachClientsFormsRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "ClientFormConnection",
        "kind": "LinkedField",
        "name": "clientForms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageCursors",
            "kind": "LinkedField",
            "name": "pageCursors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageCursor",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageCursor",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "page",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageCursor",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientFormEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientForm",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalQuestions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM D, YYYY"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"MMM D, YYYY\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "clientForms(first:50)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CoachClientsFormsListScreen_clientForms",
        "kind": "LinkedHandle",
        "name": "clientForms"
      }
    ]
  },
  "params": {
    "cacheID": "159e2df551fbd95bb511e50355006cb5",
    "id": null,
    "metadata": {},
    "name": "CoachClientsFormsRouteQuery",
    "operationKind": "query",
    "text": "query CoachClientsFormsRouteQuery {\n  ...CoachClientsFormsListScreen_root\n}\n\nfragment CoachClientsFormsListItem_clientForm on ClientForm {\n  id\n  name\n  slug\n  totalQuestions\n  createdAt(format: \"MMM D, YYYY\")\n}\n\nfragment CoachClientsFormsListScreen_root on Root {\n  clientForms(first: 50) {\n    pageCursors {\n      ...ConnectionPagination_pageCursors\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        ...CoachClientsFormsListItem_clientForm\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ConnectionPagination_pageCursors on PageCursors {\n  previous {\n    current\n    cursor\n  }\n  around {\n    current\n    cursor\n    page\n  }\n  next {\n    current\n    cursor\n  }\n}\n"
  }
};
})();

(node as any).hash = "381bd5c00bfd5aef873245cf82354619";

export default node;
