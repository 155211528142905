import { Card, CardProps, CardHeader, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

export interface BasicSkeletonCardProps extends CardProps {}

export function BasicSkeletonCard(props: BasicSkeletonCardProps) {
  return (
    <Card {...props}>
      <CardHeader
        title={
          <Grid item xs={12} md={6}>
            <Skeleton animation="wave" />
          </Grid>
        }
        subheader={
          <Grid item xs={6} md={3}>
            <Skeleton animation="wave" />
          </Grid>
        }
      />
    </Card>
  );
}
