/**
 * @generated SignedSource<<2802b505ba364bd9c8ff0c58343ce020>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyPhoneInput = {
  clientMutationId?: string | null | undefined;
  phone: string;
};
export type VerifyPhoneDialogVerifyPhoneMutation$variables = {
  input: VerifyPhoneInput;
};
export type VerifyPhoneDialogVerifyPhoneMutation$data = {
  readonly verifyPhone: {
    readonly sid: string;
  } | null | undefined;
};
export type VerifyPhoneDialogVerifyPhoneMutation = {
  response: VerifyPhoneDialogVerifyPhoneMutation$data;
  variables: VerifyPhoneDialogVerifyPhoneMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VerifyPhonePayload",
    "kind": "LinkedField",
    "name": "verifyPhone",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPhoneDialogVerifyPhoneMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyPhoneDialogVerifyPhoneMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ad6d12459b645aa1355f2b097e4b035a",
    "id": null,
    "metadata": {},
    "name": "VerifyPhoneDialogVerifyPhoneMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPhoneDialogVerifyPhoneMutation(\n  $input: VerifyPhoneInput!\n) {\n  verifyPhone(input: $input) {\n    sid\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2c971e322230dc15c78cebdaf3c716a";

export default node;
