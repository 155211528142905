import clsx from "clsx";
import React from "react";
import {
  Menu,
  MenuProps,
  MenuItem,
  ListItemText,
  Tooltip,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {
    margin: theme.spacing(0, 0.5),
    borderRadius: 2,
    minWidth: 220,

    "&:hover": {
      background: theme.palette.selected.main,
    },

    "&$reactive": {
      pointerEvents: "auto",
    },
  },

  listItemText: {
    fontWeight: 500,
  },

  tooltip: {
    padding: theme.spacing(1, 2),
  },

  reactive: {},
}));

export interface ListMenuOption {
  label: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  disabled?: boolean;
  tooltipText?: string;
}

export interface ListMenuProps extends MenuProps {
  disabled?: boolean;
  options: ListMenuOption[];
}

export function ListMenu(props: ListMenuProps) {
  const { className, onClose, disabled, options, ...other } = props;
  const s = useStyles();

  return (
    <Menu
      MenuListProps={{ dense: true }}
      onClose={onClose}
      className={clsx(s.root, className)}
      {...other}
    >
      {options.map(
        ({ label, onClick, disabled: optionDisabled, tooltipText }, index) => {
          const itemDisabled = disabled || optionDisabled;
          const item = (
            <MenuItem
              className={clsx(
                s.listItem,
                itemDisabled && tooltipText && s.reactive,
              )}
              onClick={itemDisabled ? undefined : onClick}
              disabled={itemDisabled}
              dense
            >
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  className: s.listItemText,
                }}
              />
            </MenuItem>
          );

          return (
            // Box because Menu does not accept fragments for children
            <Box key={index}>
              {tooltipText ? (
                <Tooltip
                  arrow
                  placement="right"
                  className={s.tooltip}
                  title={tooltipText}
                  children={item}
                />
              ) : (
                item
              )}
            </Box>
          );
        },
      )}
    </Menu>
  );
}
