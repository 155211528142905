/**
 * @generated SignedSource<<3c1cd57d02f04fd0b513e052b8927ffa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateGroupArchivedInput = {
  archived: boolean;
  clientMutationId?: string | null | undefined;
  id: string;
};
export type UpdateGroupArchivedMutation$variables = {
  input: UpdateGroupArchivedInput;
};
export type UpdateGroupArchivedMutation$data = {
  readonly updateGroupArchived: {
    readonly clientMutationId: string | null | undefined;
    readonly group: {
      readonly archived: boolean;
    };
    readonly groupEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GroupCard_group">;
      } | null | undefined;
    };
    readonly groupId: string;
  } | null | undefined;
};
export type UpdateGroupArchivedMutation = {
  response: UpdateGroupArchivedMutation$data;
  variables: UpdateGroupArchivedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateGroupArchivedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGroupArchivedPayload",
        "kind": "LinkedField",
        "name": "updateGroupArchived",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupEdge",
            "kind": "LinkedField",
            "name": "groupEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "GroupCard_group"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateGroupArchivedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGroupArchivedPayload",
        "kind": "LinkedField",
        "name": "updateGroupArchived",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupEdge",
            "kind": "LinkedField",
            "name": "groupEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "clients",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "230cb073a1b202aaaecae20a434556c2",
    "id": null,
    "metadata": {},
    "name": "UpdateGroupArchivedMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateGroupArchivedMutation(\n  $input: UpdateGroupArchivedInput!\n) {\n  updateGroupArchived(input: $input) {\n    clientMutationId\n    groupId\n    group {\n      archived\n      id\n    }\n    groupEdge {\n      node {\n        ...GroupCard_group\n        id\n      }\n    }\n  }\n}\n\nfragment GroupCard_group on Group {\n  ...ManageGroupDialog_group\n  id\n  name\n  slug\n  archived\n  clients {\n    ...StackedClientsAvatars_clients\n    id\n  }\n}\n\nfragment ManageGroupDialog_group on Group {\n  id\n  name\n  size\n  clients {\n    email\n    displayName\n    id\n  }\n}\n\nfragment StackedClientsAvatars_clients on User {\n  id\n  displayName\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "0ec94e93ee8e14c8c624047a3d083156";

export default node;
