import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { GroupPostsListItem } from "./GroupPostsListItem";
import { GroupPostsEmpty } from "./GroupPostsEmpty";
import { GroupPostsList_group$key } from "./__generated__/GroupPostsList_group.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const groupFragment = graphql`
  fragment GroupPostsList_group on Group
  @refetchable(queryName: "GroupPostsListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 5 }
    after: { type: "String" }
  ) {
    id
    posts(first: $first, after: $after)
      @connection(key: "GroupPostsList_posts") {
      edges {
        node {
          ...GroupPostsListItem_post
          id
        }
      }
    }
  }
`;

export interface GroupPostsListProps extends BoxProps {
  groupRef: GroupPostsList_group$key;
}

export function GroupPostsList(props: GroupPostsListProps) {
  const { className, groupRef, ...other } = props;
  const {
    data: group,
    loadNext,
    hasNext,
  } = usePaginationFragment(groupFragment, groupRef);
  const s = useStyles();
  const { posts } = group;
  const pageSize = 5;

  const [loading, setLoading] = React.useState(false);
  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      loadNext(pageSize, {
        onComplete: setLoaded,
      });
    }
  }, [loading, loadNext, setLoaded]);

  return posts.edges.length ? (
    <Box className={clsx(s.root, className)} {...other}>
      {posts.edges.map(({ node }) => (
        <GroupPostsListItem key={node.id} post={node} />
      ))}

      {hasNext && (
        <Button onClick={handleMoreClick} disabled={loading} fullWidth>
          Show older posts
        </Button>
      )}
    </Box>
  ) : (
    <GroupPostsEmpty groupId={group.id} />
  );
}
