import clsx from "clsx";
import React from "react";
import {
  ListItemProps,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { useUserIsClient } from "../../hooks/useCurrentUser";
import { maybePluralize } from "../../utils/text";
import { CardAvatar } from "../card/CardAvatar";

import { RecipientListItem_recipient$key } from "./__generated__/RecipientListItem_recipient.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },

  divider: {
    borderBottomColor: theme.palette.quote,
  },

  itemAvatar: {
    minWidth: theme.spacing(6),

    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(7),
    },
  },

  avatar: {
    margin: theme.spacing(0, 0, 0, 0),
    border: "2px solid",
    borderColor: theme.palette.grey[50],
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    fontSize: 12,
    marginLeft: theme.spacing(-2),
    fontWeight: "bold",
    backgroundColor: theme.palette.avatar,

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: theme.spacing(0, 2, 0, 0),
      fontSize: 18,
    },
  },

  primaryText: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },

  secondaryText: {
    color: theme.palette.text.secondary,
    wordBreak: "break-word",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
}));

const recipientFragment = graphql`
  fragment RecipientListItem_recipient on MessageRecipient {
    id
    name
    user {
      photoURL
      email
    }
    group {
      membersCount
    }
  }
`;

export interface RecipientListItemProps extends ListItemProps {
  recipientRef: RecipientListItem_recipient$key;
}

export function RecipientListItem(props: RecipientListItemProps) {
  const s = useStyles();
  const { className, recipientRef, ...other } = props;
  const recipient = useFragment(recipientFragment, recipientRef);
  const isClient = useUserIsClient();
  const { name, user, group } = recipient;

  return (
    <ListItemButton className={clsx(s.root, className)} {...(other as any)}>
      <ListItemAvatar className={s.itemAvatar}>
        <CardAvatar
          className={clsx(s.avatar, className)}
          src={user?.photoURL}
          children={name.substring(0, 1)}
        />
      </ListItemAvatar>

      <ListItemText
        classes={{
          primary: s.primaryText,
          secondary: s.secondaryText,
        }}
        primary={name}
        secondary={
          group
            ? maybePluralize(group.membersCount, "member")
            : !isClient && user?.email
        }
      />
    </ListItemButton>
  );
}
