/**
 * @generated SignedSource<<603c0f9b3764cffbcde121b1dcbf06a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CoachProgramFoldersMenuQuery$variables = Record<PropertyKey, never>;
export type CoachProgramFoldersMenuQuery$data = {
  readonly programFolders: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type CoachProgramFoldersMenuQuery = {
  response: CoachProgramFoldersMenuQuery$data;
  variables: CoachProgramFoldersMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProgramFolder",
    "kind": "LinkedField",
    "name": "programFolders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramFoldersMenuQuery",
    "selections": (v0/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoachProgramFoldersMenuQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ece88df1e72f0d87e8344ae7184eb4e6",
    "id": null,
    "metadata": {},
    "name": "CoachProgramFoldersMenuQuery",
    "operationKind": "query",
    "text": "query CoachProgramFoldersMenuQuery {\n  programFolders {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "47dff00b0034fbaea992fd78dfc6303c";

export default node;
