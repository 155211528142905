/**
 * @generated SignedSource<<abd0e3cd89256585a60c18ab17ea1c2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientProgram_enrollments$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly active: boolean;
      readonly currentWeek: number | null | undefined;
      readonly past: boolean;
      readonly program: {
        readonly name: string;
        readonly slug: string;
      };
      readonly upcoming: boolean;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "CoachClientProgram_enrollments";
};
export type CoachClientProgram_enrollments$key = {
  readonly " $data"?: CoachClientProgram_enrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientProgram_enrollments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientProgram_enrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EnrollmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Enrollment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "active",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "upcoming",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "past",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentWeek",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Program",
              "kind": "LinkedField",
              "name": "program",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EnrollmentConnection",
  "abstractKey": null
};

(node as any).hash = "a681406b57ab6cf56f65d4305df7a863";

export default node;
