import clsx from "clsx";
import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelected, RenderElementProps } from "slate-react";
import { Node } from "slate";
import { useReadOnly } from "../../hooks";

export const MEDIA_CAPTION = "MEDIA_CAPTION";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    outline: "none",
  },

  empty: {
    "&::after": {
      content: "'Add a caption…'",
    },

    '& [data-slate-node="text"]': {
      minWidth: 1,
    },
  },
}));

export function MediaCaptionElement(props: RenderElementProps) {
  const { children, element, attributes } = props;
  const s = useStyles();
  const selected = useSelected();
  const readOnly = useReadOnly();
  const empty = !Node.string(element);
  const skip = empty && readOnly && !selected;

  return skip ? (
    children
  ) : (
    <Box
      className={clsx(s.root, {
        [s.empty]: selected && empty,
      })}
      suppressContentEditableWarning
      contentEditable
      {...attributes}
    >
      {children}
    </Box>
  );
}
