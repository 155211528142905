import { Tooltip, alpha } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactElement<any, any>;
  visible?: boolean;
}
const DisabledCheckInTooltip = ({ children, visible = false }: Props) => {
  return (
    <Tooltip
      title={visible ? "Client's turn to answer." : undefined}
      followCursor
      placement="top-start"
      enterDelay={500}
      enterNextDelay={1500}
      componentsProps={{
        tooltip: {
          sx: {
            px: 1,
            py: 0.8,
            fontSize: 12,
            bgcolor: (theme) => alpha(theme.palette.common.black, 0.7),
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default DisabledCheckInTooltip;
