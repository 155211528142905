import React from "react";

const WorkingOut = ({ fill }) => (
  <svg
    width="385"
    height="210"
    viewBox="0 0 385 210"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M385 196.866C385 185.404 379.181 176.112 372.004 176.112C367.493 176.112 363.519 179.784 361.189 185.357C360.402 185.031 359.558 184.861 358.706 184.859C356.533 184.859 354.524 185.972 352.886 187.855C350.786 180.906 346.332 176.112 341.176 176.112C336.356 176.112 332.151 180.306 329.908 186.531C328.661 185.48 327.091 184.889 325.46 184.859C319.951 184.859 315.486 191.99 315.486 200.787C315.463 203.719 315.996 206.629 317.057 209.363H382.379C384.024 205.886 385 201.559 385 196.866Z"
      fill={fill}
    />
    <path
      d="M338.408 77.0214C328.173 54.8905 308.144 36.0111 283.948 32.9341C277.55 32.1205 270.87 32.4468 264.935 34.9672C257.143 38.2755 251.401 44.9534 245.597 51.1135C233.309 64.1527 219.558 75.7324 204.616 85.6233C197.241 90.5036 189.331 95.057 180.576 96.3057C163.021 98.8093 145.656 86.8429 137.65 71.0269C132.649 61.1471 130.338 49.5474 126.295 39.271C121.639 39.2621 116.092 39.2638 110.123 39.2913C93.6266 39.3673 80.1988 39.6109 80.1988 39.6109C80.1988 39.6109 96.1598 18.4779 110.063 26.3075C114.507 28.8096 119.16 31.0425 123.437 32.9234C121.008 28.2516 117.943 24.0481 113.727 20.6685C105.413 14.0044 94.1532 12.1351 83.5082 12.6314C72.3301 13.1525 61.0493 16.144 51.9662 22.6772C35.7852 34.3157 29.1032 54.8015 23.3843 73.8902L37.2077 80.8172C43.1371 75.6931 51.8077 70.6244 59.7996 75.1249C73.7033 82.9546 89.6751 88.1531 89.6751 88.1531C89.6751 88.1531 76.3563 88.0327 59.8595 88.1087C57.0888 88.1214 54.408 88.139 51.8595 88.1593L205.333 165.066C255.954 165.778 294.122 163.042 342.222 165.066C344.731 165.171 350.373 152.027 350.392 148.568C350.525 124.187 348.642 99.1523 338.408 77.0214ZM289.255 83.6545C272.759 83.7305 259.331 83.9741 259.331 83.9741C259.331 83.9741 275.292 62.8411 289.195 70.6707C303.099 78.5004 319.071 83.6989 319.071 83.6989C319.071 83.6989 305.752 83.5785 289.255 83.6545L289.255 83.6545Z"
      fill="#F2F2F2"
    />
    <path
      opacity="0.3"
      d="M332.241 155.98C332.241 147.645 327.079 140.889 320.711 140.889C316.709 140.889 313.184 143.559 311.117 147.612C310.407 147.372 309.662 147.25 308.913 147.249C306.975 147.282 305.125 148.063 303.75 149.428C301.887 144.375 297.935 140.889 293.361 140.889C289.085 140.889 285.355 143.939 283.364 148.465C282.197 147.682 280.824 147.259 279.418 147.249C274.531 147.249 270.57 152.434 270.57 158.83C270.555 160.988 271.032 163.12 271.964 165.066H329.916C331.47 162.291 332.271 159.159 332.241 155.98Z"
      fill={fill}
    />
    <path
      d="M385 209.964H29.2314V209.251H384.643L385 209.964Z"
      fill="#2F2E41"
    />
    <path
      d="M355.769 165.779H0V165.066H355.412L355.769 165.779Z"
      fill="#2F2E41"
    />
    <path
      d="M151.016 100.178C151.016 100.178 153.257 83.7534 156.991 88.2328C160.726 92.7122 156.991 99.4313 156.991 99.4313L154.004 103.164L151.016 100.924V100.178Z"
      fill="#FFB8B8"
    />
    <path
      d="M142.054 105.404C142.054 105.404 149.522 97.1915 151.016 98.6846C152.51 100.178 156.991 100.924 156.244 103.911C155.498 106.897 139.813 116.602 139.813 116.602L142.054 105.404Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M142.054 105.404C142.054 105.404 149.522 97.1915 151.016 98.6846C152.51 100.178 156.991 100.924 156.244 103.911C155.498 106.897 139.813 116.602 139.813 116.602L142.054 105.404Z"
      fill="black"
    />
    <path
      d="M99.4813 104.657V110.63C99.4813 110.63 98.7344 124.068 103.216 123.322C107.697 122.575 105.456 111.376 105.456 111.376L104.709 104.657H99.4813Z"
      fill="#FFB8B8"
    />
    <path
      d="M101.722 147.958C101.722 147.958 94.9998 158.41 92.7592 159.157C90.5185 159.904 78.5683 166.623 77.0746 173.342L66.6182 186.78L71.8464 193.499C71.8464 193.499 95.7467 168.116 102.469 165.876C109.191 163.636 115.913 152.438 115.913 152.438L101.722 147.958Z"
      fill="#FFB8B8"
    />
    <path
      d="M154.75 130.787C154.75 130.787 168.941 133.027 166.7 141.986C164.46 150.945 142.8 169.609 142.8 169.609L142.053 174.088L133.837 175.581L130.103 171.849L130.85 169.609C130.85 169.609 139.813 161.397 140.559 158.41C141.306 155.424 149.522 143.479 153.256 142.732L148.028 139.746L154.75 130.787Z"
      fill="#FFB8B8"
    />
    <path
      d="M68.8591 186.78C68.8591 186.78 71.8466 182.301 67.3653 183.047C62.884 183.794 59.8965 184.54 59.8965 187.527C59.8965 190.513 63.6309 211.417 68.1122 209.924C72.5935 208.431 68.8591 201.712 68.8591 201.712C68.8591 201.712 71.0998 195.739 72.5935 194.992C74.0873 194.246 74.0873 190.513 72.5935 190.513C71.0998 190.513 68.1122 188.273 68.8591 186.78Z"
      fill="#2F2E41"
    />
    <path
      d="M132.344 170.356C132.344 170.356 132.344 166.623 129.357 170.356C126.369 174.088 124.875 177.075 127.863 179.314C130.85 181.554 141.307 186.78 141.307 186.78C141.307 186.78 152.51 189.02 151.016 183.794C151.016 183.794 143.547 180.061 143.547 177.075C143.547 174.088 143.547 168.862 141.307 170.356C139.066 171.849 132.344 172.595 132.344 170.356Z"
      fill="#2F2E41"
    />
    <path
      d="M118.153 114.363C118.153 114.363 107.697 115.856 108.444 130.787C108.444 130.787 95 144.226 98.7345 148.705C102.469 153.184 110.685 161.397 113.672 159.157C116.66 156.917 125.622 139.746 125.622 139.746C125.622 139.746 146.535 147.212 150.27 143.479C154.004 139.746 157.738 130.787 157.738 130.787L139.066 121.082L118.153 114.363Z"
      fill="#2F2E41"
    />
    <path
      d="M147.282 71.8081C152.232 71.8081 156.245 67.7971 156.245 62.8492C156.245 57.9014 152.232 53.8904 147.282 53.8904C142.332 53.8904 138.319 57.9014 138.319 62.8492C138.319 67.7971 142.332 71.8081 147.282 71.8081Z"
      fill="#FFB8B8"
    />
    <path
      d="M140.56 63.5959C140.56 63.5959 135.331 71.8082 133.838 72.5548C132.344 73.3014 144.294 80.7671 144.294 80.7671C144.294 80.7671 144.294 71.0617 148.029 68.8219L140.56 63.5959Z"
      fill="#FFB8B8"
    />
    <path
      d="M139.813 71.8081C139.813 71.8081 127.863 68.0753 125.622 74.0478C123.381 80.0204 118.153 91.219 118.9 98.6847C119.647 106.15 121.141 104.657 118.9 106.897C116.659 109.137 115.166 107.644 115.913 110.63C116.659 113.616 117.406 113.616 116.659 115.109C115.913 116.602 125.622 121.828 133.838 122.575L142.054 123.322C142.054 123.322 142.054 109.883 143.547 106.897C145.041 103.911 148.029 97.9381 148.029 95.6984V89.7723C148.029 85.828 146.999 81.9519 145.041 78.5273C145.041 78.5273 140.56 71.8081 139.813 71.8081Z"
      fill={fill}
    />
    <path
      d="M130.85 74.7944C130.85 74.7944 103.215 77.0341 100.228 84.4999C97.2402 91.9656 95.7464 106.897 98.7339 106.897C101.721 106.897 105.456 108.39 106.203 106.897C106.95 105.404 109.937 90.4724 110.684 90.4724C111.431 90.4724 133.838 88.2327 133.838 88.2327C133.838 88.2327 143.547 76.2876 130.85 74.7944Z"
      fill={fill}
    />
    <path
      d="M147.642 60.6282C147.642 60.6282 150.02 56.4967 152.993 60.0853C155.966 63.6739 160.803 57.0792 160.06 55.6721C159.318 54.2649 156.696 55.7353 152.203 54.2671C147.71 52.7989 139.054 49.8078 137.604 59.5648C136.155 69.3218 139.607 68.2989 139.607 68.2989C139.607 68.2989 141.19 59.6531 142.62 62.1366L144.05 64.62L145.291 64.9252L147.642 60.6282Z"
      fill="#2F2E41"
    />
    <path
      d="M232.179 37.767C238.219 37.767 243.114 32.8733 243.114 26.8367C243.114 20.8001 238.219 15.9065 232.179 15.9065C226.14 15.9065 221.245 20.8001 221.245 26.8367C221.245 32.8733 226.14 37.767 232.179 37.767Z"
      fill="#2F2E41"
    />
    <path
      d="M228.684 34.9268C228.684 34.9268 229.25 45.108 228.118 45.108C226.987 45.108 235.192 49.9157 235.192 49.9157L240.284 47.0876V43.6939C240.284 43.6939 233.494 42.8455 236.323 35.4924C239.153 28.1394 228.684 34.9268 228.684 34.9268Z"
      fill="#9F616A"
    />
    <path
      d="M204.918 146.636L204.069 155.121L206.899 155.969L209.162 153.707L210.011 146.636H204.918Z"
      fill="#9F616A"
    />
    <path
      d="M245.66 151.444L247.075 156.817L251.036 159.363L255.28 157.1L250.47 149.182L245.66 151.444Z"
      fill="#9F616A"
    />
    <path
      d="M231.372 39.3104C235.903 39.3104 239.577 35.6385 239.577 31.1089C239.577 26.5794 235.903 22.9075 231.372 22.9075C226.84 22.9075 223.167 26.5794 223.167 31.1089C223.167 35.6385 226.84 39.3104 231.372 39.3104Z"
      fill="#9F616A"
    />
    <path
      d="M226.421 61.7938L224.44 60.9454C224.44 60.9454 221.045 58.9657 220.762 55.0064C220.479 51.0471 223.592 45.9565 223.592 45.9565L228.118 44.5425L233.437 48.8696L233.494 55.8549L226.421 61.7938Z"
      fill="#9F616A"
    />
    <path
      d="M224.44 46.5221L223.309 45.9565C223.309 45.9565 217.65 45.6737 217.084 52.4611C216.518 59.2485 214.538 70.5609 214.538 70.5609C214.538 70.5609 210.011 78.4796 210.294 86.9638L208.03 94.3169C208.03 94.3169 204.918 105.064 208.596 105.064C212.274 105.064 211.991 93.4685 211.991 93.4685C211.991 93.4685 218.782 77.0655 219.348 74.2374C219.913 71.4093 224.723 58.1173 223.309 54.4408C221.894 50.7643 224.44 46.5221 224.44 46.5221Z"
      fill="#9F616A"
    />
    <path
      d="M228.684 64.3391V67.45C228.684 67.45 230.665 69.7125 229.816 71.1265C228.967 72.5406 228.118 75.3687 228.118 75.3687L232.079 81.5905L245.377 82.7217L248.021 65.0122L228.684 64.3391Z"
      fill="#9F616A"
    />
    <path
      d="M241.133 44.5424L243.397 43.4112C243.397 43.4112 248.207 40.0175 252.168 39.4519L264.333 31.2504L272.255 12.3022C272.255 12.3022 271.972 2.12106 274.236 3.25229C276.499 4.38353 276.216 13.1506 276.216 13.1506C276.216 13.1506 271.973 30.6848 268.294 35.4925C264.616 40.3003 251.319 48.219 251.319 48.219C251.319 48.219 247.641 52.4611 247.924 54.4407C248.207 56.4204 241.133 44.5424 241.133 44.5424Z"
      fill="#9F616A"
    />
    <path
      d="M230.665 75.3687C230.665 75.3687 227.553 73.389 225.572 76.7827C223.592 80.1764 219.914 81.0248 217.65 91.206C215.387 101.387 210.011 117.507 208.596 118.638C207.182 119.77 201.24 131.648 204.635 148.333H210.294L215.104 124.295C215.104 124.295 230.665 105.064 231.797 97.145L236.889 126.84C236.889 126.84 237.172 138.152 241.416 143.526L245.943 154.272L251.884 151.444L245.094 124.295C245.094 124.295 248.489 96.8621 246.226 89.2263L245.901 79.2156C245.901 79.2156 236.606 79.6108 230.665 75.3687Z"
      fill="#2F2E41"
    />
    <path
      d="M206.898 153.707H203.503C202.938 153.707 198.411 157.383 200.391 159.363C202.372 161.343 205.484 165.019 205.484 165.019C205.484 165.019 216.518 166.422 216.801 164.442C217.084 162.463 211.991 158.232 211.991 158.232L210.577 153.707C210.577 153.707 207.747 150.596 206.898 153.707Z"
      fill="#2F2E41"
    />
    <path
      d="M251.884 156.535C251.884 156.535 247.075 156.252 246.226 155.969C245.377 155.686 243.679 163.322 246.226 163.888C248.772 164.454 259.24 165.302 259.24 165.302C259.24 165.302 267.445 165.019 266.879 162.757C266.314 160.494 255.279 155.969 255.279 155.969C255.279 155.969 253.865 153.141 251.884 156.535Z"
      fill="#2F2E41"
    />
    <path
      d="M229.533 44.2596L228.118 43.4112L226.421 44.8252C226.421 44.8252 231.514 48.2189 230.382 51.3298C229.25 54.4407 224.44 60.9453 224.44 60.9453C224.44 60.9453 228.684 65.7531 228.684 67.4499C228.684 67.4499 239.719 63.4906 247.923 67.4499C247.923 67.4499 247.923 61.7937 249.055 60.6625C250.187 59.5313 250.753 55.572 247.923 52.1783C245.93 49.6811 244.133 47.0336 242.548 44.2596L239.719 42.5627L238.261 43.0248C238.261 43.0248 241.133 46.2393 238.304 46.5221C235.475 46.8049 230.948 45.9564 229.533 44.2596Z"
      fill={fill}
    />
    <path
      d="M240.171 8.20142C239.21 9.27392 237.893 9.96326 236.464 10.1426C233.992 10.4955 235.934 14.3778 235.934 14.3778C235.934 14.3778 235.228 12.9661 236.64 11.7308C238.053 10.4955 240.171 9.78964 240.171 8.20142Z"
      fill="#2F2E41"
    />
    <path
      d="M235.406 18.2358C237.089 18.2358 238.454 16.872 238.454 15.1897C238.454 13.5074 237.089 12.1436 235.406 12.1436C233.723 12.1436 232.359 13.5074 232.359 15.1897C232.359 16.872 233.723 18.2358 235.406 18.2358Z"
      fill="#2F2E41"
    />
    <path
      d="M238.964 18.189C236.61 16.9598 232.56 15.993 230.408 16.0622L230.952 13.9819C232.857 15.2756 236.886 16.2605 239.508 16.1088L238.964 18.189Z"
      fill={fill}
    />
    <path
      d="M234.1 18.3721C234.1 18.3721 230.39 23.1399 222.971 24.1994C215.551 25.2589 221.381 36.9136 221.381 36.9136C221.381 36.9136 219.261 32.6755 223.501 28.9672C227.741 25.2589 234.1 23.1399 234.1 18.3721Z"
      fill="#2F2E41"
    />
    <path
      d="M234.383 18.0894C234.383 18.0894 236.503 25.5059 239.683 26.5655C242.863 27.625 241.273 38.7499 235.973 39.8094C235.973 39.8094 240.478 33.7172 237.828 28.9494C235.178 24.1816 233.588 20.4733 234.383 18.0894Z"
      fill="#2F2E41"
    />
    <path d="M284.28 5.65625H264.758V7.91872H284.28V5.65625Z" fill="#2F2E41" />
    <path
      d="M284.28 13.5748C285.217 13.5748 285.977 10.536 285.977 6.78742C285.977 3.03883 285.217 0 284.28 0C283.342 0 282.582 3.03883 282.582 6.78742C282.582 10.536 283.342 13.5748 284.28 13.5748Z"
      fill="#2F2E41"
    />
    <path
      d="M264.757 13.5748C265.695 13.5748 266.455 10.536 266.455 6.78742C266.455 3.03883 265.695 0 264.757 0C263.82 0 263.06 3.03883 263.06 6.78742C263.06 10.536 263.82 13.5748 264.757 13.5748Z"
      fill="#2F2E41"
    />
    <path d="M294.182 157.813H274.66V160.075H294.182V157.813Z" fill="#2F2E41" />
    <path
      d="M294.182 165.732C295.119 165.732 295.88 162.693 295.88 158.944C295.88 155.196 295.119 152.157 294.182 152.157C293.244 152.157 292.484 155.196 292.484 158.944C292.484 162.693 293.244 165.732 294.182 165.732Z"
      fill="#2F2E41"
    />
    <path
      d="M274.66 165.732C275.598 165.732 276.358 162.693 276.358 158.944C276.358 155.196 275.598 152.157 274.66 152.157C273.722 152.157 272.962 155.196 272.962 158.944C272.962 162.693 273.722 165.732 274.66 165.732Z"
      fill="#2F2E41"
    />
    <path
      opacity="0.3"
      d="M69.1574 155.98C69.1574 147.645 63.9954 140.889 57.6276 140.889C53.6253 140.889 50.1004 143.559 48.033 147.612C47.3231 147.372 46.5789 147.25 45.8296 147.249C43.8919 147.282 42.042 148.063 40.6668 149.428C38.8033 144.375 34.8516 140.889 30.2777 140.889C26.0012 140.889 22.2712 143.939 20.2807 148.465C19.1132 147.682 17.7408 147.259 16.3346 147.249C11.4478 147.249 7.48615 152.434 7.48615 158.83C7.47184 160.988 7.94855 163.12 8.88022 165.066H66.8322C68.3862 162.291 69.1877 159.159 69.1574 155.98Z"
      fill={fill}
    />

    <defs>
      <clipPath id="clip0">
        <rect width="385" height="210" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WorkingOut;
