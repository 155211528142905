import clsx from "clsx";
import React from "react";
import { StaticDatePicker, PickersDay } from "@mui/x-date-pickers";
import makeStyles from "@mui/styles/makeStyles";
import dayjs, { Dayjs } from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPickersCalendar-root": {
      minHeight: 192,
    },
    "& .MuiPickersDay-root": {
      fontWeight: "bold",
      fontSize: 16,
    },
  },
  highlightedDay: {
    "&::before": {
      content: "''",
      display: "inline-block",
      position: "absolute",
      top: "4px",
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

export interface DatePickerProps {
  className?: string;
  value?: Date | Dayjs;
  onChange?: (date: any) => void;
  minDate?: Date | Dayjs;
  maxDate?: Date | Dayjs;
  highlightDays?: Date[];
}

export function DatePicker(props: DatePickerProps) {
  const {
    className,
    value,
    onChange,
    minDate,
    maxDate,
    highlightDays = [],
    ...other
  } = props;
  const s = useStyles();

  function CustomDay(ownerState: any) {
    const day = ownerState.day;

    const isHighlighted = highlightDays.some((highlightDay) => {
      return dayjs(day).isSame(dayjs(highlightDay), "day");
    });

    return (
      <PickersDay
        day={day}
        outsideCurrentMonth={false}
        isFirstVisibleCell={false}
        isLastVisibleCell={false}
        className={clsx(ownerState.className, {
          [s.highlightedDay]: isHighlighted,
        })}
        {...ownerState}
      />
    );
  }

  return (
    <StaticDatePicker
      className={clsx(s.root, className)}
      value={dayjs(value)}
      onChange={onChange}
      minDate={minDate && dayjs(minDate)}
      maxDate={maxDate && dayjs(maxDate)}
      slots={{
        day: CustomDay,
        toolbar: undefined,
      }}
      slotProps={{
        actionBar: { actions: undefined },
        day: (ownerState) =>
          ({
            ownerState,
            highlightDays,
          }) as any,
      }}
      {...other}
    />
  );
}
