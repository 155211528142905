/**
 * @generated SignedSource<<d0a8e8ace2ce24e4d2ca04c3c6f9885e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WeekComponentMenu_component$data = {
  readonly id: string;
  readonly locked: boolean;
  readonly previousStatus: ComponentStatus | null | undefined;
  readonly status: ComponentStatus;
  readonly type: ComponentType;
  readonly weekId: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeComponentCoverDialog_component" | "CopyComponentDialog_component" | "SelectComponentIcons_component">;
  readonly " $fragmentType": "WeekComponentMenu_component";
};
export type WeekComponentMenu_component$key = {
  readonly " $data"?: WeekComponentMenu_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekComponentMenu_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekComponentMenu_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previousStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locked",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectComponentIcons_component"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CopyComponentDialog_component"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "draft",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "ChangeComponentCoverDialog_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "6966a1278df1c473eadb6a67f131b44f";

export default node;
