/**
 * @generated SignedSource<<7241d778039e3102fa0901b5a1c44da1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateClientFormInput = {
  clientMutationId?: string | null | undefined;
};
export type CreateClientFormMutation$variables = {
  input: CreateClientFormInput;
};
export type CreateClientFormMutation$data = {
  readonly createClientForm: {
    readonly clientForm: {
      readonly slug: string;
    };
  } | null | undefined;
};
export type CreateClientFormMutation = {
  response: CreateClientFormMutation$data;
  variables: CreateClientFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClientFormPayload",
        "kind": "LinkedField",
        "name": "createClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientForm",
            "kind": "LinkedField",
            "name": "clientForm",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClientFormPayload",
        "kind": "LinkedField",
        "name": "createClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientForm",
            "kind": "LinkedField",
            "name": "clientForm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c49fb2afcb02d998554802841e790203",
    "id": null,
    "metadata": {},
    "name": "CreateClientFormMutation",
    "operationKind": "mutation",
    "text": "mutation CreateClientFormMutation(\n  $input: CreateClientFormInput!\n) {\n  createClientForm(input: $input) {\n    clientForm {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "837d6e42dfd9c7e6bb233040454ad1e0";

export default node;
