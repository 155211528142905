import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { FeedbackForm } from "../form/FeedbackForm";
import { UserRole } from "../../constants";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { ActivityFeedback } from "./ActivityFeedback";
import { ActivityFeedbackListAnswersReadMutation } from "./__generated__/ActivityFeedbackListAnswersReadMutation.graphql";
import { ActivityFeedbackList_activity$key } from "./__generated__/ActivityFeedbackList_activity.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0),
    paddingBottom: theme.spacing(2.5),
  },

  feedback: {
    marginLeft: 0,
    "&:not(:nth-child(1))": {
      borderTop: `1px solid ${theme.palette.quote}`,
    },
  },

  form: {
    flexDirection: "column",
    marginTop: theme.spacing(2.25),

    "& [data-slate-editor]": {
      "& .MuiTypography-root": {
        minHeight: theme.spacing(14),
      },

      "&::before": {
        left: theme.spacing(1.75),
        top: theme.spacing(1.75),
      },
    },
  },

  button: {
    width: "100%",
    backgroundColor: theme.palette.common.black,
    marginLeft: 0,
    marginTop: theme.spacing(2),
  },
}));

const activityAnswersReadMutation = graphql`
  mutation ActivityFeedbackListAnswersReadMutation(
    $input: ActivityAnswersReadInput!
  ) {
    activityAnswersRead(input: $input) {
      activity {
        ...ActivityResponseCard_activity
      }
    }
  }
`;

const activityFragment = graphql`
  fragment ActivityFeedbackList_activity on Activity {
    feedbacks {
      id
      ...ActivityFeedback_activityFeedback
    }
    ...FeedbackForm_activity
    id
    coachReadAnswers
  }
`;

export interface ActivityFeedbackListProps extends BoxProps {
  activity: ActivityFeedbackList_activity$key;
}

export function ActivityFeedbackList(props: ActivityFeedbackListProps) {
  const { className, activity: activityRef, ...other } = props;
  const activity = useFragment(activityFragment, activityRef);
  const s = useStyles();
  const formRef = React.useRef<HTMLFormElement>();
  const onError = useGenericErrorHandler();
  const user = useCurrentUser();
  const [read, readInFlight] =
    useMutation<ActivityFeedbackListAnswersReadMutation>(
      activityAnswersReadMutation,
    );

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }
  }, [activity.feedbacks.length]);

  React.useEffect(() => {
    if (
      user.role === UserRole.COACH &&
      !activity.coachReadAnswers &&
      !readInFlight
    ) {
      read({
        variables: {
          input: { id: activity.id },
        },
        onCompleted(_, errors) {
          if (errors && errors?.length) {
            onError(errors[0]);
          }
        },
        updater(store) {
          const user = store.getRoot().getLinkedRecord("me");

          if (user) {
            const unreadCount = user.getValue(
              "unreadActivitiesCount",
            ) as number;

            user.setValue(
              Math.max(unreadCount - 1, 0),
              "unreadActivitiesCount",
            );
          }
        },
      });
    }
  }, [
    read,
    readInFlight,
    activity.id,
    activity.coachReadAnswers,
    user.role,
    onError,
  ]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {activity.feedbacks.map((feedback) => (
        <ActivityFeedback
          className={s.feedback}
          key={feedback.id}
          fragmentRef={feedback}
        />
      ))}
      <FeedbackForm className={s.form} activity={activity} formRef={formRef} />
    </Box>
  );
}
