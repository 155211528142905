/**
 * @generated SignedSource<<df0bba858c63be0d8307050959480e56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletionPeriodType = "ALL_TIME" | "BEFORE_NEXT_WEEK" | "BEFORE_NOW" | "LAST_30_DAYS" | "LAST_7_DAYS" | "LAST_WEEK" | "THIS_WEEK" | "%future added value";
export type MeasurementType = "BODYFAT" | "BODYWEIGHT" | "CALF" | "CHEST" | "HIP" | "SHOULDER" | "STEP_COUNT" | "THIGH" | "UPPER_ARM" | "WAIST" | "%future added value";
export type ClientMeasurementsCartDrawerCoachRefetchQuery$variables = {
  id: string;
  includeMeasurement: boolean;
  includeNutrition: boolean;
  measurementType?: MeasurementType | null | undefined;
  period?: CompletionPeriodType | null | undefined;
};
export type ClientMeasurementsCartDrawerCoachRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementsCartDrawerCoach_client">;
  } | null | undefined;
};
export type ClientMeasurementsCartDrawerCoachRefetchQuery = {
  response: ClientMeasurementsCartDrawerCoachRefetchQuery$data;
  variables: ClientMeasurementsCartDrawerCoachRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeMeasurement"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeNutrition"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "measurementType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "period"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "measurementType",
  "variableName": "measurementType"
},
v7 = {
  "kind": "Variable",
  "name": "period",
  "variableName": "period"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carbsGrams",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proteinGrams",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fatGrams",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calories",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientMeasurementsCartDrawerCoachRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "includeMeasurement",
                "variableName": "includeMeasurement"
              },
              {
                "kind": "Variable",
                "name": "includeNutrition",
                "variableName": "includeNutrition"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ClientMeasurementsCartDrawerCoach_client"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientMeasurementsCartDrawerCoachRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientNutritionTarget",
                "kind": "LinkedField",
                "name": "nutritionTarget",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trackingType",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "condition": "includeNutrition",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v7/*: any*/)
                    ],
                    "concreteType": "ClientNutritionEntryConnection",
                    "kind": "LinkedField",
                    "name": "clientNutritionEntries",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClientNutritionEntryEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClientNutritionEntry",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "notes",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "date",
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ClientNutritionTarget",
                                "kind": "LinkedField",
                                "name": "target",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "includeMeasurement",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v6/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "metricType",
                        "value": "CHECKIN_ANSWER_MEASUREMENT"
                      }
                    ],
                    "concreteType": "ClientMetric",
                    "kind": "LinkedField",
                    "name": "metrics",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "raw",
                            "value": true
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "activityDate",
                        "storageKey": "activityDate(raw:true)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "measurement",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unit",
                                "storageKey": null
                              }
                            ],
                            "type": "CheckinAnswerMeasurementValue",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eec21971e55c423148aecd89aa35dadc",
    "id": null,
    "metadata": {},
    "name": "ClientMeasurementsCartDrawerCoachRefetchQuery",
    "operationKind": "query",
    "text": "query ClientMeasurementsCartDrawerCoachRefetchQuery(\n  $includeMeasurement: Boolean!\n  $includeNutrition: Boolean!\n  $measurementType: MeasurementType\n  $period: CompletionPeriodType\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ClientMeasurementsCartDrawerCoach_client_1TZTQz\n    id\n  }\n}\n\nfragment ClientMeasurementsCartDrawerCoach_client_1TZTQz on User {\n  id\n  username\n  units\n  nutritionTarget {\n    trackingType\n    id\n  }\n  clientNutritionEntries(period: $period) @include(if: $includeNutrition) {\n    edges {\n      node {\n        ...ClientNutritionResults_nutritionEntries\n        id\n      }\n    }\n  }\n  metrics(metricType: CHECKIN_ANSWER_MEASUREMENT, measurementType: $measurementType) @include(if: $includeMeasurement) {\n    ...ClientMeasurementsCartDrawerEditView_metrics\n    id\n  }\n}\n\nfragment ClientMeasurementsCartDrawerEditView_metrics on ClientMetric {\n  id\n  activityDate(raw: true)\n  name\n  value {\n    __typename\n    ... on CheckinAnswerMeasurementValue {\n      measurement\n      unit\n    }\n  }\n}\n\nfragment ClientNutritionNotesDialog_nutritionEntry on ClientNutritionEntry {\n  notes\n  date\n}\n\nfragment ClientNutritionResultsWeek_nutritionEntries on ClientNutritionEntry {\n  ...ClientNutritionNotesDialog_nutritionEntry\n  id\n  date\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  notes\n  target {\n    calories\n    carbsGrams\n    proteinGrams\n    fatGrams\n    id\n  }\n}\n\nfragment ClientNutritionResults_nutritionEntries on ClientNutritionEntry {\n  ...ClientNutritionResultsWeek_nutritionEntries\n  id\n  date\n}\n"
  }
};
})();

(node as any).hash = "c6def758b52c0fe3d7995e272e25858b";

export default node;
