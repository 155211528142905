/**
 * @generated SignedSource<<9409e3f794607d1964cc50e20564087b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Likeable_likeable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LikesButton_likeable" | "LikesList_likeable">;
  readonly " $fragmentType": "Likeable_likeable";
};
export type Likeable_likeable$key = {
  readonly " $data"?: Likeable_likeable$data;
  readonly " $fragmentSpreads": FragmentRefs<"Likeable_likeable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Likeable_likeable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LikesList_likeable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LikesButton_likeable"
    }
  ],
  "type": "Likeable",
  "abstractKey": "__isLikeable"
};

(node as any).hash = "37f2e95dae087907cbce24946781fc56";

export default node;
