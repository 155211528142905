/**
 * @generated SignedSource<<24db51a4c7b05635fbac4878fd1bf3c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientHomeScreen_enrollments$data = {
  readonly totalCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"Calendar_enrollments">;
  readonly " $fragmentType": "ClientHomeScreen_enrollments";
};
export type ClientHomeScreen_enrollments$key = {
  readonly " $data"?: ClientHomeScreen_enrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientHomeScreen_enrollments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientHomeScreen_enrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Calendar_enrollments"
    }
  ],
  "type": "EnrollmentConnection",
  "abstractKey": null
};

(node as any).hash = "3f5827f8db961908e95285dd254b8451";

export default node;
