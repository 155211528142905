import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { StartWorkout } from "../../../../../components/startWorkout/StartWorkout";
import { useParams } from "react-router-dom";
import { ClientProgramActivityStartRouteQuery } from "./__generated__/ClientProgramActivityStartRouteQuery.graphql";

export function ClientProgramActivityStartRoute() {
  const params = useParams();
  const { date, component } = params;

  const props = useLazyLoadQuery<ClientProgramActivityStartRouteQuery>(
    graphql`
      query ClientProgramActivityStartRouteQuery(
        $date: String!
        $component: String!
      ) {
        activity(client: "$me", date: $date, component: $component) {
          ...StartWorkout_activity
        }
      }
    `,

    {
      date: date,
      component: component,
      shouldFetchSummary: true,
    },
  );

  const { activity } = props;
  return activity && <StartWorkout activityRef={activity} />;
}
