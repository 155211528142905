import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import CoachClientsArrowsSort from "./CoachClientsArrowsSort";
import { ClientSort } from "../../constants";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import CoachClientsSortHeaderTagMenu from "./CoachClientsSortHeaderTagMenu";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 14,
    display: "flex",
    justifyContent: "flex-start",
  },
  buttonsContainer: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      paddingLeft: 0,
    },
  },
  nameButton: {
    width: "28%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  tagButton: {
    width: "17%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  programButton: {
    width: "55%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

type TagsProps = {
  node: {
    title: string;
    id: string;
  };
};

type CoachClientsSortHeaderProps = {
  sortKey: string;
  handleChangeSortNameProgram: (value: boolean) => void;
  tagList: TagsProps[];
  onChangeFilterTagId: (tagId: string) => void;
  filterTag?: string;
  disabled: boolean;
};

const CoachClientsSortHeader = (props: CoachClientsSortHeaderProps) => {
  const {
    sortKey,
    handleChangeSortNameProgram,
    tagList,
    onChangeFilterTagId,
    filterTag,
    disabled,
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const xsDown = useMediaQuery(breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);
  return (
    <Box className={s.buttonsContainer}>
      <Box className={s.nameButton}>
        <Button
          className={clsx(s.button)}
          children="Name"
          endIcon={
            <CoachClientsArrowsSort
              activeUp={sortKey === ClientSort.NAME_ASC}
              activeDown={sortKey === ClientSort.NAME_DESC}
            />
          }
          onClick={() => handleChangeSortNameProgram(true)}
        />
      </Box>
      {!disabled && (
        <>
          <Box className={s.tagButton}>
            <CoachClientsSortHeaderTagMenu
              open={open}
              setOpen={setOpen}
              tagList={tagList}
              onChangeFilterTagId={onChangeFilterTagId}
              filterTag={filterTag}
            />
          </Box>

          <Box className={s.programButton}>
            <Button
              className={clsx(s.button)}
              children={!xsDown ? "Due date programs" : "programs"}
              endIcon={
                <CoachClientsArrowsSort
                  activeUp={sortKey === ClientSort.PROGRAM_END_ASC}
                  activeDown={sortKey === ClientSort.PROGRAM_END_DESC}
                />
              }
              onClick={() => handleChangeSortNameProgram(false)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CoachClientsSortHeader;
