/**
 * @generated SignedSource<<855ea5056e898fa5c3ecb154b9bc7a63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardProgramTemplate_template$data = {
  readonly category: string | null | undefined;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly imageURL: string | null | undefined;
  readonly name: string;
  readonly programLength: number;
  readonly used: number;
  readonly " $fragmentType": "CardProgramTemplate_template";
};
export type CardProgramTemplate_template$key = {
  readonly " $data"?: CardProgramTemplate_template$data;
  readonly " $fragmentSpreads": FragmentRefs<"CardProgramTemplate_template">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CardProgramTemplate_template",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": "programLength",
      "args": null,
      "kind": "ScalarField",
      "name": "length",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "used",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    }
  ],
  "type": "ProgramTemplate",
  "abstractKey": null
};

(node as any).hash = "0c204e8c59fdf53a0aa57ac31c42576e";

export default node;
