/**
 * @generated SignedSource<<b793924407cc0ab207682233409f1a14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientsFormScreen_root$data = {
  readonly clientForm: {
    readonly clients: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly displayName: string | null | undefined;
          readonly email: string | null | undefined;
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ClientAddableItem_client">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClientForm_clientForm" | "RefreshSlug">;
  } | null | undefined;
  readonly clients: {
    readonly " $fragmentSpreads": FragmentRefs<"SelectAddableClientsDialog_clients">;
  } | null | undefined;
  readonly " $fragmentType": "CoachClientsFormScreen_root";
};
export type CoachClientsFormScreen_root$key = {
  readonly " $data"?: CoachClientsFormScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsFormScreen_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientsFormScreen_root",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "concreteType": "ClientForm",
      "kind": "LinkedField",
      "name": "clientForm",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefreshSlug"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientForm_clientForm"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ClientAddableItem_client"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 999
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "ACTIVE"
        }
      ],
      "concreteType": "ClientConnection",
      "kind": "LinkedField",
      "name": "clients",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SelectAddableClientsDialog_clients"
        }
      ],
      "storageKey": "clients(first:999,status:\"ACTIVE\")"
    }
  ],
  "type": "Root",
  "abstractKey": null
};
})();

(node as any).hash = "1d9b914ee1ecdb62e61291c48cef4784";

export default node;
