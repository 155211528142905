/**
 * @generated SignedSource<<f66bb6cf6b99cd60a5033dea581c3b1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignUpCompleteRouteQuery$variables = Record<PropertyKey, never>;
export type SignUpCompleteRouteQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"SignupClientCompleteScreen_user">;
  } | null | undefined;
  readonly workspace: {
    readonly " $fragmentSpreads": FragmentRefs<"SignupClientCompleteScreen_workspace">;
  };
};
export type SignUpCompleteRouteQuery = {
  response: SignUpCompleteRouteQuery$data;
  variables: SignUpCompleteRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignUpCompleteRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SignupClientCompleteScreen_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SignupClientCompleteScreen_workspace"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignUpCompleteRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "units",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          },
          {
            "alias": "rawDisplayName",
            "args": [
              {
                "kind": "Literal",
                "name": "raw",
                "value": true
              }
            ],
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": "displayName(raw:true)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeZone",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bfcd607c8960c1ea08a9445da35ad7aa",
    "id": null,
    "metadata": {},
    "name": "SignUpCompleteRouteQuery",
    "operationKind": "query",
    "text": "query SignUpCompleteRouteQuery {\n  me {\n    ...SignupClientCompleteScreen_user\n    id\n  }\n  workspace {\n    ...SignupClientCompleteScreen_workspace\n    id\n  }\n}\n\nfragment ClientSettingsForm_settings on User {\n  email\n  photoURL\n  gender\n  location\n  birthday\n  units\n  height\n  weight\n  rawDisplayName: displayName(raw: true)\n  username\n  timeZone\n}\n\nfragment SignupClientCompleteScreen_user on User {\n  ...ClientSettingsForm_settings\n  id\n  displayName\n  username\n  photoURL\n}\n\nfragment SignupClientCompleteScreen_workspace on Workspace {\n  name\n}\n"
  }
};
})();

(node as any).hash = "eaf7b308ad5ffc916c91d5f9d2772ee6";

export default node;
