import { Environment, RecordSource, Store } from "relay-runtime";
import {
  RelayNetworkLayer,
  retryMiddleware,
  batchMiddleware,
  authMiddleware,
  urlMiddleware,
} from "react-relay-network-modern";

import { authStorage } from "./utils/auth";
import { androidMobileApp } from "./utils/mobile";
import { updateNetworkStatus } from "./hooks/useNetworkStatus";

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || "/graphql";
const corsOpts = {
  mode: "cors",
  credentials: null,
  headers: {
    "Access-Control-Max-Age": "86400",
  },
} as const;

export function createRelay() {
  const recordSource = new RecordSource();
  const store = new Store(recordSource);
  const network = new RelayNetworkLayer(
    [
      urlMiddleware({
        url: GRAPHQL_ENDPOINT,
        ...corsOpts,
      }),
      batchMiddleware({
        batchUrl: GRAPHQL_ENDPOINT + "/batch",
        batchTimeout: 240,
        ...corsOpts,
      }),
      retryMiddleware({
        fetchTimeout: 240000,
        retryDelays: (attempt) => Math.pow(2, attempt + 4) * 2400,
        beforeRetry: () => {
          updateNetworkStatus({
            disconnected: true,
            connecting: true,
          });
        },
        statusCodes: [500, 503, 504],
      }),
      (next) => (req) => {
        updateNetworkStatus({
          disconnected: false,
          connecting: false,
        });

        return next(req).then((response: any) => {
          const authToken = (response.headers as any).get("x-auth-token");

          // if (response?.errors) {
          //   response?.errors[0]?.code === 402 &&
          //     window.location.replace("/coach/plan");
          // } else {
          //   console.log(response)
          //   response?.json.find(({ payload }) => {
          //     if (payload?.errors) {
          //       return (
          //         payload?.errors[0].code === 402 &&
          //         window.location.replace("/coach/plan")
          //       );
          //     }
          //   });
          // }

          if (authToken) {
            authStorage.setToken(authToken);
          }

          return response;
        });
      },
      authMiddleware({
        token: () => authStorage.getToken(),
      }),
    ],
    { noThrow: true },
  );
  const relay = new Environment({ store, network });

  return relay;
}
