/**
 * @generated SignedSource<<0d32fd9a87d1c4d869829aab6d13b022>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientSmallProgramCard_enrollment$data = {
  readonly client: {
    readonly username: string;
  };
  readonly program: {
    readonly imageURL: string | null | undefined;
    readonly name: string;
    readonly slug: string;
  };
  readonly " $fragmentType": "ClientSmallProgramCard_enrollment";
};
export type ClientSmallProgramCard_enrollment$key = {
  readonly " $data"?: ClientSmallProgramCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSmallProgramCard_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSmallProgramCard_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "a3f2121033f521701cc50b35e9578d39";

export default node;
