import React from "react";

const NoTemplates = ({ fill, className }) => (
  <svg
    className={className}
    width="336"
    height="222"
    viewBox="0 0 336 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M141.024 82.2166L135.138 78.538L138.755 69.3562C139.103 68.474 139.697 67.7104 140.466 67.1564C141.235 66.6023 142.148 66.2813 143.095 66.2314C144.041 66.1816 144.982 66.4048 145.805 66.8744C146.627 67.344 147.298 68.0402 147.737 68.8802C148.175 69.7201 148.363 70.6684 148.278 71.612C148.193 72.5557 147.838 73.4551 147.257 74.203L141.024 82.2166Z"
      fill="#FEB8B8"
    />
    <path
      d="M110.062 181.699L101.958 178.185L106.355 169.135L112.9 172.965L110.062 181.699Z"
      fill="#FEB8B8"
    />
    <path
      d="M106.916 196.167L97.595 186.661C96.7055 185.759 96.2085 184.543 96.2127 183.276C96.2168 182.01 96.7217 180.797 97.6172 179.901L102.31 175.208C102.479 175.038 102.686 174.91 102.914 174.834C103.142 174.758 103.385 174.737 103.622 174.773C103.86 174.808 104.086 174.899 104.282 175.038C104.477 175.177 104.638 175.36 104.75 175.573C105.905 177.818 107.479 179.029 109.517 179.091C110.738 179.128 110.931 179.082 111.249 180.262L110.857 189.155L112.469 190.99C113.074 191.678 113.409 192.561 113.414 193.477C113.419 194.393 113.093 195.279 112.495 195.974C112.153 196.371 111.732 196.693 111.26 196.92C110.787 197.147 110.272 197.274 109.748 197.292C109.224 197.31 108.702 197.22 108.214 197.026C107.727 196.832 107.285 196.54 106.916 196.167Z"
      fill="#2F2E41"
    />
    <path
      d="M113.434 174.552L104.973 170.505L111.963 126.362L77.7948 129.209C66.9918 130.109 58.0747 120.448 60.2643 109.831C60.9018 106.618 62.6683 103.739 65.2442 101.715L99.0875 105.761L121.016 108.776C123.216 109.079 125.271 110.049 126.903 111.556C128.536 113.062 129.667 115.033 130.144 117.202C130.525 118.931 130.477 120.726 130.006 122.432L113.434 174.552Z"
      fill="#2F2E41"
    />
    <path
      d="M54.76 221.821C84.219 221.821 108.1 197.94 108.1 168.481C108.1 139.022 84.219 115.141 54.76 115.141C25.3011 115.141 1.41992 139.022 1.41992 168.481C1.41992 197.94 25.3011 221.821 54.76 221.821Z"
      fill="#E6E6E6"
    />
    <path
      d="M135.506 76.3312L143.967 80.3777L130.506 102.491C129.917 103.46 129.054 104.233 128.027 104.714C127 105.194 125.854 105.361 124.732 105.193C123.661 105.032 122.658 104.573 121.836 103.868C121.014 103.163 120.408 102.241 120.087 101.207L105.05 72.4469C104.539 71.4685 104.244 70.3915 104.186 69.2891C104.128 68.1866 104.309 67.0846 104.715 66.0581C105.121 65.0315 105.743 64.1045 106.54 63.3401C107.336 62.5756 108.288 61.9917 109.33 61.6281H109.33C110.297 61.2911 111.32 61.1504 112.341 61.2142C113.363 61.278 114.361 61.5451 115.278 61.9998C116.194 62.4545 117.011 63.0876 117.68 63.8621C118.348 64.6365 118.856 65.5367 119.172 66.5099L126.309 88.4707L135.506 76.3312Z"
      fill="#3F3D56"
    />
    <path
      d="M98.5037 65.5449C101.266 65.9277 104.103 65.0172 106.408 63.4476C108.714 61.8781 110.535 59.6905 112.077 57.3669C114.683 53.4364 116.562 49.0701 117.625 44.4757C118.504 45.5316 119.653 46.3281 120.951 46.7795C122.248 47.2309 123.644 47.3201 124.988 47.0375C129.374 46.0528 132.005 40.9353 131.149 36.5222C130.294 32.1091 126.673 28.6104 122.542 26.8392C118.41 25.068 113.806 24.7754 109.31 24.7854C106.346 24.792 103.325 24.9302 100.541 25.9469C97.7566 26.9635 95.2058 28.9897 94.2166 31.7839C93.5965 33.5357 93.6167 35.4747 92.9116 37.1941C91.6315 40.3155 88.3113 42.0201 85.1954 43.3134C82.0794 44.6067 78.6919 45.9202 76.8544 48.7495C74.7793 51.9446 75.3782 56.4038 77.6649 59.451C79.9515 62.4982 83.6241 64.2651 87.3615 65.0039C91.099 65.7428 94.9494 65.5601 98.7546 65.3743"
      fill="#2F2E41"
    />
    <path
      d="M97.1868 181.699L89.0831 178.185L93.4802 169.135L100.025 172.965L97.1868 181.699Z"
      fill="#FEB8B8"
    />
    <path
      d="M94.0412 196.167L84.7199 186.661C83.8303 185.759 83.3334 184.543 83.3376 183.276C83.3417 182.01 83.8466 180.797 84.7421 179.901L89.4349 175.208C89.6041 175.038 89.811 174.91 90.039 174.834C90.2669 174.758 90.5095 174.737 90.7471 174.773C90.9846 174.808 91.2105 174.899 91.4064 175.038C91.6023 175.177 91.7627 175.36 91.8747 175.573C93.0299 177.818 94.6035 179.029 96.6416 179.091C97.8633 179.128 98.0558 179.082 98.374 180.262L97.9816 189.155L99.5941 190.99C100.199 191.678 100.534 192.561 100.539 193.477C100.544 194.393 100.217 195.279 99.6201 195.974C99.2781 196.371 98.8574 196.693 98.3846 196.92C97.9118 197.147 97.3971 197.274 96.873 197.292C96.3489 197.31 95.8267 197.22 95.3393 197.026C94.8519 196.832 94.4099 196.54 94.0412 196.167Z"
      fill="#2F2E41"
    />
    <path
      d="M99.4554 176.759L90.9946 172.712L97.984 128.569L63.816 131.416C53.013 132.316 44.0959 122.655 46.2856 112.038C46.9231 108.825 48.6895 105.946 51.2654 103.922L85.1088 107.968L107.037 110.983C109.238 111.286 111.292 112.256 112.925 113.763C114.557 115.269 115.688 117.24 116.166 119.409C116.546 121.138 116.498 122.933 116.027 124.639L99.4554 176.759Z"
      fill="#2F2E41"
    />
    <path
      d="M111.779 56.0981C119.194 56.0981 125.206 50.0867 125.206 42.6711C125.206 35.2556 119.194 29.2441 111.779 29.2441C104.363 29.2441 98.3518 35.2556 98.3518 42.6711C98.3518 50.0867 104.363 56.0981 111.779 56.0981Z"
      fill="#FEB8B8"
    />
    <path
      d="M102.398 74.1237L96.8804 59.0414C100.798 54.8838 101.661 49.7819 100.191 43.959L116.009 52.7877L102.398 74.1237Z"
      fill="#FEB8B8"
    />
    <path
      d="M91.3624 109.806C81.5412 113.084 68.544 112.012 52.259 106.436C51.8189 106.283 51.4227 106.026 51.1048 105.685C50.7869 105.345 50.5568 104.932 50.4347 104.483C50.3126 104.033 50.302 103.561 50.404 103.106C50.5059 102.652 50.7172 102.229 51.0196 101.875C62.0034 88.964 71.858 76.3192 79.9904 64.0798C83.0594 60.1117 87.4253 57.3485 92.3251 56.2729L99.8232 54.627L111.227 60.5128L114.216 62.202C114.92 62.6004 115.537 63.1381 116.027 63.7824C116.518 64.4266 116.872 65.164 117.068 65.9495C117.264 66.735 117.298 67.5522 117.169 68.3514C117.039 69.1506 116.748 69.915 116.313 70.5981C111.734 81.786 105.765 92 95.4089 99.1384L91.3624 109.806Z"
      fill="#3F3D56"
    />
    <path
      d="M112.09 40.0588C113.74 42.6841 116.193 44.7079 119.083 45.8302C121.131 46.6186 123.729 46.8145 125.265 45.2487C126.379 44.1143 126.6 42.3686 126.442 40.7872C125.949 35.8511 122.24 31.5528 117.637 29.7033C113.034 27.8538 107.72 28.2581 103.126 30.1305C98.7919 31.8972 94.6757 35.5165 94.4426 40.1911C94.3231 42.5867 95.1905 45.2246 93.9045 47.2493C92.936 48.7742 91.0653 49.3938 89.3898 50.0691C87.7143 50.7444 85.916 51.8099 85.572 53.5834C85.3383 54.7889 85.8781 56.0471 86.7258 56.9356C87.6167 57.7863 88.671 58.4473 89.8248 58.8786C92.5573 60.0422 95.5652 60.7675 98.5008 60.3177C101.436 59.8679 104.275 58.0802 105.424 55.3415C106.309 53.2325 106.127 50.8526 106.025 48.5679C105.923 46.2831 105.954 43.8498 107.174 41.9153C108.394 39.9808 111.226 38.8977 113.049 40.2787"
      fill="#2F2E41"
    />
    <path
      opacity="0.2"
      d="M79.4069 78.7227C79.4069 78.7227 90.0749 105.209 92.6499 106.68C95.225 108.152 95.225 104.476 95.225 104.476L79.4069 78.7227Z"
      fill="black"
    />
    <path
      d="M163.141 70.0881L98.3927 80.6211C98.0747 80.6725 97.7494 80.5956 97.488 80.4074C97.2267 80.2192 97.0507 79.935 96.9986 79.6172L94.3858 63.5559C94.3345 63.238 94.4113 62.9127 94.5995 62.6513C94.7878 62.39 95.0719 62.2139 95.3898 62.1619L160.138 51.6288C160.456 51.5775 160.781 51.6544 161.042 51.8426C161.304 52.0308 161.48 52.315 161.532 52.6328L164.145 68.694C164.196 69.012 164.119 69.3373 163.931 69.5987C163.743 69.86 163.459 70.036 163.141 70.0881Z"
      fill={fill}
    />
    <path
      d="M105.285 75.1281C108.102 75.1281 110.387 72.8438 110.387 70.026C110.387 67.2081 108.102 64.9238 105.285 64.9238C102.467 64.9238 100.182 67.2081 100.182 70.026C100.182 72.8438 102.467 75.1281 105.285 75.1281Z"
      fill="white"
    />
    <path
      d="M116.369 64.776C116.259 64.7938 116.153 64.8332 116.058 64.8918C115.963 64.9505 115.88 65.0274 115.815 65.1181C115.749 65.2087 115.702 65.3114 115.677 65.4202C115.651 65.529 115.647 65.6417 115.665 65.7521C115.683 65.8624 115.722 65.9681 115.781 66.0631C115.84 66.1581 115.917 66.2407 116.008 66.3059C116.099 66.3712 116.201 66.4179 116.31 66.4434C116.419 66.4689 116.532 66.4727 116.642 66.4546L156.21 60.0178C156.32 59.9999 156.426 59.9604 156.521 59.9017C156.616 59.8429 156.698 59.766 156.764 59.6754C156.829 59.5848 156.876 59.4822 156.901 59.3735C156.927 59.2648 156.931 59.1522 156.913 59.0419C156.895 58.9317 156.856 58.8261 156.797 58.7311C156.738 58.6362 156.661 58.5537 156.571 58.4884C156.48 58.4232 156.377 58.3764 156.269 58.3508C156.16 58.3252 156.047 58.3212 155.937 58.3392L116.369 64.776Z"
      fill="white"
    />
    <path
      d="M117.188 69.8129C117.078 69.8307 116.972 69.8701 116.877 69.9288C116.782 69.9875 116.699 70.0644 116.634 70.155C116.568 70.2457 116.522 70.3483 116.496 70.4571C116.47 70.5659 116.466 70.6787 116.484 70.789C116.502 70.8993 116.542 71.005 116.6 71.1C116.659 71.1951 116.736 71.2776 116.827 71.3428C116.918 71.4081 117.021 71.4548 117.129 71.4803C117.238 71.5059 117.351 71.5097 117.461 71.4916L157.029 65.0548C157.252 65.0183 157.451 64.8951 157.582 64.7121C157.714 64.5292 157.767 64.3015 157.731 64.0791C157.695 63.8566 157.572 63.6576 157.389 63.5258C157.206 63.394 156.979 63.3402 156.756 63.3761L117.188 69.8129Z"
      fill="white"
    />
    <path
      d="M116.009 88.4705L110.123 84.7919L113.74 75.6101C114.088 74.7279 114.682 73.9643 115.451 73.4103C116.221 72.8562 117.133 72.5352 118.08 72.4853C119.026 72.4355 119.967 72.6587 120.79 73.1283C121.613 73.5979 122.284 74.2941 122.722 75.1341C123.161 75.974 123.348 76.9223 123.263 77.866C123.178 78.8096 122.824 79.709 122.242 80.4569L116.009 88.4705Z"
      fill="#FEB8B8"
    />
    <path
      d="M110.124 82.5851L118.584 86.6316L105.124 108.745C104.534 109.714 103.672 110.487 102.645 110.968C101.617 111.448 100.471 111.615 99.3497 111.446C98.279 111.286 97.2753 110.827 96.4536 110.122C95.632 109.417 95.0257 108.495 94.7042 107.461L79.668 78.7008C79.1565 77.7224 78.8618 76.6454 78.8038 75.543C78.7459 74.4405 78.9261 73.3385 79.3322 72.312C79.7383 71.2854 80.3608 70.3584 81.1573 69.594C81.9538 68.8295 82.9056 68.2456 83.9479 67.882H83.9479C84.9141 67.545 85.9378 67.4043 86.959 67.4681C87.9803 67.5319 88.9785 67.799 89.8952 68.2537C90.8118 68.7084 91.6285 69.3415 92.2973 70.116C92.966 70.8904 93.4734 71.7906 93.7897 72.7638L100.927 94.7246L110.124 82.5851Z"
      fill="#3F3D56"
    />
    <path
      d="M321.648 162.246C316.774 169.136 315.487 208.35 315.2 220.92C315.192 221.203 315.185 221.472 315.181 221.726H302.843C302.692 221.457 302.552 221.192 302.42 220.92C295.107 206.485 299.164 189.177 299.378 188.302C298.822 191.053 301.335 199.029 304.175 206.754C305.907 183.001 320.721 163.445 321.648 162.246Z"
      fill="#E6E6E6"
    />
    <path d="M335.09 220.92L334.943 221.726H0V220.92H335.09Z" fill="#3F3D56" />
    <path
      d="M305.301 72.1641H205.977C205.49 72.1635 205.022 71.9696 204.678 71.6248C204.333 71.2799 204.139 70.8124 204.138 70.3248V45.687C204.139 45.1993 204.333 44.7318 204.678 44.387C205.022 44.0422 205.49 43.8482 205.977 43.8477H305.301C305.788 43.8482 306.256 44.0422 306.601 44.387C306.945 44.7318 307.139 45.1993 307.14 45.687V70.3248C307.139 70.8124 306.945 71.2799 306.601 71.6248C306.256 71.9696 305.788 72.1635 305.301 72.1641ZM205.977 44.5834C205.685 44.5837 205.404 44.7001 205.197 44.907C204.991 45.1139 204.874 45.3944 204.874 45.687V70.3248C204.874 70.6174 204.991 70.8979 205.197 71.1048C205.404 71.3117 205.685 71.4281 205.977 71.4284H305.301C305.593 71.4281 305.874 71.3117 306.081 71.1048C306.288 70.8979 306.404 70.6174 306.404 70.3248V45.687C306.404 45.3944 306.288 45.1139 306.081 44.907C305.874 44.7001 305.593 44.5837 305.301 44.5834H205.977Z"
      fill="#E6E6E6"
    />
    <path
      d="M218.853 65.7315C223.119 65.7315 226.578 62.2728 226.578 58.0064C226.578 53.7399 223.119 50.2812 218.853 50.2812C214.586 50.2812 211.128 53.7399 211.128 58.0064C211.128 62.2728 214.586 65.7315 218.853 65.7315Z"
      fill="#E6E6E6"
    />
    <path
      d="M236.694 52.8574C236.353 52.8574 236.025 52.9931 235.784 53.2345C235.542 53.476 235.407 53.8035 235.407 54.1449C235.407 54.4864 235.542 54.8139 235.784 55.0553C236.025 55.2968 236.353 55.4325 236.694 55.4325H297.392C297.733 55.4325 298.06 55.2968 298.302 55.0553C298.543 54.8139 298.679 54.4864 298.679 54.1449C298.679 53.8035 298.543 53.476 298.302 53.2345C298.06 52.9931 297.733 52.8574 297.392 52.8574H236.694Z"
      fill="#E6E6E6"
    />
    <path
      d="M236.694 60.582C236.353 60.582 236.025 60.7177 235.784 60.9591C235.542 61.2006 235.407 61.5281 235.407 61.8696C235.407 62.211 235.542 62.5385 235.784 62.78C236.025 63.0214 236.353 63.1571 236.694 63.1571H297.392C297.733 63.1571 298.06 63.0214 298.302 62.78C298.543 62.5385 298.679 62.211 298.679 61.8696C298.679 61.5281 298.543 61.2006 298.302 60.9591C298.06 60.7177 297.733 60.582 297.392 60.582H236.694Z"
      fill="#E6E6E6"
    />
    <path
      d="M305.301 113.365H205.977C205.49 113.365 205.022 113.171 204.678 112.826C204.333 112.481 204.139 112.014 204.138 111.526V86.8881C204.139 86.4005 204.333 85.933 204.678 85.5882C205.022 85.2434 205.49 85.0494 205.977 85.0488H305.301C305.788 85.0494 306.256 85.2434 306.601 85.5882C306.945 85.933 307.139 86.4005 307.14 86.8881V111.526C307.139 112.014 306.945 112.481 306.601 112.826C306.256 113.171 305.788 113.365 305.301 113.365ZM205.977 85.7846C205.685 85.7849 205.404 85.9012 205.197 86.1081C204.991 86.315 204.874 86.5956 204.874 86.8881V111.526C204.874 111.819 204.991 112.099 205.197 112.306C205.404 112.513 205.685 112.629 205.977 112.63H305.301C305.593 112.629 305.874 112.513 306.081 112.306C306.288 112.099 306.404 111.819 306.404 111.526V86.8881C306.404 86.5956 306.288 86.315 306.081 86.1081C305.874 85.9012 305.593 85.7849 305.301 85.7846H205.977Z"
      fill="#E6E6E6"
    />
    <path
      d="M218.853 106.933C223.119 106.933 226.578 103.474 226.578 99.2075C226.578 94.9411 223.119 91.4824 218.853 91.4824C214.586 91.4824 211.128 94.9411 211.128 99.2075C211.128 103.474 214.586 106.933 218.853 106.933Z"
      fill="#E6E6E6"
    />
    <path
      d="M236.694 94.0566C236.353 94.0566 236.025 94.1923 235.784 94.4337C235.542 94.6752 235.407 95.0027 235.407 95.3442C235.407 95.6856 235.542 96.0131 235.784 96.2546C236.025 96.496 236.353 96.6317 236.694 96.6317H297.392C297.733 96.6317 298.06 96.496 298.302 96.2546C298.543 96.0131 298.679 95.6856 298.679 95.3442C298.679 95.0027 298.543 94.6752 298.302 94.4337C298.06 94.1923 297.733 94.0566 297.392 94.0566H236.694Z"
      fill="#E6E6E6"
    />
    <path
      d="M236.694 101.783C236.353 101.783 236.025 101.919 235.784 102.16C235.542 102.402 235.407 102.729 235.407 103.071C235.407 103.412 235.542 103.74 235.784 103.981C236.025 104.223 236.353 104.358 236.694 104.358H297.392C297.733 104.358 298.06 104.223 298.302 103.981C298.543 103.74 298.679 103.412 298.679 103.071C298.679 102.729 298.543 102.402 298.302 102.16C298.06 101.919 297.733 101.783 297.392 101.783H236.694Z"
      fill="#E6E6E6"
    />
    <path
      d="M255.639 30.9005C252.583 30.9005 249.596 29.9943 247.055 28.2966C244.515 26.599 242.534 24.186 241.365 21.3628C240.196 18.5396 239.89 15.4331 240.486 12.4361C241.082 9.439 242.553 6.68603 244.714 4.52528C246.875 2.36452 249.628 0.89303 252.625 0.296879C255.622 -0.299272 258.728 0.00669534 261.552 1.17609C264.375 2.34548 266.788 4.32577 268.485 6.86655C270.183 9.40733 271.089 12.3945 271.089 15.4502C271.085 19.5465 269.455 23.4736 266.559 26.3701C263.662 29.2666 259.735 30.8959 255.639 30.9005ZM255.639 0.735731C252.729 0.735731 249.884 1.59872 247.464 3.21557C245.044 4.83242 243.158 7.13052 242.045 9.81924C240.931 12.508 240.64 15.4666 241.207 18.3209C241.775 21.1752 243.176 23.7971 245.234 25.855C247.292 27.9128 249.914 29.3143 252.768 29.882C255.623 30.4498 258.581 30.1584 261.27 29.0447C263.959 27.931 266.257 26.045 267.874 23.6252C269.491 21.2054 270.354 18.3605 270.354 15.4502C270.349 11.5491 268.797 7.80895 266.039 5.05041C263.28 2.29186 259.54 0.740163 255.639 0.735731Z"
      fill={fill}
    />
    <path
      d="M261.735 13.9264H257.163V9.35417C257.163 8.94996 257.003 8.5623 256.717 8.27647C256.431 7.99065 256.043 7.83008 255.639 7.83008C255.235 7.83008 254.847 7.99065 254.561 8.27647C254.276 8.5623 254.115 8.94996 254.115 9.35417V13.9264H249.543C249.139 13.9264 248.751 14.0869 248.465 14.3728C248.179 14.6586 248.019 15.0462 248.019 15.4504C248.019 15.8547 248.179 16.2423 248.465 16.5281C248.751 16.8139 249.139 16.9745 249.543 16.9745H254.115V21.5467C254.115 21.9509 254.276 22.3386 254.561 22.6244C254.847 22.9102 255.235 23.0708 255.639 23.0708C256.043 23.0708 256.431 22.9102 256.717 22.6244C257.003 22.3386 257.163 21.9509 257.163 21.5467V16.9745H261.735C262.14 16.9745 262.527 16.814 262.813 16.5281C263.099 16.2423 263.26 15.8547 263.26 15.4505C263.26 15.0462 263.099 14.6586 262.813 14.3728C262.527 14.087 262.14 13.9264 261.735 13.9264V13.9264Z"
      fill={fill}
    />
  </svg>
);

export default NoTemplates;
