import clsx from "clsx";
import React from "react";
import {
  Popover,
  PopoverProps,
  Box,
  Button,
  TextField,
  LinearProgress,
  StepIconClassKey,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { Tabs } from "../../../uploader/Tabs";
import { UnsplashForm } from "../../../unsplash/UnsplashForm";
import {
  AssetType,
  maxVideoUploadFileSize,
  maxUploadFileSize,
} from "../../../../constants";
import {
  getMimeTypes,
  uploadFile,
  getAssetType,
  nameFromUrl,
} from "../../../../utils/file";
import { ElementType, MediaElementType } from "../../types/elements";
import { useTranscodeVideo } from "../../../../hooks/useTranscodeVideo";
import { useSnackAlert } from "../../../../hooks/useSnackAlert";
import { ImageDimensions } from "../../../image/ResizableImage";
import { CropScaled } from "../../types/legacy";
import { useEditorProgram } from "../../../new-editor/hooks";
import { useNativeDropzone } from "../../../../utils/device";
import { useCurrentUserId } from "../../../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "9999 !important" as any,
  },
  button: {
    margin: theme.spacing(4, 0, 2),
  },
  buttonUpload: {
    marginTop: 0,
  },
  progress: {
    borderRadius: theme.spacing(1),
  },
}));

const programFragment = graphql`
  fragment MediaElementPopover_program on Program {
    id
  }
`;

export const createUploadUrlMutation = graphql`
  mutation MediaElementPopoverCreateUploadUrlMutation(
    $id: ID!
    $file: String!
    $type: AssetType!
  ) {
    createSignedUrl(id: $id, file: $file, type: $type) {
      url
    }
  }
`;

export interface Media {
  url: string;
  size?: number;
  name?: string;
  dimensions?: ImageDimensions;
  crop?: CropScaled;
  mimeType?: string;
  align?: string;
}

export interface MediaElementPopoverProps extends PopoverProps {
  onMediaChange: (media: Media) => void;
  elementType: MediaElementType;
  embedUrl: string;
}

const uploadFileLabel = (elementTypeCapitalized: string) => {
  switch (elementTypeCapitalized) {
    case "File":
      return "Upload a File";
    default:
      return `Upload ${elementTypeCapitalized} File`;
  }
};

export function MediaElementPopover(props: MediaElementPopoverProps) {
  const {
    className,
    anchorEl,
    onClose,
    onMediaChange,
    elementType,
    embedUrl: initialEmbedUrl,
    ...other
  } = props;
  const s = useStyles();
  const tabs = React.useMemo(() => {
    switch (elementType) {
      case ElementType.IMAGE:
        return ["Upload", "Embed", "Unsplash"];

      case ElementType.VIDEO:
        return ["Upload", "Embed"];

      default:
        return ["Upload", "Embed"];
    }
  }, [elementType]);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [embedUrl, setEmbedUrl] = React.useState<string>(initialEmbedUrl);
  const initialActiveTab = tabs.length === 1 ? 0 : embedUrl ? 1 : 0;
  const [activeTab, setActiveTab] = React.useState<number>(initialActiveTab);
  const programRef = useEditorProgram();
  const program = useFragment(programFragment, programRef);
  const [createUploadUrl] = useMutation(createUploadUrlMutation);
  const transcodeVideo = useTranscodeVideo();
  const snackAlert = useSnackAlert();
  const userId = useCurrentUserId();
  const [progress, setProgress] = React.useState<number | null>(null);

  const elementTypeCapitalized = React.useMemo(
    () => elementType[0].toUpperCase() + elementType.substring(1),
    [elementType],
  );

  const handleClose = React.useCallback(
    (event: Event) => {
      event.stopPropagation();
      if (!uploading) {
        onClose(null, "backdropClick");
      }
    },
    [uploading, onClose],
  );

  const handleTabChange = React.useCallback((event, index) => {
    setActiveTab(index);
  }, []);

  const handleEmbedUrlChange = React.useCallback((event) => {
    setEmbedUrl(event.target.value);
  }, []);

  const handleEmbedClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClose(null, "backdropClick");

      onMediaChange({
        url: embedUrl,
        name: nameFromUrl(embedUrl),
      });
    },
    [onClose, onMediaChange, embedUrl],
  );

  const handleEmbedUrlSubmit = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      onClose(null, "backdropClick");

      onMediaChange({
        url: embedUrl,
        name: nameFromUrl(embedUrl),
      });
    },
    [onClose, onMediaChange, embedUrl],
  );

  const handleOnProgress = React.useCallback(
    (event: ProgressEvent<EventTarget>) => {
      const progress = Math.round((100 * event.loaded) / event.total);

      setProgress(progress);
    },
    [],
  );

  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: ([file], errors) => {
      if (errors.length) {
        // TODO: Inform user of errors using common component
        console.error(errors);
        return;
      } else if (!file) {
        return;
      }

      const { name, size, type: mimeType } = file;
      const type = getAssetType(elementType);
      const maxFileSize = AssetType.PROGRAM_VIDEO
        ? maxVideoUploadFileSize
        : maxUploadFileSize;

      if (file.size > maxFileSize) {
        snackAlert({
          severity: "error",
          message: "File size is too big",
        });
        return;
      }

      setUploading(true);
      setProgress(null);

      createUploadUrl({
        variables: {
          id: program?.id || userId,
          file: file.name,
          type,
        },
        onCompleted: ({ createSignedUrl }: any, errors) => {
          if (errors) {
            setUploading(false);
            // TODO: Inform user of errors using common component
          } else {
            // TODO: Consider deleting the signed URL and uploaded file on change or element removal
            const { url } = createSignedUrl;

            uploadFile(url, file, handleOnProgress)
              .then(() => {
                const urlInstance = new URL(
                  `https:/${url.substr(url.indexOf("/", 8))}`,
                );
                urlInstance.search = "";

                const uploadUrl = String(urlInstance);

                return elementType === ElementType.VIDEO
                  ? transcodeVideo(uploadUrl)
                  : uploadUrl;
              })
              .then((url) => {
                onMediaChange({
                  url,
                  name,
                  size,
                  mimeType,
                });
                setEmbedUrl("");
              })
              .catch((err) => {
                // TODO: Inform user of errors using common component
                console.error(err);
              })
              .finally(() => {
                setUploading(false);
                onClose(null, "backdropClick");
              });
          }
        },
        onError: (err) => {
          setUploading(false);
          // TODO: Inform user of errors using common component
          console.error(err);
        },
      });
    },
    accept: getMimeTypes(elementType),
  });

  const uploadTab = (
    <>
      <Button
        {...(getRootProps() as any)}
        variant="contained"
        fullWidth
        className={clsx(s.button, s.buttonUpload)}
        disabled={uploading}
      >
        <input type="file" {...getInputProps()} />
        {uploading
          ? progress === 100
            ? "Processing..."
            : "Uploading..."
          : uploadFileLabel(elementTypeCapitalized)}
      </Button>

      {uploading && (
        <LinearProgress
          className={s.progress}
          variant="determinate"
          color="primary"
          value={progress || 0}
        />
      )}
    </>
  );

  const embedTab = (
    <form onSubmit={handleEmbedUrlSubmit}>
      <TextField
        type="text"
        name={`${elementTypeCapitalized} link`}
        label={`${elementTypeCapitalized} link`}
        placeholder={`Paste ${elementType} link`}
        value={embedUrl}
        onChange={handleEmbedUrlChange}
        fullWidth
        autoFocus
      />
      <Button
        variant="contained"
        fullWidth
        className={s.button}
        onClick={handleEmbedClick}
      >
        Embed
      </Button>
    </form>
  );

  const handleUnsplashSelect = React.useCallback(
    (url: string) => {
      onMediaChange({
        url,
      });
    },
    [onMediaChange],
  );

  const unsplashTab = <UnsplashForm onSelect={handleUnsplashSelect} />;

  return (
    <Popover
      className={clsx(s.root, className)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      {...other}
    >
      <Box p={2} minWidth={376}>
        <Tabs items={tabs} onChange={handleTabChange} index={activeTab} />
        {tabs.length === 1
          ? activeTab === 0
            ? embedTab
            : null
          : activeTab === 0
            ? uploadTab
            : activeTab === 1
              ? embedTab
              : activeTab === 2
                ? unsplashTab
                : null}
      </Box>
    </Popover>
  );
}
