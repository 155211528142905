import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { capitalize } from "lodash";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { notificationReadStoreUpdater } from "../activity-feedback/ActivityFeedback";
import { UserRole } from "../../constants";

import { ActivityResponseItem_feedback$key } from "./__generated__/ActivityResponseItem_feedback.graphql";
import { MessageContent } from "../messages/MessageContent";

const useStyles = makeStyles((theme) => ({
  root: {},

  avatar: {
    width: 48,
    height: 48,
    borderRadius: 8,
    marginRight: theme.spacing(2),
  },

  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },

  infoText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.black,
    whiteSpace: "break-spaces",
  },

  contentWrapper: {
    marginTop: theme.spacing(1),
    whiteSpace: "break-spaces",
  },
}));

const fragment = graphql`
  fragment ActivityResponseItem_feedback on ActivityFeedback {
    id
    author {
      id
      role
      displayName
      photoURL
    }
    content
    read
    createdAt(format: "fromNow")
  }
`;

const notificationReadMutation = graphql`
  mutation ActivityResponseItemNotificationReadMutation(
    $input: NotificationReadInput!
  ) {
    notificationRead(input: $input) {
      notification {
        id
        activityFeedbackId
      }
    }
  }
`;

export interface ActivityResponseItemProps extends BoxProps {
  feedbackRef: ActivityResponseItem_feedback$key;
}

export function ActivityResponseItem(props: ActivityResponseItemProps) {
  const { className, feedbackRef, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { id, author, content, read, createdAt } = useFragment(
    fragment,
    feedbackRef,
  );
  const [notificationRead, inFlight] = useMutation(notificationReadMutation);

  React.useEffect(() => {
    if (author.id !== user.id && !read && !inFlight) {
      notificationRead({
        variables: {
          input: { activityFeedbackId: id },
        },
        onCompleted: (data, errors) => {
          if (errors && errors.length) {
            console.error(errors[0]);
          }
        },
        onError: (error) => {
          console.error(error);
        },
        updater: notificationReadStoreUpdater,
      });
    }
  }, [id, read, notificationRead, inFlight, author.id, user.id]);

  const name = React.useMemo(
    () =>
      user.id === author.id
        ? "You"
        : `${author.role === UserRole.COACH ? "Coach" : ""} ${
            author.displayName
          }`,
    [author.displayName, author.id, author.role, user.id],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.info}>
        <Avatar
          className={s.avatar}
          alt={name}
          src={author.photoURL}
          variant="square"
        />
        <Box className={s.infoText}>
          <Typography
            className={s.title}
            variant="h4"
            children={`${name} added feedback`}
          />
          <Typography
            className={s.subtitle}
            variant="h5"
            children={capitalize(createdAt)}
          />
        </Box>
      </Box>
      <MessageContent content={content} textClassName={s.text} />
    </Box>
  );
}
