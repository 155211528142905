import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Tabs, Tab, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import queryString from "query-string";
import assign from "lodash.assign";

import { useLocation, useNavigate } from "react-router-dom";

import { NavigationItem } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: "0 !important",
  },

  tabs: {
    maxWidth: theme.breakpoints.values.slg,
    margin: "auto",
  },

  tab: {
    maxWidth: "initial",
    flexGrow: 1,
    fontSize: 14,
    fontWeight: "bold",
    color: `${theme.palette.secondary.main}99`,
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },

  divider: {
    height: 1,
    backgroundColor: theme.palette.border.primary,
    marginTop: -1,
  },

  selected: {},
}));

export interface AppTabsNavigationProps
  extends Omit<ContainerProps, "children"> {
  items: NavigationItem[];
}

const getQuery = (query: NavigationItem["query"]) =>
  typeof query === "function" ? query() : query;

export function AppTabsNavigation(props: AppTabsNavigationProps) {
  const navigate = useNavigate();
  const { className, items, ...other } = props;
  const s = useStyles();
  const location = useLocation();
  const [selected, setSelected] = React.useState<number>(0);

  React.useEffect(() => {
    const selected = items.findIndex((item) => {
      if (item.selected) {
        return true;
      }
      if (location.pathname.includes(item.link)) {
        return true;
      }
      return false;
    });

    setSelected(selected >= 0 ? selected : 0);
  }, [items, location.pathname, location.search]);

  const getTabClickHandler = React.useCallback(
    (item, index) => () => {
      const query = item.link + location.search;

      setSelected(index);

      navigate(query);
    },
    [location.search],
  );

  return (
    <Container className={clsx(s.root, className)} maxWidth={false} {...other}>
      <Tabs
        className={s.tabs}
        value={selected}
        variant="scrollable"
        indicatorColor="primary"
      >
        {items.map((item, index) => (
          <Tab
            key={index}
            label={item.name}
            value={index}
            className={clsx(s.tab, selected === index && s.selected)}
            onClick={getTabClickHandler(item, index)}
          />
        ))}
      </Tabs>
      <Divider className={s.divider} orientation="horizontal" />
    </Container>
  );
}
