/**
 * @generated SignedSource<<6d709dab7e0d4c3f3ab901d1386e14ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChooseProgramEnrollDialogSearchQuery$variables = {
  after?: string | null | undefined;
  first: number;
  query?: string | null | undefined;
};
export type ChooseProgramEnrollDialogSearchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChooseProgramEnrollDialogSearch_fragment">;
};
export type ChooseProgramEnrollDialogSearchQuery = {
  response: ChooseProgramEnrollDialogSearchQuery$data;
  variables: ChooseProgramEnrollDialogSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "kind": "Literal",
    "name": "status",
    "value": "PUBLISHED"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChooseProgramEnrollDialogSearchQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ChooseProgramEnrollDialogSearch_fragment"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChooseProgramEnrollDialogSearchQuery",
    "selections": [
      {
        "alias": "connection",
        "args": (v6/*: any*/),
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "length",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "connection",
        "args": (v6/*: any*/),
        "filters": [
          "query",
          "status"
        ],
        "handle": "connection",
        "key": "ChooseProgramEnrollDialogSearch_connection",
        "kind": "LinkedHandle",
        "name": "programs"
      }
    ]
  },
  "params": {
    "cacheID": "4cfb286811301e05cb17b0148aea6991",
    "id": null,
    "metadata": {},
    "name": "ChooseProgramEnrollDialogSearchQuery",
    "operationKind": "query",
    "text": "query ChooseProgramEnrollDialogSearchQuery(\n  $first: Int!\n  $after: String\n  $query: String\n) {\n  ...ChooseProgramEnrollDialogSearch_fragment_1Tyy4D\n}\n\nfragment ChooseProgramEnrollDialogSearch_fragment_1Tyy4D on Root {\n  connection: programs(first: $first, after: $after, query: $query, status: PUBLISHED) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...ProgramSelectableItem_program\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment ProgramSelectableItem_program on Program {\n  id\n  name\n  length\n  imageURL\n}\n"
  }
};
})();

(node as any).hash = "f2fbb42e4c3f9b9611f6533a13f67237";

export default node;
