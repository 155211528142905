import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { Avatar, AvatarProps } from "../avatar/Avatar";

import { LikeListItem_like$key } from "./__generated__/LikeListItem_like.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const fragment = graphql`
  fragment LikeListItem_like on Like {
    author {
      id
      ...Avatar_user
    }
  }
`;

export interface LikeListItemProps extends Omit<AvatarProps, "userRef"> {
  likeRef: LikeListItem_like$key;
}

export function LikeListItem(props: LikeListItemProps) {
  const { className, likeRef, ...other } = props;
  const s = useStyles();
  const { author } = useFragment(fragment, likeRef);

  return (
    <Avatar className={clsx(s.root, className)} userRef={author} {...other} />
  );
}
