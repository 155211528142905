/**
 * @generated SignedSource<<54e0526f93dcd4a84398b23910f3c393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachThemeSettingsScreen_workspace$data = {
  readonly primaryColor: string;
  readonly " $fragmentType": "CoachThemeSettingsScreen_workspace";
};
export type CoachThemeSettingsScreen_workspace$key = {
  readonly " $data"?: CoachThemeSettingsScreen_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachThemeSettingsScreen_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachThemeSettingsScreen_workspace",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryColor",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "64777b08b50f3d4eee1f4a1776fd3a2b";

export default node;
