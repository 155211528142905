import React from "react";
import { Grid, ContainerProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";
import { ClientWorkouts_data$key } from "./__generated__/ClientWorkouts_data.graphql";

import ClientWorkoutsChoose from "./ClientWorkoutsChoose";
import ClientWorkoutsItem from "./ClientWorkoutsItem";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { WorkoutSection } from "../workout/types";
import { parseWorkoutResultValueJsonToWorkoutSection } from "../workout/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 100,
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  hasMore: {
    margin: theme.spacing(0, 1),
  },
  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "center",
  },
}));

const dataFragment = graphql`
  fragment ClientWorkouts_data on Root
  @refetchable(queryName: "ClientWorkoutsRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 99 }
    clientId: { type: ID, defaultValue: null }
    after: { type: "String", defaultValue: null }
  ) {
    workoutExerciseResults(first: $first, after: $after, clientId: $clientId)
      @connection(key: "ClientWorkouts_workoutExerciseResults", filters: []) {
      edges {
        node {
          id
          activity {
            component {
              title
              content
            }
            endWorkout
            completedAt(format: "MMM D.YY")
          }
          value
        }
      }
    }
  }
`;

export interface ClientWorkoutsProps extends Omit<ContainerProps, "children"> {
  dataRef?: ClientWorkouts_data$key;
  pageSize?: number;
  selectWorkout: any;
  handleCloseWorkout: any;
  chooseWorkoutItem: any;
  sumSessions: any;
  timeWorkout: any;
  sumLbs: any;
  setLastRecorded: any;
  lastRecorded: any;
}

function ClientWorkouts(props: ClientWorkoutsProps) {
  const {
    selectWorkout,
    handleCloseWorkout,
    chooseWorkoutItem,
    sumSessions,
    timeWorkout,
    sumLbs,
    dataRef,
    setLastRecorded,
    lastRecorded,
  } = props;
  const { data, loadNext, hasNext } = usePaginationFragment(
    dataFragment,
    dataRef,
  );

  const s = useStyles();

  const handleMoreClick = React.useCallback(() => {
    loadNext(3);
  }, [loadNext]);

  const workoutExerciseResults = data.workoutExerciseResults.edges.map(
    ({ node: { value, ...node } }) => ({
      ...node,
      section: parseWorkoutResultValueJsonToWorkoutSection(value),
    }),
  );

  React.useEffect(() => {
    if (!lastRecorded) {
      if (workoutExerciseResults.length)
        setLastRecorded(workoutExerciseResults[0].activity.completedAt);
    }
  }, [workoutExerciseResults, lastRecorded, setLastRecorded]);
  return (
    <Grid container spacing={2} className={s.container}>
      {selectWorkout ? (
        <ClientWorkoutsChoose
          onClose={handleCloseWorkout}
          selectWorkout={selectWorkout}
        />
      ) : (
        <>
          {workoutExerciseResults.length ? (
            workoutExerciseResults.map(({ activity, section }, index) => (
              <ClientWorkoutsItem
                key={index}
                index={index}
                title={activity.component?.title}
                date={activity.completedAt}
                onClick={() => {
                  chooseWorkoutItem({
                    value: section.exercises,
                    session: sumSessions(section),
                    min: timeWorkout(activity.endWorkout),
                    lbs: sumLbs(section),
                    title: activity.component?.title,
                    date: activity.completedAt,
                  });
                }}
                session={sumSessions(section)}
                min={timeWorkout(activity.endWorkout)}
              />
            ))
          ) : (
            <Typography className={s.noText}>No workouts to show.</Typography>
          )}
          {hasNext && (
            <LoadMoreButton onClick={handleMoreClick} className={s.hasMore} />
          )}
        </>
      )}
    </Grid>
  );
}

export default React.memo(ClientWorkouts);
