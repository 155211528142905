import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Typography,
  CardMedia,
  CardContent,
  Grid,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Pagination } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { colorSystem } from "../../theme";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { UserRole } from "../../constants";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";
import { ReactComponent as ProgressPhotosIcon } from "../../icons/ProgressPhotos.svg";

import { ClientProgressPhotosCard_client$key } from "./__generated__/ClientProgressPhotosCard_client.graphql";
import { ClientProgressPhotosCardDeletePhotoMutation } from "./__generated__/ClientProgressPhotosCardDeletePhotoMutation.graphql";
import { ConfirmActionProgressPhotos } from "./ConfirmActionProgressPhotos";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { WorkoutMediaGallery } from "../dialog/WorkoutMediaGallery";
import AddCircle from "../../icons/AddCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  button: {
    color: colorSystem.black,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },

  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4),
    textAlign: "center",
  },

  image: {
    height: 264,
    cursor: "pointer",
  },

  content: {
    padding: `${theme.spacing(0.75, 1.5)} !important`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  date: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "26px",
    color: theme.palette.common.black,
  },

  label: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3.4),
  },
  paginationItem: {
    "& .Mui-selected:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-selected": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
  },
  imageContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      overflow: "scroll",
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  images: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "70%",
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
}));

const photosFragment = graphql`
  fragment ClientProgressPhotosCard_client on User {
    id
    username
    photos: metrics(metricType: CHECKIN_ANSWER_PROGRESS_PHOTO) {
      id
      createdAt(format: "MMM DD, YYYY")
      value {
        ... on CheckinAnswerProgressPhotoValue {
          front
          back
          side
        }
      }
    }
  }
`;

const deletePhoto = graphql`
  mutation ClientProgressPhotosCardDeletePhotoMutation(
    $id: ID!
    $url: String!
    $bodySide: bodySide!
  ) {
    deletePhoto(id: $id, url: $url, bodySide: $bodySide) {
      deleted
    }
  }
`;

export interface ClientProgressPhotosCardProps extends CardProps {
  clientRef: ClientProgressPhotosCard_client$key;
}

export function ClientProgressPhotosCard(props: ClientProgressPhotosCardProps) {
  const { className, clientRef, ...other } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const snackAlert = useSnackAlert();
  const user = useCurrentUser();
  const { photos, id } = useFragment(photosFragment, clientRef);
  const [allPhotos, setAllPhotos] = React.useState([]);
  const [deletePhotoFromList, deleting] =
    useMutation<ClientProgressPhotosCardDeletePhotoMutation>(deletePhoto);
  const [visibleAction, setVisibleAction] = React.useState(false);
  const [visibleActionDelete, setVisibleActionDelete] = React.useState(false);
  const [photosList, setPhotosList] = React.useState([]);
  const [currentPhotosList, setCurrentPhotosList] = React.useState(1);
  const [photosPerList] = React.useState(6);
  const [chooseImage, setChooseImage] = React.useState({
    idMetric: "",
    url: "",
    label: "",
  });
  const [selectedMediaIndex, setSelectedMediaIndex] = React.useState(0);
  const [openMedia, setOpenMedia] = React.useState(false);
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const [isOpenContent, setIsOpenContent] = React.useState(true);

  const lastPhotosIndex = React.useMemo(() => {
    return currentPhotosList * photosPerList;
  }, [currentPhotosList, photosPerList]);
  const firstPhotosIndex = React.useMemo(() => {
    return lastPhotosIndex - photosPerList;
  }, [lastPhotosIndex, photosPerList]);
  const currentPhotos = React.useMemo(() => {
    return smUp
      ? photosList.slice(firstPhotosIndex, lastPhotosIndex)
      : photosList;
  }, [photosList, lastPhotosIndex, firstPhotosIndex, smUp]);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const updatePhotosList = React.useCallback(
    (value) => {
      const photoList = [];
      const data = [value];
      data.forEach(({ id, createdAt, value: { front, back, side } }) => {
        const item = [front, back, side];
        item.forEach((url, i) => {
          if (url) {
            const data = {
              idProgram: id,
              createdAt,
              label: i === 0 ? "Front" : i === 1 ? "Back" : "Side",
              url,
            };
            photoList.push(data);
          }
        });
      });
      setAllPhotos((prev) => [{ ...value }, ...prev]);
      setPhotosList([...photoList, ...photosList]);
      setVisibleAction(false);
    },
    [setVisibleAction, photosList, setPhotosList, setAllPhotos],
  );

  React.useEffect(() => {
    const data = [...photos];
    setAllPhotos(data);
  }, [photos]);

  React.useEffect(() => {
    const photoList = [];
    allPhotos.forEach(({ id, createdAt, value: { front, back, side } }) => {
      const item = [front, back, side];
      item.forEach((url, i) => {
        if (url) {
          const data = {
            idProgram: id,
            createdAt,
            label: i === 0 ? "Front" : i === 1 ? "Back" : "Side",
            url,
          };
          photoList.push(data);
        }
      });
    });
    setPhotosList(photoList);
  }, [allPhotos, setPhotosList]);

  const handlePaginationPhotosList = React.useCallback(
    (number) => {
      setCurrentPhotosList(number);
    },
    [setCurrentPhotosList],
  );
  const theme = useTheme();

  const handleRemove = React.useCallback(() => {
    const label = chooseImage.label.toUpperCase();
    deletePhotoFromList({
      variables: {
        id: chooseImage.idMetric,
        url: chooseImage.url,
        bodySide: label,
      },
      onCompleted: (data, error) => {
        if (error) {
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
          setVisibleActionDelete(false);
        } else {
          snackAlert({
            severity: "success",
            message: "Saved successfully",
          });
          const data = photosList.filter(
            ({ url: imageUrl }) => imageUrl !== chooseImage.url,
          );
          setPhotosList(data);
          setVisibleActionDelete(false);
          if (!currentPhotos.length)
            setCurrentPhotosList(currentPhotosList - 1);
        }
      },
    });
  }, [
    chooseImage,
    photosList,
    setPhotosList,
    deletePhotoFromList,
    currentPhotosList,
    setCurrentPhotosList,
    snackAlert,
    currentPhotos.length,
  ]);

  const handleRemoveAction = React.useCallback(
    (idMetric, url, label) => {
      setVisibleActionDelete(true);
      setChooseImage({
        idMetric,
        url,
        label,
      });
    },
    [setChooseImage],
  );

  const handleMediaClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const {
        dataset: { mediaIndex },
      } = event.currentTarget;

      setOpenMedia(true);
      setSelectedMediaIndex(parseInt(mediaIndex));
    },
    [],
  );

  const handleMediaDialogClose = React.useCallback(() => {
    setOpenMedia(false);
  }, []);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Box className={s.headerContainer}>
          <ProgressPhotosIcon className={s.icon} />
          <Box>
            <Typography
              variant="h6"
              className={s.title}
              children="Progress photos"
            />
            {photos.length ? (
              <Typography variant="body1" className={s.subtitle}>
                Last recorded {photos[0].createdAt}
              </Typography>
            ) : null}
          </Box>
        </Box>
        {user.role !== UserRole.COACH && smUp ? (
          <Button
            className={s.button}
            startIcon={<AddCircle fill={theme.palette.primary.main} />}
            children="Add new photo"
            onClick={() => setVisibleAction(true)}
          />
        ) : (
          !smUp && (
            <ArrowIcon
              className={isOpenContent ? "" : s.arrowIconDown}
              onClick={() => setIsOpenContent(!isOpenContent)}
            />
          )
        )}
      </Box>
      {isOpenContent && photosList.length ? (
        <Grid container spacing={2} className={s.imageContainer}>
          {currentPhotos.map(({ url, label, createdAt, idProgram }, index) => (
            <Grid key={url} item xs={12} sm={6} md={4} className={s.images}>
              <Card>
                <CardMedia
                  className={s.image}
                  image={url}
                  title={`${label}, ${createdAt}`}
                  onClick={handleMediaClick}
                  data-media-index={index}
                />
                <CardContent className={s.content}>
                  <Box>
                    <Typography
                      className={s.date}
                      variant="body1"
                      children={createdAt}
                    />
                    <Typography
                      className={s.label}
                      variant="body1"
                      children={label}
                    />
                  </Box>
                  {user.role !== UserRole.COACH && (
                    <IconButton
                      children={<DeleteIcon width={20} height={26} />}
                      onClick={() => handleRemoveAction(idProgram, url, label)}
                      disabled={false}
                      size="large"
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        isOpenContent &&
        !photosList.length && (
          <Typography className={s.noText}>
            No progress photos to show.
          </Typography>
        )
      )}
      {smUp ? (
        <Box className={s.pagination}>
          <Pagination
            count={Math.ceil(photosList.length / photosPerList)}
            // variant="outlined"
            shape="rounded"
            page={currentPhotosList}
            onChange={(_, number) => handlePaginationPhotosList(number)}
            color={"primary"}
            className={s.paginationItem}
          />
        </Box>
      ) : (
        user.role !== UserRole.COACH &&
        !smUp &&
        isOpenContent && (
          <Button
            className={s.button}
            startIcon={<AddCircle fill={theme.palette.primary.main} />}
            children="Add new photo"
            onClick={() => setVisibleAction(true)}
          />
        )
      )}
      <ConfirmActionProgressPhotos
        title={"Progress photos"}
        onCancel={() => setVisibleAction(false)}
        onConfirm={() => {}}
        open={visibleAction}
        id={id}
        updatePhotosList={updatePhotosList}
      />
      <ConfirmActionDialog
        title="Are you sure you want to remove this result?"
        onCancel={() => setVisibleActionDelete(false)}
        onConfirm={handleRemove}
        open={visibleActionDelete}
        disabled={deleting}
      />
      {openMedia && (
        <WorkoutMediaGallery
          open
          defaultIndex={selectedMediaIndex}
          onClose={handleMediaDialogClose}
          images={currentPhotos}
        />
      )}
    </Card>
  );
}
