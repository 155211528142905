import React from "react";

const NoMeals = ({ fill }) => (
  <svg
    width="213"
    height="221"
    viewBox="0 0 213 221"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.3573 77.2726L40.2837 82.571L124.366 210.848L132.44 205.55L48.3573 77.2726Z"
      fill="#E6E6E6"
    />
    <path
      d="M29.1363 28.9973L47.816 57.4953C48.1778 58.0472 48.3056 58.7202 48.1714 59.3664C48.0372 60.0126 47.6519 60.5789 47.1004 60.9409C46.5489 61.3029 45.8762 61.4308 45.2304 61.2965C44.5846 61.1622 44.0186 60.7767 43.6569 60.2248L22.1823 27.4629C19.2674 27.2193 16.3376 27.7008 13.6537 28.8644L37.0512 64.5599C37.4127 65.1118 37.5403 65.7847 37.4061 66.4308C37.2718 67.0768 36.8866 67.643 36.3351 68.0049C35.7837 68.3668 35.1112 68.4947 34.4655 68.3605C33.8199 68.2264 33.2539 67.8411 32.8921 67.2895L9.49453 31.5939C7.35854 33.5935 5.74986 36.0905 4.81174 38.8627L26.2864 71.6246C26.6479 72.1765 26.7755 72.8494 26.6412 73.4954C26.5069 74.1414 26.1217 74.7076 25.5703 75.0695C25.0189 75.4314 24.3464 75.5593 23.7007 75.4252C23.055 75.291 22.4891 74.9057 22.1272 74.3541L3.44748 45.8561C2.96771 53.802 5.55684 63.2201 11.2311 71.8769C21.777 87.9658 38.9795 95.3295 49.654 88.3242C60.3284 81.3189 60.4326 62.5973 49.8867 46.5084C44.2124 37.8516 36.611 31.7229 29.1363 28.9973Z"
      fill="#E6E6E6"
    />
    <path d="M84.5563 59.723H74.9011V213.129H84.5563V59.723Z" fill="#E6E6E6" />
    <path
      d="M79.5824 69.6768C92.3479 69.6768 102.696 54.0791 102.696 34.8384C102.696 15.5977 92.3479 0 79.5824 0C66.8169 0 56.4684 15.5977 56.4684 34.8384C56.4684 54.0791 66.8169 69.6768 79.5824 69.6768Z"
      fill="#E6E6E6"
    />
    <path
      d="M79.5824 63.502C90.0857 63.502 98.6003 50.6685 98.6003 34.8375C98.6003 19.0066 90.0857 6.17302 79.5824 6.17302C69.0792 6.17302 60.5646 19.0066 60.5646 34.8375C60.5646 50.6685 69.0792 63.502 79.5824 63.502Z"
      fill="#CCCCCC"
    />
    <path
      d="M106.5 221.001C165.318 221.001 213 218.264 213 214.887C213 211.51 165.318 208.772 106.5 208.772C47.6817 208.772 0 211.51 0 214.887C0 218.264 47.6817 221.001 106.5 221.001Z"
      fill="#E6E6E6"
    />
    <path
      d="M169.484 214.796C129.572 207.597 88.6948 207.597 48.7829 214.796C48.1489 214.911 47.4974 214.885 46.8744 214.721C46.2514 214.556 45.6721 214.257 45.1773 213.844C44.6825 213.431 44.2844 212.914 44.0109 212.33C43.7375 211.747 43.5954 211.11 43.5947 210.465V155.748H174.672V210.465C174.671 211.11 174.529 211.747 174.255 212.33C173.982 212.914 173.584 213.431 173.089 213.844C172.594 214.257 172.015 214.556 171.392 214.721C170.769 214.885 170.118 214.911 169.484 214.796Z"
      fill={fill}
    />
    <path
      d="M162.676 115.347C159.649 115.345 156.644 115.848 153.783 116.836C149.61 107.781 142.943 100.104 134.563 94.7062C126.183 89.3089 116.438 86.4152 106.472 86.3649C96.5063 86.3145 86.7329 89.1094 78.2989 94.4218C69.8648 99.7341 63.1201 107.343 58.8562 116.357C54.6576 115.178 50.2374 115.026 45.9681 115.914C41.6987 116.803 37.7051 118.704 34.3238 121.459C30.9424 124.215 28.2723 127.743 26.5384 131.746C24.8045 135.75 24.0576 140.111 24.3606 144.464C24.6635 148.817 26.0075 153.033 28.2792 156.758C30.551 160.482 33.684 163.606 37.4144 165.865C41.1448 168.125 45.3632 169.454 49.7144 169.742C54.0656 170.029 58.4221 169.266 62.4169 167.516C67.2183 174.837 73.7763 180.838 81.492 184.97C89.2076 189.102 97.835 191.234 106.586 191.171C115.337 191.108 123.933 188.852 131.588 184.609C139.244 180.366 145.715 174.272 150.41 166.883C154.079 168.736 158.119 169.734 162.228 169.801C166.337 169.868 170.408 169.004 174.135 167.272C177.862 165.54 181.149 162.986 183.749 159.801C186.349 156.616 188.194 152.884 189.146 148.884C190.098 144.884 190.132 140.72 189.246 136.705C188.36 132.689 186.576 128.927 184.029 125.7C181.482 122.473 178.238 119.865 174.54 118.072C170.841 116.279 166.785 115.348 162.676 115.347Z"
      fill={fill}
    />
    <path
      d="M54.1277 138.767H53.5425C53.5226 130.972 55.2468 123.271 58.5887 116.229L59.1176 116.48C55.8128 123.443 54.1078 131.059 54.1277 138.767Z"
      fill="#3F3D56"
    />
    <path
      d="M158.872 138.769H158.287C158.305 131.238 156.678 123.794 153.52 116.958L154.051 116.713C157.245 123.625 158.891 131.153 158.872 138.769Z"
      fill="#3F3D56"
    />
    <path
      d="M43.6653 181.21L43.5242 180.641C43.6893 180.601 60.3194 176.514 84.6906 174.486C107.185 172.614 141.067 172.163 174.743 180.642L174.6 181.21C141.007 172.752 107.198 173.201 84.7514 175.068C60.4238 177.092 43.83 181.169 43.6653 181.21Z"
      fill="#3F3D56"
    />
    <path
      d="M43.6653 189.114L43.5242 188.546C43.6893 188.505 60.3194 184.418 84.6906 182.39C107.185 180.519 141.067 180.067 174.743 188.546L174.6 189.114C141.007 180.656 107.198 181.106 84.7514 182.973C60.4238 184.996 43.83 189.073 43.6653 189.114Z"
      fill="#3F3D56"
    />
    <path
      d="M43.6653 197.019L43.5242 196.45C43.6893 196.41 60.3194 192.323 84.6906 190.295C107.185 188.423 141.067 187.972 174.743 196.451L174.6 197.019C141.007 188.561 107.198 189.011 84.7514 190.877C60.4238 192.901 43.83 196.978 43.6653 197.019Z"
      fill="#3F3D56"
    />
    <path
      d="M151.343 154.036L144.254 151.624L151.06 116.865L146.381 116.44C141.818 119.943 135.742 121.316 127.948 120.27L140.425 139.14L134.063 143.633L113.767 119.274C112.853 118.176 112.352 116.792 112.352 115.362C112.352 114.037 112.782 112.748 113.579 111.689C114.375 110.63 115.494 109.859 116.767 109.492L138.44 103.246L163.82 106.083C167.658 111.937 167.808 116.008 161.967 117.182L151.343 154.036Z"
      fill="#2F2E41"
    />
    <path
      d="M144.087 167.558C143.354 167.512 142.643 167.293 142.01 166.92C141.378 166.547 140.842 166.03 140.447 165.41C140.052 164.791 139.808 164.087 139.736 163.356C139.663 162.625 139.764 161.887 140.03 161.202L143.555 152.134C145.345 148.74 147.802 149.165 150.703 152.134L152.121 156.296C152.238 156.638 152.284 157.001 152.257 157.362C152.231 157.723 152.131 158.075 151.965 158.396L148.529 165.042C148.113 165.848 147.471 166.515 146.682 166.961C145.894 167.408 144.992 167.616 144.087 167.558Z"
      fill="#2F2E41"
    />
    <path
      d="M134.925 155.683C134.192 155.637 133.481 155.418 132.848 155.045C132.216 154.672 131.68 154.154 131.285 153.535C130.889 152.916 130.646 152.212 130.573 151.481C130.501 150.75 130.602 150.012 130.868 149.327L134.393 140.259C136.183 136.865 138.64 137.29 141.541 140.259L142.959 144.42C143.076 144.763 143.122 145.126 143.095 145.487C143.068 145.848 142.969 146.2 142.803 146.521L139.367 153.167C138.951 153.972 138.309 154.639 137.52 155.086C136.731 155.533 135.83 155.74 134.925 155.683Z"
      fill="#2F2E41"
    />
    <path
      d="M139.433 70.0485C143.818 70.0485 147.373 66.4915 147.373 62.1036C147.373 57.7158 143.818 54.1587 139.433 54.1587C135.048 54.1587 131.493 57.7158 131.493 62.1036C131.493 66.4915 135.048 70.0485 139.433 70.0485Z"
      fill="#FFB9B9"
    />
    <path
      d="M151.769 73.3121L140.851 78.9869L138.582 68.772L146.097 64.0901L151.769 73.3121Z"
      fill="#FFB9B9"
    />
    <path
      d="M164.955 107.502C154.061 104.21 144.176 102.504 137.023 105.091C139.488 98.1498 134.937 87.5509 129.933 76.7159C132.092 73.8917 135.535 72.7977 139.433 72.3178C143.698 75.4165 146.672 73.4276 148.933 68.6291C153.864 68.431 158.616 70.1783 163.253 73.169C159.628 84.7875 160.281 96.2284 164.955 107.502Z"
      fill="#3F3D56"
    />
    <path
      d="M136.881 106.794C135.161 107.808 133.573 109.517 132.06 111.617C129.503 110.051 127.253 108.269 125.481 106.148C124.24 104.644 123.394 102.855 123.02 100.941C122.645 99.0276 122.754 97.0509 123.336 95.1901L128.182 79.4333C128.584 78.1246 129.366 76.9649 130.428 76.1015L131.068 75.5816L137.448 87.6408L131.068 99.5582C131.877 101.729 134.261 104.236 136.881 106.794Z"
      fill="#3F3D56"
    />
    <path
      d="M139.077 118.806C138.498 119.07 137.866 119.196 137.231 119.173C136.596 119.15 135.974 118.98 135.416 118.676C134.858 118.371 134.378 117.941 134.015 117.419C133.651 116.897 133.414 116.298 133.323 115.668L132.268 108.445L135.955 106.458L140.633 112.587C141.007 113.076 141.266 113.644 141.39 114.247C141.514 114.85 141.501 115.474 141.352 116.072C141.202 116.669 140.92 117.225 140.526 117.699C140.132 118.172 139.637 118.551 139.077 118.806Z"
      fill="#FFB9B9"
    />
    <path
      d="M147.444 61.7549L146.088 61.7952L142.328 56.4649C137.509 56.9633 133.56 59.0581 130.219 62.2669L129.88 60.0068C129.557 57.8516 130.072 55.6545 131.318 53.8673C132.565 52.0802 134.448 50.8389 136.581 50.3988H136.581C137.702 50.1676 138.857 50.1648 139.979 50.3904C141.1 50.616 142.165 51.0654 143.109 51.7119C144.053 52.3583 144.857 53.1885 145.474 54.1529C146.09 55.1172 146.506 56.196 146.696 57.3247L147.444 61.7549Z"
      fill="#2F2E41"
    />
    <path
      d="M132.197 111.621C131.933 111.042 131.808 110.41 131.83 109.774C131.853 109.138 132.023 108.517 132.327 107.958C132.632 107.4 133.062 106.92 133.583 106.556C134.105 106.192 134.704 105.955 135.333 105.863L142.552 104.808L144.537 108.497L138.413 113.178C137.923 113.552 137.356 113.811 136.753 113.936C136.15 114.06 135.527 114.047 134.93 113.897C134.333 113.748 133.777 113.465 133.304 113.071C132.831 112.677 132.452 112.181 132.197 111.621Z"
      fill="#FFB9B9"
    />
    <path
      d="M166.33 105.049C159.462 105.582 151.963 107.726 144.112 110.766C144.015 108.434 143.17 106.195 141.702 104.381C148.832 102.502 155.302 100.297 159.283 96.862L156.873 78.7022L162.119 72.7435L163.024 73.2159C163.776 73.6066 164.437 74.1511 164.964 74.814C165.492 75.4769 165.875 76.2434 166.087 77.0637C168.302 85.6852 169.286 93.9456 168.61 101.719C168.697 102.456 168.514 103.2 168.095 103.812C167.676 104.425 167.049 104.864 166.33 105.049Z"
      fill="#3F3D56"
    />
    <path
      d="M28.0545 213.161C21.6667 217.159 15.2789 217.158 8.89113 213.156C8.68892 213.032 8.52469 212.854 8.4163 212.643C8.30791 212.431 8.25951 212.194 8.27634 211.957L10.2695 186.03H26.391L28.6681 211.948C28.6877 212.187 28.6408 212.427 28.5325 212.641C28.4242 212.856 28.2587 213.036 28.0545 213.161Z"
      fill="#3F3D56"
    />
    <path
      d="M18.2558 189.256C22.7487 189.256 26.3909 187.943 26.3909 186.323C26.3909 184.703 22.7487 183.39 18.2558 183.39C13.7629 183.39 10.1207 184.703 10.1207 186.323C10.1207 187.943 13.7629 189.256 18.2558 189.256Z"
      fill="#3F3D56"
    />
    <path
      d="M24.6322 185.334C20.3932 188.318 16.2895 188.318 12.3213 185.334V180.641H24.6322V185.334Z"
      fill={fill}
    />
    <path
      d="M18.4767 182.988C21.8763 182.988 24.6322 181.938 24.6322 180.642C24.6322 179.346 21.8763 178.296 18.4767 178.296C15.0772 178.296 12.3213 179.346 12.3213 180.642C12.3213 181.938 15.0772 182.988 18.4767 182.988Z"
      fill={fill}
    />
    <path
      d="M18.7698 180.641C19.0936 180.641 19.3561 180.51 19.3561 180.348C19.3561 180.186 19.0936 180.055 18.7698 180.055C18.4461 180.055 18.1836 180.186 18.1836 180.348C18.1836 180.51 18.4461 180.641 18.7698 180.641Z"
      fill="#E6E6E6"
    />
    <path
      d="M21.5381 180.641C21.8619 180.641 22.1244 180.51 22.1244 180.348C22.1244 180.186 21.8619 180.055 21.5381 180.055C21.2144 180.055 20.9519 180.186 20.9519 180.348C20.9519 180.51 21.2144 180.641 21.5381 180.641Z"
      fill="#E6E6E6"
    />
    <path
      d="M20.154 181.332C20.4778 181.332 20.7402 181.201 20.7402 181.039C20.7402 180.877 20.4778 180.746 20.154 180.746C19.8302 180.746 19.5677 180.877 19.5677 181.039C19.5677 181.201 19.8302 181.332 20.154 181.332Z"
      fill="#E6E6E6"
    />
    <path
      d="M14.6178 181.332C14.9416 181.332 15.2041 181.201 15.2041 181.039C15.2041 180.877 14.9416 180.746 14.6178 180.746C14.2941 180.746 14.0316 180.877 14.0316 181.039C14.0316 181.201 14.2941 181.332 14.6178 181.332Z"
      fill="#E6E6E6"
    />
    <path
      d="M16.0019 180.641C16.3257 180.641 16.5881 180.51 16.5881 180.348C16.5881 180.186 16.3257 180.055 16.0019 180.055C15.6781 180.055 15.4156 180.186 15.4156 180.348C15.4156 180.51 15.6781 180.641 16.0019 180.641Z"
      fill="#E6E6E6"
    />
    <path
      d="M17.386 181.681C17.7098 181.681 17.9723 181.549 17.9723 181.387C17.9723 181.225 17.7098 181.094 17.386 181.094C17.0623 181.094 16.7998 181.225 16.7998 181.387C16.7998 181.549 17.0623 181.681 17.386 181.681Z"
      fill="#E6E6E6"
    />
    <path
      d="M205.067 213.161C198.679 217.159 192.291 217.158 185.904 213.156C185.701 213.032 185.537 212.854 185.429 212.643C185.32 212.431 185.272 212.194 185.289 211.957L187.282 186.03H203.403L205.681 211.948C205.7 212.187 205.653 212.427 205.545 212.641C205.437 212.856 205.271 213.036 205.067 213.161Z"
      fill="#3F3D56"
    />
    <path
      d="M195.268 189.256C199.761 189.256 203.403 187.943 203.403 186.323C203.403 184.703 199.761 183.39 195.268 183.39C190.775 183.39 187.133 184.703 187.133 186.323C187.133 187.943 190.775 189.256 195.268 189.256Z"
      fill="#3F3D56"
    />
    <path
      d="M201.645 185.334C197.406 188.318 193.302 188.318 189.334 185.334V180.641H201.645V185.334Z"
      fill={fill}
    />
    <path
      d="M195.489 182.988C198.889 182.988 201.645 181.938 201.645 180.642C201.645 179.346 198.889 178.296 195.489 178.296C192.09 178.296 189.334 179.346 189.334 180.642C189.334 181.938 192.09 182.988 195.489 182.988Z"
      fill={fill}
    />
    <path
      d="M195.782 180.641C196.106 180.641 196.368 180.51 196.368 180.348C196.368 180.186 196.106 180.055 195.782 180.055C195.459 180.055 195.196 180.186 195.196 180.348C195.196 180.51 195.459 180.641 195.782 180.641Z"
      fill="#E6E6E6"
    />
    <path
      d="M198.55 180.641C198.874 180.641 199.137 180.51 199.137 180.348C199.137 180.186 198.874 180.055 198.55 180.055C198.227 180.055 197.964 180.186 197.964 180.348C197.964 180.51 198.227 180.641 198.55 180.641Z"
      fill="#E6E6E6"
    />
    <path
      d="M197.166 181.332C197.49 181.332 197.753 181.201 197.753 181.039C197.753 180.877 197.49 180.746 197.166 180.746C196.843 180.746 196.58 180.877 196.58 181.039C196.58 181.201 196.843 181.332 197.166 181.332Z"
      fill="#E6E6E6"
    />
    <path
      d="M191.63 181.332C191.954 181.332 192.216 181.201 192.216 181.039C192.216 180.877 191.954 180.746 191.63 180.746C191.306 180.746 191.044 180.877 191.044 181.039C191.044 181.201 191.306 181.332 191.63 181.332Z"
      fill="#E6E6E6"
    />
    <path
      d="M193.014 180.641C193.338 180.641 193.601 180.51 193.601 180.348C193.601 180.186 193.338 180.055 193.014 180.055C192.691 180.055 192.428 180.186 192.428 180.348C192.428 180.51 192.691 180.641 193.014 180.641Z"
      fill="#E6E6E6"
    />
    <path
      d="M194.398 181.681C194.722 181.681 194.985 181.549 194.985 181.387C194.985 181.225 194.722 181.094 194.398 181.094C194.075 181.094 193.812 181.225 193.812 181.387C193.812 181.549 194.075 181.681 194.398 181.681Z"
      fill="#E6E6E6"
    />
  </svg>
);

export default NoMeals;
