/**
 * @generated SignedSource<<fcda9004c25da0c8be7d9e8ed0092f61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupNavItemListItem$data = {
  readonly name: string;
  readonly slug: string;
  readonly " $fragmentType": "GroupNavItemListItem";
};
export type GroupNavItemListItem$key = {
  readonly " $data"?: GroupNavItemListItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupNavItemListItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupNavItemListItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "9f1e7f242ed63bb7967088a6d2f12780";

export default node;
