import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { WorkoutSection, ExerciseId } from "./types";

import { WorkoutExerciseItem } from "./WorkoutExerciseItem";
import { WorkoutExerciseDivider } from "./WorkoutExerciseDivider";
import { orderedExercises, isSupersetAtIndex } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  group: {},
}));

export interface WorkoutEditSectionProps extends BoxProps {
  section: WorkoutSection;
  onUpdate?: (workout: WorkoutSection) => void;
  onEditExercise?: (exerciseId: ExerciseId) => void;
  onRemoveExercise?: (exerciseId: ExerciseId) => void;
}

export function WorkoutEditSection(props: WorkoutEditSectionProps) {
  const {
    className,
    children,
    section,
    onUpdate,
    onEditExercise,
    onRemoveExercise,
    ...other
  } = props;
  const s = useStyles();
  const [move, setMove] = React.useState<[number, number]>();
  const exercises = orderedExercises(section.exercises, move);

  const handleMove = React.useCallback((dragPosition, hoverPosition) => {
    setMove([dragPosition, hoverPosition]);
  }, []);

  const handleMoveEnd = React.useCallback(() => {
    if (move) {
      setMove(null);
      if (onUpdate) {
        onUpdate({
          ...section,
          exercises,
        });
      }
    }
  }, [exercises, onUpdate, move, section]);

  const handleToggleSuperset = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onUpdate) {
        const index = parseInt(event.currentTarget.dataset.index);
        const active = isSupersetAtIndex(exercises, index);
        const left = exercises[index - 1];
        const right = exercises[index];

        onUpdate({
          ...section,
          exercises: active
            ? exercises.map((it, i) => ({
                ...it,
                superset:
                  it.superset === left.superset && i < index
                    ? left.id
                    : it.superset === right.superset && i >= index
                      ? right.id
                      : it.superset,
              }))
            : exercises.map((it) => ({
                ...it,
                superset:
                  it.superset === left.superset ||
                  it.superset === right.superset
                    ? left.superset
                    : it.superset,
              })),
        });
      }
    },
    [exercises, onUpdate, section],
  );

  let position = 0;
  return (
    <Box className={clsx(s.root, className)} {...other}>
      {exercises.map((exercise, index) => (
        <React.Fragment key={exercise.id}>
          {index > 0 && (
            <WorkoutExerciseDivider
              data-index={index}
              active={isSupersetAtIndex(exercises, index)}
              onClick={handleToggleSuperset}
            />
          )}
          <WorkoutExerciseItem
            itemPosition={position++}
            exercise={exercise}
            onEdit={onEditExercise}
            onRemove={onRemoveExercise}
            onMove={handleMove}
            onMoveEnd={handleMoveEnd}
          />
        </React.Fragment>
      ))}
      {children}
    </Box>
  );
}
