import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { Activity } from "../../../../../components/activity/Activity";
import { CoachClientProgramComponentRouteQuery } from "./__generated__/CoachClientProgramComponentRouteQuery.graphql";
import AppLayout from "../../../../../components/app/AppLayout";

export function CoachClientProgramComponentRoute() {
  const { username, program, date, component } = useParams();

  const props = useLazyLoadQuery<CoachClientProgramComponentRouteQuery>(
    graphql`
      query CoachClientProgramComponentRouteQuery(
        $username: String!
        $date: String!
        $component: String!
        $shouldFetchSummary: Boolean!
      ) {
        activity(client: $username, date: $date, component: $component) {
          ...Activity_activity
            @arguments(shouldFetchSummary: $shouldFetchSummary)
          component {
            ...RefreshSlug
            program {
              ...RefreshSlug
            }
          }
        }
      }
    `,
    {
      component: component,
      program: program,
      date: date,
      shouldFetchSummary: true,
      username: username,
    },
  );

  const { activity } = props;
  return (
    activity && (
      <AppLayout appBar={false}>
        <RefreshSlug
          nodesRef={[activity.component, activity.component?.program]}
        />
        <Activity activityRef={activity} coach />
      </AppLayout>
    )
  );
}
