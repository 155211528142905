/**
 * @generated SignedSource<<db665fc74e4a5d6717e81692b143cd9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type PlanTierType = "BUILD" | "DEFAULT" | "LAUNCH" | "SCALE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProUpgradeCard_user$data = {
  readonly clientsCountNoSample: number;
  readonly displayName: string | null | undefined;
  readonly id: string;
  readonly plan: Plan | null | undefined;
  readonly planTier: PlanTierType | null | undefined;
  readonly subscription: {
    readonly status: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ProUpgradeCard_user";
};
export type ProUpgradeCard_user$key = {
  readonly " $data"?: ProUpgradeCard_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProUpgradeCard_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProUpgradeCard_user",
  "selections": [
    {
      "alias": "clientsCountNoSample",
      "args": [
        {
          "kind": "Literal",
          "name": "noSample",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "clientsCount",
      "storageKey": "clientsCount(noSample:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planTier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4d2745054e41613e56f51e42078e981a";

export default node;
