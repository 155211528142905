import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { sortBy } from "lodash";

import { getWeekLabel, getWeekISODates } from "../../utils/date";
import { ClientCircularProgress } from "../progress/ClientCircularProgress";
import { ActivityByTypesList } from "../activity/ActivityByTypesList";

import { ClientProgram_enrollment$data } from "./__generated__/ClientProgram_enrollment.graphql";
import { ClientProgramWeek_activities$key } from "./__generated__/ClientProgramWeek_activities.graphql";
import { ClientProgramWeek_completion$key } from "./__generated__/ClientProgramWeek_completion.graphql";
import { EditorValue } from "./EditorValue";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0),
    },
  },
  headerLeft: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "left",
    justifyContent: "center",
  },
  title: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 600,
  },
  label: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  description: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(4),
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 5,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  circularProgress: {
    marginLeft: theme.spacing(3.75),
    alignSelf: "start",
  },
}));

const completionFragment = graphql`
  fragment ClientProgramWeek_completion on Completion {
    rate
  }
`;

const activitiesFragment = graphql`
  fragment ClientProgramWeek_activities on Activity @relay(plural: true) {
    week
    date(raw: true)
    ...ActivityByTypesList_activities
  }
`;

export interface ClientProgramWeekProps extends BoxProps {
  // week: ClientProgram_enrollment$data["program"]["weeks"][0];
  week: any;
  startDate: ClientProgram_enrollment$data["startDate"];
  activitiesRef: ClientProgramWeek_activities$key;
  completionRef: ClientProgramWeek_completion$key;
}

export function ClientProgramWeek(props: ClientProgramWeekProps) {
  const { className, week, startDate, activitiesRef, completionRef } = props;
  const s = useStyles();
  const completion = useFragment(completionFragment, completionRef);
  const progress = completion.rate;
  const activities = useFragment(activitiesFragment, activitiesRef);

  const weekDates = React.useMemo(() => {
    return getWeekISODates(startDate, week.node.week);
  }, [startDate, week]);

  const weekActivities = React.useMemo(
    () =>
      sortBy(
        activities.filter((it) => it.week === week.node.week),
        (it) => it.date,
      ),
    [activities, week],
  );

  return (
    <Box className={clsx(s.root, className)}>
      <Box className={s.header}>
        <Box className={s.headerLeft}>
          <Typography variant="h5" className={s.title}>
            Week {week.node.week}
          </Typography>
          <Typography variant="body1" className={s.label}>
            {getWeekLabel(weekDates[0], weekDates[6], true)}
          </Typography>
          <EditorValue
            className={s.description}
            value={week.node.description}
            multiline
          />
        </Box>
        <ClientCircularProgress
          className={s.circularProgress}
          value={progress}
          size="normal"
        >
          <Typography
            className={s.progress}
            variant="h5"
            component="div"
            children={`${progress}%`}
          />
        </ClientCircularProgress>
      </Box>

      <ActivityByTypesList
        activitiesRef={weekActivities}
        week={week}
        variant="summary"
      />
    </Box>
  );
}
