/**
 * @generated SignedSource<<eb3d822b5a0537222777890fba3a3357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type UpsertComponentTemplateInput = {
  clientMutationId?: string | null | undefined;
  componentType?: ComponentType;
  content: string;
  habitPrompt?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  title: string;
};
export type EditorMenuCreateTemplateMutation$variables = {
  input: UpsertComponentTemplateInput;
};
export type EditorMenuCreateTemplateMutation$data = {
  readonly upsertComponentTemplate: {
    readonly template: {
      readonly " $fragmentSpreads": FragmentRefs<"ComponentTemplatePreview_template">;
    };
  } | null | undefined;
};
export type EditorMenuCreateTemplateMutation = {
  response: EditorMenuCreateTemplateMutation$data;
  variables: EditorMenuCreateTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorMenuCreateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentTemplatePayload",
        "kind": "LinkedField",
        "name": "upsertComponentTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ComponentTemplate",
            "kind": "LinkedField",
            "name": "template",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ComponentTemplatePreview_template"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorMenuCreateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentTemplatePayload",
        "kind": "LinkedField",
        "name": "upsertComponentTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ComponentTemplate",
            "kind": "LinkedField",
            "name": "template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "habitPrompt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "private",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconName",
                    "storageKey": null
                  }
                ],
                "type": "ComponentIcon",
                "abstractKey": "__isComponentIcon"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67f10cc94ac209959981be532341c212",
    "id": null,
    "metadata": {},
    "name": "EditorMenuCreateTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation EditorMenuCreateTemplateMutation(\n  $input: UpsertComponentTemplateInput!\n) {\n  upsertComponentTemplate(input: $input) {\n    template {\n      ...ComponentTemplatePreview_template\n      id\n    }\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment ComponentTemplatePreview_template on ComponentTemplate {\n  ...ComponentIcon_component\n  id\n  title\n  content\n  type\n  habitPrompt\n  private\n}\n"
  }
};
})();

(node as any).hash = "f13f580906928474b29be64980daab24";

export default node;
