import { createContext } from "react";

export interface ICompactDateDialogContextProps {
  dateDialogOpen: boolean;
  setDateDialogOpen: (openModal: boolean) => void;
}

const CompactDateDialogContext = createContext<
  ICompactDateDialogContextProps | undefined
>(undefined);

export default CompactDateDialogContext;
