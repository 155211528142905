import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Typography, Box, IconButton } from "@mui/material";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { AppLayout } from "../app/AppLayout";
import { ReactComponent as ArrowForwardIcon } from "../../icons/ArrowForwardOutline.svg";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";

import { Link } from "../link/Link";
import { ProgramTemplatesList } from "../program/ProgramTemplatesList";

import { ProgramTemplatesScreen$key } from "./__generated__/ProgramTemplatesScreen.graphql";
import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../routes/routes";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    margin: theme.spacing(5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
  },

  button: {
    backgroundColor: "transparent",
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(-2),

    "& span": {
      fontSize: 18,
      fontWeight: 600,
      margin: theme.spacing(0, 0.5),
    },
  },

  backIcon: {
    "& svg": {
      transform: "rotate(180deg)",
      width: 24,
      height: 24,
    },
  },
}));

const fragment = graphql`
  fragment ProgramTemplatesScreen on Root {
    programTemplates {
      totalCount
      ...ProgramTemplatesList_connection
    }
  }
`;

export interface ProgramTemplatesScreenProps {
  rootRef: ProgramTemplatesScreen$key;
}

export function ProgramTemplatesScreen(props: ProgramTemplatesScreenProps) {
  const backUrl = COACH_PROGRAMS_PUBLISHED_ROUTE;
  const s = useStyles();
  const location = useLocation();
  const { rootRef } = props;
  const { programTemplates } = useFragment(fragment, rootRef);

  return (
    <AppLayout
      appBar={false}
      appDrawer={false}
      trackInfo={{
        name: "Coach - Program Templates",
      }}
      hideUpgradeBanner={location.pathname.endsWith("/new")}
    >
      <Container className={s.root}>
        <Box className={s.header}>
          <Box>
            <Link href={backUrl} underline="none">
              <IconButton className={clsx(s.button, s.backIcon)} size="large">
                <ArrowForwardIcon />
                <Typography component="span">Back</Typography>
              </IconButton>
            </Link>
            <Typography variant="h2" className={s.title} gutterBottom>
              Choose a program template
            </Typography>
            <Typography variant="body1" className={s.text}>
              {programTemplates.totalCount} templates available
            </Typography>
          </Box>

          <Link href={backUrl} underline="none">
            <IconButton className={s.button} size="large">
              <CloseIcon />
            </IconButton>
          </Link>
        </Box>

        <ProgramTemplatesList templatesRef={programTemplates} />
      </Container>
    </AppLayout>
  );
}
