import clsx from "clsx";
import React from "react";
import { DialogProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLazyLoadQuery } from "react-relay/hooks";

import { BaseDialog } from "../dialog/BaseDialog";
import { SearchField } from "../fields/SearchField";
import { isMobileApp } from "../../utils/mobile";

import {
  RecipientList,
  RecipientListQuery,
  RecipientListProps,
} from "./RecipientList";
import { RecipientListRefetchQuery } from "./__generated__/RecipientListRefetchQuery.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {},
}));

export interface RecipientDialogProps
  extends Omit<DialogProps, "onSelect">,
    Pick<RecipientListProps, "onSelect"> {}

export function RecipientDialog(props: RecipientDialogProps) {
  const s = useStyles();
  const { onSelect, className, ...other } = props;
  const [query, setQuery] = React.useState("");

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setQuery(event.currentTarget.value);
    },
    [],
  );

  const root = useLazyLoadQuery<RecipientListRefetchQuery>(
    RecipientListQuery,
    {},
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
      }}
      title="New message"
      {...other}
    >
      <SearchField
        variant="outlined"
        fullWidth
        placeholder="Search clients"
        value={query}
        onChange={handleChange}
      />

      <RecipientList rootRef={root} query={query} onSelect={onSelect} />
    </BaseDialog>
  );
}
