import { TRAINING_SECTION } from "../../utils/menuSectionsUtil";
import {
  BasicToolbarMenu,
  CheckinToolbarMenu,
  MediaToolbarMenu,
  Schema,
} from "./Schema";

export const CoachComponentEditorSchema: Schema = {
  generalDropDownMenuItems: [
    ...BasicToolbarMenu.sections,
    ...MediaToolbarMenu.sections,
    TRAINING_SECTION,
    ...CheckinToolbarMenu.sections,
  ],
  stickyToolbar: true,
};
