import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, usePaginationFragment } from "react-relay";

import { ActivityFeedbackButton } from "../activity-feedback/ActivityFeedbackButton";
import { getISODate } from "../../utils/date";

import { ClientMealLoggingEmpty } from "./ClientMealLoggingEmpty";
import { ClientMealLoggingButton } from "./ClientMealLoggingButton";
import { ClientMealLoggingEntryDialog } from "./ClientMealLoggingEntryDialog";
import { ClientMealLoggingEntriesList_user$key } from "./__generated__/ClientMealLoggingEntriesList_user.graphql";
import { ClientMealLoggingEntriesList_workspace$key } from "./__generated__/ClientMealLoggingEntriesList_workspace.graphql";
import { ClientMealLoggingEntriesListItem } from "./ClientMealLoggingEntriesListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingBottom: theme.spacing(10),
  },
}));

const userFragment = graphql`
  fragment ClientMealLoggingEntriesList_user on User
  @refetchable(queryName: "ClientMealLoggingEntriesListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    date: { type: "String!" }
  ) {
    mealLoggingEntries(first: $first, after: $after, date: $date)
      @connection(
        key: "ClientMealLoggingEntriesList_mealLoggingEntries"
        filters: []
      ) {
      activity {
        ...ActivityFeedbackButton_activity
      }
      edges {
        node {
          id
          ...ClientMealLoggingEntriesListItem_mealLoggingEntry
          ...ClientMealLoggingEntryDialog_mealLoggingEntry
        }
      }
    }
  }
`;

const workspaceFragment = graphql`
  fragment ClientMealLoggingEntriesList_workspace on Workspace {
    ...ClientMealLoggingEntryDialog_workspace
  }
`;

export interface ClientMealLoggingEntriesListProps extends BoxProps {
  userRef: ClientMealLoggingEntriesList_user$key;
  workspaceRef: ClientMealLoggingEntriesList_workspace$key;
  date: string;
}

export function ClientMealLoggingEntriesList(
  props: ClientMealLoggingEntriesListProps,
) {
  const s = useStyles();
  const { className, userRef, workspaceRef, date, ...other } = props;
  const {
    data: user,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(userFragment, userRef);
  const { mealLoggingEntries } = user;

  const workspace = useFragment(workspaceFragment, workspaceRef);

  const today = date === getISODate();
  const future = new Date(date).getTime() > new Date(getISODate()).getTime();
  const pageSize = 10;

  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedEntryId, setSelectedEntryId] = React.useState<string>();

  const handleMoreClick = React.useCallback(() => {
    loadNext(pageSize);
  }, [loadNext]);

  const handleEditOpen = React.useCallback(() => {
    setOpenEdit(true);
  }, []);

  const handleEditClose = React.useCallback(() => {
    setOpenEdit(false);
    setSelectedEntryId(null);
  }, []);

  const handleEntryEditClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {
        dataset: { entryId },
      } = event.currentTarget;

      if (entryId) {
        setSelectedEntryId(entryId);
        setOpenEdit(true);
      }
    },
    [],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {mealLoggingEntries.edges.length ? (
        <>
          {mealLoggingEntries.edges.map(({ node }) => (
            <ClientMealLoggingEntriesListItem
              key={node.id}
              mealLoggingEntryRef={node}
              onEditClick={handleEntryEditClick}
            />
          ))}

          {hasNext && (
            <Button
              onClick={handleMoreClick}
              disabled={isLoadingNext}
              fullWidth
            >
              Show more
            </Button>
          )}

          {mealLoggingEntries.activity && (
            <ActivityFeedbackButton
              activity={mealLoggingEntries.activity}
              hideWhenEmpty
            />
          )}
        </>
      ) : (
        <ClientMealLoggingEmpty today={today} />
      )}

      {!future && <ClientMealLoggingButton onClick={handleEditOpen} />}

      {openEdit && (
        <ClientMealLoggingEntryDialog
          open
          date={date}
          workspace={workspace}
          mealLoggingEntry={
            mealLoggingEntries.edges
              .map(({ node }) => node)
              .find(({ id }) => id === selectedEntryId) || null
          }
          onClose={handleEditClose}
        />
      )}
    </Box>
  );
}
