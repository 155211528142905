import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
  LocalStorageKeys,
  Units,
  UnitsTime,
} from "../constants";

interface ExerciseConfig {
  typeReps: ExerciseTypeReps;
  typeSet: ExerciseTypeSet;
  units: Units | UnitsTime;
  typeExtraMeasurement: ExerciseTypeExtra;
}

const defaultExerciseConfig: ExerciseConfig = {
  typeReps: ExerciseTypeReps.WHOLE,
  typeSet: ExerciseTypeSet.WEIGHT,
  units: Units.METRIC,
  typeExtraMeasurement: undefined,
};

export const getExerciseLocalStorageConfig = (): ExerciseConfig => {
  const config = localStorage.getItem(
    LocalStorageKeys.LAST_CREATED_EXERCISE_CONFIG,
  );
  return config ? JSON.parse(config) : { ...defaultExerciseConfig };
};

export const updateExerciseLocalStorageConfig = (
  updatedFields: Partial<ExerciseConfig>,
) => {
  const currentConfig = getExerciseLocalStorageConfig();
  const newConfig = { ...currentConfig, ...updatedFields };
  localStorage.setItem(
    LocalStorageKeys.LAST_CREATED_EXERCISE_CONFIG,
    JSON.stringify(newConfig),
  );
};
