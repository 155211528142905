import clsx from "clsx";
import React from "react";
import { Button, DialogProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFragment, useMutation, graphql } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { ConfirmRemoveFromProgramDialog_enrollment$key } from "./__generated__/ConfirmRemoveFromProgramDialog_enrollment.graphql";
import { BaseDialog } from "./BaseDialog";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
  },

  button: {
    width: `calc(50% - ${theme.spacing(1)})`,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    padding: theme.spacing(2.25, 0),
  },

  cancel: {
    borderWidth: 2,
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },

  remove: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

const enrollmentFragment = graphql`
  fragment ConfirmRemoveFromProgramDialog_enrollment on Enrollment {
    id
    client {
      displayName
    }
    program {
      name
    }
  }
`;

const unenrollMutation = graphql`
  mutation ConfirmRemoveFromProgramDialogUnenrollMutation(
    $input: UnenrollClientInput!
  ) {
    unenrollClient(input: $input) {
      clientMutationId
    }
  }
`;

export interface ConfirmRemoveFromProgramDialogProps extends DialogProps {
  enrollmentRef: ConfirmRemoveFromProgramDialog_enrollment$key;
  onRemove?: () => void;
}

export function ConfirmRemoveFromProgramDialog(
  props: ConfirmRemoveFromProgramDialogProps,
) {
  const { className, enrollmentRef, onRemove, onClose, ...other } = props;
  const s = useStyles();
  const { id, client, program } = useFragment(
    enrollmentFragment,
    enrollmentRef,
  );
  const [unenroll, unenrollInFlight] = useMutation(unenrollMutation);
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();

  const handleCancelClick = React.useCallback(
    (event) => onClose(event, "backdropClick"),
    [onClose],
  );

  const handleRemoveClick = React.useCallback(
    (event) => {
      unenroll({
        variables: { input: { id } },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Client removed from program.",
            });
            onClose(event, "backdropClick");

            if (onRemove) {
              onRemove();
            }
          }
        },
        onError,
        updater: (store) => {
          const enrollments = ConnectionHandler.getConnection(
            store.getRoot(),
            "CoachClientCalendar_enrollments",
            [],
          );

          if (enrollments) {
            ConnectionHandler.deleteNode(enrollments, id);
          }
        },
      });
    },
    [id, onClose, onError, onRemove, snackAlert, unenroll],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`Remove ${client.displayName} from ${program.name}?`}
      onClose={onClose}
      PaperProps={{
        className: s.paper,
      }}
      {...other}
    >
      <Box className={s.buttons}>
        <Button
          className={clsx(s.button, s.cancel)}
          fullWidth
          variant="outlined"
          children="Cancel"
          onClick={handleCancelClick}
          disabled={unenrollInFlight}
        />
        <Button
          className={clsx(s.button, s.remove)}
          fullWidth
          variant="contained"
          children="Remove"
          onClick={handleRemoveClick}
          disabled={unenrollInFlight}
        />
      </Box>
    </BaseDialog>
  );
}
