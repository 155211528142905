import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { StarRating } from "../fields/StarRating";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create(["height"]),
  },

  response: {
    marginTop: theme.spacing(2),
    pointerEvents: "none",
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.quote}`,
    },
  },

  starRating: {
    marginBottom: theme.spacing(1),
  },

  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

interface Response {
  starRating: number;
  date: string;
}

export interface StarRatingResponsesSummaryProps extends BoxProps {
  responses: readonly Response[];
}

export function StarRatingResponsesSummary(
  props: StarRatingResponsesSummaryProps,
) {
  const { className, responses, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {responses
        .filter((x) => x !== null)
        .map(({ starRating, date }, index) => (
          <Box key={index} className={s.response}>
            <StarRating className={s.starRating} value={starRating} small />
            <Typography className={s.date} children={date} />
          </Box>
        ))}
    </Box>
  );
}
