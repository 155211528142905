import clsx from "clsx";
import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import { RenderElementProps } from "slate-react";
import { Node } from "slate";

import { colorSystem } from "../../../../theme";
import { useReadOnly } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2.5),

    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
  },

  empty: {
    position: "relative",
    "&::before": {
      position: "absolute",
      color: colorSystem.gray7,
      content: '"Content"',
    },
  },
}));

// export interface TableCellElementProps extends RenderElementProps {}

export const ELEMENT_TABLE_CELL = "table_cell";

export function TableCellElement(props: any) {
  const s = useStyles();
  const { element, children, attributes } = props;
  const readOnly = useReadOnly();
  const empty = !readOnly && !Node.string(element);

  return (
    <Typography
      component="td"
      className={clsx(s.root, empty && s.empty)}
      data-table-row-index={element.rowIndex}
      data-table-column-index={element.columnIndex}
      {...attributes}
    >
      {children}
    </Typography>
  );
}
