/**
 * @generated SignedSource<<6a441e42a3e6c4a5f2c5aa4adabe7049>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateClientMetricInput = {
  clientMutationId?: string | null | undefined;
  date?: string | null | undefined;
  id: string;
  measurement: number;
  unit: string;
};
export type ClientStepsDrawerUpdateClientMetricMutation$variables = {
  input: UpdateClientMetricInput;
};
export type ClientStepsDrawerUpdateClientMetricMutation$data = {
  readonly updateClientMetric: {
    readonly metric: {
      readonly activityDate: string | null | undefined;
      readonly value: {
        readonly measurement?: number;
      };
      readonly x: string | null | undefined;
    };
  } | null | undefined;
};
export type ClientStepsDrawerUpdateClientMetricMutation = {
  response: ClientStepsDrawerUpdateClientMetricMutation$data;
  variables: ClientStepsDrawerUpdateClientMetricMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": "x",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "YYYY-MM-DD"
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(format:\"YYYY-MM-DD\")"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(raw:true)"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurement",
      "storageKey": null
    }
  ],
  "type": "CheckinAnswerMeasurementValue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientStepsDrawerUpdateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientMetricPayload",
        "kind": "LinkedField",
        "name": "updateClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientStepsDrawerUpdateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientMetricPayload",
        "kind": "LinkedField",
        "name": "updateClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a9ca4b03c75a4ccd60d72f4d16925d9",
    "id": null,
    "metadata": {},
    "name": "ClientStepsDrawerUpdateClientMetricMutation",
    "operationKind": "mutation",
    "text": "mutation ClientStepsDrawerUpdateClientMetricMutation(\n  $input: UpdateClientMetricInput!\n) {\n  updateClientMetric(input: $input) {\n    metric {\n      x: activityDate(format: \"YYYY-MM-DD\")\n      activityDate(raw: true)\n      value {\n        __typename\n        ... on CheckinAnswerMeasurementValue {\n          measurement\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da9f5219467791d2cfbbd31c88933922";

export default node;
