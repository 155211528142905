import React, { useTransition } from "react";
import {
  Drawer,
  DrawerProps,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { ClientMeasurementsCartDrawerEditView } from "./ClientMeasurementsCartDrawerEditView";
import { graphql, useRefetchableFragment } from "react-relay";
import { measurementTypes, Units } from "../../constants";
import { ClientMeasurementsCartDrawer_user$key } from "./__generated__/ClientMeasurementsCartDrawer_user.graphql";
import { toTrendMetricType } from "../client-measurement/ClientMeasurementScreen";
import { ClientStepsDrawerHeader } from "../client-steps/ClientStepsDrawerHeader";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },
  header: {
    marginBottom: theme.spacing(4),
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  sortOption: {
    fontWeight: 500,
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
  },

  sort: {},

  overlay: {},
}));

const fragment = graphql`
  fragment ClientMeasurementsCartDrawer_user on User
  @refetchable(queryName: "ClientMeasurementsCartDrawerRefetchQuery")
  @argumentDefinitions(measurementType: { type: "MeasurementType" }) {
    id
    units

    nutritionTarget {
      trackingType
    }

    metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: $measurementType
    ) {
      ...ClientMeasurementsCartDrawerEditView_metrics
    }
  }
`;

export interface ClientMeasurementsCartDrawerProps extends DrawerProps {
  today?: any;
  clientId?: string;
  setSteps?: (steps: any[]) => void;
  steps?: any[];
  userRef?: ClientMeasurementsCartDrawer_user$key;
}

export function ClientMeasurementsCartDrawer(
  props: ClientMeasurementsCartDrawerProps,
) {
  const { onClose, open, userRef } = props;
  const [user, refetch] = useRefetchableFragment(fragment, userRef);

  const s = useStyles();
  const metrics = user.metrics && user.metrics.filter(Boolean);
  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);
  const [selectType, setSelectType] = React.useState<string>("bodyfat");

  // TODO handle loading state with isPending
  const [isPending, startTransition] = useTransition();

  const handleChangeMetricType = React.useCallback((value) => {
    setSelectType(value);
    const variables = {
      measurementType: toTrendMetricType(value).toUpperCase(),
    };
    startTransition(() => {
      refetch(variables, RELAY_LAZY_LOAD_COMMON_CONFIG);
    });
  }, []);

  const handleMeasurementChanged = React.useCallback(() => {
    const variables = {
      measurementType: toTrendMetricType(selectType).toUpperCase(),
    };
    startTransition(() => {
      refetch(variables, RELAY_LAZY_LOAD_COMMON_CONFIG);
    });
  }, [selectType]);

  const units = user.units as Units;
  const items = React.useMemo(
    () =>
      measurementTypes
        .filter(({ label }) => label !== "Bodyweight")
        .map(({ label, name }) => ({ label, name })),
    [],
  );

  return (
    <Drawer
      className={clsx(s.root)}
      classes={{
        paper: clsx(s.paper),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={
        <>
          <ClientStepsDrawerHeader
            className={s.header}
            onClose={handleClose}
            title="Measurements"
          />
          <Select
            className={clsx(s.select, s.sort)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
            variant="outlined"
            fullWidth
            onChange={(e) => handleChangeMetricType(e.target.value)}
            value={selectType}
          >
            {items.map(({ label, name }) => (
              <MenuItem className={s.sortOption} key={label} value={name}>
                <ListItemText
                  classes={{ primary: s.primaryText }}
                  primary={label}
                />
              </MenuItem>
            ))}
          </Select>
          <ClientMeasurementsCartDrawerEditView
            onClose={handleClose}
            metrics={metrics}
            clientId={user.id}
            units={units}
            onMeasurementChanged={handleMeasurementChanged}
            selectType={selectType}
          />
        </>
      }
    />
  );
}
