import React from "react";

import { useCurrentUser } from "./useCurrentUser";
import dayjs from "dayjs";

type TimeZoneOption = {
  value: string;
  label: string;
  separate?: boolean;
};

const COMMON = [
  "US/Hawaii",
  "US/Alaska",
  "US/Pacific",
  "US/Arizona",
  "US/Mountain",
  "US/Central",
  "US/Eastern",
  "US/East-Indiana",
];

export function useTimeZones(): TimeZoneOption[] {
  const user = useCurrentUser();
  const userTimeZone = user?.timeZone;

  const timeZones: TimeZoneOption[] = React.useMemo(
    () =>
      Intl.supportedValuesOf("timeZone").map((timeZone) => {
        return {
          value: timeZone,
          label: `(GMT${dayjs.tz(dayjs(), timeZone).format("Z")}) ${timeZone}`,
        };
      }),
    [],
  );

  const userTimeZoneIndex = React.useMemo(
    () => timeZones.findIndex(({ value }) => value === userTimeZone),
    [timeZones, userTimeZone],
  );

  return React.useMemo(() => {
    let _timeZones = [...timeZones];
    if (userTimeZoneIndex !== -1) {
      const [item] = _timeZones.splice(userTimeZoneIndex, 1);
      _timeZones = [{ ...item, separate: true }, ...timeZones];
    }

    const commonTimeZones = COMMON.map((value, index) => {
      const itemIndex = timeZones.findIndex(
        (timeZone) => timeZone.value === value,
      );
      const timeZone = _timeZones.splice(itemIndex, 1)[0];

      if (index === COMMON.length - 1) {
        timeZone.separate = true;
      }

      return timeZone;
    });

    _timeZones.splice(userTimeZoneIndex !== -1 ? 1 : 0, 0, ...commonTimeZones);

    return _timeZones;
  }, [timeZones, userTimeZoneIndex]);
}
