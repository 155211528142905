/**
 * @generated SignedSource<<ba9dc3cebbe492a9494bcd97958509be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertAutoNutritionInput = {
  alcohol?: number | null | undefined;
  calories?: number | null | undefined;
  carbs?: number | null | undefined;
  cholesterol?: number | null | undefined;
  clientMutationId?: string | null | undefined;
  date?: string | null | undefined;
  fat?: number | null | undefined;
  fiber?: number | null | undefined;
  id?: string | null | undefined;
  protein?: number | null | undefined;
  sodium?: number | null | undefined;
  sugar?: number | null | undefined;
  transFat?: number | null | undefined;
};
export type ClientAutoNutritionEntryHistoryItemUpdateMutation$variables = {
  input: UpsertAutoNutritionInput;
};
export type ClientAutoNutritionEntryHistoryItemUpdateMutation$data = {
  readonly upsertAutoNutrition: {
    readonly autoNutrition: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ClientAutoNutritionEntryHistoryItemUpdateMutation = {
  response: ClientAutoNutritionEntryHistoryItemUpdateMutation$data;
  variables: ClientAutoNutritionEntryHistoryItemUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertAutoNutritionPayload",
    "kind": "LinkedField",
    "name": "upsertAutoNutrition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoNutrition",
        "kind": "LinkedField",
        "name": "autoNutrition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAutoNutritionEntryHistoryItemUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientAutoNutritionEntryHistoryItemUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "87a51464aa7db2a2dd64099f2ac4ab7e",
    "id": null,
    "metadata": {},
    "name": "ClientAutoNutritionEntryHistoryItemUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ClientAutoNutritionEntryHistoryItemUpdateMutation(\n  $input: UpsertAutoNutritionInput!\n) {\n  upsertAutoNutrition(input: $input) {\n    autoNutrition {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "54621ffdfe9d32cceb0fe6dc9129e62f";

export default node;
