import React from "react";

import { ComponentType } from "../../constants";

import { ComponentTemplateButton } from "./ComponentTemplateButton";
import { ComponentTemplatesContext } from "./context";
import {
  ComponentTemplateDialog,
  ComponentTemplate,
} from "./ComponentTemplateDialog";

export interface UseComponentTemplateProps {
  onApplyTemplate?: (template: ComponentTemplate) => void;
  componentType?: ComponentType;
}

export function UseComponentTemplate(props: UseComponentTemplateProps) {
  const { onApplyTemplate, componentType } = props;
  const rootRef = React.useContext(ComponentTemplatesContext);
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelect = React.useCallback(
    (event, template) => {
      if (onApplyTemplate) {
        onApplyTemplate(template);
      }
    },
    [onApplyTemplate],
  );

  return (
    <>
      <ComponentTemplateButton onClick={handleOpen} />
      {open && (
        <ComponentTemplateDialog
          open={open}
          rootRef={rootRef}
          onClose={handleClose}
          onSelectTemplate={handleSelect}
          componentType={componentType}
        />
      )}
    </>
  );
}
