import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
  },

  close: {
    marginLeft: theme.spacing(-1.5),
    marginBottom: theme.spacing(2),
  },
}));

export interface ClientGoalsDrawerHeaderProps extends BoxProps {
  onClose: () => void;
  disabled?: boolean;
  title?: string;
}

export function ClientStepsDrawerHeader(props: ClientGoalsDrawerHeaderProps) {
  const { className, title, onClose, disabled = false, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box>
        <IconButton
          className={s.close}
          color="primary"
          children={<CloseIcon />}
          onClick={onClose}
          disabled={disabled}
          size="large"
        />
      </Box>
      <Box>
        <Typography className={s.title} variant="h2" children={title} />
      </Box>
    </Box>
  );
}
