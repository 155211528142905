import React from "react";

const CloseIcon = ({ fill }) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0384 8.48379L16.7381 2.78379C17.0087 2.51374 17.1608 2.1473 17.1612 1.76506C17.1615 1.38282 17.01 1.01611 16.7399 0.745587C16.4699 0.475066 16.1035 0.3229 15.7213 0.322562C15.339 0.322225 14.9723 0.473744 14.7018 0.743787L9.00204 6.44379L3.30225 0.743787C3.03174 0.473266 2.66485 0.321289 2.28229 0.321289C1.89973 0.321289 1.53284 0.473266 1.26233 0.743787C0.991815 1.01431 0.839844 1.38121 0.839844 1.76379C0.839844 2.14636 0.991815 2.51327 1.26233 2.78379L6.96211 8.48379L1.26233 14.1838C0.991815 14.4543 0.839844 14.8212 0.839844 15.2038C0.839844 15.5864 0.991815 15.9533 1.26233 16.2238C1.53284 16.4943 1.89973 16.6463 2.28229 16.6463C2.66485 16.6463 3.03174 16.4943 3.30225 16.2238L9.00204 10.5238L14.7018 16.2238C14.9723 16.4943 15.3392 16.6463 15.7218 16.6463C16.1043 16.6463 16.4712 16.4943 16.7417 16.2238C17.0123 15.9533 17.1642 15.5864 17.1642 15.2038C17.1642 14.8212 17.0123 14.4543 16.7417 14.1838L11.0384 8.48379Z"
      fill={fill}
    />
  </svg>
);

export default CloseIcon;
