import { FC } from "react";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { createNodesWithHOC } from "@udecode/plate-common";
import {
  WithDraggableOptions,
  withDraggable as withDraggablePrimitive,
} from "@udecode/plate-dnd";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";
import { ELEMENT_OL, ELEMENT_TODO_LI, ELEMENT_UL } from "@udecode/plate-list";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { Draggable, DraggableProps } from "./draggable";
import { ELEMENT_CALLOUT } from "../new-editor/elements/Callout";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule";
import { ELEMENT_TOGGLE } from "@udecode/plate-toggle";
import { ELEMENT_PULLQUOTE } from "../new-editor/elements/Pullquote";
import { EMBED_WEBSITE } from "../new-editor/elements/MediaEmbed";
import { WEB_BOOKMARK } from "../new-editor/elements/web-bookmark/WebBookmark";
import { VIDEO } from "../new-editor/elements/Video";
import { AUDIO } from "../new-editor/elements/Audio";
import { IMAGE_ELEMENT } from "../new-editor/elements/Image";
import { FILE } from "../new-editor/elements/FileUploader";
import { CheckInTypes } from "../new-editor/utils/menuItemUtil";
import { IMenuSection } from "../new-editor/utils/menuSectionsUtil";
import { withAdditionalProps } from "../new-editor/utils/with-custom-props";
import {
  ELEMENT_ACCORDION,
  ELEMENT_TABS,
} from "../new-editor/elements/tabs/Tabs";
import { WORKOUT_SECTION } from "../new-editor/components/workout/WorkoutSectionElement";

export const withDraggable = (
  Component: FC,
  options?: WithDraggableOptions<
    Partial<Omit<DraggableProps, "editor" | "element" | "children">>
  >,
  generalDropDownMenuItems?: IMenuSection[],
  showInsertDropdownOnDraggable: boolean = true,
) =>
  withDraggablePrimitive<DraggableProps>(
    withAdditionalProps(Draggable, {
      generalDropDownMenuItems: generalDropDownMenuItems,
      showInsertDropdownOnDraggable: showInsertDropdownOnDraggable,
    }),

    Component,
    options as any,
  );

const withDraggablesPrimitive = (
  components: any,
  configurations: any[],
  generalDropDownMenuItems?: IMenuSection[],
  showInsertDropdownOnDraggable: boolean = true,
) => {
  const enhancedConfigurations = configurations.map((config) => ({
    ...config,
    draggableProps: {
      ...config.draggableProps,
      generalDropDownMenuItems: generalDropDownMenuItems,
      showInsertDropdownOnDraggable: showInsertDropdownOnDraggable,
    },
  }));
  return createNodesWithHOC(withDraggable)(components, enhancedConfigurations);
};

export const withDraggables = (
  components: any,
  generalDropDownMenuItems: IMenuSection[],
  showInsertDropdownOnDraggable: boolean = true,
) => {
  return withDraggablesPrimitive(
    components,
    [
      {
        keys: [ELEMENT_PARAGRAPH, ELEMENT_UL, ELEMENT_OL],
        level: null,
      },
      {
        key: ELEMENT_H1,
        draggableProps: {
          classNames: {
            gutterLeft: "px-0 pb-1 text-[1.875em]",
            blockToolbarWrapper: "h-[1.3em]",
          },
        },
        level: null,
      },
      {
        key: ELEMENT_H2,
        draggableProps: {
          classNames: {
            gutterLeft: "px-0 pb-1 text-[1.5em]",
            blockToolbarWrapper: "h-[1.3em]",
          },
        },
        level: null,
      },
      {
        key: ELEMENT_H3,
        draggableProps: {
          classNames: {
            gutterLeft: "pt-[2px] px-0 pb-1 text-[1.25em]",
            blockToolbarWrapper: "h-[1.3em]",
          },
        },
      },
      {
        keys: [ELEMENT_H4, ELEMENT_H5],
        draggableProps: {
          classNames: {
            gutterLeft: "pt-[3px] px-0 pb-0 text-[1.1em]",
            blockToolbarWrapper: "h-[1.3em]",
          },
        },
      },
      {
        keys: [ELEMENT_PARAGRAPH],
        draggableProps: {
          classNames: {
            gutterLeft: "pt-[3px] px-0 pb-0",
          },
        },
        level: null,
      },
      {
        keys: [ELEMENT_H6, ELEMENT_UL, ELEMENT_OL],
        draggableProps: {
          classNames: {
            gutterLeft: "px-0 pb-0",
          },
        },
      },
      {
        key: ELEMENT_BLOCKQUOTE,
        draggableProps: {
          classNames: {
            gutterLeft: "px-0 pb-0",
          },
        },
        level: null,
      },
      {
        keys: [
          AUDIO,
          IMAGE_ELEMENT,
          ELEMENT_CALLOUT,
          WEB_BOOKMARK,
          EMBED_WEBSITE,
          VIDEO,
          FILE,
          ELEMENT_TABLE,
          ELEMENT_HR,
          ELEMENT_PULLQUOTE,
          ELEMENT_TOGGLE,
          ELEMENT_TODO_LI,
          ELEMENT_ACCORDION,
          ELEMENT_TABS,
          CheckInTypes.YES_NO,
          CheckInTypes.MEASUREMENT,
          CheckInTypes.PROGRESS_PHOTO,
          CheckInTypes.RATING_SCALE,
          CheckInTypes.LONG_TEXT,
          CheckInTypes.SHORT_TEXT,
          CheckInTypes.NUMBER,
          CheckInTypes.FILE,
          CheckInTypes.MULTIPLE_CHOICE,
          CheckInTypes.MULTIPLE_CHECKBOX,
          CheckInTypes.DATE,
          CheckInTypes.EMOJI,
          CheckInTypes.STAR_RATING,
          CheckInTypes.NUTRITION_TARGET,
          WORKOUT_SECTION,
        ],
        level: null,
      },
    ],
    generalDropDownMenuItems,
    showInsertDropdownOnDraggable,
  );
};
