import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ClientUpcomingProgramCard_enrollment$key } from "./__generated__/ClientUpcomingProgramCard_enrollment.graphql";
import { ClientSmallProgramCard } from "./ClientSmallProgramCard";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(0, 2),
  },

  textWrapper: {
    textAlign: "center",
  },

  bigText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
  },

  smallText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },
}));

const enrollmentFragment = graphql`
  fragment ClientUpcomingProgramCard_enrollment on Enrollment {
    ...ClientSmallProgramCard_enrollment
    totalDays
    startFromNow: startDate(utc: true, format: "fromNow")
    endDateShort: endDate(utc: true, format: "MMM DD, YYYY")
  }
`;

export interface ClientUpcomingProgramCardProps {
  enrollmentRef: ClientUpcomingProgramCard_enrollment$key;
}

export function ClientUpcomingProgramCard(
  props: ClientUpcomingProgramCardProps,
) {
  const { enrollmentRef, ...other } = props;
  const s = useStyles();
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);
  const { totalDays, startFromNow, endDateShort } = enrollment;

  const content = React.useMemo(
    () => (
      <Box className={s.content}>
        <Box className={s.textWrapper}>
          <Typography
            className={s.bigText}
            children={`${totalDays} day${totalDays > 1 ? "s" : ""}`}
          />
          <Typography className={s.smallText} children="Program length" />
        </Box>
        <Box className={s.textWrapper}>
          <Typography className={s.bigText} children={endDateShort} />
          <Typography className={s.smallText} children="End date" />
        </Box>
      </Box>
    ),
    [totalDays, endDateShort], // eslint-disable-line
  );

  return (
    <ClientSmallProgramCard
      enrollmentRef={enrollment}
      subtitle={`Starts ${startFromNow}`}
      content={content}
      {...other}
    />
  );
}
