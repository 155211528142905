import React from "react";

import ErrorPage from "./ErrorPage";

export interface ErrorBoundaryProps {
  children?: any;
}
export interface ErrorBoundaryState {
  error?: any;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const error = this.state?.error;
    const handleClose = () => this.setState({ error: null });

    return error ? (
      <ErrorPage error={error} onClose={handleClose} />
    ) : (
      this.props.children
    );
  }
}
