/**
 * @generated SignedSource<<2a41f02252d756ebb20f64ae931252b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttachmentType = "FILE" | "IMAGE" | "STICKER" | "%future added value";
export type CreateMessageInput = {
  attachments?: ReadonlyArray<InputAttachment> | null | undefined;
  clientMutationId?: string | null | undefined;
  content: string;
  recipient: string;
};
export type InputAttachment = {
  type: AttachmentType;
  url: string;
};
export type NewMessageFormCreateMessageMutation$variables = {
  input: CreateMessageInput;
};
export type NewMessageFormCreateMessageMutation$data = {
  readonly createMessage: {
    readonly clientMutationId: string | null | undefined;
    readonly message: {
      readonly group: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"Message_message">;
    };
  } | null | undefined;
};
export type NewMessageFormCreateMessageMutation = {
  response: NewMessageFormCreateMessageMutation$data;
  variables: NewMessageFormCreateMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": "group",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "YMd"
    }
  ],
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": "createdAt(format:\"YMd\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewMessageFormCreateMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMessagePayload",
        "kind": "LinkedField",
        "name": "createMessage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Message_message"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewMessageFormCreateMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateMessagePayload",
        "kind": "LinkedField",
        "name": "createMessage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": "formattedDate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "dddd DD MMM yyyy"
                  }
                ],
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": "createdAt(format:\"dddd DD MMM yyyy\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bf9ee84c187968b5846c4e8fe92f7ff",
    "id": null,
    "metadata": {},
    "name": "NewMessageFormCreateMessageMutation",
    "operationKind": "mutation",
    "text": "mutation NewMessageFormCreateMessageMutation(\n  $input: CreateMessageInput!\n) {\n  createMessage(input: $input) {\n    clientMutationId\n    message {\n      group: createdAt(format: \"YMd\")\n      ...Message_message\n      id\n    }\n  }\n}\n\nfragment Message_message on Message {\n  id\n  content\n  formattedDate: createdAt(format: \"dddd DD MMM yyyy\")\n  direction\n  author {\n    displayName\n    photoURL\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "99567b19bd8665d06752f5c983ff7483";

export default node;
