import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandMore } from "@mui/icons-material";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { capitalize } from "lodash";

import workingOut from "../../icons/working-out.svg";
import { EnrollmentStatus } from "../../constants";
import { InfoBox } from "../../components/info/InfoBox";
import { ClientUpcomingProgramCard } from "../card/ClientUpcomingProgramCard";
import { ClientPastProgramCard } from "../card/ClientPastProgramCard";
import { ClientActiveProgramCard } from "../card/ClientActiveProgramCard";

import { ClientPrograms_enrollments$key } from "./__generated__/ClientPrograms_enrollments.graphql";
import WorkingOut from "../../icons/WorkingOut";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  panel: {
    background: "transparent !important",
    boxShadow: "none !important",
    border: "none !important",
    padding: 0,
  },

  panelSummary: {
    padding: 0,
    marginLeft: theme.spacing(-1),
    minHeight: `${theme.spacing(6)} !important`,
    "& .MuiExpansionPanelSummary-content": {
      alignItems: "center",
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2, 0),
      },
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(-2),
    },
  },

  panelTitle: {
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },

  panelDetails: {
    padding: 0,
    flexDirection: "column",
    "& .MuiPaper-root": {
      margin: 0,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(3),
      },
    },
  },

  expand: {
    fontSize: 24,
    color: theme.palette.primary.main,
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
    "&$open": {
      transform: "rotate(0)",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
    },
  },

  empty: {
    margin: theme.spacing(12, "auto", 0),
  },

  open: {},
}));

const enrollmentsFragment = graphql`
  fragment ClientPrograms_enrollments on EnrollmentConnection {
    edges {
      node {
        id
        active
        upcoming
        past
        ...ClientActiveProgramCard_enrollment
        ...ClientUpcomingProgramCard_enrollment
        ...ClientPastProgramCard_enrollment
      }
    }
  }
`;

export interface ClientProgramsProps extends Omit<ContainerProps, "children"> {
  enrollmentsRef: ClientPrograms_enrollments$key;
}

export function ClientPrograms(props: ClientProgramsProps) {
  const { className, enrollmentsRef, ...other } = props;
  const s = useStyles();
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const [expanded, setExpanded] = React.useState<EnrollmentStatus>(
    EnrollmentStatus.ACTIVE,
  );

  const EnrollmentCard = React.useMemo(() => {
    switch (expanded) {
      case EnrollmentStatus.UPCOMING:
        return ClientUpcomingProgramCard;
      case EnrollmentStatus.PAST:
        return ClientPastProgramCard;
      default:
        return ClientActiveProgramCard;
    }
  }, [expanded]);

  const items = React.useMemo(
    () => ({
      [EnrollmentStatus.ACTIVE]: enrollments.edges.filter(
        (it) => it.node.active,
      ),
      [EnrollmentStatus.UPCOMING]: enrollments.edges.filter(
        (it) => it.node.upcoming,
      ),
      [EnrollmentStatus.PAST]: enrollments.edges.filter((it) => it.node.past),
    }),
    [enrollments.edges],
  );

  const handlePanelSummaryClick = (panel: EnrollmentStatus) => () => {
    setExpanded((expanded) => (expanded === panel ? null : panel));
  };
  const theme = useTheme();

  return (
    <Container className={clsx(s.root, className)} {...other}>
      {enrollments.edges.length ? (
        Object.values(EnrollmentStatus).map((panel) => (
          <Accordion
            className={s.panel}
            expanded={expanded === panel}
            key={panel}
          >
            <AccordionSummary
              className={s.panelSummary}
              onClick={handlePanelSummaryClick(panel)}
            >
              <ExpandMore
                className={clsx(s.expand, expanded === panel && s.open)}
              />
              <Typography component="h2" className={s.panelTitle}>
                {capitalize(panel)} ({items[panel].length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={s.panelDetails}>
              <Grid container spacing={3}>
                {expanded === panel &&
                  items[panel].map(({ node: enrollment }) => (
                    <Grid key={enrollment.id} item xs={12} sm={6} md={4}>
                      <EnrollmentCard enrollmentRef={enrollment} />
                    </Grid>
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <InfoBox
          className={s.empty}
          image={<WorkingOut fill={theme.palette.primary.main} />}
          title="Not enrolled in a program yet"
          subtitle="Your client’s programs will appear here so you can check their progress."
        />
      )}
    </Container>
  );
}
