/**
 * @generated SignedSource<<0346e36fd56804b40d47c2cdd78aebb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActivityList_activities$data = ReadonlyArray<{
  readonly completed: boolean;
  readonly component: {
    readonly id: string;
    readonly type: ComponentType;
  } | null | undefined;
  readonly date: string | null | undefined;
  readonly id: string;
  readonly week: number;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityCard_activity">;
  readonly " $fragmentType": "ActivityList_activities";
}>;
export type ActivityList_activities$key = ReadonlyArray<{
  readonly " $data"?: ActivityList_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityList_activities">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "compact"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ActivityList_activities",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "compact",
          "variableName": "compact"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ActivityCard_activity"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "week",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};
})();

(node as any).hash = "fdd0fc90da2ea453d8e4d5fecdac6716";

export default node;
