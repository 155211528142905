import { createContext } from "react";
import { CoachClientsListScreen_root$data } from "../components/coach-clients/__generated__/CoachClientsListScreen_root.graphql";

export interface ICoachClientListContextProps {
  coachClientsList: CoachClientsListScreen_root$data;
  setCoachClientsList: (programsData: CoachClientsListScreen_root$data) => void;
}

const CoachClientListContext = createContext<
  ICoachClientListContextProps | undefined
>(undefined);

export default CoachClientListContext;
