import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientProgram_enrollment$data } from "./__generated__/ClientProgram_enrollment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },

  tabs: {
    "& .Mui-disabled": {
      display: "none",
    },
  },

  tab: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
    flexGrow: 1,

    minWidth: 156,
    maxWidth: "100%",

    [theme.breakpoints.up("md")]: {
      minWidth: 253,
    },
  },

  selected: {
    color: theme.palette.primary.main,
  },

  current: {
    "&:not($selected)": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.selected.main,
    },
  },
}));

export interface ClientProgramTimelineProps extends Omit<BoxProps, "onChange"> {
  weeks: ClientProgram_enrollment$data["program"]["weeks"];
  currentWeek: number;
  selected: number;
  setSelected: (x: string) => void;
}

export function ClientProgramTimeline(props: ClientProgramTimelineProps) {
  const { className, weeks, currentWeek, selected, setSelected } = props;
  const s = useStyles();

  const handleChange = React.useCallback(
    (_, value: any) => {
      setSelected(value);
    },
    [setSelected],
  );

  return (
    <Box className={clsx(s.root, className)}>
      <Tabs
        className={s.tabs}
        value={selected}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        aria-label="scrollable force tabs example"
        allowScrollButtonsMobile
      >
        {weeks.edges.map(({ node }, index) => (
          <Tab
            key={index}
            label={`Week ${node.week}`}
            value={node.week}
            className={clsx(s.tab, {
              [s.selected]: node.week === selected,
              [s.current]: node.week === currentWeek,
            })}
          />
        ))}
      </Tabs>
    </Box>
  );
}
