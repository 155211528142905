import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpsertClientGoalMutation } from "./__generated__/UpsertClientGoalMutation.graphql";

const mutation = graphql`
  mutation UpsertClientGoalMutation($input: UpsertClientGoalInput!) {
    upsertClientGoal(input: $input) {
      clientGoalEdge {
        node {
          id
          targetValue
          currentValue
          name
          date(raw: true)
          formattedDate: date(format: "MMM D, YYYY")
          ...ClientGoal_goal
        }
      }
    }
  }
`;

export const useUpsertClientGoalMutation = () => {
  return useConfiguredMutation<UpsertClientGoalMutation>(
    mutation,
    (config) => ({
      configs: config.variables.input.id
        ? []
        : [
            {
              type: "RANGE_ADD",
              parentID: config.variables.input.clientId,
              connectionInfo: [
                {
                  key: "ClientDetails_goals",
                  rangeBehavior: "prepend",
                },
              ],
              edgeName: "clientGoalEdge",
            },
          ],
    }),
  );
};
