/**
 * @generated SignedSource<<2f09b766eff8c7622a2c9ee5bbba07c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HardDeleteClientInput = {
  clientMutationId?: string | null | undefined;
};
export type ClientSettingsProfileHardDeleteMutation$variables = {
  input: HardDeleteClientInput;
};
export type ClientSettingsProfileHardDeleteMutation$data = {
  readonly hardDeleteClient: {
    readonly deleted: boolean | null | undefined;
  } | null | undefined;
};
export type ClientSettingsProfileHardDeleteMutation = {
  response: ClientSettingsProfileHardDeleteMutation$data;
  variables: ClientSettingsProfileHardDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HardDeleteClientPayload",
    "kind": "LinkedField",
    "name": "hardDeleteClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsProfileHardDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSettingsProfileHardDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e958118f8f166d4bbe80ae69ea1f2b60",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsProfileHardDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSettingsProfileHardDeleteMutation(\n  $input: HardDeleteClientInput!\n) {\n  hardDeleteClient(input: $input) {\n    deleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "aea88092abe79dfc4a6ef80310c2a41c";

export default node;
