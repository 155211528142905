/**
 * @generated SignedSource<<cb8b88711590ee38cf1279f43ddaddf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachComponentBar_component$data = {
  readonly id: string;
  readonly title: string;
  readonly type: ComponentType;
  readonly updatedAt: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentPublishButton_component" | "EditorMenu_component">;
  readonly " $fragmentType": "CoachComponentBar_component";
};
export type CoachComponentBar_component$key = {
  readonly " $data"?: CoachComponentBar_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachComponentBar_component">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "draft",
    "variableName": "draft"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "draft"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachComponentBar_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EditorMenu_component"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentPublishButton_component"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "utc",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": "updatedAt(utc:true)"
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "542ab63f948f05de333465d92e78d19d";

export default node;
