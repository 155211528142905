import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as EmptyIcon } from "../../icons/FitnessWeightlifting.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(5),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
    },
  },

  text: {
    textAlign: "center",
    margin: theme.spacing(2, 10),
  },
}));

export interface ExerciseLibraryEmptyProps extends BoxProps {}

export function ExerciseLibraryEmpty(props: ExerciseLibraryEmptyProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <EmptyIcon />
      <Typography variant="subtitle1" className={s.text}>
        Save time by adding some exercises to the library
      </Typography>
    </Box>
  );
}
