/**
 * @generated SignedSource<<ded0417ac20061589a3cc53b79636246>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientLast7Days_enrollment$data = {
  readonly completion7Days: {
    readonly rate: number;
  };
  readonly completionByDate7Days: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientLast7DaysCompletion_completionByDate">;
  } | null | undefined>;
  readonly habits7Days: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly lessons7Days: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly " $fragmentType": "ClientLast7Days_enrollment";
};
export type ClientLast7Days_enrollment$key = {
  readonly " $data"?: ClientLast7Days_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientLast7Days_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "period",
  "value": "LAST_7_DAYS"
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "completed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientLast7Days_enrollment",
  "selections": [
    {
      "alias": "completion7Days",
      "args": (v1/*: any*/),
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": "completion(period:\"LAST_7_DAYS\")"
    },
    {
      "alias": "lessons7Days",
      "args": [
        {
          "kind": "Literal",
          "name": "componentType",
          "value": "LESSON"
        },
        (v0/*: any*/)
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "completion(componentType:\"LESSON\",period:\"LAST_7_DAYS\")"
    },
    {
      "alias": "habits7Days",
      "args": [
        {
          "kind": "Literal",
          "name": "componentType",
          "value": "HABIT"
        },
        (v0/*: any*/)
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": "completion(componentType:\"HABIT\",period:\"LAST_7_DAYS\")"
    },
    {
      "alias": "completionByDate7Days",
      "args": (v1/*: any*/),
      "concreteType": "CompletionByDate",
      "kind": "LinkedField",
      "name": "completionByDate",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientLast7DaysCompletion_completionByDate"
        }
      ],
      "storageKey": "completionByDate(period:\"LAST_7_DAYS\")"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "67485264d055c6e93af06ddb200dc0d0";

export default node;
