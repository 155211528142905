import { createContext } from "react";

export interface ILayoutLoadingContextProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const LayoutLoadingContext = createContext<
  ILayoutLoadingContextProps | undefined
>(undefined);

export default LayoutLoadingContext;
