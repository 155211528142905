/**
 * @generated SignedSource<<e8ef4861c9ec93725893b614c246114c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostsScreen_rootRef$data = {
  readonly group: {
    readonly membersCanNotSeeSidebar: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"GroupPostsList_group" | "GroupPostsSidebar_group" | "RefreshSlug">;
  } | null | undefined;
  readonly " $fragmentType": "GroupPostsScreen_rootRef";
};
export type GroupPostsScreen_rootRef$key = {
  readonly " $data"?: GroupPostsScreen_rootRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsScreen_rootRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostsScreen_rootRef",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefreshSlug"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GroupPostsSidebar_group"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GroupPostsList_group"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membersCanNotSeeSidebar",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "ee9a14d8e3d2abccc3f92737a659ad0b";

export default node;
