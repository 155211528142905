import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { UserRole } from "../constants";

import { useSwitchUserSignInAsMutation } from "./__generated__/useSwitchUserSignInAsMutation.graphql";
import { useSnackAlert } from "./useSnackAlert";
import { useCurrentUser } from "./useCurrentUser";
import {
  ADMIN_USERS_ROUTE,
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../routes/routes";

export const signInAsMutation = graphql`
  mutation useSwitchUserSignInAsMutation($userId: String) {
    signInAs(userId: $userId) {
      me {
        id
        role
        admin
      }
    }
  }
`;

export function useSwitchUser() {
  const snackAlert = useSnackAlert();
  const [signInAs] =
    useMutation<useSwitchUserSignInAsMutation>(signInAsMutation);
  const me = useCurrentUser();
  const wasImpersonating = me?.isImpersonating;

  const switchUser = React.useCallback(
    (userId?: string, redirect = true) => {
      signInAs({
        variables: {
          userId,
        },
        updater(store) {
          (store as any).invalidateStore();
        },
        onCompleted(result, errors) {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: errors[0].message || "Failed to switch user",
            });
          } else if (redirect) {
            const user = result.signInAs.me;
            const { role, admin } = user;

            // TODO change to navigate() (same as LoginScreen)
            window.location.href =
              role === UserRole.CLIENT
                ? HOME_ROUTE
                : admin && wasImpersonating
                  ? ADMIN_USERS_ROUTE
                  : COACH_PROGRAMS_PUBLISHED_ROUTE;
          } else {
            window.location.reload();
          }
        },
      });
    },
    [signInAs, snackAlert, wasImpersonating],
  );

  return switchUser;
}
