import clsx from "clsx";
import React from "react";
import { Paper, PaperProps, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 16,
    fontWeight: "bold",
    padding: theme.spacing(2),
  },
}));

export interface AdminPaperProps extends PaperProps {
  title?: string;
}

export function AdminPaper(props: AdminPaperProps) {
  const { className, title, children, ...other } = props;
  const s = useStyles();

  return (
    <Paper className={clsx(s.root, className)} {...other}>
      {title && (
        <>
          <Typography className={s.title} variant="h4" children={title} />
          <Divider />
        </>
      )}

      {children}
    </Paper>
  );
}
