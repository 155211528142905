import clsx from "clsx";
import React, { Suspense, useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  Collapse,
  Skeleton,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";

import { useSnackAlert } from "../../hooks/useSnackAlert";
import {
  useProgramSchedule,
  ScheduleUpdate,
} from "../../hooks/useProgramSchedule";
import { ComponentType, SOMETHING_WENT_WRONG } from "../../constants";
import {
  ProgramCalendarWeek,
  ProgramCalendarWeekProps,
} from "../program-calendar/ProgramCalendarWeek";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";
import { useDirtyMutation } from "../dirty-transaction/hooks";

import { ProgramCalendarWeeksListMutation } from "./__generated__/ProgramCalendarWeeksListMutation.graphql";
import { WEEK_DRAG_ITEM_TYPE } from "./types";
import { getDaysSpan, setSpan } from "./utils";
import { useParams, useSearchParams } from "react-router-dom";
import ProgramCalendarSidebar from "./ProgramCalendarSidebar";
import { grey } from "@mui/material/colors";
import { TransitionProps } from "@mui/material/transitions";
import CalendarSidebarTitle from "./components/CalendarSidebarTitle";
import { getBannerHeight } from "../../contexts/BannersContext";
import ComponentDialog from "../program/ComponentDialog";

export const CALENDAR_SIDEBAR_WIDTH = 320;

export const ComponentDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  card: {
    margin: theme.spacing(3, 0, 4, 0),
  },
}));

const mutation = graphql`
  mutation ProgramCalendarWeeksListMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        id
        days
        weekId

        updatedAt(utc: true)
      }
    }
  }
`;

export interface ProgramCalendarWeeksListProps extends BoxProps {
  filters?: ProgramCalendarWeekProps["filters"];
  startDate?: string;
  onAddComponent?: ProgramAddComponentCallback;
  onOpenMenu?: ProgramCalendarWeekProps["onOpenMenu"];
  programRef: any;
  weeks?: any;
  programId?: string;
  activityPreview: boolean;
  setActivityPreview: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ProgramCalendarWeeksList(props: ProgramCalendarWeeksListProps) {
  const {
    activityPreview,
    setActivityPreview,
    className,
    programRef,
    filters,
    startDate,
    onOpenMenu,
    onAddComponent,
    weeks,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const programSlug = params.slug;
  const componentSlugSearchParams = searchParams.get("component");

  const s = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const snackAlert = useSnackAlert();
  const [scheduleUpdate, setScheduleUpdate] = React.useState<ScheduleUpdate>();
  const [upsertComponent] =
    useDirtyMutation<ProgramCalendarWeeksListMutation>(mutation);

  const [components, schedule] = useProgramSchedule(
    programRef,
    filters,
    scheduleUpdate,
  );

  const handleMove: ProgramCalendarWeekProps["onMove"] = React.useCallback(
    (update) => {
      const { type, componentId, day, week } = update;
      const originDay = scheduleUpdate
        ? scheduleUpdate.originDay
        : update.originDay;
      const originWeek = update.originWeek;
      const component = components.find(({ id }) => id === componentId);
      const days = [...component.days];
      const originSpan = getDaysSpan(days, originDay);
      let weekId = scheduleUpdate ? scheduleUpdate.weekId : component.weekId,
        offset = 0;

      setSpan(days, originDay, originSpan, false);

      switch (type) {
        case WEEK_DRAG_ITEM_TYPE.COMPONENT:
          const weekOffset = week - originWeek;
          const currentWeekIndex = weeks.findIndex(({ id }) => id === weekId);
          const newWeekIndex = Math.min(
            Math.max(currentWeekIndex + weekOffset, 0),
            weeks.length - 1,
          );

          weekId = weeks[newWeekIndex].id;
          setSpan(days, day, originSpan, true);
          break;

        case WEEK_DRAG_ITEM_TYPE.RESIZE_LEFT:
          offset = originDay - day;
          offset = Math.max(offset, 1 - originSpan);

          setSpan(days, originDay - offset, originSpan + offset, true);
          break;

        case WEEK_DRAG_ITEM_TYPE.RESIZE_RIGHT:
          offset = originDay + originSpan - day - 1;

          setSpan(days, originDay, Math.max(originSpan - offset, 1), true);
          break;
      }

      setScheduleUpdate({
        id: update.componentId,
        weekId,
        days,
        originDay,
        originWeek,
      });
    },
    [components, weeks, scheduleUpdate],
  );

  const handleMoveEnd = React.useCallback(() => {
    if (scheduleUpdate) {
      const input = {
        id: scheduleUpdate.id,
        days: scheduleUpdate.days,
        weekId: scheduleUpdate.weekId,
      };

      upsertComponent({
        variables: {
          input,
        },
        optimisticResponse: {
          upsertComponent: {
            component: input,
          },
        },
        onCompleted: (_, errors) => {
          if (errors) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Schedule updated",
            });
          }
        },
      });

      setScheduleUpdate(null);
    }
  }, [scheduleUpdate, snackAlert, upsertComponent]);

  const [activityPreviewItem, setActivityPreviewItem] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = (e?: any, slug?: string) => {
    slug && setActivityPreviewItem(slug);
    setSearchParams({
      component: slug ?? componentSlugSearchParams ?? activityPreviewItem,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSearchParams();
    setOpenDialog(false);
  };

  useEffect(() => {
    if (componentSlugSearchParams && programSlug) {
      setActivityPreviewItem(componentSlugSearchParams);
      handleClickOpenDialog();
    }
  }, []);

  useEffect(() => {
    isMd && setActivityPreview(false);
  }, [isMd]);

  useEffect(() => {
    searchParams.size === 0 && handleCloseDialog();
  }, [searchParams]);

  const bannerSpacing = getBannerHeight();

  return (
    <Box
      sx={{
        margin: theme.spacing(1, 0),
        display: "flex",
        gap: 2,
      }}
    >
      <Box className={clsx(s.root, className)}>
        {weeks.map((week, i) => (
          <ProgramCalendarWeek
            className={s.card}
            key={week.id}
            weekRef={week}
            schedule={schedule}
            filters={filters}
            startDate={startDate}
            onOpenMenu={onOpenMenu}
            onAddComponent={(
              weekId: string,
              type: ComponentType,
              days?: boolean[],
            ) => {
              onAddComponent(
                weekId,
                type,
                days,
                undefined,
                undefined,
                handleClickOpenDialog,
              );
            }}
            onMove={handleMove}
            onMoveEnd={handleMoveEnd}
            setActivityPreview={setActivityPreview}
            activityPreviewItem={activityPreview && activityPreviewItem}
            setActivityPreviewItem={setActivityPreviewItem}
            handleClickOpenDialog={handleClickOpenDialog}
            activityPreview={activityPreview}
          />
        ))}
      </Box>

      <Collapse in={activityPreview} orientation="horizontal" unmountOnExit>
        <Box
          sx={{
            marginTop: 8.5,
            marginBottom: 4,
            backgroundColor: theme.palette.background.paper,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: theme.palette.quote,
            borderRadius: theme.spacing(1),
            minWidth: CALENDAR_SIDEBAR_WIDTH,
            maxWidth: CALENDAR_SIDEBAR_WIDTH,
            position: "sticky",
            top: `calc(44px + ${theme.spacing(bannerSpacing)})`,
          }}
        >
          {activityPreviewItem && (
            <Suspense
              fallback={
                <>
                  <Box
                    display={"flex"}
                    gap={1}
                    sx={{
                      justifyContent: "end",
                      position: "absolute",
                      top: "-40px",
                      right: "0px",
                      width: "80%",
                      height: "40px",
                    }}
                  >
                    {/* TODO reuse <Tab/> instead */}
                    <Skeleton
                      width={"20%"}
                      sx={{ background: theme.palette.selected.main }}
                    />
                    <Skeleton
                      width={"20%"}
                      sx={{ background: theme.palette.selected.main }}
                    />
                    <Skeleton
                      width={"20%"}
                      sx={{ background: theme.palette.selected.main }}
                    />
                  </Box>
                  <CalendarSidebarTitle
                    title={""}
                    color={theme.palette.selected.main}
                    componentSlug={"slug"}
                    setActivityPreview={() => {}}
                    openDialog={false}
                    handleClickOpenDialog={() => {}}
                    handleCloseDialog={() => {}}
                  />
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    color={grey[400]}
                    sx={{ height: "500px" }}
                  >
                    <CircularProgress size={20} color={"inherit"} />
                  </Box>
                </>
              }
            >
              <ProgramCalendarSidebar
                slug={activityPreviewItem}
                setActivityPreview={setActivityPreview}
                openDialog={openDialog}
                handleClickOpenDialog={handleClickOpenDialog}
                handleCloseDialog={handleCloseDialog}
              />
            </Suspense>
          )}
        </Box>
      </Collapse>

      <ComponentDialog
        componentSlug={activityPreviewItem}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
}
