import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";

import { Avatar } from "../avatar/Avatar";
import { HeightOverflow } from "../container/HeightOverflow";
import { EditorFieldEditor } from "../new-editor/EditFieldEditor";
import { Likeable } from "../likeable/Likeable";
import { colorSystem } from "../../theme";

import {
  GroupPostCommentsListItem_comment$data,
  GroupPostCommentsListItem_comment$key,
} from "./__generated__/GroupPostCommentsListItem_comment.graphql";
import { GroupMemberBadges } from "./GroupMemberBadges";
import { GroupPostCommentsListItemNoReplies } from "./GroupPostCommentsListItemNoReplies";
import { GroupPostsCommentForm } from "./GroupPostsCommentForm";
import { useMutation, usePaginationFragment } from "react-relay/hooks";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { notificationReadStoreUpdater } from "../activity-feedback/ActivityFeedback";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",

    "&::before": {
      width: 1,
      height: "100%",
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 0,
      left: theme.spacing(6.5),
      backgroundColor: colorSystem.secondaryGray,
    },

    "&$first::before": {
      height: "calc(100% - 21px)",
    },
  },

  header: {
    padding: theme.spacing(2, 3, 1, 3),
    display: "flex",
    alignItems: "center",
  },

  author: {
    flexGrow: 1,
  },

  avatar: {
    borderWidth: 0,
    margin: theme.spacing(0, 2, 0, 0),

    [theme.breakpoints.up("md")]: {
      width: 40,
      height: 40,
      marginLeft: theme.spacing(1),
    },
  },

  displayName: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "1.5em",
  },

  time: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  content: {
    fontSize: 14,
    fontWeight: 500,

    '& [data-slate-node="element"]': {
      padding: 0,
      border: 0,
    },
  },

  expand: {
    fontSize: 14,
    fontWeight: 500,
  },

  body: {
    padding: theme.spacing(1, 3, 3, 10),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 3, 3, 11),
    },
  },

  moreButton: {
    marginTop: theme.spacing(2),
  },

  reply: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    cursor: "pointer",
  },

  form: {
    padding: 0,
    backgroundColor: "transparent",

    "&$noReplies": {
      padding: theme.spacing(2, 0, 0),
    },
  },

  first: {},
  noReplies: {},
}));

export interface GroupPostCommentsListItemProps extends BoxProps {
  commentRef: GroupPostCommentsListItem_comment$key;
  headerClassName?: string;
  avatarClassName?: string;
  bodyClassName?: string;
  first?: boolean;
  onReplyClick?: (comment: GroupPostCommentsListItem_comment$data) => void;
}

const commentFragment = graphql`
  fragment GroupPostCommentsListItem_comment on GroupPostComment
  @refetchable(queryName: "GroupPostCommentsListItemRefetchQuery")
  @argumentDefinitions(
    last: { type: "Int", defaultValue: 3 }
    before: { type: "String" }
    fetchReplies: { type: "Boolean", defaultValue: false }
  ) {
    ...Likeable_likeable
    ...GroupPostsCommentForm_comment

    id
    content
    replies(last: $last, before: $before)
      @include(if: $fetchReplies)
      @connection(key: "GroupPostCommentsListItem_replies") {
      edges {
        node {
          id
          ...GroupPostCommentsListItemNoReplies_comment
          ...GroupPostsCommentForm_comment
        }
      }
    }
    notification {
      userId
      read
    }
    createdAt(format: "fromNow")
    author {
      ...Avatar_user
      ...GroupMemberBadges_user
      displayName
      role
    }
  }
`;

const notificationReadMutation = graphql`
  mutation GroupPostCommentsListItemNotificationReadMutation(
    $input: NotificationReadInput!
  ) {
    notificationRead(input: $input) {
      notification {
        id
        activityFeedbackId
      }
    }
  }
`;

export function GroupPostCommentsListItem(
  props: GroupPostCommentsListItemProps,
) {
  const {
    className,
    commentRef,
    headerClassName,
    avatarClassName,
    bodyClassName,
    first = false,
    onReplyClick,
    ...other
  } = props;
  const s = useStyles();

  const {
    data: comment,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(commentFragment, commentRef);
  const { author, content, replies, createdAt, notification } = comment;
  const pageSize = 5;
  const user = useCurrentUser();
  const [loading, setLoading] = React.useState(false);
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const [replyToComment, setReplyToComment] =
    React.useState<GroupPostCommentsListItem_comment$data | null>(null);

  const [notificationRead, inFlight] = useMutation(notificationReadMutation);

  React.useEffect(() => {
    if (notification) {
      if (
        notification?.userId === user.id &&
        !notification?.read &&
        !inFlight
      ) {
        notificationRead({
          variables: {
            input: { groupPostComment: comment.id },
          },
          onCompleted: (data, errors) => {
            if (errors && errors.length) {
              console.error(errors[0]);
            }
          },
          onError: (error) => {
            console.error(error);
          },
          updater: notificationReadStoreUpdater,
        });
      }
    }
  }, [comment.id, notification, notificationRead, inFlight, user.id]);

  const handleChildReplyClick = React.useCallback((comment) => {
    setReplyToComment((_comment) =>
      _comment?.id === comment.id ? null : comment,
    );
  }, []);

  const handleCommentSubmit = React.useCallback(() => {
    setReplyToComment(null);
  }, []);

  const handleMoreClick = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      loadNext(pageSize, {
        onComplete: setLoaded,
      });
    }
  }, [loading, loadNext, setLoaded]);

  const handleReplyClick = React.useCallback(() => {
    if (onReplyClick) {
      onReplyClick(comment);
    } else {
      handleChildReplyClick(comment);
    }
  }, [comment, handleChildReplyClick, onReplyClick]);

  return (
    <Box className={clsx(s.root, className, first && s.first)} {...other}>
      <Box className={clsx(s.header, headerClassName)}>
        <Avatar className={clsx(s.avatar, avatarClassName)} userRef={author} />
        <Box className={s.author}>
          <Box display={"flex"}>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              {author.displayName}
            </Typography>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              <GroupMemberBadges memberRef={author} />
            </Typography>
          </Box>
          <Typography className={s.time} variant="subtitle2">
            {createdAt}
          </Typography>
        </Box>
        <Likeable likeableRef={comment} />
      </Box>

      <Box className={clsx(s.body, bodyClassName)}>
        <Typography component="div" className={s.content}>
          <HeightOverflow
            maxHeight={68}
            renderLabel={(expanded: boolean) => (
              <Typography className={s.expand}>
                {expanded ? "Show less" : "Show more"}
              </Typography>
            )}
            disableOverlay
          >
            <EditorFieldEditor value={content} readOnly multiline />
          </HeightOverflow>
        </Typography>

        <Typography
          className={s.reply}
          children="Reply"
          variant="body1"
          onClick={handleReplyClick}
        />

        {hasNext && (
          <Button
            className={s.moreButton}
            onClick={handleMoreClick}
            disabled={loading}
            fullWidth
            children="Show older comments"
          />
        )}

        {replies?.edges.map(({ node }, index) => (
          <GroupPostCommentsListItemNoReplies
            key={node.id}
            comment={node}
            first={index === 0}
            onReplyClick={handleChildReplyClick}
          />
        ))}

        {replies && replyToComment && (
          <GroupPostsCommentForm
            className={clsx(s.form, replies.edges.length === 0 && s.noReplies)}
            postRef={null}
            commentRef={replyToComment}
            placeholder={`Send feedback to ${replyToComment.author.displayName}`}
            helperText=""
            onSubmit={handleCommentSubmit}
            focusOnInit
          />
        )}
      </Box>
    </Box>
  );
}
