import React from "react";

const Calendar = ({ fill, className }) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3594 15.4799C10.3594 16.8378 10.8988 18.1401 11.859 19.1002C12.8192 20.0604 14.1215 20.5999 15.4794 20.5999C16.8373 20.5999 18.1396 20.0604 19.0998 19.1002C20.0599 18.1401 20.5994 16.8378 20.5994 15.4799C20.5994 14.122 20.0599 12.8197 19.0998 11.8595C18.1396 10.8993 16.8373 10.3599 15.4794 10.3599C14.1215 10.3599 12.8192 10.8993 11.859 11.8595C10.8988 12.8197 10.3594 14.122 10.3594 15.4799Z"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.7621 13.9917L15.2832 17.2967C15.2281 17.3699 15.1579 17.4305 15.0773 17.4743C14.9968 17.5182 14.9078 17.5442 14.8164 17.5508C14.7249 17.5573 14.6331 17.5442 14.5472 17.5123C14.4612 17.4804 14.3831 17.4304 14.3181 17.3658L13.0381 16.0858"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.80012 18.0397H2.68013C2.51088 18.0439 2.34255 18.0137 2.18535 17.9508C2.02815 17.888 1.88536 17.7939 1.76565 17.6742C1.64594 17.5544 1.55181 17.4117 1.48897 17.2544C1.42614 17.0972 1.39591 16.9289 1.40013 16.7597V4.05099C1.38861 3.69957 1.51686 3.3579 1.75679 3.10086C1.99671 2.84382 2.32874 2.69237 2.68013 2.67969H15.4801C15.8315 2.69237 16.1635 2.84382 16.4035 3.10086C16.6434 3.3579 16.7716 3.69957 16.7601 4.05099V7.79968"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.24023 1.3999V5.2399"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.08008 1.3999V5.2399"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.9199 1.3999V5.2399"
      stroke={fill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Calendar;
