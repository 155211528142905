import React, { useEffect, useState } from "react";
import { AppMetadata, AppMetadataContext } from "../../hooks/useAppMetadata";
import Cookies from "js-cookie";

export interface AppMetadataProviderProps {
  children: React.ReactNode;
}

export const AppMetadataProvider = (props: AppMetadataProviderProps) => {
  const MAINENTNACE_MODE_KEY_COOKIE_NAME = "MAINENTNACE_MODE_KEY_COOKIE_NAME";
  const METADATA_URL = process.env.REACT_APP_METADATA_ENDPOINT;

  const { children } = props;
  const [appMetadata, setAppMetadata] = useState<AppMetadata>(undefined);

  useEffect(() => {
    const fetchAppMetadata = async () => {
      try {
        const maintenanceModeKey = Cookies.get(
          MAINENTNACE_MODE_KEY_COOKIE_NAME,
        );

        const metadataUrl = maintenanceModeKey
          ? `${METADATA_URL}?key=${maintenanceModeKey}`
          : METADATA_URL;

        const response = await fetch(metadataUrl);
        const data = await response.json();
        setAppMetadata(data);
      } catch (error) {
        console.error("Failed to fetch app metadata:", error);
      }
    };

    fetchAppMetadata();
    const intervalId = setInterval(fetchAppMetadata, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <AppMetadataContext.Provider value={appMetadata}>
      {children}
    </AppMetadataContext.Provider>
  );
};
