import React from "react";
import { useParams } from "react-router-dom";
import { ClientActivityStates } from "../../../../constants";
import { ClientProgramActivityStartRoute } from "./start/ClientProgramActivityStartRoute";
import { ClientProgramActivityEndRoute } from "./end/ClientProgramActivityEndRoute";
import { ClientProgramActivitySummaryRoute } from "./summary/ClientProgramActivitySummaryRoute";

export const ClientProgramActivityRoute = () => {
  const params = useParams();

  const { status } = params;

  switch (status) {
    case ClientActivityStates.START:
      return <ClientProgramActivityStartRoute />;
    case ClientActivityStates.SUMMARY:
      return <ClientProgramActivitySummaryRoute />;
    case ClientActivityStates.END:
      return <ClientProgramActivityEndRoute />;

    default:
      break;
  }
};
