/**
 * @generated SignedSource<<afaecc1322605197d98eec2c06c6373d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BrandedAppStatus = "IN_PROGRESS" | "NONE" | "PUBLISHED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ThemeProvider_workspace$data = {
  readonly brandedAppAppleStoreUrl: string | null | undefined;
  readonly brandedAppGooglePlayUrl: string | null | undefined;
  readonly brandedAppLogoUrl: string | null | undefined;
  readonly brandedAppName: string | null | undefined;
  readonly brandedAppStatus: BrandedAppStatus;
  readonly logoURL: string | null | undefined;
  readonly mealLogging: boolean;
  readonly message: string | null | undefined;
  readonly name: string | null | undefined;
  readonly primaryColor: string;
  readonly slug: string;
  readonly " $fragmentType": "ThemeProvider_workspace";
};
export type ThemeProvider_workspace$key = {
  readonly " $data"?: ThemeProvider_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeProvider_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThemeProvider_workspace",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealLogging",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandedAppStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandedAppName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandedAppLogoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandedAppAppleStoreUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandedAppGooglePlayUrl",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "74c842aa3f722c520362211a28c79949";

export default node;
