/**
 * @generated SignedSource<<72da7a6b185687b788e69ec0d6a40177>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupDescription_group$data = {
  readonly coachId: string;
  readonly cover: string | null | undefined;
  readonly description: string;
  readonly " $fragmentSpreads": FragmentRefs<"GroupDescriptionDialog_group">;
  readonly " $fragmentType": "GroupDescription_group";
};
export type GroupDescription_group$key = {
  readonly " $data"?: GroupDescription_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupDescription_group">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupDescription_group",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupDescriptionDialog_group"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachId",
      "storageKey": null
    },
    {
      "alias": "cover",
      "args": null,
      "kind": "ScalarField",
      "name": "imageURL",
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "b275030a2f8b3fa4ef151bf7489fb86e";

export default node;
