import React from "react";
import clsx from "clsx";
import { Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../../theme";
import { ReactComponent as CloseIcon } from "../../../icons/CloseSilver.svg";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  modal: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colorSystem.blackOpacity,
    zIndex: 9999999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: colorSystem.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05);",
    padding: spacing(4, 3, 3, 3),
    maxWidth: 524,
    margin: "0 20px",
  },
  header: {
    display: "flex",
    justifyContent: "spaceBetween",
    alignItems: "flex-start",

    "& button": {
      [breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  titleModal: {
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: "29px",
    marginBottom: spacing(2),
    fontFamily: "Montserrat, sans-serif",
  },
  textModal: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    marginBottom: 24,
    fontFamily: "Montserrat, sans-serif",
  },
  button: {
    backgroundColor: colorSystem.primary,
    color: colorSystem.white,
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: "20px",
    padding: 18,
    width: "100%",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: colorSystem.primary,
    "&:hover": {
      color: colorSystem.primary,
      backgroundColor: colorSystem.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "spaceBetween",
    width: "100%",

    [breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  buttonCancel: {
    marginRight: spacing(3),
    backgroundColor: "transparent",
    color: colorSystem.gray,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: colorSystem.gray,
    "&:hover": {
      backgroundColor: colorSystem.gray8,
      color: colorSystem.gray,
    },

    [breakpoints.down("sm")]: {
      marginRight: spacing(0),
      marginTop: spacing(2),
    },
  },
}));

export interface DeleteUserDialogProps {
  onClose: () => void;
  onDelete: () => void;
}

export function DeleteUserDialog(props: DeleteUserDialogProps) {
  const { onClose, onDelete } = props;
  const s = useStyles();

  return (
    <div className={clsx(s.modal)}>
      <div className={clsx(s.modalContent)}>
        <div className={s.header}>
          <div className={clsx(s.titleModal)}>
            Are you sure you want to delete your account?
          </div>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <div className={clsx(s.textModal)}>
          All your data will be permanently deleted and you will no longer be
          able to see it, including what your trainer sees.
        </div>
        <div className={s.buttonsContainer}>
          <Button
            className={clsx(s.button, s.buttonCancel)}
            children={"Cancel"}
            onClick={onClose}
          />
          <Button
            className={clsx(s.button)}
            children={"Delete"}
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  );
}
