import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "scroll",
  },

  container: {
    display: "flex",
  },
}));

export interface ScrollableContainerProps extends BoxProps {}

export function ScrollableContainer(props: ScrollableContainerProps) {
  const { className, children, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.container}>{children}</Box>
    </Box>
  );
}
