/**
 * @generated SignedSource<<15da7044311e97cf962c5e402333079a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResendClientInviteInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type PendingInviteItemResendMutation$variables = {
  input: ResendClientInviteInput;
};
export type PendingInviteItemResendMutation$data = {
  readonly resendClientInvite: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type PendingInviteItemResendMutation = {
  response: PendingInviteItemResendMutation$data;
  variables: PendingInviteItemResendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResendClientInvitePayload",
    "kind": "LinkedField",
    "name": "resendClientInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingInviteItemResendMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingInviteItemResendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "478ee0c8512eb7f7d8d697b3a40a0a67",
    "id": null,
    "metadata": {},
    "name": "PendingInviteItemResendMutation",
    "operationKind": "mutation",
    "text": "mutation PendingInviteItemResendMutation(\n  $input: ResendClientInviteInput!\n) {\n  resendClientInvite(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "c255ca133d59ecc1313f509090de7624";

export default node;
