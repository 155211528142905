import { useMutation } from "react-relay/hooks";
import { graphql } from "react-relay";

import { useTranscodeVideoMutation } from "./__generated__/useTranscodeVideoMutation.graphql";

const transcodeVideoMutation = graphql`
  mutation useTranscodeVideoMutation($url: String!) {
    transcodeVideo(url: $url) {
      url
    }
  }
`;

export function useTranscodeVideo() {
  const [transcodeVideo] = useMutation<useTranscodeVideoMutation>(
    transcodeVideoMutation,
  );

  return function (url: string): Promise<string> {
    return new Promise((resolve, reject) =>
      transcodeVideo({
        variables: {
          url,
        },
        onCompleted: (result, errors) => {
          if (errors?.length) {
            reject(errors);
          } else {
            resolve(result.transcodeVideo.url);
          }
        },
      }),
    );
  };
}
