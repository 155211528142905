import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    cursor: "zoom-out",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  dialog: {
    zIndex: "99999 !important" as any,
  },
  fullImage: {
    maxWidth: "calc(100vw - 16px)",
    maxHeight: "calc(100vh - 50px)",
  },
}));

export interface ZoomableImageProps {
  className?: string;
  previewSrc?: string;
  src: string;
  alt: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
  preview?: React.ReactNode;
}

export function ZoomableImage(props: ZoomableImageProps) {
  const { className, onClick, src, alt, previewSrc = src, style } = props;
  const [zoomed, setZoomed] = React.useState(false);
  const s = useStyles();

  const handleZoomIn = React.useCallback(() => {
    setZoomed(true);
  }, []);

  const handleZoomOut = React.useCallback(() => {
    setZoomed(false);
  }, []);

  return zoomed ? (
    <Dialog className={s.dialog} fullScreen open={true} onClick={handleZoomOut}>
      <Box className={s.container}>
        <img src={src} alt={alt} className={s.fullImage} />
      </Box>
    </Dialog>
  ) : (
    <img
      className={clsx(s.root, className)}
      src={previewSrc}
      alt={alt}
      onClick={onClick}
      onDoubleClick={handleZoomIn}
      style={style}
    />
  );
}
