import React from "react";
import clsx from "clsx";
import WorkoutSetTable from "./WorkoutSetTable";
import { useLazyLoadQuery, graphql } from "react-relay/hooks";
import { WorkoutHistoryClientQuery } from "./__generated__/WorkoutHistoryClientQuery.graphql";
import { Sets, WorkoutSection } from "./types";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { Divider } from "@mui/material";
import { parseWorkoutResultValueJsonToWorkoutSection } from "./utils";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: 12,
    },
    header: {
      display: "flex",
      color: colorSystem.gray,
      fontWeight: 500,
      marginBottom: 12,
    },
    history: {
      backgroundColor: colorSystem.green2,
      textAlign: "center",
      color: colorSystem.white,
      borderRadius: 4,
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
  };
});

const WorkoutHistoryClient = (props: any) => {
  const { exercise } = props;
  const [bestResult, setBestResult] = React.useState(0);
  const s = useStyles();
  const data = useLazyLoadQuery<WorkoutHistoryClientQuery>(
    graphql`
      query WorkoutHistoryClientQuery(
        $exerciseId: String
        $exerciseTitle: String
      ) {
        workoutExerciseResults(
          exerciseId: $exerciseId
          exerciseTitle: $exerciseTitle
        ) {
          edges {
            node {
              id
              client {
                id
                displayName
                email
                photoURL
              }
              activity {
                component {
                  title
                  content
                }
                completedAt(format: "dddd MMMM DD, YYYY")
              }
              value
            }
          }
        }
      }
    `,
    {
      exerciseId: exercise.assetId,
      exerciseTitle: exercise.title,
    },
    {
      fetchPolicy: "store-or-network",
    },
  );

  const workoutExerciseResults = data.workoutExerciseResults.edges.map(
    ({ node: { value, ...node } }) => ({
      ...node,
      section: parseWorkoutResultValueJsonToWorkoutSection(value),
    }),
  );

  const resultSets = React.useMemo<
    Array<{ sets: Array<Sets>; completedAt: string }>
  >(() => {
    const results = [];
    workoutExerciseResults.forEach((workoutExerciseResult) =>
      workoutExerciseResult.section.exercises.forEach((i) => {
        if (i.title === exercise.title && Array.isArray(i.result)) {
          results.push({
            sets: i.result,
            completedAt: workoutExerciseResult.activity.completedAt,
          });
        }
      }),
    );
    return results;
  }, [workoutExerciseResults, exercise.title]);

  React.useEffect(() => {
    let bestResult = 0;
    resultSets.forEach(({ sets }) => {
      if (Array.isArray(sets)) {
        const summ = sets
          .map((i) => (i.checked ? Number(i.weight) ?? 0 : 0))
          .reduce((prev, curr) => +prev + +curr, 0);
        if (summ > bestResult) bestResult = summ;
      }
    });
    setBestResult(bestResult);
  }, [resultSets]);

  return (
    <div>
      {resultSets.length ? (
        <>
          <div className={clsx(s.history)}>{`Best result ${bestResult}`}</div>
          {resultSets
            .filter(({ sets }) => Array.isArray(sets) && sets.length > 0)
            .map(({ sets, completedAt }, index) => (
              <React.Fragment key={index}>
                <WorkoutSetTable
                  sets={sets}
                  history
                  exercise={exercise}
                  completedAt={completedAt}
                />
                <Divider sx={{ marginBlock: 3 }} />
              </React.Fragment>
            ))}
        </>
      ) : (
        <div>No results...</div>
      )}
    </div>
  );
};

export default WorkoutHistoryClient;
