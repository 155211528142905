import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppLogo } from "../app/AppLogo";
import art from "../../icons/healthy-habit.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  art: {
    width: 395,
    marginTop: theme.spacing(54),
  },
}));

export interface SignUpSetUpSideProps extends BoxProps {}

export function SignUpSetUpSide(props: SignUpSetUpSideProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo main full width={160} height={37} />

      <img className={s.art} src={art} alt="" />
    </Box>
  );
}
