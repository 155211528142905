import React, { useEffect } from "react";
import { BaseEditorProps } from "../../../new-editor/BaseEditor";
import { PlateEditor } from "../../../new-editor/PlateEditor";
import { deepCompare } from "../../../new-editor/utils/withSchema";
import { fixLegacyContent } from "../../utils/common";

export interface BaseEditorDelayBufferProps extends BaseEditorProps {
  delay?: number;
}

export function BaseEditorDelayBuffer(props: any) {
  const { value, onChange, delay = 1000, stickyTop, ...other } = props;
  const [dirty, setDirty] = React.useState(false);
  const [content, setContent] = React.useState(
    fixLegacyContent(
      value && typeof value === "string" ? JSON.parse(value) : value,
    ),
  );

  const handleChange = React.useCallback(
    (value) => {
      if (!deepCompare(value, content)) {
        setContent(value);
        setDirty(true);
      }
    },
    [content],
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (dirty && onChange) {
        onChange(content);
        setDirty(false);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [delay, onChange, value, dirty, content]);

  return (
    <PlateEditor
      editorData={content}
      setEditorData={handleChange}
      stickyTop={stickyTop}
      {...other}
    />
  );
}
