import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { PasteComponentMutation } from "./__generated__/PasteComponentMutation.graphql";

const mutation = graphql`
  mutation PasteComponentMutation($input: PasteComponentInput!) {
    pasteComponent(input: $input) {
      component {
        ...ProgramCalendarComponent_component
      }
      program {
        ...CurriculumEditScreen_program
      }
    }
  }
`;

export const usePasteComponentMutation = () => {
  return useConfiguredMutation<PasteComponentMutation>(
    mutation,
    (config) => config,
  );
};
