/**
 * @generated SignedSource<<103adf2d7d0ebdbe8f5877c549dd1d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsMenuRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  id: string;
};
export type NotificationsMenuRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenu_userRef">;
  } | null | undefined;
};
export type NotificationsMenuRefetchQuery = {
  response: NotificationsMenuRefetchQuery$data;
  variables: NotificationsMenuRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v8/*: any*/),
  (v4/*: any*/)
],
v10 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMM DD, YYYY"
  }
],
v11 = {
  "alias": "formattedDate",
  "args": (v10/*: any*/),
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Group",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsMenuRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "NotificationsMenu_userRef"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsMenuRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "NotificationConnection",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NotificationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Notification",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "activityFeedbackId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ActivityFeedback",
                            "kind": "LinkedField",
                            "name": "activityFeedback",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Activity",
                                "kind": "LinkedField",
                                "name": "activity",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Component",
                                    "kind": "LinkedField",
                                    "name": "component",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Program",
                                        "kind": "LinkedField",
                                        "name": "program",
                                        "plural": false,
                                        "selections": (v9/*: any*/),
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ClientForm",
                                    "kind": "LinkedField",
                                    "name": "clientForm",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v6/*: any*/),
                                      (v11/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "raw",
                                        "value": true
                                      }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "date",
                                    "storageKey": "date(raw:true)"
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v12/*: any*/),
                              {
                                "alias": "formattedDate",
                                "args": (v10/*: any*/),
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": "createdAt(format:\"MMM DD, YYYY\")"
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GroupPost",
                            "kind": "LinkedField",
                            "name": "groupPost",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v12/*: any*/),
                              (v7/*: any*/),
                              (v11/*: any*/),
                              (v5/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Message",
                            "kind": "LinkedField",
                            "name": "message",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v12/*: any*/),
                              (v11/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "notified",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GroupPostComment",
                            "kind": "LinkedField",
                            "name": "groupComment",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v12/*: any*/),
                              (v11/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GroupPost",
                                "kind": "LinkedField",
                                "name": "groupPost",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v13/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClientForm",
                            "kind": "LinkedField",
                            "name": "clientForm",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v8/*: any*/),
                              (v6/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "read",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "NotificationsMenu_notifications",
                "kind": "LinkedHandle",
                "name": "notifications"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9c314be623200f60368e4df7ad0f65d",
    "id": null,
    "metadata": {},
    "name": "NotificationsMenuRefetchQuery",
    "operationKind": "query",
    "text": "query NotificationsMenuRefetchQuery(\n  $after: String = null\n  $first: Int = 10\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...NotificationsMenu_userRef_2HEEH6\n    id\n  }\n}\n\nfragment NotificationBox_notification on Notification {\n  id\n  author {\n    username\n    displayName\n    photoURL\n    id\n  }\n  activityFeedbackId\n  activityFeedback {\n    activity {\n      type\n      component {\n        slug\n        title\n        program {\n          slug\n          name\n          id\n        }\n        id\n      }\n      clientForm {\n        name\n        slug\n        formattedDate: updatedAt(format: \"MMM DD, YYYY\")\n        id\n      }\n      date(raw: true)\n      id\n    }\n    content\n    formattedDate: createdAt(format: \"MMM DD, YYYY\")\n    id\n  }\n  groupPost {\n    id\n    content\n    title\n    formattedDate: updatedAt(format: \"MMM DD, YYYY\")\n    author {\n      username\n      displayName\n      photoURL\n      id\n    }\n    group {\n      slug\n      name\n      id\n    }\n  }\n  message {\n    id\n    content\n    formattedDate: updatedAt(format: \"MMM DD, YYYY\")\n    author {\n      username\n      displayName\n      photoURL\n      id\n    }\n    notified\n  }\n  groupComment {\n    id\n    content\n    formattedDate: updatedAt(format: \"MMM DD, YYYY\")\n    author {\n      username\n      displayName\n      photoURL\n      id\n    }\n    groupPost {\n      title\n      group {\n        slug\n        name\n        id\n      }\n      id\n    }\n  }\n  clientForm {\n    id\n    name\n    slug\n    formattedDate: updatedAt(format: \"MMM DD, YYYY\")\n  }\n  deleted\n  read\n}\n\nfragment NotificationsMenu_userRef_2HEEH6 on User {\n  notifications(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        ...NotificationBox_notification\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "517745532a2ec01fc1000d5670531c5a";

export default node;
