import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";

import { GroupNavItemList_me$key } from "./__generated__/GroupNavItemList_me.graphql";
import { GroupNavItemListItem } from "./GroupNavItemListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(8),
  },

  more: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(0.5),

    "&:hover": {
      background: "none !important",
    },

    "& svg": {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      marginLeft: theme.spacing(0.5),
    },
  },
}));

const meFragment = graphql`
  fragment GroupNavItemList_me on User
  @refetchable(queryName: "GroupNavItemListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 3 }
    after: { type: "String" }
  ) {
    groups(first: $first, after: $after)
      @connection(key: "GroupNavItemList_groups") {
      totalCount
      edges {
        node {
          ...GroupNavItemListItem
          id
        }
      }
    }
  }
`;

export interface GroupNavItemListProps extends BoxProps {
  meRef: GroupNavItemList_me$key;
}

export function GroupNavItemList(props: GroupNavItemListProps) {
  const { className, meRef, ...other } = props;
  const s = useStyles();
  const {
    data: me,
    loadNext,
    hasNext,
  } = usePaginationFragment(meFragment, meRef);
  const { groups } = me;
  const [loading, setLoading] = React.useState(false);
  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      loadNext(groups.totalCount, {
        onComplete: setLoaded,
      });
    }
  }, [groups.totalCount, loading, loadNext, setLoaded]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {groups.edges.map(({ node }) => (
        <GroupNavItemListItem key={node.id} groupRef={node} />
      ))}

      {hasNext && (
        <Button className={s.more} onClick={handleMoreClick} disabled={loading}>
          Show more <CaretDownIcon />
        </Button>
      )}
    </Box>
  );
}
