import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { CardAvatar, CardAvatarProps } from "../card/CardAvatar";

import { Avatar_user$key } from "./__generated__/Avatar_user.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid",
    borderColor: theme.palette.grey[50],
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    fontSize: 12,
    marginLeft: theme.spacing(-2),
    fontWeight: "bold",
    backgroundColor: theme.palette.avatar,
  },
}));

const fragment = graphql`
  fragment Avatar_user on User {
    displayName
    photoURL
  }
`;

export interface AvatarProps extends CardAvatarProps {
  userRef: Avatar_user$key;
}

export function Avatar(props: AvatarProps) {
  const { className, userRef, ...other } = props;
  const s = useStyles();
  const user = useFragment(fragment, userRef);
  const { displayName, photoURL } = user;
  const initials = displayName.substring(0, 1);

  return (
    <CardAvatar
      className={clsx(s.root, className)}
      src={photoURL}
      children={initials}
      {...other}
    />
  );
}
