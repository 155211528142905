import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserRole } from "../constants";
import { useCurrentUser, useCurrentUserRole } from "../hooks/useCurrentUser";
import {
  NotFoundPage,
  loggedInClientRoutes,
  loggedInCoachRoutes,
  loggedOutRoutes,
  publicRoutes,
  adminRoutes,
} from "./groupedRoutes";
import { CurrentUserWrapper } from "../wrappers/current-user/CurrentUserWrapper";
import { useCurrentBrand } from "../hooks/useCurrentWorkspace";

const AppRoutes = () => {
  const userRole = useCurrentUserRole();
  const { isBrandedApp, isBrandedWorkspace } = useCurrentBrand();
  const me = useCurrentUser();
  const isCoach = userRole === UserRole.COACH;
  const isClient = userRole === UserRole.CLIENT;
  const isAdmin = me?.admin;

  return (
    <Routes>
      {publicRoutes}
      {!userRole && loggedOutRoutes(isBrandedApp)}
      {isCoach && loggedInCoachRoutes(isBrandedWorkspace)}
      {isClient && loggedInClientRoutes}
      {isAdmin && adminRoutes}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<CurrentUserWrapper />}>
          <Route path="/*" element={<AppRoutes />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
