import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  Avatar,
  TextField,
  Button,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import { useSnackAlert } from "../../hooks/useSnackAlert";

import { UpsertedGroup, useUpsertGroupMutation } from "./mutations/UpsertGroup";

const COMMON_SPACING = 7;

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
  },

  avatar: {
    width: theme.spacing(COMMON_SPACING),
    height: theme.spacing(COMMON_SPACING),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },

  input: {
    height: theme.spacing(COMMON_SPACING),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface GroupNameDialogProps extends DialogProps {
  groupId?: string;
  groupName?: string;
  onCreated?: (group: UpsertedGroup) => void;
}

export function GroupNameDialog(props: GroupNameDialogProps) {
  const {
    className,
    onClose,
    groupId,
    groupName = "",
    onCreated,
    ...other
  } = props;
  const s = useStyles();
  const [name, setName] = React.useState(groupName);
  const [upsertGroup, inFlight] = useUpsertGroupMutation();
  const snackAlert = useSnackAlert();

  const handleNameChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => setName(event.target.value),
    [],
  );

  const resetState = React.useCallback(() => {
    setName("");
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleCreate = React.useCallback(() => {
    upsertGroup({
      variables: {
        input: { name },
      },
      onSuccess({ upsertGroup }) {
        handleClose();

        snackAlert({
          severity: "success",
          message: "Group created.",
        });

        if (onCreated) {
          onCreated(upsertGroup.group);
        }
      },
    });
  }, [handleClose, name, onCreated, snackAlert, upsertGroup]);

  const handleUpdate = React.useCallback(() => {
    upsertGroup({
      variables: {
        input: { id: groupId, name },
      },
      onSuccess() {
        handleClose();
        snackAlert({
          severity: "success",
          message: "Group updated.",
        });
      },
    });
  }, [groupId, handleClose, name, snackAlert, upsertGroup]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`${groupId ? "Rename" : "Create"} group`}
      closeDisabled={inFlight}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Box className={s.wrapper}>
        <Avatar className={s.avatar} variant="square">
          {name?.length ? name[0].toUpperCase() : "G"}
        </Avatar>
        <TextField
          className={s.input}
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
          label="Group name"
        />
      </Box>
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={`${groupId ? "Save" : "Create group"}`}
        onClick={groupId ? handleUpdate : handleCreate}
        disabled={!name || inFlight}
      />
    </BaseDialog>
  );
}
