/**
 * @generated SignedSource<<46ba5577027acb077dcef382748259f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnsplashImagesRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  query?: string | null | undefined;
  shouldFetchUnsplash?: boolean | null | undefined;
};
export type UnsplashImagesRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UnsplashImages_rootRef">;
};
export type UnsplashImagesRefetchQuery = {
  response: UnsplashImagesRefetchQuery$data;
  variables: UnsplashImagesRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "shouldFetchUnsplash"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsplashImagesRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "shouldFetchUnsplash",
            "variableName": "shouldFetchUnsplash"
          }
        ],
        "kind": "FragmentSpread",
        "name": "UnsplashImages_rootRef"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnsplashImagesRefetchQuery",
    "selections": [
      {
        "condition": "shouldFetchUnsplash",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "concreteType": "UnsplashImageConnection",
            "kind": "LinkedField",
            "name": "unsplashImages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnsplashImageEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnsplashImage",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unsplashImageId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authorUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "authorName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnsplashImageUrls",
                        "kind": "LinkedField",
                        "name": "urls",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "regular",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumb",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "139c7c8f83c17d9880bd3b47d462dd12",
    "id": null,
    "metadata": {},
    "name": "UnsplashImagesRefetchQuery",
    "operationKind": "query",
    "text": "query UnsplashImagesRefetchQuery(\n  $after: String\n  $first: Int\n  $query: String\n  $shouldFetchUnsplash: Boolean = false\n) {\n  ...UnsplashImages_rootRef_1QbuVZ\n}\n\nfragment UnsplashImage_image on UnsplashImage {\n  id\n  unsplashImageId\n  width\n  height\n  authorUrl\n  authorName\n  description\n  urls {\n    regular\n    thumb\n  }\n}\n\nfragment UnsplashImages_rootRef_1QbuVZ on Root {\n  unsplashImages(first: $first, after: $after, query: $query) @include(if: $shouldFetchUnsplash) {\n    edges {\n      node {\n        id\n        ...UnsplashImage_image\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c7d3675c02a1e79a82e330c1a36d516";

export default node;
