import React, { useEffect, useState } from "react";
import axios, { AxiosInstance } from "axios";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../other/loader/loader";
interface UnsplashImage {
  id: string;
  alt_description: string;
  urls: {
    small: string;
    regular: string;
    full: string;
  };
  user: {
    username: string;
    links: {
      html: string;
    };
  };
}
//TODO: move Unsplash API call to the backend
const instance: AxiosInstance = axios.create({
  baseURL: "https://api.unsplash.com/",
  headers: {
    Authorization: "Client-ID FJ-rh0wX8WTSjwUXoeojuTLdbdIqLcMMJzr7aW9MEj8",
    "Content-Type": "application/json",
  },
});
const UnsplashImagePicker = ({ handleUnsplashImageClick }: any) => {
  const [query, setQuery] = useState<string>("fitness");
  const [images, setImages] = useState<UnsplashImage[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  const searchImages = async () => {
    try {
      const response = await instance.get("/search/photos", {
        params: { query: query, per_page: 9 * pageNumber, page: 1 },
      });

      setImages(response.data.results);
      setHasMore(response.data.total - 9 * pageNumber > 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };
  const handleMore = () => {
    setPageNumber(pageNumber + 1);
    searchImages();
  };

  useEffect(() => {
    searchImages();
  }, [query, pageNumber]);
  return (
    <Box height={"100%"}>
      {loading ? (
        <Loader height={"100%"}></Loader>
      ) : (
        <Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <TextField
              variant="standard"
              type="text"
              fullWidth
              placeholder="Search for images"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                searchImages();
              }}
            />
          </Box>

          <Grid container justifyContent="center" spacing={1}>
            {images.map((image) => (
              <Grid xs={4} key={image.id} item>
                <Card
                  sx={{
                    minWidth: "100px !important",
                    padding: "0px !important",
                    maxHeight: 100,
                  }}
                >
                  <CardActionArea
                    onClick={(event) =>
                      handleUnsplashImageClick(event, image.urls.full)
                    }
                  >
                    <img
                      width="100%"
                      key={image.id}
                      src={image.urls.small}
                      title={image.alt_description}
                    />
                  </CardActionArea>
                </Card>
                <a
                  href={image.user.links.html}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="subtitle2">
                    by {image.user.username}
                  </Typography>
                </a>
              </Grid>
            ))}
          </Grid>

          {hasMore && (
            <Button onClick={handleMore} fullWidth>
              Load more
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UnsplashImagePicker;
