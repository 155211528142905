import clsx from "clsx";
import React from "react";
import {
  BoxProps,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDrop } from "react-dnd";

import { ComponentType } from "../../constants";
import { WeekAddComponent } from "../program-week/WeekAddComponent";
import { getTimeDiff } from "../../utils/date";
import type { ProgramAddComponentCallback } from "../program/ProgramDetails";
import { useCopyCalendarComponent } from "../../hooks/useCopyCalendarComponent";

import { WEEK_DRAG_ITEM_TYPE, WeekDragUpdate, DragItemTyped } from "./types";
import { usePasteComponentMutation } from "./mutations/PasteComponent";
import dayjs from "dayjs";
import { getBannerHeight } from "../../contexts/BannersContext";
import { headerHeight } from "../app/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(14),
    alignSelf: "stretch",
    width: "calc(100% / 7)",

    display: "flex",
    flexDirection: "column",

    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: theme.palette.quote,
    boxSizing: "content-box",

    "&:last-child": {
      borderRightWidth: 0,
    },
  },

  title: {
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0, 0.5),

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.quote,

    "&$today": {
      color: theme.palette.primary.main,
      borderBottomColor: theme.palette.primary.main,
    },

    "&$future": {
      color: theme.palette.common.black,
    },

    borderTop: "1px solid",
    borderTopColor: theme.palette.quote,
    background: "white",
    zIndex: theme.zIndex.appBar,
  },

  buttonsWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  buttons: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: `calc(100% - ${theme.spacing(1)})`,
    padding: theme.spacing(0.5),
    display: "none",

    "$buttonsWrapper:hover &": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
    },
  },

  pasteButton: {
    borderWidth: 2,
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: "15px",
  },

  today: {},
  future: {},
}));

export interface ProgramCalendarDayProps extends BoxProps {
  week: number;
  day: number;
  weekId: string;
  startDate?: string;
  onMove?: (update: WeekDragUpdate) => void;
  onMoveEnd?: () => void;
  onAddComponent?: ProgramAddComponentCallback;
}

export function ProgramCalendarDay(props: ProgramCalendarDayProps) {
  const {
    className,
    day,
    week,
    weekId,
    startDate,
    onMove,
    onMoveEnd,
    onAddComponent,
    children,
    ...other
  } = props;
  const s = useStyles();
  const dropRef = React.useRef<HTMLDivElement>();
  const [copiedCalendarComponentId, setCopiedCalendarComponentId] =
    useCopyCalendarComponent();
  const [pasteComponent] = usePasteComponentMutation();
  const [shiftDown, setShiftDown] = React.useState(false);

  const keyDownListener = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Shift" && !shiftDown) {
        setShiftDown(true);
      }
    },
    [shiftDown],
  );

  const keyUpListener = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Shift" && shiftDown) {
        setShiftDown(false);
      }
    },
    [shiftDown],
  );

  React.useEffect(() => {
    window.addEventListener("keydown", keyDownListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, [keyDownListener]);

  React.useEffect(() => {
    window.addEventListener("keyup", keyUpListener);

    return () => {
      window.removeEventListener("keyup", keyUpListener);
    };
  }, [keyUpListener]);

  const [, drop] = useDrop({
    accept: Object.values(WEEK_DRAG_ITEM_TYPE),
    hover(dragItem: DragItemTyped) {
      if (day !== dragItem.day || week !== dragItem.week) {
        const originDay = dragItem.day;
        const originWeek = dragItem.week;

        dragItem.day = day;
        dragItem.week = week;

        if (onMove) {
          onMove({
            type: dragItem.type as WEEK_DRAG_ITEM_TYPE,
            componentId: dragItem.componentId,
            originWeek,
            originDay,
            week,
            day,
          });
        }
      }
    },
    drop() {
      if (onMoveEnd) {
        onMoveEnd();
      }
    },
  });

  drop(dropRef);

  const handleAddComponent = React.useCallback(
    (componentType: ComponentType) => {
      if (onAddComponent) {
        const days = [...Array(7)].map((_, index) => index === day);

        onAddComponent(weekId, componentType, days);
      }
    },
    [day, onAddComponent, weekId],
  );

  const handlePasteComponentClick = React.useCallback(() => {
    pasteComponent({
      variables: {
        input: {
          componentId: copiedCalendarComponentId,
          weekId,
          day,
        },
      },
    });

    if (!shiftDown) {
      setCopiedCalendarComponentId(undefined);
    }
  }, [
    copiedCalendarComponentId,
    day,
    pasteComponent,
    setCopiedCalendarComponentId,
    shiftDown,
    weekId,
  ]);

  const title = React.useMemo(
    () =>
      startDate
        ? dayjs(startDate)
            .add(week - 1, "weeks")
            .add(day, "days")
            .format("ddd D")
        : `Day ${day + 1}`,
    [day, startDate, week],
  );

  const titleClassName = React.useMemo(() => {
    if (!startDate) {
      return;
    } else {
      const date = dayjs(startDate)
        .add(week - 1, "weeks")
        .add(day, "days")
        .format("YYYY-MM-DD");
      const timeDiff = getTimeDiff(date);
      return timeDiff === 0 ? s.today : timeDiff > 0 ? s.future : undefined;
    }
  }, [startDate, week, day, s.today, s.future]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const bannerSpacing = theme.spacing(getBannerHeight());
  const computedHeaderHeight = isMd ? headerHeight(theme) : "0px";

  return (
    <Box ref={dropRef} className={clsx(s.root, className)} {...other}>
      <Box
        sx={{
          background: "white",
          position: "sticky",
          top: `calc(44px + ${bannerSpacing} + ${computedHeaderHeight})`,
          zIndex: theme.zIndex.speedDial,
          marginLeft: day === 0 ? "-1px" : 0,
          marginRight: day === 6 ? "-1px" : 0,
        }}
      >
        <Typography
          className={clsx(s.title, titleClassName)}
          children={title}
          sx={{
            borderColor: theme.palette.quote,
            borderTopLeftRadius: day === 0 ? 8 : 0,
            borderTopRightRadius: day === 6 ? 8 : 0,
            borderLeftWidth: day === 0 ? 1 : 0,
            borderRightWidth: day === 6 ? 1 : 0,
          }}
        />
      </Box>

      {children}

      <div className={s.buttonsWrapper}>
        <div className={s.buttons}>
          {copiedCalendarComponentId && (
            <Button
              className={s.pasteButton}
              variant="outlined"
              fullWidth
              children="Paste"
              onClick={handlePasteComponentClick}
            />
          )}

          <WeekAddComponent
            variant="icon"
            onAddComponent={handleAddComponent}
          />
        </div>
      </div>
    </Box>
  );
}
