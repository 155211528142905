import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { maybePluralize } from "../../../utils/text";

import { CreateManyMessagesMutation } from "./__generated__/CreateManyMessagesMutation.graphql";

const mutation = graphql`
  mutation CreateManyMessagesMutation($input: CreateManyMessagesInput!) {
    createManyMessages(input: $input) {
      messages {
        id
      }
    }
  }
`;

export const useCreateManyMessagesMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<CreateManyMessagesMutation>(
    mutation,
    ({ onSuccess }) => ({
      onSuccess: (response) => {
        const {
          createManyMessages: { messages },
        } = response;

        snackAlert({
          severity: "success",
          message: `Message has been sent to ${maybePluralize(
            messages.length,
            "client",
          )}.`,
        });

        if (onSuccess) {
          onSuccess(response);
        }
      },
    }),
  );
};
