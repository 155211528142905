import { createContext } from "react";
import { CoachProgramEnrollmentsRouteQuery$data } from "../routes/coach/program/enrollments/__generated__/CoachProgramEnrollmentsRouteQuery.graphql";
import { CoachProgramCurriculumRouteQuery$data } from "../routes/coach/program/curriculum/__generated__/CoachProgramCurriculumRouteQuery.graphql";

export interface IProgramContextProps {
  programQuery:
    | CoachProgramEnrollmentsRouteQuery$data
    | CoachProgramCurriculumRouteQuery$data;
  setProgramQuery: (
    programQuery:
      | CoachProgramEnrollmentsRouteQuery$data
      | CoachProgramCurriculumRouteQuery$data,
  ) => void;
}

export const ProgramContext = createContext<IProgramContextProps | undefined>(
  undefined,
);
