/**
 * @generated SignedSource<<105b6ce80085df85b015532faf5c168b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupEnrollmentCard_groupEnrollment$data = {
  readonly group: {
    readonly name: string;
    readonly size: number;
  };
  readonly id: string;
  readonly " $fragmentType": "GroupEnrollmentCard_groupEnrollment";
};
export type GroupEnrollmentCard_groupEnrollment$key = {
  readonly " $data"?: GroupEnrollmentCard_groupEnrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupEnrollmentCard_groupEnrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupEnrollmentCard_groupEnrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GroupEnrollment",
  "abstractKey": null
};

(node as any).hash = "171be2e5c98b52993d764f4876a3faf5";

export default node;
