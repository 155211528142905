import clsx from "clsx";
import React from "react";
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},

  tooltip: {
    padding: theme.spacing(1, 2),
  },

  popper: {
    zIndex: 99999,
  },
}));

export interface TooltipProps extends MuiTooltipProps {}

export function Tooltip(props: TooltipProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <MuiTooltip
      className={clsx(s.root, className)}
      classes={{ tooltip: s.tooltip, popper: s.popper }}
      arrow
      {...other}
    />
  );
}
