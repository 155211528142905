import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useRefetchableFragment } from "react-relay";
import { Box, Container, ContainerProps } from "@mui/material";

import {
  measurementTypes,
  TypeMeasurement,
  Units,
  CompletionPeriod,
} from "../../constants";
import { useQueryParam } from "../../hooks/useQueryParam";
import { toEnum } from "../../utils/misc";
import { PeriodFilter } from "../filters/PeriodFilter";

import { ClientMeasurementScreen_client$key } from "./__generated__/ClientMeasurementScreen_client.graphql";
import { ClientMeasurementChart } from "./ClientMeasurementChart";
import { ClientMeasurementRows } from "./ClientMeasurementRows";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    maxWidth: theme.breakpoints.values.slg,
  },

  paper: {
    minHeight: "90vh",
  },

  card: {
    margin: theme.spacing(4, 0),
  },
}));

export const TrendMetricNutrition = "nutrition";
export type TrendMetricType = TypeMeasurement | typeof TrendMetricNutrition;

export const defaultClientMeasurementType = TypeMeasurement.BODYFAT;
export const defaultCompletionPeriod = CompletionPeriod.ALL_TIME;

export const toTrendMetricType = (value: string): TrendMetricType =>
  value === TrendMetricNutrition
    ? TrendMetricNutrition
    : toEnum(value, TypeMeasurement, defaultClientMeasurementType);

export type TrendMetricQueryVariablesParams = {
  username: string;
  metric?: string;
  period?: string;
};

export const toTrendMetricQueryVariables = ({
  username,
  metric,
  period,
}: TrendMetricQueryVariablesParams) => {
  const includeNutrition = metric === TrendMetricNutrition;
  const includeMeasurement = !includeNutrition;

  return {
    username,
    includeNutrition,
    includeMeasurement,
    ...(includeNutrition
      ? {
          period: period as any,
        }
      : {
          measurementType: toTrendMetricType(metric).toUpperCase() as any,
        }),
  };
};

const fragment = graphql`
  fragment ClientMeasurementScreen_client on User
  @refetchable(queryName: "ClientMeasurementScreenRefetchQuery")
  @argumentDefinitions(
    measurementType: { type: "MeasurementType" }
    includeMeasurement: { type: "Boolean!" }
    includeNutrition: { type: "Boolean!" }
    period: { type: "CompletionPeriodType" }
  ) {
    id
    username
    units

    nutritionTarget {
      trackingType
    }

    clientNutritionEntries(period: $period) @include(if: $includeNutrition) {
      edges {
        node {
          ...ClientNutritionResults_nutritionEntries
        }
      }
    }

    metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: $measurementType
    ) @include(if: $includeMeasurement) {
      ...ClientMeasurementChart_metrics
      ...ClientMeasurementRows_metrics
    }
  }
`;

export interface ClientMeasurementScreenProps
  extends Omit<ContainerProps, "children"> {
  client: ClientMeasurementScreen_client$key;
}

export function ClientMeasurementScreen(props: ClientMeasurementScreenProps) {
  const s = useStyles();
  const { className, client: clientRef, ...other } = props;
  const [client, refetch] = useRefetchableFragment(fragment, clientRef);

  const { username } = client;
  const metrics = client.metrics && client.metrics.filter(Boolean);
  const [stale, setStale] = React.useState(false);

  const [measurementType] = useQueryParam<TrendMetricType>(
    "metric",
    defaultClientMeasurementType,
    {
      silent: true,
      normalize: toTrendMetricType,
    },
  );

  const [period, setPeriod] = useQueryParam("period", defaultCompletionPeriod, {
    silent: true,
  });

  const showNutrition = measurementType === TrendMetricNutrition;

  // const handleDropdownClick = React.useCallback(
  //   ({
  //     currentTarget: {
  //       dataset: { name },
  //     },
  //   }: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
  //     setMeasurementType(toTrendMetricType(name));
  //     setStale(true);
  //   },
  //   [setMeasurementType],
  // );
  //
  // const nutritionLabel = `Nutrition (${
  //   trackingType === "PORTIONS" ? "Portions" : "Macros"
  // })`;

  // const items = React.useMemo(
  //   () => [
  //     {
  //       children: nutritionLabel,
  //       selected: showNutrition,
  //       onClick: handleDropdownClick,
  //       data: {
  //         name: TrendMetricNutrition,
  //       },
  //     },
  //     ...measurementTypes.map(
  //       ({ label, name }) =>
  //         ({
  //           children: label,
  //           selected: name === measurementType,
  //           onClick: handleDropdownClick,
  //           data: {
  //             name,
  //           },
  //         } as DropdownMenuItem),
  //     ),
  //   ],
  //   [handleDropdownClick, measurementType, nutritionLabel, showNutrition],
  // );

  const selectedType = measurementTypes.find(
    ({ name }) => name === measurementType,
  );

  const refresh = React.useCallback(() => {
    const variables = toTrendMetricQueryVariables({
      username,
      metric: measurementType,
      period,
    });

    refetch(variables, {
      onComplete: () => setStale(false),
    });
  }, [measurementType, period, username]);

  React.useEffect(() => {
    if (stale) {
      refresh();
    }
  }, [client.username, measurementType, refresh, stale]);

  const units = client.units as Units;

  const handlePeriodChange = React.useCallback(
    ({
      currentTarget: {
        dataset: { period },
      },
    }: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      setPeriod(toEnum(period, CompletionPeriod, defaultCompletionPeriod));
      setStale(true);
    },
    [setPeriod],
  );

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.paper}>
        {/*<DropdownMenu*/}
        {/*  header={selectedType?.label || nutritionLabel}*/}
        {/*  subHeader="Switch to"*/}
        {/*  variant="big"*/}
        {/*  items={items}*/}
        {/*/>*/}

        {showNutrition ? (
          <>
            <PeriodFilter
              selectedPeriod={period as CompletionPeriod}
              onPeriodChange={handlePeriodChange}
            />

            {/*{client.clientNutritionEntries && (*/}
            {/*  <ClientNutritionResults*/}
            {/*    trackingType={trackingType as NutritionTrackingType}*/}
            {/*    nutritionEntries={client.clientNutritionEntries.edges.map(*/}
            {/*      ({ node }) => node,*/}
            {/*    )}*/}
            {/*  />*/}
            {/*)}*/}
          </>
        ) : (
          metrics && (
            <>
              <ClientMeasurementChart
                className={s.card}
                title={selectedType.label}
                metrics={metrics}
                measurementType={selectedType}
                units={units}
              />
              <ClientMeasurementRows
                className={s.card}
                clientId={client.id}
                metrics={metrics}
                measurementType={selectedType}
                units={units}
                refresh={refresh}
              />
            </>
          )
        )}
      </Box>
    </Container>
  );
}
