/**
 * @generated SignedSource<<08304c4300980ff88c05047b8fdd56ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityAnswersReadInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type ActivityFeedbackListAnswersReadMutation$variables = {
  input: ActivityAnswersReadInput;
};
export type ActivityFeedbackListAnswersReadMutation$data = {
  readonly activityAnswersRead: {
    readonly activity: {
      readonly " $fragmentSpreads": FragmentRefs<"ActivityResponseCard_activity">;
    } | null | undefined;
  } | null | undefined;
};
export type ActivityFeedbackListAnswersReadMutation = {
  response: ActivityFeedbackListAnswersReadMutation$data;
  variables: ActivityFeedbackListAnswersReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "fromNow"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityFeedbackListAnswersReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivityAnswersReadPayload",
        "kind": "LinkedField",
        "name": "activityAnswersRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityResponseCard_activity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityFeedbackListAnswersReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivityAnswersReadPayload",
        "kind": "LinkedField",
        "name": "activityAnswersRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "raw",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(raw:true)"
              },
              {
                "alias": "dateFormatted",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "ddd, MMM DD"
                  },
                  {
                    "kind": "Literal",
                    "name": "utc",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(format:\"ddd, MMM DD\",utc:true)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": "completedAt(format:\"fromNow\")"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": "submittedAt(format:\"fromNow\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "edited",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startWorkout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endWorkout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Enrollment",
                "kind": "LinkedField",
                "name": "enrollment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "program",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "component",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientMetric",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formattedValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "value",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          (v3/*: any*/)
                        ],
                        "type": "CheckinAnswerFileValue",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityFeedback",
                "kind": "LinkedField",
                "name": "feedbacks",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "read",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"fromNow\")"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkoutExerciseResult",
                "kind": "LinkedField",
                "name": "workoutExerciseResults",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientForm",
                "kind": "LinkedField",
                "name": "clientForm",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachArchived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachReadAnswers",
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "463a082d25e5e1ec502c0f8b885d0c1a",
    "id": null,
    "metadata": {},
    "name": "ActivityFeedbackListAnswersReadMutation",
    "operationKind": "mutation",
    "text": "mutation ActivityFeedbackListAnswersReadMutation(\n  $input: ActivityAnswersReadInput!\n) {\n  activityAnswersRead(input: $input) {\n    activity {\n      ...ActivityResponseCard_activity\n      id\n    }\n  }\n}\n\nfragment ActivityResponseCard_activity on Activity {\n  id\n  type\n  date(raw: true)\n  dateFormatted: date(utc: true, format: \"ddd, MMM DD\")\n  completedAt(format: \"fromNow\")\n  submittedAt(format: \"fromNow\")\n  answers\n  edited\n  startWorkout\n  endWorkout\n  enrollment {\n    program {\n      name\n      slug\n      id\n    }\n    id\n  }\n  client {\n    displayName\n    photoURL\n    username\n    id\n  }\n  component {\n    type\n    title\n    slug\n    id\n  }\n  metrics {\n    id\n    type\n    name\n    formattedValue\n    value {\n      __typename\n      ... on CheckinAnswerFileValue {\n        url\n        name\n        type\n      }\n    }\n  }\n  feedbacks {\n    id\n    ...ActivityResponseItem_feedback\n  }\n  workoutExerciseResults {\n    ...ActivityWorkoutResponse_workoutExerciseResult\n    id\n  }\n  clientForm {\n    slug\n    id\n  }\n  coachArchived\n  coachReadAnswers\n  ...FeedbackForm_activity\n  ...ActivityResponseMealsList_activity\n}\n\nfragment ActivityResponseItem_feedback on ActivityFeedback {\n  id\n  author {\n    id\n    role\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ActivityResponseMealsList_activity on Activity {\n  content\n}\n\nfragment ActivityWorkoutResponse_workoutExerciseResult on WorkoutExerciseResult {\n  id\n  value\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "358d9f394fa52eed0803730d3f7525b0";

export default node;
