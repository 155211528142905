import React, { useEffect, useState } from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Menu, MenuItem, Typography } from "@mui/material";

import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import { IElementChildrenProp } from "../other/commonInterfaces";

export const ELEMENT_PULLQUOTE = "pullquote";

export const Pullquote = ({ children }: IElementChildrenProp) => {
  return (
    <Box display={"flex"} gap={1.5} alignItems={"flex-start"}>
      <Box contentEditable={false}>
        <FormatQuoteRoundedIcon
          className="text-5xl"
          sx={{
            transform: "rotate(180deg)",
          }}
          color="primary"
        ></FormatQuoteRoundedIcon>
      </Box>
      <Typography pt={1.5} component="span" fontWeight={600} fontSize={24}>
        <Box component="p" sx={{ wordBreak: "break-word" }} display={"block"}>
          {children}
        </Box>
      </Typography>
    </Box>
  );
};

export const PullquoteElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Pullquote children={children} />
      </PlateElement>
    );
  },
);
