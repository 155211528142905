/**
 * @generated SignedSource<<5179170c66a4dedd1197b94a7dd8ba10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupManageMembersButton_root$data = {
  readonly clients: {
    readonly " $fragmentSpreads": FragmentRefs<"ManageGroupDialog_clients">;
  } | null | undefined;
  readonly group: {
    readonly " $fragmentSpreads": FragmentRefs<"ManageGroupDialog_group">;
  } | null | undefined;
  readonly " $fragmentType": "GroupManageMembersButton_root";
};
export type GroupManageMembersButton_root$key = {
  readonly " $data"?: GroupManageMembersButton_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupManageMembersButton_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupManageMembersButton_root",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        }
      ],
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ManageGroupDialog_group"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 9999
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "ACTIVE"
        }
      ],
      "concreteType": "ClientConnection",
      "kind": "LinkedField",
      "name": "clients",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ManageGroupDialog_clients"
        }
      ],
      "storageKey": "clients(first:9999,status:\"ACTIVE\")"
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "3f944e339272030396141d9c6cd33127";

export default node;
