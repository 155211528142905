import clsx from "clsx";
import React from "react";
import {
  ListItemText,
  ListItemIcon,
  TextField,
  Box,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  SortableListItemProps,
  SortableListItem,
} from "../sortable-list/SortableListItem";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";
import { ReactComponent as RadioButtonUncheckedIcon } from "../../icons/RadioButtonUnchecked.svg";
import { ReactComponent as CheckboxUncheckedIcon } from "../../icons/CheckboxUnchecked.svg";

import { MealLoggingOption } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {},

  primaryText: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  textField: {
    "& > div": {
      minHeight: "auto",
    },

    "& fieldset": {
      border: 0,
    },

    "& input": {
      padding: 0,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },

  empty: {
    color: theme.palette.text.secondary,
  },

  addOther: {
    lineHeight: 1.5,
    padding: 0,
  },

  value: {
    cursor: "pointer",
  },

  prompt: {
    cursor: "initial",
    fontStyle: "italic",
  },
}));

export interface MealLoggingQuestionOptionsListItemProps
  extends SortableListItemProps<MealLoggingOption> {
  type: "radio" | "checkbox";
  hasOther?: boolean;
}

export function MealLoggingQuestionOptionsListItem(
  props: MealLoggingQuestionOptionsListItemProps,
) {
  const s = useStyles();
  const { className, type, hasOther, ...other } = props;
  const { onAction, option } = props;
  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(option.text);

  const handleClick = React.useCallback(() => {
    if (!edit && !option.prompt) {
      setEdit(true);
    }
  }, [edit, option.prompt]);

  const handleBlur = React.useCallback(
    (event) => {
      onAction(event, option);
      setEdit(false);
    },
    [onAction, option],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.currentTarget.value);
    },
    [],
  );

  const handleAddOther = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onAction(event, option);
    },
    [onAction, option],
  );

  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (value && event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();

        handleBlur(event);
      }
    },
    [handleBlur, value],
  );

  const empty = !value;

  return (
    <SortableListItem
      className={clsx(s.root, className)}
      actions={empty ? [] : [{ name: "remove", Icon: RemoveIcon }]}
      disableDragging={!value}
      {...other}
    >
      <ListItemIcon>
        {type === "radio" ? (
          <RadioButtonUncheckedIcon />
        ) : (
          <CheckboxUncheckedIcon />
        )}
      </ListItemIcon>
      <ListItemText classes={{ primary: s.primaryText }} onClick={handleClick}>
        {edit ? (
          <TextField
            inputProps={{
              "data-action": "change-text",
              onKeyPress: handleKeyPress,
            }}
            className={s.textField}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
            autoFocus
            variant="outlined"
          />
        ) : empty ? (
          <Box className={s.empty}>
            Add option
            {!hasOther && (
              <>
                {" or "}
                <Button
                  className={s.addOther}
                  variant="text"
                  color="primary"
                  onClick={handleAddOther}
                  data-action="add-other"
                >
                  add "Other"
                </Button>
              </>
            )}
          </Box>
        ) : (
          <Box className={clsx(s.value, option.prompt && s.prompt)}>
            {value}
          </Box>
        )}
      </ListItemText>
    </SortableListItem>
  );
}
