import React from "react";

import { UnsplashImages_rootRef$key } from "../components/unsplash/__generated__/UnsplashImages_rootRef.graphql";

export const UnsplashContext =
  React.createContext<UnsplashImages_rootRef$key>(null);

export function useUnsplash() {
  return React.useContext(UnsplashContext);
}
