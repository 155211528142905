import React from "react";
import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { DeleteProgramFolderMutation } from "./__generated__/DeleteProgramFolderMutation.graphql";

const mutation = graphql`
  mutation DeleteProgramFolderMutation($input: DeleteProgramFolderInput!) {
    deleteProgramFolder(input: $input) {
      programFolderId
    }
  }
`;

export const useDeleteProgramFolderMutation = () => {
  return useConfiguredMutation<DeleteProgramFolderMutation>(mutation);
};
