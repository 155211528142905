import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ProgressLine } from "../progress/ProgressLine";

import { ClientCardCompletion_client$key } from "./__generated__/ClientCardCompletion_client.graphql";
import { ClientCardCompletion_enrollment$key } from "./__generated__/ClientCardCompletion_enrollment.graphql";
import { ClientLast7DaysCompletion } from "./ClientLast7DaysCompletion";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  last7DaysCompletion: {
    marginBottom: theme.spacing(1),
  },

  text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "justify",
    textAlignLast: "justify",
    padding: theme.spacing(0, 0.2),
  },

  progress: {
    marginTop: theme.spacing(1),
  },

  completed: {},
  none: {},
  some: {},
  empty: {},
}));

const clientFragment = graphql`
  fragment ClientCardCompletion_client on User {
    completion30Days: completion(period: LAST_30_DAYS) {
      completed
      total
      rate
    }
    completionByDate(period: LAST_7_DAYS) {
      ...ClientLast7DaysCompletion_completionByDate
    }
  }
`;

const enrollmentFragment = graphql`
  fragment ClientCardCompletion_enrollment on Enrollment {
    completion30Days: completion(period: LAST_30_DAYS) {
      completed
      total
      rate
    }
    completionByDate(period: LAST_7_DAYS) {
      ...ClientLast7DaysCompletion_completionByDate
    }
  }
`;

export interface ClientCardCompletionProps extends BoxProps {
  clientRef?: ClientCardCompletion_client$key;
  enrollmentRef?: ClientCardCompletion_enrollment$key;
}

export function ClientCardCompletion(props: ClientCardCompletionProps) {
  const { className, clientRef, enrollmentRef, ...other } = props;
  const s = useStyles();

  if ((!clientRef && !enrollmentRef) || (clientRef && enrollmentRef)) {
    throw new Error(
      "ClientCardCompletion requires either clientRef or enrollmentRef prop.",
    );
  }

  const client = useFragment(clientFragment, clientRef);
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);

  const {
    completion30Days: { completed, total, rate },
    completionByDate,
  } = clientRef ? client : enrollment;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientLast7DaysCompletion
        className={s.last7DaysCompletion}
        fragmentRef={completionByDate}
      />
      <Typography className={s.text} component="div">
        Last 30 days &bull; {completed} of {total} completed
      </Typography>
      <ProgressLine className={s.progress} value={rate} />
    </Box>
  );
}
