import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import "emoji-mart/css/emoji-mart.css";
import { usePopupState, bindTrigger } from "material-ui-popup-state/hooks";

import { ReactComponent as GifIcon } from "../../icons/Gif.svg";

import { SelectGifDialog, SelectGifDialogProps } from "./SelectGifDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface MessageEditorGifButtonProps extends IconButtonProps {
  onSelectGif?: SelectGifDialogProps["onSelectGif"];
}

export function MessageEditorGifButton(props: MessageEditorGifButtonProps) {
  const { className, onSelectGif, ...other } = props;
  const s = useStyles();
  const dialogState = usePopupState({
    variant: "popover",
    popupId: "select-gif",
  });

  return (
    <>
      <IconButton
        className={clsx(s.root, className)}
        {...bindTrigger(dialogState)}
        {...other}
        size="large"
      >
        <GifIcon />
      </IconButton>
      {dialogState.isOpen && (
        <SelectGifDialog
          open
          onClose={dialogState.close}
          onSelectGif={onSelectGif}
        />
      )}
    </>
  );
}
