import React from "react";
import { RequiresUpgradeClientScreen } from "../../../components/screen/RequiresUpgradeClientScreen";
import { RequiresUpgradeReason } from "../../../constants";

const ClientTrialCanceledRoute = () => {
  return (
    <>
      <RequiresUpgradeClientScreen
        reason={RequiresUpgradeReason.SUBSCRIPTION_CANCELED}
      />
    </>
  );
};

export default ClientTrialCanceledRoute;
