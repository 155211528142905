import { ElementType } from "../editor/types/elements";
import { AssetType } from "../../constants";

export const getTypesByMime = (mimeType: string) => {
  if (mimeType.startsWith("image/")) {
    return {
      elementType: ElementType.IMAGE,
      type: AssetType.LIBRARY_IMAGE,
    };
  }

  if (mimeType.startsWith("video/")) {
    return {
      elementType: ElementType.VIDEO,
      type: AssetType.LIBRARY_VIDEO,
    };
  }

  if (mimeType.startsWith("audio/")) {
    return {
      elementType: ElementType.AUDIO,
      type: AssetType.LIBRARY_AUDIO,
    };
  }

  return {
    elementType: ElementType.FILE,
    type: AssetType.LIBRARY_FILE,
  };
};
