import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { ClientPrograms } from "../../../../components/coach-clients/ClientPrograms";
import { CoachClientProgramsRouteQuery } from "./__generated__/CoachClientProgramsRouteQuery.graphql";
import { ClientContext } from "../../../../contexts/ClientContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachClientProgramsRoute() {
  const { username } = useParams();

  const props = useLazyLoadQuery<CoachClientProgramsRouteQuery>(
    graphql`
      query CoachClientProgramsRouteQuery($username: String!) {
        client: user(username: $username) {
          ...ClientSummary_client
          ... on User {
            username
            email
            displayName
          }
        }
        enrollments(client: $username) {
          ...ClientPrograms_enrollments
        }
      }
    `,
    {
      username: username,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const context = useContext(ClientContext);
  context.setCoachClientQuery(props);

  const { client, enrollments } = props;
  return (
    client && (
      <>
        <ClientPrograms enrollmentsRef={enrollments} />
        <TrackInfoTool
          trackInfo={{
            name: "Coach - Client Programs",
            properties: {
              clientUsername: client.username,
            },
          }}
        />
      </>
    )
  );
}
