/**
 * @generated SignedSource<<a6dbf01d2847bb2723c8e0e3ca8415d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecipientListItem_recipient$data = {
  readonly group: {
    readonly membersCount: number;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly user: {
    readonly email: string | null | undefined;
    readonly photoURL: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "RecipientListItem_recipient";
};
export type RecipientListItem_recipient$key = {
  readonly " $data"?: RecipientListItem_recipient$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecipientListItem_recipient">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecipientListItem_recipient",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membersCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageRecipient",
  "abstractKey": null
};

(node as any).hash = "099b2d517f870505a3f3514ce9907f3e";

export default node;
