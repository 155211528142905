import React from "react";
import clsx from "clsx";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as ArrowUpBlack } from "../../icons/ArrowUpBlack.svg";
import { ReactComponent as ArrowUpGrey } from "../../icons/ArrowUpGrey.svg";
import { ReactComponent as ArrowDownBlack } from "../../icons/ArrowDownBlack.svg";
import { ReactComponent as ArrowDownGrey } from "../../icons/ArrowDownGrey.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    "& span:first-child": {
      marginLeft: theme.spacing(-0.5),
    },
    "& span:last-child": {
      marginLeft: theme.spacing(-1.25),
    },
  },
  arrow: {
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
}));

type CoachClientsArrowsSortProps = {
  activeUp: boolean;
  activeDown: boolean;
};

const CoachClientsArrowsSort = ({
  activeUp,
  activeDown,
}: CoachClientsArrowsSortProps) => {
  const s = useStyles();
  return (
    <Box className={s.container}>
      <span className={s.arrow}>
        {activeUp ? <ArrowUpBlack /> : <ArrowUpGrey />}
      </span>
      <span className={clsx(s.arrow)}>
        {activeDown ? <ArrowDownBlack /> : <ArrowDownGrey />}
      </span>
    </Box>
  );
};

export default CoachClientsArrowsSort;
