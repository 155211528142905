import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { SignUpMutation } from "./__generated__/SignUpMutation.graphql";

const mutation = graphql`
  mutation SignUpMutation($input: SignUpInput!) {
    signUp(input: $input) {
      idToken
    }
  }
`;

export const useSignUpMutation = () => {
  return useConfiguredMutation<SignUpMutation>(mutation, () => ({
    updater: (store) => {
      (store as any).invalidateStore();
    },
  }));
};
