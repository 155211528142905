import React from "react";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 250,
    padding: 5.5,
  },
  skeleton: {
    height: theme.spacing(2),
  },
}));

const ClientStatsSkeleton = () => {
  const s = useStyles();

  return (
    <>
      <div className={s.card}>
        <Skeleton className={s.skeleton} animation="wave" />
        <Skeleton className={s.skeleton} animation="wave" />
        <Skeleton className={s.skeleton} animation="wave" />
      </div>
    </>
  );
};

export default ClientStatsSkeleton;
