import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/app/App";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-database";
import "./config";
import "./index.css";
import "./slate.css";
import { unregister } from "./serviceWorker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

// TODO check logs
// const SENTRY_DSN = process.env.SENTRY_DSN;
// const Sentry: any = window["Sentry"];

// if (Sentry && SENTRY_DSN) {
//   const environment = document.body.getAttribute("data-env") || "local";
//   const release =
//     "stridist-" + (document.body.getAttribute("data-version") || "latest");

//   Sentry.init({
//     dsn: SENTRY_DSN,
//     environment,
//     release,
//   });
// }
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

const root = createRoot(document.getElementById("root"));

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
