import React from "react";
import { DialogProps, useTheme } from "@mui/material";

import art from "../../icons/UploadPortalLogoArt.svg";
import { UploadDialog } from "../dialog/UploadDialog";
import {
  DropzoneUploadButton,
  DropzoneUploadButtonProps,
} from "../button/DropzoneUploadButton";
import { AssetType } from "../../constants";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";
import UploadPhoto from "../../icons/UploadPhoto";

// export interface ClientPortalSettingsUploadLogoDialogProps
//   extends Omit<DialogProps, "onChange">,
//     Pick<DropzoneUploadButtonProps, "onChange">,
//     Pick<UploadDialogProps, "onSkipClick" | "noSkip"> {}

export function ClientPortalSettingsUploadLogoDialog(props: any) {
  const { className, onChange, ...other } = props;
  const theme = useTheme();
  return (
    <UploadDialog
      title="Add your logo"
      subtitle="A square image that will be shown on your client portal. Recommended size is 400px x 400px."
      content={<UploadPhoto fill={theme.palette.primary.main}></UploadPhoto>}
      action={
        <DropzoneUploadButton
          onChange={onChange}
          assetType={AssetType.PORTAL_LOGO}
          mimeType={getMimeTypes(ElementType.IMAGE)}
          inputName="logoURL"
          assetDescription="a logo"
        />
      }
      {...other}
    />
  );
}
