import { createContext } from "react";

export interface IContextProps {
  addClientDialog: boolean;
  setAddClientDialog: (data: boolean) => void;
}

const AddClientDialogContext = createContext<IContextProps | undefined>(
  undefined,
);

export default AddClientDialogContext;
