import { ReactComponent as MultipleChoiceIcon } from "../../icons/MultipleChoiceBoxed.svg";
import { ReactComponent as YesNoIcon } from "../../icons/YesNoBoxed.svg";
import { ReactComponent as EmojiIcon } from "../../icons/EmojiBoxed.svg";
import { ReactComponent as StarIcon } from "../../icons/StarOutlinedBoxed.svg";
import { ReactComponent as TextIcon } from "../../icons/ParagraphBoxed.svg";
import { ReactComponent as CheckboxIcon } from "../../icons/CheckboxBoxed.svg";
import { ReactComponent as NumberIcon } from "../../icons/HashBoxed.svg";
import { ReactComponent as RatingIcon } from "../../icons/ChainBoxed.svg";
import { ReactComponent as NutritionIcon } from "../../icons/AppleBoxed.svg";

import { MealLoggingQuestionType } from "./types";

export const MealLoggingQuestionTypes = {
  [MealLoggingQuestionType.MULTIPLE_CHOICE]: {
    name: "Multiple choice",
    Icon: MultipleChoiceIcon,
  },

  [MealLoggingQuestionType.EMOJI]: {
    name: "Emoji",
    Icon: EmojiIcon,
  },

  [MealLoggingQuestionType.YES_NO]: {
    name: "Yes/No",
    Icon: YesNoIcon,
  },

  [MealLoggingQuestionType.STAR_RATING]: {
    name: "Star rating",
    Icon: StarIcon,
  },

  [MealLoggingQuestionType.LONG_TEXT]: {
    name: "Long text",
    Icon: TextIcon,
  },

  [MealLoggingQuestionType.CHECKBOXES]: {
    name: "Checkboxes",
    Icon: CheckboxIcon,
  },

  [MealLoggingQuestionType.NUMBER]: {
    name: "Number",
    Icon: NumberIcon,
  },

  [MealLoggingQuestionType.RATING_SCALE]: {
    name: "Rating scale",
    Icon: RatingIcon,
  },

  [MealLoggingQuestionType.NUTRITION]: {
    name: "Nutrition",
    Icon: NutritionIcon,
  },
} as const;

export const MEAL_LOGGING_OPTIONS_OTHER = "Other…";
