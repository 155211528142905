import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BackButton } from "../button/BackButton";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginBottom: 8,
    marginTop: -8,
  },
  itemContainer: {
    display: "flex",
  },
  item: {
    textAlign: "center",
    maxWidth: 280,
    width: "100%",
    padding: theme.spacing(0, 0, 1, 0),
    color: colorSystem.gray,
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  activeItem: {
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  search: {
    maxWidth: 550,
    marginTop: theme.spacing(3),
    "& fieldset": {
      borderRadius: 0,
      borderColor: theme.palette.border.primary,
      borderWidth: 1,
      borderStyle: "solid",
    },
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

const ClientWorkoutsExercisesHeader = (props: any) => {
  const {
    selectWorkout,
    selectExercise,
    handleCloseWorkout,
    handleExitBox,
    type,
    setType,
  } = props;
  const { breakpoints } = useTheme();
  const s = useStyles();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  return (
    <>
      {!smUp && (
        <BackButton
          className={s.backButton}
          tooltip={""}
          onClick={() =>
            selectWorkout || selectExercise
              ? handleCloseWorkout()
              : handleExitBox()
          }
        />
      )}
      {smUp ? (
        <Box className={s.itemContainer}>
          <Typography
            className={clsx(s.item, type === "workout" && s.activeItem)}
            onClick={() => setType("workout")}
          >
            Workouts
          </Typography>
          <Typography
            className={clsx(s.item, type === "exercises" && s.activeItem)}
            onClick={() => setType("exercises")}
          >
            Exercises
          </Typography>
        </Box>
      ) : (
        !smUp &&
        (!selectExercise || !selectWorkout) && (
          <Box className={s.itemContainer}>
            <Typography
              className={clsx(s.item, type === "workout" && s.activeItem)}
              onClick={() => setType("workout")}
            >
              Workouts
            </Typography>
            <Typography
              className={clsx(s.item, type === "exercises" && s.activeItem)}
              onClick={() => setType("exercises")}
            >
              Exercises
            </Typography>
          </Box>
        )
      )}
    </>
  );
};

export default React.memo(ClientWorkoutsExercisesHeader);
