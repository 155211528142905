import clsx from "clsx";
import React from "react";
import { DialogProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFragment, useMutation, graphql } from "react-relay/hooks";

import { DatePicker } from "../fields/DatePicker";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { getISODate } from "../../utils/date";

import { BaseDialog } from "./BaseDialog";
import { ChangeEnrollmentStartDateDialog_enrollment$key } from "./__generated__/ChangeEnrollmentStartDateDialog_enrollment.graphql";
import { ChangeEnrollmentStartDateDialogChangeStartDateMutation } from "./__generated__/ChangeEnrollmentStartDateDialogChangeStartDateMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

const enrollmentFragment = graphql`
  fragment ChangeEnrollmentStartDateDialog_enrollment on Enrollment {
    id
  }
`;

const changeStartDateMutation = graphql`
  mutation ChangeEnrollmentStartDateDialogChangeStartDateMutation(
    $input: ChangeEnrollmentStartDateInput!
  ) {
    changeEnrollmentStartDate(input: $input) {
      enrollment {
        ...CoachClientCalendar_enrollment
      }
    }
  }
`;

export interface ChangeEnrollmentStartDateDialogProps extends DialogProps {
  enrollmentRef: ChangeEnrollmentStartDateDialog_enrollment$key;
  onSuccess?: () => void;
}

export function ChangeEnrollmentStartDateDialog(
  props: ChangeEnrollmentStartDateDialogProps,
) {
  const { className, enrollmentRef, onSuccess, onClose, ...other } = props;
  const s = useStyles();
  const [startDate, setStartDate] = React.useState(new Date());
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);
  const [changeStartDate, changeStartDateInFlight] =
    useMutation<ChangeEnrollmentStartDateDialogChangeStartDateMutation>(
      changeStartDateMutation,
    );
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();

  const handleDateChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setStartDate(new Date());
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleUpdateClick = React.useCallback(() => {
    changeStartDate({
      variables: {
        input: { id: enrollment.id, startDate: getISODate(startDate) },
      },
      onCompleted(_, errors) {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          snackAlert({
            severity: "success",
            message: "Enrollment start date changed.",
          });
          handleClose();

          if (onSuccess) {
            onSuccess();
          }
        }
      },
      onError,
    });
  }, [
    changeStartDate,
    enrollment.id,
    handleClose,
    onError,
    onSuccess,
    snackAlert,
    startDate,
  ]);

  const disabled = !startDate || changeStartDateInFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Change program start date"
      subtitle="Select date the program should start."
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleDateChange}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="Update start date"
        onClick={handleUpdateClick}
        disabled={disabled}
      />
    </BaseDialog>
  );
}
