import { graphql } from "react-relay/lib/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { DuplicateClientFormMutation } from "./__generated__/DuplicateClientFormMutation.graphql";

const mutation = graphql`
  mutation DuplicateClientFormMutation($input: DuplicateClientFormInput!) {
    duplicateClientForm(input: $input) {
      clientFormEdge {
        node {
          ...CoachClientsFormsListItem_clientForm
        }
      }
    }
  }
`;

export const useDuplicateClientFormMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<DuplicateClientFormMutation>(
    mutation,
    ({ onSuccess }) => ({
      configs: [
        {
          type: "RANGE_ADD",
          parentID: "client:root",
          connectionInfo: [
            {
              key: "CoachClientsFormsListScreen_clientForms",
              rangeBehavior: "prepend",
            },
          ],
          edgeName: "clientFormEdge",
        },
      ],

      onSuccess: (response) => {
        snackAlert({
          severity: "success",
          message: "Client form duplicated.",
        });

        if (onSuccess) {
          onSuccess(response);
        }
      },
    }),
  );
};
