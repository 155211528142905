/**
 * @generated SignedSource<<c7e11c873f141d01e93ba2d6e823feb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeEnrollmentStartDateDialog_enrollment$data = {
  readonly id: string;
  readonly " $fragmentType": "ChangeEnrollmentStartDateDialog_enrollment";
};
export type ChangeEnrollmentStartDateDialog_enrollment$key = {
  readonly " $data"?: ChangeEnrollmentStartDateDialog_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeEnrollmentStartDateDialog_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeEnrollmentStartDateDialog_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "089d9837b84790b8fac2640299576e27";

export default node;
