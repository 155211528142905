import clsx from "clsx";
import React from "react";
import { Menu, MenuProps, ListItem, ListItemText, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";

import { NotificationsMenu_userRef$key } from "./__generated__/NotificationsMenu_userRef.graphql";
import { NotificationBox } from "./NotificationBox";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "9999 !important" as any,
  },

  paper: {
    padding: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 2),
    },
  },

  list: {
    width: "calc(100vw - 32px)",
    maxHeight: 440,
    padding: 0,

    "@media (min-width: 472px)": {
      width: 400,
    },
  },

  item: {
    padding: 0,
  },

  button: {
    margin: theme.spacing(1, 0),
  },
}));

const userRefFragment = graphql`
  fragment NotificationsMenu_userRef on User
  @refetchable(queryName: "NotificationsMenuRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String", defaultValue: null }
  ) {
    notifications(first: $first, after: $after)
      @connection(key: "NotificationsMenu_notifications") {
      edges {
        node {
          id
          ...NotificationBox_notification
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export interface NotificationsMenuProps extends MenuProps {
  className?: string;
  userRefRef: NotificationsMenu_userRef$key;
  pageSize?: number;
  onClose: () => void;
  id: string;
  role: string;
}

export function NotificationsMenu(props: NotificationsMenuProps) {
  const { className, userRefRef, pageSize = 10, onClose, ...other } = props;
  const {
    data: userRef,
    loadNext,
    hasNext,
  } = usePaginationFragment(userRefFragment, userRefRef);
  const { notifications } = userRef;
  const s = useStyles();

  const handleLoadMoreClick = React.useCallback(() => {
    loadNext(pageSize);
  }, [pageSize, loadNext]);

  const [notificationList, setNotificationList] = React.useState(notifications);

  const handleDeleteClick = React.useCallback(
    (index) => {
      const newNotificationList = {
        ...notificationList,
        totalCount: notificationList.totalCount - 1,
        edges: notificationList.edges.filter(
          (_, edgeIndex) => edgeIndex !== index,
        ),
      };
      setNotificationList(newNotificationList);
    },
    [notificationList],
  );

  React.useEffect(() => {
    notifications && setNotificationList(notifications);
  }, [notifications]);

  return (
    <Menu
      className={s.root}
      PaperProps={{
        className: clsx(s.paper),
      }}
      MenuListProps={{
        className: clsx(s.list, className),
        dense: true,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      {...other}
    >
      {notificationList?.totalCount > 0 ? (
        notificationList?.edges.map(({ node }, index) => (
          <ListItem
            key={node.id}
            className={s.item}
            dense
            style={{ outline: "none" }}
          >
            <NotificationBox
              notificationRef={node}
              onClickDelete={handleDeleteClick}
              onClickBox={onClose}
              index={index}
            />
          </ListItem>
        ))
      ) : (
        <ListItem className={s.item} dense style={{ outline: "none" }}>
          <ListItemText primary="You have no notifications." />
        </ListItem>
      )}
      {hasNext && (
        <Button
          className={s.button}
          fullWidth
          onClick={handleLoadMoreClick}
          children="Load more"
        />
      )}
    </Menu>
  );
}
