import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  SelectProps,
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { SettingsCoverImage } from "../group-settings/SettingsCoverImage";
import {
  AssetType,
  InviteCodeStartDateType,
  ProgramStatus,
} from "../../constants";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";
import { maybePluralize } from "../../utils/text";
import { InviteLinkSettings } from "../coach-clients/InviteLinkSettings";
import { DateDialog } from "../dialog/DateDialog";
import { CheckboxField } from "../checkbox/CheckboxField";
import {
  defaultCalendarViewOptions,
  ProgramDetailsViewMode,
} from "../program/ProgramDetailsViewButton";

import {
  ProgramSettings_program$key,
  ProgramDefaultView,
} from "./__generated__/ProgramSettings_program.graphql";
import { useUpdateProgramMutation } from "./mutations/UpdateProgram";
import { useUpdateInviteCodeStartDateMutation } from "./mutations/UpdateInviteCodeStartDate";
import { toEnum } from "../../utils/misc";
import { ActionButton } from "../button/ActionButton";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.background.paper,
    },
  },

  topBar: {
    padding: theme.spacing(2, 3),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.border.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  closeButton: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 700,
    marginLeft: theme.spacing(-2),

    "& svg": {
      marginRight: theme.spacing(1),
    },
  },

  root: {
    padding: theme.spacing(6, 2, 12, 2),
  },

  control: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  fieldsRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
  },

  label: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
    color: `${theme.palette.text.secondary} !important`,
  },

  inviteSettings: {
    height: theme.spacing(10),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),

    "& > div:nth-child(1)": {
      flexShrink: 0,
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      alignItems: "flex-start",
    },
  },

  inviteSettingsLabel: {
    fontSize: 18,
    fontWeight: 600,
  },

  inviteSettingsHint: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,

    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
    },
  },

  invitesHeader: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: theme.spacing(4),
  },

  inviteLinkSettings: {
    justifyContent: "flex-end",
  },

  generateInviteLink: {
    padding: theme.spacing(1, 6.25),
  },

  inviteCopyLink: {
    fontWeight: 500,
  },

  divider: {
    color: theme.palette.quote,
  },

  checkboxLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  select: {
    "& > div": {
      padding: 0,
    },
  },

  option: {
    padding: theme.spacing(0, 2),
  },

  optionIcon: {
    color: theme.palette.text.primary,
    minWidth: theme.spacing(3),
    paddingRight: theme.spacing(1.5),
  },

  primaryText: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

const programFragment = graphql`
  fragment ProgramSettings_program on Program {
    id
    slug
    name
    description
    imageURL
    status
    length
    defaultView

    inviteCode {
      ...InviteLinkSettings_inviteCode
      id
      startDate
    }
  }
`;

export interface ProgramSettingsProps extends Omit<ContainerProps, "children"> {
  program: ProgramSettings_program$key;
}

export function ProgramSettings(props: ProgramSettingsProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const { className, program: programRef, ...other } = props;
  const program = useFragment(programFragment, programRef);
  const { id, slug } = program;
  const [dirty, setDirty] = React.useState(false);
  const [name, setName] = React.useState(program.name);
  const [description, setDescription] = React.useState(program.description);
  const [programLength, setProgramLength] = React.useState(program.length);
  const [imageURL, setImageURL] = React.useState(program.imageURL);
  const [status, setStatus] = React.useState(program.status);
  const [defaultView, setDefaultView] = React.useState(
    toEnum(
      program.defaultView,
      ProgramDetailsViewMode,
      ProgramDetailsViewMode.LIST,
    ),
  );

  const [inviteCodeStartDate, setInviteCodeStartDate] = React.useState(
    program.inviteCode?.startDate
      ? new Date(program.inviteCode?.startDate)
      : null,
  );
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const inviteCodeStartDateType = inviteCodeStartDate
    ? InviteCodeStartDateType.FIXED_DATE
    : InviteCodeStartDateType.IMMEDIATELY;
  const [updateProgram, inFlightUpdateProgram] = useUpdateProgramMutation();
  const [updateInviteCodeStartDate, inFlightInviteCodeStartDate] =
    useUpdateInviteCodeStartDateMutation();
  const inFlight = inFlightUpdateProgram || inFlightInviteCodeStartDate;

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setName(event.target.value);
      setDirty(true);
    },
    [],
  );

  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDescription(event.target.value);
      setDirty(true);
    },
    [],
  );

  const handleChangeCoverImage = React.useCallback((value: string) => {
    setImageURL(value || "");
    setDirty(true);
  }, []);

  const handleProgramLengthChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setProgramLength(event.target.value as number);
      setDirty(true);
    },
    [],
  );

  const handleCloseClick = React.useCallback(
    () => navigate(COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug)),
    [slug],
  );

  const handleSave = React.useCallback(() => {
    updateProgram({
      variables: {
        input: {
          id,
          name,
          description,
          image: imageURL,
          length: programLength,
          status,
          defaultView: defaultView.toUpperCase() as ProgramDefaultView,
        },
      },
      onSuccess: () => {
        setDirty(false);
      },
    });
  }, [
    defaultView,
    description,
    id,
    imageURL,
    name,
    programLength,
    status,
    updateProgram,
  ]);

  const saveInviteCodeStartDate = React.useCallback(
    (startDate: Date | null) => {
      setInviteCodeStartDate(startDate);
      updateInviteCodeStartDate({
        variables: {
          input: {
            id: program.inviteCode?.id,
            startDate: startDate ? startDate.toISOString() : null,
          },
        },
      });
    },
    [program.inviteCode, updateInviteCodeStartDate],
  );

  const handleInviteCodeStartTypeChange: SelectProps["onChange"] =
    React.useCallback(
      (event) => {
        const value = event.target.value as InviteCodeStartDateType;

        if (value === InviteCodeStartDateType.IMMEDIATELY) {
          saveInviteCodeStartDate(null);
        }
      },
      [saveInviteCodeStartDate],
    );

  const handleCloseDatePicker = React.useCallback(
    () => setOpenDatePicker(false),
    [],
  );
  const handleOpenDatePicker = React.useCallback(
    () => setOpenDatePicker(true),
    [],
  );

  const handleInviteCodeStartDateChange = React.useCallback(
    (value: Date | null) => {
      saveInviteCodeStartDate(value);
    },
    [saveInviteCodeStartDate],
  );

  const handleProgramStatusChange = React.useCallback(() => {
    setStatus((status) =>
      status === ProgramStatus.PUBLISHED
        ? ProgramStatus.DRAFT
        : ProgramStatus.PUBLISHED,
    );
    setDirty(true);
  }, []);

  const handleDefaultViewChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setDefaultView(event.target.value as ProgramDetailsViewMode);
      setDirty(true);
    },
    [],
  );

  const disabled = inFlight;

  return (
    <>
      <Box className={s.topBar}>
        <Button className={s.closeButton} onClick={handleCloseClick}>
          <CloseIcon />
          Program settings
        </Button>
        <ActionButton
          size="large"
          disabled={!dirty || inFlight}
          onClick={handleSave}
        >
          Save changes
        </ActionButton>
      </Box>
      <Container className={clsx(s.root, className)} maxWidth="md" {...other}>
        <DiscardChangesDialog dirty={dirty} />
        <Typography variant="h5" className={s.title}>
          General settings
        </Typography>

        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="name"
            component="label"
            children="Title"
          />
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            value={name}
            onChange={handleNameChange}
            disabled={disabled}
            required
          />
        </FormControl>

        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="description"
            component="label"
            children="Description"
          />
          <TextField
            variant="outlined"
            fullWidth
            multiline
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            disabled={disabled}
            required
          />
        </FormControl>

        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="status"
            component="label"
            children="Program status"
          />
          <CheckboxField
            name="comparisonMode"
            label={
              <Typography className={s.checkboxLabel}>Published</Typography>
            }
            checked={status === ProgramStatus.PUBLISHED}
            onChange={handleProgramStatusChange}
          />
        </FormControl>

        <SettingsCoverImage
          className={s.control}
          nodeId={program.id}
          assetType={AssetType.PROGRAM_IMAGE}
          image={imageURL}
          onImageChange={handleChangeCoverImage}
          disabled={disabled}
          removable={false}
        >
          Give your program its own identity with a cover photo. Similar to a
          book cover, this photo will appear at the top of your program page.
          Upload your own, or choose a beautiful royalty free image from
          Unsplash.
        </SettingsCoverImage>

        <Box className={s.fieldsRow}>
          <FormControl className={s.control}>
            <FormLabel
              className={s.label}
              htmlFor="length"
              component="label"
              children="Length"
            />
            <Select
              variant="outlined"
              id="length"
              value={programLength}
              onChange={handleProgramLengthChange}
              fullWidth
              disabled={disabled}
              className={s.select}
            >
              {Array.from({ length: 99 }, (_, i) => i + 1).map((value) => (
                <MenuItem
                  className={s.option}
                  key={value}
                  value={value}
                  disabled={program.length > value}
                >
                  <ListItem component="div">
                    <ListItemText classes={{ primary: s.primaryText }}>
                      {maybePluralize(value, "week")}
                    </ListItemText>
                  </ListItem>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={s.control}>
            <FormLabel
              className={s.label}
              htmlFor="defaultView"
              component="label"
              children="Default view"
            />
            <Select
              className={s.select}
              variant="outlined"
              id="defaultView"
              value={defaultView}
              onChange={handleDefaultViewChange}
              fullWidth
              disabled={disabled}
            >
              {Object.entries(defaultCalendarViewOptions).map(
                ([viewMode, { label, icon }]) => (
                  <MenuItem
                    key={viewMode}
                    value={viewMode}
                    className={s.option}
                  >
                    <ListItem component="div">
                      <ListItemIcon className={s.optionIcon}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText classes={{ primary: s.primaryText }}>
                        {label}
                      </ListItemText>
                    </ListItem>
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Box>

        <Typography className={s.invitesHeader}>Public invites</Typography>

        <Box className={s.inviteSettings}>
          <Box>
            <Typography className={s.inviteSettingsLabel}>
              Public page
            </Typography>
            <Typography className={s.inviteSettingsHint}>
              Allow clients to enroll via invite link.
            </Typography>
          </Box>
          <InviteLinkSettings
            className={s.inviteLinkSettings}
            programId={program.id}
            inviteCode={program.inviteCode}
            textLink
            disabled={disabled}
            GenerateButtonProps={{
              className: s.generateInviteLink,
            }}
            CopyButtonProps={{
              className: s.inviteCopyLink,
              variant: "text",
              color: "primary",
            }}
          />
        </Box>

        {program.inviteCode && (
          <>
            <Divider className={s.divider} />

            <Box className={s.inviteSettings}>
              <Box>
                <Typography className={s.inviteSettingsLabel}>
                  Start date
                </Typography>
                <Typography className={s.inviteSettingsHint}>
                  When do you want clients to start?
                </Typography>
              </Box>

              <Select
                value={inviteCodeStartDateType}
                onChange={handleInviteCodeStartTypeChange}
                disableUnderline
                disabled={disabled}
              >
                <MenuItem value={InviteCodeStartDateType.IMMEDIATELY}>
                  Start immediately
                </MenuItem>
                <MenuItem
                  value={InviteCodeStartDateType.FIXED_DATE}
                  onClick={handleOpenDatePicker}
                >
                  {inviteCodeStartDate
                    ? `Starts ${dayjs(inviteCodeStartDate).format(
                        "MMMM DD, YYYY",
                      )}`
                    : "Start on fixed date"}
                </MenuItem>
              </Select>
            </Box>
            <DateDialog
              title="Choose start date"
              subtitle="Select when clients should start on the program."
              open={openDatePicker}
              ConfirmButtonProps={{
                children: "Use this start date",
              }}
              DatePickerProps={{
                minDate: new Date(),
              }}
              onDateChange={handleInviteCodeStartDateChange}
              onClose={handleCloseDatePicker}
            />
          </>
        )}
      </Container>
    </>
  );
}
