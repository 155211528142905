import clsx from "clsx";
import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import { ButtonProps } from "@mui/material";

import { InviteDialog } from "../../components/dialog/InviteDialog";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { planClients, Plan } from "../../constants";

import { InviteDialog_addedUsers$key } from "../dialog/__generated__/InviteDialog_addedUsers.graphql";
import { InviteDialog_invitedUsers$key } from "../dialog/__generated__/InviteDialog_invitedUsers.graphql";
import { InviteDialog_inviteCode$key } from "../dialog/__generated__/InviteDialog_inviteCode.graphql";
import { useNavigate } from "react-router-dom";
import AddClientDialogContext from "../../contexts/AddClientDialogContext";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface NewClientActionProps extends ButtonProps {
  addedUsers: InviteDialog_addedUsers$key;
  invitedUsers: InviteDialog_invitedUsers$key;
  inviteCode: InviteDialog_inviteCode$key;
  handleNewClientAction: () => void;
}

export function NewClientAction(props: NewClientActionProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const {
    className,
    inviteCode,
    invitedUsers,
    addedUsers,
    handleNewClientAction,
    ...other
  } = props;
  const user = useCurrentUser();
  const context = useContext(AddClientDialogContext);

  const limitReached = React.useMemo(
    () => user.clientsCountNoSample >= Number(planClients(user.plan as Plan)),
    [user.clientsCountNoSample, user.plan],
  );

  const handleClose = React.useCallback(() => {
    context.setAddClientDialog(false);
  }, [context.setAddClientDialog]);

  return (
    <>
      {(invitedUsers || addedUsers) && !limitReached && (
        <InviteDialog
          open={context.addClientDialog}
          inviteCode={inviteCode}
          invitedUsers={invitedUsers}
          addedUsers={addedUsers}
          onClose={handleClose}
          onInvite={handleNewClientAction}
        />
      )}
    </>
  );
}
