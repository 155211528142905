import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useRefetchableFragment } from "react-relay";
import { Box, Container, ContainerProps } from "@mui/material";

import { TypeMeasurement, measurementTypes, Units } from "../../constants";
import { polyfillCSS } from "../../utils/css";

import { DropdownMenu, DropdownMenuItem } from "../menu/DropdownMenu";
import { useQueryParam } from "../../hooks/useQueryParam";
import {
  TrendMetricType,
  defaultClientMeasurementType,
  toTrendMetricType,
} from "../client-measurement/ClientMeasurementScreen";
import { ClientMeasurementChart } from "../client-measurement/ClientMeasurementChart";
import { ClientMeasurementRows } from "../client-measurement/ClientMeasurementRows";
import { BackButton } from "../button/BackButton";

import { ClientProfileMeasurementScreen_user$key } from "./__generated__/ClientProfileMeasurementScreen_user.graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CLIENT_PROFILE_ROUTE } from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import { toEnum } from "../../utils/misc";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    maxWidth: theme.breakpoints.values.slg,
  },

  paper: {
    minHeight: "90vh",
    paddingTop: polyfillCSS(
      `calc(var(--safe-area-inset-top) + ${theme.spacing(4)})`,
    ),
  },

  card: {
    margin: theme.spacing(4, 0),
  },

  backButton: {
    position: "absolute",

    top: polyfillCSS(
      `calc(var(--safe-area-inset-top) + ${theme.spacing(2.5)})`,
    ),

    left: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      left: theme.spacing(3),
    },

    [theme.breakpoints.up("md")]: {
      left: theme.spacing(5),
    },
  },
}));

const userFragment = graphql`
  fragment ClientProfileMeasurementScreen_user on User
  @refetchable(queryName: "ClientProfileMeasurementScreenRefetchQuery")
  @argumentDefinitions(measurementType: { type: "MeasurementType" }) {
    id
    units

    nutritionTarget {
      trackingType
    }

    metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: $measurementType
    ) {
      ...ClientMeasurementChart_metrics
      ...ClientMeasurementRows_metrics
    }
  }
`;

export interface ClientProfileMeasurementScreenProps
  extends Omit<ContainerProps, "children"> {
  userRef: ClientProfileMeasurementScreen_user$key;
}

export function ClientProfileMeasurementScreen(
  props: ClientProfileMeasurementScreenProps,
) {
  const navigate = useNavigate();
  const s = useStyles();
  const { className, userRef, ...other } = props;
  const [user, userRefetch] = useRefetchableFragment(userFragment, userRef);
  const metrics = user.metrics && user.metrics.filter(Boolean);
  const [stale, setStale] = React.useState(false);

  const [searchParams] = useSearchParams();
  const metric = searchParams.get("metric");

  const [measurementType, setMeasurementType] = useQueryParam<TrendMetricType>(
    "metric",
    defaultClientMeasurementType,
    {
      silent: true,
      normalize: toTrendMetricType,
    },
  );

  const handleDropdownClick = React.useCallback(
    ({
      currentTarget: {
        dataset: { name },
      },
    }: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      setMeasurementType(toTrendMetricType(name));
      setStale(true);
    },
    [setMeasurementType],
  );

  const items = React.useMemo(
    () =>
      measurementTypes.map(
        ({ label, name }) =>
          ({
            children: label,
            selected: name === measurementType,
            onClick: handleDropdownClick,
            data: {
              name,
            },
          }) as DropdownMenuItem,
      ),

    [handleDropdownClick, measurementType],
  );

  const selectedType = measurementTypes.find(
    ({ name }) => name === measurementType,
  );

  const refresh = React.useCallback(() => {
    const variables = {
      measurementType: toTrendMetricType(measurementType).toUpperCase(),
    };

    userRefetch(variables, { onComplete: () => setStale(false) });
  }, [measurementType, userRefetch]);

  React.useEffect(() => {
    if (stale) {
      refresh();
    }
  }, [measurementType, refresh, userRefetch, stale]);

  const units = user.units as Units;

  const handleClose = React.useCallback(
    () => navigate(CLIENT_PROFILE_ROUTE),
    [],
  );

  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        <Box className={s.paper}>
          <BackButton className={s.backButton} onClick={handleClose} />
          <DropdownMenu
            header={selectedType?.label}
            subHeader="Switch to"
            variant="big"
            items={items}
          />

          {metrics && (
            <>
              <ClientMeasurementChart
                className={s.card}
                title={selectedType.label}
                metrics={metrics}
                measurementType={selectedType}
                units={units}
              />
              <ClientMeasurementRows
                className={s.card}
                clientId={user.id}
                metrics={metrics}
                measurementType={selectedType}
                units={units}
                refresh={refresh}
              />
            </>
          )}
        </Box>
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client – Measurements",
          properties: {
            measurementType: toEnum(
              metric,
              TypeMeasurement,
              defaultClientMeasurementType,
            ),
          },
        }}
      />
    </>
  );
}
