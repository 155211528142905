import React from "react";

export type HistoryBlockUnsafe = <T>(fn: () => T) => T;
export type HistoryBlockUpdater = (
  dirty: boolean,
  cleanup?: () => void,
) => void;
export type HistoryBlockCallbacks = [
  HistoryBlockUnsafe,
  HistoryBlockUpdater,
  boolean,
];

export const HistoryBlockContext =
  React.createContext<HistoryBlockCallbacks>(null);

const pass: HistoryBlockCallbacks = [(f) => f(), () => null, false];

export const useHistoryBlock = (): HistoryBlockCallbacks =>
  React.useContext(HistoryBlockContext) || pass;
