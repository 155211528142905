import React from "react";
import { graphql } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { PaginationContext } from "../../../utils/paging";

import { DeleteGroupMutation } from "./__generated__/DeleteGroupMutation.graphql";

const mutation = graphql`
  mutation DeleteGroupMutation($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      clientMutationId
      removedGroupId
    }
  }
`;

export const useDeleteGroupMutation = () => {
  const snackAlert = useSnackAlert();
  const filters = React.useContext(PaginationContext);

  return useConfiguredMutation<DeleteGroupMutation>(mutation, (config) => ({
    updater(store) {
      const connection = ConnectionHandler.getConnection(
        store.getRoot(),
        "CoachGroups_groups",
        filters,
      );

      if (connection) {
        ConnectionHandler.deleteNode(connection, config.variables.input.id);
        const totalCount = connection.getValue("totalCount") as number;
        connection.setValue(Math.max(totalCount - 1, 0), "totalCount");
      }
    },

    onSuccess: () =>
      snackAlert({
        severity: "success",
        message: "Group deleted.",
      }),
  }));
};
