import clsx from "clsx";
import React from "react";
import {
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MealLoggingQuestionMultipleChoice } from "../meal-logging-settings/types";
import { sortMealLoggingOptions } from "../meal-logging-settings/utils";

import { ClientMealLoggingQuestionProps } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {},

  label: {
    width: "100%",
  },

  otherField: {
    "& > div": {
      backgroundColor: "transparent !important",
    },
  },
}));

export interface ClientMealLoggingMultipleChoiceQuestionProps
  extends RadioGroupProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionMultipleChoice> {}

export function ClientMealLoggingMultipleChoiceQuestion(
  props: ClientMealLoggingMultipleChoiceQuestionProps,
) {
  const s = useStyles();
  const { className, option, onUpdate, ...other } = props;
  const options = sortMealLoggingOptions(option.options);
  const [answer, setAnswer] = React.useState(option.answer || "");
  const [otherText, setOtherText] = React.useState(
    options.some(({ text }) => text === answer) ? "" : answer,
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, answer: string) => {
      setAnswer(answer);

      if (onUpdate) {
        onUpdate({ ...option, answer });
      }
    },
    [onUpdate, option],
  );

  const handleChangeOtherText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const answer = event.currentTarget.value;

      setOtherText(answer);
      setAnswer(answer);

      if (onUpdate) {
        onUpdate({ ...option, answer });
      }
    },
    [onUpdate, option],
  );

  return (
    <RadioGroup
      className={clsx(s.root, className)}
      value={answer}
      onChange={handleChange}
      {...other}
    >
      {options.map(({ id, text, prompt }) => (
        <FormControlLabel
          key={id}
          value={prompt ? otherText || text : text}
          control={<Radio color="primary" />}
          classes={{
            label: s.label,
          }}
          label={
            prompt ? (
              <TextField
                className={s.otherField}
                placeholder={text}
                value={otherText}
                onChange={handleChangeOtherText}
                fullWidth
              />
            ) : (
              text
            )
          }
        />
      ))}
    </RadioGroup>
  );
}
