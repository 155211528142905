import { loadStripe } from "@stripe/stripe-js";

const PROD_ORIGIN = "https://app.stridist.com";
const STRIPE_TEST_PUBLISHABLE_KEY =
  "pk_test_51LsOvfKMXGjdxyrwoHwTa7Xsdb01Bj1DLaUJs84wBeGboFhy6fePnZYHQ96SdojRMD7rPWqs4vYuSkhwnxwHTc2E0010HJDVh4";
const STRIPE_LIVE_PUBLISHABLE_KEY =
  "pk_live_51LsOvfKMXGjdxyrwH1Rt0XRHMFCKyTBS37ri8yjO6SSvmYX2AeANK77oaUMXSPFyTOr7A5hbiBubPrYY6TSAuEoE009l1R2dlK";

export const STRIPE_MODE =
  window.location.origin === PROD_ORIGIN ? "live" : "test";

const publishableKey =
  STRIPE_MODE === "live"
    ? STRIPE_LIVE_PUBLISHABLE_KEY
    : STRIPE_TEST_PUBLISHABLE_KEY;

export const initStripe = async () => {
  return await loadStripe(publishableKey);
};
