import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { LinkPreviewVariant, UrlMetadata } from "../../../types/links";

type RichPreviewLinkProps = UrlMetadata & {
  onClick: () => void;
};

export const RichPreviewLink = ({
  title,
  description,
  image,
  variant,
  url,
  onClick,
}: RichPreviewLinkProps) => {
  const theme = useTheme();
  const showImage = image && variant === LinkPreviewVariant.RICH;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: showImage
          ? { xs: "70% 30%", md: "78% 22%" }
          : "100%",
        border: "1px solid",
        borderRadius: 2,
        borderColor: theme.palette.border.primary,
        overflow: "hidden",
        width: "100%",
        minHeight: "140px",
        alignItems: "center",
      }}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          padding: theme.spacing(3, 4),
          paddingRight: showImage && { md: 8 },
          gap: 1,
        }}
      >
        {title && <Typography variant="h6">{title}</Typography>}
        {description && (
          <Typography
            color={theme.palette.text.disabled}
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <InsertLinkIcon
            fontSize="small"
            sx={{ color: theme.palette.primary.main }}
          ></InsertLinkIcon>

          <Typography
            width={"100%"}
            sx={{ pr: 1 }}
            color={theme.palette.primary.main}
          >
            {url}
          </Typography>
        </Box>
      </Box>
      {image && variant === LinkPreviewVariant.RICH && (
        <Box
          sx={{
            height: "100%",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}
    </Box>
  );
};
