import clsx from "clsx";
import React from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const EditorFieldEditor = React.lazy(
  () => import("../new-editor/EditFieldEditor"),
);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      overflowY: "scroll",
      padding: theme.spacing(1.75),
    },
  },
}));

export function EditorField(props: any) {
  const {
    className,
    topToolbar,
    delay,
    schema,
    multiline,
    editor,
    compact,
    placeholder = "",
    ...other
  } = props;
  const s = useStyles();

  return (
    <TextField
      className={clsx(s.root, className)}
      InputLabelProps={{
        // undefined case is needed
        shrink: props.value ? true : undefined,
      }}
      InputProps={{
        inputComponent: EditorFieldEditor,
        inputProps: {
          topToolbar,
          delay,
          schema,
          multiline,
          editor,
          compact,
          placeholder,
        },
      }}
      multiline={multiline}
      {...other}
    />
  );
}
