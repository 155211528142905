import React from "react";

import { CoachProgramCurriculumRouteQuery$data } from "../routes/coach/program/curriculum/__generated__/CoachProgramCurriculumRouteQuery.graphql";

export const ProgramEnrollmentsListContext =
  React.createContext<CoachProgramCurriculumRouteQuery$data["enrollments"]>(
    null,
  );

export function useProgramEnrollmentsList() {
  return React.useContext(ProgramEnrollmentsListContext);
}
