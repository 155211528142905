import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as TemplateIcon } from "../../icons/BookmarkOutline.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(-1.5),

    "& svg": {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
  },
}));

export interface ComponentTemplateButtonProps extends ButtonProps {}

export function ComponentTemplateButton(props: ComponentTemplateButtonProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Button className={clsx(s.root, className)} {...other}>
      <TemplateIcon />
      Use a template
    </Button>
  );
}
