import React, { ReactNode } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";

export const InfoSection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: ReactNode | string;
  children?: ReactNode;
}) => {
  const theme = useTheme();

  return (
    <Stack spacing={1.2}>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            textTransform: "uppercase",
            color: theme.palette.text.secondary,
            lineHeight: 1.5,
          }}
        >
          {title}
        </Typography>
        <Typography component="span" variant="body2">
          {description}
        </Typography>
      </Box>

      <Stack spacing={1.5}>{children}</Stack>
    </Stack>
  );
};
