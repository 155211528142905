import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Button,
  Box,
  TextField,
  FormGroup,
  TextFieldProps,
} from "@mui/material";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import makeStyles from "@mui/styles/makeStyles";

import { CardAvatar } from "../card/CardAvatar";
import { ReactComponent as ChangePasswordIcon } from "../../icons/LockClosedOutlined.svg";
import { CheckboxField, CheckboxFieldProps } from "../checkbox/CheckboxField";
import { TimezoneField } from "../fields/TimezoneField";
import { SettingsCard } from "../card/SettingsCard";
import { FieldsGroup } from "../fields/FieldsGroup";
import { UploadAvatar, UploadAvatarProps } from "../fields/UploadAvatar";
import { FieldLabel } from "../fields/FieldLabel";
import { validateEmail, validatePhone } from "../../utils/user";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { PhoneField, PhoneFieldProps } from "../fields/PhoneField";
import { PHONE_NUMBER_VERIFICATION_CODE_SENT } from "../../constants";
import { VerifyPhoneDialog } from "../dialog/VerifyPhoneDialog";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";

import { CoachSettingsAccount_user$key } from "./__generated__/CoachSettingsAccount_user.graphql";
import { CoachSettingsAccountUpdateMutation } from "./__generated__/CoachSettingsAccountUpdateMutation.graphql";
import { CoachSettingsAccountNotificationUpdateMutation } from "./__generated__/CoachSettingsAccountNotificationUpdateMutation.graphql";
import { ClientSettingsProfileVerifyPhoneMutation } from "./__generated__/ClientSettingsProfileVerifyPhoneMutation.graphql";
import { verifyPhoneMutation } from "./ClientSettingsProfile";
import { RadioGroupField } from "../fields/RadioGroupField";
import { Units, UnitsLabels } from "../../constants";
import { ChangePasswordDialog } from "./dialogs/ChangePasswordDialog";
import { ChangeEmailDialog } from "./dialogs/ChangeEmailDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  headerText: {
    fontSize: 24,
    fontWeight: "bold",
  },

  subheaderText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  button: {
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },
    fontWeight: 600,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.75, 3),
  },

  photoUrl: {
    display: "flex",
    alignItems: "center",
  },

  avatar: {
    borderRadius: theme.spacing(1),
    width: 104,
    height: 104,
    marginRight: theme.spacing(4),
  },

  fields: {
    display: "grid",
    gridGap: theme.spacing(0, 4),
    margin: theme.spacing(5, 0),
    alignItems: "center",
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },

  unit: {
    display: "grid",
    gridGap: theme.spacing(0, 4),
    marginTop: theme.spacing(-5),
    alignItems: "center",
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },

  passwordButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(0.5, 1),

    "& svg": {
      marginRight: theme.spacing(1),
    },

    "& svg [path]": {
      color: theme.palette.primary.main,
    },

    "& svg [stroke]": {
      stroke: theme.palette.primary.main,
    },
  },

  phoneField: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },

  verifyButtonBox: {
    height: "100%",
  },

  verifyButton: {
    height: theme.spacing(7),
    minWidth: "50%",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    width: "100%",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4.25),
      width: "auto",
    },
  },

  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.up("sm")]: {
        minWidth: 500,
      },
    },
  },
}));

const fragment = graphql`
  fragment CoachSettingsAccount_user on User {
    id
    displayName
    email
    photoURL
    timeZone
    passwordSet
    passwordUpdatedAt(format: "MMM DD, YYYY")
    phone
    phoneCode
    phoneVerified
    units
    notificationsSetting {
      id
      newMessageEmail
      newResponseEmail
      activityCompletion
    }
  }
`;

const updateNotificationSettingsMutation = graphql`
  mutation CoachSettingsAccountNotificationUpdateMutation(
    $input: UpdateNotificationsSettingInput!
  ) {
    updateNotificationsSetting(input: $input) {
      notificationsSetting {
        id
        newMessageEmail
        newResponseEmail
        activityCompletion
      }
    }
  }
`;

const updateUserMutation = graphql`
  mutation CoachSettingsAccountUpdateMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...CurrentUserProvider_user
        ...CoachSettingsAccount_user
      }
    }
  }
`;

export interface CoachSettingsAccountProps
  extends Omit<ContainerProps, "children"> {
  userRef: CoachSettingsAccount_user$key;
}

export function CoachSettingsAccount(props: CoachSettingsAccountProps) {
  const { className, userRef, ...other } = props;
  const s = useStyles();
  const user = useFragment(fragment, userRef);
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [notificationsDirty, setNotificationsDirty] = React.useState(false);
  const [displayName, setDisplayName] = React.useState(user.displayName);
  const [email, setEmail] = React.useState(user.email);
  const [timeZone, setTimeZone] = React.useState(user.timeZone);
  const [phoneCode, setPhoneCode] = React.useState<string>(user.phoneCode);
  const [verifyPhoneDialogOpen, setVerifyPhoneDialogOpen] =
    React.useState(false);
  const [phone, setPhone] = React.useState(user.phone || "");
  const [units, setUnits] = React.useState(user.units);
  const phoneIsValid = !phone || phone.length <= 5 || validatePhone(phone);
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const [notificationsSetting, setNotificationsSetting] = React.useState(
    user.notificationsSetting,
  );
  const [photoURL, setPhotoURL] = React.useState(user.photoURL);

  const [updateUser, updateUserInFlight] =
    useMutation<CoachSettingsAccountUpdateMutation>(updateUserMutation);
  const [updateNotification, updateNotificationInFlight] =
    useMutation<CoachSettingsAccountNotificationUpdateMutation>(
      updateNotificationSettingsMutation,
    );
  const [verifyPhone, verifyPhoneInFlight] =
    useMutation<ClientSettingsProfileVerifyPhoneMutation>(verifyPhoneMutation);

  const inFlight = updateUserInFlight || verifyPhoneInFlight;
  const phoneVerified = user.phoneVerified && user.phone === phone;
  const verifyDisabled = inFlight || !validatePhone(phone) || phoneVerified;

  const handleError = React.useCallback(
    (message: string) => {
      snackAlert({
        severity: "error",
        message,
      });
    },
    [snackAlert],
  );

  const unitOptions = React.useMemo(
    () =>
      Object.keys(Units).map((unit) => ({
        value: unit,
        label: UnitsLabels[unit],
      })),
    [],
  );

  const handleUnitsChange = React.useCallback(({ target: { value } }) => {
    setUnits(value);
    setDirty(true);
  }, []);

  const handleChangePassword = React.useCallback(() => {
    setPasswordDialogOpen(true);
  }, []);

  const handlePasswordDialogClose = React.useCallback(() => {
    setPasswordDialogOpen(false);
  }, []);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const handleCloseVerifyPhoneDialog = React.useCallback(() => {
    setVerifyPhoneDialogOpen(false);
  }, []);

  const handleNotificationToggle: CheckboxFieldProps["onChange"] =
    React.useCallback((event, checked) => {
      const name = event.currentTarget.name;

      setNotificationsSetting((state) => ({
        ...state,
        [name]: checked,
      }));
      setNotificationsDirty(true);
    }, []);

  const handleDisplayNameChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setDisplayName(event.target.value);
      setDirty(true);
    },
    [],
  );

  const handleEmailChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setEmail(event.target.value);
      setDirty(true);
    },
    [],
  );

  const handleChangePhotoURL: UploadAvatarProps["onChange"] = React.useCallback(
    (value) => {
      setPhotoURL(value);
      setDirty(true);
    },
    [],
  );

  const handlePhoneChange: PhoneFieldProps["onChange"] = React.useCallback(
    (event, value, countryCode) => {
      setPhone(value);
      setPhoneCode(countryCode);
      setDirty(true);
    },
    [],
  );

  const handleTimeZoneChange = React.useCallback((value: string) => {
    setTimeZone(value);
    setDirty(true);
  }, []);

  const handleVerifyPhone = React.useCallback(() => {
    verifyPhone({
      variables: { input: { phone } },
      onCompleted(data, errors) {
        if (errors) {
          onError(errors[0]);
        } else {
          setVerifyPhoneDialogOpen(true);
          snackAlert({
            severity: "info",
            message: PHONE_NUMBER_VERIFICATION_CODE_SENT,
          });
        }
      },
      onError,
    });
  }, [onError, phone, snackAlert, verifyPhone]);

  const handleSave = React.useCallback(() => {
    const promises = [];

    if (dirty) {
      promises.push(
        new Promise((resolve, reject) =>
          updateUser({
            variables: {
              input: {
                id: user.id,
                displayName,
                photoURL,
                phone,
                phoneCode,
                timeZone,
                units,
              },
            },

            onCompleted(response, errors) {
              if (errors) {
                reject(errors);
              } else {
                setDirty(false);
                resolve(response);
              }
            },
          }),
        ),
      );
    }

    if (notificationsDirty) {
      const { id: _, ...input } = notificationsSetting;

      promises.push(
        new Promise((resolve, reject) =>
          updateNotification({
            variables: {
              input,
            },
            onCompleted(response, errors) {
              if (errors) {
                reject(errors);
              } else {
                setNotificationsDirty(false);
                resolve(response);
              }
            },
          }),
        ),
      );
    }

    Promise.all(promises)
      .then(() => {
        if (promises.length) {
          snackAlert({
            severity: "success",
            message: "Settings updated",
          });
        }

        if (email !== user.email) {
          setEmailDialogOpen(true);
        }
      })
      .catch(() =>
        snackAlert({ severity: "error", message: "Failed to update settings" }),
      );
  }, [
    dirty,
    displayName,
    email,
    notificationsDirty,
    notificationsSetting,
    phone,
    phoneCode,
    photoURL,
    snackAlert,
    timeZone,
    updateNotification,
    updateUser,
    user.email,
    user.id,
    units,
  ]);

  const displayNameIsValid = Boolean(displayName);
  const emailIsValid = Boolean(email) && validateEmail(email);
  const timeZoneIsValid = Boolean(timeZone);
  const disabled = updateUserInFlight || updateNotificationInFlight;
  const canSave =
    (dirty || notificationsDirty || email !== user.email) &&
    displayNameIsValid &&
    emailIsValid &&
    timeZoneIsValid;

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={dirty} />
      <SettingsCard
        header="Profile"
        ButtonProps={{
          disabled: disabled || !canSave,
          onClick: handleSave,
        }}
      >
        <FieldLabel>Profile image</FieldLabel>

        <Box className={s.photoUrl}>
          <CardAvatar className={s.avatar} src={photoURL} />

          <UploadAvatar
            onChange={handleChangePhotoURL}
            value={photoURL}
            onError={handleError}
            disabled={disabled}
            uploadOnly
          >
            <Button
              variant="contained"
              className={s.button}
              disabled={disabled}
            >
              Upload new picture
            </Button>
          </UploadAvatar>
        </Box>

        <Box className={s.fields}>
          <FieldsGroup label="Full name">
            <TextField
              variant="outlined"
              fullWidth
              value={displayName}
              onChange={handleDisplayNameChange}
              error={!displayNameIsValid}
              disabled={disabled}
            />
          </FieldsGroup>

          <FieldsGroup label="Email address">
            <TextField
              variant="outlined"
              fullWidth
              type="helperText"
              value={email}
              onChange={handleEmailChange}
              error={!emailIsValid}
              disabled={disabled}
            />
          </FieldsGroup>

          <PhoneField
            className={s.phoneField}
            value={phone}
            countryCode={phoneCode}
            onChange={handlePhoneChange}
            error={!phoneIsValid && "Incorrect phone number"}
            disabled={inFlight}
          />

          <Box className={s.verifyButtonBox}>
            <Button
              className={s.verifyButton}
              variant="contained"
              onClick={handleVerifyPhone}
              disabled={verifyDisabled}
              children={phoneVerified ? "Verified" : "Verify"}
            />
          </Box>

          {user.passwordSet && (
            <>
              <FieldsGroup label="Password">
                <TextField
                  variant="outlined"
                  type="password"
                  value="••••••••••••"
                  fullWidth
                  disabled={disabled}
                />
              </FieldsGroup>

              <Box>
                <Button
                  variant="text"
                  onClick={handleChangePassword}
                  className={s.passwordButton}
                  disabled={disabled}
                >
                  <ChangePasswordIcon />
                  Change password
                </Button>

                <Typography variant="body1" className={s.subheaderText}>
                  Last changed {user.passwordUpdatedAt}
                </Typography>
              </Box>
            </>
          )}
          <Box>
            <TimezoneField
              value={timeZone}
              onChange={handleTimeZoneChange}
              error={!timeZoneIsValid}
              disabled={disabled}
            />
          </Box>
        </Box>

        <Box className={s.unit}>
          <RadioGroupField
            label="Unit of Measurement"
            value={units}
            options={unitOptions}
            onChange={handleUnitsChange}
            disabled={disabled}
          />
        </Box>

        <Box>
          <Typography variant="h3" className={s.headerText}>
            Notifications
          </Typography>
          <Typography variant="body1" className={s.subheaderText}>
            Manage notification preferences
          </Typography>

          <FormGroup>
            <CheckboxField
              name="newMessageEmail"
              label="I get a new message from a client"
              checked={notificationsSetting.newMessageEmail}
              onChange={handleNotificationToggle}
              disabled={disabled}
            />

            <CheckboxField
              name="newResponseEmail"
              label="New responses from my clients"
              checked={notificationsSetting.newResponseEmail}
              onChange={handleNotificationToggle}
              disabled={disabled}
            />

            <CheckboxField
              name="activityCompletion"
              label="My client completes an activity"
              checked={notificationsSetting.activityCompletion}
              onChange={handleNotificationToggle}
              disabled={disabled}
            />
          </FormGroup>
        </Box>
      </SettingsCard>

      {emailDialogOpen && (
        <ChangeEmailDialog
          className={s.dialog}
          id={user.id}
          email={email}
          open={true}
          onClose={handleCloseEmailDialog}
          fullScreen={false}
        />
      )}

      {passwordDialogOpen && (
        <ChangePasswordDialog
          id={user.id}
          open={true}
          onClose={handlePasswordDialogClose}
          fullScreen={false}
        />
      )}

      {verifyPhoneDialogOpen && (
        <VerifyPhoneDialog
          open={true}
          onClose={handleCloseVerifyPhoneDialog}
          phone={phone}
        />
      )}
    </Container>
  );
}
