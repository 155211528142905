import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";

import { NutritionTrackingType, NutrientType } from "../../utils/nutrition";

const useStyles = makeStyles((theme) => ({
  root: {},

  type: {
    marginBottom: theme.spacing(3),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },

  trackers: {
    display: "flex",
    flexDirection: "column",
  },

  tracker: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },

  trackerLabel: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "17px",
    color: theme.palette.common.black,
  },
}));

export interface MealLoggingQuestionNutritionOptionsProps extends BoxProps {
  trackingType?: NutritionTrackingType;
  nutrientTypes?: NutrientType[];
  onUpdate: (
    trackingType: NutritionTrackingType,
    nutrientTypes: NutrientType[],
  ) => void;
}

export function MealLoggingQuestionNutritionOptions(
  props: MealLoggingQuestionNutritionOptionsProps,
) {
  const {
    className,
    // Currently, only PORTIONS type is supported
    trackingType = NutritionTrackingType.PORTIONS,
    nutrientTypes = Object.values(NutrientType),
    onUpdate,
    ...other
  } = props;
  const s = useStyles();

  const listedNutrientTypes = React.useMemo(
    () =>
      trackingType
        ? trackingType === NutritionTrackingType.MACROS
          ? Object.values(NutrientType).filter(
              (nutrientType) => nutrientType !== NutrientType.VEGETABLE,
            )
          : Object.values(NutrientType)
        : [],
    [trackingType],
  );

  const handleTrackingTypeChange: SelectProps["onChange"] = React.useCallback(
    ({ target: { value } }) => {
      onUpdate(value as NutritionTrackingType, nutrientTypes);
    },
    [onUpdate, nutrientTypes],
  );

  const handleNutrientTypeToggle: SwitchProps["onChange"] = React.useCallback(
    (event, checked) => {
      const nutrientType = event.target.dataset.nutrientType as NutrientType;
      const newNutrientTypes = checked
        ? [...nutrientTypes, nutrientType]
        : nutrientTypes.filter((value) => value !== nutrientType);

      if (newNutrientTypes.length > 0) {
        onUpdate(trackingType, newNutrientTypes);
      }
    },
    [nutrientTypes, onUpdate, trackingType],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {/* Currently, only PORTIONS type is supported */}
      {false && (
        <Box className={s.type}>
          <Typography
            variant="h5"
            className={s.label}
            children="Tracking type"
          />
          <Select
            native
            variant="outlined"
            fullWidth
            value={trackingType}
            onChange={handleTrackingTypeChange}
          >
            {Object.values(NutritionTrackingType).map((value) => (
              <option key={value} value={value} children={capitalize(value)} />
            ))}
          </Select>
        </Box>
      )}

      <Box className={s.trackers}>
        <Typography variant="h5" className={s.label} children="Trackers" />
        {listedNutrientTypes.map((nutrientType) => (
          <Box className={s.tracker} key={nutrientType}>
            <Typography
              className={s.trackerLabel}
              children={`${capitalize(nutrientType)} ${
                trackingType === NutritionTrackingType.MACROS
                  ? "grams"
                  : "portions"
              }`}
            />
            <Switch
              checked={nutrientTypes.includes(nutrientType)}
              onChange={handleNutrientTypeToggle}
              inputProps={
                {
                  "data-nutrient-type": nutrientType,
                } as any
              }
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
