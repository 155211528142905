import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { AssetType } from "../constants";
import { uploadFile } from "../utils/file";
import { ErrorWithCode } from "../utils/errors";

import { useUploadFilesCreateUploadUrlMutation } from "./__generated__/useUploadFilesCreateUploadUrlMutation.graphql";

const createUploadUrlMutation = graphql`
  mutation useUploadFilesCreateUploadUrlMutation(
    $id: ID!
    $file: String!
    $type: AssetType!
  ) {
    createSignedUrl(id: $id, file: $file, type: $type) {
      url
    }
  }
`;

const getUploadedUrl = (signedUrl: string): string => {
  const url = new URL(`https:/${signedUrl.substr(signedUrl.indexOf("/", 8))}`);
  url.search = "";

  return String(url);
};

export interface UploadFileParams {
  id: string;
  getAssetType: (file: File) => AssetType | false;
  maxFileSize?: number;
}

export interface UploadedFile {
  file: File;
  url: string;
  assetType: AssetType;
}

type UploadFileFn = (file: File) => Promise<UploadedFile>;
type UploadManyFilesFn = (files: File[]) => Promise<UploadedFile[]>;

export function useUploadFile({
  id,
  getAssetType,
  maxFileSize,
}: UploadFileParams) {
  const [createUploadUrl, inFlight] =
    useMutation<useUploadFilesCreateUploadUrlMutation>(createUploadUrlMutation);
  const [requestsCounter, setRequestsCounter] = React.useState(0);

  const upload: UploadFileFn = React.useCallback(
    (data) => {
      const file = Array.isArray(data) ? data[0] : data;
      const assetType = getAssetType(file);

      if (!assetType) {
        return Promise.reject(new Error("Unsupported file type"));
      }

      if (maxFileSize && file.size > maxFileSize) {
        return Promise.reject(
          new ErrorWithCode(
            "upload/max-size",
            `Maximum file size is ${Math.round(maxFileSize / 1024 / 1024)} MB.`,
          ),
        );
      }

      return new Promise((resolve, reject) =>
        createUploadUrl({
          variables: {
            id,
            type: assetType,
            file: file.name,
          },

          onCompleted: ({ createSignedUrl }: any, errors) => {
            if (errors) {
              reject(errors);
            } else {
              const { url } = createSignedUrl;
              setRequestsCounter((value) => value + 1);

              uploadFile(url, file)
                .then((file) => {
                  resolve({
                    file: file as File,
                    url: getUploadedUrl(url),
                    assetType,
                  });
                })
                .catch(reject)
                .finally(() => setRequestsCounter((value) => value - 1));
            }
          },

          onError: reject,
        }),
      );
    },
    [getAssetType, maxFileSize, createUploadUrl, id],
  );

  const uploadMany: UploadManyFilesFn = React.useCallback(
    async (files) => {
      const errors = [];

      const uploaded = (
        await Promise.all(
          files.map((file) =>
            upload(file).catch((error) => {
              errors.push(error);
            }),
          ),
        )
      ).filter(Boolean);

      if (errors.length && !uploaded.length) {
        throw errors[0];
      }

      return uploaded as UploadedFile[];
    },
    [upload],
  );

  return [upload, uploadMany, inFlight || requestsCounter > 0] as const;
}
