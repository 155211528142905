import { HttpError } from "../types";

export function reportError(error: Error | HttpError) {
  const Sentry: any = window["Sentry"];

  if (Sentry) {
    console.info("Reporting error to Sentry", error);
    Sentry.captureException(error);
  } else {
    console.error("Captured error, but no Sentry instance found", error);
  }
}

export class ErrorWithCode extends Error {
  code: string;

  constructor(code: string, ...args: any) {
    super(...args);
    this.code = code;
  }
}
