import React, { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { SkeletonCard } from "../../../components/card/SkeletonCard";
import { Breakpoint, Container } from "@mui/material";
import { COACH_CLIENTS_ROUTE } from "../../../routes/routes";
import CommonSuspenseWrapper from "../client/CommonSuspenseWrapper";

const CoachClientWrapper = () => {
  const location = useLocation();

  const maxWidth: Breakpoint = React.useMemo(() => {
    if (
      location.pathname.includes(COACH_CLIENTS_ROUTE) &&
      (location.pathname.includes("overview") ||
        location.pathname.includes("programs") ||
        location.pathname.includes("notes") ||
        location.pathname.includes("calendar") ||
        location.pathname.includes("response"))
    ) {
      return "lg";
    }
    return undefined;
  }, [location.pathname]);

  return CommonSuspenseWrapper({ maxWidth });
};

export default CoachClientWrapper;
