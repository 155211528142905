import clsx from "clsx";
import React, { Suspense, useState, useTransition } from "react";
import {
  Card,
  CardProps,
  Box,
  Typography,
  Button,
  Portal,
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded } from "@mui/icons-material";
import { graphql, useFragment } from "react-relay";
import {
  bindPopper,
  bindToggle,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import omit from "lodash.omit";

import { useClient } from "../../hooks/useClient";
import { ReactComponent as NutritionTargetsIcon } from "../../icons/NutritionTargets.svg";
import { ReactComponent as EditIcon } from "../../icons/PenEdit.svg";
import ClientAutoNutritionDetails from "../client-auto-nutrition/ClientAutoNutritionDetails";
import ClientAutoNutritionItem from "../client-auto-nutrition/ClientAutoNutritionItem";
import EmptyText from "../client-auto-nutrition/EmptyText";
import LastSync from "../client-auto-nutrition/LastSync";

import { ClientAutoNutritionDrawer } from "./ClientAutoNutritionDrawer";
import { ClientNutritionTargetEmpty } from "./ClientNutritionTargetEmpty";
import { ClientNutritionTargetDrawer } from "./ClientNutritionTargetDrawer";
import { ClientNutritionTargetList } from "./ClientNutritionTargetList";
import { ClientNutritionCard_autoNutrition$key } from "./__generated__/ClientNutritionCard_autoNutrition.graphql";
import { ClientNutritionCard_autoNutritionMacroTarget$key } from "./__generated__/ClientNutritionCard_autoNutritionMacroTarget.graphql";
import { ClientNutritionCard_nutritionTarget$key } from "./__generated__/ClientNutritionCard_nutritionTarget.graphql";
import { ClientNutritionCard_nutritionPortionTarget$key } from "./__generated__/ClientNutritionCard_nutritionPortionTarget.graphql";
import { ClientNutritionCard_nutritionMacroTarget$key } from "./__generated__/ClientNutritionCard_nutritionMacroTarget.graphql";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: 4,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  actionButton: {
    fontSize: "1rem",
    fontWeight: 600,
    height: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  actionButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
  },
}));

const nutritionTargetFragment = graphql`
  fragment ClientNutritionCard_nutritionTarget on ClientNutritionTarget
  @relay(plural: true) {
    id
    trackingType
    targetType
    commentType
    carbsGrams
    proteinGrams
    fatGrams
    calories
    date(raw: true)
    updatedAt(format: "MMM DD, YYYY")
  }
`;

const nutritionMacroTargetFragment = graphql`
  fragment ClientNutritionCard_nutritionMacroTarget on ClientNutritionTarget
  @relay(plural: true) {
    id
    trackingType
    targetType
    commentType
    carbsGrams
    proteinGrams
    fatGrams
    calories
    currentCalories
    currentFat
    currentCarbs
    currentProtein
    date(raw: true)
    clientNutritionEntry {
      id
      carbsGrams
      proteinGrams
      fatGrams
      calories
      date
    }
  }
`;

const nutritionPortionTargetFragment = graphql`
  fragment ClientNutritionCard_nutritionPortionTarget on ClientNutritionTarget
  @relay(plural: true) {
    id
    trackingType
    targetType
    commentType
    carbsGrams
    proteinGrams
    fatGrams
    calories
    currentCalories
    currentFat
    currentCarbs
    currentProtein
    date(raw: true)
    clientNutritionEntry {
      id
      carbsGrams
      proteinGrams
      fatGrams
      calories
      date
    }
  }
`;

const autoNutritionFragment = graphql`
  fragment ClientNutritionCard_autoNutrition on AutoNutrition {
    calories
    carbs
    fat
    protein
  }
`;

const autoNutritionMacroTargetFragment = graphql`
  fragment ClientNutritionCard_autoNutritionMacroTarget on ClientNutritionTarget
  @relay(plural: true) {
    id
    targetType
    carbsGrams
    proteinGrams
    fatGrams
    calories
  }
`;

export interface ClientNutritionCardProps extends CardProps {
  username?: string;
  nutritionTargetRef?: ClientNutritionCard_nutritionTarget$key;
  nutritionPortionTargetRef?: ClientNutritionCard_nutritionPortionTarget$key;
  nutritionMacroTargetRef?: ClientNutritionCard_nutritionMacroTarget$key;
  autoNutritionRef: ClientNutritionCard_autoNutrition$key;
  autoNutritionLastSync?: string;
  autoNutritionMacroTargetRef: ClientNutritionCard_autoNutritionMacroTarget$key;
  refresh?: () => void;
  relay?: any;
  handleAddNutrition?: any;
  isProgram?: boolean;
  readonly?: boolean;
}

export function ClientNutritionCard(props: ClientNutritionCardProps) {
  const {
    className,
    username,
    nutritionTargetRef,
    refresh,
    nutritionMacroTargetRef,
    nutritionPortionTargetRef,
    autoNutritionRef,
    autoNutritionLastSync,
    autoNutritionMacroTargetRef,
    relay,
    handleAddNutrition,
    isProgram,
    readonly,
    ...other
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-nutrition",
  });

  const nutritionTarget = useFragment(
    nutritionTargetFragment,
    nutritionTargetRef,
  );
  const nutritionPortionTarget = useFragment(
    nutritionPortionTargetFragment,
    nutritionPortionTargetRef,
  );
  const nutritionMacroTarget = useFragment(
    nutritionMacroTargetFragment,
    nutritionMacroTargetRef,
  );
  const autoNutrition = useFragment(autoNutritionFragment, autoNutritionRef);
  const autoNutritionMacroTarget = useFragment(
    autoNutritionMacroTargetFragment,
    autoNutritionMacroTargetRef,
  );

  // NOTE: Used to determine if the card is viewed by a coach in the client profile.
  // IMPORTANT: Works only on `Overview` route.
  const viewedClient = useClient();
  const isCoachView = Boolean(viewedClient);

  const refreshCardTarget = React.useCallback(() => {
    relay?.refetch({ username });
  }, [relay, username]);

  const autoNutritionDetailsDialogState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-details",
  });

  const autoEditDrawerState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-edit-drawer",
  });

  const showSyncTime =
    dayjs(autoNutritionLastSync || 0).diff(
      dayjs(nutritionTarget[0]?.updatedAt || 0),
    ) >= 0;

  const [isPending, startTransition] = useTransition();

  const handleMoreInfoClick = () => {
    startTransition(() => {
      autoNutritionDetailsDialogState.open();
    });
  };

  return (
    <>
      <Card
        sx={{ ...(isProgram && { boxShadow: 0 }) }}
        className={clsx(s.root, className)}
        {...other}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" wrap="nowrap">
              <NutritionTargetsIcon />
              <Grid item>
                <Box ml={2}>
                  <Grid container direction="column">
                    <Typography variant="h6" className={s.title}>
                      Nutrition
                    </Typography>

                    <Grid container alignItems="center">
                      <Grid item>
                        {showSyncTime ? (
                          <LastSync value={autoNutritionLastSync} />
                        ) : (
                          !!nutritionTarget.length && (
                            <Typography variant="body1" className={s.subtitle}>
                              Last recorded {nutritionTarget[0].updatedAt}
                            </Typography>
                          )
                        )}
                      </Grid>
                      {!isCoachView && !readonly && (
                        <IconButton
                          {...bindToggle(autoEditDrawerState)}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {!readonly && (
              <Grid container alignItems="center">
                {Boolean(nutritionTarget.length) &&
                  mdUp &&
                  Boolean(viewedClient) && (
                    <Button
                      className={s.actionButton}
                      endIcon={
                        <ArrowForwardRounded className={s.actionButtonIcon} />
                      }
                      {...bindToggle(drawerState)}
                    >
                      Create
                    </Button>
                  )}
                <Button
                  className={s.actionButton}
                  endIcon={
                    <ArrowForwardRounded className={s.actionButtonIcon} />
                  }
                  onClick={handleMoreInfoClick}
                  disabled={isPending}
                >
                  More Info
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Box my={4}>
          {autoNutrition ? (
            <Grid container spacing={2}>
              <Grid item xs={6} sm>
                <ClientAutoNutritionItem
                  value={autoNutrition.calories}
                  label="Calories"
                />
              </Grid>
              <Grid item xs={6} sm>
                <ClientAutoNutritionItem
                  value={autoNutrition.carbs}
                  label="Carbs"
                  unit="g"
                />
              </Grid>
              <Grid item xs={6} sm>
                <ClientAutoNutritionItem
                  value={autoNutrition.fat}
                  label="Fat"
                  unit="g"
                />
              </Grid>
              <Grid item xs={6} sm>
                <ClientAutoNutritionItem
                  value={autoNutrition.protein}
                  label="Protein"
                  unit="g"
                />
              </Grid>
            </Grid>
          ) : !isCoachView ? (
            <EmptyText />
          ) : (
            <></>
          )}
        </Box>

        {autoNutritionDetailsDialogState.isOpen && (
          <ClientAutoNutritionDetails
            open={autoNutritionDetailsDialogState.isOpen}
            onClose={autoNutritionDetailsDialogState.close}
            autoNutritionMacroTarget={autoNutritionMacroTarget}
            username={username}
          />
        )}
        {nutritionTarget.length ? (
          <>
            {Boolean(nutritionMacroTarget.length) && (
              <ClientNutritionTargetList
                title="Macro targets"
                targets={nutritionMacroTarget}
                refresh={refresh || refreshCardTarget}
                isProgram={isProgram}
                handleAddNutrition={handleAddNutrition}
                {...bindToggle(drawerState)}
              />
            )}
            {Boolean(nutritionPortionTarget.length) && (
              <ClientNutritionTargetList
                title="Portion targets"
                targets={nutritionPortionTarget}
                refresh={refresh || refreshCardTarget}
                isProgram={isProgram}
                handleAddNutrition={handleAddNutrition}
                {...bindToggle(drawerState)}
              />
            )}
          </>
        ) : (
          <ClientNutritionTargetEmpty {...bindToggle(drawerState)} />
        )}
      </Card>

      {drawerState.isOpen && (
        <Portal>
          <ClientNutritionTargetDrawer
            {...omit(bindPopper(drawerState), ["anchorEl"])}
            onClose={drawerState.close}
            nutritionTargets={nutritionTarget}
            refresh={refresh || refreshCardTarget}
          />
        </Portal>
      )}

      {autoEditDrawerState.isOpen && (
        <Suspense fallback={<div></div>}>
          <Portal>
            <ClientAutoNutritionDrawer
              {...omit(bindPopper(autoEditDrawerState), ["anchorEl"])}
              onClose={autoEditDrawerState.close}
              refresh={refresh || refreshCardTarget}
            />
          </Portal>
        </Suspense>
      )}
    </>
  );
}
