import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { DropdownMenuItem } from "../menu/DropdownMenu";
import { HabitCheckInProgressTable } from "../program/HabitCheckInProgressTable";
import { useQueryParam } from "../../hooks/useQueryParam";
import { CompletionPeriod } from "../../constants";
import { PeriodFilter } from "../filters/PeriodFilter";

import { HabitCheckInProgress_enrollment$key } from "./__generated__/HabitCheckInProgress_enrollment.graphql";
import { toEnum } from "../../utils/misc";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3.5),
  },

  menu: {
    marginBottom: theme.spacing(3),
  },
}));

const enrollmentFragment = graphql`
  fragment HabitCheckInProgress_enrollment on Enrollment
  @argumentDefinitions(period: { type: "CompletionPeriodType!" }) {
    startDate(raw: true)
    habitsCheckIns: activities(componentTypes: [HABIT, CHECKIN]) {
      ...HabitCheckInProgressTable_activities @arguments(period: $period)
    }
  }
`;

export interface HabitCheckInProgressProps extends BoxProps {
  enrollmentRef: HabitCheckInProgress_enrollment$key;
}

export function HabitCheckInProgress(props: HabitCheckInProgressProps) {
  const { className, enrollmentRef, ...other } = props;
  const s = useStyles();
  const { startDate, habitsCheckIns } = useFragment(
    enrollmentFragment,
    enrollmentRef,
  );
  const [periodQueryParam, setPeriodQueryParam] = useQueryParam(
    "period",
    CompletionPeriod.BEFORE_NOW,
    {
      normalize: (param) =>
        toEnum(param, CompletionPeriod, CompletionPeriod.BEFORE_NOW),
    },
  );

  const handlePeriodItemClick: DropdownMenuItem["onClick"] = React.useCallback(
    (event) => {
      const {
        currentTarget: {
          dataset: { period },
        },
      } = event;

      setPeriodQueryParam(period as CompletionPeriod);
    },
    [setPeriodQueryParam],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography
        className={s.title}
        variant="h3"
        children="Habit and Check-In progress"
      />
      <PeriodFilter
        className={s.menu}
        selectedPeriod={periodQueryParam}
        onPeriodChange={handlePeriodItemClick}
        startDate={startDate}
        itemLabels={{
          [CompletionPeriod.ALL_TIME]: "Since program start",
        }}
      />
      <HabitCheckInProgressTable activitiesRef={habitsCheckIns} />
    </Box>
  );
}
