import React from "react";

import { SOMETHING_WENT_WRONG } from "../constants";
import { reportError } from "../utils/errors";

import { useSnackAlert } from "./useSnackAlert";

export const getValidationErrorMessage = (
  state: any,
  fieldsLabels?: Record<string, string>,
) => {
  if (!state || !fieldsLabels) return null;

  for (const [field, message] of Object.entries(state)) {
    const label = fieldsLabels[field];

    if (label) {
      return `${label} is invalid. ${message}`;
    }
  }
};

export function useGenericErrorHandler(
  disableDefaultMessage = false,
  fieldsLabels?: Record<string, string>,
) {
  const snackAlert = useSnackAlert();

  return React.useCallback(
    (error) => {
      console.error("generic error hook", error);

      const validationErrorMessage = getValidationErrorMessage(
        error.state,
        fieldsLabels,
      );

      if (validationErrorMessage) {
        snackAlert({
          severity: "error",
          message: validationErrorMessage,
        });
      } else if (
        typeof error.code === "string" &&
        (error.code as string).includes("/")
      ) {
        snackAlert({
          severity: "error",
          message: error.message,
        });
      } else if (!disableDefaultMessage) {
        snackAlert({
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        });
      }

      reportError(error);
    },
    [disableDefaultMessage, fieldsLabels, snackAlert],
  );
}
