import clsx from "clsx";
import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Element, Node } from "slate";
// import { Emoji, EmojiSkin } from "emoji-mart";

import { ElementType } from "../editor/types/elements";
import { humanReadableFileSize } from "../../utils/file";
import { giphyUrlById } from "../../utils/giphy";
import { ELEMENT_LINK } from "@udecode/plate-link";
import {
  autoReplaceInNodes,
  replaceEmail,
  replaceUrl,
} from "../new-editor/editor-configuration/plate-plugins/withAutoReplacer";

const useStyles = makeStyles((theme) => ({
  root: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  link: {
    textDecoration: "underline",
    fontFamily: "inherit",
    fontSize: "initial",
  },

  fileButton: {
    display: "flex",
    whiteSpace: "nowrap",

    maxWidth: "-webkit-fill-available",

    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.border.primary}`,

    [theme.breakpoints.up("md")]: {
      "& span:nth-child(1)": {},
    },
  },

  fileIcon: {
    color: theme.palette.secondary.main,
  },

  fileName: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    marginLeft: 21,
    marginRight: "auto",
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  fileSize: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1.5),
  },

  media: {
    "&:not(:nth-child(1))": {
      marginTop: theme.spacing(1),
    },
  },

  image: {
    maxWidth: 300,
    borderRadius: theme.spacing(0.5),
  },

  gif: {
    maxWidth: 200,
    borderRadius: theme.spacing(0.5),
  },
}));

export interface MessageContentProps {
  content: string;
  wrapperClassName?: string;
  paragraphWrapperClassName?: string;
  textClassName?: string;
}

export function MessageContent(props: any) {
  const {
    content,
    wrapperClassName,
    paragraphWrapperClassName,
    textClassName,
  } = props;
  const s = useStyles();

  const rendered = React.useMemo((): React.ReactNode => {
    try {
      const parsed = autoReplaceInNodes(JSON.parse(content), {
        rules: [replaceEmail, replaceUrl],
      }) as Element[];

      return parsed.map((node: any, index) => {
        switch (node.type) {
          case ElementType.ATTACHMENT:
          case ElementType.FILE: {
            if (!node.mimeType || !/^image\//.test(node.mimeType as string)) {
              const handleFileButtonClick = () => {
                window.open(node.url as string, "_blank");
              };

              return (
                <Button
                  key={index}
                  className={clsx(wrapperClassName, s.media, s.fileButton)}
                  onClick={handleFileButtonClick}
                >
                  <GetApp className={s.fileIcon} />
                  <Typography
                    className={s.fileName}
                    component="span"
                    children={node.name || node.url}
                  />
                  {node.size && (
                    <Typography
                      className={s.fileSize}
                      component="span"
                      children={humanReadableFileSize(node.size as number)}
                    />
                  )}
                </Button>
              );
            }
          }

          // eslint-disable-next-line no-fallthrough
          case ElementType.GIF:
          case ElementType.IMAGE:
            return (
              <img
                key={index}
                className={clsx(
                  wrapperClassName,
                  s.media,
                  node.type === ElementType.GIF ? s.gif : s.image,
                )}
                src={
                  (node.url as string) || giphyUrlById(node.giphyId as string)
                }
                alt=""
              />
            );

          case ElementType.PARAGRAPH:
          case ElementType.MESSAGE:
            return (
              <Box
                key={index}
                className={clsx(wrapperClassName, paragraphWrapperClassName)}
              >
                <Typography className={clsx(s.root, textClassName)}>
                  {node.children.map((child, childIndex) => {
                    if (child.type === ElementType.EMOJI) {
                      return <>{child.emoji}</>;
                    }

                    if (child.type === ELEMENT_LINK) {
                      return (
                        <a
                          key={childIndex}
                          className={clsx(s.root, s.link, textClassName)}
                          href={child.url as string}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Node.string(child)}
                        </a>
                      );
                    }

                    return (
                      <React.Fragment key={childIndex}>
                        {child.text}
                      </React.Fragment>
                    );
                  })}
                </Typography>
              </Box>
            );
          default:
            return (
              <Typography key={index} className={clsx(s.root, textClassName)}>
                {node.children[0].text}
              </Typography>
            );
        }
      });
    } catch (e) {
      return <>{content}</>;
    }
  }, [
    content,
    wrapperClassName,
    s.media,
    s.gif,
    s.image,
    s.root,
    s.fileButton,
    s.fileIcon,
    s.fileName,
    s.fileSize,
    s.link,
    paragraphWrapperClassName,
    textClassName,
  ]);

  return <>{rendered}</>;
}
