/**
 * @generated SignedSource<<6792dc06d95f6bd5e6bc5ce888d710b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientHomeRouteQuery$variables = {
  date: string;
};
export type ClientHomeRouteQuery$data = {
  readonly activities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientHomeScreen_activities">;
  }> | null | undefined;
  readonly enrollments: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientHomeScreen_enrollments">;
  } | null | undefined;
};
export type ClientHomeRouteQuery = {
  response: ClientHomeRouteQuery$data;
  variables: ClientHomeRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "client",
    "value": "$me"
  },
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(raw:true)"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientHomeRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activities",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientHomeScreen_activities"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EnrollmentConnection",
        "kind": "LinkedField",
        "name": "enrollments",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientHomeScreen_enrollments"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientHomeRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activities",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "formattedDate",
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "MMM DD, YYYY"
              }
            ],
            "kind": "ScalarField",
            "name": "date",
            "storageKey": "date(format:\"MMM DD, YYYY\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submitted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "days",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teaser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconName",
                    "storageKey": null
                  }
                ],
                "type": "ComponentIcon",
                "abstractKey": "__isComponentIcon"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "week",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EnrollmentConnection",
        "kind": "LinkedField",
        "name": "enrollments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrollmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Enrollment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DateActivityCount",
                    "kind": "LinkedField",
                    "name": "activityCountByDate",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4eae36f128e8b0e6b15fd105b781c90",
    "id": null,
    "metadata": {},
    "name": "ClientHomeRouteQuery",
    "operationKind": "query",
    "text": "query ClientHomeRouteQuery(\n  $date: String!\n) {\n  activities(client: \"$me\", date: $date) {\n    ...ClientHomeScreen_activities\n    id\n  }\n  enrollments {\n    ...ClientHomeScreen_enrollments\n  }\n}\n\nfragment ActivityByTypesList_activities on Activity {\n  ...ActivityList_activities_394vM\n  component {\n    type\n    id\n  }\n}\n\nfragment ActivityCard_activity_394vM on Activity {\n  id\n  date(raw: true)\n  formattedDate: date(format: \"MMM DD, YYYY\")\n  completed\n  submitted\n  component {\n    ...ComponentIcon_component\n    type\n    title\n    slug\n    days\n    locked\n    image\n    teaser\n    program {\n      slug\n      id\n    }\n    id\n  }\n}\n\nfragment ActivityList_activities_394vM on Activity {\n  ...ActivityCard_activity_394vM\n  id\n  week\n  date(raw: true)\n  completed\n  component {\n    id\n    type\n  }\n}\n\nfragment Calendar_enrollments on EnrollmentConnection {\n  edges {\n    node {\n      activityCountByDate {\n        date(raw: true)\n        count\n      }\n      id\n    }\n  }\n}\n\nfragment ClientHomeScreen_activities on Activity {\n  ...ActivityByTypesList_activities\n}\n\nfragment ClientHomeScreen_enrollments on EnrollmentConnection {\n  totalCount\n  ...Calendar_enrollments\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n"
  }
};
})();

(node as any).hash = "d2ea6752f7c9d7da89da87a0c8b5a1b3";

export default node;
