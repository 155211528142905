import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  Typography,
  lighten,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { ArrowDropDown } from "@mui/icons-material";

import { ReactComponent as CalendarDots } from "../../icons/CalendarDots.svg";
import {
  ProgramEnrollmentStartDateViewDialog,
  ProgramEnrollmentStartDateViewDialogProps,
} from "../dialog/ProgramEnrollmentStartDateViewDialog";
import { useProgramEnrollmentsList } from "../../hooks/useProgramEnrollmentsList";
import {
  ProgramSpecificStartDateViewDialog,
  ProgramSpecificStartDateViewDialogProps,
} from "../dialog/ProgramSpecificStartDateViewDialog";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: lighten(theme.palette.text.secondary, 0.8),
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.75, 2),
    borderRadius: theme.spacing(0.5),
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
  },
}));

export enum ProgramStartDateView {
  ANY = "any",
  CLIENT = "client",
  SPECIFIC = "specific",
}

export interface ProgramStartDateViewDetails {
  view: ProgramStartDateView;
  date?: string;
  clientName?: string;
}

const programStartDateViewLabel: Record<ProgramStartDateView, string> = {
  [ProgramStartDateView.ANY]: "View by any start date",
  [ProgramStartDateView.CLIENT]: "View by client start date",
  [ProgramStartDateView.SPECIFIC]: "View by specific date",
};

export interface ProgramStartDateViewButtonProps
  extends Omit<ButtonProps, "value" | "onChange"> {
  value?: ProgramStartDateViewDetails;
  onChange?: (value: ProgramStartDateViewDetails) => void;
}

export function ProgramStartDateViewButton(
  props: ProgramStartDateViewButtonProps,
) {
  const {
    className,
    value = { view: ProgramStartDateView.ANY },
    onChange = () => {},
    ...other
  } = props;
  const s = useStyles();
  const enrollments = useProgramEnrollmentsList();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "program-details-view",
  });
  const [enrollmentDialogOpen, setEnrollmentDialogOpen] = React.useState(false);
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);

  const handleItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const {
        dataset: { view },
      } = event.currentTarget;

      if (view) {
        switch (view) {
          case ProgramStartDateView.CLIENT:
            setEnrollmentDialogOpen(true);
            break;
          case ProgramStartDateView.SPECIFIC:
            setDateDialogOpen(true);
            break;
          default:
            onChange({ view: ProgramStartDateView.ANY });
        }
      }

      menuState.close();
    },
    [menuState, onChange],
  );

  const handleSelectEnrollment: ProgramEnrollmentStartDateViewDialogProps["onSelect"] =
    React.useCallback(
      (date, clientName) => {
        onChange({
          view: ProgramStartDateView.CLIENT,
          date,
          clientName,
        });
      },
      [onChange],
    );

  const handleSelectDate: ProgramSpecificStartDateViewDialogProps["onSelect"] =
    React.useCallback(
      (date) => {
        onChange({
          view: ProgramStartDateView.SPECIFIC,
          date,
        });
      },
      [onChange],
    );

  const handleEnrollmentDialogClose = React.useCallback(() => {
    setEnrollmentDialogOpen(false);
  }, []);

  const handleDateDialogClose = React.useCallback(() => {
    setDateDialogOpen(false);
  }, []);

  const header = React.useMemo(() => {
    switch (value.view) {
      case ProgramStartDateView.ANY:
        return "Starting: Anytime";
      case ProgramStartDateView.CLIENT:
        return `Showing: ${value.clientName}`;
      case ProgramStartDateView.SPECIFIC:
        return `Starting: ${dayjs(value.date).format("MMM D, YYYY")}`;
      default:
        console.error("Invalid start date view");
    }
  }, [value.clientName, value.date, value.view]);

  return (
    <>
      <Button
        className={clsx(s.root, className)}
        startIcon={<CalendarDots />}
        endIcon={<ArrowDropDown />}
        children={header}
        {...bindTrigger(menuState)}
        {...other}
      />

      <Menu {...bindMenu(menuState)}>
        {Object.entries(programStartDateViewLabel).map(([view, label]) => {
          if (
            view === ProgramStartDateView.CLIENT &&
            !enrollments.edges.length
          ) {
            return false;
          }

          return (
            <MenuItem
              key={view}
              data-view={view}
              selected={view === value.view}
              onClick={handleItemClick}
              children={<Typography className={s.text} children={label} />}
            />
          );
        })}
      </Menu>

      <ProgramEnrollmentStartDateViewDialog
        open={enrollmentDialogOpen}
        enrollments={enrollments}
        onSelect={handleSelectEnrollment}
        onClose={handleEnrollmentDialogClose}
      />

      <ProgramSpecificStartDateViewDialog
        open={dateDialogOpen}
        onSelect={handleSelectDate}
        onClose={handleDateDialogClose}
      />
    </>
  );
}
