/**
 * @generated SignedSource<<ef0f45011aa2fbdf79d98d801957b7ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientFormRouteQuery$variables = {
  slug: string;
};
export type ClientFormRouteQuery$data = {
  readonly clientForm: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ClientFormScreen_root">;
};
export type ClientFormRouteQuery = {
  response: ClientFormRouteQuery$data;
  variables: ClientFormRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  },
  {
    "kind": "Literal",
    "name": "username",
    "value": ""
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientFormRouteQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ClientFormScreen_root"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientForm",
        "kind": "LinkedField",
        "name": "clientForm",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientFormRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientForm",
        "kind": "LinkedField",
        "name": "clientForm",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slugId",
                "storageKey": null
              }
            ],
            "type": "Slug",
            "abstractKey": "__isSlug"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requestId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submittedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityFeedback",
                "kind": "LinkedField",
                "name": "feedbacks",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "read",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "fromNow"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"fromNow\")"
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachReadAnswers",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "542f455e4d568f777e5845454af0a70f",
    "id": null,
    "metadata": {},
    "name": "ClientFormRouteQuery",
    "operationKind": "query",
    "text": "query ClientFormRouteQuery(\n  $slug: String!\n) {\n  ...ClientFormScreen_root_2yeisM\n  clientForm(slug: $slug, username: \"\") {\n    id\n  }\n}\n\nfragment ActivityFeedbackButton_activity on Activity {\n  ...ActivityFeedbackList_activity\n  feedbacks {\n    id\n  }\n}\n\nfragment ActivityFeedbackList_activity on Activity {\n  feedbacks {\n    id\n    ...ActivityFeedback_activityFeedback\n  }\n  ...FeedbackForm_activity\n  id\n  coachReadAnswers\n}\n\nfragment ActivityFeedback_activityFeedback on ActivityFeedback {\n  id\n  author {\n    id\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ClientFormScreen_root_2yeisM on Root {\n  clientForm(slug: $slug, username: \"\") {\n    ...RefreshSlug\n    ...ClientForm_clientForm\n    id\n  }\n}\n\nfragment ClientForm_clientForm on ClientForm {\n  id\n  requestId\n  name\n  description\n  image\n  content\n  updatedAt\n  submittedAt\n  activity {\n    ...ActivityFeedbackButton_activity\n    id\n  }\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n"
  }
};
})();

(node as any).hash = "ee3d02bf507c6574e4f34516ae140b87";

export default node;
