import React from "react";

import { SnackbarAlertContext } from "../../hooks/useSnackAlert";

import { SnackbarAlert, AlertData } from "./SnackbarAlert";

export interface SnackbarNotificationsProps {
  children?: React.ReactNode;
}

export function SnackbarNotifications(props: SnackbarNotificationsProps) {
  const { children } = props;
  const [snackAlert, setSnackAlert] = React.useState<AlertData>(null);

  const handleAlert = React.useCallback((snackAlert: AlertData | null) => {
    setSnackAlert(snackAlert?.message ? snackAlert : null);
  }, []);

  const handleClose = React.useCallback(() => {
    setSnackAlert(null);
  }, []);

  return (
    <SnackbarAlertContext.Provider value={handleAlert}>
      {children}
      {snackAlert && (
        <SnackbarAlert open={true} onClose={handleClose} {...snackAlert}>
          {snackAlert.message}
        </SnackbarAlert>
      )}
    </SnackbarAlertContext.Provider>
  );
}
