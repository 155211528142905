/**
 * @generated SignedSource<<4e8a2c923ab4dd4de15753d5727de2cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WorkoutHistoryClientQuery$variables = {
  exerciseId?: string | null | undefined;
  exerciseTitle?: string | null | undefined;
};
export type WorkoutHistoryClientQuery$data = {
  readonly workoutExerciseResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly activity: {
          readonly completedAt: string | null | undefined;
          readonly component: {
            readonly content: string | null | undefined;
            readonly title: string;
          } | null | undefined;
        };
        readonly client: {
          readonly displayName: string | null | undefined;
          readonly email: string | null | undefined;
          readonly id: string;
          readonly photoURL: string | null | undefined;
        };
        readonly id: string;
        readonly value: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type WorkoutHistoryClientQuery = {
  response: WorkoutHistoryClientQuery$data;
  variables: WorkoutHistoryClientQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "exerciseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "exerciseTitle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "exerciseId",
    "variableName": "exerciseId"
  },
  {
    "kind": "Variable",
    "name": "exerciseTitle",
    "variableName": "exerciseTitle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "dddd MMMM DD, YYYY"
    }
  ],
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": "completedAt(format:\"dddd MMMM DD, YYYY\")"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkoutHistoryClientQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WorkoutExerciseResultConnection",
        "kind": "LinkedField",
        "name": "workoutExerciseResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkoutExerciseResultEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkoutExerciseResult",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Activity",
                    "kind": "LinkedField",
                    "name": "activity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Component",
                        "kind": "LinkedField",
                        "name": "component",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkoutHistoryClientQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WorkoutExerciseResultConnection",
        "kind": "LinkedField",
        "name": "workoutExerciseResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkoutExerciseResultEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkoutExerciseResult",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Activity",
                    "kind": "LinkedField",
                    "name": "activity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Component",
                        "kind": "LinkedField",
                        "name": "component",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46ba86ced09fbea6685076df6ef8c1cc",
    "id": null,
    "metadata": {},
    "name": "WorkoutHistoryClientQuery",
    "operationKind": "query",
    "text": "query WorkoutHistoryClientQuery(\n  $exerciseId: String\n  $exerciseTitle: String\n) {\n  workoutExerciseResults(exerciseId: $exerciseId, exerciseTitle: $exerciseTitle) {\n    edges {\n      node {\n        id\n        client {\n          id\n          displayName\n          email\n          photoURL\n        }\n        activity {\n          component {\n            title\n            content\n            id\n          }\n          completedAt(format: \"dddd MMMM DD, YYYY\")\n          id\n        }\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca6b02f0ce30ea331d39c0e27baff7c6";

export default node;
