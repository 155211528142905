import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  Box,
  Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    padding: 1,
    boxShadow: theme.shadows[4],
  },
  container: {
    margin: theme.spacing(3, 2),
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
  summary: {
    margin: theme.spacing(1.5, 0),
  },
  table: {
    minWidth: 650,
  },
  card: {
    padding: theme.spacing(2),
  },
}));

export interface TableSkeletonProps {
  rowsPerPage: number;
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  rowsPerPage,
}) => {
  const classes = useStyles();
  const emptyRows = Array.from(new Array(rowsPerPage));

  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        <Skeleton variant="rectangular" className={classes.searchInput} />

        <Typography className={classes.summary}>
          <Skeleton width={100} />
        </Typography>

        <Table className={classes.table} aria-label="skeleton table">
          <TableHead>
            <TableRow>
              {Array.from({ length: 5 }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {emptyRows.map((_, index) => (
              <TableRow key={index}>
                {Array.from({ length: 5 }).map((_, cellIndex) => (
                  <TableCell key={cellIndex} align="center">
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};
