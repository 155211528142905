import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { AuthTokenData } from "../utils/auth";

import { useAuthTokenSignInMutation } from "./__generated__/useAuthTokenSignInMutation.graphql";
import firebase from "firebase";

const mutation = graphql`
  mutation useAuthTokenSignInMutation($input: SignInWithAuthTokenInput!) {
    signInWithAuthToken(input: $input) {
      idToken
    }
  }
`;

export type AuthTokenSignInResponse = {
  fireBaseToken?: string;
  data?: AuthTokenData;
};

export const useAuthTokenSignIn = (
  callback?: (error: Error | null, response?: AuthTokenSignInResponse) => void,
) => {
  const [signInWithAuthToken, inFlight] =
    useMutation<useAuthTokenSignInMutation>(mutation);
  const [loading, setLoading] = React.useState(false);
  const disabled = inFlight || loading;

  const signIn = React.useCallback(
    (data: AuthTokenData) =>
      signInWithAuthToken({
        variables: {
          input: {
            token: data.id_token,
          },
        },
        onCompleted: (response, errors) => {
          if (errors) {
            callback(errors[0] as any as Error, { data });
          } else {
            setLoading(true);

            const customToken = response?.signInWithAuthToken?.idToken;

            firebase
              .auth()
              .signInWithCustomToken(customToken)
              .then((credential) => credential.user.getIdToken())
              .then((fireBaseToken) => {
                setLoading(false);
                callback(null, {
                  fireBaseToken,
                  data,
                });
              })
              .catch((error) => {
                setLoading(false);
                callback(error, {
                  data,
                });
              });
          }
        },
      }),
    [callback, signInWithAuthToken],
  );

  return [signIn, disabled] as const;
};
