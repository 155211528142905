/**
 * @generated SignedSource<<0e01535772a82c764f2227e6d8a8d52b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachMessageClientsDialog_clients$data = ReadonlyArray<{
  readonly displayName: string | null | undefined;
  readonly id: string;
  readonly photoURL: string | null | undefined;
  readonly username: string;
  readonly " $fragmentType": "CoachMessageClientsDialog_clients";
}>;
export type CoachMessageClientsDialog_clients$key = ReadonlyArray<{
  readonly " $data"?: CoachMessageClientsDialog_clients$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachMessageClientsDialog_clients">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CoachMessageClientsDialog_clients",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "64c4a349e0af51b154ebda886188b600";

export default node;
