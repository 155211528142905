import clsx from "clsx";
import React from "react";
import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import { ConnectionHandler, SelectorStoreUpdater } from "relay-runtime";
import { Node } from "slate";

import { polyfillCSS } from "../../utils/css";
import { MessageEditor } from "../new-editor/MessageEditor";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { NewMessageFormCreateMessageMutation } from "./__generated__/NewMessageFormCreateMessageMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: polyfillCSS("var(--safe-area-inset-bottom)"),
  },
}));

const createMessageMutation = graphql`
  mutation NewMessageFormCreateMessageMutation($input: CreateMessageInput!) {
    createMessage(input: $input) {
      clientMutationId
      message {
        group: createdAt(format: "YMd")
        ...Message_message
      }
    }
  }
`;

const getStoreUpdater =
  (threadId: string): SelectorStoreUpdater<object> =>
  (store) => {
    const thread = store.get(threadId);
    const messages =
      thread && ConnectionHandler.getConnection(thread, "Messages_messages");
    const mutationResult = store.getRootField("createMessage");
    const newMessage =
      mutationResult && mutationResult.getLinkedRecord("message");

    if (messages && newMessage) {
      const edge = ConnectionHandler.createEdge(
        store,
        messages,
        newMessage,
        "MessageEdge",
      );
      ConnectionHandler.insertEdgeBefore(messages, edge);
    }
  };

export interface NewMessageFormProps extends BoxProps {
  threadId?: string;
  recipient?: string;
}

export function NewMessageForm(props: NewMessageFormProps) {
  const s = useStyles();
  const { className, recipient, threadId, ...others } = props;
  const onError = useGenericErrorHandler();

  const [createMessage, createMessageInFlight] =
    useMutation<NewMessageFormCreateMessageMutation>(createMessageMutation);
  const disabled = !recipient || createMessageInFlight;
  const storeUpdater = React.useMemo(
    () => getStoreUpdater(threadId),
    [threadId],
  );

  const handleSendMessage = React.useCallback(
    (fragment?: Node[], callback?: () => void) => {
      createMessage({
        variables: {
          input: {
            recipient,
            content: JSON.stringify(fragment),
          },
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            callback();
          }
        },
        onError,
        updater: storeUpdater,
      });
    },

    [createMessage, onError, recipient, storeUpdater],
  );

  return (
    <MessageEditor
      className={clsx(s.root, className)}
      backupKeyType="message"
      backupKeyId={threadId}
      onMessageSubmit={handleSendMessage}
      placeholder="Type your message"
      sendButtonVariant="icon"
      softBreak={false}
      disabled={disabled}
    />
  );
}
