import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { uniq } from "lodash";

import { ReactComponent as SearchIcon } from "../../icons/Search.svg";
import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";
import { queryMatchesContent } from "../../utils/search";
import { SOMETHING_WENT_WRONG } from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";

import { ComponentTemplateLibrary_template$key } from "./__generated__/ComponentTemplateLibrary_template.graphql";
import { ComponentTemplateLibraryRemoveMutation } from "./__generated__/ComponentTemplateLibraryRemoveMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.selected.light,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  search: {
    padding: theme.spacing(3, 3, 0, 3),
    "& > div": {
      borderRadius: 0,
    },
  },

  searchInput: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },

  body: {
    padding: theme.spacing(0, 3),
    overflowY: "auto",
  },

  title: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.75,
    margin: theme.spacing(4, 0, 1),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  category: {
    margin: theme.spacing(0, -2),
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  myTemplates: {
    margin: theme.spacing(0, -1),

    "& $template": {
      paddingRight: theme.spacing(5),
    },
  },

  template: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: `${theme.palette.quote}66`,
    },
  },

  templateInCategory: {
    marginLeft: theme.spacing(2),
  },

  caret: {
    color: theme.palette.text.secondary,
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    "&:not($caretOpen)": {
      transform: "rotate(-90deg)",
    },
  },

  caretOpen: {},

  removeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    display: "none",

    "& svg": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },

    "$template:hover &": {
      display: "block",
    },
  },
}));

const fragment = graphql`
  fragment ComponentTemplateLibrary_template on ComponentTemplate
  @relay(plural: true) {
    id
    title
    category
    private
  }
`;

const removeTemplateMutation = graphql`
  mutation ComponentTemplateLibraryRemoveMutation(
    $input: RemoveComponentTemplateInput!
  ) {
    removeComponentTemplate(input: $input) {
      removedComponentTemplateId
    }
  }
`;

export interface ComponentTemplateLibraryProps
  extends Omit<BoxProps, "onSelect"> {
  templatesRef: ComponentTemplateLibrary_template$key;
  onSelect?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    templateId: string,
  ) => void;
}

export function ComponentTemplateLibrary(props: ComponentTemplateLibraryProps) {
  const { className, templatesRef, onSelect, ...other } = props;
  const s = useStyles();
  const [search, setSearch] = React.useState("");
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const _templates = useFragment(fragment, templatesRef);
  const snackAlert = useSnackAlert();

  const [removeTemplate] = useMutation<ComponentTemplateLibraryRemoveMutation>(
    removeTemplateMutation,
  );

  const { privateTemplates, sharedTemplates } = React.useMemo(() => {
    const filtered = _templates.filter(({ title }) =>
      queryMatchesContent(title, search),
    );
    const privateTemplates = filtered.filter((it) => it.private);
    const sharedTemplates = filtered.filter((it) => !it.private);

    return { privateTemplates, sharedTemplates };
  }, [_templates, search]);

  const categories = React.useMemo(() => {
    const categories = uniq(sharedTemplates.map(({ category }) => category));
    categories.sort();

    return categories;
  }, [sharedTemplates]);

  const handleSearchChange = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      setSearch(event.currentTarget.value);
    },
    [],
  );

  const handleCategoryClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {
        dataset: { category },
      } = event.currentTarget;

      setExpanded(
        expanded.includes(category)
          ? expanded.filter((it) => it !== category)
          : [...expanded, category],
      );
    },
    [expanded],
  );

  const handleTemplateClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { templateId },
      } = event.currentTarget;

      if (onSelect) {
        onSelect(event, templateId);
      }
    },
    [onSelect],
  );

  const handleRemoveTemplate = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const {
        dataset: { templateId },
      } = event.currentTarget;

      removeTemplate({
        variables: {
          input: {
            id: templateId,
          },
        },
        onCompleted: (_, errors) => {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Template removed",
            });
          }
        },

        configs: [
          {
            type: "RANGE_DELETE",
            parentID: "client:root",
            connectionKeys: [
              {
                key: "ComponentTemplateDialog_componentTemplates",
              },
            ],
            pathToConnection: ["client:root", "componentTemplates"],
            deletedIDFieldName: "removedComponentTemplateId",
          },
        ],
      });
    },
    [removeTemplate, snackAlert],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <TextField
        className={s.search}
        InputProps={{
          className: s.searchInput,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={handleSearchChange as any}
        placeholder="Search"
        variant="outlined"
        fullWidth
      />

      <Box className={clsx(s.body)}>
        {privateTemplates.length > 0 && (
          <>
            <Typography className={s.title} variant="subtitle1">
              My templates
            </Typography>

            <List component="div" className={s.myTemplates} disablePadding>
              {privateTemplates.map(({ id, title }) => (
                <ListItem
                  className={s.template}
                  button
                  key={id}
                  onClick={handleTemplateClick}
                  data-template-id={id}
                >
                  <ListItemText classes={{ primary: s.text }}>
                    {title}
                    <IconButton
                      className={s.removeButton}
                      onClick={handleRemoveTemplate}
                      data-template-id={id}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}

        <Typography className={s.title} variant="subtitle1">
          Suggested templates
        </Typography>

        <List component="nav">
          {categories.map((category: any) => {
            const open = expanded.includes(category);
            return (
              <React.Fragment key={category}>
                <ListItem
                  className={s.category}
                  button
                  onClick={handleCategoryClick as any}
                  data-category={category}
                >
                  <CaretDownIcon
                    className={clsx(s.caret, open && s.caretOpen)}
                  />
                  <ListItemText
                    classes={{ primary: s.text }}
                    primary={category}
                  />
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {sharedTemplates
                      .filter((it) => it.category === category)
                      .map(({ id, title }) => (
                        <ListItem
                          className={clsx(s.template, s.templateInCategory)}
                          button
                          key={id}
                          onClick={handleTemplateClick}
                          data-template-id={id}
                        >
                          <ListItemText
                            classes={{ primary: s.text }}
                            primary={title}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
