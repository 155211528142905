/**
 * @generated SignedSource<<382ec107e2b0fb6893d83a4647ed3a46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Activity_activity$data = {
  readonly completed: boolean;
  readonly completion: {
    readonly rate: number;
  };
  readonly component: {
    readonly habitPrompt: string | null | undefined;
    readonly locked: boolean;
    readonly slug: string;
    readonly title: string;
    readonly type: ComponentType;
    readonly " $fragmentSpreads": FragmentRefs<"ComponentIcon_component">;
  } | null | undefined;
  readonly componentId: string;
  readonly content: string;
  readonly date: string | null | undefined;
  readonly endWorkout: string | null | undefined;
  readonly formattedDate: string | null | undefined;
  readonly formattedLongDate: string | null | undefined;
  readonly id: string;
  readonly startWorkout: string | null | undefined;
  readonly submitted: boolean;
  readonly summary?: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCalendar_summary">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityFeedbackButton_activity" | "ActivityStatus_activity">;
  readonly " $fragmentType": "Activity_activity";
};
export type Activity_activity$key = {
  readonly " $data"?: Activity_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"Activity_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "utc",
  "value": true
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldFetchSummary"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Activity_activity",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityStatus_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityFeedbackButton_activity"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": "formattedDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM Do"
        },
        (v0/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(format:\"MMM Do\",utc:true)"
    },
    {
      "alias": "formattedLongDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "dddd, MMMM D"
        },
        (v0/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(format:\"dddd, MMMM D\",utc:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startWorkout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endWorkout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "componentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "habitPrompt",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ComponentIcon_component"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "shouldFetchSummary",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivitySummary",
          "kind": "LinkedField",
          "name": "summary",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ActivityCalendar_summary"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Activity",
  "abstractKey": null
};
})();

(node as any).hash = "83357fe3576a60494262a96c9d029147";

export default node;
