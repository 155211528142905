import React from "react";

import {
  NavigationFilterList,
  NavigationFilterListProps,
} from "../../components/filters/NavigationFilterList";
import { GroupSort, groupSortLabels } from "../../constants";

export interface GroupsFiltersProps {}

export const GroupsFilters = (_props: GroupsFiltersProps) => {
  const allFilters: NavigationFilterListProps["filters"] = [
    {
      name: "sort",
      defaultValue: GroupSort.NAME_ASC,
      options: [
        {
          name: GroupSort.RECENTLY_ACTIVE,
          label: groupSortLabels[GroupSort.RECENTLY_ACTIVE],
        },

        {
          name: GroupSort.NAME_ASC,
          label: groupSortLabels[GroupSort.NAME_ASC],
        },
      ],
    },
  ];

  return <NavigationFilterList maxWidth="lg" filters={allFilters} />;
};
