/**
 * @generated SignedSource<<1118c073a9f16a28f1b121e16b69ef9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientHomeScreen_activities$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ActivityByTypesList_activities">;
  readonly " $fragmentType": "ClientHomeScreen_activities";
}>;
export type ClientHomeScreen_activities$key = ReadonlyArray<{
  readonly " $data"?: ClientHomeScreen_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientHomeScreen_activities">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientHomeScreen_activities",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityByTypesList_activities"
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "c74cf90bd09e3865dc9a8f4b5dedeb6f";

export default node;
