import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { graphql } from "react-relay";

import { ClientStepsDrawerEditView } from "./ClientStepsDrawerEditView";

import { colorSystem } from "../../theme";
import { MetricType } from "../client-measurement/ClientMeasurementRow";
import { useMutation } from "react-relay/hooks";

import {
  getDefaultUnit,
  TypeMeasurement,
  Units,
  UnitType,
} from "../../constants";

import { ClientStepsDrawerMutation } from "./__generated__/ClientStepsDrawerMutation.graphql";
import { ClientStepsDrawerUpdateClientMetricMutation } from "./__generated__/ClientStepsDrawerUpdateClientMetricMutation.graphql";
import { ClientStepsDrawerRemoveClientMetricMutation } from "./__generated__/ClientStepsDrawerRemoveClientMetricMutation.graphql";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  overlay: {},
}));

const createMeasurementMutation = graphql`
  mutation ClientStepsDrawerMutation($input: CreateClientMetricInput!) {
    createClientMetric(input: $input) {
      metric {
        id
        x: activityDate(format: "YYYY-MM-DD")
        activityDate(raw: true)
        value {
          ... on CheckinAnswerMeasurementValue {
            measurement
          }
        }
      }
    }
  }
`;

const updateMeasurementMutation = graphql`
  mutation ClientStepsDrawerUpdateClientMetricMutation(
    $input: UpdateClientMetricInput!
  ) {
    updateClientMetric(input: $input) {
      metric {
        x: activityDate(format: "YYYY-MM-DD")
        activityDate(raw: true)
        value {
          ... on CheckinAnswerMeasurementValue {
            measurement
          }
        }
      }
    }
  }
`;

const removeMeasurementMutation = graphql`
  mutation ClientStepsDrawerRemoveClientMetricMutation(
    $input: RemoveClientMetricInput!
  ) {
    removeClientMetric(input: $input) {
      clientMutationId
    }
  }
`;

export interface ClientGoalsDrawerProps extends DrawerProps {
  today?: any;
  clientId: string;
  setSteps: (steps: any[]) => void;
  steps: any[];
  units?: Units;
}

function ClientGoalsDrawerComponent(props: ClientGoalsDrawerProps) {
  const { className, onClose, open, steps, today, setSteps, clientId, units } =
    props;
  const s = useStyles();
  const [stepsValue, setStepsValue] = React.useState(
    today?.value?.measurement || "",
  );
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [editingDate, setEditingDate] = React.useState(false);
  const [editingValue, setEditingValue] = React.useState(false);
  const [newHistory, setNewHistory] = React.useState(null);

  const snackAlert = useSnackAlert();

  const unit = getDefaultUnit(UnitType.STEPS, units);

  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleCancel = React.useCallback(() => {
    setEditingIndex(-1);
  }, []);

  const handleEditing = React.useCallback((value) => {
    setEditingIndex(value);
  }, []);

  const [createMeasurement, creating] = useMutation<ClientStepsDrawerMutation>(
    createMeasurementMutation,
  );

  const [updateMeasurement, updating] =
    useMutation<ClientStepsDrawerUpdateClientMetricMutation>(
      updateMeasurementMutation,
    );

  const [removeMeasurement, removing] =
    useMutation<ClientStepsDrawerRemoveClientMetricMutation>(
      removeMeasurementMutation,
    );

  const handleCreateClick = React.useCallback(() => {
    setNewHistory({
      activityDate: dayjs().subtract(1, "days").format("YYYY-MM-DD"),
      value: { measurement: parseFloat("0") },
    });
    setEditingIndex(0);
  }, [setNewHistory]);

  const valuesHistory = React.useMemo(() => {
    return [
      newHistory,
      ...steps.filter(
        (item) => item.activityDate !== dayjs().format("YYYY-MM-DD"),
      ),
    ];
  }, [steps, newHistory]);

  const handleSave = React.useCallback(
    ({ id, date, name, measurement, index, history }: MetricType) => {
      if (id) {
        updateMeasurement({
          variables: {
            input: {
              id,
              unit,
              date,
              measurement,
            },
          },
          onCompleted: (data, errors) => {
            if (errors) {
              console.error(errors);
              snackAlert({
                severity: "error",
                message: "Error occurred.",
              });
            } else {
              const updateSteps = [...steps];
              updateSteps[index] = {
                ...updateSteps[index],
                activityDate: data.updateClientMetric.metric.activityDate,
                value: data.updateClientMetric.metric.value,
              };
              setSteps([...updateSteps]);
              snackAlert({
                severity: "success",
                message: "Saved successfully",
              });
            }
          },
        });
      } else {
        createMeasurement({
          variables: {
            input: {
              clientId,
              type: TypeMeasurement.STEP_COUNT.toLocaleUpperCase(),
              name,
              unit,
              date,
              measurement,
            },
          },
          onCompleted: (data, errors) => {
            if (errors) {
              console.error(errors);
              snackAlert({
                severity: "error",
                message: "Error occurred.",
              });
            } else {
              setSteps([{ ...data.createClientMetric.metric }, ...steps]);
              snackAlert({
                severity: "success",
                message: "Created successfully",
              });
              if (!history) handleClose();
            }
          },
        });
      }
    },
    [
      clientId,
      createMeasurement,
      snackAlert,
      steps,
      handleClose,
      setSteps,
      updateMeasurement,
      unit,
    ],
  );

  const handleRemove = React.useCallback(
    ({ id, index }: MetricType) => {
      removeMeasurement({
        variables: {
          input: { id },
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            const updateSteps = [...steps];
            updateSteps.splice(index, 1);
            setSteps([...updateSteps]);
            snackAlert({
              severity: "success",
              message: "Delete successfully",
            });
          }
        },
      });
    },
    [removeMeasurement, snackAlert, steps, setSteps],
  );

  const handleStepsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.currentTarget;
      if (/^[0-9]*$/.test(value) || value === "") {
        setStepsValue(value);
      }
    },
    [],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(s.paper),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={
        <ClientStepsDrawerEditView
          disabled={creating || updating || removing}
          today={today}
          stepsValue={stepsValue}
          onClose={handleClose}
          onStepsChange={handleStepsChange}
          onSave={handleSave}
          stepsValueHistory={valuesHistory}
          editingIndex={editingIndex}
          handleCancel={handleCancel}
          handleEditing={handleEditing}
          editingDate={editingDate}
          setEditingDate={setEditingDate}
          editingValue={editingValue}
          setEditingValue={setEditingValue}
          onRemove={handleRemove}
          newHistory={newHistory}
          handleCreateClick={handleCreateClick}
          setNewHistory={setNewHistory}
        />
      }
    />
  );
}

export const ClientStepsDrawer = ClientGoalsDrawerComponent;
