import React, { useState } from "react";
import { Button, Dialog, TextField } from "@mui/material";
import { isPdfUrl, isValidUrl } from "../utils/validationUtil";
import { formatVideoUrl } from "../../../utils/component";

interface IEmbedUrlProps {
  title: string;
  url: string;
  open: boolean;
  onSubmit: (url: string) => void;
  onClose: (e: any, v: string) => void;
}

export const EmbedUrlDialog = ({
  url,
  title,
  open,
  onClose,
  onSubmit,
}: IEmbedUrlProps) => {
  const [embedUrl, setEmbedUrl] = useState<any>(url);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setEmbedUrl(event.target.value);
    },
    [],
  );

  const handleTextClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleUrlSubmit = React.useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onSubmit && onSubmit(formatVideoUrl(embedUrl));
      onClose && onClose(event, "escapeKeyDown");
    },
    [onSubmit, onClose, embedUrl],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        handleUrlSubmit(event);
      }
    },
    [handleUrlSubmit],
  );

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        ".MuiPaper-root": {
          minWidth: 480,
          padding: 3,
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
        },
      }}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          handleUrlSubmit(e);
        }
      }}
    >
      <React.Fragment>
        <TextField
          variant="outlined"
          label="URL"
          value={embedUrl}
          helperText={
            !isValidUrl(embedUrl) && !isPdfUrl(embedUrl) && embedUrl
              ? "Paste valid link"
              : "Paste any link to embed it into your component."
          }
          autoFocus
          fullWidth
          onChange={handleChange}
          onClick={handleTextClick}
          onKeyDown={handleKeyDown}
          error={!isValidUrl(embedUrl) && !isPdfUrl(embedUrl) && embedUrl}
        />
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          disabled={!isValidUrl(embedUrl)}
          onClick={handleUrlSubmit}
          fullWidth
        >
          {title}
        </Button>
      </React.Fragment>
    </Dialog>
  );
};
