/**
 * @generated SignedSource<<cf5d503b3092f5205316715500948c72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MealLoggingSettings_workspace$data = {
  readonly mealLogging: boolean;
  readonly mealLoggingQuestionsTotal: number;
  readonly " $fragmentSpreads": FragmentRefs<"MealLoggingSettingsDrawer_workspace">;
  readonly " $fragmentType": "MealLoggingSettings_workspace";
};
export type MealLoggingSettings_workspace$key = {
  readonly " $data"?: MealLoggingSettings_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"MealLoggingSettings_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MealLoggingSettings_workspace",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MealLoggingSettingsDrawer_workspace"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealLogging",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealLoggingQuestionsTotal",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "ec60ba3ae6af5dcdad033e2c8fdcfe43";

export default node;
