import React from "react";
import {
  Container,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useRefetchableFragment } from "react-relay";

import { polyfillCSS } from "../../utils/css";
import { ConnectionPagination } from "../pagination/ConnectionPagination";
import { PageSkeleton } from "../loading/PageSkeleton";
import { useEffectLater } from "../../hooks/useEffectLater";
import { clientsNavigation } from "../coach-clients/constants";
import { EmptyResults } from "../coach-programs/EmptyResults";

import { CoachClientsFormsListScreen_root$key } from "./__generated__/CoachClientsFormsListScreen_root.graphql";
import { CoachClientsFormsListItem } from "./CoachClientsFormsListItem";
import { CreateClientFormAction } from "./CreateClientFormAction";
import TrackInfoTool from "../tools/TrackInfoTool";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    marginRight: theme.spacing(10),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
  },

  table: {
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[7],
    marginTop: theme.spacing(3),

    "& th": {
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: "bold",

      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1.25),
    },

    "& th:nth-child(1)": {
      paddingLeft: theme.spacing(9),
    },

    "& tr:last-child td": {
      paddingBottom: theme.spacing(3),
    },
  },
}));

const rootFragment = graphql`
  fragment CoachClientsFormsListScreen_root on Root
  @refetchable(queryName: "CoachClientsFormsListScreenRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 50 }
    after: { type: "String" }
  ) {
    clientForms(first: $first, after: $after)
      @connection(key: "CoachClientsFormsListScreen_clientForms", filters: []) {
      pageCursors {
        ...ConnectionPagination_pageCursors
      }
      totalCount
      edges {
        cursor
        node {
          ...CoachClientsFormsListItem_clientForm
          id
        }
      }
    }
  }
`;

export interface CoachClientsFormsListScreenProps {
  root: CoachClientsFormsListScreen_root$key;
}

export function CoachClientsFormsListScreen(
  props: CoachClientsFormsListScreenProps,
) {
  const s = useStyles();
  const { root: rootRef } = props;
  const [root, refetch] = useRefetchableFragment(rootFragment, rootRef);
  const pageSize = 50;
  const clientForms = root?.clientForms;
  const [loading, setLoading] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [after, setAfter] = React.useState<string>(null);
  const handleLoaded = React.useCallback(() => setLoading(false), []);

  const refetchFunction = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      setPending(false);
      refetch(
        {
          after,
          first: pageSize,
        },
        {
          onComplete: handleLoaded,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after]);

  useEffectLater(() => refetchFunction(), [refetchFunction]);

  const handlePageChange = React.useCallback((cursor: string) => {
    setAfter(cursor);
    setPending(true);
  }, []);

  return (
    <>
      <Container className={s.root} maxWidth="xl">
        {loading || pending || !clientForms ? (
          <PageSkeleton fullWidth />
        ) : clientForms.edges.length > 0 ? (
          <>
            <TableContainer className={s.table} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell># of questions</TableCell>
                    <TableCell>Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientForms.edges.map(({ node }) => (
                    <CoachClientsFormsListItem
                      key={node.id}
                      clientForm={node}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <ConnectionPagination
              pageCursors={clientForms.pageCursors}
              onChange={handlePageChange}
              autoHide
            />
          </>
        ) : (
          <EmptyResults title="No client forms found" />
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Forms",
        }}
      />
    </>
  );
}
