/**
 * @generated SignedSource<<b606af7fac466c5a257ae59343d89f00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ComponentTemplatePreview_template$data = {
  readonly content: string;
  readonly habitPrompt: string | null | undefined;
  readonly id: string;
  readonly private: boolean;
  readonly title: string;
  readonly type: ComponentType;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentIcon_component">;
  readonly " $fragmentType": "ComponentTemplatePreview_template";
};
export type ComponentTemplatePreview_template$key = {
  readonly " $data"?: ComponentTemplatePreview_template$data;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentTemplatePreview_template">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ComponentTemplatePreview_template",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentIcon_component"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "habitPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "private",
      "storageKey": null
    }
  ],
  "type": "ComponentTemplate",
  "abstractKey": null
};

(node as any).hash = "1cad8ba94cdf868a1af1eb5c28f7fd72";

export default node;
