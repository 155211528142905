import React, { FormEvent } from "react";
import { iOSMobileApp } from "../../utils/mobile";
import { useAnalytics } from "../../hooks/useAnalytics";

import { useSignInMutation } from "../auth/mutations/SignIn";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import {
  useAuthTokenSignIn,
  AuthTokenSignInResponse,
} from "../../hooks/useAuthTokenSignIn";
import { UserRole } from "../../constants";
import { isMobileApp } from "../../utils/mobile";

import { LoginForm, LoginFormProps } from "./LoginForm";
import { AuthButtonProps } from "../../utils/auth";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";

type FormError = {
  _?: string;
  email?: string;
  password?: string;
};

type LoginError = {
  code: string;
  message: string;
};

const getErrorMessage = (error: LoginError) => {
  switch (error.code) {
    case "auth/user-not-found":
      return "The email you entered does not belong to any account";

    case "auth/wrong-password":
      return "Invalid password";

    default:
      return error.message;
  }
};

type FormState = {
  email: string;
  password: string;
};

export interface LoginProps {
  className?: string;
  email?: string;
  message?: string;
  returnUrl?: string;
  component?: React.FC<LoginFormProps>;
  onEmailChange?: (email: string) => void;
  onSignIn?: AuthButtonProps["onSuccess"];
}

export function Login(props: LoginProps) {
  const {
    className,
    component: Form = LoginForm,
    returnUrl,
    onEmailChange,
  } = props;
  const [state, setState] = React.useState<FormState>({
    email: props.email || "",
    password: "",
  });
  const [errors, setErrors] = React.useState<FormError>({});
  const [loading, setLoading] = React.useState(false);
  const [noAccountEmail, setNoAccountEmail] = React.useState("");
  const [signIn] = useSignInMutation();
  const [trackEvent] = useAnalytics();
  const onError = useGenericErrorHandler(isMobileApp && iOSMobileApp);
  const navigate = useNavigate();
  const signInWithIdToken = React.useCallback(
    (error, response: AuthTokenSignInResponse) => {
      if (error) {
        if (error.code === "auth/user-not-found") {
          setNoAccountEmail(
            response.data.email ||
              `your ${(response?.data?.provider ?? "").toLowerCase()} account`,
          );
        } else {
          onError(error);
        }
      } else {
        signIn({
          variables: { idToken: response.fireBaseToken },
          updater: (store) => {
            (store as any).invalidateStore();
          },
          onCompleted(result, errors) {
            setLoading(false);
            if (errors && errors[0]) {
              onError(errors[0]);
            } else {
              trackEvent("Login");
              if (returnUrl) {
                navigate(returnUrl);
              } else {
                navigate(
                  isMobileApp || result.signIn.me.role === UserRole.CLIENT
                    ? HOME_ROUTE
                    : COACH_PROGRAMS_PUBLISHED_ROUTE,
                );
              }
            }
          },
          onError,
        });
      }
    },
    [onError, returnUrl, signIn, trackEvent],
  );

  const [handleSignIn, signInInFlight] = useAuthTokenSignIn(signInWithIdToken);

  React.useEffect(() => {
    setErrors((x) => (JSON.stringify(x) === "{}" ? x : {}));
  }, []);

  const handleError = React.useCallback((err) => {
    setLoading(false);
    setErrors({
      [err.message.toLowerCase().includes("email") ||
      err.code === "auth/user-not-found"
        ? "email"
        : err.message.toLowerCase().includes("password")
          ? "password"
          : "_"]: getErrorMessage(err),
    });
    if (err.code === "auth/wrong-password") {
      setState((x) => ({ ...x, password: "" }));
    }
  }, []);

  const handleChange = React.useCallback(
    (event) => {
      const { name, value } = event.target;
      setState((x) => (x[name] === value ? x : { ...x, [name]: value }));
      setErrors((x) => (x[name] ? { ...x, [name]: undefined } : x));

      if (name === "email" && onEmailChange) {
        onEmailChange(value);
      }
    },
    [onEmailChange],
  );

  const handleSubmit = React.useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const err: FormError = {};

      if (!state.email) {
        err.email = "Email is required.";
      }

      if (!state.password) {
        err.password = "Password is required.";
      }

      setErrors((x) => (JSON.stringify(x) === JSON.stringify(err) ? x : err));

      if (Object.keys(err).length > 0) {
        return;
      }

      setLoading(true);

      firebase
        .auth()
        .signInWithEmailAndPassword(state.email, state.password)
        .then((x) => x.user?.getIdToken())
        .then((idToken) => {
          if (!idToken) {
            return;
          }
          // Using user's ID token, authenticate in the API
          signIn({
            variables: { idToken },
            onCompleted(result, errors) {
              setLoading(false);

              const err = errors && errors[0];
              if (err) {
                if (err["code"]) {
                  onError(err);
                }
                setErrors({ _: err.message });
              } else {
                trackEvent("Login");
                if (returnUrl) {
                  navigate(returnUrl);
                } else {
                  window.location.href =
                    // isMobileApp ||
                    result.signIn?.me?.role === "CLIENT"
                      ? HOME_ROUTE
                      : COACH_PROGRAMS_PUBLISHED_ROUTE;
                }
              }
            },
          });
        })
        .catch(handleError);
    },
    [
      handleError,
      onError,
      returnUrl,
      signIn,
      state.email,
      state.password,
      trackEvent,
    ],
  );

  return (
    <Form
      className={className}
      loading={loading || signInInFlight}
      state={state}
      errors={errors}
      onSignIn={handleSignIn}
      onSubmit={handleSubmit}
      onChange={handleChange}
      noAccountEmail={noAccountEmail}
    />
  );
}
