import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { graphql, useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { ClientNutritionCard } from "../client-nutrition-targets/ClientNutritionCard";
import { useParams } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const clientFragment = graphql`
  fragment NutritionTargetScreen_client on User
  @refetchable(queryName: "NutritionTargetScreenRefetchQuery") {
    nutritionTarget {
      calories
      ...ClientNutritionCard_nutritionTarget
    }

    nutritionMacroTarget {
      ...ClientNutritionCard_nutritionMacroTarget
    }

    nutritionPortionTarget {
      ...ClientNutritionCard_nutritionPortionTarget
    }
  }
`;

const nutritionByUsernameQuery = graphql`
  query NutritionTargetScreenUsernameQuery($username: String!) {
    user(username: $username) {
      ...NutritionTargetScreen_client
    }
  }
`;

const meNutritionQuery = graphql`
  query NutritionTargetScreenMeQuery {
    me {
      ...NutritionTargetScreen_client
    }
  }
`;

export interface NutritionTargetProps extends Omit<BoxProps, "onSelect"> {
  handleAddNutrition?: any;
  readonly?: boolean;
}

export function NutritionTargetScreen(props: NutritionTargetProps) {
  const { username } = useParams();
  const currentUser: any = useCurrentUser();

  // NOTE: If there is no username in parameters, the element is used in new created component.
  // Then, display the current user's nutrition target
  const targetUserName = username || currentUser?.username;

  const query =
    targetUserName === username ? nutritionByUsernameQuery : meNutritionQuery;
  const data: any = useLazyLoadQuery(
    query,
    {
      ...(query === nutritionByUsernameQuery
        ? { username: targetUserName }
        : {}),
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  const clientRef = data?.user || data?.me;
  const { handleAddNutrition, readonly, ...other } = props;
  const [client, refetch] = useRefetchableFragment(clientFragment, clientRef);
  const s = useStyles();

  const refresh = React.useCallback(() => {
    refetch({});
  }, []);

  return (
    <Box className={clsx(s.root)} {...other}>
      <ClientNutritionCard
        nutritionTargetRef={client.nutritionTarget}
        nutritionPortionTargetRef={client.nutritionPortionTarget}
        nutritionMacroTargetRef={client.nutritionMacroTarget}
        autoNutritionRef={client.autoNutrition}
        autoNutritionLastSync={client.autoNutritionLastSync}
        autoNutritionMacroTargetRef={client.autoNutritionMacroTarget}
        refresh={refresh}
        handleAddNutrition={handleAddNutrition}
        isProgram={true}
        readonly={readonly}
      />
    </Box>
  );
}
