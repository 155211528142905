/**
 * @generated SignedSource<<2cadf8268d58ba9a56c57c6a10f9f7a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientFormScreen_root$data = {
  readonly clientForm: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClientForm_clientForm" | "RefreshSlug">;
  } | null | undefined;
  readonly " $fragmentType": "ClientFormScreen_root";
};
export type ClientFormScreen_root$key = {
  readonly " $data"?: ClientFormScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientFormScreen_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "slug"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "username"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientFormScreen_root",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        },
        {
          "kind": "Variable",
          "name": "username",
          "variableName": "username"
        }
      ],
      "concreteType": "ClientForm",
      "kind": "LinkedField",
      "name": "clientForm",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefreshSlug"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientForm_clientForm"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "d39597a6cf913a97790030842b4569b4";

export default node;
