import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface LoadMoreButtonProps extends ButtonProps {}

export function LoadMoreButton(props: LoadMoreButtonProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Button className={clsx(s.root, className)} fullWidth {...other}>
      Load more
    </Button>
  );
}
