import React from "react";
import { NewMessageAction } from "../../../../components/messages/NewMessageAction";
import { NavigationItem } from "../../../../constants";
import {
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_CLIENT_CALENDAR_ROUTE,
  COACH_CLIENT_NOTES_ROUTE,
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_CLIENT_PR0GRAMS_ROUTE,
  COACH_CLIENT_RESPONSE_ROUTE,
} from "../../../routes";

export const clientNavigation = (username: string): NavigationItem[] => [
  {
    name: "Overview",
    link: COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", username),
  },

  {
    name: "Calendar",
    link: COACH_CLIENT_CALENDAR_ROUTE.replace(":username", username),
  },

  {
    name: "Responses",
    link: COACH_CLIENT_RESPONSE_ROUTE.replace(":username", username),
  },

  {
    name: "Notes",
    link: COACH_CLIENT_NOTES_ROUTE.replace(":username", username),
  },

  {
    name: "Programs",
    link: COACH_CLIENT_PR0GRAMS_ROUTE.replace(":username", username),
  },
];

export const clientsBreadcrumb: NavigationItem = {
  name: "Clients",
  link: COACH_CLIENTS_ACTIVE_ROUTE,
};

export const SendMessage = ({
  username,
  disabled,
}: {
  username: string;
  disabled?: boolean;
}) => (
  <NewMessageAction
    children="Send message"
    username={username}
    disabled={disabled}
  />
);
