import React, { createContext, useContext } from "react";
import { ProgramSort } from "../constants";
import { CoachProgramCardDisplay } from "../components/coach-programs/CoachProgramCard";

export interface ContextValues {
  query: string;
  sortKey: ProgramSort;
  folder: string | null;
  display: CoachProgramCardDisplay;
}

export const ProgramListFilterContext = createContext<
  ContextValues | undefined
>(undefined);

export const useProgramListFilterContext = () => {
  const context = useContext(ProgramListFilterContext);
  if (context === undefined) {
    throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
};
