/**
 * @generated SignedSource<<da5ed4e7b936bd56089ddb5928182e78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateTrialExpirationDateDialog_user$data = {
  readonly id: string;
  readonly trialExpiryDate: string | null | undefined;
  readonly " $fragmentType": "UpdateTrialExpirationDateDialog_user";
};
export type UpdateTrialExpirationDateDialog_user$key = {
  readonly " $data"?: UpdateTrialExpirationDateDialog_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateTrialExpirationDateDialog_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateTrialExpirationDateDialog_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "trialExpiryDate",
      "storageKey": "trialExpiryDate(raw:true)"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0650b912e8e75fa774958986b4a27f63";

export default node;
