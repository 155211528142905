import type { TElement } from "@udecode/slate";

import { cn } from "@udecode/cn";
import {
  type LiFC,
  type MarkerFC,
  useIndentTodoListElement,
  useIndentTodoListElementState,
} from "@udecode/plate-indent-list";

import { Checkbox } from "./checkbox";
import React from "react";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useReadOnly } from "../new-editor/hooks";
import { UserRole } from "../../constants";

export const TodoMarker = ({ element }: { element: TElement }) => {
  const state = useIndentTodoListElementState({ element });
  const user = useCurrentUser();
  const readOnly = useReadOnly();
  const { checkboxProps } = useIndentTodoListElement({
    ...state,
    readOnly: readOnly && user.role !== UserRole.CLIENT,
  });

  return (
    <div contentEditable={false}>
      <Checkbox
        style={{ left: -24, position: "absolute", top: 2 }}
        {...checkboxProps}
      />
    </div>
  );
};

export const TodoLi: LiFC = (props) => {
  const { children, element } = props;

  return <span>{children}</span>;
};
