/**
 * @generated SignedSource<<1789642a6b9193ed2f7bb78cf928a4b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignTagToClientInput = {
  clientId: string;
  clientMutationId?: string | null | undefined;
  tagId: string;
};
export type ClientSettingsDialogAssignTagMutation$variables = {
  input: AssignTagToClientInput;
};
export type ClientSettingsDialogAssignTagMutation$data = {
  readonly assignTagToClient: {
    readonly user: {
      readonly tag: {
        readonly id: string;
        readonly title: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ClientSettingsDialogAssignTagMutation = {
  response: ClientSettingsDialogAssignTagMutation$data;
  variables: ClientSettingsDialogAssignTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tag",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsDialogAssignTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignTagToClientPayload",
        "kind": "LinkedField",
        "name": "assignTagToClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSettingsDialogAssignTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignTagToClientPayload",
        "kind": "LinkedField",
        "name": "assignTagToClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec9e63e222b358d596ee0014c4c96710",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsDialogAssignTagMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSettingsDialogAssignTagMutation(\n  $input: AssignTagToClientInput!\n) {\n  assignTagToClient(input: $input) {\n    user {\n      tag {\n        id\n        title\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ec2a0d11d9ad67594f5dddc7a66ce49";

export default node;
