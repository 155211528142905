import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";
import { ComponentTypeDefaultIcons } from "../program/icons";

import {
  getLastNode,
  insertEmptyElement,
  isElementEmpty,
  removeNodes,
  TElement,
  useEditorRef,
} from "@udecode/plate-common";
import { insertElementAt } from "../new-editor/utils/editorUtils";
import { WORKOUT_SECTION } from "../new-editor/components/workout/WorkoutSectionElement";
import { Node } from "slate";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";

const Icon = ComponentTypeDefaultIcons[ComponentType.WORKOUT];

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    padding: theme.spacing(2),

    "& svg": {
      width: 24,
      height: 24,
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1),
    },

    "&:hover": {
      borderWidth: 2,
      backgroundColor: `${theme.palette.primary.main}0F`,
    },
  },
}));

export interface WorkoutAddButtonProps extends ButtonProps {}

export function WorkoutAddButton(props: WorkoutAddButtonProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const editor = useEditorRef();

  const handleClick = React.useCallback(() => {
    const { children } = editor;
    const numberOfEditorElements = children.length;
    let insertWorkoutAt = [numberOfEditorElements];

    const lastElementPosition = [numberOfEditorElements - 1];
    const lastElement = Node.get(editor, lastElementPosition) as TElement;

    if (
      lastElement.type === ELEMENT_PARAGRAPH &&
      isElementEmpty(editor, lastElement)
    ) {
      removeNodes(editor, { at: lastElementPosition });
      insertWorkoutAt = [numberOfEditorElements - 1];
    }
    insertEmptyElement(editor, WORKOUT_SECTION, {
      at: insertWorkoutAt,
    });
  }, [editor]);

  return (
    <>
      <Button
        className={clsx(s.root, className)}
        variant="outlined"
        fullWidth
        onClick={handleClick}
        {...other}
      >
        <Icon />
        Add new exercise section
      </Button>
    </>
  );
}
