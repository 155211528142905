/**
 * @generated SignedSource<<829f8524aff664d5df6388ad7dd08200>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClientStatus = "ACTIVE" | "ADDED" | "ARCHIVED" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientCard_client$data = {
  readonly clientStatus: ClientStatus;
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly endPrograms: string | null | undefined;
  readonly id: string;
  readonly lastLoginAt: string | null | undefined;
  readonly photoURL: string | null | undefined;
  readonly tag: {
    readonly title: string;
  } | null | undefined;
  readonly username: string;
  readonly " $fragmentType": "ClientCard_client";
};
export type ClientCard_client$key = {
  readonly " $data"?: ClientCard_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientCard_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientCard_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tag",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "lastLoginAt",
      "storageKey": "lastLoginAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endPrograms",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5a485cf540be17fc1cf6b2a309f02f9c";

export default node;
