/**
 * @generated SignedSource<<c6688d36f2420931624ef1f66fc38179>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MeasurementType = "BODYFAT" | "BODYWEIGHT" | "CALF" | "CHEST" | "HIP" | "SHOULDER" | "STEP_COUNT" | "THIGH" | "UPPER_ARM" | "WAIST" | "%future added value";
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
export type CreateClientMetricInput = {
  clientId: string;
  clientMutationId?: string | null | undefined;
  date?: string | null | undefined;
  measurement: number;
  name: string;
  type: MeasurementType;
  unit: string;
};
export type ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation$variables = {
  input: CreateClientMetricInput;
};
export type ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation$data = {
  readonly createClientMetric: {
    readonly metric: {
      readonly activityDate: string | null | undefined;
      readonly value: {
        readonly measurement?: number;
        readonly unit?: MeasurementUnit;
      };
      readonly x: string | null | undefined;
    };
  } | null | undefined;
};
export type ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation = {
  response: ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation$data;
  variables: ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": "x",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "YYYY-MM-DD"
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(format:\"YYYY-MM-DD\")"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(raw:true)"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    }
  ],
  "type": "CheckinAnswerMeasurementValue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClientMetricPayload",
        "kind": "LinkedField",
        "name": "createClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClientMetricPayload",
        "kind": "LinkedField",
        "name": "createClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d35f8fa3917ba40cf837e6e5f7b2ea76",
    "id": null,
    "metadata": {},
    "name": "ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation",
    "operationKind": "mutation",
    "text": "mutation ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation(\n  $input: CreateClientMetricInput!\n) {\n  createClientMetric(input: $input) {\n    metric {\n      x: activityDate(format: \"YYYY-MM-DD\")\n      activityDate(raw: true)\n      value {\n        __typename\n        ... on CheckinAnswerMeasurementValue {\n          measurement\n          unit\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1afe97a7d909365a1f32d57c71951641";

export default node;
