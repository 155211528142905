import React from "react";

import { TElement } from "@udecode/plate-common";
import MultipleItemsQuestionBox, {
  IMultipleItemQuestionBoxAnswer,
} from "../composite/MultipleItemsQuestionBox";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";

interface IMultipleCheckBoxQuestionProps
  extends ICheckInQuestionPropsBase<IMultipleItemQuestionBoxAnswer> {
  element: TElement;
  handleSetNode: (dataToSet: object) => void;
}

const MultipleCheckBoxQuestion = ({
  element,
  handleSetNode,
  view,
  disableAnswer,
}: IMultipleCheckBoxQuestionProps) => {
  return (
    <MultipleItemsQuestionBox
      type={"checkbox"}
      element={element}
      handleSetNode={handleSetNode}
      view={view}
      disableAnswer={disableAnswer}
    />
  );
};

export default MultipleCheckBoxQuestion;
