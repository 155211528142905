import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { Button, Box, useTheme } from "@mui/material";
import { useFragment, useMutation } from "react-relay/hooks";

import {
  CompactContainer,
  CompactContainerProps,
} from "../container/CompactContainer";
import { ReactComponent as EmptyAvatarImage } from "../../icons/EmptyClientAvatar.svg";
import { ActionButton } from "../button/ActionButton";
import { CardMedia } from "../card/CardMedia";

import { useAnalytics } from "../../hooks/useAnalytics";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { DropzoneUploadButton } from "../button/DropzoneUploadButton";
import { AssetType } from "../../constants";

import { SignupClientCompletePhotoScreen_user$key } from "./__generated__/SignupClientCompletePhotoScreen_user.graphql";
import { SignupClientCompletePhotoScreenMutation } from "./__generated__/SignupClientCompletePhotoScreenMutation.graphql";
import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE, HOME_ROUTE } from "../../routes/routes";
import EmptyAvatar from "../../icons/EmptyAvatar";

const useStyles = makeStyles((theme) => ({
  root: {},

  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  empty: {
    maxWidth: "100%",
  },

  image: {
    borderColor: theme.palette.text.secondary,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: theme.spacing(0.5),

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(42.5),
    height: theme.spacing(42.5),

    "& > *": {
      borderRadius: theme.spacing(1),
      width: theme.spacing(27.5),
      height: theme.spacing(27.5),
    },
  },

  buttons: {
    textAlign: "center",
    width: "100%",
    marginTop: "auto",

    "& > *": {
      margin: theme.spacing(0.5, 0),
    },

    [theme.breakpoints.up("sm")]: {
      marginBottom: "auto",
    },
  },

  uploadButton: {
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,

    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  uploadButtonText: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
  },

  skipButton: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: 14,
  },
}));

const updateUserMutation = graphql`
  mutation SignupClientCompletePhotoScreenMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...SignupClientCompletePhotoScreen_user
      }
    }
  }
`;

const userFragment = graphql`
  fragment SignupClientCompletePhotoScreen_user on User {
    id
    photoURL
  }
`;

export interface SignupClientCompletePhotoScreenProps
  extends Omit<CompactContainerProps, "children"> {
  user: SignupClientCompletePhotoScreen_user$key;
}

export function SignupClientCompletePhotoScreen(
  props: SignupClientCompletePhotoScreenProps,
) {
  const navigate = useNavigate();
  const s = useStyles();
  const [trackEvent] = useAnalytics();
  const { className, user: userRef, ...other } = props;
  const user = useFragment(userFragment, userRef);
  const snackAlert = useSnackAlert();
  const { id, photoURL } = user;
  const [uploadedImage, setUploadedImage] = React.useState<string>();

  const [updateUser, inFlight] =
    useMutation<SignupClientCompletePhotoScreenMutation>(updateUserMutation);

  const handleClose = React.useCallback(() => {
    navigate(HOME_ROUTE);
  }, []);

  const handleReset = React.useCallback(() => {
    setUploadedImage(null);
  }, []);

  const handleSave = React.useCallback(() => {
    updateUser({
      variables: {
        input: {
          id,
          photoURL: uploadedImage,
        },
      },
      onCompleted(_, errors) {
        if (errors) {
          snackAlert({
            severity: "error",
            message: errors[0].message,
          });
        } else {
          navigate(HOME_ROUTE);
          trackEvent("Client - Enter Info", { photoURL });
        }
      },
    });
  }, [id, photoURL, snackAlert, trackEvent, updateUser, uploadedImage]);

  const disabled = inFlight;
  const image = uploadedImage || photoURL;
  const theme = useTheme();

  return (
    <CompactContainer
      className={clsx(s.root, className)}
      classes={{
        body: s.body,
      }}
      title="Add a profile photo"
      subtitle="Show us who you are!"
      {...other}
    >
      {image ? (
        <Box className={s.image}>
          <CardMedia image={image} cover={{ w: 256, h: 256 }} />
        </Box>
      ) : (
        <EmptyAvatar className={s.empty} fill={theme.palette.primary.main} />
      )}

      <Box className={s.buttons}>
        {uploadedImage ? (
          <>
            <ActionButton
              size="large"
              onClick={handleSave}
              fullWidth
              disabled={disabled}
            >
              Save profile photo
            </ActionButton>
            <Button
              className={s.skipButton}
              onClick={handleReset}
              variant="text"
              fullWidth
              disabled={disabled}
            >
              Upload another photo
            </Button>
          </>
        ) : (
          <>
            <DropzoneUploadButton
              classes={{
                root: s.uploadButton,
                text: s.uploadButtonText,
              }}
              onChange={setUploadedImage}
              assetType={AssetType.USER_PHOTO}
              mimeType={getMimeTypes(ElementType.IMAGE)}
              inputName="photoURL"
              text="Upload photo"
              color="secondary"
            />
            <Button
              className={s.skipButton}
              variant="text"
              fullWidth
              onClick={handleClose}
            >
              Skip for now
            </Button>
          </>
        )}
      </Box>
    </CompactContainer>
  );
}
