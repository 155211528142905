/**
 * @generated SignedSource<<93c9f771a45142c6403b285cef1f862c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostMenu_post$data = {
  readonly groupId: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostsEditDialog_post">;
  readonly " $fragmentType": "GroupPostMenu_post";
};
export type GroupPostMenu_post$key = {
  readonly " $data"?: GroupPostMenu_post$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostMenu_post">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostMenu_post",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupPostsEditDialog_post"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupId",
      "storageKey": null
    }
  ],
  "type": "GroupPost",
  "abstractKey": null
};

(node as any).hash = "6aa13960c9ec7eee3c1937bd5984c0bc";

export default node;
