import clsx from "clsx";
import React, { FormEvent } from "react";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import makeStyles from "@mui/styles/makeStyles";

import { useAnalytics } from "../../hooks/useAnalytics";
import { ClientSettingsForm, ClientSettings } from "../form/ClientSettingsForm";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import {
  CompactContainer,
  CompactContainerProps,
} from "../container/CompactContainer";

import { SignupClientCompleteScreen_user$key } from "./__generated__/SignupClientCompleteScreen_user.graphql";
import { SignupClientCompleteScreen_workspace$key } from "./__generated__/SignupClientCompleteScreen_workspace.graphql";
import { SignupClientCompleteScreenMutation } from "./__generated__/SignupClientCompleteScreenMutation.graphql";
import { ActionButton } from "../button/ActionButton";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  HOME_ROUTE,
  SIGN_UP_CLIENT_COMPLETE_PHOTO,
} from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {},

  submit: {
    margin: theme.spacing(2.5, 0, 3.5, 0),

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5.5, 0, 6.5, 0),
    },
  },
}));

const updateUserMutation = graphql`
  mutation SignupClientCompleteScreenMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...CurrentUserProvider_user
        ...SignupClientCompleteScreen_user
      }
    }
  }
`;

const userFragment = graphql`
  fragment SignupClientCompleteScreen_user on User {
    ...ClientSettingsForm_settings
    id
    displayName
    username
    photoURL
  }
`;

const workspaceFragment = graphql`
  fragment SignupClientCompleteScreen_workspace on Workspace {
    name
  }
`;

export interface SignupClientScreenProps
  extends Omit<CompactContainerProps, "children"> {
  returnUrl?: string;
  user: SignupClientCompleteScreen_user$key;
  workspace: SignupClientCompleteScreen_workspace$key;
}

export function SignupClientCompleteScreen(props: SignupClientScreenProps) {
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const navigate = useNavigate();
  const {
    className,
    user: userRef,
    workspace: workspaceRef,
    returnUrl,
    ...other
  } = props;
  const user = useFragment(userFragment, userRef);
  const workspace = useFragment(workspaceFragment, workspaceRef);
  const [loading, setLoading] = React.useState(false);
  const [settings, setSettings] = React.useState<ClientSettings>();
  const incomplete = user.displayName && user.username !== user.displayName;
  const [trackEvent] = useAnalytics();
  const [updateUser, signupInFlight] =
    useMutation<SignupClientCompleteScreenMutation>(updateUserMutation);
  const { photoURL } = user;

  const disabled = loading || signupInFlight;
  const completed =
    settings?.rawDisplayName &&
    settings?.gender &&
    settings?.birthday &&
    settings?.location &&
    parseInt(settings?.height) > 0 &&
    parseInt(settings?.weight) > 0;

  const handleSubmit = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const {
        email,
        username,
        rawDisplayName: displayName,
        timeZone,
        ...otherSettings
      } = settings;

      setLoading(true);
      updateUser({
        variables: {
          input: {
            id: user.id,
            displayName,
            ...otherSettings,
          },
        },
        onCompleted(_: any, errors: any[]) {
          if (errors) {
            snackAlert({
              severity: "error",
              message: errors[0].message,
            });
            setLoading(false);
          } else {
            navigate(photoURL ? HOME_ROUTE : SIGN_UP_CLIENT_COMPLETE_PHOTO);
            trackEvent("Client - Enter Info", settings);
          }
        },
      });
    },
    [photoURL, settings, snackAlert, trackEvent, updateUser, user.id],
  );

  const workspaceName = workspace.name || "Stridist";

  return (
    <CompactContainer
      className={clsx(s.root, className)}
      maxWidth="sm"
      title={incomplete ? "Welcome Back" : `Welcome to ${workspaceName}`}
      subtitle={
        incomplete
          ? "Please complete your missing profile details."
          : "First, tell us about yourself."
      }
      {...other}
    >
      <form onSubmit={handleSubmit}>
        <ClientSettingsForm
          settings={user}
          onChange={setSettings}
          disabled={disabled}
          disableTimeZone
          isClient
        />

        <ActionButton
          className={s.submit}
          disabled={disabled || !completed}
          size="large"
          type="submit"
          fullWidth
        >
          Continue
        </ActionButton>
      </form>
    </CompactContainer>
  );
}
