import clsx from "clsx";
import React from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface LocationFieldProps
  extends Omit<FieldsGroupProps, "onChange" | "label"> {
  disabled?: boolean;
  onChange?: (value: string) => void;
  value: string;
}

export function LocationField(props: LocationFieldProps) {
  const { className, onChange, value, disabled, ...other } = props;
  const s = useStyles();
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FieldsGroup
      className={clsx(s.root, className)}
      label="Location"
      {...other}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Add your location"
        disabled={disabled}
        value={value}
        onChange={handleChange}
      />
    </FieldsGroup>
  );
}
