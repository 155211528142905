import {
  PlateEditor,
  focusEditor,
  getAboveNode,
  getEndPoint,
  getNode,
  insertElements,
  insertEmptyElement,
  toggleNodeType,
} from "@udecode/plate-common";
import { ELEMENT_MEDIA_EMBED, insertMedia } from "@udecode/plate-media";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { toggleIndentList } from "@udecode/plate-indent-list";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { ELEMENT_LINK, triggerFloatingLink } from "@udecode/plate-link";
import { Path, Transforms } from "slate";
import { UserRole } from "../../../constants";
import { ListStyleType, ListType } from "./listElementsUtils";
import { unwrapList } from "@udecode/plate-list";
import { createTableRows } from "../components/table/utils";
import { IMAGE_ELEMENT } from "../elements/Image";
import { generateId } from "./nodeUtil";
import { ELEMENT_ACCORDION, ELEMENT_TABS } from "../elements/tabs/Tabs";
import { ELEMENT_TABS_ITEM } from "../elements/tabs/TabsItem";
import { WORKOUT_SECTION } from "../components/workout/WorkoutSectionElement";

export const insertElementAt = async (
  elementType: string,
  editor: PlateEditor,
  at?: Path,
) => {
  at ??= editor.selection
    ? Path.next(editor.selection.focus.path.slice(0, -1))
    : [0];
  let focusAt = at;

  switch (elementType) {
    case ELEMENT_TABS_ITEM: {
      insertElements(
        editor,
        {
          type: elementType,
          children: [{ type: "p", children: [{ text: "" }], id: generateId() }],
        },
        {
          select: true,
          at: at,
        },
      );

      break;
    }
    case WORKOUT_SECTION: {
      insertElements(
        editor,
        {
          type: elementType,
          children: [{ text: "" }],
          workout: {
            exercises: [],
            title: "",
          },
        },
        {
          select: true,
          at: at,
        },
      );

      break;
    }
    case ELEMENT_TABS:
    case ELEMENT_ACCORDION: {
      insertElements(
        editor,
        {
          type: elementType,
          children: [
            {
              type: "tabs_item",
              children: [
                { type: "p", children: [{ text: "" }], id: generateId() },
              ],
              label: "First Tab",
              active: true,
            },
            {
              type: "tabs_item",
              children: [
                { type: "p", children: [{ text: "" }], id: generateId() },
              ],
              label: "Second Tab",
              active: false,
            },
          ],
        },
        {
          select: true,
          at: at,
        },
      );

      focusAt = focusAt && [...focusAt, 0, 0];

      break;
    }
    case IMAGE_ELEMENT: {
      await insertElements(
        editor,
        {
          type: IMAGE_ELEMENT,
          children: [
            {
              type: "MEDIA_CAPTION",
              children: [{ text: "" }],
              id: generateId(),
            },
          ],
        },
        {
          at,
        },
      );

      break;
    }
    case ELEMENT_MEDIA_EMBED: {
      await insertMedia(editor, {
        type: ELEMENT_MEDIA_EMBED,
      });

      break;
    }
    case ListType.UNORDERED:
    case ListType.ORDERED:
    case ListType.TODO: {
      insertEmptyElement(editor, ELEMENT_PARAGRAPH, {
        at: at,
        select: true,
      });
      toggleIndentList(editor, {
        listStyleType: ListStyleType[elementType],
      });

      break;
    }
    case ELEMENT_TABLE: {
      insertElements(
        editor,
        {
          type: ELEMENT_TABLE,
          children: createTableRows(2, 2),
        },
        {
          at: at,
        },
      );

      focusAt = at && [...focusAt, 0, 0];

      break;
    }
    case ELEMENT_LINK: {
      insertEmptyElement(editor, ELEMENT_PARAGRAPH, {
        at: at,
        select: true,
      });

      setTimeout(() => {
        focusEditor(editor, focusAt);
        setTimeout(() => {
          triggerFloatingLink(editor, { focused: true });
        }, 0);
      }, 0);

      return;
    }
    default: {
      insertEmptyElement(editor, elementType, {
        at: at,
      });
    }
  }

  setTimeout(() => {
    focusEditor(editor, focusAt);
  }, 0);
};

export const turnIntoElement = (editor: PlateEditor, type: string) => {
  if (Object.values(ListType).includes(type as ListType)) {
    toggleIndentList(editor, {
      listStyleType: ListStyleType[type],
    });
  } else {
    unwrapList(editor);
    toggleNodeType(editor, { activeType: type });
  }
};

export const duplicateElement = (editor, path) => {
  const node = getNode(editor, path);
  if (node) {
    const duplicatedNode = { ...node };
    Transforms.insertNodes(editor, duplicatedNode, { at: Path.next(path) });
  }
};

/**
 * Enum representing different views for the editor element.
 */
export enum EditorElementView {
  Coach = "coach", // View for coaches while editing the component
  Client = "client", // View for clients while submitting the component
  Preview = "preview", // View for coach while previewing the component
  Review = "review", // View for coach while reviewing the submitted component
}

export enum CheckInComponentStatus {
  Submitted = "submitted",
  Unsubmitted = "unsubmitted",
}

export function getCheckInComponentView(
  role: UserRole | string,
  isPreview: boolean,
  isSubmitted: boolean,
) {
  switch (role) {
    case UserRole.CLIENT:
      return EditorElementView.Client;
    case UserRole.COACH:
      return isPreview
        ? EditorElementView.Preview
        : isSubmitted
          ? EditorElementView.Review
          : EditorElementView.Coach;
    default:
      return;
  }
}

export function getCheckInComponentStatus(
  role: UserRole | string,
  isSubmitted: boolean,
) {
  if (role === UserRole.COACH) return undefined;
  return isSubmitted
    ? CheckInComponentStatus.Submitted
    : CheckInComponentStatus.Unsubmitted;
}
