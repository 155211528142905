import React from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { SkeletonCard } from "../../components/card/SkeletonCard";
import { Pagination } from "../../components/pagination/Pagination";
import { EnrollmentCard } from "../../components/card/EnrollmentCard";
import { PaginationContext } from "../../utils/paging";

import { EnrollmentList_enrollments$key } from "./__generated__/EnrollmentList_enrollments.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

const enrollmentsFragment = graphql`
  fragment EnrollmentList_enrollments on EnrollmentConnection {
    totalCount
    edges {
      node {
        id
        ...EnrollmentCard_enrollment
      }
    }
  }
`;

export interface EnrollmentListProps {
  enrollmentsRef: EnrollmentList_enrollments$key;
}

export function EnrollmentList(props: EnrollmentListProps) {
  const { enrollmentsRef } = props;
  const s = useStyles();
  const { first } = React.useContext(PaginationContext);

  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);

  return enrollments ? (
    <Container className={s.root} maxWidth="md">
      {enrollments.edges.length > 0 &&
        enrollments.edges.map(({ node }) => (
          <EnrollmentCard
            key={node.id}
            className={s.card}
            enrollmentRef={node}
          />
        ))}

      <Pagination totalCount={enrollments.totalCount} perPage={first} />
    </Container>
  ) : (
    <Container className={s.root} maxWidth="md">
      {Array.from({ length: 3 }).map((_, i) => (
        <SkeletonCard key={i} className={s.card} />
      ))}
    </Container>
  );
}
