/**
 * @generated SignedSource<<008615b5a07ebe9fbda342b250b1db48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type upsertProgressPhotosInput = {
  clientMutationId?: string | null | undefined;
  content: string;
  date: string;
};
export type ConfirmActionProgressPhotosUploadMutation$variables = {
  input: upsertProgressPhotosInput;
};
export type ConfirmActionProgressPhotosUploadMutation$data = {
  readonly upsertProgressPhotos: {
    readonly metric: {
      readonly createdAt: string | null | undefined;
      readonly id: string;
      readonly value: {
        readonly back?: string | null | undefined;
        readonly front?: string | null | undefined;
        readonly side?: string | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type ConfirmActionProgressPhotosUploadMutation = {
  response: ConfirmActionProgressPhotosUploadMutation$data;
  variables: ConfirmActionProgressPhotosUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "MMM DD, YYYY"
    }
  ],
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": "createdAt(format:\"MMM DD, YYYY\")"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "front",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "back",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    }
  ],
  "type": "CheckinAnswerProgressPhotoValue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmActionProgressPhotosUploadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "upsertProgressPhotosPayload",
        "kind": "LinkedField",
        "name": "upsertProgressPhotos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmActionProgressPhotosUploadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "upsertProgressPhotosPayload",
        "kind": "LinkedField",
        "name": "upsertProgressPhotos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d3e55ca5aecca601136608655a52df8",
    "id": null,
    "metadata": {},
    "name": "ConfirmActionProgressPhotosUploadMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmActionProgressPhotosUploadMutation(\n  $input: upsertProgressPhotosInput!\n) {\n  upsertProgressPhotos(input: $input) {\n    metric {\n      id\n      createdAt(format: \"MMM DD, YYYY\")\n      value {\n        __typename\n        ... on CheckinAnswerProgressPhotoValue {\n          front\n          back\n          side\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e1372865bf293d2d1704a71854e8bd1";

export default node;
