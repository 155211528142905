import { LucideIcon, SquarePlay } from "lucide-react";
import {
  CHECK_IN_FEEDBACK_SECTION,
  CHECK_IN_QUESTIONS_SECTION,
  CHECK_IN_RATING_SECTION,
  CHECK_IN_VOTE_SECTION,
  IMenuSection,
  LAYOUT_SECTION,
  LISTS_SECTION,
  MEDIA_SECTION,
  QUOTES_SECTION,
  SWITCH_SECTION,
  TEXT_SECTION,
  URL_SECTION,
} from "../../utils/menuSectionsUtil";
import { Icons } from "../../../plate-ui/Icons/icons";
import { ITurnIntoMenuItemGroup } from "../../utils/menuItemTurnIntoGroups";

export type ToolbarOptions = {
  showInsertDropdown?: boolean;
  showTextTransformOptions?: boolean;
  showTextFormattingOptions?: boolean;
  showTextColorOptions?: boolean;
  showTextIndentationOptions?: boolean;
  showListOptions?: boolean;
  showQuotesOptions?: boolean;
  showLinkOption?: boolean;
  showAdvancedLinkOptions?: boolean;
  showElementInsertOptions?: boolean;
  showEmojiOption?: boolean;
};

export type ToolbarMenu = {
  label: string;
  icon: LucideIcon;
  sections: IMenuSection[];
};

export type Schema = {
  generalDropDownMenuItems: IMenuSection[];
  turnIntoMenuItemGroups?: ITurnIntoMenuItemGroup[];
  showToolbar?: boolean;
  showToolbarBorder?: boolean;
  toolbarOptions?: ToolbarOptions;
  stickyToolbar?: boolean;
  showToolbarAlways?: boolean;
  showInsertDropdownOnDraggable?: boolean;
};

export const MediaToolbarMenu: ToolbarMenu = {
  label: "Media",
  icon: SquarePlay,
  sections: [MEDIA_SECTION, URL_SECTION],
};

export const CheckinToolbarMenu: ToolbarMenu = {
  label: "Check-in",
  icon: Icons.messageQuestion,
  sections: [
    CHECK_IN_QUESTIONS_SECTION,
    CHECK_IN_VOTE_SECTION,
    CHECK_IN_RATING_SECTION,
    CHECK_IN_FEEDBACK_SECTION,
  ],
};

export const BasicToolbarMenu: ToolbarMenu = {
  label: "Basic",
  icon: Icons.notepadText,
  sections: [
    TEXT_SECTION,
    LISTS_SECTION,
    SWITCH_SECTION,
    QUOTES_SECTION,
    LAYOUT_SECTION,
  ],
};
