/**
 * @generated SignedSource<<45fd59b60bb18c1f6122fbd0f1cae3e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientsListScreen_addedUsers$data = {
  readonly addedUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"InviteDialog_addedUsers">;
  } | null | undefined;
  readonly " $fragmentType": "CoachClientsListScreen_addedUsers";
};
export type CoachClientsListScreen_addedUsers$key = {
  readonly " $data"?: CoachClientsListScreen_addedUsers$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsListScreen_addedUsers">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "addedUsers"
        ]
      }
    ]
  },
  "name": "CoachClientsListScreen_addedUsers",
  "selections": [
    {
      "alias": "addedUsers",
      "args": null,
      "concreteType": "InviteConnection",
      "kind": "LinkedField",
      "name": "__ClientInvites_addedUsers_connection",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InviteDialog_addedUsers"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InviteEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Invite",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "3cc9d2fa57a4dcc67b4955c494b05658";

export default node;
