import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";

export const ExtraMeasurementTypesInfoSection = () => {
  return (
    <InfoSection
      title="Types"
      description={
        <>
          Name of <b>PRE</b> / <b>RIR</b> column is automatic. <br /> Pick
          training type for the exercise and column name is in sync. <br />{" "}
          <i>(based on the type used the most in the column)</i>
        </>
      }
    >
      <InfoBox
        title="Rate of Perceived Exertion"
        description="Define intensity (e.g. 1-10 scale or percentage based)."
      >
        <ExampleValueBadge value="8" unit="RPE" />
        <ExampleValueBadge value="75" unit="RPE" />
      </InfoBox>

      <InfoBox
        title="Reps in Reserve"
        description="Set number of reps before failure."
      >
        <ExampleValueBadge value="2" unit="RIR" />
      </InfoBox>
    </InfoSection>
  );
};
