import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachActivity } from "../../../../components/activity/CoachActivity";
import {
  ActivitySort,
  ActivitySortLocalStorageParameter,
  ActivityStatus,
  ActivityType,
} from "../../../../constants";
import { CoachActivityRouteQuery } from "./__generated__/CoachActivityRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

export function CoachActivityRoute({
  archived = false,
}: {
  archived?: boolean;
}) {
  const activityTypesObject = JSON.parse(
    localStorage.getItem(ActivitySortLocalStorageParameter.FILTER),
  ) ?? {
    [ActivityType.PROGRAM_COMPONENT]: true,
    [ActivityType.MEAL_LOGGING]: true,
    [ActivityType.CLIENT_FORM]: true,
  };

  const activityTypesArray = Object.keys(activityTypesObject).filter(
    (key) => activityTypesObject[key],
  );
  const orderBy =
    JSON.parse(localStorage.getItem(ActivitySortLocalStorageParameter.SORT)) ??
    ActivitySort.COMPLETED_AT_DESC;
  const props = useLazyLoadQuery<CoachActivityRouteQuery>(
    graphql`
      query CoachActivityRouteQuery(
        $archived: Boolean!
        $orderBy: ActivitySortBy!
        $activityTypes: [ActivityType!]!
      ) {
        ...CoachActivity_root
          @arguments(
            first: 5
            after: null
            withAnswersOnly: true
            withMealLogging: true
            withClientForms: true
            coachArchived: $archived
            orderBy: $orderBy
            activityTypes: $activityTypes
          )
      }
    `,
    {
      activityTypes: activityTypesArray ?? Object.values(ActivityType),
      archived: archived,
      orderBy: orderBy ?? ActivitySort.COMPLETED_AT_DESC,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  return (
    <CoachActivity
      root={props}
      status={archived ? ActivityStatus.ARCHIVED : ActivityStatus.ACTIVE}
    />
  );
}

export const CoachActivityInboxRoute = () => {
  return <CoachActivityRoute />;
};

export const CoachActivityArchivedRoute = () => {
  return <CoachActivityRoute archived />;
};
