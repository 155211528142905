import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import {
  ActivityLocked,
  ActivityLockedProps,
} from "../activity/ActivityLocked";

import { FullScreenDialog, FullScreenDialogProps } from "./FullScreenDialog";

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface LockedActivityDialogProps extends FullScreenDialogProps {
  daysDiff: ActivityLockedProps["daysDiff"];
}

export function LockedActivityDialog(props: LockedActivityDialogProps) {
  const { className, open, onClose, daysDiff, ...other } = props;
  const s = useStyles();

  return (
    <FullScreenDialog
      className={clsx(s.root, className)}
      open={open}
      onClose={onClose}
      showBackButton={false}
      showCloseButton
      {...other}
    >
      <ActivityLocked daysDiff={daysDiff} />
    </FullScreenDialog>
  );
}
