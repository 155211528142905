import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

import { GroupPostMenu_post$key } from "./__generated__/GroupPostMenu_post.graphql";
import { GroupPostsEditDialog } from "./GroupPostsEditDialog";
import { useRemoveGroupPostMutation } from "./mutations/RemoveGroupPost";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const fragment = graphql`
  fragment GroupPostMenu_post on GroupPost {
    ...GroupPostsEditDialog_post
    id
    groupId
  }
`;

export interface GroupPostMenuProps extends MenuProps {
  postRef: GroupPostMenu_post$key;
}

export function GroupPostMenu(props: GroupPostMenuProps) {
  const { className, postRef, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const post = useFragment(fragment, postRef);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [removeGroupPost] = useRemoveGroupPostMutation();

  const handleEdit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
      setOpenEditDialog(true);
    },
    [onClose],
  );

  const handleEditClose = React.useCallback(() => {
    setOpenEditDialog(false);
  }, []);

  const handleDelete = React.useCallback(() => {
    removeGroupPost({
      variables: {
        input: {
          groupId: post.groupId,
          postId: post.id,
        },
      },
    });
  }, [post.groupId, post.id, removeGroupPost]);

  return (
    <>
      <Menu className={clsx(s.root, className)} {...other}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit post</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <RemoveIcon />
          </ListItemIcon>
          <ListItemText>Delete post</ListItemText>
        </MenuItem>
      </Menu>
      {openEditDialog && (
        <GroupPostsEditDialog
          open
          groupId={post.groupId}
          post={post}
          onClose={handleEditClose}
        />
      )}
    </>
  );
}
