import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { SOMETHING_WENT_WRONG } from "../../../constants";

import { UpdateInviteCodeStartDateMutation } from "./__generated__/UpdateInviteCodeStartDateMutation.graphql";

const mutation = graphql`
  mutation UpdateInviteCodeStartDateMutation(
    $input: UpdateInviteCodeStartDateInput!
  ) {
    updateInviteCodeStartDate(input: $input) {
      inviteCode {
        id
        startDate
      }
    }
  }
`;

export const useUpdateInviteCodeStartDateMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpdateInviteCodeStartDateMutation>(
    mutation,
    ({ onSuccess }) => ({
      onSuccess: (result) => {
        snackAlert({
          severity: "success",
          message: "Program invite start date updated",
        });

        if (onSuccess) {
          onSuccess(result);
        }
      },
      onFailure: () =>
        snackAlert({
          severity: "error",
          message: SOMETHING_WENT_WRONG,
        }),
    }),
  );
};
