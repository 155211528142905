import clsx from "clsx";
import React from "react";
import {
  Drawer,
  DrawerProps,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ActionButton } from "../button/ActionButton";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { ReactComponent as BackIcon } from "../../icons/ArrowForwardOutline.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";
import { SchemaElements } from "../editor/normalizers/withSchema";

import { MealLoggingSettingsDrawer_workspace$key } from "./__generated__/MealLoggingSettingsDrawer_workspace.graphql";
import { MealLoggingQuestion, MealLoggingQuestionType } from "./types";
import { MealLoggingQuestionList } from "./MealLoggingQuestionList";
import { MealLoggingQuestionEdit } from "./MealLoggingQuestionEdit";
import { MealLoggingQuestionListItemProps } from "./MealLoggingQuestionListItem";
import { useUpdateMealLoggingQuestionsMutation } from "./mutations/UpdateMealLoggingQuestions";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(3, 4),
    width: "100%",

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  header: {
    display: "flex",
    alignItems: "center",

    "& > :first-child": {
      marginRight: "auto",
    },
  },

  button: {
    "& svg": {
      width: 24,
      height: 24,
    },
  },

  back: {
    "& svg": {
      transform: "rotate(180deg)",
    },
    marginLeft: theme.spacing(-1.5),
  },

  close: {
    marginRight: theme.spacing(-1.5),
  },

  add: {
    margin: theme.spacing(2, 0),
  },
}));

const createEmptyQuestion = () => ({
  id: SchemaElements.generateId(),
  type: MealLoggingQuestionType.MULTIPLE_CHOICE,
  text: "",
  options: [],
});

const workspaceFragment = graphql`
  fragment MealLoggingSettingsDrawer_workspace on Workspace {
    mealLoggingQuestions
    mealLoggingQuestionsTotal
  }
`;

export interface MealLoggingSettingsDrawerProps extends DrawerProps {
  workspace: MealLoggingSettingsDrawer_workspace$key;
}

export function MealLoggingSettingsDrawer(
  props: MealLoggingSettingsDrawerProps,
) {
  const { className, workspace: workspaceRef, ...other } = props;
  const workspace = useFragment(workspaceFragment, workspaceRef);
  const { onClose } = props;
  const s = useStyles();
  const [updateQuestions] = useUpdateMealLoggingQuestionsMutation();
  const [dirty, setDirty] = React.useState(false);

  const initialQuestions = JSON.parse(
    workspace.mealLoggingQuestions,
  ) as MealLoggingQuestion[];

  const [questions, setQuestions] = React.useState(initialQuestions);
  const [editQuestion, setEditQuestion] = React.useState<MealLoggingQuestion>();

  const save = React.useCallback(() => {
    if (dirty) {
      const mealLoggingQuestions = JSON.stringify(
        questions.filter(({ text }) => Boolean(text)),
      );

      updateQuestions({
        variables: {
          input: {
            mealLoggingQuestions,
          },
        },
        onSuccess: () => setDirty(false),
      });
    }
  }, [dirty, questions, updateQuestions]);

  const handleUpdate = React.useCallback((questions: MealLoggingQuestion[]) => {
    setQuestions(questions);
    setDirty(true);
  }, []);

  const handleAddQuestion = React.useCallback(() => {
    const question: MealLoggingQuestion =
      createEmptyQuestion() as MealLoggingQuestion;

    handleUpdate([...questions, question]);
    setEditQuestion(question);
  }, [handleUpdate, questions]);

  const handleCloseClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
      save();
    },
    [onClose, save],
  );

  const handleBackClick = React.useCallback(() => {
    setEditQuestion(null);
  }, []);

  const handleQuestionUpdate = React.useCallback(
    (question: MealLoggingQuestion) => {
      const index = questions.indexOf(editQuestion);
      const update = [...questions];

      Object.assign(update[index], question);
      handleUpdate(update);
    },
    [editQuestion, handleUpdate, questions],
  );

  const handleRemoveClick = React.useCallback(() => {
    const index = questions.indexOf(editQuestion);
    const update = [...questions];

    update.splice(index, 1);
    handleUpdate(update);
    setEditQuestion(null);
  }, [editQuestion, handleUpdate, questions]);

  const handleQuestionAction: MealLoggingQuestionListItemProps["onAction"] =
    React.useCallback((event, option) => {
      const {
        dataset: { action },
      } = event.currentTarget;

      switch (action) {
        case "edit":
          setEditQuestion(option);
          break;

        default:
          throw new Error(`Unknown action "${action}"`);
      }
    }, []);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      anchor="right"
      variant="persistent"
      {...other}
    >
      <Box className={s.header}>
        {editQuestion ? (
          <>
            <IconButton
              className={clsx(s.button, s.back)}
              color="primary"
              onClick={handleBackClick}
              size="large"
            >
              <BackIcon />
            </IconButton>

            <IconButton
              className={s.button}
              color="primary"
              onClick={handleRemoveClick}
              size="large"
            >
              <RemoveIcon />
            </IconButton>
          </>
        ) : (
          <Typography variant="h2">Edit Prompts</Typography>
        )}

        <IconButton
          className={clsx(s.button, s.close)}
          onClick={handleCloseClick}
          color="primary"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {editQuestion ? (
        <MealLoggingQuestionEdit
          question={editQuestion}
          onUpdate={handleQuestionUpdate}
        />
      ) : (
        <>
          <ActionButton
            className={s.add}
            size="large"
            onClick={handleAddQuestion}
          >
            Add prompt
          </ActionButton>
          <MealLoggingQuestionList
            questions={questions}
            onUpdate={handleUpdate}
            ListItemProps={{
              onAction: handleQuestionAction,
            }}
          />
        </>
      )}
    </Drawer>
  );
}
