import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { GroupPostCommentsList_post$key } from "./__generated__/GroupPostCommentsList_post.graphql";
import { GroupPostCommentsListItemWithReplies } from "./GroupPostCommentsListItemWithReplies";

const useStyles = makeStyles((theme) => ({
  root: {},

  divider: {
    height: 2,
    backgroundColor: theme.palette.quote,
    opacity: 0.4,
  },

  loadMore: {
    borderRadius: 0,
  },
}));

const postFragment = graphql`
  fragment GroupPostCommentsList_post on GroupPost
  @refetchable(queryName: "GroupPostCommentsListRefetchQuery")
  @argumentDefinitions(
    last: { type: "Int", defaultValue: 5 }
    before: { type: "String" }
  ) {
    id
    comments(last: $last, before: $before)
      @connection(key: "GroupPostCommentsList_comments") {
      edges {
        node {
          ...GroupPostCommentsListItemWithReplies_comment
          id
        }
      }
    }
  }
`;

export interface GroupPostCommentsListProps extends BoxProps {
  postRef: GroupPostCommentsList_post$key;
}

export function GroupPostCommentsList(props: GroupPostCommentsListProps) {
  const { className, postRef, ...other } = props;
  const {
    data: post,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(postFragment, postRef);
  const s = useStyles();
  const { comments } = post;
  const pageSize = 5;

  const [loading, setLoading] = React.useState(false);
  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      loadNext(pageSize, {
        onComplete: setLoaded,
      });
    }
  }, [loading, loadNext, setLoaded]);

  return (
    comments.edges.length > 0 && (
      <Box className={clsx(s.root, className)} {...other}>
        {hasNext && (
          <React.Fragment>
            <Divider />
            <Button
              className={s.loadMore}
              onClick={handleMoreClick}
              disabled={loading}
              fullWidth
            >
              Show older comments
            </Button>
          </React.Fragment>
        )}

        {comments.edges.map(({ node }, index) => (
          <React.Fragment key={node.id}>
            {index === 0 && <Divider />}
            <GroupPostCommentsListItemWithReplies
              comment={node}
              first={index === 0}
            />
          </React.Fragment>
        ))}
      </Box>
    )
  );
}
