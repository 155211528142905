import React from "react";
import {
  Card,
  CardProps,
  CardHeader,
  Button,
  Paper,
  CardActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import { useFragment } from "react-relay/hooks";
import { MoreHoriz } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { ConnectionHandler } from "relay-runtime";

import { CardAvatar } from "../card/CardAvatar";
import { ListMenu } from "../menu/ListMenu";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { colorSystem } from "../../theme";

import { GroupEnrollmentCard_groupEnrollment$key } from "./__generated__/GroupEnrollmentCard_groupEnrollment.graphql";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    position: "relative",
  },

  header: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },

  cardTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  cardSubheader: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },

  metrics: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
    },
  },

  actions: {
    padding: theme.spacing(4),
  },

  menuButton: {
    minWidth: theme.spacing(5.5),
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    backgroundColor: colorSystem.secondaryGray,
  },
}));

const fragment = graphql`
  fragment GroupEnrollmentCard_groupEnrollment on GroupEnrollment {
    id
    group {
      name
      size
    }
  }
`;

const unenrollGroupMutation = graphql`
  mutation GroupEnrollmentCardUnenrollMutation($input: UnenrollGroupInput!) {
    unenrollGroup(input: $input) {
      clientMutationId
    }
  }
`;

export interface GroupEnrollmentCardProps extends CardProps {
  groupEnrollmentRef: GroupEnrollmentCard_groupEnrollment$key;
}

export function GroupEnrollmentCard(props: GroupEnrollmentCardProps) {
  const { groupEnrollmentRef, ...other } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const groupEnrollment = useFragment(fragment, groupEnrollmentRef);
  const { id, group } = groupEnrollment;
  const menuState = usePopupState({
    variant: "popover",
    popupId: "groupMenu",
  });
  const triggerProps = React.useMemo(() => bindTrigger(menuState), [menuState]);
  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);

  const [unenrollGroup, unenrollGroupInFlight] = useMutation(
    unenrollGroupMutation,
  );

  const unenrollGroupStoreUpdater = React.useCallback(
    (store) => {
      const groupEnrollments = ConnectionHandler.getConnection(
        store.getRoot(),
        "Program_groupEnrollments",
        [],
      );

      if (groupEnrollments) {
        ConnectionHandler.deleteNode(groupEnrollments, id);

        const totalCount = groupEnrollments.getValue("totalCount") as number;
        groupEnrollments.setValue(Math.max(totalCount - 1, 0), "totalCount");
      }
    },
    [id],
  );

  const handleUnenrollGroup = React.useCallback(() => {
    menuProps.onClose();

    const input = { id };
    unenrollGroup({
      variables: { input },
      onCompleted: () => {
        snackAlert({
          severity: "success",
          message: "Enrollment has been removed.",
        });
      },
      updater: unenrollGroupStoreUpdater,
    });
  }, [id, menuProps, snackAlert, unenrollGroup, unenrollGroupStoreUpdater]);

  const menuOptions = [
    {
      label: "Remove from Program",
      onClick: handleUnenrollGroup,
    },
  ];

  return (
    <Card {...other}>
      <Paper className={s.actionArea}>
        <CardHeader
          className={s.header}
          avatar={
            <CardAvatar
              className={s.avatar}
              variant="square"
              children={group.name.substring(0, 1)}
            />
          }
          title={group.name}
          titleTypographyProps={{ variant: "h5", className: s.cardTitle }}
          subheader={`${group.size} member${group.size !== 1 ? "s" : ""}`}
          subheaderTypographyProps={{ className: s.cardSubheader }}
        />
        <CardActions className={s.actions}>
          <Button
            {...triggerProps}
            className={s.menuButton}
            children={<MoreHoriz />}
          />
        </CardActions>
      </Paper>

      <ListMenu
        {...menuProps}
        options={menuOptions}
        disabled={unenrollGroupInFlight}
      />
    </Card>
  );
}
