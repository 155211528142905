import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { RemoveMealLoggingEntryMutation } from "./__generated__/RemoveMealLoggingEntryMutation.graphql";

const mutation = graphql`
  mutation RemoveMealLoggingEntryMutation(
    $input: RemoveMealLoggingEntryInput!
  ) {
    removeMealLoggingEntry(input: $input) {
      removeMealLoggingEntryId
    }
  }
`;

export const useRemoveMealLoggingEntryMutation = () => {
  const snackAlert = useSnackAlert();
  const user = useCurrentUser();

  return useConfiguredMutation<RemoveMealLoggingEntryMutation>(
    mutation,
    () => ({
      onSuccess: () =>
        snackAlert({
          severity: "success",
          message: "Meal removed",
        }),

      configs: [
        {
          type: "RANGE_DELETE",
          parentID: user.id,
          connectionKeys: [
            {
              key: "ClientMealLoggingEntriesList_mealLoggingEntries",
            },
          ],
          pathToConnection: [user.id, "members"],
          deletedIDFieldName: "removeMealLoggingEntryId",
        },
      ],
    }),
  );
};
