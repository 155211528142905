/**
 * @generated SignedSource<<7c2a3bbc2c389cbe47d1189a6c5bee9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientResponses_root$data = {
  readonly client: {
    readonly createdAt: string | null | undefined;
    readonly displayName: string | null | undefined;
    readonly responsesSummaries: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ClientResponsesSummaryCard_summary">;
    } | null | undefined> | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ClientResponsesActivities_root">;
  readonly " $fragmentType": "ClientResponses_root";
};
export type ClientResponses_root$key = {
  readonly " $data"?: ClientResponses_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientResponses_root">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "period"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "username"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientResponses_root",
  "selections": [
    {
      "alias": "client",
      "args": (v0/*: any*/),
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "period",
              "variableName": "period"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "responsesSummaries",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ClientResponsesSummaryCard_summary"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ClientResponsesActivities_root"
    }
  ],
  "type": "Root",
  "abstractKey": null
};
})();

(node as any).hash = "8158a6fd6f4ac6812f1cd4dd0a9b3586";

export default node;
