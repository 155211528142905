/**
 * @generated SignedSource<<09b34d966bf48761dd5b3b3351fe8e71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertClientNutritionEntryInput = {
  calories?: number | null | undefined;
  carbsGrams?: number | null | undefined;
  clientMutationId?: string | null | undefined;
  date: string;
  fatGrams?: number | null | undefined;
  id?: string | null | undefined;
  proteinGrams?: number | null | undefined;
  targetId: string;
};
export type ClientNutritionTargetListItemMutation$variables = {
  input: UpsertClientNutritionEntryInput;
};
export type ClientNutritionTargetListItemMutation$data = {
  readonly upsertClientNutritionEntry: {
    readonly clientNutritionEntry: {
      readonly carbsGrams: number | null | undefined;
      readonly id: string;
      readonly target: {
        readonly id: string;
      };
    } | null | undefined;
  } | null | undefined;
};
export type ClientNutritionTargetListItemMutation = {
  response: ClientNutritionTargetListItemMutation$data;
  variables: ClientNutritionTargetListItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertClientNutritionEntryPayload",
    "kind": "LinkedField",
    "name": "upsertClientNutritionEntry",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientNutritionEntry",
        "kind": "LinkedField",
        "name": "clientNutritionEntry",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "carbsGrams",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "target",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientNutritionTargetListItemMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientNutritionTargetListItemMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "168407bdb114b13e336f8af9d9e4d7ab",
    "id": null,
    "metadata": {},
    "name": "ClientNutritionTargetListItemMutation",
    "operationKind": "mutation",
    "text": "mutation ClientNutritionTargetListItemMutation(\n  $input: UpsertClientNutritionEntryInput!\n) {\n  upsertClientNutritionEntry(input: $input) {\n    clientNutritionEntry {\n      id\n      carbsGrams\n      target {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc664221fbd6ecc1cbadb0f785a646ed";

export default node;
