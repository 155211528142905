import React from "react";
import { AppLayout } from "../../../components/app/AppLayout";
import { ChangePasswordScreen } from "../../../components/auth/ChangePasswordScreen";
import { useSearchParams } from "react-router-dom";
import { LoginScreen } from "../../../components/auth/LoginScreen";

const LoginRoute = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const email = searchParams.get("email");
  const returnUrl = searchParams.get("returnUrl");

  const resetMode = mode == "resetPassword" && oobCode && email;

  return (
    <AppLayout appBar={false} title={resetMode ? "Change Password" : "Log In"}>
      {resetMode ? (
        <ChangePasswordScreen />
      ) : (
        <LoginScreen returnUrl={returnUrl} />
      )}
    </AppLayout>
  );
};

export default LoginRoute;
