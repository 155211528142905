import clsx from "clsx";
import React, { HTMLProps } from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    position: "relative",
    fontStyle: "normal",
  },
  text: {
    position: "relative",
    zIndex: 2,
  },
  underline: {
    width: "85%",
    height: "30%",
    background: theme.palette.primary.main,
    opacity: 0.2,
    position: "absolute",
    zIndex: 1,
    left: "-2%",
    bottom: "5%",
  },
}));

function Em(props: HTMLProps<HTMLSpanElement>) {
  const { children, className, ...other } = props;
  const s = useStyles();

  return (
    <em className={clsx(s.root, className)} {...other}>
      <span className={s.underline} />
      <span className={s.text}>{children}</span>
    </em>
  );
}

export default Em;
