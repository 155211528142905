import clsx from "clsx";
import React from "react";
import { List, Typography, ListItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ComponentType } from "../../constants";
import { getContentType } from "../../utils/component";

import { ActivityList, ActivityListProps } from "./ActivityList";
import { ActivityByTypesList_activities$key } from "./__generated__/ActivityByTypesList_activities.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 0),
    padding: 0,
  },
  item: {
    display: "block",
    padding: 0,
    margin: theme.spacing(0, 0, 5, 0),
  },
  header: {
    fontSize: 16,
    margin: theme.spacing(0, 0, 2, 0),
    textTransform: "uppercase",
  },
  habitText: {
    float: "right",
    color: theme.palette.text.secondary,
  },
  compact: {
    "& $header": {
      color: theme.palette.primary.main,
    },
  },
}));

const fragment = graphql`
  fragment ActivityByTypesList_activities on Activity
  @relay(plural: true)
  @argumentDefinitions(compact: { type: "Boolean!", defaultValue: false }) {
    ...ActivityList_activities @arguments(compact: $compact)
    component {
      type
    }
  }
`;

const componentTypes = [
  ComponentType.LESSON,
  ComponentType.HABIT,
  ComponentType.CHECKIN,
  ComponentType.WORKOUT,
];

export interface ActivityByTypesListProps
  extends Omit<ActivityListProps, "activitiesRef"> {
  activitiesRef: ActivityByTypesList_activities$key;
  userName?: string;
  clientsScreen?: boolean;
  idProgram?: string;
  week?: any;
}

export function ActivityByTypesList(props: ActivityByTypesListProps) {
  const {
    className,
    activitiesRef,
    variant,
    userName,
    clientsScreen,
    idProgram,
    week,
    ...other
  } = props;
  const s = useStyles();
  const activities = useFragment(fragment, activitiesRef);
  const types = componentTypes.filter((componentType) =>
    activities.some(({ component }) => component.type === componentType),
  );

  return (
    <List
      className={clsx(s.root, className, {
        [s.compact]: variant === "compact",
      })}
      {...other}
    >
      {types.map((componentType) => (
        <ListItem key={componentType} className={s.item}>
          {componentType === ComponentType.HABIT && variant !== "compact" && (
            <Typography variant="body2" className={s.habitText}>
              Did you complete this today?
            </Typography>
          )}
          <Typography variant="h6" className={s.header}>
            {getContentType(componentType)}s
          </Typography>
          <ActivityList
            activitiesRef={activities.filter(
              ({ component }) => component.type === componentType,
            )}
            userName={userName}
            variant={variant}
            clientsScreen={clientsScreen}
            idProgram={idProgram}
            week={week}
          />
        </ListItem>
      ))}
    </List>
  );
}
