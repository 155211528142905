import { UserRole } from "../../constants";
import { COACH_ROUTE, DEFAULT_ROUTE } from "../../routes/routes";

export const isDrawerItemSelected = (navPath, mode) => {
  const [base, splitValue] =
    mode === UserRole.CLIENT ? [DEFAULT_ROUTE, 0] : [COACH_ROUTE, 1];

  const getBasePath = (path) => path.replace(base, "").split("/")[splitValue];
  return getBasePath(location.pathname) === getBasePath(navPath);
};
