import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

export interface UpdateLocationProps {
  pathname: string;
}

export function UpdateLocation({ pathname }: UpdateLocationProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const url =
    pathname !== location.pathname ? pathname + location.search : null;

  React.useEffect(() => {
    if (url) {
      navigate(url, { replace: true });
    }
  }, [history, location.state, url]);

  return null;
}
