/**
 * @generated SignedSource<<fcb5ed865bc74f9fcbf4f4059f739e8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientDetailsQuery$variables = {
  username: string;
};
export type ClientDetailsQuery$data = {
  readonly targets: {
    readonly autoNutrition: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_autoNutrition">;
    } | null | undefined;
    readonly autoNutritionLastSync: string | null | undefined;
    readonly autoNutritionMacroTarget: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_autoNutritionMacroTarget">;
    } | null | undefined> | null | undefined;
    readonly nutritionMacroTarget: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionMacroTarget">;
    } | null | undefined> | null | undefined;
    readonly nutritionPortionTarget: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionPortionTarget">;
    } | null | undefined> | null | undefined;
    readonly nutritionTarget: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionTarget">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type ClientDetailsQuery = {
  response: ClientDetailsQuery$data;
  variables: ClientDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "h:mm A, MMM DD, YYYY"
    }
  ],
  "kind": "ScalarField",
  "name": "autoNutritionLastSync",
  "storageKey": "autoNutritionLastSync(format:\"h:mm A, MMM DD, YYYY\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trackingType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carbsGrams",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proteinGrams",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fatGrams",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calories",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(raw:true)"
},
v12 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCalories",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentFat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCarbs",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentProtein",
    "storageKey": null
  },
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientNutritionEntry",
    "kind": "LinkedField",
    "name": "clientNutritionEntry",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientDetailsQuery",
    "selections": [
      {
        "alias": "targets",
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionTarget",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientNutritionCard_nutritionTarget"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionMacroTarget",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientNutritionCard_nutritionMacroTarget"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionPortionTarget",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientNutritionCard_nutritionPortionTarget"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoNutrition",
            "kind": "LinkedField",
            "name": "autoNutrition",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientNutritionCard_autoNutrition"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": "autoNutritionMacroTarget",
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionMacroTarget",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientNutritionCard_autoNutritionMacroTarget"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientDetailsQuery",
    "selections": [
      {
        "alias": "targets",
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionTarget",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMM DD, YYYY"
                  }
                ],
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionMacroTarget",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionPortionTarget",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoNutrition",
            "kind": "LinkedField",
            "name": "autoNutrition",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "carbs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "protein",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": "autoNutritionMacroTarget",
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionMacroTarget",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5485d2df89e1aa14cfdcf3a6ccd288c3",
    "id": null,
    "metadata": {},
    "name": "ClientDetailsQuery",
    "operationKind": "query",
    "text": "query ClientDetailsQuery(\n  $username: String!\n) {\n  targets: user(username: $username) {\n    nutritionTarget {\n      ...ClientNutritionCard_nutritionTarget\n      id\n    }\n    nutritionMacroTarget {\n      ...ClientNutritionCard_nutritionMacroTarget\n      id\n    }\n    nutritionPortionTarget {\n      ...ClientNutritionCard_nutritionPortionTarget\n      id\n    }\n    autoNutrition {\n      id\n      ...ClientNutritionCard_autoNutrition\n    }\n    autoNutritionLastSync(format: \"h:mm A, MMM DD, YYYY\")\n    autoNutritionMacroTarget: nutritionMacroTarget {\n      ...ClientNutritionCard_autoNutritionMacroTarget\n      id\n    }\n    id\n  }\n}\n\nfragment ClientNutritionCard_autoNutrition on AutoNutrition {\n  calories\n  carbs\n  fat\n  protein\n}\n\nfragment ClientNutritionCard_autoNutritionMacroTarget on ClientNutritionTarget {\n  id\n  targetType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n}\n\nfragment ClientNutritionCard_nutritionMacroTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  currentCalories\n  currentFat\n  currentCarbs\n  currentProtein\n  date(raw: true)\n  clientNutritionEntry {\n    id\n    carbsGrams\n    proteinGrams\n    fatGrams\n    calories\n    date\n  }\n}\n\nfragment ClientNutritionCard_nutritionPortionTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  currentCalories\n  currentFat\n  currentCarbs\n  currentProtein\n  date(raw: true)\n  clientNutritionEntry {\n    id\n    carbsGrams\n    proteinGrams\n    fatGrams\n    calories\n    date\n  }\n}\n\nfragment ClientNutritionCard_nutritionTarget on ClientNutritionTarget {\n  id\n  trackingType\n  targetType\n  commentType\n  carbsGrams\n  proteinGrams\n  fatGrams\n  calories\n  date(raw: true)\n  updatedAt(format: \"MMM DD, YYYY\")\n}\n"
  }
};
})();

(node as any).hash = "c0ad5b2e4ca3abf80870bff1e7ae9c6d";

export default node;
