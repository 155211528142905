/**
 * @generated SignedSource<<a56e701dcf6975cae0a4e369f1ef5a5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomAssetType = "AUDIO" | "CLIENT_FORM" | "COMPONENT_TEMPLATE" | "FILE" | "IMAGE" | "OTHER" | "VIDEO" | "WORKOUT_EXERCISE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ComponentLibraryAsset_asset$data = {
  readonly assetType: CustomAssetType;
  readonly content: string;
  readonly id: string;
  readonly " $fragmentType": "ComponentLibraryAsset_asset";
};
export type ComponentLibraryAsset_asset$key = {
  readonly " $data"?: ComponentLibraryAsset_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentLibraryAsset_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ComponentLibraryAsset_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "type": "CustomAsset",
  "abstractKey": null
};

(node as any).hash = "e3be17ffb2c389a0e767553ddf5da1e7";

export default node;
