import React from "react";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { ComponentIcons } from "../../program/icons";

import { ComponentStatus, ComponentType } from "../../../constants";
import dayjs from "dayjs";
import { getLockIcon, getStatusIcon } from "../../../utils/icons";
import WorkoutOverview from "../WorkoutOverview";
import { ProgramCalendarSidebarQuery$data } from "../__generated__/ProgramCalendarSidebarQuery.graphql";
import { Pen } from "lucide-react";

interface IGenericComponentContainerProps {
  componentData: ProgramCalendarSidebarQuery$data;
  handleClickOpenDialog: () => void;
}

const SidebarDetailsTab = (props: IGenericComponentContainerProps) => {
  const theme = useTheme();
  const { componentData, handleClickOpenDialog } = props;

  const { type, iconName } = componentData.component;
  const Icon = ComponentIcons[iconName];
  const locked = componentData.component.locked;

  const getMainComponent = () => {
    switch (type) {
      case ComponentType.WORKOUT:
        return <WorkoutOverview result={componentData} />;
      case ComponentType.LESSON:
      case ComponentType.HABIT:
      case ComponentType.CHECKIN:
      case ComponentType.MESSAGE:
        return <></>;

      default:
        break;
    }
  };

  const getStatusIconText = (status: ComponentStatus) => {
    switch (status) {
      case ComponentStatus.ARCHIVED:
        return "Archived";
      case ComponentStatus.DRAFT:
        return "Draft";

      default:
        return "";
    }
  };

  const componentStatus = componentData.component.status as ComponentStatus;

  return (
    <>
      <Box
        sx={{
          p: 2,
          pt: 0,
          overflow: "clip",
          display: "flex",
          flexDirection: "column",
          minHeight: 500,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{
            display: "flex",
            justifyContent: " center",
            alignItems: "center",
            gap: 1,
            p: 1.5,
            marginBlock: 1,
            borderRadius: 2,
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleClickOpenDialog}
        >
          <Box
            sx={{
              "& svg": {
                width: 25,
                height: 25,
              },
            }}
          >
            <Icon />
          </Box>
          <Typography fontWeight={"bold"} textAlign={"center"}>
            {componentData.component.title}
          </Typography>
        </Box>

        <Button
          fullWidth
          variant="contained"
          startIcon={<Pen />}
          sx={{ fontWeight: 600 }}
          onClick={handleClickOpenDialog}
        >
          Edit
        </Button>

        <Box sx={{ marginBottom: 2 }} />
        {getMainComponent()}

        <Box display={"flex"} flexDirection={"column"} gap={1} marginTop="auto">
          <Divider sx={{ marginBlock: 2, mb: 1 }} />
          <Typography fontWeight={500}>
            Last update:{" "}
            <i>{dayjs(componentData.component.updatedAt).fromNow()}</i>
          </Typography>
          <Divider sx={{ marginBlock: 2, mt: 1 }} />
          <Box
            display={"flex"}
            gap={1}
            flexDirection={"column"}
            sx={{
              "& svg": {
                width: 20,
                height: 20,
              },
            }}
          >
            <Box display={"flex"} gap={1}>
              {getStatusIcon(componentStatus)}
              {getStatusIconText(componentStatus)}
            </Box>

            <Box display={"flex"} gap={1}>
              {locked && getLockIcon(locked)}
              {locked && "Locked"}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarDetailsTab;
