import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { Node } from "slate";
import { unfixLegacyContent } from "./utils/common";

interface JsonEditorProps {
  content: Node[] | null;
  onContentChange: (content: Node[] | null) => void;
}

const JsonEditor: React.FC<JsonEditorProps> = ({
  content,
  onContentChange,
}) => {
  const [textFieldContent, setTextFieldContent] = useState<string>(
    JSON.stringify(content, null, 2),
  );

  // alphabetically order keys of JSON.
  const sortObjectKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(sortObjectKeys);
    } else if (typeof obj === "object" && obj !== null) {
      return Object.keys(obj)
        .sort()
        .reduce((sortedObj, key) => {
          sortedObj[key] = sortObjectKeys(obj[key]);
          return sortedObj;
        }, {});
    } else {
      return obj;
    }
  };

  const formatJSON = (contentString: string) => {
    const content = JSON.parse(contentString);
    const sortedContent = content.map(sortObjectKeys);
    return JSON.stringify(sortedContent, null, 2);
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextFieldContent(e.target.value);
  };

  const handleSaveButtonClick = () => {
    try {
      const newContent = JSON.parse(textFieldContent);
      onContentChange(newContent);
    } catch (error) {
      console.error("Invalid JSON content", error);
    }
  };

  const handleRefreshButtonClick = () => {
    if (content) {
      setTextFieldContent(formatJSON(JSON.stringify(content, null, 2)));
    }
  };

  const handleUnfixButtonClick = () => {
    if (content) {
      setTextFieldContent(
        formatJSON(JSON.stringify(unfixLegacyContent(content), null, 2)),
      );
    }
  };

  const handleFormatButtonClick = () => {
    try {
      setTextFieldContent(formatJSON(textFieldContent));
    } catch (error) {
      console.error("Invalid JSON content", error);
    }
  };

  return (
    <Box pt={2}>
      <Typography variant="h3">JSON editor (only for development)</Typography>
      <Box py={1}>
        <Button
          variant="contained"
          onClick={handleRefreshButtonClick}
          sx={{ mr: 1 }}
        >
          Refresh
        </Button>
        <Button onClick={handleFormatButtonClick}>Format</Button>
        <Button onClick={handleUnfixButtonClick}>Unfix</Button>
        <Button onClick={handleSaveButtonClick}>Save</Button>
      </Box>
      <TextField
        fullWidth
        multiline
        value={textFieldContent}
        rows={30}
        onChange={handleTextFieldChange}
        sx={{
          "& textarea": {
            fontSize: 9,
            lineHeight: 1.25,
            fontFamily: "monospace",
          },
        }}
      />
    </Box>
  );
};

export default JsonEditor;
