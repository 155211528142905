/**
 * @generated SignedSource<<2f213a41f173985242f7f07b3d7e7e3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientCalendarEnrollment_enrollment$data = {
  readonly program: {
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ClientCalendarProgramCard_enrollment">;
  readonly " $fragmentType": "CoachClientCalendarEnrollment_enrollment";
};
export type CoachClientCalendarEnrollment_enrollment$key = {
  readonly " $data"?: CoachClientCalendarEnrollment_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientCalendarEnrollment_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientCalendarEnrollment_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientCalendarProgramCard_enrollment"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "e5ab8a1ef3a19ecb545e95110e6cda1e";

export default node;
