import clsx from "clsx";
import React, { Suspense, useContext } from "react";
import { Container, ContainerProps, Portal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { Calendar } from "../activity/Calendar";
import { ActivityByTypesList } from "../activity/ActivityByTypesList";
import { HomePageSkeleton } from "../loading/HomePageSkeleton";
import { useCurrentWorkspace } from "../../hooks/useCurrentWorkspace";
import { defaultHomeScreenMessage } from "../../constants";
import { useRequestPushNotifications } from "../../utils/device";

import { ClientHomeScreen_enrollments$key } from "./__generated__/ClientHomeScreen_enrollments.graphql";
import { ClientHomeScreen_activities$key } from "./__generated__/ClientHomeScreen_activities.graphql";
import { InfoBox } from "../info/InfoBox";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE } from "../../routes/routes";
import HomeCalendarContext from "../../contexts/HomeCalendarContext";
import TrackInfoTool from "../tools/TrackInfoTool";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    marginTop: theme.spacing(4),
    position: "relative",
  },
  calendar: {
    marginBottom: theme.spacing(-3),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0),
    },
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  sectionHeader: {
    display: "flex",
    alignItems: "bottom",
    justifyContent: "space-between",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  habitQuestion: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  none: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },
}));

const activitiesFragment = graphql`
  fragment ClientHomeScreen_activities on Activity @relay(plural: true) {
    ...ActivityByTypesList_activities
  }
`;

const enrollmentsFragment = graphql`
  fragment ClientHomeScreen_enrollments on EnrollmentConnection {
    totalCount
    ...Calendar_enrollments
  }
`;

export interface ClientHomeScreenProps
  extends Omit<ContainerProps, "children"> {
  date?: string;
  activities: ClientHomeScreen_activities$key;
  enrollments: ClientHomeScreen_enrollments$key;
}

export function ClientHomeScreen(props: ClientHomeScreenProps) {
  useRequestPushNotifications();
  const navigate = useNavigate();
  const {
    className,
    date,
    activities: activitiesRef,
    enrollments: enrollmentsRef,
    ...other
  } = props;
  const activities = useFragment(activitiesFragment, activitiesRef);
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const s = useStyles();
  const workspace = useCurrentWorkspace();
  const handleChange = React.useCallback((_, date) => {
    navigate(CLIENT_HOME_ROUTE.replace(":date", date));
  }, []);
  const loading = !enrollments;
  const showCalendar = !enrollments || enrollments?.totalCount > 0;

  const context = useContext(HomeCalendarContext);

  return (
    <>
      <Container className={clsx(s.root, className)} {...other}>
        <Portal container={() => context.current!}>
          {showCalendar && (
            <Calendar
              className={s.calendar}
              date={date}
              enrollmentsRef={enrollments}
              onChange={handleChange}
              disabled={loading}
              disableTooltip
            />
          )}
        </Portal>

        {!enrollments && !activities ? (
          <HomePageSkeleton className={clsx(s.root, className)} />
        ) : activities?.length ? (
          <ActivityByTypesList activitiesRef={activities} variant="normal" />
        ) : (
          <InfoBox
            className={s.none}
            title={Boolean(enrollments.totalCount) && "Take the day off!"}
            subtitle={
              enrollments.totalCount
                ? "Your coach hasn't scheduled anything for this day."
                : "Your coach hasn't enrolled you in any program yet."
            }
          />
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Home",
        }}
      />
    </>
  );
}
