import React from "react";
import { RequiresUpgradeClientScreen } from "../../../components/screen/RequiresUpgradeClientScreen";
import { RequiresUpgradeReason } from "../../../constants";

const ClientSubscriptionCanceledRoute = () => {
  return (
    <>
      <RequiresUpgradeClientScreen
        reason={RequiresUpgradeReason.TRAIL_EXPIRED}
      />
    </>
  );
};

export default ClientSubscriptionCanceledRoute;
