import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  Button,
  ButtonProps,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import sharedGoals from "../../icons/shared-goals.svg";
import Goals from "../../icons/Goals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginTop: theme.spacing(6),
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(1),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
  },
}));

export interface ClientGoalsEmptyProps extends BoxProps {
  onClose: () => void;
  onCreateClick: ButtonProps["onClick"];
}

export function ClientGoalsEmpty(props: ClientGoalsEmptyProps) {
  const { className, onClose, onCreateClick, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Goals fill={theme.palette.primary.main}></Goals>
      <Typography className={s.title} children="A goal is like a north star" />
      <Typography
        className={s.subtitle}
        children="Provide accountability for your clients by adding attainable yet ambitious goals."
      />
      <Button
        className={s.button}
        variant="contained"
        children="Create a goal"
        onClick={onCreateClick}
      />
    </Box>
  );
}
