/**
 * @generated SignedSource<<437bd12f4b3d93febd07db36ea99d88a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useExerciseLibrary_rootRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseAutocomplete_rootRef" | "ExerciseLibraryExercisesList_rootRef">;
  readonly " $fragmentType": "useExerciseLibrary_rootRef";
};
export type useExerciseLibrary_rootRef$key = {
  readonly " $data"?: useExerciseLibrary_rootRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"useExerciseLibrary_rootRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useExerciseLibrary_rootRef",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseLibraryExercisesList_rootRef"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseAutocomplete_rootRef"
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "00c65b90ea2d16cfb7a62199d4cf8d2b";

export default node;
