/**
 * @generated SignedSource<<6b4566a8eade2f2f298df70910b26ee6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type UpsertComponentInput = {
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  days?: ReadonlyArray<boolean> | null | undefined;
  draft?: string | null | undefined;
  duration?: number | null | undefined;
  habitPrompt?: string | null | undefined;
  iconName?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  locked?: boolean | null | undefined;
  messageContent?: string | null | undefined;
  messageTime?: string | null | undefined;
  reminderTime?: string | null | undefined;
  reminderType?: ReminderType | null | undefined;
  repeat?: ComponentRepeat | null | undefined;
  status?: ComponentStatus | null | undefined;
  title?: string | null | undefined;
  type?: ComponentType | null | undefined;
  weekId?: string | null | undefined;
};
export type SelectComponentIconsUpdateComponentIconMutation$variables = {
  input: UpsertComponentInput;
};
export type SelectComponentIconsUpdateComponentIconMutation$data = {
  readonly upsertComponent: {
    readonly component: {
      readonly " $fragmentSpreads": FragmentRefs<"SelectComponentIcons_component">;
    };
  } | null | undefined;
};
export type SelectComponentIconsUpdateComponentIconMutation = {
  response: SelectComponentIconsUpdateComponentIconMutation$data;
  variables: SelectComponentIconsUpdateComponentIconMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectComponentIconsUpdateComponentIconMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelectComponentIcons_component"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectComponentIconsUpdateComponentIconMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iconName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67fbb962bd1e5b551cc9da67d204b802",
    "id": null,
    "metadata": {},
    "name": "SelectComponentIconsUpdateComponentIconMutation",
    "operationKind": "mutation",
    "text": "mutation SelectComponentIconsUpdateComponentIconMutation(\n  $input: UpsertComponentInput!\n) {\n  upsertComponent(input: $input) {\n    component {\n      ...SelectComponentIcons_component\n      id\n    }\n  }\n}\n\nfragment SelectComponentIcons_component on Component {\n  id\n  iconName\n  type\n}\n"
  }
};
})();

(node as any).hash = "fa41fed8c7fcd619e907b2223dab0964";

export default node;
