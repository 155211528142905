import React from "react";
import { Node } from "slate";
import { autoReplaceInNodes } from "../new-editor/editor-configuration/plate-plugins/withAutoReplacer";
import { BaseEditor } from "./BaseEditor";
import { editorPlugins } from "./editor-configuration/plate-plugins/EditorPlugins";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { SimpleEditorFieldEditorSchema } from "../new-editor/editor-configuration/schemas/SimpleEditorFieldEditorSchema";
import { generateId } from "../new-editor/utils/nodeUtil";
import { isEmptyContent } from "../editor/utils/common";
import { BaseEditorDelayBuffer } from "../editor/components/utils/BaseEditorDelayBuffer";
import { clipTrailingNodes } from "../editor/normalizers/withSchema";

const defaultValue: any[] = [
  {
    type: ELEMENT_PARAGRAPH,
    children: [{ text: "" }],
    id: generateId(),
  },
];

export function EditorFieldEditor(props: any) {
  const {
    className,
    onChange,
    onBlur,
    onFocus,
    readOnly,
    editor,
    delay = 500,
    placeholder = "Share your thoughts, or ask a question.",
    topToolbar = false,
    multiline = true,
    compact = true,
    ...other
  } = props;
  const ref = React.useRef();

  const inputValue = React.useMemo(() => {
    return props.value ? JSON.parse(props.value) : defaultValue;
  }, [props.value]);
  const [value, setValue] = React.useState(inputValue);

  React.useEffect(() => {
    if (!readOnly && onChange && inputValue !== value) {
      const event: any = {
        target: {
          value: isEmptyContent(value) ? "" : JSON.stringify(value),
        },
      };
      onChange(event);
    }
  }, [value, inputValue, onChange, props, readOnly]);

  const handleChange = React.useCallback((value: Node[]) => {
    setValue(value);
  }, []);

  const Editor = delay && !readOnly ? BaseEditorDelayBuffer : BaseEditor;

  return (
    <div className={className} ref={ref}>
      <Editor
        value={
          readOnly ? clipTrailingNodes(autoReplaceInNodes(inputValue)) : value
        }
        onChange={handleChange}
        autoFocus={false}
        onFocus={onFocus as any}
        onBlur={onBlur as any}
        inlineToolbar
        editor={editor}
        topToolbar={topToolbar}
        multiline={multiline}
        placeholder={readOnly ? "" : placeholder}
        readOnly={readOnly}
        compact={compact}
        editorSchema={SimpleEditorFieldEditorSchema}
        platePlugins={editorPlugins}
      />
    </div>
  );
}

export default EditorFieldEditor;
