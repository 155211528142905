import React from "react";
import { useMutation, useRelayEnvironment } from "react-relay/hooks";
import {
  MutationParameters,
  GraphQLTaggedNode,
  Disposable,
  applyOptimisticMutation,
} from "relay-runtime";
import { UseMutationConfig } from "react-relay/hooks";
import { RelayModernEnvironment } from "relay-runtime/lib/store/RelayModernEnvironment";

import { uuidv4 } from "../../utils/uuid";
import { historyBlockDefaultMessage } from "../history-block/HistoryBlock";

export type DirtyTransactionMutation<
  T extends MutationParameters = any,
  C = UseMutationConfig<T>,
> = {
  config: C;
  commit: (config: C) => Disposable;
  mutation: GraphQLTaggedNode;
};

export type DirtyTransactionConfirmOptions = {
  onSuccess: () => void;
  onError: (error: any) => void;
};

export type DirtyTransactionConfig = {
  dirty: boolean;
  confirm: (options?: DirtyTransactionConfirmOptions) => void;
  cancel: () => void;
  append: (mutation: DirtyTransactionMutation) => void;
  undo: (steps?: number) => void;
  redo: (steps?: number) => void;
  canUndo: boolean;
  canRedo: boolean;
};

const applyMutation = (
  { config, mutation }: DirtyTransactionMutation,
  relay: RelayModernEnvironment,
) => applyOptimisticMutation(relay, { ...config, mutation });

const commitMutation = (
  dirtyMutation: DirtyTransactionMutation,
  beforeUpdater?: () => void,
) =>
  new Promise<void>((resolve, reject) =>
    dirtyMutation.commit(
      Object.assign({
        mutation: dirtyMutation.mutation,
        variables: dirtyMutation.config.variables,
        onCompleted(result: any, errors: any[]) {
          if (beforeUpdater) {
            beforeUpdater();
          }

          errors?.length ? reject(errors) : resolve();
        },
        onError: reject,
      }),
    ),
  );

export function useCreateDirtyTransaction(): DirtyTransactionConfig {
  const relay = useRelayEnvironment();
  const [mutations, setMutations] = React.useState<DirtyTransactionMutation[]>(
    [],
  );
  const [history, setHistory] = React.useState<Disposable[]>([]);
  const [index, setIndex] = React.useState(-1);
  const historySize = history.length;
  const bufferSize = mutations.length;
  const canUndo = historySize > 0;
  const canRedo = historySize < bufferSize;
  const dirty = canUndo;
  const currentIndex = historySize - 1;

  const cleanup = React.useCallback(() => {
    history.forEach((it) => it.dispose());
    setMutations([]);
    setHistory([]);
    setIndex(-1);
  }, [history]);

  const confirm = React.useCallback(
    async (options: DirtyTransactionConfirmOptions) => {
      try {
        const relevant = mutations.slice(0, currentIndex + 1);

        for (const mutation of relevant) {
          const disposable = history.shift();
          await commitMutation(mutation, () => disposable.dispose());
        }
      } catch (e) {
        console.error(e);
        if (options.onError) {
          options.onError(e);
        }
      }

      cleanup();

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
    [cleanup, currentIndex, history, mutations],
  );

  const undo = React.useCallback((steps = 1) => {
    setIndex((value) => Math.max(value - steps, -1));
  }, []);

  const redo = React.useCallback((steps = 1) => {
    setIndex((value) => value + steps);
  }, []);

  const append: DirtyTransactionConfig["append"] = React.useCallback(
    (mutation) => {
      setMutations((mutations) => [
        ...mutations.slice(0, currentIndex + 1),
        mutation,
      ]);
      redo();
    },
    [currentIndex, redo],
  );

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        event.preventDefault();

        if (event.shiftKey) {
          redo();
        } else {
          undo();
        }
      }
    },
    [redo, undo],
  );

  const goForward = React.useCallback(() => {
    const offset = currentIndex + 1;
    const delta = mutations.slice(offset, offset + index - currentIndex);

    if (delta.length) {
      const disposals = delta.map((mutation) => applyMutation(mutation, relay));

      setHistory(history.concat(disposals));
    }
  }, [currentIndex, history, index, mutations, relay]);

  const goBackwards = React.useCallback(() => {
    const disposals = history.slice(index + 1, history.length).reverse();

    disposals.forEach((it) => it.dispose());
    setHistory(history.slice(0, index + 1));
  }, [history, index]);

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    if (currentIndex < index) {
      goForward();
    }

    if (currentIndex > index) {
      goBackwards();
    }

    if (historySize && !bufferSize) {
      setHistory([]);
      setIndex(-1);
    }
  }, [bufferSize, currentIndex, goBackwards, goForward, historySize, index]);

  return {
    dirty,
    confirm,
    cancel: cleanup,
    append,
    undo,
    redo,
    canUndo,
    canRedo,
  };
}

export const DirtyTransactionContext =
  React.createContext<DirtyTransactionConfig>(null);

export function useDirtyTransaction() {
  return React.useContext(DirtyTransactionContext);
}

export function useDirtyMutation<T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
) {
  const transaction = React.useContext(DirtyTransactionContext);
  const [commit, pending] = useMutation<T>(mutation);
  const append = transaction?.append;

  const dirtyCommit = React.useCallback(
    (config: UseMutationConfig<T>) => {
      if (append) {
        append({
          commit,
          config,
          mutation,
        });
      }
    },
    [append, commit, mutation],
  );

  return [transaction ? dirtyCommit : commit, pending] as const;
}

export function useInterruptiveMutation<T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
) {
  const transaction = React.useContext(DirtyTransactionContext);
  const [commit, pending] = useMutation<T>(mutation);

  const interruptiveCommit = React.useCallback(
    async (config: UseMutationConfig<T>) => {
      if (transaction.dirty) {
        if (window.confirm(historyBlockDefaultMessage)) {
          try {
            await transaction.confirm();
          } catch (e) {
            console.error(e);
          }
        } else {
          return;
        }
      }

      commit(config);
    },
    [commit, transaction],
  );

  return [transaction ? interruptiveCommit : commit, pending] as const;
}

export const generateUniqueServerID = (typeName: string) =>
  btoa(`${typeName}:${uuidv4()}`).toString();
