import React from "react";
import { Button, ButtonProps } from "@mui/material";

import { Link } from "../link/Link";

export interface LinkButtonProps extends Omit<ButtonProps, "component"> {
  href: string;
  state?: object;
}

export function LinkButton(props: LinkButtonProps) {
  return <Button component={Link} underline="none" {...props} />;
}
