import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import {
  MealLoggingQuestionOptionsList,
  MealLoggingQuestionOptionsListProps,
} from "./MealLoggingQuestionOptionsList";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface MealLoggingQuestionMultipleChoiceListProps
  extends Omit<MealLoggingQuestionOptionsListProps, "type"> {}

export function MealLoggingQuestionMultipleChoiceList(
  props: MealLoggingQuestionMultipleChoiceListProps,
) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <MealLoggingQuestionOptionsList
      className={clsx(s.root, className)}
      type="radio"
      {...other}
    />
  );
}
