import React from "react";
import { BoxProps } from "@mui/material";
import { graphql, useFragment } from "react-relay";

import {
  GroupPostCommentsListItem,
  GroupPostCommentsListItemProps,
} from "./GroupPostCommentsListItem";
import { GroupPostCommentsListItemWithReplies_comment$key } from "./__generated__/GroupPostCommentsListItemWithReplies_comment.graphql";

const commentFragment = graphql`
  fragment GroupPostCommentsListItemWithReplies_comment on GroupPostComment {
    ...GroupPostCommentsListItem_comment @arguments(fetchReplies: true)
  }
`;

export interface GroupPostCommentsListItemWithRepliesProps
  extends BoxProps,
    Pick<GroupPostCommentsListItemProps, "first" | "onReplyClick"> {
  comment: GroupPostCommentsListItemWithReplies_comment$key;
}

export function GroupPostCommentsListItemWithReplies(
  props: GroupPostCommentsListItemWithRepliesProps,
) {
  const { className, comment: commentRef, ...other } = props;
  const comment = useFragment(commentFragment, commentRef);

  return <GroupPostCommentsListItem commentRef={comment} {...other} />;
}
