/**
 * @generated SignedSource<<8dc4306246e7add607c84915038b11e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertGroupInput = {
  clientMutationId?: string | null | undefined;
  description?: string | null | undefined;
  id?: string | null | undefined;
  imageURL?: string | null | undefined;
  membersCanMessage?: boolean | null | undefined;
  membersCanNotDiscover?: boolean | null | undefined;
  membersCanNotPost?: boolean | null | undefined;
  membersCanNotSeeSidebar?: boolean | null | undefined;
  name?: string | null | undefined;
};
export type UpsertGroupMutation$variables = {
  input: UpsertGroupInput;
};
export type UpsertGroupMutation$data = {
  readonly upsertGroup: {
    readonly group: {
      readonly " $fragmentSpreads": FragmentRefs<"GroupCard_group" | "GroupDescription_group" | "GroupSettingsCard_group" | "ManageGroupDialog_group">;
    };
  } | null | undefined;
};
export type UpsertGroupMutation = {
  response: UpsertGroupMutation$data;
  variables: UpsertGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertGroupPayload",
        "kind": "LinkedField",
        "name": "upsertGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GroupCard_group"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ManageGroupDialog_group"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GroupDescription_group"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GroupSettingsCard_group"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertGroupPayload",
        "kind": "LinkedField",
        "name": "upsertGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachId",
                "storageKey": null
              },
              {
                "alias": "cover",
                "args": null,
                "kind": "ScalarField",
                "name": "imageURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "membersCanNotPost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "membersCanNotDiscover",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "membersCanNotSeeSidebar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "membersCanMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InviteCode",
                "kind": "LinkedField",
                "name": "inviteCode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f2e57633a38c6666f4f99a96bd3ad2f",
    "id": null,
    "metadata": {},
    "name": "UpsertGroupMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertGroupMutation(\n  $input: UpsertGroupInput!\n) {\n  upsertGroup(input: $input) {\n    group {\n      ...GroupCard_group\n      ...ManageGroupDialog_group\n      ...GroupDescription_group\n      ...GroupSettingsCard_group\n      id\n    }\n  }\n}\n\nfragment GroupCard_group on Group {\n  ...ManageGroupDialog_group\n  id\n  name\n  slug\n  archived\n  clients {\n    ...StackedClientsAvatars_clients\n    id\n  }\n}\n\nfragment GroupDescriptionDialog_group on Group {\n  id\n  description\n}\n\nfragment GroupDescription_group on Group {\n  ...GroupDescriptionDialog_group\n  description\n  coachId\n  cover: imageURL\n}\n\nfragment GroupSettingsCard_group on Group {\n  id\n  name\n  membersCanNotPost\n  membersCanNotDiscover\n  membersCanNotSeeSidebar\n  membersCanMessage\n  imageURL\n  inviteCode {\n    ...InviteLinkSettings_inviteCode\n    ...InviteCodePreviewDialog_inviteCode\n    id\n  }\n}\n\nfragment InviteCodePreviewDialog_inviteCode on InviteCode {\n  code\n}\n\nfragment InviteLinkSettings_inviteCode on InviteCode {\n  id\n  code\n}\n\nfragment ManageGroupDialog_group on Group {\n  id\n  name\n  size\n  clients {\n    email\n    displayName\n    id\n  }\n}\n\nfragment StackedClientsAvatars_clients on User {\n  id\n  displayName\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "1d7ed40122322d2d40ee99bfb2eb1f0b";

export default node;
