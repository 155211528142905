import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { ClientHomeScreen } from "../../../components/screen/ClientHomeScreen";
import { getISODate } from "../../../utils/date";
import { useParams } from "react-router-dom";
import { ClientHomeRouteQuery } from "./__generated__/ClientHomeRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";

export function ClientHomeRoute() {
  const { date } = useParams();

  const props = useLazyLoadQuery<ClientHomeRouteQuery>(
    graphql`
      query ClientHomeRouteQuery($date: String!) {
        activities(client: "$me", date: $date) {
          ...ClientHomeScreen_activities
        }
        enrollments {
          ...ClientHomeScreen_enrollments
        }
      }
    `,
    {
      date: date || getISODate(),
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  return (
    <ClientHomeScreen
      date={date}
      activities={props?.activities || null}
      enrollments={props?.enrollments || null}
    />
  );
}
