import { IGif } from "@giphy/js-types";

import { GIPHY_API_KEY } from "../constants";

export interface IGifLight extends Pick<IGif, "id" | "title"> {}

export type GiphyOptions = {
  query?: string;
  limit: number;
  offset: number;
};

export const fetchGiphy = async ({ query, limit, offset }: GiphyOptions) => {
  const endpoint = query ? "search" : "trending";
  const baseUrl = `https://api.giphy.com/v1/gifs/${endpoint}?api_key=${GIPHY_API_KEY}`;
  const url =
    (query ? `${baseUrl}&q=${query}` : baseUrl) +
    `&limit=${limit}&offset=${offset}`;
  const response = await fetch(url);

  if (response && response.ok) {
    const parsed = await response.json();
    const mapped: IGifLight[] = (parsed.data as IGif[]).map(
      ({ id, title }) => ({ id, title }),
    );

    return mapped;
  }
};

export const giphyUrlById = (id: string | number) =>
  `https://i.giphy.com/${id}.gif`;
