import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { GroupPostsSidebar_group$key } from "./__generated__/GroupPostsSidebar_group.graphql";
import { GroupDescription } from "./GroupDescription";
import { GroupSidebarMembers } from "./GroupSidebarMembers";

const useStyles = makeStyles((theme) => ({
  root: {},

  description: {
    marginBottom: theme.spacing(5),
  },
}));

const fragment = graphql`
  fragment GroupPostsSidebar_group on Group {
    ...GroupDescription_group
    ...GroupSidebarMembers_group
  }
`;

export interface GroupPostsSidebarProps extends BoxProps {
  groupRef: GroupPostsSidebar_group$key;
}

export function GroupPostsSidebar(props: GroupPostsSidebarProps) {
  const { className, groupRef, ...other } = props;
  const s = useStyles();
  const group = useFragment(fragment, groupRef);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <GroupDescription className={s.description} groupRef={group} />
      <GroupSidebarMembers groupRef={group} />
    </Box>
  );
}
