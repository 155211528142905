import { Transforms, Editor, Node, Path, Point } from "slate";
import { ReactEditor } from "slate-react";

export const focusAtEnd = (editor: ReactEditor, rootPath: Path): boolean => {
  const children = Node.children(editor, rootPath, {
    reverse: true,
  });

  for (const [node, path] of children) {
    const element = node as any;

    if (editor.isVoid(element) || !element.children) {
      continue;
    }

    if (Editor.hasInlines(editor, element)) {
      const range = Editor.end(editor, path);

      Transforms.setSelection(editor, {
        anchor: range,
        focus: range,
      });
      ReactEditor.focus(editor);

      return true;
    } else {
      if (focusAtEnd(editor, path)) {
        return true;
      }
    }
  }

  return false;
};

export const getFocusPoint = (
  editor: ReactEditor,
  rootPath: Path = [editor.children.length - 1],
): Point => {
  const { selection } = editor;

  if (selection) {
    return selection.focus;
  } else {
    const [node, path] = Node.last(editor, rootPath);
    const offset = Node.string(node).length;

    return { path, offset };
  }
};
