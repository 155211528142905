import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldLabel } from "./FieldLabel";
import { FieldError } from "./FieldError";

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    padding: 0,
    marginBottom: theme.spacing(3),
  },

  inputs: {},
}));

export interface FieldsGroupProps extends BoxProps {
  label: string;
  error?: string;
}

export function FieldsGroup(props: FieldsGroupProps) {
  const { className, label, error, children, ...other } = props;
  const s = useStyles();

  return (
    <Box component="fieldset" className={clsx(s.root, className)} {...other}>
      <FieldLabel>{label}</FieldLabel>
      <Box className={s.inputs}>{children}</Box>
      {Boolean(error) && <FieldError>{error}</FieldError>}
    </Box>
  );
}
