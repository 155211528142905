import React, { useEffect, useState } from "react";
import { ProgramCalendarSidebarQuery$data } from "../__generated__/ProgramCalendarSidebarQuery.graphql";
import { ComponentSchedule } from "../../schedule/ComponentSchedule";
import {
  ComponentRepeat,
  ComponentType,
  ReminderType,
} from "../../../constants";
import { Schedule } from "../../schedule/types";
import { graphql } from "relay-runtime";
import { useMutation } from "react-relay";
import { SidebarScheduleTabSaveMutation } from "./__generated__/SidebarScheduleTabSaveMutation.graphql";
import { Box, debounce, LinearProgress } from "@mui/material";
import { ProgramAddComponentCallback } from "../../program/ProgramDetails";
import { useProgramWeeks } from "../../../hooks/useProgramWeeks";

const AUTO_SAVE_DELAY = 2000;

const saveComponentMutation = graphql`
  mutation SidebarScheduleTabSaveMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        ...RefreshSlug
        id
        weekId

        days
        duration
        repeat
        reminderType(draft: true)
        reminderTime(draft: true)
        messageTime(draft: true)

        updatedAt(utc: true)
      }
    }
  }
`;

interface ISidebarScheduleTabProps {
  componentData: ProgramCalendarSidebarQuery$data;
  componentTypeColor: string;
}

const SidebarScheduleTab = (props: ISidebarScheduleTabProps) => {
  const { componentData, componentTypeColor } = props;
  const { component } = componentData;

  const [isDirty, setIsDirty] = useState<undefined | boolean>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const toSchedule = ({
    component,
  }: ProgramCalendarSidebarQuery$data): Schedule => ({
    weekId: component.weekId,
    days: component.days as boolean[],
    duration: component.duration,
    repeat: component.repeat as ComponentRepeat,
    reminderType: component.reminderType as ReminderType,
    reminderTime: component.reminderTime,
    messageTime: component.messageTime,
  });

  const [schedule, setSchedule] = React.useState<Schedule>(
    toSchedule(componentData),
  );
  const programWeeks = useProgramWeeks();

  useEffect(() => {
    setSchedule(toSchedule(componentData));
  }, [componentData]);

  const handleScheduleChange = (schedule: Schedule) => {
    setIsDirty(true);
    setSchedule(schedule);
    handleSave(schedule);
  };

  const save = (schedule: Schedule) => {
    const input = {
      id: component.id,
      ...schedule,
    };

    setIsLoading(true);
    setIsDirty(undefined);
    saveComponent({
      variables: { input },
      onCompleted: () => {
        setIsLoading(false);
      },
    });
  };

  const handleSave = React.useMemo(() => debounce(save, AUTO_SAVE_DELAY), []);

  const [saveComponent] = useMutation<SidebarScheduleTabSaveMutation>(
    saveComponentMutation,
  );

  return (
    <>
      <Box sx={{ color: componentTypeColor }}>
        <LinearProgress
          color={"primary"}
          sx={{
            visibility:
              isLoading || isDirty !== undefined ? "visible" : "hidden",
          }}
        />
      </Box>
      <ComponentSchedule
        layout="sidebar"
        componentType={component.type as ComponentType}
        schedule={schedule}
        onChange={handleScheduleChange}
        panelOpen
        disableClickAwayListener
        programWeeks={programWeeks}
      />
    </>
  );
};

export default SidebarScheduleTab;
