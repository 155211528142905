/**
 * @generated SignedSource<<6dd7c5e259979fb18b98aa6af49db46b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachProgramCreateRouteQuery$variables = Record<PropertyKey, never>;
export type CoachProgramCreateRouteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProgramTemplatesScreen" | "UnsplashImages_rootRef">;
};
export type CoachProgramCreateRouteQuery = {
  response: CoachProgramCreateRouteQuery$data;
  variables: CoachProgramCreateRouteQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramCreateRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProgramTemplatesScreen"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UnsplashImages_rootRef"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoachProgramCreateRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramTemplateConnection",
        "kind": "LinkedField",
        "name": "programTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProgramTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": "programLength",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "length",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "used",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "280eda5059aa9174137724e147576f1c",
    "id": null,
    "metadata": {},
    "name": "CoachProgramCreateRouteQuery",
    "operationKind": "query",
    "text": "query CoachProgramCreateRouteQuery {\n  ...ProgramTemplatesScreen\n}\n\nfragment CardProgramTemplate_template on ProgramTemplate {\n  id\n  name\n  imageURL\n  description\n  programLength: length\n  used\n  category\n}\n\nfragment ProgramTemplatesList_connection on ProgramTemplateConnection {\n  edges {\n    node {\n      id\n      ...CardProgramTemplate_template\n    }\n  }\n}\n\nfragment ProgramTemplatesScreen on Root {\n  programTemplates {\n    totalCount\n    ...ProgramTemplatesList_connection\n  }\n}\n"
  }
};

(node as any).hash = "7a0e775d834abcb9a86273e250f46529";

export default node;
