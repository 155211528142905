import { createContext } from "react";
import { CoachGroupMembersRouteQuery$data } from "../routes/coach/group/members/__generated__/CoachGroupMembersRouteQuery.graphql";
import { CoachGroupPostsRouteQuery$data } from "../routes/coach/group/post/__generated__/CoachGroupPostsRouteQuery.graphql";
import { CoachGroupSettingsRouteQuery$data } from "../routes/coach/group/settings/__generated__/CoachGroupSettingsRouteQuery.graphql";

export type TGroupRoute =
  | CoachGroupMembersRouteQuery$data
  | CoachGroupSettingsRouteQuery$data
  | CoachGroupPostsRouteQuery$data;

export interface IGroupContextProps {
  groupRouteQuery: TGroupRoute;
  setGroupRouteQuery: (groupRouteQuery: TGroupRoute) => void;
}

const GroupContext = createContext<IGroupContextProps | undefined>(undefined);

export default GroupContext;
