import clsx from "clsx";
import React, { ReactNode } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },

  title: {
    marginBottom: theme.spacing(1),
  },

  description: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));

export interface EmptyResultsProps extends Omit<BoxProps, "title"> {
  title: ReactNode;
  description?: ReactNode;
}

export function EmptyResults(props: EmptyResultsProps) {
  const { className, title, description, children, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography variant="h6" className={s.title}>
        {title}
      </Typography>
      {description && (
        <Typography variant="body1" className={s.description}>
          {description}
        </Typography>
      )}
      {children}
    </Box>
  );
}
