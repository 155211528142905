/**
 * @generated SignedSource<<8fdf3a6ac4abfd9917ce0d3081d38228>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramEnrollments_enrollments$data = {
  readonly totalCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"EnrollmentList_enrollments">;
  readonly " $fragmentType": "ProgramEnrollments_enrollments";
};
export type ProgramEnrollments_enrollments$key = {
  readonly " $data"?: ProgramEnrollments_enrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramEnrollments_enrollments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramEnrollments_enrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EnrollmentList_enrollments"
    }
  ],
  "type": "EnrollmentConnection",
  "abstractKey": null
};

(node as any).hash = "1e2383a35600a073230cefddca00c753";

export default node;
