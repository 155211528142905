/**
 * @generated SignedSource<<129e1c1529ee49ec8d9c03f23199d3a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAssetType = "AUDIO" | "CLIENT_FORM" | "COMPONENT_TEMPLATE" | "FILE" | "IMAGE" | "OTHER" | "VIDEO" | "WORKOUT_EXERCISE" | "%future added value";
export type ComponentLibraryAssetListRefetchQuery$variables = {
  after?: string | null | undefined;
  assetType?: ReadonlyArray<CustomAssetType> | null | undefined;
  first?: number | null | undefined;
  query?: string | null | undefined;
};
export type ComponentLibraryAssetListRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ComponentLibraryAssetList_rootRef">;
};
export type ComponentLibraryAssetListRefetchQuery = {
  response: ComponentLibraryAssetListRefetchQuery$data;
  variables: ComponentLibraryAssetListRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetType"
  },
  {
    "defaultValue": 0,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "assetType",
    "variableName": "assetType"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ComponentLibraryAssetListRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ComponentLibraryAssetList_rootRef"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ComponentLibraryAssetListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomAssetsConnection",
        "kind": "LinkedField",
        "name": "custom_assets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomAssetsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomAsset",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ComponentLibraryAssetList_custom_assets",
        "kind": "LinkedHandle",
        "name": "custom_assets"
      }
    ]
  },
  "params": {
    "cacheID": "5769d71679268196ea94737e9cbda709",
    "id": null,
    "metadata": {},
    "name": "ComponentLibraryAssetListRefetchQuery",
    "operationKind": "query",
    "text": "query ComponentLibraryAssetListRefetchQuery(\n  $after: String\n  $assetType: [CustomAssetType!]\n  $first: Int = 0\n  $query: String\n) {\n  ...ComponentLibraryAssetList_rootRef_1HOiMB\n}\n\nfragment ComponentLibraryAssetList_rootRef_1HOiMB on Root {\n  custom_assets(first: $first, after: $after, query: $query, assetType: $assetType) {\n    edges {\n      node {\n        id\n        ...ComponentLibraryAsset_asset\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ComponentLibraryAsset_asset on CustomAsset {\n  id\n  assetType\n  content\n}\n"
  }
};
})();

(node as any).hash = "ab0acb9f1dd5680cc2b12b258670ecb0";

export default node;
