import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { ClientProfileScreen } from "../../../../components/screen/ClientProfileScreen";
import { ClientProfileRouteQuery } from "./__generated__/ClientProfileRouteQuery.graphql";

export function ClientProfileRoute() {
  const props = useLazyLoadQuery<ClientProfileRouteQuery>(
    graphql`
      query ClientProfileRouteQuery {
        me {
          ...ClientProfileScreen_client
        }
      }
    `,
    {},
  );

  const { me } = props;

  return me && <ClientProfileScreen clientRef={me} />;
}
