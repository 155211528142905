/**
 * @generated SignedSource<<e15a178e9a8afbcfccfefe5d1626cf4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "CLIENT" | "COACH" | "%future added value";
export type CoachGroupMembersRouteQuery$variables = {
  slug: string;
};
export type CoachGroupMembersRouteQuery$data = {
  readonly group: {
    readonly coachId: string;
    readonly id: string;
    readonly membersCanNotPost: boolean;
    readonly membersCount: number;
    readonly name: string;
    readonly slug: string;
  } | null | undefined;
  readonly me: {
    readonly id: string;
    readonly role: UserRole;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GroupManageMembersButton_root" | "GroupMembersScreen_rootRef">;
};
export type CoachGroupMembersRouteQuery = {
  response: CoachGroupMembersRouteQuery$data;
  variables: CoachGroupMembersRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membersCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membersCanNotPost",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coachId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachGroupMembersRouteQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "GroupMembersScreen_rootRef"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "GroupManageMembersButton_root"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachGroupMembersRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "GroupMemberConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GroupMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "members(first:10)"
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "GroupMembersList_members",
            "kind": "LinkedHandle",
            "name": "members"
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "clients",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slugId",
                "storageKey": null
              }
            ],
            "type": "Slug",
            "abstractKey": "__isSlug"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 9999
          },
          {
            "kind": "Literal",
            "name": "status",
            "value": "ACTIVE"
          }
        ],
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "clients(first:9999,status:\"ACTIVE\")"
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "faa504088281dc1f104d90ced41575e6",
    "id": null,
    "metadata": {},
    "name": "CoachGroupMembersRouteQuery",
    "operationKind": "query",
    "text": "query CoachGroupMembersRouteQuery(\n  $slug: String!\n) {\n  ...GroupMembersScreen_rootRef_20J5Pl\n  ...GroupManageMembersButton_root_20J5Pl\n  group(slug: $slug) {\n    id\n    name\n    slug\n    membersCount\n    membersCanNotPost\n    coachId\n  }\n  me {\n    id\n    role\n  }\n}\n\nfragment Avatar_user on User {\n  displayName\n  photoURL\n}\n\nfragment ClientAddableItem_client on User {\n  id\n  email\n  displayName\n  photoURL\n}\n\nfragment GroupManageMembersButton_root_20J5Pl on Root {\n  group(slug: $slug) {\n    ...ManageGroupDialog_group\n    id\n  }\n  clients(status: ACTIVE, first: 9999) {\n    ...ManageGroupDialog_clients\n  }\n}\n\nfragment GroupMemberListItem_member on User {\n  ...Avatar_user\n  id\n  email\n  username\n  displayName\n}\n\nfragment GroupMembersList_groupRef on Group {\n  id\n  members(first: 10) {\n    edges {\n      node {\n        id\n        ...GroupMemberListItem_member\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment GroupMembersScreen_rootRef_20J5Pl on Root {\n  group(slug: $slug) {\n    ...RefreshSlug\n    ...GroupMembersList_groupRef\n    id\n    name\n  }\n}\n\nfragment ManageGroupDialog_clients on ClientConnection {\n  edges {\n    node {\n      id\n      email\n      displayName\n      ...ClientAddableItem_client\n    }\n  }\n}\n\nfragment ManageGroupDialog_group on Group {\n  id\n  name\n  size\n  clients {\n    email\n    displayName\n    id\n  }\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n"
  }
};
})();

(node as any).hash = "ad4397af8338d9c8fc20352bb5668468";

export default node;
