import clsx from "clsx";
import React from "react";
import { graphql, useFragment } from "react-relay";
import makeStyles from "@mui/styles/makeStyles";
import {
  Card,
  CardProps,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  CardActions,
} from "@mui/material";
import { usePopupState, bindMenu } from "material-ui-popup-state/hooks";

import { ReactComponent as MoreIcon } from "../../icons/MoreDots.svg";

import { Link } from "../link/Link";
import { maybePluralize } from "../../utils/text";
import { StackedClientsAvatars } from "../coach-clients/StackedClientsAvatars";

import { CoachProgramCard_program$key } from "./__generated__/CoachProgramCard_program.graphql";
import { CoachProgramMenu } from "./CoachProgramMenu";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    boxShadow: theme.shadows[7],
    "&$list": {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
      },
    },

    "&$grid": {
      marginBottom: 0,
    },
  },
  grid: {},
  list: {},
  mediaArea: {
    width: "auto",

    "$list &": {
      [theme.breakpoints.up("sm")]: {
        height: theme.spacing(15),
      },
    },
  },

  media: {
    width: "100%",
    height: theme.spacing(27.5),

    "$list &": {
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(21.5),
        height: "100%",
      },
    },
  },

  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: "pointer",
    overflow: "hidden",

    "& $moreButton": {
      float: "right",
    },

    "$list & $moreButton": {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  },

  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
    fontWeight: 700,
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(1, 0),
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  info: {
    fontSize: 14,
    fontWeight: 500,

    "&>span:not(:first-child)::before": {
      content: '"•"',
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
    },
  },

  actions: {
    display: "none",

    "$list &": {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",

        "& $moreButton": {
          display: "block",
        },
      },
    },
  },

  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.text.secondary,
  },

  menuOpen: {},
  moreButton: {
    "$root:not(:hover) &:not($menuOpen)": {
      display: "none",
    },
    borderRadius: theme.spacing(0.5),
  },
}));

export enum CoachProgramCardDisplay {
  grid = "grid",
  list = "list",
}

const programFragment = graphql`
  fragment CoachProgramCard_program on Program {
    ...CoachProgramMenu_program

    id
    slug
    name
    description
    length
    status
    createdAt
    createdAtFromNow: createdAt(format: "fromNow")
    imageURL: imageURL
    enrolledTotal

    clients {
      ...StackedClientsAvatars_clients
    }
  }
`;

export interface CoachProgramCardProps extends CardProps {
  program: CoachProgramCard_program$key;
  display: CoachProgramCardDisplay;
  onFolderUpdated?: () => void;
}

export function CoachProgramCard(props: CoachProgramCardProps) {
  const navigate = useNavigate();
  const {
    className,
    program: programRef,
    display,
    onFolderUpdated,
    ...other
  } = props;
  const program = useFragment(programFragment, programRef);
  const { clients } = program;
  const s = useStyles();
  const link = COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", program.slug);
  const menuState = usePopupState({
    variant: "popover",
    popupId: "program-menu",
  });

  const handleClick = React.useCallback(() => {
    navigate(link);
  }, [link]);

  const handleMenuClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      menuState.toggle(event);
    },
    [menuState],
  );

  return (
    <>
      <Card className={clsx(s.root, className, s[display])} {...other}>
        <CardActionArea className={s.mediaArea} component={Link} href={link}>
          <CardMedia
            className={s.media}
            image={program.imageURL?.replace(
              "//stridist.com/img",
              "//s.stridist.com",
            )}
            title={program.name}
          />
        </CardActionArea>
        <CardContent className={s.content} onClick={handleClick}>
          <IconButton
            className={clsx(s.moreButton, menuState.isOpen && s.menuOpen)}
            onClick={handleMenuClick}
            size="large"
          >
            <MoreIcon />
          </IconButton>
          <Typography className={s.status} color="textSecondary">
            {program.status}
          </Typography>
          <Typography className={s.title} variant="h4">
            {program.name}
          </Typography>
          <Typography className={s.info} color="textSecondary">
            {display !== CoachProgramCardDisplay.grid && (
              <span>{maybePluralize(program.length, "week")}</span>
            )}
            <span>{program.enrolledTotal} enrolled</span>
          </Typography>
        </CardContent>

        <CardActions className={s.actions}>
          <StackedClientsAvatars
            classes={{ avatar: s.avatar }}
            clients={clients}
          />
          <IconButton
            className={clsx(s.moreButton, menuState.isOpen && s.menuOpen)}
            onClick={handleMenuClick}
            size="large"
          >
            <MoreIcon />
          </IconButton>
        </CardActions>
      </Card>
      {menuState.isOpen && (
        <CoachProgramMenu
          program={program}
          open
          {...bindMenu(menuState)}
          onFolderUpdated={onFolderUpdated}
        />
      )}
    </>
  );
}
