import clsx from "clsx";
import React from "react";
import {
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MealLoggingQuestionYesNo } from "../meal-logging-settings/types";

import { ClientMealLoggingQuestionProps } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ClientMealLoggingYesNoQuestionProps
  extends RadioGroupProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionYesNo> {}

export function ClientMealLoggingYesNoQuestion(
  props: ClientMealLoggingYesNoQuestionProps,
) {
  const s = useStyles();
  const { className, option, onUpdate, ...other } = props;
  const [answer, setAnswer] = React.useState<string>(option.answer || "");

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, answer: string) => {
      setAnswer(answer);

      if (onUpdate) {
        onUpdate({
          ...option,
          answer,
        });
      }
    },
    [onUpdate, option],
  );

  return (
    <RadioGroup
      className={clsx(s.root, className)}
      value={answer}
      onChange={handleChange}
      {...other}
    >
      <FormControlLabel
        value="yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
  );
}
