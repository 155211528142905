/**
 * @generated SignedSource<<7c8d9c3bbfa6338ec29aa556b7b8985b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NutritionCommentsType = "CALORIES_ONLY" | "FULL_MACROS" | "PROTEIN_CALORIES" | "PROTEIN_ONLY" | "TRACKING_ONLY" | "%future added value";
export type NutritionTargetType = "ALL_DAYS" | "OFF_DAYS" | "REFEED_DAYS" | "TRAINING_DAYS" | "WEEKDAYS" | "WEEKENDS" | "%future added value";
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientDetails_client$data = {
  readonly displayName: string | null | undefined;
  readonly enrollments: ReadonlyArray<{
    readonly active: boolean;
    readonly endDateRaw: string | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClientProgramProgressCard_enrollment" | "ProgressDialog_enrollments">;
  } | null | undefined>;
  readonly goals: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ClientGoalsCard_goals">;
  };
  readonly id: string;
  readonly nutritionMacroTarget: ReadonlyArray<{
    readonly calories: number | null | undefined;
    readonly carbsGrams: number | null | undefined;
    readonly commentType: NutritionCommentsType;
    readonly currentCalories: number | null | undefined;
    readonly currentCarbs: number | null | undefined;
    readonly currentFat: number | null | undefined;
    readonly currentProtein: number | null | undefined;
    readonly date: string | null | undefined;
    readonly fatGrams: number | null | undefined;
    readonly id: string;
    readonly proteinGrams: number | null | undefined;
    readonly targetType: NutritionTargetType;
    readonly trackingType: NutritionTrackingType;
  } | null | undefined> | null | undefined;
  readonly nutritionPortionTarget: ReadonlyArray<{
    readonly calories: number | null | undefined;
    readonly carbsGrams: number | null | undefined;
    readonly commentType: NutritionCommentsType;
    readonly currentCalories: number | null | undefined;
    readonly currentCarbs: number | null | undefined;
    readonly currentFat: number | null | undefined;
    readonly currentProtein: number | null | undefined;
    readonly date: string | null | undefined;
    readonly fatGrams: number | null | undefined;
    readonly id: string;
    readonly proteinGrams: number | null | undefined;
    readonly targetType: NutritionTargetType;
    readonly trackingType: NutritionTrackingType;
  } | null | undefined> | null | undefined;
  readonly nutritionTarget: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionTarget">;
  } | null | undefined> | null | undefined;
  readonly timeZone: string | null | undefined;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBodyWeightCard_client" | "ClientFormsCard_client" | "ClientMeasurementsCard_client" | "ClientProgressPhotosCard_client" | "ClientSettingsDialog_client" | "ClientStepsCard_client" | "ClientSummary_client">;
  readonly " $fragmentType": "ClientDetails_client";
};
export type ClientDetails_client$key = {
  readonly " $data"?: ClientDetails_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientDetails_client">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "raw",
    "value": true
  }
],
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "trackingType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "targetType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commentType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "carbsGrams",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "proteinGrams",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fatGrams",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "calories",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCalories",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentFat",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentCarbs",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentProtein",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "date",
    "storageKey": "date(raw:true)"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "goals"
        ]
      }
    ]
  },
  "name": "ClientDetails_client",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Enrollment",
      "kind": "LinkedField",
      "name": "enrollments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": "endDateRaw",
          "args": (v1/*: any*/),
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": "endDate(raw:true)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgressDialog_enrollments"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientProgramProgressCard_enrollment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionMacroTarget",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionPortionTarget",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "goals",
      "args": null,
      "concreteType": "ClientGoalConnection",
      "kind": "LinkedField",
      "name": "__ClientDetails_goals_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientGoalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientGoal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientGoalsCard_goals"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSummary_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSettingsDialog_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientBodyWeightCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientStepsCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientProgressPhotosCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientMeasurementsCard_client"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientFormsCard_client"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "870959293f3b1b352bcc681c171153a2";

export default node;
