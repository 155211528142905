import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { ReactComponent as LikeIcon } from "../../icons/Like.svg";
import { ReactComponent as UnlikeIcon } from "../../icons/Liked.svg";

import { LikesButton_likeable$key } from "./__generated__/LikesButton_likeable.graphql";
import { useLikeMutation } from "./mutations/Like";
import { useUnlikeMutation } from "./mutations/Unlike";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
}));

const fragment = graphql`
  fragment LikesButton_likeable on Likeable {
    id
    likedByMe
  }
`;

export interface LikesButtonProps extends IconButtonProps {
  likeableRef: LikesButton_likeable$key;
}

export function LikesButton(props: LikesButtonProps) {
  const { className, likeableRef, ...other } = props;
  const s = useStyles();
  const { id, likedByMe } = useFragment(fragment, likeableRef);
  const [like, likePending] = useLikeMutation();
  const [unlike, unlikePending] = useUnlikeMutation();
  const pending = likePending || unlikePending;
  const toggle = likedByMe ? unlike : like;

  const handleClick = React.useCallback(
    () =>
      toggle({
        variables: { input: { id } },
      }),
    [id, toggle],
  );

  return (
    <IconButton
      className={clsx(s.root, className)}
      onClick={handleClick}
      disabled={pending}
      {...other}
      size="large"
    >
      {likedByMe ? <UnlikeIcon /> : <LikeIcon />}
    </IconButton>
  );
}
