import { createContext } from "react";
import { LocalStorageKeys } from "../constants";

export interface IContextProps {
  minimizedDrawer: boolean;
  setMinimizedDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const MinimizedDrawerContext = createContext<IContextProps | undefined>(
  undefined,
);

export const getLocalStorageMinimizedDrawerValue = () => {
  return localStorage.getItem(LocalStorageKeys.IS_DRAWER_MINIMIZED) === "true"
    ? true
    : false;
};

export default MinimizedDrawerContext;
