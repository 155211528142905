import React from "react";

import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CurrentUserProvider } from "../../components/routes/CurrentUserProvider";
import { CurrentUserWrapperQuery } from "./__generated__/CurrentUserWrapperQuery.graphql";
import {
  ACTIVITY_ROUTE,
  CLIENT_PORTAL_ROUTE,
  CLIENT_PROFILE_ROUTE,
  GROUPS_ROUTE,
  HOME_ROUTE,
  MESSAGES_ROUTE,
  PROGRAMS_ROUTE,
} from "../../routes/routes";
import { Outlet, useLocation, matchRoutes } from "react-router-dom";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { brandedAppWorkspaceKey } from "../../utils/mobile";

interface CurrentUserWrapperProps {
  children: React.JSX.Element;
}

const ignoreWorkspaceRoutes = [
  HOME_ROUTE,
  PROGRAMS_ROUTE,
  GROUPS_ROUTE,
  ACTIVITY_ROUTE,
  MESSAGES_ROUTE,
  CLIENT_PROFILE_ROUTE,
];

export const CurrentUserWrapper = () => {
  const location = useLocation();
  const matches = matchRoutes([{ path: CLIENT_PORTAL_ROUTE }], location);
  const { isBrandedApp } = useCurrentBrand();
  const workspace = isBrandedApp
    ? brandedAppWorkspaceKey
    : matches && matches[0]?.params?.workspace;

  // To distinguish :workspace param from just one word route
  // we forced to add all of them to`ignoreWorkspaceRoutes`.
  // This is needed to retrieve slug from coach workspace URL when user
  // is not logged and the only source of custom theme and logo is data in URL
  const isWorkspace = isBrandedApp
    ? isBrandedApp
    : !ignoreWorkspaceRoutes.includes("/" + workspace);
  const data = useLazyLoadQuery<CurrentUserWrapperQuery>(
    graphql`
      query CurrentUserWrapperQuery($workspace: String, $program: String) {
        ...CurrentUserProvider_root
          @arguments(workspace: $workspace, program: $program)
      }
    `,
    {
      workspace: isWorkspace ? workspace : null,
    },
  );

  return (
    <CurrentUserProvider me={undefined} rootRef={data}>
      <Outlet />
    </CurrentUserProvider>
  );
};
