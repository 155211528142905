/**
 * @generated SignedSource<<eb00bd1b6b8d43690c02cb583a616de5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientMealLoggingEntryDialog_mealLoggingEntry$data = {
  readonly answers: string;
  readonly date: string | null | undefined;
  readonly description: string;
  readonly id: string;
  readonly photoURL: string | null | undefined;
  readonly time: string;
  readonly " $fragmentType": "ClientMealLoggingEntryDialog_mealLoggingEntry";
};
export type ClientMealLoggingEntryDialog_mealLoggingEntry$key = {
  readonly " $data"?: ClientMealLoggingEntryDialog_mealLoggingEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntryDialog_mealLoggingEntry">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientMealLoggingEntryDialog_mealLoggingEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answers",
      "storageKey": null
    }
  ],
  "type": "MealLoggingEntry",
  "abstractKey": null
};

(node as any).hash = "201a1cb56bfcefecb78d695693cad2e1";

export default node;
