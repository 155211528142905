/**
 * @generated SignedSource<<c80b96af4ad656dc2515c1c96ab16fad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientMealLoggingEntryDialog_workspace$data = {
  readonly mealLoggingQuestions: string;
  readonly " $fragmentType": "ClientMealLoggingEntryDialog_workspace";
};
export type ClientMealLoggingEntryDialog_workspace$key = {
  readonly " $data"?: ClientMealLoggingEntryDialog_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMealLoggingEntryDialog_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientMealLoggingEntryDialog_workspace",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealLoggingQuestions",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "5f4713d98db9ba7327b3ddc7a1cdd637";

export default node;
