import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { maxEnrollments } from "../../../../constants";

import { AppLayout } from "../../../../components/app/AppLayout";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { UnsplashContext } from "../../../../hooks/useUnsplash";
import { ProgramSettings } from "../../../../components/program-settings/ProgramSettings";
import { CoachProgramSettingsRouteQuery } from "./__generated__/CoachProgramSettingsRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

export function CoachProgramSettingsRoute() {
  const { slug } = useParams();

  const props = useLazyLoadQuery<CoachProgramSettingsRouteQuery>(
    graphql`
      query CoachProgramSettingsRouteQuery(
        $programSlug: String!
        $maxEnrollments: Int!
      ) {
        ...UnsplashImages_rootRef

        program(slug: $programSlug) {
          ...RefreshSlug
          ...ProgramSettings_program
          ...EnrollDialog_program
          ...EnrollDateDialog_program
          id
          name
          slug
        }

        clients {
          ...EnrollDialog_clients
        }

        groups {
          ...EnrollDialog_groups
        }

        enrollmentsAvatars: enrollments(
          programSlug: $programSlug
          first: $maxEnrollments
        ) @connection(key: "Program_enrollmentsAvatars", filters: []) {
          totalCount
          edges {
            node {
              id
            }
          }
          ...EnrolledAvatars_connection
        }
      }
    `,
    {
      programSlug: slug,
      maxEnrollments,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { program } = props;

  return (
    <>
      <AppLayout
        trackInfo={{
          name: "Coach - Program Settings",
          properties: {
            programId: program.id,
            programSlug: program.slug,
            programTitle: program.name,
          },
        }}
        hideUpgradeBanner
        appDrawer={false}
        appBar={false}
      >
        <RefreshSlug nodesRef={[program]} />
        <UnsplashContext.Provider value={props}>
          <ProgramSettings program={program} />
        </UnsplashContext.Provider>
      </AppLayout>
    </>
  );
}
