import clsx from "clsx";
import React from "react";
import { Box, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";

const useStyles = makeStyles((theme) => ({
  root: {},

  newDesign: {},

  controls: {
    display: "flex",
  },

  select: {
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },

    "$newDesign &:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  empty: {
    color: theme.palette.text.secondary,
  },
}));

function range(start: number, length: number): number[] {
  return Array.from({ length }).map((_, i) => start + i);
}

const getYears = (min: number, max: number) =>
  range(new Date().getFullYear() - max, max - min).reverse();

const daysInMonth = (month: number, year: number) =>
  month && year ? new Date(year, month, 0).getDate() : 31;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const parseValue = (value?: string) => {
  const parsed: number[] = new Array(3);

  if (value) {
    value.split("/").forEach((x, index) => {
      parsed[index] = x ? parseInt(x) : null;
    });
  }

  return parsed;
};

export interface BirthdayFieldProps
  extends Omit<FieldsGroupProps, "onChange" | "label" | "error"> {
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
  minAge?: number;
  maxAge?: number;
  valid?: boolean;
  error?: boolean;
  errorMessage?: string;
}

export function BirthdayField(props: BirthdayFieldProps) {
  const {
    className,
    disabled,
    onChange,
    value,
    minAge = 12,
    maxAge = 90,
    valid = true,
    error,
    errorMessage = "Please enter a valid date of birth",
    ...other
  } = props;
  const s = useStyles();
  const [date, setDate] = React.useState(parseValue(value));
  const [month, day, year] = date;
  const years = getYears(minAge, maxAge);
  const days = range(1, daysInMonth(month, year));

  const handleChange = React.useCallback(
    (value: number, index: number) => {
      const newDate = [...date];

      newDate[index] = value;

      setDate(newDate);

      if (newDate.every(Boolean)) {
        onChange(newDate.join("/"));
      }
    },
    [onChange, date, setDate],
  );

  const handleMonthChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      handleChange(event.target.value as unknown as number, 0);
    },
    [handleChange],
  );

  const handleDayChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      handleChange(event.target.value as unknown as number, 1);
    },
    [handleChange],
  );

  const handleYearChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      handleChange(event.target.value as unknown as number, 2);
    },
    [handleChange],
  );

  return (
    <FieldsGroup
      label="Date of birth"
      className={s.root}
      error={error && errorMessage}
      {...other}
    >
      <Box className={s.controls}>
        <Select
          variant="outlined"
          className={clsx(s.select, !month && s.empty)}
          value={String(month)}
          onChange={handleMonthChange}
          displayEmpty
          fullWidth
          error={!valid}
          disabled={disabled}
        >
          <MenuItem disabled>Month</MenuItem>

          {months.map((month, index) => (
            <MenuItem value={index + 1} key={month}>
              {month}
            </MenuItem>
          ))}
        </Select>

        <Select
          variant="outlined"
          className={clsx(s.select, !day && s.empty)}
          value={String(day)}
          onChange={handleDayChange}
          displayEmpty
          fullWidth
          error={!valid}
          disabled={disabled}
        >
          <MenuItem disabled>Day</MenuItem>

          {days.map((day) => (
            <MenuItem value={day} key={day}>
              {day}
            </MenuItem>
          ))}
        </Select>

        <Select
          variant="outlined"
          className={clsx(s.select, !year && s.empty)}
          value={String(year)}
          onChange={handleYearChange}
          displayEmpty
          fullWidth
          error={!valid}
          disabled={disabled}
        >
          <MenuItem disabled>Year</MenuItem>

          {years.map((year) => (
            <MenuItem value={year} key={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </FieldsGroup>
  );
}
