/**
 * @generated SignedSource<<806ff25c884048af1684a7fafee80744>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollDialog_program$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EnrollDialog_program";
};
export type EnrollDialog_program$key = {
  readonly " $data"?: EnrollDialog_program$data;
  readonly " $fragmentSpreads": FragmentRefs<"EnrollDialog_program">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnrollDialog_program",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Program",
  "abstractKey": null
};

(node as any).hash = "d2cccd98cbd9b9bf5d032c87ed41557b";

export default node;
