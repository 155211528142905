import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import dayjs, { Dayjs } from "dayjs";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import { Box } from "@mui/material";

type IDateQuestionAnswer = {
  selectedDate: string;
};

const DATE_QUESTION_FORMAT = "MM-DD-YYYY";

const DateQuestion = ({
  view,
  disableAnswer,
  element,
  handleSetNode,
}: ICheckInQuestionPropsBase<IDateQuestionAnswer>) => {
  const answer = element.answer as IDateQuestionAnswer;
  const formattedAnswer = answer ? dayjs(answer?.selectedDate) : null;

  const handleChange = (newValue: Dayjs | null) => {
    const newValueString = newValue?.format(DATE_QUESTION_FORMAT);
    handleSetNode({ answer: { selectedDate: newValueString } });
  };

  const isCoachView = view === EditorElementView.Coach;
  const disabled = view === EditorElementView.Coach || disableAnswer;

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      {/* BOX needed to make tooltip work properly */}
      <Box>
        <DatePicker
          format={DATE_QUESTION_FORMAT}
          value={formattedAnswer}
          onChange={handleChange}
          sx={{ width: "100%", maxWidth: 250 }}
          disabled={disabled}
        />
      </Box>
    </DisabledCheckInTooltip>
  );
};

export default DateQuestion;
