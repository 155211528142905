/**
 * @generated SignedSource<<fba572d3cd2978e1352b69da64362669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeComponentCoverDialog_component$data = {
  readonly content: string | null | undefined;
  readonly image: string;
  readonly program: {
    readonly imageURL: string | null | undefined;
  };
  readonly teaser: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentPreview_component">;
  readonly " $fragmentType": "ChangeComponentCoverDialog_component";
};
export type ChangeComponentCoverDialog_component$key = {
  readonly " $data"?: ChangeComponentCoverDialog_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeComponentCoverDialog_component">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "draft",
    "variableName": "draft"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "draft"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeComponentCoverDialog_component",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "teaser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ComponentPreview_component"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "51a5f1cabfc83ce12be7594d5987e15c";

export default node;
