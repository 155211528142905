import React from "react";
import YesNoQuestion from "./checkInQuestions/YesNoQuestion";
import LongTextQuestion from "./checkInQuestions/LongTextQuestion";
import ShortTextQuestion from "./checkInQuestions/ShortTextQuestion";
import RatingScaleQuestion from "./checkInQuestions/RatingScaleQuestion";
import MeasurementQuestion from "./checkInQuestions/MeasurementQuestion";
import ProgressPhotoQuestion from "./checkInQuestions/ProgressPhotoQuestion";
import NumberQuestion from "./checkInQuestions/NumberQuestion";
import FileQuestion from "./checkInQuestions/FileQuestion";
import MultipleChoiceQuestion from "./checkInQuestions/MultipleChoiceQuestion";
import MultipleCheckBoxQuestion from "./checkInQuestions/MultipleCheckBoxQuestion";
import StarRatingQuestion from "./checkInQuestions/StarRatingQuestion";
import EmojiQuestion from "./checkInQuestions/EmojiQuestion";
import DateQuestion from "./checkInQuestions/DateQuestion";
import { TElement } from "@udecode/plate-common";
import { CheckInTypes } from "../utils/menuItemUtil";
import { CheckInNutritionTarget } from "./CheckInNutritionTarget";
import {
  CheckInComponentStatus,
  EditorElementView,
} from "../utils/editorUtils";
import { Box } from "@mui/material";

export interface ICheckInQuestionPropsBase<T> {
  view: EditorElementView;
  disableAnswer?: boolean;
  element: TElement | (TElement & { answer: T });
  handleSetNode: (dataToSet: object, debounce?: boolean) => void;
}

interface ICheckInQuestionsProps {
  element: TElement;
  initType: CheckInTypes;
  view: EditorElementView;
  handleSetNode: (dataToSet: object, debounce?: boolean) => void;
  disableAnswer?: boolean;
}

const CheckInQuestions = ({
  element,
  initType,
  handleSetNode,
  view,
  disableAnswer,
}: ICheckInQuestionsProps) => {
  switch (initType) {
    case CheckInTypes.YES_NO:
      return (
        <YesNoQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.SHORT_TEXT:
      return (
        <ShortTextQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.LONG_TEXT:
      return (
        <LongTextQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.RATING_SCALE:
      return (
        <RatingScaleQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.MEASUREMENT:
      return (
        <MeasurementQuestion
          handleSetNode={handleSetNode}
          element={element}
          view={view}
          disableAnswer={disableAnswer}
        />
      );
    case CheckInTypes.PROGRESS_PHOTO:
      return (
        <ProgressPhotoQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.NUMBER:
      return (
        <NumberQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.FILE:
      return (
        <FileQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceQuestion
          handleSetNode={handleSetNode}
          disableAnswer={disableAnswer}
          element={element}
          view={view}
        />
      );
    case CheckInTypes.MULTIPLE_CHECKBOX:
      return (
        <MultipleCheckBoxQuestion
          handleSetNode={handleSetNode}
          element={element}
          view={view}
          disableAnswer={disableAnswer}
        />
      );
    case CheckInTypes.DATE:
      return (
        <DateQuestion
          view={view}
          handleSetNode={handleSetNode}
          element={element}
          disableAnswer={disableAnswer}
        />
      );
    case CheckInTypes.EMOJI:
      return (
        <EmojiQuestion
          view={view}
          handleSetNode={handleSetNode}
          element={element}
          disableAnswer={disableAnswer}
        />
      );
    case CheckInTypes.STAR_RATING:
      return (
        <StarRatingQuestion
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    case CheckInTypes.NUTRITION_TARGET:
      return (
        <CheckInNutritionTarget
          view={view}
          disableAnswer={disableAnswer}
          handleSetNode={handleSetNode}
          element={element}
        />
      );
    default:
      throw new Error("Unknown component type");
  }
};

export default CheckInQuestions;
