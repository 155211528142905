import React, { createContext, useState } from "react";
import { CoachProgramsListScreen_root$data } from "../components/coach-programs/__generated__/CoachProgramsListScreen_root.graphql";

interface CoachProgramsListScreenContextType {
  programFolders: CoachProgramsListScreen_root$data;
  setProgramFolders: React.Dispatch<
    React.SetStateAction<CoachProgramsListScreen_root$data>
  >;
  pending: boolean;
  setPending: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CoachProgramsListScreenContext = createContext<
  CoachProgramsListScreenContextType | undefined
>(undefined);
