/**
 * @generated SignedSource<<68d8a18daf73860c1af7842d37dd84cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsDialog_notificationsSetting$data = {
  readonly dailyUpdate: boolean;
  readonly newMessageEmail: boolean;
  readonly newResponseEmail: boolean;
  readonly weeklyUpdate: boolean;
  readonly " $fragmentType": "ClientSettingsDialog_notificationsSetting";
};
export type ClientSettingsDialog_notificationsSetting$key = {
  readonly " $data"?: ClientSettingsDialog_notificationsSetting$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsDialog_notificationsSetting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSettingsDialog_notificationsSetting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newMessageEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newResponseEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weeklyUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dailyUpdate",
      "storageKey": null
    }
  ],
  "type": "NotificationsSetting",
  "abstractKey": null
};

(node as any).hash = "39b6d746c53d3130306bfb9a27ea2328";

export default node;
