import React from "react";
import { Box, BoxProps } from "@mui/material";
import { FileIcon, defaultStyles } from "react-file-icon";

export interface AssetFileIconProps extends BoxProps {
  extension: string;
  size?: number;
}

export function AssetFileIcon(props: AssetFileIconProps) {
  const { className, extension, size = 80, ...other } = props;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={1}
      height={1}
    >
      <Box sx={{ width: size }}>
        <FileIcon
          extension={extension}
          {...defaultStyles[extension]}
          xs={{ maxHeight: "100%" }}
        />
      </Box>
    </Box>
  );
}
