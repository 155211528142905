/**
 * @generated SignedSource<<c988403c4889e8ad669a791752736b20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientsFormRouteQuery$variables = {
  slug: string;
};
export type CoachClientsFormRouteQuery$data = {
  readonly clientForm: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsFormScreen_root" | "UnsplashImages_rootRef">;
};
export type CoachClientsFormRouteQuery = {
  response: CoachClientsFormRouteQuery$data;
  variables: CoachClientsFormRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachClientsFormRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UnsplashImages_rootRef"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CoachClientsFormScreen_root"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientForm",
        "kind": "LinkedField",
        "name": "clientForm",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachClientsFormRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientForm",
        "kind": "LinkedField",
        "name": "clientForm",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slugId",
                "storageKey": null
              }
            ],
            "type": "Slug",
            "abstractKey": "__isSlug"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requestId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submittedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityFeedback",
                "kind": "LinkedField",
                "name": "feedbacks",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "read",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "fromNow"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"fromNow\")"
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachReadAnswers",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientConnection",
            "kind": "LinkedField",
            "name": "clients",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 999
          },
          {
            "kind": "Literal",
            "name": "status",
            "value": "ACTIVE"
          }
        ],
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": "clients(first:999,status:\"ACTIVE\")"
      }
    ]
  },
  "params": {
    "cacheID": "51bd285d93482c6ac325bbc41e453200",
    "id": null,
    "metadata": {},
    "name": "CoachClientsFormRouteQuery",
    "operationKind": "query",
    "text": "query CoachClientsFormRouteQuery(\n  $slug: String!\n) {\n  ...CoachClientsFormScreen_root_20J5Pl\n  clientForm(slug: $slug) {\n    id\n  }\n}\n\nfragment ActivityFeedbackButton_activity on Activity {\n  ...ActivityFeedbackList_activity\n  feedbacks {\n    id\n  }\n}\n\nfragment ActivityFeedbackList_activity on Activity {\n  feedbacks {\n    id\n    ...ActivityFeedback_activityFeedback\n  }\n  ...FeedbackForm_activity\n  id\n  coachReadAnswers\n}\n\nfragment ActivityFeedback_activityFeedback on ActivityFeedback {\n  id\n  author {\n    id\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ClientAddableItem_client on User {\n  id\n  email\n  displayName\n  photoURL\n}\n\nfragment ClientForm_clientForm on ClientForm {\n  id\n  requestId\n  name\n  description\n  image\n  content\n  updatedAt\n  submittedAt\n  activity {\n    ...ActivityFeedbackButton_activity\n    id\n  }\n}\n\nfragment CoachClientsFormScreen_root_20J5Pl on Root {\n  clientForm(slug: $slug) {\n    ...RefreshSlug\n    ...ClientForm_clientForm\n    id\n    clients {\n      edges {\n        node {\n          id\n          email\n          displayName\n          ...ClientAddableItem_client\n        }\n      }\n    }\n  }\n  clients(first: 999, status: ACTIVE) {\n    ...SelectAddableClientsDialog_clients\n  }\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n\nfragment SelectAddableClientsDialog_clients on ClientConnection {\n  edges {\n    node {\n      id\n      email\n      displayName\n      ...ClientAddableItem_client\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f847743e7935a650fc362128d43a0096";

export default node;
