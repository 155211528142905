import React, { useLayoutEffect, useTransition } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { RelayModernEnvironment } from "relay-runtime/lib/store/RelayModernEnvironment";
import { ConnectionHandler, graphql, fetchQuery } from "relay-runtime";
import {
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime/lib/store/RelayStoreTypes";

import {
  RELAY_LAZY_LOAD_COMMON_CONFIG,
  relayCounterIncrement,
} from "../utils/relay";

import { useCurrentUserId } from "./useCurrentUser";
import { useLiveUpdatesQuery } from "./__generated__/useLiveUpdatesQuery.graphql";
import firebase from "firebase";

type ResponseNode = useLiveUpdatesQuery["response"]["node"];

export const liveUpdatesEnabled = true;

const QUERY = graphql`
  query useLiveUpdatesQuery($id: ID!) {
    me {
      unreadMessagesCounter
      notificationUnreadCount
      notificationsActive
    }

    node(id: $id) {
      id
      __typename
      ... on Notification {
        ...NotificationBox_notification @relay(mask: false)
      }
      ... on Message {
        ...Message_message @relay(mask: false)
        thread {
          ...ThreadCard_thread @relay(mask: false)
        }
      }
      ... on ActivityFeedback {
        ...ActivityFeedback_activityFeedback @relay(mask: false)
        activity {
          id
        }
      }
      ... on GroupPost {
        ...GroupPostsListItem_post
        groupId
      }
      ... on GroupPostComment {
        ...GroupPostCommentsListItem_comment
        groupPostId
      }
    }
  }
`;

const addNodeToConnection = (
  store: RecordSourceProxy,
  conn: RecordProxy<{}>,
  node: RecordProxy<{}>,
  edgeType: string,
  position: "before" | "after",
) => {
  if (conn) {
    const exists = conn
      .getLinkedRecords("edges")
      .some(
        (edge) => edge.getLinkedRecord("node").getDataID() === node.getDataID(),
      );

    if (!exists) {
      const edge = ConnectionHandler.createEdge(store, conn, node, edgeType);
      const method =
        position === "before" ? "insertEdgeBefore" : "insertEdgeAfter";

      ConnectionHandler[method](conn, edge);

      return true;
    }
  }

  return false;
};

const applyLiveUpdateMessage = (
  relay: RelayModernEnvironment,
  node: ResponseNode,
) =>
  relay.commitUpdate((store) => {
    const thread = store.get(node.thread.id);
    const threads = ConnectionHandler.getConnection(
      store.getRoot(),
      "Threads_threads",
    );

    const message = store.get(node.id);
    const messages = ConnectionHandler.getConnection(
      thread,
      "Messages_messages",
    );

    if (threads) {
      addNodeToConnection(store, threads, thread, "ThreadEdge", "before");
    }

    if (messages) {
      addNodeToConnection(store, messages, message, "MessageEdge", "before");
    }
  });

const applyLiveUpdateNotification = (
  relay: RelayModernEnvironment,
  node: ResponseNode,
) =>
  relay.commitUpdate((store) => {
    const me = store.getRoot().getLinkedRecord("me");
    const notification = store.get(node.id);
    const notifications = ConnectionHandler.getConnection(
      me,
      "NotificationsMenu_notifications",
    );

    if (notifications && !notification.getValue("read")) {
      const appended = addNodeToConnection(
        store,
        notifications,
        notification,
        "NotificationEdge",
        "before",
      );

      if (appended) {
        relayCounterIncrement(notifications, "totalCount");
        relayCounterIncrement(notifications, "unreadCount");
      }
    }
  });

const applyLiveUpdateActivityFeedback = (
  relay: RelayModernEnvironment,
  node: ResponseNode,
) =>
  relay.commitUpdate((store) => {
    const feedback = store.get(node.id);
    const activity = store.get(node.activity.id);
    const feedbacks = activity && activity.getLinkedRecords("feedbacks");

    if (feedbacks) {
      activity.setLinkedRecords([...feedbacks, feedback], "feedbacks");
    }
  });

const applyLiveUpdateGroupPost = (
  relay: RelayModernEnvironment,
  node: ResponseNode,
) =>
  relay.commitUpdate((store) => {
    const post = store.get(node.id);
    const group = store.get(node.groupId);
    const posts =
      group && ConnectionHandler.getConnection(group, "GroupPostsList_posts");

    if (post && posts) {
      addNodeToConnection(store, posts, post, "GroupPostEdge", "before");
      relayCounterIncrement(posts, "totalCount");
    }
  });

const applyLiveUpdateGroupPostComment = (
  relay: RelayModernEnvironment,
  node: ResponseNode,
) =>
  relay.commitUpdate((store) => {
    const groupPost = store.get(node.groupPostId);
    const comment = store.get(node.id);
    const comments =
      groupPost &&
      ConnectionHandler.getConnection(
        groupPost,
        "GroupPostCommentsList_comments",
      );

    if (comment && comments) {
      addNodeToConnection(
        store,
        comments,
        comment,
        "GroupPostCommentEdge",
        "after",
      );
      relayCounterIncrement(comments, "totalCount");
    }
  });

const applyLiveUpdate = async (
  relay: RelayModernEnvironment,
  node: ResponseNode,
) => {
  try {
    if (node) {
      switch (node.__typename) {
        case "Message":
          return applyLiveUpdateMessage(relay, node);
        case "Notification":
          return applyLiveUpdateNotification(relay, node);
        case "ActivityFeedback":
          return applyLiveUpdateActivityFeedback(relay, node);
        case "GroupPost":
          return applyLiveUpdateGroupPost(relay, node);
        case "GroupPostComment":
          return applyLiveUpdateGroupPostComment(relay, node);
        default:
          console.error("live update not recognized", node);
      }
    }
  } catch (e) {
    console.error("Live update error", node, e);
  }
};

interface LiveUpdatesOptions {
  userId: string;
  relay: RelayModernEnvironment;
  enabled?: boolean;
}
export const useLiveUpdates = ({
  userId,
  relay,
  enabled = liveUpdatesEnabled,
}: LiveUpdatesOptions) => {
  const ref = React.useMemo(() => {
    const id = atob(userId).split(":")[1];
    const path = `users/${id}/updates`;
    return firebase.database().ref(path);
  }, [userId]);

  const [_, startTransition] = useTransition();
  const [queryArgs, setQueryArgs] = React.useState({
    id: "",
  });

  const query = useLazyLoadQuery<useLiveUpdatesQuery>(
    QUERY,
    queryArgs,
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  React.useEffect(() => {
    if (enabled && relay && query.node) {
      applyLiveUpdate(relay, query.node);
    }
  }, [enabled, query, relay]);

  const handleSnapshot = React.useCallback(
    (snapshot: firebase.database.DataSnapshot) => {
      startTransition(() => {
        setQueryArgs({
          id: snapshot.val(),
        });
      });
      snapshot.ref.remove();
    },
    [relay],
  );
  React.useEffect(() => {
    if (enabled) {
      ref.limitToLast(1).on("child_added", handleSnapshot);
      return () => ref.off("child_added", handleSnapshot);
    }
  }, [enabled, handleSnapshot, ref]);
};
