/**
 * @generated SignedSource<<bc0a8630bfc7cf2ca51e303037d47560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NutritionCommentsType = "CALORIES_ONLY" | "FULL_MACROS" | "PROTEIN_CALORIES" | "PROTEIN_ONLY" | "TRACKING_ONLY" | "%future added value";
export type NutritionTargetType = "ALL_DAYS" | "OFF_DAYS" | "REFEED_DAYS" | "TRAINING_DAYS" | "WEEKDAYS" | "WEEKENDS" | "%future added value";
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientNutritionCard_nutritionMacroTarget$data = ReadonlyArray<{
  readonly calories: number | null | undefined;
  readonly carbsGrams: number | null | undefined;
  readonly clientNutritionEntry: {
    readonly calories: number | null | undefined;
    readonly carbsGrams: number | null | undefined;
    readonly date: string | null | undefined;
    readonly fatGrams: number | null | undefined;
    readonly id: string;
    readonly proteinGrams: number | null | undefined;
  } | null | undefined;
  readonly commentType: NutritionCommentsType;
  readonly currentCalories: number | null | undefined;
  readonly currentCarbs: number | null | undefined;
  readonly currentFat: number | null | undefined;
  readonly currentProtein: number | null | undefined;
  readonly date: string | null | undefined;
  readonly fatGrams: number | null | undefined;
  readonly id: string;
  readonly proteinGrams: number | null | undefined;
  readonly targetType: NutritionTargetType;
  readonly trackingType: NutritionTrackingType;
  readonly " $fragmentType": "ClientNutritionCard_nutritionMacroTarget";
}>;
export type ClientNutritionCard_nutritionMacroTarget$key = ReadonlyArray<{
  readonly " $data"?: ClientNutritionCard_nutritionMacroTarget$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionMacroTarget">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "carbsGrams",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proteinGrams",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fatGrams",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calories",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientNutritionCard_nutritionMacroTarget",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentType",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentCalories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentFat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentCarbs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentProtein",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionEntry",
      "kind": "LinkedField",
      "name": "clientNutritionEntry",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientNutritionTarget",
  "abstractKey": null
};
})();

(node as any).hash = "e2346a49e07512925ee11656c6c4c110";

export default node;
