import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { Avatar } from "../avatar/Avatar";
import { MoreMenuButton } from "../button/MoreMenuButton";
import EditorFieldEditor from "../new-editor/EditFieldEditor";
import { HeightOverflow } from "../container/HeightOverflow";
import { Likeable } from "../likeable/Likeable";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { GroupPostsCommentForm } from "./GroupPostsCommentForm";
import { GroupPostCommentsList } from "./GroupPostCommentsList";
import { GroupMemberBadges } from "./GroupMemberBadges";
import { GroupPostMenu } from "./GroupPostMenu";
import { GroupPostsListItem_post$key } from "./__generated__/GroupPostsListItem_post.graphql";
import { notificationReadStoreUpdater } from "../activity-feedback/ActivityFeedback";
import useDependantKey from "../../hooks/useDependantKey";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    boxShadow: theme.shadows[4],

    marginBottom: theme.spacing(3),
    overflow: "hidden",
  },

  header: {
    padding: theme.spacing(3, 3, 1, 3),
    display: "flex",
    alignItems: "center",
  },

  avatar: {
    margin: theme.spacing(0, 2, 0, 0),

    [theme.breakpoints.up("md")]: {
      width: 48,
      height: 48,
    },
  },

  author: {
    flexGrow: 1,
  },

  displayName: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "1.5em",
  },

  time: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  body: {
    padding: theme.spacing(1, 3, 3, 10),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 3, 3, 11),
    },
  },

  more: {
    color: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),

    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  title: {
    fontSize: 14,
    fontWeight: 600,
  },

  content: {
    fontSize: 14,
    fontWeight: 500,
  },

  expand: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const notificationReadMutation = graphql`
  mutation GroupPostsListItemNotificationReadMutation(
    $input: NotificationReadInput!
  ) {
    notificationRead(input: $input) {
      notification {
        id
        activityFeedbackId
      }
    }
  }
`;

const postFragment = graphql`
  fragment GroupPostsListItem_post on GroupPost {
    ...GroupPostsCommentForm_post
    ...GroupPostCommentsList_post
    ...Likeable_likeable
    ...GroupPostMenu_post
    id
    title
    content
    createdAt(format: "fromNow")
    groupId
    notification {
      userId
      read
    }
    author {
      ...Avatar_user
      ...GroupMemberBadges_user
      id
      role
      displayName
    }
  }
`;

export interface GroupPostsListItemProps extends BoxProps {
  post: GroupPostsListItem_post$key;
}

export function GroupPostsListItem(props: GroupPostsListItemProps) {
  const { className, post: postRef, ...other } = props;
  const post = useFragment(postFragment, postRef);
  const s = useStyles();
  const user = useCurrentUser();
  const { title, content, createdAt, notification, author } = post;
  const menuState = usePopupState({
    variant: "popover",
    popupId: "group-post-menu",
  });
  const canEdit = user.id === author.id || user.role === UserRole.COACH;

  const [notificationRead, inFlight] = useMutation(notificationReadMutation);

  React.useEffect(() => {
    if (notification) {
      if (
        notification?.userId === user.id &&
        !notification?.read &&
        !inFlight
      ) {
        notificationRead({
          variables: {
            input: { groupPostId: post.id },
          },
          onCompleted: (data, errors) => {
            if (errors && errors.length) {
              console.error(errors[0]);
            }
          },
          onError: (error) => {
            console.error(error);
          },
          updater: notificationReadStoreUpdater,
        });
      }
    }
  }, [post.id, notification, notificationRead, inFlight, user.id]);

  const contentKey = useDependantKey(content);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Avatar className={s.avatar} userRef={author} />
        <Box className={s.author}>
          <Box display={"flex"}>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              {author.displayName}
            </Typography>
            <Typography
              component="div"
              className={s.displayName}
              variant="subtitle1"
            >
              <GroupMemberBadges memberRef={author} />
            </Typography>
          </Box>
          <Typography className={s.time} variant="subtitle2">
            {createdAt}
          </Typography>
        </Box>
        <Likeable likeableRef={post} />

        {canEdit && (
          <>
            <MoreMenuButton
              className={s.more}
              styleVariant="inverse"
              {...bindTrigger(menuState)}
            />

            <GroupPostMenu
              postRef={post}
              {...bindMenu(menuState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            />
          </>
        )}
      </Box>

      <Box className={s.body}>
        {title && (
          <Typography className={s.title} gutterBottom>
            {title}
          </Typography>
        )}
        <Typography component="div" className={s.content}>
          <HeightOverflow
            maxHeight={72}
            renderLabel={(expanded: boolean) => (
              <Typography className={s.expand}>
                {expanded ? "Show less" : "Show more"}
              </Typography>
            )}
            disableOverlay
          >
            <EditorFieldEditor
              key={contentKey}
              value={content}
              readOnly
              multiline
            />
          </HeightOverflow>
        </Typography>
      </Box>

      <GroupPostCommentsList postRef={post} />
      <GroupPostsCommentForm postRef={post} commentRef={null} />
    </Box>
  );
}
