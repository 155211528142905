/**
 * @generated SignedSource<<c1f49b483fac639aa97d9d5508f0bbef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditorMenu_component$data = {
  readonly content: string | null | undefined;
  readonly habitPrompt: string | null | undefined;
  readonly id: string;
  readonly image: string;
  readonly locked: boolean;
  readonly title: string;
  readonly type: ComponentType;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeComponentCoverDialog_component">;
  readonly " $fragmentType": "EditorMenu_component";
};
export type EditorMenu_component$key = {
  readonly " $data"?: EditorMenu_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditorMenu_component">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "draft",
    "variableName": "draft"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "draft"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditorMenu_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "habitPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locked",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ChangeComponentCoverDialog_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};
})();

(node as any).hash = "a34e4b596461347b4101a4cc214bf460";

export default node;
