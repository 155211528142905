import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { useSnackAlert } from "../../../hooks/useSnackAlert";

import { UpdateMealLoggingQuestionsMutation } from "./__generated__/UpdateMealLoggingQuestionsMutation.graphql";

const mutation = graphql`
  mutation UpdateMealLoggingQuestionsMutation(
    $input: UpdateMealLoggingQuestionsInput!
  ) {
    updateMealLoggingQuestions(input: $input) {
      workspace {
        ...MealLoggingSettingsDrawer_workspace
      }
    }
  }
`;

export const useUpdateMealLoggingQuestionsMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpdateMealLoggingQuestionsMutation>(
    mutation,
    (config) => ({
      onSuccess: () =>
        snackAlert({
          severity: "success",
          message: "Meal logging prompts updated.",
        }),
    }),
  );
};
