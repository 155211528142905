import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { capitalize } from "lodash";

import { UserPage_user$key } from "./__generated__/UserPage_user.graphql";
import { UserDetails } from "./UserDetails";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: theme.spacing(2.5),
  },

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
    marginBottom: theme.spacing(2.5),
  },
}));

enum UserTab {
  DETAILS = "DETAILS",
}

const userFragment = graphql`
  fragment UserPage_user on User {
    displayName
    ...UserDetails_user
  }
`;

export interface UserPageProps extends BoxProps {
  user: UserPage_user$key;
}

export function UserPage(props: UserPageProps) {
  const { className, user: userRef, ...other } = props;
  const user = useFragment(userFragment, userRef);
  const s = useStyles();
  const [selectedTab, setSelectedTab] = React.useState<UserTab>(
    UserTab.DETAILS,
  );

  window.document.title = user.displayName;

  const handleTabChange = React.useCallback((_, value: UserTab) => {
    setSelectedTab(value);
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography
        className={s.title}
        variant="h1"
        children={user.displayName}
      />

      <Tabs
        className={s.tabs}
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        {Object.values(UserTab).map((tab) => (
          <Tab key={tab} label={capitalize(tab)} value={tab} />
        ))}
      </Tabs>

      {selectedTab === UserTab.DETAILS && <UserDetails user={user} />}
    </Box>
  );
}
