import { NutrientType, NutritionTrackingType } from "../../utils/nutrition";

export enum MealLoggingQuestionType {
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  EMOJI = "EMOJI",
  YES_NO = "YES_NO",
  STAR_RATING = "STAR_RATING",
  LONG_TEXT = "LONG_TEXT",
  CHECKBOXES = "CHECKBOXES",
  NUMBER = "NUMBER",
  RATING_SCALE = "RATING_SCALE",
  NUTRITION = "NUTRITION",
}

export type MealLoggingOption = {
  id: number;
  text: string;
  prompt?: boolean;
};

export type MealLoggingQuestionMultipleChoice = {
  type: MealLoggingQuestionType.MULTIPLE_CHOICE;
  options: Array<MealLoggingOption>;
};

export type MealLoggingQuestionEmoji = {
  type: MealLoggingQuestionType.EMOJI;
};

export type MealLoggingQuestionYesNo = {
  type: MealLoggingQuestionType.YES_NO;
};

export type MealLoggingQuestionStarRating = {
  type: MealLoggingQuestionType.STAR_RATING;
};

export type MealLoggingQuestionLongText = {
  type: MealLoggingQuestionType.LONG_TEXT;
};

export type MealLoggingQuestionCheckboxes = {
  type: MealLoggingQuestionType.CHECKBOXES;
  options: Array<MealLoggingOption>;
};

export type MealLoggingQuestionNumber = {
  type: MealLoggingQuestionType.NUMBER;
};

export type MealLoggingQuestionRatingScale = {
  type: MealLoggingQuestionType.RATING_SCALE;
};

export type MealLoggingQuestionNutrition = {
  type: MealLoggingQuestionType.NUTRITION;
  trackingType: NutritionTrackingType;
  nutrientTypes: Array<NutrientType>;
};

export type MealLoggingQuestionKind =
  | MealLoggingQuestionMultipleChoice
  | MealLoggingQuestionEmoji
  | MealLoggingQuestionYesNo
  | MealLoggingQuestionStarRating
  | MealLoggingQuestionLongText
  | MealLoggingQuestionCheckboxes
  | MealLoggingQuestionNumber
  | MealLoggingQuestionRatingScale
  | MealLoggingQuestionNutrition;

export type MealLoggingQuestion<T = MealLoggingQuestionKind> = {
  id: number;
  type: MealLoggingQuestionType;
  text: string;
  answer?: string;
} & T;
