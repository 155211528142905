import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { humanizeUserPlan } from "../../utils/user";
import { CoachSettingsPlanTextSubtitle_user$key } from "./__generated__/CoachSettingsPlanTextSubtitle_user.graphql";
import { CoachSettingsPlanTextSubtitle_subscription$key } from "./__generated__/CoachSettingsPlanTextSubtitle_subscription.graphql";
import { planStatus, planStatusLabel } from "../../constants";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  status: {
    fontSize: 20,
  },

  statusLabel: {
    padding: theme.spacing(0, 0.5),
    borderRadius: 2,
    color: theme.palette.common.white,
  },
}));

const userFragment = graphql`
  fragment CoachSettingsPlanStatusText_user on User {
    plan
    trialExpiryDate(raw: true)
    trialExpired
  }
`;

const subscriptionFragment = graphql`
  fragment CoachSettingsPlanStatusText_subscription on Subscription {
    status
    canceledAtDate(utc: true, format: "MMMM D, YYYY")
    periodEndDate(utc: true, format: "MMMM D, YYYY")
    cancelAtPeriodEnd
  }
`;

export interface CoachSettingsPlanStatusTextProps extends TypographyProps {
  user: CoachSettingsPlanTextSubtitle_user$key;
  subscription: CoachSettingsPlanTextSubtitle_subscription$key;
}

export function CoachSettingsPlanStatusText(
  props: CoachSettingsPlanStatusTextProps,
) {
  const {
    className,
    user: userRef,
    subscription: subscriptionRef,
    ...other
  } = props;
  const user = useFragment(userFragment, userRef);
  const subscription = useFragment(subscriptionFragment, subscriptionRef);
  const s = useStyles();

  const text = React.useMemo(() => {
    if (user?.plan) {
      if (!subscription) {
        return `You are on the ${humanizeUserPlan(user.plan)} plan`;
      }
      if (
        subscription?.status === planStatus.ACTIVE ||
        subscription?.status === planStatus.TRIALING
      ) {
        return `You are on the ${humanizeUserPlan(user.plan)} plan`;
      } else {
        return <span className={s.status}>Your updated plan is in </span>;
      }
    } else {
      return "14 days trial plan";
    }
  }, [subscription, user, s.status]);

  const status = React.useMemo(() => {
    const color = () => {
      switch (subscription?.status) {
        case planStatus.PAST_DUE:
        case planStatus.INCOMPLETE:
          return "#F2994A";
        case planStatus.CANCELED:
        case planStatus.INCOMPLETE_EXPIRED:
        case planStatus.UNPAID:
          return "#EB5757";
        default:
          return "#ffffff";
      }
    };
    return (
      <span
        className={clsx(s.status, s.statusLabel)}
        style={{ backgroundColor: color() }}
      >
        {planStatusLabel[subscription?.status]}
      </span>
    );
  }, [subscription, s.status, s.statusLabel]);

  return (
    <Typography className={clsx(s.title, className)} variant="h2" {...other}>
      {text}
      {subscription?.status !== planStatus.ACTIVE &&
        subscription?.status !== planStatus.TRIALING &&
        status}
    </Typography>
  );
}
