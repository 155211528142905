import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Sets } from "../workout/types";

import CoachSet from "./CoachSet";
import {
  ExerciseSetNumberType,
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
} from "../../constants";
import { unitSetLabelCoach } from "../../utils/units";
import { hasExtraColumn } from "../workout/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  hint: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    fontWeight: 500,
    padding: theme.spacing(1, 0.5),
    lineHeight: 1.2,
  },
}));

export interface CoachSetsProps {
  sets: Sets[];
  units: string;
  typeExtraMeasurement: ExerciseTypeExtra;
  typeSet: ExerciseTypeSet;
  typeReps: ExerciseTypeReps;
  onChangeSet: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ) => void;
  disabled?: boolean;
}

function CoachSets(props: CoachSetsProps) {
  const {
    sets,
    onChangeSet,
    units,
    typeExtraMeasurement,
    typeSet,
    typeReps,
    disabled,
  } = props;
  const s = useStyles();

  const ExtraColumnHint = ({
    typeExtraMeasurement,
  }: {
    typeExtraMeasurement: ExerciseTypeExtra;
  }) => {
    if (typeExtraMeasurement === ExerciseTypeExtra.NONE) {
      return <></>;
    }

    switch (typeExtraMeasurement) {
      case ExerciseTypeExtra.REPS_IN_RESERVE:
        return (
          <>
            * feel free to leave <b>Reps</b> or{" "}
            <b>{unitSetLabelCoach(units, typeSet)}</b> column empty with{" "}
            <b>RIR</b> programming
          </>
        );
      case ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION:
        return (
          <>
            * feel free to leave <b>Reps</b> or{" "}
            <b>{unitSetLabelCoach(units, typeSet)}</b> column empty with{" "}
            <b>RPE</b> programming
          </>
        );
    }
  };

  return (
    <div className={s.root}>
      {sets.map(({ extraMeasurement, reps, weight, type }, index) => (
        <CoachSet
          extraMeasurement={extraMeasurement}
          reps={reps}
          weight={weight}
          index={index}
          key={`set_${index}`}
          onChangeSet={onChangeSet}
          units={units}
          typeExtraMeasurement={typeExtraMeasurement}
          typeSet={typeSet}
          typeReps={typeReps}
          disabled={disabled}
          type={type}
        />
      ))}
      {hasExtraColumn(typeExtraMeasurement) && (
        <div className={s.hint}>
          <ExtraColumnHint typeExtraMeasurement={typeExtraMeasurement} />
        </div>
      )}
    </div>
  );
}

export default CoachSets;
