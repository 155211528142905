import React from "react";

import { AlertData } from "../components/snackbar/SnackbarAlert";

export const SnackbarAlertContext =
  React.createContext<(snackAlert: AlertData | null) => void>(null);

export function useSnackAlert() {
  return React.useContext(SnackbarAlertContext);
}
