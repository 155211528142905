/**
 * @generated SignedSource<<32d9acb13c1cddb2501d7d2ec250baf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NutritionTargetScreen_client$data = {
  readonly id: string;
  readonly nutritionMacroTarget: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionMacroTarget">;
  } | null | undefined> | null | undefined;
  readonly nutritionPortionTarget: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionPortionTarget">;
  } | null | undefined> | null | undefined;
  readonly nutritionTarget: ReadonlyArray<{
    readonly calories: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionTarget">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "NutritionTargetScreen_client";
};
export type NutritionTargetScreen_client$key = {
  readonly " $data"?: NutritionTargetScreen_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"NutritionTargetScreen_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./NutritionTargetScreenRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "NutritionTargetScreen_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "calories",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionMacroTarget",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionMacroTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionPortionTarget",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientNutritionCard_nutritionPortionTarget"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "dadff36360de117f79b5d31fcc6857fc";

export default node;
