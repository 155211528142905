import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import {
  ComponentIcons,
  SpecialIconNames,
  ComponentTypeDefaultIcons,
} from "../program/icons";

import { ComponentIcon_component$key } from "./__generated__/ComponentIcon_component.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
  small: {
    width: 24,
    height: 24,

    "& .MuiIconButton-label": {
      width: "inherit",
    },

    "&$special": {
      width: 32,
      height: 32,
    },
  },
  special: {},
  icon: {
    color: theme.palette.secondary.main,

    "$small &": {
      width: 24,
      height: 24,
    },

    "$small$special &": {
      width: 32,
      height: 32,
    },

    '$small:not($special) & [stroke-width="2"]': {
      strokeWidth: 2.5,
    },
  },

  button: {
    padding: 0,
    borderRadius: 0,
  },
}));

const fragment = graphql`
  fragment ComponentIcon_component on ComponentIcon {
    iconName
    type
  }
`;

export interface ComponentIconProps extends IconButtonProps {
  componentRef: ComponentIcon_component$key;
  variant?: "button" | "icon";
}

export function ComponentIcon(props: ComponentIconProps) {
  const s = useStyles();
  const { className, componentRef, variant = "button", size, ...other } = props;
  const { iconName, type } = useFragment(fragment, componentRef);
  const Icon = ComponentIcons[iconName] || ComponentTypeDefaultIcons[type];
  const special = SpecialIconNames.includes(iconName);
  const classNames = clsx(s.root, className, {
    [s.small]: size === "small",
    [s.special]: special,
    [s.button]: variant === "button",
  });

  if (!Icon) {
    throw new Error(`Couldn't find "${iconName}" icon`);
  }

  const icon = <Icon className={s.icon} />;

  return variant === "button" ? (
    <IconButton className={classNames} disableRipple {...other} size="large">
      {icon}
    </IconButton>
  ) : (
    <Box className={classNames}>{icon}</Box>
  );
}
