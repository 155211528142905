import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  Radio,
  useTheme,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDrop, DropTargetMonitor, useDrag } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import React, { useRef, useState } from "react";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import { Icons } from "../../../plate-ui/Icons/icons";
import CheckInTextfield from "../checkInQuestions/CheckInTextfield";

const ItemType = {
  OPTION: "option",
};
interface MultipleChoiseOptionProps {
  checked?: boolean;
  onChange?: (checked: boolean, index: number) => void;
  index: number;
  handleAddClick: () => void;
  type: string;
  option: string;
  handleOptionValueChange: (e: any, index: number) => void;
  handleRemoveClick: (index: number) => void;
  onMoveEnd: (dragIndex: number, hoverIndex: number) => void;
  isReadOnly: boolean;
  view: EditorElementView;
  disableAnswer: boolean;
}
export const MultipleChoiceOption = ({
  checked,
  onChange,
  index,
  type,
  option,
  handleOptionValueChange,
  handleRemoveClick,
  onMoveEnd,
  isReadOnly,
  view,
  disableAnswer,
}: MultipleChoiseOptionProps) => {
  const theme = useTheme();

  const isCoachView = view === EditorElementView.Coach;
  const disableChoice = isCoachView || disableAnswer;
  const disableInput = !isCoachView || disableAnswer;

  const ref = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLButtonElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [, drop] = useDrop({
    accept: ItemType.OPTION,
    hover(draggedItem: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = draggedItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMoveEnd(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType.OPTION,
    item: { type: ItemType.OPTION, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(dragRef);
  drop(preview(ref));
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      key={index}
      ref={ref}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {!isReadOnly && (
        <IconButton
          ref={dragRef}
          disableRipple
          sx={{
            cursor: "grabbing",
            visibility: isHovered ? "visible" : "hidden",
          }}
        >
          <DragIndicatorIcon />
        </IconButton>
      )}
      <DisabledCheckInTooltip visible={isCoachView}>
        <Box>
          {type === "radio" && (
            <Radio
              disabled={disableChoice}
              checked={checked}
              onChange={(e, checked) => onChange && onChange(checked, index)}
              sx={{ mr: 0.5, ml: -1.25 }}
            />
          )}
          {type === "checkbox" && (
            <Checkbox
              disabled={disableChoice}
              checked={checked}
              onChange={(e, checked) => onChange && onChange(checked, index)}
              sx={{ mr: 0.5, ml: -1.25 }}
            />
          )}
        </Box>
      </DisabledCheckInTooltip>

      <CheckInTextfield
        variant="standard"
        placeholder="Add option"
        fullWidth
        value={option}
        onChange={(e) => handleOptionValueChange(e, index)}
        InputProps={{
          readOnly: isReadOnly && disableInput,
          disableUnderline: isReadOnly && disableInput,
        }}
        sx={{
          input: {
            "&::placeholder": {
              color: theme.palette.text.secondary,
              opacity: 1,
              fontWeight: 12,
            },
          },
        }}
      />

      {!isReadOnly && (
        <IconButton sx={{ ml: 0.5 }} onClick={() => handleRemoveClick(index)}>
          <Icons.bin color={theme.palette.text.primary} />
        </IconButton>
      )}
      <Divider />
    </Box>
  );
};
