import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  Button,
  TextFieldProps,
  Divider,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { BaseDialog } from "../dialog/BaseDialog";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import {
  ClientAddableItem,
  ClientAddableItemProps,
} from "../item/ClientAddableItem";
import { ReactComponent as Close } from "../../icons/Close.svg";
import { SearchField } from "../fields/SearchField";

import { ManageGroupDialog_group$key } from "./__generated__/ManageGroupDialog_group.graphql";
import { ManageGroupDialog_clients$key } from "./__generated__/ManageGroupDialog_clients.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: theme.spacing(65.5),
  },

  lozenges: {
    padding: theme.spacing(2, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  lozenge: {
    display: "inline-flex",
    height: 34,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 20,
    backgroundColor: `${theme.palette.primary.main}99`,
    padding: theme.spacing(0.5, 2),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",

    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  remove: {
    width: 22,
    height: 22,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },

  clients: {
    height: 438,
    overflowY: "auto",
  },

  item: {
    padding: theme.spacing(2.5, 0),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },

  inviteDivider: {
    margin: theme.spacing(2, -3),
  },
}));

const groupFragment = graphql`
  fragment ManageGroupDialog_group on Group {
    id
    name
    size
    clients {
      email
      displayName
    }
  }
`;

const clientsFragment = graphql`
  fragment ManageGroupDialog_clients on ClientConnection {
    edges {
      node {
        id
        email
        displayName
        ...ClientAddableItem_client
      }
    }
  }
`;

const updateGroupClientsMutation = graphql`
  mutation ManageGroupDialogUpdateGroupClientsMutation(
    $input: UpdateGroupClientsInput!
  ) {
    updateGroupClients(input: $input) {
      group {
        ...GroupCard_group
        ...GroupMembersList_groupRef
      }
    }
  }
`;

export interface ManageGroupDialogProps extends DialogProps {
  groupRef: ManageGroupDialog_group$key;
  clientsRef: ManageGroupDialog_clients$key;
}

export function ManageGroupDialog(props: ManageGroupDialogProps) {
  const { className, onClose, groupRef, clientsRef, ...other } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const group = useFragment(groupFragment, groupRef);
  const clients = useFragment(clientsFragment, clientsRef);
  const [updateGroupClients, updateInFlight] = useMutation(
    updateGroupClientsMutation,
  );
  const [filter, setFilter] = React.useState("");
  const [addedClients, setAddedClients] = React.useState(group.clients);

  const handleFilterChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [],
  );

  const handleFilterReset = React.useCallback(() => {
    setFilter("");
  }, []);

  const resetState = React.useCallback(() => {
    setFilter("");
    setAddedClients([]);
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleUpdate = React.useCallback(() => {
    const input = {
      id: group.id,
      emails: addedClients.map(({ email }) => email),
    };
    updateGroupClients({
      variables: { input },
      onCompleted(_data, errors) {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          handleClose();
          snackAlert({
            severity: "success",
            message: "Group updated.",
          });
        }
      },
      updater(store) {
        const node = store.get(input.id);
        const membersCount = addedClients.length;

        node.setValue(membersCount, "membersCount");
      },
      onError,
    });
  }, [
    addedClients,
    group.id,
    handleClose,
    onError,
    snackAlert,
    updateGroupClients,
  ]);

  const removeClient = React.useCallback(
    (email: string) => {
      const i = addedClients.findIndex(
        (addedClient) => addedClient.email === email,
      );

      if (i !== -1) {
        const updated = [...addedClients];
        updated.splice(i, 1);
        setAddedClients(updated);
      }
    },
    [addedClients],
  );

  const handleToggle: ClientAddableItemProps["onToggle"] = React.useCallback(
    (added, client) => {
      if (added) {
        setAddedClients((v) => [...v, client]);
      } else {
        removeClient(client.email);
      }
    },
    [removeClient],
  );

  const getRemoveHandler = React.useCallback(
    (email: string) => () => removeClient(email),
    [removeClient],
  );

  const filteredClients = React.useMemo(() => {
    const edges = filter
      ? clients.edges.filter(({ node: { displayName, email } }) =>
          [displayName, email].some((value) =>
            value.toLowerCase().includes(filter.toLowerCase()),
          ),
        )
      : clients.edges;

    return edges.map(({ node }) => node);
  }, [clients, filter]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={group.name}
      closeDisabled={updateInFlight}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <SearchField
        variant="outlined"
        fullWidth
        value={filter}
        onChange={handleFilterChange}
        onReset={handleFilterReset}
        placeholder="Search clients"
      />

      {/* Temporarily remove lozenges with false */}
      {Boolean(addedClients.length) && false && (
        <Box className={s.lozenges}>
          {addedClients.map(({ email, displayName }) => (
            <Box key={email} className={s.lozenge}>
              <Typography component="span" children={displayName} />
              <Close className={s.remove} onClick={getRemoveHandler(email)} />
            </Box>
          ))}
        </Box>
      )}

      <Box className={s.clients}>
        {filteredClients.map((client, i, arr) => {
          const added = Boolean(
            addedClients.find(({ email }) => email === client.email),
          );

          return (
            <React.Fragment key={client.id}>
              <ClientAddableItem
                className={s.item}
                clientRef={client}
                added={added}
                onToggle={handleToggle}
              />
              {i < arr.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </Box>

      <Divider />

      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children={group.size ? "Save changes" : "Add members"}
        onClick={handleUpdate}
        disabled={updateInFlight}
      />
    </BaseDialog>
  );
}
