import clsx from "clsx";
import React from "react";
import { BoxProps, Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { QueryRenderer } from "react-relay";
import { graphql, useRelayEnvironment } from "react-relay/hooks";

import { ActionButton } from "../button/ActionButton";
import { FieldError } from "../fields/FieldError";
import { planClients, Plan } from "../../constants";

import { EnterInviteCodeQuery } from "./__generated__/EnterInviteCodeQuery.graphql";
import { useNavigate } from "react-router-dom";
import { INVITE_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(5, 2),
    flexGrow: 1,
  },

  code: {
    width: "100%",
    marginTop: theme.spacing(4),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(9.5),
    },
  },

  codeChars: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },

  codeChar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    flexShrink: 0,
    flexGrow: 0,

    "& fieldset": {
      borderRadius: theme.spacing(0.5),
    },

    "& input": {
      fontSize: 24,
      fontWeight: 600,
      textAlign: "center",
      backgroundColor: "transparent !important",
      textTransform: "uppercase",
    },
  },
}));

export interface EnterInviteCodeProps extends BoxProps {
  codeLength?: number;
}

export function EnterInviteCode(props: EnterInviteCodeProps) {
  const { className, codeLength = 5, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const relay = useRelayEnvironment();
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [checking, setChecking] = React.useState(false);
  const chars = [...new Array(codeLength)].map(
    (_, index) => code.substring(index, index + 1) || "",
  );

  const disabled = code.length !== codeLength && !checking;
  const currentIndex = Math.min(code.length, codeLength - 1);

  const handleClick = React.useCallback(() => {
    setChecking(true);
  }, []);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const key = event.key;

      setErrorMessage("");

      if (key === "Backspace") {
        setCode((code) => code.substring(0, code.length - 1));
      }

      if (key === "Enter" && code.length === codeLength) {
        setChecking(true);
      }
    },
    [code.length, codeLength],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const chars = event.target.value;

      setCode((value) =>
        (value.substring(0, currentIndex + 1) + chars)
          .substring(0, codeLength)
          .toLowerCase(),
      );
    },
    [codeLength, currentIndex],
  );

  const handleQueryResponse = React.useCallback(
    (props: EnterInviteCodeQuery["response"]) => {
      if (props) {
        setTimeout(() => {
          setChecking(false);
          if (props.inviteCode?.coach) {
            const { clientsCountNoSample, plan } = props.inviteCode.coach;

            if (clientsCountNoSample >= Number(planClients(plan as Plan))) {
              setErrorMessage("Sorry, this coach can't add new clients.");
            } else {
              setErrorMessage("");
              navigate(INVITE_ROUTE.replace(":code", code));
            }
          } else {
            setErrorMessage("We couldn’t find a coach with that ID");
          }
        }, 0);
      }
    },
    [code],
  );

  const focusAtLastChar = React.useCallback(() => {
    const el = document.querySelector(
      `[data-char-index="${currentIndex}"] input`,
    );

    if (el && el !== document.activeElement && !checking) {
      (el as HTMLInputElement).focus();
    }
  }, [checking, currentIndex]);

  React.useLayoutEffect(() => {
    const timer = setInterval(focusAtLastChar, 25);

    return () => clearInterval(timer);
  }, [codeLength, currentIndex, focusAtLastChar]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.code}>
        <Box className={s.codeChars}>
          {chars.map((char, index) => (
            <TextField
              className={s.codeChar}
              variant="outlined"
              key={index}
              data-char-index={index}
              value={char}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              autoFocus={index === currentIndex}
            />
          ))}
        </Box>
        {errorMessage && <FieldError>{errorMessage}</FieldError>}
      </Box>
      <ActionButton
        size="large"
        fullWidth
        disabled={disabled}
        onClick={handleClick}
      >
        Continue
      </ActionButton>

      {checking && code && (
        <QueryRenderer<EnterInviteCodeQuery>
          environment={relay}
          query={graphql`
            query EnterInviteCodeQuery($code: String!) {
              inviteCode(code: $code) {
                id
                coach {
                  clientsCountNoSample: clientsCount(noSample: true)
                  plan
                }
              }
            }
          `}
          variables={{ code }}
          render={({ props }) => {
            handleQueryResponse(props);
            return null;
          }}
        />
      )}
    </Box>
  );
}
