import clsx from "clsx";
import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    fill: ({ fill }: TimeLockIconProps) => fill || theme.palette.common.black,
  },
  background: {
    fill: ({ backgroundFill }: TimeLockIconProps) =>
      backgroundFill || theme.palette.common.white,
  },
}));

export interface TimeLockIconProps extends SvgIconProps {
  fill?: string;
  backgroundFill?: string;
}

export function TimeLockIcon(props: TimeLockIconProps) {
  const { className, fill, backgroundFill, ...other } = props;
  const s = useStyles(props);

  return (
    <SvgIcon className={clsx(s.root, className)} {...other}>
      <path d="M9.55535 1.59387C5.15621 1.59387 1.59387 5.16418 1.59387 9.56332C1.59387 13.9625 5.15621 17.5328 9.55535 17.5328C13.9625 17.5328 17.5328 13.9625 17.5328 9.56332C17.5328 5.16418 13.9625 1.59387 9.55535 1.59387ZM9.56332 15.9389C6.04082 15.9389 3.18776 13.0858 3.18776 9.56332C3.18776 6.04082 6.04082 3.18776 9.56332 3.18776C13.0858 3.18776 15.9389 6.04082 15.9389 9.56332C15.9389 13.0858 13.0858 15.9389 9.56332 15.9389Z" />
      <path d="M9.02626 5.57861H10.2217V10.3603L6.03772 12.8707L5.44001 11.8904L9.02626 9.76257V5.57861Z" />
      <path
        d="M19.7295 12.5765H19.1059V11.4267C19.1059 9.84007 17.7092 8.55237 15.9883 8.55237C14.2673 8.55237 12.8706 9.84007 12.8706 11.4267V12.5765H12.2471C11.5612 12.5765 11 13.0938 11 13.7262V19.4749C11 20.1072 11.5612 20.6246 12.2471 20.6246H19.7295C20.4154 20.6246 20.9765 20.1072 20.9765 19.4749V13.7262C20.9765 13.0938 20.4154 12.5765 19.7295 12.5765ZM15.9883 17.7503C15.3024 17.7503 14.7412 17.2329 14.7412 16.6005C14.7412 15.9682 15.3024 15.4508 15.9883 15.4508C16.6742 15.4508 17.2353 15.9682 17.2353 16.6005C17.2353 17.2329 16.6742 17.7503 15.9883 17.7503ZM17.9212 12.5765H14.0553V11.4267C14.0553 10.4437 14.922 9.64462 15.9883 9.64462C17.0545 9.64462 17.9212 10.4437 17.9212 11.4267V12.5765Z"
        className={s.background}
      />
      <rect
        x="13"
        y="9.00003"
        width="10"
        height="11"
        className={s.background}
      />
      <path d="M18.5917 12.8362H18.1249V11.9084C18.1249 10.6281 17.0793 9.58902 15.791 9.58902C14.5027 9.58902 13.4571 10.6281 13.4571 11.9084V12.8362H12.9903C12.4769 12.8362 12.0568 13.2537 12.0568 13.764V18.4028C12.0568 18.9131 12.4769 19.3306 12.9903 19.3306H18.5917C19.1052 19.3306 19.5253 18.9131 19.5253 18.4028V13.764C19.5253 13.2537 19.1052 12.8362 18.5917 12.8362ZM15.791 17.0111C15.2776 17.0111 14.8575 16.5936 14.8575 16.0834C14.8575 15.5731 15.2776 15.1556 15.791 15.1556C16.3045 15.1556 16.7246 15.5731 16.7246 16.0834C16.7246 16.5936 16.3045 17.0111 15.791 17.0111ZM17.238 12.8362H14.344V11.9084C14.344 11.1152 14.9928 10.4704 15.791 10.4704C16.5892 10.4704 17.238 11.1152 17.238 11.9084V12.8362Z" />
    </SvgIcon>
  );
}
