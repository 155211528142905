import clsx from "clsx";
import React from "react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CardMedia } from "../card/CardMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    border: "3px solid",
    borderColor: theme.palette.background.paper,
    "&$selected": {
      borderColor: theme.palette.primary.main,
    },
  },
  selected: {},
  image: {
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(1),
  },
}));

export interface ImageButtonProps extends ButtonBaseProps {
  src: string;
  selected?: boolean;
}

export function ImageButton(props: ImageButtonProps) {
  const { className, src, selected, value, ...other } = props;
  const s = useStyles();

  return (
    <ButtonBase
      className={clsx(s.root, className, selected && s.selected)}
      data-src={src}
      {...other}
    >
      <CardMedia className={s.image} image={src} />
    </ButtonBase>
  );
}
