export function getTrialEndsText(days: number): string {
  if (days === 0) {
    return "today";
  } else {
    return `in ${days} day${days === 1 ? "" : "s"}`;
  }
}

export const maybePluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;
