import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DraggableSnippet } from "../editor/components/utils/DraggableSnippet";
import { ElementTypesDescriptions } from "./constants";
import { useItemDisabled } from "../new-editor/hooks";
import { InsertElementMenuItem } from "../plate-ui/insert-element-menu-item";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    cursor: "grab",
    "&:hover": {
      backgroundColor: theme.palette.selected.light,
    },
    padding: theme.spacing(1, 1, 1, 0),
  },
  preview: {
    width: "100%",
    marginLeft: theme.spacing(1),
  },
  icon: {
    backgroundColor: theme.palette.selected.light,
    marginRight: theme.spacing(1),
    width: 32,
    height: 32,
    padding: theme.spacing(1),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    "& svg": {
      width: "100%",
      height: "100%",
      transform: "scale(1.5)",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
  },
  disabled: {
    pointerEvents: "none",
    filter: theme.filters.disabled,
  },
}));

export interface ComponentLibrarySnippetProps extends BoxProps {
  elementType: string;
}

export const ComponentLibrarySource = "ComponentLibrary";

export function ComponentLibrarySnippet(props: ComponentLibrarySnippetProps) {
  const { className, elementType, ...other } = props;
  const s = useStyles();
  const info = ElementTypesDescriptions[elementType];
  const { text: name, icon: Icon, description } = info;

  const isItemDisabled = useItemDisabled();

  return (
    <Box
      className={clsx(
        s.root,
        className,
        isItemDisabled(elementType) && s.disabled,
      )}
      {...other}
    >
      <DraggableSnippet
        content={{ type: elementType, source: ComponentLibrarySource }}
        draggableContent
      >
        <InsertElementMenuItem
          itemLabel={name}
          itemDescription={description}
          Icon={Icon}
        />
      </DraggableSnippet>
    </Box>
  );
}
