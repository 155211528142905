import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.75,
    textTransform: "uppercase",
    lineHeight: "16px",
    padding: theme.spacing(0.75, 1),
    color: colorSystem.green2,
    backgroundColor: lighten(colorSystem.green2, 0.8),
    borderRadius: theme.spacing(0.5),
  },
}));

export interface AdminTagProps extends TypographyProps {}

export function AdminTag(props: AdminTagProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Typography
      className={clsx(s.root, className)}
      children="Admin"
      {...other}
    />
  );
}
