import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getCloudFlareSources, isVideoStream } from "../../utils/component";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  video: {
    minWidth: "100%",
    width: "100%",
    maxWidth: "100%",
    minHeight: 200,
    maxHeight: 200,
    height: 200,
    display: "flex",

    "& video": {
      width: "100%",
      height: "100%",
    },

    "& [data-vjs-player]": {
      paddingTop: "unset",
      height: 200,

      [theme.breakpoints.up("md")]: {
        height: 200,
      },
    },

    "& .vjs-big-play-button": {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
}));

export interface WorkoutMediaProps extends BoxProps {
  video: any;
}

export function WorkoutDemoVideoPreview(props: WorkoutMediaProps) {
  const { className, video, ...other } = props;
  const s = useStyles();
  const sources = getCloudFlareSources(video);
  return (
    <Box className={clsx(s.root, className)} {...other}>
      {isVideoStream(video) ? (
        <ReactPlayer className={s.video} url={sources[2].src} controls />
      ) : (
        <ReactPlayer className={s.video} url={video} controls />
      )}
    </Box>
  );
}
