import clsx from "clsx";

import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { Calendar } from "../../../components/activity/Calendar";
import HomeCalendarContext from "../../../contexts/HomeCalendarContext";
import { Container, useTheme } from "@mui/material";

const HomeWrapper = () => {
  const calendarPortal = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  return (
    <>
      <HomeCalendarContext.Provider value={calendarPortal}>
        <Container
          style={{
            maxWidth: theme.breakpoints.values.slg,
            marginTop: theme.spacing(4),
            position: "relative",
          }}
        >
          <div ref={calendarPortal} />
          <Suspense
            fallback={
              <PageSkeleton
                fullWidth
                style={{ marginBlock: 16, paddingInline: 24 }}
              />
            }
          >
            <Outlet />
          </Suspense>
        </Container>
      </HomeCalendarContext.Provider>
    </>
  );
};

export default HomeWrapper;
