import React, { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CommonSuspenseWrapper from "../client/CommonSuspenseWrapper";
import { Breakpoint, Theme, useMediaQuery } from "@mui/material";

const CoachGroupsWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.search) {
      url.search = "";
      navigate(url.pathname, { replace: true });
    }
  }, [location.pathname, navigate]);

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const isPostsRoute = location.pathname.includes("posts");
  const wrapperProps = React.useMemo(() => {
    return {
      maxWidth: (isPostsRoute ? "md" : "lg") as Breakpoint,
      ...(!isSmallScreen && isPostsRoute && { sx: { marginRight: 100 } }),
    };
  }, [isPostsRoute, isSmallScreen]);

  return CommonSuspenseWrapper(wrapperProps);
};

export default CoachGroupsWrapper;
