import clsx from "clsx";
import React, { useTransition } from "react";
import {
  Box,
  BoxProps,
  Divider,
  Button,
  Skeleton,
  ListSubheader,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { useDebounce } from "../../hooks/useDebounce";
import { AssetType } from "../../constants";

import { ComponentLibraryAssetList_rootRef$key } from "./__generated__/ComponentLibraryAssetList_rootRef.graphql";
import {
  LibrarySections,
  LibrarySection,
  defaultAssetTypes,
} from "./constants";
import {
  ComponentLibraryAsset,
  ComponentLibraryAssetListMode,
} from "./ComponentLibraryAsset";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: theme.spacing(2, 1),
  },
  list: {},
  divider: {
    backgroundColor: theme.palette.quote,
    margin: theme.spacing(0, -4, 0, 4),
  },

  subHeader: {
    textTransform: "uppercase",
    fontWeight: 700,
  },

  moreButton: {
    marginTop: theme.spacing(1.5),

    "$grid &": {
      gridColumn: "1 / -1",
    },
  },

  loading: {
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "$grid &": {
      gridColumn: "1 / -1",
    },
  },
  skeletonItem: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}));

const rootRefFragment = graphql`
  fragment ComponentLibraryAssetList_rootRef on Root
  @refetchable(queryName: "ComponentLibraryAssetListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 0 }
    after: { type: "String" }
    query: { type: "String" }
    assetType: { type: "[CustomAssetType!]" }
  ) {
    custom_assets(
      first: $first
      after: $after
      query: $query
      assetType: $assetType
    ) @connection(key: "ComponentLibraryAssetList_custom_assets", filters: []) {
      edges {
        node {
          id
          ...ComponentLibraryAsset_asset
        }
      }
    }
  }
`;

export interface ComponentLibraryAssetListProps extends BoxProps {
  rootRefRef: ComponentLibraryAssetList_rootRef$key;
  pageSize?: number;
  mode?: ComponentLibraryAssetListMode;
  query?: string;
  disableHeader?: boolean;
  assetTypes?: AssetType[];
}

export function ComponentLibraryAssetList(
  props: ComponentLibraryAssetListProps,
) {
  const {
    className,
    pageSize = 12,
    query = "",
    mode = ComponentLibraryAssetListMode.GRID,
    rootRefRef,
    disableHeader = true,
    assetTypes = defaultAssetTypes,
    ...other
  } = props;
  const {
    data: rootRef,
    loadNext,
    hasNext,
    refetch: refetchConnection,
  } = usePaginationFragment(rootRefFragment, rootRefRef);
  const s = useStyles();
  const { custom_assets: assets } = rootRef;
  const [loading, setLoading] = React.useState(true);
  const delayedQuery = useDebounce(query, 500);
  const [isPending, setTransition] = useTransition();

  const isGrid = mode === ComponentLibraryAssetListMode.GRID;
  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    setLoading(true);
    loadNext(pageSize, { onComplete: setLoaded });
  }, [pageSize, loadNext, setLoaded]);

  React.useEffect(() => {
    setTransition(() => {
      refetchConnection(
        {
          query: delayedQuery,
          first: pageSize,
          assetType: assetTypes,
        },
        {
          ...RELAY_LAZY_LOAD_COMMON_CONFIG,
          onComplete: setLoaded,
        },
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(assetTypes), delayedQuery, pageSize, refetchConnection]);

  const edges = (assets?.edges || []).filter(({ node }) => node);

  const renderSkeletons = () => {
    const skeletonCount = isGrid ? 3 : 6;
    return Array.from(new Array(skeletonCount)).map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        className={s.skeletonItem}
        height={isGrid ? 200 : 50}
        width={"100%"}
      />
    ));
  };

  if (!edges.length && !loading) return <Typography>Nothing found</Typography>;

  return (
    <Box
      className={clsx(s.root, className, isGrid ? s.grid : s.list)}
      {...other}
    >
      {!disableHeader && edges.length > 0 && (
        <ListSubheader className={s.subHeader} disableGutters disableSticky>
          {LibrarySections[LibrarySection.ASSET_LIBRARY].name}
        </ListSubheader>
      )}
      {isPending
        ? renderSkeletons()
        : edges.map(({ node }, index) => (
            <React.Fragment key={node.id}>
              {!isGrid && index !== 0 && <Divider className={s.divider} />}
              <ComponentLibraryAsset assetRef={node} mode={mode} />
            </React.Fragment>
          ))}

      {!loading && !isPending && hasNext && (
        <Button
          onClick={handleMoreClick}
          className={s.moreButton}
          fullWidth
          disabled={loading}
        >
          Load more
        </Button>
      )}
    </Box>
  );
}
