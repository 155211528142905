import clsx from "clsx";
import React from "react";
import { TextField, StandardTextFieldProps, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useReadOnly } from "slate-react";

const useStyles = makeStyles((theme) => ({
  root: {},

  input: {
    color: theme.palette.text.primary,
  },

  text: {},
}));

export type WorkoutTitleClassKey = "root" | "input" | "text";

export interface WorkoutTitleProps
  extends Omit<StandardTextFieldProps, "onChange"> {
  classes?: Partial<ClassNameMap<WorkoutTitleClassKey>>;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: string,
  ) => void;
  value?: string;
}

export function WorkoutTitle(props: WorkoutTitleProps) {
  const {
    className,
    onChange,
    value = "",
    defaultValue,
    classes = {},
    ...other
  } = props;
  const { disabled } = props;
  const s = useStyles();
  const readOnly = useReadOnly();
  const [edit, setEdit] = React.useState(false);
  const [title, setTitle] = React.useState(value);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTitle(event.currentTarget.value);
    },
    [],
  );

  const handleTextClick = React.useCallback(
    (event) => {
      event.stopPropagation();

      if (!readOnly && !disabled) {
        setEdit(true);
      }
    },
    [readOnly, disabled],
  );

  const handleFieldClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleBlur = React.useCallback(
    (event) => {
      setEdit(false);
      if (onChange) {
        onChange(event, title);
      }
    },
    [onChange, title],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        handleBlur(event);
      }

      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        setTitle(value);
        setEdit(false);
      }
    },
    [value, handleBlur],
  );

  React.useEffect(() => {
    if (value !== title) {
      setTitle(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return edit ? (
    <TextField
      autoFocus
      className={clsx(s.root, className, classes.root)}
      value={title}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onClick={handleFieldClick}
      fullWidth
      multiline
      InputProps={{
        classes: {
          input: clsx(s.input, s.text, classes.text, classes.input),
        },
        disableUnderline: true,
      }}
      {...other}
    />
  ) : (
    <Typography
      className={clsx(s.root, className, classes.root, s.text, classes.text)}
      variant="h5"
      onClick={handleTextClick}
    >
      {title || (defaultValue as string)}
    </Typography>
  );
}
