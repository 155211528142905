/**
 * @generated SignedSource<<eefcdf5c92ecaa0a2861cf7f02008a84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachSettingsPlanTextSubtitle_user$data = {
  readonly plan: Plan | null | undefined;
  readonly trialExpired: boolean;
  readonly trialExpiryDate: string | null | undefined;
  readonly " $fragmentType": "CoachSettingsPlanTextSubtitle_user";
};
export type CoachSettingsPlanTextSubtitle_user$key = {
  readonly " $data"?: CoachSettingsPlanTextSubtitle_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachSettingsPlanTextSubtitle_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachSettingsPlanTextSubtitle_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "trialExpiryDate",
      "storageKey": "trialExpiryDate(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trialExpired",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "78a58c7592cb3051c61ab78b2b1862bd";

export default node;
