import clsx from "clsx";
import React from "react";
import { BoxProps, Button, Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as WelcomeArt } from "../../icons/ClientWelcomeScreenArt.svg";
import ClientWelcomeScreenArt from "../../icons/ClientWelcomeScreenArt";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(5, 2),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(2.25),
  },
}));

export interface WelcomeClientProps extends BoxProps {
  onSignIn?: () => void;
  onSignUp?: () => void;
}

export function WelcomeClient(props: WelcomeClientProps) {
  const { className, onSignIn, onSignUp, ...other } = props;
  const s = useStyles();
  const theme = useTheme();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box>
        <ClientWelcomeScreenArt fill={theme.palette.primary.main} />
      </Box>
      <Button
        className={s.button}
        variant="contained"
        color="primary"
        fullWidth
        onClick={onSignIn}
      >
        Log In
      </Button>
      <Button className={s.button} variant="text" fullWidth onClick={onSignUp}>
        I have an invite code
      </Button>
    </Box>
  );
}
