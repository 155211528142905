import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { SOMETHING_WENT_WRONG } from "../../../constants";

import { UpdateThemeMutation } from "./__generated__/UpdateThemeMutation.graphql";

const mutation = graphql`
  mutation UpdateThemeMutation($input: UpdateThemeInput!) {
    updateTheme(input: $input) {
      workspace {
        ...ThemeProvider_workspace
      }
    }
  }
`;

export const useUpdateThemeMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<UpdateThemeMutation>(
    mutation,
    ({ onSuccess }) => ({
      onSuccess: (result) => {
        snackAlert({
          severity: "success",
          message: "Theme settings updated",
        });

        if (onSuccess) {
          onSuccess(result);
        }
      },
      onFailure: ([error]) =>
        snackAlert({
          severity: "error",
          message:
            error.message === "ERROR_INVALID_COLOR"
              ? "Invalid color format, only values #nnn or #nnnnnn are supported"
              : SOMETHING_WENT_WRONG,
        }),
    }),
  );
};
