/**
 * @generated SignedSource<<ffeac91b25ee3ff1b03899a35d4b2d3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LikeListItem_like$data = {
  readonly author: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
  };
  readonly " $fragmentType": "LikeListItem_like";
};
export type LikeListItem_like$key = {
  readonly " $data"?: LikeListItem_like$data;
  readonly " $fragmentSpreads": FragmentRefs<"LikeListItem_like">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LikeListItem_like",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Like",
  "abstractKey": null
};

(node as any).hash = "f2e1dc35fe2d267cbb47607dcac14e30";

export default node;
