/**
 * @generated SignedSource<<599b826313038364f4aafdd518a39e93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeClientGoalOrdinalInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  idOrdinal: string;
};
export type ChangeClientGoalOrdinalMutation$variables = {
  input: ChangeClientGoalOrdinalInput;
};
export type ChangeClientGoalOrdinalMutation$data = {
  readonly changeClientGoalOrdinal: {
    readonly client: {
      readonly goals: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
        } | null | undefined> | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ClientGoalsCard_goals">;
      };
    };
    readonly goal: {
      readonly " $fragmentSpreads": FragmentRefs<"ClientGoal_goal">;
    };
  } | null | undefined;
};
export type ChangeClientGoalOrdinalMutation = {
  response: ChangeClientGoalOrdinalMutation$data;
  variables: ChangeClientGoalOrdinalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetValue",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentValue",
  "storageKey": null
},
v9 = {
  "alias": "formattedDate",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "MMM D, YYYY"
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(format:\"MMM D, YYYY\")"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeClientGoalOrdinalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeClientGoalOrdinalPayload",
        "kind": "LinkedField",
        "name": "changeClientGoalOrdinal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientGoal",
            "kind": "LinkedField",
            "name": "goal",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientGoal_goal"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              {
                "alias": "goals",
                "args": null,
                "concreteType": "ClientGoalConnection",
                "kind": "LinkedField",
                "name": "__ClientDetails_goals_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientGoalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClientGoal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClientGoalsCard_goals"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeClientGoalOrdinalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeClientGoalOrdinalPayload",
        "kind": "LinkedField",
        "name": "changeClientGoalOrdinal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientGoal",
            "kind": "LinkedField",
            "name": "goal",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "ClientGoalConnection",
                "kind": "LinkedField",
                "name": "goals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientGoalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClientGoal",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "MMM DD, YYYY"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "updatedAt",
                            "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
                          },
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "raw",
                                "value": true
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": "date(raw:true)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": "goals(first:9999)"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "ClientDetails_goals",
                "kind": "LinkedHandle",
                "name": "goals"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "108a498a7ff9611e91b903d6928c4123",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "changeClientGoalOrdinal",
            "client",
            "goals"
          ]
        }
      ]
    },
    "name": "ChangeClientGoalOrdinalMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeClientGoalOrdinalMutation(\n  $input: ChangeClientGoalOrdinalInput!\n) {\n  changeClientGoalOrdinal(input: $input) {\n    goal {\n      ...ClientGoal_goal\n      id\n    }\n    client {\n      goals(first: 9999) {\n        edges {\n          cursor\n          node {\n            __typename\n            id\n          }\n        }\n        ...ClientGoalsCard_goals\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment ClientGoal_goal on ClientGoal {\n  id\n  name\n  targetValue\n  currentValue\n  formattedDate: date(format: \"MMM D, YYYY\")\n  completed\n}\n\nfragment ClientGoalsCard_goals on ClientGoalConnection {\n  totalCount\n  edges {\n    node {\n      id\n      updatedAt(format: \"MMM DD, YYYY\")\n      ...ClientGoal_goal\n    }\n  }\n  ...ClientGoalsDrawer_goals\n}\n\nfragment ClientGoalsDrawer_goals on ClientGoalConnection {\n  totalCount\n  edges {\n    node {\n      id\n      name\n      targetValue\n      currentValue\n      date(raw: true)\n      formattedDate: date(format: \"MMM D, YYYY\")\n    }\n  }\n  ...ClientGoalsList_goals\n}\n\nfragment ClientGoalsList_goals on ClientGoalConnection {\n  edges {\n    node {\n      id\n      name\n      targetValue\n      currentValue\n      date(raw: true)\n      formattedDate: date(format: \"MMM D, YYYY\")\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b567d27ba4f79c1c114f2f43a9be2e74";

export default node;
