/**
 * @generated SignedSource<<9827913a1ad7ce114c43b23a2e690e60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientExercisesRefetchQuery$variables = {
  after?: string | null | undefined;
  clientId?: string | null | undefined;
  first?: number | null | undefined;
  query?: string | null | undefined;
};
export type ClientExercisesRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ClientExercises_data">;
};
export type ClientExercisesRefetchQuery = {
  response: ClientExercisesRefetchQuery$data;
  variables: ClientExercisesRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId"
  },
  {
    "defaultValue": 99,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "clientId",
    "variableName": "clientId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientExercisesRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ClientExercises_data"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientExercisesRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserExerciseConnection",
        "kind": "LinkedField",
        "name": "user_exercises",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserExerciseEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserExercise",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "exerciseId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomAsset",
                    "kind": "LinkedField",
                    "name": "exercise",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM D.YY"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": "completedAt(format:\"MMM D.YY\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ClientExercises_user_exercises",
        "kind": "LinkedHandle",
        "name": "user_exercises"
      }
    ]
  },
  "params": {
    "cacheID": "ff1f9196f61181dfa99e20f2d1e56f80",
    "id": null,
    "metadata": {},
    "name": "ClientExercisesRefetchQuery",
    "operationKind": "query",
    "text": "query ClientExercisesRefetchQuery(\n  $after: String\n  $clientId: ID = null\n  $first: Int = 99\n  $query: String\n) {\n  ...ClientExercises_data_7TAAi\n}\n\nfragment ClientExercises_data_7TAAi on Root {\n  user_exercises(first: $first, after: $after, clientId: $clientId, query: $query) {\n    edges {\n      node {\n        id\n        exerciseId\n        exercise {\n          name\n          id\n        }\n        title\n        completedAt(format: \"MMM D.YY\")\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5588fb0b005ceb2b14e352bd02681e13";

export default node;
