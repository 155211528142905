import clsx from "clsx";
import React from "react";
import { IconButton, Popover, Box, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RenderElementProps, ReactEditor } from "slate-react";
import {
  usePopupState,
  bindTrigger,
  bindPopper,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { colorSystem } from "../../../../theme";
import { ReactComponent as AddIcon } from "../../../../icons/AddOutline.svg";
import { ReactComponent as RemoveIcon } from "../../../../icons/Bin.svg";

import {
  setAddButtonPosition,
  getCellEl,
  getCellPosition,
  updateTable,
  TableSelector,
  highlightTableCells,
  setMoreButtonPosition,
  insertTableCells,
  removeTableCells,
} from "./utils";
import { useEditorRef } from "@udecode/plate-common";
import { Icons } from "../../../plate-ui/Icons/icons";
import AnimateHeightBox from "../../elements/common/AnimateHeightBox";
import { useReadOnly } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    margin: theme.spacing(1, 0),
  },

  selected: {},

  wrapper: {
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    scrollbarWidth: "thin", // Firefox scrollbar width
    scrollbarColor: `${colorSystem.gray4} ${colorSystem.gray4}`, // Firefox scrollbar color

    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px ${colorSystem.gray4}`,
    },
    "&::-webkit-scrollbar-thumb": {
      border: `1px solid ${colorSystem.gray4}`,
    },
  },

  table: {
    width: "100%",
    borderCollapse: "collapse",

    "& th": {
      whiteSpace: "pre",
    },

    "& th:hover, & td:hover, & th.selected, & td.selected": {
      backgroundColor: alpha(theme.palette.primary.light, 0.05),
    },
  },

  addButtonWrapper: {
    position: "absolute",
    display: "none",
    top: 0,
    left: 0,
    "$selected:hover &": {
      display: "block",
    },
  },

  addButton: {
    padding: theme.spacing(0.5),
    marginTop: -1,
    zIndex: 1,

    backgroundColor: theme.palette.common.white,
    color: colorSystem.gray7,

    "& > span": {
      position: "relative",
      zIndex: 2,
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },

  divider: {
    content: '""',
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    borderWidth: 0,
    margin: 0,
    display: "none",
    "$addButtonWrapper:hover &": {
      display: "block",
    },
  },

  moreButton: {
    position: "absolute",
    borderRadius: 4,
    padding: 0,
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: 0,
    display: "none",
    zIndex: 1,
    backgroundColor: "transparent",

    "$selected:hover &": {
      display: "flex",
    },
    "$selected &": {
      display: "flex",
    },
  },

  horizontal: {
    "& > span": {
      transform: "rotate(90deg)",
    },
  },

  moreOpen: {
    display: "block",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  removeButton: {
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5),

    "& svg": {
      width: 16,
      height: 16,
    },
  },
}));

export interface TableElementProps extends RenderElementProps {}

export const ELEMENT_TABLE = "table";

export function TableElement(props: TableElementProps) {
  const [selector, setSelector] = React.useState<TableSelector>();
  const s = useStyles();
  const { element, children } = props;
  const editorRef = useEditorRef();
  const editor = editorRef as any;
  const tableRef = React.useRef<HTMLTableElement>();
  const addButtonRef = React.useRef<HTMLDivElement>();
  const moreButtonRef = React.useRef<HTMLButtonElement>();
  const table = element as any;
  const readOnly = useReadOnly();

  const popperState = usePopupState({
    popupId: "table-popover",
    variant: "popper",
  });

  const menuState = usePopupState({
    variant: "popover",
    popupId: "table-menu",
  });

  const handleMouseMove = React.useCallback(
    (event: React.MouseEvent<HTMLTableElement, MouseEvent>) => {
      const el = getCellEl(event.target as HTMLElement);
      const cell = el && (ReactEditor.toSlateNode(editor, el) as any);

      if (cell && !readOnly) {
        const position = getCellPosition(table, cell);
        const rect = el.getBoundingClientRect();

        if (rect && position) {
          const orientation = position.row === 0 ? "vertical" : "horizontal";
          const direction = (
            orientation === "vertical"
              ? event.clientX - rect.x > rect.width / 2
              : event.clientY - rect.y > rect.height / 2
          )
            ? "after"
            : "before";

          const selector: TableSelector = {
            ...position,
            orientation,
            direction,
          };

          setAddButtonPosition(addButtonRef.current, el, selector);
          setMoreButtonPosition(moreButtonRef.current, el, selector);
          setSelector(selector);
        }
      } else {
        setSelector(null);
      }
    },
    [editor, readOnly, table],
  );

  const handleWrapperScroll = React.useCallback(() => {
    setSelector(null);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setSelector(null);
  }, []);

  const handleAddClick = React.useCallback(() => {
    insertTableCells(editor, table, selector);
  }, [editor, selector, table]);

  const handleMoreButtonHover = React.useCallback(() => {
    setSelector({
      ...selector,
      highlight: true,
    });
  }, [selector]);

  React.useEffect(() => {
    updateTable(editor, table);

    if (tableRef.current) {
      highlightTableCells(tableRef.current, selector);
    }
  });

  const handleRemoveClick = React.useCallback(() => {
    popperState.close();
    removeTableCells(editor, table, selector);
  }, [editor, popperState, selector, table]);

  return (
    <Box
      className={clsx(s.root, selector && s.selected)}
      onMouseLeave={handleMouseLeave}
    >
      <AnimateHeightBox className={s.wrapper} onScroll={handleWrapperScroll}>
        <table
          ref={tableRef}
          className={s.table}
          onMouseMoveCapture={handleMouseMove}
        >
          <tbody>{children}</tbody>
        </table>
      </AnimateHeightBox>

      {!readOnly && (
        <div
          ref={addButtonRef}
          className={s.addButtonWrapper}
          contentEditable={false}
        >
          <IconButton
            className={s.addButton}
            contentEditable={false}
            onClick={handleAddClick}
            size="large"
          >
            <AddIcon />
          </IconButton>
          <hr className={s.divider} />
        </div>
      )}

      {!readOnly && (
        <IconButton
          ref={moreButtonRef}
          contentEditable={false}
          className={clsx(
            s.moreButton,
            selector?.orientation === "horizontal" && s.horizontal,
            popperState.isOpen && s.moreOpen,
          )}
          onMouseEnter={handleMoreButtonHover}
          {...bindTrigger(popperState)}
          size="large"
        >
          {selector?.orientation === "horizontal" ? (
            <Icons.moreVertical />
          ) : (
            <Icons.moreHorizontal />
          )}
        </IconButton>
      )}
      {!readOnly && selector && (
        <Popover
          anchorOrigin={
            selector.orientation === "vertical"
              ? {
                  vertical: "top",
                  horizontal: "center",
                }
              : {
                  vertical: "center",
                  horizontal: "right",
                }
          }
          transformOrigin={
            selector.orientation === "vertical"
              ? {
                  vertical: "bottom",
                  horizontal: "center",
                }
              : {
                  vertical: "center",
                  horizontal: "left",
                }
          }
          {...bindPopper(popperState)}
          onClose={popperState.close}
          contentEditable={false}
        >
          <IconButton
            className={s.removeButton}
            onClick={handleRemoveClick}
            size="large"
          >
            <RemoveIcon />
          </IconButton>
        </Popover>
      )}
    </Box>
  );
}
