import { useRef, useEffect } from "react";

/*
    Hook to focus an input element after a timeout.
    This might be useful when a component is not mounted when the top-level component loads.
    See https://stackoverflow.com/a/44587705
*/
const useAutoFocusWithTimeout = () => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return inputRef;
};

export default useAutoFocusWithTimeout;
