/**
 * @generated SignedSource<<3647555f995dac26189ac62bc112a9d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityWorkoutResponse_workoutExerciseResult$data = {
  readonly id: string;
  readonly value: string;
  readonly " $fragmentType": "ActivityWorkoutResponse_workoutExerciseResult";
};
export type ActivityWorkoutResponse_workoutExerciseResult$key = {
  readonly " $data"?: ActivityWorkoutResponse_workoutExerciseResult$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityWorkoutResponse_workoutExerciseResult">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityWorkoutResponse_workoutExerciseResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "WorkoutExerciseResult",
  "abstractKey": null
};

(node as any).hash = "b4b4029c88a6b2d9077be5aff44b7988";

export default node;
