/**
 * @generated SignedSource<<3189a88aa039a1af651dda172f60a392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ComponentPublishButton_component$data = {
  readonly draftExists: boolean;
  readonly status: ComponentStatus;
  readonly type: ComponentType;
  readonly " $fragmentType": "ComponentPublishButton_component";
};
export type ComponentPublishButton_component$key = {
  readonly " $data"?: ComponentPublishButton_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentPublishButton_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ComponentPublishButton_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftExists",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "4dae65426ce9026338ea1ae2dbe0b589";

export default node;
