import clsx from "clsx";
import React, { useTransition } from "react";
import { Button, List, ListProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { useDebounce } from "../../hooks/useDebounce";

import { GroupMembersList_groupRef$key } from "./__generated__/GroupMembersList_groupRef.graphql";
import { GroupMemberListItem } from "./GroupMemberListItem";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },

  empty: {
    paddingBottom: theme.spacing(3),
  },
}));

const groupRefFragment = graphql`
  fragment GroupMembersList_groupRef on Group
  @refetchable(queryName: "GroupMembersListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    query: { type: "String" }
  ) {
    id
    members(first: $first, after: $after, query: $query)
      @connection(key: "GroupMembersList_members", filters: []) {
      edges {
        node {
          id
          ...GroupMemberListItem_member
        }
      }
    }
  }
`;

export interface GroupMembersListProps extends ListProps {
  groupRefRef: GroupMembersList_groupRef$key;
  query?: string;
  pageSize?: 10;
}

export function GroupMembersList(props: GroupMembersListProps) {
  const { className, query = "", groupRefRef, pageSize = 10, ...other } = props;
  const {
    data: groupRef,
    refetch: refetchConnection,
    hasNext,
    loadNext,
    isLoadingNext,
  } = usePaginationFragment(groupRefFragment, groupRefRef);
  const s = useStyles();
  const { id, members } = groupRef;
  const [loading, setLoading] = React.useState(false);
  const delayedQuery = useDebounce(query, 250);
  const setLoaded = React.useCallback(() => setLoading(false), []);
  const [isPending, setTransition] = useTransition();

  const handleLoadMoreClick = () => {
    loadNext(pageSize);
  };

  React.useEffect(() => {
    if (!loading) {
      setTransition(() => {
        refetchConnection(
          {
            query: delayedQuery,
            first: pageSize,
          },
          {
            onComplete: setLoaded,
            ...RELAY_LAZY_LOAD_COMMON_CONFIG,
          },
        );
      });
    }
  }, [delayedQuery, pageSize, refetchConnection, setLoaded, loading]);

  const edges = (members?.edges || []).filter(({ node }) => node);

  return edges.length > 0 ? (
    <List className={clsx(s.root, className)} {...other}>
      {edges.map(({ node }, index) => (
        <GroupMemberListItem
          key={node.id}
          groupId={id}
          memberRef={node}
          divider={index < edges.length - 1}
        />
      ))}
      {hasNext && (
        <Button
          onClick={handleLoadMoreClick}
          disabled={isLoadingNext}
          fullWidth
        >
          Show more
        </Button>
      )}
    </List>
  ) : (
    <Typography component="div" className={s.empty}>
      Nothing found
    </Typography>
  );
}
