/**
 * @generated SignedSource<<e93091d8748dab967abae104728e47ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HabitCheckInProgress_enrollment$data = {
  readonly habitsCheckIns: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"HabitCheckInProgressTable_activities">;
  } | null | undefined>;
  readonly startDate: string | null | undefined;
  readonly " $fragmentType": "HabitCheckInProgress_enrollment";
};
export type HabitCheckInProgress_enrollment$key = {
  readonly " $data"?: HabitCheckInProgress_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HabitCheckInProgress_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "period"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HabitCheckInProgress_enrollment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(raw:true)"
    },
    {
      "alias": "habitsCheckIns",
      "args": [
        {
          "kind": "Literal",
          "name": "componentTypes",
          "value": [
            "HABIT",
            "CHECKIN"
          ]
        }
      ],
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activities",
      "plural": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "period",
              "variableName": "period"
            }
          ],
          "kind": "FragmentSpread",
          "name": "HabitCheckInProgressTable_activities"
        }
      ],
      "storageKey": "activities(componentTypes:[\"HABIT\",\"CHECKIN\"])"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "d9e3e1bf1a06d9046891b6248d0a23aa";

export default node;
