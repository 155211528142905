/**
 * @generated SignedSource<<2c953848063a3ccb49778732e14e8cd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramActivitySummaryRouteQuery$variables = {
  component: string;
  date: string;
};
export type ClientProgramActivitySummaryRouteQuery$data = {
  readonly activity: {
    readonly " $fragmentSpreads": FragmentRefs<"TrainingSummary_activity">;
  } | null | undefined;
};
export type ClientProgramActivitySummaryRouteQuery = {
  response: ClientProgramActivitySummaryRouteQuery$data;
  variables: ClientProgramActivitySummaryRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "component"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v2 = [
  {
    "kind": "Literal",
    "name": "client",
    "value": "$me"
  },
  {
    "kind": "Variable",
    "name": "component",
    "variableName": "component"
  },
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProgramActivitySummaryRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TrainingSummary_activity"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientProgramActivitySummaryRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startWorkout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endWorkout",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa03fe0b5b31322b9bf5f170305ac927",
    "id": null,
    "metadata": {},
    "name": "ClientProgramActivitySummaryRouteQuery",
    "operationKind": "query",
    "text": "query ClientProgramActivitySummaryRouteQuery(\n  $date: String!\n  $component: String!\n) {\n  activity(client: \"$me\", date: $date, component: $component) {\n    ...TrainingSummary_activity\n    id\n  }\n}\n\nfragment TrainingSummary_activity on Activity {\n  id\n  clientId\n  content\n  startWorkout\n  endWorkout\n}\n"
  }
};
})();

(node as any).hash = "3068879790d74834bc96e0b1e547f883";

export default node;
