import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { LikeMutation } from "./__generated__/LikeMutation.graphql";

const mutation = graphql`
  mutation LikeMutation($input: LikeInput!) {
    like(input: $input) {
      likeEdge {
        node {
          ...LikeListItem_like
        }
      }
    }
  }
`;

export const useLikeMutation = () =>
  useConfiguredMutation<LikeMutation>(mutation, (config) => ({
    configs: [
      {
        type: "RANGE_ADD",
        parentID: config.variables.input.id,
        connectionInfo: [
          {
            key: "LikesList_likes",
            rangeBehavior: "prepend",
          },
        ],
        edgeName: "likeEdge",
      },
    ],
    updater(store) {
      const node = store.get(config.variables.input.id);
      node.setValue(true, "likedByMe");
    },
  }));
