import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams, useSearchParams } from "react-router-dom";
import {
  EnrollmentSort,
  GroupEnrollmentSort,
  maxEnrollments,
} from "../../../../constants";

import { EnrolledAvatars } from "../../../../components/coach-programs/EnrolledAvatars";
import { EnrollAction } from "../../../../components/coach-programs/EnrollAction";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { EnrollmentsFilters } from "../../../../components/filters/EnrollmentsFilters";
import { coachProgramNavigation, programsBreadcrumb } from "../utils/common";
import { ProgramEnrollments } from "../../../../components/coach-programs/ProgramEnrollments";
import { CoachProgramEnrollmentsRouteQuery } from "./__generated__/CoachProgramEnrollmentsRouteQuery.graphql";
import { PaginationContext } from "../../../../utils/paging";
import { ProgramContext } from "../../../../contexts/ProgramContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachProgramEnrollmentsRoute() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("sort");
  const { slug } = useParams();

  const groupSortKey = (sortKey: keyof typeof EnrollmentSort) => {
    if (
      Object.values(GroupEnrollmentSort).includes(
        sortKey as GroupEnrollmentSort,
      )
    ) {
      return sortKey;
    } else {
      return GroupEnrollmentSort.NAME_ASC;
    }
  };

  const props = useLazyLoadQuery<CoachProgramEnrollmentsRouteQuery>(
    graphql`
      query CoachProgramEnrollmentsRouteQuery(
        $programSlug: String!
        $maxEnrollments: Int!
        $first: Int
        $after: String
        $enrollmentOrderBy: EnrollmentSortBy
        $groupEnrollmentOrderBy: GroupEnrollmentSortBy
      ) {
        program(slug: $programSlug) {
          ...ProgramSettings_program
          ...EnrollDialog_program
          ...EnrollDateDialog_program
          ...RefreshSlug
          id
          name
          slug
        }

        clients {
          ...EnrollDialog_clients
        }

        groups {
          ...EnrollDialog_groups
        }

        enrollmentsAvatars: enrollments(
          programSlug: $programSlug
          first: $maxEnrollments
        ) @connection(key: "Program_enrollmentsAvatars", filters: []) {
          totalCount
          edges {
            node {
              id
            }
          }
          ...EnrolledAvatars_connection
        }

        enrollments(
          programSlug: $programSlug
          first: $first
          after: $after
          orderBy: $enrollmentOrderBy
        )
          @connection(
            key: "Program_enrollments"
            filters: ["first", "after", "orderBy", "programSlug"]
          ) {
          totalCount
          edges {
            cursor
          }
          ...ProgramEnrollments_enrollments
        }

        groupEnrollments(
          programSlug: $programSlug
          first: $first
          after: $after
          orderBy: $groupEnrollmentOrderBy
        ) @connection(key: "Program_groupEnrollments", filters: []) {
          totalCount
          edges {
            cursor
          }
          ...ProgramEnrollments_groupEnrollments
        }
      }
    `,
    {
      programSlug: slug,
      maxEnrollments,
      enrollmentOrderBy: filter ?? EnrollmentSort.CREATED_AT_ASC,
      groupEnrollmentOrderBy: filter
        ? groupSortKey(filter as EnrollmentSort)
        : EnrollmentSort.CREATED_AT_ASC,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  const { program, enrollments, groupEnrollments } = props;

  const context = useContext(ProgramContext);
  context.setProgramQuery(props);

  return (
    <>
      <RefreshSlug nodesRef={[program]} />
      <PaginationContext.Provider
        value={{
          programSlug: slug,
          filter: filter ?? EnrollmentSort.CREATED_AT_ASC,
        }}
      >
        <ProgramEnrollments
          enrollmentsRef={enrollments}
          groupEnrollmentsRef={groupEnrollments}
        />
      </PaginationContext.Provider>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Program Enrollments",
          properties: {
            programId: program.id,
            programSlug: program.slug,
            programTitle: program.name,
          },
        }}
      />
    </>
  );
}
