import matchAll from "string.prototype.matchall";

const rHost = /-?s(\.stridist)/;
const rModifier = /([^/]+),(c_crop|c_scale)\//g;

export type ThumbnailScale = {
  w: number;
  h: number;
};

export type ThumbnailCrop = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type ThumbnailCover = ThumbnailScale;
export type ThumbnailFill = ThumbnailScale;

export type ThumbnailModifier =
  | ThumbnailScale
  | ThumbnailCrop
  | ThumbnailCover
  | ThumbnailFill;

export type ThumbnailInfo = {
  src: string;
  scale?: ThumbnailScale;
  cover?: ThumbnailScale;
  crop?: ThumbnailCrop;
  fill?: ThumbnailFill;
};

const parseThumbnailModifier = (modifier: string): ThumbnailModifier =>
  modifier
    .split(",")
    .map((s) => s.split("_"))
    .reduce(
      (acc, [key, value]) =>
        Object.assign(acc, {
          [key]: parseInt(value),
        }),
      {},
    ) as ThumbnailModifier;

const serializeThumbnailModifier = (
  modifier: ThumbnailModifier,
  type: string,
) => [...Object.entries(modifier).map((entry) => entry.join("_")), type];

export const normalizeThumbnailUrl = (src: string) => {
  let url;

  try {
    url = new URL(src);
  } catch (e) {
    console.error(e);
    return;
  }

  if (/\.stridist\./.test(url.hostname)) {
    if (rHost.test(url.hostname)) {
      url.hostname = url.hostname.replace(rHost, "$1").replace(/^\./, "");
    }

    if (/^\/img\//.test(url.pathname)) {
      url.pathname = url.pathname.replace(/^\/img/, "/img2");
    }

    if (!/^\/img2\//.test(url.pathname)) {
      url.pathname = "/img2" + url.pathname;
    }
  }

  return url.toString();
};

export const parseThumbnailUrl = (src: string): ThumbnailInfo => {
  const info: ThumbnailInfo = { src };
  let url;

  try {
    url = new URL(src);
  } catch (e) {
    console.error(e);
    return;
  }

  if (/\.stridist\./.test(url.hostname)) {
    const matches = matchAll(url.pathname, rModifier);

    for (const match of matches) {
      const value = parseThumbnailModifier(match[1]);
      const type = match[2];

      switch (match[2]) {
        case "c_crop":
          info.crop = value as ThumbnailCrop;
          break;

        case "c_scale":
          info.scale = value as ThumbnailScale;
          break;

        case "c_fill":
          info.scale = value as ThumbnailFill;
          break;

        default:
          throw new Error(`Unknown "${type}" modifier type`);
      }

      url.pathname = url.pathname.replace(match[0], "");
    }

    info.src = url.toString();
  }

  return info;
};

export const generateThumbnailUrl = ({ src, ...other }: ThumbnailInfo) => {
  const info = {
    ...parseThumbnailUrl(src),
    ...other,
  };
  let url;

  try {
    url = new URL(normalizeThumbnailUrl(info.src));
  } catch (e) {
    console.error(e);
    return;
  }
  if (/\.*stridist\./.test(url.hostname)) {
    const modifiers = [
      info.crop && serializeThumbnailModifier(info.crop, "c_crop"),
      info.scale && serializeThumbnailModifier(info.scale, "c_scale"),
      info.cover && serializeThumbnailModifier(info.cover, "c_cover"),
      info.fill && serializeThumbnailModifier(info.fill, "c_fill"),
    ]
      .filter(Boolean)
      .join("/");

    if (modifiers) {
      if (url.host == "test.stridist.com") {
        url.pathname = url.pathname.replace("/img2", ``);
        url.hostname = url.hostname.replace("test.", `test-s.`);
      } else if (url.host == "dev.stridist.com") {
        url.pathname = url.pathname.replace(/^(\/img2\/)/, `$1${modifiers}/`);
      } else {
        // url.pathname = url.pathname.replace(/^(\/img2\/)/, `$1${modifiers}/`);
        url.pathname = url.pathname.replace("/img2", ``);
        url.hostname = url.hostname.replace("stridist.com", `s.stridist.com`);
      }
    }
  }

  return url.toString();
};
