import clsx from "clsx";
import React, { MouseEvent } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Em } from "../typography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  item: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 0,
    paddingRight: 0,

    "&:hover": {
      background: "none",
    },

    "&$active": {
      color: theme.palette.text.primary,
    },

    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  active: {},
}));

export interface TabsProps {
  className?: string;
  items: string[];
  index: number;
  onChange: (event: MouseEvent<HTMLButtonElement>, index: number) => void;
}

export function Tabs(props: TabsProps) {
  const { className, items, index, onChange, ...other } = props;
  const s = useStyles();

  const handleClick = React.useCallback(
    (event) => {
      onChange(event, Number(event.currentTarget.dataset.index));
    },
    [onChange],
  );

  return (
    <div className={clsx(s.root, className)} {...other}>
      {items.map((value, i) => (
        <Button
          key={value}
          className={clsx(s.item, { [s.active]: i === index })}
          onClick={handleClick}
          data-index={i}
          disableRipple
          children={i === index ? <Em>{value}</Em> : value}
        />
      ))}
    </div>
  );
}
