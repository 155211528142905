import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";
import { TextField } from "@mui/material";

import { EditorDialog, EditorDialogProps } from "../dialog/EditorDialog";
import { useBackupState } from "../../hooks/useLocalStorage";
import { MessageEditor } from "../new-editor/MessageEditor";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { GroupPostsEditDialog_post$key } from "./__generated__/GroupPostsEditDialog_post.graphql";
import { useUpsertGroupPostMutation } from "./mutations/UpsertGroupPost";
import { GroupPostEditor } from "./GroupPostEditor";
import { useEditorRef } from "@udecode/plate-common";

const useStyles = makeStyles((theme) => ({
  root: {},

  editor: {
    marginTop: theme.spacing(3),
    marginLeft: 2,
    marginRight: 2,
    height: `calc(100% - ${theme.spacing(11)})`,
  },

  input: {
    "& .MuiInputBase-multiline": {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    },

    "& .fixed-toolbar-buttons": {
      marginLeft: theme.spacing(-1),
    },
  },
}));

const postFragment = graphql`
  fragment GroupPostsEditDialog_post on GroupPost {
    id
    title
    content
  }
`;

export interface GroupPostsEditDialogProps extends EditorDialogProps {
  groupId: string;
  post?: GroupPostsEditDialog_post$key;
}

export function GroupPostsEditDialog(props: GroupPostsEditDialogProps) {
  const { className, groupId, post: postRef, ...other } = props;
  const post = useFragment(postFragment, postRef);
  const { onClose } = props;
  const s = useStyles();
  const onError = useGenericErrorHandler();

  const [title, setTitle, removeTitleBackup] = useBackupState(
    "group-post-title",
    post?.id,
    post?.title,
  );

  const buttonRef = React.useRef<HTMLButtonElement>();
  const [upsertGroupPosts, pending] = useUpsertGroupPostMutation(() => null);
  const postId = post?.id;

  const handleSendMessage = React.useCallback(
    (fragment?: any[], callback?: () => void) => {
      upsertGroupPosts({
        variables: {
          input: {
            id: postId,
            groupId,
            title,
            content: JSON.stringify(fragment),
          },
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            removeTitleBackup();
            callback();
            if (onClose) {
              onClose({}, "backdropClick");
            }
          }
        },
        onError,
      });
    },
    [
      groupId,
      onClose,
      onError,
      postId,
      removeTitleBackup,
      title,
      upsertGroupPosts,
    ],
  );

  const handleTitleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setTitle(event.target.value);
    },
    [setTitle],
  );

  const editor = useEditorRef();

  return (
    <EditorDialog
      className={clsx(s.root, className)}
      title={post ? "Edit post" : "New Post"}
      submitDisabled={pending}
      submitLabel={post ? "Update post" : "Submit post"}
      buttonRef={buttonRef}
      {...other}
      classes={{
        wrapper: "h-full",
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Title (Optional)"
        value={title}
        onChange={handleTitleChange}
        fullWidth
      />

      <MessageEditor
        className={s.editor}
        backupKeyType="group-post-message"
        backupKeyId={post?.id}
        message={post?.content && JSON.parse(post?.content)}
        placeholder="Share your thoughts, or ask a question."
        buttonRef={buttonRef}
        onMessageSubmit={handleSendMessage}
        InputProps={{ inputComponent: GroupPostEditor }}
        inputProps={{ editor }}
        hideSendButton
        rowsMax={null}
        classes={{
          input: clsx(s.input, "!overflow-y-auto h-full"),
        }}
        rootMinHeight={160}
      />
    </EditorDialog>
  );
}
