import { RecordProxy } from "relay-runtime/lib/store/RelayStoreTypes";

export const relayFieldUpdate = <T>(
  node: RecordProxy<{}>,
  fieldName: string,
  updater: (value: T) => T,
) => {
  const value: T = node.getValue(fieldName) as any;
  const newValue = updater(value) as any;

  node.setValue(newValue, fieldName);
};

export const relayCounterIncrement = (
  node: RecordProxy<{}>,
  fieldName: string,
) => relayFieldUpdate(node, fieldName, (counter: number) => (counter || 0) + 1);

export const relayCounterDecrement = (
  node: RecordProxy<{}>,
  fieldName: string,
) =>
  relayFieldUpdate(node, fieldName, (counter: number) =>
    Math.max((counter || 0) - 1, 0),
  );

export const RELAY_LAZY_LOAD_COMMON_CONFIG = {
  fetchPolicy: "network-only",
};
