import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackAlert } from "../../hooks/useSnackAlert";

import { colorSystem } from "../../theme";
import { ClientNutritionTargetDrawerEditView } from "./ClientNutritionTargetDrawerEditView";
import { ClientNutritionDrawerHeader } from "./ClientNutritionDrawerHeader";
import { ClientNutritionTargetListView } from "./ClientNutritionTargetListView";

import {
  nutritionTrackingTypeArray,
  nutritionCommentsTypeArray,
  nutritionTargetsArray,
  nutritionComments,
} from "../../constants";
import { ClientNutritionTargetDrawerSetDateView } from "./ClientNutritionTargetDrawerSetDateView";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import { useClient } from "../../hooks/useClient";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useUpsertClientNutritionTargetMutation } from "./mutations/UpsertClientNutritionTarget";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    marginBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  overlay: {},
}));

export enum ClientNutritionTargetDrawerView {
  LIST = "LIST",
  EDIT = "EDIT",
  DATE_PICKER = "DATE_PICKER",
}

const removeNutritionTargetDrawerMutation = graphql`
  mutation ClientNutritionTargetDrawerDeleteMutation(
    $input: DeleteClientNutritionTargetInput!
  ) {
    deleteClientNutritionTarget(input: $input) {
      clientMutationId
    }
  }
`;

export interface ClientNutritionTargetDrawerProps extends DrawerProps {
  nutritionTargets: any;
  refresh?: () => void;
}

export function ClientNutritionTargetDrawer(
  props: ClientNutritionTargetDrawerProps,
) {
  const { className, onClose, open, nutritionTargets, refresh } = props;
  const s = useStyles();
  const [targetId, setTargetId] = React.useState(undefined);
  const [dirty, setDirty] = React.useState(false);
  const [view, setView] = React.useState<ClientNutritionTargetDrawerView>(
    ClientNutritionTargetDrawerView.LIST,
  );
  const [targetValue, setTargetValue] = React.useState(
    nutritionTargetsArray[0].value,
  );
  const [commentTypeValue, setCommentTypeValue] = React.useState(
    nutritionCommentsTypeArray[0].value,
  );
  const [trackingTypeValue, setTrackingTypeValue] = React.useState(
    nutritionTrackingTypeArray[0].value,
  );
  const [proteinValue, setProteinValue] = React.useState("0.0");
  const [carbohydrateValue, setCarbohydrateValue] = React.useState("0.0");
  const [fatValue, setFatValue] = React.useState("0");
  const [caloriesValue, setCaloriesValue] = React.useState("0");
  const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  const user = useCurrentUser();
  const client = useClient<any>();

  const snackAlert = useSnackAlert();

  const [upsertGoal, creating] = useUpsertClientNutritionTargetMutation();

  const [removeNutritionTarget] = useMutation(
    removeNutritionTargetDrawerMutation,
  );

  const userId = React.useMemo(() => client?.id || user.id, [user, client]);

  const setDefault = React.useCallback(() => {
    setTargetId(undefined);
    setDirty(false);
    setTargetValue(nutritionTargetsArray[0].value);
    setCommentTypeValue(nutritionCommentsTypeArray[0].value);
    setTrackingTypeValue(nutritionTrackingTypeArray[0].value);
    setProteinValue("0.0");
    setCarbohydrateValue("0.0");
    setFatValue("0");
    setDate(dayjs().format("YYYY-MM-DD"));
  }, []);

  const handleClickSave = React.useCallback(() => {
    upsertGoal({
      variables: {
        input: {
          id: targetId,
          clientId: userId,
          trackingType: trackingTypeValue,
          targetType: targetValue,
          commentType: commentTypeValue,
          carbsGrams: +carbohydrateValue,
          proteinGrams: +proteinValue,
          fatGrams: +fatValue,
          calories: +caloriesValue,
          date: date,
        },
      },
      onCompleted: (data: any, errors) => {
        if (errors) {
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Success",
          });
          setTargetId(data.upsertNutritionTarget.nutritionTarget.id);
          if (refresh) {
            window.location.reload();
          }
        }
      },
    });
  }, [
    userId,
    trackingTypeValue,
    targetValue,
    commentTypeValue,
    carbohydrateValue,
    proteinValue,
    fatValue,
    date,
    caloriesValue,
    refresh,
    snackAlert,
    targetId,
    upsertGoal,
  ]);

  const handleDelete = React.useCallback(() => {
    removeNutritionTarget({
      variables: {
        input: { id: targetId },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          console.error(errors);
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          if (refresh) {
            window.location.reload();
          }
          snackAlert({
            severity: "success",
            message: "Delete successfully",
          });
          setDefault();
          setView(ClientNutritionTargetDrawerView.LIST);
        }
      },
    });
  }, [targetId, refresh, removeNutritionTarget, setDefault, snackAlert]);

  const validateInputNumber = React.useCallback(
    (value, prevValue, defaultValue = "0") => {
      if (+value < 0) {
        return defaultValue;
      } else if (value.length >= 6) {
        return prevValue;
      } else return value;
    },
    [],
  );

  const handleDateChange = React.useCallback((date) => {
    setDate(date);
    setView(ClientNutritionTargetDrawerView.EDIT);
  }, []);

  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleCreate = React.useCallback(() => {
    setView(ClientNutritionTargetDrawerView.EDIT);
  }, [setView]);

  const handleBackDate = React.useCallback(() => {
    setView(ClientNutritionTargetDrawerView.EDIT);
  }, [setView]);

  const handleChangeTarget = React.useCallback(
    (value) => {
      setTargetValue(value);
    },
    [setTargetValue],
  );

  const handleBack = React.useCallback(() => {
    setView(ClientNutritionTargetDrawerView.LIST);
    setDefault();
  }, [setDefault]);

  const handleEditClick = React.useCallback((event, target) => {
    setTargetId(target.id);
    setTargetValue(target.targetType);
    setCommentTypeValue(target.commentType);
    setTrackingTypeValue(target.trackingType);
    setProteinValue(target.proteinGrams);
    setCaloriesValue(target.calories);
    setFatValue(target.fatGrams);
    setCarbohydrateValue(target.carbsGrams);
    setDate(target.date);

    setView(ClientNutritionTargetDrawerView.EDIT);
  }, []);

  const handleChangeCommentType = React.useCallback(
    (value) => {
      const {
        PROTEIN_ONLY,
        TRACKING_ONLY,
        PROTEIN_CALORIES,
        CALORIES_ONLY,
        FULL_MACROS,
      } = nutritionComments;
      switch (value) {
        case PROTEIN_ONLY:
          setCarbohydrateValue("0.0");
          setFatValue("0");
          setCaloriesValue("0");
          break;
        case CALORIES_ONLY:
          setProteinValue("0.0");
          setCarbohydrateValue("0.0");
          setFatValue("0");
          break;
        case PROTEIN_CALORIES:
          setCarbohydrateValue("0.0");
          setFatValue("0");
          break;
        case TRACKING_ONLY:
        case FULL_MACROS:
          setProteinValue("0.0");
          setCarbohydrateValue("0.0");
          setFatValue("0");
          setCaloriesValue("0");
          break;
      }
      setCommentTypeValue(value);
    },
    [setCommentTypeValue],
  );

  const handleChangeTrackingType = React.useCallback(
    (value) => {
      setDefault();
      setTrackingTypeValue(value);
    },
    [setTrackingTypeValue, setDefault],
  );

  const handleChangeProtein = React.useCallback(
    (value) => {
      setDirty(true);
      setProteinValue(validateInputNumber(value, proteinValue, "0.0"));
    },
    [setProteinValue, proteinValue, validateInputNumber],
  );

  const handleChangeCarbohydrate = React.useCallback(
    (value) => {
      setDirty(true);
      setCarbohydrateValue(
        validateInputNumber(value, carbohydrateValue, "0.0"),
      );
    },
    [setCarbohydrateValue, carbohydrateValue, validateInputNumber],
  );

  const handleChangeFat = React.useCallback(
    (value) => {
      setDirty(true);
      setFatValue(validateInputNumber(value, fatValue));
    },
    [setFatValue, fatValue, validateInputNumber],
  );

  const handleChangeCalories = React.useCallback(
    (value) => {
      setDirty(true);
      setCaloriesValue(validateInputNumber(value, caloriesValue));
    },
    [setCaloriesValue, caloriesValue, validateInputNumber],
  );

  React.useEffect(() => {
    if (commentTypeValue === nutritionComments.FULL_MACROS) {
      const calories =
        +fatValue * 9 + +proteinValue * 4 + +carbohydrateValue * 4;
      setCaloriesValue(calories.toString());
    }
  }, [fatValue, carbohydrateValue, proteinValue, commentTypeValue]);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(
          s.paper,
          view === ClientNutritionTargetDrawerView.DATE_PICKER && s.overlay,
        ),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={
        <>
          <ClientNutritionDrawerHeader
            className={s.header}
            onClose={handleClose}
            onBack={view !== ClientNutritionTargetDrawerView.LIST && handleBack}
            onDelete={
              view === ClientNutritionTargetDrawerView.EDIT &&
              targetId &&
              handleDelete
            }
            title={
              !targetId && view === ClientNutritionTargetDrawerView.EDIT
                ? "Create Nutrition Target"
                : "Update Nutrition Targets"
            }
          />
          {view === ClientNutritionTargetDrawerView.EDIT ? (
            <ClientNutritionTargetDrawerEditView
              dirty={dirty}
              creating={creating}
              date={date}
              targetValue={targetValue}
              commentTypeValue={commentTypeValue}
              trackingTypeValue={trackingTypeValue}
              proteinValue={proteinValue}
              fatValue={fatValue}
              caloriesValue={caloriesValue}
              carbohydrateValue={carbohydrateValue}
              onChangeTarget={handleChangeTarget}
              onChangeCommentType={handleChangeCommentType}
              onChangeTrackingType={handleChangeTrackingType}
              onChangeProtein={handleChangeProtein}
              onChangeCarbohydrate={handleChangeCarbohydrate}
              onChangeFat={handleChangeFat}
              onChangeCalories={handleChangeCalories}
              onClickCalendar={() =>
                setView(ClientNutritionTargetDrawerView.DATE_PICKER)
              }
              onClickSave={handleClickSave}
            />
          ) : view === ClientNutritionTargetDrawerView.DATE_PICKER ? (
            <ClientNutritionTargetDrawerSetDateView
              date={date}
              onBack={handleBackDate}
              onDateChange={handleDateChange}
            />
          ) : (
            <ClientNutritionTargetListView
              onItemActionClick={handleEditClick}
              onCreateClick={handleCreate}
              nutritionTargets={nutritionTargets}
            />
          )}
        </>
      }
    />
  );
}
