import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { maybePluralize } from "../../../utils/text";

import { ToggleManyClientsArchivedMutation } from "./__generated__/ToggleManyClientsArchivedMutation.graphql";

const mutation = graphql`
  mutation ToggleManyClientsArchivedMutation(
    $input: ToggleManyClientsArchivedInput!
  ) {
    toggleManyClientsArchived(input: $input) {
      clients {
        id
      }
    }
  }
`;

export const useToggleManyClientsArchivedMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<ToggleManyClientsArchivedMutation>(
    mutation,
    ({
      variables: {
        input: { archived },
      },
      onSuccess,
    }) => ({
      onSuccess: (response) => {
        const {
          toggleManyClientsArchived: { clients },
        } = response;

        snackAlert({
          severity: "success",
          message: `${maybePluralize(clients.length, "client")} ${
            archived ? "archived" : "restored"
          }`,
        });

        if (onSuccess) {
          onSuccess(response);
        }
      },
    }),
  );
};
