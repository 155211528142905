/**
 * @generated SignedSource<<6e262e4f31f62fe3697c590306e45470>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertActivityInput = {
  clientMutationId?: string | null | undefined;
  completed?: boolean | null | undefined;
  content?: string | null | undefined;
  edited?: boolean | null | undefined;
  endWorkout?: string | null | undefined;
  id: string;
  startWorkout?: string | null | undefined;
  submitted?: boolean | null | undefined;
};
export type StartWorkoutActivityCompleteActivityMutation$variables = {
  input: UpsertActivityInput;
};
export type StartWorkoutActivityCompleteActivityMutation$data = {
  readonly upsertActivity: {
    readonly activity: {
      readonly " $fragmentSpreads": FragmentRefs<"StartWorkout_activity">;
    } | null | undefined;
  } | null | undefined;
};
export type StartWorkoutActivityCompleteActivityMutation = {
  response: StartWorkoutActivityCompleteActivityMutation$data;
  variables: StartWorkoutActivityCompleteActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StartWorkoutActivityCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StartWorkout_activity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StartWorkoutActivityCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "raw",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(raw:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "component",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locked",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "325c041937822e68322afa8a0c8a04ae",
    "id": null,
    "metadata": {},
    "name": "StartWorkoutActivityCompleteActivityMutation",
    "operationKind": "mutation",
    "text": "mutation StartWorkoutActivityCompleteActivityMutation(\n  $input: UpsertActivityInput!\n) {\n  upsertActivity(input: $input) {\n    activity {\n      ...StartWorkout_activity\n      id\n    }\n  }\n}\n\nfragment StartWorkout_activity on Activity {\n  id\n  date(raw: true)\n  component {\n    locked\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ecc26382fd1625b2f78787b46b203e5a";

export default node;
