import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { WeekCard, WeekCardProps } from "../program-week/WeekCard";
import { useProgramSchedule } from "../../hooks/useProgramSchedule";

import type { ProgramAddComponentCallback } from "./ProgramDetails";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getContentType } from "../../utils/component";
import { ComponentType } from "../../constants";
import ComponentDialog from "./ComponentDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    margin: theme.spacing(3, 0),
  },
}));

export interface ProgramWeeksListProps extends BoxProps {
  programRef: any;
  filters?: WeekCardProps["filters"];
  startDate?: string;
  onAddComponent?: ProgramAddComponentCallback;
  onOpenMenu?: WeekCardProps["onOpenMenu"];
  weeks?: any;
  programId?: string;
}

export function ProgramWeeksList(props: ProgramWeeksListProps) {
  const {
    className,
    programRef,
    filters,
    startDate,
    onOpenMenu,
    onAddComponent,
    weeks,
    programId,
    ...other
  } = props;
  const s = useStyles();

  const [, schedule] = useProgramSchedule(programRef, filters);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();

  const componentSlugSearchParams = searchParams.get("component");
  const programSlug = params.slug;

  const addComponentCallback = (
    e?: any,
    slug?: string,
    type?: ComponentType,
  ) => {
    navigate(
      `/coach/programs/${programSlug}/curriculum/${getContentType(type)}s/${slug}`,
    );
  };

  const [componentSlug, setComponentSlug] = useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = (e?: any, slug?: string) => {
    slug && setComponentSlug(slug);
    setSearchParams({
      component: slug ?? componentSlugSearchParams ?? componentSlug,
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSearchParams();
    setOpenDialog(false);
  };

  useEffect(() => {
    if (componentSlugSearchParams && programSlug) {
      setComponentSlug(componentSlugSearchParams);
      handleClickOpenDialog();
    }
  }, []);

  useEffect(() => {
    searchParams.size === 0 && handleCloseDialog();
  }, [searchParams]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {weeks.map((week, i) => (
        <WeekCard
          className={s.card}
          key={week.id}
          weekRef={week}
          schedule={schedule.filter(({ weeks }) => weeks.includes(week.week))}
          filters={filters}
          startDate={startDate}
          onOpenMenu={onOpenMenu}
          onAddComponent={(
            weekId: string,
            type: ComponentType,
            days?: boolean[],
          ) => {
            onAddComponent(
              weekId,
              type,
              days,
              undefined,
              undefined,
              addComponentCallback,
            );
          }}
          id={week.id}
          week={week.week}
          programId={programId}
          handleClickOpenDialog={handleClickOpenDialog}
        />
      ))}
      <ComponentDialog
        componentSlug={componentSlug}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Box>
  );
}
