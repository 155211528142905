import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CustomAssetsContext } from "../../../../../components/component-library/hooks/useCustomAssets";
import { ComponentTemplatesContext } from "../../../../../components/component-template/context";
import {
  EditorProgramContext,
  EditorComponentContext,
} from "../../../../../components/new-editor/hooks";
import { ExerciseLibraryContext } from "../../../../../components/exercise-library/hooks/useExerciseLibrary";
import { HistoryBlock } from "../../../../../components/history-block/HistoryBlock";
import { CoachComponent } from "../../../../../components/program-component/CoachComponent";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { UnsplashContext } from "../../../../../hooks/useUnsplash";
import { CoachProgramComponentPreviewRouteQuery } from "./__generated__/CoachProgramComponentPreviewRouteQuery.graphql";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TrackInfoTool from "../../../../../components/tools/TrackInfoTool";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../../utils/relay";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../../../routes";

interface ICoachProgramComponentPreviewRouteProps {
  programSlugProp?: string;
  componentSlugProp?: string;
  handleCloseDialog?: () => void;
}

export function CoachProgramComponentPreviewRoute(
  inputProps: ICoachProgramComponentPreviewRouteProps,
) {
  const { programSlugProp, componentSlugProp, handleCloseDialog } = inputProps;

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const readOnly = searchParams.get("readOnly");

  const componentSlug = params.componentSlug ?? componentSlugProp;
  const programSlug = params.slug ?? programSlugProp;

  const props = useLazyLoadQuery<CoachProgramComponentPreviewRouteQuery>(
    graphql`
      query CoachProgramComponentPreviewRouteQuery(
        $programSlug: String!
        $componentSlug: String!
      ) {
        ...UnsplashImages_rootRef
        ...ComponentLibraryAssetList_rootRef
        ...ComponentTemplateDialog_rootRef
          @arguments(componentSlug: $componentSlug)
        ...useExerciseLibrary_rootRef
        program(slug: $programSlug) {
          id
          name
          slug
          weeks(first: 9999, after: "") {
            edges {
              node {
                id
                week
              }
            }
          }
          ...RefreshSlug
          ...MediaElementPopover_program
          ...CheckInAnswerProgressPhotoUpload_program
          ...WorkoutSectionElement_program
        }
        component(slug: $componentSlug) {
          ...CoachComponent_component @arguments(draft: true)
          ...RefreshSlug
          id
          title(draft: true)
          slug
          type
        }
      }
    `,
    {
      programSlug: programSlug,
      componentSlug: componentSlug,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  const { program, component } = props;

  const defaultCloseDialogHandler = () => {
    navigate(COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", programSlug));
  };

  return (
    program &&
    component && (
      <>
        <RefreshSlug nodesRef={[program, component]} />
        <EditorProgramContext.Provider value={program}>
          <EditorComponentContext.Provider value={component}>
            <UnsplashContext.Provider value={props}>
              <CustomAssetsContext.Provider value={props}>
                <ExerciseLibraryContext.Provider value={props}>
                  <ComponentTemplatesContext.Provider value={props}>
                    <HistoryBlock>
                      <CoachComponent
                        componentRef={component}
                        readOnly={Boolean(readOnly)}
                        handleCloseDialog={
                          handleCloseDialog ?? defaultCloseDialogHandler
                        }
                      />
                    </HistoryBlock>
                  </ComponentTemplatesContext.Provider>
                </ExerciseLibraryContext.Provider>
              </CustomAssetsContext.Provider>
            </UnsplashContext.Provider>
          </EditorComponentContext.Provider>
        </EditorProgramContext.Provider>
        <TrackInfoTool
          trackInfo={{
            name: "Coach - Program Component",
            properties: {
              programId: program?.id,
              programName: program?.name,
              programSlug: program?.slug,
              componentId: component?.id,
              componentTitle: component?.title,
              componentSlug: component?.slug,
            },
          }}
        />
      </>
    )
  );
}
