import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpdateThreadMutation } from "./__generated__/UpdateThreadMutation.graphql";

const mutation = graphql`
  mutation UpdateThreadMutation($input: UpdateThreadInput!) {
    updateThread(input: $input) {
      me {
        unreadMessagesCounter
      }
      thread {
        ...ThreadCard_thread
      }
    }
  }
`;

export const useUpdateThreadMutation = () => {
  return useConfiguredMutation<UpdateThreadMutation>(mutation, () => ({}));
};
