import React from "react";

const NoRespond = ({ fill }) => (
  <svg
    width="280"
    height="249"
    viewBox="0 0 280 249"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.6033 91.7501L17.9636 103.68C26.8026 107.43 35.7499 102.224 39.4175 99.6138C51.2604 106.123 65.1378 107.875 78.2262 104.513C91.3146 101.15 102.631 92.9263 109.872 81.5143C117.113 70.1022 119.735 56.3593 117.206 43.0817C114.676 29.8042 107.183 17.9895 96.2533 10.0415C85.3232 2.0936 71.7764 -1.39048 58.3692 0.298182C44.9619 1.98685 32.7015 8.72139 24.0824 19.1316C15.4634 29.5418 11.1333 42.8455 11.9732 56.3359C12.813 69.8263 18.7597 82.4899 28.6033 91.7501V91.7501Z"
      fill="#E6E6E6"
    />
    <path
      d="M36.3384 44.3175C47.3149 44.2031 58.2899 44.0069 69.2659 43.8577C72.925 43.808 76.5842 43.7644 80.2435 43.7269C83.3965 43.6941 83.4045 38.79 80.2435 38.8229C69.2671 38.9372 58.292 39.1335 47.3161 39.2827C43.6569 39.3324 39.9977 39.376 36.3384 39.4134C33.1855 39.4463 33.1774 44.3504 36.3384 44.3175Z"
      fill="white"
    />
    <path
      d="M36.3384 55.7601L80.7008 55.3003L93.3176 55.1696C96.4706 55.1369 96.4786 50.2328 93.3176 50.2655L48.9553 50.7253L36.3384 50.8561C33.1855 50.8887 33.1774 55.7929 36.3384 55.7601Z"
      fill="white"
    />
    <path
      d="M36.3384 67.203L80.7008 66.7432L93.3176 66.6125C96.4706 66.5798 96.4786 61.6757 93.3176 61.7084L48.9553 62.1682L36.3384 62.2989C33.1855 62.3316 33.1774 67.2357 36.3384 67.203Z"
      fill="white"
    />
    <path
      d="M3.86517 113.998C5.99984 113.998 7.73033 112.267 7.73033 110.132C7.73033 107.996 5.99984 106.265 3.86517 106.265C1.73049 106.265 0 107.996 0 110.132C0 112.267 1.73049 113.998 3.86517 113.998Z"
      fill="#E6E6E6"
    />
    <path
      d="M195.959 133.457L193.799 146.946C193.663 147.792 193.344 148.597 192.864 149.305C192.383 150.013 191.753 150.608 191.018 151.046C190.283 151.484 189.46 151.755 188.609 151.841C187.757 151.927 186.897 151.824 186.09 151.541C185.287 151.259 184.554 150.806 183.945 150.212C183.335 149.618 182.862 148.897 182.559 148.102C182.256 147.306 182.131 146.454 182.191 145.604C182.252 144.755 182.497 143.929 182.91 143.185L189.94 130.502L217.117 76.205C218.187 74.3994 219.824 72.9988 221.773 72.2214C223.722 71.4441 225.874 71.3338 227.892 71.9078L230.771 72.7267L226.489 85.2868L195.959 133.457Z"
      fill="#A0616A"
    />
    <path
      d="M270.959 228.29L277.32 227.955L270.289 204.511L260.244 206.521L270.959 228.29Z"
      fill="#A0616A"
    />
    <path
      d="M207.341 232.644H214.372L218.055 209.87H207.341V232.644Z"
      fill="#A0616A"
    />
    <path
      d="M218.487 40.2638C218.487 45.263 220.458 50.0604 223.973 53.6141C227.489 57.1678 232.264 59.1908 237.261 59.2439C242.259 59.2969 247.076 57.3756 250.665 53.8973C254.255 50.419 256.328 45.6645 256.434 40.6665C256.439 40.5342 256.439 40.3961 256.439 40.2638C256.441 37.7704 255.951 35.3012 254.998 32.9973C254.044 30.6934 252.647 28.5998 250.885 26.8363C249.122 25.0728 247.03 23.6739 244.727 22.7194C242.424 21.7649 239.956 21.2737 237.463 21.2737C234.97 21.2737 232.502 21.7649 230.199 22.7194C227.896 23.6739 225.804 25.0728 224.042 26.8363C222.279 28.5998 220.882 30.6934 219.929 32.9973C218.976 35.3012 218.486 37.7704 218.487 40.2638Z"
      fill="#2F2E41"
    />
    <path
      d="M245.514 29.9106C250.913 29.9106 255.289 25.5327 255.289 20.1324C255.289 14.7321 250.913 10.3542 245.514 10.3542C240.115 10.3542 235.738 14.7321 235.738 20.1324C235.738 25.5327 240.115 29.9106 245.514 29.9106Z"
      fill="#2F2E41"
    />
    <path
      d="M244.494 27.6067C246.381 28.2963 248.434 28.3877 250.374 27.8685C252.315 27.3492 254.048 26.2446 255.339 24.7048C256.629 23.165 257.414 21.2649 257.586 19.263C257.759 17.2612 257.31 15.2549 256.302 13.5171C257.225 14.5313 257.924 15.7284 258.354 17.0307C258.785 18.333 258.936 19.7112 258.799 21.0758C258.662 22.4404 258.239 23.7609 257.558 24.9514C256.877 26.1419 255.954 27.1758 254.847 27.9859C253.741 28.796 252.476 29.3641 251.136 29.6533C249.796 29.9426 248.409 29.9465 247.067 29.6648C245.725 29.3831 244.458 28.8222 243.347 28.0183C242.236 27.2145 241.306 26.1859 240.619 24.9992C241.68 26.1703 243.01 27.0652 244.494 27.6067Z"
      fill="#2F2E41"
    />
    <path
      d="M235.966 61.0996C242.021 61.0996 246.93 56.1896 246.93 50.1327C246.93 44.0758 242.021 39.1658 235.966 39.1658C229.911 39.1658 225.002 44.0758 225.002 50.1327C225.002 56.1896 229.911 61.0996 235.966 61.0996Z"
      fill="#A0616A"
    />
    <path
      d="M243.837 54.1345C243.837 54.1345 244.842 66.8613 251.204 69.5406L226.426 92.3148L218.39 88.6308L224.082 72.5548C224.082 72.5548 237.141 69.8755 231.784 58.1535L243.837 54.1345Z"
      fill="#A0616A"
    />
    <path
      d="M201.649 211.88L219.73 212.884L228.1 149.92L249.864 212.549L271.628 205.516L250.199 133.844C244.682 114.018 249.324 92.23 256.896 69.8757L248.525 67.5312L229.44 70.2106C227.466 70.2136 225.53 70.7435 223.83 71.7455C222.129 72.7475 220.728 74.1853 219.769 75.9104C215.067 84.3301 211.668 92.4987 214.44 99.4768C203.328 128.566 200.932 168.318 201.649 211.88Z"
      fill="#2F2E41"
    />
    <path
      d="M255.222 139.203L250.572 152.049C250.28 152.853 250.169 153.712 250.246 154.565C250.323 155.417 250.586 156.243 251.016 156.983C251.447 157.722 252.034 158.359 252.738 158.846C253.441 159.334 254.243 159.662 255.086 159.806C255.925 159.949 256.785 159.907 257.607 159.684C258.428 159.46 259.191 159.06 259.841 158.51C260.492 157.961 261.015 157.277 261.374 156.505C261.732 155.733 261.918 154.891 261.918 154.04V139.538L264.472 78.8703C264.412 76.7724 263.659 74.7534 262.331 73.1282C261.004 71.503 259.176 70.3631 257.132 69.8862L254.217 69.2058L251.873 82.2675L255.222 139.203Z"
      fill="#A0616A"
    />
    <path
      d="M273.235 246.906C274.582 246.945 275.903 246.523 276.978 245.709C278.052 244.895 278.817 243.738 279.144 242.429L279.366 241.539C280.431 237.277 280.149 232.79 278.557 228.696L276.316 222.932L269.619 225.946L267.558 240.146C267.439 240.971 267.495 241.812 267.723 242.614C267.951 243.415 268.346 244.16 268.882 244.798C269.418 245.437 270.083 245.954 270.833 246.317C271.583 246.681 272.402 246.881 273.235 246.906Z"
      fill="#2F2E41"
    />
    <path
      d="M196.573 248.265C197.306 248.712 198.141 248.963 198.998 248.996C199.856 249.029 200.707 248.843 201.473 248.454L214.223 241.271C215.05 240.805 215.712 240.094 216.117 239.236C216.523 238.378 216.652 237.415 216.487 236.48L215.042 228.29H207.006L195.522 240.511C195.009 241.057 194.623 241.711 194.395 242.425C194.166 243.139 194.1 243.895 194.201 244.638C194.301 245.382 194.567 246.093 194.977 246.72C195.388 247.348 195.933 247.876 196.573 248.265Z"
      fill="#2F2E41"
    />
    <path
      d="M218.659 37.7043C221.632 44.9114 229.315 50.042 238.326 50.042C246.043 50.042 252.788 46.2803 256.434 40.6665C256.439 40.5342 256.439 40.3961 256.439 40.2638C256.439 35.4517 254.612 30.8193 251.327 27.3036C248.042 23.7879 243.545 21.6513 238.745 21.3261C233.945 21.0009 229.201 22.5114 225.472 25.5519C221.743 28.5925 219.308 32.9361 218.659 37.7043V37.7043Z"
      fill="#2F2E41"
    />
    <path
      d="M185.243 93.7637C185.238 82.137 181.415 70.8337 174.362 61.5924C167.308 52.351 157.415 45.6834 146.203 42.6151C134.991 39.5467 123.082 40.2476 112.308 44.6098C101.533 48.972 92.4893 56.754 86.5677 66.7589C80.6461 76.7638 78.1745 88.4375 79.533 99.9846C80.8914 111.532 86.0048 122.313 94.0865 130.669C102.168 139.026 112.771 144.495 124.264 146.237C135.756 147.978 147.503 145.894 157.696 140.306C161.364 142.917 170.311 148.123 179.15 144.373L168.51 132.443C173.799 127.486 178.014 121.495 180.892 114.841C183.77 108.188 185.251 101.013 185.243 93.7637Z"
      fill={fill}
    />
    <path
      d="M105.062 85.0099C116.039 84.8955 127.014 84.6992 137.99 84.5501C141.649 84.5004 145.308 84.4568 148.967 84.4193C152.12 84.3865 152.128 79.4824 148.967 79.5153C137.991 79.6296 127.016 79.8259 116.04 79.9751C112.381 80.0248 108.722 80.0684 105.062 80.1058C101.909 80.1387 101.901 85.0428 105.062 85.0099Z"
      fill="white"
    />
    <path
      d="M105.062 96.4525L149.425 95.9927L162.042 95.862C165.194 95.8293 165.203 90.9252 162.042 90.9579L117.679 91.4177L105.062 91.5485C101.909 91.5811 101.901 96.4853 105.062 96.4525V96.4525Z"
      fill="white"
    />
    <path
      d="M105.062 107.895L149.425 107.436L162.042 107.305C165.194 107.272 165.203 102.368 162.042 102.401L117.679 102.861L105.062 102.991C101.909 103.024 101.901 107.928 105.062 107.895Z"
      fill="white"
    />

    <defs>
      <clipPath id="clip0">
        <rect width="280" height="249" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NoRespond;
