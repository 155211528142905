import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { CoachClientNotesScreen } from "../../../../components/screen/CoachClientNotesScreen";
import { CoachClientNotesRouteQuery } from "./__generated__/CoachClientNotesRouteQuery.graphql";
import { ClientContext } from "../../../../contexts/ClientContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

export function CoachClientNotesRoute() {
  const { username } = useParams();

  const props = useLazyLoadQuery<CoachClientNotesRouteQuery>(
    graphql`
      query CoachClientNotesRouteQuery($username: String!) {
        ...CoachClientNotesScreen_root @arguments(username: $username)

        client: user(username: $username) {
          username
          displayName
        }
      }
    `,
    {
      username: username,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const context = useContext(ClientContext);
  context.setCoachClientQuery(props);

  return props.client && <CoachClientNotesScreen root={props} />;
}
