/**
 * @generated SignedSource<<693bfb6a0e52413c4433434b8b47bf30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CopyComponentDialogSearchProgramsQuery$variables = {
  after?: string | null | undefined;
  first: number;
  query?: string | null | undefined;
};
export type CopyComponentDialogSearchProgramsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CopyComponentDialogSearchPrograms_fragment">;
};
export type CopyComponentDialogSearchProgramsQuery = {
  response: CopyComponentDialogSearchProgramsQuery$data;
  variables: CopyComponentDialogSearchProgramsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CopyComponentDialogSearchProgramsQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "CopyComponentDialogSearchPrograms_fragment"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CopyComponentDialogSearchProgramsQuery",
    "selections": [
      {
        "alias": "connection",
        "args": (v3/*: any*/),
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "length",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enrolledTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "after",
                        "value": ""
                      },
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 9999
                      }
                    ],
                    "concreteType": "WeeksConnection",
                    "kind": "LinkedField",
                    "name": "weeks",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WeeksEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Week",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "week",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "weeks(after:\"\",first:9999)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "connection",
        "args": (v3/*: any*/),
        "filters": [
          "query"
        ],
        "handle": "connection",
        "key": "CopyPrograms_connection",
        "kind": "LinkedHandle",
        "name": "programs"
      }
    ]
  },
  "params": {
    "cacheID": "80d7b4ae9c0d878526ff39a437ea7633",
    "id": null,
    "metadata": {},
    "name": "CopyComponentDialogSearchProgramsQuery",
    "operationKind": "query",
    "text": "query CopyComponentDialogSearchProgramsQuery(\n  $first: Int!\n  $after: String\n  $query: String\n) {\n  ...CopyComponentDialogSearchPrograms_fragment_1Tyy4D\n}\n\nfragment CopyComponentDialogSearchPrograms_fragment_1Tyy4D on Root {\n  connection: programs(first: $first, after: $after, query: $query) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        name\n        length\n        enrolledTotal\n        imageURL\n        weeks(first: 9999, after: \"\") {\n          edges {\n            node {\n              id\n              week\n            }\n          }\n        }\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7522124554be201f67ce6c324ca567d";

export default node;
