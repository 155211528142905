import React from "react";
import { LinkPreviewVariant, UrlMetadata } from "../../../types/links";
import { InlinePreviewLink } from "./InlinePreviewLink";
import { RichPreviewLink } from "./RichPreviewLink";

interface IPreviewLinkProps {
  url: string;
  metadata: UrlMetadata;
}

export const PreviewLink = ({ url, metadata }: IPreviewLinkProps) => {
  const onClick = () => {
    window.open(url, "_blank").focus();
  };
  const getPreviewLinkComponent = () => {
    switch (metadata.variant) {
      case LinkPreviewVariant.INLINE:
        return <InlinePreviewLink onClick={onClick} {...metadata} />;
      case LinkPreviewVariant.RICH:
      case LinkPreviewVariant.TEXT:
        return <RichPreviewLink onClick={onClick} {...metadata} />;
    }
  };

  return metadata ? (
    getPreviewLinkComponent()
  ) : (
    <InlinePreviewLink url={url} onClick={onClick} />
  );
};
