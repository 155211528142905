import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { BackButton } from "../button/BackButton";
import ClientExerciseChooseHistory from "./ClientExerciseChooseHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "inherit",
    minHeight: 300,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "24px",
    paddingLeft: 8,
    marginTop: 8,
    marginBottom: 12,
  },
  container: {},
}));

type ClientWorkoutsExerciseProps = {
  onClose: () => void;
  selectExercise: any;
  id: any;
};

const ClientExerciseChoose = ({
  onClose,
  selectExercise: { name },
  id,
}: ClientWorkoutsExerciseProps) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  return (
    <Box className={s.root}>
      {smUp && <BackButton tooltip={""} onClick={onClose} />}
      <React.Suspense fallback={<div>Loading...</div>}>
        <Typography className={s.title}>{name}</Typography>
        <ClientExerciseChooseHistory name={name} id={id} />
      </React.Suspense>
    </Box>
  );
};

export default React.memo(ClientExerciseChoose);
