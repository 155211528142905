import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WarningIcon from "@mui/icons-material/Warning";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(-0.5),
    fontWeight: 500,

    "& svg": {
      height: 15,
      marginBottom: theme.spacing(-0.5),
    },
  },
}));

export interface FieldErrorProps extends TypographyProps {}

export function FieldError(props: FieldErrorProps) {
  const { className, children, ...other } = props;
  const s = useStyles();

  return (
    <Typography className={clsx(s.root, className)} color="error" {...other}>
      <WarningIcon />
      {children}
    </Typography>
  );
}
