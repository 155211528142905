/**
 * @generated SignedSource<<e2482e9d24c69c691e8a08fd7ef30767>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateMealLoggingQuestionsInput = {
  clientMutationId?: string | null | undefined;
  mealLoggingQuestions: string;
};
export type UpdateMealLoggingQuestionsMutation$variables = {
  input: UpdateMealLoggingQuestionsInput;
};
export type UpdateMealLoggingQuestionsMutation$data = {
  readonly updateMealLoggingQuestions: {
    readonly workspace: {
      readonly " $fragmentSpreads": FragmentRefs<"MealLoggingSettingsDrawer_workspace">;
    };
  } | null | undefined;
};
export type UpdateMealLoggingQuestionsMutation = {
  response: UpdateMealLoggingQuestionsMutation$data;
  variables: UpdateMealLoggingQuestionsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMealLoggingQuestionsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMealLoggingQuestionsPayload",
        "kind": "LinkedField",
        "name": "updateMealLoggingQuestions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MealLoggingSettingsDrawer_workspace"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMealLoggingQuestionsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMealLoggingQuestionsPayload",
        "kind": "LinkedField",
        "name": "updateMealLoggingQuestions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mealLoggingQuestions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mealLoggingQuestionsTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24efd4a1796611a5a639e6cad0d29655",
    "id": null,
    "metadata": {},
    "name": "UpdateMealLoggingQuestionsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMealLoggingQuestionsMutation(\n  $input: UpdateMealLoggingQuestionsInput!\n) {\n  updateMealLoggingQuestions(input: $input) {\n    workspace {\n      ...MealLoggingSettingsDrawer_workspace\n      id\n    }\n  }\n}\n\nfragment MealLoggingSettingsDrawer_workspace on Workspace {\n  mealLoggingQuestions\n  mealLoggingQuestionsTotal\n}\n"
  }
};
})();

(node as any).hash = "beb62971c8ad5067b438fe89aee5cbfa";

export default node;
