import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  LinearProgress,
  Typography,
  LinearProgressProps,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  label: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.primary.main,
  },

  progress: {
    height: theme.spacing(6),
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0, 2),
    flexGrow: 1,
  },
}));

const BinaryLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: theme.spacing(6),
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(0, 2),
      flexGrow: 1,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "24px",
    },
    colorPrimary: {
      backgroundColor: `${theme.palette.primary.main}26`,

      "&::after": {
        display: "block",
        content: ({ value }: LinearProgressProps) => `'${100 - value}%'`,
        position: "absolute",
        right: theme.spacing(1),
        top: `calc(50% - ${theme.spacing(2)})`,
        color: theme.palette.primary.main,
        padding: theme.spacing(0.5, 1),
        backgroundColor: `${colorSystem.white}66`,
        borderRadius: theme.spacing(0.5),
      },
    },
    bar: {
      backgroundColor: theme.palette.primary.main,

      "&::after": {
        display: "block",
        content: ({ value }: LinearProgressProps) => `'${value}%'`,
        position: "absolute",
        right: ({ value }: LinearProgressProps) =>
          theme.spacing(value >= 85 ? 5 : 2),
        top: "calc(50% - 12px)",
        color: theme.palette.common.white,
      },
    },
  }),
)(LinearProgress);

export interface BinaryResponsesSummaryProps extends BoxProps {
  value: number;
}

export function BinaryResponsesSummary(props: BinaryResponsesSummaryProps) {
  const { className, value, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.label} children="Yes" />
      <BinaryLinearProgress variant="determinate" value={value} />
      <Typography className={s.label} children="No" />
    </Box>
  );
}
