/**
 * @generated SignedSource<<e25ec0eae5108adb595c897a4545c864>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThreadCard_thread$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly id: string;
    readonly photoURL: string | null | undefined;
  } | null | undefined;
  readonly content: string;
  readonly formattedDate: string | null | undefined;
  readonly group: {
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly read: boolean;
  readonly slug: string;
  readonly " $fragmentType": "ThreadCard_thread";
};
export type ThreadCard_thread$key = {
  readonly " $data"?: ThreadCard_thread$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThreadCard_thread">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThreadCard_thread",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": "formattedDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "D MMM"
        }
      ],
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": "updatedAt(format:\"D MMM\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "raw",
              "value": true
            }
          ],
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": "displayName(raw:true)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Thread",
  "abstractKey": null
};
})();

(node as any).hash = "6c442a3dda8a026fe72657b05190d4c0";

export default node;
