import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  useDrop,
  DragSourceMonitor,
  useDrag,
  DropTargetMonitor,
} from "react-dnd";

import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";
import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as DragIcon } from "../../icons/Drag.svg";

import { WorkoutExercise, ExerciseId } from "./types";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { Edit2Icon } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },

  bullet: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    flexShrink: 0,

    borderRadius: "50%",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.common.black,
  },

  header: {
    overflow: "hidden",
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  actions: {
    flexShrink: 0,
    marginLeft: "auto",
    marginRight: theme.spacing(-1),
    visibility: "hidden",

    "$root:hover &": {
      visibility: "visible",
    },
  },

  actionsVisible: {
    visibility: "visible",
  },

  button: {
    borderRadius: 8,
    padding: theme.spacing(1),
  },

  icon: {
    color: theme.palette.text.secondary,
    width: 20,
    height: 20,
  },

  drag: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(0.75),
    cursor: "grab",
  },

  dragging: {
    opacity: 0.5,
  },
}));

interface DragItem {
  type: string;
  position: number;
  initialPosition: number;
}

export interface WorkoutExerciseItemProps extends BoxProps {
  exercise: WorkoutExercise;
  itemPosition?: number;
  onEdit?: (exerciseId: ExerciseId) => void;
  onRemove?: (exerciseId: ExerciseId) => void;
  onMove?: (dragPosition, hoverPosition) => void;
  onMoveEnd?: () => void;
}

export function WorkoutExerciseItem(props: WorkoutExerciseItemProps) {
  const s = useStyles();
  const {
    className,
    exercise,
    itemPosition = 0,
    onEdit,
    onRemove,
    onMove,
    onMoveEnd,
    ...other
  } = props;
  const { title, subtitle } = exercise;

  const itemType = "WORKOUT_EXERCISE";
  const ref = React.useRef<HTMLDivElement>(null);
  const dragRef = React.useRef<HTMLButtonElement>(null);

  const isMobile = useMediaMobile();

  const [, drop] = useDrop({
    accept: itemType,

    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.position;
      const hoverIndex = itemPosition;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (onMove) {
        onMove(item.initialPosition, hoverIndex);

        item.position = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: {
      id: exercise.id,
      position: itemPosition,
      initialPosition: itemPosition,
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end() {
      if (onMoveEnd) {
        onMoveEnd();
      }
    },
  });

  const handleEdit = React.useCallback(() => {
    if (onEdit) {
      onEdit(exercise.id);
    }
  }, [exercise, onEdit]);

  const handleRemove = React.useCallback(() => {
    if (onRemove) {
      onRemove(exercise.id);
    }
  }, [exercise, onRemove]);

  drag(dragRef);
  drop(ref);
  preview(ref);

  return (
    <Box
      ref={ref}
      className={clsx(s.root, className, isDragging && s.dragging)}
      {...other}
    >
      <Box className={s.bullet} />
      <Box className={s.header}>
        <Typography className={s.title}>{title}</Typography>
        <Typography className={s.subtitle}>{subtitle}</Typography>
      </Box>

      <Box className={clsx(s.actions, isMobile && s.actionsVisible)}>
        <IconButton className={s.button} onClick={handleEdit} size="large">
          <Edit2Icon className={s.icon} />
        </IconButton>

        <IconButton className={s.button} onClick={handleRemove} size="large">
          <RemoveIcon className={s.icon} />
        </IconButton>

        <IconButton
          ref={dragRef}
          className={clsx(s.button, s.drag)}
          size="large"
        >
          <DragIcon className={s.icon} />
        </IconButton>
      </Box>
    </Box>
  );
}
