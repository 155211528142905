/**
 * @generated SignedSource<<bb8cb223c819543f6e1e055dcf8a1f0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NutritionCommentsType = "CALORIES_ONLY" | "FULL_MACROS" | "PROTEIN_CALORIES" | "PROTEIN_ONLY" | "TRACKING_ONLY" | "%future added value";
export type NutritionTargetType = "ALL_DAYS" | "OFF_DAYS" | "REFEED_DAYS" | "TRAINING_DAYS" | "WEEKDAYS" | "WEEKENDS" | "%future added value";
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
export type UpsertNutritionTargetInput = {
  calories?: number | null | undefined;
  carbsGrams?: number | null | undefined;
  carbsPortions?: number | null | undefined;
  clientId: string;
  clientMutationId?: string | null | undefined;
  commentType: NutritionCommentsType;
  currentCalories?: number | null | undefined;
  currentCarbs?: number | null | undefined;
  currentFat?: number | null | undefined;
  currentProtein?: number | null | undefined;
  date: string;
  fatGrams?: number | null | undefined;
  fatPortions?: number | null | undefined;
  id?: string | null | undefined;
  proteinGrams?: number | null | undefined;
  proteinPortions?: number | null | undefined;
  targetType: NutritionTargetType;
  trackingType: NutritionTrackingType;
};
export type UpsertClientNutritionTargetMutation$variables = {
  input: UpsertNutritionTargetInput;
};
export type UpsertClientNutritionTargetMutation$data = {
  readonly upsertNutritionTarget: {
    readonly nutritionTarget: {
      readonly calories: number | null | undefined;
      readonly carbsGrams: number | null | undefined;
      readonly fatGrams: number | null | undefined;
      readonly id: string;
      readonly proteinGrams: number | null | undefined;
    };
  } | null | undefined;
};
export type UpsertClientNutritionTargetMutation = {
  response: UpsertClientNutritionTargetMutation$data;
  variables: UpsertClientNutritionTargetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertNutritionTargetPayload",
    "kind": "LinkedField",
    "name": "upsertNutritionTarget",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientNutritionTarget",
        "kind": "LinkedField",
        "name": "nutritionTarget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "calories",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "carbsGrams",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fatGrams",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proteinGrams",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertClientNutritionTargetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertClientNutritionTargetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "174e0d010e9410103a090366d44c9b4b",
    "id": null,
    "metadata": {},
    "name": "UpsertClientNutritionTargetMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertClientNutritionTargetMutation(\n  $input: UpsertNutritionTargetInput!\n) {\n  upsertNutritionTarget(input: $input) {\n    nutritionTarget {\n      id\n      calories\n      carbsGrams\n      fatGrams\n      proteinGrams\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "079d9112996afd69dfdddfa5293b11ba";

export default node;
