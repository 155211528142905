import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import EndWorkout from "../../../../../components/end-workout/EndWorkout";
import { useParams } from "react-router-dom";
import { ClientProgramActivityEndRouteQuery } from "./__generated__/ClientProgramActivityEndRouteQuery.graphql";

export function ClientProgramActivityEndRoute() {
  const params = useParams();
  const { date, component } = params;

  const props = useLazyLoadQuery<ClientProgramActivityEndRouteQuery>(
    graphql`
      query ClientProgramActivityEndRouteQuery(
        $date: String!
        $component: String!
      ) {
        activity(client: "$me", date: $date, component: $component) {
          ...EndWorkout_activity
        }
      }
    `,
    {
      date: date,
      component: component,
      shouldFetchSummary: true,
    },
  );
  const { activity } = props;
  return activity && <EndWorkout activityRef={activity} />;
}
