import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextFieldProps } from "@mui/material";
import { useDebounce } from "../../hooks/useDebounce";
import { SchemaElements } from "../editor/normalizers/withSchema";
import { ElementType } from "../editor/types/elements";
import { BaseEditor } from "../new-editor/BaseEditor";
import { editorPlugins } from "../new-editor/editor-configuration/plate-plugins/EditorPlugins";
import { GroupPostEditorSchema } from "../new-editor/editor-configuration/schemas/GroupPostEditorSchema";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupPostEditorProps
  extends Pick<
    TextFieldProps,
    "className" | "onChange" | "onBlur" | "onFocus"
  > {
  value?: string;
  inputRef?: any;
}

const defaultValue = SchemaElements.createElement(ElementType.PARAGRAPH);

export function GroupPostEditor(props: any) {
  const { className, value, onChange, onBlur, onFocus, inputRef, ...other } =
    props;
  const s = useStyles();
  const initialValue = React.useMemo(
    () => value || JSON.stringify([defaultValue]),
    [value],
  );

  const [content, setContent] = React.useState(JSON.parse(initialValue));
  const delayedValue = useDebounce(JSON.stringify(content), 100);

  React.useEffect(() => {
    if (initialValue !== delayedValue) {
      const event: any = {
        target: {
          value: delayedValue,
        },
      };

      onChange(event);
    }
  }, [content, delayedValue, initialValue, onChange, value]);

  return (
    <div className={className} ref={inputRef}>
      <BaseEditor
        className={s.root}
        onFocus={onFocus as any}
        onBlur={onBlur as any}
        inlineToolbar
        topToolbar
        multiline
        compact
        value={content}
        onChange={setContent}
        editorSchema={GroupPostEditorSchema}
        platePlugins={editorPlugins}
        {...other}
      />
    </div>
  );
}
