/**
 * @generated SignedSource<<86d8d9fa2f4f9f3d55d174e4874827f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsNotifications_settings$data = {
  readonly dailyUpdate: boolean;
  readonly groupReplyEmail: boolean;
  readonly groupReplyPush: boolean;
  readonly id: string;
  readonly newGroupPostEmail: boolean;
  readonly newGroupPostPush: boolean;
  readonly newMessageEmail: boolean;
  readonly newMessagePush: boolean;
  readonly newResponseEmail: boolean;
  readonly newResponsePush: boolean;
  readonly remindersEmail: boolean;
  readonly remindersPush: boolean;
  readonly remindersSms: boolean;
  readonly weeklyUpdate: boolean;
  readonly " $fragmentType": "ClientSettingsNotifications_settings";
};
export type ClientSettingsNotifications_settings$key = {
  readonly " $data"?: ClientSettingsNotifications_settings$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsNotifications_settings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSettingsNotifications_settings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newMessageEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newMessagePush",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newResponseEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newResponsePush",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weeklyUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dailyUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remindersEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remindersSms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remindersPush",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newGroupPostEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newGroupPostPush",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupReplyEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groupReplyPush",
      "storageKey": null
    }
  ],
  "type": "NotificationsSetting",
  "abstractKey": null
};

(node as any).hash = "3ec0fc0a96ab1957c744284cba2f1973";

export default node;
