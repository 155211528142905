import clsx from "clsx";
import React from "react";
import { Box, useTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useAnalytics } from "../../hooks/useAnalytics";

import {
  BaseEditorDelayBuffer,
  BaseEditorDelayBufferProps,
} from "../editor/components/utils/BaseEditorDelayBuffer";
import { BaseEditor } from "./BaseEditor";
import { ClientFormEditorSchema } from "./editor-configuration/schemas/ClientFormEditorSchema";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ClientFormEditorProps
  extends Omit<BaseEditorDelayBufferProps, "schema"> {}

export function ClientFormEditor(props: any) {
  const theme = useTheme();
  const { className, readOnly, delay = 0 } = props;
  const s = useStyles();
  const [trackEvent] = useAnalytics();

  const Editor = readOnly || !delay ? BaseEditor : BaseEditorDelayBuffer;

  return (
    <Box className={clsx(s.root, className)}>
      <Editor
        trackEvent={trackEvent}
        inlineToolbar
        sidebar
        deserialize
        preNormalize={!readOnly}
        clipTrailing={false}
        multiline
        autoFocus
        editorSchema={ClientFormEditorSchema}
        stickyTop={parseInt(theme.spacing(9.5)) - 1}
        {...props}
      />
    </Box>
  );
}
