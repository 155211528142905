/**
 * @generated SignedSource<<4008e6c0d7e4e31daf7525576d9a4466>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Gender = "FEMALE" | "MALE" | "PRIVATE" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
export type UpdateUserInput = {
  admin?: boolean | null | undefined;
  birthday?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  displayName?: string | null | undefined;
  gender?: Gender | null | undefined;
  height?: string | null | undefined;
  id: string;
  location?: string | null | undefined;
  passwordUpdated?: boolean | null | undefined;
  phone?: string | null | undefined;
  phoneCode?: string | null | undefined;
  photoURL?: string | null | undefined;
  themeOptions?: string | null | undefined;
  timeZone?: string | null | undefined;
  topToolbar?: boolean | null | undefined;
  trialExpiryDate?: string | null | undefined;
  units?: Units | null | undefined;
  username?: string | null | undefined;
  weight?: string | null | undefined;
};
export type EditorMenuUpdateUserMutation$variables = {
  input: UpdateUserInput;
};
export type EditorMenuUpdateUserMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly topToolbar: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type EditorMenuUpdateUserMutation = {
  response: EditorMenuUpdateUserMutation$data;
  variables: EditorMenuUpdateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topToolbar",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorMenuUpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorMenuUpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25894006566c7431b57b4b8ba5ef0111",
    "id": null,
    "metadata": {},
    "name": "EditorMenuUpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation EditorMenuUpdateUserMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      topToolbar\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33d006d10618ae1505bbade7daa1463b";

export default node;
