import React from "react";
import { Outlet } from "react-router-dom";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { CompletionPeriod, ResponsesView } from "../../../constants";
import { toEnum } from "../../../utils/misc";
import { ClientResponseFilterContext } from "../../../contexts/ClientResponseFilterContext";

const CoachClientResponsesWrapper = () => {
  const [view, setView] = useQueryParam<ResponsesView>(
    "view",
    ResponsesView.SUMMARY,
    {
      silent: true,
    },
  );

  const [period, setPeriod] = useQueryParam<CompletionPeriod>(
    "period",
    CompletionPeriod.LAST_7_DAYS,
    {
      silent: true,
      normalize: (param) =>
        toEnum(param, CompletionPeriod, CompletionPeriod.LAST_7_DAYS),
    },
  );

  const contextValue = {
    view,
    setView,
    period,
    setPeriod,
  };

  return (
    <>
      <ClientResponseFilterContext.Provider value={contextValue}>
        <Outlet />
      </ClientResponseFilterContext.Provider>
    </>
  );
};

export default CoachClientResponsesWrapper;
