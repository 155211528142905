import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { capitalize } from "lodash";
import { EnrollmentSort, EnrollmentsView } from "../../constants";
import { DropdownMenu } from "../menu/DropdownMenu";
import { EnrollmentList } from "../list/EnrollmentList";
import { GroupEnrollmentList } from "../list/GroupEnrollmentList";

import { ProgramEnrollments_enrollments$key } from "./__generated__/ProgramEnrollments_enrollments.graphql";
import { ProgramEnrollments_groupEnrollments$key } from "./__generated__/ProgramEnrollments_groupEnrollments.graphql";
import { useQueryParams } from "../../hooks/useQueryParams";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}));

const enrollmentsFragment = graphql`
  fragment ProgramEnrollments_enrollments on EnrollmentConnection {
    totalCount
    ...EnrollmentList_enrollments
  }
`;

const groupEnrollmentsFragment = graphql`
  fragment ProgramEnrollments_groupEnrollments on GroupEnrollmentConnection {
    totalCount
    ...GroupEnrollmentList_groupEnrollments
  }
`;

export interface ProgramEnrollmentsProps
  extends Omit<ContainerProps, "children"> {
  enrollmentsRef: ProgramEnrollments_enrollments$key;
  groupEnrollmentsRef: ProgramEnrollments_groupEnrollments$key;
}

export function ProgramEnrollments(props: ProgramEnrollmentsProps) {
  const { className, enrollmentsRef, groupEnrollmentsRef, ...other } = props;
  const s = useStyles();
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const groupEnrollments = useFragment(
    groupEnrollmentsFragment,
    groupEnrollmentsRef,
  );
  const [queryParams, setQueryParams] = useQueryParams({
    view: EnrollmentsView.CLIENTS,
    sort: EnrollmentSort.NAME_ASC,
  });

  const items = React.useMemo(
    () => [
      {
        children: `Enrolled ${EnrollmentsView.CLIENTS} (${enrollments.totalCount})`,
        onClick: () => setQueryParams({ view: EnrollmentsView.CLIENTS }),
      },
      {
        children: `Enrolled ${EnrollmentsView.GROUPS} (${groupEnrollments.totalCount})`,
        onClick: () =>
          setQueryParams({
            view: EnrollmentsView.GROUPS,
            sort: EnrollmentSort.NAME_ASC,
          }),
      },
    ],
    [enrollments.totalCount, groupEnrollments.totalCount, setQueryParams],
  );

  const header = React.useMemo(() => {
    const count =
      queryParams.view === EnrollmentsView.CLIENTS ||
      queryParams.view === undefined
        ? enrollments.totalCount
        : groupEnrollments.totalCount;

    return `${capitalize(queryParams.view ?? EnrollmentsView.CLIENTS)} (${count})`;
  }, [enrollments.totalCount, groupEnrollments.totalCount, queryParams]);

  if (
    !Object.values(EnrollmentsView).includes(
      (queryParams.view ?? EnrollmentsView.CLIENTS) as EnrollmentsView,
    )
  ) {
    return null;
  }
  return (
    <Container className={clsx(s.root, className)} maxWidth="md" {...other}>
      <DropdownMenu header={header} variant="normal" items={items} />

      {queryParams.view === EnrollmentsView.CLIENTS ||
      queryParams.view === undefined ? (
        <EnrollmentList enrollmentsRef={enrollments} />
      ) : (
        <GroupEnrollmentList groupEnrollmentsRef={groupEnrollments} />
      )}
    </Container>
  );
}
