/**
 * @generated SignedSource<<19db857bbbfc710e9a8ff25c3f4bee94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollDialog_clients$data = {
  readonly totalCount: number;
  readonly " $fragmentType": "EnrollDialog_clients";
};
export type EnrollDialog_clients$key = {
  readonly " $data"?: EnrollDialog_clients$data;
  readonly " $fragmentSpreads": FragmentRefs<"EnrollDialog_clients">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnrollDialog_clients",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "type": "ClientConnection",
  "abstractKey": null
};

(node as any).hash = "b3fd72b068a3174903f718c4da65d674";

export default node;
