import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Link,
  Button,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CONTACT_EMAIL, RequiresUpgradeReason } from "../../constants";
import logo from "../app/logo.svg";
import { ReactComponent as YogaIcon } from "../../icons/yoga.svg";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useLogout } from "../../hooks/useLogout";
import Yoga from "../../icons/Yoga";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100vh",
  },

  logo: {
    marginBlock: theme.spacing(4),
  },

  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },

  text: {
    fontSize: 16,
    lineHeight: "22px",
    marginBottom: theme.spacing(3),
  },

  yoga: {
    maxWidth: `calc(100vw - ${theme.spacing(4)})`,
    height: "auto",
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(3),
  },
}));

export interface RequiresUpgradeClientScreenProps
  extends Omit<ContainerProps, "children"> {
  reason: RequiresUpgradeReason;
}

export function RequiresUpgradeClientScreen(
  props: RequiresUpgradeClientScreenProps,
) {
  const { className, reason, ...other } = props;
  const s = useStyles();

  const logout = useLogout();
  const me = useCurrentUser();
  const switchUser = useSwitchUser();
  const theme = useTheme();
  const explanation = React.useMemo(() => {
    switch (reason) {
      case RequiresUpgradeReason.TRAIL_EXPIRED:
        return "This trial has expired.";
      case RequiresUpgradeReason.SUBSCRIPTION_CANCELED:
        return "This subscription has been canceled.";
      default:
        return "Upgrade required.";
    }
  }, [reason]);

  const handleClick = () => {
    me.isSample ? switchUser() : logout();
  };

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <img className={s.logo} src={logo} alt="logo" />
      <Typography className={s.title} variant="h1">
        It’s time to upgrade to Pro plan
      </Typography>
      <Typography className={s.text}>
        {explanation} Please, ask your coach to upgrade.
        <br />
        Need help? Contact{" "}
        <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>
      </Typography>

      <Button
        variant="contained"
        children={me.isSample ? "Back to coach" : "Logout"}
        onClick={handleClick}
      />
      <Yoga fill={theme.palette.primary.main} />
    </Container>
  );
}
