"use client";

import React from "react";
import { useCallback, useState } from "react";
import {
  Root as RadixRoot,
  Trigger as RadixTrigger,
  Group as RadixGroup,
  Portal as RadixPortal,
  Sub as RadixSub,
  RadioGroup as RadixRadioGroup,
  SubTrigger as RadixSubTrigger,
  Content as RadixContent,
  SubContent as RadixSubContent,
  Item as RadixItem,
  CheckboxItem as RadixCheckboxItem,
  ItemIndicator as RadixItemIndicator,
  RadioItem as RadixRadioItem,
  Label as RadixLabel,
  Separator as RadixSeparator,
} from "@radix-ui/react-dropdown-menu";
import {
  cn,
  createPrimitiveElement,
  withCn,
  withProps,
  withRef,
  withVariants,
} from "@udecode/cn";
import { cva } from "class-variance-authority";

import { Icons } from "./Icons/icons";

export const DropdownMenu = RadixRoot;
export const DropdownMenuTrigger = RadixTrigger;
export const DropdownMenuGroup = RadixGroup;
export const DropdownMenuPortal = RadixPortal;
export const DropdownMenuSub = RadixSub;
export const DropdownMenuRadioGroup = RadixRadioGroup;

export const DropdownMenuSubTrigger = withRef<
  typeof RadixSubTrigger,
  {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <RadixSubTrigger
    ref={ref}
    className={cn(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent",
      "data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      inset && "pl-8",
      className,
    )}
    {...props}
  >
    {children}
    <Icons.chevronRight className="ml-auto h-4 w-4" />
  </RadixSubTrigger>
));

export const DropdownMenuSubContent = withCn(
  RadixSubContent,
  "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
);

const DropdownMenuContentVariants = withProps(RadixContent, {
  sideOffset: 4,
  className: cn(
    "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover px-1 pb-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
  ),
});

export const DropdownMenuContent = withRef<typeof RadixContent>(
  ({ ...props }, ref) => (
    <RadixPortal>
      <DropdownMenuContentVariants ref={ref} {...props} />
    </RadixPortal>
  ),
);

const menuItemVariants = cva(
  cn(
    "relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors max-w-[280px]",
    "focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
  ),
  {
    variants: {
      inset: {
        true: "pl-8",
      },
    },
  },
);

export const DropdownMenuItem = withVariants(RadixItem, menuItemVariants, [
  "inset",
]);

export const DropdownMenuCheckboxItem = withRef<typeof RadixCheckboxItem>(
  ({ className, children, ...props }, ref) => (
    <RadixCheckboxItem
      ref={ref}
      className={cn(
        "relative flex select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        "cursor-pointer",
        className,
      )}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <RadixItemIndicator>
          <Icons.check className="h-4 w-4" />
        </RadixItemIndicator>
      </span>
      {children}
    </RadixCheckboxItem>
  ),
);

export const DropdownMenuRadioItem = withRef<
  typeof RadixRadioItem,
  {
    hideIcon?: boolean;
  }
>(({ className, children, hideIcon, ...props }, ref) => (
  <RadixRadioItem
    ref={ref}
    className={cn(
      "relative flex select-none items-center rounded-sm pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      "h-9 cursor-pointer px-2 data-[state=checked]:bg-accent data-[state=checked]:text-accent-foreground",
      className,
    )}
    {...props}
  >
    {!hideIcon && (
      <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
        <RadixItemIndicator>
          <Icons.check className="h-4 w-4" />
        </RadixItemIndicator>
      </span>
    )}
    {children}
  </RadixRadioItem>
));

const dropdownMenuLabelVariants = cva(
  cn("select-none px-2 py-1.5 text-sm font-semibold"),
  {
    variants: {
      inset: {
        true: "pl-8",
      },
    },
  },
);

export const DropdownMenuLabel = withVariants(
  RadixLabel,
  dropdownMenuLabelVariants,
  ["inset"],
);

export const DropdownMenuSeparator = withCn(
  RadixSeparator,
  "-mx-1 my-1 h-px bg-muted",
);

export const DropdownMenuShortcut = withCn(
  createPrimitiveElement("span"),
  "ml-auto text-xs tracking-widest opacity-60",
);

export const useOpenState = () => {
  const [open, setOpen] = useState(false);

  const onOpenChange = useCallback(
    (_value = !open) => {
      setOpen(_value);
    },
    [open],
  );

  return {
    open,
    onOpenChange,
  };
};
