import React from "react";

const EmptyAvatar = ({ fill, className }) => (
  <svg
    className={className}
    width="506"
    height="343"
    viewBox="0 0 506 343"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M477.941 341.648C479.125 328.887 482.393 316.407 487.615 304.707C489.071 301.451 490.673 298.266 492.422 295.154C492.509 294.991 492.53 294.8 492.481 294.622C492.431 294.444 492.314 294.292 492.154 294.199C491.995 294.106 491.805 294.078 491.626 294.122C491.446 294.166 491.291 294.278 491.192 294.434C484.833 305.771 480.378 318.078 478.006 330.863C477.346 334.433 476.85 338.028 476.518 341.648C476.433 342.562 477.857 342.556 477.941 341.648Z"
      fill="#F2F2F2"
    />
    <path
      d="M495.87 297.44C500.85 297.44 504.887 293.398 504.887 288.411C504.887 283.424 500.85 279.382 495.87 279.382C490.89 279.382 486.853 283.424 486.853 288.411C486.853 293.398 490.89 297.44 495.87 297.44Z"
      fill="#F2F2F2"
    />
    <path
      d="M478.864 341.889C479.631 333.61 481.751 325.514 485.14 317.924C486.084 315.811 487.123 313.745 488.258 311.726C488.315 311.62 488.329 311.497 488.296 311.381C488.264 311.265 488.188 311.167 488.084 311.106C487.981 311.046 487.858 311.028 487.741 311.056C487.625 311.085 487.524 311.157 487.46 311.259C483.335 318.614 480.445 326.598 478.905 334.892C478.477 337.208 478.155 339.54 477.94 341.889C477.885 342.482 478.809 342.478 478.864 341.889H478.864Z"
      fill="#F2F2F2"
    />
    <path
      d="M490.495 313.21C493.725 313.21 496.344 310.587 496.344 307.352C496.344 304.117 493.725 301.494 490.495 301.494C487.264 301.494 484.645 304.117 484.645 307.352C484.645 310.587 487.264 313.21 490.495 313.21Z"
      fill="#F2F2F2"
    />
    <path
      d="M477.698 341.336C473.218 334.336 469.917 326.646 467.926 318.575C467.374 316.327 466.924 314.058 466.578 311.767C466.558 311.649 466.493 311.543 466.397 311.472C466.3 311.401 466.18 311.37 466.061 311.386C465.942 311.402 465.834 311.463 465.76 311.558C465.686 311.652 465.651 311.771 465.663 311.891C466.928 320.231 469.553 328.306 473.433 335.793C474.518 337.883 475.697 339.921 476.97 341.905C477.291 342.406 478.017 341.834 477.698 341.336Z"
      fill="#F2F2F2"
    />
    <path
      d="M465.655 312.797C468.886 312.797 471.505 310.175 471.505 306.94C471.505 303.705 468.886 301.082 465.655 301.082C462.424 301.082 459.805 303.705 459.805 306.94C459.805 310.175 462.424 312.797 465.655 312.797Z"
      fill="#F2F2F2"
    />
    <path
      d="M100.315 341.649C99.1321 328.887 95.8639 316.407 90.6412 304.707C89.1857 301.451 87.5836 298.266 85.835 295.154C85.7472 294.991 85.7261 294.8 85.776 294.622C85.8259 294.444 85.9429 294.292 86.1024 294.199C86.2619 294.106 86.4513 294.078 86.6308 294.122C86.8103 294.166 86.9657 294.278 87.0643 294.434C93.4233 305.771 97.8782 318.078 100.251 330.863C100.911 334.433 101.407 338.028 101.739 341.649C101.824 342.562 100.399 342.556 100.315 341.649Z"
      fill="#F2F2F2"
    />
    <path
      d="M82.3869 297.44C87.3668 297.44 91.4039 293.398 91.4039 288.411C91.4039 283.424 87.3668 279.382 82.3869 279.382C77.4069 279.382 73.3699 283.424 73.3699 288.411C73.3699 293.398 77.4069 297.44 82.3869 297.44Z"
      fill="#F2F2F2"
    />
    <path
      d="M99.3929 341.889C98.6252 333.61 96.505 325.514 93.1168 317.924C92.1725 315.811 91.1331 313.745 89.9987 311.726C89.9417 311.62 89.9279 311.497 89.9603 311.381C89.9926 311.265 90.0685 311.167 90.1721 311.106C90.2756 311.046 90.3986 311.028 90.515 311.056C90.6315 311.085 90.7324 311.157 90.7963 311.259C94.9217 318.614 97.8118 326.598 99.3511 334.892C99.7793 337.208 100.101 339.54 100.317 341.889C100.371 342.482 99.4473 342.478 99.3929 341.889H99.3929Z"
      fill="#F2F2F2"
    />
    <path
      d="M87.7616 313.21C90.9923 313.21 93.6114 310.587 93.6114 307.352C93.6114 304.117 90.9923 301.494 87.7616 301.494C84.5309 301.494 81.9119 304.117 81.9119 307.352C81.9119 310.587 84.5309 313.21 87.7616 313.21Z"
      fill="#F2F2F2"
    />
    <path
      d="M100.559 341.336C105.039 334.336 108.34 326.646 110.331 318.575C110.883 316.327 111.333 314.058 111.679 311.767C111.699 311.649 111.764 311.543 111.86 311.472C111.957 311.401 112.077 311.37 112.196 311.386C112.315 311.402 112.423 311.463 112.497 311.558C112.571 311.652 112.606 311.771 112.594 311.891C111.329 320.231 108.704 328.306 104.824 335.793C103.739 337.883 102.56 339.921 101.287 341.905C100.966 342.406 100.24 341.834 100.559 341.336H100.559Z"
      fill="#F2F2F2"
    />
    <path
      d="M112.602 312.797C115.832 312.797 118.451 310.175 118.451 306.94C118.451 303.705 115.832 301.082 112.602 301.082C109.371 301.082 106.752 303.705 106.752 306.94C106.752 310.175 109.371 312.797 112.602 312.797Z"
      fill="#F2F2F2"
    />
    <path
      d="M444.05 223.611L444.191 329.773C444.191 331.744 443.411 333.634 442.022 335.03C440.632 336.426 438.746 337.212 436.778 337.218L392.74 337.282C405.137 314.059 412.156 277.507 412.115 249.331C412.104 238.212 410.976 227.122 408.748 216.229L436.616 216.188C438.584 216.188 440.472 216.969 441.866 218.361C443.259 219.752 444.045 221.64 444.05 223.611Z"
      fill={fill}
    />
    <path
      d="M504.772 343H70.2042C70.0187 343 69.8409 342.925 69.7099 342.794C69.5789 342.662 69.5054 342.484 69.5054 342.298C69.5054 342.113 69.5789 341.934 69.7099 341.803C69.8409 341.671 70.0187 341.597 70.2042 341.597H504.772C504.958 341.597 505.137 341.671 505.268 341.802C505.399 341.934 505.473 342.112 505.473 342.298C505.473 342.485 505.399 342.663 505.268 342.795C505.137 342.926 504.958 343 504.772 343Z"
      fill="#3F3D56"
    />
    <path
      d="M379.516 209.1L301.032 208.792L308.664 183.366C309.273 181.369 310.505 179.619 312.178 178.373C313.852 177.127 315.88 176.449 317.966 176.44H318.003L362.8 176.617C364.892 176.634 366.923 177.323 368.594 178.583C370.266 179.843 371.489 181.607 372.084 183.616L379.516 209.1Z"
      fill="#3F3D56"
    />
    <path
      d="M423.332 200.4C421.146 200.4 419.05 201.27 417.505 202.817C415.959 204.365 415.091 206.464 415.091 208.652H431.572C431.572 206.464 430.704 204.365 429.159 202.817C427.613 201.27 425.517 200.4 423.332 200.4Z"
      fill={fill}
    />
    <path
      d="M448.642 342.449H232.033C229.38 342.446 226.837 341.389 224.961 339.511C223.085 337.632 222.03 335.085 222.027 332.429V218.083C222.03 215.426 223.085 212.879 224.961 211.001C226.837 209.122 229.38 208.065 232.033 208.062H448.642C451.295 208.065 453.838 209.122 455.714 211.001C457.59 212.879 458.645 215.426 458.648 218.083V332.429C458.645 335.085 457.59 337.632 455.714 339.511C453.838 341.389 451.295 342.446 448.642 342.449ZM232.033 209.241C229.692 209.244 227.448 210.176 225.793 211.834C224.138 213.491 223.206 215.739 223.204 218.083V332.429C223.206 334.773 224.138 337.02 225.793 338.677C227.448 340.335 229.692 341.267 232.033 341.27H448.642C450.983 341.267 453.227 340.335 454.882 338.677C456.537 337.02 457.468 334.773 457.471 332.429V218.083C457.468 215.739 456.537 213.491 454.882 211.834C453.227 210.176 450.983 209.244 448.642 209.241H232.033Z"
      fill="#3F3D56"
    />
    <path
      d="M349.755 199.517H330.92C329.593 199.517 328.32 198.989 327.382 198.05C326.444 197.11 325.917 195.836 325.917 194.507C325.917 193.178 326.444 191.904 327.382 190.964C328.32 190.025 329.593 189.497 330.92 189.497H349.755C351.082 189.497 352.355 190.025 353.293 190.964C354.231 191.904 354.759 193.178 354.759 194.507C354.759 195.836 354.231 197.11 353.293 198.05C352.355 198.989 351.082 199.517 349.755 199.517Z"
      fill="white"
    />
    <path
      d="M340.338 320.052C331.49 320.052 322.841 317.424 315.484 312.502C308.128 307.58 302.394 300.584 299.008 292.399C295.622 284.213 294.737 275.207 296.463 266.517C298.189 257.828 302.449 249.846 308.706 243.581C314.962 237.316 322.933 233.05 331.61 231.322C340.288 229.593 349.283 230.48 357.457 233.871C365.631 237.261 372.617 243.003 377.533 250.369C382.448 257.736 385.072 266.397 385.072 275.256C385.059 287.133 380.341 298.519 371.955 306.917C363.568 315.314 352.198 320.038 340.338 320.052ZM340.338 231.64C331.723 231.64 323.301 234.198 316.138 238.99C308.975 243.783 303.393 250.595 300.096 258.565C296.799 266.535 295.937 275.305 297.617 283.765C299.298 292.226 303.446 299.998 309.538 306.098C315.629 312.198 323.391 316.352 331.84 318.035C340.289 319.718 349.047 318.854 357.006 315.553C364.965 312.251 371.768 306.661 376.554 299.488C381.34 292.316 383.895 283.883 383.895 275.256C383.882 263.692 379.289 252.606 371.123 244.429C362.957 236.252 351.886 231.653 340.338 231.64Z"
      fill="#3F3D56"
    />
    <path
      d="M340.337 304.726C334.517 304.726 328.826 302.998 323.987 299.76C319.147 296.521 315.375 291.919 313.147 286.534C310.919 281.149 310.337 275.223 311.472 269.506C312.608 263.79 315.411 258.538 319.527 254.417C323.643 250.295 328.887 247.489 334.596 246.351C340.305 245.214 346.222 245.798 351.6 248.028C356.978 250.259 361.574 254.036 364.808 258.883C368.042 263.729 369.768 269.427 369.768 275.256C369.759 283.069 366.656 290.56 361.138 296.085C355.621 301.61 348.14 304.718 340.337 304.726ZM340.337 246.964C334.749 246.964 329.287 248.623 324.641 251.732C319.994 254.841 316.373 259.259 314.235 264.429C312.096 269.599 311.537 275.287 312.627 280.775C313.717 286.263 316.408 291.304 320.359 295.261C324.31 299.218 329.345 301.912 334.825 303.004C340.306 304.096 345.987 303.535 351.149 301.394C356.312 299.253 360.725 295.626 363.829 290.974C366.934 286.321 368.591 280.851 368.591 275.256C368.582 267.755 365.603 260.564 360.306 255.26C355.009 249.956 347.828 246.972 340.337 246.964Z"
      fill="#3F3D56"
    />
    <path
      d="M340.338 297.654C352.691 297.654 362.705 287.626 362.705 275.256C362.705 262.886 352.691 252.858 340.338 252.858C327.985 252.858 317.97 262.886 317.97 275.256C317.97 287.626 327.985 297.654 340.338 297.654Z"
      fill="#CCCCCC"
    />
    <path
      d="M326.8 275.256C326.8 271.705 328.081 268.275 330.406 265.594C332.731 262.914 335.944 261.164 339.455 260.667C337.368 260.37 335.242 260.525 333.221 261.122C331.199 261.719 329.33 262.744 327.738 264.127C326.146 265.51 324.87 267.22 323.995 269.14C323.12 271.06 322.668 273.145 322.668 275.256C322.668 277.366 323.12 279.452 323.995 281.372C324.87 283.292 326.146 285.001 327.738 286.384C329.33 287.768 331.199 288.792 333.221 289.389C335.242 289.986 337.368 290.141 339.455 289.844C335.944 289.347 332.731 287.598 330.406 284.917C328.081 282.237 326.8 278.806 326.8 275.256Z"
      fill="white"
    />
    <path
      d="M438.047 209.242H408.616V208.652C408.616 204.744 410.167 200.996 412.926 198.233C415.686 195.469 419.429 193.917 423.332 193.917C427.234 193.917 430.977 195.469 433.737 198.233C436.497 200.996 438.047 204.744 438.047 208.652V209.242ZM409.806 208.063H436.857C436.705 204.573 435.214 201.276 432.694 198.86C430.174 196.445 426.82 195.096 423.332 195.096C419.843 195.096 416.489 196.445 413.969 198.86C411.449 201.276 409.958 204.573 409.806 208.063Z"
      fill="#3F3D56"
    />
    <path
      d="M236.742 225.745C235.578 225.745 234.44 225.4 233.472 224.752C232.504 224.104 231.749 223.184 231.304 222.107C230.858 221.03 230.742 219.845 230.969 218.701C231.196 217.558 231.757 216.508 232.58 215.683C233.403 214.859 234.452 214.298 235.593 214.07C236.735 213.843 237.919 213.96 238.994 214.406C240.07 214.852 240.989 215.607 241.636 216.577C242.283 217.546 242.628 218.685 242.628 219.851C242.626 221.414 242.005 222.912 240.902 224.017C239.799 225.122 238.302 225.743 236.742 225.745ZM236.742 215.136C235.81 215.136 234.9 215.412 234.126 215.931C233.351 216.449 232.748 217.185 232.391 218.047C232.035 218.908 231.942 219.856 232.123 220.771C232.305 221.686 232.754 222.526 233.412 223.185C234.071 223.845 234.91 224.294 235.823 224.476C236.737 224.658 237.683 224.564 238.544 224.208C239.404 223.851 240.14 223.246 240.657 222.471C241.175 221.695 241.451 220.784 241.451 219.851C241.449 218.601 240.953 217.402 240.07 216.519C239.187 215.635 237.99 215.137 236.742 215.136Z"
      fill="#3F3D56"
    />
    <path
      d="M236.742 222.798C238.368 222.798 239.685 221.479 239.685 219.851C239.685 218.224 238.368 216.904 236.742 216.904C235.117 216.904 233.799 218.224 233.799 219.851C233.799 221.479 235.117 222.798 236.742 222.798Z"
      fill={fill}
    />
    <path
      d="M252.046 222.798C253.671 222.798 254.989 221.479 254.989 219.851C254.989 218.224 253.671 216.904 252.046 216.904C250.421 216.904 249.103 218.224 249.103 219.851C249.103 221.479 250.421 222.798 252.046 222.798Z"
      fill="#CCCCCC"
    />
    <path
      d="M267.35 222.798C268.975 222.798 270.293 221.479 270.293 219.851C270.293 218.224 268.975 216.904 267.35 216.904C265.724 216.904 264.407 218.224 264.407 219.851C264.407 221.479 265.724 222.798 267.35 222.798Z"
      fill="#CCCCCC"
    />
    <path
      d="M252.046 225.745C250.882 225.745 249.744 225.4 248.776 224.752C247.808 224.104 247.054 223.184 246.608 222.107C246.163 221.03 246.046 219.845 246.273 218.701C246.5 217.558 247.061 216.508 247.884 215.683C248.707 214.859 249.756 214.298 250.898 214.07C252.04 213.843 253.223 213.96 254.299 214.406C255.374 214.852 256.294 215.607 256.94 216.577C257.587 217.546 257.932 218.685 257.932 219.851C257.931 221.414 257.31 222.912 256.206 224.017C255.103 225.122 253.607 225.743 252.046 225.745ZM252.046 215.136C251.115 215.136 250.204 215.412 249.43 215.931C248.656 216.449 248.052 217.185 247.696 218.047C247.339 218.908 247.246 219.856 247.428 220.771C247.61 221.686 248.058 222.526 248.717 223.185C249.375 223.845 250.214 224.294 251.128 224.476C252.041 224.658 252.988 224.564 253.848 224.208C254.709 223.851 255.444 223.246 255.961 222.471C256.479 221.695 256.755 220.784 256.755 219.851C256.754 218.601 256.257 217.402 255.374 216.519C254.492 215.635 253.295 215.137 252.046 215.136Z"
      fill="#3F3D56"
    />
    <path
      d="M267.35 225.745C266.186 225.745 265.048 225.4 264.08 224.752C263.112 224.104 262.357 223.184 261.912 222.107C261.466 221.03 261.35 219.845 261.577 218.701C261.804 217.558 262.365 216.508 263.188 215.683C264.011 214.859 265.06 214.298 266.202 214.07C267.343 213.843 268.527 213.96 269.602 214.406C270.678 214.852 271.597 215.607 272.244 216.577C272.891 217.546 273.236 218.685 273.236 219.851C273.234 221.414 272.614 222.912 271.51 224.017C270.407 225.122 268.911 225.743 267.35 225.745ZM267.35 215.136C266.419 215.136 265.508 215.412 264.734 215.931C263.959 216.449 263.356 217.185 263 218.047C262.643 218.908 262.55 219.856 262.732 220.771C262.913 221.686 263.362 222.526 264.02 223.185C264.679 223.845 265.518 224.294 266.431 224.476C267.345 224.658 268.292 224.564 269.152 224.208C270.012 223.851 270.748 223.246 271.265 222.471C271.783 221.695 272.059 220.784 272.059 219.851C272.057 218.601 271.561 217.402 270.678 216.519C269.795 215.635 268.598 215.137 267.35 215.136Z"
      fill="#3F3D56"
    />
    <path
      d="M299.135 259.267H222.615V260.445H299.135V259.267Z"
      fill="#3F3D56"
    />
    <path
      d="M297.234 282.994H222.615V284.173H297.234V282.994Z"
      fill="#3F3D56"
    />
    <path d="M458.06 259.267H381.54V260.445H458.06V259.267Z" fill="#3F3D56" />
    <path
      d="M457.925 282.994H383.306V284.173H457.925V282.994Z"
      fill="#3F3D56"
    />
    <path
      d="M160.588 142.918C155.815 140.722 152.677 134.444 152.856 128.253C153.035 122.061 156.289 116.271 160.776 113.318C162.964 111.914 165.466 111.077 168.057 110.881C170.648 110.685 173.247 111.136 175.621 112.195C180.437 114.248 186.904 114.807 189.799 119.91C192.023 123.831 191.222 132.579 189.647 136.939C188.285 140.708 184.956 142.831 181.71 144.019C178.117 145.323 174.287 145.838 170.477 145.531C166.668 145.224 162.969 144.101 159.631 142.238"
      fill="#2F2E41"
    />
    <path
      d="M131.484 335.204L124.622 332.968L129.967 305.399L140.095 308.699L131.484 335.204Z"
      fill="#FFB8B8"
    />
    <path
      d="M120.359 329.409L133.593 333.72L130.882 342.064L109.315 335.038C109.671 333.943 110.239 332.928 110.987 332.052C111.734 331.176 112.647 330.456 113.672 329.933C114.698 329.411 115.816 329.095 116.963 329.005C118.111 328.915 119.264 329.052 120.359 329.409Z"
      fill="#2F2E41"
    />
    <path
      d="M192.917 335.353L185.701 335.353L182.268 307.48L192.919 307.481L192.917 335.353Z"
      fill="#FFB8B8"
    />
    <path
      d="M180.546 333.289H194.464V342.064H171.784C171.784 340.911 172.011 339.77 172.451 338.706C172.891 337.641 173.537 336.674 174.35 335.859C175.164 335.044 176.13 334.398 177.193 333.957C178.256 333.516 179.396 333.289 180.546 333.289Z"
      fill="#2F2E41"
    />
    <path
      d="M192.554 323.953C192.511 323.953 192.467 323.952 192.424 323.95L184.427 323.568C183.754 323.535 183.118 323.247 182.65 322.761C182.181 322.276 181.915 321.63 181.904 320.955L180.821 241.744C180.818 241.547 180.75 241.357 180.628 241.204C180.505 241.05 180.335 240.942 180.144 240.896C179.953 240.851 179.753 240.87 179.574 240.951C179.395 241.032 179.249 241.17 179.157 241.344L136.801 321.764C136.539 322.267 136.12 322.673 135.609 322.919C135.098 323.166 134.52 323.24 133.963 323.132L126.005 321.614C125.63 321.542 125.275 321.391 124.964 321.169C124.653 320.948 124.394 320.661 124.204 320.33C124.013 319.999 123.897 319.63 123.862 319.25C123.828 318.869 123.875 318.486 124.002 318.126L154.863 230.567L157.238 204.667C157.291 204.062 157.55 203.495 157.972 203.06C158.394 202.625 158.953 202.35 159.555 202.28C168.547 201.173 181.112 193.795 186.097 190.674C186.403 190.481 186.745 190.354 187.103 190.3C187.461 190.246 187.826 190.267 188.175 190.361C188.528 190.456 188.858 190.623 189.144 190.85C189.431 191.078 189.668 191.362 189.841 191.684C201.849 214.03 206.441 239.624 202.953 264.758L195.174 321.66C195.09 322.296 194.778 322.879 194.296 323.301C193.814 323.723 193.195 323.955 192.554 323.953Z"
      fill="#2F2E41"
    />
    <path
      d="M171.029 149.002C179.014 149.002 185.486 142.521 185.486 134.525C185.486 126.53 179.014 120.049 171.029 120.049C163.045 120.049 156.573 126.53 156.573 134.525C156.573 142.521 163.045 149.002 171.029 149.002Z"
      fill="#FFB8B8"
    />
    <path
      d="M156.624 210.884L152.271 179.736C151.212 175.003 152.072 170.043 154.663 165.945C157.254 161.847 161.364 158.946 166.089 157.881C170.815 156.816 175.769 157.673 179.864 160.264C183.958 162.856 186.858 166.969 187.926 171.7C189.165 177.457 191.684 184.466 190.764 192.022C184.317 209.94 172.614 215.464 156.624 210.884Z"
      fill="#B3B3B3"
    />
    <path
      d="M175.755 162.552H165.159C164.457 162.551 163.784 162.272 163.287 161.774C162.791 161.277 162.512 160.603 162.511 159.9V156.363C162.512 155.66 162.791 154.986 163.287 154.489C163.784 153.991 164.457 153.712 165.159 153.711H175.755C176.457 153.712 177.13 153.991 177.627 154.489C178.123 154.986 178.402 155.66 178.403 156.363V159.9C178.402 160.603 178.123 161.277 177.627 161.774C177.13 162.272 176.457 162.551 175.755 162.552Z"
      fill="#B3B3B3"
    />
    <path
      d="M229.45 221.427C229.737 220.547 229.828 219.615 229.717 218.696C229.606 217.777 229.295 216.893 228.806 216.108C228.317 215.322 227.661 214.654 226.886 214.149C226.111 213.645 225.235 213.316 224.32 213.188L191.341 165.908L181.562 175.589L217.054 219.019C216.94 220.573 217.399 222.116 218.344 223.354C219.29 224.592 220.656 225.439 222.184 225.736C223.712 226.033 225.295 225.758 226.634 224.964C227.974 224.17 228.975 222.911 229.45 221.427V221.427Z"
      fill="#FFB8B8"
    />
    <path
      d="M201.657 180.37L189.747 188.331C189.408 188.558 189.025 188.709 188.623 188.773C188.22 188.838 187.809 188.815 187.416 188.706C187.024 188.597 186.659 188.405 186.347 188.142C186.036 187.879 185.784 187.552 185.61 187.183L179.509 174.259C178.363 172.516 177.953 170.389 178.369 168.345C178.786 166.3 179.995 164.504 181.732 163.35C183.469 162.197 185.591 161.78 187.634 162.19C189.678 162.601 191.475 163.806 192.632 165.542L202.185 176.103C202.458 176.406 202.663 176.764 202.786 177.153C202.909 177.542 202.947 177.953 202.897 178.358C202.846 178.763 202.71 179.152 202.496 179.499C202.282 179.846 201.996 180.143 201.657 180.37Z"
      fill="#B3B3B3"
    />
    <path
      d="M112.943 157.897C113.015 158.215 113.062 158.539 113.084 158.864L138.369 173.471L144.515 169.928L151.067 178.517L140.795 185.848C139.966 186.44 138.966 186.747 137.948 186.722C136.929 186.697 135.946 186.342 135.146 185.71L109.072 165.113C107.896 165.549 106.614 165.616 105.399 165.305C104.183 164.994 103.091 164.32 102.267 163.372C101.443 162.424 100.927 161.248 100.787 159.999C100.647 158.751 100.891 157.489 101.484 156.382C102.078 155.275 102.994 154.376 104.111 153.803C105.228 153.23 106.492 153.012 107.736 153.176C108.98 153.341 110.145 153.881 111.075 154.724C112.005 155.567 112.656 156.674 112.943 157.897V157.897Z"
      fill="#FFB8B8"
    />
    <path
      d="M145.718 184.086L139.365 171.236C139.184 170.87 139.084 170.47 139.072 170.062C139.06 169.654 139.135 169.249 139.293 168.873C139.452 168.497 139.689 168.159 139.99 167.884C140.29 167.608 140.646 167.4 141.034 167.275L154.618 162.878C156.491 161.962 158.651 161.828 160.623 162.505C162.595 163.181 164.219 164.613 165.138 166.486C166.058 168.36 166.198 170.522 165.528 172.498C164.857 174.475 163.432 176.105 161.564 177.031L149.876 185.159C149.541 185.392 149.16 185.549 148.759 185.621C148.358 185.693 147.946 185.678 147.552 185.576C147.157 185.474 146.789 185.288 146.473 185.031C146.156 184.774 145.899 184.452 145.718 184.086H145.718Z"
      fill="#B3B3B3"
    />
    <path
      d="M180.731 116.294C181.251 116.37 181.782 116.272 182.242 116.015C182.701 115.759 183.063 115.357 183.272 114.874C183.465 114.384 183.543 113.856 183.5 113.331C183.458 112.806 183.296 112.298 183.027 111.846C182.48 110.944 181.716 110.193 180.805 109.663C179.755 108.961 178.542 108.543 177.284 108.45C176.656 108.409 176.026 108.503 175.438 108.727C174.85 108.95 174.316 109.298 173.873 109.746C173.442 110.205 173.157 110.783 173.055 111.406C172.953 112.028 173.039 112.667 173.302 113.24C173.83 114.261 174.939 114.833 176.02 115.222C178.164 115.991 180.448 116.291 182.717 116.103"
      fill="#2F2E41"
    />
    <path
      d="M185.354 141.118C182.827 141.237 183.262 135.868 182.471 133.462C181.68 131.055 180.59 128.185 178.103 127.724C176.065 127.346 174.222 128.839 172.447 129.91C169.282 131.82 165.186 132.524 161.863 130.907C158.54 129.289 156.519 124.966 158.135 121.639C158.627 120.725 159.302 119.921 160.117 119.278C160.932 118.635 161.87 118.167 162.873 117.901C164.884 117.384 166.98 117.283 169.031 117.605C173.608 118.167 178.005 119.728 181.913 122.178C184.61 123.871 187.176 126.178 187.979 129.262C188.782 132.347 189.109 140.735 185.971 141.266L185.354 141.118Z"
      fill="#2F2E41"
    />
    <path
      d="M178.743 108.827C176.834 106.404 177.553 102.586 179.777 100.451C182.002 98.3148 185.31 97.6492 188.38 97.9208C191.422 98.2276 194.329 99.3298 196.811 101.117C199.293 102.904 201.262 105.313 202.521 108.103C204.461 112.547 204.445 117.629 206.2 122.15C207.135 124.56 208.553 126.752 210.366 128.592C212.179 130.433 214.35 131.882 216.743 132.85C219.137 133.819 221.704 134.287 224.285 134.225C226.866 134.163 229.407 133.572 231.751 132.49C234.805 131.08 237.713 128.319 237.538 124.956C239.469 132.41 235.992 140.936 229.402 144.906C225.543 147.231 220.971 148.02 216.5 148.564C213.586 148.918 210.591 149.181 207.747 148.452C203.769 147.431 200.438 144.491 198.421 140.909C196.404 137.326 195.61 133.152 195.58 129.039C195.556 125.836 195.974 122.63 195.753 119.434C195.532 116.238 194.572 112.932 192.259 110.72C189.945 108.507 186.041 107.778 183.502 109.727C182.481 110.511 181.733 111.655 180.584 112.233C179.435 112.81 177.62 112.361 177.543 111.076L178.743 108.827Z"
      fill="#2F2E41"
    />
    <path
      d="M123.351 158.001H4.11169C3.02159 158 1.9765 157.566 1.20568 156.794C0.434866 156.022 0.00126495 154.976 0 153.884V86.6349C0.00126651 85.5433 0.434867 84.4968 1.20568 83.7249C1.9765 82.953 3.02159 82.5188 4.11169 82.5176H123.351C124.441 82.5188 125.486 82.953 126.257 83.7249C127.028 84.4968 127.461 85.5433 127.463 86.6349V153.884C127.461 154.976 127.028 156.022 126.257 156.794C125.486 157.566 124.441 158 123.351 158.001ZM4.11169 83.4325C3.26383 83.4335 2.45096 83.7712 1.85143 84.3715C1.25189 84.9719 0.914657 85.7859 0.913705 86.6349V153.884C0.914657 154.733 1.25189 155.547 1.85143 156.147C2.45096 156.748 3.26382 157.086 4.11169 157.086H123.351C124.199 157.085 125.012 156.748 125.611 156.147C126.211 155.547 126.548 154.733 126.549 153.884V86.6349C126.548 85.7859 126.211 84.9719 125.611 84.3716C125.012 83.7712 124.199 83.4335 123.351 83.4325H4.11169Z"
      fill="#3F3D56"
    />
    <path
      d="M43.4205 129.725C52.8372 129.725 60.471 122.081 60.471 112.652C60.471 103.222 52.8372 95.5781 43.4205 95.5781C34.0039 95.5781 26.3701 103.222 26.3701 112.652C26.3701 122.081 34.0039 129.725 43.4205 129.725Z"
      fill="#E6E6E6"
    />
    <path
      d="M109.123 142.802L89.1083 108.089C88.9832 107.872 88.8032 107.692 88.5866 107.566C88.3699 107.441 88.1241 107.375 87.8739 107.375C87.6237 107.375 87.3779 107.441 87.1612 107.566C86.9446 107.692 86.7646 107.872 86.6395 108.089L74.4253 129.273L56.2162 97.6913C56.0558 97.4131 55.8252 97.1821 55.5474 97.0215C55.2696 96.8609 54.9544 96.7764 54.6336 96.7764C54.3129 96.7764 53.9977 96.8609 53.7199 97.0215C53.4421 97.1821 53.2114 97.4131 53.0511 97.6913L27.3898 142.198C27.2295 142.477 27.145 142.792 27.145 143.113C27.145 143.435 27.2295 143.75 27.3898 144.028C27.5502 144.306 27.7809 144.537 28.0587 144.698C28.3365 144.859 28.6517 144.943 28.9724 144.943H107.888C108.139 144.943 108.384 144.877 108.601 144.752C108.818 144.627 108.998 144.447 109.123 144.23C109.248 144.013 109.314 143.767 109.314 143.516C109.314 143.265 109.248 143.019 109.123 142.802Z"
      fill={fill}
    />
    <path
      d="M15.9899 90.7529H8.68018V98.0726H15.9899V90.7529Z"
      fill="#3F3D56"
    />
    <path
      d="M169.766 58.9803H76.5965C75.7448 58.9793 74.9282 58.64 74.3259 58.0369C73.7236 57.4338 73.3848 56.6161 73.3838 55.7632V3.21711C73.3848 2.36419 73.7236 1.54648 74.3259 0.943371C74.9282 0.340261 75.7448 0.000992124 76.5965 0H169.766C170.617 0.000960986 171.434 0.340219 172.036 0.943335C172.639 1.54645 172.977 2.36418 172.978 3.21711V55.7632C172.977 56.6161 172.639 57.4338 172.036 58.0369C171.434 58.64 170.617 58.9793 169.766 58.9803ZM76.5965 0.714912C75.934 0.715658 75.2989 0.979521 74.8304 1.44861C74.362 1.9177 74.0985 2.55372 74.0977 3.21711V55.7632C74.0985 56.4266 74.362 57.0626 74.8304 57.5317C75.2989 58.0008 75.934 58.2646 76.5965 58.2654H169.766C170.428 58.2646 171.063 58.0007 171.532 57.5316C172 57.0625 172.264 56.4265 172.264 55.7632V3.21711C172.264 2.55373 172 1.91773 171.532 1.44865C171.063 0.979563 170.428 0.715686 169.766 0.714912H76.5965Z"
      fill="#CCCCCC"
    />
    <path
      d="M107.311 36.8875C114.669 36.8875 120.633 30.9146 120.633 23.5468C120.633 16.1789 114.669 10.2061 107.311 10.2061C99.953 10.2061 93.9883 16.1789 93.9883 23.5468C93.9883 30.9146 99.953 36.8875 107.311 36.8875Z"
      fill="#F2F2F2"
    />
    <path
      d="M158.648 47.1016L143.01 19.9777C142.912 19.8082 142.771 19.6674 142.602 19.5695C142.433 19.4716 142.241 19.4201 142.045 19.4201C141.85 19.4201 141.658 19.4716 141.488 19.5695C141.319 19.6674 141.178 19.8082 141.081 19.9777L131.537 36.5306L117.309 11.8536C117.184 11.6362 117.003 11.4557 116.786 11.3302C116.569 11.2047 116.323 11.1387 116.072 11.1387C115.822 11.1387 115.575 11.2047 115.358 11.3302C115.141 11.4557 114.961 11.6362 114.836 11.8536L94.7851 46.6297C94.6597 46.8471 94.5937 47.0936 94.5938 47.3446C94.5938 47.5956 94.6597 47.8422 94.7851 48.0595C94.9104 48.2769 95.0906 48.4574 95.3077 48.5829C95.5248 48.7084 95.771 48.7744 96.0216 48.7744H157.684C157.879 48.7744 158.071 48.7229 158.241 48.625C158.41 48.5271 158.55 48.3864 158.648 48.2168C158.746 48.0473 158.797 47.855 158.797 47.6592C158.797 47.4635 158.746 47.2712 158.648 47.1016Z"
      fill="#E6E6E6"
    />
    <path d="M85.8775 6.43457H80.166V12.1539H85.8775V6.43457Z" fill="#CCCCCC" />

    <defs>
      <clipPath id="clip0">
        <rect width="505.473" height="343" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyAvatar;
