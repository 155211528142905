import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { AppLayout } from "../../../../components/app/AppLayout";
import { SignupClientCompleteScreen } from "../../../../components/screen/SignupClientCompleteScreen";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_ROUTE } from "../../../routes";
import { SignUpCompleteRouteQuery } from "./__generated__/SignUpCompleteRouteQuery.graphql";

export function SignUpCompleteRoute() {
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");

  const props = useLazyLoadQuery<SignUpCompleteRouteQuery>(
    graphql`
      query SignUpCompleteRouteQuery {
        me {
          ...SignupClientCompleteScreen_user
        }

        workspace {
          ...SignupClientCompleteScreen_workspace
        }
      }
    `,
    {},
  );

  const { me, workspace } = props;

  return (
    me && (
      <AppLayout title="Tell us about yourself" appBar={false}>
        <SignupClientCompleteScreen
          returnUrl={returnUrl || DEFAULT_ROUTE}
          user={me}
          workspace={workspace}
        />
      </AppLayout>
    )
  );
}
