/**
 * @generated SignedSource<<2922370fd0d4f722fb01b4b2c8d0b3de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramTemplatesScreen$data = {
  readonly programTemplates: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"ProgramTemplatesList_connection">;
  } | null | undefined;
  readonly " $fragmentType": "ProgramTemplatesScreen";
};
export type ProgramTemplatesScreen$key = {
  readonly " $data"?: ProgramTemplatesScreen$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramTemplatesScreen">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramTemplatesScreen",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgramTemplateConnection",
      "kind": "LinkedField",
      "name": "programTemplates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgramTemplatesList_connection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "e6c8ea967661484055b5b6817507067e";

export default node;
