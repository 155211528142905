import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  AttachmentElement,
  MessageAttachmentsPreviewListItem,
} from "./MessageAttachmentsPreviewListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    width: "100%",
    overflowY: "scroll",
  },
}));

export interface MessageAttachmentsPreviewListProps extends BoxProps {
  attachments: AttachmentElement[];
  onUpdateAttachments: (attachments: AttachmentElement[]) => void;
}

export function MessageAttachmentsPreviewList(
  props: MessageAttachmentsPreviewListProps,
) {
  const { className, attachments, onUpdateAttachments, ...other } = props;
  const s = useStyles();

  const handleRemove = React.useCallback(
    (attachment: AttachmentElement) =>
      onUpdateAttachments(attachments.filter((it) => it !== attachment)),
    [attachments, onUpdateAttachments],
  );

  return (
    attachments.length > 0 && (
      <Box className={clsx(s.root, className)} {...other}>
        {attachments.map((attachment, index) => (
          <MessageAttachmentsPreviewListItem
            key={index}
            attachment={attachment}
            onRemove={handleRemove}
          />
        ))}
      </Box>
    )
  );
}
