/**
 * @generated SignedSource<<b7523faa1a8eb4a294f388f33e32c6bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RefreshSlug$data = ReadonlyArray<{
  readonly slug: string;
  readonly slugId: string;
  readonly " $fragmentType": "RefreshSlug";
}>;
export type RefreshSlug$key = ReadonlyArray<{
  readonly " $data"?: RefreshSlug$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefreshSlug">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RefreshSlug",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slugId",
      "storageKey": null
    }
  ],
  "type": "Slug",
  "abstractKey": "__isSlug"
};

(node as any).hash = "0aff3a24881a22afaab01d41c6d882a3";

export default node;
