import clsx from "clsx";
import React from "react";
import { LinearProgress, LinearProgressProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(6),
    borderRadius: theme.spacing(0.5),
    flexGrow: 1,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",

    "&::before": {
      display: "block",
      content: ({ label }: MultipleChoiceLinearProgressProps) => `'${label}'`,
      position: "absolute",
      left: theme.spacing(4.5),
      top: "calc(50% - 12px)",
      color: theme.palette.common.white,
      zIndex: 1,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflowY: "hidden",
      maxWidth: "75%",
    },
  },
  colorPrimary: {
    backgroundColor: `${theme.palette.primary.main}26`,

    "&::after": {
      display: "block",
      content: ({ count }: MultipleChoiceLinearProgressProps) =>
        `'${count} response${count === 1 ? "" : "s"}'`,
      position: "absolute",
      right: theme.spacing(1),
      top: `calc(50% - ${theme.spacing(2)})`,
      color: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      backgroundColor: `${colorSystem.white}66`,
      borderRadius: theme.spacing(0.5),
    },
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export interface MultipleChoiceLinearProgressProps extends LinearProgressProps {
  count: number;
  label: string;
}

export function MultipleChoiceLinearProgress(
  props: MultipleChoiceLinearProgressProps,
) {
  const { className, ...other } = props;
  const s = useStyles(props);

  return (
    <LinearProgress
      classes={{
        root: clsx(s.root, className),
        colorPrimary: s.colorPrimary,
        bar: s.bar,
      }}
      {...other}
    />
  );
}
