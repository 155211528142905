import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClientNutritionTargetDrawerList } from "./ClientNutritionTargetDrawerList";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    marginBottom: theme.spacing(2),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },
}));

export interface ClientNutritionTargetListViewProps extends BoxProps {
  onCreateClick: ButtonProps["onClick"];
  onItemActionClick: any;
  nutritionTargets: any;
}

export function ClientNutritionTargetListView(
  props: ClientNutritionTargetListViewProps,
) {
  const {
    className,
    onCreateClick,
    onItemActionClick,
    nutritionTargets,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Button
        className={s.button}
        variant="contained"
        children="Create Nutrition Targets"
        onClick={onCreateClick}
      />
      <ClientNutritionTargetDrawerList
        nutritionTargets={nutritionTargets}
        onItemActionClick={onItemActionClick}
        onUpdate={() => {}}
      />
    </Box>
  );
}
