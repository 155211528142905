import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { capitalize } from "lodash";

import { ProgressLine } from "../progress/ProgressLine";

import { ClientLast7DaysCompletion } from "./ClientLast7DaysCompletion";
import { ClientLast7Days_enrollment$key } from "./__generated__/ClientLast7Days_enrollment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    lineHeight: "29px",
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  last7DaysCompletion: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },

  text: {
    fontSize: 13,
    color: theme.palette.text.secondary,
  },

  typeCompletionWrapper: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },

  progress: {
    marginTop: theme.spacing(1),
  },
}));

const enrollmentFragment = graphql`
  fragment ClientLast7Days_enrollment on Enrollment {
    completion7Days: completion(period: LAST_7_DAYS) {
      rate
    }
    lessons7Days: completion(period: LAST_7_DAYS, componentType: LESSON) {
      completed
      total
      rate
    }
    habits7Days: completion(period: LAST_7_DAYS, componentType: HABIT) {
      completed
      total
      rate
    }
    completionByDate7Days: completionByDate(period: LAST_7_DAYS) {
      ...ClientLast7DaysCompletion_completionByDate
    }
  }
`;

export interface ClientLast7DaysProps extends BoxProps {
  enrollmentRef: ClientLast7Days_enrollment$key;
}

export function ClientLast7Days(props: ClientLast7DaysProps) {
  const { className, enrollmentRef, ...other } = props;
  const s = useStyles();
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h6">
        Last 7 days
      </Typography>
      <Typography className={s.subtitle} variant="body1">
        {enrollment.completion7Days.rate}% completion
      </Typography>
      <ClientLast7DaysCompletion
        className={s.last7DaysCompletion}
        fragmentRef={enrollment.completionByDate7Days}
      />
      {["lessons", "habits"].map((type) => {
        const typeCompletion = enrollment[`${type}7Days`];
        return (
          <Box key={type} className={s.typeCompletionWrapper}>
            <Typography className={s.text}>
              {capitalize(type)} &bull; {typeCompletion.completed} of{" "}
              {typeCompletion.total}
            </Typography>
            <ProgressLine className={s.progress} value={typeCompletion.rate} />
          </Box>
        );
      })}
    </Box>
  );
}
