import clsx from "clsx";
import React from "react";
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  Grid,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { SelectedPhoto } from "./ProgressDialog";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    width: ((comparisonMode: boolean) => (comparisonMode ? 120 : 210)) as any,
    margin: spacing(0, 2),
    [breakpoints.up("md")]: {
      width: "425px !important",
      margin: spacing(0, 3),
    },
  },
  media: {
    height: ((comparisonMode: boolean) => (comparisonMode ? 170 : 300)) as any,
    borderRadius: spacing(1.5),
    [breakpoints.up("md")]: {
      height: "595px !important",
      maxHeight: `calc(100vh - ${spacing(38)})`,
    },
  },
  mediaHighlight: {
    position: "absolute",
    top: -7,
    right: -7,
    bottom: -7,
    left: -7,
    border: "2px solid",
    borderRadius: spacing(2),
    borderColor: palette.primary.main,
  },
  mediaWrapper: {
    borderRadius: 0,
    boxShadow: "none",
    position: "relative",
    overflow: "visible",
  },
  title: {
    paddingTop: spacing(3),
  },
}));

function getWeightValue(weight: SelectedPhoto["photo"]["weight"]): string {
  return weight ? `${weight.measurement} ${weight.unit.toLowerCase()}` : "";
}
export interface ProgressDialogCardProps {
  selectedPhoto: SelectedPhoto;
  cell?: number;
  selected?: boolean;
  onClick?: any;
  className?: string;
  comparisonMode?: boolean;
}

export function ProgressDialogCard(props: ProgressDialogCardProps) {
  const {
    selectedPhoto: {
      url,
      photo: { date, weight },
    },
    cell,
    selected,
    onClick,
    className,
    comparisonMode = false,
  } = props;
  const s = useStyles(comparisonMode);
  const value = getWeightValue(weight);

  return (
    <Grid item className={clsx(s.root, className)}>
      <Card className={s.mediaWrapper}>
        <CardActionArea
          onClick={onClick}
          disabled={selected || !onClick}
          data-cell={cell}
          data-url={url}
        >
          <CardMedia className={s.media} image={url} />
          {selected && <div className={s.mediaHighlight} />}
        </CardActionArea>
      </Card>
      <Typography
        className={s.title}
        variant="h5"
        component="div"
        children={value}
      />
      <Typography
        variant="body1"
        color="textSecondary"
        component="div"
        children={date}
      />
    </Grid>
  );
}

export function ProgressDialogCardSkeleton(props) {
  const { className, cell, selected, onClick, comparisonMode = false } = props;
  const s = useStyles(comparisonMode);

  return (
    <Grid item className={clsx(s.root, className)}>
      <CardActionArea
        disabled={selected || !onClick}
        onClick={onClick}
        data-cell={cell}
      >
        <Card className={s.mediaWrapper}>
          <Skeleton
            className={s.media}
            variant="rectangular"
            animation={false}
          />
          {selected && <div className={s.mediaHighlight} />}
        </Card>
      </CardActionArea>
      <Skeleton className={s.title} variant="text" animation={false} />
      <Skeleton variant="text" animation={false} />
    </Grid>
  );
}
