export enum ListType {
  ORDERED = "ol",
  UNORDERED = "ul",
  TODO = "todo",
}

export const ListStyleType: { [key in ListType]: string } = {
  [ListType.ORDERED]: "decimal",
  [ListType.UNORDERED]: "disc",
  [ListType.TODO]: "todo",
};

export const ELEMENT_LIST = "list";
