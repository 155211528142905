/**
 * @generated SignedSource<<b659838b0e7afa5e81a04c4a216c34a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityCalendar_summary$data = {
  readonly completion: {
    readonly completed: number;
    readonly rate: number;
    readonly total: number;
  };
  readonly longestStreak: number | null | undefined;
  readonly schedule: ReadonlyArray<{
    readonly completed: boolean;
    readonly date: string | null | undefined;
    readonly id: string;
  }>;
  readonly " $fragmentType": "ActivityCalendar_summary";
};
export type ActivityCalendar_summary$key = {
  readonly " $data"?: ActivityCalendar_summary$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityCalendar_summary">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityCalendar_summary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "schedule",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "raw",
              "value": true
            }
          ],
          "kind": "ScalarField",
          "name": "date",
          "storageKey": "date(raw:true)"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longestStreak",
      "storageKey": null
    }
  ],
  "type": "ActivitySummary",
  "abstractKey": null
};
})();

(node as any).hash = "9fd665ceb1b102d7527e47f95d31e89a";

export default node;
