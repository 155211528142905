import React from "react";

import {
  MealLoggingQuestionOptionsListItem,
  MealLoggingQuestionOptionsListItemProps,
} from "./MealLoggingQuestionOptionsListItem";

export interface MealLoggingQuestionMultipleChoiceListItemProps
  extends Omit<MealLoggingQuestionOptionsListItemProps, "type"> {}

export function MealLoggingQuestionMultipleChoiceListItem(
  props: MealLoggingQuestionMultipleChoiceListItemProps,
) {
  return <MealLoggingQuestionOptionsListItem type="radio" {...props} />;
}
