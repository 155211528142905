/**
 * @generated SignedSource<<41c3779f7b88f9d355effdac62ce256a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupSortBy = "NAME_ASC" | "RECENTLY_ACTIVE" | "%future added value";
export type GroupStatus = "ACTIVE" | "ARCHIVED" | "%future added value";
export type CoachGroupsListRouteQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  orderBy: GroupSortBy;
  status: GroupStatus;
};
export type CoachGroupsListRouteQuery$data = {
  readonly clients: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ManageGroupDialog_clients">;
  } | null | undefined;
  readonly groups: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"GroupList_groups">;
  } | null | undefined;
};
export type CoachGroupsListRouteQuery = {
  response: CoachGroupsListRouteQuery$data;
  variables: CoachGroupsListRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "ACTIVE"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachGroupsListRouteQuery",
    "selections": [
      {
        "alias": "groups",
        "args": (v4/*: any*/),
        "concreteType": "GroupConnection",
        "kind": "LinkedField",
        "name": "__CoachGroups_groups_connection",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GroupList_groups"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "clients",
        "args": null,
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "__CoachGroups_clients_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ManageGroupDialog_clients"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CoachGroupsListRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "GroupConnection",
        "kind": "LinkedField",
        "name": "groups",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "clients",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v10/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "first",
          "after",
          "orderBy",
          "status"
        ],
        "handle": "connection",
        "key": "CoachGroups_groups",
        "kind": "LinkedHandle",
        "name": "groups"
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": "clients(first:9999,status:\"ACTIVE\")"
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CoachGroups_clients",
        "kind": "LinkedHandle",
        "name": "clients"
      }
    ]
  },
  "params": {
    "cacheID": "80b4f728dcd8655a70b6b58aa1a654c0",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "groups"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "clients"
          ]
        }
      ]
    },
    "name": "CoachGroupsListRouteQuery",
    "operationKind": "query",
    "text": "query CoachGroupsListRouteQuery(\n  $first: Int\n  $after: String\n  $orderBy: GroupSortBy!\n  $status: GroupStatus!\n) {\n  groups(first: $first, after: $after, orderBy: $orderBy, status: $status) {\n    totalCount\n    edges {\n      cursor\n      node {\n        __typename\n        id\n      }\n    }\n    ...GroupList_groups\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  clients(first: 9999, status: ACTIVE) {\n    edges {\n      cursor\n      node {\n        __typename\n        id\n      }\n    }\n    ...ManageGroupDialog_clients\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ClientAddableItem_client on User {\n  id\n  email\n  displayName\n  photoURL\n}\n\nfragment GroupCard_group on Group {\n  ...ManageGroupDialog_group\n  id\n  name\n  slug\n  archived\n  clients {\n    ...StackedClientsAvatars_clients\n    id\n  }\n}\n\nfragment GroupList_groups on GroupConnection {\n  totalCount\n  edges {\n    node {\n      id\n      ...GroupCard_group\n    }\n  }\n}\n\nfragment ManageGroupDialog_clients on ClientConnection {\n  edges {\n    node {\n      id\n      email\n      displayName\n      ...ClientAddableItem_client\n    }\n  }\n}\n\nfragment ManageGroupDialog_group on Group {\n  id\n  name\n  size\n  clients {\n    email\n    displayName\n    id\n  }\n}\n\nfragment StackedClientsAvatars_clients on User {\n  id\n  displayName\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "6d2b7b1610d366eb395529c6cb83a7bc";

export default node;
