/**
 * @generated SignedSource<<1d03800f6fa7dd7febc513463446556d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupSettingsCard_group$data = {
  readonly id: string;
  readonly imageURL: string | null | undefined;
  readonly inviteCode: {
    readonly " $fragmentSpreads": FragmentRefs<"InviteCodePreviewDialog_inviteCode" | "InviteLinkSettings_inviteCode">;
  } | null | undefined;
  readonly membersCanMessage: boolean;
  readonly membersCanNotDiscover: boolean;
  readonly membersCanNotPost: boolean;
  readonly membersCanNotSeeSidebar: boolean;
  readonly name: string;
  readonly " $fragmentType": "GroupSettingsCard_group";
};
export type GroupSettingsCard_group$key = {
  readonly " $data"?: GroupSettingsCard_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupSettingsCard_group">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupSettingsCard_group",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "membersCanNotPost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "membersCanNotDiscover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "membersCanNotSeeSidebar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "membersCanMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InviteCode",
      "kind": "LinkedField",
      "name": "inviteCode",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InviteLinkSettings_inviteCode"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InviteCodePreviewDialog_inviteCode"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "82c4603ec561ccc4cbc23c7a59f20c13";

export default node;
