import clsx from "clsx";
import React from "react";
import { DialogProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { RequiresUpgradeReason } from "../../constants";
import { ActionButton } from "../button/ActionButton";

import { BaseDialog } from "./BaseDialog";

const useStyles = makeStyles((theme) => ({
  root: {},

  text: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.black,
  },

  upgrade: {
    marginTop: theme.spacing(3),
  },

  downgrade: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
}));

export interface RequiresUpgradeDialogProps extends DialogProps {
  reason: RequiresUpgradeReason;
  onDowngradeClick: () => void;
}

export function RequiresUpgradeDialog(props: RequiresUpgradeDialogProps) {
  const { className, onClose, reason, onDowngradeClick, ...other } = props;
  const s = useStyles();

  const title = React.useMemo(() => {
    switch (reason) {
      case RequiresUpgradeReason.TRAIL_EXPIRED:
        return "Free Trail Expired";
      case RequiresUpgradeReason.SUBSCRIPTION_CANCELED:
        return "Subscription Canceled";
      default:
        return "Upgrade Required";
    }
  }, [reason]);

  const handleUpgradeClick = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  return (
    <>
      <BaseDialog
        className={clsx(s.root, className)}
        title={title}
        showClose={false}
        {...other}
      >
        <Typography className={s.text}>
          You’ll need to upgrade to continue using your account.
        </Typography>
        <ActionButton
          className={s.upgrade}
          fullWidth
          size="small"
          variant="contained"
          children="Upgrade Plan"
          onClick={handleUpgradeClick}
        />
      </BaseDialog>
    </>
  );
}
