import clsx from "clsx";
import React from "react";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { useInputChange } from "../../hooks/useInputChange";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { ActionButton } from "../button/ActionButton";
import { partiallyCompare } from "../../utils/misc";
import { SettingsCard, SettingsCardProps } from "../card/SettingsCard";
import { useUpsertGroupMutation } from "../groups/mutations/UpsertGroup";
import { InviteLinkSettings } from "../coach-clients/InviteLinkSettings";
import { AssetType } from "../../constants";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";
import { InviteCodePreviewDialog } from "../dialog/InviteCodePreviewDialog";

import { GroupSettingsCard_group$key } from "./__generated__/GroupSettingsCard_group.graphql";
import { GroupArchiveDialog } from "./GroupArchiveDialog";
import { SettingsCoverImage } from "./SettingsCoverImage";

const useStyles = makeStyles((theme) => ({
  root: {},

  fieldsGroup: {
    "&:not(:nth-child(1))": {
      marginTop: theme.spacing(5),
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  name: {
    width: "50%",
  },

  settingsList: {
    margin: theme.spacing(-2, 0, 3),
  },

  settingItem: {
    paddingLeft: 0,
  },

  primaryText: {
    fontSize: 18,
    fontWeight: 600,
  },

  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
  },

  generateInviteLink: {
    width: "100%",
  },

  copyInviteLink: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(-1),
    fontSize: 16,
    fontWeight: 500,
  },

  mutedText: {
    color: theme.palette.text.secondary,
  },

  image: {
    marginTop: theme.spacing(2),
  },

  previewInviteLink: {
    fontSize: 16,
    fontWeight: 500,
    marginRight: theme.spacing(-0.5),
  },
}));

const fragment = graphql`
  fragment GroupSettingsCard_group on Group {
    id
    name
    membersCanNotPost
    membersCanNotDiscover
    membersCanNotSeeSidebar
    membersCanMessage
    imageURL

    inviteCode {
      ...InviteLinkSettings_inviteCode
      ...InviteCodePreviewDialog_inviteCode
    }
  }
`;

const groupSettings = [
  {
    name: "membersCanNotPost",
    primary: "Prevent members from posting",
    secondary: "If enabled, members won’t be able to post in this group",
  },

  {
    name: "membersCanNotDiscover",
    primary: "Hide group from members",
    secondary: "If hidden, link will not appear in client’s portal",
  },

  {
    name: "membersCanNotSeeSidebar",
    primary: "Hide sidebar",
    secondary:
      "Members will not see group members and description in right sidebar",
  },

  {
    name: "membersCanMessage",
    primary: "Group chat",
    secondary: "Allow members to view and send group messages.",
  },
] as const;

export interface GroupSettingsCardProps
  extends Omit<SettingsCardProps, "header"> {
  groupRef: GroupSettingsCard_group$key;
}

export function GroupSettingsCard(props: GroupSettingsCardProps) {
  const { className, groupRef, ...other } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const group = useFragment(fragment, groupRef);
  const [updateGroup, updateGroupInFlight] = useUpsertGroupMutation();
  const [name, setName] = React.useState(group.name);
  const [settings, setSettings] = React.useState<Record<string, boolean>>({
    membersCanNotDiscover: group.membersCanNotDiscover,
    membersCanNotPost: group.membersCanNotPost,
    membersCanNotSeeSidebar: group.membersCanNotSeeSidebar,
    membersCanMessage: group.membersCanMessage,
  });
  const [imageURL, setImageURL] = React.useState(group.imageURL);
  const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);
  const [previewInviteCode, setPreviewInviteCode] = React.useState(false);

  const handleArchiveDialogOpen = React.useCallback(() => {
    setOpenArchiveDialog(true);
  }, []);

  const handleArchiveDialogClose = React.useCallback(() => {
    setOpenArchiveDialog(false);
  }, []);

  const handlePreviewInviteCodeOpen = React.useCallback(
    () => setPreviewInviteCode(true),
    [],
  );

  const handlePreviewInviteCodeClose = React.useCallback(
    () => setPreviewInviteCode(false),
    [],
  );

  const handleSettingChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSettings({
        ...settings,
        [event.currentTarget.name]: checked,
      });
    },
    [settings],
  );

  const handleSave = React.useCallback(
    () =>
      updateGroup({
        variables: {
          input: {
            id: group.id,
            name,
            imageURL,
            ...settings,
          },
        },
        onSuccess: () => {
          snackAlert({
            severity: "success",
            message: "Group settings updated",
          });
        },
      }),
    [group.id, imageURL, name, settings, snackAlert, updateGroup],
  );

  const dirty = !partiallyCompare({ name, imageURL, ...settings }, group);
  const pending = updateGroupInFlight;

  return (
    <SettingsCard
      className={clsx(s.root, className)}
      header="Group Settings"
      ButtonProps={{
        onClick: handleSave,
        disabled: !dirty || pending,
      }}
      {...other}
    >
      <DiscardChangesDialog dirty={dirty} />
      <Box className={s.fieldsGroup}>
        <Typography variant="h4" className={s.label}>
          Group name
        </Typography>
        <TextField
          className={s.name}
          variant="outlined"
          value={name}
          onChange={useInputChange(setName)}
        />
      </Box>

      <Box className={s.fieldsGroup}>
        <Typography variant="h4" className={s.label}>
          General settings
        </Typography>

        <List className={s.settingsList}>
          {groupSettings.map(({ name, ...props }, index) => (
            <ListItem key={name} className={s.settingItem} divider>
              <ListItemText
                classes={{
                  primary: s.primaryText,
                  secondary: s.secondaryText,
                }}
                {...props}
              />
              <ListItemSecondaryAction>
                <Switch
                  name={name}
                  checked={settings[name]}
                  onChange={handleSettingChange}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>

      <SettingsCoverImage
        className={s.fieldsGroup}
        nodeId={group.id}
        assetType={AssetType.GROUP_IMAGE}
        image={imageURL}
        onImageChange={setImageURL}
      >
        Give your group identity with a cover photo. Similar to a book cover,
        this photo will appear at the top of your group page. Upload your own,
        or choose a beautiful royalty free image from Unsplash.
      </SettingsCoverImage>

      <Box className={s.fieldsGroup}>
        <Typography variant="h4" className={s.label}>
          Invite settings
        </Typography>

        <List className={s.settingsList}>
          <ListItem
            className={s.settingItem}
            divider={Boolean(group.inviteCode)}
          >
            <ListItemText
              classes={{
                primary: s.primaryText,
                secondary: s.secondaryText,
              }}
              primary="Invite link"
              secondary="Allow clients to join this group via an invite link."
            />

            <ListItemSecondaryAction>
              <InviteLinkSettings
                groupId={group.id}
                inviteCode={group.inviteCode}
                textLink
                GenerateButtonProps={{
                  className: s.generateInviteLink,
                }}
                CopyButtonProps={{
                  className: s.copyInviteLink,
                  variant: "text",
                  color: "primary",
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          {group.inviteCode && (
            <ListItem className={s.settingItem}>
              <ListItemText
                classes={{
                  primary: s.primaryText,
                }}
                primary="Invite code"
              />

              <ListItemSecondaryAction>
                <Button
                  className={s.previewInviteLink}
                  variant="text"
                  color="primary"
                  onClick={handlePreviewInviteCodeOpen}
                >
                  Share via invite code
                </Button>
              </ListItemSecondaryAction>

              {previewInviteCode && (
                <InviteCodePreviewDialog
                  open
                  inviteCode={group.inviteCode}
                  onClose={handlePreviewInviteCodeClose}
                />
              )}
            </ListItem>
          )}
        </List>
      </Box>

      <Box className={s.fieldsGroup}>
        <ActionButton
          color="primary"
          size="large"
          onClick={handleArchiveDialogOpen}
        >
          Archive group
        </ActionButton>
        {openArchiveDialog && (
          <GroupArchiveDialog
            groupId={group.id}
            open={openArchiveDialog}
            onClose={handleArchiveDialogClose}
          />
        )}
      </Box>
    </SettingsCard>
  );
}
