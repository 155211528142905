import React from "react";
import { Add } from "@mui/icons-material";

import { ActionButton } from "../button/ActionButton";

import { ManageGroupDialog, ManageGroupDialogProps } from "./ManageGroupDialog";
import { GroupNameDialog, GroupNameDialogProps } from "./GroupNameDialog";
import { UpsertedGroup } from "./mutations/UpsertGroup";

export interface CreateGroupActionProps {
  disabled?: boolean;
  clientsRef?: ManageGroupDialogProps["clientsRef"];
}

export function CreateGroupAction(props: CreateGroupActionProps) {
  const { disabled = false, clientsRef } = props;
  const [groupNameDialogOpen, setGroupNameDialogOpen] = React.useState(false);
  const [manageGroupDialogOpen, setManageGroupDialogOpen] =
    React.useState(false);
  const [group, setGroup] = React.useState<UpsertedGroup>();

  const handleCreateGroupClick = React.useCallback(() => {
    setGroupNameDialogOpen(true);
  }, []);

  const handleCloseGroupDialog = React.useCallback(() => {
    setGroupNameDialogOpen(false);
  }, []);

  const handleCloseManageGroupDialog = React.useCallback(() => {
    setManageGroupDialogOpen(false);
  }, []);

  const handleCreated: GroupNameDialogProps["onCreated"] = React.useCallback(
    (group) => {
      setGroup(group);
      setManageGroupDialogOpen(true);
    },
    [],
  );

  return (
    <>
      <ActionButton
        startIcon={<Add />}
        size="small"
        children="Create group"
        onClick={handleCreateGroupClick}
        disabled={disabled}
      />

      {groupNameDialogOpen && (
        <GroupNameDialog
          open={true}
          onClose={handleCloseGroupDialog}
          onCreated={handleCreated}
        />
      )}

      {group && manageGroupDialogOpen && (
        <ManageGroupDialog
          open={true}
          onClose={handleCloseManageGroupDialog}
          groupRef={group}
          clientsRef={clientsRef}
        />
      )}
    </>
  );
}
