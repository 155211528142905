import React from "react";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  useOpenState,
} from "../dropdown-menu";
import { ToolbarButton } from "../toolbar";

import { ITurnIntoMenuItemGroup } from "../../new-editor/utils/menuItemTurnIntoGroups";
import { Box, Divider } from "@mui/material";
import { IMenuItem } from "../../new-editor/utils/menuItemUtil";
import { useItemDisabled } from "../../new-editor/hooks";

export interface TurnIntoDropdownMenuProps extends DropdownMenuProps {
  menuItemGroups: ITurnIntoMenuItemGroup[];
  selectedMenuItem: IMenuItem;
  onTypeChange: (type: string) => void;
  label?: string;
}

export function TurnIntoDropdownMenu({
  menuItemGroups,
  selectedMenuItem,
  onTypeChange,
  label = "Turn into",
  ...props
}: TurnIntoDropdownMenuProps) {
  const openState = useOpenState();

  const { icon: SelectedItemIcon, label: selectedItemLabel } = selectedMenuItem;

  const isItemDisabled = useItemDisabled();
  const isItemSelected = (value: string) => selectedMenuItem.value === value;

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <ToolbarButton pressed={openState.open} tooltip="Turn into" isDropdown>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                p: 0.5,
                borderRadius: 1,
                border: "1px solid",
                background: (theme) => theme.palette.common.white,
                aspectRatio: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 1,
              }}
            >
              <SelectedItemIcon />
            </Box>
            <span className="max-lg:hidden">{selectedItemLabel}</span>
          </Box>
        </ToolbarButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className="min-w-0">
        <DropdownMenuLabel className="text-xs">{label}</DropdownMenuLabel>

        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5 p-0"
          value={selectedMenuItem.value}
          onValueChange={onTypeChange}
        >
          {menuItemGroups.map(({ items: groupItems }, groupIndex) => (
            <>
              {groupItems.map(({ value: itemValue, label, icon: Icon }) => (
                <DropdownMenuRadioItem
                  key={itemValue}
                  value={itemValue}
                  className="min-w-[170px] pr-10 h-8"
                  disabled={
                    // The isItemDisabled should disable duplicate for specific items. Do not disable the selected item to not break the styling.
                    isItemDisabled(itemValue) && !isItemSelected(itemValue)
                  }
                >
                  <Box
                    sx={{
                      p: 0.5,
                      borderRadius: 1,
                      border: "1px solid",
                      background: (theme) => theme.palette.common.white,
                      aspectRatio: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 1.2,
                    }}
                  >
                    <Icon className="h-4 w-4" />
                  </Box>
                  {label}
                </DropdownMenuRadioItem>
              ))}
              {groupIndex < menuItemGroups.length - 1 && (
                <Divider sx={{ width: 1 }} />
              )}
            </>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
