import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { useSnackAlert } from "../../../hooks/useSnackAlert";

import { ArchiveProgramMutation } from "./__generated__/ArchiveProgramMutation.graphql";

const mutation = graphql`
  mutation ArchiveProgramMutation($input: ArchiveProgramInput!) {
    archiveProgram(input: $input) {
      programId
    }
  }
`;

export const useArchiveProgramMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<ArchiveProgramMutation>(mutation, () => ({
    configs: [
      {
        type: "RANGE_DELETE",
        parentID: "client:root",
        connectionKeys: [
          {
            key: "ProgramsList_programs",
          },
        ],
        pathToConnection: ["client:root", "programs"],
        deletedIDFieldName: "programId",
      },
    ],

    onSuccess: () =>
      snackAlert({
        severity: "success",
        message: "Program archived.",
      }),
  }));
};
