import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  FormGroup,
  FormControlLabel,
  Popover,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDropDown } from "@mui/icons-material";

import { ActivityType, activityResponseTypeLabels } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: 15,
  },
  paper: {
    borderRadius: theme.spacing(1.5),
    minWidth: theme.spacing(32),
    padding: theme.spacing(2, 0),

    "& > :not($divider)": {
      margin: theme.spacing(0, 2),
    },
  },
  divider: {
    margin: theme.spacing(1.5, 0),
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  header: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export interface ActivityTypesFilterProps
  extends Omit<ButtonProps, "onChange"> {
  filters: Record<ActivityType, boolean>;
  onChange: (filters: Record<ActivityType, boolean>) => void;
}

export function ActivityTypesFilter(props: ActivityTypesFilterProps) {
  const { className, filters, onChange, ...other } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLButtonElement>();
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange: CheckboxProps["onChange"] = React.useCallback(
    (event, checked) => {
      const updatedFilters = {
        ...filters,
        [event.target.value]: checked,
      };

      const none = Object.values(updatedFilters).filter(Boolean).length === 0;

      if (!none) {
        onChange(updatedFilters);
      }
    },
    [filters, onChange],
  );

  const filterStatusText = React.useMemo(() => {
    const types = Object.values(ActivityType);
    const checkedFilters = types.filter((type) => filters[type]);

    return checkedFilters.length === types.length
      ? "All types"
      : checkedFilters
          .map((type) => activityResponseTypeLabels[type])
          .join(" and ") + " only";
  }, [filters]);

  return (
    <>
      <Button
        ref={ref}
        className={clsx(s.root, className)}
        onClick={handleOpen}
        {...other}
      >
        Filter by: {filterStatusText}
        <ArrowDropDown />
      </Button>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        classes={{ paper: s.paper }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Typography className={s.header} variant="body2">
          Filter by
        </Typography>
        {Object.values(ActivityType).map((type) => (
          <FormGroup key={type}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={type}
                  checked={filters[type]}
                  onChange={handleChange}
                />
              }
              label={
                <Typography variant="body1" className={s.label}>
                  {activityResponseTypeLabels[type]}
                </Typography>
              }
            />
          </FormGroup>
        ))}
      </Popover>
    </>
  );
}
