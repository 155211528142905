import React from "react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { graphql, useFragment } from "react-relay";

import { createTheme, GLOBAL_STYLES } from "../../theme";
import {
  CurrentWorkspaceContext,
  useCurrentBrand,
} from "../../hooks/useCurrentWorkspace";

import { ThemeProvider_workspace$key } from "./__generated__/ThemeProvider_workspace.graphql";
import { SnackbarNotifications } from "../snackbar/SnackbarNotifications";
import { LocalStorageKeys } from "../../constants";
import { generateThumbnailUrl } from "../../utils/thumbnail";
import { GlobalStyles } from "@mui/material";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const workspaceFragment = graphql`
  fragment ThemeProvider_workspace on Workspace {
    primaryColor
    name
    logoURL
    message
    slug
    mealLogging
    brandedAppStatus
    brandedAppName
    brandedAppLogoUrl
    brandedAppAppleStoreUrl
    brandedAppGooglePlayUrl
  }
`;

export interface ThemeProviderProps {
  children?: React.ReactNode;
  workspace: ThemeProvider_workspace$key;
}

export function AppThemeProvider(props: ThemeProviderProps) {
  const { children, workspace: workspaceRef } = props;
  const workspace = useFragment(workspaceFragment, workspaceRef);
  const { primaryColor, logoURL, name, brandedAppStatus } = workspace;

  const theme = React.useMemo(
    () =>
      createTheme({
        primary: primaryColor,
      }),
    [primaryColor],
  );

  React.useMemo(() => {
    const isBrandedApp = brandedAppStatus !== "NONE";
    const generatedLogoUrl = logoURL
      ? generateThumbnailUrl({
          src: logoURL,
          scale: {
            w: 60,
            h: 60,
          },
        })
      : "";
    // TODO_Branded_Apps: Replace with useCurrentBrand hook
    const brandName =
      isBrandedApp && workspace.name ? workspace.name : "Stridist";
    window.document.title = brandName;
    (window.document.getElementById("favicon") as any).href =
      isBrandedApp && logoURL
        ? generatedLogoUrl
        : `${process.env.PUBLIC_URL}/favicon.png`;

    if (isBrandedApp && logoURL) {
      localStorage.setItem(LocalStorageKeys.FAVICON_LOGO_URL, generatedLogoUrl);
    } else {
      localStorage.setItem(
        LocalStorageKeys.FAVICON_LOGO_URL,
        `${process.env.PUBLIC_URL}/favicon.png`,
      );
    }

    localStorage.setItem(LocalStorageKeys.TITLE, brandName);
  }, [logoURL, name]);

  return (
    <CurrentWorkspaceContext.Provider value={workspace}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={GLOBAL_STYLES} />
          <SnackbarNotifications>{children}</SnackbarNotifications>
        </ThemeProvider>
      </StyledEngineProvider>
    </CurrentWorkspaceContext.Provider>
  );
}
