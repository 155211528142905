/**
 * @generated SignedSource<<57552b1b438165345b770ed7f2015027>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ProgramDefaultView = "CALENDAR" | "LIST" | "SPREADSHEET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurriculumEditScreen_program$data = {
  readonly defaultView: ProgramDefaultView;
  readonly id: string;
  readonly isEmpty: boolean;
  readonly length: number;
  readonly name: string;
  readonly slug: string;
  readonly weeks: ReadonlyArray<{
    readonly components: ReadonlyArray<{
      readonly content: string | null | undefined;
      readonly days: ReadonlyArray<boolean>;
      readonly draftExists: boolean;
      readonly duration: number;
      readonly iconName: string;
      readonly id: string;
      readonly locked: boolean;
      readonly repeat: ComponentRepeat;
      readonly slug: string;
      readonly status: ComponentStatus;
      readonly title: string;
      readonly type: ComponentType;
      readonly weekId: string;
      readonly " $fragmentSpreads": FragmentRefs<"DragCellRenderer_component" | "ProgramCalendarComponentButtons_component" | "ProgramCalendarComponent_component" | "WeekComponentList_components">;
    } | null | undefined> | null | undefined;
    readonly id: string;
    readonly positions: ReadonlyArray<string>;
    readonly week: number;
    readonly " $fragmentSpreads": FragmentRefs<"ProgramCalendarWeek_week" | "ProgramSpreadsheetWeek_week" | "WeekCard_week">;
  }> | null | undefined;
  readonly " $fragmentType": "CurriculumEditScreen_program";
};
export type CurriculumEditScreen_program$key = {
  readonly " $data"?: CurriculumEditScreen_program$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumEditScreen_program">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": true
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 3,
      "kind": "LocalArgument",
      "name": "endNumber"
    },
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "startNumber"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CurriculumEditScreenRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CurriculumEditScreen_program",
  "selections": [
    {
      "alias": "weeks",
      "args": [
        {
          "kind": "Variable",
          "name": "endNumber",
          "variableName": "endNumber"
        },
        {
          "kind": "Variable",
          "name": "startNumber",
          "variableName": "startNumber"
        }
      ],
      "concreteType": "Week",
      "kind": "LinkedField",
      "name": "weekRange",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "week",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "positions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "archived",
              "value": true
            }
          ],
          "concreteType": "Component",
          "kind": "LinkedField",
          "name": "components",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProgramCalendarComponentButtons_component"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProgramCalendarComponent_component"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WeekComponentList_components"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DragCellRenderer_component"
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "iconName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "weekId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "title",
              "storageKey": "title(draft:true)"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "repeat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "days",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locked",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v1/*: any*/),
              "kind": "ScalarField",
              "name": "content",
              "storageKey": "content(draft:true)"
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "draftExists",
              "storageKey": null
            }
          ],
          "storageKey": "components(archived:true)"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WeekCard_week"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgramCalendarWeek_week"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgramSpreadsheetWeek_week"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "length",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEmpty",
      "storageKey": null
    }
  ],
  "type": "Program",
  "abstractKey": null
};
})();

(node as any).hash = "d43799c8d66cf2d13b7fc52ceddd2d74";

export default node;
