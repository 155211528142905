import React, { useEffect, useState } from "react";
import { App } from "@capacitor/app";
import { isMobileApp } from "../utils/mobile";
import { compare } from "compare-versions";
import { useAppMetadata } from "./useAppMetadata";

interface AppVersion {
  requiresUpdate: boolean;
  requiresForceUpdate: boolean;
}

export function useCurrentAppVersion(): AppVersion {
  const [requiresUpdate, setRequiresUpdate] = useState<boolean>(false);
  const [requiresForceUpdate, setRequiresForceUpdate] =
    useState<boolean>(false);
  const appMetadata = useAppMetadata();

  useEffect(() => {
    if (!isMobileApp || !appMetadata) {
      setRequiresUpdate(false);
      setRequiresForceUpdate(false);
    } else {
      App.getInfo().then((info) => {
        if (compare(info.version, appMetadata.forceAppVersion, "<")) {
          setRequiresUpdate(false);
          setRequiresForceUpdate(true);
        } else if (compare(info.version, appMetadata.softAppVersion, "<")) {
          setRequiresUpdate(true);
          setRequiresForceUpdate(false);
        } else {
          setRequiresUpdate(false);
          setRequiresForceUpdate(false);
        }
      });
    }
  }, [appMetadata]);

  return {
    requiresUpdate,
    requiresForceUpdate,
  };
}
