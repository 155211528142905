import { createContext } from "react";

export interface IContextProps {
  componentBarHeight: number;
  setComponentBarHeight: (data: number) => void;
}

const ComponentBarHeightContext = createContext<IContextProps | undefined>(
  undefined,
);

export default ComponentBarHeightContext;
