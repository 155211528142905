/**
 * @generated SignedSource<<a5c9d318118abbcd6b1d27ce9f5ad388>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientActiveProgramCard_enrollment$data = {
  readonly currentDay: number | null | undefined;
  readonly totalDays: number;
  readonly " $fragmentSpreads": FragmentRefs<"ClientCardCompletion_enrollment" | "ClientSmallProgramCard_enrollment">;
  readonly " $fragmentType": "ClientActiveProgramCard_enrollment";
};
export type ClientActiveProgramCard_enrollment$key = {
  readonly " $data"?: ClientActiveProgramCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientActiveProgramCard_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientActiveProgramCard_enrollment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSmallProgramCard_enrollment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientCardCompletion_enrollment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDays",
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "6d926e1594e016531b523747487d61b5";

export default node;
