/**
 * @generated SignedSource<<5f172aa9e38a179e960017d778e1fdff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProgramCalendarComponent_component$data = {
  readonly slug: string;
  readonly type: ComponentType;
  readonly " $fragmentSpreads": FragmentRefs<"CalendarComponentCard_component">;
  readonly " $fragmentType": "ProgramCalendarComponent_component";
};
export type ProgramCalendarComponent_component$key = {
  readonly " $data"?: ProgramCalendarComponent_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramCalendarComponent_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramCalendarComponent_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CalendarComponentCard_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "9af5895b27579df81b863117b110fb5d";

export default node;
