/**
 * @generated SignedSource<<5b4eec17d0d21552c835f562df37d0e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignupClientCompleteScreen_workspace$data = {
  readonly name: string | null | undefined;
  readonly " $fragmentType": "SignupClientCompleteScreen_workspace";
};
export type SignupClientCompleteScreen_workspace$key = {
  readonly " $data"?: SignupClientCompleteScreen_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignupClientCompleteScreen_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignupClientCompleteScreen_workspace",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "a18a46c84036e9c1b504b731584ec64f";

export default node;
