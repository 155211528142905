import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { AdminUsersRouteQuery } from "./__generated__/AdminUsersRouteQuery.graphql";
import { UsersTable } from "../../../components/admin/UsersTable";
import { useSearchParams } from "react-router-dom";
import { UserRole } from "../../../constants";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";

export function AdminUsersRoute() {
  const [searchParams] = useSearchParams();

  const perPage = searchParams.get("perPage");
  const role = searchParams.get("role");
  const search = searchParams.get("search");
  const page = searchParams.get("page");

  const props = useLazyLoadQuery<AdminUsersRouteQuery>(
    graphql`
      query AdminUsersRouteQuery(
        $perPage: Int!
        $role: UserRole!
        $after: String
        $search: String
        $page: Int
      ) {
        users(
          first: $perPage
          page: $page
          after: $after
          role: $role
          search: $search
        ) {
          ...UsersTable_users
        }
      }
    `,
    {
      perPage: perPage ? parseInt(perPage) : 5,
      role: role ? (role as UserRole) : UserRole.COACH,
      search: search,
      page: page ? parseInt(page) : 0,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { users } = props;

  return (
    <>
      <UsersTable usersRef={users} />
      <TrackInfoTool
        trackInfo={{
          name: "Admin - Users",
        }}
      />
    </>
  );
}
