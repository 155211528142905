import { Box, BoxProps } from "@mui/material";
import React from "react";

interface DynamicContainerProps extends BoxProps {
  children: React.ReactNode;
  className?: string;
  duration?: number;
  animate?: boolean;
}

export default function AnimateHeightBox({
  children,
  className,
  duration = 500,
  animate = true,
  ...props
}: DynamicContainerProps) {
  const content = React.useRef(null);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const { height } = content.current.getBoundingClientRect();
    setHeight(height);
  }, [children]);

  const AnimateContainer = (children: React.ReactNode) => (
    <Box
      sx={{
        transition: `${duration / 1000}s height`,
        height: `${height}px`,
        overflow: "hidden",
      }}
      {...props}
    >
      {children}
    </Box>
  );

  const ContentContainer = (children: React.ReactNode) => (
    <Box
      className={className}
      ref={content}
      sx={{
        ...props.sx,
        height: "fit-content",
      }}
    >
      {children}
    </Box>
  );

  return animate
    ? AnimateContainer(ContentContainer(children))
    : ContentContainer(children);
}
