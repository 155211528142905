import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { VisibilityOutlined } from "@mui/icons-material";

import { PreviewProgramStartDateDialog } from "../../dialog/PreviewProgramStartDateDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(2.5),
    padding: 7,

    [theme.breakpoints.up("md")]: {
      padding: 8,
    },
  },
}));

export interface PreviewProgramButtonProps extends IconButtonProps {}

export function PreviewProgramButton(props: PreviewProgramButtonProps) {
  const s = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <IconButton
        className={s.root}
        children={<VisibilityOutlined />}
        onClick={handleClick}
        size="large"
      />
      <PreviewProgramStartDateDialog open={dialogOpen} onClose={handleClose} />
    </>
  );
}
