import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  Checkbox,
  CheckboxProps,
  Typography,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { BaseDialog, BaseDialogProps } from "./BaseDialog";
import {
  SelectClientsDialog_clients$data,
  SelectClientsDialog_clients$key,
} from "./__generated__/SelectClientsDialog_clients.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  list: {
    maxHeight: 420,
    overflowY: "scroll",
  },

  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 0),
  },

  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  name: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
  },

  email: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  sectionDivider: {},

  button: {
    marginTop: theme.spacing(2),
  },
}));

type ClientItem = SelectClientsDialog_clients$data["edges"][0]["node"];

const clientsFragment = graphql`
  fragment SelectClientsDialog_clients on ClientConnection {
    edges {
      node {
        email
        displayName
      }
    }
  }
`;

export interface SelectClientsDialogProps
  extends Omit<DialogProps, "title">,
    Pick<BaseDialogProps, "title" | "description"> {
  clients: SelectClientsDialog_clients$key;
  onConfirm: (clients: ClientItem[]) => void;
  minSelected?: number;
  maxSelected?: number;
}

export function SelectClientsDialog(props: SelectClientsDialogProps) {
  const {
    className,
    onClose,
    title = "Select clients",
    clients: clientsRef,
    onConfirm,
    maxSelected = Infinity,
    ...other
  } = props;
  const clients = useFragment(clientsFragment, clientsRef);
  const s = useStyles();
  const [selectedClients, setSelectedClients] = React.useState<ClientItem[]>(
    [],
  );

  const handleClose = React.useCallback(
    (event = {}) => {
      setSelectedClients([]);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleClientToggle: CheckboxProps["onChange"] = React.useCallback(
    (event, checked) => {
      const { email } = event.currentTarget.dataset;

      setSelectedClients((selectedClients) => {
        if (!checked) {
          return selectedClients.filter((c) => c.email !== email);
        } else {
          const client = clients.edges.find(
            ({ node }) => node.email === email,
          )?.node;

          return client && selectedClients.length < maxSelected
            ? [...selectedClients, client]
            : selectedClients;
        }
      });
    },
    [clients.edges, maxSelected],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={title}
      onClose={handleClose}
      {...other}
    >
      <Box className={s.list}>
        {clients.edges.map(({ node: { email, displayName } }, index, array) => (
          <React.Fragment key={email}>
            <Box className={s.item}>
              <Checkbox
                onChange={handleClientToggle}
                checked={Boolean(
                  selectedClients.find((client) => client.email === email),
                )}
                inputProps={
                  {
                    "data-email": email,
                  } as any
                }
              />
              <Box className={s.info}>
                <Typography className={s.name} children={displayName} />
                <Typography className={s.email} children={email} />
              </Box>
            </Box>
            {index < array.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Box>
    </BaseDialog>
  );
}
