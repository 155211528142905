import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { WorkoutSection } from "../workout/types";
import ActivityPreviewExercises from "./ActivityPreviewExercises";
import { defaultComponentTitles } from "../../utils/component";
import { ComponentType } from "../../constants";

interface IActivityPreviewSectionsProps {
  card: any;
  index: number;
}

const ActivityPreviewSections = ({
  card,
  index,
}: IActivityPreviewSectionsProps) => {
  if (card?.type === "workout_section") {
    const workout: WorkoutSection = card?.workout;
    return (
      <>
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            p: 0.5,
          }}
        >
          {index !== 0 && (
            <Divider sx={{ marginBlock: 1.5, borderStyle: "dashed" }} />
          )}
          <Typography sx={{ fontWeight: "bold" }}>
            {workout?.title || defaultComponentTitles[ComponentType.WORKOUT]}
          </Typography>

          {workout?.exercises.map((c, index) => (
            <ActivityPreviewExercises
              card={c}
              index={index}
              exercises={workout?.exercises}
            />
          ))}
        </Box>
      </>
    );
  }
};

export default ActivityPreviewSections;
