import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ArrowBackIcon } from "../../icons/ArrowBack.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { useClient } from "../../hooks/useClient";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
  },

  backIcon: {
    height: 32,
    width: 32,
  },

  close: {
    marginLeft: theme.spacing(-1.5),
    marginBottom: theme.spacing(2),
  },

  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export interface ClientNutritionDrawerHeaderProps extends BoxProps {
  onClose: () => void;
  onBack?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  title?: string;
}

export function ClientNutritionDrawerHeader(
  props: ClientNutritionDrawerHeaderProps,
) {
  const {
    className,
    title,
    onClose,
    onBack,
    onDelete,
    disabled = false,
    ...other
  } = props;
  const s = useStyles();

  const client: any = useClient();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.actions}>
        <IconButton
          className={s.close}
          color="primary"
          children={
            onBack ? <ArrowBackIcon className={s.backIcon} /> : <CloseIcon />
          }
          onClick={onBack ? onBack : onClose}
          disabled={disabled}
          size="large"
        />
        {onDelete && (
          <IconButton
            className={s.close}
            color="primary"
            children={<BinIcon />}
            onClick={onDelete}
            disabled={disabled}
            size="large"
          />
        )}
      </Box>
      <Box>
        {!client && (
          <Typography className={s.title} variant="h2" children={title} />
        )}
      </Box>
    </Box>
  );
}
