import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.gray7,
    borderRadius: 12,
    padding: 20,
    cursor: "pointer",
  },
  exercises: {
    padding: `${theme.spacing(0)} !important`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrow: {
    transform: "rotate(90deg)",
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
  },
}));

type ClientExercisesItemProps = {
  name: string;
  onClick: () => void;
};

const ClientExercisesItem = ({ name, onClick }: ClientExercisesItemProps) => {
  const s = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={s.container} onClick={onClick}>
        <CardContent className={s.exercises}>
          <Typography className={s.text}>{name}</Typography>
          <ArrowIcon className={s.arrow} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ClientExercisesItem;
