import React, { ReactElement, ReactNode } from "react";

interface StaticContainerProps {
  shouldUpdate?: boolean;
  children?: ReactNode | boolean;
}

export class StaticContainer extends React.Component<StaticContainerProps> {
  shouldComponentUpdate({ shouldUpdate = false }): boolean {
    return shouldUpdate;
  }

  render() {
    const child = this.props.children;
    if (child === null || child === false) {
      return null;
    }
    return React.Children.only(child);
  }
}
