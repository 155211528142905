import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { UnsplashContext } from "../../../../hooks/useUnsplash";
import { ProgramTemplatesScreen } from "../../../../components/screen/ProgramTemplatesScreen";
import { CoachProgramCreateRouteQuery } from "./__generated__/CoachProgramCreateRouteQuery.graphql";

export function CoachProgramCreateRoute() {
  const props = useLazyLoadQuery<CoachProgramCreateRouteQuery>(
    graphql`
      query CoachProgramCreateRouteQuery {
        ...ProgramTemplatesScreen
        ...UnsplashImages_rootRef
      }
    `,
    {},
  );
  return (
    <UnsplashContext.Provider value={props}>
      <ProgramTemplatesScreen rootRef={props} />
    </UnsplashContext.Provider>
  );
}
