import clsx from "clsx";
import React, { useCallback } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { floatNumberInputProps, nutritionComments } from "../../constants";
import { colorSystem } from "../../theme";
import { ClientNutritionTargetDrawerView } from "./ClientNutritionTargetDrawer";
import { ClientNutritionDrawerHeader } from "./ClientNutritionDrawerHeader";
import { ClientNutritionTargetDrawerSetDateView } from "./ClientNutritionTargetDrawerSetDateView";
import { ClientNutritionTargetDrawerEntryHistory } from "./ClientNutritionTargetDrawerEntryHistory";
import { ReactComponent as CalendarEmptyIcon } from "../../icons/CalendarEmpty.svg";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "react-relay";
import { useClient } from "../../hooks/useClient";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    "&$program": {
      padding: theme.spacing(8, 4, 5, 4),
    },

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  disableInput: {
    color: "rgba(0, 0, 0, 0.38)",
  },

  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },

  primaryText: {
    color: colorSystem.black,
    fontWeight: 500,
  },

  gramContainer: {
    position: "relative",
  },

  gram: {
    position: "absolute",
    zIndex: 2,
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: 16,
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
  },

  calendar: {
    borderColor: "rgba(25, 25, 25, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    fontSize: 16,
    fontFamily: "Montserrat",
    color: colorSystem.black,
    fontWeight: 500,
    cursor: "pointer",
  },

  header: {
    marginBottom: theme.spacing(4),
  },

  sort: {},
  overlay: {},
  program: {},
}));

export interface ClientNutritionTargetDrawerEditCurrentViewProps
  extends DrawerProps {
  dirty: boolean;
  creating: boolean;
  isProgram?: boolean;
  trackingTypeValue: string;
  commentTypeValue: string;
  proteinValue: string;
  carbohydrateValue: string;
  fatValue: string;
  caloriesValue: string;
  date: string;
  targetId: string;
  onChangeDate: (string) => void;
  onChangeProtein: (value: string) => void;
  onChangeCarbohydrate: (value: string) => void;
  onChangeFat: (value: string) => void;
  onChangeCalories: (value: string) => void;
  onClickSave: () => void;
  refetchEntries: () => void;
  queryArgs: any;
}

export function ClientNutritionTargetDrawerEditCurrentView(
  props: ClientNutritionTargetDrawerEditCurrentViewProps,
) {
  const {
    isProgram,
    targetId,
    queryArgs,
    trackingTypeValue,
    commentTypeValue,
    creating,
    proteinValue,
    caloriesValue,
    fatValue,
    carbohydrateValue,
    dirty,
    date,
    onChangeDate,
    onChangeProtein,
    onChangeCarbohydrate,
    onChangeFat,
    onChangeCalories,
    onClickSave,
    refetchEntries,
    className,
    open,
    onClose,
  } = props;
  const s = useStyles(isProgram);

  const client: any = useClient();

  const [view, setView] = React.useState<ClientNutritionTargetDrawerView>(
    ClientNutritionTargetDrawerView.EDIT,
  );

  const {
    entries: { edges: entriesHistory },
  }: any = useLazyLoadQuery(
    graphql`
      query ClientNutritionTargetDrawerEditCurrentViewQuery(
        $targetId: ID!
        $clientId: ID!
      ) {
        entries: nutrition_entries(targetId: $targetId, clientId: $clientId) {
          edges {
            node {
              id
              carbsGrams
              fatGrams
              proteinGrams
              calories
              date
            }
          }
        }
      }
    `,
    queryArgs.variables,
    queryArgs.options,
  );

  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const gramRender = React.useCallback(
    (value: any) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "500 16px Montserrat";
      const { width } = context.measureText(value);
      return (
        Boolean(value) &&
        trackingTypeValue === "MACROS" && (
          <span style={{ left: width + 23 }} className={s.gram}>
            g
          </span>
        )
      );
    },
    [trackingTypeValue, s.gram],
  );

  const disableCalories = useCallback(() => {
    const { PROTEIN_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      trackingTypeValue === "PORTIONS" ||
      commentTypeValue === nutritionComments.FULL_MACROS
    );
  }, [commentTypeValue, trackingTypeValue]);

  const disableFat = useCallback(() => {
    const { PROTEIN_ONLY, PROTEIN_CALORIES, CALORIES_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === PROTEIN_CALORIES ||
      commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableCarb = useCallback(() => {
    const { PROTEIN_ONLY, PROTEIN_CALORIES, CALORIES_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === PROTEIN_CALORIES ||
      commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableProtein = useCallback(() => {
    const { CALORIES_ONLY } = nutritionComments;
    return commentTypeValue === CALORIES_ONLY;
  }, [commentTypeValue]);

  const disableButton = useCallback(() => {
    const {
      PROTEIN_ONLY,
      TRACKING_ONLY,
      PROTEIN_CALORIES,
      CALORIES_ONLY,
      FULL_MACROS,
    } = nutritionComments;
    if (creating || !dirty) {
      return true;
    }
    switch (commentTypeValue) {
      case PROTEIN_ONLY:
        return +proteinValue < 0;
      case CALORIES_ONLY:
        return +caloriesValue < 0;
      case PROTEIN_CALORIES:
        return +proteinValue < 0 || +caloriesValue < 0;
      case FULL_MACROS:
        return +proteinValue < 0 || +carbohydrateValue < 0 || +fatValue < 0;
      case TRACKING_ONLY:
        return false;
    }
  }, [
    commentTypeValue,
    proteinValue,
    fatValue,
    carbohydrateValue,
    caloriesValue,
    creating,
    dirty,
  ]);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(s.paper, isProgram && s.program),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={
        view === ClientNutritionTargetDrawerView.EDIT ? (
          <Box className={clsx(className)}>
            <ClientNutritionDrawerHeader
              className={s.header}
              onClose={handleClose}
              title="Add Your Intake"
            />
            {!client && (
              <>
                <Box className={s.inputWrapper}>
                  <Typography variant="h4" className={s.label}>
                    Date
                  </Typography>
                  <Box
                    onClick={() =>
                      setView(ClientNutritionTargetDrawerView.DATE_PICKER)
                    }
                    className={s.calendar}
                  >
                    <span>
                      {dayjs(date, "YYYY-MM-DD").format("MMM DD YYYY")}
                    </span>
                    <CalendarEmptyIcon />
                  </Box>
                </Box>
                <Box className={s.inputWrapper}>
                  <Typography variant="h4" className={s.label}>
                    Protein
                  </Typography>
                  <Box
                    className={clsx(
                      s.gramContainer,
                      disableProtein() && s.disableInput,
                    )}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={proteinValue}
                      onChange={(e) =>
                        onChangeProtein(e.target.value as string)
                      }
                      className={s.input}
                      disabled={disableProtein()}
                      error={+proteinValue < 0}
                      type="number"
                      InputProps={{
                        inputProps: floatNumberInputProps,
                      }}
                    />
                    {gramRender(proteinValue)}
                  </Box>
                </Box>
                <Box className={s.inputWrapper}>
                  <Typography variant="h4" className={s.label}>
                    Carbohydrate
                  </Typography>
                  <Box
                    className={clsx(
                      s.gramContainer,
                      disableCarb() && s.disableInput,
                    )}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={carbohydrateValue}
                      onChange={(e) =>
                        onChangeCarbohydrate(e.target.value as string)
                      }
                      className={s.input}
                      disabled={disableCarb()}
                      error={+carbohydrateValue < 0}
                      type="number"
                      InputProps={{
                        inputProps: floatNumberInputProps,
                      }}
                    />
                    {gramRender(carbohydrateValue)}
                  </Box>
                </Box>
                <Box className={s.inputWrapper}>
                  <Typography variant="h4" className={s.label}>
                    Fat
                  </Typography>
                  <Box
                    className={clsx(
                      s.gramContainer,
                      disableCarb() && s.disableInput,
                    )}
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={fatValue}
                      onChange={(e) => onChangeFat(e.target.value as string)}
                      className={s.input}
                      disabled={disableFat()}
                      error={+fatValue < 0}
                      type="number"
                      InputProps={{
                        inputProps: floatNumberInputProps,
                      }}
                    />
                    {gramRender(fatValue)}
                  </Box>
                </Box>
                {trackingTypeValue === "MACROS" && (
                  <Box className={s.inputWrapper}>
                    <Typography variant="h4" className={s.label}>
                      Calories
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={caloriesValue}
                      onChange={(e) =>
                        onChangeCalories(e.target.value as string)
                      }
                      className={s.input}
                      disabled={disableCalories()}
                      error={+caloriesValue < 0}
                      type="number"
                      InputProps={{
                        inputProps: floatNumberInputProps,
                      }}
                    />
                  </Box>
                )}
                <Button
                  className={s.button}
                  variant="contained"
                  children="Save values"
                  onClick={onClickSave}
                  disabled={disableButton()}
                />
              </>
            )}

            <ClientNutritionTargetDrawerEntryHistory
              entries={entriesHistory}
              commentTypeValue={commentTypeValue}
              trackingTypeValue={trackingTypeValue}
              refetchEntries={refetchEntries}
              targetId={targetId}
            />
          </Box>
        ) : (
          <ClientNutritionTargetDrawerSetDateView
            maxDate={dayjs()}
            date={date}
            onBack={() => setView(ClientNutritionTargetDrawerView.EDIT)}
            onDateChange={(date) => {
              onChangeDate(date);
              setView(ClientNutritionTargetDrawerView.EDIT);
            }}
          />
        )
      }
    />
  );
}
