import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ArrowBack } from "../../icons/ArrowBack.svg";
import { useUserIsClient } from "../../hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(-1.5),
  },
}));

export interface AppBarBackButtonProps extends IconButtonProps {}

export function AppBarBackButton(props: AppBarBackButtonProps) {
  const { className, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const isClient = useUserIsClient();
  const backHref = isClient ? HOME_ROUTE : COACH_PROGRAMS_PUBLISHED_ROUTE;

  const handleClick = React.useCallback(() => {
    navigate(backHref);
  }, [backHref]);

  return (
    <IconButton
      className={clsx(s.root, className)}
      color="secondary"
      onClick={handleClick}
      {...other}
      size="large"
    >
      <ArrowBack />
    </IconButton>
  );
}
