import React from "react";

const Camera = ({ fill, className }) => (
  <svg
    className={className}
    width="342"
    height="232"
    viewBox="0 0 342 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M323.372 231.086C324.173 222.454 326.384 214.013 329.917 206.099C330.902 203.897 331.986 201.743 333.169 199.637C333.229 199.527 333.243 199.398 333.209 199.278C333.175 199.158 333.096 199.055 332.988 198.992C332.88 198.929 332.752 198.91 332.631 198.94C332.509 198.969 332.404 199.045 332.338 199.151C328.035 206.819 325.021 215.143 323.416 223.79C322.969 226.205 322.633 228.637 322.409 231.086C322.352 231.703 323.315 231.7 323.372 231.086Z"
      fill="#F2F2F2"
    />
    <path
      d="M335.502 201.184C338.872 201.184 341.603 198.45 341.603 195.077C341.603 191.704 338.872 188.97 335.502 188.97C332.133 188.97 329.401 191.704 329.401 195.077C329.401 198.45 332.133 201.184 335.502 201.184Z"
      fill="#F2F2F2"
    />
    <path
      d="M323.996 231.248C324.516 225.649 325.95 220.173 328.243 215.038C328.881 213.61 329.585 212.212 330.352 210.846C330.391 210.775 330.4 210.691 330.378 210.613C330.356 210.535 330.305 210.468 330.235 210.427C330.165 210.386 330.082 210.374 330.003 210.394C329.924 210.413 329.856 210.462 329.813 210.531C327.021 215.506 325.066 220.906 324.024 226.516C323.735 228.082 323.517 229.66 323.371 231.248C323.334 231.649 323.959 231.647 323.996 231.248H323.996Z"
      fill="#F2F2F2"
    />
    <path
      d="M331.866 211.85C334.051 211.85 335.824 210.076 335.824 207.888C335.824 205.7 334.051 203.926 331.866 203.926C329.68 203.926 327.908 205.7 327.908 207.888C327.908 210.076 329.68 211.85 331.866 211.85Z"
      fill="#F2F2F2"
    />
    <path
      d="M323.207 230.875C320.176 226.14 317.943 220.939 316.596 215.479C316.222 213.959 315.918 212.424 315.684 210.875C315.67 210.795 315.626 210.723 315.561 210.675C315.495 210.627 315.414 210.606 315.334 210.617C315.253 210.628 315.18 210.669 315.13 210.733C315.08 210.797 315.056 210.877 315.064 210.958C315.92 216.599 317.696 222.061 320.322 227.126C321.056 228.539 321.853 229.917 322.714 231.26C322.931 231.599 323.423 231.212 323.207 230.875Z"
      fill="#F2F2F2"
    />
    <path
      d="M315.059 211.572C317.245 211.572 319.017 209.798 319.017 207.609C319.017 205.421 317.245 203.647 315.059 203.647C312.873 203.647 311.101 205.421 311.101 207.609C311.101 209.798 312.873 211.572 315.059 211.572Z"
      fill="#F2F2F2"
    />
    <path
      d="M67.8727 231.086C67.0721 222.454 64.8609 214.013 61.3273 206.099C60.3424 203.897 59.2585 201.743 58.0754 199.637C58.016 199.527 58.0017 199.398 58.0355 199.278C58.0693 199.157 58.1485 199.055 58.2564 198.992C58.3643 198.929 58.4924 198.91 58.6139 198.939C58.7353 198.969 58.8404 199.045 58.9072 199.151C63.2096 206.819 66.2238 215.143 67.8291 223.79C68.2757 226.205 68.6113 228.637 68.836 231.086C68.8931 231.703 67.9295 231.699 67.8727 231.086Z"
      fill="#F2F2F2"
    />
    <path
      d="M55.7424 201.184C59.1118 201.184 61.8433 198.45 61.8433 195.077C61.8433 191.704 59.1118 188.97 55.7424 188.97C52.373 188.97 49.6416 191.704 49.6416 195.077C49.6416 198.45 52.373 201.184 55.7424 201.184Z"
      fill="#F2F2F2"
    />
    <path
      d="M67.2486 231.248C66.7292 225.649 65.2947 220.173 63.0023 215.038C62.3634 213.61 61.6601 212.212 60.8926 210.846C60.854 210.775 60.8447 210.691 60.8666 210.613C60.8885 210.535 60.9398 210.468 61.0099 210.427C61.0799 210.386 61.1631 210.374 61.2419 210.394C61.3207 210.413 61.389 210.462 61.4322 210.531C64.2234 215.506 66.1789 220.906 67.2203 226.516C67.51 228.082 67.7278 229.66 67.8736 231.248C67.9106 231.649 67.2854 231.647 67.2486 231.248H67.2486Z"
      fill="#F2F2F2"
    />
    <path
      d="M59.3788 211.85C61.5647 211.85 63.3367 210.076 63.3367 207.888C63.3367 205.7 61.5647 203.926 59.3788 203.926C57.1929 203.926 55.4209 205.7 55.4209 207.888C55.4209 210.076 57.1929 211.85 59.3788 211.85Z"
      fill="#F2F2F2"
    />
    <path
      d="M68.0374 230.875C71.0683 226.14 73.3019 220.939 74.6489 215.479C75.023 213.959 75.327 212.424 75.5611 210.875C75.5746 210.795 75.6187 210.723 75.6839 210.675C75.7492 210.627 75.8306 210.606 75.911 210.617C75.9914 210.628 76.0644 210.669 76.1147 210.733C76.165 210.797 76.1886 210.877 76.1805 210.958C75.3247 216.599 73.5486 222.061 70.923 227.126C70.1889 228.539 69.3913 229.917 68.5302 231.26C68.3132 231.599 67.8218 231.212 68.0374 230.875H68.0374Z"
      fill="#F2F2F2"
    />
    <path
      d="M76.1854 211.572C78.3713 211.572 80.1433 209.798 80.1433 207.609C80.1433 205.421 78.3713 203.647 76.1854 203.647C73.9995 203.647 72.2275 205.421 72.2275 207.609C72.2275 209.798 73.9995 211.572 76.1854 211.572Z"
      fill="#F2F2F2"
    />
    <path
      d="M300.441 151.247L300.536 223.053C300.537 224.386 300.009 225.665 299.069 226.609C298.129 227.553 296.853 228.085 295.521 228.089L265.725 228.132C274.113 212.425 278.862 187.701 278.834 168.644C278.827 161.123 278.064 153.622 276.556 146.254L295.411 146.227C296.743 146.226 298.02 146.754 298.963 147.696C299.906 148.637 300.438 149.914 300.441 151.247Z"
      fill={fill}
    />
    <path
      d="M341.526 232H47.4997C47.3742 232 47.2539 231.95 47.1653 231.861C47.0766 231.772 47.0269 231.651 47.0269 231.526C47.0269 231.4 47.0766 231.279 47.1653 231.19C47.2539 231.101 47.3742 231.051 47.4997 231.051H341.526C341.651 231.051 341.772 231.101 341.861 231.19C341.95 231.279 342 231.4 342 231.526C342 231.651 341.95 231.772 341.861 231.861C341.772 231.95 341.651 232 341.526 232Z"
      fill="#3F3D56"
    />
    <path
      d="M256.778 141.432L203.676 141.224L208.84 124.026C209.252 122.675 210.085 121.491 211.218 120.649C212.35 119.806 213.723 119.348 215.134 119.341H215.159L245.468 119.461C246.883 119.472 248.258 119.939 249.388 120.791C250.519 121.643 251.347 122.836 251.75 124.195L256.778 141.432Z"
      fill="#3F3D56"
    />
    <path
      d="M286.424 135.548C284.945 135.548 283.527 136.136 282.481 137.183C281.436 138.229 280.848 139.649 280.848 141.129H291.999C291.999 139.649 291.412 138.229 290.366 137.183C289.321 136.136 287.902 135.548 286.424 135.548Z"
      fill={fill}
    />
    <path
      d="M303.548 231.628H156.992C155.197 231.626 153.476 230.911 152.207 229.64C150.938 228.37 150.224 226.647 150.222 224.85V147.508C150.224 145.711 150.938 143.989 152.207 142.718C153.476 141.447 155.197 140.733 156.992 140.731H303.548C305.343 140.733 307.064 141.447 308.333 142.718C309.602 143.989 310.316 145.711 310.318 147.508V224.85C310.316 226.647 309.602 228.37 308.333 229.64C307.064 230.911 305.343 231.626 303.548 231.628ZM156.992 141.528C155.408 141.53 153.89 142.161 152.77 143.282C151.65 144.403 151.02 145.923 151.018 147.508V224.85C151.02 226.436 151.65 227.956 152.77 229.077C153.89 230.198 155.408 230.828 156.992 230.83H303.548C305.132 230.828 306.65 230.198 307.77 229.077C308.89 227.956 309.52 226.436 309.522 224.85V147.508C309.52 145.923 308.89 144.403 307.77 143.282C306.65 142.161 305.132 141.53 303.548 141.528H156.992Z"
      fill="#3F3D56"
    />
    <path
      d="M236.642 134.95H223.898C223.001 134.95 222.139 134.593 221.505 133.957C220.87 133.322 220.513 132.46 220.513 131.561C220.513 130.663 220.87 129.801 221.505 129.165C222.139 128.53 223.001 128.173 223.898 128.173H236.642C237.54 128.173 238.401 128.53 239.036 129.165C239.671 129.801 240.027 130.663 240.027 131.561C240.027 132.46 239.671 133.322 239.036 133.957C238.401 134.593 237.54 134.95 236.642 134.95Z"
      fill="white"
    />
    <path
      d="M230.27 216.478C224.284 216.478 218.432 214.701 213.455 211.372C208.477 208.042 204.598 203.31 202.307 197.774C200.016 192.237 199.417 186.145 200.585 180.268C201.752 174.391 204.635 168.992 208.868 164.754C213.101 160.517 218.494 157.631 224.365 156.462C230.236 155.293 236.322 155.893 241.853 158.186C247.383 160.48 252.11 164.363 255.436 169.346C258.762 174.328 260.537 180.186 260.537 186.179C260.528 194.212 257.336 201.913 251.662 207.594C245.988 213.274 238.295 216.469 230.27 216.478ZM230.27 156.677C224.441 156.677 218.743 158.408 213.897 161.649C209.051 164.891 205.273 169.498 203.043 174.889C200.812 180.28 200.229 186.212 201.366 191.934C202.503 197.657 205.31 202.914 209.431 207.04C213.553 211.166 218.804 213.975 224.521 215.114C230.237 216.252 236.163 215.668 241.548 213.435C246.933 211.202 251.536 207.421 254.774 202.569C258.012 197.718 259.741 192.014 259.741 186.179C259.732 178.357 256.624 170.859 251.099 165.328C245.574 159.797 238.083 156.686 230.27 156.677Z"
      fill="#3F3D56"
    />
    <path
      d="M230.27 206.112C226.332 206.112 222.482 204.943 219.207 202.753C215.933 200.563 213.38 197.45 211.873 193.807C210.366 190.165 209.972 186.157 210.74 182.29C211.508 178.423 213.405 174.872 216.19 172.084C218.975 169.296 222.523 167.398 226.385 166.628C230.248 165.859 234.252 166.254 237.89 167.763C241.529 169.271 244.639 171.826 246.827 175.104C249.015 178.383 250.183 182.236 250.183 186.179C250.177 191.464 248.077 196.531 244.344 200.267C240.611 204.004 235.549 206.107 230.27 206.112ZM230.27 167.043C226.489 167.043 222.793 168.165 219.65 170.268C216.506 172.371 214.056 175.359 212.609 178.856C211.162 182.353 210.784 186.2 211.521 189.912C212.259 193.624 214.079 197.034 216.753 199.71C219.426 202.387 222.832 204.209 226.541 204.947C230.249 205.686 234.092 205.307 237.585 203.858C241.078 202.41 244.064 199.957 246.164 196.81C248.265 193.664 249.386 189.964 249.386 186.179C249.38 181.105 247.364 176.241 243.781 172.654C240.197 169.066 235.338 167.049 230.27 167.043Z"
      fill="#3F3D56"
    />
    <path
      d="M230.27 201.329C238.628 201.329 245.404 194.546 245.404 186.179C245.404 177.812 238.628 171.03 230.27 171.03C221.912 171.03 215.137 177.812 215.137 186.179C215.137 194.546 221.912 201.329 230.27 201.329Z"
      fill="#CCCCCC"
    />
    <path
      d="M221.111 186.179C221.111 183.778 221.977 181.457 223.55 179.644C225.124 177.831 227.298 176.648 229.673 176.311C228.261 176.11 226.823 176.215 225.455 176.619C224.087 177.023 222.822 177.716 221.745 178.652C220.669 179.587 219.805 180.743 219.213 182.042C218.621 183.341 218.315 184.751 218.315 186.179C218.315 187.606 218.621 189.017 219.213 190.316C219.805 191.614 220.669 192.771 221.745 193.706C222.822 194.642 224.087 195.335 225.455 195.739C226.823 196.142 228.261 196.247 229.673 196.046C227.298 195.71 225.124 194.527 223.55 192.714C221.977 190.901 221.111 188.58 221.111 186.179Z"
      fill="white"
    />
    <path
      d="M296.38 141.528H276.467V141.129C276.467 138.486 277.516 135.951 279.383 134.082C281.251 132.212 283.783 131.162 286.424 131.162C289.064 131.162 291.597 132.212 293.464 134.082C295.331 135.951 296.38 138.486 296.38 141.129V141.528ZM277.272 140.73H295.575C295.472 138.37 294.463 136.14 292.758 134.506C291.053 132.872 288.784 131.96 286.424 131.96C284.063 131.96 281.794 132.872 280.089 134.506C278.384 136.14 277.375 138.37 277.272 140.73Z"
      fill="#3F3D56"
    />
    <path
      d="M160.178 152.691C159.39 152.691 158.62 152.457 157.965 152.019C157.31 151.581 156.8 150.958 156.498 150.23C156.197 149.501 156.118 148.7 156.272 147.926C156.426 147.153 156.805 146.442 157.362 145.885C157.919 145.327 158.628 144.948 159.401 144.794C160.173 144.64 160.974 144.719 161.702 145.021C162.43 145.322 163.052 145.833 163.489 146.489C163.927 147.145 164.16 147.915 164.16 148.704C164.159 149.761 163.739 150.774 162.993 151.522C162.246 152.269 161.234 152.689 160.178 152.691ZM160.178 145.515C159.548 145.515 158.932 145.702 158.408 146.052C157.884 146.403 157.475 146.901 157.234 147.483C156.993 148.066 156.93 148.707 157.053 149.326C157.176 149.945 157.479 150.513 157.925 150.959C158.371 151.405 158.938 151.709 159.556 151.832C160.174 151.955 160.815 151.892 161.397 151.651C161.979 151.409 162.477 151 162.827 150.476C163.177 149.951 163.364 149.335 163.364 148.704C163.363 147.858 163.027 147.048 162.43 146.45C161.832 145.852 161.023 145.516 160.178 145.515Z"
      fill="#3F3D56"
    />
    <path
      d="M160.178 150.697C161.278 150.697 162.17 149.805 162.17 148.704C162.17 147.603 161.278 146.711 160.178 146.711C159.079 146.711 158.187 147.603 158.187 148.704C158.187 149.805 159.079 150.697 160.178 150.697Z"
      fill={fill}
    />
    <path
      d="M170.533 150.697C171.633 150.697 172.524 149.805 172.524 148.704C172.524 147.603 171.633 146.711 170.533 146.711C169.433 146.711 168.542 147.603 168.542 148.704C168.542 149.805 169.433 150.697 170.533 150.697Z"
      fill="#CCCCCC"
    />
    <path
      d="M180.887 150.697C181.987 150.697 182.878 149.805 182.878 148.704C182.878 147.603 181.987 146.711 180.887 146.711C179.788 146.711 178.896 147.603 178.896 148.704C178.896 149.805 179.788 150.697 180.887 150.697Z"
      fill="#CCCCCC"
    />
    <path
      d="M170.533 152.691C169.745 152.691 168.975 152.457 168.32 152.019C167.665 151.581 167.155 150.958 166.853 150.23C166.552 149.501 166.473 148.7 166.627 147.926C166.78 147.153 167.16 146.442 167.717 145.885C168.274 145.327 168.983 144.948 169.756 144.794C170.528 144.64 171.329 144.719 172.057 145.021C172.785 145.322 173.407 145.833 173.844 146.489C174.282 147.145 174.515 147.915 174.515 148.704C174.514 149.761 174.094 150.774 173.348 151.522C172.601 152.269 171.589 152.689 170.533 152.691ZM170.533 145.515C169.903 145.515 169.287 145.702 168.763 146.052C168.239 146.403 167.83 146.901 167.589 147.483C167.348 148.066 167.285 148.707 167.408 149.326C167.531 149.945 167.834 150.513 168.28 150.959C168.726 151.405 169.293 151.709 169.911 151.832C170.529 151.955 171.17 151.892 171.752 151.651C172.334 151.409 172.832 151 173.182 150.476C173.532 149.951 173.719 149.335 173.719 148.704C173.718 147.858 173.382 147.048 172.785 146.45C172.187 145.852 171.377 145.516 170.533 145.515Z"
      fill="#3F3D56"
    />
    <path
      d="M180.887 152.691C180.1 152.691 179.33 152.457 178.675 152.019C178.02 151.581 177.509 150.958 177.208 150.23C176.907 149.501 176.828 148.7 176.981 147.926C177.135 147.153 177.514 146.442 178.071 145.885C178.628 145.327 179.338 144.948 180.11 144.794C180.883 144.64 181.684 144.719 182.411 145.021C183.139 145.322 183.761 145.833 184.199 146.489C184.636 147.145 184.87 147.915 184.87 148.704C184.869 149.761 184.449 150.774 183.702 151.522C182.955 152.269 181.943 152.689 180.887 152.691ZM180.887 145.515C180.257 145.515 179.641 145.702 179.117 146.052C178.593 146.403 178.185 146.901 177.944 147.483C177.703 148.066 177.64 148.707 177.763 149.326C177.885 149.945 178.189 150.513 178.634 150.959C179.08 151.405 179.648 151.709 180.266 151.832C180.884 151.955 181.524 151.892 182.107 151.651C182.689 151.409 183.186 151 183.536 150.476C183.886 149.951 184.073 149.335 184.073 148.704C184.072 147.858 183.736 147.048 183.139 146.45C182.542 145.852 181.732 145.516 180.887 145.515Z"
      fill="#3F3D56"
    />
    <path d="M202.393 175.364H150.62V176.161H202.393V175.364Z" fill="#3F3D56" />
    <path d="M201.107 191.413H150.62V192.21H201.107V191.413Z" fill="#3F3D56" />
    <path d="M309.92 175.364H258.147V176.161H309.92V175.364Z" fill="#3F3D56" />
    <path d="M309.829 191.413H259.342V192.21H309.829V191.413Z" fill="#3F3D56" />
    <path
      d="M108.653 96.6675C105.423 95.1822 103.301 90.9358 103.421 86.748C103.542 82.5602 105.744 78.6435 108.78 76.6464C110.26 75.697 111.953 75.1305 113.706 74.9979C115.459 74.8653 117.218 75.1707 118.824 75.8867C122.082 77.2753 126.458 77.6535 128.416 81.1049C129.922 83.7572 129.379 89.6742 128.314 92.6234C127.393 95.1725 125.14 96.6088 122.944 97.4125C120.513 98.2941 117.921 98.6428 115.344 98.435C112.767 98.2272 110.264 97.4677 108.005 96.2079"
      fill="#2F2E41"
    />
    <path
      d="M88.9616 226.727L84.3184 225.215L87.9349 206.568L94.7876 208.8L88.9616 226.727Z"
      fill="#FFB8B8"
    />
    <path
      d="M81.4337 222.807L90.388 225.723L88.5538 231.367L73.9618 226.615C74.2026 225.874 74.5869 225.187 75.0928 224.595C75.5987 224.002 76.2161 223.515 76.91 223.162C77.6038 222.808 78.3604 222.595 79.1366 222.534C79.9128 222.473 80.6934 222.566 81.4337 222.807Z"
      fill="#2F2E41"
    />
    <path
      d="M130.526 226.828L125.644 226.828L123.321 207.975L130.527 207.976L130.526 226.828Z"
      fill="#FFB8B8"
    />
    <path
      d="M122.157 225.431H131.573V231.366H116.228C116.228 230.587 116.381 229.815 116.679 229.095C116.977 228.375 117.414 227.721 117.965 227.17C118.515 226.618 119.169 226.181 119.888 225.883C120.607 225.585 121.378 225.431 122.157 225.431Z"
      fill="#2F2E41"
    />
    <path
      d="M130.281 219.117C130.251 219.117 130.222 219.117 130.192 219.115L124.782 218.857C124.326 218.835 123.896 218.639 123.579 218.311C123.262 217.983 123.082 217.546 123.075 217.09L122.342 163.512C122.34 163.379 122.294 163.251 122.211 163.147C122.128 163.043 122.013 162.97 121.884 162.939C121.755 162.908 121.619 162.921 121.498 162.976C121.378 163.03 121.278 163.124 121.216 163.242L92.5588 217.636C92.381 217.977 92.098 218.251 91.752 218.418C91.406 218.585 91.0154 218.635 90.6385 218.562L85.2537 217.535C85.0001 217.487 84.7599 217.384 84.5496 217.234C84.3393 217.084 84.1638 216.891 84.0352 216.667C83.9065 216.443 83.8278 216.193 83.8043 215.936C83.7808 215.679 83.8132 215.419 83.8991 215.176L104.779 155.953L106.386 138.434C106.422 138.025 106.597 137.641 106.883 137.347C107.168 137.053 107.547 136.867 107.954 136.819C114.038 136.071 122.539 131.08 125.912 128.969C126.119 128.839 126.35 128.753 126.592 128.716C126.834 128.68 127.081 128.694 127.318 128.758C127.557 128.822 127.78 128.935 127.974 129.089C128.168 129.243 128.328 129.434 128.445 129.653C136.569 144.767 139.677 162.078 137.317 179.079L132.053 217.566C131.997 217.996 131.785 218.391 131.459 218.676C131.133 218.962 130.714 219.119 130.281 219.117Z"
      fill="#2F2E41"
    />
    <path
      d="M115.717 100.783C121.12 100.783 125.499 96.3993 125.499 90.9915C125.499 85.5836 121.12 81.1997 115.717 81.1997C110.315 81.1997 105.936 85.5836 105.936 90.9915C105.936 96.3993 110.315 100.783 115.717 100.783Z"
      fill="#FFB8B8"
    />
    <path
      d="M105.971 142.639L103.026 121.571C102.309 118.37 102.891 115.015 104.644 112.243C106.397 109.471 109.177 107.509 112.375 106.789C115.572 106.069 118.924 106.648 121.694 108.401C124.465 110.153 126.427 112.935 127.149 116.135C127.988 120.03 129.692 124.771 129.069 129.881C124.708 142.001 116.789 145.737 105.971 142.639Z"
      fill="#B3B3B3"
    />
    <path
      d="M118.914 109.948H111.746C111.271 109.947 110.815 109.758 110.479 109.422C110.143 109.085 109.954 108.629 109.954 108.154V105.762C109.954 105.286 110.143 104.83 110.479 104.494C110.815 104.157 111.271 103.968 111.746 103.968H118.914C119.389 103.968 119.845 104.157 120.181 104.494C120.517 104.83 120.706 105.286 120.706 105.762V108.154C120.706 108.629 120.517 109.085 120.181 109.422C119.845 109.758 119.389 109.947 118.914 109.948Z"
      fill="#B3B3B3"
    />
    <path
      d="M155.244 149.769C155.438 149.174 155.5 148.544 155.425 147.922C155.35 147.3 155.139 146.703 154.808 146.172C154.477 145.64 154.034 145.188 153.51 144.847C152.985 144.506 152.393 144.284 151.773 144.196L129.46 112.217L122.843 118.765L146.857 148.141C146.78 149.192 147.091 150.235 147.73 151.073C148.37 151.91 149.294 152.483 150.328 152.684C151.362 152.885 152.433 152.699 153.339 152.162C154.245 151.624 154.923 150.773 155.244 149.769V149.769Z"
      fill="#FFB8B8"
    />
    <path
      d="M136.44 121.999L128.382 127.384C128.153 127.537 127.893 127.639 127.621 127.683C127.349 127.727 127.07 127.711 126.805 127.637C126.539 127.564 126.292 127.434 126.081 127.256C125.871 127.078 125.7 126.857 125.583 126.607L121.455 117.865C120.679 116.687 120.402 115.248 120.684 113.865C120.966 112.482 121.784 111.267 122.959 110.487C124.134 109.707 125.57 109.425 126.952 109.703C128.335 109.98 129.551 110.796 130.334 111.969L136.797 119.113C136.982 119.318 137.121 119.56 137.204 119.823C137.287 120.086 137.313 120.364 137.279 120.638C137.245 120.912 137.152 121.175 137.008 121.41C136.863 121.645 136.669 121.846 136.44 121.999Z"
      fill="#B3B3B3"
    />
    <path
      d="M76.4165 106.8C76.4654 107.015 76.4973 107.233 76.5118 107.453L93.6197 117.333L97.778 114.937L102.211 120.746L95.2613 125.705C94.6999 126.106 94.0238 126.313 93.3346 126.296C92.6453 126.279 91.9801 126.039 91.4389 125.612L73.7977 111.68C73.0016 111.975 72.1346 112.02 71.3121 111.81C70.4895 111.6 69.7504 111.144 69.1931 110.503C68.6358 109.862 68.2866 109.066 68.192 108.221C68.0975 107.377 68.262 106.524 68.6637 105.775C69.0655 105.026 69.6854 104.418 70.441 104.03C71.1966 103.643 72.0521 103.495 72.8937 103.606C73.7354 103.718 74.5232 104.083 75.1525 104.653C75.7818 105.224 76.2227 105.972 76.4165 106.8V106.8Z"
      fill="#FFB8B8"
    />
    <path
      d="M98.5921 124.513L94.2934 115.821C94.1711 115.574 94.1035 115.303 94.0951 115.027C94.0868 114.751 94.1379 114.477 94.2451 114.223C94.3522 113.968 94.5129 113.74 94.716 113.554C94.9192 113.367 95.1601 113.227 95.4224 113.142L104.613 110.168C105.881 109.549 107.342 109.458 108.676 109.915C110.011 110.373 111.109 111.341 111.731 112.608C112.354 113.876 112.448 115.338 111.995 116.675C111.542 118.012 110.577 119.114 109.313 119.741L101.405 125.238C101.179 125.396 100.921 125.502 100.649 125.551C100.378 125.6 100.099 125.589 99.8326 125.52C99.5656 125.452 99.3166 125.326 99.1026 125.152C98.8885 124.978 98.7144 124.76 98.5921 124.513H98.5921Z"
      fill="#B3B3B3"
    />
    <path
      d="M122.281 78.6598C122.634 78.7113 122.993 78.645 123.304 78.4714C123.614 78.2977 123.859 78.0263 124 77.6992C124.131 77.3678 124.184 77.0109 124.155 76.6559C124.127 76.3008 124.017 75.9571 123.835 75.651C123.465 75.041 122.948 74.5335 122.331 74.1746C121.621 73.6996 120.801 73.417 119.949 73.3541C119.524 73.3262 119.098 73.3901 118.7 73.5414C118.302 73.6927 117.941 73.9278 117.642 74.2309C117.35 74.5416 117.157 74.9324 117.088 75.3534C117.019 75.7743 117.077 76.2063 117.255 76.5941C117.612 77.2845 118.363 77.6717 119.094 77.9346C120.545 78.4548 122.09 78.6579 123.625 78.5304"
      fill="#2F2E41"
    />
    <path
      d="M125.409 95.4499C123.699 95.5304 123.994 91.8989 123.459 90.2714C122.924 88.644 122.186 86.7028 120.503 86.3907C119.124 86.135 117.878 87.1446 116.676 87.8693C114.535 89.161 111.764 89.6374 109.515 88.5433C107.267 87.4491 105.9 84.5255 106.993 82.275C107.326 81.6564 107.783 81.1128 108.334 80.6779C108.885 80.2431 109.52 79.9262 110.199 79.7468C111.56 79.3968 112.977 79.3286 114.365 79.5465C117.462 79.9265 120.437 80.9822 123.081 82.6391C124.906 83.7847 126.642 85.3447 127.186 87.4312C127.729 89.5176 127.95 95.1914 125.826 95.5506L125.409 95.4499Z"
      fill="#2F2E41"
    />
    <path
      d="M120.936 73.6089C119.645 71.97 120.131 69.3879 121.636 67.9434C123.141 66.4988 125.38 66.0485 127.457 66.2323C129.515 66.4398 131.482 67.1853 133.161 68.3941C134.84 69.6028 136.173 71.2324 137.024 73.1192C138.337 76.1249 138.326 79.5629 139.513 82.6205C140.146 84.2504 141.105 85.7333 142.332 86.978C143.559 88.2228 145.028 89.2029 146.647 89.8581C148.267 90.5132 150.003 90.8296 151.749 90.7877C153.496 90.7457 155.215 90.3464 156.801 89.6142C158.867 88.6605 160.835 86.7931 160.717 84.5185C162.023 89.5602 159.67 95.3274 155.212 98.0124C152.601 99.585 149.508 100.119 146.482 100.486C144.511 100.726 142.484 100.904 140.56 100.411C137.869 99.7206 135.615 97.7317 134.25 95.3086C132.885 92.8856 132.349 90.0619 132.328 87.2803C132.312 85.1134 132.595 82.9454 132.445 80.7836C132.295 78.6218 131.646 76.3858 130.081 74.8891C128.516 73.3924 125.874 72.8998 124.156 74.2181C123.466 74.748 122.96 75.5217 122.182 75.9125C121.405 76.3034 120.177 75.9994 120.125 75.1301L120.936 73.6089Z"
      fill="#2F2E41"
    />
    <path
      d="M83.4584 106.87H2.78194C2.04439 106.869 1.33729 106.575 0.815758 106.053C0.294228 105.531 0.000855859 104.823 0 104.085V58.5987C0.00085691 57.8604 0.294228 57.1525 0.815758 56.6305C1.33729 56.1084 2.04439 55.8147 2.78194 55.8138H83.4584C84.196 55.8147 84.9031 56.1083 85.4246 56.6304C85.9461 57.1525 86.2395 57.8604 86.2403 58.5987V104.085C86.2395 104.823 85.9462 105.531 85.4246 106.053C84.9031 106.575 84.196 106.869 83.4584 106.87ZM2.78194 56.4327C2.20828 56.4333 1.6583 56.6617 1.25266 57.0678C0.847023 57.4739 0.618851 58.0244 0.618207 58.5987V104.085C0.618851 104.659 0.847023 105.21 1.25266 105.616C1.6583 106.022 2.20828 106.25 2.78194 106.251H83.4584C84.0321 106.25 84.582 106.022 84.9877 105.616C85.3933 105.21 85.6215 104.659 85.6221 104.085V58.5987C85.6215 58.0244 85.3933 57.4739 84.9877 57.0678C84.582 56.6618 84.0321 56.4334 83.4584 56.4327H2.78194Z"
      fill="#3F3D56"
    />
    <path
      d="M29.378 87.7439C35.7493 87.7439 40.9142 82.5735 40.9142 76.1955C40.9142 69.8176 35.7493 64.6472 29.378 64.6472C23.0067 64.6472 17.8418 69.8176 17.8418 76.1955C17.8418 82.5735 23.0067 87.7439 29.378 87.7439Z"
      fill="#E6E6E6"
    />
    <path
      d="M73.8319 96.589L60.2901 73.1093C60.2055 72.9625 60.0837 72.8407 59.9371 72.7559C59.7905 72.6712 59.6243 72.6266 59.455 72.6266C59.2857 72.6266 59.1194 72.6712 58.9728 72.7559C58.8262 72.8407 58.7045 72.9625 58.6198 73.1093L50.3557 87.4382L38.0356 66.0766C37.9271 65.8885 37.771 65.7322 37.583 65.6236C37.3951 65.515 37.1819 65.4578 36.9648 65.4578C36.7478 65.4578 36.5346 65.515 36.3466 65.6236C36.1587 65.7322 36.0026 65.8885 35.8941 66.0766L18.5319 96.1805C18.4233 96.3687 18.3662 96.5821 18.3662 96.7994C18.3662 97.0166 18.4233 97.2301 18.5319 97.4182C18.6404 97.6064 18.7965 97.7626 18.9844 97.8713C19.1724 97.9799 19.3856 98.0371 19.6026 98.0371H72.9967C73.166 98.0371 73.3323 97.9925 73.4789 97.9078C73.6255 97.823 73.7472 97.7012 73.8319 97.5544C73.9165 97.4077 73.9611 97.2412 73.9611 97.0717C73.9611 96.9023 73.9165 96.7358 73.8319 96.589Z"
      fill={fill}
    />
    <path
      d="M10.8187 61.3839H5.87305V66.3348H10.8187V61.3839Z"
      fill="#3F3D56"
    />
    <path
      d="M114.862 39.8934H51.8246C51.2483 39.8927 50.6958 39.6632 50.2883 39.2553C49.8808 38.8473 49.6515 38.2943 49.6509 37.7174V2.176C49.6515 1.59909 49.8808 1.04601 50.2883 0.638074C50.6958 0.23014 51.2483 0.000663428 51.8246 -7.62939e-06H114.862C115.438 0.000642367 115.991 0.230111 116.398 0.63805C116.806 1.04599 117.035 1.59909 117.036 2.176V37.7174C117.035 38.2943 116.806 38.8474 116.399 39.2553C115.991 39.6632 115.438 39.8927 114.862 39.8934ZM51.8246 0.483548C51.3764 0.484052 50.9466 0.662525 50.6297 0.979812C50.3127 1.2971 50.1344 1.72729 50.1339 2.176V37.7174C50.1344 38.1661 50.3127 38.5963 50.6297 38.9135C50.9466 39.2308 51.3764 39.4093 51.8246 39.4098H114.862C115.31 39.4093 115.74 39.2308 116.057 38.9135C116.374 38.5962 116.552 38.1661 116.553 37.7174V2.176C116.552 1.72729 116.374 1.29712 116.057 0.979836C115.74 0.662554 115.31 0.484071 114.862 0.483548H51.8246Z"
      fill="#CCCCCC"
    />
    <path
      d="M72.6058 24.9499C77.584 24.9499 81.6197 20.9099 81.6197 15.9264C81.6197 10.9429 77.584 6.90294 72.6058 6.90294C67.6275 6.90294 63.5918 10.9429 63.5918 15.9264C63.5918 20.9099 67.6275 24.9499 72.6058 24.9499Z"
      fill="#F2F2F2"
    />
    <path
      d="M107.34 31.8594L96.7593 13.5132C96.6931 13.3985 96.598 13.3033 96.4835 13.2371C96.3689 13.1709 96.239 13.136 96.1067 13.136C95.9744 13.136 95.8445 13.1709 95.7299 13.2371C95.6154 13.3033 95.5203 13.3985 95.4541 13.5132L88.9969 24.7093L79.3704 8.01812C79.2856 7.8711 79.1636 7.74902 79.0168 7.66414C78.8699 7.57926 78.7033 7.53458 78.5337 7.53458C78.3642 7.53458 78.1976 7.57926 78.0507 7.66414C77.9038 7.74902 77.7819 7.8711 77.6971 8.01812L64.1309 31.5402C64.0461 31.6872 64.0015 31.854 64.0015 32.0237C64.0015 32.1935 64.0461 32.3603 64.1309 32.5073C64.2157 32.6543 64.3377 32.7764 64.4845 32.8613C64.6314 32.9461 64.798 32.9908 64.9676 32.9908H106.688C106.82 32.9908 106.95 32.956 107.064 32.8898C107.179 32.8236 107.274 32.7283 107.34 32.6137C107.406 32.499 107.441 32.3689 107.441 32.2365C107.441 32.1041 107.406 31.974 107.34 31.8594Z"
      fill="#E6E6E6"
    />
    <path
      d="M58.1041 4.35223H54.2397V8.22067H58.1041V4.35223Z"
      fill="#CCCCCC"
    />

    <defs>
      <clipPath id="clip0">
        <rect width="342" height="232" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Camera;
