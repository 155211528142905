import React from "react";
import { Box, useTheme } from "@mui/material";
import { LengthMeasurementUnit, Units } from "../../constants";
import { addDays } from "../../utils/date";
import makeStyles from "@mui/styles/makeStyles";
import { Line } from "react-chartjs-2";
import { convertLength } from "../../utils/units";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";
import { colorSystem } from "../../theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "&$pointer": {
      cursor: "pointer",
    },
  },

  container: {
    marginTop: 8,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  center: {
    display: "flex",
    alignItems: "center",
  },

  bigText: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.black,

    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },

  smallText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  indicator: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },

  pointer: {},
  gain: {},
  drop: {},
}));

type MeasurementsChartsProps = {
  dataMetrics: any;
  units?: Units;
};

const MeasurementsCharts = (props: MeasurementsChartsProps) => {
  const { dataMetrics, units } = props;
  const s = useStyles();
  const { palette } = useTheme();
  const weights = React.useMemo(
    () =>
      dataMetrics.map((it) => ({
        value:
          it.value.unit === "PERCENTAGE"
            ? it.value.measurement
            : convertLength(
                it.value.measurement,
                it.value.unit.toLowerCase(),
                units,
              ).toFixed(1),
        unit:
          it.value.unit === "PERCENTAGE"
            ? (it.value.unit.toLowerCase() as LengthMeasurementUnit)
            : units === Units.METRIC
              ? "cm"
              : "in",
        date: it.activityDate,
      })),
    [dataMetrics, units],
  );
  const data = React.useMemo(
    () => weights.map(({ date: x, value: y }) => ({ x, y })),
    [weights],
  );

  const ticks = React.useMemo(
    () =>
      data.length === 1
        ? {
            min: addDays(data[0].x, -1),
            max: addDays(data[0].x, 1),
          }
        : {},
    [data],
  );

  const getTooltipLabel = React.useCallback(
    (item) =>
      `${weights[item.dataIndex].value} ${weights[item.dataIndex].unit.toLowerCase()}`,
    [weights],
  );
  return (
    <Box className={s.container}>
      <Line
        height={70}
        data={{
          datasets: [
            {
              data,
              fill: false,
              borderColor: palette.primary.main,
              borderWidth: 1,
              pointRadius: 4,
              pointBorderColor: palette.primary.main,
              pointBackgroundColor: palette.primary.main,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              displayColors: false,
              callbacks: {
                title: () => "",
                label: getTooltipLabel,
              },
            },
          },
          layout: {
            padding: 5,
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                displayFormats: {
                  day: "dd",
                },
              },
            },
            y: {
              type: "linear",
              grid: {
                display: false,
              },
              ticks: {
                display: false,
                stepSize: 2,
                precision: 0,
              },
              border: {
                display: false,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default MeasurementsCharts;
