import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps, Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentLibrarySectionComponentsList } from "../component-library/ComponentLibrarySectionComponentsList";

import { ClientFormSections } from "./constants";
import { ClientFormSection } from "./types";

type StyleProps = {
  open: boolean;
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      paddingRight: ({ open }: StyleProps) => theme.spacing(open ? 27 : 0),
      transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    },
  },

  root: {},

  tabs: {
    boxShadow: theme.shadows[3],
  },

  tabsIndicator: {
    height: 1.33,
  },

  tab: {
    fontSize: 14,
    fontWeight: 700,
    width: "50%",

    '&[aria-selected="false"]': {
      color: theme.palette.text.secondary,
    },

    '&[aria-selected="true"]': {
      color: theme.palette.primary.main,
    },
  },

  paper: {
    paddingTop: theme.spacing(9.5),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(38),
      boxShadow: theme.shadows[8],
    },
  },

  components: {
    paddingLeft: theme.spacing(1),
    marginRight: 0,
  },
}));

export interface ClientFormSidebarProps extends DrawerProps {}

export function ClientFormSidebar(props: ClientFormSidebarProps) {
  const { className, onClose, open, ...other } = props;
  const s = useStyles({ open });
  const [section, setSection] = React.useState(ClientFormSection.CONTENT);

  const handleSectionChange = React.useCallback(
    (event, section: ClientFormSection) => setSection(section),
    [],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
      }}
      anchor="right"
      variant="persistent"
      onClose={onClose}
      open={open}
      {...other}
    >
      <Tabs
        className={s.tabs}
        indicatorColor="primary"
        classes={{ indicator: s.tabsIndicator }}
        value={section}
        onChange={handleSectionChange}
      >
        {Object.entries(ClientFormSections).map(([section, { name }]) => (
          <Tab key={section} className={s.tab} label={name} value={section} />
        ))}
      </Tabs>

      <ComponentLibrarySectionComponentsList
        className={s.components}
        types={ClientFormSections[section].types}
      />
    </Drawer>
  );
}
