import clsx from "clsx";
import React from "react";
import {
  ListItem,
  ListItemProps,
  ListItemIcon,
  Divider,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  DragSourceMonitor,
  useDrag,
  DropTargetMonitor,
  useDrop,
} from "react-dnd";

import { ReactComponent as GrabSharp } from "../../icons/GrabSharp.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(-3),
  },

  dragging: {
    opacity: 0.5,
  },

  container: {
    "&:hover $actions": {
      display: "initial",
    },
  },

  actions: {
    display: "none",
    marginRight: theme.spacing(-2),
  },

  dragIcon: {
    color: theme.palette.quote,
    cursor: "grab",
  },

  dragIconDisabled: {
    cursor: "initial",
    opacity: 0,
  },

  divider: {
    backgroundColor: theme.palette.quote,
    marginLeft: theme.spacing(3.75),
    marginRight: theme.spacing(1),
  },

  action: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),

    "& svg": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
}));

export interface SortableListDragItem<T> {
  type: string;
  option: T;
  position: number;
  initialPosition: number;
}

export interface SortableListItemAction {
  name: string;
  Icon: React.FC;
}

export interface SortableListItemProps<T> extends ListItemProps {
  button?: false;
  option: T;
  position: number;
  itemType: string;
  disableDragging?: boolean;
  actions?: SortableListItemAction[];
  onAction?: (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    option?: T,
  ) => void;
  onMove?: (dragPosition: number, hoverPosition: number) => void;
  onMoveEnd?: () => void;
}
export function SortableListItem<T>(props: SortableListItemProps<T>) {
  const {
    className,
    divider,
    children,
    option,
    actions,
    onAction,
    itemType,
    position,
    onMove,
    onMoveEnd,
    disableDragging = false,
    ...other
  } = props;
  const s = useStyles();

  const handleActionClick = React.useCallback(
    (event) => {
      if (onAction) {
        onAction(event, option);
      }
    },
    [onAction, option],
  );

  const ref = React.useRef<HTMLLIElement>(null);
  const dragRef = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: itemType,

    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      if (disableDragging) {
        return;
      }

      const dragIndex = item.position;
      const hoverIndex = position;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (onMove) {
        onMove(item.initialPosition, hoverIndex);

        item.position = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: {
      option,
      position,
      initialPosition: position,
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end() {
      if (onMoveEnd) {
        onMoveEnd();
      }
    },
  });

  if (!disableDragging) {
    drag(dragRef);
  }

  drop(ref);
  preview(ref);

  return (
    <>
      <ListItem
        ref={ref}
        className={clsx(s.root, className, isDragging && s.dragging)}
        classes={{ container: s.container }}
        {...other}
      >
        <ListItemIcon
          ref={dragRef}
          className={clsx(s.dragIcon, disableDragging && s.dragIconDisabled)}
        >
          <GrabSharp />
        </ListItemIcon>

        {children}

        {actions && actions.length > 0 && (
          <ListItemSecondaryAction className={s.actions}>
            {actions.map(({ name, Icon }) => (
              <IconButton
                key={name}
                data-action={name}
                className={s.action}
                onClick={handleActionClick}
                size="large"
              >
                <Icon />
              </IconButton>
            ))}
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {divider && <Divider className={s.divider} />}
    </>
  );
}
