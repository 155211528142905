import clsx from "clsx";
import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { ReactComponent as ChevronSilverLeftIcon } from "../../icons/ChevronSilverLeft.svg";

import { ClientNutritionTargetListTableHeader } from "./ClientNutritionTargetListTableHeader";
import { ClientNutritionTargetListItem } from "./ClientNutritionTargetListItem";
import { ClientNutritionTargetListItemHeader } from "./ClientNutritionTargetListItemHeader";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useClient } from "../../hooks/useClient";

const useStyles = makeStyles((theme) => ({
  title: {
    color: colorSystem.gray,
    textTransform: "uppercase",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colorSystem.gray11,
    padding: theme.spacing(1.8, 2.3, 1.8, 1.2),
    cursor: "pointer",
    marginBottom: theme.spacing(3),
  },

  chevronIcon: {
    transform: "rotate(-90deg)",
  },

  table: {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.25s ease-in",
  },
  targetOpen: {
    maxHeight: "inherit",
    paddingBottom: theme.spacing(3),
  },

  targetListHeader: {
    display: "flex",
    overflow: "scroll",
    margin: theme.spacing(0, -1),
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  createButton: {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    fontSize: 16,
    fontWeight: 600,
    boxShadow: "none",
    height: theme.spacing(4),
    position: "absolute",
    bottom: -3,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  createButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: "25px!important",
  },

  tableBody: {
    position: "relative",
  },
}));

export interface ClientNutritionTargetListProps {
  title: string;
  targets: any;
  refresh?: () => void;
  handleAddNutrition?: () => void;
  isProgram?: boolean;
}

export function ClientNutritionTargetList(
  props: ClientNutritionTargetListProps,
) {
  const { title, targets, refresh, isProgram, handleAddNutrition, ...other } =
    props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));

  const client = useClient();

  const [targetOpen, setTargetOpen] = React.useState(true);
  const [targetActiveIndex, setTargetActiveIndex] = React.useState(0);

  const handleClickItem = React.useCallback(
    (index) => {
      setTargetActiveIndex(index);
    },
    [setTargetActiveIndex],
  );

  return (
    <>
      <Box className={s.header} onClick={() => setTargetOpen(!targetOpen)}>
        <Typography children={title} variant="subtitle1" className={s.title} />
        <ChevronSilverLeftIcon className={s.chevronIcon} />
      </Box>

      <Box className={clsx(s.table, targetOpen && s.targetOpen)}>
        {mdUp && <ClientNutritionTargetListTableHeader />}
        {!mdUp && (
          <Box className={s.targetListHeader}>
            {targets.map((item, index) => (
              <ClientNutritionTargetListItemHeader
                title={item.targetType}
                key={item.id}
                index={index}
                activeIndex={targetActiveIndex}
                onClick={() => handleClickItem(index)}
              />
            ))}
          </Box>
        )}
        <Box className={s.tableBody}>
          {targets.map((item, index) =>
            index === targetActiveIndex || mdUp ? (
              <ClientNutritionTargetListItem
                target={item}
                key={item.id}
                refresh={refresh}
                handleAddNutrition={handleAddNutrition}
                isProgram={isProgram}
              />
            ) : null,
          )}
          {!mdUp && Boolean(client) && (
            <Button
              className={s.createButton}
              endIcon={<ArrowForwardRounded className={s.createButtonIcon} />}
              children="Create"
              {...other}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
