import clsx from "clsx";
import React from "react";
import { Box, BoxProps, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientMealLoggingQuestionProps } from "./types";
import { MealLoggingQuestionLongText } from "../meal-logging-settings/types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },
}));

export interface ClientMealLoggingLongTextQuestionProps
  extends BoxProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionLongText> {}

export function ClientMealLoggingLongTextQuestion(
  props: ClientMealLoggingLongTextQuestionProps,
) {
  const s = useStyles();
  const { className, option, onUpdate, ...other } = props;
  const [answer, setAnswer] = React.useState<string>(option.answer);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setAnswer(event.currentTarget.value);

      if (onUpdate) {
        onUpdate({ ...option, answer });
      }
    },
    [answer, onUpdate, option],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Enter answer"
        value={answer}
        onChange={handleChange}
        multiline
        rows={3}
      />
    </Box>
  );
}
