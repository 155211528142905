import clsx from "clsx";
import React, { useEffect, useTransition } from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";

import { ActivityResponseCard } from "../activity/ActivityResponseCard";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { ActivityTypesFilterProps } from "../filters/ActivityTypesFilter";
import { ActivitySortFilterProps } from "../filters/ActivitySortFilter";

import { ClientResponsesActivities_root$key } from "./__generated__/ClientResponsesActivities_root.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {},

  loadMore: {
    marginBottom: theme.spacing(3),
  },
}));

const rootFragment = graphql`
  fragment ClientResponsesActivities_root on Root
  @refetchable(queryName: "ClientResponsesActivitiesRefetchQuery")
  @argumentDefinitions(
    username: { type: "String!" }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    withAnswersOnly: { type: "Boolean", defaultValue: true }
    withMealLogging: { type: "Boolean", defaultValue: true }
    withClientForms: { type: "Boolean", defaultValue: true }
    coachArchived: { type: "Boolean" }
    orderBy: { type: "ActivitySortBy", defaultValue: COMPLETED_AT_DESC }
    activityTypes: { type: "[ActivityType!]" }
  ) {
    activities: activitiesConn(
      client: $username
      first: $first
      after: $after
      withAnswersOnly: $withAnswersOnly
      withMealLogging: $withMealLogging
      withClientForms: $withClientForms
      coachArchived: $coachArchived
      orderBy: $orderBy
      activityTypes: $activityTypes
    ) @connection(key: "ClientResponsesActivities_activities", filters: []) {
      edges {
        node {
          id
          coachArchived
          ...ActivityResponseCard_activity
        }
      }
    }
  }
`;

export interface ClientResponsesActivitiesProps extends BoxProps {
  root: ClientResponsesActivities_root$key;
  emptyInfoBox: React.ReactNode;
  filters: ActivityTypesFilterProps["filters"];
  sortedBy: ActivitySortFilterProps["sortedBy"];
  pageSize?: number;
}

export function ClientResponsesActivities(
  props: ClientResponsesActivitiesProps,
) {
  const s = useStyles();
  const {
    className,
    root: rootRef,
    emptyInfoBox,
    children,
    pageSize = 10,
    filters,
    sortedBy,
    ...other
  } = props;
  const {
    data: root,
    loadNext,
    hasNext,
    refetch: refetchConnection,
  } = usePaginationFragment(rootFragment, rootRef);
  const { activities } = root;
  const [loading, setLoading] = React.useState(false);
  const [isPending, setTransition] = useTransition();
  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    loadNext(pageSize, {
      onComplete: setLoaded,
      UNSTABLE_extraVariables: {
        activityTypes: Object.entries(filters)
          .filter(([, enabled]) => enabled)
          .map(([type]) => type),
        orderBy: sortedBy,
      },
    });
  }, [pageSize, loadNext, setLoaded]);

  const refetch = () => {
    setTransition(() => {
      refetchConnection(
        {
          activityTypes: Object.entries(filters)
            .filter(([, enabled]) => enabled)
            .map(([type]) => type),
          orderBy: sortedBy,
          coachArchived: false,
          first: pageSize,
        },
        {
          onComplete: setLoaded,
          ...RELAY_LAZY_LOAD_COMMON_CONFIG,
        },
      );
    });
  };

  React.useEffect(() => {
    if (!loading) {
      refetch();
    }
  }, [refetchConnection, setLoaded, loading, filters, sortedBy]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {root?.activities?.edges?.length ? (
        <>
          {children}
          {activities.edges.map(({ node: activity }) => (
            <ActivityResponseCard
              key={activity.id}
              activityRef={activity}
              enableArchive={false}
            />
          ))}
          {hasNext && (
            <LoadMoreButton
              className={s.loadMore}
              onClick={handleMoreClick}
              disabled={loading}
            />
          )}
        </>
      ) : (
        emptyInfoBox
      )}
    </Box>
  );
}
