/**
 * @generated SignedSource<<e5732e5c64bcf536b5d29636b982f094>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityFeedbackList_activity$data = {
  readonly coachReadAnswers: boolean;
  readonly feedbacks: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityFeedback_activityFeedback">;
  }> | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackForm_activity">;
  readonly " $fragmentType": "ActivityFeedbackList_activity";
};
export type ActivityFeedbackList_activity$key = {
  readonly " $data"?: ActivityFeedbackList_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityFeedbackList_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityFeedbackList_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityFeedback",
      "kind": "LinkedField",
      "name": "feedbacks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityFeedback_activityFeedback"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedbackForm_activity"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachReadAnswers",
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};
})();

(node as any).hash = "b4e93f937c2805811d0b4726bacb3ab1";

export default node;
