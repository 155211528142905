import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { ExerciseTypeSet, Units } from "../../../../../constants";
import { unitSetLabelCoach } from "../../../../../utils/units";

export const WeightSetCustomizationInfoSection = () => {
  const me = useCurrentUser();
  const defaultCoachUnits = me.units ?? Units.US;

  return (
    <InfoSection
      title="Customize Sets"
      description={
        <>
          Number of column values depends on value in <b>Sets</b> column.
        </>
      }
    >
      <InfoBox
        title="Comma"
        description="Define
  different value per each set using commas."
      >
        <ExampleValueBadge
          value="200, 300"
          unit={unitSetLabelCoach(defaultCoachUnits, ExerciseTypeSet.DISTANCE)}
        />
      </InfoBox>

      <InfoBox
        title="Auto"
        description={
          <>
            Leave repetitive values blank. <br /> This will automatically apply
            value to all next sets.
          </>
        }
      >
        <ExampleValueBadge
          value="5"
          secondaryValue=", 5, 5"
          unit={unitSetLabelCoach(defaultCoachUnits, ExerciseTypeSet.WEIGHT)}
        />
        <ExampleValueBadge
          value="10, 15"
          secondaryValue=", 10, 15"
          unit="min"
        />
      </InfoBox>
    </InfoSection>
  );
};
