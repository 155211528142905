/**
 * @generated SignedSource<<8a65dff91aa1dbac09fe5d119b252cb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrollGroupsInput = {
  clientMutationId?: string | null | undefined;
  groupsIds: ReadonlyArray<string>;
  programId: string;
  startDate: string;
};
export type EnrollDateDialogEnrollGroupsMutation$variables = {
  input: EnrollGroupsInput;
};
export type EnrollDateDialogEnrollGroupsMutation$data = {
  readonly enrollGroups: {
    readonly enrollments: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EnrolledAvatar_enrollment" | "EnrollmentCard_enrollment">;
    }>;
  } | null | undefined;
};
export type EnrollDateDialogEnrollGroupsMutation = {
  response: EnrollDateDialogEnrollGroupsMutation$data;
  variables: EnrollDateDialogEnrollGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "MMM Do"
  },
  {
    "kind": "Literal",
    "name": "utc",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnrollDateDialogEnrollGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EnrollGroupsPayload",
        "kind": "LinkedField",
        "name": "enrollGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Enrollment",
            "kind": "LinkedField",
            "name": "enrollments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EnrolledAvatar_enrollment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EnrollmentCard_enrollment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnrollDateDialogEnrollGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EnrollGroupsPayload",
        "kind": "LinkedField",
        "name": "enrollGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Enrollment",
            "kind": "LinkedField",
            "name": "enrollments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": "startDate(format:\"MMM Do\",utc:true)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": "endDate(format:\"MMM Do\",utc:true)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "period",
                    "value": "BEFORE_NOW"
                  }
                ],
                "concreteType": "Completion",
                "kind": "LinkedField",
                "name": "completion",
                "plural": false,
                "selections": [
                  {
                    "alias": "unlockedScore",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate",
                    "storageKey": null
                  }
                ],
                "storageKey": "completion(period:\"BEFORE_NOW\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viaGroup",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac2fedb17c3013cfe3906c6969257a16",
    "id": null,
    "metadata": {},
    "name": "EnrollDateDialogEnrollGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation EnrollDateDialogEnrollGroupsMutation(\n  $input: EnrollGroupsInput!\n) {\n  enrollGroups(input: $input) {\n    enrollments {\n      id\n      ...EnrolledAvatar_enrollment\n      ...EnrollmentCard_enrollment\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  displayName\n  photoURL\n}\n\nfragment EnrolledAvatar_enrollment on Enrollment {\n  client {\n    ...Avatar_user\n    id\n  }\n}\n\nfragment EnrollmentCard_enrollment on Enrollment {\n  id\n  client {\n    id\n    username\n    displayName\n    email\n    photoURL\n  }\n  startDate(utc: true, format: \"MMM Do\")\n  endDate(utc: true, format: \"MMM Do\")\n  completion(period: BEFORE_NOW) {\n    unlockedScore: rate\n  }\n  viaGroup\n}\n"
  }
};
})();

(node as any).hash = "a93a24eaa3213c922e98d474d2dedf61";

export default node;
