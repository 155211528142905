import clsx from "clsx";
import React from "react";
import { MenuItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../../../button/MoreMenuButton";
import { Menu, MenuProps } from "../../../menu/Menu";

const useStyles = makeStyles((theme) => ({
  root: {},
  menu: {
    zIndex: "99999 !important" as any,
  },
  active: {
    display: "block !important",
  },
}));

export interface WorkoutMediaMenuProps extends Omit<MenuProps, "open"> {
  isCover?: boolean;
  onToggleCover?: () => void;
  onRemove?: () => void;
}

export function WorkoutMediaMenu(props: WorkoutMediaMenuProps) {
  const { className, isCover, onToggleCover, onRemove, ...other } = props;
  const s = useStyles();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "workout-image",
  });
  const { isOpen } = menuState;

  const handleToggleCoverClick = React.useCallback(
    (event) => {
      event.preventDefault();

      menuState.close();
      if (onToggleCover) {
        onToggleCover();
      }
    },
    [menuState, onToggleCover],
  );

  const handleRemoveClick = React.useCallback(
    (event) => {
      event.preventDefault();

      menuState.close();
      if (onRemove) {
        onRemove();
      }
    },
    [menuState, onRemove],
  );

  return (
    <div
      className={clsx(s.root, className, isOpen && s.active)}
      contentEditable={false}
    >
      <MoreMenuButton {...bindTrigger(menuState)} />

      <Menu
        className={s.menu}
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...other}
      >
        <MenuItem onClick={handleToggleCoverClick}>
          <ListItemText>
            {isCover ? "Remove as exercise cover" : "Set as exercise cover"}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleRemoveClick}>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
