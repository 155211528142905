import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Box, BoxProps, TextField, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ClientGoal_goal$key } from "./__generated__/ClientGoal_goal.graphql";
import { useClient } from "../../hooks/useClient";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ProgressLine } from "../progress/ProgressLine";
import { useUpsertClientGoalMutation } from "./mutations/UpsertClientGoal";
import AddCircle from "../../icons/AddCircle";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  name: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },
  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  button: {
    top: -12,
  },
  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  input: {
    "& input": {
      fontWeight: 500,
    },
  },
  inputWrapper: {
    width: "100%",
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.25s ease-in",
  },
  inputWrapperVisible: {
    maxHeight: 80,
    transition: "max-height 0.15s ease-out",
  },
  progress: {
    width: "100%",
    maxWidth: "100%",
    marginTop: theme.spacing(2),
  },
}));

const goalFragment = graphql`
  fragment ClientGoal_goal on ClientGoal {
    id
    name
    targetValue
    currentValue
    formattedDate: date(format: "MMM D, YYYY")
    completed
  }
`;

export interface ClientGoalProps extends BoxProps {
  goalRef: ClientGoal_goal$key;
}

export function ClientGoal(props: ClientGoalProps) {
  const { className, goalRef, ...other } = props;
  const goal = useFragment(goalFragment, goalRef);
  const s = useStyles();
  const client = useClient<any>();
  const user = useCurrentUser();
  const [upsertGoal] = useUpsertClientGoalMutation();
  const [dif, setDif] = React.useState("");
  const [editGoal, setEditGoal] = useState(false);
  const [currentValue, setCurrentValue] = React.useState(goal.currentValue);

  const daysDif = React.useCallback(() => {
    const now = dayjs();
    const end = dayjs(goal.formattedDate, "MMM D, YYYY");
    const duration = dayjs.duration(now.diff(end));
    const days = Math.floor(duration.asDays());

    if (days <= 0) {
      setDif(`${Math.abs(days).toString()} days left`);
    } else {
      setDif(`late  ${days.toString()} days`);
    }
  }, [goal.formattedDate]);

  useEffect(() => {
    if (goal.formattedDate) {
      daysDif();
    }
  }, [goal.formattedDate, daysDif]);

  const save = React.useCallback(() => {
    const dirty = Boolean(currentValue && goal.currentValue !== currentValue);

    if (dirty) {
      upsertGoal({
        variables: {
          input: {
            id: goal?.id,
            clientId: client?.id || user.id,
            name: goal.name,
            currentValue: currentValue,
            date: goal.formattedDate || null,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.id, user.id, currentValue, upsertGoal]);

  const handleCurrentValueChange = React.useCallback(
    (event) => {
      const pattern = /^[0-9]+$/;
      if (pattern.test(event.target.value) || event.target.value === "") {
        if (+event.target.value > +goal.targetValue) {
          setCurrentValue(goal.targetValue);
        } else {
          setCurrentValue(event.target.value);
        }
      }
    },
    [goal.targetValue],
  );

  const handleCurrentValueBlur = React.useCallback(() => {
    save();
  }, [save]);

  const calculateProgress = React.useMemo(() => {
    const value = (100 / +goal.targetValue) * +currentValue;
    return value > 100 ? 100 : value;
  }, [goal.targetValue, currentValue]);
  const theme = useTheme();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <div className={s.container}>
        <Typography className={s.name} children={goal.name} />
        <Typography className={s.date} children={dif} />
      </div>
      <IconButton
        className={s.button}
        aria-label="Update current value"
        onClick={() => setEditGoal(!editGoal)}
        size="large"
      >
        <AddCircle fill={theme.palette.primary.main} />
      </IconButton>
      <Box className={clsx(s.inputWrapper, editGoal && s.inputWrapperVisible)}>
        <Typography variant="h4" className={s.label}>
          Set new current value
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={currentValue === null ? "" : currentValue}
          onChange={handleCurrentValueChange}
          onBlur={handleCurrentValueBlur}
          className={s.input}
          error={!currentValue && currentValue !== null}
        />
      </Box>
      {/^[0-9]+$/.test(goal.targetValue) && (
        <ProgressLine value={calculateProgress} className={s.progress} />
      )}
    </Box>
  );
}
