import React from "react";
import { graphql } from "react-relay/hooks";
import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

import { PaginationContext } from "../../../utils/paging";

import { UpdateGroupArchivedMutation } from "./__generated__/UpdateGroupArchivedMutation.graphql";

const mutation = graphql`
  mutation UpdateGroupArchivedMutation($input: UpdateGroupArchivedInput!) {
    updateGroupArchived(input: $input) {
      clientMutationId
      groupId
      group {
        archived
      }
      groupEdge {
        node {
          ...GroupCard_group
        }
      }
    }
  }
`;

export const useUpdateGroupArchivedMutation = () => {
  const snackAlert = useSnackAlert();
  const filters = React.useContext(PaginationContext);
  const user = useCurrentUser();

  return useConfiguredMutation<UpdateGroupArchivedMutation>(
    mutation,
    (config) => ({
      configs: config.variables.input.archived
        ? [
            {
              type: "RANGE_DELETE",
              parentID: user.id,
              connectionKeys: [
                {
                  key: "GroupNavItemList_groups",
                },
              ],
              pathToConnection: ["me", "groups"],
              deletedIDFieldName: "groupId",
            },
            {
              type: "RANGE_DELETE",
              parentID: "client:root",
              connectionKeys: [
                {
                  key: "CoachGroups_groups",
                  filters,
                },
              ],
              pathToConnection: ["client:root", "groups"],
              deletedIDFieldName: "groupId",
            },
          ]
        : [
            {
              type: "RANGE_ADD",
              parentID: user.id,
              connectionInfo: [
                {
                  key: "GroupNavItemList_groups",
                  rangeBehavior: "prepend",
                },
              ],
              edgeName: "groupEdge",
            },
            {
              type: "RANGE_DELETE",
              parentID: "client:root",
              connectionKeys: [
                {
                  key: "CoachGroups_groups",
                  filters,
                },
              ],
              pathToConnection: ["client:root", "groups"],
              deletedIDFieldName: "groupId",
            },
          ],

      onSuccess: ({
        updateGroupArchived: {
          group: { archived },
        },
      }) =>
        snackAlert({
          severity: "success",
          message: `Group ${archived ? "archived" : "restored"}`,
        }),
    }),
  );
};
