/**
 * @generated SignedSource<<6dfb4db5d056dc9c2adf60dd1f87925a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientForm_clientForm$data = {
  readonly activity: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityFeedbackButton_activity">;
  } | null | undefined;
  readonly content: string;
  readonly description: string;
  readonly id: string;
  readonly image: string | null | undefined;
  readonly name: string;
  readonly requestId: string;
  readonly submittedAt: string | null | undefined;
  readonly updatedAt: string | null | undefined;
  readonly " $fragmentType": "ClientForm_clientForm";
};
export type ClientForm_clientForm$key = {
  readonly " $data"?: ClientForm_clientForm$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientForm_clientForm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientForm_clientForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submittedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activity",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityFeedbackButton_activity"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientForm",
  "abstractKey": null
};

(node as any).hash = "aaf914bbca4dde05848e44e42ea5e0fb";

export default node;
