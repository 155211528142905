import React from "react";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import {
  ClientAddableItem_client$key,
  ClientAddableItem_client$data,
} from "./__generated__/ClientAddableItem_client.graphql";

import { SelectableItem, SelectableItemProps } from "./SelectableItem";

const clientFragment = graphql`
  fragment ClientAddableItem_client on User {
    id
    email
    displayName
    photoURL
  }
`;

export interface ClientAddableItemProps
  extends Pick<
    SelectableItemProps,
    "className" | "added" | "disabled" | "disabledText"
  > {
  clientRef: ClientAddableItem_client$key;
  onToggle: (added: boolean, client: ClientAddableItem_client$data) => void;
}

export function ClientAddableItem(props: ClientAddableItemProps) {
  const { className, clientRef, onToggle, ...other } = props;
  const client = useFragment(clientFragment, clientRef);
  const { email, displayName, photoURL } = client;

  const handleToggle: SelectableItemProps["onToggle"] = React.useCallback(
    (added) => {
      onToggle(added, client);
    },
    [client, onToggle],
  );

  return (
    <SelectableItem
      className={className}
      avatarSrc={photoURL}
      header={displayName}
      subheader={email}
      onToggle={handleToggle}
      {...other}
    />
  );
}
