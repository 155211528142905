import React from "react";
import { cn, withRef } from "@udecode/cn";
import { PlateLeaf } from "@udecode/plate-common";

export const HighlightLeaf = withRef<typeof PlateLeaf>(
  ({ className, children, ...props }, ref) => {
    const color = props.leaf.color as string;
    const backgroundColor = props.leaf.highlight as string;

    return (
      <PlateLeaf
        ref={ref}
        asChild
        className={cn(
          "bg-primary/20 text-inherit dark:bg-primary/40",
          className,
        )}
        {...props}
        style={{
          ...(color && { color }),
          ...(backgroundColor && { backgroundColor }),
          ...props.style,
        }}
      >
        <mark>{children}</mark>
      </PlateLeaf>
    );
  },
);
