import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LocalStorageKeys } from "../../constants";
import { Close as CloseIcon } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const SmallScreenAlert = () => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [neverShowAgain, setNeverShowAgain] = React.useState(false);

  useEffect(() => {
    const showSmallScreenAlert = localStorage.getItem(
      LocalStorageKeys.SHOW_SMALL_SCREEN_ALERT,
    );
    showSmallScreenAlert !== "false" && setOpen(!isSm);
  }, [isSm]);

  const handleNeverShowAgainClick = (_, checked: boolean) => {
    setNeverShowAgain(checked);
    localStorage.setItem(
      LocalStorageKeys.SHOW_SMALL_SCREEN_ALERT,
      String(!checked),
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiDialog-paper": {
            maxWidth: 300,
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <InfoOutlinedIcon color="primary" />
            <Typography variant="h6">Info</Typography>
          </Box>
          <IconButton
            size="small"
            children={<CloseIcon />}
            onClick={handleClose}
          />
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
            For best user experience we sugest to try using editor on larger
            screen
          </Typography>
        </DialogContent>

        <DialogActions>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1.5 }}>
            <Checkbox
              size="small"
              checked={neverShowAgain}
              onChange={handleNeverShowAgainClick}
              sx={{
                padding: 0,
              }}
            />
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              Newer show this again
            </Typography>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SmallScreenAlert;
