import clsx from "clsx";
import React from "react";
import { Box, BoxProps, IconButton, Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as StarIcon } from "../../icons/Star2.svg";
import { colorSystem } from "../../theme";
import { Icons } from "../plate-ui/Icons/icons";

type StyleProps = {
  disabled: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },

  button: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: colorSystem.secondaryGray,
    borderRadius: theme.spacing(0.5),
    padding: 0,

    "& svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
      color: theme.palette.common.white,
    },

    "&$disabled": {
      filter: theme.filters.disabled,
    },

    "&$active": {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },

    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },

    "&:hover ": {
      backgroundColor: alpha(theme.palette.primary.light, 0.3),
      cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
    },

    "&$small": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),

      "& svg": {
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
      },
    },
  },

  active: {},
  small: {},
  disabled: {},
}));

export interface StarRatingProps extends Omit<BoxProps, "onSelect"> {
  value?: number;
  onSelect?: (value: number | null) => void;
  disabled?: boolean;
  small?: boolean;
}

export function StarRating(props: StarRatingProps) {
  const {
    className,
    value = null,
    onSelect,
    disabled = false,
    small = false,
    ...other
  } = props;
  const s = useStyles({ disabled });

  const handleClick = React.useCallback(
    (value: number) => {
      if (onSelect) {
        onSelect(value);
      }
    },
    [onSelect],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {Array.from({ length: 5 })
        .map((v, i) => i + 1)
        .map((_value) => (
          <IconButton
            disabled={disabled}
            className={clsx(
              s.button,
              small && s.small,
              disabled && s.disabled,
              value >= _value && s.active,
            )}
            sx={{
              "&:hover": {
                backgroundColor: colorSystem.secondaryGrayOpacity5,
              },
              // Override MUI's disabled styles since icon will be hidden by default
              ":disabled": {
                backgroundColor: colorSystem.secondaryGray,
                "&:hover": {
                  cursor: "default",
                },
              },
            }}
            key={_value}
            onClick={() => (disabled ? undefined : handleClick(_value))}
            role="img"
            aria-label={String(_value)}
            value={String(_value)}
            size="large"
          >
            <Icons.star />
          </IconButton>
        ))}
    </Box>
  );
}
