/**
 * @generated SignedSource<<51f4e6ca2b010b3e027e381fa86a1703>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateWeekInput = {
  clientMutationId?: string | null | undefined;
  description?: string | null | undefined;
  programId: string;
  week: number;
};
export type WeekDrawerMutation$variables = {
  input: UpdateWeekInput;
};
export type WeekDrawerMutation$data = {
  readonly updateWeek: {
    readonly week: {
      readonly " $fragmentSpreads": FragmentRefs<"WeekDrawer_week">;
    } | null | undefined;
  } | null | undefined;
};
export type WeekDrawerMutation = {
  response: WeekDrawerMutation$data;
  variables: WeekDrawerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WeekDrawerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeekPayload",
        "kind": "LinkedField",
        "name": "updateWeek",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WeekDrawer_week"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WeekDrawerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWeekPayload",
        "kind": "LinkedField",
        "name": "updateWeek",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Week",
            "kind": "LinkedField",
            "name": "week",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0194e5e5ce0faeaaf74c84e90750e524",
    "id": null,
    "metadata": {},
    "name": "WeekDrawerMutation",
    "operationKind": "mutation",
    "text": "mutation WeekDrawerMutation(\n  $input: UpdateWeekInput!\n) {\n  updateWeek(input: $input) {\n    week {\n      ...WeekDrawer_week\n      id\n    }\n  }\n}\n\nfragment WeekDrawer_week on Week {\n  description\n}\n"
  }
};
})();

(node as any).hash = "d88f46032f5106d2292b6b8944c7b26c";

export default node;
