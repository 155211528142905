import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import FileUploader, { FileInfo } from "../../other/FileUploader";
import { ProgressPhotoView } from "./ProgressPhotoView";

const INIT_VALUES = {
  front: null,
  side: null,
  back: null,
};

export type ProgressPhotoView = "front" | "side" | "back";

export type ProgressImageInfo = {
  url: string;
  fileName: string;
  fileSize: number;
};

const options: Record<ProgressPhotoView, string> = {
  front: "Front view",
  side: "Side view",
  back: "Back view",
};

export type ProgressPhotos = Record<
  ProgressPhotoView,
  ProgressImageInfo | null
>;

export type CheckInAnswerProgressPhotoValue = {
  photos?: ProgressPhotos;
};

export const PROGRESS_PHOTO_UPLOADER_HEIGHT = 350;

const ProgressPhotoQuestion = ({
  element,
  handleSetNode,
  view,
  disableAnswer,
}: ICheckInQuestionPropsBase<any>) => {
  const isCoachView = view === EditorElementView.Coach;
  const disabled = isCoachView || disableAnswer;
  const theme = useTheme();

  const answer = element.answer as CheckInAnswerProgressPhotoValue;
  const photos = answer?.photos;

  const onFileUpload = (file: FileInfo, key: string) => {
    const newPhotos = {
      ...photos,
      [key]: {
        url: file.url,
        fileName: file.name,
        fileSize: file.size,
      },
    };

    handleSetNode({ answer: { photos: newPhotos } });
  };

  const onFileDelete = (key: string) => {
    const newPhotos = {
      ...photos,
      [key]: null,
    };
    handleSetNode({ answer: { photos: newPhotos } });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        {Object.keys(options).map((key, index) => (
          <Grid
            item
            key={index}
            display={"flex"}
            flexDirection={"column"}
            xs={12}
            sm={4}
          >
            <Typography textAlign="center">{options[key]}</Typography>
            <DisabledCheckInTooltip visible={isCoachView}>
              <Box height={PROGRESS_PHOTO_UPLOADER_HEIGHT}>
                <FileUploader
                  onlyImages
                  previewBox={ProgressPhotoView}
                  readonly={view === EditorElementView.Review}
                  file={
                    photos?.[key] && {
                      name: photos?.[key]?.fileName || "",
                      url: photos?.[key]?.url || "",
                      size: photos?.[key]?.fileSize || 0,
                      type: "image",
                    }
                  }
                  onFileUpload={(file) => onFileUpload(file, key)}
                  onFileDelete={() => onFileDelete(key)}
                  disabled={disabled}
                />
              </Box>
            </DisabledCheckInTooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProgressPhotoQuestion;
