/**
 * @generated SignedSource<<092c1e502c86e404f9594f0544f4abd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramDefaultView = "CALENDAR" | "LIST" | "SPREADSHEET" | "%future added value";
export type CreateProgramInput = {
  clientMutationId?: string | null | undefined;
  defaultView?: ProgramDefaultView | null | undefined;
  description?: string | null | undefined;
  image?: string | null | undefined;
  length?: number | null | undefined;
  name?: string | null | undefined;
};
export type CreateProgramDialogMutation$variables = {
  input: CreateProgramInput;
};
export type CreateProgramDialogMutation$data = {
  readonly createProgram: {
    readonly program: {
      readonly id: string;
      readonly slug: string;
      readonly weeks: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"CoachProgramCard_program">;
    } | null | undefined;
  } | null | undefined;
};
export type CreateProgramDialogMutation = {
  response: CreateProgramDialogMutation$data;
  variables: CreateProgramDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "after",
      "value": ""
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "WeeksConnection",
  "kind": "LinkedField",
  "name": "weeks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WeeksEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Week",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "weeks(after:\"\",first:1)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProgramDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProgramPayload",
        "kind": "LinkedField",
        "name": "createProgram",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CoachProgramCard_program"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProgramDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProgramPayload",
        "kind": "LinkedField",
        "name": "createProgram",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProgramFolder",
                "kind": "LinkedField",
                "name": "folder",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "length",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": "createdAtFromNow",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "fromNow"
                  }
                ],
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": "createdAt(format:\"fromNow\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enrolledTotal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoURL",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "331a10f49cd9bd6149e09605de91f84b",
    "id": null,
    "metadata": {},
    "name": "CreateProgramDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateProgramDialogMutation(\n  $input: CreateProgramInput!\n) {\n  createProgram(input: $input) {\n    program {\n      ...CoachProgramCard_program\n      id\n      slug\n      weeks(first: 1, after: \"\") {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CoachProgramCard_program on Program {\n  ...CoachProgramMenu_program\n  id\n  slug\n  name\n  description\n  length\n  status\n  createdAt\n  createdAtFromNow: createdAt(format: \"fromNow\")\n  imageURL\n  enrolledTotal\n  clients {\n    ...StackedClientsAvatars_clients\n    id\n  }\n}\n\nfragment CoachProgramMenu_program on Program {\n  id\n  status\n  folder {\n    id\n  }\n}\n\nfragment StackedClientsAvatars_clients on User {\n  id\n  displayName\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "6c9d0252c62641cfd69da8eaeaf5b507";

export default node;
