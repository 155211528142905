import clsx from "clsx";
import React from "react";
import {
  Card,
  CardHeader,
  CardActionArea,
  IconButton,
  CardProps,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { graphql, useFragment } from "react-relay";
import { ConnectionHandler } from "relay-runtime";

import { UserRole } from "../../constants";
import { CardAvatar } from "../card/CardAvatar";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Link } from "../link/Link";

import { ThreadMenu } from "./ThreadMenu";
import { ThreadCard_thread$key } from "./__generated__/ThreadCard_thread.graphql";
import { useUpdateThreadMutation } from "./mutations/UpdateThread";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.quote,
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",

    "&:hover": {
      background: theme.palette.selected.main,
      "& $more": {
        visibility: "visible",
      },
      "& $unread": {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
    },
  },
  selected: {
    background: theme.palette.background.paper,
  },

  header: {
    padding: theme.spacing(3, 3, 3, 0),
    position: "relative",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 3, 3, 1),
    },
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.1,
    overflow: "hidden",
    marginRight: theme.spacing(8.25),
    textOverflow: "ellipsis",
  },

  time: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(4),
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 600,

    [theme.breakpoints.up("md")]: {
      right: theme.spacing(3),
    },
  },

  avatar: {
    marginRight: 0,
    width: 39,
    height: 39,
    boxShadow: theme.shadows[1],
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "transparent",

    "$selected &": {
      borderColor: theme.palette.primary.main,
    },

    [theme.breakpoints.up("md")]: {
      width: 56,
      height: 56,
      marginLeft: theme.spacing(1),
    },
  },

  more: {
    position: "absolute",
    right: 0,
    top: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),

    [theme.breakpoints.up("md")]: {
      right: theme.spacing(2),
      top: theme.spacing(6.25),
      visibility: "hidden",
    },
  },

  unread: {
    position: "absolute",
    background: theme.palette.primary.main,
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: "50%",
    top: `calc(50% + ${theme.spacing(1.25)})`,
    right: theme.spacing(2),
  },

  content: {
    overflow: "hidden",
  },

  subheader: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: theme.spacing(4),
  },
}));

const threadFragment = graphql`
  fragment ThreadCard_thread on Thread {
    id
    content
    formattedDate: updatedAt(format: "D MMM")
    read
    slug
    author {
      id
      displayName(raw: true)
      photoURL
    }
    group {
      name
    }
  }
`;

export interface ThreadCardProps extends CardProps {
  thread: ThreadCard_thread$key;
  selected?: boolean;
}

export function ThreadCard(props: ThreadCardProps) {
  const { className, thread: threadRef, selected, ...other } = props;
  const thread = useFragment(threadFragment, threadRef);
  const s = useStyles();
  const { id, read, content, formattedDate, author, group } = thread;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [updateThread, updateThreadInFlight] = useUpdateThreadMutation();

  const { role } = useCurrentUser();
  const href = `${role === UserRole.COACH ? "/coach" : ""}/messages/${
    thread.slug
  }`;

  const handleMoreClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    return false;
  };

  const handleMoreClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleArchiveStoreUpdater = React.useCallback(
    (store) => {
      const threads = ConnectionHandler.getConnection(
        store.getRoot(),
        "Threads_threads",
      );

      ConnectionHandler.deleteNode(threads, id);
    },
    [id],
  );

  const handleToggleArchive = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      handleMoreClose();
      updateThread({
        variables: {
          input: { id, archived: true },
        },
        updater: toggleArchiveStoreUpdater,
        optimisticUpdater: toggleArchiveStoreUpdater,
      });
    },
    [handleMoreClose, updateThread, id, toggleArchiveStoreUpdater],
  );

  const setIsRead = React.useCallback(
    (read: boolean) => {
      handleMoreClose();
      updateThread({
        variables: {
          input: { id, read },
        },
        optimisticResponse: {
          updateThread: {
            thread: {
              ...thread,
              read,
            },
          },
        },
      });
    },
    [handleMoreClose, updateThread, id, thread],
  );

  const handleToggleRead = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsRead(!read);
    },
    [setIsRead, read],
  );

  const threadName = author?.displayName || group?.name || "";
  const photoURL = author?.photoURL;

  return (
    <Card className={clsx(s.root, selected && s.selected)} {...other}>
      <CardActionArea
        component={Link}
        color="textPrimary"
        underline="none"
        href={href}
      >
        <CardHeader
          classes={{
            root: s.header,
            title: s.title,
            content: s.content,
          }}
          avatar={
            <CardAvatar alt={threadName} src={photoURL} className={s.avatar}>
              {threadName.substring(0, 1)}
            </CardAvatar>
          }
          title={
            <React.Fragment>
              <Typography component="div" className={s.time}>
                {formattedDate}
              </Typography>
              {threadName}
            </React.Fragment>
          }
          titleTypographyProps={{ variant: "subtitle1" }}
          subheader={content}
          subheaderTypographyProps={{
            className: s.subheader,
            variant: "subtitle2",
          }}
          action={
            <React.Fragment>
              <IconButton
                aria-label="settings"
                className={s.more}
                onClick={handleMoreClick}
                size="large"
              >
                <MoreHorizIcon />
              </IconButton>
              <ThreadMenu
                thread={thread}
                open={open}
                onClose={handleMoreClose}
                anchorEl={anchorEl}
                handleToggleArchive={handleToggleArchive}
                handleToggleRead={handleToggleRead}
                disabled={updateThreadInFlight}
              />
              {!thread.read && <div className={s.unread} />}
            </React.Fragment>
          }
        />
      </CardActionArea>
    </Card>
  );
}
