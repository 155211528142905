import React from "react";
import { Check as CheckIcon } from "@mui/icons-material";
import { MenuItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";

import { Menu, MenuProps } from "../menu/Menu";

import { CoachProgramMenu_program$data } from "./__generated__/CoachProgramMenu_program.graphql";
import { CoachProgramFoldersMenuQuery } from "./__generated__/CoachProgramFoldersMenuQuery.graphql";
import { useUpdateProgramMutation } from "../program-settings/mutations/UpdateProgram";

const useStyles = makeStyles((theme) => ({
  primaryText: {
    fontWeight: 500,
  },
  secondaryAction: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface CoachProgramFoldersMenuProps extends MenuProps {
  program: CoachProgramMenu_program$data;
  onUpdated?: (folderId: string | null) => void;
}

export const CoachProgramFoldersMenu: React.FC<CoachProgramFoldersMenuProps> = (
  props,
) => {
  const s = useStyles();
  const { program, onUpdated, onClose, ...other } = props;
  const { id: programId, folder } = program;
  const [updateProgram] = useUpdateProgramMutation();

  const handleSetProgramFolder = React.useCallback(
    (event, folderId: string | null) => {
      updateProgram({
        variables: {
          input: {
            id: programId,
            folderId,
          },
        },
      });

      onClose?.(event, "backdropClick");
      onUpdated(folderId);
    },
    [updateProgram, programId, onClose, onUpdated],
  );

  const { programFolders } = useLazyLoadQuery<CoachProgramFoldersMenuQuery>(
    graphql`
      query CoachProgramFoldersMenuQuery {
        programFolders {
          id
          name
        }
      }
    `,
    {},
    {
      fetchPolicy: "store-or-network",
    },
  );

  return (
    <Menu
      MenuListProps={{ dense: true }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      {...other}
    >
      {[
        {
          id: null,
          name: "Ungrouped",
        },
        ...programFolders,
      ].map((programFolder) => (
        <MenuItem
          key={programFolder.id}
          onClick={(event) => {
            handleSetProgramFolder(event, programFolder.id);
          }}
          dense
        >
          <ListItemText
            primary={programFolder.name}
            classes={{ primary: s.primaryText }}
          />
          {(folder?.id || null) === programFolder.id && (
            <ListItemSecondaryAction className={s.secondaryAction}>
              <CheckIcon />
            </ListItemSecondaryAction>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};
