import { NavigationItem } from "../../../../constants";
import {
  CLIENT_SETTING_NOTIFICATIONS_ROUTE,
  CLIENT_SETTING_PROFILE_ROUTE,
} from "../../../routes";

export const clientSettingsNavigation: NavigationItem[] = [
  {
    name: "Profile",
    link: CLIENT_SETTING_PROFILE_ROUTE,
  },
  {
    name: "Notifications",
    link: CLIENT_SETTING_NOTIFICATIONS_ROUTE,
  },
];
