import React, { Suspense } from "react";
import { Outlet, useParams } from "react-router-dom";
import { PageSkeleton } from "../../components/loading/PageSkeleton";
import AdminLayout from "../../components/app/AdminLayout";
import { TableSkeleton } from "../../components/loading/TableSkeleton";

const AdminWrapper = () => {
  const { username } = useParams();
  return (
    <>
      <AdminLayout
        title={!username && "Users"}
        breadcrumbs={username && [{ name: "Users", link: "/admin/users" }]}
      >
        <Suspense fallback={<TableSkeleton rowsPerPage={5} />}>
          <Outlet />
        </Suspense>
      </AdminLayout>
    </>
  );
};

export default AdminWrapper;
