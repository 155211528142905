/**
 * @generated SignedSource<<16840c6118aae510c701398803fa450f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupPostCommentsListItemWithReplies_comment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsListItem_comment">;
  readonly " $fragmentType": "GroupPostCommentsListItemWithReplies_comment";
};
export type GroupPostCommentsListItemWithReplies_comment$key = {
  readonly " $data"?: GroupPostCommentsListItemWithReplies_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupPostCommentsListItemWithReplies_comment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupPostCommentsListItemWithReplies_comment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "fetchReplies",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "GroupPostCommentsListItem_comment"
    }
  ],
  "type": "GroupPostComment",
  "abstractKey": null
};

(node as any).hash = "ff7e9254e56f2fb1c2468958d4ec0789";

export default node;
