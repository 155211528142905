import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { Breakpoint, SxProps } from "@mui/material";

interface CommonSuspenseWrapperProps {
  maxWidth?: Breakpoint;
  sx?: SxProps;
}

const CommonSuspenseWrapper = ({
  maxWidth,
  sx,
}: CommonSuspenseWrapperProps) => {
  return (
    <>
      <Suspense
        fallback={
          <PageSkeleton
            {...(maxWidth
              ? { maxWidth }
              : {
                  fullWidth: true,
                })}
            style={{ marginBlock: 16, paddingInline: 24 }}
            sx={sx}
          />
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
};

export default CommonSuspenseWrapper;
