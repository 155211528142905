import clsx from "clsx";
import React from "react";
import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { clipTrailingNodes } from "../editor/normalizers/withSchema";
import { BaseEditorDelayBuffer } from "../editor/components/utils/BaseEditorDelayBuffer";
import { BaseEditor } from "./BaseEditor";
import { UserNoteEditorSchema } from "./editor-configuration/schemas/UserNoteEditorSchema";
import { SchemaElements } from "./utils/withSchema";

const useStyles = makeStyles((theme) => ({
  root: {
    '& [data-slate-enter-command-button="true"]': {
      marginRight: theme.spacing(-0.5),
    },
  },
}));

// export interface NoteEditorProps
//   extends Omit<BaseEditorDelayBufferProps, "schema"> {
//   className?: string;
// }

const initialValue = [SchemaElements.getEmptyNode()];

export function NoteEditor(props: any) {
  const { delay, className, value = initialValue, ...other } = props;
  const s = useStyles();
  const readOnly = props.readOnly;
  const Editor = readOnly || !delay ? BaseEditor : BaseEditorDelayBuffer;

  return (
    <Box className={clsx(s.root, className)}>
      <Editor
        placeholder={readOnly ? "" : "Add your notes here"}
        value={readOnly ? clipTrailingNodes(value) : value}
        inlineToolbar
        sidebar
        deserialize
        preNormalize
        multiline
        autoFocus
        topToolbar
        editorSchema={UserNoteEditorSchema}
        {...other}
      />
    </Box>
  );
}

export default NoteEditor;
