import React from "react";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { uploadFile, getMimeTypes } from "../../../../utils/file";
import { useNativeDropzone } from "../../../../utils/device";
import { CloudUpload } from "@mui/icons-material";
import {
  CheckInAnswerProgressPhotoView,
  ProgressImageInfo,
} from "./CheckInAnswerProgressPhotoView";
import { AssetType } from "../../../../constants";
import { ElementType } from "../../types/elements";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.depressed,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed",
    borderColor: theme.palette.border.primary,
    padding: theme.spacing(2.75),
    borderRadius: theme.spacing(1),
    height: 300,
    width: "100%",
  },

  icon: {
    backgroundColor: theme.palette.avatar,
    color: theme.palette.selected.main,
    borderRadius: "50%",
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    padding: theme.spacing(2.25),
    marginBottom: theme.spacing(1),
  },

  text: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },

  button: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 5),
    border: "2px solid",
    borderColor: theme.palette.common.black,
    borderRadius: "6px",
    fontWeight: "bold",
  },
}));

type CheckInUploadClintPhotoProps = {
  name: string;
  value?: ProgressImageInfo;
  onChange: (name: string, value: ProgressImageInfo | null) => void;
  createUrl: any;
  id: string;
  disabled: boolean;
};

const CheckInUploadClintPhoto = (props: CheckInUploadClintPhotoProps) => {
  const { name, value, createUrl, id, onChange } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const s = useStyles();
  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: ([file], errors) => {
      if (errors.length) {
        // TODO: Inform user of errors using common component
        console.error(errors);
        return;
      } else if (!file) {
        return;
      }

      setUploading(true);

      createUrl({
        variables: {
          id,
          file: file.name,
          type: AssetType.PROGRAM_CHECKIN_PHOTO,
        },
        onCompleted: ({ createSignedUrl }: any, errors) => {
          if (errors) {
            setUploading(false);
            // TODO: Inform user of errors using common component
          } else {
            // TODO: Consider deleting the signed URL and uploaded file on change or element removal
            const { url } = createSignedUrl;

            uploadFile(url, file)
              .then(() => {
                const urlInstance = new URL(
                  `https:/${url.substr(url.indexOf("/", 8))}`,
                );
                urlInstance.search = "";
                const uploadUrl = String(urlInstance);

                onChange(name, {
                  url: uploadUrl,
                  fileName: file.name,
                  fileSize: file.size,
                });
              })
              .catch((err) => {
                // TODO: Inform user of errors using common component
                console.error(err);
              })
              .finally(() => {
                setUploading(false);
              });
          }
        },
        onError: (err) => {
          setUploading(false);
          // TODO: Inform user of errors using common component
          console.error(err);
        },
      });
    },
    accept: getMimeTypes(ElementType.IMAGE),
  });

  const handleRemove = React.useCallback(() => {
    onChange(name, null);
  }, [name, onChange]);

  const renderDND = () => (
    <>
      <Box className={s.icon}>
        <CloudUpload />
      </Box>
      <Typography className={s.text} variant="body2">
        Drag and drop here
      </Typography>
      <Typography className={s.text} variant="body2">
        or
      </Typography>
      <Button className={s.button} variant="outlined" disabled={uploading}>
        {uploading ? "Uploading…" : "Upload"}
      </Button>
      <input disabled={uploading} type="file" {...getInputProps()} />
    </>
  );

  const renderImage = () => (
    <CheckInAnswerProgressPhotoView image={value} onRemove={handleRemove} />
  );

  return (
    <Box className={s.root} {...getRootProps({ ref })}>
      {value ? renderImage() : renderDND()}
    </Box>
  );
};

export default CheckInUploadClintPhoto;
