import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import notes from "../../icons/notes.svg";
import { UserNoteDialog } from "../user-notes/UserNoteDialog";
import { UserNotesList } from "../user-notes/UserNotesList";
import { InfoBox } from "../info/InfoBox";
import { UnsplashContext } from "../../hooks/useUnsplash";

import { CoachClientNotesScreen_root$key } from "./__generated__/CoachClientNotesScreen_root.graphql";
import NoNotes from "../../icons/NoNotes";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.slg,
  },

  empty: {
    maxWidth: 444,
    margin: theme.spacing(12, "auto", 0),
  },

  emptyButton: {
    width: "100%",
    marginTop: theme.spacing(3),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    padding: theme.spacing(2.25),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  button: {
    fontWeight: 700,
    fontSize: 16,
    borderRadius: 4,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.5, 8),
    },
  },
}));

const rootFragment = graphql`
  fragment CoachClientNotesScreen_root on Root
  @argumentDefinitions(
    username: { type: "String!" }
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
  ) {
    ...UnsplashImages_rootRef
    client: user(username: $username) {
      id
      notes(first: $first, after: $after)
        @connection(key: "CoachClientNotesScreen_notes") {
        edges {
          cursor
        }
      }
      ...UserNotesList_user
    }
  }
`;

export interface CoachClientNotesScreenProps
  extends Omit<ContainerProps, "children"> {
  root: CoachClientNotesScreen_root$key;
}

export function CoachClientNotesScreen(props: CoachClientNotesScreenProps) {
  const { className, root: rootRef, ...other } = props;
  const root = useFragment(rootFragment, rootRef);
  const { client } = root;
  const s = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleNewNoteClick = React.useCallback(() => setOpenDialog(true), []);
  const handleDialogClose = React.useCallback(() => setOpenDialog(false), []);
  const theme = useTheme();

  return (
    <UnsplashContext.Provider value={root}>
      <Container className={clsx(s.root, className)} {...other}>
        {client?.notes?.edges.length ? (
          <Box className={s.header}>
            <Typography className={s.title} variant="h4">
              Notes
            </Typography>
            <Button
              className={s.button}
              variant="contained"
              onClick={handleNewNoteClick}
            >
              New note
            </Button>
          </Box>
        ) : (
          <InfoBox
            className={s.empty}
            image={<NoNotes fill={theme.palette.primary.main} />}
            title="Add a client note"
          >
            <Button
              className={s.emptyButton}
              variant="contained"
              onClick={handleNewNoteClick}
              children="New note"
            />
          </InfoBox>
        )}

        <UserNotesList user={client} />

        {openDialog && (
          <UserNoteDialog
            open={openDialog}
            userId={client.id}
            onClose={handleDialogClose}
          />
        )}
      </Container>
    </UnsplashContext.Provider>
  );
}
