import clsx from "clsx";
import React from "react";
import { DialogProps, Box, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { getISODate } from "../../utils/date";
import { TimeInput } from "../fields/TimeInput";
import { ActionButton } from "../button/ActionButton";

import { BaseDialog } from "./BaseDialog";
import { Dayjs } from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 368,
  },

  divider: {
    margin: theme.spacing(3, 0),
  },

  buttons: {
    marginTop: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  doneButton: {
    color: theme.palette.primary.main,
  },
}));

export interface DateTimeDialogProps extends Omit<DialogProps, "onChange"> {
  date?: string;
  time?: string;
  onConfirm?: (date: string, time: string) => void;
  maxDate?: Dayjs;
  minDate?: Dayjs;
}

export function DateTimeDialog(props: DateTimeDialogProps) {
  const {
    className,
    date: initialDate = getISODate(),
    time: initialTime = "12:00",
    onConfirm,
    onClose,
    maxDate,
    minDate,
    ...other
  } = props;
  const s = useStyles();

  const [date, setDate] = React.useState<string>(initialDate);
  const [time, setTime] = React.useState<string>(initialTime);

  const handleClose = React.useCallback(() => {
    setDate(initialDate);
    setTime(initialTime);
    onClose({}, "backdropClick");
  }, [initialDate, initialTime, onClose]);

  const handleDateChange = React.useCallback((date) => {
    setDate(date);
  }, []);

  const handleTimeChange = React.useCallback((event) => {
    setTime(event.target.value);
  }, []);

  const handleCancelClick = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleConfirmClick = React.useCallback(() => {
    if (onConfirm) {
      onConfirm(getISODate(date), time);
    }

    onClose({}, "backdropClick");
  }, [date, onClose, onConfirm, time]);

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      showClose={false}
      classes={{
        paper: s.paper,
      }}
      onClose={handleClose}
      {...other}
    >
      <DatePicker
        value={new Date(date)}
        onChange={handleDateChange}
        maxDate={maxDate}
        minDate={minDate}
      />

      <Divider className={s.divider} />

      <TimeInput fullWidth value={time} onChange={handleTimeChange} />

      <Box className={s.buttons}>
        <ActionButton
          variant="text"
          children="Cancel"
          onClick={handleCancelClick}
        />
        <ActionButton
          className={s.doneButton}
          variant="text"
          children="Done"
          onClick={handleConfirmClick}
        />
      </Box>
    </BaseDialog>
  );
}
