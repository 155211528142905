import clsx from "clsx";
import React from "react";
import { BoxProps, Box, debounce } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDrag } from "react-dnd";

import { WEEK_DRAG_ITEM_TYPE } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5, 0),
    display: "flex",
    height: 90,

    ".dragging &": {
      position: "relative",
      zIndex: 2,
      pointerEvents: "none",
    },
  },

  resizable: {},

  content: {
    cursor: "move",
    width: "100%",
    position: "relative",
    zIndex: theme.zIndex.mobileStepper,
  },

  placeholder: {
    borderColor: theme.palette.primary.main,
  },

  resize: {
    position: "relative",
    zIndex: 2,
    width: 5,
    minHeight: "100%",
    opacity: 0,
    marginTop: 1,

    "$resizable &": {
      cursor: "ew-resize",
    },
  },
}));

export interface ProgramCalendarComponentContainerProps extends BoxProps {
  span: number;
  componentId: string;
  week: number;
  day: number;
  disableResize?: boolean;
}

const setDragging = debounce((dragging: boolean) => {
  if (dragging) {
    document.body.classList.add("dragging");
  } else {
    document.body.classList.remove("dragging");
  }
}, 5);

export function ProgramCalendarComponentContainer(props: any) {
  const s = useStyles();
  const {
    className,
    span,
    children,
    week,
    day,
    componentId,
    disableResize,
    ...other
  } = props;

  const dragProps = React.useCallback(
    (type: WEEK_DRAG_ITEM_TYPE) => ({
      type,
      previewOptions: {
        captureDraggingState: true,
      },

      item: () => {
        setDragging(true);
        return {
          componentId,
          week,
          day,
          type,
        };
      },
      end: () => setDragging(false),
    }),
    [componentId, day, week],
  );

  const [, drag, preview] = useDrag(dragProps(WEEK_DRAG_ITEM_TYPE.COMPONENT));
  const [, resizeLeftRef] = useDrag(dragProps(WEEK_DRAG_ITEM_TYPE.RESIZE_LEFT));
  const [, resizeRightRef] = useDrag(
    dragProps(WEEK_DRAG_ITEM_TYPE.RESIZE_RIGHT),
  );
  const resizable = children && !disableResize;
  const dragRef = React.useRef<HTMLDivElement>();

  preview(drag(dragRef));

  return (
    <Box
      className={clsx(s.root, resizable && s.resizable)}
      ref={(span && dragRef) || undefined}
      style={{
        width: `calc(${Math.max(span, 1)} * (100% + 1px) - 3px)`,
      }}
      {...other}
    >
      <div
        ref={(resizable && resizeLeftRef) || undefined}
        className={s.resize}
      />
      {children && <div className={s.content}>{children}</div>}
      <div
        ref={(resizable && resizeRightRef) || undefined}
        className={s.resize}
      />
    </Box>
  );
}
