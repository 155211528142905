import { createContext } from "react";

export interface IContextProps {
  manageGroupDialogOpen: boolean;
  setManageGroupDialogOpen: (data: boolean) => void;
}

const ManageGroupDialogContext = createContext<IContextProps | undefined>(
  undefined,
);

export default ManageGroupDialogContext;
