import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";

import { GroupNavItemListItem$key } from "./__generated__/GroupNavItemListItem.graphql";
import { useLocation, useNavigate } from "react-router-dom";
import { COACH_ROUTE, GROUPS_ROUTE } from "../../routes/routes";
import { useDrawer } from "../../hooks/useDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.text.secondary,
    display: "block",
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(0.5),
    textTransform: "uppercase",
    margin: theme.spacing(1, 0),

    "& span": {
      maxWidth: theme.spacing(18),
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
    },
  },

  active: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}19`,

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}40`,
    },
  },
}));

const fragment = graphql`
  fragment GroupNavItemListItem on Group {
    name
    slug
  }
`;

export interface GroupNavItemListItemProps extends ButtonProps {
  groupRef: GroupNavItemListItem$key;
}

export function GroupNavItemListItem(props: GroupNavItemListItemProps) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useDrawer();
  const { className, groupRef, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const group = useFragment(fragment, groupRef);
  const location = useLocation();
  const active = location.pathname.includes(group.slug);

  const handleClick = React.useCallback(() => {
    navigate(
      `${user.role === UserRole.COACH ? COACH_ROUTE : ""}${GROUPS_ROUTE + "/" + group.slug + "/posts"}`,
    );
    setDrawerOpen(false);
  }, [group.slug, user.role]);

  return (
    <Button
      className={clsx(s.root, className, active && s.active)}
      variant="text"
      onClick={handleClick}
      {...other}
    >
      <span>{group.name}</span>
    </Button>
  );
}
