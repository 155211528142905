import React, { ReactNode, useEffect, useState } from "react";
import { Plate } from "@udecode/plate-common";
import { Editor } from "../plate-ui/editor";
import { FloatingToolbar } from "../plate-ui/floating-toolbar";
import { FloatingToolbarButtons } from "../plate-ui/floating-toolbar-buttons";
import { TooltipProvider } from "../plate-ui/tooltip";
import { Box } from "@mui/material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import makeStyles from "@mui/styles/makeStyles";
import { Schema } from "./editor-configuration/schemas/Schema";
import { createAdvancedEditorPlatePlugins } from "./editor-configuration/plate-plugins/AdvancedEditorPlugins";
import EditorToolbar from "./components/toolbars/EditorToolbar";
import { generateId } from "./utils/nodeUtil";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .slate-start-area.slate-start-area-left": {
        display: "none",
      },
    },
  };
});

interface IPlateEditorProps {
  readOnly?: boolean;
  editorData: any;
  setEditorData: React.Dispatch<any>;
  borderRadius?: number;
  children?: ReactNode | ReactNode[];
  editorSchema: Schema;
  platePlugins?: any[];
  stickyTop?: number;
}

export const PlateEditor = ({
  readOnly,
  editorData,
  setEditorData,
  borderRadius,
  children,
  editorSchema,
  platePlugins,
  stickyTop,
}: IPlateEditorProps) => {
  const s = useStyles();
  const user = useCurrentUser();
  const [plugins, setPlatePlugins] = useState<any[]>(
    platePlugins ?? createAdvancedEditorPlatePlugins(editorSchema),
  );

  useEffect(() => {
    if (!platePlugins) {
      setPlatePlugins(createAdvancedEditorPlatePlugins(editorSchema));
    }
  }, [editorSchema]);

  const DUMMY_INIT_OBJECT = [
    {
      children: [
        {
          text: "",
        },
      ],
      type: "p",
    },
  ];

  const INIT_OBJECT = [
    {
      children: [
        {
          text: "",
        },
      ],
      type: "p",
      id: generateId(),
    },
  ];

  return (
    <Box className={s.root}>
      <TooltipProvider>
        <Plate
          plugins={plugins}
          initialValue={editorData}
          onChange={(newValue) => {
            setEditorData(
              // TODO_Editor drop after find place where to set this
              isEqual(newValue, DUMMY_INIT_OBJECT) ? INIT_OBJECT : newValue,
            );
          }}
        >
          <EditorToolbar
            editorSchema={editorSchema}
            readOnly={readOnly}
            top={stickyTop}
          />

          <Editor
            readOnly={readOnly}
            style={{
              border: "none",
              padding: 0,
              overflowX: "visible",
              paddingTop: 20,
              paddingBottom: 20,
              backgroundColor: "transparent",
              // TODO_Editor drop border?
              // ...(!showToolbar && {
              //   borderRadius: "0.5rem",
              //   borderTopLeftRadius: borderRadius ?? "0.5rem",
              //   borderTopWidth: 1,
              // }),
            }}
          />
          {!readOnly && (
            <FloatingToolbar>
              <FloatingToolbarButtons
                turnIntoMenuItemGroups={editorSchema.turnIntoMenuItemGroups}
                toolbarOptions={editorSchema.toolbarOptions}
              />
            </FloatingToolbar>
          )}
          {children}
        </Plate>
      </TooltipProvider>
    </Box>
  );
};
