import { useGridCellEditor, CustomCellEditorProps } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { WorkoutSection } from "../../workout/types";
import {
  MAX_EXERCISE_SETS_COUNT,
  createEmptySet,
  isPositiveNumber,
} from "../../workout/utils";
import { isEqual } from "lodash";
import { CustomProgramCellEditorProps } from "../utils";
import { wholeNumberInputProps } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingInline: theme.spacing(1.8),
    border: "none",
    outline: "none",
    width: "100%",
    textAlign: "center",
  },
}));

export default memo(
  ({
    initialValue,
    onValueChange,
    api,
    data,
    rowIndex,
    column,
    handleExerciseUpdate,
  }: CustomCellEditorProps & CustomProgramCellEditorProps) => {
    const s = useStyles();

    const inputRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState(initialValue);

    const onChange = ({ target: { value } }) => {
      setValue(value);
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const isCancelAfterEnd = useCallback(() => {
      const sanitizedValue = Number(value);

      if (initialValue === sanitizedValue) {
        return true;
      }

      if (
        isNaN(sanitizedValue) ||
        initialValue === sanitizedValue ||
        !isPositiveNumber(sanitizedValue) ||
        sanitizedValue > MAX_EXERCISE_SETS_COUNT
      ) {
        const rowNode = api!.getDisplayedRowAtIndex(rowIndex)!;
        api.flashCells({
          rowNodes: [rowNode],
          columns: [column],
        });
        return true;
      }

      onValueChange(sanitizedValue);

      const changeSetsLength = (setsArray, newLength) => {
        if (newLength <= setsArray.length) {
          return setsArray.slice(0, newLength);
        }

        const emptySetsToAdd = Array.from(
          { length: newLength - setsArray.length },
          () => createEmptySet(),
        );
        return setsArray.concat(emptySetsToAdd);
      };

      handleExerciseUpdate(
        {
          ...data.workoutSectionData,
          exercises: data.exercises.map((e) => ({
            ...e,
            sets:
              e.id === data.exerciseData.id
                ? changeSetsLength(e.sets, sanitizedValue)
                : e.sets,
          })),
        },
        data.componentContent,
        data.workoutSectionData.id,
        data.componentId,
      );

      return false;
    }, [value]);

    useGridCellEditor({
      isCancelAfterEnd,
    });

    return (
      <input
        className={s.input}
        ref={inputRef}
        type="text"
        {...wholeNumberInputProps}
        value={value}
        onChange={onChange}
      />
    );
  },
);
