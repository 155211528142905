import React from "react";

import { ConfirmChangesDialog as DefaultConfirmDialog } from "../dialog/ConfirmChangesDialog";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { SOMETHING_WENT_WRONG } from "../../constants";

import { DirtyTransactionContext, useCreateDirtyTransaction } from "./hooks";

export interface ConfirmDialogProps {
  dirty?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export interface DirtyTransactionProps {
  children: React.ReactNode;
  ConfirmDialog?: React.FC<ConfirmDialogProps>;
}

export function DirtyTransaction(props: DirtyTransactionProps) {
  const { ConfirmDialog = DefaultConfirmDialog, children } = props;
  const transaction = useCreateDirtyTransaction();
  const { dirty, confirm, cancel } = transaction;
  const snackAlert = useSnackAlert();

  const handleConfirm = React.useCallback(
    () =>
      confirm({
        onSuccess: () =>
          snackAlert({
            severity: "success",
            message: "Successfully saved all changes",
          }),
        onError: () =>
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          }),
      }),
    [confirm, snackAlert],
  );

  return (
    <DirtyTransactionContext.Provider value={transaction}>
      {children}

      <ConfirmDialog
        dirty={dirty}
        onConfirm={handleConfirm}
        onCancel={cancel}
      />
    </DirtyTransactionContext.Provider>
  );
}
