/**
 * @generated SignedSource<<41e9d1af46ed4433363a6423369f1cbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AttachmentType = "FILE" | "IMAGE" | "STICKER" | "%future added value";
export type CreateManyMessagesInput = {
  attachments?: ReadonlyArray<InputAttachment> | null | undefined;
  clientMutationId?: string | null | undefined;
  content: string;
  recipients: ReadonlyArray<string>;
};
export type InputAttachment = {
  type: AttachmentType;
  url: string;
};
export type CreateManyMessagesMutation$variables = {
  input: CreateManyMessagesInput;
};
export type CreateManyMessagesMutation$data = {
  readonly createManyMessages: {
    readonly messages: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type CreateManyMessagesMutation = {
  response: CreateManyMessagesMutation$data;
  variables: CreateManyMessagesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateManyMessagesPayload",
    "kind": "LinkedField",
    "name": "createManyMessages",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "messages",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateManyMessagesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateManyMessagesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c28c6d7e4882e5a8d0961f05981b8feb",
    "id": null,
    "metadata": {},
    "name": "CreateManyMessagesMutation",
    "operationKind": "mutation",
    "text": "mutation CreateManyMessagesMutation(\n  $input: CreateManyMessagesInput!\n) {\n  createManyMessages(input: $input) {\n    messages {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d86e033ae9fff5f1ad53907e64774e6";

export default node;
