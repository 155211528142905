import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  TextField,
  ButtonProps,
  FormControl,
  FormLabel,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton } from "../button/ActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  label: {
    color: `${theme.palette.text.secondary}`,
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  copyButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export interface CoachSettingsIntegrationProps
  extends Omit<ContainerProps, "children"> {}

export function CoachSettingsIntegration(props: CoachSettingsIntegrationProps) {
  const { className, ...other } = props;
  const s = useStyles();

  const token = localStorage.getItem("auth-token");

  const inputRef = React.useRef<HTMLInputElement>();

  const handleCopyToken: ButtonProps["onClick"] = React.useCallback(() => {
    inputRef.current.select();
    document.execCommand("copy");
    inputRef.current.blur();
  }, []);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.wrapper}>
        <FormControl component="fieldset">
          <FormLabel
            className={s.label}
            htmlFor="token"
            component="label"
            children="Auth token"
          />
          <TextField
            variant="outlined"
            fullWidth
            id="token"
            value={token}
            contentEditable={false}
            inputRef={inputRef}
          />
        </FormControl>

        <ActionButton
          className={s.copyButton}
          size="large"
          children="Copy"
          onClick={handleCopyToken}
        />
      </Box>
    </Container>
  );
}
