/**
 * @generated SignedSource<<eabecc53517f0f2e9719790ad4783bfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachClientCalendar_enrollment$data = {
  readonly activities: ReadonlyArray<{
    readonly component: {
      readonly type: ComponentType;
    } | null | undefined;
    readonly date: string | null | undefined;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CoachClientCalendarActivity_activity">;
  } | null | undefined>;
  readonly id: string;
  readonly program: {
    readonly name: string;
  };
  readonly startDate: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientCalendarEnrollment_enrollment">;
  readonly " $fragmentType": "CoachClientCalendar_enrollment";
};
export type CoachClientCalendar_enrollment$key = {
  readonly " $data"?: CoachClientCalendar_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientCalendar_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "raw",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientCalendar_enrollment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activities",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": (v1/*: any*/),
          "kind": "ScalarField",
          "name": "date",
          "storageKey": "date(raw:true)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Component",
          "kind": "LinkedField",
          "name": "component",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CoachClientCalendarActivity_activity"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CoachClientCalendarEnrollment_enrollment"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "ceaafaf03267ece86172bd198ea0bf0e";

export default node;
