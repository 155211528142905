/**
 * @generated SignedSource<<3c9937a7e40ec044608de4c59843db72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Plan = "FREE" | "PRO" | "PROMO" | "STARTER" | "UP_TO_100" | "UP_TO_15" | "UP_TO_200" | "UP_TO_30" | "UP_TO_5" | "UP_TO_50" | "UP_TO_75" | "UP_TO_INFINITY" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type CoachSettingsThemeRouteQuery$variables = Record<PropertyKey, never>;
export type CoachSettingsThemeRouteQuery$data = {
  readonly me: {
    readonly subscription: {
      readonly plan: Plan | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly workspace: {
    readonly " $fragmentSpreads": FragmentRefs<"CoachThemeSettingsScreen_workspace">;
  };
};
export type CoachSettingsThemeRouteQuery = {
  response: CoachSettingsThemeRouteQuery$data;
  variables: CoachSettingsThemeRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachSettingsThemeRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CoachThemeSettingsScreen_workspace"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoachSettingsThemeRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryColor",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3927dbc0b8af72bb8568d86a7b83b6b2",
    "id": null,
    "metadata": {},
    "name": "CoachSettingsThemeRouteQuery",
    "operationKind": "query",
    "text": "query CoachSettingsThemeRouteQuery {\n  me {\n    subscription {\n      plan\n      id\n    }\n    id\n  }\n  workspace {\n    ...CoachThemeSettingsScreen_workspace\n    id\n  }\n}\n\nfragment CoachThemeSettingsScreen_workspace on Workspace {\n  primaryColor\n}\n"
  }
};
})();

(node as any).hash = "615d5bd9a41a7c7e11d3a6c4308c8bf6";

export default node;
