import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { CoachClientResponsesRouteQuery } from "./__generated__/CoachClientResponsesRouteQuery.graphql";
import { ClientContext } from "../../../../contexts/ClientContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import { CompletionPeriod, ResponsesView } from "../../../../constants";
import { toEnum } from "../../../../utils/misc";
import { ClientResponseFilterContext } from "../../../../contexts/ClientResponseFilterContext";
import { ClientResponses } from "../../../../components/coach-clients/ClientResponses";

export function CoachClientResponsesRoute() {
  const { username } = useParams();
  const { period } = useContext(ClientResponseFilterContext);

  const props = useLazyLoadQuery<CoachClientResponsesRouteQuery>(
    graphql`
      query CoachClientResponsesRouteQuery(
        $username: String!
        $period: CompletionPeriodType
      ) {
        ...ClientResponses_root @arguments(username: $username, period: $period)

        client: user(username: $username) {
          displayName
          username
        }
      }
    `,
    {
      username: username,
      period: toEnum(period, CompletionPeriod, CompletionPeriod.LAST_7_DAYS),
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const context = useContext(ClientContext);
  context.setCoachClientQuery(props);

  return props.client && <ClientResponses root={props} />;
}
