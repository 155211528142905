import clsx from "clsx";
import React, { useContext, useState } from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Link,
  Box,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { ClientResponsesSummaryCard } from "../card/ClientResponsesSummaryCard";
import { DropdownMenu, DropdownMenuItem } from "../menu/DropdownMenu";
import { InfoBox } from "../info/InfoBox";
import {
  ResponsesView,
  CompletionPeriod,
  defaultCoachActivityFilters,
  ActivitySort,
} from "../../constants";
import { getFirstName } from "../../utils/user";
import { getISODate } from "../../utils/date";
import { toEnum } from "../../utils/misc";
import {
  ActivityTypesFilterProps,
  ActivityTypesFilter,
} from "../filters/ActivityTypesFilter";
import { PeriodFilter } from "../filters/PeriodFilter";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  ActivitySortFilter,
  ActivitySortFilterProps,
} from "../filters/ActivitySortFilter";

import { ClientResponses_root$key } from "./__generated__/ClientResponses_root.graphql";
import { ClientResponsesActivities } from "./ClientResponsesActivities";
import { ClientResponseFilterContext } from "../../contexts/ClientResponseFilterContext";
import { useQueryParams } from "../../hooks/useQueryParams";
import NoRespond from "../../icons/NoRespond";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    paddingTop: theme.spacing(5),
  },

  empty: {
    maxWidth: 648,
    margin: theme.spacing(12, "auto", 0),
  },

  card: {
    margin: theme.spacing(3, 0),
  },

  text: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.common.black,
  },

  typeLink: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3.25),
    cursor: "pointer",
    display: "block",
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
}));

const ResponsesViewLabel = {
  [ResponsesView.SUMMARY]: "Summary",
  [ResponsesView.INDIVIDUAL]: "Individual responses",
};

const rootFragment = graphql`
  fragment ClientResponses_root on Root
  @argumentDefinitions(
    username: { type: "String!" }
    period: { type: "CompletionPeriodType" }
  ) {
    client: user(username: $username) {
      username
      displayName
      createdAt
      responsesSummaries(period: $period) {
        ...ClientResponsesSummaryCard_summary @relay(plural: true)
      }
    }

    ...ClientResponsesActivities_root @arguments(username: $username)
  }
`;

export interface ClientResponsesProps extends Omit<ContainerProps, "children"> {
  root: ClientResponses_root$key;
}

export function ClientResponses(props: ClientResponsesProps) {
  const s = useStyles();
  const { className, root: rootRef, ...other } = props;
  const root = useFragment(rootFragment, rootRef);
  const { client } = root;
  const { responsesSummaries: summaries, createdAt } = client;
  const { view, setView, period, setPeriod } = useContext(
    ClientResponseFilterContext,
  );

  // TODO mealDate is always undefined because there are no meal logging notifications
  // Fix mean logging notifications and make sure that redirect to single meal logging action works
  const [queryParams, setQueryParams] = useQueryParams({
    mealDate: undefined,
  });

  const [sortedBy, setSortedBy] = useState(ActivitySort.COMPLETED_AT_DESC);

  const [filters, setFilters] = useState<ActivityTypesFilterProps["filters"]>(
    defaultCoachActivityFilters,
  );

  const handleSortChange: ActivitySortFilterProps["onChange"] =
    React.useCallback(
      (sortedBy) => {
        setSortedBy(sortedBy);
      },
      [setSortedBy],
    );

  const handleFiltersChange: ActivityTypesFilterProps["onChange"] =
    React.useCallback(
      (filters) => {
        setFilters(filters);
      },
      [setFilters],
    );

  const firstName = React.useMemo(
    () => getFirstName(client) || "client",
    [client],
  );

  const handleViewItemClick = React.useCallback(() => {
    setView(
      view === ResponsesView.SUMMARY
        ? ResponsesView.INDIVIDUAL
        : ResponsesView.SUMMARY,
    );
  }, [view, setView]);

  const handleSeeAllResponses = React.useCallback(() => {
    setQueryParams({
      view: ResponsesView.INDIVIDUAL,
      mealDate: undefined,
    });
  }, [setQueryParams]);

  const handlePeriodItemClick: DropdownMenuItem["onClick"] = React.useCallback(
    (event) => {
      const {
        currentTarget: {
          dataset: { period: newPeriod },
        },
      } = event;

      setPeriod(
        toEnum(newPeriod, CompletionPeriod, CompletionPeriod.LAST_7_DAYS),
      );
    },
    [period, setPeriod],
  );
  const theme = useTheme();
  const emptyInfoBox = React.useMemo(
    () => (
      <InfoBox
        className={s.empty}
        image={<NoRespond fill={theme.palette.primary.main} />}
        title="No responses to show"
        subtitle="Just add some check-in questions to your client’s programs. You’ll see results summarized in one convenient place."
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DropdownMenu
        header={ResponsesViewLabel[view]}
        subHeader="Switch to"
        variant="big"
        items={[
          {
            onClick: handleViewItemClick,
            children:
              view === ResponsesView.SUMMARY
                ? ResponsesViewLabel[ResponsesView.INDIVIDUAL]
                : ResponsesViewLabel[ResponsesView.SUMMARY],
          },
        ]}
      />

      {!queryParams.mealDate && (
        <Box className={s.actions}>
          {view === ResponsesView.SUMMARY && (
            <PeriodFilter
              startDate={getISODate(createdAt)}
              selectedPeriod={period}
              onPeriodChange={handlePeriodItemClick}
            />
          )}

          {view === ResponsesView.INDIVIDUAL && (
            <>
              <ActivitySortFilter
                sortedBy={sortedBy}
                onChange={handleSortChange}
              />
              <ActivityTypesFilter
                filters={filters}
                onChange={handleFiltersChange}
              />
            </>
          )}
        </Box>
      )}
      {view === ResponsesView.SUMMARY ? (
        summaries?.length ? (
          summaries.map((summary, index) => (
            <ClientResponsesSummaryCard
              key={index}
              className={s.card}
              summaryFragmentRef={summary}
            />
          ))
        ) : (
          emptyInfoBox
        )
      ) : (
        <ClientResponsesActivities
          root={view === ResponsesView.INDIVIDUAL ? root : null}
          emptyInfoBox={emptyInfoBox}
          filters={filters}
          sortedBy={sortedBy}
        >
          {queryParams.mealDate && (
            <Typography
              className={s.typeLink}
              component={Link}
              onClick={handleSeeAllResponses}
              children={`See all of ${firstName}’s responses`}
            />
          )}
        </ClientResponsesActivities>
      )}
    </Container>
  );
}
