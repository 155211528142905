import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import ScaleRating from "../../../fields/ScaleRating";
import { Box } from "@mui/material";

export type IRatingScaleQuestionAnswer = {
  rate?: number;
};

const RatingScaleQuestion = ({
  view,
  disableAnswer,
  element,
  handleSetNode,
}: ICheckInQuestionPropsBase<IRatingScaleQuestionAnswer>) => {
  const answer = element.answer as IRatingScaleQuestionAnswer;
  const handleChange = (newValue: number | null) => {
    handleSetNode({ answer: { rate: newValue } });
  };

  const isCoachView = view === EditorElementView.Coach;
  const disabled = isCoachView || disableAnswer;

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      {/* BOX needed to make tooltip work properly */}
      <Box>
        <ScaleRating
          value={answer?.rate}
          onSelect={handleChange}
          disabled={disabled}
        />
      </Box>
    </DisabledCheckInTooltip>
  );
};
export default RatingScaleQuestion;
