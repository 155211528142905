import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useUserIsClient } from "../../hooks/useCurrentUser";

import { RecipientDialogProps, RecipientDialog } from "./RecipientDialog";
import { useNavigate } from "react-router-dom";
import { COACH_MESSAGES_ROUTE, MESSAGES_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface SelectRecipientProps extends RecipientDialogProps {}

export function SelectRecipient(props: SelectRecipientProps) {
  const navigate = useNavigate();
  const { className, ...other } = props;
  const { open, onClose } = props;
  const s = useStyles();
  const isClient = useUserIsClient();

  const handleSelect = React.useCallback(
    (event, username: string) => {
      const navigationLink = isClient
        ? MESSAGES_ROUTE + `/${username}`
        : COACH_MESSAGES_ROUTE + `/${username}`;

      if (onClose) {
        onClose(event, "backdropClick");
      }

      navigate(navigationLink);
    },
    [isClient, onClose],
  );

  return (
    open && (
      <React.Suspense fallback={<></>}>
        <RecipientDialog
          className={clsx(s.root, className)}
          onSelect={handleSelect}
          {...other}
        />
      </React.Suspense>
    )
  );
}
