import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ThumbUpOutlined, ThumbUp } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid",
    borderRadius: theme.spacing(1),
    fontWeight: 600,
    fontSize: 14,
    padding: theme.spacing(0.5, 2),
    color: theme.palette.avatar,

    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      padding: theme.spacing(1.25, 7),
    },
  },

  completed: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,

    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export interface HabitCompleteButtonProps extends ButtonProps {
  completed?: boolean;
}

export function HabitCompleteButton(props: HabitCompleteButtonProps) {
  const { className, completed, disabled, ...other } = props;
  const s = useStyles();

  return (
    <Button
      className={clsx(s.root, className, completed && s.completed)}
      fullWidth
      startIcon={completed ? <ThumbUp /> : <ThumbUpOutlined />}
      variant={completed ? "contained" : "outlined"}
      disabled={disabled}
      {...other}
    >
      {completed ? "Completed" : "Mark Complete"}
    </Button>
  );
}
