/**
 * @generated SignedSource<<dcb3f93518a2ce77d88f67a7a10dccf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramProgressCard_enrollment$data = {
  readonly currentDay: number | null | undefined;
  readonly endDate: string | null | undefined;
  readonly program: {
    readonly imageURL: string | null | undefined;
    readonly name: string;
    readonly slug: string;
  };
  readonly totalDays: number;
  readonly " $fragmentSpreads": FragmentRefs<"ClientLast7Days_enrollment" | "ClientProgramActivity_enrollment">;
  readonly " $fragmentType": "ClientProgramProgressCard_enrollment";
};
export type ClientProgramProgressCard_enrollment$key = {
  readonly " $data"?: ClientProgramProgressCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProgramProgressCard_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientProgramProgressCard_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM DD, YYYY"
        },
        {
          "kind": "Literal",
          "name": "utc",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": "endDate(format:\"MMM DD, YYYY\",utc:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientProgramActivity_enrollment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientLast7Days_enrollment"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "8953047caaa37afd7445c76126a966f4";

export default node;
