/**
 * @generated SignedSource<<67724e2ad20fbbd1ca005da636fac013>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClientAutoNutritionDetailsRefetchUserQuery$variables = {
  date: string;
  username: string;
};
export type ClientAutoNutritionDetailsRefetchUserQuery$data = {
  readonly user: {
    readonly autoNutritionLastSync: string | null | undefined;
    readonly nutritionRecord: {
      readonly alcohol: number | null | undefined;
      readonly calories: number | null | undefined;
      readonly carbs: number | null | undefined;
      readonly cholesterol: number | null | undefined;
      readonly fat: number | null | undefined;
      readonly fiber: number | null | undefined;
      readonly protein: number | null | undefined;
      readonly sodium: number | null | undefined;
      readonly sugar: number | null | undefined;
      readonly transFat: number | null | undefined;
    } | null | undefined;
    readonly nutritionRecordHistory: ReadonlyArray<{
      readonly date: string | null | undefined;
    }>;
  } | null | undefined;
};
export type ClientAutoNutritionDetailsRefetchUserQuery = {
  response: ClientAutoNutritionDetailsRefetchUserQuery$data;
  variables: ClientAutoNutritionDetailsRefetchUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "date",
      "variableName": "date"
    }
  ],
  "concreteType": "NutritionRecord",
  "kind": "LinkedField",
  "name": "nutritionRecord",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carbs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protein",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cholesterol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fiber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sugar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sodium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transFat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alcohol",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "h:mm A, MMM DD, YYYY"
    }
  ],
  "kind": "ScalarField",
  "name": "autoNutritionLastSync",
  "storageKey": "autoNutritionLastSync(format:\"h:mm A, MMM DD, YYYY\")"
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "NutritionRecord",
  "kind": "LinkedField",
  "name": "nutritionRecordHistory",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientAutoNutritionDetailsRefetchUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientAutoNutritionDetailsRefetchUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72fd1c321860219c2a0a295d5adf9811",
    "id": null,
    "metadata": {},
    "name": "ClientAutoNutritionDetailsRefetchUserQuery",
    "operationKind": "query",
    "text": "query ClientAutoNutritionDetailsRefetchUserQuery(\n  $date: String!\n  $username: String!\n) {\n  user(username: $username) {\n    nutritionRecord(date: $date) {\n      calories\n      carbs\n      fat\n      protein\n      cholesterol\n      fiber\n      sugar\n      sodium\n      transFat\n      alcohol\n    }\n    autoNutritionLastSync(format: \"h:mm A, MMM DD, YYYY\")\n    nutritionRecordHistory {\n      date(raw: true)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "affa78b7605e9249626fa39d936a5dc2";

export default node;
