/**
 * @generated SignedSource<<0b44b8940923ead9dc940f26e6d35602>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachProgramsFoldersScreen_root$data = {
  readonly programFolders: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProgramFolderCard_folder">;
  }>;
  readonly " $fragmentType": "CoachProgramsFoldersScreen_root";
};
export type CoachProgramsFoldersScreen_root$key = {
  readonly " $data"?: CoachProgramsFoldersScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachProgramsFoldersScreen_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./CoachProgramsFoldersScreenRefetchQuery.graphql')
    }
  },
  "name": "CoachProgramsFoldersScreen_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgramFolder",
      "kind": "LinkedField",
      "name": "programFolders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgramFolderCard_folder"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "8c90bbc3f60a3a7fd4a2a20ae40900e2";

export default node;
