import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachSettingsIntegration } from "../../../../components/settings/CoachSettingsIntegration";
import { CoachSettingsIntegrationRouteQuery } from "./__generated__/CoachSettingsIntegrationRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachSettingsIntegrationRoute() {
  const props = useLazyLoadQuery<CoachSettingsIntegrationRouteQuery>(
    graphql`
      query CoachSettingsIntegrationRouteQuery {
        me {
          subscription {
            plan
          }
        }
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { me } = props;

  return (
    <>
      <CoachSettingsIntegration />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Integration",
        }}
      />
    </>
  );
}
