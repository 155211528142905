import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { GroupManageMembersButton } from "../../../../components/group-members/GroupManageMembersButton";
import { GroupMembersScreen } from "../../../../components/group-members/GroupMembersScreen";
import { CoachGroupMembersRouteQuery } from "./__generated__/CoachGroupMembersRouteQuery.graphql";
import GroupContext from "../../../../contexts/GroupContext";
import { useCurrentUserRole } from "../../../../hooks/useCurrentUser";
import { UserRole } from "../../../../constants";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachGroupMembersRoute() {
  const USER_ROLE = useCurrentUserRole();
  const isCoach = USER_ROLE === UserRole.COACH;

  const { slug } = useParams();

  const context = useContext(GroupContext);

  const props = useLazyLoadQuery<CoachGroupMembersRouteQuery>(
    graphql`
      query CoachGroupMembersRouteQuery($slug: String!) {
        ...GroupMembersScreen_rootRef @arguments(slug: $slug)
        ...GroupManageMembersButton_root @arguments(slug: $slug)

        group(slug: $slug) {
          id
          name
          slug
          membersCount
          membersCanNotPost
          coachId
        }

        me {
          id
          role
        }
      }
    `,
    {
      slug: slug,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  context.setGroupRouteQuery(props);
  const { group, me } = props;

  return (
    <>
      {isCoach && (
        <GroupManageMembersButton root={props} children="Manage members" />
      )}
      <GroupMembersScreen rootRef={props} />
      <TrackInfoTool
        trackInfo={{
          name: `${
            me?.role === UserRole.COACH ? "Coach" : "Client"
          } - Group Members`,
          properties: {
            groupSlug: group.slug,
            groupName: group.name,
          },
        }}
      />
    </>
  );
}
