/**
 * @generated SignedSource<<fc16a2456d9239d480f2e4443aeeb332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachProgramsFoldersScreenRefetchQuery$variables = Record<PropertyKey, never>;
export type CoachProgramsFoldersScreenRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CoachProgramsFoldersScreen_root">;
};
export type CoachProgramsFoldersScreenRefetchQuery = {
  response: CoachProgramsFoldersScreenRefetchQuery$data;
  variables: CoachProgramsFoldersScreenRefetchQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramsFoldersScreenRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CoachProgramsFoldersScreen_root"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoachProgramsFoldersScreenRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramFolder",
        "kind": "LinkedField",
        "name": "programFolders",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "149aded3edc4f55530455d4388c20719",
    "id": null,
    "metadata": {},
    "name": "CoachProgramsFoldersScreenRefetchQuery",
    "operationKind": "query",
    "text": "query CoachProgramsFoldersScreenRefetchQuery {\n  ...CoachProgramsFoldersScreen_root\n}\n\nfragment CoachProgramsFoldersScreen_root on Root {\n  programFolders {\n    id\n    name\n    ...ProgramFolderCard_folder\n  }\n}\n\nfragment ProgramFolderCard_folder on ProgramFolder {\n  id\n  name\n}\n"
  }
};

(node as any).hash = "8c90bbc3f60a3a7fd4a2a20ae40900e2";

export default node;
