/**
 * @generated SignedSource<<87a4062d50dc65a1eb2541d790a57a34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteDialog_inviteCode$data = {
  readonly code: string;
  readonly " $fragmentSpreads": FragmentRefs<"InviteCodePreviewDialog_inviteCode" | "InviteLinkSettings_inviteCode">;
  readonly " $fragmentType": "InviteDialog_inviteCode";
};
export type InviteDialog_inviteCode$key = {
  readonly " $data"?: InviteDialog_inviteCode$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteDialog_inviteCode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteDialog_inviteCode",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteLinkSettings_inviteCode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteCodePreviewDialog_inviteCode"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "type": "InviteCode",
  "abstractKey": null
};

(node as any).hash = "b73cd4c9913fbeb0f6054f705e9e6efa";

export default node;
