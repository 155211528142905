import React, { FC } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import {
  Check as CheckIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";

import { TerraConnectButtonWidgetSessionQuery } from "./__generated__/TerraConnectButtonWidgetSessionQuery.graphql";

export interface TerraConnectProps extends ButtonProps<"a"> {
  connected: boolean;
}

const TerraConnectButton: FC<TerraConnectProps> = (props) => {
  const { connected, children, ...restProps } = props;

  const { terraWidgetSession } =
    useLazyLoadQuery<TerraConnectButtonWidgetSessionQuery>(
      graphql`
        query TerraConnectButtonWidgetSessionQuery {
          terraWidgetSession
        }
      `,
      {},
    );

  return (
    <Button
      fullWidth
      variant="contained"
      size="large"
      startIcon={
        terraWidgetSession ? (
          connected && <CheckIcon />
        ) : (
          <CircularProgress size={16} />
        )
      }
      endIcon={<OpenInNewIcon />}
      disabled={!terraWidgetSession}
      href={terraWidgetSession}
      target="_blank"
      rel="noopener noreferrer"
      {...restProps}
    >
      {connected ? "Tracker Connected" : "Connect Tracker"}
      {children}
    </Button>
  );
};

export default TerraConnectButton;
