import clsx from "clsx";
import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RenderElementProps } from "slate-react";
import { Node } from "slate";

import { colorSystem } from "../../../../theme";
import { useReadOnly } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2.5),
    minWidth: 100,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderBottomColor: colorSystem.gray7,

    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
  },

  empty: {
    position: "relative",
    "&::before": {
      position: "absolute",
      color: colorSystem.gray7,
      content: '"Title"',
    },
  },
}));

export interface TableHeadCellElementProps extends RenderElementProps {}

export const ELEMENT_TABLE_HEAD_CELL = "table_head_cell";

export function TableHeadCellElement(props: any) {
  const s = useStyles();
  const { element, children, attributes } = props;
  const readOnly = useReadOnly();
  const empty = !readOnly && !Node.string(element);

  return (
    <Typography
      component="th"
      className={clsx(s.root, empty && s.empty)}
      data-table-column-index={element.columnIndex}
      {...attributes}
    >
      {children}
    </Typography>
  );
}
