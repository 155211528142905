import { withProps } from "@udecode/cn";
import {
  createPlugins,
  RenderAfterEditable,
  PlateLeaf,
  createPluginFactory,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
} from "@udecode/plate-common";
import {
  createBlockquotePlugin,
  ELEMENT_BLOCKQUOTE,
} from "@udecode/plate-block-quote";
import {
  createHorizontalRulePlugin,
  ELEMENT_HR,
} from "@udecode/plate-horizontal-rule";
import { createLinkPlugin, ELEMENT_LINK } from "@udecode/plate-link";
import {
  ELEMENT_IMAGE,
  createMediaEmbedPlugin,
  ELEMENT_MEDIA_EMBED,
} from "@udecode/plate-media";
import { createTogglePlugin, ELEMENT_TOGGLE } from "@udecode/plate-toggle";
import {
  createParagraphPlugin,
  ELEMENT_PARAGRAPH,
} from "@udecode/plate-paragraph";
import {
  createHeadingPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  KEYS_HEADING,
} from "@udecode/plate-heading";
import { createCaptionPlugin } from "@udecode/plate-caption";
import { ELEMENT_TR, ELEMENT_TD, ELEMENT_TH } from "@udecode/plate-table";
import { createTodoListPlugin, ELEMENT_TODO_LI } from "@udecode/plate-list";
import {
  createBoldPlugin,
  MARK_BOLD,
  createItalicPlugin,
  MARK_ITALIC,
  createUnderlinePlugin,
  MARK_UNDERLINE,
  createStrikethroughPlugin,
  MARK_STRIKETHROUGH,
  createCodePlugin,
  createSubscriptPlugin,
  MARK_SUBSCRIPT,
  createSuperscriptPlugin,
  MARK_SUPERSCRIPT,
} from "@udecode/plate-basic-marks";
import {
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createFontSizePlugin,
} from "@udecode/plate-font";
import {
  createHighlightPlugin,
  MARK_HIGHLIGHT,
} from "@udecode/plate-highlight";
import { createKbdPlugin, MARK_KBD } from "@udecode/plate-kbd";
import { createAlignPlugin } from "@udecode/plate-alignment";
import { createLineHeightPlugin } from "@udecode/plate-line-height";
import { createAutoformatPlugin } from "@udecode/plate-autoformat";
import { createDndPlugin } from "@udecode/plate-dnd";
import { ELEMENT_EMOJI_INPUT, createEmojiPlugin } from "@udecode/plate-emoji";
import {
  createExitBreakPlugin,
  createSoftBreakPlugin,
} from "@udecode/plate-break";
import { createNodeIdPlugin } from "@udecode/plate-node-id";
import { createResetNodePlugin } from "@udecode/plate-reset-node";
import { createDeletePlugin } from "@udecode/plate-select";
import { createTabbablePlugin } from "@udecode/plate-tabbable";
import { createTrailingBlockPlugin } from "@udecode/plate-trailing-block";
import { CheckInElement } from "../../elements/CheckIn";
import { CalloutElement, ELEMENT_CALLOUT } from "../../elements/Callout";
import { BlockquoteElement } from "../../../plate-ui/blockquote-element";
import { HeadingElement } from "../../../plate-ui/heading-element";
import { HighlightLeaf } from "../../../plate-ui/highlight-leaf";
import { HrElement } from "../../../plate-ui/hr-element";
import { KbdLeaf } from "../../../plate-ui/kbd-leaf";
import { LinkElement } from "../../../plate-ui/link-element";
import { ParagraphElement } from "../../../plate-ui/paragraph-element";
import { TodoListElement } from "../../../plate-ui/todo-list-element";
import { ToggleElement } from "../../../plate-ui/toggle-element";
import { withDraggables } from "../../../plate-ui/with-draggables";
import { withPlaceholders } from "../../../plate-ui/placeholder";
import { LinkFloatingToolbar } from "../../../plate-ui/link-floating-toolbar";
import { ELEMENT_PULLQUOTE, PullquoteElement } from "../../elements/Pullquote";
import { EMBED_WEBSITE, MediaEmbedElement } from "../../elements/MediaEmbed";
import {
  WEB_BOOKMARK,
  WebBookmarkElement,
} from "../../elements/web-bookmark/WebBookmark";
import { VIDEO, VideoElement } from "../../elements/Video";
import { AUDIO, AudioElement } from "../../elements/Audio";
import { IMAGE_ELEMENT, ImageElement } from "../../elements/Image";
import {
  ELEMENT_ACCORDION,
  ELEMENT_TABS,
  TabsElement,
} from "../../elements/tabs/Tabs";
import { FILE, FileUploaderElement } from "../../elements/FileUploader";
import { CheckInTypes } from "../../utils/menuItemUtil";
import { EmojiInputElement } from "../../../plate-ui/emoji-input-element";
import { SlashInputElement } from "../../../plate-ui/slash-input-element";
import {
  ELEMENT_SLASH_INPUT,
  createSlashPlugin,
} from "@udecode/plate-slash-command";
import { ELEMENT_TABS_ITEM, TabsItem } from "../../elements/tabs/TabsItem";
import {
  WORKOUT_SECTION,
  WorkoutSectionElement,
} from "../../components/workout/WorkoutSectionElement";
import { withAdditionalProps } from "../../utils/with-custom-props";
import { Schema } from "../schemas/Schema";
import {
  createIndentListPluginWithOptions,
  createIndentPluginWithOptions,
} from "./utils";
import {
  MEDIA_CAPTION,
  MediaCaptionElement,
} from "../../elements/media/MediaCaptionElement";
import {
  ELEMENT_TABLE_ROW,
  TableRowElement,
} from "../../components/table/TableRowElement";
import {
  ELEMENT_TABLE_CELL,
  TableCellElement,
} from "../../components/table/TableCellElement";
import {
  ELEMENT_TABLE_HEAD_ROW,
  TableHeadRowElement,
} from "../../components/table/TableHeadRowElement";
import {
  ELEMENT_TABLE_HEAD_CELL,
  TableHeadCellElement,
} from "../../components/table/TableHeadCellElement";
import {
  ELEMENT_TABLE,
  TableElement,
} from "../../components/table/TableElement";
import { ImageMediaElement } from "../../elements/media/ImageMediaElement";
import { generateId } from "../../utils/nodeUtil";
import { createAutoReplacerPlugin } from "./withAutoReplacer";

const resetBlockTypesCommonRule = {
  types: [
    ELEMENT_BLOCKQUOTE,
    ELEMENT_PULLQUOTE,
    ELEMENT_CALLOUT,
    ELEMENT_TODO_LI,
  ],
  defaultType: ELEMENT_PARAGRAPH,
};

const createTabsPlugin = createPluginFactory({
  key: ELEMENT_TABS,
  isElement: true,
});

const createAccordionPlugin = createPluginFactory({
  key: ELEMENT_ACCORDION,
  isElement: true,
});

const createCalloutPlugin = createPluginFactory({
  key: ELEMENT_CALLOUT,
  isElement: true,
});
const createFileUploaderPlugin = createPluginFactory({
  key: FILE,
  isElement: true,
});
const createAudioPlugin = createPluginFactory({
  key: AUDIO,
  isElement: true,
});
export const createVideoPlugin = createPluginFactory({
  key: VIDEO,
  isElement: true,
});
const createWebBookmarkPlugin = createPluginFactory({
  key: WEB_BOOKMARK,
  isElement: true,
});
const createImagePlugin = createPluginFactory({
  key: IMAGE_ELEMENT,
  isElement: true,
});

const createPullQuotePlugin = createPluginFactory({
  key: ELEMENT_PULLQUOTE,
  isElement: true,
});
const createEmbedPlugin = createPluginFactory({
  key: EMBED_WEBSITE,
  isElement: true,
});

const createYesNoPlugin = createPluginFactory({
  key: CheckInTypes.YES_NO,
  isElement: true,
  isVoid: true,
});

const createMeasurementPlugin = createPluginFactory({
  key: CheckInTypes.MEASUREMENT,
  isElement: true,
  isVoid: true,
});

const createProgressPhotoPlugin = createPluginFactory({
  key: CheckInTypes.PROGRESS_PHOTO,
  isElement: true,
  isVoid: true,
});

const createRatingScalePlugin = createPluginFactory({
  key: CheckInTypes.RATING_SCALE,
  isElement: true,
  isVoid: true,
});

const createLongTextPlugin = createPluginFactory({
  key: CheckInTypes.LONG_TEXT,
  isElement: true,
  isVoid: true,
});

const createShortTextPlugin = createPluginFactory({
  key: CheckInTypes.SHORT_TEXT,
  isElement: true,
});

const createNumberPlugin = createPluginFactory({
  key: CheckInTypes.NUMBER,
  isElement: true,
  isVoid: true,
});

const createFilePlugin = createPluginFactory({
  key: CheckInTypes.FILE,
  isElement: true,
  isVoid: true,
});

const createMultipleChoicePlugin = createPluginFactory({
  key: CheckInTypes.MULTIPLE_CHOICE,
  isElement: true,
  isVoid: true,
});

const createMultipleCheckboxPlugin = createPluginFactory({
  key: CheckInTypes.MULTIPLE_CHECKBOX,
  isElement: true,
  isVoid: true,
});

const createDatePlugin = createPluginFactory({
  key: CheckInTypes.DATE,
  isElement: true,
  isVoid: true,
});

const createCheckInEmojiPlugin = createPluginFactory({
  key: CheckInTypes.EMOJI,
  isElement: true,
  isVoid: true,
});

const createStarRatingPlugin = createPluginFactory({
  key: CheckInTypes.STAR_RATING,
  isElement: true,
  isVoid: true,
});

const createNutritionTargetPlugin = createPluginFactory({
  key: CheckInTypes.NUTRITION_TARGET,
  isElement: true,
  isVoid: true,
});

const createTabsItemsPlugin = createPluginFactory({
  key: ELEMENT_TABS_ITEM,
  isElement: true,
});

const createWorkoutSectionPlugin = createPluginFactory({
  key: WORKOUT_SECTION,
  isElement: true,
  isVoid: true,
});

const createTablePlugin = createPluginFactory({
  key: ELEMENT_TABLE,
  isElement: true,
});

const createTableRowPlugin = createPluginFactory({
  key: ELEMENT_TABLE_ROW,
  isElement: true,
});

const createTableCellPlugin = createPluginFactory({
  key: ELEMENT_TABLE_CELL,
  isElement: true,
});

const createTableHeadCellPlugin = createPluginFactory({
  key: ELEMENT_TABLE_HEAD_CELL,
  isElement: true,
});

const createTableHeadRowPlugin = createPluginFactory({
  key: ELEMENT_TABLE_HEAD_ROW,
  isElement: true,
});

const createMediaCaptionPlugin = createPluginFactory({
  key: MEDIA_CAPTION,
  isElement: true,
});

export const createAdvancedEditorPlatePlugins = (editorSchema: Schema) =>
  createPlugins(
    [
      createAutoReplacerPlugin(),
      createTabsPlugin(),
      createTabsItemsPlugin(),
      createAccordionPlugin(),
      createFileUploaderPlugin(),
      createYesNoPlugin(),
      createMeasurementPlugin(),
      createProgressPhotoPlugin(),
      createRatingScalePlugin(),
      createLongTextPlugin(),
      createShortTextPlugin(),
      createNumberPlugin(),
      createImagePlugin(),
      createFilePlugin(),
      createAudioPlugin(),
      createMultipleChoicePlugin(),
      createMultipleCheckboxPlugin(),
      createDatePlugin(),
      createCheckInEmojiPlugin(),
      createStarRatingPlugin(),
      createNutritionTargetPlugin(),
      createCalloutPlugin(),
      createPullQuotePlugin(),
      createVideoPlugin(),
      createEmbedPlugin(),
      createBlockquotePlugin(),
      createHorizontalRulePlugin(),
      createWebBookmarkPlugin(),
      createLinkPlugin({
        renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
      }),
      createTogglePlugin(),
      createParagraphPlugin(),
      createHeadingPlugin(),
      createCaptionPlugin({
        options: {
          pluginKeys: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED],
        },
      }),
      createTablePlugin(),
      createTableRowPlugin(),
      createTableCellPlugin(),
      createTableHeadCellPlugin(),
      createTableHeadRowPlugin(),
      createTodoListPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createCodePlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createFontSizePlugin(),
      createHighlightPlugin(),
      createKbdPlugin(),
      createAlignPlugin({
        inject: {
          props: {
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
          },
        },
      }),
      createIndentPluginWithOptions(),
      createIndentListPluginWithOptions(),
      createLineHeightPlugin({
        inject: {
          props: {
            defaultNodeValue: 1.5,
            validNodeValues: [1, 1.2, 1.5, 2, 3],
            validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3],
          },
        },
      }),
      createAutoformatPlugin({
        options: {
          rules: [
            // Usage: https://platejs.org/docs/autoformat
          ],
          enableUndoOnDelete: true,
        },
      }),
      createDndPlugin({
        options: { enableScroller: true },
      }),
      createEmojiPlugin(),
      createSlashPlugin(),
      createExitBreakPlugin({
        options: {
          rules: [
            {
              hotkey: "mod+enter",
            },
            {
              hotkey: "mod+shift+enter",
              before: true,
            },
            {
              hotkey: "enter",
              query: {
                start: true,
                end: true,
                allow: KEYS_HEADING,
              },
              relative: true,
              level: 1,
            },
          ],
        },
      }),
      createNodeIdPlugin({
        options: {
          idCreator: () => generateId(),
        },
      }),
      createResetNodePlugin({
        options: {
          rules: [
            {
              ...resetBlockTypesCommonRule,
              hotkey: "Enter",
              predicate: isBlockAboveEmpty,
            },
            {
              ...resetBlockTypesCommonRule,
              hotkey: "Backspace",
              predicate: isSelectionAtBlockStart,
            },
          ],
        },
      }),
      createDeletePlugin(),
      createSoftBreakPlugin({
        options: {
          rules: [
            { hotkey: "shift+enter" },
            {
              hotkey: "enter",
              query: {
                allow: [ELEMENT_BLOCKQUOTE, ELEMENT_TD],
              },
            },
          ],
        },
      }),
      createTabbablePlugin(),
      createTrailingBlockPlugin({
        options: { type: ELEMENT_PARAGRAPH },
      }),
      createWorkoutSectionPlugin(),
      createMediaCaptionPlugin(),
    ],
    {
      components: withDraggables(
        withPlaceholders({
          [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
          [ELEMENT_HR]: HrElement,
          [ELEMENT_IMAGE]: ImageMediaElement,
          [ELEMENT_LINK]: LinkElement,
          [ELEMENT_TOGGLE]: ToggleElement,
          [ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
          [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
          [ELEMENT_H3]: withProps(HeadingElement, { variant: "h3" }),
          [ELEMENT_H4]: withProps(HeadingElement, { variant: "h4" }),
          [ELEMENT_H5]: withProps(HeadingElement, { variant: "h5" }),
          [ELEMENT_H6]: withProps(HeadingElement, { variant: "h6" }),
          [ELEMENT_PARAGRAPH]: ParagraphElement,
          [ELEMENT_TABLE]: TableElement,
          [ELEMENT_TABLE_ROW]: TableRowElement,
          [ELEMENT_TABLE_HEAD_ROW]: TableHeadRowElement,
          [ELEMENT_TABLE_CELL]: TableCellElement,
          [ELEMENT_TABLE_HEAD_CELL]: withProps(TableHeadCellElement, {
            as: "th",
          }),
          [ELEMENT_TODO_LI]: TodoListElement,
          [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
          [MARK_HIGHLIGHT]: HighlightLeaf,
          [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
          [MARK_KBD]: KbdLeaf,
          [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
          [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: "sub" }),
          [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: "sup" }),
          [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
          [ELEMENT_CALLOUT]: CalloutElement,
          [ELEMENT_PULLQUOTE]: PullquoteElement,
          [EMBED_WEBSITE]: MediaEmbedElement,
          [WEB_BOOKMARK]: WebBookmarkElement,
          [VIDEO]: VideoElement,
          [AUDIO]: AudioElement,
          [IMAGE_ELEMENT]: ImageMediaElement,
          [ELEMENT_ACCORDION]: TabsElement,
          [ELEMENT_TABS]: TabsElement,
          [ELEMENT_TABS_ITEM]: TabsItem,
          [FILE]: FileUploaderElement,
          [ELEMENT_EMOJI_INPUT]: EmojiInputElement,
          [MEDIA_CAPTION]: MediaCaptionElement,
          [ELEMENT_SLASH_INPUT]: withAdditionalProps(SlashInputElement, {
            generalDropDownMenuItems: editorSchema.generalDropDownMenuItems,
          }),

          // CHECKIN_ELEMENTS //
          [CheckInTypes.YES_NO]: withProps(CheckInElement, {
            type: CheckInTypes.YES_NO,
          }),
          [CheckInTypes.MEASUREMENT]: withProps(CheckInElement, {
            type: CheckInTypes.MEASUREMENT,
          }),
          [CheckInTypes.PROGRESS_PHOTO]: withProps(CheckInElement, {
            type: CheckInTypes.PROGRESS_PHOTO,
          }),
          [CheckInTypes.RATING_SCALE]: withProps(CheckInElement, {
            type: CheckInTypes.RATING_SCALE,
          }),
          [CheckInTypes.LONG_TEXT]: withProps(CheckInElement, {
            type: CheckInTypes.LONG_TEXT,
          }),
          [CheckInTypes.SHORT_TEXT]: withProps(CheckInElement, {
            type: CheckInTypes.SHORT_TEXT,
          }),
          [CheckInTypes.NUMBER]: withProps(CheckInElement, {
            type: CheckInTypes.NUMBER,
          }),
          [CheckInTypes.FILE]: withProps(CheckInElement, {
            type: CheckInTypes.FILE,
          }),
          [CheckInTypes.MULTIPLE_CHOICE]: withProps(CheckInElement, {
            type: CheckInTypes.MULTIPLE_CHOICE,
          }),
          [CheckInTypes.MULTIPLE_CHECKBOX]: withProps(CheckInElement, {
            type: CheckInTypes.MULTIPLE_CHECKBOX,
          }),
          [CheckInTypes.DATE]: withProps(CheckInElement, {
            type: CheckInTypes.DATE,
          }),
          [CheckInTypes.EMOJI]: withProps(CheckInElement, {
            type: CheckInTypes.EMOJI,
          }),
          [CheckInTypes.STAR_RATING]: withProps(CheckInElement, {
            type: CheckInTypes.STAR_RATING,
          }),
          [CheckInTypes.NUTRITION_TARGET]: withProps(CheckInElement, {
            type: CheckInTypes.NUTRITION_TARGET,
          }),

          // WORKOUT_ELEMENTS //
          [WORKOUT_SECTION]: withProps(WorkoutSectionElement, {
            type: WORKOUT_SECTION,
          }),
        }),
        editorSchema.generalDropDownMenuItems,
        editorSchema.showInsertDropdownOnDraggable,
      ),
    },
  );
