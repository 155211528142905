import { createContext } from "react";

export interface IPendingActivityContextProps {
  isActivityPending: boolean;
  setIsActivityPending: (isActivityPending: boolean) => void;
}

export const PendingActivityContext = createContext<
  IPendingActivityContextProps | undefined
>(undefined);
