import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import Loader from "../../icons/loader.gif";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colorSystem.blackOpacitySecond,
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
    top: 0,
    left: 0,
  },
  content: {
    maxWidth: 410,
    width: "100%",
    backgroundColor: colorSystem.white,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    padding: theme.spacing(3),
    borderRadius: 12,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05);",
  },
  title: {},
  gif: {
    height: 56,
    width: 70,
  },
  text: {
    marginTop: "-10px",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
  },
}));

type PreLoaderProps = {
  text: string;
};

const PreLoader = ({ text }: PreLoaderProps) => {
  const s = useStyles();
  return (
    <Box className={s.container}>
      <Box className={s.content}>
        <Typography variant={"h6"} className={s.title} children={text} />
        <Box>
          <img src={Loader} alt="loader" className={s.gif} />
        </Box>
        <div className={s.text}>Please wait ...</div>
      </Box>
    </Box>
  );
};

export default PreLoader;
