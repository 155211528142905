import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { ReactComponent as ArrowForwardOutline } from "../../icons/ArrowForwardOutline.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    marginLeft: theme.spacing(-1),
    "& svg": {
      width: 24,
      height: 24,
      marginRight: theme.spacing(0.5),
    },
  },

  backIcon: {
    transform: "rotate(180deg)",
  },
}));

export interface ComponentDrawerBackButtonProps
  extends Omit<ButtonProps, "variant"> {
  variant?: "back" | "close";
}

export function ComponentDrawerBackButton(
  props: ComponentDrawerBackButtonProps,
) {
  const { className, variant, ...other } = props;
  const s = useStyles();

  return (
    <Button
      className={clsx(s.root, className)}
      variant="text"
      color="primary"
      {...other}
    >
      {variant === "back" ? (
        <>
          <ArrowForwardOutline className={s.backIcon} /> Back
        </>
      ) : (
        <>
          <CloseIcon /> Close
        </>
      )}
    </Button>
  );
}
