/**
 * @generated SignedSource<<cebeb0ade350a58ca90e8adf61933f4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NewPlan = "PROMO" | "UP_TO_100" | "UP_TO_200" | "UP_TO_50" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type SubscribeOnNewPlanInput = {
  clientMutationId?: string | null | undefined;
  plan: NewPlan;
};
export type ProUpgradeCardMakeSubscriptionProStarterMutation$variables = {
  input: SubscribeOnNewPlanInput;
};
export type ProUpgradeCardMakeSubscriptionProStarterMutation$data = {
  readonly subscribeOnNewPlan: {
    readonly status: string | null | undefined;
  } | null | undefined;
};
export type ProUpgradeCardMakeSubscriptionProStarterMutation = {
  response: ProUpgradeCardMakeSubscriptionProStarterMutation$data;
  variables: ProUpgradeCardMakeSubscriptionProStarterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubscribeOnNewPlanPayload",
    "kind": "LinkedField",
    "name": "subscribeOnNewPlan",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProUpgradeCardMakeSubscriptionProStarterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProUpgradeCardMakeSubscriptionProStarterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f3bf07b60d1ccd0de8d137dee851e0a",
    "id": null,
    "metadata": {},
    "name": "ProUpgradeCardMakeSubscriptionProStarterMutation",
    "operationKind": "mutation",
    "text": "mutation ProUpgradeCardMakeSubscriptionProStarterMutation(\n  $input: SubscribeOnNewPlanInput!\n) {\n  subscribeOnNewPlan(input: $input) {\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "11e8cd1401a4891ad7c918bb21641269";

export default node;
