import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Box, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

import { polyfillCSS } from "../../utils/css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
    paddingBottom: polyfillCSS("var(--safe-area-inset-bottom)"),

    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.background.default,
      maxWidth: theme.spacing(68.5),
    },

    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },

  header: {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(10, 0),
      padding: 0,
      textAlign: "center",

      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 700,

    [theme.breakpoints.up("sm")]: {
      fontWeight: 600,
    },
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
    },
  },

  body: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export type CompactContainerClassKey = "body" | "header";
export interface CompactContainerProps
  extends Omit<ContainerProps, "title" | "classes"> {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  classes?: Partial<ClassNameMap<CompactContainerClassKey>>;
}

export function CompactContainer(props: CompactContainerProps) {
  const { className, children, title, subtitle, classes, ...other } = props;
  const s = useStyles();

  return (
    <Container className={clsx(s.root, className)} maxWidth="sm" {...other}>
      {(title || subtitle) && (
        <Box className={clsx(s.header, classes?.header)}>
          {title && (
            <Typography className={s.title} variant="h1">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography className={s.subtitle} variant="body1">
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      <Box className={clsx(s.body, classes?.body)}>{children}</Box>
    </Container>
  );
}
