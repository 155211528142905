import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { useEffect, useRef } from "react";
import { Icons } from "../../../plate-ui/Icons/icons";
import { alpha, Box, Button, Stack, useTheme } from "@mui/material";
import MinimizedTooltip, {
  TooltipLine,
} from "../../../tooltip/MinimizedTooltip";
import { WeekComponentMenu } from "../../../menu/WeekComponentMenu";
import { graphql } from "relay-runtime";
import { useFragment } from "react-relay";
import { ComponentType, RowType } from "../../../../constants";
import { ProgramAddComponentCallback } from "../../../program/ProgramDetails";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Filters } from "../../../program/ProgramDetailsFilters";
import {
  createEmptyExercise,
  generateEmptyWorkoutSection,
  generateNewWorkoutContent,
} from "../../../workout/utils";
import WeekWorkoutMenu from "../../../menu/WeekWorkoutMenu";
import { InsertItemComponent } from "./InsertItemComponent";
import { colorSystem } from "../../../../theme";
import { ProgramDetailsViewMode } from "../../../program/ProgramDetailsViewButton";
import { ExerciseDrawerData } from "../../utils";

const fragment = graphql`
  fragment DragCellRenderer_component on Component {
    ...WeekComponentMenu_component
    id
    slug
    type
    days
    status
    locked
    draftExists
  }
`;

interface IDragCellRendererProps {
  componentRef: any;
  onAddComponent: ProgramAddComponentCallback;
  day: number;
  weekId: string;
  positions: string[];
  filters: Filters;
  saveWorkoutContent: any;
  setExerciseDrawerData: React.Dispatch<
    React.SetStateAction<ExerciseDrawerData>
  >;
  setGridLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const iconsHoverStyling = (theme, border: boolean = true) => ({
  border: border && `1px solid ${alpha(theme.palette.common.black, 0)}`,
  color: colorSystem.gray4,
  ":hover": {
    color: alpha(theme.palette.common.black, 1),
    background: alpha(theme.palette.common.black, 0.02),
    border: border && `1px solid ${alpha(theme.palette.common.black, 0.08)}`,
  },

  transition: `${theme.transitions.create(
    ["border", "color", "background", "opacity"],
    {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    },
  )} !important`,
});

const DragCellRenderer = (
  props: CustomCellRendererProps & IDragCellRendererProps,
) => {
  const {
    onAddComponent,
    day,
    weekId,
    positions,
    filters,
    saveWorkoutContent,
    setExerciseDrawerData,
    setGridLoader,
  } = props;
  const theme = useTheme();
  const CURRENT_ROW_TYPE = props.data.rowType;
  const component = useFragment(fragment, props.componentRef);

  // COMPONENT MENU
  const [componentMenuAnchorEl, setComponentMenuAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const handleOpenComponentMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setComponentMenuAnchorEl(event.currentTarget);
  };

  const closeComponentMenu = () => {
    setComponentMenuAnchorEl(null);
  };

  // WORKOUT MENU
  const [workoutMenuAnchorEl, setWorkoutMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openComponentMenu = Boolean(workoutMenuAnchorEl);

  const handleOpenWorkoutMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setWorkoutMenuAnchorEl(event.currentTarget);
  };

  const closeWorkoutMenu = () => {
    setWorkoutMenuAnchorEl(null);
  };

  const handleDragIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    [RowType.WORKOUT_SECTION, RowType.EXERCISE].includes(CURRENT_ROW_TYPE)
      ? handleOpenWorkoutMenu(event)
      : handleOpenComponentMenu(event);
  };

  const dragHandleRef = useRef(null);
  useEffect(() => {
    props.registerRowDragger(dragHandleRef.current!);
  });

  const saveCurrentComponentContent = (content: any) => {
    saveWorkoutContent(component.id, content);
  };

  const handleAddComponent = React.useCallback(
    (componentType: ComponentType | RowType, isFirstLine?: boolean) => {
      const isSameTypeAdded = component.type === componentType;
      switch (componentType) {
        case RowType.WORKOUT_SECTION: {
          const workoutSectionId = props.data.workoutSectionData?.id;
          const componentContent = props.data.componentContent ?? [];
          const result = workoutSectionId
            ? [
                ...componentContent.slice(
                  0,
                  componentContent.findIndex((c) => c.id === workoutSectionId) +
                    1,
                ),
                generateEmptyWorkoutSection(2),
                ...componentContent.slice(
                  componentContent.findIndex((c) => c.id === workoutSectionId) +
                    1,
                ),
              ]
            : [generateEmptyWorkoutSection(2), ...componentContent];
          saveWorkoutContent(component.id, result);
          return;
        }
        case RowType.EXERCISE: {
          const workoutExerciseId = props.data.exerciseData?.id;
          const workoutSectionId = props.data.workoutSectionData.id;
          const workoutSection = props.data.componentContent.find(
            (c) => c.id === workoutSectionId,
          );

          const exercises = workoutSection.workout.exercises;

          const updatedExercises = workoutExerciseId
            ? [
                ...exercises.slice(
                  0,
                  exercises.findIndex((ex) => ex.id === workoutExerciseId) + 1,
                ),
                createEmptyExercise(),
                ...exercises.slice(
                  exercises.findIndex((ex) => ex.id === workoutExerciseId) + 1,
                ),
              ]
            : [createEmptyExercise(), ...exercises];

          const resultWorkoutSection = {
            ...workoutSection,
            workout: {
              ...workoutSection.workout,
              exercises: updatedExercises,
            },
          };

          const resultComponentContent = props.data.componentContent.map((c) =>
            c.id === workoutSectionId ? resultWorkoutSection : c,
          );

          saveWorkoutContent(component.id, resultComponentContent);
          return;
        }
      }
      if (onAddComponent) {
        const days = [...Array(7)].map((_, index) => index === day);
        const index = positions.indexOf(component.id);
        if (index !== -1 && !isFirstLine) {
          positions.splice(index + 1, 0, "");
        }
        isFirstLine && positions.unshift("");

        const newWorkoutContent = JSON.stringify(
          generateNewWorkoutContent(1, 2),
        );

        const newComponentContent =
          componentType === ComponentType.WORKOUT
            ? newWorkoutContent
            : undefined;

        setGridLoader(true);
        onAddComponent(
          weekId,
          componentType as ComponentType,
          days,
          isSameTypeAdded ? positions : undefined,
          newComponentContent,
        );
      }
    },
    [day, onAddComponent, weekId],
  );

  const menuState = usePopupState({
    variant: "popover",
    popupId: "add-component-menu",
  });

  const firstLineMenuState = usePopupState({
    variant: "popover",
    popupId: "add-first-line-component-menu",
  });

  const openWorkoutDrawer = () => {
    setExerciseDrawerData({
      componentId: props.data.componentId,
      componentContent: props.data.componentContent,
      workoutSection: props.data.workoutSectionData,
      exercise: props.data.exerciseData,
    });
  };

  return (
    <Box className="drag-cell-renderer flex justify-center">
      <Box
        sx={{
          opacity: 0,
          ":hover": {
            opacity: 1,
          },
          transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeOut,
          }),
        }}
      >
        {props.node.rowIndex === 0 &&
          component &&
          InsertItemComponent({
            firstLine: true,
            iconsHoverStyling: iconsHoverStyling(theme),
            menuState,
            firstLineMenuState,
            currentRowType: CURRENT_ROW_TYPE,
            handleAddComponent,
            filters,
          })}
      </Box>

      <Box
        className={`flex cursor-grab h-full flex-col justify-center opacity-0 group-hover:opacity-100`}
      >
        <MinimizedTooltip
          title={
            <Stack direction="column">
              <TooltipLine>
                <b>Drag</b> to move
              </TooltipLine>
              <TooltipLine>
                <b>Click</b> to action
              </TooltipLine>
            </Stack>
          }
        >
          <Button
            onClick={handleDragIconClick}
            sx={{
              minWidth: 0,
              p: 0.4,
              m: "auto",
              pr: { xs: 0, sm: 0.4 },
              cursor: "grab",
              ...iconsHoverStyling(theme),
            }}
          >
            <div ref={dragHandleRef}>
              <Icons.dragHandle className="h-4 w-4" />
            </div>
          </Button>
        </MinimizedTooltip>
      </Box>

      {component &&
        InsertItemComponent({
          firstLine: false,
          iconsHoverStyling: iconsHoverStyling(theme),
          menuState,
          firstLineMenuState,
          currentRowType: CURRENT_ROW_TYPE,
          handleAddComponent,
          filters,
        })}

      {component && (
        <WeekComponentMenu
          anchorEl={componentMenuAnchorEl}
          componentRef={component}
          open={Boolean(componentMenuAnchorEl)}
          onClose={closeComponentMenu}
          view={ProgramDetailsViewMode.SPREADSHEET}
        />
      )}

      <WeekWorkoutMenu
        anchorEl={workoutMenuAnchorEl}
        open={openComponentMenu}
        onClose={closeWorkoutMenu}
        type={CURRENT_ROW_TYPE}
        cellData={props.data}
        saveCurrentComponentContent={saveCurrentComponentContent}
        handleEditExerciseClick={openWorkoutDrawer}
      />
    </Box>
  );
};
export default DragCellRenderer;
