import { Box, LinearProgress } from "@mui/material";
import React from "react";
import { DRAG_CELL_WIDTH } from "../ProgramSpreadsheetGrid";
import { useTheme } from "@mui/styles";

const CustomHeaderGroup = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: 1,
        position: "absolute",
        top: 34,
        zIndex: theme.zIndex.speedDial,
        color: theme.palette.divider,
      }}
    >
      <LinearProgress color="inherit" />
    </Box>
  );
};

export default CustomHeaderGroup;
