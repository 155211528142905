/**
 * @generated SignedSource<<f91be7fe614c259dd3391a47905dd4cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeasurementType = "BODYFAT" | "BODYWEIGHT" | "CALF" | "CHEST" | "HIP" | "SHOULDER" | "STEP_COUNT" | "THIGH" | "UPPER_ARM" | "WAIST" | "%future added value";
export type ClientProfileTrendsRouteQuery$variables = {
  measurementType?: MeasurementType | null | undefined;
};
export type ClientProfileTrendsRouteQuery$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientProfileMeasurementScreen_user">;
  } | null | undefined;
};
export type ClientProfileTrendsRouteQuery = {
  response: ClientProfileTrendsRouteQuery$data;
  variables: ClientProfileTrendsRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "measurementType"
  }
],
v1 = {
  "kind": "Variable",
  "name": "measurementType",
  "variableName": "measurementType"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProfileTrendsRouteQuery",
    "selections": [
      {
        "alias": "user",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ClientProfileMeasurementScreen_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientProfileTrendsRouteQuery",
    "selections": [
      {
        "alias": "user",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "units",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionTarget",
            "kind": "LinkedField",
            "name": "nutritionTarget",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trackingType",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "metricType",
                "value": "CHECKIN_ANSWER_MEASUREMENT"
              }
            ],
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metrics",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "x",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "YYYY-MM-DD"
                  }
                ],
                "kind": "ScalarField",
                "name": "activityDate",
                "storageKey": "activityDate(format:\"YYYY-MM-DD\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "measurement",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit",
                        "storageKey": null
                      }
                    ],
                    "type": "CheckinAnswerMeasurementValue",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "raw",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "activityDate",
                "storageKey": "activityDate(raw:true)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0fa6bddd4787825d6aa4a4107b131e4",
    "id": null,
    "metadata": {},
    "name": "ClientProfileTrendsRouteQuery",
    "operationKind": "query",
    "text": "query ClientProfileTrendsRouteQuery(\n  $measurementType: MeasurementType\n) {\n  user: me {\n    ...ClientProfileMeasurementScreen_user_4m8Ydj\n    id\n  }\n}\n\nfragment ClientMeasurementChart_metrics on ClientMetric {\n  id\n  x: activityDate(format: \"YYYY-MM-DD\")\n  value {\n    __typename\n    ... on CheckinAnswerMeasurementValue {\n      measurement\n      unit\n    }\n  }\n}\n\nfragment ClientMeasurementRows_metrics on ClientMetric {\n  id\n  activityDate(raw: true)\n  name\n  value {\n    __typename\n    ... on CheckinAnswerMeasurementValue {\n      measurement\n      unit\n    }\n  }\n}\n\nfragment ClientProfileMeasurementScreen_user_4m8Ydj on User {\n  id\n  units\n  nutritionTarget {\n    trackingType\n    id\n  }\n  metrics(metricType: CHECKIN_ANSWER_MEASUREMENT, measurementType: $measurementType) {\n    ...ClientMeasurementChart_metrics\n    ...ClientMeasurementRows_metrics\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "299e9d4bae046a17ffab174c781d4aa3";

export default node;
