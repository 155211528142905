import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { ListMenu, ListMenuProps } from "./ListMenu";
import { ClientPendingMenuResendMutation } from "./__generated__/ClientPendingMenuResendMutation.graphql";
import { ClientPendingMenuRevokeMutation } from "./__generated__/ClientPendingMenuRevokeMutation.graphql";
import { ClientPendingMenuDeleteMutation } from "./__generated__/ClientPendingMenuDeleteMutation.graphql";

export interface ClientPendingMenuProps extends Omit<ListMenuProps, "options"> {
  inviteId: string;
  clientId: string;
  isPending: boolean;
  onSendInvite: () => void;
}

const resendInviteMutation = graphql`
  mutation ClientPendingMenuResendMutation($input: ResendClientInviteInput!) {
    resendClientInvite(input: $input) {
      clientMutationId
    }
  }
`;

const revokeInviteMutation = graphql`
  mutation ClientPendingMenuRevokeMutation($input: RevokeClientInviteInput!) {
    revokeClientInvite(input: $input) {
      clientMutationId
    }
  }
`;

const revokeAddedClientMutation = graphql`
  mutation ClientPendingMenuDeleteMutation($input: RevokeAddedClientInput!) {
    revokeAddedClient(input: $input) {
      clientMutationId
    }
  }
`;

export function ClientPendingMenu(props: ClientPendingMenuProps) {
  const { inviteId, onClose, isPending, clientId, onSendInvite, ...other } =
    props;
  const [resendInvite] =
    useMutation<ClientPendingMenuResendMutation>(resendInviteMutation);
  const [revokeInvite] =
    useMutation<ClientPendingMenuRevokeMutation>(revokeInviteMutation);
  const [deleteAddedUser] = useMutation<ClientPendingMenuDeleteMutation>(
    revokeAddedClientMutation,
  );

  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const onNotification = React.useCallback(
    (message: string) => {
      snackAlert({
        severity: "success",
        message,
      });
    },
    [snackAlert],
  );

  const handleResendInvite = React.useCallback(
    (event) => {
      resendInvite({
        variables: {
          input: {
            id: inviteId,
          },
        },
        onCompleted: () => {
          if (onNotification) {
            onNotification("Invitation has been resent");
          }
          onClose(event, "escapeKeyDown");
        },
      });
    },
    [inviteId, resendInvite, onClose, onNotification],
  );

  const handleRevokeInvite = React.useCallback(
    (event) => {
      revokeInvite({
        variables: {
          input: {
            id: inviteId,
          },
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            if (onNotification) {
              onNotification("Invitation has been revoked");
            }
            onClose(event, "escapeKeyDown");
          }
        },
        updater: (store) => {
          store.delete(inviteId);
        },
      });
    },
    [revokeInvite, inviteId, onError, onNotification, onClose],
  );

  const handleRevokeAddedClient = React.useCallback(
    (event) => {
      deleteAddedUser({
        variables: {
          input: {
            id: clientId,
          },
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            if (onNotification) {
              onNotification("User deleted");
            }
            onClose(event, "escapeKeyDown");
          }
        },
        updater: (store) => {
          store.delete(inviteId);
        },
      });
    },
    [inviteId, onError, onNotification, onClose, clientId, deleteAddedUser],
  );

  const optionsPending = [
    {
      label: "Revoke Invite",
      onClick: handleRevokeInvite,
    },

    {
      label: "Resend Invite",
      onClick: handleResendInvite,
    },
  ];

  const optionsAdded = [
    {
      label: "Delete",
      onClick: handleRevokeAddedClient,
    },

    {
      label: "Send Invite",
      onClick: onSendInvite,
    },
  ];

  return (
    <ListMenu
      options={isPending ? optionsPending : optionsAdded}
      onClose={onClose}
      {...other}
    />
  );
}
