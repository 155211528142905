import React from "react";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import {
  GroupAddableItem_group$key,
  GroupAddableItem_group$data,
} from "./__generated__/GroupAddableItem_group.graphql";

import { SelectableItem, SelectableItemProps } from "./SelectableItem";

const groupFragment = graphql`
  fragment GroupAddableItem_group on Group {
    id
    name
    size
  }
`;

export interface GroupAddableItemProps
  extends Pick<
    SelectableItemProps,
    "className" | "added" | "disabled" | "disabledText"
  > {
  groupRef: GroupAddableItem_group$key;
  onToggle: (added: boolean, group: GroupAddableItem_group$data) => void;
}

export function GroupAddableItem(props: GroupAddableItemProps) {
  const { className, groupRef, onToggle, ...other } = props;
  const group = useFragment(groupFragment, groupRef);
  const { name, size } = group;

  const handleToggle: SelectableItemProps["onToggle"] = React.useCallback(
    (added) => {
      onToggle(added, group);
    },
    [group, onToggle],
  );

  return (
    <SelectableItem
      className={className}
      header={name}
      subheader={`${size} client${size === 1 ? "" : "s"}`}
      onToggle={handleToggle}
      {...other}
    />
  );
}
