/**
 * @generated SignedSource<<e70e7499a6999cf930b5b772f15225d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeasurementType = "BODYFAT" | "BODYWEIGHT" | "CALF" | "CHEST" | "HIP" | "SHOULDER" | "STEP_COUNT" | "THIGH" | "UPPER_ARM" | "WAIST" | "%future added value";
export type ClientProfileMeasurementScreenRefetchQuery$variables = {
  id: string;
  measurementType?: MeasurementType | null | undefined;
};
export type ClientProfileMeasurementScreenRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientProfileMeasurementScreen_user">;
  } | null | undefined;
};
export type ClientProfileMeasurementScreenRefetchQuery = {
  response: ClientProfileMeasurementScreenRefetchQuery$data;
  variables: ClientProfileMeasurementScreenRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "measurementType"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "kind": "Variable",
  "name": "measurementType",
  "variableName": "measurementType"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProfileMeasurementScreenRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ClientProfileMeasurementScreen_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientProfileMeasurementScreenRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientNutritionTarget",
                "kind": "LinkedField",
                "name": "nutritionTarget",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trackingType",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "metricType",
                    "value": "CHECKIN_ANSWER_MEASUREMENT"
                  }
                ],
                "concreteType": "ClientMetric",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": "x",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "YYYY-MM-DD"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "activityDate",
                    "storageKey": "activityDate(format:\"YYYY-MM-DD\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "value",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "measurement",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unit",
                            "storageKey": null
                          }
                        ],
                        "type": "CheckinAnswerMeasurementValue",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "raw",
                        "value": true
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "activityDate",
                    "storageKey": "activityDate(raw:true)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d1d110b6d46ec10a9b560b2f19a1b1c",
    "id": null,
    "metadata": {},
    "name": "ClientProfileMeasurementScreenRefetchQuery",
    "operationKind": "query",
    "text": "query ClientProfileMeasurementScreenRefetchQuery(\n  $measurementType: MeasurementType\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ClientProfileMeasurementScreen_user_4m8Ydj\n    id\n  }\n}\n\nfragment ClientMeasurementChart_metrics on ClientMetric {\n  id\n  x: activityDate(format: \"YYYY-MM-DD\")\n  value {\n    __typename\n    ... on CheckinAnswerMeasurementValue {\n      measurement\n      unit\n    }\n  }\n}\n\nfragment ClientMeasurementRows_metrics on ClientMetric {\n  id\n  activityDate(raw: true)\n  name\n  value {\n    __typename\n    ... on CheckinAnswerMeasurementValue {\n      measurement\n      unit\n    }\n  }\n}\n\nfragment ClientProfileMeasurementScreen_user_4m8Ydj on User {\n  id\n  units\n  nutritionTarget {\n    trackingType\n    id\n  }\n  metrics(metricType: CHECKIN_ANSWER_MEASUREMENT, measurementType: $measurementType) {\n    ...ClientMeasurementChart_metrics\n    ...ClientMeasurementRows_metrics\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f1b9073feaa0ff930429031ac144af2";

export default node;
