import React from "react";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import { useEditorReadOnly } from "@udecode/plate-common";

import { Icons, iconVariants } from "./Icons/icons";

import { MarkToolbarButton } from "./mark-toolbar-button";
import { ToolbarGroup } from "./toolbar";
import { LineHeightDropdownMenu } from "./line-height-dropdown-menu";
import { AlignDropdownMenu } from "./align-dropdown-menu";
import { ListStyleType } from "@udecode/plate-indent-list";
import { OutdentToolbarButton } from "./outdent-toolbar-button";
import { IndentToolbarButton } from "./indent-toolbar-button";
import { IndentListToolbarButton } from "./indent-list-toolbar-button";
import { LinkToolbarButton } from "./link-toolbar-button";
import { EmojiDropdownMenu } from "./emoji-dropdown-menu";
import { ColorDropdownMenu } from "./color-dropdown-menu";
import { MARK_BG_COLOR, MARK_COLOR } from "@udecode/plate-font";
import { ToolbarOptions } from "../new-editor/editor-configuration/schemas/Schema";
import { ELEMENT_H1, ELEMENT_H2 } from "@udecode/plate-heading";
import { TurnIntoToolbarButton } from "./turn-into-toolbar-button";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { ELEMENT_PULLQUOTE } from "../new-editor/elements/Pullquote";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { InsertToolbarButton } from "./insert-toolbar-button";
import { FILE } from "../new-editor/elements/FileUploader";
import { IMAGE_ELEMENT } from "../new-editor/elements/Image";
import { WEB_BOOKMARK } from "../new-editor/elements/web-bookmark/WebBookmark";
import { IndentTodoToolbarButton } from "./indent-todo-toolbar-button";
import { IMenuSection } from "../new-editor/utils/menuSectionsUtil";
import { ToolbarInsertDropdownMenu } from "./toolbar-insert-dropdown-menu";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";
import { Box } from "@mui/material";

interface FixedToolbarButtonsProps {
  toolbarOptions?: ToolbarOptions;
  dropDownMenuItems: IMenuSection[];
}

export function FixedToolbarButtons({
  toolbarOptions,
  dropDownMenuItems,
}: FixedToolbarButtonsProps) {
  const readOnly = useEditorReadOnly();

  const getKeyBind = (...keys: string[]) => {
    let actionKey = "CTRL";
    if (window.navigator.appVersion.indexOf("Mac") !== -1) actionKey = "⌘";
    keys = [actionKey, ...keys];
    return `(${keys.join("+")})`;
  };

  return (
    <div className="w-full overflow-hidden fixed-toolbar-buttons">
      <Box
        sx={{
          display: "flex",
          transform: "translateX(calc(-1px))",
          overflowX: "scroll",
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* Internet Explorer 10+ */,
          /* WebKit */
          "::-webkit-scrollbar": {
            width: 0,
            height: 0,
          },
        }}
      >
        {!readOnly && (
          <>
            <ToolbarGroup>
              {(!toolbarOptions || toolbarOptions?.showInsertDropdown) && (
                <ToolbarInsertDropdownMenu items={dropDownMenuItems} />
              )}
              {(!toolbarOptions ||
                toolbarOptions?.showTextTransformOptions) && (
                <>
                  <TurnIntoToolbarButton
                    tooltip="Heading 1"
                    nodeType={ELEMENT_H1}
                  >
                    <Icons.h1 />
                  </TurnIntoToolbarButton>
                  <TurnIntoToolbarButton
                    tooltip="Heading 2"
                    nodeType={ELEMENT_H2}
                  >
                    <Icons.h2 />
                  </TurnIntoToolbarButton>
                </>
              )}
            </ToolbarGroup>

            <ToolbarGroup>
              {(!toolbarOptions ||
                toolbarOptions?.showTextFormattingOptions) && (
                <>
                  <MarkToolbarButton
                    tooltip={"Bold " + getKeyBind("B")}
                    nodeType={MARK_BOLD}
                  >
                    <Icons.bold />
                  </MarkToolbarButton>
                  <MarkToolbarButton
                    tooltip={"Italic " + getKeyBind("I")}
                    nodeType={MARK_ITALIC}
                  >
                    <Icons.italic />
                  </MarkToolbarButton>
                  <MarkToolbarButton
                    tooltip={"Underline " + getKeyBind("U")}
                    nodeType={MARK_UNDERLINE}
                  >
                    <Icons.underline />
                  </MarkToolbarButton>
                  <MarkToolbarButton
                    tooltip={"Strikethrough " + getKeyBind("⇧", "M")}
                    nodeType={MARK_STRIKETHROUGH}
                  >
                    <Icons.strikethrough />
                  </MarkToolbarButton>
                </>
              )}

              {(!toolbarOptions || toolbarOptions?.showTextColorOptions) && (
                <>
                  <ColorDropdownMenu nodeType={MARK_COLOR} tooltip="Text Color">
                    <Icons.color
                      className={iconVariants({ variant: "toolbar" })}
                    />
                  </ColorDropdownMenu>
                  <ColorDropdownMenu
                    nodeType={MARK_HIGHLIGHT}
                    tooltip="Highlight Color"
                  >
                    <Icons.bg
                      className={iconVariants({ variant: "toolbar" })}
                    />
                  </ColorDropdownMenu>
                </>
              )}
            </ToolbarGroup>
            {(!toolbarOptions ||
              toolbarOptions?.showTextIndentationOptions) && (
              <ToolbarGroup>
                <AlignDropdownMenu />
                <LineHeightDropdownMenu />
                <OutdentToolbarButton />
                <IndentToolbarButton />
              </ToolbarGroup>
            )}
            {(!toolbarOptions || toolbarOptions?.showListOptions) && (
              <ToolbarGroup>
                <IndentTodoToolbarButton />
                <IndentListToolbarButton nodeType={ListStyleType.Disc} />
                <IndentListToolbarButton nodeType={ListStyleType.Decimal} />
              </ToolbarGroup>
            )}
            {(!toolbarOptions || toolbarOptions?.showQuotesOptions) && (
              <ToolbarGroup>
                <TurnIntoToolbarButton
                  tooltip="Pull quote"
                  nodeType={ELEMENT_PULLQUOTE}
                >
                  <Icons.blockquote />
                </TurnIntoToolbarButton>
                <TurnIntoToolbarButton
                  tooltip="Block quote"
                  nodeType={ELEMENT_BLOCKQUOTE}
                >
                  <Icons.textQuote />
                </TurnIntoToolbarButton>
              </ToolbarGroup>
            )}

            <ToolbarGroup>
              {(!toolbarOptions || toolbarOptions?.showLinkOption) && (
                <LinkToolbarButton />
              )}
              {(!toolbarOptions || toolbarOptions?.showAdvancedLinkOptions) && (
                <>
                  <InsertToolbarButton
                    tooltip="Web bookmark"
                    nodeType={WEB_BOOKMARK}
                  >
                    <Icons.globe />
                  </InsertToolbarButton>
                </>
              )}

              {(!toolbarOptions ||
                toolbarOptions?.showElementInsertOptions) && (
                <>
                  <InsertToolbarButton tooltip="Table" nodeType={ELEMENT_TABLE}>
                    <Icons.table />
                  </InsertToolbarButton>
                  <InsertToolbarButton tooltip="Image" nodeType={IMAGE_ELEMENT}>
                    <Icons.image />
                  </InsertToolbarButton>
                  <InsertToolbarButton
                    tooltip="File attachment"
                    nodeType={FILE}
                  >
                    <Icons.paperclip />
                  </InsertToolbarButton>
                </>
              )}

              {(!toolbarOptions || toolbarOptions?.showEmojiOption) && (
                <EmojiDropdownMenu />
              )}
            </ToolbarGroup>
          </>
        )}
      </Box>
    </div>
  );
}
