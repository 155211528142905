import clsx from "clsx";
import React from "react";
import { ListItemText, ListItemIcon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import {
  SortableListItemProps,
  SortableListItem,
} from "../sortable-list/SortableListItem";

import { MealLoggingQuestion } from "./types";
import { MealLoggingQuestionTypes } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {},

  primaryText: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export interface MealLoggingQuestionListItemProps
  extends SortableListItemProps<MealLoggingQuestion> {}

export function MealLoggingQuestionListItem(
  props: MealLoggingQuestionListItemProps,
) {
  const s = useStyles();
  const { className, ...other } = props;
  const { option } = props;
  const { Icon } = MealLoggingQuestionTypes[option.type];

  return (
    <SortableListItem
      className={clsx(s.root, className)}
      actions={[{ name: "edit", Icon: EditIcon }]}
      {...other}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: s.primaryText }}
        primary={option.text}
      />
    </SortableListItem>
  );
}
