import React from "react";

import { useOnlineStatus } from "./useOnlineStatus";

export interface NetworkConnectionState {
  disconnected: boolean;
  connecting: boolean;
}

const currentState: NetworkConnectionState = {
  disconnected: false,
  connecting: false,
};

export function useNetworkStatus() {
  const online = useOnlineStatus();
  const [state, setState] = React.useState<NetworkConnectionState>({
    ...currentState,
  });
  const connected = online || !state.disconnected;

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (
        currentState.connecting !== state.connecting ||
        currentState.disconnected !== state.disconnected
      ) {
        setState({ ...currentState });
      }
    }, 500);

    return () => clearInterval(timer);
  }, [state]);

  return [connected, state.connecting] as const;
}

export const updateNetworkStatus = (newState: NetworkConnectionState) =>
  Object.assign(currentState, newState);
