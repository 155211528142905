import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from "@udecode/plate-heading";
import { createIndentListPlugin } from "@udecode/plate-indent-list";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import {
  TodoLi,
  TodoMarker,
} from "../../../plate-ui/indent-todo-marker-component";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { createIndentPlugin } from "@udecode/plate-indent";

export const createIndentListPluginWithOptions = () =>
  createIndentListPlugin({
    inject: {
      props: {
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_H1,
          ELEMENT_H2,
          ELEMENT_H3,
          ELEMENT_BLOCKQUOTE,
        ],
      },
    },
    options: {
      listStyleTypes: {
        todo: {
          liComponent: TodoLi,
          markerComponent: TodoMarker,
          type: "todo",
        },
      },
    },
  });

export const createIndentPluginWithOptions = () =>
  createIndentPlugin({
    inject: {
      props: {
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_H1,
          ELEMENT_H2,
          ELEMENT_H3,
          ELEMENT_BLOCKQUOTE,
        ],
      },
    },
  });
