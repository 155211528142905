import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { useSnackAlert } from "../../../hooks/useSnackAlert";

import { RemoveClientFormMutation } from "./__generated__/RemoveClientFormMutation.graphql";

const mutation = graphql`
  mutation RemoveClientFormMutation($input: RemoveClientFormInput!) {
    removeClientForm(input: $input) {
      clientFormId
    }
  }
`;

export const useRemoveClientFormMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<RemoveClientFormMutation>(
    mutation,
    ({ onSuccess }) => ({
      configs: [
        {
          type: "RANGE_DELETE",
          parentID: "client:root",
          connectionKeys: [
            {
              key: "CoachClientsFormsListScreen_clientForms",
            },
          ],
          pathToConnection: ["client:root", "clientForms"],
          deletedIDFieldName: "clientFormId",
        },
      ],

      onSuccess: (response) => {
        snackAlert({
          severity: "success",
          message: "Client form deleted.",
        });

        if (onSuccess) {
          onSuccess(response);
        }
      },
    }),
  );
};
