import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as SendIcon } from "../../icons/SendMessage.svg";

const useStyles = makeStyles((theme) => ({
  root: {},

  icon: {
    padding: theme.spacing(1),

    "&:not([disabled]) svg": {
      color: theme.palette.primary.main,
    },
  },

  normal: {
    fontSize: 14,
    fontWeight: 700,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.75, 3.5),
    margin: theme.spacing(1, 1.5),
  },
}));

export interface MessageEditorSendButtonProps {
  className: string;
  onClick?: (event: any) => void;
  disabled?: boolean;
  variant: "icon" | "normal";
}

export function MessageEditorSendButton(props: MessageEditorSendButtonProps) {
  const { className, variant, ...other } = props;
  const s = useStyles();

  return variant === "icon" ? (
    <IconButton
      className={clsx(s.root, s.icon, className)}
      {...other}
      size="large"
    >
      <SendIcon />
    </IconButton>
  ) : (
    <Button
      variant="contained"
      className={clsx(s.root, s.normal, className)}
      {...other}
    >
      Send
    </Button>
  );
}
