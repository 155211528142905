import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";

export const ExtraMeasurementSetCustomizationInfoSection = () => {
  return (
    <InfoSection
      title="Customize Sets"
      description={
        <>
          Number of column values depends on value in <b>Sets</b> column.
        </>
      }
    >
      <InfoBox
        title="Comma"
        description="Define
  different value per each set using commas."
      >
        <ExampleValueBadge value="3, 2, 1" unit="RIR" />
      </InfoBox>

      <InfoBox
        title="Auto"
        description={
          <>
            Leave repetitive values blank. <br /> This will automatically apply
            value to all next sets.
          </>
        }
      >
        <ExampleValueBadge value="9" secondaryValue=", 9, 9" unit="REP" />
        <ExampleValueBadge value="2, 1" secondaryValue=", 2, 1" unit="RIR" />
      </InfoBox>
    </InfoSection>
  );
};
