import clsx from "clsx";
import React from "react";
import { Box, Button, lighten, BoxProps } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { useOnAnswerUpdate } from "../../../../hooks/useOnAnswerUpdate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: theme.spacing(2.5),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    height: theme.spacing(7),
    borderRadius: theme.spacing(0.5),

    "&$edit": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },

    "&$cancel": {
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
    },

    "&$disabled": {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.text.disabled,
    },

    "&$save": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.progress.green,
      marginLeft: theme.spacing(2),

      "&$disabled": {
        filter: theme.filters.disabled,
      },

      "&:hover": {
        backgroundColor: lighten(theme.palette.progress.green, 0.2),
      },
    },
  },

  edit: {},
  cancel: {},
  save: {},
  disabled: {},
}));

export interface EditResponseToolbarProps extends Omit<BoxProps, "children"> {
  onCancel: () => void;
  edit: boolean;
  anyEditing?: boolean;
  setEdit: (edit: boolean) => void;
  onUpdateAnswer: () => void;
  disableSave?: boolean;
}

export const EditResponseToolbar = (props: EditResponseToolbarProps) => {
  const {
    className,
    onCancel,
    edit,
    setEdit,
    anyEditing,
    onUpdateAnswer: onUpdateAnswerFromProps,
    disableSave,
    ...other
  } = props;
  const s = useStyles();
  const onUpdateAnswer = useOnAnswerUpdate();

  const handleEdit = React.useCallback(() => {
    setEdit(true);
  }, [setEdit]);

  const handleCancelEdit = React.useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSaveEdit = React.useCallback(() => {
    if (onUpdateAnswerFromProps) {
      onUpdateAnswerFromProps();
    } else {
      onUpdateAnswer();
    }
  }, [onUpdateAnswer]);

  const disabled = anyEditing && !edit;

  return onUpdateAnswer ? (
    <Box className={clsx(s.root, className)} {...other}>
      {edit ? (
        <>
          <Button
            className={clsx(s.button, s.cancel)}
            variant="outlined"
            fullWidth
            children="Cancel"
            onClick={handleCancelEdit}
          />
          <Button
            className={clsx(
              s.button,
              s.save,
              disableSave && { [s.disabled]: true },
            )}
            variant="contained"
            fullWidth
            children="Save"
            disabled={disableSave}
            onClick={handleSaveEdit}
          />
        </>
      ) : (
        <Button
          className={clsx(s.button, s.edit, disabled && { [s.disabled]: true })}
          variant="outlined"
          fullWidth
          disabled={disabled}
          children={disabled ? "Save your changes first" : "Edit Response"}
          onClick={handleEdit}
        />
      )}
    </Box>
  ) : null;
};
