/**
 * @generated SignedSource<<6a0b686f55be8b40ca1726dc90041c83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteAddedClientInput = {
  clientMutationId?: string | null | undefined;
  id: ReadonlyArray<string | null | undefined>;
  message: string;
};
export type PendingInviteItemSendEmailMutation$variables = {
  input: InviteAddedClientInput;
};
export type PendingInviteItemSendEmailMutation$data = {
  readonly inviteAddedClient: {
    readonly clientMutationId: string | null | undefined;
    readonly invites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"PendingInviteItem_invite">;
    }>;
  } | null | undefined;
};
export type PendingInviteItemSendEmailMutation = {
  response: PendingInviteItemSendEmailMutation$data;
  variables: PendingInviteItemSendEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingInviteItemSendEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteAddedClientPayload",
        "kind": "LinkedField",
        "name": "inviteAddedClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invite",
            "kind": "LinkedField",
            "name": "invites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PendingInviteItem_invite"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingInviteItemSendEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteAddedClientPayload",
        "kind": "LinkedField",
        "name": "inviteAddedClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invite",
            "kind": "LinkedField",
            "name": "invites",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMMM Do"
                  }
                ],
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": "createdAt(format:\"MMMM Do\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5102ae797be60c98aa66f20d3324cea4",
    "id": null,
    "metadata": {},
    "name": "PendingInviteItemSendEmailMutation",
    "operationKind": "mutation",
    "text": "mutation PendingInviteItemSendEmailMutation(\n  $input: InviteAddedClientInput!\n) {\n  inviteAddedClient(input: $input) {\n    invites {\n      ...PendingInviteItem_invite\n      id\n    }\n    clientMutationId\n  }\n}\n\nfragment PendingInviteItem_invite on Invite {\n  id\n  email\n  source\n  createdAt(format: \"MMMM Do\")\n  client {\n    id\n    clientStatus\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc034ab59c7186bc05fb4925782736e0";

export default node;
