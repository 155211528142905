/**
 * @generated SignedSource<<ac616af9388081d5e9bd66a472cb9601>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateClientFormInput = {
  clientMutationId?: string | null | undefined;
  content: string;
  description: string;
  id: string;
  image?: string | null | undefined;
  name: string;
};
export type UpdateClientFormMutation$variables = {
  input: UpdateClientFormInput;
};
export type UpdateClientFormMutation$data = {
  readonly updateClientForm: {
    readonly clientForm: {
      readonly " $fragmentSpreads": FragmentRefs<"ClientForm_clientForm">;
    };
  } | null | undefined;
};
export type UpdateClientFormMutation = {
  response: UpdateClientFormMutation$data;
  variables: UpdateClientFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientFormPayload",
        "kind": "LinkedField",
        "name": "updateClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientForm",
            "kind": "LinkedField",
            "name": "clientForm",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientForm_clientForm"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientFormPayload",
        "kind": "LinkedField",
        "name": "updateClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientForm",
            "kind": "LinkedField",
            "name": "clientForm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submittedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityFeedback",
                    "kind": "LinkedField",
                    "name": "feedbacks",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "photoURL",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "read",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "format",
                            "value": "fromNow"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": "createdAt(format:\"fromNow\")"
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coachReadAnswers",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1065007d9450c41f2b89b0121c41b476",
    "id": null,
    "metadata": {},
    "name": "UpdateClientFormMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateClientFormMutation(\n  $input: UpdateClientFormInput!\n) {\n  updateClientForm(input: $input) {\n    clientForm {\n      ...ClientForm_clientForm\n      id\n    }\n  }\n}\n\nfragment ActivityFeedbackButton_activity on Activity {\n  ...ActivityFeedbackList_activity\n  feedbacks {\n    id\n  }\n}\n\nfragment ActivityFeedbackList_activity on Activity {\n  feedbacks {\n    id\n    ...ActivityFeedback_activityFeedback\n  }\n  ...FeedbackForm_activity\n  id\n  coachReadAnswers\n}\n\nfragment ActivityFeedback_activityFeedback on ActivityFeedback {\n  id\n  author {\n    id\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ClientForm_clientForm on ClientForm {\n  id\n  requestId\n  name\n  description\n  image\n  content\n  updatedAt\n  submittedAt\n  activity {\n    ...ActivityFeedbackButton_activity\n    id\n  }\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "462b34fd728a19801214ec3c50cf47a8";

export default node;
