import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay/hooks";

import { useDebounce } from "../../hooks/useDebounce";

import { ExerciseLibraryExercisesList_rootRef$key } from "./__generated__/ExerciseLibraryExercisesList_rootRef.graphql";
import {
  ExerciseLibraryExercise,
  ExerciseLibraryExerciseProps,
} from "./ExerciseLibraryExercise";

import { ExerciseLibraryEmpty } from "./ExerciseLibraryEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(-4),
  },

  divider: {
    backgroundColor: theme.palette.quote,
  },

  subHeader: {
    textTransform: "uppercase",
    fontWeight: 700,
  },

  moreButton: {
    marginTop: theme.spacing(1.5),
  },

  loading: {
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const rootRefFragment = graphql`
  fragment ExerciseLibraryExercisesList_rootRef on Root
  @refetchable(queryName: "ExerciseLibraryExercisesListRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 0 }
    after: { type: "String" }
    query: { type: "String" }
  ) {
    exercises: custom_assets(
      first: $first
      after: $after
      query: $query
      assetType: [WORKOUT_EXERCISE]
      includeShared: true
    ) @connection(key: "ExerciseLibraryExercisesList_exercises", filters: []) {
      edges {
        node {
          id
          ...ExerciseLibraryExercise_asset
        }
      }
    }
  }
`;

export interface ExerciseLibraryExercisesListProps
  extends Omit<BoxProps, "onClick">,
    Pick<ExerciseLibraryExerciseProps, "onClick"> {
  rootRefRef: ExerciseLibraryExercisesList_rootRef$key;
  pageSize?: number;
  query?: string;
}

export function ExerciseLibraryExercisesList(
  props: ExerciseLibraryExercisesListProps,
) {
  const {
    className,
    pageSize = 12,
    query = "",
    rootRefRef,
    onClick,
    ...other
  } = props;
  const {
    data: rootRef,
    loadNext,
    hasNext,
    refetch: refetchConnection,
  } = usePaginationFragment(rootRefFragment, rootRefRef);
  const s = useStyles();
  const { exercises: assets } = rootRef;
  const [fetched, setFetched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const delayedQuery = useDebounce(query, 250);

  const setLoaded = React.useCallback(() => {
    setFetched(true);
    setLoading(false);
  }, []);

  const handleMoreClick = React.useCallback(() => {
    setLoading(true);
    loadNext(pageSize, { onComplete: setLoaded });
  }, [pageSize, loadNext, setLoaded]);

  React.useEffect(() => {
    if (!loading) {
      refetchConnection(
        {
          query: delayedQuery,
          first: pageSize,
        },
        {
          onComplete: setLoaded,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedQuery, pageSize, refetchConnection]);

  const edges = (assets?.edges || []).filter(({ node }) => node);

  if (!fetched) return null;
  if (!edges.length) return query ? null : <ExerciseLibraryEmpty />;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {assets ? (
        edges.map(({ node }, index) => (
          <React.Fragment key={node.id}>
            {index !== 0 && <Divider className={s.divider} />}
            <ExerciseLibraryExercise assetRef={node} onClick={onClick} />
          </React.Fragment>
        ))
      ) : (
        <Box className={s.loading}>
          <CircularProgress size={32} />
        </Box>
      )}

      {hasNext && (
        <Button
          onClick={handleMoreClick}
          className={s.moreButton}
          fullWidth
          disabled={loading}
        >
          Load more
        </Button>
      )}
    </Box>
  );
}
