import queryString from "query-string";

import { ClientSort, NavigationItem } from "../../constants";
import {
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_CLIENTS_ARCHIVED_ROUTE,
  COACH_CLIENTS_FORMS_ROUTE,
  COACH_CLIENTS_PENDING_ROUTE,
} from "../../routes/routes";

export const clientsNavigation = (): NavigationItem[] => {
  const query: any = () => ({
    // sort: queryString.parse(window.location.search).sort || ClientSort.NAME_ASC,
    sort: "",
    tag: "",
  });

  return [
    {
      name: "Active",
      link: COACH_CLIENTS_ACTIVE_ROUTE,
      query,
    },
    {
      name: "Pending",
      link: COACH_CLIENTS_PENDING_ROUTE,
      query,
    },
    {
      name: "Archived",
      link: COACH_CLIENTS_ARCHIVED_ROUTE,
      query,
    },
    {
      name: "Forms",
      link: COACH_CLIENTS_FORMS_ROUTE,
      query: () => ({}),
    },
  ];
};
