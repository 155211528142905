import React, { useContext } from "react";
import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";
import { useAnalytics } from "../../hooks/useAnalytics";

import {
  isIncompleteMediaNode,
  isCheckingNode,
  applyNodeFilters,
} from "../editor/utils/common";

import { BaseEditor } from "./BaseEditor";
import {
  BaseEditorDelayBuffer,
  BaseEditorDelayBufferProps,
} from "../editor/components/utils/BaseEditorDelayBuffer";
import { CoachComponentEditorSchema } from "../new-editor/editor-configuration/schemas/CoachComponentEditorSchema";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import { SchemaElements } from "../new-editor/utils/withSchema";
import SmallScreenAlert from "../dialog/SmallScreenAlert";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface ComponentEditorProps
  extends Omit<BaseEditorDelayBufferProps, "schema"> {
  skipCheckInQuestions?: boolean;
  componentType?: ComponentType;
}

const initialValue = [SchemaElements.getEmptyNode()];

export function ComponentEditor(props: any) {
  const { skipCheckInQuestions, componentType, delay = 1000, ...other } = props;
  const s = useStyles();
  const readOnly = props.readOnly;
  const [trackEvent] = useAnalytics();
  const valueFilters = [];

  // TODO: Make a plugin
  if (readOnly) {
    valueFilters.push(isIncompleteMediaNode);
    if (skipCheckInQuestions) {
      valueFilters.push(isCheckingNode);
    }
  }
  const value = applyNodeFilters(
    props.value && props.value.length ? props.value : initialValue,
    valueFilters,
  );

  const Editor = readOnly || !delay ? BaseEditor : BaseEditorDelayBuffer;

  const heightContext = useContext(ComponentBarHeightContext);

  return (
    <Box className={s.root}>
      <Editor
        placeholder={readOnly ? "" : "Add your content here"}
        value={value}
        trackEvent={trackEvent}
        inlineToolbar
        sidebar
        deserialize
        preNormalize
        multiline
        autoFocus
        editorSchema={CoachComponentEditorSchema}
        stickyTop={
          props.useStickyTop ? heightContext?.componentBarHeight : undefined
        }
        {...other}
      />
      {/* NOTE: Alert is shown only when component editable*/}
      {!readOnly && <SmallScreenAlert />}
    </Box>
  );
}

export default ComponentEditor;
