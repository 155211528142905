import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFragment } from "react-relay/hooks";
import { graphql } from "react-relay";

import { CardMedia } from "../card/CardMedia";
import { ComponentIcon } from "../program-component/ComponentIcon";

import { ComponentPreview_component$key } from "./__generated__/ComponentPreview_component.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  cover: {
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.background.paper,
    height: 141,
    margin: theme.spacing(0, 0, 2.5, 0),

    [theme.breakpoints.up("md")]: {
      width: 224,
      flexGrow: 0,
      flexShrink: 0,
      margin: theme.spacing(0, 2.5, 0, 0),
    },
  },

  content: {
    borderRadius: 12,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(3),
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    height: 141,
    flexGrow: 1,
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  title: {
    display: "flex",
    alignItems: "center",

    "& h6": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 600,
    },
  },
}));

const fragment = graphql`
  fragment ComponentPreview_component on Component
  @argumentDefinitions(draft: { type: "Boolean!", defaultValue: false }) {
    title(draft: $draft)
    teaser(draft: $draft)
    image(draft: $draft)
    ...ComponentIcon_component
  }
`;

export interface ComponentPreviewProps extends BoxProps {
  componentRef: ComponentPreview_component$key;
  teaser?: string;
  image?: string;
}

export function ComponentPreview(props: ComponentPreviewProps) {
  const {
    className,
    componentRef,
    teaser: _teaser,
    image: _image,
    ...other
  } = props;
  const s = useStyles();
  const component = useFragment(fragment, componentRef);
  const teaser = _teaser || component.teaser;
  const image = _image || component.image;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {image && <CardMedia className={s.cover} image={image} />}

      <Box className={s.content}>
        <Box className={s.title}>
          <ComponentIcon
            className={s.icon}
            variant="icon"
            size="small"
            componentRef={component}
          />
          <Typography variant="h6">{component.title}</Typography>
        </Box>

        <Typography variant="body1" color="textSecondary">
          {teaser}
        </Typography>
      </Box>
    </Box>
  );
}
