/**
 * @generated SignedSource<<1d069e720e61fdc7e3c5e530a62bdf41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientGoalsList_goals$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly currentValue: string | null | undefined;
      readonly date: string | null | undefined;
      readonly formattedDate: string | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly targetValue: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ClientGoalsList_goals";
};
export type ClientGoalsList_goals$key = {
  readonly " $data"?: ClientGoalsList_goals$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientGoalsList_goals">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientGoalsList_goals",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientGoalEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientGoal",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "raw",
                  "value": true
                }
              ],
              "kind": "ScalarField",
              "name": "date",
              "storageKey": "date(raw:true)"
            },
            {
              "alias": "formattedDate",
              "args": [
                {
                  "kind": "Literal",
                  "name": "format",
                  "value": "MMM D, YYYY"
                }
              ],
              "kind": "ScalarField",
              "name": "date",
              "storageKey": "date(format:\"MMM D, YYYY\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientGoalConnection",
  "abstractKey": null
};

(node as any).hash = "eda638642f1597099ccdd109e2dafe0d";

export default node;
