import React from "react";

import { ComponentLibraryAssetList_rootRef$key } from "../__generated__/ComponentLibraryAssetList_rootRef.graphql";

export const CustomAssetsContext =
  React.createContext<ComponentLibraryAssetList_rootRef$key>(null);

export function useCustomAssets() {
  return React.useContext(CustomAssetsContext);
}
