import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientGoalsList, ClientGoalsListProps } from "./ClientGoalsList";
import { ClientGoalsDrawerHeader } from "./ClientGoalsDrawerHeader";
import { ClientGoalListItemProps } from "./ClientGoalListItem";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    marginBottom: theme.spacing(2),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },
}));

export interface ClientGoalsDrawerListViewProps extends BoxProps {
  goals: any;
  onClose: () => void;
  onCreateClick: ButtonProps["onClick"];
  onItemActionClick: ClientGoalListItemProps["onAction"];
  onListUpdate: ClientGoalsListProps["onUpdate"];
}

export function ClientGoalsDrawerListView(
  props: ClientGoalsDrawerListViewProps,
) {
  const {
    className,
    goals,
    onClose,
    onCreateClick,
    onItemActionClick,
    onListUpdate,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientGoalsDrawerHeader
        className={s.header}
        showTitle
        onClose={onClose}
      />
      <Button
        className={s.button}
        variant="contained"
        children="Create a goal"
        onClick={onCreateClick}
      />
      <ClientGoalsList
        goals={goals}
        onItemActionClick={onItemActionClick}
        onUpdate={onListUpdate}
      />
    </Box>
  );
}
