"use client";

import React from "react";
import { Root, Indicator } from "@radix-ui/react-checkbox";
import { cn, withRef } from "@udecode/cn";

import { Icons } from "./Icons/icons";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    '&[data-state="checked"]': {
      backgroundColor: theme.palette.primary.main,
      border: "none",
    },
  },
}));

export const Checkbox = withRef<typeof Root>(({ className, ...props }, ref) => {
  const s = useStyles();

  return (
    <Root
      ref={ref}
      className={cn(
        "peer h-4 w-4 shrink-0 rounded-sm border border-primary bg-background ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#fff] data-[state=checked]:text-primary-foreground",
        s.root,
        className,
      )}
      {...props}
    >
      <Indicator
        className={cn("flex items-center justify-center text-current")}
      >
        <Icons.check className="h-4 w-4" />
      </Indicator>
    </Root>
  );
});
