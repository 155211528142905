import clsx from "clsx";
import React from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormGroupProps,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  MealLoggingQuestionCheckboxes,
  MealLoggingOption,
} from "../meal-logging-settings/types";

import { ClientMealLoggingQuestionProps } from "./types";
import { sortMealLoggingOptions } from "../meal-logging-settings/utils";

const useStyles = makeStyles((theme) => ({
  root: {},

  label: {
    width: "100%",
  },

  otherField: {
    "& > div": {
      backgroundColor: "transparent !important",
    },
  },
}));

type CheckboxesAnswer = string[];

function getInitialAnswer(answer: string): CheckboxesAnswer {
  return answer && answer !== "" ? JSON.parse(answer) : [];
}

function getInitialOtherText(
  options: MealLoggingOption[],
  answer: CheckboxesAnswer,
) {
  const promptText = answer.find(
    (value) => !options.find((option) => option.text === value),
  );

  return promptText || "";
}

export interface ClientMealLoggingCheckboxesQuestionProps
  extends FormGroupProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionCheckboxes> {}

export function ClientMealLoggingCheckboxesQuestion(
  props: ClientMealLoggingCheckboxesQuestionProps,
) {
  const s = useStyles();
  const { className, option, onUpdate, ...other } = props;
  const options = sortMealLoggingOptions(option.options);
  const initialAnswer: CheckboxesAnswer = getInitialAnswer(option.answer);
  const [answer, setAnswer] = React.useState<CheckboxesAnswer>(initialAnswer);
  const [otherText, setOtherText] = React.useState(
    getInitialOtherText(option.options, initialAnswer),
  );

  const handleAnswerChange = React.useCallback(
    (answer: CheckboxesAnswer) => {
      setAnswer(answer);

      if (onUpdate) {
        onUpdate({ ...option, answer: JSON.stringify(answer) });
      }
    },
    [onUpdate, option],
  );

  const handleChange = React.useCallback(
    (
      { target: { value } }: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      const answerIndex = answer.findIndex((v) => v === value);
      const newAnswer = [...answer];

      if (checked && value && !newAnswer.includes(value)) {
        newAnswer.push(value);
      } else if (answerIndex !== -1) {
        newAnswer.splice(answerIndex, 1);
      }

      handleAnswerChange(newAnswer);
    },
    [answer, handleAnswerChange],
  );

  const handleChangeOtherText = React.useCallback(
    ({
      currentTarget: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOtherText(value);

      const promptTextIndex = answer.findIndex(
        (v) => !options.find((option) => option.text === v),
      );
      const newAnswer = [...answer];

      if (promptTextIndex !== -1) {
        newAnswer.splice(promptTextIndex, 1);
      }

      if (value && !newAnswer.includes(value)) {
        newAnswer.push(value);
      }

      handleAnswerChange(newAnswer);
    },
    [answer, handleAnswerChange, options],
  );

  return (
    <FormGroup className={clsx(s.root, className)} {...other}>
      {options.map(({ id, text, prompt }) => {
        const value = prompt ? otherText : text;

        return (
          <FormControlLabel
            key={id}
            value={value}
            control={
              <Checkbox
                value={value}
                checked={Boolean(answer.find((a) => a === value))}
                onChange={handleChange}
              />
            }
            classes={{
              label: s.label,
            }}
            label={
              prompt ? (
                <TextField
                  className={s.otherField}
                  placeholder={text}
                  value={value}
                  onChange={handleChangeOtherText}
                  fullWidth
                />
              ) : (
                value
              )
            }
          />
        );
      })}
    </FormGroup>
  );
}
