/**
 * @generated SignedSource<<ce83e2e2b54102970237fc4b43b51011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PaymentOnlyPlan = "PROMO" | "UP_TO_100" | "UP_TO_200" | "UP_TO_50" | "UP_TO_UNLIMITED" | "YEARLY_UNLIMITED" | "YEARLY_UP_TO_100" | "YEARLY_UP_TO_200" | "YEARLY_UP_TO_50" | "%future added value";
export type RegisterCoachWithFreeLaunchInput = {
  clientMutationId?: string | null | undefined;
  displayName: string;
  email: string;
  password: string;
  plan?: PaymentOnlyPlan;
  timeZone: string;
};
export type RegisterCoachWithFreeLaunchMutation$variables = {
  input: RegisterCoachWithFreeLaunchInput;
};
export type RegisterCoachWithFreeLaunchMutation$data = {
  readonly registerCoachWithFreeLaunch: {
    readonly idToken: string;
  } | null | undefined;
};
export type RegisterCoachWithFreeLaunchMutation = {
  response: RegisterCoachWithFreeLaunchMutation$data;
  variables: RegisterCoachWithFreeLaunchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RegisterCoachWithFreeLaunchPayload",
    "kind": "LinkedField",
    "name": "registerCoachWithFreeLaunch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterCoachWithFreeLaunchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterCoachWithFreeLaunchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "370da4660e473cbefec361fe28ba5115",
    "id": null,
    "metadata": {},
    "name": "RegisterCoachWithFreeLaunchMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterCoachWithFreeLaunchMutation(\n  $input: RegisterCoachWithFreeLaunchInput!\n) {\n  registerCoachWithFreeLaunch(input: $input) {\n    idToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "41c8c2ad313cf72133bd6c47b30e93b6";

export default node;
