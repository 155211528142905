/**
 * @generated SignedSource<<408d7135921d03587faef5e41500410b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClientNutritionTargetDrawerEditCurrentViewQuery$variables = {
  clientId: string;
  targetId: string;
};
export type ClientNutritionTargetDrawerEditCurrentViewQuery$data = {
  readonly entries: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calories: number | null | undefined;
        readonly carbsGrams: number | null | undefined;
        readonly date: string | null | undefined;
        readonly fatGrams: number | null | undefined;
        readonly id: string;
        readonly proteinGrams: number | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type ClientNutritionTargetDrawerEditCurrentViewQuery = {
  response: ClientNutritionTargetDrawerEditCurrentViewQuery$data;
  variables: ClientNutritionTargetDrawerEditCurrentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetId"
},
v2 = [
  {
    "alias": "entries",
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Variable",
        "name": "targetId",
        "variableName": "targetId"
      }
    ],
    "concreteType": "ClientNutritionEntryConnection",
    "kind": "LinkedField",
    "name": "nutrition_entries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientNutritionEntryEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientNutritionEntry",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "carbsGrams",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fatGrams",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "proteinGrams",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calories",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientNutritionTargetDrawerEditCurrentViewQuery",
    "selections": (v2/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientNutritionTargetDrawerEditCurrentViewQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fdcfcf27586e9556decee6a29afd4497",
    "id": null,
    "metadata": {},
    "name": "ClientNutritionTargetDrawerEditCurrentViewQuery",
    "operationKind": "query",
    "text": "query ClientNutritionTargetDrawerEditCurrentViewQuery(\n  $targetId: ID!\n  $clientId: ID!\n) {\n  entries: nutrition_entries(targetId: $targetId, clientId: $clientId) {\n    edges {\n      node {\n        id\n        carbsGrams\n        fatGrams\n        proteinGrams\n        calories\n        date\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2a4925ae343ae662bc86ece866c71b1";

export default node;
