import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { SelectableItem, SelectableItemProps } from "./SelectableItem";
import { SelectableClient_client$key } from "./__generated__/SelectableClient_client.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const clientFragment = graphql`
  fragment SelectableClient_client on User {
    photoURL
    displayName
    email
  }
`;

export interface SelectableClientProps
  extends Pick<SelectableItemProps, "className" | "onClick"> {
  client: SelectableClient_client$key;
}

export function SelectableClient(props: SelectableClientProps) {
  const { className, client: clientRef, onClick, ...other } = props;
  const client = useFragment(clientFragment, clientRef);
  const s = useStyles();

  return (
    <SelectableItem
      className={clsx(s.root, className)}
      avatarSrc={client.photoURL}
      header={client.displayName}
      subheader={client.email}
      onClick={onClick}
      {...other}
    />
  );
}
