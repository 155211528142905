/**
 * @generated SignedSource<<ae0998917b8bca0ef46f4dbc516b3562>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ComponentTemplateDialogRefetchQuery$variables = {
  after?: string | null | undefined;
  componentSlug?: string | null | undefined;
  componentType?: ComponentType | null | undefined;
  first?: number | null | undefined;
  templateId?: string | null | undefined;
  withTemplate?: boolean | null | undefined;
};
export type ComponentTemplateDialogRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ComponentTemplateDialog_rootRef">;
};
export type ComponentTemplateDialogRefetchQuery = {
  response: ComponentTemplateDialogRefetchQuery$data;
  variables: ComponentTemplateDialogRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentType"
  },
  {
    "defaultValue": 99,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "templateId"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "withTemplate"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "componentSlug",
  "variableName": "componentSlug"
},
v3 = {
  "kind": "Variable",
  "name": "componentType",
  "variableName": "componentType"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ComponentTemplateDialogRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "templateId",
            "variableName": "templateId"
          },
          {
            "kind": "Variable",
            "name": "withTemplate",
            "variableName": "withTemplate"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ComponentTemplateDialog_rootRef"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ComponentTemplateDialogRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ComponentTemplateConnection",
        "kind": "LinkedField",
        "name": "componentTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ComponentTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComponentTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ComponentTemplateDialog_componentTemplates",
        "kind": "LinkedHandle",
        "name": "componentTemplates"
      },
      {
        "condition": "withTemplate",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "template",
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "templateId"
              }
            ],
            "concreteType": "ComponentTemplate",
            "kind": "LinkedField",
            "name": "componentTemplate",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "habitPrompt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconName",
                    "storageKey": null
                  }
                ],
                "type": "ComponentIcon",
                "abstractKey": "__isComponentIcon"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a9b1efc6b4de1af6c7ba610b8422c17a",
    "id": null,
    "metadata": {},
    "name": "ComponentTemplateDialogRefetchQuery",
    "operationKind": "query",
    "text": "query ComponentTemplateDialogRefetchQuery(\n  $after: String\n  $componentSlug: String\n  $componentType: ComponentType\n  $first: Int = 99\n  $templateId: ID = \"\"\n  $withTemplate: Boolean = false\n) {\n  ...ComponentTemplateDialog_rootRef_4dDIqL\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment ComponentTemplateDialog_rootRef_4dDIqL on Root {\n  template: componentTemplate(id: $templateId) @include(if: $withTemplate) {\n    id\n    title\n    habitPrompt\n    content\n    private\n    image\n    ...ComponentTemplatePreview_template\n  }\n  componentTemplates(first: $first, after: $after, componentType: $componentType, componentSlug: $componentSlug) {\n    edges {\n      node {\n        ...ComponentTemplateLibrary_template\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment ComponentTemplateLibrary_template on ComponentTemplate {\n  id\n  title\n  category\n  private\n}\n\nfragment ComponentTemplatePreview_template on ComponentTemplate {\n  ...ComponentIcon_component\n  id\n  title\n  content\n  type\n  habitPrompt\n  private\n}\n"
  }
};
})();

(node as any).hash = "5a7f5675ced6ad3d00a71d114545ccea";

export default node;
