import { useMutation } from "react-relay/hooks";
import { PayloadError } from "relay-runtime";

import {
  upsertAssetMutation,
  updateAssetsLibrary,
  updateExercisesLibrary,
} from "../../component-library/hooks/useCreateCustomAsset";
import { ExerciseAsset } from "../../exercise-library/types";
import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { SOMETHING_WENT_WRONG, CustomAssetType } from "../../../constants";
import { useCreateCustomAssetTypeMutation as UpsertAssetMutation } from "../../component-library/hooks/__generated__/useCreateCustomAssetTypeMutation.graphql";

type Options = {
  onCompleted?: (
    response: UpsertAssetMutation["response"],
    errors: PayloadError[],
  ) => void;
};

export function useSaveExercise() {
  const [upsertAsset] = useMutation<UpsertAssetMutation>(upsertAssetMutation);
  const snackAlert = useSnackAlert();

  return ({ assetId, exercise }: ExerciseAsset, options?: Options) => {
    const content = JSON.stringify(exercise);
    const variables: UpsertAssetMutation["variables"] = {
      input: {
        id: assetId,
        name: exercise.title,
        content,
        assetType: CustomAssetType.WORKOUT_EXERCISE,
      },
    };

    return upsertAsset({
      variables,
      onCompleted:
        options?.onCompleted ||
        ((_, errors) => {
          if (errors && errors.length) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: assetId
                ? "Exercise updated."
                : "Exercise added to library.",
            });
          }
        }),
      updater: (store) => {
        if (!assetId) {
          updateAssetsLibrary(store);
          updateExercisesLibrary(store);
        }
      },
    });
  };
}
