import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { NavigationFilter, FilterConfig } from "./NavigationFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "right",
    padding: theme.spacing(1),
  },
}));

export interface NavigationFilterListProps
  extends Omit<ContainerProps, "children"> {
  filters: FilterConfig[];
}

export function NavigationFilterList(props: NavigationFilterListProps) {
  const { className, filters, maxWidth = "md", ...other } = props;
  const s = useStyles();

  return (
    <Container
      className={clsx(s.root, className)}
      maxWidth={maxWidth}
      {...other}
    >
      {filters.map((filter) => (
        <NavigationFilter key={filter.name} filter={filter} />
      ))}
    </Container>
  );
}
