/**
 * @generated SignedSource<<3c5918a5a6fbf9d18c9d6947753e33b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagesList_messages$data = {
  readonly edges: ReadonlyArray<{
    readonly cursor: string;
    readonly node: {
      readonly author: {
        readonly id: string;
        readonly username: string;
      };
      readonly group: string | null | undefined;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"Message_message">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "MessagesList_messages";
};
export type MessagesList_messages$key = {
  readonly " $data"?: MessagesList_messages$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagesList_messages">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagesList_messages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Message",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "group",
              "args": [
                {
                  "kind": "Literal",
                  "name": "format",
                  "value": "dddd DD MMM yyyy"
                }
              ],
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": "createdAt(format:\"dddd DD MMM yyyy\")"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Message_message"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0a2f2ebe39f797e0e7d462865f5c2990";

export default node;
