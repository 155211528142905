import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NotificationsNone } from "@mui/icons-material";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";

import { NotificationsMenu } from "../menu/NotificationsMenu";

import { Notifications_user$key } from "./__generated__/Notifications_user.graphql";
import { NotificationsClickedMutation } from "./__generated__/NotificationsClickedMutation.graphql";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
    padding: 8,

    "&$unread": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    [theme.breakpoints.up("md")]: {
      padding: 10,
    },
  },

  unreadCount: {
    width: 18,
    height: 18,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: "50%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: -5,
    right: -5,
    fontSize: 11,
    fontWeight: "bold",
  },

  unread: {},
}));

const userFragment = graphql`
  fragment Notifications_user on User {
    id
    notificationsActive

    notifications(first: 10, after: null)
      @connection(key: "NotificationsMenu_notifications") {
      unreadCount
      edges {
        cursor
      }
    }
    ...NotificationsMenu_userRef
  }
`;

const notificationsClickedMutation = graphql`
  mutation NotificationsClickedMutation($input: NotificationsClickedInput!) {
    notificationsClicked(input: $input) {
      user {
        ...Notifications_user
      }
    }
  }
`;

export interface NotificationsProps {
  userRef: Notifications_user$key;
  className?: string;
}

export function Notifications(props: NotificationsProps) {
  const { userRef, className } = props;
  const { notificationsActive, notificationUnreadCount } = useCurrentUser();
  const s = useStyles();
  const [menuEl, setMenuEl] = React.useState<Element | null>(null);
  const user = useFragment(userFragment, userRef);
  const [notificationsClicked] = useMutation<NotificationsClickedMutation>(
    notificationsClickedMutation,
  );

  const handleClick: IconButtonProps["onClick"] = React.useCallback(
    (event) => {
      setMenuEl(event.currentTarget);
      notificationsClicked({
        variables: { input: {} },
        updater(store) {
          store.get(user.id).setValue(false, "notificationsActive");
        },
      });
    },
    [notificationsClicked, user.id],
  );

  const closeMenu = React.useCallback(() => {
    setMenuEl(null);
  }, []);

  const active = Boolean(notificationsActive && notificationUnreadCount);

  return (
    <>
      <IconButton
        className={clsx(s.button, className, active && s.unread)}
        onClick={handleClick}
        aria-owns={menuEl ? "notifications-menu" : undefined}
        aria-haspopup="true"
        size="large"
      >
        <NotificationsNone />
        {active && (
          <Box className={s.unreadCount}>{notificationUnreadCount}</Box>
        )}
      </IconButton>
      <NotificationsMenu
        id="notifications-menu"
        role="menu"
        anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={closeMenu}
        userRefRef={user}
      />
    </>
  );
}
