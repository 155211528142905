/**
 * @generated SignedSource<<1d753ab3bd10590b6cf5ec2b70a7382c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ToggleClientArchivedInput = {
  archived: boolean;
  clientMutationId?: string | null | undefined;
  id: string;
};
export type ClientSummaryUpdateUserMutation$variables = {
  input: ToggleClientArchivedInput;
};
export type ClientSummaryUpdateUserMutation$data = {
  readonly toggleClientArchived: {
    readonly client: {
      readonly " $fragmentSpreads": FragmentRefs<"ClientSummary_client">;
    } | null | undefined;
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type ClientSummaryUpdateUserMutation = {
  response: ClientSummaryUpdateUserMutation$data;
  variables: ClientSummaryUpdateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSummaryUpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleClientArchivedPayload",
        "kind": "LinkedField",
        "name": "toggleClientArchived",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientSummary_client"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientSummaryUpdateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ToggleClientArchivedPayload",
        "kind": "LinkedField",
        "name": "toggleClientArchived",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "photoURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "age",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "fromNow"
                  }
                ],
                "kind": "ScalarField",
                "name": "lastLoginAt",
                "storageKey": "lastLoginAt(format:\"fromNow\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c9d1e33666d9307925a8d2d0ab72f70",
    "id": null,
    "metadata": {},
    "name": "ClientSummaryUpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation ClientSummaryUpdateUserMutation(\n  $input: ToggleClientArchivedInput!\n) {\n  toggleClientArchived(input: $input) {\n    clientMutationId\n    client {\n      ...ClientSummary_client\n      id\n    }\n  }\n}\n\nfragment ClientSummary_client on User {\n  id\n  displayName\n  email\n  photoURL\n  age\n  gender\n  weight\n  height\n  units\n  location\n  clientStatus\n  lastLoginAt(format: \"fromNow\")\n}\n"
  }
};
})();

(node as any).hash = "8ea4ed8d61344bf379ccdcc8e29421e6";

export default node;
