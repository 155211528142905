/**
 * @generated SignedSource<<923fc28d8eca16346623926ba37635b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateGroupClientsInput = {
  clientMutationId?: string | null | undefined;
  emails: ReadonlyArray<string>;
  id: string;
};
export type ManageGroupDialogUpdateGroupClientsMutation$variables = {
  input: UpdateGroupClientsInput;
};
export type ManageGroupDialogUpdateGroupClientsMutation$data = {
  readonly updateGroupClients: {
    readonly group: {
      readonly " $fragmentSpreads": FragmentRefs<"GroupCard_group" | "GroupMembersList_groupRef">;
    };
  } | null | undefined;
};
export type ManageGroupDialogUpdateGroupClientsMutation = {
  response: ManageGroupDialogUpdateGroupClientsMutation$data;
  variables: ManageGroupDialogUpdateGroupClientsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageGroupDialogUpdateGroupClientsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGroupClientsPayload",
        "kind": "LinkedField",
        "name": "updateGroupClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GroupCard_group"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GroupMembersList_groupRef"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageGroupDialogUpdateGroupClientsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGroupClientsPayload",
        "kind": "LinkedField",
        "name": "updateGroupClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "GroupMemberConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "members(first:10)"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "GroupMembersList_members",
                "kind": "LinkedHandle",
                "name": "members"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45ab826ada03fc20f75d000778f0ff64",
    "id": null,
    "metadata": {},
    "name": "ManageGroupDialogUpdateGroupClientsMutation",
    "operationKind": "mutation",
    "text": "mutation ManageGroupDialogUpdateGroupClientsMutation(\n  $input: UpdateGroupClientsInput!\n) {\n  updateGroupClients(input: $input) {\n    group {\n      ...GroupCard_group\n      ...GroupMembersList_groupRef\n      id\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  displayName\n  photoURL\n}\n\nfragment GroupCard_group on Group {\n  ...ManageGroupDialog_group\n  id\n  name\n  slug\n  archived\n  clients {\n    ...StackedClientsAvatars_clients\n    id\n  }\n}\n\nfragment GroupMemberListItem_member on User {\n  ...Avatar_user\n  id\n  email\n  username\n  displayName\n}\n\nfragment GroupMembersList_groupRef on Group {\n  id\n  members(first: 10) {\n    edges {\n      node {\n        id\n        ...GroupMemberListItem_member\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ManageGroupDialog_group on Group {\n  id\n  name\n  size\n  clients {\n    email\n    displayName\n    id\n  }\n}\n\nfragment StackedClientsAvatars_clients on User {\n  id\n  displayName\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "d9ce6d2203e2c786f50e865489ad4e96";

export default node;
