import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CompletedIcon } from "../../icons/CheckMarkOutline.svg";
import { ReactComponent as NotCompletedIcon } from "../../icons/Close.svg";
import { getISODate } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    "&::before, &::after": {
      content: '""',
      opacity: 0.2,
      backgroundColor: theme.palette.primary.main,
      display: "block",
      height: theme.spacing(6),
      width: theme.spacing(1),
    },

    "&::before": {
      borderTopLeftRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
    },

    "&::after": {
      borderTopRightRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
  },

  day: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",

    "&::before": {
      content: '""',
      opacity: 0.2,
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(6),
      width: "100%",
      display: "block",
      position: "absolute",
    },

    "& svg": {
      height: theme.spacing(4),
      margin: theme.spacing(1, 0.5),

      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(1),
      },
    },

    "& p": {
      marginTop: theme.spacing(0.5),
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
      fontSize: 12,
      fontWeight: 600,
    },

    "& svg [fill]": {
      fill: theme.palette.primary.main,
    },
    "& svg [stroke]": {
      stroke: theme.palette.primary.main,
    },

    "&:not($completed) svg": {
      opacity: 0.2,
    },
  },

  completed: {},
}));

export interface ActivityCompletionProgress {
  date: string;
  completed: boolean;
}

export interface ActivityCompletionProps extends BoxProps {
  progress: ActivityCompletionProgress[];
  onDateClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    date: string,
  ) => void;
}

export function ActivityCompletion(props: ActivityCompletionProps) {
  const { className, progress, onDateClick, ...other } = props;
  const s = useStyles();
  const today = getISODate();
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const {
        dataset: { activityDate },
      } = event.currentTarget;

      if (activityDate && onDateClick) {
        onDateClick(event, activityDate);
      }
    },
    [onDateClick],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {progress.map(({ date, completed }) => (
        <Box
          key={date}
          className={clsx(s.day, {
            [s.completed]: completed,
          })}
          data-activity-date={date}
          onClick={handleClick}
        >
          {completed || date > today ? <CompletedIcon /> : <NotCompletedIcon />}
          <Typography variant="body2">
            {new Date(date).toUTCString().split(",")[0]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
