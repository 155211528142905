import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, Typography, Box, Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { getMimeTypes, uploadFile } from "../../utils/file";
import { useNativeDropzone } from "../../utils/device";
import { AssetType } from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ElementType } from "../editor/types/elements";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
    background: theme.palette.depressed,
    borderColor: theme.palette.border.primary,
    padding: theme.spacing(2, 2.75),
  },

  avatar: {
    margin: 0,
  },

  label: {
    fontWeight: 500,
    marginLeft: theme.spacing(3),
  },

  large: {
    display: "inherit",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  small: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "inherit",
    },
  },

  pseudoLink: {
    textDecoration: "underline",
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
}));

export interface UploadAvatarProps
  extends Omit<ButtonProps, "onChange" | "onError"> {
  value: string;
  onChange: (value: string) => void;
  onError: (error: string) => void;
  uploadOnly?: boolean;
}

const uploadMutation = graphql`
  mutation UploadAvatarCreateUrlMutation(
    $id: ID!
    $file: String!
    $type: AssetType!
  ) {
    createSignedUrl(id: $id, file: $file, type: $type) {
      url
    }
  }
`;

const updateUserMutation = graphql`
  mutation UploadAvatarUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        photoURL
      }
    }
  }
`;

export function UploadAvatar(props: UploadAvatarProps) {
  const {
    className,
    value,
    onChange,
    onError,
    children,
    uploadOnly,
    ...other
  } = props;
  const user = useCurrentUser();
  const s = useStyles();

  const [uploading, setUploading] = React.useState(false);
  const [createUploadUrl] = useMutation(uploadMutation);
  const [updateUser] = useMutation(updateUserMutation);

  const onUpload = React.useCallback(
    (file) => {
      setUploading(true);
      createUploadUrl({
        variables: {
          id: user.id,
          file: file[0].name,
          type: AssetType.USER_PHOTO,
        },
        onCompleted: ({ createSignedUrl }: any, errors) => {
          let { url } = createSignedUrl;
          if (errors) {
            onError(errors[0].message);
            setUploading(false);
          } else {
            uploadFile(url, file[0])
              .then(() => {
                url = new URL(`https:/${url.substr(url.indexOf("/", 8))}`);
                url.search = "";
                const photoURL = String(url);

                onChange(photoURL);
                setUploading(false);

                if (!uploadOnly) {
                  updateUser({
                    variables: { input: { id: user.id, photoURL } },
                    onCompleted(_, errors) {
                      if (errors && errors.length > 0) {
                        console.error(errors[0]);
                        onError(errors[0].message);
                      }
                    },
                  });
                }
              })
              .catch((err) => {
                onError(err.message);
                setUploading(false);
                console.error(err);
              });
          }
        },
      });
    },
    [createUploadUrl, updateUser, onError, onChange, user, uploadOnly],
  );

  const { getRootProps, getInputProps, isDragActive } = useNativeDropzone({
    onDrop: onUpload,
    accept: getMimeTypes(ElementType.IMAGE),
  });

  return children ? (
    <Box className={clsx(className)} {...(getRootProps() as any)}>
      <input type="file" name="photoURL" {...getInputProps()} />
      {children}
    </Box>
  ) : (
    <Button
      {...(getRootProps() as any)}
      className={clsx(s.root, className)}
      variant="outlined"
      fullWidth
      color="primary"
      {...other}
    >
      <input type="file" name="photoURL" {...getInputProps()} />
      <Avatar className={s.avatar} src={value} />
      <Typography className={s.label} color="textSecondary">
        {uploading ? (
          "Uploading..."
        ) : isDragActive ? (
          "Drop the file here ..."
        ) : (
          <>
            <Box component="span" className={s.large}>
              Drag a profile photo or{" "}
              <Typography className={s.pseudoLink} component="span">
                upload
              </Typography>
            </Box>

            <Box component="span" className={s.small}>
              <Typography className={s.pseudoLink} component="span">
                Upload profile photo
              </Typography>
            </Box>
          </>
        )}
      </Typography>
    </Button>
  );
}
