import { AuthProvider } from "../constants";

const storage = "localStorage" in window && localStorage;
const tokenKey = "auth-token";

export const authStorage = {
  getToken() {
    return storage && storage.getItem(tokenKey);
  },

  setToken(token: string) {
    return storage && storage.setItem(tokenKey, token);
  },

  resetToken() {
    return storage && storage.removeItem(tokenKey);
  },
};

export type AuthTokenData = {
  provider: AuthProvider;
  id_token: string;
  email?: string;
  displayName?: string;
  photoURL?: string;
};

export interface AuthButtonProps {
  onSuccess?: (data: AuthTokenData) => void;
  onFailure?: (error: Error) => void;
}
