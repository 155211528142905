import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Box,
  Typography,
  Button,
  Portal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded } from "@mui/icons-material";
import { graphql, useFragment } from "react-relay";
import omit from "lodash.omit";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";

import { colorSystem } from "../../theme";
import { EmptyValueBars } from "../item/EmptyValueBars";
import { ClientGoalsDrawer } from "../client-goals/ClientGoalsDrawer";
import { ClientGoal } from "../client-goals/ClientGoal";

import { ReactComponent as GoalsIcon } from "../../icons/Goals.svg";

import { ClientGoalsCard_goals$key } from "./__generated__/ClientGoalsCard_goals.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: 4,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  setButton: {
    backgroundColor: colorSystem.gray1,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    height: 44,
    padding: theme.spacing(1.5, 7.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  updateButton: {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    fontSize: 16,
    fontWeight: 600,
    boxShadow: "none",
    height: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  updateButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: "25px!important",
  },

  goals: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    marginTop: theme.spacing(4.5),

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },

  goal: {
    justifyContent: "space-between",
    marginRight: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0),
    },

    "&:nth-child(n+2)": {
      marginTop: theme.spacing(3),
    },

    [theme.breakpoints.up("sm")]: {
      "&:nth-child(n+2)": {
        marginTop: "initial",
      },
      "&:nth-child(n+3)": {
        marginTop: theme.spacing(3),
      },
    },

    [theme.breakpoints.up("md")]: {
      "&:nth-child(n+3)": {
        marginTop: "initial",
      },
      "&:nth-child(n+4)": {
        marginTop: theme.spacing(3),
      },
    },
  },

  goalsTopContainer: {
    display: "flex",
    alignItems: "center",
  },

  goalsContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: theme.spacing(2),
  },

  empty: {
    "&:not(:last-child)": {
      paddingRight: theme.spacing(1),
    },
  },
}));

const goalsFragment = graphql`
  fragment ClientGoalsCard_goals on ClientGoalConnection {
    totalCount
    edges {
      node {
        id
        updatedAt(format: "MMM DD, YYYY")
        ...ClientGoal_goal
      }
    }
    ...ClientGoalsDrawer_goals
  }
`;

export interface ClientGoalsCardProps extends CardProps {
  goalsRef: ClientGoalsCard_goals$key;
  refresh?: () => void;
}

export function ClientGoalsCard(props: ClientGoalsCardProps) {
  const { className, goalsRef, refresh, ...other } = props;
  const goals = useFragment(goalsFragment, goalsRef);
  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-goals",
  });

  return (
    <>
      <Card className={clsx(s.root, className)} {...other}>
        <Box className={s.header}>
          <Box className={s.goalsTopContainer}>
            <GoalsIcon />
            <div className={s.goalsContent}>
              <Typography variant="h6" className={s.title} children="Goals" />
              {Boolean(goals.totalCount) && (
                <Typography variant="body1" className={s.subtitle}>
                  Last recorded {goals.edges[0].node.updatedAt}
                </Typography>
              )}
            </div>
          </Box>

          {goals.totalCount && smUp ? (
            <Button
              className={s.updateButton}
              endIcon={<ArrowForwardRounded className={s.updateButtonIcon} />}
              children="Update"
              {...bindToggle(drawerState)}
            />
          ) : (
            smUp && (
              <Button
                className={s.setButton}
                variant="contained"
                children="Set Goals"
                {...bindToggle(drawerState)}
              />
            )
          )}
        </Box>

        <Box className={s.goals}>
          {goals.totalCount
            ? goals.edges.map(({ node: goal }) => (
                <ClientGoal key={goal.id} className={s.goal} goalRef={goal} />
              ))
            : Array.from({ length: 3 }).map((_, i) => (
                <EmptyValueBars key={i} className={s.empty} />
              ))}
        </Box>
        {goals.totalCount && !smUp ? (
          <Button
            className={s.updateButton}
            endIcon={<ArrowForwardRounded className={s.updateButtonIcon} />}
            children="Update"
            {...bindToggle(drawerState)}
          />
        ) : (
          !smUp && (
            <Button
              className={s.setButton}
              variant="contained"
              children="Set Goals"
              {...bindToggle(drawerState)}
            />
          )
        )}
      </Card>

      {drawerState.isOpen && (
        <Portal>
          <ClientGoalsDrawer
            {...omit(bindPopper(drawerState), ["anchorEl"])}
            onClose={drawerState.close}
            goals={goals}
            refresh={refresh}
          />
        </Portal>
      )}
    </>
  );
}
