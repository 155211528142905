import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import {
  CheckInComponentStatus,
  EditorElementView,
} from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";

export type IYesNoAnswerBinaryValue = "yes" | "no";

export type IYesNoQuestionAnswer = {
  selected?: IYesNoAnswerBinaryValue;
};

const YesNoQuestion = ({
  view,
  disableAnswer,
  element,
  handleSetNode,
}: ICheckInQuestionPropsBase<IYesNoQuestionAnswer>) => {
  const answer = element.answer as IYesNoQuestionAnswer;

  const isCoachView = view === EditorElementView.Coach;
  const disabled = isCoachView || disableAnswer;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as IYesNoAnswerBinaryValue;
    handleSetNode({ answer: { selected: newValue } });
  };

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      <RadioGroup
        row
        value={answer?.selected}
        onChange={handleChange}
        aria-disabled={disabled}
      >
        <FormControlLabel
          disabled={disabled}
          value={"yes"}
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          disabled={disabled}
          value={"no"}
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </DisabledCheckInTooltip>
  );
};

export default YesNoQuestion;
