import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  TextField,
  Button,
  TextFieldProps,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { BaseDialog } from "../dialog/BaseDialog";
import {
  ClientAddableItemProps,
  ClientAddableItem,
} from "../item/ClientAddableItem";
import {
  GroupAddableItemProps,
  GroupAddableItem,
} from "../item/GroupAddableItem";
import { AddedLozenge } from "../item/AddedLozenge";
import { ClientAddableItem_client$data } from "../item/__generated__/ClientAddableItem_client.graphql";
import { GroupAddableItem_group$data } from "../item/__generated__/GroupAddableItem_group.graphql";
import { EnrollDialogEmpty } from "../dialog/EnrollDialogEmpty";
import { DynamicSearch } from "../list/DynamicSearch";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { useDebounce } from "../../hooks/useDebounce";

import { EnrollDialog_groups$key } from "./__generated__/EnrollDialog_groups.graphql";
import { EnrollDialog_clients$key } from "./__generated__/EnrollDialog_clients.graphql";
import { EnrollDialog_program$key } from "./__generated__/EnrollDialog_program.graphql";
import { EnrollDialogSearchClients_fragment$data } from "./__generated__/EnrollDialogSearchClients_fragment.graphql";
import { EnrollDialogSearchGroups_fragment$data } from "./__generated__/EnrollDialogSearchGroups_fragment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  tabs: {},

  tab: {
    maxWidth: "initial",
    flexGrow: 1,
    fontSize: 14,
    fontWeight: "bold",
    color: `${theme.palette.secondary.main}99`,
    "&$selected": {
      color: theme.palette.primary.main,
    },
  },

  divider: {
    height: 1,
    backgroundColor: theme.palette.border.primary,
    marginTop: -1,
  },

  input: {
    marginTop: theme.spacing(3),
  },

  lozenges: {
    padding: theme.spacing(2, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  lozenge: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  list: {
    height: 350,
    overflowY: "auto",
  },

  item: {
    padding: theme.spacing(2.5, 0),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },

  selected: {},
}));

const programFragment = graphql`
  fragment EnrollDialog_program on Program {
    id
    name
  }
`;

const clientsFragment = graphql`
  fragment EnrollDialog_clients on ClientConnection {
    totalCount
  }
`;

const groupsFragment = graphql`
  fragment EnrollDialog_groups on GroupConnection {
    totalCount
  }
`;

const tabs = ["clients", "groups"];

export interface EnrollmentSelected {
  clients?: ClientAddableItem_client$data[];
  groups?: GroupAddableItem_group$data[];
}

export interface EnrollDialogProps extends DialogProps {
  programRef: EnrollDialog_program$key;
  clientsRef: EnrollDialog_clients$key;
  groupsRef: EnrollDialog_groups$key;
  onSelected: (selected: EnrollmentSelected) => void;
}

export function EnrollDialog(props: EnrollDialogProps) {
  const {
    className,
    onClose,
    programRef,
    clientsRef,
    groupsRef,
    onSelected,
    ...other
  } = props;
  const s = useStyles();
  const program = useFragment(programFragment, programRef);
  const clients = useFragment(clientsFragment, clientsRef);
  const groups = useFragment(groupsFragment, groupsRef);
  const [filter, setFilter] = React.useState("");
  const delayedFilter = useDebounce(filter, 250);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [addedClients, setAddedClients] = React.useState<
    ClientAddableItem_client$data[]
  >([]);
  const [addedGroups, setAddedGroups] = React.useState<
    GroupAddableItem_group$data[]
  >([]);

  const handleFilterChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [],
  );

  const getTabClickHandler = React.useCallback(
    (index) => () => {
      setSelectedTab(index);
    },
    [],
  );

  const removeClient = React.useCallback(
    (email: string) => {
      const i = addedClients.findIndex(
        (addedClient) => addedClient.email === email,
      );

      if (i !== -1) {
        const updated = [...addedClients];
        updated.splice(i, 1);
        setAddedClients(updated);
      }
    },
    [addedClients],
  );

  const removeGroup = React.useCallback(
    (id: string) => {
      const i = addedGroups.findIndex((addedGroup) => addedGroup.id === id);

      if (i !== -1) {
        const updated = [...addedGroups];
        updated.splice(i, 1);
        setAddedGroups(updated);
      }
    },
    [addedGroups],
  );

  const handleToggleClient: ClientAddableItemProps["onToggle"] =
    React.useCallback(
      (added, client) => {
        if (added) {
          setAddedClients((v) => [...v, client]);
        } else {
          removeClient(client.email);
        }
      },
      [removeClient],
    );

  const handleToggleGroup: GroupAddableItemProps["onToggle"] =
    React.useCallback(
      (added, group) => {
        if (added) {
          setAddedGroups((v) => [...v, group]);
        } else {
          removeGroup(group.id);
        }
      },
      [removeGroup],
    );

  const getRemoveClientHandler = React.useCallback(
    (email: string) => () => removeClient(email),
    [removeClient],
  );

  const getRemoveGroupHandler = React.useCallback(
    (id: string) => () => removeGroup(id),
    [removeGroup],
  );

  const resetState = React.useCallback(() => {
    setFilter("");
    setAddedClients([]);
    setAddedGroups([]);
  }, []);

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  const handleNextClick = React.useCallback(() => {
    const selected = {
      ...(selectedTab === 0
        ? {
            clients: addedClients,
          }
        : {
            groups: addedGroups,
          }),
    };
    handleClose();
    onSelected(selected);
  }, [addedClients, addedGroups, handleClose, onSelected, selectedTab]);

  const entity = React.useMemo(
    () => tabs[selectedTab].substring(0, tabs[selectedTab].length - 1),
    [selectedTab],
  );

  const count = React.useMemo(
    () => (selectedTab === 0 ? clients : groups).totalCount,
    [clients, groups, selectedTab],
  );

  const nextButton = (
    <>
      <Divider />

      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="Next"
        onClick={handleNextClick}
        disabled={
          selectedTab === 0 ? !addedClients.length : !addedGroups.length
        }
      />
    </>
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={`Enroll ${entity}s`}
      subtitle={`Select ${entity}s to enroll into ${program.name}`}
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Tabs
        className={s.tabs}
        value={selectedTab}
        variant="scrollable"
        indicatorColor="primary"
      >
        {tabs.map((name, index) => (
          <Tab
            key={index}
            label={name}
            value={index}
            className={clsx(s.tab, selectedTab === index && s.selected)}
            onClick={getTabClickHandler(index)}
          />
        ))}
      </Tabs>
      <Divider className={s.divider} orientation="horizontal" />

      <TextField
        className={s.input}
        variant="outlined"
        fullWidth
        value={filter}
        onChange={handleFilterChange}
        label={`Filter ${count} ${entity}${count === 1 ? "" : "s"}`}
      />

      {/* Temporarily remove lozenges with false */}
      {(Boolean(addedClients.length) || Boolean(addedGroups.length)) &&
        false && (
          <Box className={s.lozenges}>
            {selectedTab === 0
              ? addedClients.map(({ email, displayName }) => (
                  <AddedLozenge
                    key={email}
                    className={s.lozenge}
                    text={displayName}
                    onRemoveClick={getRemoveClientHandler(email)}
                  />
                ))
              : addedGroups.map(({ id, name }) => (
                  <AddedLozenge
                    key={id}
                    className={s.lozenge}
                    text={name}
                    onRemoveClick={getRemoveGroupHandler(id)}
                  />
                ))}
          </Box>
        )}

      {selectedTab === 0 ? (
        <DynamicSearch<EnrollDialogSearchClients_fragment$data>
          query={graphql`
            query EnrollDialogSearchClientsQuery(
              $first: Int!
              $after: String
              $query: String
            ) {
              ...EnrollDialogSearchClients_fragment
                @arguments(first: $first, query: $query, after: $after)
            }
          `}
          filter={delayedFilter}
          fragment={graphql`
            fragment EnrollDialogSearchClients_fragment on Root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              query: { type: "String" }
            ) {
              connection: clients(first: $first, after: $after, query: $query)
                @connection(key: "Clients_connection", filters: [$query]) {
                totalCount

                pageInfo {
                  hasNextPage
                }

                edges {
                  cursor
                  node {
                    id
                    email
                    displayName
                    programs {
                      id
                    }
                    ...ClientAddableItem_client
                  }
                }
              }
            }
          `}
        >
          {({ connection: { edges }, hasMore, onMoreClick, loading }) => (
            <>
              <Box className={s.list}>
                {edges.length
                  ? edges.map(({ node }, index) => (
                      <React.Fragment key={node.id}>
                        <ClientAddableItem
                          className={s.item}
                          clientRef={node}
                          added={Boolean(
                            addedClients.find(
                              ({ email }) => email === node.email,
                            ),
                          )}
                          disabled={node.programs.some(
                            ({ id: programId }) => programId === program.id,
                          )}
                          disabledText="This client is already enrolled in the program."
                          onToggle={handleToggleClient}
                        />
                        {index < edges.length - 1 && <Divider />}
                      </React.Fragment>
                    ))
                  : !loading && (
                      <EnrollDialogEmpty
                        header={
                          delayedFilter
                            ? "No clients match your search"
                            : "No clients"
                        }
                        filter={filter}
                      />
                    )}
                {hasMore && (
                  <LoadMoreButton onClick={onMoreClick} disabled={loading} />
                )}
              </Box>
              {edges.length > 0 && nextButton}
            </>
          )}
        </DynamicSearch>
      ) : (
        <DynamicSearch<EnrollDialogSearchGroups_fragment$data>
          query={graphql`
            query EnrollDialogSearchGroupsQuery(
              $first: Int!
              $after: String
              $query: String
            ) {
              ...EnrollDialogSearchGroups_fragment
                @arguments(first: $first, query: $query, after: $after)
            }
          `}
          filter={delayedFilter}
          fragment={graphql`
            fragment EnrollDialogSearchGroups_fragment on Root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              query: { type: "String" }
            ) {
              connection: groups(
                first: $first
                after: $after
                query: $query
                status: ACTIVE
              ) @connection(key: "Groups_connection", filters: [$query]) {
                totalCount

                pageInfo {
                  hasNextPage
                }

                edges {
                  cursor
                  node {
                    id
                    name
                    size
                    programs {
                      id
                    }
                    ...GroupAddableItem_group
                  }
                }
              }
            }
          `}
        >
          {({ connection: { edges }, hasMore, onMoreClick, loading }) => (
            <>
              <Box className={s.list}>
                {edges.map(({ node }, index) => (
                  <React.Fragment key={node.id}>
                    <GroupAddableItem
                      className={s.item}
                      groupRef={node}
                      added={Boolean(
                        addedGroups.find(({ id }) => id === node.id),
                      )}
                      disabled={node.programs.some(
                        ({ id: programId }) => programId === program.id,
                      )}
                      disabledText="This group is already enrolled in the program."
                      onToggle={handleToggleGroup}
                    />
                    {index < edges.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
                {hasMore && (
                  <LoadMoreButton onClick={onMoreClick} disabled={loading} />
                )}
              </Box>
              {edges.length > 0 && nextButton}
            </>
          )}
        </DynamicSearch>
      )}
    </BaseDialog>
  );
}
