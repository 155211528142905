import { capitalize, snakeCase } from "lodash";

export enum NutritionTrackingType {
  MACROS = "MACROS",
  PORTIONS = "PORTIONS",
}

export enum NutrientType {
  CARB = "CARB",
  PROTEIN = "PROTEIN",
  FAT = "FAT",
  VEGETABLE = "VEGETABLE",
}

export type Macronutrient =
  | NutrientType.CARB
  | NutrientType.PROTEIN
  | NutrientType.FAT;

export function getNutrientType(key: string): NutrientType {
  const target = snakeCase(key).split("_")[0].toUpperCase();

  if (Object.keys(NutrientType).find((key) => key === target)) {
    return NutrientType[target];
  } else {
    console.error("getNutrientType", `invalid key ${key}`);
  }
}

export function getNutritionTargetLabel(
  key: string,
  pluralizeSome = false,
): string {
  const nutrientType = getNutrientType(key);

  if (nutrientType) {
    return `${capitalize(nutrientType)}${
      pluralizeSome &&
      [NutrientType.CARB, NutrientType.VEGETABLE].includes(nutrientType)
        ? "s"
        : ""
    }`;
  }
}

export function getCaloricValue(macronutrient: Macronutrient): number {
  switch (macronutrient) {
    case NutrientType.CARB:
    case NutrientType.PROTEIN:
      return 4;
    case NutrientType.FAT:
      return 9;
    default:
      console.error(
        "getCaloricValue",
        `unknown macronutrient ${macronutrient}`,
      );
  }
}
