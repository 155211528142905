/**
 * @generated SignedSource<<4fff45c4d1d5c40a182c400cbf7d889a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "CLIENT" | "COACH" | "%future added value";
export type CoachGroupSettingsRouteQuery$variables = {
  slug: string;
};
export type CoachGroupSettingsRouteQuery$data = {
  readonly group: {
    readonly coachId: string;
    readonly id: string;
    readonly membersCanNotPost: boolean;
    readonly membersCount: number;
    readonly name: string;
    readonly slug: string;
  } | null | undefined;
  readonly me: {
    readonly id: string;
    readonly role: UserRole;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"GroupSettingsScreen_rootRef" | "UnsplashImages_rootRef">;
};
export type CoachGroupSettingsRouteQuery = {
  response: CoachGroupSettingsRouteQuery$data;
  variables: CoachGroupSettingsRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membersCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membersCanNotPost",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coachId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachGroupSettingsRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UnsplashImages_rootRef"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "GroupSettingsScreen_rootRef"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachGroupSettingsRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membersCanNotDiscover",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membersCanNotSeeSidebar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membersCanMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InviteCode",
            "kind": "LinkedField",
            "name": "inviteCode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slugId",
                "storageKey": null
              }
            ],
            "type": "Slug",
            "abstractKey": "__isSlug"
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "bf0a1bfa8420d806789b4095b41d44be",
    "id": null,
    "metadata": {},
    "name": "CoachGroupSettingsRouteQuery",
    "operationKind": "query",
    "text": "query CoachGroupSettingsRouteQuery(\n  $slug: String!\n) {\n  ...GroupSettingsScreen_rootRef_20J5Pl\n  group(slug: $slug) {\n    id\n    name\n    slug\n    membersCount\n    membersCanNotPost\n    coachId\n  }\n  me {\n    id\n    role\n  }\n}\n\nfragment GroupSettingsCard_group on Group {\n  id\n  name\n  membersCanNotPost\n  membersCanNotDiscover\n  membersCanNotSeeSidebar\n  membersCanMessage\n  imageURL\n  inviteCode {\n    ...InviteLinkSettings_inviteCode\n    ...InviteCodePreviewDialog_inviteCode\n    id\n  }\n}\n\nfragment GroupSettingsScreen_rootRef_20J5Pl on Root {\n  group(slug: $slug) {\n    ...RefreshSlug\n    ...GroupSettingsCard_group\n    id\n  }\n}\n\nfragment InviteCodePreviewDialog_inviteCode on InviteCode {\n  code\n}\n\nfragment InviteLinkSettings_inviteCode on InviteCode {\n  id\n  code\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n"
  }
};
})();

(node as any).hash = "7cf5a62203cd47fb9b21c03dcacae109";

export default node;
