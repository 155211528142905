import React, { useContext, useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { GroupSort, GroupStatus, NavigationItem } from "../../../constants";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
} from "../../routes";
import { CreateGroupAction } from "../../../components/groups/CreateGroupAction";
import { GroupList } from "../../../components/groups/GroupList";
import { GroupsFilters } from "../../../components/filters/GroupsFilters";
import { PaginationContext, getAfter } from "../../../utils/paging";
import { CoachGroupsListRouteQuery } from "./__generated__/CoachGroupsListRouteQuery.graphql";
import GroupsContext from "../../../contexts/GroupsContext";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";

const perPage = 5;

export const groupsNavigation: NavigationItem[] = [
  {
    name: "Active",
    link: COACH_GROUPS_ACTIVE_ROUTE,
  },

  {
    name: "Archived",
    link: COACH_GROUPS_ARCHIVED_ROUTE,
  },
];

const getStatusFromPath = (path: string) => {
  switch (path) {
    case COACH_GROUPS_ACTIVE_ROUTE:
      return GroupStatus.ACTIVE;
    case COACH_GROUPS_ARCHIVED_ROUTE:
      return GroupStatus.ARCHIVED;

    default:
      return GroupStatus.ACTIVE;
  }
};

export function CoachGroupsListRoute() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const sortKey = searchParams.get("sort");
  const page = searchParams.get("page");

  const variables = {
    after: getAfter(perPage, Number(page ?? "1")),
    first: perPage,
    orderBy: sortKey ?? GroupSort.NAME_ASC,
    status: getStatusFromPath(location.pathname),
  };
  const props = useLazyLoadQuery<CoachGroupsListRouteQuery>(
    graphql`
      query CoachGroupsListRouteQuery(
        $first: Int
        $after: String
        $orderBy: GroupSortBy!
        $status: GroupStatus!
      ) {
        groups(
          first: $first
          after: $after
          orderBy: $orderBy
          status: $status
        )
          @connection(
            key: "CoachGroups_groups"
            filters: ["first", "after", "orderBy", "status"]
          ) {
          totalCount
          edges {
            cursor
          }
          ...GroupList_groups
        }

        clients(first: 9999, status: ACTIVE)
          @connection(key: "CoachGroups_clients", filters: []) {
          edges {
            cursor
          }
          ...ManageGroupDialog_clients
        }
      }
    `,
    variables,
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { groups, clients } = props;

  const context = useContext(GroupsContext);
  context.setCoachGroupsListRouteQuery(props);

  return (
    <>
      <GroupList groupsRef={groups} clientsRef={clients} />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Groups",
        }}
      />
    </>
  );
}
