import React from "react";
import { DialogContent, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../../components/dialog/FullScreenDialog";
import { useQueryParam } from "../../hooks/useQueryParam";

import { ProgressDialogActions } from "./ProgressDialogActions";
import {
  ProgressDialogCard,
  ProgressDialogCardSkeleton,
} from "./ProgressDialogCard";
import {
  ProgressDialog_enrollments$data,
  ProgressDialog_enrollments$key,
} from "./__generated__/ProgressDialog_enrollments.graphql";

const useStyles = makeStyles((theme) => ({
  content: {
    overflowY: "initial",
    padding: theme.spacing(1),
    height: "50vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      height: "initial",
    },
  },
  grid: {
    flexWrap: "nowrap",
  },
}));

type ProgressPhoto = ProgressDialog_enrollments$data[0]["progressPhotos"][0];
type ProgressPhotos = ReadonlyArray<ProgressPhoto> | ProgressPhoto[];

export type SelectedPhoto = {
  photo: ProgressPhoto;
  url: string;
};

type ProgressDialogState = {
  comparisonMode: boolean;
  selected: SelectedPhoto;
  comparison: SelectedPhoto[];
  selectedCell: number;
};

const photoByUrlPred =
  (url: string) =>
  ({ urls }: ProgressPhoto) =>
    url && urls.includes(url);

const getSelectedPhotoByUrl = (
  photos: ProgressPhotos,
  url: string,
): SelectedPhoto | null => {
  const photo = photos.find(photoByUrlPred(url)) || photos[0];

  return (
    photo && {
      photo,
      url: url || photo.url,
    }
  );
};

const enrollmentsFragment = graphql`
  fragment ProgressDialog_enrollments on Enrollment @relay(plural: true) {
    progressPhotos {
      url
      urls
      date(utc: true, format: "MMMM D, YYYY")
      weight {
        measurement
        unit
      }
    }
  }
`;

export interface ProgressDialogProps
  extends Omit<FullScreenDialogProps, "title" | "children"> {
  clientName: string;
  enrollments: ProgressDialog_enrollments$key;
}

export function ProgressDialog(props: ProgressDialogProps) {
  const { onClose, clientName, enrollments: enrollmentsRef, ...other } = props;
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const s = useStyles();
  const photos = enrollments.flatMap((it) => it.progressPhotos);
  const [querySelected, setQuerySelected] = useQueryParam("selected", null);
  const defaultSelectedPhoto = getSelectedPhotoByUrl(photos, querySelected);
  const [state, setState] = React.useState<ProgressDialogState>({
    comparisonMode: false,
    selected: defaultSelectedPhoto,
    comparison: [defaultSelectedPhoto],
    selectedCell: 0,
  });

  React.useEffect(() => {
    if (querySelected && defaultSelectedPhoto) {
      setState((x) => ({
        ...x,
        selected: defaultSelectedPhoto,
      }));
      setQuerySelected(null);
    }
  }, [querySelected, setQuerySelected, defaultSelectedPhoto]);

  function handleClickClose(event) {
    onClose(event, "escapeKeyDown");
  }

  function handleSelect(event, url) {
    const comparisonItems = state.comparison;
    const callToReplace = state.selectedCell;
    const selectedPhoto = getSelectedPhotoByUrl(photos, url);
    comparisonItems[callToReplace] = selectedPhoto;
    setState((x) => ({
      ...x,
      selected: selectedPhoto,
      comparison: comparisonItems,
    }));
  }

  function toggleComparisonMode() {
    setState((x) => ({
      ...x,
      comparisonMode: !x.comparisonMode,
      comparison: [x.selected],
      selectedCell: 0,
      selected: x.comparisonMode ? x.comparison[0] : x.selected,
    }));
  }

  function handleCardClick(event) {
    const cell = parseInt(event.currentTarget.dataset.cell);
    const url = event.currentTarget.dataset.url;
    const selectedPhoto = getSelectedPhotoByUrl(photos, url);
    setState((x) => ({
      ...x,
      selectedCell: cell,
      selected: selectedPhoto,
    }));
  }

  const cards = state.comparisonMode ? state.comparison : undefined;
  const card = !state.comparisonMode
    ? getSelectedPhotoByUrl(photos, state.selected?.url)
    : undefined;
  const selectedCell: number = state.comparisonMode
    ? state.selectedCell
    : undefined;

  return (
    <FullScreenDialog
      onClose={handleClickClose}
      actions={
        <ProgressDialogActions
          clientName={clientName}
          photos={photos}
          selected={state.selected}
          onSelect={handleSelect}
          onSwitchMode={toggleComparisonMode}
          comparisonMode={state.comparisonMode}
        />
      }
      maxWidth="lg"
      align="top"
      {...other}
    >
      <DialogContent className={s.content}>
        <Grid container justifyContent="center" className={s.grid}>
          {cards &&
            cards.length > 1 &&
            cards.map((x, indx) => (
              <ProgressDialogCard
                key={indx}
                onClick={handleCardClick}
                selected={selectedCell === indx}
                cell={indx}
                selectedPhoto={x}
                comparisonMode={state.comparisonMode}
              />
            ))}
          {cards && cards.length === 1 && (
            <React.Fragment>
              <ProgressDialogCard
                {...cards[0].photo}
                onClick={handleCardClick}
                selected={selectedCell === 0}
                cell={0}
                selectedPhoto={cards[0]}
                comparisonMode={state.comparisonMode}
              />
              <ProgressDialogCardSkeleton
                onClick={handleCardClick}
                selected={selectedCell === 1}
                cell={1}
                comparisonMode={state.comparisonMode}
              />
            </React.Fragment>
          )}
          {card && (
            <ProgressDialogCard
              selectedPhoto={card}
              comparisonMode={state.comparisonMode}
            />
          )}
        </Grid>
      </DialogContent>
    </FullScreenDialog>
  );
}
