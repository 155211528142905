/**
 * @generated SignedSource<<d7a01cc346e2b63fd15ed3ca5f7c90fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramSpreadsheetWeek_week$data = {
  readonly description: string | null | undefined;
  readonly id: string;
  readonly positions: ReadonlyArray<string>;
  readonly week: number;
  readonly " $fragmentType": "ProgramSpreadsheetWeek_week";
};
export type ProgramSpreadsheetWeek_week$key = {
  readonly " $data"?: ProgramSpreadsheetWeek_week$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramSpreadsheetWeek_week">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramSpreadsheetWeek_week",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "week",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "positions",
      "storageKey": null
    }
  ],
  "type": "Week",
  "abstractKey": null
};

(node as any).hash = "838df27f8672c7d2860779fc94e61cfe";

export default node;
