/**
 * @generated SignedSource<<f8413b62570c16c2e2f519cc0c0dc0ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TerraConnectButtonWidgetSessionQuery$variables = Record<PropertyKey, never>;
export type TerraConnectButtonWidgetSessionQuery$data = {
  readonly terraWidgetSession: string;
};
export type TerraConnectButtonWidgetSessionQuery = {
  response: TerraConnectButtonWidgetSessionQuery$data;
  variables: TerraConnectButtonWidgetSessionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "terraWidgetSession",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TerraConnectButtonWidgetSessionQuery",
    "selections": (v0/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TerraConnectButtonWidgetSessionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f9effb844ec3045de30eaa85f4e7aee0",
    "id": null,
    "metadata": {},
    "name": "TerraConnectButtonWidgetSessionQuery",
    "operationKind": "query",
    "text": "query TerraConnectButtonWidgetSessionQuery {\n  terraWidgetSession\n}\n"
  }
};
})();

(node as any).hash = "eabb9f2960f65f8d97e6f573d2edc97a";

export default node;
