import React from "react";

const ClientWelcomeScreenArt = ({ fill }) => (
  <svg
    width="335"
    height="220"
    viewBox="0 0 335 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M328.513 70.1433C331.036 77.7984 323.62 84.6831 317.787 90.2277C309.803 97.8341 303.503 107.038 299.296 117.243C296.76 123.41 294.997 130.044 295.47 136.698C296.81 155.571 314.538 168.145 328.704 180.647C330.71 182.435 332.717 184.305 333.886 186.715C337.64 194.449 331.259 203.362 324.23 208.294C299.201 225.85 263.073 220.628 238.796 202.042C226.223 192.415 215.418 179.323 200.182 175.043C190.441 172.32 180.102 173.611 170.022 174.45C154.909 175.706 139.726 175.886 124.587 174.99C119.273 174.661 113.716 174.148 109.266 171.221C97.997 163.819 99.8986 146.27 91.7667 135.503C85.9206 127.766 75.933 124.783 67.2394 120.506C53.8582 113.886 42.6924 103.499 35.109 90.6162C28.1298 78.7071 24.5138 65.1683 20.9865 51.8204C18.3261 41.7913 15.656 31.2882 17.909 21.1571C20.1621 11.026 29.056 1.56653 39.3687 2.45551C47.2872 3.13706 53.5865 9.40932 61.2127 11.6548C79.4801 17.048 97.2154 -1.6832 116.166 0.124398C128.098 1.26032 137.721 10.3312 144.996 19.8796C152.27 29.4279 158.428 40.178 168.035 47.3689C182.979 58.5634 203.453 58.9585 221.747 55.3335C240.04 51.7084 257.592 44.5735 276.089 42.1963C286.986 40.797 295.338 47.3063 305.152 50.8853C314.157 54.1712 325.176 60.0187 328.513 70.1433Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M289.693 219.999C276.499 219.999 265.803 216.741 265.803 212.723C265.803 208.704 276.499 205.446 289.693 205.446C302.887 205.446 313.583 208.704 313.583 212.723C313.583 216.741 302.887 219.999 289.693 219.999Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M63.8043 123.901C28.5661 123.901 -6.10352e-05 122.001 -6.10352e-05 119.657C-6.10352e-05 117.313 28.5661 115.413 63.8043 115.413C99.0425 115.413 127.609 117.313 127.609 119.657C127.609 122.001 99.0425 123.901 63.8043 123.901Z"
      fill={fill}
    />
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M102.379 22.5205C101.494 22.5205 100.645 22.8729 100.019 23.5003C99.3935 24.1276 99.0419 24.9785 99.0419 25.8657V118.88H105.716V25.8624C105.715 24.9758 105.363 24.1258 104.737 23.4991C104.111 22.8725 103.263 22.5205 102.379 22.5205Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M29.1614 22.5205C28.2764 22.5205 27.4277 22.873 26.8019 23.5003C26.1761 24.1277 25.8245 24.9786 25.8245 25.8658V118.88H32.5147V25.8625C32.5143 25.4221 32.4271 24.9861 32.2582 24.5795C32.0893 24.173 31.8419 23.8038 31.5304 23.4933C31.2188 23.1828 30.8492 22.9371 30.4427 22.7701C30.0361 22.6032 29.6007 22.5184 29.1614 22.5205Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M9.68538 43.3125C7.84248 43.3125 6.34851 41.8148 6.34851 39.9673C6.34851 38.1198 7.84248 36.6221 9.68538 36.6221L121.851 36.6221C123.694 36.6221 125.188 38.1198 125.188 39.9673C125.188 41.8148 123.694 43.3125 121.851 43.3125L9.68538 43.3125Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M107.699 30.0203C107.699 28.5728 108.87 27.3994 110.314 27.3994C111.758 27.3994 112.928 28.5728 112.928 30.0203V49.9071C112.928 51.3546 111.758 52.528 110.314 52.528C108.87 52.528 107.699 51.3546 107.699 49.9071V30.0203Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M112.928 32.3718C112.928 30.9244 114.099 29.751 115.542 29.751C116.986 29.751 118.157 30.9244 118.157 32.3718V47.5603C118.157 49.0077 116.986 50.1811 115.542 50.1811C114.099 50.1811 112.928 49.0077 112.928 47.5603V32.3718Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M23.8409 49.9075C23.8409 51.3549 22.6705 52.5283 21.2266 52.5283C19.7828 52.5283 18.6123 51.3549 18.6123 49.9075L18.6123 30.0206C18.6123 28.5732 19.7828 27.3998 21.2266 27.3998C22.6705 27.3998 23.8409 28.5732 23.8409 30.0206L23.8409 49.9075Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M18.6122 47.5569C18.6122 49.0043 17.4417 50.1777 15.9979 50.1777C14.554 50.1777 13.3835 49.0043 13.3835 47.5569L13.3835 32.3685C13.3835 30.921 14.554 29.7476 15.9979 29.7476C17.4417 29.7476 18.6122 30.921 18.6122 32.3685V47.5569Z"
        fill={fill}
      />
    </g>
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M72.43 61.4512H58.787C57.3062 61.4512 55.8861 62.0409 54.839 63.0906C53.7919 64.1403 53.2037 65.564 53.2037 67.0485V90.5439H78.0002V67.0485C78.0002 65.5663 77.4138 64.1445 76.3696 63.0952C75.3253 62.0459 73.9086 61.4547 72.43 61.4512Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M63.8042 88.4336H67.4334V100.56H63.8042V88.4336Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M71.0627 102.074H74.6919V114.201H71.0627V102.074Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M55.9418 102.074H59.5709V114.201H55.9418V102.074Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M53.2189 99.6484H78.0155V102.984H53.2189V99.6484Z"
        fill={fill}
      />
    </g>
    <path
      opacity="0.1"
      d="M179.622 200.294C104.47 200.294 43.5465 195.816 43.5465 190.292C43.5465 184.767 104.47 180.289 179.622 180.289C254.775 180.289 315.698 184.767 315.698 190.292C315.698 195.816 254.775 200.294 179.622 200.294Z"
      fill={fill}
    />
    <path
      d="M144.392 154.67C144.392 154.67 135.018 158.914 130.483 158.914C130.483 158.914 125.645 160.126 122.929 161.341C120.213 162.556 109.624 163.158 109.624 163.158C109.624 163.158 97.2257 167.705 95.715 169.829C94.2042 171.952 89.6685 162.256 89.6685 162.256L92.0891 156.194L137.741 147.403L144.392 154.67Z"
      fill="#FFB9B9"
    />
    <path
      opacity="0.05"
      d="M144.392 154.67C144.392 154.67 135.018 158.914 130.483 158.914C130.483 158.914 125.645 160.126 122.929 161.341C120.213 162.556 109.624 163.158 109.624 163.158C109.624 163.158 97.2257 167.705 95.715 169.829C94.2042 171.952 89.6685 162.256 89.6685 162.256L92.0891 156.194L137.741 147.403L144.392 154.67Z"
      fill="black"
    />
    <path
      d="M98.1259 168.01C98.1259 168.01 92.3816 175.583 94.7988 180.136C97.2161 184.69 96.3096 184.683 94.7988 184.986C93.288 185.289 84.8211 187.11 83.9113 180.439C83.0016 173.769 81.1886 158.916 81.1886 158.916L93.6033 157.701C93.6033 157.701 90.2632 168.919 98.1259 168.01Z"
      fill="#737A9D"
    />
    <path
      d="M169.491 144.667L143.485 156.794C143.485 156.794 135.321 150.426 135.931 147.094L166.17 139.817L169.491 144.667Z"
      fill="#4C4C78"
    />
    <path
      opacity="0.1"
      d="M169.491 144.667L143.485 156.794C143.485 156.794 135.321 150.426 135.931 147.094L166.17 139.817L169.491 144.667Z"
      fill="black"
    />
    <path
      d="M60.3261 160.432L63.0587 167.099L69.3974 168.607C71.1734 167.121 73.0733 165.791 75.076 164.63C78.4884 162.655 82.978 160.722 87.2279 161.028C87.2279 161.028 104.162 158.298 105.068 157.077C105.068 157.077 115.956 154.65 118.373 154.953C119.539 155.098 122.045 154.259 124.324 153.347C126.771 152.359 128.958 151.315 128.958 151.315L121.096 138.886C120.179 139.281 119.235 139.612 118.271 139.874C115.266 140.756 109.857 141.902 102.043 141.902C89.343 141.902 71.8048 154.937 71.8048 154.937C71.8048 154.937 71.6307 154.996 71.3319 155.085C70.0181 155.483 66.274 156.422 64.5465 154.937C62.4445 153.156 60.3261 160.432 60.3261 160.432Z"
      fill="#FFB9B9"
    />
    <path
      opacity="0.1"
      d="M60.3265 160.432L63.059 167.1L69.3977 168.608C71.1738 167.122 73.0736 165.791 75.0763 164.63C65.854 165.325 71.3617 155.118 71.3617 155.118H71.342C70.0283 155.517 66.2842 156.455 64.5567 154.97C62.4449 153.156 60.3265 160.432 60.3265 160.432Z"
      fill="black"
    />
    <path
      d="M75.0397 164.886C75.0397 164.886 67.7255 175.169 70.8029 180.96C73.8803 186.752 72.7275 186.749 70.8029 187.137C68.8783 187.526 58.0959 189.84 56.9431 181.349C56.0563 174.82 56.8347 162.288 57.242 156.849C57.268 156.506 57.3618 156.171 57.5181 155.864C57.6743 155.558 57.8898 155.285 58.152 155.063C58.4142 154.84 58.7179 154.673 59.0454 154.569C59.373 154.465 59.7178 154.428 60.0599 154.459L70.7569 155.447C70.7569 155.447 65.0291 166.045 75.0397 164.886Z"
      fill="#737A9D"
    />
    <path
      d="M248.718 174.982L248.416 189.838L245.089 192.261H233.597L231.482 189.232V174.982L234.809 171.949H245.693L248.718 174.982Z"
      fill={fill}
    />
    <path
      opacity="0.2"
      d="M246.905 175.285C246.905 175.285 244.787 188.926 246.905 190.141L248.416 189.838L248.718 175.288L246.905 175.285Z"
      fill="white"
    />
    <path
      d="M222.108 174.072L219.688 171.646H208.193L205.772 174.072C205.772 174.072 202.747 182.863 205.168 188.622L207.897 191.351H219.392L222.115 188.925L222.108 174.072Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M248.718 174.982L248.416 189.838L245.089 192.261H233.597L231.482 189.232V174.982L234.809 171.949H245.693L248.718 174.982Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M222.108 174.072L219.688 171.646H208.193L205.772 174.072C205.772 174.072 202.747 182.863 205.168 188.622L207.897 191.351H219.392L222.115 188.925L222.108 174.072Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M219.392 174.679C219.392 174.679 216.972 185.291 219.392 188.926H222.115V174.073L219.392 174.679Z"
      fill="white"
    />
    <path
      d="M231.482 123.75C231.482 123.75 230.273 135.573 230.273 137.088C230.273 138.603 230.878 148.612 229.065 158.308C227.252 168.005 230.273 180.131 230.273 180.131H220.296L216.062 147.39L219.087 130.111L220.296 124.655L231.482 123.75Z"
      fill="#FFB9B9"
    />
    <path
      opacity="0.05"
      d="M231.482 123.75C231.482 123.75 230.273 135.573 230.273 137.088C230.273 138.603 230.878 148.612 229.065 158.308C227.252 168.005 230.273 180.131 230.273 180.131H220.296L216.062 147.39L219.087 130.111L220.296 124.655L231.482 123.75Z"
      fill="black"
    />
    <path
      d="M233.294 93.7384C233.294 93.7384 246.599 94.0413 236.017 116.17C236.017 116.17 233.294 125.264 233.294 126.782C233.294 128.3 219.687 128.297 218.187 126.782C216.686 125.268 220.607 112.838 220.607 107.076C220.607 101.314 224.525 91.9209 233.294 93.7384Z"
      fill="#CBCDDA"
    />
    <path
      opacity="0.05"
      d="M233.294 93.7384C233.294 93.7384 246.599 94.0413 236.017 116.17C236.017 116.17 233.294 125.264 233.294 126.782C233.294 128.3 219.687 128.297 218.187 126.782C216.686 125.268 220.607 112.838 220.607 107.076C220.607 101.314 224.525 91.9209 233.294 93.7384Z"
      fill="black"
    />
    <path
      d="M249.322 80.6963C249.322 80.6963 237.827 93.1256 228.155 84.0316L229.363 116.167C229.363 116.167 245.088 97.07 255.966 98.5846L249.322 80.6963Z"
      fill="#FFB9B9"
    />
    <path
      opacity="0.1"
      d="M118.284 139.909C118.744 143.461 120.189 150.942 124.337 153.365C126.784 152.377 128.971 151.334 128.971 151.334L121.109 138.904C120.192 139.306 119.248 139.641 118.284 139.909Z"
      fill="black"
    />
    <path
      d="M118.991 137.986C118.991 137.986 119.897 152.841 127.156 154.053L170.995 146.125C173.122 145.737 175.123 144.833 176.821 143.491C178.591 142.095 180.762 140.198 182.046 138.46C182.743 137.511 183.163 136.606 183.094 135.859C182.792 132.523 169.487 124.641 174.023 110.697C173.378 110.185 172.661 109.772 171.895 109.472C168.108 107.895 159.391 106.736 146.809 119.791C146.809 119.808 123.527 138.605 118.991 137.986Z"
      fill="#4C4C78"
    />
    <path
      d="M237.528 176.193L237.226 191.049L233.902 193.476H222.411L220.292 190.443V176.193L223.619 173.164H234.507L237.528 176.193Z"
      fill={fill}
    />
    <path
      opacity="0.2"
      d="M235.715 176.509C235.715 176.509 233.597 190.15 235.715 191.365L237.226 191.062L237.528 176.509H235.715Z"
      fill="white"
    />
    <path
      d="M204.264 174.981L201.844 172.555H190.349L187.928 174.981C187.928 174.981 184.903 183.772 187.324 189.531L190.047 192.26H201.542L204.264 189.834V174.981Z"
      fill={fill}
    />
    <path
      opacity="0.2"
      d="M201.545 175.587C201.545 175.587 199.125 186.199 201.545 189.834H204.268V174.981L201.545 175.587Z"
      fill="white"
    />
    <path
      d="M220.377 180.744H204.018C202.737 180.744 201.699 181.897 201.699 183.322C201.699 184.745 202.737 185.9 204.018 185.9H220.377C221.766 185.9 222.844 184.554 222.676 183.023C222.542 181.722 221.55 180.744 220.377 180.744Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M173.119 133.45C176.354 135.721 179.764 137.21 182.049 138.477C182.746 137.529 183.166 136.624 183.097 135.876C182.795 132.541 169.49 124.659 174.026 110.715C173.381 110.203 172.664 109.79 171.898 109.49C171.187 109.475 170.481 109.378 169.792 109.2C169.792 109.2 167.073 129.206 173.119 133.45Z"
      fill="black"
    />
    <path
      d="M241.006 104.195C241.006 104.195 236.471 108.742 232.086 111.623C228.04 114.28 235.262 87.5218 235.262 87.5218C235.262 87.5218 230.782 83.9001 228.152 81.7501C226.395 80.301 224.156 79.5718 221.885 79.7087C215.832 80.1005 204.744 82.6522 195.798 94.9531C182.795 112.838 171.001 109.2 171.001 109.2C171.001 109.2 168.279 129.209 174.328 133.453C180.378 137.697 187.029 139.211 185.823 141.638C184.618 144.064 191.265 129.814 206.386 127.391C221.507 124.968 238.139 114.356 237.834 109.203C237.83 109.2 242.215 105.72 241.006 104.195Z"
      fill="#CBCDDA"
    />
    <path
      opacity="0.1"
      d="M244.182 88.5988C244.184 87.7223 244.26 86.8477 244.412 85.9845C248.373 84.009 247.811 81.3223 247.811 81.3223L254.465 99.2073C252.032 98.878 249.355 99.5629 246.668 100.854C243.131 98.0516 244.182 93.4355 244.182 88.5988Z"
      fill="black"
    />
    <path
      d="M257.487 103.442C249.136 103.442 242.366 96.6548 242.366 88.2828C242.366 79.9108 249.136 73.124 257.487 73.124C265.838 73.124 272.608 79.9108 272.608 88.2828C272.608 96.6548 265.838 103.442 257.487 103.442Z"
      fill="#FFB9B9"
    />
    <path
      opacity="0.1"
      d="M271.225 91.6997C271.504 92.5986 271.935 93.5238 272.746 94.0045C273.731 94.5906 275.091 94.3008 275.968 93.5435C276.845 92.7863 277.318 91.6536 277.564 90.521C277.919 88.881 277.862 87.1785 277.4 85.5657C277.006 84.1993 276.326 82.9317 276.04 81.5324C275.734 80.0442 275.889 78.4868 275.547 77.0052C275.084 74.9967 273.764 73.3143 272.476 71.7042C272.073 71.0896 271.55 70.563 270.939 70.1556C270.328 69.7483 269.641 69.4687 268.92 69.3336L262.591 67.4206C261.391 67.0178 260.158 66.7225 258.906 66.5382C257.296 66.373 255.67 66.5161 254.114 66.9597C252.875 67.2722 251.699 67.798 250.639 68.5137C249.589 69.2958 248.664 70.234 247.897 71.2959C244.55 75.6816 242.642 82.856 239.19 87.1725C240.553 87.5939 241.916 86.1847 243.279 86.6128C243.473 86.6965 243.688 86.7139 243.893 86.6622C244.096 86.5518 244.255 86.3752 244.343 86.1617C245.544 84.0583 247.435 82.4365 249.693 81.5719C251.368 80.9365 253.634 80.9397 254.551 82.4807C255.171 83.5211 254.922 84.8348 254.758 86.0366C254.593 87.2383 254.623 88.6706 255.572 89.4114C256.097 89.7668 256.707 89.9738 257.339 90.0107C260.285 90.3977 263.28 90.1038 266.095 89.1513C267.08 88.8221 268.082 87.7915 269.123 88.1207C270.529 88.5652 270.854 90.5045 271.225 91.6997Z"
      fill="black"
    />
    <path
      d="M271.557 91.3673C271.836 92.2662 272.267 93.1914 273.078 93.6721C274.063 94.2582 275.423 93.9684 276.3 93.2111C277.177 92.4539 277.65 91.3212 277.896 90.1886C278.251 88.5486 278.194 86.8461 277.732 85.2334C277.337 83.867 276.658 82.5993 276.372 81.2C276.066 79.7118 276.221 78.1544 275.879 76.6728C275.416 74.6643 274.096 72.9819 272.808 71.3718C272.405 70.7572 271.882 70.2306 271.271 69.8233C270.66 69.4159 269.973 69.1363 269.252 69.0012L262.923 67.0882C261.723 66.6865 260.489 66.3923 259.238 66.2091C257.628 66.0439 256.002 66.187 254.446 66.6306C253.207 66.9431 252.031 67.4689 250.971 68.1846C249.921 68.9667 248.996 69.9049 248.229 70.9668C244.882 75.3525 242.974 82.5269 239.522 86.8434C240.885 87.2648 242.248 85.8556 243.611 86.2837C243.805 86.3674 244.02 86.3848 244.225 86.3331C244.43 86.2239 244.591 86.0471 244.682 85.8326C245.883 83.7292 247.773 82.1074 250.032 81.2428C251.707 80.6074 253.973 80.6106 254.889 82.1516C255.51 83.192 255.26 84.5057 255.096 85.7075C254.932 86.9093 254.961 88.3415 255.911 89.0823C256.435 89.4377 257.046 89.6447 257.678 89.6816C260.623 90.0686 263.619 89.7747 266.434 88.8222C267.419 88.493 268.421 87.4624 269.462 87.7916C270.861 88.2328 271.186 90.1721 271.557 91.3673Z"
      fill="#B96B6B"
    />
    <g opacity="0.1">
      <path
        opacity="0.1"
        d="M234.237 86.6953L235.262 87.525C235.262 87.525 231.028 103.207 230.861 109.368C228.7 108.782 232.858 92.0029 234.237 86.6953Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M173.74 131.026C179.787 135.27 186.441 136.784 185.235 139.211C184.03 141.638 190.677 127.388 205.798 124.964C219.983 122.689 235.499 113.21 237.114 107.79C239.381 105.848 241.029 104.198 241.029 104.198C242.238 105.713 237.853 109.2 237.853 109.2C238.156 114.352 221.524 124.964 206.406 127.387C191.288 129.811 184.634 144.061 185.843 141.634C187.052 139.208 180.401 137.683 174.348 133.449C172.617 132.234 171.605 129.728 171.041 126.769C171.625 128.642 172.492 130.16 173.74 131.026Z"
        fill="black"
      />
    </g>
    <path
      d="M204.265 160.129C205.171 161.341 205.775 173.77 205.775 173.77C205.775 173.77 202.163 191.655 213.329 189.837C224.496 188.02 214.84 175.288 214.84 175.288L216.351 157.706C222.095 142.56 220.886 131.635 220.886 131.635L221.96 121.735L222.401 117.692L209.398 114.659C209.753 115.133 209.602 118.09 209.283 121.524C208.787 126.904 207.887 133.453 207.887 133.453C205.174 136.482 203.358 158.914 204.265 160.129Z"
      fill="#FFB9B9"
    />
    <path
      opacity="0.1"
      d="M222.713 87.3739C222.713 87.3739 236.018 87.6768 225.436 109.806C225.436 109.806 222.713 118.9 222.713 120.418C222.713 121.936 209.106 121.932 207.605 120.418C206.104 118.903 210.026 106.474 210.026 100.712C210.026 94.95 213.944 85.5531 222.713 87.3739Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M209.293 121.524C212.117 122.298 219.041 122.367 221.973 121.735L222.414 117.692L209.411 114.659C209.759 115.13 209.608 118.087 209.293 121.524Z"
      fill="black"
    />
    <path
      d="M223.317 87.3739C223.317 87.3739 236.621 87.6768 226.039 109.806C226.039 109.806 223.317 118.9 223.317 120.418C223.317 121.936 209.71 121.932 208.209 120.418C206.708 118.903 210.629 106.474 210.629 100.712C210.629 94.95 214.547 85.5531 223.317 87.3739Z"
      fill="#CBCDDA"
    />
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M310.355 134.83L319.984 135.028L321.554 137.194V144.681L319.59 146.061H310.355L308.388 143.894V136.802L310.355 134.83Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M310.549 136.011C310.549 136.011 319.393 137.39 320.178 136.011L319.981 135.023L310.549 134.825V136.011Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M309.566 156.5L307.993 158.077V165.564L309.566 167.141C309.566 167.141 315.265 169.117 318.999 167.537L320.769 165.762V158.275L319.196 156.5H309.566Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M309.96 158.275C309.96 158.275 316.838 159.852 319.196 158.275V156.5H309.566L309.96 158.275Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M313.301 146.007V156.665C313.324 157.087 313.513 157.483 313.826 157.766C314.139 158.049 314.551 158.197 314.973 158.176C315.394 158.197 315.806 158.049 316.12 157.766C316.433 157.483 316.621 157.087 316.644 156.665V146.007C316.644 145.102 315.771 144.4 314.779 144.506C314.391 144.529 314.026 144.695 313.753 144.972C313.48 145.249 313.319 145.618 313.301 146.007Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M294.932 135.131L304.561 135.328L306.135 137.495V144.982L304.167 146.362H294.932L292.968 144.195V137.103L294.932 135.131Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M295.129 136.316C295.129 136.316 303.973 137.696 304.758 136.316L304.561 135.328L295.129 135.131V136.316Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M294.147 156.806L292.574 158.383V165.87L294.147 167.447C294.147 167.447 299.845 169.423 303.579 167.843L305.35 166.068V158.577L303.776 156.803L294.147 156.806Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M294.537 158.577C294.537 158.577 301.415 160.154 303.773 158.577V156.803H294.143L294.537 158.577Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M297.888 146.311V156.969C297.91 157.391 298.099 157.787 298.412 158.07C298.726 158.353 299.138 158.501 299.559 158.48C299.981 158.501 300.393 158.353 300.706 158.07C301.019 157.787 301.208 157.391 301.231 156.969V146.311C301.231 145.405 300.357 144.704 299.365 144.809C298.978 144.832 298.612 144.999 298.339 145.276C298.066 145.553 297.906 145.922 297.888 146.311Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M275.298 140.834L283.226 140.995L284.52 142.78V148.943L282.901 150.076H275.298L273.682 148.292V142.454L275.298 140.834Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M275.462 141.805C275.462 141.805 282.744 142.941 283.391 141.805L283.23 140.995L275.466 140.834L275.462 141.805Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M274.651 158.673L273.357 159.97V166.134L274.651 167.431C274.651 167.431 279.341 169.054 282.415 167.76L283.874 166.302V160.131L282.58 158.673H274.651Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M274.976 160.131C274.976 160.131 280.638 161.429 282.579 160.131V158.673H274.651L274.976 160.131Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M277.725 150.034V158.808C277.746 159.155 277.902 159.479 278.159 159.711C278.417 159.943 278.756 160.063 279.101 160.046C279.448 160.063 279.788 159.942 280.046 159.709C280.304 159.475 280.459 159.149 280.478 158.801V150.034C280.466 149.848 280.416 149.667 280.331 149.502C280.245 149.337 280.126 149.191 279.982 149.075C279.837 148.959 279.67 148.874 279.49 148.827C279.311 148.779 279.124 148.77 278.94 148.799C278.622 148.818 278.321 148.955 278.097 149.183C277.872 149.411 277.74 149.714 277.725 150.034Z"
        fill={fill}
      />
      <path
        opacity="0.5"
        d="M262.604 141.081L270.533 141.246L271.827 143.027V149.191L270.207 150.326H262.604L260.985 148.542V142.704L262.604 141.081Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M262.765 142.055C262.765 142.055 270.047 143.191 270.694 142.055L270.533 141.245L262.769 141.084L262.765 142.055Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M261.957 158.924L260.663 160.221V166.384L261.957 167.682C261.957 167.682 266.647 169.305 269.721 168.011L271.176 166.552V160.376L269.882 158.917L261.957 158.924Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M262.279 160.376C262.279 160.376 267.941 161.673 269.882 160.376V158.917H261.957L262.279 160.376Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M265.044 150.283V159.057C265.063 159.405 265.218 159.731 265.476 159.964C265.734 160.197 266.073 160.319 266.42 160.302C266.767 160.319 267.107 160.197 267.365 159.964C267.622 159.731 267.778 159.405 267.796 159.057V150.283C267.785 150.097 267.735 149.916 267.65 149.751C267.564 149.586 267.445 149.44 267.301 149.324C267.156 149.208 266.988 149.123 266.809 149.076C266.63 149.028 266.443 149.019 266.259 149.048C265.941 149.067 265.64 149.204 265.416 149.432C265.191 149.66 265.059 149.963 265.044 150.283Z"
        fill={fill}
      />
    </g>
    <g opacity="0.8">
      <path
        opacity="0.8"
        d="M296.514 193.96C296.514 193.96 301.168 192.38 303.411 196.492C304.002 197.607 304.303 198.853 304.286 200.115C304.269 201.377 303.934 202.615 303.313 203.713C301.841 206.38 298.81 209.84 293.066 209.534C288.192 209.274 286.573 205.543 286.133 202.076C285.964 200.776 286.132 199.454 286.619 198.237C287.106 197.02 287.897 195.949 288.915 195.126C289.933 194.303 291.145 193.755 292.435 193.536C293.725 193.317 295.049 193.434 296.281 193.875L296.514 193.96Z"
        fill={fill}
      />
      <path
        opacity="0.1"
        d="M302.324 204.077C302.945 202.979 303.28 201.742 303.297 200.48C303.315 199.218 303.014 197.971 302.423 196.857C300.183 192.744 295.526 194.325 295.526 194.325L295.286 194.239C293.512 193.596 291.562 193.639 289.818 194.361C290.781 193.785 291.86 193.431 292.977 193.324C294.093 193.216 295.22 193.359 296.275 193.742L296.514 193.828C296.514 193.828 301.168 192.247 303.411 196.36C304.002 197.474 304.303 198.72 304.286 199.983C304.269 201.245 303.934 202.482 303.313 203.58C302.328 205.335 300.685 207.432 298.058 208.575C299.862 207.493 301.337 205.938 302.324 204.077Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M298.918 195.935C298.918 195.935 297.194 197.292 293.006 195.935H298.918Z"
        fill="black"
      />
      <path
        opacity="0.8"
        d="M299.286 186.057C299.286 186.057 296.33 194.09 296.573 196.191L294.231 195.944C294.231 195.944 298.173 188.901 297.187 186.067L299.286 186.057Z"
        fill={fill}
      />
      <path
        opacity="0.2"
        d="M299.286 186.057C299.286 186.057 296.33 194.09 296.573 196.191L294.231 195.944C294.231 195.944 298.173 188.901 297.187 186.067L299.286 186.057Z"
        fill="black"
      />
      <path
        opacity="0.8"
        d="M287.213 186.716C287.213 186.716 291.871 184.329 293.953 186.301C296.035 188.273 296.357 193.314 296.357 193.314C296.357 193.314 288.764 194.365 287.213 186.716Z"
        fill={fill}
      />
      <path
        opacity="0.1"
        d="M287.213 186.716C287.213 186.716 291.871 184.329 293.953 186.301C296.035 188.273 296.357 193.314 296.357 193.314C296.357 193.314 288.764 194.365 287.213 186.716Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M296.357 193.301C296.357 193.301 295.135 187.45 287.213 186.716"
        stroke={fill}
        stroke-miterlimit="10"
      />
    </g>
    <path
      d="M276.868 201.232C276.868 201.232 281.521 199.651 283.765 203.764C284.356 204.878 284.656 206.124 284.639 207.387C284.622 208.649 284.287 209.886 283.666 210.984C282.195 213.651 279.163 217.112 273.419 216.805C268.545 216.545 266.926 212.815 266.486 209.348C266.318 208.047 266.485 206.725 266.972 205.508C267.459 204.291 268.25 203.22 269.268 202.397C270.287 201.574 271.499 201.027 272.789 200.808C274.078 200.589 275.402 200.705 276.634 201.146L276.868 201.232Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M282.668 211.354C283.289 210.256 283.624 209.018 283.641 207.756C283.658 206.494 283.357 205.248 282.766 204.133C280.527 200.021 275.869 201.601 275.869 201.601L275.63 201.516C273.855 200.872 271.905 200.915 270.161 201.637C271.125 201.061 272.204 200.707 273.32 200.6C274.437 200.493 275.563 200.636 276.618 201.018L276.858 201.104C276.858 201.104 281.512 199.524 283.755 203.636C284.346 204.751 284.647 205.997 284.63 207.259C284.612 208.521 284.278 209.759 283.656 210.857C282.671 212.611 281.029 214.709 278.402 215.851C280.206 214.77 281.681 213.215 282.668 211.354Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M279.262 203.208C279.262 203.208 277.538 204.566 273.35 203.208H279.262Z"
      fill="black"
    />
    <path
      d="M279.633 193.324C279.633 193.324 276.677 201.358 276.92 203.459L274.579 203.212C274.579 203.212 278.52 196.169 277.534 193.334L279.633 193.324Z"
      fill={fill}
    />
    <path
      opacity="0.2"
      d="M279.633 193.324C279.633 193.324 276.677 201.358 276.92 203.459L274.579 203.212C274.579 203.212 278.52 196.169 277.534 193.334L279.633 193.324Z"
      fill="black"
    />
    <path
      d="M267.556 193.993C267.556 193.993 272.214 191.606 274.296 193.578C276.378 195.551 276.7 200.591 276.7 200.591C276.7 200.591 269.107 201.639 267.556 193.993Z"
      fill={fill}
    />
    <path
      opacity="0.1"
      d="M267.556 193.993C267.556 193.993 272.214 191.606 274.296 193.578C276.378 195.551 276.7 200.591 276.7 200.591C276.7 200.591 269.107 201.639 267.556 193.993Z"
      fill="white"
    />
    <path
      d="M276.7 200.59C276.7 200.59 275.478 194.739 267.556 194.005"
      stroke={fill}
      stroke-miterlimit="10"
    />
    <path
      d="M314.309 182.163C314.309 182.163 313.852 181.652 314.709 181.448V180.609H314.194C314.194 179.594 314.542 178.61 315.179 177.821C315.817 177.032 316.705 176.487 317.695 176.276V175.258H317.56V173.941H320.877V175.258H320.782V176.289C321.759 176.511 322.632 177.06 323.257 177.845C323.883 178.63 324.224 179.604 324.224 180.609H323.764V181.415C323.764 181.415 324.855 181.932 323.764 182.854C322.674 183.776 322.845 184.464 322.845 184.464C322.845 184.464 323.304 186.19 324.221 186.42V202.471C324.221 203.417 323.846 204.324 323.179 204.993C322.512 205.661 321.607 206.037 320.664 206.037H317.852C316.912 206.033 316.011 205.657 315.347 204.989C314.683 204.321 314.31 203.417 314.309 202.474V186.42C314.309 186.42 315.915 185.212 315.622 184.464C315.241 183.666 314.802 182.897 314.309 182.163Z"
      fill={fill}
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="335"
          height="220"
          fill="white"
          transform="matrix(-1 0 0 1 335 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ClientWelcomeScreenArt;
