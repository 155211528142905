/**
 * @generated SignedSource<<f2648dde0689c897b11a31c483c83cc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserNoteDialog_note$data = {
  readonly content: string;
  readonly id: string;
  readonly " $fragmentType": "UserNoteDialog_note";
};
export type UserNoteDialog_note$key = {
  readonly " $data"?: UserNoteDialog_note$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNoteDialog_note">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNoteDialog_note",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "type": "UserNote",
  "abstractKey": null
};

(node as any).hash = "82eb9bd271794fa3fc680f928585dc77";

export default node;
