import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { ConfirmDialogProps } from "../dirty-transaction/DirtyTransaction";
import { useHistoryBlock } from "../history-block/hooks";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    left: "50%",
  },

  root: {
    position: "fixed",
    bottom: theme.spacing(5),
    padding: theme.spacing(2),
    zIndex: 9999,
    transform: "translateX(-50%)",
    whiteSpace: "nowrap",

    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[5],
  },

  button: {
    fontWeight: 700,
    fontSize: 14,
    margin: theme.spacing(0.75),
    borderRadius: theme.spacing(0.5),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 10),
      fontSize: 16,
    },
  },

  outlinedButton: {
    borderColor: theme.palette.secondary.main,
  },

  spacer: {
    width: "100%",
    height: theme.spacing(20),
  },
}));

export interface ConfirmChangesDialogProps
  extends BoxProps,
    ConfirmDialogProps {}

export function ConfirmChangesDialog(props: ConfirmChangesDialogProps) {
  const { className, onConfirm, onCancel, dirty, ...other } = props;
  const s = useStyles();
  const [, update] = useHistoryBlock();

  React.useEffect(() => {
    update(dirty, onCancel);
  }, [dirty, onCancel, update]);

  return (
    <Box>
      {dirty && (
        <Box className={s.wrapper}>
          <Box className={clsx(s.root, className)} {...other}>
            <Button
              className={clsx(s.button, s.outlinedButton)}
              variant="outlined"
              onClick={onCancel}
            >
              Discard
            </Button>
            <Button
              className={s.button}
              variant="contained"
              onClick={onConfirm}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      )}
      <Box className={s.spacer} />
    </Box>
  );
}
