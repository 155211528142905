import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardProps,
  IconButton,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import makeStyles from "@mui/styles/makeStyles";
import { chunk, sortBy, reverse } from "lodash";
import omit from "lodash.omit";
import { Bar } from "react-chartjs-2";
import {
  // ChartPoint,
  TimeUnit,
} from "chart.js";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ClientStepsCard_client$key } from "./__generated__/ClientStepsCard_client.graphql";
import { Units } from "../../constants";

import { addDays } from "../../utils/date";
import { colorSystem } from "../../theme";
import { ClientStepsDrawer } from "./ClientStepsDrawer";
import { TextButton } from "../button/TextButton";

import { ReactComponent as StepsIcon } from "../../icons/Steps.svg";
import { ReactComponent as EditIcon } from "../../icons/PenEdit.svg";
import { ReactComponent as ChevronIcon } from "../../icons/ChevronSilverLeft.svg";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerContent: {
    marginLeft: theme.spacing(1.3),
  },

  today: {
    fontSize: 10,
    fontWeight: 800,
    lineHeight: "12px",
    color: theme.palette.common.black,
    backgroundColor: colorSystem.gray9,
    borderRadius: 4,
    width: "fit-content",
    padding: theme.spacing(0.2, 0.8),
    textTransform: "uppercase",
  },

  subtitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: theme.palette.common.black,
  },

  editButton: {
    margin: theme.spacing(-10, 0),
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2.3, -0.45, 3, -0.45),
  },

  button: {
    margin: theme.spacing(0, 0.45),
    backgroundColor: "transparent",
    borderWidth: 0,
    color: theme.palette.text.secondary,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 700,
    padding: theme.spacing(0.8, 0.2),
    lineHeight: "17px",
    "&:hover": {
      color: colorSystem.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  activeButton: {
    color: colorSystem.white,
    backgroundColor: theme.palette.secondary.main,
  },

  headerNav: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  navButtonRight: {
    transform: "rotate(180deg)",
  },

  navButton: {
    width: 40,
  },

  todayContainer: {
    display: "flex",
    alignItems: "center",
  },
  todayWeight: {
    fontSize: 13,
    lineHeight: "13px",
    fontWeight: 500,
    color: colorSystem.gray,
    marginLeft: 6,
  },
  indicator: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },
  indicatorValue: {
    fontSize: 20,
    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  headerContainer: {
    display: "flex",
  },
  smlsubtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  gain: {},
  drop: {},

  syncButton: {
    color: colorSystem.black,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
}));

const clientFragment = graphql`
  fragment ClientStepsCard_client on User {
    units
    steps: metrics(
      metricType: CHECKIN_ANSWER_MEASUREMENT
      measurementType: STEP_COUNT
    ) {
      id
      activityDate(raw: true)
      value {
        ... on CheckinAnswerMeasurementValue {
          measurement
        }
      }
    }
  }
`;

export interface ClientStepsCardProps extends CardProps {
  clientRef: ClientStepsCard_client$key | any;
}

export function ClientStepsCard(props: ClientStepsCardProps) {
  const { clientRef, ...other } = props;
  const client = useFragment(clientFragment, clientRef);
  const { breakpoints } = useTheme();

  const [steps, setSteps] = useState([]);
  const [activeButton, setActiveButton] = useState<TimeUnit>("day");
  const [navIndex, setNavIndex] = useState(0);
  const [isOpenContent, setIsOpenContent] = React.useState(true);
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const units = client.units as Units;

  const s = useStyles();
  const theme = useTheme();

  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-goals",
  });

  const chunkOffset = React.useCallback(() => {
    switch (activeButton) {
      case "day":
        return 5;
      case "week":
        return 4;
      case "month":
        return 3;
    }
  }, [activeButton]);

  const data = React.useMemo(() => {
    return sortBy(steps, ({ activityDate }) => activityDate).map(
      ({ activityDate, value }) => ({
        x: activityDate,
        y: value.measurement,
      }),
    );
  }, [steps]);

  const sortingSteps = React.useMemo(() => {
    return reverse(sortBy(steps, ({ activityDate }) => activityDate));
  }, [steps]);

  const getToday = React.useCallback(() => {
    return steps.find(
      (day) => day.activityDate === dayjs().format("YYYY-MM-DD"),
    );
  }, [steps]);

  const todayValue = React.useMemo(() => {
    const today = getToday();
    return today ? `${today.value.measurement} steps` : "0 steps ";
  }, [getToday]);

  const chunkData = React.useCallback(() => {
    switch (activeButton) {
      case "day":
        return chunk(data, chunkOffset());
      case "week": {
        let firsDay = dayjs(data.length ? data[0].x : 0);
        let lastDay = dayjs(data.length ? data[0].x : 0).add(6, "days");
        let newWeeks = [];
        let weekTotal = 0;
        let averageValueDivision = 0;
        data.forEach((item, index) => {
          averageValueDivision++;
          if (dayjs(item.x, "YYYY-MM-DD").diff(lastDay) < 0) {
            weekTotal += item.y;
            if (index === data.length - 1) {
              newWeeks = [
                ...newWeeks,
                {
                  x: lastDay.format("YYYY-MM-DD"),
                  y: weekTotal / averageValueDivision,
                  labelDate: `${firsDay.format(
                    "YYYY.MM.DD",
                  )} - ${lastDay.format("YYYY.MM.DD")}`,
                },
              ];
              averageValueDivision = 0;
            }
          } else if (dayjs(item.x).diff(lastDay) === 0) {
            newWeeks = [
              ...newWeeks,
              {
                x: lastDay.format("YYYY-MM-DD"),
                y: (weekTotal + item.y) / averageValueDivision,
                labelDate: `${firsDay.format("YYYY.MM.DD")} - ${lastDay.format(
                  "YYYY.MM.DD",
                )}`,
              },
            ];
            averageValueDivision = 0;
            firsDay = dayjs(item.x).add(1, "days");
            lastDay = dayjs(item.x).add(7, "days");
            weekTotal = 0;
          } else {
            if (index === data.length - 1) {
              newWeeks = [
                ...newWeeks,
                {
                  x: lastDay.format("YYYY-MM-DD"),
                  y: weekTotal / averageValueDivision,
                  labelDate: `${firsDay.format(
                    "YYYY.MM.DD",
                  )} - ${lastDay.format("YYYY.MM.DD")}`,
                },
                {
                  x: dayjs(item.x).add(6, "days"),
                  y: item.y,
                  labelDate: `${dayjs(item.x).format("YYYY.MM.DD")} - ${dayjs(
                    item.x,
                  )
                    .add(6, "days")
                    .format("YYYY.MM.DD")}`,
                },
              ];
              averageValueDivision = 0;
            } else {
              newWeeks = [
                ...newWeeks,
                {
                  x: lastDay.format("YYYY-MM-DD"),
                  y: weekTotal / (averageValueDivision - 1),
                  labelDate: `${firsDay.format(
                    "YYYY.MM.DD",
                  )} - ${lastDay.format("YYYY.MM.DD")}`,
                },
              ];
              firsDay = dayjs(item.x);
              lastDay = dayjs(item.x).add(6, "days");
              weekTotal = item.y;
            }
            averageValueDivision = 1;
          }
        });
        return chunk(newWeeks, chunkOffset());
      }
      case "month": {
        let newMonths = [];
        let monthTotal = 0;
        let currentMonth = dayjs(data.length ? data[0].x : 0);
        let averageValueDivision = 0;
        data.forEach((item, index) => {
          averageValueDivision++;
          if (dayjs(item.x).isSame(currentMonth, "month")) {
            monthTotal += item.y;
            if (index === data.length - 1) {
              newMonths = [
                ...newMonths,
                {
                  x: currentMonth.format("YYYY-MM"),
                  y: monthTotal / averageValueDivision,
                  labelDate: currentMonth.format("YYYY.MM"),
                },
              ];
              averageValueDivision = 0;
            }
          } else if (dayjs(item.x).isAfter(currentMonth, "month")) {
            if (index === data.length - 1) {
              newMonths = [
                ...newMonths,
                {
                  x: currentMonth.format("YYYY-MM"),
                  y: monthTotal / averageValueDivision,
                  labelDate: currentMonth.format("YYYY.MM"),
                },
                {
                  x: dayjs(item.x).format("YYYY-MM"),
                  y: item.y,
                  labelDate: dayjs(item.x).format("YYYY.MM"),
                },
              ];
              averageValueDivision = 0;
            } else {
              newMonths = [
                ...newMonths,
                {
                  x: currentMonth.format("YYYY-MM"),
                  y: monthTotal / (averageValueDivision - 1),
                  labelDate: currentMonth.format("YYYY.MM"),
                },
              ];
              currentMonth = dayjs(item.x);
              monthTotal = item.y;
            }
            averageValueDivision = 1;
          }
        });
        return chunk(newMonths, chunkOffset());
      }
    }
  }, [activeButton, data, chunkOffset]);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  useEffect(() => {
    const data = client.steps;
    const steps = sortBy(data, ({ activityDate }) => activityDate)
      .filter((step) => step)
      .map((step) => ({
        ...step,
        activityDate: dayjs(step.activityDate).format("YYYY-MM-DD"),
      }));
    setSteps(steps);
  }, [client]);

  const chartData = chunkData();

  return (
    <>
      <Card className={clsx(s.root)} {...other}>
        <Box className={s.header}>
          <Box className={s.headerContainer}>
            <StepsIcon />
            <Box className={s.headerContent}>
              <Box className={s.todayContainer}>
                <Typography variant="h6" className={s.today} children="Today" />
              </Box>
              <Typography variant="body1" className={clsx(s.subtitle)}>
                {todayValue}
                <IconButton
                  className={s.editButton}
                  {...bindToggle(drawerState)}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Box>

        <>
          <Box className={s.buttons}>
            <TextButton
              className={clsx(
                s.button,
                activeButton === "day" && s.activeButton,
              )}
              onClick={() => {
                setActiveButton("day");
                setNavIndex(0);
              }}
            >
              Days
            </TextButton>
            <TextButton
              className={clsx(
                s.button,
                activeButton === "week" && s.activeButton,
              )}
              onClick={() => {
                setActiveButton("week");
                setNavIndex(0);
              }}
            >
              Weeks
            </TextButton>
            <TextButton
              className={clsx(
                s.button,
                activeButton === "month" && s.activeButton,
              )}
              onClick={() => {
                setActiveButton("month");
                setNavIndex(0);
              }}
            >
              Months
            </TextButton>
            <Box className={s.headerNav}>
              <IconButton
                className={s.navButton}
                onClick={() => setNavIndex((prev) => prev - 1)}
                disabled={navIndex === 0}
                size="large"
              >
                <ChevronIcon />
              </IconButton>
              <IconButton
                className={clsx(s.navButton, s.navButtonRight)}
                onClick={() => setNavIndex((prev) => prev + 1)}
                disabled={chartData && navIndex >= chartData.length - 1}
                size="large"
              >
                <ChevronIcon />
              </IconButton>
            </Box>
          </Box>
          <Bar
            height={60}
            redraw
            data={{
              datasets: [
                {
                  data: chartData[navIndex],
                  backgroundColor: `${theme.palette.primary.main}`,
                  borderRadius: 3,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    title: function (context) {
                      const parts = context[0].label.split(",");
                      return `${parts[0].trim()}, ${parts[1].trim()}`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: activeButton,
                    displayFormats: {
                      day: "MMM dd",
                      week: "MMM dd",
                      month: "MMMM",
                    },
                  },
                },
              },
            }}
          />
        </>
      </Card>
      {drawerState.isOpen && (
        <Portal>
          <ClientStepsDrawer
            {...omit(bindPopper(drawerState), ["anchorStepEl"])}
            onClose={drawerState.close}
            setSteps={setSteps}
            today={getToday()}
            steps={sortingSteps}
            clientId={clientRef?.id || clientRef.__id}
            units={units}
          />
        </Portal>
      )}
    </>
  );
}
