import clsx from "clsx";
import React from "react";
import {
  TextField,
  TextFieldProps,
  Box,
  ClickAwayListener,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ClassNameMap } from "@mui/styles";
import { Node, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { MessageEditorGifButton } from "../message-editor/MessageEditorAttachGifButton";
import { MessageEditorAttachFileButton } from "../message-editor/MessageEditorAttachFileButton";
import { MessageEditorEmojiButton } from "../message-editor/MessageEditorEmojiButton";
import {
  MessageEditorSendButton,
  MessageEditorSendButtonProps,
} from "../message-editor/MessageEditorSendButton";
import { deviceSupports } from "../../utils/device";
import { MessageAttachmentsPreviewList } from "../message-editor/MessageAttachmentsPreviewList";
import { AttachmentElement } from "../message-editor/MessageAttachmentsPreviewListItem";
import { UploadedFile } from "../../hooks/useUploadFile";
import { emulateTextType } from "../../utils/selection";
import {
  useBackupState,
  LocalStorageObjectType,
} from "../../hooks/useLocalStorage";
import { createTextMessage } from "../../utils/slate";

import { ElementType } from "../editor/types/elements";
import { SchemaElements } from "../editor/normalizers/withSchema";
import { getFocusPoint } from "../editor/utils/focus";
import { isEmptyContent } from "../editor/utils/common";

const useStyles = makeStyles((theme) => ({
  root: {},
  expanded: {},
  focused: {},

  fullWidth: {
    width: "100%",
  },

  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    outlineWidth: 1,
    outlineStyle: "solid",
    outlineColor: theme.palette.border.primary,

    "&:hover": {
      outlineColor: theme.palette.secondary.main,
    },

    "$focused &": {
      outlineWidth: 2,
      outlineColor: theme.palette.primary.main,
    },
  },

  input: {
    marginBottom: "auto",
    overflowY: "scroll",

    "& .MuiInputBase-multiline": {
      padding: theme.spacing(1.75),
    },

    "& .MuiOutlinedInput-root": {
      minHeight: theme.spacing(5.75),
    },

    "& fieldset": {
      border: 0,
    },

    "& input": {
      fontSize: 14,
      fontWeight: 400,
      padding: theme.spacing(1.5),
      border: 0,
    },
  },

  attachments: {
    margin: theme.spacing(-1, 0),
    flexShrink: 0,
  },

  buttons: {
    display: "flex",
    alignItems: "flex-end",
    margin: 2,
    backgroundColor: theme.palette.background.paper,

    ":not($expanded) &": {
      position: "absolute",
      right: 0,
      bottom: 0,
      top: 0,
    },

    "$expanded &": {
      position: "static",
    },
  },

  button: {
    padding: theme.spacing(1),

    "&:not(:last-child)": {
      marginRight: theme.spacing(-1),
    },
  },

  send: {
    marginLeft: "auto",
  },
}));

export type MessageEditorClassKey = "root" | "container" | "buttons" | "input";

const createAttachment = (
  type: ElementType,
  attrs: Omit<AttachmentElement, "id" | "type">,
) =>
  SchemaElements.createElement(type, attrs, [
    { text: "" },
  ]) as any as AttachmentElement;

const stringifyContent = (content: any, separator = "\r\n") =>
  content.map((node) => Node.string(node)).join(separator);

export interface MessageEditorProps
  extends Omit<TextFieldProps, "variant" | "onChange" | "defaultValue"> {
  classes?: Partial<ClassNameMap<MessageEditorClassKey>>;
  fullWidth?: boolean;
  hideAttachButton?: boolean;
  hideSendButton?: boolean;
  hideGifButton?: boolean;
  hideEmojiButton?: boolean;
  sendButtonVariant?: MessageEditorSendButtonProps["variant"];
  rowsMax?: number;
  rows?: number;
  expanded?: boolean;
  softBreak?: boolean;

  backupKeyType: LocalStorageObjectType;
  backupKeyId?: string;

  onMessageSubmit?: (value: any, callback?: () => void) => void;
  message?: any;
  onMessageChange?: (value: any) => void;
  buttonRef?:
    | React.MutableRefObject<HTMLButtonElement>
    | React.MutableRefObject<HTMLDivElement>;

  rootMinHeight?: number;

  inputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>;
}

export function MessageEditor(props: MessageEditorProps) {
  const s = useStyles();
  const {
    className,
    onMessageSubmit,
    classes = {},
    fullWidth = false,
    hideAttachButton = !deviceSupports.arbitraryFileUpload,
    hideEmojiButton = deviceSupports.emojiKeyboard,
    hideSendButton = false,
    hideGifButton = false,
    softBreak = true,
    sendButtonVariant = "normal",
    message: initialMessage = [],
    buttonRef,
    onMessageChange,
    rowsMax = 5,
    expanded,
    rows,
    backupKeyType,
    backupKeyId,
    InputProps,
    disabled,
    rootMinHeight,
    inputRef,
    ...other
  } = props;
  const [focused, setFocused] = React.useState(false);
  const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const inputCombinedRef = inputRef ?? ref;
  const editor: ReactEditor = props.inputProps?.editor;

  const [content, setContent, removeContentBackup] = useBackupState(
    backupKeyType,
    backupKeyId + "-content",
    initialMessage,
  );

  const [attachments, setAttachments, removeAttachmentsBackup] = useBackupState(
    backupKeyType,
    backupKeyId + "-attachments",
    [] as AttachmentElement[],
  );

  const [message, setMessage] = React.useState([
    ...content,
    ...attachments,
  ] as any);

  const handleRemoveBackup = React.useCallback(() => {
    removeContentBackup();
    removeAttachmentsBackup();
  }, [removeAttachmentsBackup, removeContentBackup]);

  const handleFocus = React.useCallback(() => {
    setFocused(true);
  }, []);

  const handleClickAway = React.useCallback(() => {
    setFocused(false);
  }, []);

  const handleSelectEmoji = React.useCallback(
    (emoji: string) => {
      try {
        if (editor) {
          const at = getFocusPoint(editor);
          Transforms.insertText(editor, emoji, { at });
        } else {
          const el = inputCombinedRef.current;
          el.focus();
          emulateTextType(emoji);
          const content = [createTextMessage(el.value)];
          const message = [...content, ...attachments] as any;

          setContent(content);
          setMessage(message);
        }
      } catch (e) {
        console.error("failed at emoji insert", e);
      }
    },
    [editor, setContent],
  );

  const appendAttachment = React.useCallback(
    (attachment: AttachmentElement) => {
      const newAttachments = [...(attachments || []), attachment];
      const message = [...content, ...newAttachments] as any;

      setAttachments(newAttachments);
      setMessage(message);
    },
    [attachments, content, setAttachments],
  );

  const handleSelectGif = React.useCallback(
    (giphyId: string) =>
      appendAttachment(createAttachment(ElementType.GIF, { giphyId })),
    [appendAttachment],
  );

  const handleFileUpload = React.useCallback(
    ({ url, file: { name, size, type: mimeType } }: UploadedFile) =>
      appendAttachment(
        createAttachment(ElementType.ATTACHMENT, { url, name, size, mimeType }),
      ),
    [appendAttachment],
  );

  const handleMessageTextChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const text = event.target.value;
      const content = editor ? JSON.parse(text) : [createTextMessage(text)];
      const message = [...content, ...attachments] as any;

      setContent(content);
      setMessage(message);

      if (onMessageChange) {
        onMessageChange(message);
      }
    },
    [attachments, editor, onMessageChange, setContent],
  );

  const handleSubmitted = React.useCallback(() => {
    setContent([]);
    setAttachments([]);
    setMessage([]);
    handleRemoveBackup();
    setFocused(false);
  }, [handleRemoveBackup, setAttachments, setContent]);

  const handleSubmitClick = React.useCallback(
    (event) => {
      if (onMessageSubmit) {
        const filterMessages = message.filter((message) =>
          message.type === "message" && message.children[0].text === ""
            ? false
            : message,
        );
        onMessageSubmit(filterMessages, handleSubmitted);
      } else {
        handleSubmitted();
      }
    },
    [handleSubmitted, onMessageSubmit, message],
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event.target["tagName"] === "DIV") {
        if (!editor && inputCombinedRef?.current) {
          inputCombinedRef.current.focus();
        }
      }
    },
    [editor],
  );

  const hasAttachments = attachments.length > 0;
  const empty = isEmptyContent(message) && !hasAttachments;
  const canSubmit = !empty;
  const hasGif = attachments.some(({ type }) => type === ElementType.GIF);
  const showSendButton =
    !hideSendButton && (focused || sendButtonVariant === "icon" || !empty);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (!softBreak && event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();

        if (canSubmit) {
          handleSubmitClick(event);
        }
      }
    },
    [canSubmit, handleSubmitClick, softBreak],
  );

  React.useEffect(() => {
    const el = buttonRef?.current;

    if (el) {
      if (el instanceof HTMLButtonElement) {
        el.disabled = !canSubmit;
      }
      el.addEventListener("click", handleSubmitClick);

      return () => el.removeEventListener("click", handleSubmitClick);
    }
  }, [buttonRef, canSubmit, handleSubmitClick]);

  return (
    <Box
      className={clsx(s.root, className, classes.root, {
        [s.focused]: focused,
        [s.expanded]: expanded || focused || !empty,
        [s.fullWidth]: fullWidth,
      })}
      onClick={handleClick}
      sx={{ minHeight: rootMinHeight }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={clsx(s.container, classes.container)}>
          <TextField
            className={clsx(s.input, classes.input)}
            value={editor ? JSON.stringify(content) : stringifyContent(content)}
            onChange={handleMessageTextChange}
            onKeyDown={handleKeyDown}
            inputRef={inputCombinedRef}
            maxRows={rows || rowsMax}
            rows={rows}
            InputProps={{
              onFocus: handleFocus,
              ...InputProps,
            }}
            variant="outlined"
            disabled={disabled}
            fullWidth
            multiline
            {...other}
          />

          <MessageAttachmentsPreviewList
            className={s.attachments}
            attachments={attachments}
            onUpdateAttachments={setAttachments}
          />
          <Box className={clsx(s.buttons, classes.buttons)}>
            {!hideGifButton && (
              <MessageEditorGifButton
                className={s.button}
                onSelectGif={handleSelectGif}
                disabled={hasAttachments || disabled}
              />
            )}
            {!hideAttachButton && (
              <MessageEditorAttachFileButton
                className={s.button}
                disabled={hasGif || disabled}
                onFileUpload={handleFileUpload}
              />
            )}
            {!hideEmojiButton && (
              <MessageEditorEmojiButton
                className={s.button}
                onSelectEmoji={handleSelectEmoji}
                disabled={disabled}
              />
            )}
            {showSendButton && (
              <MessageEditorSendButton
                variant={sendButtonVariant}
                className={s.send}
                onClick={handleSubmitClick}
                disabled={!canSubmit || disabled}
              />
            )}
          </Box>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}

export default MessageEditor;
