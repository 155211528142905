/**
 * @generated SignedSource<<c0aa5df851ea844ae1dc3a74fb4d0dc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClientStatus = "ACTIVE" | "ADDED" | "ARCHIVED" | "PENDING" | "%future added value";
export type InviteSource = "EMAIL" | "LINK" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PendingInviteItem_invite$data = {
  readonly client: {
    readonly clientStatus: ClientStatus;
    readonly id: string;
  } | null | undefined;
  readonly createdAt: string | null | undefined;
  readonly email: string;
  readonly id: string;
  readonly source: InviteSource;
  readonly " $fragmentType": "PendingInviteItem_invite";
};
export type PendingInviteItem_invite$key = {
  readonly " $data"?: PendingInviteItem_invite$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingInviteItem_invite">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PendingInviteItem_invite",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMMM Do"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"MMMM Do\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Invite",
  "abstractKey": null
};
})();

(node as any).hash = "f42bd974c578a7076a1fe53f7e60477f";

export default node;
