/**
 * @generated SignedSource<<21f0ee7cf7a0f3346efad7d190b50508>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertUserNoteInput = {
  clientMutationId?: string | null | undefined;
  content: string;
  id?: string | null | undefined;
  userId: string;
};
export type UserNoteDialogUpsertUserNoteMutation$variables = {
  input: UpsertUserNoteInput;
};
export type UserNoteDialogUpsertUserNoteMutation$data = {
  readonly upsertUserNote: {
    readonly userNote: {
      readonly " $fragmentSpreads": FragmentRefs<"UserNotesListItem_note">;
    };
  } | null | undefined;
};
export type UserNoteDialogUpsertUserNoteMutation = {
  response: UserNoteDialogUpsertUserNoteMutation$data;
  variables: UserNoteDialogUpsertUserNoteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNoteDialogUpsertUserNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertUserNotePayload",
        "kind": "LinkedField",
        "name": "upsertUserNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNote",
            "kind": "LinkedField",
            "name": "userNote",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserNotesListItem_note"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNoteDialogUpsertUserNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertUserNotePayload",
        "kind": "LinkedField",
        "name": "upsertUserNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNote",
            "kind": "LinkedField",
            "name": "userNote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": "createdAtDate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMMM D, YYYY"
                  }
                ],
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": "createdAt(format:\"MMMM D, YYYY\")"
              },
              {
                "alias": "createdAtTime",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "hh:mmA"
                  }
                ],
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": "createdAt(format:\"hh:mmA\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebd04189a7743018303c961a8efd1dd4",
    "id": null,
    "metadata": {},
    "name": "UserNoteDialogUpsertUserNoteMutation",
    "operationKind": "mutation",
    "text": "mutation UserNoteDialogUpsertUserNoteMutation(\n  $input: UpsertUserNoteInput!\n) {\n  upsertUserNote(input: $input) {\n    userNote {\n      ...UserNotesListItem_note\n      id\n    }\n  }\n}\n\nfragment UserNoteDialog_note on UserNote {\n  id\n  content\n}\n\nfragment UserNotesListItem_note on UserNote {\n  ...UserNoteDialog_note\n  id\n  content\n  createdAtDate: createdAt(format: \"MMMM D, YYYY\")\n  createdAtTime: createdAt(format: \"hh:mmA\")\n  user {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe829c52f8bc700ca06d9a1cf6dd4cbe";

export default node;
