import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  workoutContainer: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.gray7,
    borderRadius: 4,
    padding: 20,
    cursor: "pointer",
  },
  workout: {
    padding: `${theme.spacing(0)} !important`,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: colorSystem.gray7,
    marginTop: 20,
  },
  footer: {
    marginTop: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  arrow: {
    transform: "rotate(90deg)",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "24px",
  },
  recorded: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
}));

type ClientWorkoutsItemProps = {
  index?: number;
  title: string;
  date: string;
  onClick: () => void;
  session: number;
  min: number;
};

const ClientWorkoutsItem = (props: ClientWorkoutsItemProps) => {
  const { index, title, date, onClick, session, min } = props;
  const s = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <Card className={s.workoutContainer} onClick={onClick}>
        <CardContent className={s.workout}>
          <Box className={s.header}>
            <Box>
              <Typography className={s.title}>{title}</Typography>
              <Typography
                className={s.recorded}
              >{`Recorded ${date}`}</Typography>
            </Box>
            <ArrowIcon className={s.arrow} />
          </Box>
          <Box className={s.line} />
          <Box className={s.footer}>
            <Typography className={s.recorded}>{`Sets ${session}`}</Typography>
            <Typography className={s.recorded}>{`${min} min`}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ClientWorkoutsItem;
