import React, { useContext, useEffect, useRef } from "react";
import { Typography, Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, usePaginationFragment } from "react-relay";

import { CreateFirstProgram } from "../program/CreateFirstProgram";
import { UploadProfilePhotoDialog } from "../settings/dialogs/UploadProfilePhotoDialog";
import { polyfillCSS } from "../../utils/css";
import { ProgramStatus } from "../../constants";
import { useDebounce } from "../../hooks/useDebounce";
import { PageSkeleton } from "../loading/PageSkeleton";

import { CoachProgramCard, CoachProgramCardDisplay } from "./CoachProgramCard";
import { CoachProgramsListScreen_root$key } from "./__generated__/CoachProgramsListScreen_root.graphql";
import { CreateButton } from "./buttons/CreateProgram";
import { useProgramListFilterContext } from "../../contexts/ProgramListFilterContext";
import { CoachProgramsListScreenContext } from "../../contexts/CoachProgramsListScreenContext";
import { useSearchParams } from "react-router-dom";
import TrackInfoTool from "../tools/TrackInfoTool";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(5)} + var(--safe-area-inset-right)) !important`,
    ),
  },

  grid: {},
  list: {},

  clients: {
    display: "grid",
    gridGap: theme.spacing(3),
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
      gridGap: theme.spacing(3.5),
    },

    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: theme.spacing(4),
    },

    "$list &": {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
  },

  filters: {
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(3),
  },

  moreButton: {
    marginTop: theme.spacing(1.5),
  },
}));

const rootFragment = graphql`
  fragment CoachProgramsListScreen_root on Root
  @refetchable(queryName: "CoachProgramsListScreenRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 12 }
    after: { type: "String" }
    status: { type: "ProgramStatus" }
    orderBy: { type: "ProgramSortBy" }
    query: { type: "String" }
    folder: { type: "ID" }
  ) {
    programs(
      first: $first
      after: $after
      status: $status
      orderBy: $orderBy
      query: $query
      folder: $folder
    ) @connection(key: "ProgramsList_programs", filters: []) {
      edges {
        node {
          id
          ...CoachProgramCard_program
        }
      }
      totalCount
    }
    programFolders {
      id
      name
    }
  }
`;

export type CoachProgramsListScreenProps = {
  rootRef: CoachProgramsListScreen_root$key;
  status?: ProgramStatus;
  pageSize?: number;
};

export function CoachProgramsListScreen(props: CoachProgramsListScreenProps) {
  const s = useStyles();
  const { rootRef, status, pageSize = 12 } = props;
  const {
    data: root,
    loadNext,
    hasNext,
    refetch: refetchConnection,
  } = usePaginationFragment(rootFragment, rootRef);

  const programs = root?.programs;

  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const photoPrompt = searchParams.get("photoPrompt");
  const context = useContext(CoachProgramsListScreenContext);
  context.setProgramFolders(root);
  const { query, sortKey, folder, display } = useProgramListFilterContext();

  const setLoaded = React.useCallback(() => setLoading(false), []);

  const handleMoreClick = React.useCallback(() => {
    setLoading(true);
    loadNext(pageSize, { onComplete: setLoaded });
  }, [pageSize, loadNext, setLoaded]);

  const delayedQuery = useDebounce(query, 250);
  const handleLoaded = React.useCallback(() => setLoading(false), []);

  const refetch = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      context.setPending(false);
      refetchConnection(
        {
          query: delayedQuery,
          folder,
          orderBy: sortKey,
          status,
        },
        {
          ...RELAY_LAZY_LOAD_COMMON_CONFIG,
          onComplete: handleLoaded,
        },
      );
    }
  }, [delayedQuery, folder, sortKey, status, pageSize]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    refetch();
  }, [refetch]);

  const handlePhotoSaved = React.useCallback(() => {
    setSearchParams(undefined);
  }, [setSearchParams]);

  const handleSkipClick = React.useCallback(() => {
    setSearchParams(undefined);
  }, [setSearchParams]);

  const hasPrograms = programs?.totalCount > 0;
  const actions = hasPrograms ? [<CreateButton />] : undefined;

  return (
    <>
      {!context.pending &&
        !!programs &&
        (programs.edges.length === 0 ? (
          status === ProgramStatus.PUBLISHED ? (
            <CreateFirstProgram />
          ) : (
            <Typography>Nothing to show here</Typography>
          )
        ) : (
          <Box
            className={s.clients}
            style={{
              display:
                display === CoachProgramCardDisplay.grid ? "grid" : "block",
            }}
          >
            {programs.edges.map(({ node }) => (
              <CoachProgramCard
                key={node.id}
                display={display}
                className={s.card}
                program={node}
                onFolderUpdated={() => {
                  refetch();
                }}
              />
            ))}
          </Box>
        ))}
      {!context.pending && hasNext && (
        <Button
          onClick={handleMoreClick}
          className={s.moreButton}
          fullWidth
          disabled={loading}
        >
          Load more
        </Button>
      )}

      {(loading || context.pending || !programs) && <PageSkeleton fullWidth />}

      {photoPrompt && (
        <UploadProfilePhotoDialog
          open={Boolean(photoPrompt)}
          onSaved={handlePhotoSaved}
          onSkipClick={handleSkipClick}
        />
      )}

      <TrackInfoTool
        trackInfo={{
          name: "Coach - Programs",
          properties: {
            status,
          },
        }}
      />
    </>
  );
}
