import React from "react";
import AppLayout from "../../../components/app/AppLayout";
import { SignUpSetUpScreen } from "../../../components/auth/SignUpSetUpScreen";
import { useSearchParams } from "react-router-dom";
import { SignUpStartScreen } from "../../../components/auth/SignUpStartScreen";

const SignupRoute = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const email = searchParams.get("email");
  const id = searchParams.get("id");

  return (
    <AppLayout appBar={false} title="Sign Up">
      {mode === "verifyEmail" && oobCode && id && email ? (
        <SignUpSetUpScreen code={oobCode} id={id} email={email} />
      ) : (
        <SignUpStartScreen />
      )}
    </AppLayout>
  );
};

export default SignupRoute;
