import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { MessagingScreen } from "../../../components/screen/MessagingScreen";
import { useParams } from "react-router-dom";
import { CoachMessagesRouteQuery } from "./__generated__/CoachMessagesRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";

export function CoachMessagesRoute() {
  const { recipient } = useParams();

  const recipientSelected = Boolean(recipient);

  const props = useLazyLoadQuery<CoachMessagesRouteQuery>(
    graphql`
      query CoachMessagesRouteQuery(
        $recipient: String!
        $shouldFetchMessages: Boolean!
      ) {
        ...MessagingScreen_root
          @arguments(
            recipient: $recipient
            shouldFetchMessages: $shouldFetchMessages
          )
      }
    `,

    {
      recipient: recipientSelected ? recipient : "",
      shouldFetchMessages: recipientSelected,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  return <MessagingScreen root={props as any} selectedThread={recipient} />;
}
