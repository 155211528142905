import React from "react";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ClientActiveProgramCard_enrollment$key } from "./__generated__/ClientActiveProgramCard_enrollment.graphql";
import { ClientSmallProgramCard } from "./ClientSmallProgramCard";
import { ClientCardCompletion } from "./ClientCardCompletion";

const enrollmentFragment = graphql`
  fragment ClientActiveProgramCard_enrollment on Enrollment {
    ...ClientSmallProgramCard_enrollment
    ...ClientCardCompletion_enrollment
    currentDay
    totalDays
  }
`;

export interface ClientActiveProgramCardProps {
  enrollmentRef: ClientActiveProgramCard_enrollment$key;
}

export function ClientActiveProgramCard(props: ClientActiveProgramCardProps) {
  const { enrollmentRef, ...other } = props;
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);
  const { currentDay, totalDays } = enrollment;

  const content = React.useMemo(
    () => <ClientCardCompletion enrollmentRef={enrollment} />,
    [enrollment],
  );

  return (
    <ClientSmallProgramCard
      enrollmentRef={enrollment}
      subtitle={`Day ${currentDay} of ${totalDays}`}
      content={content}
      {...other}
    />
  );
}
