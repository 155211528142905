export enum LinkPreviewVariant {
  INLINE = "inline",
  RICH = "rich",
  TEXT = "text",
}

export interface UrlMetadata {
  url: string;
  variant?: LinkPreviewVariant;
  title?: string;
  description?: string;
  image?: string;
}

export interface LinkWithPreviewNode {
  metadata?: UrlMetadata;
}
