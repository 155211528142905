import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";
import { useCurrentUser } from "../../../../../hooks/useCurrentUser";
import { ExerciseTypeSet, Units } from "../../../../../constants";
import { unitSetLabelCoach } from "../../../../../utils/units";

export const WeightValuesInfoSection = () => {
  const me = useCurrentUser();
  const defaultCoachUnits = me.units ?? Units.US;

  return (
    <InfoSection
      title="Values"
      description={
        <>
          Float numbers permitted in <b>Distance</b> / <b>Time</b> /{" "}
          <b>Weight</b> column.
        </>
      }
    >
      <InfoBox title="Number" description="Apply exact number for sets.">
        <ExampleValueBadge
          value="150"
          unit={unitSetLabelCoach(defaultCoachUnits, ExerciseTypeSet.DISTANCE)}
        />
      </InfoBox>

      <InfoBox title="Empty" description="Leave empty for client to enter.">
        <ExampleValueBadge value="-" />
      </InfoBox>
    </InfoSection>
  );
};
