/**
 * @generated SignedSource<<96c4518db905e928bc0c0dc8b6a87591>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateNotificationsSettingInput = {
  activityCompletion?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  dailyUpdate?: boolean | null | undefined;
  groupReplyEmail?: boolean | null | undefined;
  groupReplyPush?: boolean | null | undefined;
  newGroupPostEmail?: boolean | null | undefined;
  newGroupPostPush?: boolean | null | undefined;
  newMessageEmail?: boolean | null | undefined;
  newMessagePush?: boolean | null | undefined;
  newResponseEmail?: boolean | null | undefined;
  newResponsePush?: boolean | null | undefined;
  remindersEmail?: boolean | null | undefined;
  remindersPush?: boolean | null | undefined;
  remindersSms?: boolean | null | undefined;
  userId?: string | null | undefined;
  weeklyUpdate?: boolean | null | undefined;
};
export type CoachSettingsAccountNotificationUpdateMutation$variables = {
  input: UpdateNotificationsSettingInput;
};
export type CoachSettingsAccountNotificationUpdateMutation$data = {
  readonly updateNotificationsSetting: {
    readonly notificationsSetting: {
      readonly activityCompletion: boolean;
      readonly id: string;
      readonly newMessageEmail: boolean;
      readonly newResponseEmail: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type CoachSettingsAccountNotificationUpdateMutation = {
  response: CoachSettingsAccountNotificationUpdateMutation$data;
  variables: CoachSettingsAccountNotificationUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateNotificationsSettingPayload",
    "kind": "LinkedField",
    "name": "updateNotificationsSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationsSetting",
        "kind": "LinkedField",
        "name": "notificationsSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newMessageEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newResponseEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activityCompletion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachSettingsAccountNotificationUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachSettingsAccountNotificationUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f455570f0d7f359ac2c8c15aa60e97c4",
    "id": null,
    "metadata": {},
    "name": "CoachSettingsAccountNotificationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CoachSettingsAccountNotificationUpdateMutation(\n  $input: UpdateNotificationsSettingInput!\n) {\n  updateNotificationsSetting(input: $input) {\n    notificationsSetting {\n      id\n      newMessageEmail\n      newResponseEmail\n      activityCompletion\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab13fd5a6ef0c26f6d1577d6ce55c8b5";

export default node;
