import React from "react";

import { ThemeProvider_workspace$data } from "../components/app/__generated__/ThemeProvider_workspace.graphql";
import { brandedAppWorkspaceKey } from "../utils/mobile";

export const CurrentWorkspaceContext =
  React.createContext<ThemeProvider_workspace$data>(null);

export function useCurrentWorkspace() {
  return React.useContext(CurrentWorkspaceContext);
}

export function useCurrentBrand() {
  const workspace = useCurrentWorkspace();

  // Indicates if the current environment is a branded mobile app.
  const isMobileBrandedApp = Boolean(brandedAppWorkspaceKey);

  // Indicates if the current workspace is branded.
  const isBrandedWorkspace = workspace?.brandedAppStatus !== "NONE";

  // Indicates if the branded app is published to the stores.
  const isBrandedAppPublished = workspace?.brandedAppStatus === "PUBLISHED";

  // Indicates if the current environment is branded, either as a mobile app or a workspace.
  const isBranded = isMobileBrandedApp || isBrandedWorkspace;

  // The name of the brand, using the workspace name if branded; otherwise or if workspace name is empty, defaults to "Stridist".
  const brandName =
    isBrandedWorkspace && workspace?.name ? workspace.name : "Stridist";

  // The name of the app in app stores. It can be either branded app name (if app is published) or Stridist.
  const appName = isBrandedAppPublished
    ? workspace?.brandedAppName
    : "Stridist";

  // App URL in Apple AppStore
  const AppleStoreUrl = isBrandedAppPublished
    ? workspace?.brandedAppAppleStoreUrl
    : process.env.REACT_APP_APP_STORE_URL;

  const brandedAppLogo = workspace?.brandedAppLogoUrl ?? workspace?.logoURL;

  // App URL in Google Play Market
  const GoogleStoreUrl = isBrandedAppPublished
    ? workspace?.brandedAppGooglePlayUrl
    : process.env.REACT_APP_GOOGLE_PLAY_URL;

  return {
    isBrandedApp: isMobileBrandedApp,
    isBrandedWorkspace,
    brandName,
    isBranded,
    appName,
    AppleStoreUrl,
    GoogleStoreUrl,
    isBrandedAppPublished,
    brandedAppLogo,
  };
}
