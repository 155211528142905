import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { LikesList } from "./LikesList";
import { Likeable_likeable$key } from "./__generated__/Likeable_likeable.graphql";
import { LikesButton } from "./LikesButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",

    "& > :last-child": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const fragment = graphql`
  fragment Likeable_likeable on Likeable {
    ...LikesList_likeable
    ...LikesButton_likeable
  }
`;

export interface LikeableProps extends BoxProps {
  likeableRef: Likeable_likeable$key;
}

export function Likeable(props: LikeableProps) {
  const { className, likeableRef, ...other } = props;
  const s = useStyles();
  const likeable = useFragment(fragment, likeableRef);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <LikesList likeableRef={likeable} />
      <LikesButton likeableRef={likeable} />
    </Box>
  );
}
