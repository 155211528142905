import clsx from "clsx";
import React from "react";
import { Paper, Typography, Link, Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMutation, graphql } from "react-relay/hooks";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../dialog/FullScreenDialog";
import { AppLogo } from "../app/AppLogo";
import newsletter from "../../icons/newsletter.svg";
import { colorSystem } from "../../theme";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { VerifyEmailDialogResendVerificationEmailMutation } from "./__generated__/VerifyEmailDialogResendVerificationEmailMutation.graphql";
import Newsletter from "../../icons/Newsletter";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",

    "& .MuiDialog-paper": {
      backgroundColor: colorSystem.white3,
    },

    "& .MuiContainer-root": {
      maxWidth: 674,
    },
  },

  dialog: {
    padding: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(5),
  },

  link: {
    cursor: "pointer",
  },
}));

const resendVerificationEmailMutation = graphql`
  mutation VerifyEmailDialogResendVerificationEmailMutation($email: String!) {
    resendVerificationEmail(email: $email)
  }
`;

export interface VerifyEmailDialogProps extends FullScreenDialogProps {
  email: string;
  onResendClick?: () => void;
}

export function VerifyEmailDialog(props: VerifyEmailDialogProps) {
  const { className, email, onResendClick, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  const snackAlert = useSnackAlert();
  const onError = useGenericErrorHandler();
  const [resend, resending] =
    useMutation<VerifyEmailDialogResendVerificationEmailMutation>(
      resendVerificationEmailMutation,
    );

  const handleResendClick = React.useCallback(() => {
    if (!resending) {
      resend({
        variables: { email },
        onCompleted(_, errors) {
          if (errors && errors[0]) {
            console.error(errors[0]);
          } else {
            snackAlert({
              severity: "success",
              message: "Verification email sent.",
            });
          }
        },
        onError,
      });
    }
  }, [resending, resend, email, onError, snackAlert]);

  return (
    <FullScreenDialog
      className={clsx(s.root, className)}
      fullScreen
      showBackButton={false}
      hideAppToolbar
      {...other}
    >
      <Paper className={s.dialog}>
        <Box display={"flex"} justifyContent={"center"}>
          <AppLogo />
        </Box>
        <Typography className={s.title} variant="h1">
          Please Check Your Inbox
        </Typography>
        <Box display={"flex"} justifyContent={"center"} width={155}>
          <Newsletter fill={theme.palette.primary.main}></Newsletter>
        </Box>
        <Typography className={s.text} variant="body1">
          We need to verify that email belongs to you, so please check your
          inbox for the confirmation link.
        </Typography>

        <Typography className={s.text} variant="body1">
          Can’t find the email? Check your spam folder,
          <br />
          or{" "}
          <Link className={s.link} onClick={onResendClick || handleResendClick}>
            resend the verification email
          </Link>
          .
        </Typography>
      </Paper>
    </FullScreenDialog>
  );
}
