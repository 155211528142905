/**
 * @generated SignedSource<<b739cdf250c994a512dac014eff22225>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupSidebarMemberItem_member$data = {
  readonly displayName: string | null | undefined;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
  readonly " $fragmentType": "GroupSidebarMemberItem_member";
};
export type GroupSidebarMemberItem_member$key = {
  readonly " $data"?: GroupSidebarMemberItem_member$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupSidebarMemberItem_member">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupSidebarMemberItem_member",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "08ae3702b56e5eb423cbf311754e6cc7";

export default node;
