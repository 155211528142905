/**
 * @generated SignedSource<<53f19dcaa9f548a8bf1374de184e32bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
export type UpdateClientMetricInput = {
  clientMutationId?: string | null | undefined;
  date?: string | null | undefined;
  id: string;
  measurement: number;
  unit: string;
};
export type ClientMeasurementRowsUpdateClientMetricMutation$variables = {
  input: UpdateClientMetricInput;
};
export type ClientMeasurementRowsUpdateClientMetricMutation$data = {
  readonly updateClientMetric: {
    readonly metric: {
      readonly activityDate: string | null | undefined;
      readonly value: {
        readonly measurement?: number;
        readonly unit?: MeasurementUnit;
      };
      readonly x: string | null | undefined;
    };
  } | null | undefined;
};
export type ClientMeasurementRowsUpdateClientMetricMutation = {
  response: ClientMeasurementRowsUpdateClientMetricMutation$data;
  variables: ClientMeasurementRowsUpdateClientMetricMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": "x",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "YYYY-MM-DD"
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(format:\"YYYY-MM-DD\")"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(raw:true)"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    }
  ],
  "type": "CheckinAnswerMeasurementValue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientMeasurementRowsUpdateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientMetricPayload",
        "kind": "LinkedField",
        "name": "updateClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientMeasurementRowsUpdateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateClientMetricPayload",
        "kind": "LinkedField",
        "name": "updateClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63e1706f134e934882a77df2b86aae7b",
    "id": null,
    "metadata": {},
    "name": "ClientMeasurementRowsUpdateClientMetricMutation",
    "operationKind": "mutation",
    "text": "mutation ClientMeasurementRowsUpdateClientMetricMutation(\n  $input: UpdateClientMetricInput!\n) {\n  updateClientMetric(input: $input) {\n    metric {\n      x: activityDate(format: \"YYYY-MM-DD\")\n      activityDate(raw: true)\n      value {\n        __typename\n        ... on CheckinAnswerMeasurementValue {\n          measurement\n          unit\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58162b91574b209eaed9c780455028a0";

export default node;
