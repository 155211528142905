/**
 * @generated SignedSource<<91cc99750f4010abb8598f0b4b497851>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkoutSectionElement_program$data = {
  readonly id: string;
  readonly " $fragmentType": "WorkoutSectionElement_program";
};
export type WorkoutSectionElement_program$key = {
  readonly " $data"?: WorkoutSectionElement_program$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkoutSectionElement_program">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkoutSectionElement_program",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Program",
  "abstractKey": null
};

(node as any).hash = "e5ef19d2e48654366d4627063ac97b9c";

export default node;
