import React, { useState, useRef, useEffect } from "react";
import { Button, Box, SxProps, Typography, darken } from "@mui/material";

interface SeeMoreNotes {
  children: string;
  animate?: boolean;
  rows?: number;
  sx?: SxProps;
}

const SeeMoreNotes = ({
  children,
  animate = true,
  rows = 3,
  sx = {},
}: SeeMoreNotes) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [currentHeight, setCurrentHeight] = useState<number | undefined>(
    undefined,
  );
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const computedStyle = window.getComputedStyle(contentElement);
      const lineHeightValue = parseFloat(computedStyle.lineHeight || "0");
      const maxHeight = lineHeightValue * rows;

      setIsOverflowing(contentElement.scrollHeight > maxHeight);
      setCurrentHeight(isExpanded ? contentElement.scrollHeight : maxHeight);
    }
  }, [children, rows, isExpanded]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box display="flex-inline">
      <Box
        ref={contentRef}
        sx={{
          ...sx,
          maxHeight: currentHeight,
          transition: animate ? "max-height 0.3s ease-in-out" : "none",
          overflow: "hidden",
          lineHeight: 1.3,
          wordBreak: "break-word",
        }}
      >
        {children}
      </Box>
      {isOverflowing && (
        <Typography
          variant="body2"
          onClick={toggleExpansion}
          sx={{
            lineHeight: 1.6,
            color: (theme) => theme.palette.primary.main,
            ":hover": {
              cursor: "pointer",
              color: (theme) => darken(theme.palette.primary.main, 0.1),
            },
          }}
        >
          {isExpanded ? "Show less" : "Read more"}
        </Typography>
      )}
    </Box>
  );
};

export default SeeMoreNotes;
