import clsx from "clsx";
import React from "react";
import { Box, BoxProps, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientGoalsEmpty } from "./ClientGoalsEmpty";
import { ClientGoalsDrawerHeader } from "./ClientGoalsDrawerHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

export interface ClientGoalsDrawerEmptyViewProps extends BoxProps {
  onClose: () => void;
  onCreateClick: ButtonProps["onClick"];
}

export function ClientGoalsDrawerEmptyView(
  props: ClientGoalsDrawerEmptyViewProps,
) {
  const { className, onClose, onCreateClick, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientGoalsDrawerHeader showTitle onClose={onClose} />
      <ClientGoalsEmpty onClose={onClose} onCreateClick={onCreateClick} />
    </Box>
  );
}
