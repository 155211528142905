/**
 * @generated SignedSource<<5cd831bb79231e5d20c7c4a69250d444>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyEmailDialogResendVerificationEmailMutation$variables = {
  email: string;
};
export type VerifyEmailDialogResendVerificationEmailMutation$data = {
  readonly resendVerificationEmail: boolean | null | undefined;
};
export type VerifyEmailDialogResendVerificationEmailMutation = {
  response: VerifyEmailDialogResendVerificationEmailMutation$data;
  variables: VerifyEmailDialogResendVerificationEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "resendVerificationEmail",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyEmailDialogResendVerificationEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyEmailDialogResendVerificationEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7794b1846ab6e363ae52083f86e857c",
    "id": null,
    "metadata": {},
    "name": "VerifyEmailDialogResendVerificationEmailMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyEmailDialogResendVerificationEmailMutation(\n  $email: String!\n) {\n  resendVerificationEmail(email: $email)\n}\n"
  }
};
})();

(node as any).hash = "6a2c7bdc638e61ef0f51b7a5e96350c8";

export default node;
