/**
 * @generated SignedSource<<e337d35ab66c10fb5085f5c5a74a60b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackForm_activity$data = {
  readonly client: {
    readonly displayName: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "FeedbackForm_activity";
};
export type FeedbackForm_activity$key = {
  readonly " $data"?: FeedbackForm_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackForm_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackForm_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "fcb99a443116a2e8e0cec2c063443b39";

export default node;
