import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useUpdateGroupArchivedMutation } from "../groups/mutations/UpdateGroupArchived";
import { useSnackAlert } from "../../hooks/useSnackAlert";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { COACH_GROUPS_ACTIVE_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: 524,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  content: {
    fontSize: 16,
    fontWeight: 500,
  },

  actions: {
    padding: theme.spacing(3),
  },

  button: {
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1.25),
    borderRadius: theme.spacing(0.5),
    borderStyle: "solid",
    borderWidth: 2,
  },

  cancel: {
    borderColor: theme.palette.secondary.main,
  },

  archive: {
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },

  close: {
    float: "right",
    padding: theme.spacing(1),
    marginRight: theme.spacing(-1),
  },
}));

export interface GroupArchiveDialogProps extends DialogProps {
  groupId: string;
}

export function GroupArchiveDialog(props: GroupArchiveDialogProps) {
  const { className, groupId, ...other } = props;
  const { onClose } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [archiveGroup, pending] = useUpdateGroupArchivedMutation();
  const snackAlert = useSnackAlert();
  const location = useLocation();

  const handleCancelClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const handleArchiveClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }

      archiveGroup({
        variables: {
          input: {
            id: groupId,
            archived: true,
          },
        },
        onSuccess: () => {
          snackAlert({
            severity: "success",
            message: `Group archived`,
          });
          navigate(COACH_GROUPS_ACTIVE_ROUTE);
        },
      });
    },
    [archiveGroup, groupId, onClose, snackAlert],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      {...other}
    >
      <DialogTitle>
        <IconButton
          className={s.close}
          onClick={handleCancelClick}
          size="large"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" className={s.title}>
          Are you sure you want to archive this group?
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography className={s.content}>
          Members will no longer have access to posts and group programs.
        </Typography>
      </DialogContent>

      <DialogActions className={s.actions}>
        <Button
          className={clsx(s.button, s.cancel)}
          variant="outlined"
          onClick={handleCancelClick}
          disabled={pending}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          className={clsx(s.button, s.archive)}
          variant="contained"
          onClick={handleArchiveClick}
          disabled={pending}
          fullWidth
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
}
