import React, { useRef, useEffect } from "react";
import { TextField, TextFieldProps } from "@mui/material";

type CheckInTextfieldProps = {
  inputRef?: React.RefObject<HTMLInputElement>;
} & TextFieldProps;

function CheckInTextfield({
  value,
  onChange,
  inputRef: initInputRef = { current: null },
  ...props
}: CheckInTextfieldProps) {
  const cursorPositionRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(initInputRef.current);
  // Save the cursor position before the value changes
  const handleInputChange = (event) => {
    const input = event.target;
    cursorPositionRef.current = input.selectionStart;
    onChange && onChange(event);
  };

  useEffect(() => {
    const input = inputRef.current;

    if (input && cursorPositionRef.current !== null) {
      input.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current,
      );
    }
  }, [value]);

  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      inputRef={inputRef}
      {...props}
    />
  );
}

export default CheckInTextfield;
