/**
 * @generated SignedSource<<410dc7bf7b3b9a72da519d27c1e09dd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramActivity_enrollment$data = {
  readonly checkinsByWeeks: ReadonlyArray<{
    readonly completion: {
      readonly completed: number;
    };
    readonly startDate: string;
  } | null | undefined>;
  readonly habitsByWeeks: ReadonlyArray<{
    readonly completion: {
      readonly completed: number;
    };
    readonly startDate: string;
  } | null | undefined>;
  readonly lessonsByWeeks: ReadonlyArray<{
    readonly completion: {
      readonly completed: number;
    };
    readonly startDate: string;
  } | null | undefined>;
  readonly " $fragmentType": "ClientProgramActivity_enrollment";
};
export type ClientProgramActivity_enrollment$key = {
  readonly " $data"?: ClientProgramActivity_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProgramActivity_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "endWeek",
  "value": 0
},
v1 = {
  "kind": "Literal",
  "name": "startWeek",
  "value": -5
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Completion",
    "kind": "LinkedField",
    "name": "completion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "completed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientProgramActivity_enrollment",
  "selections": [
    {
      "alias": "lessonsByWeeks",
      "args": [
        {
          "kind": "Literal",
          "name": "componentType",
          "value": "LESSON"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "concreteType": "CompletionByWeek",
      "kind": "LinkedField",
      "name": "completionByWeeks",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": "completionByWeeks(componentType:\"LESSON\",endWeek:0,startWeek:-5)"
    },
    {
      "alias": "habitsByWeeks",
      "args": [
        {
          "kind": "Literal",
          "name": "componentType",
          "value": "HABIT"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "concreteType": "CompletionByWeek",
      "kind": "LinkedField",
      "name": "completionByWeeks",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": "completionByWeeks(componentType:\"HABIT\",endWeek:0,startWeek:-5)"
    },
    {
      "alias": "checkinsByWeeks",
      "args": [
        {
          "kind": "Literal",
          "name": "componentType",
          "value": "CHECKIN"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "concreteType": "CompletionByWeek",
      "kind": "LinkedField",
      "name": "completionByWeeks",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": "completionByWeeks(componentType:\"CHECKIN\",endWeek:0,startWeek:-5)"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "e54a87bbb576b3ec49253a19a3f6c91a";

export default node;
