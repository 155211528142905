import { NavigationItem, UserRole } from "../../../constants";
import {
  CLIENT_GROUP_MEMBERS_ROUTE,
  CLIENT_GROUP_POST_ROUTE,
  COACH_GROUP_MEMBERS_ROUTE,
  COACH_GROUP_POST_ROUTE,
  COACH_GROUP_SETTINGS_ROUTE,
} from "../../routes";

export const groupNavigation = (
  slug: string,
  role: UserRole,
): NavigationItem[] =>
  role === UserRole.COACH
    ? [
        {
          name: "Posts",
          link: COACH_GROUP_POST_ROUTE.replace(":slug", slug),
        },
        {
          name: "Members",
          link: COACH_GROUP_MEMBERS_ROUTE.replace(":slug", slug),
        },
        {
          name: "Settings",
          link: COACH_GROUP_SETTINGS_ROUTE.replace(":slug", slug),
        },
      ]
    : [
        {
          name: "Posts",
          link: CLIENT_GROUP_POST_ROUTE.replace(":slug", slug),
        },
        {
          name: "Members",
          link: CLIENT_GROUP_MEMBERS_ROUTE.replace(":slug", slug),
        },
      ];
