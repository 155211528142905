import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import {
  MENU_ITEM_BULLETED_LIST,
  MENU_ITEM_CHECKLIST,
  MENU_ITEM_HEADING_1,
  MENU_ITEM_HEADING_2,
  MENU_ITEM_NUMBERED_LIST,
  MENU_ITEM_QUOTE_PULL,
  MENU_ITEM_QUOTE_BLOCK,
  MENU_ITEM_TEXT,
  IMenuItem,
  MENU_ITEM_CHECK_IN_SHORT_TEXT,
  MENU_ITEM_CHECK_IN_LONG_TEXT,
  MENU_ITEM_CHECK_IN_DATE,
  MENU_ITEM_CHECK_IN_NUMBER,
  MENU_ITEM_CHECK_IN_FILE,
  MENU_ITEM_CHECK_IN_YES_NO,
  MENU_ITEM_CHECK_MULTIPLE_CHOICE,
  MENU_ITEM_CHECK_IN_MULTIPLE_CHECKBOX,
  MENU_ITEM_CHECK_RATING_SCALE,
  MENU_ITEM_CHECK_STAR_RATING,
  MENU_ITEM_CHECK_EMOJI,
  MENU_ITEM_CHECK_MEASUREMENT,
  MENU_ITEM_CHECK_PROGRESS_PHOTO,
  MENU_ITEM_CHECK_NUTRITION_TARGET,
} from "./menuItemUtil";

export interface ITurnIntoMenuItemGroup {
  items: IMenuItem[];
}

export const TURN_INTO_TEXT_MENU_ITEM_DEFAULT = MENU_ITEM_TEXT;
export const TURN_INTO_TEXT_MENU_ITEM_GROUPS: ITurnIntoMenuItemGroup[] = [
  {
    items: [MENU_ITEM_TEXT, MENU_ITEM_HEADING_1, MENU_ITEM_HEADING_2],
  },
  {
    items: [MENU_ITEM_QUOTE_BLOCK, MENU_ITEM_QUOTE_PULL],
  },
  {
    items: [
      MENU_ITEM_CHECKLIST,
      MENU_ITEM_BULLETED_LIST,
      MENU_ITEM_NUMBERED_LIST,
    ],
  },
];

export const TURN_INTO_CHECK_IN_MENU_ITEM_DEFAULT =
  MENU_ITEM_CHECK_IN_SHORT_TEXT;
export const TURN_INTO_CHECK_IN_MENU_ITEM_GROUP: ITurnIntoMenuItemGroup[] = [
  {
    items: [
      MENU_ITEM_CHECK_IN_SHORT_TEXT,
      MENU_ITEM_CHECK_IN_LONG_TEXT,
      MENU_ITEM_CHECK_IN_DATE,
      MENU_ITEM_CHECK_IN_NUMBER,
      MENU_ITEM_CHECK_IN_FILE,
    ],
  },
  {
    items: [
      MENU_ITEM_CHECK_IN_YES_NO,
      MENU_ITEM_CHECK_MULTIPLE_CHOICE,
      MENU_ITEM_CHECK_IN_MULTIPLE_CHECKBOX,
    ],
  },
  {
    items: [
      MENU_ITEM_CHECK_RATING_SCALE,
      MENU_ITEM_CHECK_STAR_RATING,
      MENU_ITEM_CHECK_EMOJI,
    ],
  },
  {
    items: [
      MENU_ITEM_CHECK_MEASUREMENT,
      MENU_ITEM_CHECK_PROGRESS_PHOTO,
      MENU_ITEM_CHECK_NUTRITION_TARGET,
    ],
  },
];
