import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { DeleteClientGoalMutation } from "./__generated__/DeleteClientGoalMutation.graphql";

const mutation = graphql`
  mutation DeleteClientGoalMutation($input: DeleteClientGoalInput!) {
    deleteClientGoal(input: $input) {
      deletedClientGoalId
    }
  }
`;

export const useDeleteClientGoalMutation = () => {
  return useConfiguredMutation<DeleteClientGoalMutation>(
    mutation,
    (config) => ({
      configs: [
        {
          type: "RANGE_DELETE",
          parentID: config.variables.input.clientId,
          connectionKeys: [
            {
              key: "ClientDetails_goals",
            },
          ],
          pathToConnection: [config.variables.input.clientId, "goals"],
          deletedIDFieldName: "deletedClientGoalId",
        },
      ],
    }),
  );
};
