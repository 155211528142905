/**
 * @generated SignedSource<<38b2741653d8cce57dfc77eba82cb308>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgramEnrollments_groupEnrollments$data = {
  readonly totalCount: number;
  readonly " $fragmentSpreads": FragmentRefs<"GroupEnrollmentList_groupEnrollments">;
  readonly " $fragmentType": "ProgramEnrollments_groupEnrollments";
};
export type ProgramEnrollments_groupEnrollments$key = {
  readonly " $data"?: ProgramEnrollments_groupEnrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgramEnrollments_groupEnrollments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramEnrollments_groupEnrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupEnrollmentList_groupEnrollments"
    }
  ],
  "type": "GroupEnrollmentConnection",
  "abstractKey": null
};

(node as any).hash = "a9f6c10c6a36c852716797b1909aa8e9";

export default node;
