import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, lighten } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as BrushIcon } from "../../icons/streamline-icon-color-brush.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
    fontWeight: 500,
    backgroundColor: lighten(theme.palette.text.secondary, 0.8),
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.75, 2),
    borderRadius: theme.spacing(0.5),
  },
}));

export interface ToggleColorsButtonProps extends ButtonProps {
  toggleFlag: boolean;
  onToggle: (value: boolean) => void;
}

export function ToggleColorsButton(props: ToggleColorsButtonProps) {
  const { className, toggleFlag, onToggle, ...other } = props;
  const s = useStyles();

  const handleClick: ButtonProps["onClick"] = React.useCallback(
    (event) => {
      event.stopPropagation();
      onToggle(!toggleFlag);
    },
    [onToggle, toggleFlag],
  );

  return (
    <Button
      className={clsx(s.root, className)}
      startIcon={<BrushIcon />}
      children={`Colors ${toggleFlag ? "enabled" : "disabled"}`}
      onClick={handleClick}
      {...other}
    />
  );
}
