import React, { useState } from "react";
import { alpha, Box, CircularProgress, IconButton } from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { AddComponentMenu } from "../../program/AddComponentMenu";
import { generateNewWorkoutContent } from "../../workout/utils";
import {
  ComponentType,
  RowType,
  SOMETHING_WENT_WRONG,
} from "../../../constants";
import CopyWithArrow from "../../../icons/CopyWithArrow";
import { useTheme } from "@mui/styles";
import { SquarePlus } from "lucide-react";
import { ProgramWeekSchedule } from "../../../hooks/useProgramSchedule";
import { graphql } from "relay-runtime";
import { useMutation } from "react-relay";
import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { NoRowsOverlayUpsertComponentMutation } from "./__generated__/NoRowsOverlayUpsertComponentMutation.graphql";
import { useProgramRefetch } from "../../../hooks/useProgramRefetch";
import { mapValues } from "lodash";
import { Filters } from "../../program/ProgramDetailsFilters";

const upsertComponentMutation = graphql`
  mutation NoRowsOverlayUpsertComponentMutation($input: UpsertComponentInput!) {
    upsertComponent(input: $input) {
      component {
        slug
        id
      }
    }
  }
`;

const NoRowsOverlay = (props: any) => {
  const {
    dayOfWeek,
    weekId,
    onAddComponent,
    duplicateByDirection,
    gridLoader,
    setGridLoader,
    filters,
  } = props;

  const programRefetch = useProgramRefetch();

  const theme = useTheme();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "add-component-menu",
  });

  const snackAlert = useSnackAlert();

  const handleAddComponent = (componentType) => {
    menuState.close();
    const days = [...Array(7)].map((_, index) => index === dayOfWeek);

    const newWorkoutContent = JSON.stringify(generateNewWorkoutContent(1, 2));
    const newComponentContent =
      componentType === ComponentType.WORKOUT ? newWorkoutContent : undefined;

    setGridLoader(true);
    onAddComponent &&
      onAddComponent(
        weekId,
        componentType,
        days,
        undefined,
        newComponentContent,
      );
  };

  const copyIconEnabledStyle = {
    ":hover": {
      cursor: "pointer",
      background: alpha(theme.palette.common.black, 0.04),
    },
    stroke: theme.palette.common.black,
    pointerEvents: "all",
  };

  const copyIconBaseStyle = {
    p: 1,
    borderRadius: 2,
    stroke: theme.palette.divider,
    transition: theme.transitions.create(["background", "stroke"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    pointerEvents: "none",
  };

  const [upsertComponent] = useMutation<NoRowsOverlayUpsertComponentMutation>(
    upsertComponentMutation,
  );

  const handleDuplicateClick = (data: ProgramWeekSchedule) => {
    setGridLoader(true);
    data.forEach((item) => {
      const days = [...Array(7)].map((_, index) => index === dayOfWeek);

      const input = {
        weekId,
        days,
        type: item.component.type,
        title: item.component.title,
        iconName: item.component.iconName,
        content: item.component.content,
      };

      upsertComponent({
        variables: {
          input,
        },
        onCompleted: (_, errors) => {
          if (errors) {
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Component was duplicated",
            });
          }
          programRefetch();
        },
        onError: () => {
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
        },
      });
    });
  };

  return (
    <>
      <Box
        width={1}
        height={1}
        paddingBlock={4}
        paddingInline={3}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Box
          sx={{
            marginInline: "auto",
            ...copyIconBaseStyle,
            ...(!gridLoader &&
              duplicateByDirection.up && {
                ...copyIconEnabledStyle,
              }),
          }}
          onClick={() => handleDuplicateClick(duplicateByDirection.up)}
        >
          <CopyWithArrow direction="down" stroke={"inherit"} />
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={1}
          marginBlock={"auto"}
        >
          <Box
            sx={{
              marginRight: "auto",
              ...copyIconBaseStyle,
              ...(!gridLoader &&
                duplicateByDirection.left && {
                  ...copyIconEnabledStyle,
                }),
            }}
            onClick={() => handleDuplicateClick(duplicateByDirection.left)}
          >
            <CopyWithArrow direction="right" stroke={"inherit"} />
          </Box>

          <CircularProgress
            size={20}
            sx={{
              color: theme.palette.divider,
              position: "absolute",
              opacity: gridLoader ? 1 : 0,
              visibility: gridLoader ? 1 : 0,
              transition: theme.transitions.create(["opacity"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.complex,
              }),
            }}
          />
          <IconButton
            {...bindTrigger(menuState)}
            sx={{
              opacity: gridLoader ? 0 : 1,
              visibility: gridLoader ? 0 : 1,
              ...copyIconEnabledStyle,
              transition: theme.transitions.create(
                ["background", "stroke", "opacity"],
                {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                },
              ),
            }}
          >
            <SquarePlus
              color={theme.palette.common.black}
              size={32}
              strokeWidth={1}
            />
          </IconButton>

          <Box
            sx={{
              marginLeft: "auto",
              ...copyIconBaseStyle,
              ...(!gridLoader &&
                duplicateByDirection.right && {
                  ...copyIconEnabledStyle,
                }),
            }}
            onClick={() => handleDuplicateClick(duplicateByDirection.right)}
          >
            <CopyWithArrow direction="left" stroke={"inherit"} />
          </Box>
        </Box>

        <Box
          sx={{
            marginInline: "auto",
            ...copyIconBaseStyle,
            ...(!gridLoader &&
              duplicateByDirection.down && {
                ...copyIconEnabledStyle,
              }),
          }}
          onClick={() => handleDuplicateClick(duplicateByDirection.down)}
        >
          <CopyWithArrow direction="up" stroke={"inherit"} />
        </Box>
      </Box>

      {menuState.isOpen && (
        <AddComponentMenu
          disabledItems={{
            ...(mapValues(filters, (value) => !value) as Filters),
            [RowType.WORKOUT_SECTION]: true,
            [RowType.EXERCISE]: true,
          }}
          enableDetailedWorkout
          onAddComponent={handleAddComponent}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          {...bindMenu(menuState)}
        />
      )}
    </>
  );
};

export default NoRowsOverlay;
