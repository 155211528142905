/**
 * @generated SignedSource<<d4aff1bc4f3bdcaa6115e52882fdac1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DuplicateClientFormInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type DuplicateClientFormMutation$variables = {
  input: DuplicateClientFormInput;
};
export type DuplicateClientFormMutation$data = {
  readonly duplicateClientForm: {
    readonly clientFormEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"CoachClientsFormsListItem_clientForm">;
      } | null | undefined;
    };
  } | null | undefined;
};
export type DuplicateClientFormMutation = {
  response: DuplicateClientFormMutation$data;
  variables: DuplicateClientFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DuplicateClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateClientFormPayload",
        "kind": "LinkedField",
        "name": "duplicateClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientFormEdge",
            "kind": "LinkedField",
            "name": "clientFormEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientForm",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CoachClientsFormsListItem_clientForm"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DuplicateClientFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateClientFormPayload",
        "kind": "LinkedField",
        "name": "duplicateClientForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientFormEdge",
            "kind": "LinkedField",
            "name": "clientFormEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientForm",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalQuestions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM D, YYYY"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"MMM D, YYYY\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e77022571c52d793ee6a14a782925b95",
    "id": null,
    "metadata": {},
    "name": "DuplicateClientFormMutation",
    "operationKind": "mutation",
    "text": "mutation DuplicateClientFormMutation(\n  $input: DuplicateClientFormInput!\n) {\n  duplicateClientForm(input: $input) {\n    clientFormEdge {\n      node {\n        ...CoachClientsFormsListItem_clientForm\n        id\n      }\n    }\n  }\n}\n\nfragment CoachClientsFormsListItem_clientForm on ClientForm {\n  id\n  name\n  slug\n  totalQuestions\n  createdAt(format: \"MMM D, YYYY\")\n}\n"
  }
};
})();

(node as any).hash = "6a61bb81fc500d54cdec994b32af5fb5";

export default node;
