import clsx from "clsx";
import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { clipTrailingNodes } from "../editor/normalizers/withSchema";
import { BaseEditorDelayBuffer } from "../editor/components/utils/BaseEditorDelayBuffer";
import { editorPlugins } from "../new-editor/editor-configuration/plate-plugins/EditorPlugins";
import { GroupDescriptionEditorSchema } from "../new-editor/editor-configuration/schemas/GroupDescriptionEditorSchema";
import { SchemaElements } from "../new-editor/utils/withSchema";
import { isEmptyContent } from "../editor/utils/common";
import { BaseEditor } from "../new-editor/BaseEditor";

const useStyles = makeStyles((theme) => ({
  root: {},

  empty: {
    '& [data-slate-node="element"] *': {
      fontWeight: "500 !important",
      color: `${theme.palette.text.secondary} !important`,
    },
  },
}));

const initialValue = [SchemaElements.getEmptyNode()];

export function GroupDescriptionEditor(props: any) {
  const { delay, className, value = initialValue, ...other } = props;
  const s = useStyles();
  const readOnly = props.readOnly;
  const Editor = readOnly || !delay ? BaseEditor : BaseEditorDelayBuffer;
  const isEmpty = isEmptyContent(value);

  return (
    <Box className={clsx(s.root, className, isEmpty && s.empty)}>
      <Editor
        placeholder={
          readOnly
            ? "Add a description"
            : "Describe who your group is for, and what you hope to accomplish."
        }
        value={readOnly ? clipTrailingNodes(value) : value}
        inlineToolbar
        sidebar={false}
        deserialize
        preNormalize
        multiline
        autoFocus
        topToolbar
        editorSchema={GroupDescriptionEditorSchema}
        platePlugins={editorPlugins}
        {...other}
      />
    </Box>
  );
}

export default GroupDescriptionEditor;
