import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { Avatar, AvatarProps } from "../../components/avatar/Avatar";

import { EnrolledAvatar_enrollment$key } from "./__generated__/EnrolledAvatar_enrollment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const enrollmentFragment = graphql`
  fragment EnrolledAvatar_enrollment on Enrollment {
    client {
      ...Avatar_user
    }
  }
`;

export interface EnrolledAvatarProps extends Omit<AvatarProps, "userRef"> {
  enrollmentRef: EnrolledAvatar_enrollment$key;
}

export function EnrolledAvatar(props: EnrolledAvatarProps) {
  const { className, enrollmentRef, ...other } = props;
  const s = useStyles();
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);
  const { client } = enrollment;

  return (
    <Avatar className={clsx(className, s.root)} userRef={client} {...other} />
  );
}
