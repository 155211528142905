import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      zIndex: 0,
      pointerEvents: "none",
      left: theme.spacing(-4),
      right: theme.spacing(-4),
      bottom: theme.spacing(-3),
      top: theme.spacing(-3),

      borderRadius: theme.spacing(1),
      borderStyle: "solid",
      borderColor: "transparent",
      borderWidth: 2,

      transition: "border-color 0.3s ease, background-color 0.3s ease",

      [theme.breakpoints.down("md")]: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  },
  rootEnabled: {
    "&::before": {
      [theme.breakpoints.up("md")]: {
        borderColor: theme.palette.quote,
        backgroundColor: theme.palette.selected.light,
      },
    },
  },
}));

export const WORKOUT_SECTION_HIGHLIGHT_CONTAINER_CLASS =
  "workout-section-highlight-container";

export interface HighlightContainerProps {
  children: React.ReactNode;
  enabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export function HighlightContainer(props: HighlightContainerProps) {
  const { children, enabled = false, onClick } = props;
  const s = useStyles();

  return (
    <div
      className={clsx(
        WORKOUT_SECTION_HIGHLIGHT_CONTAINER_CLASS,
        s.root,
        enabled && s.rootEnabled,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
