import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams, useSearchParams } from "react-router-dom";
import { CoachClientProgram } from "../../../../components/program/CoachClientProgram";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { CoachClientProgramRouteQuery } from "./__generated__/CoachClientProgramRouteQuery.graphql";
import { ClientContext } from "../../../../contexts/ClientContext";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { toEnum } from "../../../../utils/misc";
import { useQueryParam } from "../../../../hooks/useQueryParam";
import { CompletionPeriod } from "../../../../constants";

export function CoachClientProgramRoute() {
  const { username, program } = useParams();
  const [searchParams] = useSearchParams();
  const [period] = useQueryParam("period", CompletionPeriod.BEFORE_NOW, {
    normalize: (param) =>
      toEnum(param, CompletionPeriod, CompletionPeriod.BEFORE_NOW),
  });

  const week = searchParams.get("week");

  const props = useLazyLoadQuery<CoachClientProgramRouteQuery>(
    graphql`
      query CoachClientProgramRouteQuery(
        $username: String!
        $program: String!
        $week: Int!
        $period: CompletionPeriodType!
      ) {
        client: user(username: $username) {
          username
          displayName
        }

        enrollments(client: $username) {
          ...CoachClientProgram_enrollments
        }

        enrollment(client: $username, program: $program) {
          ...CoachClientProgram_enrollment
            @arguments(week: $week, period: $period)
          program {
            ...RefreshSlug
            name
            description
            imageURL
            id
            slug
            length
            weeks(first: 9999, after: "", week: $week) {
              edges {
                node {
                  id
                  week
                  description
                }
              }
            }
          }
        }
      }
    `,
    {
      period: period,
      program: program,
      username: username,
      week: Number(week) ?? 1,
    },
  );

  const { client, enrollment, enrollments } = props;

  const context = useContext(ClientContext);

  context.setCoachClientQuery(props);

  return (
    enrollment && (
      <>
        <RefreshSlug nodesRef={[enrollment?.program]} />
        <CoachClientProgram
          userName={client?.username}
          enrollmentRef={enrollment}
          enrollmentsRef={enrollments}
          program={enrollment?.program}
        />
        <TrackInfoTool
          trackInfo={{
            name: "Coach - Client Program",
            properties: {
              clientUsername: client?.username,
            },
          }}
        />
      </>
    )
  );
}
