import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

import { useRemoveGroupMemberMutation } from "./mutations/RemoveGroupMember";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupMemberMenuProps extends MenuProps {
  groupId: string;
  memberId: string;
}

export function GroupMemberMenu(props: GroupMemberMenuProps) {
  const { className, groupId, memberId, ...other } = props;
  const s = useStyles();
  const [removeGroupMember] = useRemoveGroupMemberMutation();

  const handleDelete = React.useCallback(() => {
    removeGroupMember({
      variables: {
        input: {
          groupId,
          memberId,
        },
      },
    });
  }, [groupId, memberId, removeGroupMember]);

  return (
    <Menu className={clsx(s.root, className)} {...other}>
      <MenuItem onClick={handleDelete}>
        <ListItemIcon>
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText>Remove from group</ListItemText>
      </MenuItem>
    </Menu>
  );
}
