import React from "react";
import { ExampleValueBadge } from "../../info-section/ExampleValueBadge";
import { InfoBox } from "../../info-section/InfoBox";
import { InfoSection } from "../../info-section/InfoSection";

export const RepsSetCustomizationInfoSection = () => (
  <InfoSection
    title="Customize Sets"
    description={
      <>
        Number of Reps values depends on value in <b>Sets</b> column.
      </>
    }
  >
    <InfoBox
      title="Comma"
      description="Define
  different reps per each set using commas."
    >
      <ExampleValueBadge value="2, 3" />
      <ExampleValueBadge value="2-3, 5, 6" />
    </InfoBox>

    <InfoBox
      title="Auto"
      description={
        <>
          Leave repetitive values blank. <br /> This will automatically apply
          value to all next sets.
        </>
      }
    >
      <ExampleValueBadge value="2" secondaryValue=", 2, 2" />
      <ExampleValueBadge value="2, 3-4" secondaryValue=", 2, 3-4, 2" />
    </InfoBox>
  </InfoSection>
);
