import { ReactComponent as Calendar } from "../../icons/Calendar.svg";
import { ReactComponent as CalendarSchool } from "../../icons/CalendarSchool.svg";
import { ReactComponent as AppWindowCheck } from "../../icons/AppWindowCheck.svg";
import { ReactComponent as StudyLightIdea } from "../../icons/StudyLightIdea.svg";
import { ReactComponent as AppWindowLink } from "../../icons/AppWindowLink.svg";
import { ReactComponent as SocialVideoYoutube } from "../../icons/SocialVideoYoutube.svg";
import { ReactComponent as PencilWrite } from "../../icons/PencilWrite.svg";
import { ReactComponent as FitnessDumbbell } from "../../icons/FitnessDumbbell.svg";
import { ReactComponent as YogaLegGrabStretch } from "../../icons/YogaLegGrabStretch.svg";
import { ReactComponent as YogaLegShoulderStretch } from "../../icons/YogaLegShoulderStretch.svg";
import { ReactComponent as FitnessGripWeights } from "../../icons/FitnessGripWeights.svg";
import { ReactComponent as FitnessHeartRate } from "../../icons/FitnessHeartRate.svg";
import { ReactComponent as SeafoodFish } from "../../icons/SeafoodFish.svg";
import { ReactComponent as VegetablesBroccoli } from "../../icons/VegetablesBroccoli.svg";
import { ReactComponent as WaterGlass } from "../../icons/WaterGlass.svg";
import { ReactComponent as FruitBanana } from "../../icons/FruitBanana.svg";
import { ReactComponent as RestaurantForkKnife } from "../../icons/RestaurantForkKnife.svg";
import { ReactComponent as VegetablesPlate } from "../../icons/VegetablesPlate.svg";
import { ReactComponent as QuestionCircle } from "../../icons/QuestionCircle.svg";
import { ReactComponent as IconCamera } from "../../icons/IconCamera.svg";
import { ReactComponent as PartyConfetti } from "../../icons/PartyConfetti.svg";
import { ReactComponent as TaskListClock } from "../../icons/TaskListClock.svg";
import { ReactComponent as SpaStone } from "../../icons/SpaStone.svg";
import { ReactComponent as SpaLotus } from "../../icons/SpaLotus.svg";
import { ReactComponent as DietScale } from "../../icons/DietScale.svg";
import { ReactComponent as ClothesDesignTapeMeasure } from "../../icons/ClothesDesignTapeMeasure.svg";
import { ReactComponent as CheckMarkDoneCircle } from "../../icons/CheckMarkDoneCircle.svg";
import { ReactComponent as LESSON } from "../../icons/Lesson.svg";
import { ReactComponent as Envelope } from "../../icons/streamline-icon-envelope.svg";
import { ComponentType } from "../../constants";

export const ComponentIcons = {
  LESSON,
  Calendar,
  CalendarSchool,
  CHECKIN: CheckMarkDoneCircle,
  HABIT: AppWindowCheck,
  StudyLightIdea,
  AppWindowLink,
  SocialVideoYoutube,
  PencilWrite,
  FitnessDumbbell,
  YogaLegGrabStretch,
  YogaLegShoulderStretch,
  FitnessGripWeights,
  FitnessHeartRate,
  SeafoodFish,
  VegetablesBroccoli,
  WaterGlass,
  FruitBanana,
  RestaurantForkKnife,
  VegetablesPlate,
  QuestionCircle,
  IconCamera,
  PartyConfetti,
  TaskListClock,
  SpaStone,
  SpaLotus,
  DietScale,
  ClothesDesignTapeMeasure,
  WORKOUT: FitnessDumbbell,
  MESSAGE: Envelope,
};

// Roundy icons, should be up-scaled and require different margins to fit the grid
export const SpecialIconNames = ["CHECKIN"];

export const ComponentTypeDefaultIcons = {
  [ComponentType.LESSON]: ComponentIcons.LESSON,
  [ComponentType.HABIT]: ComponentIcons.HABIT,
  [ComponentType.CHECKIN]: ComponentIcons.CHECKIN,
  [ComponentType.WORKOUT]: ComponentIcons.WORKOUT,
  [ComponentType.MESSAGE]: ComponentIcons.MESSAGE,
};
