import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../button/MoreMenuButton";
import { LibraryExerciseMenu } from "../menu/LibraryExerciseMenu";
import { ExerciseAsset } from "../exercise-library/types";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { WorkoutExercise } from "../workout/types";
import { ExerciseLibraryExercise_asset$key } from "./__generated__/ExerciseLibraryExercise_asset.graphql";
import { DraggableSnippet } from "../editor/components/utils/DraggableSnippet";
import { WORKOUT_SECTION } from "../new-editor/components/workout/WorkoutSectionElement";
import { ComponentLibraryAssetSource } from "../component-library/ComponentLibraryAsset";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: theme.spacing(8),
    paddingRight: theme.spacing(4),
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.selected.light,
    },
  },

  name: {
    marginLeft: theme.spacing(2),
    fontWeight: 500,
  },

  moreButton: {
    position: "absolute",
    right: theme.spacing(4),
    opacity: 0,

    "$root:hover &": {
      opacity: 1,
    },
  },
}));

const fragment = graphql`
  fragment ExerciseLibraryExercise_asset on CustomAsset {
    id
    name
    content
    userId
  }
`;

export interface ExerciseLibraryExerciseProps
  extends Omit<BoxProps, "onClick"> {
  assetRef: ExerciseLibraryExercise_asset$key;
  onClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ExerciseAsset,
  ) => void;
}

export function ExerciseLibraryExercise(props: ExerciseLibraryExerciseProps) {
  const { className, assetRef, onClick, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const {
    id: assetId,
    name,
    content,
    userId,
  } = useFragment(fragment, assetRef);
  const menuState = usePopupState({
    popupId: "library-exercise-menu",
    variant: "popper",
  });
  const canEdit = user.id === userId;

  const handleClick = React.useCallback(
    (event) => {
      const exercise = JSON.parse(content) as WorkoutExercise;
      const value: ExerciseAsset = {
        assetId,
        exercise,
      };

      if (onClick) {
        onClick(event, value);
      }
    },
    [assetId, content, onClick],
  );

  const handleMenuClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      menuState.open(event);
    },
    [menuState],
  );

  return (
    <Box className={clsx(s.root, className)}>
      <DraggableSnippet
        content={{
          element: {
            type: WORKOUT_SECTION,
            children: [{ text: "" }],
            workout: {
              exercises: [JSON.parse(content) as WorkoutExercise],
              title: "",
            },
          },
          source: ComponentLibraryAssetSource,
        }}
        draggableContent
      >
        <Box
          className={clsx(s.root, className)}
          onClick={handleClick}
          {...other}
        >
          <Typography className={s.name}>{name}</Typography>

          {canEdit && (
            <>
              <MoreMenuButton
                {...bindTrigger(menuState)}
                className={s.moreButton}
                onClick={handleMenuClick}
              />
              {menuState.isOpen && (
                <LibraryExerciseMenu
                  {...bindMenu(menuState)}
                  assetId={assetId}
                />
              )}
            </>
          )}
        </Box>
      </DraggableSnippet>
    </Box>
  );
}
