/**
 * @generated SignedSource<<2af3697a3da28dcee93d4adb833cf6ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteAddedClientInput = {
  clientMutationId?: string | null | undefined;
  id: ReadonlyArray<string | null | undefined>;
  message: string;
};
export type CoachClientsListScreenInviteAllAddedClientMutation$variables = {
  input: InviteAddedClientInput;
};
export type CoachClientsListScreenInviteAllAddedClientMutation$data = {
  readonly inviteAddedClient: {
    readonly clientMutationId: string | null | undefined;
    readonly invites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ClientPendingCard_invite">;
    }>;
  } | null | undefined;
};
export type CoachClientsListScreenInviteAllAddedClientMutation = {
  response: CoachClientsListScreenInviteAllAddedClientMutation$data;
  variables: CoachClientsListScreenInviteAllAddedClientMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachClientsListScreenInviteAllAddedClientMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteAddedClientPayload",
        "kind": "LinkedField",
        "name": "inviteAddedClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invite",
            "kind": "LinkedField",
            "name": "invites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientPendingCard_invite"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachClientsListScreenInviteAllAddedClientMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteAddedClientPayload",
        "kind": "LinkedField",
        "name": "inviteAddedClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Invite",
            "kind": "LinkedField",
            "name": "invites",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": "invitedAt",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "fromNow"
                  }
                ],
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": "updatedAt(format:\"fromNow\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientStatus",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14e335a8b2cf2fdec3f2353658029be8",
    "id": null,
    "metadata": {},
    "name": "CoachClientsListScreenInviteAllAddedClientMutation",
    "operationKind": "mutation",
    "text": "mutation CoachClientsListScreenInviteAllAddedClientMutation(\n  $input: InviteAddedClientInput!\n) {\n  inviteAddedClient(input: $input) {\n    invites {\n      ...ClientPendingCard_invite\n      id\n    }\n    clientMutationId\n  }\n}\n\nfragment ClientPendingCard_invite on Invite {\n  id\n  email\n  invitedAt: updatedAt(format: \"fromNow\")\n  client {\n    clientStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb2bfb0c85a48d6d19c40bcf5927eb90";

export default node;
