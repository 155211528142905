import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { Units, LengthMeasurementUnit } from "../../constants";
import {
  UnitLength,
  convertFormatLength,
  getLengthDiff,
} from "../../utils/units";

import { ReactComponent as CheckDownIcon } from "../../icons/CheckDownGreen.svg";
import { ReactComponent as CheckUpIcon } from "../../icons/CheckYellowUp.svg";

import {
  ClientMeasurementItem_metrics$key,
  ClientMeasurementItem_metrics$data,
} from "./__generated__/ClientMeasurementItem_metrics.graphql";
import Chart from "../../icons/Chart";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "left",
  },

  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    width: "35%",
  },

  values: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },

  value: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
  },

  diff: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",

    "&$gain": {
      color: theme.palette.progress.yellow,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },

  indicator: {
    fontSize: 12,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },

  icon: {
    cursor: "pointer",
  },

  gain: {},
  drop: {},
}));

const metricsFragment = graphql`
  fragment ClientMeasurementItem_metrics on ClientMetric @relay(plural: true) {
    value {
      ... on CheckinAnswerMeasurementValue {
        unit
        measurement
      }
    }
  }
`;

function toLengthMeasurement(
  value: ClientMeasurementItem_metrics$data[0]["value"],
): UnitLength {
  return {
    measurement: value.measurement,
    unit: value.unit.toLowerCase() as LengthMeasurementUnit,
  };
}

export interface ClientMeasurementItemProps extends BoxProps {
  metricsRef: ClientMeasurementItem_metrics$key;
  label: string;
  units?: Units;
  onClick?: () => void;
}

export function ClientMeasurementItem(props: ClientMeasurementItemProps) {
  const {
    className,
    metricsRef,
    label,
    units = Units.US,
    onClick,
    ...other
  } = props;
  const s = useStyles();
  const metrics = useFragment(metricsFragment, metricsRef);

  const values = React.useMemo(
    () => metrics.map(({ value }) => value),
    [metrics],
  );

  const formattedValue = values.length
    ? values[0].unit === "PERCENTAGE"
      ? values[0].measurement
      : convertFormatLength(
          values[0].measurement,
          values[0].unit.toLowerCase() as LengthMeasurementUnit,
          units,
          false,
          true,
        )
    : null;

  const diff =
    values.length > 1
      ? values[0].unit === "PERCENTAGE"
        ? values[0].measurement - values[1].measurement
        : getLengthDiff(
            toLengthMeasurement(values[0]),
            toLengthMeasurement(values[1]),
            units as Units,
          )
      : null;

  const Arrow = React.useMemo(
    () => (diff === null ? null : diff > 0 ? CheckUpIcon : CheckDownIcon),
    [diff],
  );
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.label} variant="body1" children={label} />
      <Box className={s.values}>
        <Typography className={s.value} variant="body1">
          {formattedValue || "N/A"}
        </Typography>
        {diff !== null && (
          <Typography
            className={clsx(s.diff, diff > 0 ? s.gain : s.drop)}
            component="span"
          >
            <Arrow className={s.indicator} />
            {Math.abs(diff).toFixed(1)}
          </Typography>
        )}
      </Box>
      <Chart fill={theme.palette.primary.main} onClick={onClick} />
    </Box>
  );
}
