/**
 * @generated SignedSource<<f2b874e8fb167f7162f97b0fd83b015e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientProfileMeasurementScreen_user$data = {
  readonly id: string;
  readonly metrics: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementChart_metrics" | "ClientMeasurementRows_metrics">;
  }>;
  readonly nutritionTarget: ReadonlyArray<{
    readonly trackingType: NutritionTrackingType;
  } | null | undefined> | null | undefined;
  readonly units: Units;
  readonly " $fragmentType": "ClientProfileMeasurementScreen_user";
};
export type ClientProfileMeasurementScreen_user$key = {
  readonly " $data"?: ClientProfileMeasurementScreen_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProfileMeasurementScreen_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "measurementType"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ClientProfileMeasurementScreenRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ClientProfileMeasurementScreen_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackingType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "measurementType",
          "variableName": "measurementType"
        },
        {
          "kind": "Literal",
          "name": "metricType",
          "value": "CHECKIN_ANSWER_MEASUREMENT"
        }
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientMeasurementChart_metrics"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientMeasurementRows_metrics"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0f1b9073feaa0ff930429031ac144af2";

export default node;
