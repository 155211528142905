/**
 * @generated SignedSource<<2705afb9ce7afdd7171e90418838763a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WeekComponentSchedule_component$data = {
  readonly duration: number;
  readonly messageTime: string | null | undefined;
  readonly reminderTime: string | null | undefined;
  readonly reminderType: ReminderType | null | undefined;
  readonly " $fragmentType": "WeekComponentSchedule_component";
};
export type WeekComponentSchedule_component$key = {
  readonly " $data"?: WeekComponentSchedule_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekComponentSchedule_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekComponentSchedule_component",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reminderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reminderTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageTime",
      "storageKey": null
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "94f69a6c117c8f5a763c68ae8dc798c0";

export default node;
