import {
  MENU_ITEM_ACCORDION,
  MENU_ITEM_AUDIO,
  MENU_ITEM_BULLETED_LIST,
  MENU_ITEM_CALLOUT,
  MENU_ITEM_CHECKLIST,
  MENU_ITEM_CHECK_EMOJI,
  MENU_ITEM_CHECK_IN_DATE,
  MENU_ITEM_CHECK_IN_FILE,
  MENU_ITEM_CHECK_IN_LONG_TEXT,
  MENU_ITEM_CHECK_IN_MULTIPLE_CHECKBOX,
  MENU_ITEM_CHECK_IN_NUMBER,
  MENU_ITEM_CHECK_IN_SHORT_TEXT,
  MENU_ITEM_CHECK_IN_YES_NO,
  MENU_ITEM_CHECK_MEASUREMENT,
  MENU_ITEM_CHECK_MULTIPLE_CHOICE,
  MENU_ITEM_CHECK_NUTRITION_TARGET,
  MENU_ITEM_CHECK_PROGRESS_PHOTO,
  MENU_ITEM_CHECK_RATING_SCALE,
  MENU_ITEM_CHECK_STAR_RATING,
  MENU_ITEM_DIVIDER,
  MENU_ITEM_EMBED,
  MENU_ITEM_EXERCISE,
  MENU_ITEM_FILE,
  MENU_ITEM_HEADING_1,
  MENU_ITEM_HEADING_2,
  MENU_ITEM_IMAGE,
  MENU_ITEM_LINK,
  MENU_ITEM_NUMBERED_LIST,
  MENU_ITEM_QUOTE_PULL,
  MENU_ITEM_QUOTE_BLOCK,
  MENU_ITEM_TABLE,
  MENU_ITEM_TABS,
  MENU_ITEM_TEXT,
  MENU_ITEM_VIDEO,
  MENU_ITEM_WEB_BOOKMARK,
  IMenuItem,
} from "./menuItemUtil";

export interface IMenuSection {
  label: string;
  items: IMenuItem[];
}

export const TEXT_SECTION: IMenuSection = {
  label: "Text",
  items: [
    MENU_ITEM_TEXT,
    MENU_ITEM_HEADING_1,
    MENU_ITEM_HEADING_2,
    MENU_ITEM_LINK,
  ],
};

export const LISTS_SECTION: IMenuSection = {
  label: "List",
  items: [
    MENU_ITEM_CHECKLIST,
    MENU_ITEM_BULLETED_LIST,
    MENU_ITEM_NUMBERED_LIST,
  ],
};

export const QUOTES_SECTION: IMenuSection = {
  label: "Quote",
  items: [MENU_ITEM_QUOTE_BLOCK, MENU_ITEM_QUOTE_PULL],
};

export const SWITCH_SECTION: IMenuSection = {
  label: "Switch",
  items: [MENU_ITEM_TABS, MENU_ITEM_ACCORDION],
};

export const LAYOUT_SECTION: IMenuSection = {
  label: "Layout",
  items: [MENU_ITEM_TABLE, MENU_ITEM_CALLOUT, MENU_ITEM_DIVIDER],
};

export const MEDIA_SECTION: IMenuSection = {
  label: "Media",
  items: [MENU_ITEM_IMAGE, MENU_ITEM_VIDEO, MENU_ITEM_AUDIO, MENU_ITEM_FILE],
};

export const URL_SECTION: IMenuSection = {
  label: "Url",
  items: [MENU_ITEM_EMBED, MENU_ITEM_WEB_BOOKMARK],
};

export const TRAINING_SECTION: IMenuSection = {
  label: "Training",
  items: [MENU_ITEM_EXERCISE],
};

export const CHECK_IN_QUESTIONS_SECTION: IMenuSection = {
  label: "Check-in question",
  items: [
    MENU_ITEM_CHECK_IN_SHORT_TEXT,
    MENU_ITEM_CHECK_IN_LONG_TEXT,
    MENU_ITEM_CHECK_IN_DATE,
    MENU_ITEM_CHECK_IN_NUMBER,
    MENU_ITEM_CHECK_IN_FILE,
  ],
};

export const CHECK_IN_VOTE_SECTION: IMenuSection = {
  label: "Check-in vote",
  items: [
    MENU_ITEM_CHECK_IN_YES_NO,
    MENU_ITEM_CHECK_MULTIPLE_CHOICE,
    MENU_ITEM_CHECK_IN_MULTIPLE_CHECKBOX,
  ],
};

export const CHECK_IN_RATING_SECTION: IMenuSection = {
  label: "Check-in rating",
  items: [
    MENU_ITEM_CHECK_RATING_SCALE,
    MENU_ITEM_CHECK_STAR_RATING,
    MENU_ITEM_CHECK_EMOJI,
  ],
};

export const CHECK_IN_FEEDBACK_SECTION: IMenuSection = {
  label: "Check-in feedback",
  items: [
    MENU_ITEM_CHECK_MEASUREMENT,
    MENU_ITEM_CHECK_PROGRESS_PHOTO,
    MENU_ITEM_CHECK_NUTRITION_TARGET,
  ],
};
