import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { RenderElementProps } from "slate-react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "static",
  },
}));

export interface TableHeadRowElementProps extends RenderElementProps {}

export const ELEMENT_TABLE_HEAD_ROW = "table_head_row";

export function TableHeadRowElement(props: any) {
  const s = useStyles();
  const { element, children, attributes } = props;

  return (
    <tr
      className={s.root}
      data-table-row-index={element.rowIndex}
      {...attributes}
    >
      {children}
    </tr>
  );
}
