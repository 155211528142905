/**
 * @generated SignedSource<<1b59fe519b76d1b63dc5624b23e46da8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsForm_settings$data = {
  readonly birthday: string;
  readonly email: string | null | undefined;
  readonly gender: string;
  readonly height: string | null | undefined;
  readonly location: string;
  readonly photoURL: string | null | undefined;
  readonly rawDisplayName: string | null | undefined;
  readonly timeZone: string | null | undefined;
  readonly units: Units;
  readonly username: string;
  readonly weight: string | null | undefined;
  readonly " $fragmentType": "ClientSettingsForm_settings";
};
export type ClientSettingsForm_settings$key = {
  readonly " $data"?: ClientSettingsForm_settings$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsForm_settings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSettingsForm_settings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": "rawDisplayName",
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": "displayName(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeZone",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "40022c371ffba691d05f329d667b955c";

export default node;
