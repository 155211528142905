import clsx from "clsx";
import React, { useCallback } from "react";
import { Snackbar, SnackbarProps } from "@mui/material";
import { Alert } from "@mui/material";
import { AlertProps } from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";

import { polyfillCSS } from "../../utils/css";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 199999,
  },
  alert: {
    marginTop: polyfillCSS("var(--safe-area-inset-top)"),
    borderRadius: theme.spacing(0.5),
    borderWidth: 1,
    borderStyle: "solid",
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(1.5, 5),

    "& button": {
      display: "none",
    },
  },

  success: {
    color: theme.palette.progress.green,
    borderColor: theme.palette.progress.green,
  },

  info: {
    color: theme.palette.progress.blue,
    borderColor: theme.palette.progress.blue,
  },

  warning: {
    color: theme.palette.progress.yellow,
    borderColor: theme.palette.progress.yellow,
  },

  error: {
    color: theme.palette.progress.red,
    borderColor: theme.palette.progress.red,
  },
}));

export type AlertData = {
  severity: AlertProps["severity"];
  message: React.ReactNode;
  autoHideDuration?: SnackbarProps["autoHideDuration"];
  icon?: AlertProps["icon"];
  className?: string;
  onClose?: SnackbarProps["onClose"];
};
export interface SnackbarAlertProps extends Omit<SnackbarProps, "children"> {
  severity?: AlertProps["severity"];
  children: AlertProps["children"];
  autoHideDuration?: SnackbarProps["autoHideDuration"];
}

export function SnackbarAlert(props: SnackbarAlertProps) {
  const { className, onClose, children, severity, ...other } = props;
  const s = useStyles();
  const onAlertClose = useCallback((e) => onClose(e, "clickaway"), [onClose]);

  return (
    <Snackbar className={clsx(s.root, className)} {...other} onClose={onClose}>
      <Alert
        className={clsx(s.alert, s[severity])}
        severity={severity}
        onClose={onClose && onAlertClose}
        icon={false}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
