import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import { ButtonProps } from "@mui/material";
import { usePopupState } from "material-ui-popup-state/hooks";

import { useUserIsClient } from "../../hooks/useCurrentUser";
import { ActionButton } from "../button/ActionButton";

import { SelectRecipient } from "./SelectRecipient";

const useStyles = makeStyles((theme) => ({
  root: {},

  icon: {
    marginRight: theme.spacing(1),
    height: 19,
    width: 19,
  },
}));

export interface NewMessageActionProps extends ButtonProps {
  iconNone?: boolean;
  children?: React.ReactNode;
  username?: string;
}

export function NewMessageAction(props: NewMessageActionProps) {
  const s = useStyles();
  const { className, iconNone, username, children, ...others } = props;
  const isClient = useUserIsClient();
  const href = username && `${isClient ? "" : "/coach"}/messages/${username}`;

  const dialogState = usePopupState({
    variant: "popover",
    popupId: "select-recipient-dialog",
  });

  const handleClick: ButtonProps["onClick"] = React.useCallback(
    (event) => {
      if (!href) {
        event.preventDefault();
        dialogState.open();
      }
    },
    [dialogState, href],
  );

  return (
    <>
      <ActionButton
        className={clsx(s.root, className)}
        startIcon={iconNone ? null : <AddIcon />}
        href={href}
        children={children || "New Message"}
        onClick={handleClick}
        {...others}
      />
      {dialogState.isOpen && (
        <SelectRecipient open onClose={dialogState.close} />
      )}
    </>
  );
}
