import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachSettingsAccount } from "../../../../components/settings/CoachSettingsAccount";
import { CoachSettingsProfileRouteQuery } from "./__generated__/CoachSettingsProfileRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachSettingsProfileRoute() {
  const props = useLazyLoadQuery<CoachSettingsProfileRouteQuery>(
    graphql`
      query CoachSettingsProfileRouteQuery {
        me {
          subscription {
            plan
          }
          ...CoachSettingsAccount_user
        }
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { me } = props;

  return (
    <>
      <CoachSettingsAccount userRef={me} />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Settings",
        }}
      />
    </>
  );
}
