import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import {
  Container,
  ContainerProps,
  Typography,
  Theme,
  Button,
  useScrollTrigger,
  Slide,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";

import {
  getComponentSpecifics,
  checkinQuestionsAreValid,
} from "../../utils/component";
import {
  CheckInComponentContext,
  EditorProgramContext,
} from "../new-editor/hooks";
import { ComponentType, UserRole } from "../../constants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useQueryParam } from "../../hooks/useQueryParam";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { OnAnswerUpdateContext } from "../../hooks/useOnAnswerUpdate";
import { HabitPrompt } from "../habit-prompt/HabitPrompt";
import { LockedActivityDialog } from "../dialog/LockedActivityDialog";
import { ActivityFeedbackButton } from "../activity-feedback/ActivityFeedbackButton";
import { ComponentIcon } from "../program-component/ComponentIcon";
import { CHECKIN_ELEMENT_TYPES } from "../editor/types/elements";
import { getDaysDiffFromToday, getTimeDiff } from "../../utils/date";
import { colorSystem } from "../../theme";
import { vibrate } from "../../utils/device";
import { useBackupState } from "../../hooks/useLocalStorage";

import { ActivityBar, ActivityBarProps } from "./ActivityBar";
import { ActivityStatus } from "./ActivityStatus";
import { ActivityCalendar, SelectedDay } from "./ActivityCalendar";
import { ActivityLocked } from "./ActivityLocked";
import WorkoutFinishAction from "../workout/WorkoutFinishAction";
import { usePopupState } from "material-ui-popup-state/hooks";
import WorkoutFinishModal from "../workout/WorkoutFinishModal";

import { Activity_activity$key } from "./__generated__/Activity_activity.graphql";
import { useLocation, useNavigate } from "react-router-dom";
import { PendingActivityContext } from "../../contexts/PendingActivityContext";
import { FinishWorkoutModalContext } from "../../contexts/FinishWorkoutModalContext";
import { COACH_ROUTE, HOME_ROUTE } from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import {
  EditorElementView,
  getCheckInComponentStatus,
} from "../new-editor/utils/editorUtils";
import { TElement } from "@udecode/plate-common";
import { unfixLegacyContent } from "../editor/utils/common";
import MinimizedDrawerContext from "../../contexts/MinimizedDrawerContext";

const ComponentEditor = React.lazy(
  () => import("../new-editor/ComponentEditor"),
);

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      body: {
        fontFamily: "Montserrat, sans-serif",
      },
    },

    root: {
      marginTop: 34,
      maxWidth: 1100,
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(4),
      paddingBottom: theme.spacing(8),

      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(12),
      },
    },
    card: {},
    contentContainer: {
      display: "flex",
    },

    content: {
      flex: 1,
    },
    video: {
      flex: 1,
    },

    calendar: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),

      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(6),
      },
    },

    summaryDate: {
      fontSize: 24,
      fontWeight: 600,
      margin: theme.spacing(4, 8, 4, 0),
      whiteSpace: "break-spaces",
    },

    icon: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      alignSelf: "baseline",
      "&$lesson": getComponentTypeStyles(ComponentType.LESSON, theme),
      "&$habit": getComponentTypeStyles(ComponentType.HABIT, theme),
      "&$checkin": getComponentTypeStyles(ComponentType.CHECKIN, theme),
    },

    subtitle: {
      position: "relative",
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      display: "flex",
    },

    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      position: "relative",
      flexWrap: "wrap",
    },

    feedbackButton: {
      backgroundColor: theme.palette.background.paper,
      width: theme.spacing(9),
      marginLeft: "auto",
    },

    title: {
      wordBreak: "break-word",
    },

    button: {
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      padding: theme.spacing(1.25, 0),
      marginTop: theme.spacing(4),
      color: theme.palette.common.white,
      borderColor: theme.palette.progress.green,

      "&:hover": {
        borderColor: colorSystem.green1,
        backgroundColor: colorSystem.green1,
      },

      "&$completed": {
        color: theme.palette.progress.green,
        borderColor: theme.palette.progress.green,
        backgroundColor: theme.palette.common.white,
      },
    },

    locked: {
      margin: theme.spacing(10, 0),
    },
    readyToSubmit: {
      backgroundColor: theme.palette.progress.green,
    },

    backButtonStyle: {
      backgroundColor: "transparent",
    },

    lesson: {},
    habit: {},
    checkin: {},
    completed: {},
  };

  function getComponentTypeStyles(componentType: ComponentType, theme: Theme) {
    const key = componentType.toLowerCase();
    const color = theme.palette.activity[key];

    return { fill: color };
  }
});

const activityFragment = graphql`
  fragment Activity_activity on Activity
  @argumentDefinitions(
    shouldFetchSummary: { type: "Boolean!", defaultValue: false }
  ) {
    ...ActivityStatus_activity
    ...ActivityFeedbackButton_activity
    id
    date(raw: true)
    formattedDate: date(utc: true, format: "MMM Do")
    formattedLongDate: date(utc: true, format: "dddd, MMMM D")
    content
    completed
    submitted
    startWorkout
    endWorkout
    componentId
    completion {
      rate
    }
    component {
      locked
      type
      slug
      title
      habitPrompt
      ...ComponentIcon_component
    }
    summary @include(if: $shouldFetchSummary) {
      ...ActivityCalendar_summary
    }
  }
`;

const upsertActivityMutation = graphql`
  mutation ActivityCompleteActivityMutation(
    $input: UpsertActivityInput!
    $shouldFetchSummary: Boolean!
  ) {
    upsertActivity(input: $input) {
      activity {
        ...Activity_activity
        summary @include(if: $shouldFetchSummary) {
          ...ActivityCalendar_summary
        }
      }
    }
  }
`;

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activityRef: Activity_activity$key;
  coach?: boolean;
  variables?: any;
  program?: any;
}

export function Activity(props: ActivityProps) {
  const navigate = useNavigate();
  const { className, activityRef, coach = false, variables, program } = props;
  const s = useStyles();
  const location = useLocation();
  const activity = useFragment(activityFragment, activityRef);
  const [upsertActivity, upsertActivityInFlight] = useMutation(
    upsertActivityMutation,
  );
  const originalContent: Node[] = React.useMemo(
    () => JSON.parse(activity.content) || [],
    [activity.content],
  );

  const backupKey = [activity.id, activity.date].filter(Boolean).join(":");
  const [content, setContent, removeBackup] = useBackupState(
    "activity",
    backupKey,
    originalContent,
    !coach && !activity.submitted,
  );

  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [responsesOnlyQueryParam, setResponsesOnlyQueryParam] = useQueryParam(
    "responses",
    undefined,
  );
  const [disabledAction] = useQueryParam("notification", "");
  const responsesOnly = responsesOnlyQueryParam === "true";
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 50 });
  const component = activity?.component;
  const [trackEvent] = useAnalytics();
  const onError = useGenericErrorHandler();
  const user = useCurrentUser();
  const [previewQueryParam] = useQueryParam("preview", "");
  const returnBack = location.key !== "default";
  const showSummary =
    activity.summary && component.type !== ComponentType.LESSON;
  const timeDiff = getTimeDiff(activity.date);
  const daysDiff = getDaysDiffFromToday(activity.date);
  const locked = component.locked && timeDiff > 0 && !previewQueryParam;
  const closeAfterSave = !showSummary;
  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));
  const finishWorkoutModalContext = useContext(FinishWorkoutModalContext);
  const path = location.pathname;

  const dialogState = usePopupState({
    variant: "popover",
    popupId: "workout-finish-exercise",
  });

  const backButtonTooltip =
    user.role === UserRole.CLIENT
      ? returnBack
        ? "Back to Program"
        : "Back to Home"
      : null;

  const { typeName } = React.useMemo(() => {
    return getComponentSpecifics(component.type as ComponentType);
  }, [component.type]);

  const goBack = React.useCallback(() => {
    if (returnBack) {
      navigate(-1);
    } else {
      const returnUrl =
        user.role === UserRole.CLIENT
          ? HOME_ROUTE
          : COACH_ROUTE +
            location.pathname.replace(/\/[^/]+\/[^/]+\/?\/?$/, "") +
            location.search;
      navigate(returnUrl);
    }
  }, [returnBack, user.role]);

  const handleComplete = React.useCallback(() => {
    const completed = !activity.completed;
    const input = {
      id: activity.id,
      completed,
      content: JSON.stringify(content),
    };

    upsertActivity({
      variables: {
        shouldFetchSummary: Boolean(showSummary),
        input,
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          trackEvent("Client - Complete " + typeName);

          if (completed) {
            vibrate();
          }

          removeBackup();
        }
      },
      onError,
    });
  }, [
    activity.completed,
    activity.id,
    content,
    upsertActivity,
    showSummary,
    onError,
    trackEvent,
    typeName,
    removeBackup,
  ]);

  const handleSubmit = React.useCallback(() => {
    const input = {
      id: activity.id,
      content: JSON.stringify(unfixLegacyContent(content)),
      submitted: true,
    };
    upsertActivity({
      variables: {
        shouldFetchSummary: Boolean(showSummary),
        input,
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          trackEvent("Client - Complete " + typeName);

          if (closeAfterSave) {
            if (component.type === ComponentType.WORKOUT) {
              navigate(
                `${path.substring(0, 9)}/end${path.substring(9, path.length)}`,
                {
                  state: {
                    defaultValues: {
                      startWorkout: activity.startWorkout,
                      endWorkout: activity.endWorkout,
                    },
                  },
                },
              );
            } else goBack();
          }

          if (component.type === ComponentType.LESSON) {
            vibrate();
          }

          removeBackup();
        }
      },
      onError,
    });
  }, [
    activity.id,
    content,
    upsertActivity,
    showSummary,
    onError,
    trackEvent,
    typeName,
    closeAfterSave,
    component.type,
    removeBackup,
    goBack,
    path,
  ]);

  const handleAnswerUpdate = React.useCallback(() => {
    upsertActivity({
      variables: {
        shouldFetchSummary: false,
        input: {
          id: activity.id,
          content: JSON.stringify(content),
          edited: true,
        },
      },
      onCompleted: (_, errors) => {
        if (errors?.length) {
          onError(errors[0]);
        } else {
          removeBackup();
        }
      },
      onError,
    });
  }, [activity.id, content, onError, removeBackup, upsertActivity]);

  const buttonText = React.useMemo(() => {
    switch (component.type) {
      case ComponentType.WORKOUT:
      case ComponentType.LESSON:
        return activity.completed ? "Completed" : "Mark as completed";
      case ComponentType.CHECKIN:
      case ComponentType.HABIT:
        return activity.submitted
          ? "Answers saved"
          : closeAfterSave
            ? "Save & close"
            : "Save my answers";
    }
  }, [activity.completed, activity.submitted, closeAfterSave, component.type]);

  const hasQuestions = React.useMemo(
    () =>
      content.some((node: any) => CHECKIN_ELEMENT_TYPES.includes(node.type)),
    [content],
  );

  React.useEffect(() => {
    setDisabled(
      !checkinQuestionsAreValid(
        content,
        component.type === ComponentType.CHECKIN,
      ),
    );
  }, [component.type, content]);

  const handleContentChange = React.useCallback(
    (value: Node[]) => {
      setContent(value);
    },
    [setContent],
  );

  const handleChangeResponsesOnly = React.useCallback(
    (value: boolean) => {
      setResponsesOnlyQueryParam(value);

      setContent(
        value
          ? originalContent.filter((node: any) => node.answer)
          : originalContent,
      );
    },
    [originalContent, setContent, setResponsesOnlyQueryParam],
  );

  const handleOpen = React.useCallback(
    (e?) => {
      e && e.stopPropagation();
      dialogState.open();
      finishWorkoutModalContext?.setIsFinishWorkoutModalOpen(false);
    },
    [dialogState],
  );

  const ActivityBarCommon = React.forwardRef<HTMLElement, ActivityBarProps>(
    (props: Partial<ActivityBarProps>, ref) => {
      return (
        <ActivityBar
          ref={ref}
          coach={coach}
          responsesOnly={responsesOnly}
          onChangeResponsesOnly={handleChangeResponsesOnly}
          onCloseClick={goBack}
          backButtonTooltip={backButtonTooltip}
          {...props}
        />
      );
    },
  );

  const handleCalendarDaySelect = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: SelectedDay) => {
      const path =
        location.pathname.replace(`/${activity.date}/`, `/${value.date}/`) +
        location.search;

      navigate(path, { replace: true });
    },
    [activity.date],
  );

  const context = useContext(PendingActivityContext);
  context?.setIsActivityPending(component.type === ComponentType.WORKOUT);

  useEffect(() => {
    finishWorkoutModalContext?.isFinishWorkoutModalOpen && handleOpen();
  }, [finishWorkoutModalContext?.isFinishWorkoutModalOpen]);

  const [editingEl, setEditingEl] = React.useState<TElement | null>(null);

  const workoutFinishButton = React.useMemo(() => {
    return (
      activity.component.type === ComponentType.WORKOUT &&
      !coach &&
      !disabledAction && (
        <WorkoutFinishAction
          submitted={activity?.submitted}
          handleSubmit={handleOpen}
        />
      )
    );
  }, [
    activity?.component?.type,
    activity.submitted,
    coach,
    disabledAction,
    handleOpen,
  ]);

  const theme = useTheme();
  const isLlg = useMediaQuery(theme.breakpoints.down("llg"));
  const { minimizedDrawer } = useContext(MinimizedDrawerContext);

  return (
    <>
      <EditorProgramContext.Provider value={program}>
        {!locked || showSummary ? (
          <>
            {!(component.type === ComponentType.WORKOUT) && (
              <ActivityBarCommon position="relative" elevation={0} />
            )}
            {mdSm && component.type === ComponentType.WORKOUT ? (
              <ActivityBarCommon
                position="relative"
                elevation={0}
                {...(activity.submitted && { action: workoutFinishButton })}
              />
            ) : (
              !mdSm &&
              component.type === ComponentType.WORKOUT && (
                <ActivityBarCommon
                  position="relative"
                  elevation={0}
                  {...(activity.submitted && { action: workoutFinishButton })}
                  className={s.backButtonStyle}
                  backgroundStyle={s.backButtonStyle}
                />
              )
            )}
            <Container className={clsx(s.root, className)}>
              <Slide
                appear={false}
                direction="down"
                in={trigger}
                style={{
                  zIndex: theme.zIndex.modal,
                  paddingLeft:
                    !activity?.submitted &&
                    !coach &&
                    component.type === ComponentType.WORKOUT &&
                    !isLlg
                      ? minimizedDrawer
                        ? theme.drawer.minimizedWidth
                        : theme.drawer.width
                      : 0,
                }}
              >
                <ActivityBarCommon
                  subtitle={typeName}
                  title={component.title}
                  action={workoutFinishButton}
                />
              </Slide>

              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  className={s.subtitle}
                  variant="body1"
                  component="h4"
                >
                  {showSummary
                    ? typeName
                    : `${typeName} • ${activity.formattedDate}`}
                </Typography>
                {!showSummary && mdSm && (
                  <ActivityFeedbackButton
                    className={s.feedbackButton}
                    activity={activity}
                  />
                )}
              </Box>

              <Box className={s.header}>
                <ComponentIcon
                  className={s.icon}
                  componentRef={component}
                  variant="icon"
                />
                <Typography
                  className={s.title}
                  variant="h3"
                  component="h1"
                  children={component.title}
                />
                {!showSummary && !mdSm && (
                  <ActivityFeedbackButton
                    className={s.feedbackButton}
                    activity={activity}
                  />
                )}
              </Box>

              {showSummary && (
                <>
                  <ActivityCalendar
                    className={s.calendar}
                    summaryRef={activity.summary}
                    onDaySelect={handleCalendarDaySelect}
                    selected={activity.date}
                  />
                  <Box className={s.header}>
                    <Typography className={s.summaryDate} variant="h3">
                      {activity.formattedLongDate}
                    </Typography>

                    <ActivityFeedbackButton
                      className={s.feedbackButton}
                      activity={activity}
                    />
                  </Box>
                </>
              )}

              {locked ? (
                <ActivityLocked className={s.locked} daysDiff={daysDiff} />
              ) : (
                <>
                  {coach && <ActivityStatus activityRef={activity} />}

                  {!coach && component.type === ComponentType.HABIT && (
                    <HabitPrompt
                      completed={activity.completed}
                      submitted={activity.submitted}
                      disabled={!hasQuestions && activity.submitted}
                      prompt={component.habitPrompt}
                      onClick={handleComplete}
                    />
                  )}
                  <OnAnswerUpdateContext.Provider
                    value={
                      user.role === UserRole.CLIENT && activity.submitted
                        ? handleAnswerUpdate
                        : undefined
                    }
                  >
                    <CheckInComponentContext.Provider
                      value={{
                        // NOTE: Coach can only review the activity
                        view:
                          user.role === UserRole.CLIENT
                            ? EditorElementView.Client
                            : EditorElementView.Review,
                        status: getCheckInComponentStatus(
                          user.role,
                          activity.submitted,
                        ),
                        editingEl: editingEl,
                        setEditingEl: setEditingEl,
                      }}
                    >
                      <ComponentEditor
                        componentType={component.type as ComponentType}
                        value={content}
                        onChange={handleContentChange}
                        readOnly={true}
                        disabled={true}
                      />
                    </CheckInComponentContext.Provider>
                  </OnAnswerUpdateContext.Provider>

                  {!coach &&
                    !activity.submitted &&
                    !(
                      (component.type === ComponentType.HABIT ||
                        component.type === ComponentType.WORKOUT) &&
                      !hasQuestions
                    ) && (
                      <Button
                        className={clsx(
                          s.button,
                          activity.submitted && s.completed,
                          !disabled &&
                            !upsertActivityInFlight &&
                            s.readyToSubmit,
                        )}
                        fullWidth
                        variant="outlined"
                        onClick={handleSubmit}
                        disabled={disabled || upsertActivityInFlight}
                        children={buttonText}
                      />
                    )}
                </>
              )}
              {dialogState.isOpen && (
                <WorkoutFinishModal
                  handleSubmit={handleSubmit}
                  onClose={() => dialogState.close()}
                  disabled={upsertActivityInFlight}
                />
              )}
            </Container>
          </>
        ) : (
          <LockedActivityDialog
            open={true}
            onClose={goBack}
            daysDiff={daysDiff}
          />
        )}
      </EditorProgramContext.Provider>
      <TrackInfoTool
        trackInfo={{
          name: previewQueryParam
            ? "Coach - Preview Activity"
            : "Client - Program",
          properties: variables,
        }}
      />
    </>
  );
}
