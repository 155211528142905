import React from "react";
import { Box, BoxProps } from "@mui/material";
import { ReactNode } from "react";
import { StandardCSSProperties } from "@mui/system";

export interface BorderedBoxProps extends Omit<BoxProps, "border"> {
  children: ReactNode;
  border?: boolean;
}

// TODO_Editor use for all components (?) to get the same borders around components?
export const BorderedBox = ({
  children,
  border = true,
  px = 3,
  py = 3.5,
  ...props
}: BorderedBoxProps) => {
  return (
    <Box
      sx={{
        border: (theme) =>
          border &&
          `${theme.shape.border.xs}px solid ${theme.palette.editor.border}`,
        borderRadius: 2,
        px: border && px,
        py: border && py,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
