import { useMemo } from "react";

/*
    Hook to get the component's key that gets updated on content's change to re-render the component.
    This is in particular needed for PlateEditor since it is not a controlled component
    https://github.com/udecode/plate/discussions/1262#discussioncomment-6851423
*/
const useDependantKey = (content) => {
  const key = useMemo(() => {
    if (content) {
      return window.crypto.randomUUID();
    }
  }, [content]);

  return key;
};

export default useDependantKey;
