import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Avatar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { MessageContent } from "../messages/MessageContent";

import { ActivityFeedback_activityFeedback$key } from "./__generated__/ActivityFeedback_activityFeedback.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "top",
    justifyContent: "left",
    padding: theme.spacing(2.5, 0, 3),
    marginLeft: theme.spacing(-1),
  },

  avatar: {},

  wrapper: {
    maxWidth: "-webkit-fill-available",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "left",
    justifyContent: "center",
    marginRight: theme.spacing(5),
    paddingLeft: theme.spacing(2.25),
  },

  name: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginRight: theme.spacing(1),
  },

  time: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  contentWrapper: {
    marginTop: theme.spacing(1),
  },

  text: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 500,
    color: theme.palette.common.black,
    whiteSpace: "break-spaces",
  },
}));

const fragment = graphql`
  fragment ActivityFeedback_activityFeedback on ActivityFeedback {
    id
    author {
      id
      displayName
      photoURL
    }
    content
    read
    createdAt(format: "fromNow")
  }
`;

const notificationReadMutation = graphql`
  mutation ActivityFeedbackNotificationReadMutation(
    $input: NotificationReadInput!
  ) {
    notificationRead(input: $input) {
      notification {
        id
        activityFeedbackId
      }
    }
  }
`;

export function notificationReadStoreUpdater(store) {
  const mutationRoot = store.getRootField("notificationRead");

  if (mutationRoot) {
    const notification = mutationRoot.getLinkedRecord("notification");

    if (notification) {
      notification.setValue(true, "read");
    }

    const activityFeedback = store.get(
      notification.getValue("activityFeedbackId") as string,
    );

    if (activityFeedback) {
      activityFeedback.setValue(true, "read");
    }
  }

  const notifications = ConnectionHandler.getConnection(
    store.getRoot().getLinkedRecord("me"),
    "NotificationsMenu_notifications",
    [],
  );

  if (notifications) {
    const unreadCount = notifications.getValue("unreadCount") as number;

    if (unreadCount > 0) {
      notifications.setValue(unreadCount - 1, "unreadCount");
    }
  }
}

export interface ActivityFeedbackProps extends BoxProps {
  fragmentRef: ActivityFeedback_activityFeedback$key;
}

export function ActivityFeedback(props: ActivityFeedbackProps) {
  const { className, fragmentRef, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { id, author, content, read, createdAt } = useFragment(
    fragment,
    fragmentRef,
  );
  const [notificationRead, inFlight] = useMutation(notificationReadMutation);

  React.useEffect(() => {
    if (author.id !== user.id && !read && !inFlight) {
      notificationRead({
        variables: {
          input: { activityFeedbackId: id },
        },
        onCompleted: (data, errors) => {
          if (errors && errors.length) {
            console.error(errors[0]);
          }
        },
        onError: (error) => {
          console.error(error);
        },
        updater: notificationReadStoreUpdater,
      });
    }
  }, [id, read, notificationRead, inFlight, author.id, user.id]);

  const name = React.useMemo(
    () => (author.id === user.id ? "You" : author.displayName),
    [author.displayName, author.id, user.id],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Avatar className={s.avatar} alt={name} src={author.photoURL} />
      <Box className={s.wrapper}>
        <Box>
          <Typography
            className={s.name}
            variant="body1"
            component="p"
            children={name}
          />
          <Typography
            className={s.time}
            variant="body1"
            component="p"
            children={createdAt}
          />
        </Box>
        <MessageContent
          content={content}
          wrapperClassName={s.contentWrapper}
          textClassName={s.text}
        />
      </Box>
    </Box>
  );
}
