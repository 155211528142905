/**
 * @generated SignedSource<<2577eeb75c890411b25800ac5b72f9d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsNotificationRouteQuery$variables = Record<PropertyKey, never>;
export type ClientSettingsNotificationRouteQuery$data = {
  readonly me: {
    readonly notificationsSetting: {
      readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsNotifications_settings">;
    } | null | undefined;
  } | null | undefined;
};
export type ClientSettingsNotificationRouteQuery = {
  response: ClientSettingsNotificationRouteQuery$data;
  variables: ClientSettingsNotificationRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsNotificationRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsSetting",
            "kind": "LinkedField",
            "name": "notificationsSetting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClientSettingsNotifications_settings"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientSettingsNotificationRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsSetting",
            "kind": "LinkedField",
            "name": "notificationsSetting",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newMessageEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newMessagePush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newResponseEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newResponsePush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weeklyUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dailyUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remindersEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remindersSms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remindersPush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newGroupPostEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newGroupPostPush",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupReplyEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupReplyPush",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e230211f89096335f4c2d6be36c49c0",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsNotificationRouteQuery",
    "operationKind": "query",
    "text": "query ClientSettingsNotificationRouteQuery {\n  me {\n    notificationsSetting {\n      ...ClientSettingsNotifications_settings\n      id\n    }\n    id\n  }\n}\n\nfragment ClientSettingsNotifications_settings on NotificationsSetting {\n  id\n  newMessageEmail\n  newMessagePush\n  newResponseEmail\n  newResponsePush\n  weeklyUpdate\n  dailyUpdate\n  remindersEmail\n  remindersSms\n  remindersPush\n  newGroupPostEmail\n  newGroupPostPush\n  groupReplyEmail\n  groupReplyPush\n}\n"
  }
};
})();

(node as any).hash = "0497e25fc8ac7526006a351c2739e55a";

export default node;
