import { graphql } from "react-relay/lib/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { UpsertGroupPostCommentMutation } from "./__generated__/UpsertGroupPostCommentMutation.graphql";

const mutation = graphql`
  mutation UpsertGroupPostCommentMutation(
    $input: UpsertGroupPostCommentInput!
  ) {
    upsertGroupPostComment(input: $input) {
      clientMutationId
      groupPostCommentEdge {
        node {
          ...GroupPostCommentsListItemNoReplies_comment
          ...GroupPostCommentsListItemWithReplies_comment
        }
      }
    }
  }
`;

export const useUpsertGroupPostCommentMutation = (connectionParentId: string) =>
  useConfiguredMutation<UpsertGroupPostCommentMutation>(mutation, (config) => {
    return {
      configs: [
        {
          type: "RANGE_ADD",
          parentID: connectionParentId,
          connectionInfo: [
            {
              key: config.variables.input.parentCommentId
                ? "GroupPostCommentsListItem_replies"
                : "GroupPostCommentsList_comments",
              rangeBehavior: "append",
            },
          ],
          edgeName: "groupPostCommentEdge",
        },
      ],
    };
  });
