import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useSearchParams } from "react-router-dom";
import { AppLayout } from "../../../../components/app/AppLayout";
import { AppThemeProvider } from "../../../../components/app/ThemeProvider";
import { ClientAcceptInviteScreen } from "../../../../components/auth/ClientAcceptInviteScreen";
import { SignUpClientRouteQuery } from "./__generated__/SignUpClientRouteQuery.graphql";

export function SignUpClientRoute() {
  const [searchParams] = useSearchParams();

  const oobCode = searchParams.get("oobCode");
  const id = searchParams.get("id");
  const email = searchParams.get("email");
  const requestWorkspace = searchParams.get("workspace");

  const props = useLazyLoadQuery<SignUpClientRouteQuery>(
    graphql`
      query SignUpClientRouteQuery($fbUserId: String!, $workspace: String) {
        invite(firebaseUid: $fbUserId) {
          ...ClientAcceptInviteScreen_invite
        }

        workspace(slug: $workspace) {
          ...ThemeProvider_workspace
        }
      }
    `,
    {
      fbUserId: id ?? "",
      workspace: requestWorkspace,
    },
  );

  const { invite, workspace } = props;

  return (
    <AppLayout title="Sign Up" appBar={false}>
      <AppThemeProvider workspace={workspace}>
        <ClientAcceptInviteScreen
          inviteRef={invite}
          code={oobCode}
          id={id}
          email={email}
        />
      </AppThemeProvider>
    </AppLayout>
  );
}
