/**
 * @generated SignedSource<<6da1915a0ca6ed39c8c56aaa1127e0f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserNotesListItem_note$data = {
  readonly content: string;
  readonly createdAtDate: string | null | undefined;
  readonly createdAtTime: string | null | undefined;
  readonly id: string;
  readonly user: {
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserNoteDialog_note">;
  readonly " $fragmentType": "UserNotesListItem_note";
};
export type UserNotesListItem_note$key = {
  readonly " $data"?: UserNotesListItem_note$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNotesListItem_note">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNotesListItem_note",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserNoteDialog_note"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": "createdAtDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMMM D, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"MMMM D, YYYY\")"
    },
    {
      "alias": "createdAtTime",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "hh:mmA"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"hh:mmA\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UserNote",
  "abstractKey": null
};
})();

(node as any).hash = "72c8e690fc8d791342d6cb7996f90820";

export default node;
