/**
 * @generated SignedSource<<97eb97dda4f99f1cea9edb6ebaf87fed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsProfileRouteQuery$variables = Record<PropertyKey, never>;
export type ClientSettingsProfileRouteQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsProfile_user">;
  } | null | undefined;
};
export type ClientSettingsProfileRouteQuery = {
  response: ClientSettingsProfileRouteQuery$data;
  variables: ClientSettingsProfileRouteQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientSettingsProfileRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientSettingsProfile_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientSettingsProfileRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSample",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "photoURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneVerified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "MMM DD, YYYY"
              }
            ],
            "kind": "ScalarField",
            "name": "passwordUpdatedAt",
            "storageKey": "passwordUpdatedAt(format:\"MMM DD, YYYY\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeZone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "units",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terraConnected",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terraProviders",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TerraSdkOptions",
            "kind": "LinkedField",
            "name": "terraSdkOptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "devId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referenceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e77551add946b50cdce5d676a8df0ea9",
    "id": null,
    "metadata": {},
    "name": "ClientSettingsProfileRouteQuery",
    "operationKind": "query",
    "text": "query ClientSettingsProfileRouteQuery {\n  me {\n    ...ClientSettingsProfile_user\n    id\n  }\n}\n\nfragment ClientSettingsProfile_user on User {\n  id\n  isSample\n  displayName\n  email\n  photoURL\n  phone\n  phoneCode\n  phoneVerified\n  passwordUpdatedAt(format: \"MMM DD, YYYY\")\n  location\n  birthday\n  gender\n  timeZone\n  weight\n  height\n  units\n  terraConnected\n  terraProviders\n  terraSdkOptions {\n    devId\n    referenceId\n    token\n  }\n}\n"
  }
};

(node as any).hash = "088cff98e1e5d331a6f466889a48df91";

export default node;
