import { graphql } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

import { UpsertMealLoggingEntryMutation } from "./__generated__/UpsertMealLoggingEntryMutation.graphql";

const mutation = graphql`
  mutation UpsertMealLoggingEntryMutation(
    $input: UpsertMealLoggingEntryInput!
  ) {
    upsertMealLoggingEntry(input: $input) {
      mealLoggingEntryEdge {
        node {
          ...ClientMealLoggingEntryDialog_mealLoggingEntry
          ...ClientMealLoggingEntriesListItem_mealLoggingEntry
        }
      }
    }
  }
`;

export const useUpsertMealLoggingEntryMutation = (date: string) => {
  const snackAlert = useSnackAlert();
  const user = useCurrentUser();

  return useConfiguredMutation<UpsertMealLoggingEntryMutation>(
    mutation,
    (config) => ({
      onSuccess: () =>
        snackAlert({
          severity: "success",
          message: "Meal submitted",
        }),

      configs: !config.variables.input.id && [
        {
          type: "RANGE_ADD",
          parentID: user.id,
          connectionInfo: [
            {
              key: "ClientMealLoggingEntriesList_mealLoggingEntries",
              rangeBehavior: "append",
            },
          ],
          edgeName: "mealLoggingEntryEdge",
        },
      ],

      updater(store) {
        if (config.variables.input.id && date !== config.variables.input.date) {
          const mealLoggingEntries = ConnectionHandler.getConnection(
            store.getRoot().getLinkedRecord("me"),
            "ClientMealLoggingEntriesList_mealLoggingEntries",
            [],
          );

          ConnectionHandler.deleteNode(
            mealLoggingEntries,
            config.variables.input.id,
          );
        }
      },
    }),
  );
};
