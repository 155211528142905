import React from "react";
import { Box, BoxProps, Button, Typography, Divider } from "@mui/material";
import {
  ClientMeasurementRow,
  MetricType,
} from "../client-measurement/ClientMeasurementRow";
import makeStyles from "@mui/styles/makeStyles";
import {
  convertUnits,
  getDefaultUnit,
  measurementTypes,
  Units,
} from "../../constants";
import { ClientMeasurementsCartDrawerEditView_metrics$key } from "./__generated__/ClientMeasurementsCartDrawerEditView_metrics.graphql";
import { graphql } from "react-relay";
import { reverse, sortBy } from "lodash";
import { useFragment, useMutation } from "react-relay/hooks";
import { ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation } from "./__generated__/ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation.graphql";
import { ClientMeasurementsCartDrawerEditViewUpdateClientMetricMutation } from "./__generated__/ClientMeasurementsCartDrawerEditViewUpdateClientMetricMutation.graphql";
import { ClientMeasurementsCartDrawerEditViewRemoveClientMetricMutation } from "./__generated__/ClientMeasurementsCartDrawerEditViewRemoveClientMetricMutation.graphql";
import { ReactComponent as PlusIcon } from "../../icons/AddCircleOutline.svg";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(4),
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  sortOption: {
    fontWeight: 500,
  },

  sort: {},

  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
  },

  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  save: {
    marginLeft: theme.spacing(1),
    color: theme.palette.progress.green,
    fontSize: 16,
    fontWeight: 500,
  },

  history: {
    fontSize: 20,
    margin: theme.spacing(3, 4, 2, 0),
  },
  addResult: {
    marginTop: theme.spacing(0),
    fontSize: 16,
    fontWeight: 500,
    float: "right",

    "& svg": {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  },
  historyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(0, 0, 2),
  },
}));

const createMeasurementMutation = graphql`
  mutation ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation(
    $input: CreateClientMetricInput!
  ) {
    createClientMetric(input: $input) {
      metric {
        x: activityDate(format: "YYYY-MM-DD")
        activityDate(raw: true)
        value {
          ... on CheckinAnswerMeasurementValue {
            measurement
            unit
          }
        }
      }
    }
  }
`;

const updateMeasurementMutation = graphql`
  mutation ClientMeasurementsCartDrawerEditViewUpdateClientMetricMutation(
    $input: UpdateClientMetricInput!
  ) {
    updateClientMetric(input: $input) {
      metric {
        x: activityDate(format: "YYYY-MM-DD")
        activityDate(raw: true)
        value {
          ... on CheckinAnswerMeasurementValue {
            measurement
            unit
          }
        }
      }
    }
  }
`;

const removeMeasurementMutation = graphql`
  mutation ClientMeasurementsCartDrawerEditViewRemoveClientMetricMutation(
    $input: RemoveClientMetricInput!
  ) {
    removeClientMetric(input: $input) {
      clientMutationId
    }
  }
`;

const metricsFragment = graphql`
  fragment ClientMeasurementsCartDrawerEditView_metrics on ClientMetric
  @relay(plural: true) {
    id
    activityDate(raw: true)
    name
    value {
      ... on CheckinAnswerMeasurementValue {
        measurement
        unit
      }
    }
  }
`;

export interface ClientMeasurementsCartDrawerEditViewProps extends BoxProps {
  onClose?: () => void;
  clientId?: string;
  metrics?: ClientMeasurementsCartDrawerEditView_metrics$key;
  units?: Units;
  onMeasurementChanged?: () => void;
  selectType: string;
}

export function ClientMeasurementsCartDrawerEditView(
  props: ClientMeasurementsCartDrawerEditViewProps,
) {
  const {
    clientId,
    metrics: metricsKey,
    units,
    onMeasurementChanged,
    selectType,
  } = props;
  const metrics = useFragment(metricsFragment, metricsKey);
  const s = useStyles();

  const [createMeasurement, creating] =
    useMutation<ClientMeasurementsCartDrawerEditViewCreateClientMetricMutation>(
      createMeasurementMutation,
    );

  const [updateMeasurement, updating] =
    useMutation<ClientMeasurementsCartDrawerEditViewUpdateClientMetricMutation>(
      updateMeasurementMutation,
    );

  const [removeMeasurement, removing] =
    useMutation<ClientMeasurementsCartDrawerEditViewRemoveClientMetricMutation>(
      removeMeasurementMutation,
    );

  const selectedType = measurementTypes.find(({ name }) => name === selectType);

  const { unitType, name } = selectedType;
  const unit = getDefaultUnit(unitType, units);
  const snackAlert = useSnackAlert();

  const disabled = creating || updating || removing;
  const [editingIndex, setEditingIndex] = React.useState(-1);
  const [draftMetric, setDraftMetric] = React.useState<MetricType>(null);
  const formattedMetrics: MetricType[] = React.useMemo(
    () =>
      [
        draftMetric,
        ...reverse(
          sortBy(
            metrics.map(({ id, name, activityDate, value }) => ({
              id,
              name,
              date: activityDate,
              measurement: convertUnits(
                unitType,
                value.unit as Units,
                unit.toLocaleUpperCase() as Units,
                value.measurement,
              ),
              unit,
            })),
            ({ date }) => date,
          ),
        ),
      ].filter(Boolean),
    [draftMetric, metrics, unit, unitType],
  );

  const handleCreateClick = React.useCallback(() => {
    setDraftMetric({
      name,
      unit,
      date: dayjs(new Date()).format("YYYY-MM-DD"),
      measurement: 0,
    });
    setEditingIndex(0);
  }, [name, unit]);

  const handleCancel = React.useCallback(() => {
    setEditingIndex(-1);

    if (draftMetric) {
      setDraftMetric(null);
    }
  }, [draftMetric]);

  const handleEdit = React.useCallback(
    (metric: MetricType) => {
      setEditingIndex(formattedMetrics.findIndex((it) => it.id === metric.id));
    },
    [formattedMetrics],
  );

  const handleRemove = React.useCallback(
    ({ id, setConfirmDelete }: MetricType) => {
      removeMeasurement({
        variables: {
          input: { id },
        },
        onCompleted: (_, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Delete successfully",
            });
            setConfirmDelete(false);

            if (onMeasurementChanged) {
              onMeasurementChanged();
            }
          }
        },
      });
    },
    [removeMeasurement, snackAlert, onMeasurementChanged],
  );
  const handleSave = React.useCallback(
    ({ id, unit, date, name, measurement, setConfirmDelete }: MetricType) => {
      if (id) {
        updateMeasurement({
          variables: {
            input: {
              id,
              measurement,
              date,
              unit,
            },
          },
          onCompleted: (_, errors) => {
            if (errors) {
              console.error(errors);
              snackAlert({
                severity: "error",
                message: "Error occurred.",
              });
            } else {
              snackAlert({
                severity: "success",
                message: "Saved successfully",
              });
              handleCancel();

              if (onMeasurementChanged) {
                onMeasurementChanged();
              }
            }
          },
        });
      } else {
        createMeasurement({
          variables: {
            input: {
              unit,
              date,
              name,
              measurement,
              clientId,
              type: selectedType.name.toLocaleUpperCase(),
            },
          },
          onCompleted: (_, errors) => {
            if (errors) {
              console.error(errors);
              snackAlert({
                severity: "error",
                message: "Error occurred.",
              });
            } else {
              snackAlert({
                severity: "success",
                message: "Created successfully",
              });
              handleCancel();

              if (onMeasurementChanged) {
                onMeasurementChanged();
              }
            }
          },
        });
      }
    },
    [
      clientId,
      createMeasurement,
      handleCancel,
      selectedType,
      onMeasurementChanged,
      snackAlert,
      updateMeasurement,
    ],
  );

  return (
    <Box>
      {!draftMetric && (
        <Button
          variant="text"
          color="primary"
          className={s.addResult}
          onClick={handleCreateClick}
          disabled={disabled}
        >
          Add result
          <PlusIcon />
        </Button>
      )}
      <Typography variant="subtitle1" className={s.title}>
        History
      </Typography>

      <Divider />
      {formattedMetrics.map((metric, index) => (
        <React.Fragment key={[index, metric.date, metric.id].join(",")}>
          <ClientMeasurementRow
            metric={metric}
            disabled={disabled}
            editing={index === editingIndex}
            onCancel={handleCancel}
            onEdit={handleEdit}
            onSave={handleSave}
            onRemove={handleRemove}
          />
          {index < metrics.length && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
}
