/**
 * @generated SignedSource<<b68f9a17a0f2b07b0dc198e1d9a48547>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupEnrollmentList_groupEnrollments$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"GroupEnrollmentCard_groupEnrollment">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly totalCount: number;
  readonly " $fragmentType": "GroupEnrollmentList_groupEnrollments";
};
export type GroupEnrollmentList_groupEnrollments$key = {
  readonly " $data"?: GroupEnrollmentList_groupEnrollments$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupEnrollmentList_groupEnrollments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupEnrollmentList_groupEnrollments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GroupEnrollmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GroupEnrollment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GroupEnrollmentCard_groupEnrollment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GroupEnrollmentConnection",
  "abstractKey": null
};

(node as any).hash = "883bd817079502e0821683ae962e60f2";

export default node;
