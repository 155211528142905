import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { getISODate } from "../../../utils/date";
import { ClientMealLoggingScreen } from "../../../components/client-meal-logging/ClientMealLoggingScreen";
import { ClientMealLoggingRouteQuery } from "./__generated__/ClientMealLoggingRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../utils/relay";

export function ClientMealLoggingRoute() {
  const { date } = useParams();

  const props = useLazyLoadQuery<ClientMealLoggingRouteQuery>(
    graphql`
      query ClientMealLoggingRouteQuery($date: String!) {
        ...ClientMealLoggingScreen_root @arguments(date: $date)
      }
    `,
    {
      date: date || getISODate(),
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  return <ClientMealLoggingScreen root={props} date={date} />;
}
