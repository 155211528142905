import React, { createContext, useContext } from "react";
import { CompletionPeriod, ResponsesView } from "../constants";

export interface ContextValues {
  view: ResponsesView;
  setView: (v: ResponsesView) => void;
  period: CompletionPeriod;
  setPeriod: (p: CompletionPeriod) => void;
}

export const ClientResponseFilterContext = createContext<
  ContextValues | undefined
>(undefined);

export const useClientResponseFilterContext = () => {
  const context = useContext(ClientResponseFilterContext);
  if (context === undefined) {
    throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
};
