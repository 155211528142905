/**
 * @generated SignedSource<<f9e2301106007d7a32e9d969b180d5e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveClientFormInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type RemoveClientFormMutation$variables = {
  input: RemoveClientFormInput;
};
export type RemoveClientFormMutation$data = {
  readonly removeClientForm: {
    readonly clientFormId: string;
  } | null | undefined;
};
export type RemoveClientFormMutation = {
  response: RemoveClientFormMutation$data;
  variables: RemoveClientFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveClientFormPayload",
    "kind": "LinkedField",
    "name": "removeClientForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientFormId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveClientFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveClientFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7d891fb5232fc05a00b7bcbaff3de231",
    "id": null,
    "metadata": {},
    "name": "RemoveClientFormMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveClientFormMutation(\n  $input: RemoveClientFormInput!\n) {\n  removeClientForm(input: $input) {\n    clientFormId\n  }\n}\n"
  }
};
})();

(node as any).hash = "0367e50039652c503bc041d9ff0ee5ae";

export default node;
