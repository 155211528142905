import clsx from "clsx";
import React from "react";
import { Box, TextField, Chip, BoxProps } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import makeStyles from "@mui/styles/makeStyles";

import { validateEmail } from "../../utils/user";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${colorSystem.secondaryGray}`,
    padding: theme.spacing(2.25, 2),
    cursor: "text",
    marginBottom: theme.spacing(2),

    "&:hover": {
      borderColor: theme.palette.common.black,
    },
  },

  emailInput: {
    color: ({ hasError }: StyleProps) =>
      hasError ? theme.palette.error.main : theme.palette.common.black,

    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },

  chip: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: colorSystem.white3,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(0.5),
  },

  chipIcon: {
    width: 17,
    height: 17,
    marginRight: theme.spacing(1.25),
    color: theme.palette.text.secondary,
  },
}));

type StyleProps = {
  hasError: boolean;
};

export interface MultipleEmailsFieldProps extends Omit<BoxProps, "onChange"> {
  values: string[];
  onChange: (values: string[]) => void;
  disabled?: boolean;
}

export function MultipleEmailsField(props: MultipleEmailsFieldProps) {
  const { className, values, disabled, onChange } = props;
  const [hasError, setHasError] = React.useState(false);
  const s = useStyles({ hasError });
  const inputRef = React.useRef<HTMLInputElement>();
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = React.useCallback(
    (values) => {
      onChange(values);
    },
    [onChange],
  );

  const handleFocusOnClick = React.useCallback(() => {
    inputRef.current.focus();
  }, []);

  const handleInputChange = React.useCallback(({ target: { value } }) => {
    setInputValue(value);
  }, []);

  const handleAddEmail = React.useCallback(() => {
    const email = inputValue.trim();

    if (email) {
      if (validateEmail(email)) {
        if (!values.includes(email)) {
          handleChange([...values, email]);
        }

        setInputValue("");
      } else {
        setHasError(true);
      }
    } else {
      setHasError(false);
    }
  }, [values, inputValue, handleChange]);

  const handleBlur = React.useCallback(() => {
    handleAddEmail();
  }, [handleAddEmail]);

  const handlePaste = React.useCallback(
    (event: React.ClipboardEvent) => {
      const clipboardText = event.clipboardData.getData("text");
      const arrayEmails = clipboardText.split(/[\0\s]+/g);
      const filteredEmails = arrayEmails.filter(
        (email, index) =>
          validateEmail(email) &&
          !values.includes(email) &&
          arrayEmails.indexOf(email) === index,
      );

      handleChange([...values, ...filteredEmails]);

      event.preventDefault();
    },
    [values, handleChange],
  );

  const handleKeyPress = React.useCallback(
    (event) => {
      if (hasError) {
        setHasError(false);
      }

      if (["Enter", ",", " "].includes(event.key)) {
        event.preventDefault();
        handleAddEmail();
      }
    },
    [handleAddEmail, hasError],
  );

  const handleKeyDown = React.useCallback(
    (event) => {
      if (event.key === "Backspace" && !inputValue && values.length) {
        handleChange(values.slice(0, -1));
      }

      if (event.key === "Tab") {
        event.preventDefault();
        handleAddEmail();
      }
    },
    [inputValue, values, handleChange, handleAddEmail],
  );

  const handleRemove = React.useCallback(
    (value) => {
      handleChange(values.filter((it) => it !== value));
    },
    [values, handleChange],
  );

  React.useEffect(() => {
    const enrollItem = window.localStorage.getItem("enrollDialogEmail");
    if (enrollItem) {
      setInputValue(enrollItem);
      window.localStorage.removeItem("enrollDialogEmail");
    }
  }, []);

  const renderChip = (value: string, index: number) => (
    <Chip
      key={index}
      label={value}
      className={s.chip}
      deleteIcon={<ClearIcon className={s.chipIcon} />}
      onDelete={() => handleRemove(value)}
      disabled={disabled}
    />
  );

  return (
    <Box className={clsx(s.root, className)} onClick={handleFocusOnClick}>
      {values.map(renderChip)}

      <TextField
        fullWidth
        multiline
        maxRows={3}
        inputRef={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onPaste={(e) => handlePaste(e)}
        InputProps={{
          className: s.emailInput,
          disableUnderline: true,
        }}
        autoFocus
        disabled={disabled}
        placeholder="Add client emails here"
      />
    </Box>
  );
}
