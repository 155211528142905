import { SxProps, Typography, TypographyProps } from "@mui/material";
import React from "react";

const SetCellText = ({
  secondary = false,
  children,
  forwardRef,
  sx,
}: {
  secondary?: boolean;
  children: TypographyProps["children"];
  forwardRef?: any;
  sx?: SxProps;
}) => (
  <Typography
    variant="inherit"
    component="span"
    color={(theme) =>
      secondary ? theme.palette.divider : theme.palette.common.black
    }
    ref={forwardRef}
    sx={{ ...sx }}
  >
    {children}
  </Typography>
);

export default SetCellText;
