import {
  BasicToolbarMenu,
  CheckinToolbarMenu,
  MediaToolbarMenu,
  Schema,
} from "./Schema";

export const ClientFormEditorSchema: Schema = {
  generalDropDownMenuItems: [
    ...BasicToolbarMenu.sections,
    ...MediaToolbarMenu.sections,
    ...CheckinToolbarMenu.sections,
  ],
  toolbarOptions: {
    showInsertDropdown: true,
    showTextTransformOptions: true,
    showTextFormattingOptions: true,
    showTextColorOptions: true,
    showTextIndentationOptions: true,
    showListOptions: true,
    showQuotesOptions: false,
    showLinkOption: false,
    showAdvancedLinkOptions: false,
    showElementInsertOptions: false,
    showEmojiOption: true,
  },
  stickyToolbar: true,
};
