import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { BoxProps, Box } from "@mui/material";
import { PaginationItem, PaginationProps } from "@mui/lab";

import { ConnectionPagination_pageCursors$key } from "./__generated__/ConnectionPagination_pageCursors.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
}));

const pageCursorsFragment = graphql`
  fragment ConnectionPagination_pageCursors on PageCursors {
    previous {
      current
      cursor
    }

    around {
      current
      cursor
      page
    }

    next {
      current
      cursor
    }
  }
`;

export interface ConnectionPaginationProps
  extends Omit<BoxProps, "onChange">,
    Pick<PaginationProps, "size"> {
  pageCursors: ConnectionPagination_pageCursors$key;
  onChange?: (cursor: string) => void;
  autoHide?: boolean;
}

export function ConnectionPagination(props: ConnectionPaginationProps) {
  const {
    className,
    pageCursors: pageCursorsRef,
    size = "large",
    onChange,
    autoHide = false,
    ...other
  } = props;
  const pageCursors = useFragment(pageCursorsFragment, pageCursorsRef);
  const s = useStyles();
  const { previous, around, next } = pageCursors;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { cursor },
      } = event.currentTarget;

      if (onChange) {
        onChange(cursor);
      }
    },
    [onChange],
  );

  return (
    (!autoHide || around.length > 1) && (
      <Box className={clsx(s.root, className)} {...other}>
        <PaginationItem
          size={size}
          selected={!previous}
          disabled={!previous}
          type="previous"
          data-cursor={previous?.cursor}
          onClick={handleClick}
        />

        {around.map(({ page, current, cursor }) => (
          <PaginationItem
            key={cursor}
            page={page}
            selected={current}
            disabled={current}
            size={size}
            data-cursor={cursor}
            onClick={handleClick}
          />
        ))}

        <PaginationItem
          size={size}
          selected={!next}
          disabled={!next}
          type="next"
          data-cursor={next?.cursor}
          onClick={handleClick}
        />
      </Box>
    )
  );
}
