import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { InfoBox } from "../../components/info/InfoBox";
import { GroupCard } from "../../components/groups/GroupCard";
import { ManageGroupDialogProps } from "../../components/groups/ManageGroupDialog";
import { Pagination } from "../../components/pagination/Pagination";
import { PaginationContext } from "../../utils/paging";

import { GroupList_groups$key } from "./__generated__/GroupList_groups.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  card: {
    marginBottom: theme.spacing(3),
  },

  none: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(15),
    },
  },
}));

const groupsFragment = graphql`
  fragment GroupList_groups on GroupConnection {
    totalCount
    edges {
      node {
        id
        ...GroupCard_group
      }
    }
  }
`;

export interface GroupListProps extends Omit<ContainerProps, "children"> {
  groupsRef: GroupList_groups$key;
  clientsRef: ManageGroupDialogProps["clientsRef"];
}

export function GroupList(props: GroupListProps) {
  const { className, groupsRef, clientsRef, ...other } = props;
  const s = useStyles();
  const groups = useFragment(groupsFragment, groupsRef);
  const { first } = React.useContext(PaginationContext);

  return (
    <Container className={clsx(s.root, className)} maxWidth="lg" {...other}>
      {groups.totalCount ? (
        <>
          {groups.edges.map(({ node }) => (
            <GroupCard
              className={s.card}
              key={node.id}
              groupRef={node}
              clientsRef={clientsRef}
            />
          ))}
          <Pagination totalCount={groups.totalCount} perPage={first} />
        </>
      ) : (
        <InfoBox
          className={s.none}
          title="No groups"
          subtitle="You haven’t created any client groups, yet."
        />
      )}
    </Container>
  );
}
