import { capitalize } from "lodash";
import { EnrollmentStatus, NavigationItem } from "../../../../constants";
import {
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  CLIENT_PROGRAMS_PAST_ROUTE,
  CLIENT_PROGRAMS_UPCOMING_ROUTE,
} from "../../../routes";

export const programsBreadcrumb: NavigationItem = {
  name: "Programs",
  link: CLIENT_PROGRAMS_ACTIVE_ROUTE,
};

export function getName(status: EnrollmentStatus, count?: number): string {
  const name = capitalize(status);
  return count !== undefined ? `${name} (${count})` : name;
}

export const programsNavigation = (
  activeCount?: number,
  upcomingCount?: number,
  pastCount?: number,
): NavigationItem[] => [
  {
    name: getName(EnrollmentStatus.ACTIVE, activeCount),
    link: CLIENT_PROGRAMS_ACTIVE_ROUTE,
  },
  {
    name: getName(EnrollmentStatus.UPCOMING, upcomingCount),
    link: CLIENT_PROGRAMS_UPCOMING_ROUTE,
  },
  {
    name: getName(EnrollmentStatus.PAST, pastCount),
    link: CLIENT_PROGRAMS_PAST_ROUTE,
  },
];
