import clsx from "clsx";
import React, { ComponentProps } from "react";
import {
  Drawer,
  DrawerProps,
  Button,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { polyfillCSS } from "../../utils/css";

import { ActivityFeedbackList } from "./ActivityFeedbackList";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    paddingTop: polyfillCSS(
      `max(${theme.spacing(1.5)}, var(--safe-area-inset-top))`,
    ),
    paddingBottom: polyfillCSS(
      `max(${theme.spacing(1.5)}, var(--safe-area-inset-bottom))`,
    ),
    paddingLeft: polyfillCSS(
      `max(${theme.spacing(2)}, var(--safe-area-inset-left))`,
    ),
    paddingRight: polyfillCSS(
      `max(${theme.spacing(2)}, var(--safe-area-inset-right))`,
    ),

    width: "100%",

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0, 0),
    },

    [theme.breakpoints.up("sm")]: {
      paddingTop: polyfillCSS(
        `max(${theme.spacing(3)}, var(--safe-area-inset-top))`,
      ),
      paddingBottom: polyfillCSS(
        `max(${theme.spacing(3)}, var(--safe-area-inset-bottom))`,
      ),
      paddingLeft: polyfillCSS(
        `max(${theme.spacing(4)}, var(--safe-area-inset-left))`,
      ),
      paddingRight: polyfillCSS(
        `max(${theme.spacing(4)}, var(--safe-area-inset-right))`,
      ),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    marginLeft: theme.spacing(-1.5),
    "& svg": {
      width: 24,
      height: 24,
      marginRight: theme.spacing(0.5),
    },
  },
}));

export interface ActivityFeedbackDrawerProps extends DrawerProps {
  activity: ComponentProps<typeof ActivityFeedbackList>["activity"];
  anchorEl?: any;
}

export function ActivityFeedbackDrawer(props: ActivityFeedbackDrawerProps) {
  const { className, onClose, anchorEl, activity, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      anchor="right"
      variant="temporary"
      onClose={(event) => onClose(event, "backdropClick")}
      sx={{
        ".MuiBackdrop-root": { background: "unset" },
      }}
      {...other}
    >
      <Box>
        <Button
          variant="text"
          color="primary"
          className={s.button}
          onClick={handleClose}
        >
          <CloseIcon /> Close
        </Button>
      </Box>

      <Typography variant="h2">Feedback</Typography>
      <ActivityFeedbackList activity={activity} />
    </Drawer>
  );
}
