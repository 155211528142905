/**
 * @generated SignedSource<<1b665438b1ca60b50be2814eb2156e1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramsProgramRouteQuery$variables = {
  program: string;
  week: number;
};
export type ClientProgramsProgramRouteQuery$data = {
  readonly enrollment: {
    readonly program: {
      readonly description: string | null | undefined;
      readonly imageURL: string | null | undefined;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"RefreshSlug">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientProgram_enrollment">;
  } | null | undefined;
};
export type ClientProgramsProgramRouteQuery = {
  response: ClientProgramsProgramRouteQuery$data;
  variables: ClientProgramsProgramRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "program"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "week"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "client",
    "value": "$me"
  },
  {
    "kind": "Variable",
    "name": "program",
    "variableName": "program"
  }
],
v2 = {
  "kind": "Variable",
  "name": "week",
  "variableName": "week"
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageURL",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "raw",
    "value": true
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "week",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProgramsProgramRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Enrollment",
        "kind": "LinkedField",
        "name": "enrollment",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ClientProgram_enrollment"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RefreshSlug"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientProgramsProgramRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Enrollment",
        "kind": "LinkedField",
        "name": "enrollment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": "startDate(raw:true)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentWeek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "completion",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "period",
                "value": "ALL_TIME"
              },
              (v2/*: any*/)
            ],
            "concreteType": "Completion",
            "kind": "LinkedField",
            "name": "completion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activities",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(raw:true)"
              },
              (v9/*: any*/),
              {
                "alias": "formattedDate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "MMM DD, YYYY"
                  }
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(format:\"MMM DD, YYYY\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submitted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "component",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "days",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "teaser",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Program",
                    "kind": "LinkedField",
                    "name": "program",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iconName",
                        "storageKey": null
                      }
                    ],
                    "type": "ComponentIcon",
                    "abstractKey": "__isComponentIcon"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "length",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "after",
                    "value": ""
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 9999
                  }
                ],
                "concreteType": "WeeksConnection",
                "kind": "LinkedField",
                "name": "weeks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WeeksEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Week",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "positions",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "weeks(after:\"\",first:9999)"
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slugId",
                    "storageKey": null
                  }
                ],
                "type": "Slug",
                "abstractKey": "__isSlug"
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f20a58b7a30a8204593f8553812b193a",
    "id": null,
    "metadata": {},
    "name": "ClientProgramsProgramRouteQuery",
    "operationKind": "query",
    "text": "query ClientProgramsProgramRouteQuery(\n  $program: String!\n  $week: Int!\n) {\n  enrollment(client: \"$me\", program: $program) {\n    ...ClientProgram_enrollment_2XYUdJ\n    program {\n      ...RefreshSlug\n      name\n      description\n      imageURL\n      id\n    }\n    id\n  }\n}\n\nfragment ActivityByTypesList_activities on Activity {\n  ...ActivityList_activities_394vM\n  component {\n    type\n    id\n  }\n}\n\nfragment ActivityCard_activity_394vM on Activity {\n  id\n  date(raw: true)\n  formattedDate: date(format: \"MMM DD, YYYY\")\n  completed\n  submitted\n  component {\n    ...ComponentIcon_component\n    type\n    title\n    slug\n    days\n    locked\n    image\n    teaser\n    program {\n      slug\n      id\n    }\n    id\n  }\n}\n\nfragment ActivityList_activities_394vM on Activity {\n  ...ActivityCard_activity_394vM\n  id\n  week\n  date(raw: true)\n  completed\n  component {\n    id\n    type\n  }\n}\n\nfragment ClientProgramWeek_activities on Activity {\n  week\n  date(raw: true)\n  ...ActivityByTypesList_activities\n}\n\nfragment ClientProgramWeek_completion on Completion {\n  rate\n}\n\nfragment ClientProgram_enrollment_2XYUdJ on Enrollment {\n  startDate(raw: true)\n  currentWeek\n  completion(week: $week, completion: true, period: ALL_TIME) {\n    ...ClientProgramWeek_completion\n  }\n  activities(week: $week) {\n    ...ClientProgramWeek_activities\n    id\n  }\n  program {\n    id\n    slug\n    name\n    length\n    weeks(first: 9999, after: \"\") {\n      edges {\n        node {\n          id\n          week\n          positions\n          description\n        }\n      }\n    }\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n"
  }
};
})();

(node as any).hash = "8b5da46a1280ec825b2a86f7b5cae96f";

export default node;
