import React from "react";
import { ComponentStatus } from "../constants";
import { ClipboardPenLine } from "lucide-react";
import { Trash } from "lucide-react";
import { Lock } from "lucide-react";
import { LockOpen } from "lucide-react";
import { Eye } from "lucide-react";
import { EyeOff } from "lucide-react";

export const getStatusIcon = (status: ComponentStatus) => {
  switch (status) {
    case ComponentStatus.ARCHIVED:
      return <Trash />;
    case ComponentStatus.DRAFT:
      return <ClipboardPenLine />;
    case ComponentStatus.PUBLISHED:
      return <></>;
    default:
      return <></>;
  }
};

export const getLockIcon = (locked: boolean) => {
  return locked ? <Lock /> : <LockOpen />;
};

export const getEyeIcon = (on: boolean) => {
  return on ? <Eye /> : <EyeOff />;
};
