/**
 * @generated SignedSource<<0344c149c4ce3b2cb054df9aef03463c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NutritionCommentsType = "CALORIES_ONLY" | "FULL_MACROS" | "PROTEIN_CALORIES" | "PROTEIN_ONLY" | "TRACKING_ONLY" | "%future added value";
export type NutritionTargetType = "ALL_DAYS" | "OFF_DAYS" | "REFEED_DAYS" | "TRAINING_DAYS" | "WEEKDAYS" | "WEEKENDS" | "%future added value";
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientNutritionCard_nutritionTarget$data = ReadonlyArray<{
  readonly calories: number | null | undefined;
  readonly carbsGrams: number | null | undefined;
  readonly commentType: NutritionCommentsType;
  readonly date: string | null | undefined;
  readonly fatGrams: number | null | undefined;
  readonly id: string;
  readonly proteinGrams: number | null | undefined;
  readonly targetType: NutritionTargetType;
  readonly trackingType: NutritionTrackingType;
  readonly updatedAt: string | null | undefined;
  readonly " $fragmentType": "ClientNutritionCard_nutritionTarget";
}>;
export type ClientNutritionCard_nutritionTarget$key = ReadonlyArray<{
  readonly " $data"?: ClientNutritionCard_nutritionTarget$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientNutritionCard_nutritionTarget">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientNutritionCard_nutritionTarget",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carbsGrams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proteinGrams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fatGrams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM DD, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": "updatedAt(format:\"MMM DD, YYYY\")"
    }
  ],
  "type": "ClientNutritionTarget",
  "abstractKey": null
};

(node as any).hash = "b618d9ec044a8050d722bd8133769996";

export default node;
