import clsx from "clsx";
import React from "react";
import { Select, SelectProps, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useQueryParam } from "../../hooks/useQueryParam";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: "transparent !important",
    marginLeft: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
  },

  option: {
    minWidth: theme.spacing(28),
    borderRadius: theme.spacing(0.25),
    margin: theme.spacing(0.5, 1),
    padding: theme.spacing(0.75, 2.75),
    fontWeight: 500,

    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },

  selected: {
    backgroundColor: theme.palette.selected.main,
  },
}));

const selectMenuProps: SelectProps["MenuProps"] = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

export interface FilterConfigOption {
  name: string;
  label: string;
}

export interface FilterConfig {
  name: string;
  defaultValue: string;
  options: FilterConfigOption[];
}

export type NavigationFilterProps = SelectProps & {
  filter: FilterConfig;
};

export function NavigationFilter(props: NavigationFilterProps) {
  const { className, filter, ...other } = props;
  const s = useStyles();
  const { name, defaultValue, options } = filter;
  const [value, setValue] = useQueryParam(name, defaultValue);

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value as string;

      setValue(newValue);
    },
    [setValue],
  );

  return (
    <Select
      className={clsx(s.root, className)}
      {...other}
      key={name}
      name={name}
      value={value}
      disableUnderline
      MenuProps={selectMenuProps}
      onChange={handleChange}
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
      }}
    >
      {options.map(({ name, label }) => (
        <MenuItem
          key={name}
          value={name}
          className={clsx(s.option, name === value && s.selected)}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}
