import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import SpeedDial, { SpeedDialProps } from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import { useCurrentWorkspace } from "../../hooks/useCurrentWorkspace";
import { useDebounce } from "../../hooks/useDebounce";
import { useCurrentUser, useUserIsClient } from "../../hooks/useCurrentUser";
import { useQueryParam } from "../../hooks/useQueryParam";
import { ReactComponent as SpeedDialIcon } from "../../icons/AddThickOutline.svg";
import { ReactComponent as MealLoggingIcon } from "../../icons/FruitApple.svg";
import { ReactComponent as DailyTrackingIcon } from "../../icons/CalendarEdit.svg";
import { polyfillCSS } from "../../utils/css";
import { getISODate } from "../../utils/date";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CLIENT_MEAL_LOGGING_ROUTE, HOME_ROUTE } from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: polyfillCSS(
        `calc(${theme.spacing(2)} + var(--safe-area-inset-bottom))`,
      ),
      right: polyfillCSS(
        `calc(${theme.spacing(2)} + var(--safe-area-inset-right))`,
      ),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: polyfillCSS(
        `calc(${theme.spacing(2)} + var(--safe-area-inset-top))`,
      ),
      left: polyfillCSS(
        `calc(${theme.spacing(2)} + var(--safe-area-inset-left))`,
      ),
    },

    "& svg": {
      transition: theme.transitions.create(["transform"]),
    },

    "& [aria-expanded=true] svg": {
      transform: "rotate(45deg)",
    },
  },

  icon: {
    color: theme.palette.text.primary,
  },
}));

export interface AppSpeedDialProps
  extends Omit<SpeedDialProps, "open" | "ariaLabel"> {}

export function AppSpeedDial(props: AppSpeedDialProps) {
  const { className, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const location = useLocation();
  const { date } = useParams();
  const isClient = useUserIsClient();
  const workspace = useCurrentWorkspace();
  const [open, setOpen] = React.useState(false);
  const actions = [];

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const mealLoggingAction = React.useCallback(() => {
    navigate(CLIENT_MEAL_LOGGING_ROUTE.replace(":date", date || getISODate()));
  }, [date]);

  if (isClient) {
    if (workspace.mealLogging && location.pathname.includes(HOME_ROUTE)) {
      actions.push({
        icon: <MealLoggingIcon className={s.icon} />,
        name: "Meal Logging",
        onClick: mealLoggingAction,
      });
    }
  }

  const hidden = useDebounce(actions.length === 0, 25);

  return (
    <SpeedDial
      className={clsx(s.root, className)}
      onClose={handleClose}
      onOpen={handleOpen}
      icon={<SpeedDialIcon />}
      open={open}
      hidden={hidden}
      ariaLabel="Quick menu"
      direction="up"
      {...other}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  );
}
