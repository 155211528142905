export enum WEEK_DRAG_ITEM_TYPE {
  COMPONENT = "component",
  RESIZE_LEFT = "resize_left",
  RESIZE_RIGHT = "resize_right",
}

export interface WeekDragUpdate {
  type: WEEK_DRAG_ITEM_TYPE;
  componentId: string;
  originWeek: number;
  originDay: number;
  week: number;
  day: number;
}

export interface DragItem {
  componentId: string;
  week: number;
  day: number;
}

export interface DragItemTyped extends DragItem {
  type: WEEK_DRAG_ITEM_TYPE;
}
