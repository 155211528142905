import clsx from "clsx";
import React from "react";
import { Add } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { COACH_PROGRAM_CREATE_ROUTE } from "../../../routes/routes";

import { Link } from "../../link/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: "nowrap",
    padding: theme.spacing(0.75, 5.5),
  },
}));

export function CreateButton(props: ButtonProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Button
      className={clsx(s.root, className)}
      underlineNone
      variant="contained"
      startIcon={<Add />}
      component={Link}
      href={COACH_PROGRAM_CREATE_ROUTE}
      children="Create a program"
      {...other}
    />
  );
}
