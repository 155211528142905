import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { PreCoachRegistrationMutation } from "./__generated__/PreCoachRegistrationMutation.graphql";

const mutation = graphql`
  mutation PreCoachRegistrationMutation($input: PreCoachRegistrationInput!) {
    preCoachRegistration(input: $input) {
      client_secret
    }
  }
`;

export const usePreCoachRegistrationMutation = () => {
  return useConfiguredMutation<PreCoachRegistrationMutation>(mutation, () => ({
    updater: (store) => {
      (store as any).invalidateStore();
    },
  }));
};
