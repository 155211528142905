/**
 * @generated SignedSource<<2e30dca08120581520b3c5c85f6e20b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type UpsertComponentInput = {
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  days?: ReadonlyArray<boolean> | null | undefined;
  draft?: string | null | undefined;
  duration?: number | null | undefined;
  habitPrompt?: string | null | undefined;
  iconName?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  locked?: boolean | null | undefined;
  messageContent?: string | null | undefined;
  messageTime?: string | null | undefined;
  reminderTime?: string | null | undefined;
  reminderType?: ReminderType | null | undefined;
  repeat?: ComponentRepeat | null | undefined;
  status?: ComponentStatus | null | undefined;
  title?: string | null | undefined;
  type?: ComponentType | null | undefined;
  weekId?: string | null | undefined;
};
export type SidebarScheduleTabSaveMutation$variables = {
  input: UpsertComponentInput;
};
export type SidebarScheduleTabSaveMutation$data = {
  readonly upsertComponent: {
    readonly component: {
      readonly days: ReadonlyArray<boolean>;
      readonly duration: number;
      readonly id: string;
      readonly messageTime: string | null | undefined;
      readonly reminderTime: string | null | undefined;
      readonly reminderType: ReminderType | null | undefined;
      readonly repeat: ComponentRepeat;
      readonly updatedAt: string | null | undefined;
      readonly weekId: string;
      readonly " $fragmentSpreads": FragmentRefs<"RefreshSlug">;
    };
  } | null | undefined;
};
export type SidebarScheduleTabSaveMutation = {
  response: SidebarScheduleTabSaveMutation$data;
  variables: SidebarScheduleTabSaveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "days",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "repeat",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": true
  }
],
v8 = {
  "alias": null,
  "args": (v7/*: any*/),
  "kind": "ScalarField",
  "name": "reminderType",
  "storageKey": "reminderType(draft:true)"
},
v9 = {
  "alias": null,
  "args": (v7/*: any*/),
  "kind": "ScalarField",
  "name": "reminderTime",
  "storageKey": "reminderTime(draft:true)"
},
v10 = {
  "alias": null,
  "args": (v7/*: any*/),
  "kind": "ScalarField",
  "name": "messageTime",
  "storageKey": "messageTime(draft:true)"
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "utc",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": "updatedAt(utc:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SidebarScheduleTabSaveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RefreshSlug"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SidebarScheduleTabSaveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slugId",
                    "storageKey": null
                  }
                ],
                "type": "Slug",
                "abstractKey": "__isSlug"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b5b59df1f4848c9752770e4c1413f5b",
    "id": null,
    "metadata": {},
    "name": "SidebarScheduleTabSaveMutation",
    "operationKind": "mutation",
    "text": "mutation SidebarScheduleTabSaveMutation(\n  $input: UpsertComponentInput!\n) {\n  upsertComponent(input: $input) {\n    component {\n      ...RefreshSlug\n      id\n      weekId\n      days\n      duration\n      repeat\n      reminderType(draft: true)\n      reminderTime(draft: true)\n      messageTime(draft: true)\n      updatedAt(utc: true)\n    }\n  }\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n"
  }
};
})();

(node as any).hash = "853490ffc88ada5e74c0642c164ce252";

export default node;
