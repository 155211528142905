/**
 * @generated SignedSource<<685c835895ede13b50592c9e8b874082>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentRepeat = "BIWEEKLY" | "EVERY_3_WEEKS" | "EVERY_4_WEEKS" | "NONE" | "WEEKLY" | "%future added value";
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type ReminderType = "AUTOMATIC" | "CUSTOM" | "%future added value";
export type UpsertComponentInput = {
  clientMutationId?: string | null | undefined;
  content?: string | null | undefined;
  days?: ReadonlyArray<boolean> | null | undefined;
  draft?: string | null | undefined;
  duration?: number | null | undefined;
  habitPrompt?: string | null | undefined;
  iconName?: string | null | undefined;
  id?: string | null | undefined;
  image?: string | null | undefined;
  locked?: boolean | null | undefined;
  messageContent?: string | null | undefined;
  messageTime?: string | null | undefined;
  reminderTime?: string | null | undefined;
  reminderType?: ReminderType | null | undefined;
  repeat?: ComponentRepeat | null | undefined;
  status?: ComponentStatus | null | undefined;
  title?: string | null | undefined;
  type?: ComponentType | null | undefined;
  weekId?: string | null | undefined;
};
export type EditorMenuUpdateComponentMutation$variables = {
  input: UpsertComponentInput;
};
export type EditorMenuUpdateComponentMutation$data = {
  readonly upsertComponent: {
    readonly component: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EditorMenu_component">;
    };
  } | null | undefined;
};
export type EditorMenuUpdateComponentMutation = {
  response: EditorMenuUpdateComponentMutation$data;
  variables: EditorMenuUpdateComponentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "draft",
    "value": false
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorMenuUpdateComponentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditorMenu_component"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorMenuUpdateComponentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertComponentPayload",
        "kind": "LinkedField",
        "name": "upsertComponent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "title",
                "storageKey": "title(draft:false)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "content",
                "storageKey": "content(draft:false)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "habitPrompt",
                "storageKey": "habitPrompt(draft:false)"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "image",
                "storageKey": "image(draft:false)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "teaser",
                "storageKey": "teaser(draft:false)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageURL",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconName",
                    "storageKey": null
                  }
                ],
                "type": "ComponentIcon",
                "abstractKey": "__isComponentIcon"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c91dd64a07b6811876524dedb64875d",
    "id": null,
    "metadata": {},
    "name": "EditorMenuUpdateComponentMutation",
    "operationKind": "mutation",
    "text": "mutation EditorMenuUpdateComponentMutation(\n  $input: UpsertComponentInput!\n) {\n  upsertComponent(input: $input) {\n    component {\n      id\n      ...EditorMenu_component\n    }\n  }\n}\n\nfragment ChangeComponentCoverDialog_component_30vxcK on Component {\n  content(draft: false)\n  teaser(draft: false)\n  image(draft: false)\n  ...ComponentPreview_component_30vxcK\n  program {\n    imageURL\n    id\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment ComponentPreview_component_30vxcK on Component {\n  title(draft: false)\n  teaser(draft: false)\n  image(draft: false)\n  ...ComponentIcon_component\n}\n\nfragment EditorMenu_component on Component {\n  id\n  type\n  title(draft: false)\n  content(draft: false)\n  habitPrompt(draft: false)\n  image(draft: false)\n  locked\n  ...ChangeComponentCoverDialog_component_30vxcK\n}\n"
  }
};
})();

(node as any).hash = "b8b104ee8c075358caf3315e1bfcdc31";

export default node;
