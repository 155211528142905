import clsx from "clsx";
import React from "react";
import { Card, CardProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import { Line } from "react-chartjs-2";
import { sortBy } from "lodash";

import { addDays } from "../../utils/date";
import {
  MeasurementTypeInfo,
  Units,
  convertUnits,
  getDefaultUnit,
} from "../../constants";

import { ClientMeasurementChart_metrics$key } from "./__generated__/ClientMeasurementChart_metrics.graphql";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3.5),
  },

  title: {
    fontSize: 24,
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
  },
}));

const metricsFragment = graphql`
  fragment ClientMeasurementChart_metrics on ClientMetric @relay(plural: true) {
    id
    x: activityDate(format: "YYYY-MM-DD")
    value {
      ... on CheckinAnswerMeasurementValue {
        measurement
        unit
      }
    }
  }
`;

export interface ClientMeasurementChartProps extends Omit<CardProps, "title"> {
  title: React.ReactNode;
  metrics: ClientMeasurementChart_metrics$key;
  measurementType: MeasurementTypeInfo;
  units: Units;
}

export function ClientMeasurementChart(props: ClientMeasurementChartProps) {
  const {
    className,
    metrics: metricsRef,
    title,
    measurementType,
    units,
    ...other
  } = props;
  const metrics = useFragment(metricsFragment, metricsRef);
  const s = useStyles();
  const theme = useTheme();
  const borderColor = theme.palette.primary.main;
  const backgroundColor = theme.palette.common.white;

  const { unitType } = measurementType;
  const unit = getDefaultUnit(unitType, units);

  const data = React.useMemo(
    () =>
      sortBy(metrics, ({ x }) => x).map(({ x, value }) => ({
        x,
        y: convertUnits(
          unitType,
          value.unit as Units,
          unit.toLocaleUpperCase() as Units,
          value.measurement,
        ),
      })),
    [metrics, unit, unitType],
  );

  const ticks =
    data.length === 1
      ? {
          min: addDays(data[0].x, -1),
          max: addDays(data[0].x, 1),
        }
      : {};

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Typography variant="subtitle1" className={s.title}>
        {title}
      </Typography>
      <Line
        height={71.5}
        data={{
          datasets: [
            {
              data,
              borderColor,
              fill: false,
              pointRadius: 4,
              backgroundColor,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                unit: "day",
                displayFormats: {
                  day: "MMM dd",
                },
              },
            },
          },
        }}
      />
    </Card>
  );
}
