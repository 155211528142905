import clsx from "clsx";
import React from "react";
import {
  ButtonBase,
  ButtonBaseProps,
  MenuItem,
  ListItemText,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useDropzone } from "react-dropzone";

import { ReactComponent as ImageFileAddIcon } from "../../icons/ImageFileAdd.svg";
import { Menu } from "../menu/Menu";
import { useUploadFile } from "../../hooks/useUploadFile";
import { SOMETHING_WENT_WRONG, AssetType } from "../../constants";
import { ElementType } from "../editor/types/elements";
import { useEditorProgram } from "../new-editor/hooks";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";

import { ImageButton } from "./ImageButton";
import { MoreMenuButton } from "./MoreMenuButton";
import { getMimeTypes } from "../../utils/file";

const useStyles = makeStyles((theme) => ({
  root: {},

  empty: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.text.secondary,
  },

  disabled: {
    opacity: 0.5,
  },

  icon: {
    color: theme.palette.text.secondary,
  },

  menuPopover: {
    zIndex: "99999 !important" as any,
  },

  primaryText: {
    fontWeight: 500,
    fontSize: 14,
  },

  image: {
    position: "relative",

    "&:hover $more": {
      display: "block",
    },
  },

  menuOpen: {
    "& $more": {
      display: "block",
    },
  },

  more: {
    display: "none",
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

export interface CustomImageButtonProps
  extends Omit<ButtonBaseProps, "src" | "onSelect"> {
  src?: string;
  onSelect?: (value: string) => void;
  onRequestImage?: () => void;
}

export function CustomImageButton(props: CustomImageButtonProps) {
  const { className, onSelect, onRequestImage, src: selectedSrc } = props;
  const s = useStyles();
  const program = useEditorProgram();
  const [upload] = useUploadFile({
    id: program.id,
    getAssetType: () => AssetType.PROGRAM_IMAGE,
  });
  const onError = useGenericErrorHandler();
  const [src, setSrc] = React.useState(selectedSrc);
  const [uploading, setUploading] = React.useState(false);

  const menuState = usePopupState({
    variant: "popover",
    popupId: "custom-image-menu",
  });

  const onUpload = React.useCallback(
    async (files: File[]) => {
      menuState.close();
      setUploading(true);

      try {
        const { url } = await upload(files[0]);

        setSrc(url);
        if (onSelect) {
          onSelect(url);
        }
      } catch (e) {
        onError(SOMETHING_WENT_WRONG);
      }

      setUploading(false);
    },
    [menuState, onError, onSelect, upload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUpload,
    accept: getMimeTypes(ElementType.IMAGE),
  });

  React.useEffect(() => {
    if (selectedSrc) {
      setSrc(selectedSrc);
    }
  }, [selectedSrc]);

  const handleClick = React.useCallback(() => {
    onSelect(src);
  }, [onSelect, src]);

  const handleChangeImage = React.useCallback(() => {
    menuState.close();
    setSrc(null);

    if (onSelect) {
      onSelect(null);
    }
  }, [menuState, onSelect]);

  return (
    <>
      {src ? (
        <Box className={clsx(s.image, menuState.isOpen && s.menuOpen)}>
          <ImageButton
            className={className}
            selected={src === selectedSrc}
            src={src}
            onClick={handleClick}
          />
          <MoreMenuButton className={s.more} {...bindTrigger(menuState)} />
        </Box>
      ) : (
        <ButtonBase
          className={clsx(className, s.empty, uploading && s.disabled)}
          disabled={uploading}
          {...bindTrigger(menuState)}
        >
          <ImageFileAddIcon className={s.icon} />
        </ButtonBase>
      )}

      <Menu
        PopoverClasses={{
          root: s.menuPopover,
        }}
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {!src && (
          <MenuItem {...(getRootProps() as any)}>
            <input
              {...getInputProps()}
              accept="image/*"
              id="image-file"
              type="file"
            />
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="From computer"
            />
          </MenuItem>
        )}
        {!src && (
          <MenuItem onClick={onRequestImage}>
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Unsplash"
            />
          </MenuItem>
        )}
        {src && (
          <MenuItem onClick={handleChangeImage}>
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Change image"
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
