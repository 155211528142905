import React, { Suspense } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { CssBaseline } from "@mui/material";
import { NetworkBoundary } from "../../components/loading/NetworkBoundary";
import { RelayEnvironmentProvider } from "react-relay";
import { createRelay } from "../../relay";
import { SnackbarNotifications } from "../../../src/components/snackbar/SnackbarNotifications";
import { HistoryContext } from "../../hooks/useHistory";
import { createBrowserHistory } from "history";
import { LoadingPage } from "../loading/LoadingPage";
import AppRouter from "../../routes/router";
import { setupDeepLinks } from "../../utils/device";
import { PlateController } from "@udecode/plate-common";
import { AppMetadataProvider } from "../routes/AppMetadataProvider";

const App = () => {
  const environment = createRelay();
  const history = createBrowserHistory();

  setupDeepLinks(history);

  return (
    <HistoryContext.Provider value={history}>
      <ErrorBoundary>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DndProvider backend={HTML5Backend}>
            <CssBaseline />
            <NetworkBoundary>
              <SnackbarNotifications>
                <Suspense fallback={<LoadingPage />}>
                  <RelayEnvironmentProvider environment={environment}>
                    <AppMetadataProvider>
                      <PlateController>
                        <AppRouter />
                      </PlateController>
                    </AppMetadataProvider>
                  </RelayEnvironmentProvider>
                </Suspense>
              </SnackbarNotifications>
            </NetworkBoundary>
          </DndProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </HistoryContext.Provider>
  );
};

export default App;
