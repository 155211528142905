import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../button/MoreMenuButton";
import { NoteEditor } from "../new-editor/NoteEditor";
import { UserNoteMenu } from "../menu/UserNoteMenu";
import { UserNoteDialog } from "../user-notes/UserNoteDialog";
import { HeightOverflow } from "../container/HeightOverflow";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { SOMETHING_WENT_WRONG } from "../../constants";

import { UserNotesListItemRemoveUserNoteMutation } from "./__generated__/UserNotesListItemRemoveUserNoteMutation.graphql";
import { UserNotesListItem_note$key } from "./__generated__/UserNotesListItem_note.graphql";
import useDependantKey from "../../hooks/useDependantKey";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(4, 3),
    boxShadow: theme.shadows[2],
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textTransform: "lowercase",
  },

  moreButton: {
    float: "right",
  },
}));

const fragment = graphql`
  fragment UserNotesListItem_note on UserNote {
    ...UserNoteDialog_note
    id
    content
    createdAtDate: createdAt(format: "MMMM D, YYYY")
    createdAtTime: createdAt(format: "hh:mmA")

    user {
      id
    }
  }
`;

const removeUserNoteMutation = graphql`
  mutation UserNotesListItemRemoveUserNoteMutation(
    $input: RemoveUserNoteInput!
  ) {
    removeUserNote(input: $input) {
      removedUserNoteId
    }
  }
`;

export interface UserNotesListItemProps extends BoxProps {
  noteRef: UserNotesListItem_note$key;
}

export function UserNotesListItem(props: UserNotesListItemProps) {
  const { className, noteRef, ...other } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const note = useFragment(fragment, noteRef);
  const { createdAtTime, createdAtDate, content } = note;
  const value = React.useMemo(() => JSON.parse(content), [content]);
  const menuState = usePopupState({
    variant: "popover",
    popupId: "user-note-menu",
  });
  const [openDialog, setOpenDialog] = React.useState(false);
  const [removeUserNote] = useMutation<UserNotesListItemRemoveUserNoteMutation>(
    removeUserNoteMutation,
  );

  const handleEditClick = React.useCallback(() => {
    menuState.close();
    setOpenDialog(true);
  }, [menuState]);

  const handleDeleteClick = React.useCallback(() => {
    menuState.close();

    removeUserNote({
      variables: { input: { id: note.id } },
      optimisticResponse: {
        removeUserNote: {
          removedUserNoteId: note.id,
        },
      },
      configs: [
        {
          type: "RANGE_DELETE",
          parentID: note.user.id,
          connectionKeys: [
            {
              key: "CoachClientNotesScreen_notes",
            },
          ],
          pathToConnection: ["client", "notes"],
          deletedIDFieldName: "removedUserNoteId",
        },
      ],
      onCompleted: (_, errors) => {
        if (errors && errors.length) {
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Note removed",
          });
        }
      },
    });
  }, [menuState, note.id, note.user.id, removeUserNote, snackAlert]);

  const handleDialogClose = React.useCallback(() => {
    setOpenDialog(false);
  }, []);

  const noteEditorKey = useDependantKey(value);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <MoreMenuButton
        styleVariant="cardAction"
        className={s.moreButton}
        {...bindTrigger(menuState)}
      />
      <UserNoteMenu
        onEdit={handleEditClick}
        onDelete={handleDeleteClick}
        {...bindMenu(menuState)}
      />

      <Typography variant="h5" className={s.title}>
        {createdAtDate}
      </Typography>
      <Typography variant="subtitle1" className={s.subtitle} gutterBottom>
        {createdAtTime}
      </Typography>

      <HeightOverflow maxHeight={296} label="note">
        <NoteEditor key={noteEditorKey} value={value} readOnly />
      </HeightOverflow>
      {openDialog && (
        <UserNoteDialog
          open={openDialog}
          userId={note.user.id}
          noteRef={note}
          onClose={handleDialogClose}
        />
      )}
    </Box>
  );
}
