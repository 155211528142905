import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDragDropManager } from "react-dnd";

import { ExerciseLibrary } from "../exercise-library/ExerciseLibrary";

import { ComponentDrawerBackButton } from "./ComponentDrawerBackButton";
import { ComponentLibrary } from "./ComponentLibrary";
import { LibrarySection } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(3, 4),
    width: `${theme.spacing(60)} !important`,

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },
}));

export interface ComponentLibraryDrawerProps extends DrawerProps {
  anchorEl?: any;
}

export function ComponentLibraryDrawer(props: ComponentLibraryDrawerProps) {
  const { className, anchorEl, onClose, open, ...other } = props;
  const s = useStyles();
  const [section, setSection] = React.useState<LibrarySection>();
  const [isDragging, setIsDragging] = React.useState(false);
  const manager = useDragDropManager();
  const monitor = manager.getMonitor();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setIsDragging(monitor.isDragging());
    }, 100);

    return () => clearInterval(timer);
  }, [monitor]);

  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const handleBackButtonClick = React.useCallback(() => {
    setSection(null);
  }, []);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      anchor="right"
      variant="persistent"
      onClose={onClose}
      open={open && !isDragging}
      {...other}
    >
      {section !== LibrarySection.EXERCISE_LIBRARY && (
        <Box>
          <ComponentDrawerBackButton
            variant={section ? "back" : "close"}
            onClick={section ? handleBackButtonClick : handleClose}
          />
        </Box>
      )}

      {section === LibrarySection.EXERCISE_LIBRARY ? (
        <ExerciseLibrary onSectionChange={setSection} />
      ) : (
        <ComponentLibrary section={section} onSectionChange={setSection} />
      )}
    </Drawer>
  );
}
