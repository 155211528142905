import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  ClickAwayListener,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SketchPicker, ColorResult } from "react-color";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    height: theme.spacing(10),
    width: theme.spacing(10),
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  checked: {
    backgroundColor: theme.palette.selected.main,
  },

  preview: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    textAlign: "center",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  color: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: theme.spacing(1),
    borderRadius: "50%",
  },

  label: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
  },

  dynamic: {
    width: 313,

    "& $preview": {
      marginRight: theme.spacing(1.5),
    },
  },

  picker: {
    width: "250px !important",
    position: "absolute",
    top: theme.spacing(10),
  },

  disabled: {
    "& $preview": {
      cursor: "initial",
    },
  },
}));

export type KnownColors = "red" | "blue" | "green";

export interface ColorPickerProps extends Omit<BoxProps, "onSelect"> {
  value: KnownColors | string;
  onSelect?: (value: string) => void;
  label?: string;
  checked?: boolean;
  dynamic?: boolean;
  disabled?: boolean;
}

export function ColorPicker(props: ColorPickerProps) {
  const {
    className,
    value,
    label,
    checked,
    onSelect,
    dynamic,
    disabled,
    ...other
  } = props;
  const s = useStyles();
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState<string>(props.value);

  React.useEffect(() => {
    setColor(props.value);
  }, [props.value]);

  const handleClickAway = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = React.useCallback(() => {
    if (disabled) {
      return;
    }

    if (dynamic) {
      setOpen(true);
    }

    if (onSelect) {
      onSelect(value);
    }
  }, [disabled, dynamic, onSelect, value]);

  const handleColorChange = React.useCallback(
    (hex: string, complete: boolean) => {
      const value = hex.toLocaleUpperCase();

      setColor(value);

      if (complete && onSelect) {
        onSelect(value);
      }
    },
    [onSelect],
  );

  const handlePickerChange = React.useCallback(
    ({ hex }: ColorResult) => {
      handleColorChange(hex, false);
    },
    [handleColorChange],
  );

  const handlePickerChangeComplete = React.useCallback(
    ({ hex }: ColorResult) => {
      handleColorChange(hex, true);
    },
    [handleColorChange],
  );

  const handleHexChange: TextFieldProps["onChange"] = React.useCallback(
    ({ target: { value } }) => {
      handleColorChange(value, true);
    },
    [handleColorChange],
  );

  return (
    <Box
      className={clsx(s.root, className, {
        [s.checked]: checked,
        [s.dynamic]: dynamic,
        [s.disabled]: disabled,
      })}
      {...other}
    >
      <Box className={s.preview} onClick={handleClick}>
        <Box className={s.color} style={{ backgroundColor: color }} />
        <Typography variant="body2" className={s.label}>
          {label}
        </Typography>
      </Box>

      {dynamic && (
        <TextField
          variant="outlined"
          placeholder="Enter HEX code"
          value={color}
          onChange={handleHexChange}
          disabled={disabled}
        />
      )}
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={s.picker}>
            <SketchPicker
              color={color}
              onChange={handlePickerChange}
              onChangeComplete={handlePickerChangeComplete}
              disableAlpha
            />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
}
