import clsx from "clsx";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  alpha,
  Box,
  Button,
  darken,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { colorSystem } from "../../../theme";
import { usePopupState } from "material-ui-popup-state/hooks";
import {
  ExerciseSetNumberType,
  ISetNumberOptions,
  setSetNumberOptions,
} from "../../../constants";
import SetNumberTypeMarking from "./SetNumberTypeMarking";

import { Close as CloseIcon } from "@mui/icons-material";
import { CircleHelp } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "30px",
    width: "100%",
    fontSize: 16,
  },
  moreContent: {
    position: "relative",
  },
  moreOverlay: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "none",
    zIndex: 9,
  },
  moreOverlayVisible: {
    display: "block",
  },
  moreModal: {
    position: "absolute",
    backgroundColor: colorSystem.white,
    zIndex: 1000,
    width: 170,
    left: theme.spacing(0.5),
    padding: theme.spacing(1, 0),
    borderRadius: 4,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.05);",
  },
  modalItem: {
    padding: theme.spacing(1.2, 2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),

    "&:not($selected):hover": {
      cursor: "pointer",
      backgroundColor: darken(theme.palette.common.white, 0.02),
    },
  },
  modalItemLabel: {
    paddingLeft: theme.spacing(0.5),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.1,
  },
  modalItemInfo: {
    marginLeft: "auto",
    color: theme.palette.grey[500],
  },
  selected: {
    background: alpha(theme.palette.primary.main, 0.1),
  },
  disabled: {
    "&:hover": {
      cursor: "default",
      backgroundColor: "unset",
    },
  },
}));

type WorkoutSetNumberProps = {
  setNumber: number;
  type: ExerciseSetNumberType;
  onChange: (value: ExerciseSetNumberType) => void;
  disabled: boolean;
};

const WorkoutSetNumber = (props: WorkoutSetNumberProps) => {
  const { setNumber, type, onChange, disabled } = props;
  const s = useStyles();

  const [numberType, setNumberType] = useState(type);

  const dialogState = usePopupState({
    variant: "popover",
    popupId: "workout-exercise-set-type",
  });

  const handleToggleModal = React.useCallback(
    (e) => {
      e.stopPropagation();
      dialogState.toggle(e);
    },
    [dialogState],
  );

  const handleChange = (value: ExerciseSetNumberType) => {
    setNumberType(value);
    onChange(value);
    dialogState.close();
  };

  const [currentOption, setCurrentOption] = useState<ISetNumberOptions>();

  const [openInfoModal, setOpenInfoModal] = React.useState(false);
  const handleOpen = (e, content: ISetNumberOptions) => {
    e.stopPropagation();
    setCurrentOption(content);
    setOpenInfoModal(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpenInfoModal(false);
  };

  return (
    <>
      <div
        className={clsx(
          s.moreOverlay,
          dialogState.isOpen && s.moreOverlayVisible,
        )}
        onClick={(e) => {
          e.stopPropagation();
          dialogState.close();
        }}
        style={{ maxWidth: "unset" }}
      />
      <div className={s.moreContent}>
        <Button
          onClick={handleToggleModal}
          className={s.button}
          disabled={disabled}
        >
          <SetNumberTypeMarking setNumber={setNumber} type={type} />
        </Button>
        {dialogState.isOpen && (
          <div className={s.moreModal}>
            {setSetNumberOptions.map((option) => (
              <div
                className={clsx(
                  s.modalItem,
                  numberType === option.value && s.selected,
                  disabled && s.disabled,
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  !disabled && handleChange(option.value);
                }}
              >
                <SetNumberTypeMarking
                  setNumber={setNumber}
                  type={option.value}
                />
                <Typography className={s.modalItemLabel}>
                  {option.label}
                </Typography>

                {option?.info && (
                  <Box
                    className={s.modalItemInfo}
                    onClick={(e) => handleOpen(e, option)}
                  >
                    <CircleHelp size={16} />
                  </Box>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <Dialog
        open={openInfoModal}
        onClose={handleClose}
        sx={{
          ".MuiDialog-paper": {
            maxWidth: 400,
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SetNumberTypeMarking
              setNumber={setNumber}
              type={currentOption?.value}
            />
            <Typography variant="h6">{currentOption?.label}</Typography>
          </Box>
          <IconButton
            size="small"
            children={<CloseIcon />}
            onClick={handleClose}
          />
        </DialogTitle>

        <DialogContent>
          <Typography>{currentOption?.info}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WorkoutSetNumber;
