import clsx from "clsx";
import React from "react";
import { BoxProps, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { Node } from "slate";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { UserRole } from "../../constants";
import { MessageEditor } from "../new-editor/MessageEditor";

import { FeedbackForm_activity$key } from "./__generated__/FeedbackForm_activity.graphql";
import { FeedbackFormSubmitMutation } from "./__generated__/FeedbackFormSubmitMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const submitMutation = graphql`
  mutation FeedbackFormSubmitMutation($input: UpsertActivityFeedbackInput!) {
    upsertActivityFeedback(input: $input) {
      activityFeedback {
        activityId
        ...ActivityResponseItem_feedback
        ...ActivityFeedback_activityFeedback
      }
    }
  }
`;

const activityFragment = graphql`
  fragment FeedbackForm_activity on Activity {
    id
    client {
      displayName
    }
  }
`;

export interface FeedbackFormProps extends BoxProps {
  activity: FeedbackForm_activity$key;
  formRef?: React.MutableRefObject<HTMLFormElement>;
}

export function FeedbackForm(props: FeedbackFormProps) {
  const s = useStyles();
  const theme = useTheme();
  const onError = useGenericErrorHandler();
  const { className, activity: activityRef, formRef, ...other } = props;
  const activity = useFragment(activityFragment, activityRef);
  const { displayName } = activity.client;

  const [submit, submitInFlight] =
    useMutation<FeedbackFormSubmitMutation>(submitMutation);
  const user = useCurrentUser();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const placeholder =
    user.role === UserRole.COACH
      ? `Send feedback${smUp ? ` to ${displayName}` : ""}`
      : "Send a comment to your coach";

  const submitUpdater = React.useCallback((store) => {
    const mutationRoot = store.getRootField("upsertActivityFeedback");

    if (mutationRoot) {
      const activityFeedback = mutationRoot.getLinkedRecord("activityFeedback");
      const activity = store.get(
        activityFeedback.getValue("activityId") as string,
      );

      if (activity) {
        const feedbacks = activity.getLinkedRecords("feedbacks");
        activity.setLinkedRecords(
          [...feedbacks, activityFeedback],
          "feedbacks",
        );
      }
    }
  }, []);

  const handleMessageSubmit = React.useCallback(
    (fragment?: Node[], callback?: () => void) => {
      submit({
        variables: {
          input: {
            activityId: activity.id,
            content: JSON.stringify(fragment),
          },
        },
        onCompleted: (_, errors) => {
          if (errors?.length) {
            onError(errors[0]);
          } else {
            callback();
          }
        },
        onError,
        updater: submitUpdater,
      });
    },
    [submit, activity.id, submitUpdater, onError],
  );

  return (
    <MessageEditor
      className={clsx(s.root, className)}
      fullWidth
      backupKeyType="feedback"
      backupKeyId={activity.id}
      disabled={submitInFlight}
      onMessageSubmit={handleMessageSubmit}
      placeholder={placeholder}
    />
  );
}
