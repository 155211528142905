import clsx from "clsx";
import React from "react";
import { Container, ContainerProps, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFragment, graphql } from "react-relay/hooks";

import { CoachClientCalendar } from "../coach-client-calendar/CoachClientCalendar";
import {
  ProgramDetailsFilters,
  defaultFilters,
} from "../program/ProgramDetailsFilters";
import { MonthSelect } from "../fields/MonthSelect";
import { getISODate } from "../../utils/date";

import { CoachClientCalendarScreen_root$key } from "./__generated__/CoachClientCalendarScreen_root.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},

  filters: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(2.5, 0),

    "& > :nth-child(1)": {
      marginLeft: theme.spacing(-2),
    },
  },
}));

const fragment = graphql`
  fragment CoachClientCalendarScreen_root on Root
  @argumentDefinitions(username: { type: "String!" }) {
    enrollments(client: $username, first: 99, after: null)
      @connection(key: "CoachClientCalendar_enrollments", filters: []) {
      edges {
        cursor
      }
      ...CoachClientCalendar_enrollments
    }
  }
`;

export interface CoachClientCalendarScreenProps
  extends Omit<ContainerProps, "children"> {
  rootRef: CoachClientCalendarScreen_root$key;
}

export function CoachClientCalendarScreen(
  props: CoachClientCalendarScreenProps,
) {
  const { className, rootRef, ...other } = props;
  const s = useStyles();
  const [date, setDate] = React.useState(getISODate());
  const [filters, setFilters] = React.useState(defaultFilters);
  const { enrollments } = useFragment(fragment, rootRef);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.filters}>
        <MonthSelect onSelect={setDate} date={date} />
        <ProgramDetailsFilters
          filters={filters}
          onChange={setFilters}
          disabledArchived
        />
      </Box>
      <CoachClientCalendar
        enrollmentsRef={enrollments}
        date={date}
        filters={filters}
      />
    </Container>
  );
}
