import clsx from "clsx";
import React from "react";
import { Container, ContainerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";
import {} from "react-relay";

import { RefreshSlug } from "../routes/RefreshSlug";

import { GroupPostsList } from "./GroupPostsList";
import { GroupPostsSidebar } from "./GroupPostsSidebar";
import { GroupPostsScreen_rootRef$key } from "./__generated__/GroupPostsScreen_rootRef.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "space-evenly",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },

  posts: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      maxWidth: 730,
    },

    [theme.breakpoints.up("lg")]: {
      maxWidth: 960,
    },
  },

  sidebar: {
    flexShrink: 0,

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(2),
      width: 300,
    },

    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(10),
    },
  },
}));

const fragment = graphql`
  fragment GroupPostsScreen_rootRef on Root
  @argumentDefinitions(slug: { type: "String!" }) {
    group(slug: $slug) {
      ...RefreshSlug
      ...GroupPostsSidebar_group
      ...GroupPostsList_group
      membersCanNotSeeSidebar
    }
  }
`;

export interface GroupPostsScreenProps
  extends Omit<ContainerProps, "children"> {
  rootRef: GroupPostsScreen_rootRef$key;
}

export function GroupPostsScreen(props: GroupPostsScreenProps) {
  const { className, rootRef, ...other } = props;
  const s = useStyles();
  const { group } = useFragment(fragment, rootRef);
  const { membersCanNotSeeSidebar } = group;

  return (
    <Container maxWidth="xl" className={clsx(s.root, className)} {...other}>
      <RefreshSlug nodesRef={[group]} />
      <GroupPostsList className={s.posts} groupRef={group} />
      {!membersCanNotSeeSidebar && (
        <GroupPostsSidebar className={s.sidebar} groupRef={group} />
      )}
    </Container>
  );
}
