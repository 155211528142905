import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachProgramsFolderRouteQuery } from "./__generated__/CoachProgramsFolderRouteQuery.graphql";
import { CoachProgramsFoldersScreen } from "../../../../components/coach-programs/CoachProgramsFoldersScreen";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

export function CoachProgramsFolderRoute() {
  const props = useLazyLoadQuery<CoachProgramsFolderRouteQuery>(
    graphql`
      query CoachProgramsFolderRouteQuery {
        ...CoachProgramsFoldersScreen_root
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  return <CoachProgramsFoldersScreen rootRef={props} />;
}
