import { ReactComponent as TextAndMediaIcon } from "../../icons/ImageFileEdit.svg";
import { ReactComponent as CheckinQuestionsIcon } from "../../icons/CursorChoose.svg";
import { ReactComponent as ExerciseLibraryIcon } from "../../icons/FitnessDumbbell.svg";
import { ElementType } from "../editor/types/elements";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { ELEMENT_H1, ELEMENT_H2 } from "@udecode/plate-heading";
import { ELEMENT_CALLOUT } from "../new-editor/elements/Callout";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { Icons } from "../plate-ui/Icons/icons";
import { ReactComponent as AssetLibraryIcon } from "../../icons/PaginateFilterVideo.svg";
import { FILE } from "../new-editor/elements/FileUploader";
import { VIDEO } from "../new-editor/elements/Video";
import { AUDIO } from "../new-editor/elements/Audio";
import { ListType } from "../new-editor/utils/listElementsUtils";
import {
  ELEMENT_ACCORDION,
  ELEMENT_TABS,
} from "../new-editor/elements/tabs/Tabs";

export enum LibrarySection {
  TEXT_AND_MEDIA = "text_and_media",
  CHECKIN_QUESTIONS = "checkin_questions",
  ASSET_LIBRARY = "asset_library",
  EXERCISE_LIBRARY = "exercise_library",
}

export enum LibrarySubSection {
  TEXT = "text",
  LISTS = "lists",
  SWITCH = "switch",
  LAYOUT = "layout",
  MEDIA = "media",
  URL = "url",
  QUESTIONS = "questions",
  VOTES = "votes",
  RATINGS = "ratings",
  FEEDBACK = "feedback",
  TRAINING = "training",
}

export type LibrarySectionInfo = {
  name: string;
  description: string;
  icon: React.FC;
  subSections: LibrarySubSection[];
};

export const LibrarySections: { [key: string]: LibrarySectionInfo } = {
  [LibrarySection.TEXT_AND_MEDIA]: {
    name: "Text and Media",
    description: "List, video, image",
    icon: TextAndMediaIcon,
    subSections: [
      LibrarySubSection.TEXT,
      LibrarySubSection.LISTS,
      LibrarySubSection.SWITCH,
      LibrarySubSection.LAYOUT,
      LibrarySubSection.MEDIA,
      LibrarySubSection.URL,
      LibrarySubSection.TRAINING,
    ],
  },

  [LibrarySection.CHECKIN_QUESTIONS]: {
    name: "Check-in Questions",
    description: "Questions, measurements",
    icon: CheckinQuestionsIcon,
    subSections: [
      LibrarySubSection.QUESTIONS,
      LibrarySubSection.VOTES,
      LibrarySubSection.RATINGS,
      LibrarySubSection.FEEDBACK,
    ],
  },

  [LibrarySection.ASSET_LIBRARY]: {
    name: "Asset Library",
    description: "Stored images & video",
    icon: AssetLibraryIcon,
    subSections: [],
  },

  [LibrarySection.EXERCISE_LIBRARY]: {
    name: "Exercise Library",
    description: "Manage Stored exercises",
    icon: ExerciseLibraryIcon,
    subSections: [],
  },
};

export type LibrarySubSectionInfo = {
  name: string;
  types: string[];
};

export const LibrarySubSections: Record<
  LibrarySubSection,
  LibrarySubSectionInfo
> = {
  [LibrarySubSection.TEXT]: {
    name: "Text",
    types: [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2],
  },

  [LibrarySubSection.LISTS]: {
    name: "Lists",
    types: [ListType.TODO, ListType.UNORDERED, ListType.ORDERED],
  },

  [LibrarySubSection.SWITCH]: {
    name: "Switch",
    types: [ELEMENT_TABS, ELEMENT_ACCORDION],
  },

  [LibrarySubSection.LAYOUT]: {
    name: "Layout",
    types: [ELEMENT_TABLE, ELEMENT_CALLOUT],
  },

  [LibrarySubSection.MEDIA]: {
    name: "Media",
    types: ["image", VIDEO, AUDIO, FILE],
  },

  [LibrarySubSection.URL]: {
    name: "URL",
    types: ["embed_website", "preview_link"],
  },

  [LibrarySubSection.TRAINING]: {
    name: "Training",
    types: ["workout_section"],
  },

  [LibrarySubSection.QUESTIONS]: {
    name: "Questions",
    types: [
      ElementType.CHECKIN_ANSWER_SHORT_TEXT,
      ElementType.CHECKIN_ANSWER_LONG_TEXT,
      ElementType.CHECKIN_ANSWER_DATE,
      ElementType.CHECKIN_ANSWER_NUMBER,
      ElementType.CHECKIN_ANSWER_FILE,
    ],
  },

  [LibrarySubSection.VOTES]: {
    name: "Votes",
    types: [
      ElementType.CHECKIN_ANSWER_BINARY,
      ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE,
      ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX,
    ],
  },

  [LibrarySubSection.RATINGS]: {
    name: "Ratings",
    types: [
      ElementType.CHECKIN_ANSWER_RATING_SCALE,
      ElementType.CHECKIN_ANSWER_STAR_RATING,
      ElementType.CHECKIN_ANSWER_EMOJI,
    ],
  },

  [LibrarySubSection.FEEDBACK]: {
    name: "Feedback",
    types: [
      ElementType.CHECKIN_ANSWER_MEASUREMENT,
      ElementType.CHECKIN_ANSWER_PROGRESS_PHOTO,
      ElementType.CHECKIN_ANSWER_NUTRITION_TARGET,
    ],
  },
};

export enum LibraryAssetType {
  IMAGE = "IMAGE",
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
  FILE = "FILE",
}

export const defaultAssetTypes = [
  LibraryAssetType.IMAGE,
  LibraryAssetType.AUDIO,
  LibraryAssetType.VIDEO,
  LibraryAssetType.FILE,
];

interface ElementTypeConfigSidebar {
  text: string;
  description: string;
  icon: React.FC;
}

export const ElementTypesDescriptions: Record<
  string,
  ElementTypeConfigSidebar
> = {
  [ELEMENT_PARAGRAPH]: {
    text: "Text",
    description: "Add some normal text.",
    icon: Icons.type,
  },
  [ELEMENT_H1]: {
    text: "Heading 1",
    description: "Use for large section headings.",
    icon: Icons.h1,
  },
  [ELEMENT_H2]: {
    text: "Heading 2",
    description: "Use for medium section headings.",
    icon: Icons.h2,
  },
  [ELEMENT_CALLOUT]: {
    text: "Callout",
    description: "Make a message stand out.",
    icon: Icons.megaphone,
  },
  workout_section: {
    text: "Exercise Section",
    description: "Add exercises and supersets.",
    icon: Icons.activity,
  },

  [ELEMENT_TABLE]: {
    text: "Table",
    description: "Add a simple table.",
    icon: Icons.table,
  },

  preview_link: {
    text: "Web Bookmark",
    description: "Add a link with a rich preview.",
    icon: Icons.globe,
  },

  embed_website: {
    text: "Embed",
    description: "Add an external website.",
    icon: Icons.compass,
  },

  [ElementType.CHECKIN_ANSWER_BINARY]: {
    text: "Yes/No",
    description: "Offer two options to choose from.",
    icon: Icons.power,
  },
  [ElementType.CHECKIN_ANSWER_MEASUREMENT]: {
    text: "Measurement",
    description: "Collect body weight or other body stats.",
    icon: Icons.ruler,
  },
  [ElementType.CHECKIN_ANSWER_PROGRESS_PHOTO]: {
    text: "Progress Photos",
    description: "Ask for progress pictures.",
    icon: Icons.camera,
  },
  [ElementType.CHECKIN_ANSWER_RATING_SCALE]: {
    text: "Rating Scale",
    description: "Ask for a numerical rating.",
    icon: Icons.arrowDownWithNumbers,
  },
  [ElementType.CHECKIN_ANSWER_LONG_TEXT]: {
    text: "Long Text Answer",
    description: "Ask an open-ended question.",
    icon: Icons.bookText,
  },
  [ElementType.CHECKIN_ANSWER_SHORT_TEXT]: {
    text: "Short Text Answer",
    description: "Ask an open-ended question.",
    icon: Icons.bookMinus,
  },
  [ElementType.CHECKIN_ANSWER_NUMBER]: {
    text: "Number",
    description: "Ask for a numeric answer.",
    icon: Icons.hash,
  },
  [ElementType.CHECKIN_ANSWER_FILE]: {
    text: "File Upload",
    description: "Ask for any file type, like PDF or image upload.",
    icon: Icons.cloudUpload,
  },
  [ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE]: {
    text: "Multiple Choice",
    description: "Provide a set of options to choose from.",
    icon: Icons.circleDot,
  },
  [ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX]: {
    text: "Checkboxes",
    description:
      "Provide a set of options to choose more than one option from.",
    icon: Icons.checkBox,
  },
  [ElementType.CHECKIN_ANSWER_DATE]: {
    text: "Date",
    description: "Ask for a date answer.",
    icon: Icons.calendar,
  },
  [ElementType.CHECKIN_ANSWER_EMOJI]: {
    text: "Emoji",
    description: "Ask for a mood rating.",
    icon: Icons.emojiPlus,
  },
  [ElementType.CHECKIN_ANSWER_STAR_RATING]: {
    text: "Star Rating",
    description: "Ask for a star rating.",
    icon: Icons.star,
  },
  [ElementType.CHECKIN_ANSWER_NUTRITION_TARGET]: {
    text: "Nutrition target",
    description: "Add a nutrition target.",
    icon: Icons.food,
  },

  ["image"]: {
    text: "Image",
    description: "Upload or embed images.",
    icon: Icons.image,
  },
  [FILE]: {
    text: "File Attachment",
    description: "Attach a file for download.",
    icon: Icons.paperclip,
  },
  [AUDIO]: {
    text: "Audio",
    description: "Embed audio (Soundcloud, Spotify, etc...)",
    icon: Icons.volume,
  },
  [VIDEO]: {
    text: "Video",
    description: "Embed video (YouTube, Vimeo, etc...)",
    icon: Icons.video,
  },

  [ListType.UNORDERED]: {
    text: "Bulleted List",
    description: "Create a list using bullet points.",
    icon: Icons.ul,
  },
  [ListType.ORDERED]: {
    text: "Numbered List",
    description: "Create a list using numbers.",
    icon: Icons.ol,
  },
  [ListType.TODO]: {
    text: "Checklist",
    description: "Add a checklist to your lesson.",
    icon: Icons.listTodo,
  },
  [ELEMENT_TABS]: {
    text: "Tabs",
    description: "Separate content into tabs.",
    icon: Icons.tabs,
  },
  [ELEMENT_ACCORDION]: {
    text: "Accordion",
    description: "Easily show and hide content.",
    icon: Icons.accordion,
  },
} as const;
