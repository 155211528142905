/**
 * @generated SignedSource<<5775f5a9763f94abc809cb6fece89871>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientsFormsListItem_clientForm$data = {
  readonly createdAt: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly totalQuestions: number;
  readonly " $fragmentType": "CoachClientsFormsListItem_clientForm";
};
export type CoachClientsFormsListItem_clientForm$key = {
  readonly " $data"?: CoachClientsFormsListItem_clientForm$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsFormsListItem_clientForm">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientsFormsListItem_clientForm",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM D, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": "createdAt(format:\"MMM D, YYYY\")"
    }
  ],
  "type": "ClientForm",
  "abstractKey": null
};

(node as any).hash = "5ef4542b6ecf24466fd8cff5324b57d9";

export default node;
