/**
 * @generated SignedSource<<67e2325e4aabfcdbbc262ecfe0ad3c89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramWeek_activities$data = ReadonlyArray<{
  readonly date: string | null | undefined;
  readonly week: number;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityByTypesList_activities">;
  readonly " $fragmentType": "ClientProgramWeek_activities";
}>;
export type ClientProgramWeek_activities$key = ReadonlyArray<{
  readonly " $data"?: ClientProgramWeek_activities$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientProgramWeek_activities">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientProgramWeek_activities",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "week",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityByTypesList_activities"
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "a64c00c46d5d349824da198cca0c436c";

export default node;
