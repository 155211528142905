import clsx from "clsx";
import React from "react";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import pick from "lodash.pick";

import { Tabs } from "../../components/uploader/Tabs";
import { NotificationsSetting } from "../../constants";
import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../../components/dialog/FullScreenDialog";
import { ActionButton } from "../../components/button/ActionButton";
import { CheckboxField } from "../../components/checkbox/CheckboxField";
import {
  ClientSettingsForm,
  ClientSettings,
} from "../../components/form/ClientSettingsForm";

import { ClientSettingsDialogUpdateNotificationsSettingMutation } from "./__generated__/ClientSettingsDialogUpdateNotificationsSettingMutation.graphql";
import { ClientSettingsDialogCreateTagMutation } from "./__generated__/ClientSettingsDialogCreateTagMutation.graphql";
import { ClientSettingsDialogAssignTagMutation } from "./__generated__/ClientSettingsDialogAssignTagMutation.graphql";
import { ClientSettingsDialogDeleteTagMutation } from "./__generated__/ClientSettingsDialogDeleteTagMutation.graphql";
import { ClientSettingsDialogDeleteTagClientMutation } from "./__generated__/ClientSettingsDialogDeleteTagClientMutation.graphql";

import { ClientSettingsDialog_client$key } from "./__generated__/ClientSettingsDialog_client.graphql";

import { ClientSettingsDialogUpdateUserMutation } from "./__generated__/ClientSettingsDialogUpdateUserMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: 32,
  },
  tabs: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    margin: theme.spacing(4, 0),
  },
  notificationsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  notificationsTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },
  notificationsSubtitle: {
    fontSize: 16,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1.25),
  },
}));

graphql`
  fragment ClientSettingsDialog_notificationsSetting on NotificationsSetting {
    newMessageEmail
    newResponseEmail
    weeklyUpdate
    dailyUpdate
  }
`;

const updateUserMutation = graphql`
  mutation ClientSettingsDialogUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...ClientSettingsDialog_client
      }
    }
  }
`;

const updateNotificationsSettingMutation = graphql`
  mutation ClientSettingsDialogUpdateNotificationsSettingMutation(
    $input: UpdateNotificationsSettingInput!
  ) {
    updateNotificationsSetting(input: $input) {
      notificationsSetting {
        ...ClientSettingsDialog_notificationsSetting
      }
    }
  }
`;

const assignTagMutation = graphql`
  mutation ClientSettingsDialogAssignTagMutation(
    $input: AssignTagToClientInput!
  ) {
    assignTagToClient(input: $input) {
      user {
        tag {
          id
          title
        }
      }
    }
  }
`;

const createTagMutation = graphql`
  mutation ClientSettingsDialogCreateTagMutation($input: CreateTagInput!) {
    createTag(input: $input) {
      tag {
        title
        id
      }
    }
  }
`;

const deleteTagMutation = graphql`
  mutation ClientSettingsDialogDeleteTagMutation($input: deleteTagInput!) {
    deleteTag(input: $input) {
      deleted
    }
  }
`;

const deleteTagClientMutation = graphql`
  mutation ClientSettingsDialogDeleteTagClientMutation(
    $input: DeleteClientTagInput!
  ) {
    deleteClientTag(input: $input) {
      deleted
    }
  }
`;

function getNotificationLabel(key: string): string {
  switch (key) {
    case "newMessageEmail":
      return "New messages";
    case "newResponseEmail":
      return "Feedback on activities";
    case "weeklyUpdate":
      return "Weekly program updates";
    case "dailyUpdate":
      return "Daily program updates";
    default:
      throw new Error("Unknown notification setting");
  }
}

type TagsProps = {
  node: {
    title: string;
    id: string;
  };
};

const clientFragment = graphql`
  fragment ClientSettingsDialog_client on User {
    ...ClientSettingsForm_settings
    id
    rawDisplayName: displayName(raw: true)
    username
    tag {
      title
      id
    }
    notificationsSetting {
      ...ClientSettingsDialog_notificationsSetting
      newMessageEmail
      newResponseEmail
      weeklyUpdate
      dailyUpdate
    }
  }
`;

export interface ClientSettingsDialogProps extends FullScreenDialogProps {
  client: ClientSettingsDialog_client$key;
  tags?: TagsProps[];
}

export function ClientSettingsDialog(props: ClientSettingsDialogProps) {
  const {
    className,
    open,
    onClose,
    client: clientRef,
    tags: defaultTags,
    ...other
  } = props;
  const client = useFragment(clientFragment, clientRef);
  const s = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const { rawDisplayName: displayName, tag: defaultTag, id: clientId } = client;
  const [notificationsSetting, setNotificationsSetting] = React.useState<
    Partial<NotificationsSetting>
  >(client.notificationsSetting);

  const [updateUser, updatingUser] =
    useMutation<ClientSettingsDialogUpdateUserMutation>(updateUserMutation);

  const [updateNotificationsSetting, updatingNotificationsSetting] =
    useMutation<ClientSettingsDialogUpdateNotificationsSettingMutation>(
      updateNotificationsSettingMutation,
    );

  const [createTag, createTagInFlight] =
    useMutation<ClientSettingsDialogCreateTagMutation>(createTagMutation);

  const [assignTagToClient, assignTagToClientInFlight] =
    useMutation<ClientSettingsDialogAssignTagMutation>(assignTagMutation);

  const [deleteTag, deleteTagInFlight] =
    useMutation<ClientSettingsDialogDeleteTagMutation>(deleteTagMutation);

  const [deleteClientTag, deleteClientTagInFlight] =
    useMutation<ClientSettingsDialogDeleteTagClientMutation>(
      deleteTagClientMutation,
    );

  const notificationsSettingKeys = React.useMemo(
    () =>
      Object.keys(client.notificationsSetting).filter(
        (key) => typeof client.notificationsSetting[key] === "boolean",
      ),
    [client.notificationsSetting],
  );

  const [settings, setSettings] = React.useState<ClientSettings>();
  const [settingsDirty, setSettingsDirty] = React.useState(false);
  const [tag, setTag] = React.useState(defaultTag?.title || "");
  const [allTags, setAllTags] = React.useState(defaultTags);

  const dirty = React.useMemo(
    () =>
      settingsDirty ||
      notificationsSettingKeys.some(
        (key) => notificationsSetting[key] !== client.notificationsSetting[key],
      ),
    [
      settingsDirty,
      notificationsSettingKeys,
      notificationsSetting,
      client.notificationsSetting,
    ],
  );

  const disabled =
    updatingUser ||
    updatingNotificationsSetting ||
    createTagInFlight ||
    assignTagToClientInFlight ||
    deleteTagInFlight ||
    deleteClientTagInFlight;
  const tabs = ["Profile", "Notifications"];

  const handleTabChange = React.useCallback((event, index) => {
    setActiveTab(index);
  }, []);

  const handleNotificationSettingChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { name } = event.target;
      setNotificationsSetting((setting) => ({ ...setting, [name]: checked }));
    },
    [],
  );

  const handleSettingsChange = React.useCallback((settings: ClientSettings) => {
    setSettingsDirty(true);
    setSettings(settings);
  }, []);

  const handleTagChange = React.useCallback((tag: string) => {
    setSettingsDirty(true);
    setTag(tag);
  }, []);

  const handleCreateTag = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const tagIndex = allTags.findIndex(
        (item) => item?.node?.title.toUpperCase() === tag.toUpperCase(),
      );
      if (
        (defaultTag?.title.toUpperCase() !== tag.toUpperCase() && tag) ||
        (defaultTag?.title.toUpperCase() === tag.toUpperCase() &&
          tag &&
          tagIndex === -1)
      ) {
        if (tagIndex === -1) {
          createTag({
            variables: { input: { title: tag } },
            onCompleted(
              {
                createTag: {
                  tag: { id: tagId },
                },
              },
              errors,
            ) {
              if (errors) {
                console.error(errors);
              } else {
                assignTagToClient({
                  variables: { input: { tagId, clientId } },
                  onCompleted(_, errors) {
                    if (errors) {
                      console.error(errors);
                    }
                  },
                });
                if (onClose && !settings) {
                  onClose(event, "backdropClick");
                }
              }
            },
          });
        } else {
          const tagId = allTags[tagIndex].node.id;
          assignTagToClient({
            variables: { input: { tagId, clientId } },
            onCompleted(_, errors) {
              if (errors) {
                console.error(errors);
              } else {
                if (onClose && !settings) {
                  onClose(event, "backdropClick");
                }
              }
            },
          });
        }
      } else if (
        defaultTag?.title.toUpperCase() === tag.toUpperCase() &&
        tag &&
        tagIndex !== -1
      ) {
        if (onClose && !settings) {
          onClose(event, "backdropClick");
        }
      } else if (defaultTag?.title && !tag) {
        deleteClientTag({
          variables: { input: { clientId } },
          onCompleted(_, errors) {
            if (errors) {
              console.error(errors);
            } else {
              if (onClose && !settings) {
                onClose(event, "backdropClick");
              }
            }
          },
        });
      } else if (!defaultTag?.title && !tag) {
        if (onClose && !settings) {
          onClose(event, "backdropClick");
        }
      }
    },
    [
      createTag,
      allTags,
      tag,
      defaultTag,
      onClose,
      settings,
      assignTagToClient,
      clientId,
      deleteClientTag,
    ],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (activeTab === 0) {
        handleCreateTag(event).then(() => {
          if (settings) {
            const {
              email,
              username,
              rawDisplayName: displayName,
              ...otherSettings
            } = settings;

            const input = {
              id: client.id,
              displayName,
              ...otherSettings,
            };

            updateUser({
              variables: { input },
              onCompleted(data, errors) {
                if (errors) {
                  console.error(errors);
                } else {
                  setSettingsDirty(false);
                  if (onClose) {
                    onClose(event, "backdropClick");
                  }
                }
              },
            });
          }
        });
      } else {
        const input = {
          userId: client.id,
          ...pick(notificationsSetting, notificationsSettingKeys),
        };

        updateNotificationsSetting({
          variables: { input },
          onCompleted(data, errors) {
            if (errors) {
              console.error(errors);
            } else {
              if (onClose) {
                onClose(event, "backdropClick");
              }
            }
          },
        });
      }
    },
    [
      activeTab,
      settings,
      client.id,
      updateUser,
      notificationsSetting,
      notificationsSettingKeys,
      updateNotificationsSetting,
      onClose,
      handleCreateTag,
    ],
  );

  const handleDeleteTag = React.useCallback(
    (tagId, tagTitle) => {
      deleteTag({
        variables: { input: { tagId } },
        onCompleted(_, errors) {
          if (errors) {
            console.error(errors);
          } else {
            if (tagTitle === tag) {
              handleTagChange("");
            }
            setAllTags((prevState) =>
              prevState.filter((item) => item.node.id !== tagId),
            );
          }
        },
      });
    },
    [deleteTag, tag, handleTagChange],
  );

  return (
    <FullScreenDialog
      className={clsx(s.root, className)}
      open={open}
      onClose={onClose}
      showBackButton={false}
      showCloseButton
      {...other}
    >
      <Typography
        className={s.title}
        variant="h1"
        children={displayName ? `${displayName}'s Settings` : "Client Settings"}
      />
      <form onSubmit={handleSubmit}>
        <Tabs
          className={s.tabs}
          items={tabs}
          onChange={handleTabChange}
          index={activeTab}
        />
        {activeTab === 0 ? (
          <ClientSettingsForm
            settings={client}
            onChange={handleSettingsChange}
            disabled={disabled}
            variant="coach"
            onChangeTag={handleTagChange}
            tag={tag}
            allTags={allTags}
            onDeleteTag={handleDeleteTag}
          />
        ) : (
          <Box className={s.notificationsWrapper}>
            <Typography
              className={s.notificationsTitle}
              children="Email notifications"
            />
            <Typography
              className={s.notificationsSubtitle}
              children="Send notifications for:"
            />
            {notificationsSettingKeys.map((key) => (
              <CheckboxField
                key={key}
                name={key}
                label={getNotificationLabel(key)}
                checked={notificationsSetting[key]}
                onChange={handleNotificationSettingChange}
              />
            ))}
          </Box>
        )}

        <ActionButton
          className={s.submit}
          disabled={!dirty}
          size="large"
          type="submit"
          fullWidth
        >
          Update
        </ActionButton>
      </form>
    </FullScreenDialog>
  );
}
