import React from "react";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import { AuthTokenData } from "../utils/auth";

import { useAuthTokenClientSignUpMutation } from "./__generated__/useAuthTokenClientSignUpMutation.graphql";
import { AuthTokenSignInResponse } from "./useAuthTokenSignIn";
import firebase from "firebase";
import dayjs from "dayjs";

const mutation = graphql`
  mutation useAuthTokenClientSignUpMutation(
    $input: SignUpClientWithAuthTokenInput!
  ) {
    signUpClientWithAuthToken(input: $input) {
      idToken
    }
  }
`;

export type AuthTokenClientSignUpResponse = AuthTokenSignInResponse;

export const useAuthTokenClientSignUp = (
  code: string,
  callback?: (
    error: Error | null,
    response?: AuthTokenClientSignUpResponse,
  ) => void,
) => {
  const [signUpClientWithAuthToken, inFlight] =
    useMutation<useAuthTokenClientSignUpMutation>(mutation);
  const [loading, setLoading] = React.useState(false);
  const disabled = inFlight || loading;

  const signIn = React.useCallback(
    (data: AuthTokenData) =>
      signUpClientWithAuthToken({
        variables: {
          input: {
            code,
            token: data.id_token,
            timeZone: dayjs.tz.guess(),
          },
        },
        onCompleted: (response, errors) => {
          if (errors) {
            callback(errors[0] as any as Error);
          } else {
            setLoading(true);
            const customToken = response?.signUpClientWithAuthToken?.idToken;

            firebase
              .auth()
              .signInWithCustomToken(customToken)
              .then((credential) => credential.user.getIdToken())
              .then((fireBaseToken) => {
                setLoading(false);
                callback(null, {
                  fireBaseToken,
                  data,
                });
              })
              .catch((error) => {
                setLoading(false);
                callback(error);
              });
          }
        },
      }),
    [callback, code, signUpClientWithAuthToken],
  );

  return [signIn, disabled] as const;
};
