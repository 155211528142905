import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { grey } from "@mui/material/colors";

import { useNetworkStatus } from "../../hooks/useNetworkStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
    flexDirection: "column",

    "& h1": {
      fontWeight: 200,
      fontSize: "2rem",
      margin: theme.spacing(3, 0),

      [theme.breakpoints.up("md")]: {
        fontSize: "5rem",
        marginBottom: theme.spacing(5, 0),
      },
    },

    "& h2": {
      fontWeight: 400,
      color: grey[400],
      fontSize: "1rem",

      [theme.breakpoints.up("md")]: {
        fontSize: "2rem",
      },
    },
  },
  spinner: {
    color: grey[400],
  },
  logo: {
    margin: theme.spacing(5),
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
  },
}));

export interface OfflinePageProps extends BoxProps {}

export function OfflinePage(props: OfflinePageProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [, connecting] = useNetworkStatus();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <CircularProgress
        className={s.spinner}
        size={mdUp ? 64 : 24}
        thickness={2}
        color="primary"
        variant={connecting ? "indeterminate" : "determinate"}
      />
      <Typography variant="h1">Your computer is offline.</Typography>
      <Typography variant="h2">
        {connecting
          ? "Attempting to reconnect..."
          : "Please, check your internet connection"}
      </Typography>
    </Box>
  );
}
