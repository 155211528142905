import { Node } from "slate";
import { ELEMENT_TABS_ITEM } from "./TabsItem";
import { ELEMENT_ACCORDION } from "./Tabs";

export type TabsVariant = "tabs" | "accordion";

export function parseTabsListElement(element: any) {
  const tabs: any[] = [];
  let selected: any = null;

  for (const [childNode] of Node.children(element, [])) {
    const node = childNode as any;

    if (node.type === ELEMENT_TABS_ITEM) {
      tabs.push(node);

      if (node.active) {
        selected = node;
      }
    }
  }

  const variant: TabsVariant =
    element.type === ELEMENT_ACCORDION ? "accordion" : "tabs";

  return {
    tabs,
    selected,
    variant,
  };
}
