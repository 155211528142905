import clsx from "clsx";
import React from "react";
import { Box, ButtonProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const MAX_WIDTH = 460;

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: theme.spacing(5),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
    },
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(1),
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    textDecoration: "none !important",
    width: "100%",
    maxWidth: MAX_WIDTH,
  },
}));

export interface CreateFirstProgramFolderProps extends ButtonProps {}

export function CreateFirstProgramFolder(props: CreateFirstProgramFolderProps) {
  const { className, children, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)}>
      <Typography className={s.subtitle} variant="body1">
        Create your first program folder to group programs.
      </Typography>
      <Button className={s.button} variant="contained" fullWidth {...other}>
        + Create your first folder
        {children}
      </Button>
    </Box>
  );
}
