import clsx from "clsx";
import React from "react";
import { Card, CardProps, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    overflow: "visible",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: 0,
      borderRadius: 0,
      padding: theme.spacing(2.5, 2, 15, 2),
    },
  },

  header: {
    display: "none",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    flexDirection: "column",

    "& > :nth-child(1)": {
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      display: "flex",

      "& > :nth-child(1)": {
        marginBottom: "initial",
      },
    },
  },

  headerText: {
    fontSize: 24,
    fontWeight: 600,
  },

  subheaderText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  fixedButton: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2.5, 2, 1.5, 2),
    boxShadow: theme.shadows[7],
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  action: {
    "$header > &": {
      display: "none",
    },

    [theme.breakpoints.up("sm")]: {
      "$header > &": {
        display: "block",
      },
    },
  },

  mobileHeader: {
    display: "block",
    margin: theme.spacing(3, 2, 0, 2),

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export interface SettingsCardProps extends CardProps {
  header: React.ReactNode;
  subheader?: React.ReactNode;
  ButtonProps?: ActionButtonProps;
}

export function SettingsCard(props: SettingsCardProps) {
  const { className, children, header, subheader, ButtonProps, ...other } =
    props;
  const s = useStyles();

  return (
    <>
      <Box className={s.mobileHeader}>
        <Typography variant="h3" className={s.headerText}>
          {header}
        </Typography>
        {subheader && (
          <Typography variant="body1" className={s.subheaderText}>
            {subheader}
          </Typography>
        )}
      </Box>

      <Card className={clsx(s.root, className)} {...other}>
        <Box className={s.header}>
          <Box>
            <Typography variant="h3" className={s.headerText}>
              {header}
            </Typography>
            {subheader && (
              <Typography variant="body1" className={s.subheaderText}>
                {subheader}
              </Typography>
            )}
          </Box>
          <ActionButton
            className={s.action}
            size="large"
            children="Save changes"
            {...ButtonProps}
          />
        </Box>

        <Box>{children}</Box>

        <Box className={s.fixedButton}>
          <ActionButton
            className={s.action}
            size="large"
            children="Save changes"
            {...ButtonProps}
            fullWidth
          />
        </Box>
      </Card>
    </>
  );
}
