import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { SortableListProps, SortableList } from "../sortable-list/SortableList";

import { MealLoggingOption } from "./types";
import { MealLoggingQuestionOptionsListItemProps } from "./MealLoggingQuestionOptionsListItem";
import { MealLoggingQuestionMultipleChoiceListItem } from "./MealLoggingQuestionMultipleChoiceListItem";
import { MealLoggingQuestionCheckboxesListItem } from "./MealLoggingQuestionCheckboxesListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface MealLoggingQuestionOptionsListProps
  extends Omit<
    SortableListProps<
      MealLoggingOption,
      MealLoggingQuestionOptionsListItemProps
    >,
    "ListItem" | "itemType"
  > {
  type: "radio" | "checkbox";
}

export function MealLoggingQuestionOptionsList(
  props: MealLoggingQuestionOptionsListProps,
) {
  const { className, type, ...other } = props;
  const s = useStyles();

  const itemType = `logging_question_${
    type === "radio" ? "multiple_choice" : "checkboxes"
  }_option`;

  const ListItem =
    type === "radio"
      ? MealLoggingQuestionMultipleChoiceListItem
      : MealLoggingQuestionCheckboxesListItem;

  return (
    <SortableList
      className={clsx(s.root, className)}
      itemType={itemType}
      ListItem={ListItem}
      {...other}
    />
  );
}
