/**
 * @generated SignedSource<<f3ea3bbee962183b78e3f929ea688bbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientAcceptInviteScreen_invite$data = {
  readonly accepted: boolean;
  readonly coach: {
    readonly displayName: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "ClientAcceptInviteScreen_invite";
};
export type ClientAcceptInviteScreen_invite$key = {
  readonly " $data"?: ClientAcceptInviteScreen_invite$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientAcceptInviteScreen_invite">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientAcceptInviteScreen_invite",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accepted",
      "storageKey": null
    }
  ],
  "type": "Invite",
  "abstractKey": null
};

(node as any).hash = "62f353e118797ce0f23689d8f5ed48bb";

export default node;
