/**
 * @generated SignedSource<<1b49a1dfd95310c54f42fec6c9a99d3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientProgramActivityEndRouteQuery$variables = {
  component: string;
  date: string;
};
export type ClientProgramActivityEndRouteQuery$data = {
  readonly activity: {
    readonly " $fragmentSpreads": FragmentRefs<"EndWorkout_activity">;
  } | null | undefined;
};
export type ClientProgramActivityEndRouteQuery = {
  response: ClientProgramActivityEndRouteQuery$data;
  variables: ClientProgramActivityEndRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "component"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v2 = [
  {
    "kind": "Literal",
    "name": "client",
    "value": "$me"
  },
  {
    "kind": "Variable",
    "name": "component",
    "variableName": "component"
  },
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientProgramActivityEndRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndWorkout_activity"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientProgramActivityEndRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Activity",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "raw",
                "value": true
              }
            ],
            "kind": "ScalarField",
            "name": "date",
            "storageKey": "date(raw:true)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Component",
            "kind": "LinkedField",
            "name": "component",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abc79cdc490064d1ff0399cb9b78ab4f",
    "id": null,
    "metadata": {},
    "name": "ClientProgramActivityEndRouteQuery",
    "operationKind": "query",
    "text": "query ClientProgramActivityEndRouteQuery(\n  $date: String!\n  $component: String!\n) {\n  activity(client: \"$me\", date: $date, component: $component) {\n    ...EndWorkout_activity\n    id\n  }\n}\n\nfragment EndWorkout_activity on Activity {\n  id\n  date(raw: true)\n  component {\n    locked\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5a7ae3db5ac05310fbd2a3d33aa16ac";

export default node;
