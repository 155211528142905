import { createContext } from "react";
import { CoachGroupsListRouteQuery$data } from "../routes/coach/groups/__generated__/CoachGroupsListRouteQuery.graphql";

export interface IGroupsContextProps {
  coachGroupsListRouteQuery: CoachGroupsListRouteQuery$data;
  setCoachGroupsListRouteQuery: (
    coachGroupsListRouteQuery: CoachGroupsListRouteQuery$data,
  ) => void;
}

const GroupsContext = createContext<IGroupsContextProps | undefined>(undefined);

export default GroupsContext;
