import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { Element } from "slate";

import { ReactComponent as DragIcon } from "../../../../icons/GrabSharp.svg";
import { Icons } from "../../../plate-ui/Icons/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 1,
    display: "flex",
    alignItems: "center",
  },

  dragIcon: {
    color: theme.palette.quote,
    cursor: "grab",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  dragging: {
    opacity: 0.5,
  },
}));

export interface DraggableSnippetProps {
  content: any;
  children: React.ReactNode;
  className?: string;
  dragButton?: React.ReactNode;
  draggableContent?: boolean;
}

export function DraggableSnippet(props: DraggableSnippetProps) {
  const { className, content, dragButton, draggableContent, children } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLDivElement>(null);
  const dragRef = React.useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: "block",
    item: {
      content: content,
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  drag(dragRef);
  preview(ref);

  if (draggableContent) {
    drag(ref);
  }

  return (
    <div
      className={clsx(s.root, className, isDragging && s.dragging)}
      ref={ref}
    >
      <div ref={dragRef}>
        <div>
          {dragButton !== undefined ? (
            dragButton
          ) : (
            <Icons.dragHandle className={s.dragIcon} />
          )}
        </div>
      </div>
      {children}
    </div>
  );
}
