import { androidMobileApp } from "./mobile";

const _polyfillCSSVars = [
  "--safe-area-inset-top",
  "--safe-area-inset-right",
  "--safe-area-inset-bottom",
  "--safe-area-inset-left",
];

const _polyfillCSS = (value: string) => {
  const style = getComputedStyle(document.body);
  let newValue = value;

  for (const varName of _polyfillCSSVars) {
    const varValue = style.getPropertyValue(varName);
    const varExpr = `var(${varName})`;
    const varNewValue =
      varValue.includes("env") || !varValue ? "0px" : varValue;

    newValue = newValue.split(varExpr).join(varNewValue);
  }

  let changed = false;
  let cnt = 0;

  do {
    changed = false;
    const expr = newValue.match(/(-?\d+)px[ ]+(\+|-)[ ]+(-?\d+)px/);
    if (expr) {
      const [found, left, op, right] = expr;
      const result = parseInt(left) + parseInt(right) * (op === "+" ? 1 : -1);

      newValue = newValue.replace(found, `${result}px`);
      changed = true;
    }

    const minmax = newValue.match(
      /(max|min)\([ ]*(-?\d+)px[ ]*,[ ]*(-?\d+)px[ ]*\)/,
    );
    if (minmax) {
      const [found, op, left, right] = minmax;
      const result = Math[op](parseInt(left), parseInt(right));

      newValue = newValue.replace(found, `${result}px`);
      changed = true;
    }
  } while (changed && ++cnt < 10);

  const unwrap = newValue.match(/calc\([ ]*(-?\d+px)[ ]*\)/);
  if (unwrap) {
    const [found, val] = unwrap;

    newValue = newValue.replace(found, val);
    changed = true;
  }

  return newValue;
};

export const polyfillCSS = (value: string) =>
  androidMobileApp ? _polyfillCSS(value) : value;

export const hexToRgbA = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
