import React, { useEffect, useTransition } from "react";
import { Grid, ContainerProps, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ClientExerciseChoose from "./ClientExerciseChoose";
import ClientExercisesItem from "./ClientExercisesItem";
import { graphql, usePaginationFragment } from "react-relay";
import { ClientExercises_data$key } from "./__generated__/ClientExercises_data.graphql";
import { useDebounce } from "../../hooks/useDebounce";
import { useEffectLater } from "../../hooks/useEffectLater";
import SearchIcon from "@mui/icons-material/Search";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  search: {
    maxWidth: 550,
    marginBottom: theme.spacing(3),
    "& fieldset": {
      borderRadius: 0,
      borderColor: theme.palette.border.primary,
      borderWidth: 1,
      borderStyle: "solid",
    },
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "center",
  },
}));

const dataFragment = graphql`
  fragment ClientExercises_data on Root
  @refetchable(queryName: "ClientExercisesRefetchQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 99 }
    clientId: { type: ID, defaultValue: null }
    after: { type: "String" }
    query: { type: "String" }
  ) {
    user_exercises(
      first: $first
      after: $after
      clientId: $clientId
      query: $query
    ) @connection(key: "ClientExercises_user_exercises", filters: []) {
      edges {
        node {
          id
          exerciseId
          exercise {
            name
          }
          title
          completedAt(format: "MMM D.YY")
        }
      }
    }
  }
`;

export interface ClientExercisesProps extends Omit<ContainerProps, "children"> {
  dataRef?: ClientExercises_data$key;
  pageSize?: number;
  selectExercise: any;
  handleCloseExercise: any;
  chooseExerciseItem: any;
  id: any;
  setLastRecorded: any;
  lastRecorded: any;
  query: string;
  setQuery: (text) => void;
}

function ClientExercises(props: ClientExercisesProps) {
  const {
    selectExercise,
    handleCloseExercise,
    chooseExerciseItem,
    dataRef,
    id,
    setLastRecorded,
    lastRecorded,
    query,
    setQuery,
  } = props;
  const { data, refetch } = usePaginationFragment(dataFragment, dataRef);
  const [isPending, setTransition] = useTransition();

  const handleQueryChange = React.useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery],
  );

  const delayedQuery = useDebounce(query, 500);

  const refetchData = React.useCallback(() => {
    setTransition(() => {
      refetch(
        {
          query: delayedQuery,
          id: id,
          first: 99,
        },
        { ...RELAY_LAZY_LOAD_COMMON_CONFIG },
      );
    });
  }, [delayedQuery, id, refetch]);

  useEffect(() => refetchData(), [refetchData]);

  React.useEffect(() => {
    if (!lastRecorded) {
      if (data.user_exercises.edges.length)
        setLastRecorded(data.user_exercises.edges[0].node.completedAt);
    }
  }, [data.user_exercises, lastRecorded, setLastRecorded]);

  const s = useStyles();
  return (
    <Grid container spacing={2} className={s.container}>
      {selectExercise ? (
        <ClientExerciseChoose
          onClose={handleCloseExercise}
          selectExercise={selectExercise}
          id={id}
        />
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              className={s.search}
              InputProps={{
                className: s.searchInput,
                endAdornment: <SearchIcon className={s.icon} />,
              }}
              value={query}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleQueryChange(event.currentTarget.value);
              }}
              placeholder="Search"
              variant="outlined"
              fullWidth
            />
          </Grid>
          {data.user_exercises.edges.length ? (
            data.user_exercises.edges.map(({ node }, index) => (
              <ClientExercisesItem
                key={index}
                name={node.title || node.exercise.name}
                onClick={() =>
                  chooseExerciseItem({
                    name: node.title || node.exercise.name,
                  })
                }
              />
            ))
          ) : (
            <Typography className={s.noText}>No exercises to show.</Typography>
          )}
        </>
      )}
    </Grid>
  );
}

export default React.memo(ClientExercises);
