import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";

import {
  AssetType,
  maxUploadFileSize,
  maxVideoUploadFileSize,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useTranscodeVideo } from "../../hooks/useTranscodeVideo";
import { uploadFile } from "../../utils/file";
import { useNativeDropzone } from "../../utils/device";
import { getTypesByMime } from "../component-library/utils";

import { SchemaElements } from "../editor/normalizers/withSchema";

import { ElementType } from "../editor/types/elements";
import { useCreateCustomAsset } from "../component-library/hooks/useCreateCustomAsset";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.text.secondary,

    borderRadius: 4,
    backgroundColor: theme.palette.selected.light,
    height: theme.spacing(7),
    fontWeight: "bold",
    fontSize: 16,
  },
  progress: {
    marginTop: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
  },
}));

const createUploadUrlMutation = graphql`
  mutation ComponentLibraryAssetUploadButtonCreateUploadUrlMutation(
    $id: ID!
    $file: String!
    $type: AssetType!
  ) {
    createSignedUrl(id: $id, file: $file, type: $type) {
      url
    }
  }
`;

export interface ComponentLibraryAssetUploadButtonProps extends ButtonProps {}
type Media = {
  type: ElementType;
  url: string;
  name: string;
  size: number;
  mimeType: string;
};

export function ComponentLibraryAssetUploadButton(
  props: ComponentLibraryAssetUploadButtonProps,
) {
  const { className, ...other } = props;
  const s = useStyles();
  const snackAlert = useSnackAlert();
  const me = useCurrentUser();
  const [progress, setProgress] = React.useState<number | null>(null);
  const [uploading, setUploading] = React.useState(false);
  const [createUploadUrl] = useMutation(createUploadUrlMutation);
  const transcodeVideo = useTranscodeVideo();
  const createAsset = useCreateCustomAsset();

  const handleOnProgress = React.useCallback(
    (event: ProgressEvent<EventTarget>) => {
      const progress = Math.round((100 * event.loaded) / event.total);

      setProgress(progress);
    },
    [],
  );

  const handleMedia = React.useCallback(
    ({ type, ...attrs }: Media) => {
      const element = SchemaElements.createElement(type, attrs);
      createAsset(element);
    },
    [createAsset],
  );

  const reportError = React.useCallback(
    () =>
      snackAlert({
        severity: "error",
        message: SOMETHING_WENT_WRONG,
      }),
    [snackAlert],
  );

  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: ([file], errors) => {
      if (errors.length) {
        reportError();
        return;
      } else if (!file) {
        return;
      }

      const { name, size, type: mimeType } = file;
      const { elementType, type } = getTypesByMime(mimeType);
      const maxFileSize =
        type === AssetType.LIBRARY_VIDEO
          ? maxVideoUploadFileSize
          : maxUploadFileSize;

      if (file.size > maxFileSize) {
        snackAlert({
          severity: "error",
          message: "File size is too big",
        });
        return;
      }

      setUploading(true);

      createUploadUrl({
        variables: {
          id: me.id,
          file: file.name,
          type,
        },
        onCompleted: ({ createSignedUrl }: any, errors) => {
          if (errors) {
            setUploading(false);
            reportError();
          } else {
            // TODO: Consider deleting the signed URL and uploaded file on change or element removal
            const { url } = createSignedUrl;

            uploadFile(url, file, handleOnProgress)
              .then(() => {
                const urlInstance = new URL(
                  `https:/${url.substr(url.indexOf("/", 8))}`,
                );
                urlInstance.search = "";

                const uploadUrl = String(urlInstance);

                return elementType === ElementType.VIDEO
                  ? transcodeVideo(uploadUrl)
                  : uploadUrl;
              })
              .then((url: string) => {
                handleMedia({
                  type: elementType,
                  url,
                  name,
                  size,
                  mimeType,
                });
              })
              .catch((err) => {
                // TODO: Inform user of errors using common component
                console.error(err);
              })
              .finally(() => {
                setUploading(false);
              });
          }
        },
        onError: (err) => {
          setUploading(false);
          reportError();
        },
      });
    },
  });

  return (
    <>
      <Button
        className={clsx(s.root, className)}
        fullWidth
        {...(getRootProps() as any)}
        {...other}
        disabled={uploading}
      >
        <input type="file" {...getInputProps()} />
        {uploading
          ? progress === 100
            ? "Processing..."
            : "Uploading..."
          : "Upload"}
      </Button>
      {uploading && (
        <LinearProgress
          className={s.progress}
          variant="determinate"
          color="primary"
          value={progress || 0}
        />
      )}
    </>
  );
}
