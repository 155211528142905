import { useCallback, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

import { isMobileApp, iOSMobileApp, androidMobileApp } from "./mobile";
import dayjs from "dayjs";

export const terraCapacitorEnabled = isMobileApp && iOSMobileApp;

const moduleRegistryKey = "TerraSdk";

if (terraCapacitorEnabled) {
  import("terra-capacitor")
    .then((TerraCapacitor) => {
      window[moduleRegistryKey] = TerraCapacitor;
    })
    .catch((error) => {
      console.error("Failed to import Terra Capacitor", error);
    });
}

const getConnection = (Connections) => {
  if (iOSMobileApp) {
    return Connections.APPLE_HEALTH;
  }
  if (androidMobileApp) {
    return Connections.GOOGLE;
  }
  return null;
};

export interface TerraSdkOptions {
  devId: string;
  referenceId: string;
  token: string;
}

export interface TerraSdkState {
  connected: boolean;
  error: unknown;
  init: () => Promise<void>;
  sync: () => Promise<void>;
}

export const useTerraSdk = (
  options: Partial<TerraSdkOptions> = {},
): TerraSdkState => {
  const { devId, referenceId, token } = options;
  const [error, setError] = useState<any>();
  const [connected, setConnected] = useLocalStorage<boolean>(
    "terra-connected",
    false,
  );

  const init = useCallback(async () => {
    if (!terraCapacitorEnabled || !devId || !referenceId || !token) {
      return;
    }
    try {
      const { Terra, Connections } = window[moduleRegistryKey];
      await Terra.echo({ value: "Echo function test" });
      const connection = getConnection(Connections);
      await Terra.initTerra({
        devId,
        referenceId,
      });
      await Terra.initConnection({
        connection,
        token,
        schedulerOn: false,
        customPermissions: [],
        startIntent: null,
      });
      setConnected(true);
    } catch (initError) {
      setError(initError);
    }
  }, [devId, referenceId, token, setError, setConnected]);

  const sync = useCallback(async () => {
    const terraModule = window[moduleRegistryKey];
    if (!terraCapacitorEnabled || !terraModule) {
      return;
    }
    const { Terra, Connections } = terraModule;
    const connection = getConnection(Connections);
    const tomorrowStart = dayjs().add(1, "day").startOf("day");
    const startDate = tomorrowStart.clone().subtract(1, "week").toDate();
    const endDate = tomorrowStart.toDate();
    await Terra.getDaily({
      connection,
      startDate,
      endDate,
      toWebhook: true,
    });
    await Terra.getNutrition({
      connection,
      startDate,
      endDate,
      toWebhook: true,
    });
    return;
  }, []);

  return {
    connected,
    error,
    init,
    sync,
  };
};
