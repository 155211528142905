import React from "react";

import { getVideoThumbnailImage } from "../utils/component";

export type ThumbnailOptions = {
  // TODO: Width/height values, crop/clip settings, etc.
};

export function useThumbnail(src: string, options: ThumbnailOptions = {}) {
  const [thumbnail, setThumbnail] = React.useState(src);

  React.useEffect(() => {
    async function updateThumbnail() {
      if (!src) {
        setThumbnail(null);
        return;
      }

      const value = await getVideoThumbnailImage(src);

      if (value !== thumbnail) {
        setThumbnail(value);
      }
    }

    updateThumbnail();
  }, [src, thumbnail]);

  return thumbnail;
}
