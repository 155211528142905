import React from "react";
import { BoxProps } from "@mui/material";

import {
  EmojiRating,
  EmojiRatingProps,
  INIT_EMOJI_VALUES,
} from "../fields/EmojiRating";

import { ClientMealLoggingQuestionProps } from "./types";
import { MealLoggingQuestionEmoji } from "../meal-logging-settings/types";

export interface ClientMealLoggingEmojiQuestionProps
  extends Omit<BoxProps, "onSelect">,
    ClientMealLoggingQuestionProps<MealLoggingQuestionEmoji> {}

export function ClientMealLoggingEmojiQuestion(
  props: ClientMealLoggingEmojiQuestionProps,
) {
  const { className, option, onUpdate, ...other } = props;

  const options = INIT_EMOJI_VALUES;

  const handleSelect: EmojiRatingProps["onSelect"] = React.useCallback(
    (index: number) => {
      if (onUpdate) {
        onUpdate({
          ...option,
          answer: options[index],
        });
      }
    },
    [onUpdate, option],
  );

  return (
    <EmojiRating
      className={className}
      options={options}
      value={option.answer}
      onSelect={handleSelect}
      {...other}
    />
  );
}
