import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  Card,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import { sortBy } from "lodash";

import { useQueryParam } from "../../hooks/useQueryParam";

import { getWeekLabel, getWeekISODates } from "../../utils/date";
import { ClientCircularProgress } from "../progress/ClientCircularProgress";
import { ActivityByTypesList } from "../activity/ActivityByTypesList";
import { EnrollmentStatus } from "../../constants";
import { DropdownMenuItem, DropdownMenu } from "../menu/DropdownMenu";

import { CoachClientProgram_enrollments$key } from "./__generated__/CoachClientProgram_enrollments.graphql";
import { CoachClientProgram_enrollment$key } from "./__generated__/CoachClientProgram_enrollment.graphql";
import { EditorValue } from "./EditorValue";
import { HabitCheckInProgress } from "./HabitCheckInProgress";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },

  label: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },

  description: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(4),
    },
  },

  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0),
    },
  },

  progress: {
    position: "absolute",
    top: 0,
    left: 5,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  circularProgress: {
    marginLeft: theme.spacing(3.75),
    alignSelf: "start",
  },

  card: {
    padding: theme.spacing(0, 3.25, 0, 2.75),
  },
}));

const enrollmentFragment = graphql`
  fragment CoachClientProgram_enrollment on Enrollment
  @argumentDefinitions(
    week: { type: "Int" }
    period: { type: "CompletionPeriodType!" }
  ) {
    id
    startDate(raw: true)
    currentWeek

    completion(week: $week, completion: true, period: ALL_TIME) {
      rate
    }

    activities(week: $week) {
      date(raw: true)
      week
      ...ActivityByTypesList_activities @arguments(compact: true)
    }

    ...HabitCheckInProgress_enrollment @arguments(period: $period)
  }
`;

const enrollmentsFragment = graphql`
  fragment CoachClientProgram_enrollments on EnrollmentConnection {
    edges {
      node {
        active
        upcoming
        past
        currentWeek
        program {
          name
          slug
        }
      }
    }
  }
`;

export interface CoachClientProgramProps
  extends Omit<ContainerProps, "children"> {
  enrollmentRef: CoachClientProgram_enrollment$key;
  enrollmentsRef: CoachClientProgram_enrollments$key;
  userName?: string;
  program?: any;
}

export function CoachClientProgram(props: CoachClientProgramProps) {
  const {
    className,
    enrollmentRef,
    enrollmentsRef,
    userName,
    program,
    ...other
  } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [weekQueryParam, setWeekQueryParam] = useQueryParam("week", null);
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);
  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const { startDate, completion } = enrollment;
  const progress = completion.rate;
  const selectedWeek = weekQueryParam ? parseInt(weekQueryParam) : null;

  const weekDates = React.useMemo(() => {
    if (selectedWeek) {
      return getWeekISODates(startDate, selectedWeek);
    }
  }, [startDate, selectedWeek]);

  const selectedProgramWeek = React.useMemo(
    () =>
      selectedWeek
        ? program.weeks.edges.find(({ node }) => node.week === selectedWeek)
        : null,
    [program.weeks, selectedWeek],
  );

  const handleProgramDropdownClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      const { slug } = event.currentTarget.dataset;

      navigate(
        location.pathname.replace(/\/programs\/.*/, "/programs/" + slug),
      );
    },
    [],
  );

  const handleWeekDropdownClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      setWeekQueryParam(event.currentTarget.dataset?.week);
    },
    [setWeekQueryParam],
  );

  const items = React.useMemo(
    () => ({
      [EnrollmentStatus.ACTIVE]: enrollments.edges.filter(
        (it) => it.node.active,
      ),
      [EnrollmentStatus.UPCOMING]: enrollments.edges.filter(
        (it) => it.node.upcoming,
      ),
      [EnrollmentStatus.PAST]: enrollments.edges.filter((it) => it.node.past),
    }),
    [enrollments.edges],
  );

  const programItems: DropdownMenuItem[] = React.useMemo(
    () =>
      Object.entries(items)
        .filter(([, enrollments]) => enrollments.length)
        .map(([group, enrollments]) => {
          const header = {
            variant: "header",
            children: formatEnrollmentStatus(group as EnrollmentStatus),
          } as const;

          const programs = enrollments.map(
            ({
              node: {
                program: { name, slug },
              },
            }) => ({
              children: name,
              onClick: handleProgramDropdownClick,
              selected: slug === program.slug,
              data: { slug },
            }),
          );

          return [header, ...programs];
        })
        .flat(),
    [handleProgramDropdownClick, items, program.slug],
  );

  const weekItems: DropdownMenuItem[] = React.useMemo(() => {
    const overview = {
      children: "Overview",
      onClick: handleWeekDropdownClick,
      selected: !weekQueryParam,
    };
    const _weeks = [...Array(program.length)]
      .map((_, index) => String(index + 1))
      .map((week) => ({
        children: `Week ${week}`,
        onClick: handleWeekDropdownClick,
        selected: week === weekQueryParam,
        data: { week },
      }));

    return [overview, ..._weeks];
  }, [handleWeekDropdownClick, program.length, weekQueryParam]);

  const { activities } = enrollment;
  const weekActivities = React.useMemo(
    () => sortBy(activities, (it) => it.date),
    [activities],
  );

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DropdownMenu
        menuId="select-program"
        header={program.name}
        variant="big"
        items={programItems}
      />

      <Box className={s.header}>
        <Box>
          <DropdownMenu
            menuId="select-week"
            header={weekQueryParam ? `Week ${weekQueryParam}` : "Overview"}
            subHeader="Go to"
            variant="normal"
            items={weekItems}
          />

          <Typography variant="body1" className={s.label}>
            {weekDates
              ? getWeekLabel(weekDates[0], weekDates[6], true)
              : `${program.length} week program`}
          </Typography>

          {selectedProgramWeek && (
            <Typography
              variant="body1"
              component="div"
              className={s.description}
            >
              <EditorValue value={selectedProgramWeek.node.description} />
            </Typography>
          )}
        </Box>

        {selectedWeek && (
          <ClientCircularProgress
            className={s.circularProgress}
            value={progress}
            size="normal"
          >
            <Typography
              className={s.progress}
              variant="h5"
              component="div"
              children={`${progress}%`}
            />
          </ClientCircularProgress>
        )}
      </Box>

      {selectedWeek ? (
        <Card className={s.card}>
          <ActivityByTypesList
            userName={userName}
            activitiesRef={weekActivities}
            variant="compact"
            clientsScreen
            idProgram={program.id}
          />
        </Card>
      ) : (
        <HabitCheckInProgress enrollmentRef={enrollment} />
      )}
    </Container>
  );
}

export const formatEnrollmentStatus = (status: EnrollmentStatus): string => {
  switch (status) {
    case EnrollmentStatus.ACTIVE:
      return "Active";
    case EnrollmentStatus.UPCOMING:
      return "Up Next";
    case EnrollmentStatus.PAST:
      return "Past";
  }
};
