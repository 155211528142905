import { Icons } from "../../../plate-ui/Icons/icons";
import { TEXT_SECTION } from "../../utils/menuSectionsUtil";
import { Schema, ToolbarMenu } from "./Schema";

export const SimpleEditorFieldEditorSchema: Schema = {
  generalDropDownMenuItems: [],
  showToolbar: false,
  toolbarOptions: {
    showTextTransformOptions: false,
    showTextFormattingOptions: true,
    showTextColorOptions: false,
    showTextIndentationOptions: false,
    showListOptions: false,
    showQuotesOptions: false,
    showLinkOption: false,
    showAdvancedLinkOptions: false,
    showElementInsertOptions: false,
  },
  turnIntoMenuItemGroups: [],
};
