import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as CheckMarkCircle } from "../../icons/CheckMarkCircle.svg";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import GetItOnGooglePlay from "../../icons/GetItOnGooglePlay";
import DownloadOnAppStore from "../../icons/DownloadOnAppStore";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    padding: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 4),
    },
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },

  features: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: theme.spacing(4.5),
    marginTop: theme.spacing(3),
  },
  feature: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(3.5),
    },

    [theme.breakpoints.up("md")]: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(3.5),
      },
    },
  },
  check: {
    marginRight: theme.spacing(1.25),

    "& svg": {
      width: 30,
      height: 30,
      color: theme.palette.primary.main,
    },
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  links: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    alignItems: "center",
    flexDirection: "column",
  },

  titleBox: {
    display: "flex",
    alignItems: "center",
    gap: 0,
    flexDirection: "column",
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: theme.spacing(2),
      marginBottom: 0,
    },
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  statusBox: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 2),
    flexShrink: 0,
    marginBottom: 4,
  },
  status: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
  },
}));

export interface CoachBrandedAppInfoProps
  extends Omit<ContainerProps, "children"> {}

export function CoachBrandedAppInfo(props: CoachBrandedAppInfoProps) {
  const { className, ...other } = props;
  const s = useStyles();

  const {
    isBrandedAppPublished,
    AppleStoreUrl,
    GoogleStoreUrl,
    appName,
    brandName,
    brandedAppLogo,
  } = useCurrentBrand();

  const features = [
    {
      text: `Custom ${brandName} app loading screen`,
    },
    {
      text: `Your logo and branding inside ${brandName} app`,
    },
    {
      text: `No mentioning of Stridist inside ${brandName} client app`,
    },
    {
      text: "Branded email and push notifications",
    },
  ];

  const publishedAppContent = () => {
    return (
      <Box className={s.header}>
        <Box className={s.titleBox}>
          <Typography className={s.title}>{brandName} Branded App</Typography>
          <Box className={s.statusBox}>
            <Typography className={s.status}>published</Typography>
          </Box>
        </Box>
        <Stack spacing={2} marginTop={2}>
          <Typography className={s.text}>
            Your custom branded app is officially live🔥on both Google Play
            Market and the Apple App Store! This means your clients can easily
            download and start using your app, creating a seamless and
            professional experience with your brand from the very first
            interaction.
          </Typography>
          <Typography className={s.text}>
            Direct links to your app are below. Your clients also have these
            links within their profiles for quick access. Now, your brand is
            always within reach, helping you stay front and center in your
            clients' fitness journeys!
          </Typography>
          <Box className={s.links}>
            <Box
              component={"img"}
              sx={{
                width: 100,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
                borderRadius: "20%",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
              src={brandedAppLogo}
            ></Box>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                letterSpacing: 0.5,
                color: "#333",
                textTransform: "capitalize",
                marginTop: 1,
              }}
            >
              {appName}
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={1}
              sx={{ my: 2, maxWidth: 350 }}
            >
              <GetItOnGooglePlay href={GoogleStoreUrl}></GetItOnGooglePlay>
              <DownloadOnAppStore href={AppleStoreUrl}></DownloadOnAppStore>
            </Stack>
          </Box>
          <Typography className={s.text}>
            No effort from you is required to migrate your existing and future
            clients to your brand new mobile app from Stridist.
            <br /> - We're promoting it to your existing clients, ensuring they
            continue their fitness journey with your brand only
            <br /> - Every future client of yours will not know what is Stridist
            - only <b>{appName}</b> from now on.
          </Typography>
        </Stack>
      </Box>
    );
  };

  const appInProgressContent = () => {
    return (
      <Box className={s.header}>
        <Box className={s.titleBox}>
          <Typography className={s.title}>{brandName} Branded App</Typography>
          <Box className={s.statusBox}>
            <Typography className={s.status}>in development</Typography>
          </Box>
        </Box>
        <Typography className={s.text}>
          Your custom branded app is under the development by Stridist dev team.
        </Typography>
        <Typography
          className={s.text}
          sx={{ marginTop: isBrandedAppPublished ? 2 : 0 }}
        >
          Very soon it will be available for clients on both Apple and Google
          stores. Which also includes:
        </Typography>
        <>
          <Box className={s.features}>
            {features.map(({ text }, index) => (
              <ListItem key={index} className={s.feature}>
                <ListItemIcon
                  className={s.check}
                  children={<CheckMarkCircle />}
                />
                <Typography children={text} className={s.text} />
              </ListItem>
            ))}
          </Box>
          <Typography className={s.text} sx={{ marginTop: 0 }}>
            In case of any questions, don't hesitate to contact Stridist
            customer support.
          </Typography>
        </>
      </Box>
    );
  };

  const renderContent = (status: boolean) => {
    switch (status) {
      case true:
        return publishedAppContent();
      default:
        return appInProgressContent();
    }
  };

  return (
    <Container className={clsx(s.root, className)} {...other}>
      {renderContent(isBrandedAppPublished)}
    </Container>
  );
}
