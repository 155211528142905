/**
 * @generated SignedSource<<6e70951155ceed992c74c2334e34370f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachProgramSettingsRouteQuery$variables = {
  maxEnrollments: number;
  programSlug: string;
};
export type CoachProgramSettingsRouteQuery$data = {
  readonly clients: {
    readonly " $fragmentSpreads": FragmentRefs<"EnrollDialog_clients">;
  } | null | undefined;
  readonly enrollmentsAvatars: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"EnrolledAvatars_connection">;
  } | null | undefined;
  readonly groups: {
    readonly " $fragmentSpreads": FragmentRefs<"EnrollDialog_groups">;
  } | null | undefined;
  readonly program: {
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"EnrollDateDialog_program" | "EnrollDialog_program" | "ProgramSettings_program" | "RefreshSlug">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UnsplashImages_rootRef">;
};
export type CoachProgramSettingsRouteQuery = {
  response: CoachProgramSettingsRouteQuery$data;
  variables: CoachProgramSettingsRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxEnrollments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "programSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "programSlug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v6/*: any*/)
],
v11 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "maxEnrollments"
  },
  {
    "kind": "Variable",
    "name": "programSlug",
    "variableName": "programSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachProgramSettingsRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UnsplashImages_rootRef"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RefreshSlug"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramSettings_program"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnrollDialog_program"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnrollDateDialog_program"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnrollDialog_clients"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupConnection",
        "kind": "LinkedField",
        "name": "groups",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnrollDialog_groups"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "enrollmentsAvatars",
        "args": null,
        "concreteType": "EnrollmentConnection",
        "kind": "LinkedField",
        "name": "__Program_enrollmentsAvatars_connection",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrollmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Enrollment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EnrolledAvatars_connection"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CoachProgramSettingsRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "length",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultView",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InviteCode",
            "kind": "LinkedField",
            "name": "inviteCode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slugId",
                "storageKey": null
              }
            ],
            "type": "Slug",
            "abstractKey": "__isSlug"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "clients",
        "plural": false,
        "selections": (v10/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GroupConnection",
        "kind": "LinkedField",
        "name": "groups",
        "plural": false,
        "selections": (v10/*: any*/),
        "storageKey": null
      },
      {
        "alias": "enrollmentsAvatars",
        "args": (v11/*: any*/),
        "concreteType": "EnrollmentConnection",
        "kind": "LinkedField",
        "name": "enrollments",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EnrollmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Enrollment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoURL",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "enrollmentsAvatars",
        "args": (v11/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "Program_enrollmentsAvatars",
        "kind": "LinkedHandle",
        "name": "enrollments"
      }
    ]
  },
  "params": {
    "cacheID": "203da5f41e0e6fa0dd0f5d1f2b4750a4",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "maxEnrollments",
          "cursor": null,
          "direction": "forward",
          "path": [
            "enrollmentsAvatars"
          ]
        }
      ]
    },
    "name": "CoachProgramSettingsRouteQuery",
    "operationKind": "query",
    "text": "query CoachProgramSettingsRouteQuery(\n  $programSlug: String!\n  $maxEnrollments: Int!\n) {\n  program(slug: $programSlug) {\n    ...RefreshSlug\n    ...ProgramSettings_program\n    ...EnrollDialog_program\n    ...EnrollDateDialog_program\n    id\n    name\n    slug\n  }\n  clients {\n    ...EnrollDialog_clients\n  }\n  groups {\n    ...EnrollDialog_groups\n  }\n  enrollmentsAvatars: enrollments(programSlug: $programSlug, first: $maxEnrollments) {\n    totalCount\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    ...EnrolledAvatars_connection\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  displayName\n  photoURL\n}\n\nfragment EnrollDateDialog_program on Program {\n  id\n}\n\nfragment EnrollDialog_clients on ClientConnection {\n  totalCount\n}\n\nfragment EnrollDialog_groups on GroupConnection {\n  totalCount\n}\n\nfragment EnrollDialog_program on Program {\n  id\n  name\n}\n\nfragment EnrolledAvatar_enrollment on Enrollment {\n  client {\n    ...Avatar_user\n    id\n  }\n}\n\nfragment EnrolledAvatars_connection on EnrollmentConnection {\n  totalCount\n  edges {\n    node {\n      id\n      ...EnrolledAvatar_enrollment\n    }\n  }\n}\n\nfragment InviteLinkSettings_inviteCode on InviteCode {\n  id\n  code\n}\n\nfragment ProgramSettings_program on Program {\n  id\n  slug\n  name\n  description\n  imageURL\n  status\n  length\n  defaultView\n  inviteCode {\n    ...InviteLinkSettings_inviteCode\n    id\n    startDate\n  }\n}\n\nfragment RefreshSlug on Slug {\n  __isSlug: __typename\n  slug\n  slugId\n}\n"
  }
};
})();

(node as any).hash = "3ceab534bba4d8c5a2bb80f5d1665187";

export default node;
