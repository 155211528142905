import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { getISODate } from "../../utils/date";
import { colorSystem } from "../../theme";

import { ClientGoalsDrawerHeader } from "./ClientGoalsDrawerHeader";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    marginBottom: theme.spacing(6),
  },

  datePicker: {
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    zIndex: 10,
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  button: {
    flexGrow: 1,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    padding: theme.spacing(2.25),
    borderRadius: theme.spacing(0.5),
    zIndex: 10,
  },

  cancelButton: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.black,
    borderStyle: "solid",
    borderWidth: 2,
    marginRight: theme.spacing(3),

    "&:hover": {
      backgroundColor: `${colorSystem.black}0A`,
    },
  },

  setDateButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
}));

export interface ClientGoalsDrawerSetDateViewProps extends BoxProps {
  date: string;
  onBack: () => void;
  onDelete: () => void;
  onClose: () => void;
  onDateChange: (date: string) => void;
}

export function ClientGoalsDrawerSetDateView(
  props: ClientGoalsDrawerSetDateViewProps,
) {
  const { className, date, onBack, onDelete, onClose, onDateChange, ...other } =
    props;
  const s = useStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    date ? new Date(date) : new Date(),
  );

  const handleDateChange = React.useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const handleSetDateClick = React.useCallback(() => {
    onDateChange(getISODate(selectedDate));
  }, [onDateChange, selectedDate]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientGoalsDrawerHeader
        className={s.header}
        onBack={onBack}
        onDelete={onDelete}
        onClose={onClose}
      />

      <DatePicker
        className={s.datePicker}
        value={selectedDate}
        onChange={handleDateChange}
      />

      <Box className={s.buttons}>
        <Button
          className={clsx(s.button, s.cancelButton)}
          variant="contained"
          children="Cancel"
          onClick={onBack}
        />
        <Button
          className={clsx(s.button, s.setDateButton)}
          variant="contained"
          children="Set date"
          onClick={handleSetDateClick}
          disabled={!selectedDate}
        />
      </Box>
    </Box>
  );
}
