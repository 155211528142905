import { graphql } from "react-relay/hooks";

import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { SendClientFormMutation } from "./__generated__/SendClientFormMutation.graphql";

const mutation = graphql`
  mutation SendClientFormMutation($input: SendClientFormInput!) {
    sendClientForm(input: $input) {
      clientForm {
        clients {
          ...SelectClientsDialog_clients
        }
      }
    }
  }
`;

export const useSendClientFormMutation = () =>
  useConfiguredMutation<SendClientFormMutation>(mutation, () => ({}));
