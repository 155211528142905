import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { ConditionProps } from "./types";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingTop: theme.spacing(6.2),
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      flexGrow: 1,
    },
    title: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: "34px",
      marginTop: theme.spacing(6.2),
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "29px",
      },
    },
    subTitle: {
      marginTop: 8,
      fontWeight: 500,
      lineHeight: "22px",
      color: colorSystem.gray,
      paddingLeft: theme.spacing(6.7),
      paddingRight: theme.spacing(6.7),
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
    valuesContainer: {
      display: "flex",
      marginTop: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(4.5),
      },
    },
    value: {
      cursor: "pointer",
      backgroundColor: colorSystem.gray9,
      padding: "2.5vh 4.4vh",
      marginRight: 2,
      fontWeight: 700,
      fontSize: 28,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2, 3.3),
        fontSize: 20,
      },
    },
    values: {
      "&:first-of-type $value": {
        borderBottomLeftRadius: 14,
        borderTopLeftRadius: 14,
      },
      "&:last-of-type $value": {
        borderBottomRightRadius: 14,
        borderTopRightRadius: 14,
        marginRight: 0,
      },
    },

    index: {
      marginLeft: 1,
      marginRight: 1,
      color: colorSystem.secondaryGray,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "17px",
      marginTop: theme.spacing(1),
    },
    activeValue_1: {
      backgroundColor: colorSystem.primary,
      color: colorSystem.white,
    },
    activeValue_2: {
      backgroundColor: colorSystem.orange,
      color: colorSystem.white,
    },
    activeValue_3: {
      backgroundColor: colorSystem.yellow,
      color: colorSystem.white,
    },
    activeValue_4: {
      backgroundColor: colorSystem.blue1,
      color: colorSystem.white,
    },
    activeValue_5: {
      backgroundColor: colorSystem.green2,
      color: colorSystem.white,
    },
    activeText_1: {
      color: colorSystem.primary,
    },
    activeText_2: {
      color: colorSystem.orange,
    },
    activeText_3: {
      color: colorSystem.yellow,
    },
    activeText_4: {
      color: colorSystem.blue1,
    },
    activeText_5: {
      color: colorSystem.green2,
    },
  };
});

const Condition = (props: ConditionProps) => {
  const { title, text, icon, onChooseValue, activeValue } = props;
  const s = useStyles();
  const values: Array<string> = [
    "Bad",
    "Tired",
    "OK",
    "Good",
    title === "Body" ? "Strong" : "Hyped",
  ];

  return (
    <div className={clsx(s.container)}>
      {icon}
      <div className={clsx(s.title)}>{title}</div>
      <div className={clsx(s.subTitle)}>{text}</div>
      <div className={clsx(s.valuesContainer)}>
        {values.map((value, index) => (
          <div key={value} className={s.values}>
            <div
              onClick={() => onChooseValue(`${index + 1} ${value}`)}
              className={clsx(
                s.value,
                activeValue === `${index + 1} ${value}` &&
                  s[`activeValue_${index + 1}`],
              )}
            >
              {index + 1}
            </div>
            <div
              className={clsx(
                s.index,
                activeValue === `${index + 1} ${value}` &&
                  s[`activeText_${index + 1}`],
              )}
            >
              {value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Condition;
