/**
 * @generated SignedSource<<049d8585236f13cf8f76c465f7050f40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThreadHeader_thread$data = {
  readonly author: {
    readonly displayName: string | null | undefined;
    readonly photoURL: string | null | undefined;
  } | null | undefined;
  readonly group: {
    readonly membersCount: number;
    readonly name: string;
  } | null | undefined;
  readonly lastMessage: {
    readonly createdAt: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ThreadHeader_thread";
};
export type ThreadHeader_thread$key = {
  readonly " $data"?: ThreadHeader_thread$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThreadHeader_thread">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThreadHeader_thread",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Message",
      "kind": "LinkedField",
      "name": "lastMessage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "format",
              "value": "fromNow"
            }
          ],
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": "createdAt(format:\"fromNow\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "membersCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Thread",
  "abstractKey": null
};

(node as any).hash = "68604b428ed482e55c725ef8f7917e4c";

export default node;
