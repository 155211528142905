import clsx from "clsx";
import React from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Menu, MenuProps } from "../menu/Menu";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as ChangeIcon } from "../../icons/PencilOutline.svg";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface UserNoteMenuProps extends MenuProps {
  onDelete?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onEdit?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export function UserNoteMenu(props: UserNoteMenuProps) {
  const { className, onEdit, onDelete, ...other } = props;
  const s = useStyles();

  return (
    <>
      <Menu
        className={clsx(s.root, className)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        variant="menu"
        {...other}
      >
        <MenuItem onClick={onDelete}>
          <ListItemIcon children={<BinIcon />} />
          <ListItemText primary="Delete" />
        </MenuItem>

        <MenuItem onClick={onEdit}>
          <ListItemIcon children={<ChangeIcon />} />
          <ListItemText primary="Edit note" />
        </MenuItem>
      </Menu>
    </>
  );
}
