/**
 * @generated SignedSource<<52b87969fdbe7c825be34e8e83b43bda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagingScreen_root$data = {
  readonly me: {
    readonly unreadMessagesCounter: number;
  } | null | undefined;
  readonly thread?: {
    readonly " $fragmentSpreads": FragmentRefs<"Messages_thread">;
  } | null | undefined;
  readonly threads: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Threads_threads">;
  } | null | undefined;
  readonly " $fragmentType": "MessagingScreen_root";
};
export type MessagingScreen_root$key = {
  readonly " $data"?: MessagingScreen_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagingScreen_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "recipient"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldFetchMessages"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "threads"
        ]
      }
    ]
  },
  "name": "MessagingScreen_root",
  "selections": [
    {
      "condition": "shouldFetchMessages",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "username",
              "variableName": "recipient"
            }
          ],
          "concreteType": "Thread",
          "kind": "LinkedField",
          "name": "thread",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Messages_thread"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": "threads",
      "args": null,
      "concreteType": "ThreadsConnection",
      "kind": "LinkedField",
      "name": "__Threads_threads_connection",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Threads_threads"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ThreadsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Thread",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadMessagesCounter",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Root",
  "abstractKey": null
};

(node as any).hash = "5c1e7b972fd30b4742b807c734e43657";

export default node;
