/**
 * @generated SignedSource<<84fccdb87f623da42cb1174974cd1bc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientProgram_enrollment$data = {
  readonly activities: ReadonlyArray<{
    readonly date: string | null | undefined;
    readonly week: number;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityByTypesList_activities">;
  } | null | undefined>;
  readonly completion: {
    readonly rate: number;
  };
  readonly currentWeek: number | null | undefined;
  readonly id: string;
  readonly startDate: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"HabitCheckInProgress_enrollment">;
  readonly " $fragmentType": "CoachClientProgram_enrollment";
};
export type CoachClientProgram_enrollment$key = {
  readonly " $data"?: CoachClientProgram_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientProgram_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "raw",
    "value": true
  }
],
v1 = {
  "kind": "Variable",
  "name": "week",
  "variableName": "week"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "period"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "week"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachClientProgram_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "completion",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "period",
          "value": "ALL_TIME"
        },
        (v1/*: any*/)
      ],
      "concreteType": "Completion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "date",
          "storageKey": "date(raw:true)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "week",
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "compact",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "ActivityByTypesList_activities"
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "period",
          "variableName": "period"
        }
      ],
      "kind": "FragmentSpread",
      "name": "HabitCheckInProgress_enrollment"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "4947c66355a39d2fa122fd24c07958b9";

export default node;
