/**
 * @generated SignedSource<<9ffa2eed6204dcfd856ba5d023fef359>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProgramStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoachProgramMenu_program$data = {
  readonly folder: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly status: ProgramStatus;
  readonly " $fragmentType": "CoachProgramMenu_program";
};
export type CoachProgramMenu_program$key = {
  readonly " $data"?: CoachProgramMenu_program$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachProgramMenu_program">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachProgramMenu_program",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgramFolder",
      "kind": "LinkedField",
      "name": "folder",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Program",
  "abstractKey": null
};
})();

(node as any).hash = "b37c946102ffb17621b34a616389fad7";

export default node;
