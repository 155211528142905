import clsx from "clsx";
import React from "react";
import { Card, CardProps, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { MetricType } from "../../constants";
import { BinaryResponsesSummary } from "../progress/BinaryResponsesSummary";
import { RatingResponsesSummary } from "../progress/RatingResponsesSummary";
import { TextResponsesSummary } from "../progress/TextResponsesSummary";
import { NumberResponsesSummary } from "../progress/NumberResponsesSummary";
import { MultipleChoiceResponsesSummary } from "../progress/MultipleChoiceResponsesSummary";
import { DateResponsesSummary } from "../progress/DateResponsesSummary";
import { EmojiResponsesSummary } from "../progress/EmojiResponsesSummary";
import { StarRatingResponsesSummary } from "../progress/StarRatingResponsesSummary";

import { ClientResponsesSummaryCard_summary$key } from "./__generated__/ClientResponsesSummaryCard_summary.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },

  rating: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 5),
    },
  },

  info: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    color: "#00000099",
    textTransform: "uppercase",
    marginTop: theme.spacing(2),

    "&$indent": {
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(6.25),
      },
    },
  },

  button: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    marginTop: theme.spacing(3),
  },

  arrow: {
    transform: "rotate(90deg)",
    transition: theme.transitions.create(["transform"]),

    "&$open": {
      transform: "rotate(-90deg)",
    },
  },

  indent: {},
  open: {},
}));

const summaryFragment = graphql`
  fragment ClientResponsesSummaryCard_summary on ResponsesSummary {
    metricType
    componentTitle
    question
    fromNow: lastDate(format: "fromNow")
    count
    ... on BinaryResponsesSummary {
      yes
      no
    }
    ... on RatingResponsesSummary {
      counts
      rates
    }
    ... on TextResponsesSummary {
      textResponses: responses {
        text
        date(format: "fromNow")
      }
    }
    ... on DateResponsesSummary {
      dateResponses: responses {
        selectedDate(format: "MM-DD-YYYY")
        date(format: "fromNow")
      }
    }
    ... on NumberResponsesSummary {
      numberResponses: responses {
        number
        date(format: "fromNow")
      }
    }
    ... on EmojiResponsesSummary {
      emojiResponses: responses {
        emoji
        date(format: "fromNow")
      }
    }
    ... on StarRatingResponsesSummary {
      starRatingResponses: responses {
        starRating
        date(format: "fromNow")
      }
    }
    ... on MultipleChoiceResponsesSummary {
      counts
      rates
      options
    }
  }
`;

const DEFAULT_RESPONSES_COUNT = 3;

export interface ClientResponsesSummaryCardProps extends CardProps {
  summaryFragmentRef: ClientResponsesSummaryCard_summary$key;
}

export function ClientResponsesSummaryCard(
  props: ClientResponsesSummaryCardProps,
) {
  const { className, summaryFragmentRef, ...other } = props;
  const s = useStyles();
  const summary = useFragment(summaryFragment, summaryFragmentRef);
  const [collapsed, setCollapsed] = React.useState(true);

  const handleCollapseClick = React.useCallback(() => {
    setCollapsed((collapsed) => !collapsed);
  }, []);

  const textResponsesTotal = summary.textResponses?.length;
  const numberResponsesTotal = summary.numberResponses?.length;

  const showCollapse = React.useMemo(
    () =>
      (summary.metricType === MetricType.CHECKIN_ANSWER_LONG_TEXT &&
        textResponsesTotal > DEFAULT_RESPONSES_COUNT) ||
      (summary.metricType === MetricType.CHECKIN_ANSWER_NUMBER &&
        numberResponsesTotal > DEFAULT_RESPONSES_COUNT),
    [summary.metricType, textResponsesTotal, numberResponsesTotal],
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} children={summary.componentTitle} />
      <Typography className={s.subtitle} children={summary.question} />

      {[
        MetricType.CHECKIN_ANSWER_MULTIPLE_CHOICE,
        MetricType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX,
      ].includes(summary.metricType as MetricType) ? (
        <MultipleChoiceResponsesSummary
          counts={summary.counts}
          rates={summary.rates}
          options={summary.options}
        />
      ) : summary.metricType === MetricType.CHECKIN_ANSWER_BINARY ? (
        <BinaryResponsesSummary value={summary.yes} />
      ) : summary.metricType === MetricType.CHECKIN_ANSWER_RATING_SCALE ? (
        <RatingResponsesSummary
          className={s.rating}
          counts={summary.counts}
          rates={summary.rates}
        />
      ) : [
          MetricType.CHECKIN_ANSWER_LONG_TEXT,
          MetricType.CHECKIN_ANSWER_SHORT_TEXT,
        ].includes(summary.metricType as MetricType) ? (
        <TextResponsesSummary
          responses={
            collapsed
              ? summary.textResponses.slice(0, DEFAULT_RESPONSES_COUNT)
              : summary.textResponses
          }
        />
      ) : summary.metricType === MetricType.CHECKIN_ANSWER_NUMBER ? (
        <NumberResponsesSummary
          responses={
            collapsed
              ? summary.numberResponses.slice(0, DEFAULT_RESPONSES_COUNT)
              : summary.numberResponses
          }
        />
      ) : summary.metricType === MetricType.CHECKIN_ANSWER_DATE ? (
        <DateResponsesSummary
          responses={
            collapsed
              ? summary.dateResponses.slice(0, DEFAULT_RESPONSES_COUNT)
              : summary.dateResponses
          }
        />
      ) : summary.metricType === MetricType.CHECKIN_ANSWER_EMOJI ? (
        <EmojiResponsesSummary
          responses={
            collapsed
              ? summary.emojiResponses.slice(0, DEFAULT_RESPONSES_COUNT)
              : summary.emojiResponses
          }
        />
      ) : summary.metricType === MetricType.CHECKIN_ANSWER_STAR_RATING ? (
        <StarRatingResponsesSummary
          responses={
            collapsed
              ? summary.starRatingResponses.slice(0, DEFAULT_RESPONSES_COUNT)
              : summary.starRatingResponses
          }
        />
      ) : null}

      {showCollapse && (
        <Button
          className={s.button}
          startIcon={
            <ArrowForwardIosRounded
              className={clsx({ [s.arrow]: true, [s.open]: !collapsed })}
            />
          }
          onClick={handleCollapseClick}
          children={collapsed ? "View all answers" : "Collapse"}
          disableRipple
        />
      )}

      <Typography
        className={clsx({
          [s.info]: true,
          [s.indent]:
            summary.metricType !== MetricType.CHECKIN_ANSWER_LONG_TEXT &&
            summary.metricType !== MetricType.CHECKIN_ANSWER_NUMBER,
        })}
      >
        {summary.count} responses &bull; updated {summary.fromNow}
      </Typography>
    </Card>
  );
}
