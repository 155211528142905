import clsx from "clsx";
import React, { useState } from "react";
import {
  AppBar,
  AppBarProps,
  Toolbar,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { polyfillCSS } from "../../utils/css";
import { BackButton } from "../button/BackButton";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { headerHeight } from "../app/AppBar";

const useStyles = makeStyles((theme) => {
  const computedHeaderHeight = headerHeight(theme);

  return {
    root: {
      backgroundColor: "transparent",
    },
    whiteBackground: {
      backgroundColor: theme.palette.common.white,
    },
    withZIndex: {
      zIndex: theme.zIndex.appBar,
    },
    bar: {
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      //to make activityBar same height as AppBAr
      height: computedHeaderHeight,
      paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
      minHeight: polyfillCSS(
        `calc(${theme.spacing(7)} + var(--safe-area-inset-top))`,
      ),

      [theme.breakpoints.up("md")]: {
        minHeight: polyfillCSS(
          `calc(${theme.spacing(8)} + var(--safe-area-inset-top))`,
        ),
      },
    },

    checkbox: {
      color: theme.palette.text.secondary,
      "& .Mui-checked": {
        "& .MuiSvgIcon-root": {
          color: theme.palette.secondary.main,
        },
        "& + .MuiFormControlLabel-label": {
          color: theme.palette.text.secondary,
        },
      },
    },

    textWrapper: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "hidden",
    },

    subtitle: {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },

    title: {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.common.black,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },

    closeButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,

      "&:hover": {
        color: theme.palette.text.primary,
      },

      "&:last-child": {
        marginRight: theme.spacing(-1),
      },
    },

    label: {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.common.black,
    },
  };
});

export interface ActivityBarProps extends AppBarProps {
  coach?: boolean;
  responsesOnly?: boolean;
  onChangeResponsesOnly?: (responsesOnly: boolean) => void;
  subtitle?: string;
  title?: string;
  backButtonTooltip?: React.ReactNode;
  onCloseClick?: () => void;
  backgroundStyle?: string;
  action?: any;
}

export const ActivityBar = React.forwardRef<HTMLElement, ActivityBarProps>(
  (props: ActivityBarProps, ref) => {
    const {
      className,
      coach = false,
      responsesOnly,
      onChangeResponsesOnly,
      subtitle,
      title,
      onCloseClick,
      backButtonTooltip,
      backgroundStyle,
      action,
      ...other
    } = props;
    const s = useStyles();
    const isMobile = useMediaMobile();

    const handleResponsesOnlyChange = React.useCallback(
      (event, checked) => {
        onChangeResponsesOnly(checked);
      },
      [onChangeResponsesOnly],
    );

    const [backButton, setBackButton] = useState(true);

    return (
      <AppBar
        className={clsx(
          !isMobile && s.withZIndex,
          s.root,
          className,
          title && s.whiteBackground,
        )}
        ref={ref}
        {...other}
      >
        <Toolbar
          className={clsx(s.bar, backgroundStyle, title && s.whiteBackground)}
        >
          {backButton && (
            <BackButton
              tooltip={backButtonTooltip}
              onClick={() => {
                onCloseClick();
                setBackButton(false);
              }}
            />
          )}

          <div className={s.textWrapper}>
            <Typography
              className={s.subtitle}
              variant="body1"
              component="h4"
              children={subtitle}
            />
            <Typography
              className={s.title}
              variant="h3"
              component="h1"
              children={title}
            />
          </div>

          {action && action}

          {coach && (
            <FormControlLabel
              label={
                <Typography
                  className={s.label}
                  children="Only show responses"
                />
              }
              labelPlacement="start"
              control={
                <Switch
                  checked={responsesOnly}
                  onChange={handleResponsesOnlyChange}
                />
              }
            />
          )}
        </Toolbar>
      </AppBar>
    );
  },
);
