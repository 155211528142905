import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  IconButton,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import omit from "lodash.omit";

import { polyfillCSS } from "../../utils/css";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    padding: theme.spacing(4, 3),
    paddingTop: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-top))`,
    ),
  },

  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },

  headerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
    marginTop: theme.spacing(0.5),
  },

  description: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },

  close: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

export interface BaseDialogProps
  extends Omit<DialogProps, "classes" | "title"> {
  header?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: string;
  description?: string | React.ReactNode;
  closeDisabled?: boolean;
  showClose?: boolean;
  classNameTitle?: string;
  classes?: DialogProps["classes"] &
    Partial<Record<"description" | "content" | "header", string>>;
}

export function BaseDialog(props: BaseDialogProps) {
  const {
    className,
    title,
    subtitle,
    description,
    onClose,
    closeDisabled = false,
    showClose = true,
    children,
    maxWidth,
    header,
    classNameTitle,
    classes: baseDialogClasses = {},
    ...other
  } = props;
  const s = useStyles();
  const { header: headerClassName, ...classes } = baseDialogClasses;

  const handleCloseButtonClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      onClose={onClose}
      classes={omit(classes, "description")}
      {...other}
    >
      <Container
        className={clsx(s.content, classes?.content, classes?.container)}
        maxWidth={maxWidth}
      >
        {header ||
          ((title || subtitle || showClose) && (
            <Box className={clsx(s.header, headerClassName)}>
              <Box className={s.headerText}>
                {title && (
                  <Typography
                    variant="h2"
                    className={clsx(s.title, classNameTitle)}
                    children={title}
                  />
                )}
                {subtitle && (
                  <Typography
                    variant="h3"
                    className={s.subtitle}
                    children={subtitle}
                  />
                )}
              </Box>
              {showClose && (
                <IconButton
                  className={s.close}
                  color="primary"
                  onClick={handleCloseButtonClick}
                  children={<Close />}
                  disabled={closeDisabled}
                  size="large"
                />
              )}
            </Box>
          ))}
        {description && (
          <Typography
            variant="body1"
            className={clsx(s.description, classes?.description)}
            children={description}
          />
        )}
        {children}
      </Container>
    </Dialog>
  );
}
