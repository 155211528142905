import clsx from "clsx";
import React, { ReactNode } from "react";
import {
  Container,
  ContainerProps,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      background: theme.palette.background.paper,
      [theme.breakpoints.up("sm")]: {
        background:
          'url("https://s.stridist.com/img/atharva-tulsi-2jA_YoQuvaA-unsplash.png") no-repeat fixed 50%',
        backgroundSize: "cover",
      },
    },
  },
  root: {
    paddingTop: "20vh",
    paddingBottom: "10vh",
  },
  card: {
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
      paddingBottom: theme.spacing(0),
    },
  },
  header: {
    paddingBottom: 0,
  },
  subheader: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(2),
    color: theme.palette.common.black,
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

export interface LoginContainerProps extends Omit<ContainerProps, "title"> {
  title: ReactNode;
  subheader?: ReactNode;
}

export function LoginContainer(props: LoginContainerProps) {
  const { title, subheader, children, className, ...other } = props;
  const s = useStyles();

  return (
    <Container className={clsx(s.root, className)} maxWidth="sm" {...other}>
      <Card className={s.card}>
        <CardHeader
          className={s.header}
          title={title}
          titleTypographyProps={{ variant: "h4", gutterBottom: true }}
          subheader={subheader}
          subheaderTypographyProps={{
            className: s.subheader,
          }}
        />
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  );
}

export function LoginButtons(props) {
  const { children } = props;
  const s = useStyles();

  return <div className={s.buttons}>{children}</div>;
}
