import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ActivityPreviewSections from "../activity-preview/ActivityPreviewSections";
import { ProgramCalendarSidebarQuery$data } from "./__generated__/ProgramCalendarSidebarQuery.graphql";

const filterWorkoutSections = (sections: any) => {
  return sections?.filter((item) => item.type === "workout_section");
};

export interface IActivityPreviewProps {
  result: ProgramCalendarSidebarQuery$data;
}

const WorkoutOverview = (props: IActivityPreviewProps) => {
  const { result } = props;
  const { component } = result;

  const [content, setContent] = useState(
    filterWorkoutSections(JSON.parse(component.content)),
  );

  useEffect(() => {
    setContent(filterWorkoutSections(JSON.parse(result.component.content)));
  }, [result]);

  if (!component) return <></>;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {content.length !== 0 ? (
        content.map((c, index) => (
          <ActivityPreviewSections card={c} index={index} />
        ))
      ) : (
        <Typography fontWeight={500} fontStyle={"italic"}>
          <>
            No exercises yet.
            <br /> Click Edit to create some.
          </>
        </Typography>
      )}
    </Box>
  );
};

export default WorkoutOverview;
