/**
 * @generated SignedSource<<b8d7c411cee992c9e827be193bd1b46e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAssetType = "AUDIO" | "CLIENT_FORM" | "COMPONENT_TEMPLATE" | "FILE" | "IMAGE" | "OTHER" | "VIDEO" | "WORKOUT_EXERCISE" | "%future added value";
export type UpsertCustomAssetInput = {
  assetType: CustomAssetType;
  clientMutationId?: string | null | undefined;
  content: string;
  id?: string | null | undefined;
  metadata?: string | null | undefined;
  name: string;
};
export type useCreateCustomAssetTypeMutation$variables = {
  input: UpsertCustomAssetInput;
};
export type useCreateCustomAssetTypeMutation$data = {
  readonly upsertCustomAsset: {
    readonly asset: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ComponentLibraryAsset_asset" | "ExerciseLibraryExercise_asset">;
    };
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type useCreateCustomAssetTypeMutation = {
  response: useCreateCustomAssetTypeMutation$data;
  variables: useCreateCustomAssetTypeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateCustomAssetTypeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertCustomAssetPayload",
        "kind": "LinkedField",
        "name": "upsertCustomAsset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomAsset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ComponentLibraryAsset_asset"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExerciseLibraryExercise_asset"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateCustomAssetTypeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertCustomAssetPayload",
        "kind": "LinkedField",
        "name": "upsertCustomAsset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomAsset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf06abdd137c99755a50fffe390bc415",
    "id": null,
    "metadata": {},
    "name": "useCreateCustomAssetTypeMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateCustomAssetTypeMutation(\n  $input: UpsertCustomAssetInput!\n) {\n  upsertCustomAsset(input: $input) {\n    clientMutationId\n    asset {\n      ...ComponentLibraryAsset_asset\n      ...ExerciseLibraryExercise_asset\n      id\n    }\n  }\n}\n\nfragment ComponentLibraryAsset_asset on CustomAsset {\n  id\n  assetType\n  content\n}\n\nfragment ExerciseLibraryExercise_asset on CustomAsset {\n  id\n  name\n  content\n  userId\n}\n"
  }
};
})();

(node as any).hash = "3bee8c52f5e07764508bae94ccce4b27";

export default node;
