import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { useParams } from "react-router-dom";
import { AppLayout } from "../../../components/app/AppLayout";
import { ClientLinkInviteScreen } from "../../../components/auth/ClientLinkInviteScreen";
import { AppThemeProvider } from "../../../components/app/ThemeProvider";
import ErrorPage from "../../../components/app/ErrorPage";
import { HttpError } from "found";
import { InviteRouteQuery } from "./__generated__/InviteRouteQuery.graphql";

export function InviteRoute() {
  const { code } = useParams();

  const props = useLazyLoadQuery<InviteRouteQuery>(
    graphql`
      query InviteRouteQuery($code: String!) {
        inviteCode(code: $code) {
          ...ClientLinkInviteScreen_inviteCode
        }

        workspace(inviteCode: $code) {
          ...ThemeProvider_workspace
        }
      }
    `,
    {
      code: code,
    },
  );

  const { inviteCode, workspace } = props;

  return inviteCode ? (
    <AppLayout title="Sign Up" appBar={false}>
      <AppThemeProvider workspace={workspace}>
        <ClientLinkInviteScreen inviteCode={inviteCode} />
      </AppThemeProvider>
    </AppLayout>
  ) : (
    <ErrorPage error={new HttpError(404)} />
  );
}
