import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ActivityStatus_activity$key } from "./__generated__/ActivityStatus_activity.graphql";

const alpha = "30";

type StyleProps = {
  completed: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    margin: theme.spacing(3.5, 0, 4),
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "left",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: "calc((100vw - 100%) / -2)",
      right: "calc((100vw - 100%) / -2)",
      height: "100%",
      zIndex: -1,
      backgroundColor: ({ completed }) =>
        completed
          ? `${theme.palette.activity.checkin}${alpha}`
          : `${theme.palette.text.secondary}${alpha}`,
    },
  },
  lozenge: {
    width: 128,
    height: 30,
    borderRadius: 25,
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ completed }) =>
      completed
        ? `${theme.palette.activity.checkin}`
        : `${theme.palette.text.secondary}`,
  },
  lozengeText: {
    fontSize: 11,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
  text: {
    fontSize: 18,
    color: theme.palette.common.black,
  },
}));

const activityFragment = graphql`
  fragment ActivityStatus_activity on Activity {
    date(raw: true)
    formattedDate: date(utc: true, format: "MMM Do")
    completed
    completedAt(format: "MMM Do")
    enrollment {
      client {
        displayName
      }
    }
  }
`;

function getText(activity): string {
  const clientName = activity?.enrollment?.client?.displayName;

  if (activity.completed) {
    return `${clientName} completed on ${activity.completedAt}`;
  }

  const future = new Date(activity.date) > new Date();

  return `${clientName} ${future ? "will receive" : "received"} on ${
    activity.formattedDate
  }`;
}

export interface ActivityStatusProps extends BoxProps {
  activityRef: ActivityStatus_activity$key;
}

export function ActivityStatus(props: ActivityStatusProps) {
  const { className, activityRef, ...other } = props;
  const activity = useFragment(activityFragment, activityRef);
  const s = useStyles({
    completed: activity.completed,
  });
  const text = getText(activity);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.lozenge}>
        <Typography
          className={s.lozengeText}
          variant="body1"
          component="span"
          children={activity.completed ? "Completed" : "Sent"}
        />
      </Box>
      <Typography
        className={s.text}
        variant="body1"
        component="span"
        children={text}
      />
    </Box>
  );
}
