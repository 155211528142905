import React from "react";

import {
  HistoryBlockContext,
  HistoryBlockUpdater,
  HistoryBlockUnsafe,
} from "./hooks";
import { useHistory } from "../../hooks/useHistory";

export interface HistoryBlockProps {
  message?: string;
  children: React.ReactNode;
}

export const historyBlockDefaultMessage =
  "Are you sure you want to leave this page? Your changes will be lost!";

export function HistoryBlock(props: HistoryBlockProps) {
  const history = useHistory();
  const { children, message = historyBlockDefaultMessage } = props;
  const [dirty, setDirty] = React.useState(false);
  const [cleanup, setCleanup] = React.useState<() => void>();

  const unsafe: HistoryBlockUnsafe = React.useCallback(
    (f) => {
      if (dirty) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(message)) {
          return;
        }
        if (cleanup) {
          cleanup();
        }
        setDirty(() => false);
      }

      return f();
    },
    [cleanup, dirty, message],
  );
  const update: HistoryBlockUpdater = React.useCallback((dirty, cleanup) => {
    setDirty(() => dirty);
    setCleanup(() => cleanup);
  }, []);

  React.useEffect(() => {
    if (dirty) {
      // TODO migrate/update navigation blocking
      const unblock = history.block(() => alert(message));
      return () => unblock();
    }
  }, [dirty, history, message]);

  return (
    <HistoryBlockContext.Provider value={[unsafe, update, dirty]}>
      {children}
    </HistoryBlockContext.Provider>
  );
}
