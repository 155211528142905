import clsx from "clsx";
import React from "react";
import { Dialog, DialogProps, Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker } from "../fields/DatePicker";
import { ISO_DATE_FORMAT, parseDateISOString } from "../../utils/date";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    margin: theme.spacing(1.5),
  },

  picker: {
    "& .MuiPickersDay-dayLabel": {
      fontSize: 16,
      fontWeight: 700,
    },

    "& .MuiPickersCalendar-weekDayLabel": {
      fontSize: 14,
      fontWeight: 700,
      margin: theme.spacing(0, 0.5),
      color: theme.palette.text.secondary,
    },

    "& .MuiPickersDay-today:not(.Mui-selected)": {
      border: 0,
      color: theme.palette.primary.main,
    },

    "& .MuiPickersDay-dayWithMargin": {
      margin: theme.spacing(0, 0.5),
    },

    "& .MuiPickersCalendarHeader-yearSelectionSwitcher": {
      display: "none",
    },

    "& .MuiPickersCalendarHeader-root": {
      position: "relative",
    },

    "& .MuiPickersCalendarHeader-labelContainer": {
      marginInline: "auto",
      marginTop: "10px",
      fontWeight: "bold",
      textTransform: "uppercase",
      zIndex: 10,
    },

    "& .MuiPickersCalendarHeader-switchViewButton": {
      display: "none",
    },

    "& .MuiPickersCalendarHeader-monthTitleContainer": {
      fontSize: 14,
      fontWeight: 700,
      textTransform: "uppercase",

      position: "relative",
      zIndex: 2,

      marginLeft: "auto",
      marginRight: "auto",
    },

    "& .MuiPickersArrowSwitcher-root": {
      position: "absolute",
      display: "flex",
      zIndex: 1,
      justifyContent: "space-between",
      left: 0,
      right: 0,
      top: 0,
      padding: theme.spacing(0, 3),
    },

    "& .MuiPickersArrowSwitcher-iconButton": {
      color: theme.palette.primary.main,
    },

    "& .MuiPickersArrowSwitcher-button": {
      color: theme.palette.primary.main,
    },

    "& .MuiDayCalendar-weekDayLabel": {
      fontWeight: "bold",
      fontSize: 14,
    },
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",

    "& button": {
      fontSize: 18,
      fontWeight: 600,
      margin: theme.spacing(1, 1),
    },
  },

  cancel: {
    color: theme.palette.text.secondary,
  },
}));

export interface CompactDateDialogProps extends DialogProps {
  value?: string;
  onDateChange?: (date: string) => void;
}

export function CompactDateDialog(props: CompactDateDialogProps) {
  const { className, value, onDateChange, ...other } = props;
  const { onClose } = props;
  const s = useStyles();
  const [date, setDate] = React.useState(value);

  const handleCancelClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const handleDoneClick = React.useCallback(() => {
    onDateChange(date);
  }, [date, onDateChange]);

  const handleDateChange = React.useCallback((value) => {
    setDate(dayjs(value).format(ISO_DATE_FORMAT));
  }, []);

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
      }}
      {...other}
    >
      <DatePicker
        className={s.picker}
        value={parseDateISOString(date)}
        onChange={handleDateChange}
      />
      <Box className={s.actions}>
        <Button
          className={s.cancel}
          variant="text"
          color="secondary"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button variant="text" color="primary" onClick={handleDoneClick}>
          Done
        </Button>
      </Box>
    </Dialog>
  );
}
