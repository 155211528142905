import { useMutation } from "react-relay/hooks";
import { graphql } from "react-relay";
import { Element } from "slate";
import { RecordSourceSelectorProxy, ConnectionHandler } from "relay-runtime";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { ElementType } from "../../editor/types/elements";
import { defaultLibraryAssetFilters } from "../../component-library/ComponentLibraryAssetFilter";
import { SOMETHING_WENT_WRONG, CustomAssetType } from "../../../constants";

import { useCreateCustomAssetTypeMutation as CreateAssetMutation } from "./__generated__/useCreateCustomAssetTypeMutation.graphql";

export const upsertAssetMutation = graphql`
  mutation useCreateCustomAssetTypeMutation($input: UpsertCustomAssetInput!) {
    upsertCustomAsset(input: $input) {
      clientMutationId
      asset {
        ...ComponentLibraryAsset_asset
        ...ExerciseLibraryExercise_asset
        id
      }
    }
  }
`;

export const getElementCustomAssetType = (element: any): CustomAssetType => {
  switch (element.type) {
    case ElementType.IMAGE:
    case ElementType.VIDEO:
    case ElementType.AUDIO:
    case ElementType.FILE:
      return (element.type as string).toUpperCase() as CustomAssetType;
  }
};

export const normalizeCustomAsset = ({ id, ...element }: any) => {
  const name = element.name as string;
  const content = JSON.stringify(element);
  const assetType = getElementCustomAssetType(element);

  return { name, assetType, content };
};

export function updateAssetsLibrary(store: RecordSourceSelectorProxy) {
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ComponentLibraryAssetList_custom_assets",
    [],
  );

  if (conn) {
    const types = Object.keys(defaultLibraryAssetFilters);
    const node = store
      .getRootField("upsertCustomAsset")
      .getLinkedRecord("asset");

    if (node && types.includes(node.getValue("assetType") as string)) {
      const edge = ConnectionHandler.createEdge(
        store,
        conn,
        node,
        "CustomAssetsEdge",
      );
      ConnectionHandler.insertEdgeBefore(conn, edge);
    }
  }
}

export function updateExercisesLibrary(store: RecordSourceSelectorProxy) {
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ExerciseLibraryExercisesList_exercises",
    [],
  );

  if (conn) {
    const node = store
      .getRootField("upsertCustomAsset")
      .getLinkedRecord("asset");

    if (
      node &&
      node.getValue("assetType") === CustomAssetType.WORKOUT_EXERCISE
    ) {
      const edge = ConnectionHandler.createEdge(
        store,
        conn,
        node,
        "CustomAssetsEdge",
      );
      ConnectionHandler.insertEdgeBefore(conn, edge);
    }
  }
}

export function useCreateCustomAsset() {
  const [upsertAsset] = useMutation<CreateAssetMutation>(upsertAssetMutation);
  const snackAlert = useSnackAlert();

  return (element: Element) => {
    const { name, assetType, content } = normalizeCustomAsset(element);

    return upsertAsset({
      variables: {
        input: {
          name,
          content,
          assetType,
        },
      },
      onCompleted: (_, errors) => {
        if (errors && errors.length) {
          snackAlert({
            severity: "error",
            message: SOMETHING_WENT_WRONG,
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Asset created.",
          });
        }
      },
      updater: updateAssetsLibrary,
    });
  };
}
