export function saveSelection(): Range | null {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0);
    }
  } else if (document["selection"] && document["selection"].createRange) {
    return document["selection"].createRange();
  }
  return null;
}

export function restoreSelection(range: Range) {
  if (range && window.getSelection) {
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (document["selection"] && range["select"]) {
    range["select"]();
  }
}

export function emulateTextType(
  text: string,
  el: any = document.activeElement,
) {
  const [start, end] = [el.selectionStart, el.selectionEnd];
  el.setRangeText(text, start, end, "select");
  el.selectionStart = el.selectionEnd;
}
