import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { AppLayout } from "../../../../components/app/AppLayout";
import { programsBreadcrumb } from "../utils/common";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { ClientProgram } from "../../../../components/program/ClientProgram";
import { useParams, useSearchParams } from "react-router-dom";
import { ClientProgramsProgramRouteQuery } from "./__generated__/ClientProgramsProgramRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

export function ClientProgramsProgramRoute() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const week = searchParams.get("week");

  const props = useLazyLoadQuery<ClientProgramsProgramRouteQuery>(
    graphql`
      query ClientProgramsProgramRouteQuery($program: String!, $week: Int!) {
        enrollment(client: "$me", program: $program) {
          ...ClientProgram_enrollment @arguments(week: $week)
          program {
            ...RefreshSlug
            name
            description
            imageURL
          }
        }
      }
    `,
    {
      program: params.program,
      week: Number(week) ?? 1,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { enrollment } = props;

  return (
    enrollment && (
      <AppLayout
        appDrawer={false}
        title={enrollment?.program?.name || "Program"}
        subtitle={enrollment?.program?.description}
        trackInfo={{
          name: "Client - Program",
        }}
        breadcrumbs={[programsBreadcrumb]}
        cover={enrollment?.program?.imageURL}
      >
        <RefreshSlug nodesRef={[enrollment?.program]} />
        <ClientProgram enrollmentRef={enrollment} />
      </AppLayout>
    )
  );
}
