/**
 * @generated SignedSource<<f024d6677585295181f4a708f60f3737>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationReadInput = {
  activityFeedbackId?: string | null | undefined;
  clientFormId?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  groupPostComment?: string | null | undefined;
  groupPostId?: string | null | undefined;
  id?: string | null | undefined;
};
export type GroupPostCommentsListItemNotificationReadMutation$variables = {
  input: NotificationReadInput;
};
export type GroupPostCommentsListItemNotificationReadMutation$data = {
  readonly notificationRead: {
    readonly notification: {
      readonly activityFeedbackId: string;
      readonly id: string;
    };
  } | null | undefined;
};
export type GroupPostCommentsListItemNotificationReadMutation = {
  response: GroupPostCommentsListItemNotificationReadMutation$data;
  variables: GroupPostCommentsListItemNotificationReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NotificationReadPayload",
    "kind": "LinkedField",
    "name": "notificationRead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "notification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activityFeedbackId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupPostCommentsListItemNotificationReadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupPostCommentsListItemNotificationReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a68652ad99bf3d84ac4206af448ac7f3",
    "id": null,
    "metadata": {},
    "name": "GroupPostCommentsListItemNotificationReadMutation",
    "operationKind": "mutation",
    "text": "mutation GroupPostCommentsListItemNotificationReadMutation(\n  $input: NotificationReadInput!\n) {\n  notificationRead(input: $input) {\n    notification {\n      id\n      activityFeedbackId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9531aa4a20b57dc0533af3875ea1ab3";

export default node;
