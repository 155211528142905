import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TElement, useEditorRef } from "@udecode/plate-common";
import { MultipleChoiceOption } from "./MultipleChoiceOption";
import { ReactEditor } from "slate-react";
import { EditorElementView } from "../../utils/editorUtils";
import { check } from "prettier";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import { Icons } from "../../../plate-ui/Icons/icons";
import CheckInTextfield from "../checkInQuestions/CheckInTextfield";

type OptionsArr = {
  option: string;
}[];

interface DragItem {
  type: string;
  index: number;
}
export type IMultipleItemQuestionBoxAnswer = {
  options?: string[];
  selected?: string;
  selectedMany?: string[];
  hasOther?: boolean;
  otherValue?: string;
};

interface IMultipleItemsQuestionBoxProps
  extends ICheckInQuestionPropsBase<IMultipleItemQuestionBoxAnswer> {
  type: "radio" | "checkbox";
}

const MultipleItemsQuestionBox = ({
  type,
  element,
  handleSetNode,
  view,
  disableAnswer,
}: IMultipleItemsQuestionBoxProps) => {
  const theme = useTheme();

  const editable = view === EditorElementView.Coach;

  const answer = element.answer as IMultipleItemQuestionBoxAnswer;

  const options = answer?.options || [""];

  // selectedMany is an array that should contain all the selected options
  const selectedMany = answer?.selectedMany || [];

  // selected is a string that should contain only selected 'choice(radio)' option
  const selected = answer?.selected || "";

  const multipleValues = type === "checkbox";

  const isOtherEnabled = answer?.hasOther;

  const otherValue = answer?.otherValue || "";

  const editor = useEditorRef();
  const isReadOnly = ReactEditor.isReadOnly(editor as any);

  const handleAddClick = () => {
    const updatedItems = [...options];
    updatedItems.splice(options.length + 1, 0, "");
    handleSetNodeAnswer("options", updatedItems);
  };

  const handleRemoveClick = (index: number) => {
    const updatedItems = [...options];
    updatedItems.splice(index, 1);
    handleSetNodeAnswer("options", updatedItems);
  };

  const handleAddOther = () => {
    handleSetNodeAnswer("hasOther", true);
  };

  const handleRemoveOther = () => {
    handleSetNodeAnswer("hasOther", false);
  };

  const handleOnMove = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const _options = [...options];
      _options.splice(dragIndex, 0, _options.splice(hoverIndex, 1)[0]);
      handleSetNodeAnswer("options", _options);
    },
    [options],
  );
  const handleSetNodeAnswer = (key, value) => {
    handleSetNode(
      {
        answer: {
          options: options,
          selectedMany: selectedMany,
          selected: selected,
          hasOther: isOtherEnabled,
          otherValue: otherValue,
          [key]: value,
        },
      },
      // NOTE: Use update with timeout if changed values is typed from input to prevent from multiple updates
      key === "otherValue" || key === "options",
    );
  };

  const handleOptionValueChange = React.useCallback(
    (e, index: number) => {
      const updatedItems = [...options];
      updatedItems[index] = e.target.value;
      handleSetNodeAnswer("options", updatedItems);
    },
    [handleSetNodeAnswer],
  );

  const handleOptionCheckedChange = React.useCallback(
    (checked: boolean, option: string) => {
      let newSelectedMany = selectedMany;
      if (checked) {
        newSelectedMany = [...selectedMany, option];
      } else {
        newSelectedMany = selectedMany.filter((item) => item !== option);
      }
      handleSetNodeAnswer("selectedMany", newSelectedMany);

      // Additionally set single selected option for radio
      if (!multipleValues) {
        const newSelected = checked ? option : null;
        handleSetNodeAnswer("selected", newSelected);
      }
    },
    [selectedMany, options, handleSetNodeAnswer],
  );

  const handleOtherOptionCheckedChange = React.useCallback(
    (checked: boolean) => {
      if (checked && !otherValue) return;
      handleOptionCheckedChange(checked, otherValue);
    },
    [options, otherValue, handleOptionCheckedChange],
  );

  const isOptionChecked = React.useCallback(
    (option: string) => {
      if (editable) return false;
      else if (multipleValues) {
        return selectedMany.includes(option);
      }
      return selected === option;
    },
    [selected, selectedMany],
  );

  const handleOtherChange = React.useCallback(
    (e) => {
      handleSetNodeAnswer("otherValue", e.target.value);
    },
    [handleSetNodeAnswer],
  );

  const isOptionVisible = React.useCallback(
    (option: string) => {
      return editable || option.trim().length !== 0;
    },
    [editable],
  );

  return (
    <Box display={"flex"} gap={2} flexDirection={"column"} paddingBottom={2}>
      {options.map((option, index) => (
        <Box display={isOptionVisible(option) ? "block" : "none"} key={index}>
          <MultipleChoiceOption
            index={index}
            checked={isOptionChecked(option)}
            onChange={(checked) => handleOptionCheckedChange(checked, option)}
            handleAddClick={handleAddClick}
            type={type}
            option={option}
            handleOptionValueChange={handleOptionValueChange}
            handleRemoveClick={handleRemoveClick}
            onMoveEnd={handleOnMove}
            isReadOnly={isReadOnly}
            view={view}
            disableAnswer={disableAnswer}
          ></MultipleChoiceOption>
        </Box>
      ))}
      <>
        {isOtherEnabled && (
          <Box display={"flex"} alignItems={"center"}>
            {!isReadOnly && (
              <IconButton sx={{ visibility: "hidden" }}>
                <AddCircleOutlineIcon />
              </IconButton>
            )}

            <DisabledCheckInTooltip visible={editable}>
              <Box>
                {type === "radio" && (
                  <Radio
                    disabled={editable || disableAnswer}
                    checked={otherValue && isOptionChecked(otherValue)}
                    onChange={(e, checked) =>
                      handleOtherOptionCheckedChange(checked)
                    }
                    sx={{ mr: 0.5, ml: -1.25 }}
                  />
                )}

                {type === "checkbox" && (
                  <Checkbox
                    disabled={editable || disableAnswer}
                    checked={otherValue && isOptionChecked(otherValue)}
                    onChange={(e, checked) =>
                      handleOtherOptionCheckedChange(checked)
                    }
                    sx={{ mr: 0.5, ml: -1.25 }}
                  />
                )}
              </Box>
            </DisabledCheckInTooltip>
            <DisabledCheckInTooltip visible={editable}>
              <CheckInTextfield
                variant="standard"
                value={otherValue || ""}
                onChange={handleOtherChange}
                fullWidth
                placeholder={"Other..."}
                InputProps={{
                  readOnly: editable || disableAnswer,
                  disableUnderline: editable || disableAnswer,
                  sx: {
                    ...(editable && {
                      pointerEvents: "none",
                    }),
                  },
                }}
              />
            </DisabledCheckInTooltip>
            {!isReadOnly && (
              <IconButton sx={{ ml: 0.5 }} onClick={() => handleRemoveOther()}>
                <Icons.bin color={theme.palette.text.primary} />
              </IconButton>
            )}
          </Box>
        )}
        {!isReadOnly && (
          <Box display={"flex"} alignItems={"center"}>
            <IconButton sx={{ visibility: "hidden" }}>
              <AddCircleOutlineIcon />
            </IconButton>

            {type === "radio" && (
              <Radio disabled={editable} sx={{ mr: 0.5, ml: -1.25 }} />
            )}

            {type === "checkbox" && (
              <Checkbox disabled={editable} sx={{ mr: 0.5, ml: -1.25 }} />
            )}

            <Button
              disableRipple
              variant="text"
              sx={{ px: 0 }}
              onClick={() => handleAddClick()}
            >
              <Typography color={theme.palette.text.primary}>
                Add option{" "}
              </Typography>
            </Button>
            {!isOtherEnabled && (
              <>
                <Typography color={theme.palette.text.secondary}>or</Typography>
                <Button
                  disableRipple
                  variant="text"
                  onClick={() => handleAddOther()}
                >
                  <Typography color={theme.palette.primary.main}>
                    add "Other"
                  </Typography>
                </Button>
              </>
            )}
          </Box>
        )}
      </>
    </Box>
  );
};

export default MultipleItemsQuestionBox;
