import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 14,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      marginBottom: theme.spacing(2),
    },
  },
}));

export interface FieldLabelProps extends TypographyProps {}

export function FieldLabel(props: FieldLabelProps) {
  const { className, children, ...other } = props;
  const s = useStyles();

  return (
    <Typography variant="h4" className={clsx(s.root, className)} {...other}>
      {children}
    </Typography>
  );
}
