/**
 * @generated SignedSource<<5e5d65cefe2cbc240824f685f939d594>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MeasurementType = "BODYFAT" | "BODYWEIGHT" | "CALF" | "CHEST" | "HIP" | "SHOULDER" | "STEP_COUNT" | "THIGH" | "UPPER_ARM" | "WAIST" | "%future added value";
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
export type CreateClientMetricInput = {
  clientId: string;
  clientMutationId?: string | null | undefined;
  date?: string | null | undefined;
  measurement: number;
  name: string;
  type: MeasurementType;
  unit: string;
};
export type ClientMeasurementRowsCreateClientMetricMutation$variables = {
  input: CreateClientMetricInput;
};
export type ClientMeasurementRowsCreateClientMetricMutation$data = {
  readonly createClientMetric: {
    readonly metric: {
      readonly activityDate: string | null | undefined;
      readonly value: {
        readonly measurement?: number;
        readonly unit?: MeasurementUnit;
      };
      readonly x: string | null | undefined;
    };
  } | null | undefined;
};
export type ClientMeasurementRowsCreateClientMetricMutation = {
  response: ClientMeasurementRowsCreateClientMetricMutation$data;
  variables: ClientMeasurementRowsCreateClientMetricMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": "x",
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "YYYY-MM-DD"
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(format:\"YYYY-MM-DD\")"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "activityDate",
  "storageKey": "activityDate(raw:true)"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    }
  ],
  "type": "CheckinAnswerMeasurementValue",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientMeasurementRowsCreateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClientMetricPayload",
        "kind": "LinkedField",
        "name": "createClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientMeasurementRowsCreateClientMetricMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateClientMetricPayload",
        "kind": "LinkedField",
        "name": "createClientMetric",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientMetric",
            "kind": "LinkedField",
            "name": "metric",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c88fcc59f361d7cabc0a4705f615171",
    "id": null,
    "metadata": {},
    "name": "ClientMeasurementRowsCreateClientMetricMutation",
    "operationKind": "mutation",
    "text": "mutation ClientMeasurementRowsCreateClientMetricMutation(\n  $input: CreateClientMetricInput!\n) {\n  createClientMetric(input: $input) {\n    metric {\n      x: activityDate(format: \"YYYY-MM-DD\")\n      activityDate(raw: true)\n      value {\n        __typename\n        ... on CheckinAnswerMeasurementValue {\n          measurement\n          unit\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21c3b9c87f05a4717c9ef2aed92649a8";

export default node;
