/**
 * @generated SignedSource<<258e6fac9b02f889a76901ea671141f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ComponentTemplateLibrary_template$data = ReadonlyArray<{
  readonly category: string | null | undefined;
  readonly id: string;
  readonly private: boolean;
  readonly title: string;
  readonly " $fragmentType": "ComponentTemplateLibrary_template";
}>;
export type ComponentTemplateLibrary_template$key = ReadonlyArray<{
  readonly " $data"?: ComponentTemplateLibrary_template$data;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentTemplateLibrary_template">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ComponentTemplateLibrary_template",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "private",
      "storageKey": null
    }
  ],
  "type": "ComponentTemplate",
  "abstractKey": null
};

(node as any).hash = "8561992b4bba06303d4bf704fcd17887";

export default node;
