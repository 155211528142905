import clsx from "clsx";
import React from "react";
import { Input, InputProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { SelectComponentIconsMenu } from "../menu/SelectComponentIconsMenu";
import { ComponentType } from "../../constants";

import { CoachComponentTitle_component$key } from "./__generated__/CoachComponentTitle_component.graphql";
import { ComponentIcon } from "./ComponentIcon";

type StyleProps = {
  type: ComponentType;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "baseline",
  },

  input: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    padding: 0,
    lineHeight: 1.25,

    "& ::before": {
      border: "none",
    },
  },

  icon: {
    marginRight: theme.spacing(1.5),
    cursor: ({ type }) =>
      type === ComponentType.MESSAGE ? "default" : "pointer",
  },

  iconsMenu: {
    width: theme.spacing(40),
    zIndex: "9999 !important" as any,
  },
}));

const componentFragment = graphql`
  fragment CoachComponentTitle_component on Component
  @argumentDefinitions(draft: { type: "Boolean!", defaultValue: false }) {
    ...ComponentIcon_component
    ...SelectComponentIcons_component

    type
    title(draft: $draft)
  }
`;

export interface CoachComponentTitleProps extends InputProps {
  component: CoachComponentTitle_component$key;
}

export function CoachComponentTitle(props: CoachComponentTitleProps) {
  const {
    className,
    component: componentRef,
    onChange,
    defaultValue,
    ...other
  } = props;
  const component = useFragment(componentFragment, componentRef);
  const [title, setTitle] = React.useState(component.title);
  const s = useStyles({ type: component.type as ComponentType });
  const [reRendered, setReRendered] = React.useState(false);

  const iconsMenuState = usePopupState({
    variant: "popover",
    popupId: "iconsMenu",
  });

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTitle(event.currentTarget.value);

      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    setTimeout(() => {
      setReRendered(true);
    }, 5);
  }, []);

  return (
    <>
      <Input
        disableUnderline
        className={clsx(s.root, className)}
        value={title === defaultValue ? "" : title}
        disabled={!reRendered}
        inputProps={{ className: s.input }}
        onChange={handleChange}
        startAdornment={
          <ComponentIcon
            componentRef={component}
            className={s.icon}
            size="small"
            {...(component.type !== ComponentType.MESSAGE
              ? bindTrigger(iconsMenuState)
              : {})}
          />
        }
        {...other}
      />
      <SelectComponentIconsMenu
        {...bindMenu(iconsMenuState)}
        className={s.iconsMenu}
        componentRef={component}
        onSubmit={iconsMenuState.close}
      />
    </>
  );
}
