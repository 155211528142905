/**
 * @generated SignedSource<<feb809dbb58efe614d882ed0e1c93684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrainingSummary_activity$data = {
  readonly clientId: string;
  readonly content: string;
  readonly endWorkout: string | null | undefined;
  readonly id: string;
  readonly startWorkout: string | null | undefined;
  readonly " $fragmentType": "TrainingSummary_activity";
};
export type TrainingSummary_activity$key = {
  readonly " $data"?: TrainingSummary_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrainingSummary_activity">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrainingSummary_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startWorkout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endWorkout",
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": null
};

(node as any).hash = "e74faeeb9dc9e628f3248c156db1a17f";

export default node;
