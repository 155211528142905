import { alpha } from "@mui/material";
import { ComponentType } from "../../../constants";
import { colorSystem } from "../../../theme";

export const getColorByComponentType = (
  type: ComponentType,
  opacity: number = 1,
) => {
  switch (type) {
    case ComponentType.LESSON:
      return alpha(colorSystem.blue1, opacity);
    case ComponentType.HABIT:
      return alpha(colorSystem.yellow, opacity);
    case ComponentType.WORKOUT:
      return alpha(colorSystem.primary, opacity);
    case ComponentType.CHECKIN:
      return alpha(colorSystem.green2, opacity);
    case ComponentType.MESSAGE:
      return alpha(colorSystem.orange, opacity);

    default:
      return "unset";
  }
};
