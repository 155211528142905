/**
 * @generated SignedSource<<96838c598dddef2470315de1a7d36fed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientSettingsDialog_client$data = {
  readonly id: string;
  readonly notificationsSetting: {
    readonly dailyUpdate: boolean;
    readonly newMessageEmail: boolean;
    readonly newResponseEmail: boolean;
    readonly weeklyUpdate: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsDialog_notificationsSetting">;
  } | null | undefined;
  readonly rawDisplayName: string | null | undefined;
  readonly tag: {
    readonly id: string;
    readonly title: string;
  } | null | undefined;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsForm_settings">;
  readonly " $fragmentType": "ClientSettingsDialog_client";
};
export type ClientSettingsDialog_client$key = {
  readonly " $data"?: ClientSettingsDialog_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsDialog_client">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientSettingsDialog_client",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSettingsForm_settings"
    },
    (v0/*: any*/),
    {
      "alias": "rawDisplayName",
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": "displayName(raw:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tag",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationsSetting",
      "kind": "LinkedField",
      "name": "notificationsSetting",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientSettingsDialog_notificationsSetting"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newMessageEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newResponseEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weeklyUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dailyUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "150a2b3fd977316d2306f5efe8ef5843";

export default node;
