import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  ClientCircularProgress,
  ClientCircularProgressProps,
} from "./ClientCircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {},
  circleTitle: {
    position: "absolute",
    top: 0,
    left: 5,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    fontSize: 13,
  },
}));

export interface EnrollmentScoreProps {
  score: number;
  className?: string;
  size?: ClientCircularProgressProps["size"];
}

export function EnrollmentScore(props: EnrollmentScoreProps) {
  const { score, className, size = "smaller" } = props;
  const s = useStyles();

  return (
    <ClientCircularProgress className={className} value={score} size={size}>
      <Typography
        className={s.circleTitle}
        variant="body1"
        color="textSecondary"
        component="div"
      >
        <span>{score}</span>
        <span>%</span>
      </Typography>
    </ClientCircularProgress>
  );
}
