/**
 * @generated SignedSource<<881611f34145a884ff2923548725a54e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectableClient_client$data = {
  readonly displayName: string | null | undefined;
  readonly email: string | null | undefined;
  readonly photoURL: string | null | undefined;
  readonly " $fragmentType": "SelectableClient_client";
};
export type SelectableClient_client$key = {
  readonly " $data"?: SelectableClient_client$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectableClient_client">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectableClient_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bcccc68a01fc8b7c89f18696e3d18534";

export default node;
