import React from "react";

export const useExternalScript = (
  src?: string,
  initialize?: () => boolean,
  enabled = true,
) => {
  const [loaded, setLoaded] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  const handleInitialize = React.useCallback(() => {
    const success = initialize();

    if (success) {
      setInitialized(true);
    }
  }, [initialize]);

  const handleLoaded = React.useCallback(() => {
    setLoaded(true);
    handleInitialize();
  }, [handleInitialize]);

  React.useEffect(() => {
    if (src && enabled) {
      const scriptLoaded = document.querySelector(`script[src="${src}"]`);

      if (scriptLoaded) {
        if (!loaded) {
          handleLoaded();
        }
      } else {
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.addEventListener("load", handleLoaded);
        script.src = src;

        document.body.appendChild(script);
      }
    }
  }, [enabled, handleLoaded, initialize, loaded, src]);

  React.useEffect(() => {
    if (loaded && initialize && !initialized) {
      const timer = setTimeout(() => handleInitialize, 250);

      return () => clearTimeout(timer);
    }
  }, [handleInitialize, initialize, initialized, loaded]);

  return (loaded && (initialized || !initialize)) || !enabled;
};
