/**
 * @generated SignedSource<<0abb2fdb5ac20868ec3993170a98d5d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MeasurementUnit = "CM" | "IN" | "KG" | "LBS" | "PERCENTAGE" | "STEPS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientMeasurementItem_metrics$data = ReadonlyArray<{
  readonly value: {
    readonly measurement?: number;
    readonly unit?: MeasurementUnit;
  };
  readonly " $fragmentType": "ClientMeasurementItem_metrics";
}>;
export type ClientMeasurementItem_metrics$key = ReadonlyArray<{
  readonly " $data"?: ClientMeasurementItem_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementItem_metrics">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientMeasurementItem_metrics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "value",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "measurement",
              "storageKey": null
            }
          ],
          "type": "CheckinAnswerMeasurementValue",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientMetric",
  "abstractKey": null
};

(node as any).hash = "f210b50a3b16cc975b567b0ce959f6aa";

export default node;
