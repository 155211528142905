import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachProgramsListQuery } from "./__generated__/CoachProgramsListQuery.graphql";
import { ProgramStatus } from "../../../../constants";
import { CoachProgramsListScreen } from "../../../../components/coach-programs/CoachProgramsListScreen";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import { ProgramListFilterContext } from "../../../../contexts/ProgramListFilterContext";

export const CoachProgramsList = ({ status }: { status: ProgramStatus }) => {
  const filterContext = useContext(ProgramListFilterContext);

  const props = useLazyLoadQuery<CoachProgramsListQuery>(
    graphql`
      query CoachProgramsListQuery(
        $status: ProgramStatus
        $orderBy: ProgramSortBy
        $folder: ID
      ) {
        ...CoachProgramsListScreen_root
          @arguments(status: $status, orderBy: $orderBy, folder: $folder)
      }
    `,
    {
      folder: filterContext.folder ?? null,
      orderBy: filterContext.sortKey ?? "NAME_ASC",
      status: status,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );
  return <CoachProgramsListScreen rootRef={props} status={status} />;
};

export const CoachProgramsPublishedRoute = () => {
  return <CoachProgramsList status={ProgramStatus.PUBLISHED} />;
};
export const CoachProgramsDraftRoute = () => {
  return <CoachProgramsList status={ProgramStatus.DRAFT} />;
};
export const CoachProgramsArchivedRoute = () => {
  return <CoachProgramsList status={ProgramStatus.ARCHIVED} />;
};
