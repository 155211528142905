import clsx from "clsx";
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { DatePicker, DatePickerProps } from "../fields/DatePicker";

import { BaseDialog, BaseDialogProps } from "./BaseDialog";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    width: 524,
  },

  datePicker: {
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(7),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    marginTop: theme.spacing(2),
  },
}));

export interface DateDialogProps extends BaseDialogProps {
  ConfirmButtonProps?: ButtonProps;
  DatePickerProps?: Partial<DatePickerProps>;
  date?: Date | null;
  onDateChange?: (date: Date | null) => void;
}

export function DateDialog(props: DateDialogProps) {
  const {
    className,
    onClose,
    ConfirmButtonProps,
    DatePickerProps,
    date,
    onDateChange,
    ...other
  } = props;
  const s = useStyles();
  const [startDate, setStartDate] = React.useState(date || new Date());

  const handleDateChange = React.useCallback((date) => {
    setStartDate(date);
  }, []);

  const resetState = React.useCallback(() => {
    setStartDate(new Date());
  }, []);

  const handleClose = React.useCallback(
    (event) => {
      resetState();
      onClose(event, "backdropClick");
    },
    [onClose, resetState],
  );

  const handleConfirmClick = React.useCallback(
    (event) => {
      if (onDateChange) {
        onDateChange(startDate);
      }

      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose, onDateChange, startDate],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Choose date"
      onClose={handleClose}
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <DatePicker
        className={s.datePicker}
        value={startDate}
        onChange={handleDateChange}
        {...DatePickerProps}
      />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        onClick={handleConfirmClick}
        {...ConfirmButtonProps}
      >
        Use this date
      </Button>
    </BaseDialog>
  );
}
