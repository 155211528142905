import { graphql } from "react-relay/hooks";

import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { useConfiguredMutation } from "../../../hooks/useConfiguredMutation";

import { RemoveGroupMemberMutation } from "./__generated__/RemoveGroupMemberMutation.graphql";

const mutation = graphql`
  mutation RemoveGroupMemberMutation($input: RemoveGroupMemberInput!) {
    removeGroupMember(input: $input) {
      clientMutationId
      removedGroupMemberId
    }
  }
`;

export const useRemoveGroupMemberMutation = () => {
  const snackAlert = useSnackAlert();

  return useConfiguredMutation<RemoveGroupMemberMutation>(
    mutation,
    (config) => ({
      configs: [
        {
          type: "RANGE_DELETE",
          parentID: config.variables.input.groupId,
          connectionKeys: [
            {
              key: "GroupMembersList_members",
            },
          ],
          pathToConnection: [config.variables.input.groupId, "members"],
          deletedIDFieldName: "removedGroupMemberId",
        },
      ],

      updater(store) {
        const node = store.get(config.variables.input.groupId);
        const membersCount = (node.getValue("membersCount") as number) - 1;

        node.setValue(membersCount, "membersCount");
      },

      onSuccess: () =>
        snackAlert({
          severity: "success",
          message: "Member removed from group.",
        }),
    }),
  );
};
