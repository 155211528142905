import clsx from "clsx";
import React from "react";
import { Avatar, AvatarProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 48,
    height: 48,
    [theme.breakpoints.up("md")]: {
      width: 56,
      height: 56,
      marginRight: theme.spacing(1),
    },
  },
}));

export interface CardAvatarProps extends AvatarProps {}

export function CardAvatar(props: CardAvatarProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return <Avatar className={clsx(s.root, className)} {...other} />;
}
