import React from "react";
import { CardMedia } from "../card/CardMedia";
import { Box } from "@mui/material";
import { WorkoutExerciseImage } from "./types";
import { getCloudFlareSources, isVideoStream } from "../../utils/component";
import ReactPlayer from "react-player";

type WorkoutCardMediaProps = {
  coverImage?: boolean | undefined;
  coverImageIndex?: number;
  handleMediaClick?: (e) => void;
  classNameContainer: string;
  coverImageUrl: string;
  classNameCoverImage: string;
  hasImages: boolean;
  images: WorkoutExerciseImage[];
  classNameHasImagesContainer: string;
  classNameHasImagesImage: string;
  video?: any;
  videoClassName: string;
};

const WorkoutCardMedia = (props: WorkoutCardMediaProps) => {
  const {
    coverImage,
    coverImageIndex,
    handleMediaClick,
    coverImageUrl,
    hasImages,
    images,
    classNameContainer,
    classNameCoverImage,
    classNameHasImagesContainer,
    classNameHasImagesImage,
    video,
    videoClassName,
  } = props;

  const videoSource = video
    ? isVideoStream(video)
      ? getCloudFlareSources(video)[2].src
      : video
    : null;

  return (
    <div className={classNameContainer}>
      {coverImage && !video && (
        <CardMedia
          data-media-index={coverImageIndex}
          onClick={handleMediaClick}
          fit={false}
          className={classNameCoverImage}
          image={coverImageUrl}
        />
      )}
      {videoSource && (
        <ReactPlayer className={videoClassName} url={videoSource} controls />
      )}
      {hasImages && (
        <Box className={classNameHasImagesContainer}>
          {images.map((image, index) =>
            video ? (
              <CardMedia
                key={index}
                data-media-index={index}
                fit={false}
                data-collapsed="hidden"
                onClick={handleMediaClick}
                className={classNameHasImagesImage}
                image={image.url}
              />
            ) : (
              index !== coverImageIndex && (
                <CardMedia
                  key={index}
                  data-media-index={index}
                  fit={false}
                  data-collapsed="hidden"
                  onClick={handleMediaClick}
                  className={classNameHasImagesImage}
                  image={image.url}
                />
              )
            ),
          )}
        </Box>
      )}
    </div>
  );
};

export default WorkoutCardMedia;
