import React, { useTransition, Suspense } from "react";
import { Container, ContainerProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { useQueryParam } from "../../hooks/useQueryParam";

import { ClientProgramTimeline } from "./ClientProgramTimeline";
import { ClientProgramWeek } from "./ClientProgramWeek";
import { ClientProgram_enrollment$key } from "./__generated__/ClientProgram_enrollment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },
  timeline: {
    // TODO: Normalize theme shadows
    filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05))",
    marginBottom: theme.spacing(4),
    marginTop: `calc(${theme.spacing(8)} + var(--safe-area-inset-top))`,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  header: {
    fontSize: 30,
    marginBottom: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(3.5, 6, 4),
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
  },
  component: {
    margin: theme.spacing(1, 0),
  },
  selected: {},
}));

const fragment = graphql`
  fragment ClientProgram_enrollment on Enrollment
  @argumentDefinitions(week: { type: "Int" }) {
    startDate(raw: true)
    currentWeek

    completion(week: $week, completion: true, period: ALL_TIME) {
      ...ClientProgramWeek_completion
    }

    activities(week: $week) {
      ...ClientProgramWeek_activities
    }

    program {
      id
      slug
      name
      length
      weeks(first: 9999, after: "") {
        edges {
          node {
            id
            week
            positions
            description
          }
        }
      }
    }
  }
`;

export interface ClientProgramProps extends Omit<ContainerProps, "children"> {
  enrollmentRef: ClientProgram_enrollment$key;
}

export function ClientProgram({ enrollmentRef }: ClientProgramProps) {
  const s = useStyles();
  const [weekQueryParam, setWeekQueryParam] = useQueryParam("week");
  const enrollment = useFragment(fragment, enrollmentRef);
  const program = enrollment?.program;
  const currentWeek = enrollment.currentWeek
    ? Math.min(enrollment.currentWeek, program.length)
    : 1;
  const selectedWeekNumber = Number(weekQueryParam) || currentWeek || 1;

  const selectedWeek = React.useMemo(() => {
    if (program && program.weeks) {
      return program.weeks.edges.find(
        ({ node }) => node.week === selectedWeekNumber,
      );
    }
  }, [program, selectedWeekNumber]);

  // TODO handle loading state with isPending
  const [isPending, startTransition] = useTransition();

  React.useEffect(() => {
    if (!weekQueryParam && selectedWeekNumber) {
      startTransition(() => {
        setWeekQueryParam(String(selectedWeekNumber));
      });
    }
  }, [selectedWeekNumber, setWeekQueryParam, weekQueryParam]);

  return (
    <>
      <ClientProgramTimeline
        className={s.timeline}
        weeks={program.weeks}
        currentWeek={currentWeek}
        selected={selectedWeekNumber}
        setSelected={(week) => startTransition(() => setWeekQueryParam(week))}
      />
      <Container className={s.root}>
        {selectedWeekNumber &&
        program &&
        enrollment &&
        program.weeks.edges.length ? (
          <ClientProgramWeek
            week={selectedWeek}
            startDate={enrollment.startDate}
            activitiesRef={enrollment.activities}
            completionRef={enrollment.completion}
          />
        ) : program ? (
          <Typography>Program does not contain any lessons</Typography>
        ) : (
          <Typography>You are currently not enrolled into a program</Typography>
        )}
      </Container>
    </>
  );
}
