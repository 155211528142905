/**
 * @generated SignedSource<<a68e21b45039c26c05bd501d737dc34e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientUpcomingProgramCard_enrollment$data = {
  readonly endDateShort: string | null | undefined;
  readonly startFromNow: string | null | undefined;
  readonly totalDays: number;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSmallProgramCard_enrollment">;
  readonly " $fragmentType": "ClientUpcomingProgramCard_enrollment";
};
export type ClientUpcomingProgramCard_enrollment$key = {
  readonly " $data"?: ClientUpcomingProgramCard_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientUpcomingProgramCard_enrollment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "utc",
  "value": true
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientUpcomingProgramCard_enrollment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSmallProgramCard_enrollment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalDays",
      "storageKey": null
    },
    {
      "alias": "startFromNow",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        },
        (v0/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": "startDate(format:\"fromNow\",utc:true)"
    },
    {
      "alias": "endDateShort",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM DD, YYYY"
        },
        (v0/*: any*/)
      ],
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": "endDate(format:\"MMM DD, YYYY\",utc:true)"
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};
})();

(node as any).hash = "68ba7396517c7b09cd430f97da916a09";

export default node;
