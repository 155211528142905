/**
 * @generated SignedSource<<3f70d440d23dfcf209502cff1350690a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientDetailedLinkInviteScreen_inviteCode$data = {
  readonly coach: {
    readonly displayName: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
  };
  readonly code: string;
  readonly group: {
    readonly description: string;
    readonly imageURL: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly program: {
    readonly description: string | null | undefined;
    readonly imageURL: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "ClientDetailedLinkInviteScreen_inviteCode";
};
export type ClientDetailedLinkInviteScreen_inviteCode$key = {
  readonly " $data"?: ClientDetailedLinkInviteScreen_inviteCode$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientDetailedLinkInviteScreen_inviteCode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "imageURL",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientDetailedLinkInviteScreen_inviteCode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_user"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Group",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Program",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "InviteCode",
  "abstractKey": null
};
})();

(node as any).hash = "20157bdfff4658458f0b864dee6b07aa";

export default node;
