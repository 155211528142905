import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { CardProgramTemplateBlank } from "../card/CardProgramTemplateBlank";
import { CardProgramTemplate } from "../card/CardProgramTemplate";

import { ProgramTemplatesList_connection$key } from "./__generated__/ProgramTemplatesList_connection.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    flexWrap: "wrap",
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  card: {
    width: 316,
    height: 374,
    flexShrink: 0,
    margin: theme.spacing(0, 7, 6, 0),
  },
}));

const fragment = graphql`
  fragment ProgramTemplatesList_connection on ProgramTemplateConnection {
    edges {
      node {
        id
        ...CardProgramTemplate_template
      }
    }
  }
`;

export interface ProgramTemplatesListProps extends BoxProps {
  templatesRef: ProgramTemplatesList_connection$key;
}

export function ProgramTemplatesList(props: ProgramTemplatesListProps) {
  const { className, templatesRef, ...other } = props;
  const s = useStyles();
  const { edges } = useFragment(fragment, templatesRef);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <CardProgramTemplateBlank className={s.card} />

      {edges.map(({ node }) => (
        <CardProgramTemplate
          templateRef={node}
          key={node.id}
          className={s.card}
        />
      ))}
    </Box>
  );
}
