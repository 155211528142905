import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { RefreshSlug } from "../routes/RefreshSlug";
import { HistoryBlock } from "../history-block/HistoryBlock";
import { AppLayout } from "../app/AppLayout";
import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { polyfillCSS } from "../../utils/css";

import { ClientForm } from "./ClientForm";
import { ClientFormScreen_root$key } from "./__generated__/ClientFormScreen_root.graphql";
import { useParams } from "react-router-dom";
import {
  CLIENT_PROFILE_ROUTE,
  COACH_CLIENT_OVERVIEW_ROUTE,
} from "../../routes/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
    paddingLeft: polyfillCSS("var(--safe-area-inset-left)"),
    paddingRight: polyfillCSS("var(--safe-area-inset-right)"),
    paddingBottom: polyfillCSS("var(--safe-area-inset-bottom)"),
    position: "relative",
  },

  back: {
    alignSelf: "flex-start",
    position: "absolute",
    top: polyfillCSS(`calc(var(--safe-area-inset-top) + ${theme.spacing(1)})`),
    left: polyfillCSS(
      `calc(var(--safe-area-inset-left) + ${theme.spacing(3)})`,
    ),
  },
}));

const rootFragment = graphql`
  fragment ClientFormScreen_root on Root
  @argumentDefinitions(
    slug: { type: "String!" }
    username: { type: "String" }
  ) {
    clientForm(slug: $slug, username: $username) {
      ...RefreshSlug
      ...ClientForm_clientForm
      id
    }
  }
`;

export interface ClientFormScreenProps {
  root: ClientFormScreen_root$key;
  username?: string;
}

export function ClientFormScreen({ root: rootRef }: ClientFormScreenProps) {
  const { username } = useParams();
  const s = useStyles();
  const root = useFragment(rootFragment, rootRef);
  const { clientForm } = root;
  const role = useCurrentUserRole();
  const returnUrl =
    role === UserRole.COACH
      ? COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", username)
      : CLIENT_PROFILE_ROUTE;

  return (
    <AppLayout appDrawer={false} appBar={false}>
      <Box className={s.root}>
        <RefreshSlug nodesRef={[clientForm]} />
        <HistoryBlock>
          <ClientForm
            clientForm={clientForm}
            readOnly={true}
            disabled={role === UserRole.COACH}
            returnUrl={returnUrl}
          />
        </HistoryBlock>
      </Box>
    </AppLayout>
  );
}
