/**
 * @generated SignedSource<<9d8d14a139b09932d39c75a83c68cf58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityType = "CLIENT_FORM" | "MEAL_LOGGING" | "NUTRITION_TARGET" | "PROGRAM_COMPONENT" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
export type MetricType = "CHECKIN_ANSWER_BINARY" | "CHECKIN_ANSWER_DATE" | "CHECKIN_ANSWER_EMOJI" | "CHECKIN_ANSWER_FILE" | "CHECKIN_ANSWER_LONG_TEXT" | "CHECKIN_ANSWER_MEASUREMENT" | "CHECKIN_ANSWER_MULTIPLE_CHOICE" | "CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX" | "CHECKIN_ANSWER_NUMBER" | "CHECKIN_ANSWER_NUTRITION_TARGET" | "CHECKIN_ANSWER_PROGRESS_PHOTO" | "CHECKIN_ANSWER_RATING_SCALE" | "CHECKIN_ANSWER_SHORT_TEXT" | "CHECKIN_ANSWER_STAR_RATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActivityResponseCard_activity$data = {
  readonly answers: number;
  readonly client: {
    readonly displayName: string | null | undefined;
    readonly photoURL: string | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly clientForm: {
    readonly slug: string;
  } | null | undefined;
  readonly coachArchived: boolean;
  readonly coachReadAnswers: boolean;
  readonly completedAt: string | null | undefined;
  readonly component: {
    readonly slug: string;
    readonly title: string;
    readonly type: ComponentType;
  } | null | undefined;
  readonly date: string | null | undefined;
  readonly dateFormatted: string | null | undefined;
  readonly edited: boolean;
  readonly endWorkout: string | null | undefined;
  readonly enrollment: {
    readonly program: {
      readonly name: string;
      readonly slug: string;
    };
  } | null | undefined;
  readonly feedbacks: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityResponseItem_feedback">;
  }> | null | undefined;
  readonly id: string;
  readonly metrics: ReadonlyArray<{
    readonly formattedValue: string;
    readonly id: string;
    readonly name: string;
    readonly type: MetricType;
    readonly value: {
      readonly name?: string;
      readonly type?: string;
      readonly url?: string;
    };
  } | null | undefined> | null | undefined;
  readonly startWorkout: string | null | undefined;
  readonly submittedAt: string | null | undefined;
  readonly type: ActivityType;
  readonly workoutExerciseResults: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityWorkoutResponse_workoutExerciseResult">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityResponseMealsList_activity" | "FeedbackForm_activity">;
  readonly " $fragmentType": "ActivityResponseCard_activity";
};
export type ActivityResponseCard_activity$key = {
  readonly " $data"?: ActivityResponseCard_activity$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityResponseCard_activity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "fromNow"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityResponseCard_activity",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "raw",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(raw:true)"
    },
    {
      "alias": "dateFormatted",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "ddd, MMM DD"
        },
        {
          "kind": "Literal",
          "name": "utc",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(format:\"ddd, MMM DD\",utc:true)"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": "completedAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "submittedAt",
      "storageKey": "submittedAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "edited",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startWorkout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endWorkout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Enrollment",
      "kind": "LinkedField",
      "name": "enrollment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Program",
          "kind": "LinkedField",
          "name": "program",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Component",
      "kind": "LinkedField",
      "name": "component",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                (v3/*: any*/),
                (v1/*: any*/)
              ],
              "type": "CheckinAnswerFileValue",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityFeedback",
      "kind": "LinkedField",
      "name": "feedbacks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityResponseItem_feedback"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkoutExerciseResult",
      "kind": "LinkedField",
      "name": "workoutExerciseResults",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityWorkoutResponse_workoutExerciseResult"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientForm",
      "kind": "LinkedField",
      "name": "clientForm",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachReadAnswers",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedbackForm_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityResponseMealsList_activity"
    }
  ],
  "type": "Activity",
  "abstractKey": null
};
})();

(node as any).hash = "9a93adb0761e408080d3ea10c4bbaa47";

export default node;
