import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&::after": {
      position: "absolute",
      content: '""',
      display: "block",
      width: "calc(100% - 36px)",
      height: 1,
      backgroundColor: theme.palette.quote,
      left: theme.spacing(4),
    },

    "&:hover::after": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  active: {
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: 2,
      height: theme.spacing(10.75),
      left: 7,
      backgroundColor: theme.palette.common.black,
    },
  },

  button: {
    position: "relative",
    zIndex: 1,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 1),
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    visibility: "hidden",

    "$root:hover &": {
      visibility: "visible",
    },
  },
}));

export interface WorkoutExerciseDividerProps extends BoxProps {
  active?: boolean;
  rootStyling?: any;
  buttonStyling?: any;
}

export function WorkoutExerciseDivider(props: WorkoutExerciseDividerProps) {
  const {
    className,
    active = false,
    rootStyling,
    buttonStyling,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box
      className={clsx(s.root, className, active && s.active, rootStyling)}
      {...other}
    >
      <Typography className={clsx(s.button, buttonStyling)}>
        {active ? "Remove superset" : "Create superset"}
      </Typography>
    </Box>
  );
}
