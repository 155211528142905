/**
 * @generated SignedSource<<759e849d83c13c12dcd7005af2e3c9b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientGoal_goal$data = {
  readonly completed: boolean;
  readonly currentValue: string | null | undefined;
  readonly formattedDate: string | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly targetValue: string | null | undefined;
  readonly " $fragmentType": "ClientGoal_goal";
};
export type ClientGoal_goal$key = {
  readonly " $data"?: ClientGoal_goal$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientGoal_goal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientGoal_goal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentValue",
      "storageKey": null
    },
    {
      "alias": "formattedDate",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "MMM D, YYYY"
        }
      ],
      "kind": "ScalarField",
      "name": "date",
      "storageKey": "date(format:\"MMM D, YYYY\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    }
  ],
  "type": "ClientGoal",
  "abstractKey": null
};

(node as any).hash = "5ccff84c2af32bfc1f5a08e708d11e9a";

export default node;
