import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { DotsProps } from "./types";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      marginTop: "5vh",
      flex: 1,
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(6.2),
      },
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: 5,
      backgroundColor: colorSystem.secondaryGray,
      marginRight: 14,
    },
    lastDot: {
      marginRight: 0,
    },
    active: {
      backgroundColor: colorSystem.black,
    },
  };
});

const Dots = (props: DotsProps) => {
  const { activeDot } = props;
  const s = useStyles();
  const dots: Array<number> = [...Array(2).keys()];

  return (
    <div className={clsx(s.container)}>
      {dots.map((i) => (
        <div
          key={i.toString()}
          className={clsx(
            s.dot,
            i === 1 && s.lastDot,
            i === activeDot && s.active,
          )}
        />
      ))}
    </div>
  );
};

export default Dots;
