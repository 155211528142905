import clsx from "clsx";
import React from "react";
import {
  Container,
  Card,
  TextField,
  Button,
  ContainerProps,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import InputPlusMinus from "../input-plus-minus/InputPlusMinus";
import { graphql } from "react-relay";
import { useFragment, useMutation } from "react-relay/hooks";
import { ReactComponent as EndWorkoutIcon } from "../../icons/endWorkout.svg";
import { EndWorkout_activity$key } from "./__generated__/EndWorkout_activity.graphql";
import { useLocation, useNavigate } from "react-router-dom";
import TrackInfoTool from "../tools/TrackInfoTool";
import { ActivityEndWorkout } from "../activity/ActivityWorkoutResponse";
import { getDaysDiffFromToday, getTimeDiff } from "../../utils/date";
import { ActivityLocked } from "../activity/ActivityLocked";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      fontFamily: "'Montserrat', sans-serif",
      minHeight: "100vh",
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: 0,
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
  },
  valuesContainer: {
    display: "flex",
    marginTop: 32,
    [theme.breakpoints.down("md")]: {
      marginTop: 37,
    },
  },
  value: {
    backgroundColor: colorSystem.gray9,
    padding: "2.5vh 4.4vh",
    marginRight: 2,
    fontWeight: 700,
    fontSize: 28,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3.3),
      fontSize: 20,
    },
  },
  values: {
    "&:first-of-type $value": {
      borderBottomLeftRadius: 14,
      borderTopLeftRadius: 14,
    },
    "&:last-of-type $value": {
      borderBottomRightRadius: 14,
      borderTopRightRadius: 14,
      marginRight: 0,
    },
  },

  index: {
    marginLeft: 1,
    marginRight: 1,
    color: colorSystem.secondaryGray,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    marginTop: 8,
    display: "flex",
    justifyContent: "center",
  },
  activeValue_1: {
    backgroundColor: colorSystem.green2,
    color: colorSystem.white,
  },
  activeValue_2: {
    backgroundColor: colorSystem.blue1,
    color: colorSystem.white,
  },
  activeValue_3: {
    backgroundColor: colorSystem.yellow,
    color: colorSystem.white,
  },
  activeValue_4: {
    backgroundColor: colorSystem.orange,
    color: colorSystem.white,
  },
  activeValue_5: {
    backgroundColor: colorSystem.primary,
    color: colorSystem.white,
  },
  activeText_1: {
    color: colorSystem.green2,
  },
  activeText_2: {
    color: colorSystem.blue1,
  },
  activeText_3: {
    color: colorSystem.yellow,
  },
  activeText_4: {
    color: colorSystem.orange,
  },
  activeText_5: {
    color: colorSystem.primary,
  },
  icon: {
    marginTop: 60,
    marginBottom: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      marginBottom: 20,
    },
  },
  title: {
    lineHeight: "39px",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      lineHeight: "29px",
    },
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "22px",
    color: colorSystem.gray,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  textField: {
    width: "100%",
  },
  trainingText: {
    fontSize: 24,
    lineHeight: "29px",
    fontWeight: "bold",
    marginTop: 60,
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
  },
  trainingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  notes: {
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: "19px",
    marginTop: 18,
    marginBottom: 12,
    color: colorSystem.gray,
  },

  notesContainer: {
    width: "100%",
    maxWidth: 453,
    padding: "0 5px",
    [theme.breakpoints.down("md")]: {
      maxWidth: 370,
    },
  },

  buttonContainer: {
    padding: "0 5px",
    width: "100%",
    maxWidth: 375,
  },

  button: {
    paddingTop: 18,
    paddingBottom: 18,
    width: "100%",
    backgroundColor: colorSystem.black,
    borderRadius: 4,
    color: colorSystem.white,
    margin: "40px 0",
    fontSize: 16,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colorSystem.gray,
    },
    "&.Mui-disabled": {
      backgroundColor: colorSystem.gray,
    },
  },
}));

const activityFragment = graphql`
  fragment EndWorkout_activity on Activity {
    id
    date(raw: true)
    component {
      locked
    }
  }
`;

const upsertActivityMutation = graphql`
  mutation EndWorkoutActivityCompleteActivityMutation(
    $input: UpsertActivityInput!
  ) {
    upsertActivity(input: $input) {
      activity {
        ...EndWorkout_activity
      }
    }
  }
`;

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activityRef: EndWorkout_activity$key;
  coach?: boolean;
}

const EndWorkout = (props: ActivityProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { defaultValues } = (location.state ?? {}) as {
    defaultValues: {
      endWorkout: any;
    };
  };

  const defaultEndWorkout = defaultValues?.endWorkout
    ? (JSON.parse(defaultValues.endWorkout) as ActivityEndWorkout)
    : null;

  const { activityRef } = props;
  const s = useStyles();
  const [time, setTime] = React.useState(defaultEndWorkout?.time ?? 60);
  const [feel, setFeel] = React.useState(defaultEndWorkout?.feel ?? "");
  const [notes, setNotes] = React.useState(defaultEndWorkout?.notes ?? "");
  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));

  const activity = useFragment(activityFragment, activityRef);

  const [upsertActivity, upsertActivityInFlight] = useMutation(
    upsertActivityMutation,
  );

  const path = location.pathname.replace("end", "summary");

  const locked = activity.component?.locked && getTimeDiff(activity.date) > 0;

  const handleSubmit = React.useCallback(() => {
    const input = {
      id: activity.id,
      endWorkout: JSON.stringify({ feel, notes, time }),
    };

    upsertActivity({
      variables: {
        input,
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          navigate(
            path,
            //   {
            //   returnBack: false,
            // }
          );
        }
      },
    });
  }, [activity.id, upsertActivity, feel, notes, time, path]);

  const handleActivityClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      handleSubmit();
    },
    [handleSubmit],
  );

  const handleChangePlusTime = React.useCallback(() => {
    setTime((prev) => prev + 5);
  }, []);

  const handleChangeMinusTime = React.useCallback(() => {
    if (time) setTime((prev) => prev - 5);
  }, [time]);

  const handleTextFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setNotes(value);
    },
    [],
  );

  const values: Array<string> = ["V.Ease", "Ease", "Medium", "Hard", "V.Hard"];
  return (
    <>
      <Container maxWidth="md" className={clsx(s.container)}>
        {locked ? (
          <Grid mt={20}>
            <ActivityLocked daysDiff={getDaysDiffFromToday(activity.date)} />
          </Grid>
        ) : (
          <Card className={clsx(s.cardContainer)}>
            <EndWorkoutIcon
              width={mdSm ? 80 : 120}
              height={mdSm ? 80 : 120}
              className={clsx(s.icon)}
            />
            <Typography variant="h3" className={clsx(s.title)}>
              End of workout survey
            </Typography>
            <div className={clsx(s.subTitle)}>How did you feel?</div>
            <div className={clsx(s.valuesContainer)}>
              {values.map((value, index) => (
                <div key={value} className={s.values}>
                  <div
                    onClick={() => setFeel(`${index + 1} ${value}`)}
                    className={clsx(
                      s.value,
                      feel === `${index + 1} ${value}` &&
                        s[`activeValue_${index + 1}`],
                    )}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={clsx(
                      s.index,
                      feel === `${index + 1} ${value}` &&
                        s[`activeText_${index + 1}`],
                    )}
                  >
                    {value}
                  </div>
                </div>
              ))}
            </div>
            <div className={clsx(s.trainingText)}>Training Time </div>
            <div className={s.trainingContainer}>
              <InputPlusMinus
                value={time}
                onClickPlus={handleChangePlusTime}
                onClickMinus={handleChangeMinusTime}
              />
              <Typography variant="caption" mt={0.5}>
                minutes
              </Typography>
            </div>
            <div className={s.notesContainer}>
              <div className={clsx(s.notes)}>Notes</div>
              <TextField
                className={s.textField}
                placeholder="ex. Need to buy a bandage for the bench press"
                variant="outlined"
                fullWidth
                rows={3}
                multiline
                value={notes}
                onChange={handleTextFieldChange}
              />
            </div>
            <div className={s.buttonContainer}>
              <Button
                onClick={handleActivityClick}
                className={clsx(s.button)}
                disabled={!feel && !upsertActivityInFlight}
              >
                Confirm
              </Button>
            </div>
          </Card>
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Program",
        }}
      />
    </>
  );
};

export default EndWorkout;
