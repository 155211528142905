/**
 * @generated SignedSource<<9a56a048c1b454465e6a67c64aff0fee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRole = "CLIENT" | "COACH" | "%future added value";
export type SignInMutation$variables = {
  idToken: string;
};
export type SignInMutation$data = {
  readonly signIn: {
    readonly me: {
      readonly id: string;
      readonly role: UserRole;
      readonly " $fragmentSpreads": FragmentRefs<"CurrentUserProvider_user">;
    } | null | undefined;
  } | null | undefined;
};
export type SignInMutation = {
  response: SignInMutation$data;
  variables: SignInMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "idToken",
    "variableName": "idToken"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "raw",
    "value": true
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoURL",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSample",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInPayload",
        "kind": "LinkedField",
        "name": "signIn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "shouldFetchNotifications",
                    "value": false
                  }
                ],
                "kind": "FragmentSpread",
                "name": "CurrentUserProvider_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignInMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignInPayload",
        "kind": "LinkedField",
        "name": "signIn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": "rawEmail",
                "args": (v6/*: any*/),
                "kind": "ScalarField",
                "name": "email",
                "storageKey": "email(raw:true)"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneVerified",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unreadMessagesCounter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notificationsActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notificationUnreadCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "age",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "units",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weight",
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isImpersonating",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "admin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topToolbar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientsCount",
                "storageKey": null
              },
              {
                "alias": "clientsCountNoSample",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "noSample",
                    "value": true
                  }
                ],
                "kind": "ScalarField",
                "name": "clientsCount",
                "storageKey": "clientsCount(noSample:true)"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "programsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unreadActivitiesCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "kind": "ScalarField",
                "name": "trialExpiryDate",
                "storageKey": "trialExpiryDate(raw:true)"
              },
              {
                "alias": "trialExpiryDateTimestamp",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "X"
                  }
                ],
                "kind": "ScalarField",
                "name": "trialExpiryDate",
                "storageKey": "trialExpiryDate(format:\"X\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trialExpired",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "plan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planTier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "coach",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": "displayName(raw:true)"
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "GroupConnection",
                "kind": "LinkedField",
                "name": "groups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Group",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "groups(first:3)"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "GroupNavItemList_groups",
                "kind": "LinkedHandle",
                "name": "groups"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientNutritionTarget",
                "kind": "LinkedField",
                "name": "nutritionTarget",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab36013c9e97d2606b4c09c175bc950f",
    "id": null,
    "metadata": {},
    "name": "SignInMutation",
    "operationKind": "mutation",
    "text": "mutation SignInMutation(\n  $idToken: String!\n) {\n  signIn(idToken: $idToken) {\n    me {\n      id\n      role\n      ...CurrentUserProvider_user_47Q57x\n    }\n  }\n}\n\nfragment CurrentUserProvider_user_47Q57x on User {\n  id\n  username\n  email\n  rawEmail: email(raw: true)\n  displayName\n  photoURL\n  phone\n  phoneVerified\n  timeZone\n  unreadMessagesCounter\n  notificationsActive\n  notificationUnreadCount\n  role\n  location\n  birthday\n  age\n  gender\n  units\n  height\n  weight\n  createdAt\n  isSample\n  isImpersonating\n  admin\n  topToolbar\n  clientsCount\n  clientsCountNoSample: clientsCount(noSample: true)\n  programsCount\n  unreadActivitiesCount\n  trialExpiryDate(raw: true)\n  trialExpiryDateTimestamp: trialExpiryDate(format: \"X\")\n  trialExpired\n  plan\n  planTier\n  subscription {\n    status\n    id\n  }\n  coach {\n    username\n    email\n    displayName\n    photoURL\n    id\n  }\n  accounts {\n    id\n    role\n    isSample\n    username\n    email\n    displayName(raw: true)\n    photoURL\n    phone\n    timeZone\n    createdAt\n    ...UserAccountMenuItem_user\n  }\n  groups(first: 3) {\n    totalCount\n  }\n  nutritionTarget {\n    id\n  }\n  ...GroupNavItemList_me\n}\n\nfragment GroupNavItemListItem on Group {\n  name\n  slug\n}\n\nfragment GroupNavItemList_me on User {\n  groups(first: 3) {\n    totalCount\n    edges {\n      node {\n        ...GroupNavItemListItem\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment UserAccountMenuItem_user on User {\n  id\n  role\n  displayName(raw: true)\n  isSample\n  photoURL\n}\n"
  }
};
})();

(node as any).hash = "fed458811c9b9a4fbb8d3884621760a5";

export default node;
