import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { Container } from "@mui/material";

const ActivityWrapper = () => {
  return (
    <Container maxWidth={"slg"}>
      <Suspense
        fallback={
          <PageSkeleton
            fullWidth
            style={{
              marginBlock: 64,
              paddingInline: 24,
            }}
          />
        }
      >
        <Outlet />
      </Suspense>
    </Container>
  );
};

export default ActivityWrapper;
