import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { CoachSettingsPlan } from "../../../../components/settings/CoachSettingsPlan";
import { CoachSettingsPlanRouteQuery } from "./__generated__/CoachSettingsPlanRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachSettingsPlanRoute() {
  const props = useLazyLoadQuery<CoachSettingsPlanRouteQuery>(
    graphql`
      query CoachSettingsPlanRouteQuery {
        me {
          subscription {
            plan
          }

          ...CoachSettingsPlan_user
        }

        clients(first: 9999, status: ACTIVE, noSample: true) {
          ...SelectClientsDialog_clients
        }
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { me, clients } = props;

  return (
    <>
      <CoachSettingsPlan user={me} clients={clients} />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Plan",
        }}
      />
    </>
  );
}
