import clsx from "clsx";
import React from "react";
import { Card, CardProps, CardActionArea, CardMedia } from "@mui/material";
import { StyledComponentProps } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { CheckCircleOutlineRounded as CheckIcon } from "@mui/icons-material";
import { useFragment } from "react-relay/hooks";

import {
  UnsplashImage_image$key,
  UnsplashImage_image$data,
} from "./__generated__/UnsplashImage_image.graphql";

export const fragment = graphql`
  fragment UnsplashImage_image on UnsplashImage {
    id
    unsplashImageId
    width
    height
    authorUrl
    authorName
    description

    urls {
      regular
      thumb
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    margin: "auto",

    "&.MuiPaper-root": {
      padding: 0,
      minWidth: "auto",
    },
  },

  fit: {
    width: "100%",
    height: "100%",
  },

  check: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.common.white,
  },

  author: {
    marginTop: theme.spacing(0.5),
    display: "block",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    fontSize: 12,
    fontWeight: 500,
    textDecoration: "none !important" as "none",
  },

  selected: {},
}));

export type UnsplashImageValue = UnsplashImage_image$data;

export interface UnsplashImageProps
  extends Omit<CardProps, "onSelect" | "classes">,
    StyledComponentProps<"root" | "selected"> {
  imageRef: UnsplashImage_image$key;
  onSelect: (value: UnsplashImageValue) => void;
  selected?: boolean;
}

export function UnsplashImage(props: UnsplashImageProps) {
  const {
    className,
    imageRef,
    onSelect,
    selected,
    classes = {},
    ...other
  } = props;
  const s = useStyles();
  const image = useFragment(fragment, imageRef);
  const {
    urls: { thumb },
    description,
    authorName,
    authorUrl,
  } = image;

  const handleClick = React.useCallback(() => {
    onSelect(image);
  }, [onSelect, image]);

  return (
    <>
      <Card
        className={clsx(
          s.root,
          s.fit,
          classes.root,
          className,
          selected && clsx(s.selected, classes.selected),
        )}
        {...other}
      >
        <CardActionArea className={s.fit} onClick={handleClick}>
          <CardMedia className={s.fit} image={thumb} title={description} />
        </CardActionArea>
        {selected && <CheckIcon className={s.check} />}
      </Card>

      <a
        href={authorUrl}
        className={s.author}
        target="_blank"
        rel="noopener noreferrer"
      >
        by {authorName}
      </a>
    </>
  );
}
