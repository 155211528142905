import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Skeleton } from "@mui/material";
import React, { Suspense, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ThreadSkeleton } from "../../components/messages/ThreadSkeleton";
import { PageSkeleton } from "../../components/loading/PageSkeleton";

const MessagesWrapper = () => {
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const { recipient } = useParams();

  interface IMySkeletonProps {
    hideThreads?: boolean;
    hideMessages?: boolean;
  }
  const MySkeleton = ({
    hideThreads = false,
    hideMessages = false,
  }: IMySkeletonProps) => {
    return (
      <>
        <Box display={"flex"} style={{ paddingTop: 24 }}>
          {!hideThreads && (
            <Box minWidth={mdUp ? 364 : "100%"}>
              <Box width={1} style={{ paddingLeft: 24 }}>
                <PageSkeleton fullWidth />
              </Box>
            </Box>
          )}
          {!hideMessages && (
            <Box width={"100%"} display={"flex"} flexDirection={"column"}>
              <Box width={"100%"} style={{ paddingInline: 24 }}>
                <PageSkeleton fullWidth cardsCount={1} />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      <Suspense
        fallback={
          <MySkeleton
            hideThreads={recipient && !mdUp}
            hideMessages={!recipient && !mdUp}
          />
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
};

export default MessagesWrapper;
