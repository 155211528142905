import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { coachSettingsNavigation } from "../utils/common";
import { ClientPortalSettings } from "../../../../components/client-portal-settings/ClientPortalSettings";
import { CoachSettingsClientPortalRouteQuery } from "./__generated__/CoachSettingsClientPortalRouteQuery.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";

export function CoachSettingsClientPortalRoute() {
  const props = useLazyLoadQuery<CoachSettingsClientPortalRouteQuery>(
    graphql`
      query CoachSettingsClientPortalRouteQuery {
        me {
          subscription {
            plan
          }
        }

        workspace {
          ...ClientPortalSettings_workspace
        }
      }
    `,
    {},
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const { workspace } = props;

  return (
    <>
      <ClientPortalSettings workspace={workspace} />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Portal",
        }}
      />
    </>
  );
}
