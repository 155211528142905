import React, { MouseEvent, Ref } from "react";
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";

import { useNavigate } from "react-router-dom";

function isLeftClickEvent(event: MouseEvent) {
  return event.button === 0;
}

function isModifiedEvent(event: MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export interface LinkProps extends MuiLinkProps {
  state?: any;
}

export const Link = React.forwardRef(function Link(
  props: any,
  ref: Ref<HTMLElement>,
) {
  const navigate = useNavigate();
  const { state, onClick, underlineNone, target, href, ...other } = props;
  const underline = underlineNone ? "none" : props.underline;

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    if (target === "_blank") {
      window.open(href, "_blank");
      event.preventDefault();
    }

    if (onClick) {
      onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();

    navigate(event.currentTarget.getAttribute("href"));
  }

  return (
    <MuiLink
      ref={ref}
      {...other}
      onClick={handleClick}
      underline={underline}
      href={href}
    />
  );
});
