import clsx from "clsx";
import React from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay";

import { useClient } from "../../hooks/useClient";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { colorSystem } from "../../theme";

import { ClientGoalsDrawerEmptyView } from "./ClientGoalsDrawerEmptyView";
import {
  ClientGoalsDrawerListView,
  ClientGoalsDrawerListViewProps,
} from "./ClientGoalsDrawerListView";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ClientGoalsDrawerEditView } from "./ClientGoalsDrawerEditView";
import { ClientGoalsDrawerSetDateView } from "./ClientGoalsDrawerSetDateView";
import { ClientGoalsDrawer_goals$key } from "./__generated__/ClientGoalsDrawer_goals.graphql";
import { useUpsertClientGoalMutation } from "./mutations/UpsertClientGoal";
import { ClientGoalListItemProps } from "./ClientGoalListItem";
import { useDeleteClientGoalMutation } from "./mutations/DeleteClientGoal";
import { useChangeClientGoalOrdinalMutation } from "./mutations/ChangeClientGoalOrdinal";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },

  overlay: {},
}));

export enum ClientGoalsDrawerView {
  EMPTY = "EMPTY",
  LIST = "LIST",
  EDIT = "EDIT",
  SET_DATE = "SET_DATE",
}

function getInitialView(goalsCount: number): ClientGoalsDrawerView {
  return goalsCount ? ClientGoalsDrawerView.LIST : ClientGoalsDrawerView.EMPTY;
}

const goalsFragment = graphql`
  fragment ClientGoalsDrawer_goals on ClientGoalConnection {
    totalCount
    edges {
      node {
        id
        name
        targetValue
        currentValue
        date(raw: true)
        formattedDate: date(format: "MMM D, YYYY")
      }
    }
    ...ClientGoalsList_goals
  }
`;

export interface ClientGoalsDrawerProps extends DrawerProps {
  goals: ClientGoalsDrawer_goals$key;
  refresh?: () => void;
}

export function ClientGoalsDrawer(props: ClientGoalsDrawerProps) {
  const { className, onClose, open, refresh, goals: goalsRef } = props;
  const goals = useFragment(goalsFragment, goalsRef);
  const s = useStyles();
  const user = useCurrentUser();
  const initialView = getInitialView(goals.totalCount);
  const [view, setView] = React.useState<ClientGoalsDrawerView>(initialView);
  const [viewHistory, setViewHistory] = React.useState<ClientGoalsDrawerView[]>(
    [view],
  );
  const [name, setName] = React.useState("");
  const [target, setTarget] = React.useState("");
  const [date, setDate] = React.useState("");
  const [selectedGoal, setSelectedGoal] =
    React.useState<ClientGoalListItemProps["option"]>(null);
  const client = useClient<any>();
  const snackAlert = useSnackAlert();

  const [upsertGoal] = useUpsertClientGoalMutation();
  const [deleteGoal] = useDeleteClientGoalMutation();
  const [changeOrdinal] = useChangeClientGoalOrdinalMutation();

  React.useEffect(() => {
    setViewHistory((viewHistory) => [initialView, ...viewHistory.slice(1)]);
  }, [initialView]);

  React.useEffect(() => {
    setName(selectedGoal?.name || "");
    setTarget(selectedGoal?.targetValue || "");
    setDate(selectedGoal?.date || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal]);

  const resetState = React.useCallback(
    (view: ClientGoalsDrawerView = initialView) => {
      setName("");
      setDate("");
      setTarget("");
      setSelectedGoal(null);
      setView(view);
      setViewHistory([view]);
    },
    [initialView],
  );

  const handleClose = React.useCallback(() => {
    resetState();
    onClose({}, "backdropClick");
  }, [onClose, resetState]);

  React.useEffect(() => {
    const lastView = viewHistory[viewHistory.length - 1];

    if (!lastView) {
      handleClose();
    } else if (lastView !== view) {
      setView(lastView);
    }
  }, [handleClose, view, viewHistory]);

  const pushView = React.useCallback((view: ClientGoalsDrawerView) => {
    setViewHistory((viewHistory) => [...viewHistory, view]);
  }, []);

  const popView = React.useCallback(() => {
    setViewHistory((viewHistory) => viewHistory.slice(0, -1));
  }, []);

  const handleBackClick = React.useCallback(() => {
    setViewHistory((viewHistory) =>
      viewHistory.slice(0, viewHistory.length - 1),
    );
  }, []);

  const userId = React.useMemo(() => client?.id || user.id, [user, client]);

  const handleDeleteClick = React.useCallback(() => {
    if (selectedGoal) {
      deleteGoal({
        variables: {
          input: { clientId: client?.id || user.id, goalId: selectedGoal.id },
        },
        onSuccess() {
          const view = getInitialView(goals.totalCount - 1);
          snackAlert({
            severity: "success",
            message: "Client goal deleted.",
          });
          if (refresh) {
            refresh();
          }
          resetState(view);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGoal, client?.id, user, selectedGoal]);

  const handleCreateClick = React.useCallback(() => {
    setSelectedGoal(null);
    pushView(ClientGoalsDrawerView.EDIT);
  }, [pushView]);

  const handleItemActionClick: ClientGoalsDrawerListViewProps["onItemActionClick"] =
    React.useCallback(
      (event, goal) => {
        setSelectedGoal(goal);
        pushView(ClientGoalsDrawerView.EDIT);
      },
      [pushView],
    );

  const handleListUpdate: ClientGoalsDrawerListViewProps["onListUpdate"] =
    React.useCallback(
      (goals, [, endIndex]) => {
        const newGoals = goals.map((goal, index) => ({
          id: goal.id,
          ordinal: index + 1,
        }));
        const input = {
          idOrdinal: JSON.stringify(newGoals),
          id: newGoals[endIndex].id,
        };
        changeOrdinal({
          variables: {
            input,
          },
          onSuccess() {
            if (refresh) {
              refresh();
            }
          },
        });
      },
      [changeOrdinal, refresh],
    );

  const save = React.useCallback(() => {
    const dirty = Boolean(
      (name && target && (!selectedGoal || selectedGoal.name !== name)) ||
        (name &&
          target &&
          (!selectedGoal || selectedGoal.targetValue !== target)) ||
        (date &&
          name &&
          target &&
          (!selectedGoal || selectedGoal.date !== date)),
    );

    if (dirty) {
      upsertGoal({
        variables: {
          input: {
            id: selectedGoal?.id,
            clientId: userId,
            name,
            targetValue: target,
            date: date || null,
          },
        },
        onSuccess(response) {
          if (!selectedGoal) {
            snackAlert({
              severity: "success",
              message: "Client goal created.",
            });
          }
          if (refresh) {
            refresh();
          }
          setSelectedGoal(response.upsertClientGoal.clientGoalEdge.node);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, refresh, user, date, name, selectedGoal, snackAlert, upsertGoal]);

  const handleTargetChange = React.useCallback((event) => {
    const pattern = /^[0-9]+$/;
    (pattern.test(event.target.value) || event.target.value === "") &&
      setTarget(event.target.value);
  }, []);

  const handleTargetBlur = React.useCallback(() => {
    save();
  }, [save]);

  const handleNameChange = React.useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleNameBlur = React.useCallback(() => {
    save();
  }, [save]);

  const handleEditDateClick = React.useCallback(() => {
    pushView(ClientGoalsDrawerView.SET_DATE);
  }, [pushView]);

  const handleDateChange = React.useCallback(
    (date: string) => {
      setDate(date);
      save();
      popView();
    },
    [popView, save],
  );

  React.useEffect(() => {
    if (date) {
      save();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const children = React.useMemo(() => {
    switch (view) {
      case ClientGoalsDrawerView.EMPTY:
        return (
          <ClientGoalsDrawerEmptyView
            onClose={handleClose}
            onCreateClick={handleCreateClick}
          />
        );
      case ClientGoalsDrawerView.LIST:
        return (
          <ClientGoalsDrawerListView
            goals={goals}
            onClose={handleClose}
            onCreateClick={handleCreateClick}
            onItemActionClick={handleItemActionClick}
            onListUpdate={handleListUpdate}
          />
        );
      case ClientGoalsDrawerView.EDIT:
        return (
          <ClientGoalsDrawerEditView
            name={name}
            target={target}
            date={date}
            onBack={handleBackClick}
            onDelete={handleDeleteClick}
            onClose={handleClose}
            onNameChange={handleNameChange}
            onTargetChange={handleTargetChange}
            onNameBlur={handleNameBlur}
            onTargetBlur={handleTargetBlur}
            onEditDateClick={handleEditDateClick}
            hideDelete={!selectedGoal?.id}
          />
        );
      case ClientGoalsDrawerView.SET_DATE:
        return (
          <ClientGoalsDrawerSetDateView
            date={date}
            onBack={handleBackClick}
            onDelete={handleDeleteClick}
            onClose={handleClose}
            onDateChange={handleDateChange}
          />
        );
      default:
        console.error("Invalid client drawer view");
        return null;
    }
  }, [
    view,
    handleClose,
    handleCreateClick,
    goals,
    handleItemActionClick,
    handleListUpdate,
    name,
    target,
    date,
    handleBackClick,
    handleDeleteClick,
    handleNameChange,
    handleTargetChange,
    handleNameBlur,
    handleTargetBlur,
    handleEditDateClick,
    handleDateChange,
    selectedGoal,
  ]);

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{
        paper: clsx(
          s.paper,
          view === ClientGoalsDrawerView.SET_DATE && s.overlay,
        ),
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      open={open}
      children={children}
    />
  );
}
