import { useEffect } from "react";

export default function useLocalScript(scriptName: string) {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", `//${window.location.host}/js/${scriptName}`);
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [scriptName]);
}
