/**
 * @generated SignedSource<<1e5fc561cd0a37ed5502dda1ee4fcfea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateEmailInput = {
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
  email?: string | null | undefined;
};
export type ChangeEmailDialogVerifyMutation$variables = {
  input: UpdateEmailInput;
};
export type ChangeEmailDialogVerifyMutation$data = {
  readonly updateEmail: {
    readonly user: {
      readonly email: string | null | undefined;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ChangeEmailDialogVerifyMutation = {
  response: ChangeEmailDialogVerifyMutation$data;
  variables: ChangeEmailDialogVerifyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateEmailPayload",
    "kind": "LinkedField",
    "name": "updateEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEmailDialogVerifyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeEmailDialogVerifyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "02c9d31607a7ed88fdba5a6220cdb2e1",
    "id": null,
    "metadata": {},
    "name": "ChangeEmailDialogVerifyMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeEmailDialogVerifyMutation(\n  $input: UpdateEmailInput!\n) {\n  updateEmail(input: $input) {\n    user {\n      id\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d2cfe0a842affa6c2888e5799c6024c";

export default node;
