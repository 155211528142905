import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { ClientFormScreen } from "../../../components/client-forms/ClientFormScreen";
import { useParams } from "react-router-dom";
import { ClientFormRouteQuery } from "./__generated__/ClientFormRouteQuery.graphql";

export function ClientFormRoute() {
  const { slug } = useParams();

  const props = useLazyLoadQuery<ClientFormRouteQuery>(
    graphql`
      query ClientFormRouteQuery($slug: String!) {
        ...ClientFormScreen_root @arguments(slug: $slug, username: "")
        clientForm(slug: $slug, username: "") {
          id
        }
      }
    `,
    {
      slug: slug,
    },
  );

  return props.clientForm ? <ClientFormScreen root={props} /> : null;
}
