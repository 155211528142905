/**
 * @generated SignedSource<<37279cbc88d8df1f9554756f48a47a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClientStatus = "ACTIVE" | "ADDED" | "ARCHIVED" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientPendingCard_invite$data = {
  readonly client: {
    readonly clientStatus: ClientStatus;
    readonly id: string;
  } | null | undefined;
  readonly email: string;
  readonly id: string;
  readonly invitedAt: string | null | undefined;
  readonly " $fragmentType": "ClientPendingCard_invite";
};
export type ClientPendingCard_invite$key = {
  readonly " $data"?: ClientPendingCard_invite$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientPendingCard_invite">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientPendingCard_invite",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": "invitedAt",
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "fromNow"
        }
      ],
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": "updatedAt(format:\"fromNow\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientStatus",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Invite",
  "abstractKey": null
};
})();

(node as any).hash = "3b9907ec61d6e899533e1c42d66b1539";

export default node;
