import React, { FormEvent } from "react";
import { DialogContent, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useMutation } from "react-relay/hooks";
import clsx from "clsx";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../../../components/dialog/FullScreenDialog";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useSnackAlert } from "../../../hooks/useSnackAlert";
import firebase from "firebase";
import { ChangePasswordDialogMutation } from "./__generated__/ChangePasswordDialogMutation.graphql";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2.5),
  },
  submit: {
    padding: theme.spacing(2),
    fontWeight: 600,
  },
}));

const initialState = {
  password: "",
  newPassword: "",
  newPasswordConfirm: "",
};

const initialErrors = {
  password: undefined,
  newPassword: undefined,
  newPasswordConfirm: undefined,
};

export interface ChangePasswordDialogProps
  extends Omit<FullScreenDialogProps, "title" | "children"> {
  id: string;
}

const updateUserMutation = graphql`
  mutation ChangePasswordDialogMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export function ChangePasswordDialog(props: ChangePasswordDialogProps) {
  const { className, id, ...other } = props;
  const [state, setState] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialErrors);
  const [loading, setLoading] = React.useState(false);
  const user = useCurrentUser();
  const s = useStyles();

  const snackAlert = useSnackAlert();
  const [updateUser] =
    useMutation<ChangePasswordDialogMutation>(updateUserMutation);

  const handleChange = React.useCallback((event) => {
    const { name, value } = event.target;
    setState((x) => (x[name] === value ? x : { ...x, [name]: value }));
    setErrors((x) => (x[name] ? { ...x, [name]: undefined } : x));
  }, []);

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const err: any = {};

    if (!state.password) {
      err.password = "Old password is required";
    }

    if (!state.newPassword) {
      err.newPassword = "New password is required.";
    }

    if (state.newPassword !== state.newPasswordConfirm) {
      err.newPasswordConfirm = "Passwords do not match.";
    }

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, state.password)
      .then((x) =>
        x.user
          .updatePassword(state.newPassword)
          .then(
            () =>
              new Promise((resolve, reject) =>
                updateUser({
                  variables: {
                    input: {
                      id,
                      passwordUpdated: true,
                    },
                  },
                  onCompleted: (response, error) => {
                    if (error) {
                      reject(error);
                    } else {
                      resolve(response);
                    }
                  },
                }),
              ),
          )
          .then(() => {
            setLoading(false);
            snackAlert({
              severity: "success",
              message: "Password updated",
            });
            props.onClose(event, "escapeKeyDown");
          })
          .catch((err) => {
            setErrors((x) => ({ ...x, newPassword: err.message }));
            setLoading(false);
          }),
      )
      .catch((err) => {
        setLoading(false);
        setErrors((x) => ({ ...x, password: err.message }));
      });
  }

  return (
    <FullScreenDialog title="Change password" {...other}>
      <DialogContent>
        <form id="change-password-form" onSubmit={handleSubmit}>
          <TextField
            className={s.field}
            variant="outlined"
            name="password"
            type="password"
            label="Current Password"
            placeholder="* * * * * *"
            value={state.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
            disabled={loading}
            fullWidth
            //needed to to fix cutting of label
            margin="dense"
          />
          <TextField
            className={s.field}
            variant="outlined"
            name="newPassword"
            type="password"
            label="New Password"
            placeholder="* * * * * *"
            value={state.newPassword}
            onChange={handleChange}
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword}
            disabled={loading}
            fullWidth
          />
          <TextField
            className={s.field}
            variant="outlined"
            name="newPasswordConfirm"
            type="password"
            label="Confirm New Password"
            placeholder="* * * * * *"
            value={state.newPasswordConfirm}
            onChange={handleChange}
            error={Boolean(errors.newPasswordConfirm)}
            helperText={errors.newPasswordConfirm}
            disabled={loading}
            fullWidth
          />

          <Button
            className={s.submit}
            variant="contained"
            type="submit"
            form="change-password-form"
            disabled={loading}
            children="Change password"
            fullWidth
          />
        </form>
      </DialogContent>
    </FullScreenDialog>
  );
}
