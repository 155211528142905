import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { Programs } from "../../../../components/client-programs/Programs";
import { EnrollmentStatus } from "../../../../constants";
import { ClientProgramListRouteQuery } from "./__generated__/ClientProgramListRouteQuery.graphql";
import ProgramsContext from "../../../../contexts/ClientPorgrams";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../../../utils/relay";

interface IClientProgramListRouteProps {
  status: EnrollmentStatus;
}

function ClientProgramListRoute({ status }: IClientProgramListRouteProps) {
  const props = useLazyLoadQuery<ClientProgramListRouteQuery>(
    graphql`
      query ClientProgramListRouteQuery($status: EnrollmentStatus!) {
        enrollments(status: $status) {
          ...Programs_enrollments
        }
        me {
          enrollmentsStatus {
            active
            past
            upcoming
          }
        }
      }
    `,
    {
      status: status,
    },
    RELAY_LAZY_LOAD_COMMON_CONFIG,
  );

  const {
    enrollments,
    me: {
      enrollmentsStatus: { active, upcoming, past },
    },
  } = props;

  const context = useContext(ProgramsContext);
  context.setProgramsData(props);

  return <Programs enrollmentsRef={enrollments} />;
}

export const ClientProgramsActiveRoute = () => {
  return <ClientProgramListRoute status={EnrollmentStatus.ACTIVE} />;
};

export const ClientProgramsUpcomingRoute = () => {
  return <ClientProgramListRoute status={EnrollmentStatus.UPCOMING} />;
};

export const ClientProgramsPastRoute = () => {
  return <ClientProgramListRoute status={EnrollmentStatus.PAST} />;
};
