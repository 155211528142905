import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { AppLayout } from "../../../../components/app/AppLayout";
import { ClientProfileMeasurementScreen } from "../../../../components/screen/ClientProfileMeasurementScreen";
import { ClientProfileTrendsRouteQuery } from "./__generated__/ClientProfileTrendsRouteQuery.graphql";

export function ClientProfileTrendsRoute() {
  const props = useLazyLoadQuery<ClientProfileTrendsRouteQuery>(
    graphql`
      query ClientProfileTrendsRouteQuery($measurementType: MeasurementType) {
        user: me {
          ...ClientProfileMeasurementScreen_user
            @arguments(measurementType: $measurementType)
        }
      }
    `,
    {
      measurementType: "BODYFAT",
    },
  );

  const { user } = props;

  return (
    user && (
      <AppLayout appBar={false} appDrawer={false}>
        <ClientProfileMeasurementScreen userRef={user} />
      </AppLayout>
    )
  );
}
