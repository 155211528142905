/**
 * @generated SignedSource<<98ad58266a9c2b6c4244f901121f474d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignupClientCompleteScreen_user$data = {
  readonly displayName: string | null | undefined;
  readonly id: string;
  readonly photoURL: string | null | undefined;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"ClientSettingsForm_settings">;
  readonly " $fragmentType": "SignupClientCompleteScreen_user";
};
export type SignupClientCompleteScreen_user$key = {
  readonly " $data"?: SignupClientCompleteScreen_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignupClientCompleteScreen_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignupClientCompleteScreen_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClientSettingsForm_settings"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoURL",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "649bfbf09f8082a3ab724564a8c56e4b";

export default node;
