import React, { FC, useCallback, useState, useTransition } from "react";
import { Drawer, DrawerProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "react-relay";

import { ClientAutoNutritionEntryHistory } from "./ClientAutoNutritionEntryHistory";
import { ClientNutritionDrawerHeader } from "./ClientNutritionDrawerHeader";
import AddAutoNutritionEntryForm from "./AddAutoNutritionEntryForm";
import { ClientAutoNutritionDrawerRefetchQuery } from "./__generated__/ClientAutoNutritionDrawerRefetchQuery.graphql";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  header: {
    marginBottom: theme.spacing(4),
  },
}));

export interface ClientAutoNutritionDrawerProps extends DrawerProps {
  refresh: any;
}

export const ClientAutoNutritionDrawer: FC<ClientAutoNutritionDrawerProps> = (
  props,
) => {
  const { onClose, refresh, ...restProps } = props;
  const s = useStyles();
  const [isPending, startTransition] = useTransition();
  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
    variables: {},
  });

  const data = useLazyLoadQuery<ClientAutoNutritionDrawerRefetchQuery>(
    graphql`
      query ClientAutoNutritionDrawerRefetchQuery {
        me {
          autoNutritionHistory {
            id
            date(raw: true)
            calories
            carbs
            fat
            protein
            cholesterol
            fiber
            sugar
            sodium
            transFat
            alcohol
          }
        }
      }
    `,
    queryArgs.variables,
    queryArgs.options,
  );

  const {
    me: { autoNutritionHistory },
  } = data;

  const refetch = useCallback(() => {
    startTransition(() => {
      setQueryArgs((prev) => ({
        ...prev,
        options: {
          fetchKey: (prev?.options.fetchKey ?? 0) + 1,
          fetchPolicy: "store-and-network",
        },
      }));
      refresh?.();
    });
  }, [refresh]);

  const handleClose = useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  return (
    <Drawer
      classes={{
        paper: s.paper,
      }}
      anchor="right"
      variant="persistent"
      onClose={handleClose}
      {...restProps}
    >
      <ClientNutritionDrawerHeader
        className={s.header}
        onClose={handleClose}
        title="Synced nutrition entries"
      />
      <AddAutoNutritionEntryForm
        entries={autoNutritionHistory}
        refresh={refetch}
      />
      <ClientAutoNutritionEntryHistory
        entries={autoNutritionHistory}
        refresh={refetch}
      />
    </Drawer>
  );
};
