/**
 * @generated SignedSource<<2ea1c9090b86e8850c373335e48a831c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LikeInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type LikeMutation$variables = {
  input: LikeInput;
};
export type LikeMutation$data = {
  readonly like: {
    readonly likeEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"LikeListItem_like">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type LikeMutation = {
  response: LikeMutation$data;
  variables: LikeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LikeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LikePayload",
        "kind": "LinkedField",
        "name": "like",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LikeEdge",
            "kind": "LinkedField",
            "name": "likeEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Like",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "LikeListItem_like"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LikeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LikePayload",
        "kind": "LinkedField",
        "name": "like",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LikeEdge",
            "kind": "LinkedField",
            "name": "likeEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Like",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c732cf7f12d7630997e4b0ea2097ca0a",
    "id": null,
    "metadata": {},
    "name": "LikeMutation",
    "operationKind": "mutation",
    "text": "mutation LikeMutation(\n  $input: LikeInput!\n) {\n  like(input: $input) {\n    likeEdge {\n      node {\n        ...LikeListItem_like\n        id\n      }\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  displayName\n  photoURL\n}\n\nfragment LikeListItem_like on Like {\n  author {\n    id\n    ...Avatar_user\n  }\n}\n"
  }
};
})();

(node as any).hash = "550ddf19315cdf5c50b38e90f67ea972";

export default node;
