/**
 * @generated SignedSource<<6d164c1af1b4613e9c0dc039bf40220a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Notifications_user$data = {
  readonly id: string;
  readonly notifications: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    } | null | undefined> | null | undefined;
    readonly unreadCount: number;
  } | null | undefined;
  readonly notificationsActive: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenu_userRef">;
  readonly " $fragmentType": "Notifications_user";
};
export type Notifications_user$key = {
  readonly " $data"?: Notifications_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Notifications_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "notifications"
        ]
      }
    ]
  },
  "name": "Notifications_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationsActive",
      "storageKey": null
    },
    {
      "alias": "notifications",
      "args": null,
      "concreteType": "NotificationConnection",
      "kind": "LinkedField",
      "name": "__NotificationsMenu_notifications_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Notification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationsMenu_userRef"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "75db72dfe75b64d0b6d05cf346bdfd9b";

export default node;
