import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { Button, useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      backgroundColor: theme.palette.common.black,
      padding: "12px 40px",
      borderRadius: 4,
      color: theme.palette.common.white,
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 8,
        fontWeight: "600",
        padding: "8px 26px",
      },
      "&:hover": {
        backgroundColor: colorSystem.gray2,
      },
    },
  };
});

const WorkoutFinishAction = (props: any) => {
  const { handleSubmit, submitted } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const actionPrefix = submitted ? "Save" : "Finish";
  return (
    <Button
      className={clsx(s.container)}
      children={smUp ? `${actionPrefix} workout` : actionPrefix}
      onClick={handleSubmit}
    />
  );
};

export default WorkoutFinishAction;
