import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { ExerciseLibraryContext } from "../../../../components/exercise-library/hooks/useExerciseLibrary";
import { EditorAddExercise } from "../../../../hooks/useAddExercise";
import { Activity } from "../../../../components/activity/Activity";
import { useParams, useSearchParams } from "react-router-dom";
import { ClientProgramsProgramComponentRouteQuery } from "./__generated__/ClientProgramsProgramComponentRouteQuery.graphql";

export function ClientProgramsProgramComponentRoute() {
  const { program, date, component } = useParams();

  const [searchParams] = useSearchParams();
  const summary = searchParams.get("summary");

  const variables = {
    component: component,
    date: date,
    program: program,
    shouldFetchSummary: summary === "yes",
  };

  const props = useLazyLoadQuery<ClientProgramsProgramComponentRouteQuery>(
    graphql`
      query ClientProgramsProgramComponentRouteQuery(
        $program: String!
        $date: String!
        $component: String!
        $shouldFetchSummary: Boolean!
      ) {
        activity(client: "$me", date: $date, component: $component) {
          ...Activity_activity
            @arguments(shouldFetchSummary: $shouldFetchSummary)
          component {
            ...RefreshSlug
          }
        }
        ...useExerciseLibrary_rootRef
        program(slug: $program, showArchived: true) {
          id
          ...RefreshSlug
          ...CheckInAnswerProgressPhotoUpload_program
          ...WorkoutSectionElement_program
        }
      }
    `,
    variables,
  );

  return (
    props?.program &&
    props?.activity && (
      <>
        <RefreshSlug nodesRef={[props.program, props.activity.component]} />
        <ExerciseLibraryContext.Provider value={props}>
          <EditorAddExercise.Provider value={true}>
            <Activity
              activityRef={props.activity}
              variables={variables}
              program={props.program}
            />
          </EditorAddExercise.Provider>
        </ExerciseLibraryContext.Provider>
      </>
    )
  );
}
