export function useGapiAuth2ClientId(): string {
  switch (window.location.hostname) {
    case "stridist.com":
      return "168713142478-q2h3smqrd4ut7k7asp1m0mmgflg9fgma.apps.googleusercontent.com";
    case "dev.stridist.com":
    case "localhost":
      return "956707039105-ce9s6rn37e5dmtuqf25d654p95pv7rk5.apps.googleusercontent.com";
    default:
      // *test.stridist.com
      return "722029373286-k6ol9d16ir2i4cflumtodf4ia64akb6j.apps.googleusercontent.com";
  }
}
