import clsx from "clsx";
import React from "react";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactCrop, { Crop, ReactCropProps } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Close as CloseIcon } from "@mui/icons-material";

import { ActionButton } from "../button/ActionButton";
import { CropScaled } from "../editor/types/legacy";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
  },
  paper: {
    maxWidth: "initial",
    minWidth: "25vw",
    minHeight: "25vh",
  },
  title: {
    "& h2": {
      fontSize: 24,
      fontWeight: 600,
      color: theme.palette.common.black,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {},
  closeButton: {
    float: "right",
  },
}));

export type CropImageDialogCrop = ReactCrop.Crop & CropScaled;

export interface CropImageDialogProps extends Omit<DialogProps, "title"> {
  src: string;
  title?: React.ReactNode;
  crop?: CropScaled;
  onCrop?: (crop: CropImageDialogCrop) => void;
  showCloseButton?: boolean;
  cropOptions?: Partial<CropScaled>;
  ReactCropProps?: Partial<ReactCropProps>;
}

export function CropImageDialog(props: CropImageDialogProps) {
  const {
    className,
    src,
    onCrop,
    crop: initialCrop,
    title = "Crop Image",
    showCloseButton = false,
    children,
    cropOptions = {},
    ReactCropProps = {},
    ...other
  } = props;
  const { onClose } = props;
  const s = useStyles();
  const [image, setImage] = React.useState<HTMLImageElement>(null);
  const [crop, setCrop] = React.useState<CropScaled>({
    ...initialCrop,
    ...cropOptions,
  });

  const handleImageLoaded = React.useCallback((image: HTMLImageElement) => {
    setImage(image);
  }, []);

  const handleCropChange = React.useCallback(
    (crop: Crop) => {
      if (image) {
        const newCrop = {
          ...crop,
          scaleX: image.naturalWidth / image.clientWidth,
          scaleY: image.naturalHeight / image.clientHeight,
        };
        setCrop(newCrop);
      }
    },
    [image],
  );

  const handleSaveClick = React.useCallback(
    (event) => {
      if (onCrop) {
        onCrop(Boolean(crop.width) && crop);
      }

      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onCrop, crop, onClose],
  );

  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      PaperProps={{ className: s.paper }}
      onClose={onClose}
      {...other}
    >
      <DialogTitle className={s.title}>
        {title}
        {showCloseButton && (
          <IconButton
            className={clsx(s.closeButton)}
            onClick={handleClose}
            children={<CloseIcon />}
            size="large"
          />
        )}
      </DialogTitle>

      <DialogContent className={s.content}>
        {src && (
          <ReactCrop
            crop={crop}
            onChange={handleCropChange}
            {...ReactCropProps}
          >
            <img
              src={src}
              style={{ maxHeight: "70vh" }}
              onLoad={(e) => {
                handleImageLoaded(e.target as HTMLImageElement);
              }}
            />
          </ReactCrop>
        )}
        {children}
      </DialogContent>

      <DialogActions>
        <ActionButton
          className={s.button}
          fullWidth
          children="Save"
          onClick={handleSaveClick}
          disabled={!crop}
        />
      </DialogActions>
    </Dialog>
  );
}
