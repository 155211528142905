import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Popover } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { graphql, useFragment } from "react-relay/hooks";

import { ClientCalendarProgramCard } from "../card/ClientCalendarProgramCard";
import { ChooseProgramEnrollDialog_enrollments$key } from "../dialog/__generated__/ChooseProgramEnrollDialog_enrollments.graphql";

import { CoachClientCalendarEnrollment_enrollment$key } from "./__generated__/CoachClientCalendarEnrollment_enrollment.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.75),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    margin: theme.spacing(0.5),
  },

  name: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "15px",
    color: theme.palette.common.white,
  },
}));

const enrollmentFragment = graphql`
  fragment CoachClientCalendarEnrollment_enrollment on Enrollment {
    program {
      name
    }
    ...ClientCalendarProgramCard_enrollment
  }
`;

export interface CoachClientCalendarEnrollmentProps extends BoxProps {
  enrollmentRef: CoachClientCalendarEnrollment_enrollment$key;
  enrollmentsRef: ChooseProgramEnrollDialog_enrollments$key;
}

export function CoachClientCalendarEnrollment(
  props: CoachClientCalendarEnrollmentProps,
) {
  const { className, enrollmentRef, enrollmentsRef, ...other } = props;
  const s = useStyles();
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);

  const enrollmentState = usePopupState({
    variant: "popover",
    popupId: "enrollmentCard",
  });
  const enrollmentTriggerProps = React.useMemo(
    () => bindTrigger(enrollmentState),
    [enrollmentState],
  );
  const enrollmentPopoverProps = React.useMemo(
    () => bindPopover(enrollmentState),
    [enrollmentState],
  );

  const closeEnrollmentPopover = React.useCallback(() => {
    enrollmentState.close();
  }, [enrollmentState]);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography
        className={s.name}
        children={enrollment.program.name}
        {...enrollmentTriggerProps}
      />
      <Popover {...enrollmentPopoverProps}>
        <ClientCalendarProgramCard
          enrollmentRef={enrollment}
          enrollmentsRef={enrollmentsRef}
          onRemove={closeEnrollmentPopover}
          onChangeStartDate={closeEnrollmentPopover}
          onChangeProgram={closeEnrollmentPopover}
        />
      </Popover>
    </Box>
  );
}
