import React from "react";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { RefreshSlug$key } from "./__generated__/RefreshSlug.graphql";
import { UpdateLocation } from "./UpdateLocation";
import { useLocation } from "react-router-dom";

const fragment = graphql`
  fragment RefreshSlug on Slug @relay(plural: true) {
    slug
    slugId
  }
`;

export interface RefreshSlugProps {
  nodesRef: RefreshSlug$key;
}

export function RefreshSlug({ nodesRef }: RefreshSlugProps) {
  const location = useLocation();
  const nodes = useFragment(fragment, nodesRef.filter(Boolean));
  const pathname = location.pathname
    .split("/")
    .map(
      (chunk) =>
        nodes.find(({ slugId }) => chunk.endsWith(slugId))?.slug || chunk,
    )
    .join("/");

  return (
    pathname !== location.pathname && <UpdateLocation pathname={pathname} />
  );
}
