import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { ElementType } from "../editor/types/elements";

import { ClientFormSection, ClientFormSectionInfo } from "./types";
import { ELEMENT_H1, ELEMENT_H2 } from "@udecode/plate-heading";
import { ELEMENT_CALLOUT } from "../new-editor/elements/Callout";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { FILE } from "../new-editor/elements/FileUploader";
import { VIDEO } from "../new-editor/elements/Video";
import { IMAGE_ELEMENT } from "../new-editor/elements/Image";
import { ListType } from "../new-editor/utils/listElementsUtils";

export const ClientFormSections: Record<
  ClientFormSection,
  ClientFormSectionInfo
> = {
  [ClientFormSection.CONTENT]: {
    name: "Content",
    types: [
      ELEMENT_PARAGRAPH,
      ELEMENT_H1,
      ELEMENT_H2,
      ELEMENT_CALLOUT,
      ELEMENT_TABLE,
      ListType.TODO,
      ListType.ORDERED,
      ListType.UNORDERED,
      FILE,
      VIDEO,
      IMAGE_ELEMENT,
      "preview_link",
    ],
  },

  [ClientFormSection.QUESTIONS]: {
    name: "Questions",
    types: [
      ElementType.CHECKIN_ANSWER_BINARY,
      ElementType.CHECKIN_ANSWER_MEASUREMENT,
      ElementType.CHECKIN_ANSWER_RATING_SCALE,
      ElementType.CHECKIN_ANSWER_LONG_TEXT,
      ElementType.CHECKIN_ANSWER_SHORT_TEXT,
      ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE,
      ElementType.CHECKIN_ANSWER_MULTIPLE_CHOICE_CHECKBOX,
      ElementType.CHECKIN_ANSWER_FILE,
      ElementType.CHECKIN_ANSWER_NUMBER,
      ElementType.CHECKIN_ANSWER_DATE,
      ElementType.CHECKIN_ANSWER_EMOJI,
      ElementType.CHECKIN_ANSWER_STAR_RATING,
      ElementType.CHECKIN_ANSWER_NUTRITION_TARGET,
    ],
  },
};

export const defaultClientFormName = "New client intake form";
