import clsx from "clsx";
import React from "react";
import { Dialog, DialogProps, Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactPlayer from "react-player";
import { ReactComponent as CloseIcon } from "../../icons/CloseOutline.svg";
import { WorkoutExerciseImage } from "../workout/types";
import { getCloudFlareSources, isVideoStream } from "../../utils/component";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
    "& > :nth-child(1)": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },

  paper: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    margin: 0,
    boxShadow: "none",
    backgroundColor: "transparent",
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "90vh",
  },

  images: {
    display: "flex",
    width: "100%",
    height: "100%",
    overflowX: "scroll",
    overflowY: "hidden",

    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "&::before, &::after": {
      content: '""',
      display: "block",
      height: 1,
      minWidth: "25vw",
    },
  },

  media: {
    flex: "0 0 auto",
    height: "100%",
    maxWidth: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > *": {
      flex: "0 1",
      height: "100%",
      width: "auto",
      maxWidth: "100%",
      display: "block",
      objectFit: "contain",
      transition: theme.transitions.create(["transform"]),
      zIndex: 2,
      position: "relative",
    },
    "&:not($active) > *": {
      transform: "scale(0.8)",
      zIndex: 1,
    },
  },

  active: {},

  video: {
    minWidth: 356,
    maxWidth: 356,
    width: 356,
    minHeight: 200,
    maxHeight: 200,
    height: 200,

    [theme.breakpoints.up("md")]: {
      minWidth: 695,
      maxWidth: 695,
      width: 695,
      minHeight: 391,
      maxHeight: 391,
      height: 391,
    },

    "& [data-vjs-player]": {
      paddingTop: "unset",
      height: 200,

      [theme.breakpoints.up("md")]: {
        height: 391,
      },
    },

    "& .vjs-big-play-button": {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },

  image: {},

  scrollButtons: {
    display: "flex",
    marginTop: theme.spacing(2),
  },

  bullet: {
    borderRadius: "50%",
    margin: theme.spacing(0.5),
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",

    "&$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  closeButton: {
    position: "fixed",
    right: theme.spacing(4),
    top: theme.spacing(4),
    padding: 0,
    borderRadius: 0,
    color: theme.palette.common.white,
  },
}));

export interface WorkoutMediaGalleryProps extends DialogProps {
  images: WorkoutExerciseImage[];
  defaultIndex?: number;
}

export function WorkoutMediaGallery(props: WorkoutMediaGalleryProps) {
  const { className, images, defaultIndex = 0, ...other } = props;
  const s = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);
  const [scrolling, setScrolling] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>();

  const handlePageClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const {
        dataset: { mediaIndex },
      } = event.currentTarget;

      setScrolling(true);
      setSelectedIndex(parseInt(mediaIndex));
    },
    [],
  );

  const handleScroll = React.useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const el = event.currentTarget;

      for (const mediaEl of el.querySelectorAll("[data-media-index]")) {
        const rect = mediaEl.getBoundingClientRect();

        if (rect.left > 0) {
          const index = parseInt((mediaEl as HTMLElement).dataset.mediaIndex);

          if (scrolling) {
            if (index === selectedIndex) {
              setScrolling(false);
            }
          } else {
            setSelectedIndex(index);
          }

          break;
        }
      }
    },
    [scrolling, selectedIndex],
  );

  const handleClose = React.useCallback(
    (event) => {
      event.stopPropagation();

      if (props.onClose) {
        props.onClose(event, "backdropClick");
      }
    },
    [props],
  );

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const el = ref.current;

      if (el && scrolling) {
        const mediaEl = el.querySelector(
          `[data-media-index="${selectedIndex}"]`,
        );

        if (mediaEl) {
          mediaEl.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      }
    }, 25);

    return () => clearTimeout(timer);
  }, [scrolling, selectedIndex]);

  const handleMediaClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const getVideoSource = (url) =>
    url ? (isVideoStream(url) ? getCloudFlareSources(url)[2].src : url) : null;

  return (
    <Dialog
      classes={{
        root: clsx(s.root, className),
        paper: s.paper,
      }}
      {...other}
    >
      <Box className={s.wrapper} onClick={handleClose}>
        <IconButton
          className={s.closeButton}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <div ref={ref} className={s.images} onScroll={handleScroll}>
          {images.map((media, index) => (
            <Box
              key={index}
              data-media-index={index}
              className={clsx(s.media, selectedIndex === index && s.active)}
              onClick={handleMediaClick}
            >
              {media.type === "video" ? (
                getVideoSource(media.url) && (
                  <ReactPlayer
                    className={s.video}
                    url={getVideoSource(media.url)}
                    controls
                  />
                )
              ) : (
                <img className={s.image} src={media.url} alt="" />
              )}
            </Box>
          ))}
        </div>
        <Box className={s.scrollButtons}>
          {images.map((src, index) => (
            <Box
              key={index}
              className={clsx(s.bullet, selectedIndex === index && s.active)}
              data-media-index={index}
              onClick={handlePageClick}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
}
