/**
 * @generated SignedSource<<7e8078ee20a2b206e3370790e7a0d36a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Gender = "FEMALE" | "MALE" | "PRIVATE" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
export type UpdateUserInput = {
  admin?: boolean | null | undefined;
  birthday?: string | null | undefined;
  clientMutationId?: string | null | undefined;
  displayName?: string | null | undefined;
  gender?: Gender | null | undefined;
  height?: string | null | undefined;
  id: string;
  location?: string | null | undefined;
  passwordUpdated?: boolean | null | undefined;
  phone?: string | null | undefined;
  phoneCode?: string | null | undefined;
  photoURL?: string | null | undefined;
  themeOptions?: string | null | undefined;
  timeZone?: string | null | undefined;
  topToolbar?: boolean | null | undefined;
  trialExpiryDate?: string | null | undefined;
  units?: Units | null | undefined;
  username?: string | null | undefined;
  weight?: string | null | undefined;
};
export type ChangePasswordDialogMutation$variables = {
  input: UpdateUserInput;
};
export type ChangePasswordDialogMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type ChangePasswordDialogMutation = {
  response: ChangePasswordDialogMutation$data;
  variables: ChangePasswordDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePasswordDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2df48fc38383eaaac3449b7793b7a978",
    "id": null,
    "metadata": {},
    "name": "ChangePasswordDialogMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePasswordDialogMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7f50c2ab8a8777472d3631b1ab5e4bf";

export default node;
