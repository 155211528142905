import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, List } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql, useFragment } from "react-relay/hooks";

import { GroupSidebarMembers_group$key } from "./__generated__/GroupSidebarMembers_group.graphql";
import { GroupSidebarMemberItem } from "./GroupSidebarMemberItem";

const useStyles = makeStyles((theme) => ({
  root: {},

  label: {
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}));

const fragment = graphql`
  fragment GroupSidebarMembers_group on Group {
    members {
      totalCount
      edges {
        node {
          ...GroupSidebarMemberItem_member
          id
        }
      }
    }
  }
`;

export interface GroupSidebarMembersProps extends BoxProps {
  groupRef: GroupSidebarMembers_group$key;
}

export function GroupSidebarMembers(props: GroupSidebarMembersProps) {
  const { className, groupRef, ...other } = props;
  const s = useStyles();
  const { members } = useFragment(fragment, groupRef);

  return (
    members.totalCount > 0 && (
      <Box className={clsx(s.root, className)} {...other}>
        <Typography variant="h4" className={s.label}>
          Members
        </Typography>

        <List>
          {members.edges.map(({ node }) => (
            <GroupSidebarMemberItem key={node.id} memberRef={node} />
          ))}
        </List>
      </Box>
    )
  );
}
