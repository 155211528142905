import clsx from "clsx";
import React, { useState, startTransition } from "react";
import {
  Container,
  Box,
  ContainerProps,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import {
  useFragment,
  useMutation,
  useRefetchableFragment,
} from "react-relay/hooks";

import { useQueryParam } from "../../hooks/useQueryParam";
import { useMediaDnDSupported } from "../../hooks/useMediaDnDSupported";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { usePreview } from "../../hooks/usePreview";
import { useLocation, useSearchParams } from "react-router-dom";
import { useProgramEnrollmentsList } from "../../hooks/useProgramEnrollmentsList";
import { ShowCoversContext } from "../../hooks/useShowCovers";
import { EnableColorsContext } from "../../hooks/useEnableColors";
import { ProgramDetails } from "../program/ProgramDetails";
import {
  DEFAULT_PROGRAM_DETAILS_VIEW_MODE,
  ProgramDetailsViewButton,
  ProgramDetailsViewMode,
  defaultCalendarViewOptions,
} from "../program/ProgramDetailsViewButton";
import {
  ProgramDetailsFilters,
  defaultFilters,
  Filters,
} from "../program/ProgramDetailsFilters";
import {
  ProgramStartDateViewButton,
  ProgramStartDateViewButtonProps,
} from "../program/ProgramStartDateViewButton";
import { EditorProgramContext } from "../new-editor/hooks";
import {
  generateUniqueServerID,
  useInterruptiveMutation,
} from "../dirty-transaction/hooks";
import { DirtyTransaction } from "../dirty-transaction/DirtyTransaction";
import {
  ProgramStartDateView,
  ProgramStartDateViewDetails,
} from "../program/ProgramStartDateViewButton";
import { ToggleCoversButton } from "../button/ToggleCoversButton";
import { ToggleColorsButton } from "../button/ToggleColorsButton";
import { toEnum } from "../../utils/misc";
import { useSnackAlert } from "../../hooks/useSnackAlert";
import { isMobileApp } from "../../utils/mobile";

import { CurriculumEditScreen_program$key } from "./__generated__/CurriculumEditScreen_program.graphql";
import {
  CurriculumEditScreenMoveWeekMutation,
  WeekMoveDirectionType,
} from "./__generated__/CurriculumEditScreenMoveWeekMutation.graphql";
import { useHistoryBlock } from "../history-block/hooks";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { LockOpen, Lock } from "lucide-react";
import { LoadingButton } from "@mui/lab";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";
import { CurriculumEditScreenComponentsLockedStatusMutation } from "./__generated__/CurriculumEditScreenComponentsLockedStatusMutation.graphql";
import { RELAY_LAZY_LOAD_COMMON_CONFIG } from "../../utils/relay";
import { ProgramRefetchContext } from "../../hooks/useProgramRefetch";
import {
  ProgramWeekRangeDetails,
  ProgramWeekRangeSelector,
  ProgramWeekRangeSelectorProps,
} from "../program/ProgramWeekRangeSelector";
import {
  CURRICULUM_VIEW_KEY,
  INITIAL_WEEK_DETAILS,
  MAX_WEEK_RANGE,
  getCurriculumViewStorageKey,
  getLimitedWeekMaxNumber,
} from "../../routes/coach/program/curriculum/CoachProgramCurriculumRoute";
import { CurriculumEditScreen_weeks$key } from "./__generated__/CurriculumEditScreen_weeks.graphql";
import { ProgramWeek, ProgramWeeksContext } from "../../hooks/useProgramWeeks";
import {
  TEMPLATE_WORKOUT_TITLE,
  useGenerateTemplateWorkoutSection,
} from "../workout/utils";
import { ComponentRepeat, ComponentType, ProgramStatus } from "../../constants";
import { defaultComponentDays } from "../../utils/component";
import { ProgramDetailsAddComponentMutation } from "../program/__generated__/ProgramDetailsAddComponentMutation.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: 1500,
  },

  calendar: {},
  spreadsheet: {
    maxWidth: "unset",
  },
  list: {
    maxWidth: 1116,
  },

  filters: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginLeft: "unset",
    flexDirection: "column",
    gap: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  filtersRight: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
}));

const addWeekMutation = graphql`
  mutation CurriculumEditScreenAddWeekMutation(
    $input: AddWeekInput!
    $startNumber: Int!
    $endNumber: Int!
  ) {
    addWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program
          @arguments(startNumber: $startNumber, endNumber: $endNumber)
      }
    }
  }
`;

const deleteWeekMutation = graphql`
  mutation CurriculumEditScreenDeleteWeekMutation(
    $input: DeleteWeekInput!
    $startNumber: Int!
    $endNumber: Int!
  ) {
    deleteWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program
          @arguments(startNumber: $startNumber, endNumber: $endNumber)
      }
    }
  }
`;

const duplicateWeekMutation = graphql`
  mutation CurriculumEditScreenDuplicateWeekMutation(
    $input: DublicateWeekInput!
    $startNumber: Int!
    $endNumber: Int!
  ) {
    dublicateWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program
          @arguments(startNumber: $startNumber, endNumber: $endNumber)
      }
    }
  }
`;

const moveWeekMutation = graphql`
  mutation CurriculumEditScreenMoveWeekMutation(
    $input: MoveWeekInput!
    $startNumber: Int!
    $endNumber: Int!
  ) {
    moveWeek(input: $input) {
      program {
        ...CurriculumEditScreen_program
          @arguments(startNumber: $startNumber, endNumber: $endNumber)
      }
    }
  }
`;

const enrollmentsFragment = graphql`
  fragment CurriculumEditScreen_enrollments on EnrollmentConnection {
    edges {
      node {
        client {
          displayName
        }
      }
    }
  }
`;

const updateComponentsLockedStatusMutation = graphql`
  mutation CurriculumEditScreenComponentsLockedStatusMutation(
    $input: UpdateComponentsLockedStatusByProgramIdInput!
  ) {
    updateComponentsLockedStatusByProgramId(input: $input) {
      components {
        id
        locked
      }
    }
  }
`;

const programFragment = graphql`
  fragment CurriculumEditScreen_program on Program
  @refetchable(queryName: "CurriculumEditScreenRefetchQuery")
  @argumentDefinitions(
    startNumber: { type: "Int", defaultValue: 1 }
    endNumber: { type: "Int", defaultValue: 3 }
  ) {
    weeks: weekRange(startNumber: $startNumber, endNumber: $endNumber) {
      week
      id
      positions
      components(archived: true) {
        ...ProgramCalendarComponentButtons_component
        ...ProgramCalendarComponent_component
        ...WeekComponentList_components
        ...DragCellRenderer_component
        id
        type
        status
        iconName
        weekId
        title(draft: true)
        duration
        repeat
        days
        locked
        content(draft: true)
        slug
        draftExists
      }
      ...WeekCard_week @relay(plural: true)
      ...ProgramCalendarWeek_week
      ...ProgramSpreadsheetWeek_week
    }
    id
    name
    slug
    defaultView
    length
    isEmpty
  }
`;

const programWeeksFragment = graphql`
  fragment CurriculumEditScreen_weeks on Program
  @refetchable(queryName: "CurriculumEditScreenWeeksRefetchQuery") {
    weeks(first: 9999, after: "") {
      edges {
        node {
          id
          week
        }
      }
    }
  }
`;

const addComponentMutation = graphql`
  mutation CurriculumEditScreenAddComponentMutation(
    $input: UpsertComponentInput!
  ) {
    upsertComponent(input: $input) {
      component {
        slug
        id
      }
    }
  }
`;

export interface CurriculumViewOptions {
  view: ProgramDetailsViewMode;
  dateDetails: ProgramStartDateViewDetails;
  weekDetails: ProgramWeekRangeDetails;
  showCovers: boolean;
  enableColors: boolean;
}

export interface CurriculumEditScreenProps
  extends Omit<ContainerProps, "children"> {
  programRef?: CurriculumEditScreen_program$key;
  programWeeksRef?: CurriculumEditScreen_weeks$key;
}

export function CurriculumEditScreen({
  programRef,
  programWeeksRef,
}: CurriculumEditScreenProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const s = useStyles();

  const [program, refetch] = useRefetchableFragment(
    programFragment,
    programRef,
  );

  const [allWeeksData, allWeeksRefetch] = useRefetchableFragment(
    programWeeksFragment,
    programWeeksRef,
  );

  const programWeeks: Array<ProgramWeek> = allWeeksData.weeks.edges.map(
    ({ node }) => ({
      id: node.id,
      week: node.week,
    }),
  );

  const enrollmentsRef = useProgramEnrollmentsList() as any;

  const [searchParams] = useSearchParams();
  const component = searchParams.get("component");

  const enrollments = useFragment(enrollmentsFragment, enrollmentsRef);
  const [unsafe] = useHistoryBlock();
  const location = useLocation();
  const [preview] = usePreview();
  const [filters, setFilters] = React.useState(defaultFilters);
  const canUseCalendar = useMediaDnDSupported() && !isSm;
  const { defaultView, id: programId } = program;
  const [loading, setLoading] = React.useState(false);

  const snackAlert = useSnackAlert();

  const [addWeek, addWeekInFlight] = useMutation(addWeekMutation);

  const [deleteWeek] = useInterruptiveMutation(deleteWeekMutation);

  const [dublicateWeek, dublicateWeekInFlight] = useMutation(
    duplicateWeekMutation,
  );

  const [moveWeek] =
    useMutation<CurriculumEditScreenMoveWeekMutation>(moveWeekMutation);

  const [updateComponents] =
    useMutation<CurriculumEditScreenComponentsLockedStatusMutation>(
      updateComponentsLockedStatusMutation,
    );

  const [addComponent] =
    useInterruptiveMutation<ProgramDetailsAddComponentMutation>(
      addComponentMutation,
    );

  const setErrorMessage = React.useCallback(
    (error: string) => {
      snackAlert({
        severity: "error",
        message: error,
      });
    },
    [snackAlert],
  );

  const curriculumViewStorageKey = getCurriculumViewStorageKey(
    program.slug,
    program.id,
  );
  const initialViewOptions: CurriculumViewOptions = React.useMemo(
    () => ({
      view: toEnum(
        defaultView,
        ProgramDetailsViewMode,
        ProgramDetailsViewMode.CALENDAR,
      ),
      dateDetails: {
        view: ProgramStartDateView.ANY,
      },
      weekDetails: INITIAL_WEEK_DETAILS,
      showCovers: false,
      enableColors: true,
    }),
    [defaultView],
  );
  const [savedViewOptions, setSavedViewOptions] =
    useLocalStorage<CurriculumViewOptions>(
      curriculumViewStorageKey,
      initialViewOptions,
    );
  const [queryViewOptions, setQueryViewOptions] = useQueryParam(
    CURRICULUM_VIEW_KEY,
    JSON.stringify({ ...initialViewOptions, ...savedViewOptions }),
    {
      silent: true,
    },
  );
  const viewOptions: CurriculumViewOptions = JSON.parse(queryViewOptions);
  const viewOptionsRef = React.useRef<CurriculumViewOptions | null>(null);

  React.useEffect(() => {
    setTimeout(() => {
      handleWeekRangeLoad(
        viewOptions.weekDetails.startNumber,
        viewOptions.weekDetails.endNumber,
      );
    }, 0);
  }, []);

  React.useEffect(() => {
    viewOptionsRef.current = viewOptions;
  }, [viewOptions]);

  React.useEffect(() => {
    if (viewOptions.dateDetails.view === ProgramStartDateView.CLIENT) {
      const clientName = viewOptions.dateDetails.clientName;
      const clientNames = enrollments.edges.map(
        ({ node: { client } }) => client.displayName,
      );

      if (!clientNames.includes(clientName)) {
        const resetViewOptions = {
          view: ProgramDetailsViewMode.CALENDAR,
          dateDetails: {
            view: ProgramStartDateView.ANY,
          },
          showCovers: false,
          enableColors: false,
        };
        setQueryViewOptions(JSON.stringify(resetViewOptions));
      }
    }
  }, [
    enrollments,
    initialViewOptions,
    setQueryViewOptions,
    viewOptions.dateDetails,
    viewOptions.dateDetails.clientName,
  ]);

  const handleWeekRangeLoad = React.useCallback(
    (
      weeksStartNumber: number,
      weeksEndNumber: number,
      // TODO drop it in V2, is needed to override max value before relay store updates
      maxEndNumber?: number,
    ) => {
      setLoading(true);

      const sanitizedStartNumber = Math.min(
        Math.max(1, Math.min(weeksStartNumber, weeksEndNumber)),
        maxEndNumber ? maxEndNumber : program.length,
      );
      const sanitizedEndNumber = getLimitedWeekMaxNumber(
        sanitizedStartNumber,
        Math.min(
          Math.max(weeksStartNumber, weeksEndNumber),
          maxEndNumber ? maxEndNumber : program.length,
        ),
      );

      startTransition(() => {
        refetch(
          {
            startNumber: sanitizedStartNumber,
            endNumber: sanitizedEndNumber,
          },
          {
            ...RELAY_LAZY_LOAD_COMMON_CONFIG,
            onComplete: () => setLoading(false),
          },
        );
        allWeeksRefetch({}, RELAY_LAZY_LOAD_COMMON_CONFIG);
      });

      unsafe(() =>
        setQueryViewOptions(
          JSON.stringify({
            ...viewOptions,
            weekDetails: {
              startNumber: sanitizedStartNumber,
              endNumber: sanitizedEndNumber,
            },
          }),
        ),
      );
    },
    [
      setLoading,
      setQueryViewOptions,
      unsafe,
      refetch,
      viewOptions,
      program.length,
    ],
  );

  const viewMode: ProgramDetailsViewMode =
    !canUseCalendar && viewOptions?.view === ProgramDetailsViewMode.CALENDAR
      ? DEFAULT_PROGRAM_DETAILS_VIEW_MODE
      : viewOptions.view;
  const weeksFilter = {
    start: viewOptions.weekDetails.startNumber,
    end: viewOptions.weekDetails.endNumber,
  };
  const startDateViewDetails: ProgramStartDateViewDetails = canUseCalendar
    ? viewOptions.dateDetails
    : { view: ProgramStartDateView.ANY };
  const Wrapper =
    viewMode === ProgramDetailsViewMode.CALENDAR
      ? DirtyTransaction
      : React.Fragment;

  const handleFiltersChange = React.useCallback((filters: Filters) => {
    setFilters(filters);
  }, []);

  const templateWorkoutSection = useGenerateTemplateWorkoutSection();

  const handleViewModeChange = React.useCallback(
    (event, view) => {
      if (view === ProgramDetailsViewMode.SPREADSHEET && program.isEmpty) {
        const input = {
          weekId: program.weeks[0].id,
          type: ComponentType.WORKOUT,
          title: TEMPLATE_WORKOUT_TITLE,
          status: ProgramStatus.DRAFT,
          days: defaultComponentDays,
          repeat: ComponentRepeat.NONE,
          duration: 1,
          content: JSON.stringify([templateWorkoutSection]),
        };

        addComponent({
          variables: { input },
          onCompleted: () => {
            onComponentAdd();
          },
        });
      }

      unsafe(() =>
        setQueryViewOptions(JSON.stringify({ ...viewOptions, view })),
      );
    },
    [setQueryViewOptions, unsafe, viewOptions],
  );

  const handleStartDateViewDetailsChange: ProgramStartDateViewButtonProps["onChange"] =
    React.useCallback(
      (dateDetails) => {
        unsafe(() =>
          setQueryViewOptions(JSON.stringify({ ...viewOptions, dateDetails })),
        );
      },
      [setQueryViewOptions, unsafe, viewOptions],
    );

  const handleWeeksDateRangeChange: ProgramWeekRangeSelectorProps["onChange"] =
    React.useCallback(
      (weekDetails) => {
        handleWeekRangeLoad(weekDetails.startNumber, weekDetails.endNumber);
      },
      [handleWeekRangeLoad],
    );

  const handleShowCoversToggle = React.useCallback(
    (value: boolean) => {
      unsafe(() =>
        setQueryViewOptions(
          JSON.stringify({
            ...viewOptions,
            showCovers: !viewOptions.showCovers,
          }),
        ),
      );
    },
    [setQueryViewOptions, unsafe, viewOptions],
  );

  const handleEnableColorsToggle = React.useCallback(
    (value: boolean) => {
      unsafe(() =>
        setQueryViewOptions(
          JSON.stringify({
            ...viewOptions,
            enableColors: !viewOptions.enableColors,
          }),
        ),
      );
    },
    [setQueryViewOptions, unsafe, viewOptions],
  );

  const handleAddWeek = React.useCallback(() => {
    const id = generateUniqueServerID("Week");

    const weekEndNumber = viewOptions.weekDetails.endNumber + 1;
    const weekStartNumber = Math.max(
      viewOptions.weekDetails.startNumber,
      weekEndNumber - MAX_WEEK_RANGE + 1,
    );

    addWeek({
      variables: {
        input: {
          id,
          programId: program.id,
        },
        startNumber: weekStartNumber,
        endNumber: weekEndNumber,
      },
      onCompleted: (data: any, errors) => {
        if (errors) {
          console.error(errors);
          snackAlert({
            severity: "error",
            message: "Error occurred.",
          });
        } else {
          snackAlert({
            severity: "success",
            message: "Added successfully",
          });

          handleWeekRangeLoad(weekStartNumber, weekEndNumber, weekEndNumber);
        }
      },
    });
  }, [addWeek, program.id, snackAlert, viewOptions, handleWeekRangeLoad]);

  const handleDeleteWeek = React.useCallback(
    (event, setMoreMenuEl) => {
      const { id } = event.currentTarget.dataset;
      setMoreMenuEl(null);

      const weekStartNumber = viewOptions.weekDetails.startNumber;
      const weekEndNumber = viewOptions.weekDetails.endNumber - 1;

      deleteWeek({
        variables: {
          input: { id },
          startNumber: weekStartNumber,
          endNumber: weekEndNumber,
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Deleted successfully",
            });

            handleWeekRangeLoad(weekStartNumber, weekEndNumber, weekEndNumber);
          }
        },
      });
    },
    [deleteWeek, snackAlert, viewOptions.weekDetails],
  );

  const handleDuplicateWeek = React.useCallback(
    (event, closeMoreMenu) => {
      const { id } = event.currentTarget.dataset;

      const duplicateWeekIndex = allWeeksData.weeks.edges.filter(
        ({ node }) => node.id === id,
      )[0].node.week;
      const isDuplicationOfLastWeekOnPage =
        viewOptions.weekDetails.endNumber === duplicateWeekIndex;

      const weekEndNumber = viewOptions.weekDetails.endNumber + 1;
      const weekStartNumber = isDuplicationOfLastWeekOnPage
        ? Math.max(
            viewOptions.weekDetails.startNumber,
            weekEndNumber - MAX_WEEK_RANGE + 1,
          )
        : viewOptions.weekDetails.startNumber;

      dublicateWeek({
        variables: {
          input: {
            id,
            programId: program.id,
          },
          startNumber: viewOptions.weekDetails.startNumber,
          endNumber: viewOptions.weekDetails.endNumber,
        },
        onCompleted: (data, errors) => {
          if (errors) {
            console.error(errors);
            snackAlert({
              severity: "error",
              message: "Error occurred.",
            });
          } else {
            closeMoreMenu();
            snackAlert({
              severity: "success",
              message: "Created successfully",
            });

            handleWeekRangeLoad(weekStartNumber, weekEndNumber, weekEndNumber);
          }
        },
      });
    },
    [dublicateWeek, program.id, snackAlert, viewOptions.weekDetails],
  );

  const handleMove = React.useCallback(
    (event, closeMoreMenu) => {
      const { id, direction: _direction } = event.currentTarget.dataset;
      const direction: WeekMoveDirectionType = _direction.toUpperCase();
      const input = {
        id,
        direction,
      };

      moveWeek({
        variables: {
          input,
          startNumber: viewOptions.weekDetails.startNumber,
          endNumber: viewOptions.weekDetails.endNumber,
        },
        onCompleted(_, errors) {
          if (errors && errors.length) {
            setErrorMessage(errors[0].message || "Error occurred.");
          } else {
            snackAlert({
              severity: "success",
              message: "Week successfully moved",
            });
          }
        },
        updater: (store) => {},
      });
      closeMoreMenu();
    },
    [moveWeek, setErrorMessage, snackAlert, viewOptions.weekDetails],
  );

  const [updateLockingLoading, setUpdateLockingLoading] = useState(false);

  const handlelLockAll = React.useCallback(
    (mode: boolean) => {
      setUpdateLockingLoading(true);

      updateComponents({
        variables: {
          input: {
            programId,
            locked: mode,
          },
        },
        onCompleted(_, errors) {
          setUpdateLockingLoading(false);

          if (errors && errors.length) {
            setErrorMessage(errors[0].message || "Error occurred.");
          } else {
            snackAlert({
              severity: "success",
              message: `Components ${mode ? "locked" : "unlocked"} successfully`,
            });
          }
        },
        updater: (store) => {},
      });
    },
    [program.weeks, updateComponents, setErrorMessage, snackAlert],
  );

  const handlePrevClick = React.useCallback(() => {
    const pageSize =
      Math.abs(
        viewOptions.weekDetails.endNumber - viewOptions.weekDetails.startNumber,
      ) + 1;
    const newWeeksStart = viewOptions.weekDetails.startNumber - pageSize;
    const newWeeksEnd = viewOptions.weekDetails.endNumber - pageSize;

    handleWeekRangeLoad(newWeeksStart, newWeeksEnd);
  }, [handleWeekRangeLoad, viewOptions]);

  const handleNextClick = React.useCallback(() => {
    const pageSize =
      Math.abs(
        viewOptions.weekDetails.endNumber - viewOptions.weekDetails.startNumber,
      ) + 1;
    const newWeeksStart = viewOptions.weekDetails.startNumber + pageSize;
    const newWeeksEnd = viewOptions.weekDetails.endNumber + pageSize;

    handleWeekRangeLoad(newWeeksStart, newWeeksEnd);
  }, [handleWeekRangeLoad, viewOptions]);

  const onComponentAdd = React.useCallback(() => {
    setLoading(true);

    // wait until the component editor opens before refetching
    // TODO drop with migration to REST API
    setTimeout(() => {
      startTransition(() => {
        refetch(
          {
            startNumber: viewOptionsRef.current.weekDetails.startNumber,
            endNumber: viewOptionsRef.current.weekDetails.endNumber,
          },
          {
            ...RELAY_LAZY_LOAD_COMMON_CONFIG,
            onComplete: () => setLoading(false),
          },
        );
      });
    }, 500);
  }, [refetch]);

  React.useEffect(() => {
    if (JSON.stringify(savedViewOptions) !== JSON.stringify(viewOptions)) {
      setSavedViewOptions(viewOptions);
    }
  }, [savedViewOptions, setSavedViewOptions, viewOptions]);

  return (
    <ProgramWeeksContext.Provider value={programWeeks}>
      <ProgramRefetchContext.Provider value={onComponentAdd}>
        <LinearProgress
          sx={{
            marginInline: "auto",
            mt: -0.25,
            maxWidth: 1180,
            height: "2px",
            opacity: loading ? 1 : 0,
            transition: theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.complex,
              easing: theme.transitions.easing.easeOut,
            }),
          }}
        />
        {preview && !component ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: theme.spacing(PREVIEW_BAR_SPACING),
              height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
              width: "100vw",
            }}
          >
            <PreviewBar />
            <PreviewBox src={`${location.pathname}/preview?week=1`} />
          </Box>
        ) : (
          <Container
            className={clsx(s.root, {
              [s.calendar]: viewMode === ProgramDetailsViewMode.CALENDAR,
              [s.list]: viewMode === ProgramDetailsViewMode.LIST,
              [s.spreadsheet]: viewMode === ProgramDetailsViewMode.SPREADSHEET,
            })}
          >
            <Box className={clsx(s.filters)}>
              <ProgramDetailsViewButton
                value={viewMode}
                onChange={handleViewModeChange}
                options={{
                  ...defaultCalendarViewOptions,
                  [ProgramDetailsViewMode.CALENDAR]: {
                    ...defaultCalendarViewOptions.calendar,
                    disabled: !canUseCalendar,
                  },
                }}
                disabled={loading}
              />
              <Box className={s.filtersRight}>
                <ProgramWeekRangeSelector
                  value={viewOptions.weekDetails}
                  onChange={handleWeeksDateRangeChange}
                  programLength={program.length}
                  disabled={loading || program.length === 0}
                />

                <ProgramDetailsFilters
                  filters={filters}
                  onChange={handleFiltersChange}
                  disabled={loading}
                />

                <ProgramStartDateViewButton
                  value={startDateViewDetails}
                  onChange={handleStartDateViewDetailsChange}
                  fullWidth={isSm}
                />

                {viewMode === ProgramDetailsViewMode.LIST && !isMobileApp && (
                  <ToggleCoversButton
                    toggleFlag={viewOptions.showCovers}
                    onToggle={handleShowCoversToggle}
                    fullWidth={isSm}
                  />
                )}

                {[
                  ProgramDetailsViewMode.CALENDAR,
                  ProgramDetailsViewMode.SPREADSHEET,
                ].includes(viewMode) && (
                  <ToggleColorsButton
                    toggleFlag={viewOptions.enableColors}
                    onToggle={handleEnableColorsToggle}
                    fullWidth={isSm}
                  />
                )}

                <Box sx={{ marginLeft: "auto" }}>
                  {updateLockingLoading ? (
                    <LoadingButton
                      loading
                      variant="text"
                      color={"primary"}
                      sx={{ minWidth: 128 }}
                    >
                      Loading
                    </LoadingButton>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <MinimizedTooltip
                        placement="bottom"
                        title={"Unlock all content"}
                        delayNextEnter={false}
                      >
                        <Button
                          onClick={() => handlelLockAll(false)}
                          sx={{ gap: 0.5 }}
                        >
                          <LockOpen />
                        </Button>
                      </MinimizedTooltip>
                      <Typography
                        sx={{
                          color: theme.palette.text.secondary,
                          fontSize: 15,
                          fontWeight: 500,
                        }}
                      >
                        All content
                      </Typography>
                      <MinimizedTooltip
                        placement="bottom"
                        title={"Lock all content"}
                        delayNextEnter={false}
                      >
                        <Button
                          onClick={() => handlelLockAll(true)}
                          sx={{ gap: 0.5 }}
                        >
                          <Lock />
                        </Button>
                      </MinimizedTooltip>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <EditorProgramContext.Provider value={program}>
              <ShowCoversContext.Provider value={viewOptions.showCovers}>
                <EnableColorsContext.Provider value={viewOptions.enableColors}>
                  <Wrapper>
                    <ProgramDetails
                      programRef={program}
                      viewMode={viewMode}
                      filters={filters}
                      weeksFilter={weeksFilter}
                      startDate={startDateViewDetails.date}
                      length={program.length}
                      weeks={program.weeks}
                      id={program.id}
                      slug={program.slug}
                      handleAddWeek={handleAddWeek}
                      addWeekInFlight={addWeekInFlight}
                      handleDeleteWeek={handleDeleteWeek}
                      handleDuplicateWeek={handleDuplicateWeek}
                      dublicateWeekInFlight={dublicateWeekInFlight}
                      handleMove={handleMove}
                      handlePrevClick={handlePrevClick}
                      handleNextClick={handleNextClick}
                      loading={loading}
                    />
                  </Wrapper>
                </EnableColorsContext.Provider>
              </ShowCoversContext.Provider>
            </EditorProgramContext.Provider>
          </Container>
        )}
      </ProgramRefetchContext.Provider>
    </ProgramWeeksContext.Provider>
  );
}
