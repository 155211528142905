import clsx from "clsx";
import React from "react";
import { BoxProps, TextField, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AuthButtonProps } from "../../utils/auth";
import { deviceSupports } from "../../utils/device";
import { ActionButton } from "../button/ActionButton";
import { LoginFormProps } from "../login/LoginForm";
import { GoogleButton } from "../auth/GoogleButton";
import { OrDivider } from "../auth/OrDivider";
import { AppleButton } from "../auth/AppleButton";
import { EmailNoAccount } from "../auth/EmailNoAccount";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  emailNoAccount: {
    marginTop: theme.spacing(3),
  },

  googleButton: {
    marginTop: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(3, "auto"),
  },

  field: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },

  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

export interface ClientPortalLoginFormProps extends LoginFormProps {
  onSignIn?: AuthButtonProps["onSuccess"];
}

export function ClientPortalLoginForm(props: ClientPortalLoginFormProps) {
  const {
    className,
    state,
    loading,
    errors,
    onChange,
    onSignIn,
    noAccountEmail,
    ...other
  } = props;
  const s = useStyles();
  const completed = Boolean(state.email && state.password);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const VisibilityIcon = passwordVisible ? VisibilityOff : Visibility;

  const handlePasswordVisibilityToggle = React.useCallback(() => {
    setPasswordVisible((value) => !value);
  }, []);

  return (
    <form className={clsx(s.root, className)} {...other}>
      {noAccountEmail && (
        <EmailNoAccount className={s.emailNoAccount} email={noAccountEmail} />
      )}

      {(deviceSupports.googleAuthSignInEnabled ||
        deviceSupports.appleAuthSignInEnabled) && (
        <>
          {deviceSupports.appleAuthSignInEnabled && (
            <AppleButton
              disabled={loading}
              onSuccess={onSignIn}
              children="Sign in with Apple"
            />
          )}

          {deviceSupports.googleAuthSignInEnabled && (
            <GoogleButton
              disabled={loading}
              onSuccess={onSignIn}
              children="Sign in with Google"
            />
          )}

          <OrDivider className={s.divider} />
        </>
      )}

      <TextField
        className={s.field}
        variant="outlined"
        name="email"
        type="email"
        label="Email Address"
        placeholder="email@example.com"
        value={state.email}
        onChange={onChange}
        disabled={loading}
        error={Boolean(errors.email)}
        helperText={errors.email}
        autoComplete="username"
        fullWidth
      />

      <TextField
        className={s.field}
        variant="outlined"
        name="password"
        type={passwordVisible ? "text" : "password"}
        label="Password"
        placeholder="* * * * * *"
        value={state.password}
        onChange={onChange}
        disabled={loading}
        error={Boolean(errors.password)}
        helperText={errors.password}
        autoComplete="current-password"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={handlePasswordVisibilityToggle} size="large">
              <VisibilityIcon />
            </IconButton>
          ),
        }}
      />

      <ActionButton
        className={s.submit}
        type="submit"
        disabled={!completed || loading}
        children="Sign in"
        fullWidth
      />
    </form>
  );
}
