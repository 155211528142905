import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { TrainingSummary } from "../../../../../components/training-summary/TrainingSummary";
import { useParams } from "react-router-dom";
import { ClientProgramActivitySummaryRouteQuery } from "./__generated__/ClientProgramActivitySummaryRouteQuery.graphql";
import TrackInfoTool from "../../../../../components/tools/TrackInfoTool";

export function ClientProgramActivitySummaryRoute() {
  const params = useParams();

  const { date, component } = params;

  const props = useLazyLoadQuery<ClientProgramActivitySummaryRouteQuery>(
    graphql`
      query ClientProgramActivitySummaryRouteQuery(
        $date: String!
        $component: String!
      ) {
        activity(client: "$me", date: $date, component: $component) {
          ...TrainingSummary_activity
        }
      }
    `,
    {
      date: date,
      component: component,
      shouldFetchSummary: true,
    },
  );
  const { activity } = props;

  return (
    activity && (
      <>
        <TrainingSummary activityRef={activity} />
        <TrackInfoTool
          trackInfo={{
            name: "Client - Program",
          }}
        />
      </>
    )
  );
}
