/**
 * @generated SignedSource<<f3d1f35b968937d9f2f577a14999be7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpsertActivityInput = {
  clientMutationId?: string | null | undefined;
  completed?: boolean | null | undefined;
  content?: string | null | undefined;
  edited?: boolean | null | undefined;
  endWorkout?: string | null | undefined;
  id: string;
  startWorkout?: string | null | undefined;
  submitted?: boolean | null | undefined;
};
export type ActivityCompleteActivityMutation$variables = {
  input: UpsertActivityInput;
  shouldFetchSummary: boolean;
};
export type ActivityCompleteActivityMutation$data = {
  readonly upsertActivity: {
    readonly activity: {
      readonly summary?: {
        readonly " $fragmentSpreads": FragmentRefs<"ActivityCalendar_summary">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"Activity_activity">;
    } | null | undefined;
  } | null | undefined;
};
export type ActivityCompleteActivityMutation = {
  response: ActivityCompleteActivityMutation$data;
  variables: ActivityCompleteActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldFetchSummary"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "raw",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "date",
  "storageKey": "date(raw:true)"
},
v3 = {
  "kind": "Literal",
  "name": "format",
  "value": "MMM Do"
},
v4 = {
  "kind": "Literal",
  "name": "utc",
  "value": true
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Activity_activity"
              },
              {
                "condition": "shouldFetchSummary",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivitySummary",
                    "kind": "LinkedField",
                    "name": "summary",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ActivityCalendar_summary"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityCompleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertActivityPayload",
        "kind": "LinkedField",
        "name": "upsertActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "formattedDate",
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(format:\"MMM Do\",utc:true)"
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": [
                  (v3/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": "completedAt(format:\"MMM Do\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Enrollment",
                "kind": "LinkedField",
                "name": "enrollment",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityFeedback",
                "kind": "LinkedField",
                "name": "feedbacks",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photoURL",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "read",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "fromNow"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"fromNow\")"
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coachReadAnswers",
                "storageKey": null
              },
              {
                "alias": "formattedLongDate",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "format",
                    "value": "dddd, MMMM D"
                  },
                  (v4/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "date",
                "storageKey": "date(format:\"dddd, MMMM D\",utc:true)"
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submitted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startWorkout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endWorkout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "componentId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Completion",
                "kind": "LinkedField",
                "name": "completion",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Component",
                "kind": "LinkedField",
                "name": "component",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "habitPrompt",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iconName",
                        "storageKey": null
                      }
                    ],
                    "type": "ComponentIcon",
                    "abstractKey": "__isComponentIcon"
                  }
                ],
                "storageKey": null
              },
              {
                "condition": "shouldFetchSummary",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivitySummary",
                    "kind": "LinkedField",
                    "name": "summary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Completion",
                        "kind": "LinkedField",
                        "name": "completion",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "total",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Activity",
                        "kind": "LinkedField",
                        "name": "schedule",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "longestStreak",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7610ef230e9e828937edeab188755b8",
    "id": null,
    "metadata": {},
    "name": "ActivityCompleteActivityMutation",
    "operationKind": "mutation",
    "text": "mutation ActivityCompleteActivityMutation(\n  $input: UpsertActivityInput!\n  $shouldFetchSummary: Boolean!\n) {\n  upsertActivity(input: $input) {\n    activity {\n      ...Activity_activity\n      summary @include(if: $shouldFetchSummary) {\n        ...ActivityCalendar_summary\n      }\n      id\n    }\n  }\n}\n\nfragment ActivityCalendar_summary on ActivitySummary {\n  completion {\n    completed\n    total\n    rate\n  }\n  schedule {\n    id\n    date(raw: true)\n    completed\n  }\n  longestStreak\n}\n\nfragment ActivityFeedbackButton_activity on Activity {\n  ...ActivityFeedbackList_activity\n  feedbacks {\n    id\n  }\n}\n\nfragment ActivityFeedbackList_activity on Activity {\n  feedbacks {\n    id\n    ...ActivityFeedback_activityFeedback\n  }\n  ...FeedbackForm_activity\n  id\n  coachReadAnswers\n}\n\nfragment ActivityFeedback_activityFeedback on ActivityFeedback {\n  id\n  author {\n    id\n    displayName\n    photoURL\n  }\n  content\n  read\n  createdAt(format: \"fromNow\")\n}\n\nfragment ActivityStatus_activity on Activity {\n  date(raw: true)\n  formattedDate: date(utc: true, format: \"MMM Do\")\n  completed\n  completedAt(format: \"MMM Do\")\n  enrollment {\n    client {\n      displayName\n      id\n    }\n    id\n  }\n}\n\nfragment Activity_activity on Activity {\n  ...ActivityStatus_activity\n  ...ActivityFeedbackButton_activity\n  id\n  date(raw: true)\n  formattedDate: date(utc: true, format: \"MMM Do\")\n  formattedLongDate: date(utc: true, format: \"dddd, MMMM D\")\n  content\n  completed\n  submitted\n  startWorkout\n  endWorkout\n  componentId\n  completion {\n    rate\n  }\n  component {\n    locked\n    type\n    slug\n    title\n    habitPrompt\n    ...ComponentIcon_component\n    id\n  }\n}\n\nfragment ComponentIcon_component on ComponentIcon {\n  __isComponentIcon: __typename\n  iconName\n  type\n}\n\nfragment FeedbackForm_activity on Activity {\n  id\n  client {\n    displayName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ce59ab56cde41bb6954ea59e6121d1b";

export default node;
