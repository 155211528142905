import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { SortableList, SortableListProps } from "../sortable-list/SortableList";

import { MealLoggingQuestion } from "./types";
import { MealLoggingQuestionListItem } from "./MealLoggingQuestionListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface MealLoggingQuestionListProps<T = MealLoggingQuestion>
  extends Omit<SortableListProps<T>, "options" | "ListItem" | "itemType"> {
  questions: T[];
}

export function MealLoggingQuestionList(props: MealLoggingQuestionListProps) {
  const { className, questions, ...other } = props;
  const s = useStyles();

  return (
    <SortableList
      className={clsx(s.root, className)}
      itemType="logging_question"
      ListItem={MealLoggingQuestionListItem}
      options={questions}
      {...other}
    />
  );
}
