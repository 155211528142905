import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  Button,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CalendarIcon } from "../../icons/CalendarDots.svg";

import { ClientGoalsDrawerHeader } from "./ClientGoalsDrawerHeader";
import { clientProfileTextfieldStyles } from "../client-body-weight/ClientBodyWeightDrawerEditView";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  header: {
    marginBottom: theme.spacing(6),
  },

  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },
  },

  addDateButton: {
    width: "100%",
    padding: theme.spacing(2.25),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: theme.spacing(0.5),
    justifyContent: "flex-start",
    backgroundColor: `${theme.palette.primary.main}0A`,

    "& svg": {
      width: 21,
      height: 21,
      marginRight: theme.spacing(1),
    },
  },
}));

export interface ClientGoalsDrawerEditViewProps extends BoxProps {
  name: string;
  target: string;
  date: string;
  onBack: () => void;
  onDelete: () => void;
  onClose: () => void;
  onNameChange: TextFieldProps["onChange"];
  onNameBlur: TextFieldProps["onBlur"];
  onTargetChange: TextFieldProps["onChange"];
  onTargetBlur: TextFieldProps["onBlur"];
  onEditDateClick: () => void;
  hideDelete: boolean;
}

export function ClientGoalsDrawerEditView(
  props: ClientGoalsDrawerEditViewProps,
) {
  const {
    className,
    name,
    target,
    date,
    onBack,
    onDelete,
    onClose,
    onNameChange,
    onTargetChange,
    onNameBlur,
    onTargetBlur,
    onEditDateClick,
    hideDelete,
    ...other
  } = props;
  const s = useStyles();

  const formattedDate = React.useMemo(() => {
    return date ? dayjs(date).format("MMMM D, YYYY") : "";
  }, [date]);
  const theme = useTheme();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientGoalsDrawerHeader
        className={s.header}
        onBack={onBack}
        onDelete={onDelete}
        onClose={onClose}
        hideDelete={hideDelete}
      />

      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Goal
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={name}
          onChange={onNameChange}
          onBlur={onNameBlur}
          className={s.input}
          error={name === null}
          autoFocus
          sx={clientProfileTextfieldStyles(theme.palette.primary.main)}
        />
      </Box>

      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Target
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={target}
          onChange={onTargetChange}
          onBlur={onTargetBlur}
          className={s.input}
          error={target === null}
          sx={clientProfileTextfieldStyles(theme.palette.primary.main)}
        />
      </Box>

      {date ? (
        <Box className={s.inputWrapper}>
          <Typography variant="h4" className={s.label}>
            Date
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={formattedDate}
            contentEditable={false}
            onClick={onEditDateClick}
            className={s.input}
          />
        </Box>
      ) : (
        <Button
          className={s.addDateButton}
          variant="outlined"
          color="primary"
          onClick={onEditDateClick}
        >
          <CalendarIcon /> Add a goal date
        </Button>
      )}
    </Box>
  );
}
