import { useGridCellEditor, CustomCellEditorProps } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  CustomExerciseTitleCellEditorProps,
  getDefaultExerciseCellTitle,
  isComponentRow,
} from "../utils";
import { RowType, SOMETHING_WENT_WRONG } from "../../../constants";
import { useDirtyMutation } from "../../dirty-transaction/hooks";
import { graphql } from "relay-runtime";
import { useSnackAlert } from "../../../hooks/useSnackAlert";
import { ExerciseTextEditorComponentTitleMutation } from "./__generated__/ExerciseTextEditorComponentTitleMutation.graphql";
import { Box, IconButton, useTheme } from "@mui/material";
import { Pencil } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingInline: theme.spacing(1.8),
    border: "none",
    outline: "none",
    width: "100%",
    color: theme.palette.common.black,
  },
}));

const updateComponentTitleMutation = graphql`
  mutation ExerciseTextEditorComponentTitleMutation(
    $input: UpsertComponentInput!
  ) {
    upsertComponent(input: $input) {
      component {
        ...DragCellRenderer_component

        updatedAt(utc: true)
      }
    }
  }
`;

export default memo(
  ({
    api,
    initialValue,
    onValueChange,
    data,
    saveWorkoutContent,
    setGridLoader,
    handleClickOpenDialog,
  }: CustomCellEditorProps & CustomExerciseTitleCellEditorProps) => {
    const s = useStyles();
    const theme = useTheme();

    const inputRef = useRef<HTMLInputElement>(null);
    const snackAlert = useSnackAlert();

    const initialSanitizedValue =
      initialValue === getDefaultExerciseCellTitle(data.rowType)
        ? ""
        : initialValue;
    const [value, setValue] = useState(initialSanitizedValue);

    const [updateComponentTitle] =
      useDirtyMutation<ExerciseTextEditorComponentTitleMutation>(
        updateComponentTitleMutation,
      );

    const onChange = ({ target: { value } }) => {
      setValue(value);
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const saveComponentTitle = (componentId: string, newTitle: string) => {
      const input = {
        id: componentId,
        title: newTitle,
      };
      setGridLoader(true);
      updateComponentTitle({
        variables: {
          input,
        },
        optimisticResponse: {
          upsertComponent: {
            component: input,
          },
        },
        onCompleted: (_, errors) => {
          if (errors) {
            setGridLoader(false);
            snackAlert({
              severity: "error",
              message: SOMETHING_WENT_WRONG,
            });
          } else {
            snackAlert({
              severity: "success",
              message: "Workout updated",
            });
          }
        },
      });
    };

    const saveWorkoutSectionTitle = (
      componentId: string,
      componentContent: any,
      workoutSectionId: string,
      newTitle: string,
    ) => {
      componentContent.map((node) => {
        if (node.id === workoutSectionId) {
          node.workout.title = newTitle;
        }
      });

      saveWorkoutContent(componentId, componentContent);
    };

    const isCancelAfterEnd = useCallback(() => {
      if (initialSanitizedValue === value) {
        return true;
      }

      if (isComponentRow(data.rowType)) {
        const valueWithDefault = value
          ? value
          : getDefaultExerciseCellTitle(data.rowType);
        data.exercise = valueWithDefault;
        onValueChange(valueWithDefault);

        saveComponentTitle(data.componentId, value);
        return false;
      }

      if (data.rowType === RowType.WORKOUT_SECTION) {
        data.exercise = value;
        onValueChange(value);

        saveWorkoutSectionTitle(
          data.componentId,
          data.componentContent,
          data.workoutSectionData?.id,
          value,
        );
        return false;
      }

      return false;
    }, [value]);

    useGridCellEditor({
      isCancelAfterEnd,
    });

    const handleOpenModalClick = () => {
      api.stopEditing();
      handleClickOpenDialog(undefined, data.slug);
    };
    return (
      <>
        <input
          className={s.input}
          ref={inputRef}
          type="text"
          value={value}
          onChange={onChange}
        />
        {data.rowType !== RowType.WORKOUT_SECTION && (
          <Box sx={{ background: theme.palette.common.white, pr: 0.5 }}>
            <IconButton
              onClick={handleOpenModalClick}
              sx={{ marginBottom: 0.5 }}
            >
              <Pencil
                size={20}
                strokeWidth={1.2}
                color={theme.palette.common.black}
              />
            </IconButton>
          </Box>
        )}
      </>
    );
  },
);
