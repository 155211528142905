/**
 * @generated SignedSource<<c21621664ecf7c922703c2f2a46df74d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientPortalSettings_workspace$data = {
  readonly logoURL: string | null | undefined;
  readonly mealLogging: boolean;
  readonly message: string | null | undefined;
  readonly name: string | null | undefined;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"MealLoggingSettings_workspace">;
  readonly " $fragmentType": "ClientPortalSettings_workspace";
};
export type ClientPortalSettings_workspace$key = {
  readonly " $data"?: ClientPortalSettings_workspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientPortalSettings_workspace">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientPortalSettings_workspace",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MealLoggingSettings_workspace"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mealLogging",
      "storageKey": null
    }
  ],
  "type": "Workspace",
  "abstractKey": null
};

(node as any).hash = "43ec116db87cb1b440b91bfa1fb3b2f5";

export default node;
