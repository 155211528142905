import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },

  header: {
    margin: theme.spacing(2, 0),
  },

  subheader: {
    fontWeight: 500,
  },
}));

export interface MessagesEmptyProps extends BoxProps {}

export function MessagesEmpty(props: MessagesEmptyProps) {
  const { className, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography variant="h5" className={s.header}>
        No messages selected
      </Typography>
      <Typography variant="body1" className={s.subheader}>
        Choose a message from the list, or start a new conversation.
      </Typography>
    </Box>
  );
}
