/**
 * @generated SignedSource<<917eed091409c4eb66628e39e0269756>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachClientsFormsListScreenRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
};
export type CoachClientsFormsListScreenRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CoachClientsFormsListScreen_root">;
};
export type CoachClientsFormsListScreenRefetchQuery = {
  response: CoachClientsFormsListScreenRefetchQuery$data;
  variables: CoachClientsFormsListScreenRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 50,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoachClientsFormsListScreenRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CoachClientsFormsListScreen_root"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoachClientsFormsListScreenRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClientFormConnection",
        "kind": "LinkedField",
        "name": "clientForms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageCursors",
            "kind": "LinkedField",
            "name": "pageCursors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageCursor",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageCursor",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "page",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageCursor",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientFormEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientForm",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalQuestions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "MMM D, YYYY"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": "createdAt(format:\"MMM D, YYYY\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CoachClientsFormsListScreen_clientForms",
        "kind": "LinkedHandle",
        "name": "clientForms"
      }
    ]
  },
  "params": {
    "cacheID": "60d1b48f9923378b7f3f8870210f4136",
    "id": null,
    "metadata": {},
    "name": "CoachClientsFormsListScreenRefetchQuery",
    "operationKind": "query",
    "text": "query CoachClientsFormsListScreenRefetchQuery(\n  $after: String\n  $first: Int = 50\n) {\n  ...CoachClientsFormsListScreen_root_2HEEH6\n}\n\nfragment CoachClientsFormsListItem_clientForm on ClientForm {\n  id\n  name\n  slug\n  totalQuestions\n  createdAt(format: \"MMM D, YYYY\")\n}\n\nfragment CoachClientsFormsListScreen_root_2HEEH6 on Root {\n  clientForms(first: $first, after: $after) {\n    pageCursors {\n      ...ConnectionPagination_pageCursors\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        ...CoachClientsFormsListItem_clientForm\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ConnectionPagination_pageCursors on PageCursors {\n  previous {\n    current\n    cursor\n  }\n  around {\n    current\n    cursor\n    page\n  }\n  next {\n    current\n    cursor\n  }\n}\n"
  }
};
})();

(node as any).hash = "ada18f92fc9ed86d5724559e2403a12c";

export default node;
