/**
 * @generated SignedSource<<25dd01491e167e9b76878a41b57e35fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EnrolledAvatar_enrollment$data = {
  readonly client: {
    readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
  };
  readonly " $fragmentType": "EnrolledAvatar_enrollment";
};
export type EnrolledAvatar_enrollment$key = {
  readonly " $data"?: EnrolledAvatar_enrollment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EnrolledAvatar_enrollment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnrolledAvatar_enrollment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatar_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Enrollment",
  "abstractKey": null
};

(node as any).hash = "199bb0b3bbee9425a3ae10c175da769e";

export default node;
