/**
 * @generated SignedSource<<e57c3aa16f17987496d43a931c6d14e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useLogoutSignOutMutation$variables = Record<PropertyKey, never>;
export type useLogoutSignOutMutation$data = {
  readonly signOut: string | null | undefined;
};
export type useLogoutSignOutMutation = {
  response: useLogoutSignOutMutation$data;
  variables: useLogoutSignOutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signOut",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLogoutSignOutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useLogoutSignOutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1cd6b8cf5d1c4e334ebf3b4054d272d0",
    "id": null,
    "metadata": {},
    "name": "useLogoutSignOutMutation",
    "operationKind": "mutation",
    "text": "mutation useLogoutSignOutMutation {\n  signOut\n}\n"
  }
};
})();

(node as any).hash = "d3e69fd4bdc1ac4668e7d5b0cdc40a4e";

export default node;
