/**
 * @generated SignedSource<<575c5a02ded6ad0ff37bcc8b07d749eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumEditScreen_weeks$data = {
  readonly id: string;
  readonly weeks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly week: number;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CurriculumEditScreen_weeks";
};
export type CurriculumEditScreen_weeks$key = {
  readonly " $data"?: CurriculumEditScreen_weeks$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumEditScreen_weeks">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CurriculumEditScreenWeeksRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CurriculumEditScreen_weeks",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "after",
          "value": ""
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 9999
        }
      ],
      "concreteType": "WeeksConnection",
      "kind": "LinkedField",
      "name": "weeks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WeeksEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Week",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "week",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "weeks(after:\"\",first:9999)"
    },
    (v0/*: any*/)
  ],
  "type": "Program",
  "abstractKey": null
};
})();

(node as any).hash = "2f45785ec35a9ce4fbcd38ff0f11acef";

export default node;
