/**
 * @generated SignedSource<<3daba480b35648a0294371aac65405e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type NutritionTrackingType = "MACROS" | "PORTIONS" | "%future added value";
export type Units = "METRIC" | "US" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ClientMeasurementsCartDrawer_user$data = {
  readonly id: string;
  readonly metrics: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementsCartDrawerEditView_metrics">;
  }>;
  readonly nutritionTarget: ReadonlyArray<{
    readonly trackingType: NutritionTrackingType;
  } | null | undefined> | null | undefined;
  readonly units: Units;
  readonly " $fragmentType": "ClientMeasurementsCartDrawer_user";
};
export type ClientMeasurementsCartDrawer_user$key = {
  readonly " $data"?: ClientMeasurementsCartDrawer_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientMeasurementsCartDrawer_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "measurementType"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ClientMeasurementsCartDrawerRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ClientMeasurementsCartDrawer_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "units",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientNutritionTarget",
      "kind": "LinkedField",
      "name": "nutritionTarget",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackingType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "measurementType",
          "variableName": "measurementType"
        },
        {
          "kind": "Literal",
          "name": "metricType",
          "value": "CHECKIN_ANSWER_MEASUREMENT"
        }
      ],
      "concreteType": "ClientMetric",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientMeasurementsCartDrawerEditView_metrics"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "7d999d052edc9df8ef8aea8b90c03cd3";

export default node;
