import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { genderOptions } from "../../constants";

import { RadioGroupField, RadioGroupFieldProps } from "./RadioGroupField";

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    fontWeight: 500,
  },
}));

export interface GenderFieldProps<T>
  extends Omit<
    RadioGroupFieldProps<(typeof genderOptions)[0]>,
    "onChange" | "options" | "label" | "error"
  > {
  onChange?: (value: T) => void;
  value?: T;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
}

export function GenderField<T extends string = string>(
  props: GenderFieldProps<T>,
) {
  const {
    className,
    onChange,
    disabled,
    value,
    error,
    errorMessage = "Please enter a valid gender",
    ...other
  } = props;
  const s = useStyles();
  const handleGenderChange = React.useCallback(
    (event) => {
      if (onChange) {
        onChange(event.target.value as T);
      }
    },
    [onChange],
  );

  return (
    <RadioGroupField
      label="Gender"
      options={genderOptions}
      value={value}
      onChange={handleGenderChange}
      error={error ? errorMessage : null}
      {...other}
    />
  );
}
