/**
 * @generated SignedSource<<8370dfa1a5623bc90f1acdd951556d02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveUserNoteInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type UserNotesListItemRemoveUserNoteMutation$variables = {
  input: RemoveUserNoteInput;
};
export type UserNotesListItemRemoveUserNoteMutation$data = {
  readonly removeUserNote: {
    readonly removedUserNoteId: string;
  } | null | undefined;
};
export type UserNotesListItemRemoveUserNoteMutation = {
  response: UserNotesListItemRemoveUserNoteMutation$data;
  variables: UserNotesListItemRemoveUserNoteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveUserNotePayload",
    "kind": "LinkedField",
    "name": "removeUserNote",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedUserNoteId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotesListItemRemoveUserNoteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNotesListItemRemoveUserNoteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6692ae718e4b50910825b1db947e1ae7",
    "id": null,
    "metadata": {},
    "name": "UserNotesListItemRemoveUserNoteMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotesListItemRemoveUserNoteMutation(\n  $input: RemoveUserNoteInput!\n) {\n  removeUserNote(input: $input) {\n    removedUserNoteId\n  }\n}\n"
  }
};
})();

(node as any).hash = "c0f223c9bf60e6718a7d997449811f58";

export default node;
