import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";

import { ProgressLine } from "../progress/ProgressLine";

import { ClientPastProgramCard_enrollment$key } from "./__generated__/ClientPastProgramCard_enrollment.graphql";
import { ClientSmallProgramCard } from "./ClientSmallProgramCard";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    margin: theme.spacing(0.25, 0, 1),
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.75),
  },
}));

const enrollmentFragment = graphql`
  fragment ClientPastProgramCard_enrollment on Enrollment {
    ...ClientSmallProgramCard_enrollment
    totalDays
    endDateLong: endDate(utc: true, format: "MMMM D, YYYY")
    completion {
      completed
      total
      rate
    }
  }
`;

export interface ClientPastProgramCardProps {
  enrollmentRef: ClientPastProgramCard_enrollment$key;
}

export function ClientPastProgramCard(props: ClientPastProgramCardProps) {
  const { enrollmentRef, ...other } = props;
  const s = useStyles();
  const enrollment = useFragment(enrollmentFragment, enrollmentRef);
  const {
    totalDays,
    endDateLong,
    completion: { completed, total, rate },
  } = enrollment;

  const subtitle = React.useMemo(() => {
    const length = `${totalDays} day${totalDays > 1 ? "s" : ""}`;
    return (
      <>
        {length} &bull; Ended {endDateLong}
      </>
    );
  }, [totalDays, endDateLong]);

  const content = React.useMemo(
    () => (
      <Box className={s.content}>
        <Typography className={s.text}>
          {completed} of {total} items completed
        </Typography>
        <ProgressLine value={rate} />
      </Box>
    ),
    [completed, total, rate], // eslint-disable-line
  );

  return (
    <ClientSmallProgramCard
      enrollmentRef={enrollment}
      subtitle={subtitle}
      content={content}
      {...other}
    />
  );
}
