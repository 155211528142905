/**
 * @generated SignedSource<<5bdab9727c1447ff25576d1f689afb61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnenrollClientInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type ConfirmRemoveFromProgramDialogUnenrollMutation$variables = {
  input: UnenrollClientInput;
};
export type ConfirmRemoveFromProgramDialogUnenrollMutation$data = {
  readonly unenrollClient: {
    readonly clientMutationId: string | null | undefined;
  } | null | undefined;
};
export type ConfirmRemoveFromProgramDialogUnenrollMutation = {
  response: ConfirmRemoveFromProgramDialogUnenrollMutation$data;
  variables: ConfirmRemoveFromProgramDialogUnenrollMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnenrollClientPayload",
    "kind": "LinkedField",
    "name": "unenrollClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmRemoveFromProgramDialogUnenrollMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmRemoveFromProgramDialogUnenrollMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb69a9e14833b8a9ba7c7d60547d806e",
    "id": null,
    "metadata": {},
    "name": "ConfirmRemoveFromProgramDialogUnenrollMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmRemoveFromProgramDialogUnenrollMutation(\n  $input: UnenrollClientInput!\n) {\n  unenrollClient(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "950f9f383856e428cf8a96b46e8b9ac3";

export default node;
