import {
  NodeEntry,
  Node,
  Range,
  Editor,
  Element,
  Transforms,
  Path,
} from "slate";
import { ReactEditor } from "slate-react";
import { generateId } from "./nodeUtil";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";

export type ElementId = any;

export const deepCompare = (left: any, right: any) =>
  left === right || JSON.stringify(left) === JSON.stringify(right);

export const SchemaElements = {
  getEmptyNode(
    type: string = ELEMENT_PARAGRAPH,
    attrs: any = {
      id: generateId(),
    },
    children: Array<Node | Element> = [{ text: "" }],
  ): Element {
    return { type, children, ...attrs };
  },

  updateElement(
    editor: ReactEditor,
    id: ElementId,
    attrs: any,
    onlyElements = false,
  ): void {
    const entry = SchemaElements.nodeEntryById(editor, id, onlyElements);

    if (entry) {
      const element = entry[0];
      const hasChanges = Object.entries(attrs).some(
        ([key, value]) => !deepCompare(element[key], value),
      );

      if (hasChanges) {
        Transforms.setNodes(editor, attrs, { at: entry[1] });
      }
    }
  },

  createElement(
    type: string,
    attrs: Record<string, any> = {},
    children: Node[] = [{ text: "" }],
  ): any {
    if (!attrs.id) {
      attrs.id = generateId();
    }

    return { type, children, ...attrs };
  },

  removeElement(
    editor: ReactEditor,
    id: ElementId,
    onlyElements = false,
  ): void {
    const entry = SchemaElements.nodeEntryById(editor, id, onlyElements);

    if (entry) {
      Transforms.removeNodes(editor, { at: entry[1] });
    }
  },

  nodeEntryById(
    editor: ReactEditor,
    id: ElementId,
    onlyElements = true,
  ): NodeEntry<Node> | null {
    const iter = onlyElements
      ? Node.elements(editor)
      : Node.descendants(editor);

    for (const entry of Node.descendants(editor)) {
      if ("id" in entry[0] && entry[0].id == id) {
        return entry;
      }
    }

    return null;
  },

  insertElementBefore(
    editor: ReactEditor,
    id: ElementId,
    element: Element | Element[],
  ) {
    const entry = SchemaElements.nodeEntryById(editor, id, false);

    if (entry) {
      const at = entry[1];

      Transforms.insertNodes(editor, element, { at });

      return at;
    }
  },

  insertElementAfter(
    editor: ReactEditor,
    id: ElementId,
    element: Element | Element[],
  ) {
    const entry = SchemaElements.nodeEntryById(editor, id, false);

    if (entry) {
      const at = Path.next(entry[1]);

      Transforms.insertNodes(editor, element, { at });

      return at;
    }
  },

  isElementSelected(editor: ReactEditor, elementId: ElementId) {
    if (ReactEditor.isFocused(editor)) {
      const [, path] = SchemaElements.nodeEntryById(editor, elementId, true);

      return editor.selection && Range.includes(editor.selection, path);
    } else {
      return false;
    }
  },
} as const;
