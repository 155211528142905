import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { Menu, MenuProps } from "../menu/Menu";
import { useRemoveCustomAsset } from "../component-library/hooks/useRemoveCustomAsset";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "999999 !important" as any,
  },
}));

export interface LibraryExerciseMenuProps extends MenuProps {
  assetId: string;
}

export function LibraryExerciseMenu(props: LibraryExerciseMenuProps) {
  const { className, onClose, assetId, ...other } = props;
  const s = useStyles();
  const removeAsset = useRemoveCustomAsset("Exercise deleted");

  const handleDeleteClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      removeAsset(assetId);
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [assetId, onClose, removeAsset],
  );

  const handleClose = React.useCallback(
    (event, reason) => {
      event.stopPropagation();

      if (onClose) {
        onClose(event, reason);
      }
    },
    [onClose],
  );

  return (
    <Menu
      className={clsx(s.root, className)}
      styleVariant="inverse"
      onClose={handleClose}
      {...other}
    >
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon children={<RemoveIcon />} />
        <ListItemText primary="Delete exercise" />
      </MenuItem>
    </Menu>
  );
}
