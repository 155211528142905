/**
 * @generated SignedSource<<85f75834c3569d0db663b7f10cb883b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ComponentStatus = "ARCHIVED" | "DRAFT" | "PUBLISHED" | "%future added value";
export type ComponentType = "CHECKIN" | "HABIT" | "LESSON" | "MESSAGE" | "WORKOUT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CalendarComponentCard_component$data = {
  readonly locked: boolean;
  readonly status: ComponentStatus;
  readonly title: string;
  readonly type: ComponentType;
  readonly " $fragmentSpreads": FragmentRefs<"ComponentIcon_component">;
  readonly " $fragmentType": "CalendarComponentCard_component";
};
export type CalendarComponentCard_component$key = {
  readonly " $data"?: CalendarComponentCard_component$data;
  readonly " $fragmentSpreads": FragmentRefs<"CalendarComponentCard_component">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CalendarComponentCard_component",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "draft",
          "value": true
        }
      ],
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(draft:true)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locked",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComponentIcon_component"
    }
  ],
  "type": "Component",
  "abstractKey": null
};

(node as any).hash = "6514e4f86d747acc1c08f357f7745dc1";

export default node;
