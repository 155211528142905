export function isValidUrl(str: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i",
  );
  return !!pattern.test(str);
}
export function isPdfUrl(url: string): boolean {
  return url?.toLowerCase()?.endsWith(".pdf");
}
export function humanReadableFileSize(size: number, precision = 0) {
  const units = ["B", "kB", "MB", "GB"];
  const base = 1024;
  const power = Math.min(
    Math.floor(Math.log(size) / Math.log(base)),
    units.length - 1,
  );
  const unit = units[power];
  const formatted = (size / Math.pow(base, power)).toFixed(precision);

  return `${formatted} ${unit}`;
}

export function isValidWorkoutTitleLength(str: string): boolean {
  return str.length >= 5 && str.length <= 250;
}
