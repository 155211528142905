/**
 * @generated SignedSource<<fd40ebdaaeac2c74dc86ec39e7626026>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteClientGoalInput = {
  clientId: string;
  clientMutationId?: string | null | undefined;
  goalId: string;
};
export type DeleteClientGoalMutation$variables = {
  input: DeleteClientGoalInput;
};
export type DeleteClientGoalMutation$data = {
  readonly deleteClientGoal: {
    readonly deletedClientGoalId: string;
  } | null | undefined;
};
export type DeleteClientGoalMutation = {
  response: DeleteClientGoalMutation$data;
  variables: DeleteClientGoalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteClientGoalPayload",
    "kind": "LinkedField",
    "name": "deleteClientGoal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedClientGoalId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteClientGoalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteClientGoalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d390376c5ac5759fd4f5d7d1a6a555a8",
    "id": null,
    "metadata": {},
    "name": "DeleteClientGoalMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteClientGoalMutation(\n  $input: DeleteClientGoalInput!\n) {\n  deleteClientGoal(input: $input) {\n    deletedClientGoalId\n  }\n}\n"
  }
};
})();

(node as any).hash = "306b0bf1edad97c8563c4d5e3a805240";

export default node;
