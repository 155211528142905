import clsx from "clsx";
import React from "react";
import { Box, BoxProps, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { HighlightOff } from "@mui/icons-material";
import { useReadOnly } from "../../hooks";
import {
  blurEditor,
  findNodePath,
  focusEditor,
  getNode,
  getNodeEntry,
  removeNodes,
  replaceNodeChildren,
  useEditorRef,
} from "@udecode/plate-common";
import { Node, Transforms } from "slate";
import { SchemaElements } from "../../utils/withSchema";
import { TabInsertDivider } from "./TabInsertDivider";
import { ReactEditor } from "slate-react";
import { parseTabsListElement } from "./utils";
import { ELEMENT_TABS_ITEM } from "./TabsItem";
import { generateId } from "../../utils/nodeUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    borderColor: theme.palette.quote,
    borderStyle: "solid",
    borderWidth: 0,
  },

  label: {
    backgroundColor: theme.palette.depressed,
    cursor: "pointer",
    color: theme.palette.text.secondary,
    fontWeight: 500,

    "$active &": {
      color: theme.palette.text.primary,
    },

    "& > *": {
      padding: theme.spacing(1.5, 2.5),
      fontSize: 16,
      lineHeight: "24px",
      border: 0,
      display: "flex",
      whiteSpace: "nowrap",
      justifyContent: "space-between",
      fontWeight: 600,
    },

    "$horizontal & > *": {
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        padding: theme.spacing(1, 1.5),
      },
    },

    "& span": {
      maxWidth: "100%",
      overflowX: "hidden",
      textOverflow: "ellipsis",
    },
  },
  input: {
    minWidth: 200,
    width: "100%",
    marginLeft: 2,
  },

  active: {},

  horizontal: {
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderRightWidth: 1,
    marginBottom: -1,

    "& $label": {
      minWidth: 50,
      maxWidth: 150,

      [theme.breakpoints.up("md")]: {
        minWidth: 100,
        maxWidth: 250,
      },
    },

    "&$active": {
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.background.paper,
    },
  },

  vertical: {
    flexDirection: "column",

    "&:not($first)": {
      borderTopWidth: 1,
    },
  },
  divider: {},
  first: {},
  removeButton: {
    padding: 0,
    marginRight: theme.spacing(-1),
    float: "right",
    opacity: 0,

    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },

    "$label :hover &": {
      opacity: 1,
    },
  },
}));

export interface TabLabelProps extends BoxProps {
  element: any;
  children?: React.ReactNode;
}

export function TabLabel(props: any) {
  const { className, element, children, parentId, ...other } = props;
  const s = useStyles();
  const editor: any = useEditorRef();
  const readOnly = useReadOnly();

  const { tabs, variant } = parseTabsListElement(
    SchemaElements.nodeEntryById(editor, parentId, false)[0],
  );
  const index = tabs.indexOf(element);

  const [edit, setEdit] = React.useState(false);
  const defaultLabel = "New Tab";
  const [label, setLabel] = React.useState<string>(
    element.label || defaultLabel,
  );
  const orientation = variant === "tabs" ? "horizontal" : "vertical";

  const saveTab = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setEdit(false);
      SchemaElements.updateElement(editor, element.id, {
        label,
      });
    },
    [label, editor, element.id],
  );

  const handleLabelKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      event.key === "Enter" && saveTab(event),
    [saveTab],
  );
  const handleLabelBlur = saveTab;

  const handleLabelChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLabel(event.target.value);
    },
    [],
  );

  const handleTabDblClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (!readOnly) {
        setEdit(true);
      }
    },
    [readOnly],
  );

  const focusOnActiveTab = (tabs, parentPath) => {
    const activeTabIndex = tabs.findIndex((tab) => tab.active === true);

    if (activeTabIndex === -1) {
      return;
    }

    const path = [...parentPath, activeTabIndex, 0, 0];
    Transforms.select(editor, {
      anchor: { path, offset: 0 },
      focus: { path, offset: 0 },
    });
  };

  const handleTabClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!edit) {
        const active = variant === "accordion" ? element.active !== true : true;

        const updatedTabs = [];

        const parent = SchemaElements.nodeEntryById(
          editor as any,
          parentId,
          false,
        );

        for (const [childNode] of Node.children(parent[0], [])) {
          const node = childNode as any;

          if (node.type === ELEMENT_TABS_ITEM) {
            updatedTabs.push({
              ...node,
              active: node.id === element.id ? active : false,
            });
          }
        }

        replaceNodeChildren(editor as any, {
          nodes: updatedTabs,
          at: parent[1],
        });

        focusOnActiveTab(updatedTabs, parent[1]);
      }
    },
    [edit, editor, element.active, element.id, tabs, variant],
  );

  const handleTabRemove = (e) => {
    e.stopPropagation();

    if (tabs.length === 1) {
      const parent = SchemaElements.nodeEntryById(editor, parentId);
      removeNodes(editor, { at: parent[1] });
      return;
    }

    const updatedTabs = tabs.filter((tab) => tab.id !== element.id);

    if (element.active) {
      updatedTabs[0].active = true;
    }

    const entry = SchemaElements.nodeEntryById(editor as any, parentId, false);
    replaceNodeChildren(editor as any, { nodes: updatedTabs, at: entry[1] });
    focusOnActiveTab(updatedTabs, entry[1]);
  };

  const handleLabelClick = React.useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (element.active && !readOnly) {
        event.stopPropagation();
        setEdit(true);
      }
    },
    [element.active, readOnly],
  );

  const handleTabAdd = (insertBefore: boolean) => {
    const newTab = {
      type: ELEMENT_TABS_ITEM,
      children: [{ type: "p", children: [{ text: "" }], id: generateId() }],
      label: "New Tab",
      active: true,
    };

    const index = tabs.indexOf(element);
    const newTabIndex = insertBefore ? index : index + 1;
    const updatedTabs = tabs.map((tab) => ({ ...tab, active: false }));

    updatedTabs.splice(newTabIndex, 0, newTab);

    const entry = SchemaElements.nodeEntryById(editor as any, parentId, false);
    replaceNodeChildren(editor as any, { nodes: updatedTabs, at: entry[1] });
    const tabsNode = SchemaElements.nodeEntryById(
      editor as ReactEditor,
      parentId,
      false,
    );
    focusEditor(editor, [...tabsNode[1], newTabIndex, 0]);
  };

  return (
    <Box
      className={clsx(
        s.root,
        className,
        s[orientation],
        index === 0 && s.first,
        element.active && s.active,
      )}
      {...other}
    >
      {!readOnly && index === 0 && (
        <TabInsertDivider
          className={s.divider}
          orientation={orientation}
          onAdd={() => handleTabAdd(true)}
        />
      )}

      <Box
        className={s.label}
        onClick={handleTabClick}
        onDoubleClick={handleTabDblClick}
        contentEditable={false}
      >
        {edit && element.active ? (
          <input
            className={s.input}
            type="text"
            value={label}
            onBlur={handleLabelBlur}
            onChange={handleLabelChange}
            onKeyDown={handleLabelKeyDown}
            autoFocus
          />
        ) : (
          <Box>
            <span onClick={handleLabelClick}>{label || defaultLabel}</span>
            {!readOnly && (
              <IconButton
                className={s.removeButton}
                children={<HighlightOff />}
                onClick={handleTabRemove}
                size="large"
              />
            )}
          </Box>
        )}
      </Box>

      {children}

      {!readOnly && (
        <TabInsertDivider
          className={s.divider}
          orientation={orientation}
          onAdd={() => handleTabAdd(false)}
        />
      )}
    </Box>
  );
}
